import { NgClass } from "@angular/common";
import { Component, computed, inject, input, OnInit, signal, TemplateRef, viewChild } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { filter, map } from "rxjs";
import { BreadcrumbService } from "xng-breadcrumb";

import { AdaaHelper, useNewDS } from "../../../core/utils";
import {
  AttachmentsListComponent,
  ConfirmationModalComponent,
  EntityPlanChangeDetectionComponent,
  ExtFieldsTabComponent,
  FloatActionComponent,
  FormStepsComponent,
  getFieldValueObject,
  NationalTargetFormComponent,
  WfAlertDecisionComponent,
} from "../../../shared/components";
import { Constants } from "../../../shared/constants/constants";
import { AdaaBoolean, Language, PageMode } from "../../../shared/constants/enums";
import {
  AffectedEntityKpi,
  AttachmentModelType,
  BenchmarkModelType,
  EntityLeaderDecisionStateType,
  ExtendedFieldAttributeModelType,
  InternationalOrganizationType,
  KpiMetricModelType,
  KpiTypeConstantType,
  MainResponseModel,
  NationalTargetImprovementPlanType,
  ObjectStatus,
  ParameterCatalog,
  TextMapModelType,
} from "../../../shared/models";
import {
  AppTitleService,
  ExtendedFieldsService,
  KpisApiService,
  KpisService,
  LanguageService,
  SystemLayoutService,
  ValidatorApiService,
} from "../../../shared/services";
import { FormDirtyCheckerService, FormModelObjectType } from "../../../shared/services/form-dirty-checker.service";
import { TextMappingsApiService } from "../../../shared/services/text-mapping-api.service";
import {
  DataInputTabComponent,
  ImprovementPlanComponent,
  InformationTabComponent,
  MeasurementDetailsComponent,
  NewInternationalOrgType,
} from "./components";
import { KpiBenchmarkFormComponent } from "./components/kpi-benchmark-form/kpi-benchmark-form.component";
import { NationalTargetManagementComponent } from "./components/national-target-management/national-target-management.component";
import { KpiTargetsComponent, MasterKpiTargetsComponent } from "./components/targets-tab";
import { ContributingEntitiesDataType, EditorTabInputType, editPageFloatActions, KpiEditorTabs } from "./utils";

@Component({
  selector: "adaa-kpi-editor",
  standalone: true,
  providers: [FormDirtyCheckerService],
  imports: [
    TranslateModule,
    FloatActionComponent,
    EntityPlanChangeDetectionComponent,
    FormStepsComponent,
    NgClass,
    InformationTabComponent,
    ExtFieldsTabComponent,
    MeasurementDetailsComponent,
    KpiTargetsComponent,
    MasterKpiTargetsComponent,
    AttachmentsListComponent,
    FormsModule,
    ReactiveFormsModule,
    NationalTargetFormComponent,
    KpiBenchmarkFormComponent,
    DataInputTabComponent,
    NationalTargetManagementComponent,
    ImprovementPlanComponent,
    WfAlertDecisionComponent,
  ],
  templateUrl: "./kpi-editor.component.html",
  styleUrl: "./kpi-editor.component.scss",
})
export class KpiEditorComponent implements OnInit {
  readonly router = inject(Router);
  readonly kpisService = inject(KpisService);
  readonly kpiEditorTabs = inject(KpiEditorTabs);
  readonly languageService = inject(LanguageService);
  private readonly _modalService = inject(NgbModal);
  private readonly _toastrService = inject(ToastrService);
  private readonly _kpisApiService = inject(KpisApiService);
  private readonly _appTitleService = inject(AppTitleService);
  private readonly _breadcrumbService = inject(BreadcrumbService);
  private readonly _validatorApiService = inject(ValidatorApiService);
  private readonly _systemLayoutService = inject(SystemLayoutService);
  private readonly _extendedFieldsService = inject(ExtendedFieldsService);
  private readonly _textMappingsApiService = inject(TextMappingsApiService);
  private readonly _formDirtyCheckerService = inject(FormDirtyCheckerService);

  readonly kpiTargetsForm = viewChild<KpiTargetsComponent | MasterKpiTargetsComponent>("kpiTargets");
  readonly infoTabForm = viewChild.required<InformationTabComponent>("infoTabForm");
  readonly extFieldsTab = viewChild.required<ExtFieldsTabComponent>("extFieldsTab");
  readonly nationalTargetForm = viewChild<NationalTargetFormComponent>("nationalTargetForm");
  readonly measurementDetailsTab = viewChild.required<MeasurementDetailsComponent>("measurementDetails");
  readonly recalculateModal = viewChild.required<TemplateRef<unknown>>("recalculateModal");
  readonly nationalTargetManagement = viewChild<NationalTargetManagementComponent>("nationalTargetManagement");
  readonly improvementPlan = viewChild<ImprovementPlanComponent>("improvementPlan");
  readonly kpiBenchmarkForm = viewChild.required<KpiBenchmarkFormComponent>("kpiBenchmarkForm");
  readonly attachmentList = viewChild.required<AttachmentsListComponent>("attachmentList");
  readonly dataInput = viewChild<DataInputTabComponent>("dataInput");

  kpiId = input(undefined, {
    transform: (val: string | undefined): number | undefined => {
      if (!val) return undefined;
      return Number(val);
    },
  });
  kpiType = input.required<Lowercase<KpiTypeConstantType>>();
  pageMode = input.required<PageMode>();

  timestamp = signal<{ start: number; end: number } | undefined>(undefined);
  kpi = signal<Record<string, unknown> | undefined>(undefined);
  stagedKpi = signal<Record<string, unknown> | undefined>(undefined);
  validations = signal<ParameterCatalog[]>([]);
  kpiName = signal<string | undefined>(undefined);
  textMaps = signal<TextMapModelType[]>([]);

  entityLeadOptionRequests = computed(() => {
    if (this.stagedKpi()?.entityLeadOptionRequests) {
      return this.stagedKpi()?.entityLeadOptionRequests as EntityLeaderDecisionStateType[];
    }
    return [];
  });
  ntkpiId = computed(() => {
    const data = this.stagedKpi();
    if (!data) return;
    const ntkpi = data.ntKpi as Record<string, unknown>;
    if (!ntkpi || !("id" in ntkpi)) return;
    return ntkpi.id as number;
  });
  entityId = computed(() => {
    const stagedKpi = this.stagedKpi();
    if (this.isSKPI()) {
      return AdaaHelper.entity?.id as number;
    }
    if (!stagedKpi) return;
    return (stagedKpi as { sponsorEntityId: number }).sponsorEntityId;
  });
  kpiIsBounded = computed(() => this.stagedKpi()?.trend === Constants.CONSTANT_TREND_BOUNDED);
  kpiTypeId = computed(() => this.kpisService.getKpiTypeId(this.kpiType()));
  pageTitle = computed(() => this.kpisService.getTableTitle(this.kpiTypeId(), "list", true));
  extAttributes = computed(() => {
    const kpi = this.kpi();
    if (!kpi) return [];
    return (kpi as { extendedFields?: ExtendedFieldAttributeModelType[] }).extendedFields ?? [];
  });
  attachments = computed(() => {
    const stagedKpi = this.stagedKpi();
    if (!stagedKpi) return [];
    return (stagedKpi as { attachments?: AttachmentModelType[] }).attachments ?? [];
  });
  benchmarks = computed(() => {
    const kpi = this.kpi();
    if (!kpi) return [];
    return (kpi as { benchmarks?: BenchmarkModelType[] }).benchmarks ?? [];
  });
  isMOKPI = computed(() => this.kpiTypeId() === Constants.KPI_TYPE.MOKPI);
  isSKPI = computed(() => this.kpiTypeId() === Constants.KPI_TYPE.SKPI);
  isMasterEKPI = computed(
    () =>
      (this.pageMode() === PageMode.edit &&
        this.kpiTypeId() === Constants.KPI_TYPE.EKPI &&
        !AdaaHelper.isDefined(this.stagedKpi()?.targetsInputEntityId)) ||
      (this.pageMode() === PageMode.create && this.kpiTypeId() === Constants.KPI_TYPE.EKPI)
  );
  isOPM = computed(() => this.kpiTypeId() === Constants.KPI_TYPE.OPM);
  isNTKPI = computed(() => this.kpiTypeId() === Constants.KPI_TYPE.NTKPI);
  isEKPI = computed(() => this.kpiTypeId() === Constants.KPI_TYPE.EKPI);
  isDTKPI = computed(() => this.kpiTypeId() === Constants.KPI_TYPE.DTKPI);
  isMTKPI = computed(() => this.kpiTypeId() === Constants.KPI_TYPE.MTKPI);
  isGSKPI = computed(() => this.kpiTypeId() === Constants.KPI_TYPE.GSKPI);
  affectedEntities = computed(() => (this.stagedKpi()?.affectedEntities ?? []) as AffectedEntityKpi[]);

  ekpiAffectedEntitiesWasRemoved = computed(() => {
    if (!this.isEKPI()) return false;

    const kpiEntityIds = ((this.kpi()?.affectedEntities ?? []) as AffectedEntityKpi[]).map((e) => e.entityId);
    const stagingkpiEntityIds = this.affectedEntities().map((e) => e.entityId);

    return kpiEntityIds.some((e) => !stagingkpiEntityIds.includes(e));
  });

  readonly entityIsPmo = () => AdaaHelper.isPMOEntity();
  readonly isDefined = AdaaHelper.isDefined.bind(AdaaHelper);

  readonly setKpiName = (data: { nameAE: string; nameEN: string }) => {
    const nameAE = data?.nameAE;
    const nameEN = data?.nameEN;

    let str: string | undefined;

    if (this.languageService.current() === Language.English && AdaaHelper.isDefinedAndNotEmpty(nameEN)) {
      str = nameEN;
    } else if (this.languageService.current() === Language.English && !AdaaHelper.isDefinedAndNotEmpty(nameEN)) {
      str = nameAE;
    } else if (this.languageService.current() === Language.Arabic && AdaaHelper.isDefinedAndNotEmpty(nameAE)) {
      str = nameAE;
    } else if (this.languageService.current() === Language.Arabic && !AdaaHelper.isDefinedAndNotEmpty(nameAE)) {
      str = nameEN;
    }

    this.kpiName.set(str);
  };
  readonly fetchKpi = () => {
    const kpiId = this.kpiId() as number;

    return this._kpisApiService.getById({
      id: kpiId,
    });
  };

  readonly #CONSTANT_AVERAGE = 25;
  readonly #floatActions = editPageFloatActions();
  readonly #untilDestroy = AdaaHelper.untilDestroyed();

  recalculateModalRef: NgbModalRef;

  public ngOnInit() {
    this._refreshPage();
    this._fetchValidations();
    this._fetchTextMaps();
  }

  public initPage(res?: MainResponseModel<unknown>) {
    const data = res?.responseData;
    const workflow = data as { wfProcessCtlId: number | null };

    if (AdaaHelper.isDefined(workflow?.wfProcessCtlId)) {
      this._toastrService.warning(this.languageService.translate("notification.error.object_in_approval"));
      this.goBack(false);

      return;
    }

    setTimeout(() => this._setKpiTitleInfo());

    if (this.pageMode() === PageMode.edit) {
      const kpi = data as Record<string, unknown>;

      kpi.formulaWasChanged = false;

      this.kpi.set(undefined);
      this.stagedKpi.set(undefined);

      this.kpi.set(kpi);
      this.stagedKpi.set(kpi);

      if (this.isNTKPI() || this.isDTKPI()) {
        this.stagedKpi.update((data) => ({ ...data, directionId: this.stagedKpi()?.govDirectionId }));
      }

      this.#floatActions(PageMode.edit, {
        id: kpi.id as number,
        kpiType: kpi.kpiType as number,
      });

      this.kpiEditorTabs.init({
        ...(data as EditorTabInputType),
        pageMode: this.pageMode(),
        kpiType: this.kpiTypeId(),
      });
    } else {
      this.kpiEditorTabs.init({
        pageMode: this.pageMode(),
        kpiType: this.kpiTypeId(),
        baselineKpi: AdaaBoolean.N,
      });

      this.#floatActions(PageMode.create);
    }

    this._fetchExtendedFields();
  }

  public goBack(showModal = true) {
    if (showModal) {
      const data = this._prepareDataSubmission(false);

      if (this.pageMode() === PageMode.edit && this._doesFormHaveAnyChanges(data)) {
        const $m = this._confirmGoBack();
        $m.result.then(($e) => {
          if ($e) {
            this.router.navigate(["/console", "kpi", this.kpiType()]);
          }
        });
        return;
      }
      if (this.pageMode() === PageMode.create && this._isDirtyOnCreate()) {
        const $m = this._confirmGoBack();
        $m.result.then(($e) => {
          if ($e) {
            this.router.navigate(["/console", "kpi", this.kpiType()]);
          }
        });
        return;
      }

      this.router.navigate(["/console", "kpi", this.kpiType()]);
    } else {
      this.router.navigate(["/console", "kpi", this.kpiType()]);
    }
  }

  public handleInfoTabFormChanges($event: Record<string, unknown>) {
    this.stagedKpi.update((data) => ({
      ...data,
      ...($event as Record<string, unknown>),
      startDate: AdaaHelper.getDubaiTime($event.startDate as number),
      endDate: AdaaHelper.getDubaiTime($event.endDate as number),
    }));

    this.setKpiName($event as { nameAE: string; nameEN: string });

    this.timestamp.update((ts) => {
      if (!ts) return ts;
      const { startDate, endDate } = $event as { startDate: number; endDate: number };
      if (AdaaHelper.isDefined(startDate)) return { ...ts, start: AdaaHelper.getDubaiTime(startDate) };
      if (AdaaHelper.isDefined(endDate)) return { ...ts, end: AdaaHelper.getDubaiTime(endDate) };
      return ts;
    });

    this.kpiEditorTabs.generateTabs({
      startDate: this.stagedKpi()?.startDate as number,
      baselineYear: this.stagedKpi()?.baselineYear as number,
      govDirectionId: this.stagedKpi()?.govDirectionId as number | undefined,
      directionId: this.stagedKpi()?.directionId as number | undefined,
    });

    const { years: year } = AdaaHelper.getDubaiTimeAsObject(this.stagedKpi()?.startDate as number);
    this.stagedKpi.update((data) => ({
      ...data,
      baselineYear: year,
    }));
  }

  public handleMeasurementDetailsFormChanges($event: Record<string, unknown>) {
    delete $event.defaultFormulaOption;

    if (!("ytpCalc" in $event) || !AdaaHelper.isDefined($event.ytpCalc)) {
      $event = { ...$event, ytpCalc: this.#CONSTANT_AVERAGE };
    }

    this.stagedKpi.update((data) => {
      const object = { ...data, ...($event as Record<string, unknown>) };
      if (data?.formulaStatus) data.formulaStatus = object.formulaStatus;
      return object;
    });

    this.kpiEditorTabs.generateTabs({
      baselineKpi: this.stagedKpi()?.baselineKpi as AdaaBoolean | undefined,
      baselineYear: this.stagedKpi()?.baselineYear as number | undefined,
    });
  }

  public handleAttachmentsFormChanges($event: AttachmentModelType[]) {
    this.stagedKpi.update((data) => ({ ...data, attachments: $event }));
  }

  public handleBenchmarkFormChanges($event: BenchmarkModelType[]) {
    this.stagedKpi.update((data) => ({ ...data, benchmarks: $event }));
  }

  public handleDataInputChanges(event: AffectedEntityKpi[]) {
    this.stagedKpi.update((data) => ({ ...data, affectedEntities: event }));
  }

  public handleImprovementPlanChanges($event: NationalTargetImprovementPlanType[]) {
    this.stagedKpi.update((data) => ({ ...data, nationalTargetImprovementPlans: $event }));
  }

  public submit(isFinish: boolean) {
    if (this.pageMode() === PageMode.create) {
      this._createKpi(isFinish);
    } else {
      if (this.stagedKpi()?.formulaWasChanged && this.kpi()?.status === ObjectStatus.ACTIVE) {
        this._showRecalculateModal(isFinish);
      } else {
        this._editKpi(isFinish);
      }
    }
  }

  private _confirmGoBack() {
    const modal = this._modalService.open(ConfirmationModalComponent, {
      centered: true,
      size: "md",
      modalDialogClass: this.languageService.modalDirection(),
    });

    modal.componentInstance.title = "common.form.modals.cancel_yes_no_information";
    modal.componentInstance.header = "common.form.modals.cancel_yes_no_title";
    modal.componentInstance.subTitle = "common.form.label.are_you_sure";

    return modal;
  }

  private _fetchExtendedFields() {
    const id = this.kpiId();

    this._extendedFieldsService.hasExtendedFields(this.kpiTypeId(), id ? Number(id) : undefined).subscribe({
      next: (enabled) => {
        const action = enabled ? "enableTab" : "disableTab";
        this.kpiEditorTabs[action]("extendedFields");
      },
    });
  }

  private _fetchValidations() {
    const key = this.kpisService.getValidationKey(this.kpiTypeId());

    this._validatorApiService
      .searchByKey(key)
      .pipe(
        filter((res) => !res.inError),
        map((res) => res.responseData?.parameterCatalogs ?? [])
      )
      .subscribe({
        next: (catalog) => this.validations.set([...catalog]),
      });
  }

  private _fetchTextMaps() {
    this._textMappingsApiService
      .getAllByAvailability()
      .pipe(
        filter((res) => !res.inError),
        map((res) => res.responseData)
      )
      .subscribe({
        next: (data) => this.textMaps.set(data ?? []),
      });
  }

  private _refreshPage() {
    const editRedirection = () => {
      this.router.navigate(["/console", "kpi", this.kpiType()], { replaceUrl: true, onSameUrlNavigation: "reload" });
    };

    this._systemLayoutService.hasActiveEntityChanged$.pipe(this.#untilDestroy()).subscribe({
      next: () => {
        if (this.pageMode() === PageMode.edit) {
          editRedirection();
        } else {
          this.isEKPI() ? editRedirection() : window.location.reload();
        }
      },
    });

    this._systemLayoutService.hasCycleChanged$.pipe(this.#untilDestroy()).subscribe({
      next: () => {
        if (this.pageMode() === PageMode.edit) {
          editRedirection();
        } else {
          window.location.reload();
        }
      },
    });
  }

  private _setKpiTitleInfo() {
    const rawTitle = this.kpisService.getTableTitle(
      this.kpiTypeId(),
      this.pageMode() === "create" ? "new" : "edit",
      true
    );
    const title = this.languageService.translate(rawTitle);

    this._appTitleService.setTitle(title, true);
    this._breadcrumbService.set("@kpiEditor", title);
    this._breadcrumbService.set("@kpisList", this.kpisService.getTableTitle(this.kpiTypeId()!, "list"));
  }

  private _showRecalculateModal(isFinish: boolean) {
    const tpl = this.recalculateModal();
    this.recalculateModalRef = this._modalService.open(tpl, {
      centered: true,
      modalDialogClass: `modal-${this.languageService.direction()}`,
    });

    this.recalculateModalRef.result.then(
      () => {
        this.measurementDetailsTab().model.controls["recalculate"]?.setValue(AdaaBoolean.Y);
        this._editKpi(isFinish);
      },
      () => {
        this.measurementDetailsTab().model.controls["recalculate"]?.setValue(null);
      }
    );
  }

  private _appendAttributes() {
    const attrs = this.extFieldsTab().submit();

    if (this.pageMode() === PageMode.create) {
      return attrs.map((data) => ({
        attrId: data.id,
        dataType: data.dataType,
        itemType: this.kpiTypeId(),
        nameAE: data.nameAE,
        nameEN: data.nameEN,
        status: ObjectStatus.DRAFT,
        itemId: undefined,
        ...getFieldValueObject(data.dataType, data.value),
      }));
    }

    const result: ExtendedFieldAttributeModelType[] = [];
    const extendedFields = (this.kpi()?.extendedFields ?? []) as ExtendedFieldAttributeModelType[];

    // Removed
    for (const item of extendedFields) {
      const found = attrs.find(($i) => $i.id === item.attrId);
      if (!found) {
        result.push({ ...item, status: Constants.OBJECT_STATUS.REMOVE });
      }
    }

    // Added
    for (const data of attrs) {
      const found = extendedFields.find(($i) => $i.attrId === data.id);
      if (!found) {
        result.push({
          attrId: data.id,
          dataType: data.dataType,
          itemType: this.kpiTypeId(),
          nameAE: data.nameAE,
          nameEN: data.nameEN,
          status: ObjectStatus.DRAFT,
          itemId: undefined,
          ...getFieldValueObject(data.dataType, data.value),
        });
      }
    }

    // Changed
    for (const data of attrs) {
      const found = extendedFields.find(($i) => $i.attrId === data.id);
      if (found) {
        result.push({ ...found, ...getFieldValueObject(data.dataType, data.value) });
      }
    }

    return result;
  }

  private _createKpi(isFinish: boolean) {
    this.kpiEditorTabs.resetTabErrors();

    if (!isFinish) {
      this.infoTabForm().model.get("nameEN")?.markAsTouched({ onlySelf: true });
      this.infoTabForm().model.get("nameAE")?.markAsTouched({ onlySelf: true });
      if (this.infoTabForm().model.get("nameAE")?.invalid || this.infoTabForm().model.get("nameEN")?.invalid) {
        this.kpiEditorTabs.tabHasError("info");
      }
    } else {
      this._handleErrors();
    }

    const data = this._prepareDataSubmission(isFinish);

    if (this.kpiEditorTabs.hasError()) {
      this._toastrService.warning(this.languageService.translate("notification.warning.missing_info"));
      return;
    }

    this._kpisApiService.create(data, isFinish).subscribe({
      next: (res) => {
        this._toastrService.success(this.languageService.translate("notification.success.save"));

        if (!isFinish) {
          const id = Number(res.responseData);

          return this.router.navigateByUrl(this.kpisService.getEditPageRoute(this.kpiTypeId(), id));
        }

        return this.goBack(false);
      },
    });
  }

  private _editKpi(isFinish: boolean) {
    this.kpiEditorTabs.resetTabErrors();

    // Reset contributing entities errors. Avoiding unnecessary errors on the form
    if (this.isDTKPI() || this.isNTKPI()) {
      this.infoTabForm()?.model.controls["contributingEntities"]?.setErrors(null);
      this.infoTabForm()?.model.controls["otherContributingEntities"]?.setErrors(null);
    }

    if (!isFinish) {
      this.infoTabForm().model.get("nameEN")?.markAsTouched({ onlySelf: true });
      this.infoTabForm().model.get("nameAE")?.markAsTouched({ onlySelf: true });
      if (this.infoTabForm().model.get("nameAE")?.invalid || this.infoTabForm().model.get("nameEN")?.invalid) {
        this.kpiEditorTabs.tabHasError("info");
      }
    } else {
      this._handleErrors();
    }

    const data = this._prepareDataSubmission(isFinish);

    if (this.kpiEditorTabs.hasError()) {
      this._toastrService.warning(this.languageService.translate("notification.warning.missing_info"));
      return;
    }

    if (this.ekpiAffectedEntitiesWasRemoved()) {
      this._showEkpiAffectedEntitiesWarning(data, isFinish);
      return;
    }

    if (this.pageMode() === PageMode.edit && isFinish && this.kpi()?.status === ObjectStatus.DRAFT) {
      this._sendUpdateRequest(data, isFinish);
    } else {
      if (!this._doesFormHaveAnyChanges(data)) {
        this._toastrService.warning(this.languageService.translate("notification.warning.no_data_to_modify"));
        return;
      }

      this._sendUpdateRequest(data, isFinish);
    }
  }

  private _sendUpdateRequest(data: Record<string, unknown>, isFinish: boolean) {
    this._kpisApiService.update(data, isFinish).subscribe({
      next: (res) => {
        this._toastrService.success(this.languageService.translate("notification.success.save"));

        if (!isFinish) {
          const id = Number(res.responseData);
          const url = new URL(this.kpisService.getEditPageRoute(this.kpiTypeId(), id), window.location.origin);

          window.location.href = url.toString();
        }

        return this.goBack(false);
      },
    });
  }

  private _handleErrors() {
    this.measurementDetailsTab().model.markAllAsTouched();
    this.infoTabForm().model.markAllAsTouched();

    if (this.infoTabForm().model.invalid) {
      this.kpiEditorTabs.tabHasError("info");
    }

    if (this.measurementDetailsTab().model.invalid || this._formulaStatusInvalid()) {
      this.kpiEditorTabs.tabHasError("measurementDetails");
    }
  }

  private _formulaStatusInvalid(): boolean {
    const formula = this.measurementDetailsTab().model.get("formula")?.value;
    const formulaStatus = this.measurementDetailsTab().model.get("formulaStatus")?.value;

    if (formula === Constants.FORMULA_STRING.NOFORMULA) return false;

    return formulaStatus === AdaaBoolean.N;
  }

  private _prepareDataSubmission(reportError = true) {
    let data: Record<string, unknown> = {
      ...(this.pageMode() === PageMode.edit ? this.kpi() : {}),
      ...this.stagedKpi(),
      ...this._generalSubmission(),
      extendedFields: this._appendAttributes(),
    };

    if (!data.benchmarks) data.benchmarks = [];

    if (this.pageMode() === PageMode.create) {
      data = {
        ...data,
        clearMetrics: true,
        ...this._onCreateSubmission(data),
      };
    }

    data = {
      ...data,
      ...this._noFormulaSubmission(data),
    };

    data = {
      ...data,
      ...this._nationalTargetSubmission(data, reportError),
    };

    data = {
      ...data,
      ...this._targetSubmission(data, reportError),
    };

    data = {
      ...data,
      ...this._opmSubmission(data),
    };

    data = {
      ...data,
      dataRevisionId: useNewDS() ? 2 : 1,
    };

    data = {
      ...data,
      ...this._contributingEntitiesSubmission(data),
    };

    data = {
      ...data,
      ...this._mokpiSubmission(data),
    };

    data = {
      ...data,
      ...this._nationalTargetManagementSubmission(data),
    };

    data = {
      ...data,
      ...this._improvementPlanSubmission(data),
    };

    data = {
      ...data,
      ...this._internationalOrganizationSubmission(data),
    };

    //EKPIs don't have a sponsor entity
    if (this.isDTKPI() || this.isNTKPI()) {
      data = {
        ...data,
        sponsorEntityId: this.stagedKpi()?.sponsorEntityId,
      };
    }

    return data;
  }

  private _generalSubmission() {
    return {
      subkpis: [],
      scopeMeasureEN: this.stagedKpi()?.scopeMeasureAE,
      kpiType: this.kpiTypeId(),
      aggChildren: AdaaBoolean.N,
    };
  }

  private _nationalTargetManagementSubmission(data: Record<string, unknown>) {
    if (!this.isNTKPI()) return data;

    data = {
      ...data,
      entityLeadOptionRequests: this.nationalTargetManagement()?.submit(),
    };

    return data;
  }

  private _targetSubmission(data: Record<string, unknown>, reportError = true) {
    const instance = this.isMasterEKPI()
      ? (this.kpiTargetsForm() as MasterKpiTargetsComponent)
      : (this.kpiTargetsForm() as KpiTargetsComponent);

    try {
      const targets = instance.submitTargets(reportError);
      data = {
        ...data,
        ...targets,
      };
    } catch (_e) {
      if (reportError) {
        instance.showCellsWithError();
        this.kpiEditorTabs.tabHasError("targets");
      }
    }

    return data;
  }

  private _noFormulaSubmission(data: Record<string, unknown>) {
    if (data.formula !== Constants.FORMULA_STRING.NOFORMULA) return data;

    const kpi = this.stagedKpi();
    const metric = (!kpi?.metrics ? [] : (kpi.metrics as KpiMetricModelType[]))[0];

    if (metric) {
      data = {
        ...data,
        metrics: [
          {
            ...metric,
            nameEN: Constants.FORMULA_STRING.NOFORMULA,
            nameAE: Constants.FORMULA_STRING.NOFORMULA,
            metricType: null,
            dataType: kpi?.measurementUnit,
          },
        ],
      };

      return data;
    }

    data = {
      ...data,
      metrics: [
        {
          nameEN: Constants.FORMULA_STRING.NOFORMULA,
          nameAE: Constants.FORMULA_STRING.NOFORMULA,
          metricType: null,
          dataType: kpi?.measurementUnit,
          metricReadings: [],
          metricReadingsEnabler: [],
          dscEN: "",
          dscAE: "",
        },
      ],
    };

    return data;
  }

  private _internationalOrganizationSubmission(data: Record<string, unknown>) {
    if (!this.isNTKPI()) return data;

    const list = this.infoTabForm().submitInternationalOrganizations();
    if (this.pageMode() === PageMode.create) {
      data = {
        ...data,
        internationalOrganizations: list,
      };

      return data;
    } else if (this.pageMode() === PageMode.edit) {
      const datalist = (this.kpi()?.internationalOrganizations ?? []) as InternationalOrganizationType[];

      // remove
      for (const item of datalist) {
        const found = list.find(({ organizationId }) => item.organizationId === organizationId);
        if (!found) {
          list.push({
            ...item,
            status: ObjectStatus.REMOVE,
          } as unknown as NewInternationalOrgType);
        }
      }
    }

    data = {
      ...data,
      internationalOrganizations: list,
    };

    return data;
  }

  private _contributingEntitiesSubmission(data: Record<string, unknown>) {
    if (!this.isNTKPI() && !this.isDTKPI()) return data;

    const contributingEntities = this.infoTabForm().submitContributingEntities();
    const otherContributingEntities = this.infoTabForm().submitOtherContributingEntities();

    if (this.pageMode() === PageMode.create) {
      data = {
        ...data,
        contributingEntities: [...contributingEntities, ...otherContributingEntities],
        otherContributingEntities,
      };
    } else if (this.pageMode() === PageMode.edit) {
      const list = this.kpi()?.contributingEntities as ContributingEntitiesDataType[];
      const sanitizedContributingEntities = list.filter(({ entityId }) => AdaaHelper.isDefined(entityId));
      const sanitizedOtherContributingEntities = list.filter(({ otherEntityId }) =>
        AdaaHelper.isDefined(otherEntityId)
      );

      // removed other contributing entities
      if (sanitizedOtherContributingEntities.length > 0) {
        for (const item of sanitizedOtherContributingEntities) {
          const found = otherContributingEntities.find(({ otherEntityId }) => item.otherEntityId === otherEntityId);

          if (!found) {
            otherContributingEntities.push({
              ...item,
              status: ObjectStatus.REMOVE,
            });
          }
        }
      }

      // removed contributing entities;
      if (sanitizedContributingEntities.length > 0) {
        for (const item of list) {
          const found = contributingEntities.find(({ entityId }) => item.entityId === entityId);

          if (!found) {
            contributingEntities.push({
              ...item,
              status: ObjectStatus.REMOVE,
            });
          }
        }
      }

      const results = [...contributingEntities, ...otherContributingEntities];
      const sanitizedResults = results.filter(({ status }) => status !== ObjectStatus.REMOVE);

      // Checking for errors
      const isRequired = (this.isDTKPI() || this.isNTKPI()) && !AdaaHelper.isPMOEntity() && this.pageMode() === "edit";
      if (isRequired && sanitizedResults.length === 0) {
        this.kpiEditorTabs.tabHasError("info");
        this.infoTabForm()?.model.controls["contributingEntities"]?.setErrors({ required: true });
        this.infoTabForm()?.model.controls["otherContributingEntities"]?.setErrors({ required: true });
        return data;
      }

      data = {
        ...data,
        contributingEntities: results,
        otherContributingEntities,
      };
    }

    return data;
  }

  private _onCreateSubmission(data: Record<string, unknown>) {
    if (this.pageMode() === PageMode.create) {
      data = {
        ...data,
        status: null,
        wfStatus: null,
        targetIsValid: true,
        targetHasChange: false,
        targetsInReview: false,
        flgDimensionsChanged: false,
      };
    }

    return data;
  }

  private _improvementPlanSubmission(data: Record<string, unknown>) {
    if (!this.isNTKPI()) return data;

    try {
      data = {
        ...data,
        nationalTargetImprovementPlans: this.improvementPlan()?.submit(),
      };
    } catch (_e) {
      this.kpiEditorTabs.tabHasError("improvementPlan");
    }

    return data;
  }

  private _mokpiSubmission(data: Record<string, unknown>, reportError = true) {
    if (!this.isMOKPI()) return data;
    const disableMainOutcomeType = this.infoTabForm()?.disableMainOutcomeType();
    const isTransformationalTargetSelected = this.infoTabForm().isTransformationalTargetSelected();

    // In edit page. MainOutcomeType was change from T.Target to something else
    if (this.pageMode() === PageMode.edit && !isTransformationalTargetSelected && !disableMainOutcomeType) {
      if (AdaaHelper.isDefined(this.kpi()?.transformationalTarget)) {
        return {
          ...data,
          transformationalTarget: {
            ...(this.kpi()?.transformationalTarget as Record<string, unknown>),
            status: ObjectStatus.REMOVE,
          },
        };
      }
    }

    // If T.Target is the main-outcome but no T.Target is selected
    if (isTransformationalTargetSelected && !AdaaHelper.isDefined(data.transformationalTarget)) {
      if (reportError) {
        this.kpiEditorTabs.tabHasError("info");
        this.infoTabForm()?.model.controls["transformationalTarget"]?.setErrors({ required: true });
        return data;
      }
    }

    // In create page. MainOutcomeType is T.Target
    if (this.pageMode() === PageMode.create && isTransformationalTargetSelected) {
      return {
        ...data,
        transformationalTarget: {
          id: data.transformationalTarget,
          status: ObjectStatus.ACTIVE,
        },
      };
    }

    /**
     * In edit page. MainOutcomeType is T.Target
     * Check if new T.Target was selected and update T.Target
     * Else morph the T.Target from `id` to an T.Target object
     */
    if (this.pageMode() === PageMode.edit && isTransformationalTargetSelected) {
      const item = this.kpi()?.transformationalTarget as { id: number };
      if (item.id === data.transformationalTarget) {
        return {
          ...data,
          transformationalTarget: {
            ...item,
          },
        };
      }

      return {
        ...data,
        transformationalTarget: {
          id: data.transformationalTarget,
          status: ObjectStatus.ACTIVE,
        },
      };
    }

    return data;
  }

  private _nationalTargetSubmission(data: Record<string, unknown>, reportError = true) {
    if (this.kpiEditorTabs.isTabAvailable("links")) {
      try {
        const links = this.nationalTargetForm()?.handleSubmit();

        if (links?.ntkpiId) {
          data = {
            ...data,
            ntKpi: {
              id: links?.ntkpiId,
              nameAE: links?.ntkpiObject?.nameAE,
              nameEN: links?.ntkpiObject?.nameEN,
              status: links?.ntkpiObject?.status,
            },
          };
        }
      } catch (_e) {
        if (reportError) this.kpiEditorTabs.tabHasError("links");
      }
    }

    return data;
  }

  private _opmSubmission(data: Record<string, unknown>) {
    if (!this.isOPM()) return data;
    return {
      ...data,
    };
  }

  private _showEkpiAffectedEntitiesWarning(data: Record<string, unknown>, isFinish: boolean) {
    const modal = this._modalService.open(ConfirmationModalComponent, {
      centered: true,
      size: "md",
      modalDialogClass: this.languageService.modalDirection(),
    });

    modal.componentInstance.header = "ekpi.affectedEntities";
    modal.componentInstance.title = "kpi.affected_information";

    modal.result.then((e) => {
      if (e) this._sendUpdateRequest(data, isFinish);
    });
  }

  private _doesFormHaveAnyChanges(model: FormModelObjectType) {
    if (this.dataInput()?.isDirty()) return true;

    return this._formDirtyCheckerService.isDirty(model, this.kpi()!, {
      fieldsToCheckStatus: ["attachments", "benchmarks", "contributingEntities", "otherContributingEntities"],
      fieldsWithCustomChecks: [
        {
          key: "targets",
          fn: this._checkForInequalityInTargets.bind(this),
        },
      ],
      fieldsToIgnore: [
        "formulaWasChanged",
        "targetWasChanged",
        "clearMetrics",
        "dataRevisionId",
        "auxAffectedEntities",
        "affectedEntitiesIds",
        "directionId",
        "otherContributingEntities",
        "accountableEntityId",
        "scopeMeasureEN",
      ],
    });
  }

  private _checkForInequalityInTargets($new: FormModelObjectType[], $old: FormModelObjectType[]) {
    if ($new.length !== $old.length) return false;

    const kpi = this.kpi()!;
    const kpiIsBounded = this.kpiIsBounded();
    const isAllowed = () => AdaaHelper.entity?.id === kpi.enablerEntityId || AdaaHelper.isPMOEntity();
    const comparison = ($n: FormModelObjectType, $o: FormModelObjectType) => {
      if (kpiIsBounded) {
        if ($n.lowerLimit === $o.lowerLimit) return false;
        if ($n.highLimit === $o.highLimit) return false;
      } else {
        if ($n.value !== $o.value) return false;
      }
      return true;
    };

    if (this.isEKPI() && isAllowed()) {
      if ($new.length !== $old.length) return false;

      for (const index in $new) {
        if (!(index in $old)) return false;
        if (!("entities" in $new[index]) || !("entities" in $old[index])) return false;

        // Check GOV Targets
        if (!comparison($new[index], $old[index])) return false;

        const newEntities = ($new[index] as Record<string, unknown>).entities as Record<string, unknown>[];
        const oldEntities = ($old[index] as Record<string, unknown>).entities as Record<string, unknown>[];

        if (newEntities.length !== oldEntities.length) return false;

        for (const i in newEntities) {
          if (!(i in oldEntities)) return false;
          if (!comparison(newEntities[i], oldEntities[i])) return false;
        }
      }

      return true;
    }

    const newTargets = $new as Record<string, unknown>[];
    const oldTargets = $old as Record<string, unknown>[];

    for (const index in newTargets) {
      if (!(index in oldTargets)) return false;
      if (!comparison(newTargets[index], oldTargets[index])) return false;
    }

    return true;
  }

  private _isDirtyOnCreate() {
    const isInfoTabFormDirty = this.infoTabForm().model.dirty;
    const isMeasurementDetailsTab = this.measurementDetailsTab().model.dirty;
    const isNationalTargetManagement = this.nationalTargetManagement()?.form.dirty;
    const isExtFieldsTab = this.extFieldsTab().isDirty();
    const isKpiBenchmarkForm = this.kpiBenchmarkForm().isDirty();
    const isAttachmentList = this.attachmentList().isDirty();
    const isNationalTargetForm = this.nationalTargetForm()?.model.dirty;
    const isImprovementPlan = this.improvementPlan()?.isDirty();
    const isDataInput = this.dataInput()?.isDirty();
    const isKpiTargets = this.kpiTargetsForm()?.hasChanges();

    return (
      isInfoTabFormDirty ||
      isMeasurementDetailsTab ||
      isNationalTargetManagement ||
      isExtFieldsTab ||
      isKpiBenchmarkForm ||
      isAttachmentList ||
      isNationalTargetForm ||
      isImprovementPlan ||
      isDataInput ||
      isKpiTargets
    );
  }

  //TODO: REMOVE ME
  public printPayload(): void {
    const data = this._prepareDataSubmission(true);
    // eslint-disable-next-line no-console
    console.log("KPI PAYLOAD");
    // eslint-disable-next-line no-console
    console.log("________________");
    // eslint-disable-next-line no-console
    console.log(data);
  }
}
