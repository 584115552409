import { Component, computed, effect, inject, input } from "@angular/core";
import { ControlContainer, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";

import { AdaaHelper } from "../../../../../../../core/utils";
import { FormDropdownComponent } from "../../../../../../../shared/components";
import { EntityModelType, ValueText } from "../../../../../../../shared/models";

@Component({
  selector: "adaa-sponsor-entity-field",
  imports: [FormDropdownComponent, ReactiveFormsModule, TranslateModule],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true }),
    },
  ],
  template: `
    <adaa-form-dropdown
      [controlName]="FIELD_NAME"
      [formControlName]="FIELD_NAME"
      [clearable]="true"
      [searchable]="true"
      [label]="'nkpi.sponsorEntity' | translate"
      [options]="entityOptions()"
      [invalid]="model.get(FIELD_NAME)?.touched! && model.get(FIELD_NAME)?.invalid!"
      [required]="isRequired()"
    />
  `,
})
export class SponsorEntityFieldComponent {
  readonly form = inject(ControlContainer);

  isRequired = input.required<boolean>();
  entities = input<EntityModelType[]>([]);
  isDisabled = input<boolean>(false);

  entityOptions = computed<ValueText[]>(() =>
    AdaaHelper.setDropdownArray(this.entities(), "id", AdaaHelper.getFieldLanguage("name"))
  );

  readonly FIELD_NAME = "sponsorEntityId";

  public get model() {
    return this.form.control as FormGroup;
  }

  constructor() {
    effect((onCleanup) => {
      const disable = this.isDisabled();
      if (disable) {
        this.model.get(this.FIELD_NAME)?.disable();
      } else {
        this.model.get(this.FIELD_NAME)?.enable();
      }

      onCleanup(() => {
        this.model.controls[this.FIELD_NAME]?.enable({ onlySelf: true, emitEvent: true });
      });
    });
  }
}
