import { FormControl, FormGroup, Validators } from "@angular/forms";

import { AdaaBooleanType } from "../../../../../../adaa-types";
import { AdaaHelper } from "../../../../../core/utils";
import { Constants } from "../../../../../shared/constants/constants";
import { KpiMetricModelType, ParameterCatalog } from "../../../../../shared/models";

export const getRequiredValidator = (fields: ParameterCatalog[], field: string) =>
  AdaaHelper.isFieldRequired(fields, field) ? [Validators.required] : [];

export const isStartGreaterThanEnd = (start: number | null, end: number | null) => {
  if (!start || !end) return false;
  return start > end;
};

export const measurementUnitFormGroup = (fields: ParameterCatalog[]) =>
  new FormGroup({
    measurementUnit: new FormControl<number | null>(null, [...getRequiredValidator(fields, "measurementUnit")]),
    measurementUnitDscAE: new FormControl<string | null>(null, [
      ...getRequiredValidator(fields, "measurementUnitDscAE"),
      Validators.maxLength(Constants.FORM_FIELD_CONSTRAINTS.CHAR_MAXLENGTH),
    ]),
    frequency: new FormControl<number | null>(null, [...getRequiredValidator(fields, "frequency")]),
    trend: new FormControl<number | null>(null, [...getRequiredValidator(fields, "trend")]),
    baselineKpi: new FormControl<AdaaBooleanType | null>(null, [...getRequiredValidator(fields, "baselineKpi")]),
    baselineYear: new FormControl<number | null>(null, [...getRequiredValidator(fields, "baselineYear")]),
    _formula: new FormControl<number | null>(null, [Validators.required]), //* will replace [formula: number]
    formulaWasChanged: new FormControl<boolean>(false, [Validators.required]),
    clearMetrics: new FormControl<boolean>(false, []),
    formula: new FormControl<string | null>(null, [Validators.required]),
    defaultFormulaOption: new FormControl<number | null>(null, [Validators.required]),
    hasTarget: new FormControl<AdaaBooleanType | null>(null, [Validators.required]),
    formulaStatus: new FormControl<AdaaBooleanType | null>(null, [Validators.required]),
    ytpCalc: new FormControl<number | null>(null, [...getRequiredValidator(fields, "ytpCalc")]),
    metrics: new FormControl<KpiMetricModelType[]>([]),
    recalculate: new FormControl<"Y" | null>(null, []),
    maxRank: new FormControl<number | null>(null, []),
  });
