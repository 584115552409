import { Component, inject, input, output, signal, TemplateRef, viewChild } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";

import { Language } from "../../../constants/enums";
import { ValueText } from "../../../models";
import { AppService } from "../../../services";
import {
  FormActionButtonsComponent,
  FormDropdownComponent,
  FormInputComponent,
  FormInputDateComponent,
} from "../../form";

@Component({
  selector: "adaa-attributes-value-input",
  standalone: true,
  imports: [
    FormInputComponent,
    FormActionButtonsComponent,
    TranslateModule,
    FormInputDateComponent,
    FormDropdownComponent,
  ],
  templateUrl: "./attributes-value-input.component.html",
  styleUrl: "./attributes-value-input.component.scss",
})
export class AttributesValueInputComponent {
  private _modalService = inject(NgbModal);
  private _appService = inject(AppService);

  modal = viewChild.required<TemplateRef<unknown>>("content");
  records = input<number | string>();
  fieldType = input<"text" | "dropdown" | "date">("text");
  dropdownData = input<ValueText[]>();
  defaultValue = output<string>();
  value = signal<string>("");

  public open() {
    const modalRef: NgbModalRef = this._modalService.open(this.modal(), {
      animation: true,
      scrollable: false,
      keyboard: false,
      size: "lg",
      centered: true,
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });

    modalRef.dismissed.subscribe(() => this.value.set(""));
    modalRef.closed.subscribe(() => this.value.set(""));
  }
}
