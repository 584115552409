import { DatePipe } from "@angular/common";
import { Component, input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

import { TranslateTokenPipe } from "../../../../../core/pipes";
import { ProgressIndicatorComponent } from "../../../../../shared/components";
import { PictureComponent } from "../../../../../shared/components/general/picture/picture.component";
import { Constants } from "../../../../../shared/constants/constants";
import type { NationalStrategyModelType } from "../../../../../shared/models";

@Component({
  selector: "adaa-strategy-card",
  standalone: true,
  templateUrl: "./strategy-card.component.html",
  styleUrl: "./strategy-card.component.scss",
  imports: [TranslateModule, TranslateTokenPipe, DatePipe, ProgressIndicatorComponent, PictureComponent],
})
export class StrategyCardComponent {
  strategy = input.required<NationalStrategyModelType>();
  showAllStrategies = input<boolean>(false);

  public getColor(code: "COMPLETED" | "ON_GOING" | "DELAYED") {
    switch (code) {
      case "COMPLETED":
        return Constants.CONSTANT_COLORS.EVALUATION_LIME;
      case "ON_GOING":
        return Constants.CONSTANT_COLORS.EVALUATION_BLUE;
      case "DELAYED":
        return Constants.CONSTANT_COLORS.EVALUATION_GRAY;
    }
  }
}
