<ng-template #content let-modal>
    <div class="modal-header">
        <h5 class="text-center fw-bold w-100">{{ "common.tables.location" | translate }}</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body overflow-x-hidden p-3">
        <form class="col-12" [formGroup]="addressForm">
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'entity.create.address_arabic' | translate"
                        formControlName="addressAE"
                        controlName="addressAE"
                        [setValidator]="addressesEditorService.formValidation()!"
                        [invalid]="checkInvalid('addressAE')"
                    ></adaa-form-input>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'entity.create.address_english' | translate"
                        formControlName="addressEN"
                        controlName="addressEN"
                        [setValidator]="addressesEditorService.formValidation()!"
                        [invalid]="checkInvalid('addressEN')"
                    ></adaa-form-input>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <adaa-form-input
                        [label]="'entity.create.zip_code_arabic' | translate"
                        formControlName="zipCodeAE"
                        controlName="zipCodeAE"
                        [setValidator]="addressesEditorService.formValidation()!"
                        [invalid]="checkInvalid('zipCodeAE')"
                    ></adaa-form-input>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'entity.create.city_arabic' | translate"
                        formControlName="cityAE"
                        controlName="cityAE"
                        [setValidator]="addressesEditorService.formValidation()!"
                        [invalid]="checkInvalid('cityAE')"
                    ></adaa-form-input>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'entity.create.city_english' | translate"
                        formControlName="cityEN"
                        controlName="cityEN"
                        [setValidator]="addressesEditorService.formValidation()!"
                        [invalid]="checkInvalid('cityEN')"
                    ></adaa-form-input>
                </div>
            </div>
            <div class="row my-3">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <div class="d-flex justify-content-center ms-1 map">
                        <google-map
                            [height]="300"
                            [width]="530"
                            [options]="options()"
                            (mapClick)="pageMode() !== PageMode.view && mapClicked($event)"
                        >
                            @for (position of markerPositions; track position.lat) {
                                <map-marker [position]="position" [options]="markerOptions" />
                            }
                        </google-map>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'entity.create.geo_x' | translate"
                        formControlName="geoX"
                        controlName="geoX"
                        [setValidator]="addressesEditorService.formValidation()!"
                        [invalid]="checkInvalid('geoX')"
                        [inputType]="'number'"
                    ></adaa-form-input>
                    <adaa-form-input
                        [label]="'entity.create.geo-y' | translate"
                        formControlName="geoY"
                        controlName="geoY"
                        [setValidator]="addressesEditorService.formValidation()!"
                        [invalid]="checkInvalid('geoY')"
                        [inputType]="'number'"
                    ></adaa-form-input>
                    <div class="my-3 d-flex justify-content-center">
                        <adaa-form-checkbox
                            [label]="'entity.create.hq' | translate"
                            [checkboxMode]="'yesNo'"
                            controlName="isHQ"
                            formControlName="isHQ"
                        ></adaa-form-checkbox>
                    </div>
                </div>
            </div>
        </form>
        <div class="my-2">
            <adaa-form-action-buttons
                [cancelButtonTitle]="
                    pageMode() === PageMode.view ? ('common.form.button.close' | translate) : ('common.form.button.cancel' | translate)
                "
                [showSaveButton]="pageMode() !== PageMode.view"
                (cancelClicked)="modal.close()"
                (saveClicked)="submit()"
            ></adaa-form-action-buttons>
        </div>
    </div>
</ng-template>
