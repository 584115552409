import { Component, EventEmitter, inject, input, OnInit, Output, signal } from "@angular/core";

import { AdaaHelper } from "../../../../../core/utils";
import { DataTableComponent } from "../../../../../shared/components";
import { Constants } from "../../../../../shared/constants/constants";
import { AffectedEntityKpi, TableInputs, TableValueListOutput } from "../../../../../shared/models";
import { PropertiesService } from "../../../../../shared/services";

@Component({
  selector: "adaa-data-input-tab",
  standalone: true,
  imports: [DataTableComponent],
  templateUrl: "./data-input-tab.component.html",
  styleUrl: "../styles.scss",
})
export class DataInputTabComponent implements OnInit {
  private _propertiesService = inject(PropertiesService);

  affectedEntities = input.required<AffectedEntityKpi[]>();

  @Output() affectedEntitiesChanged = new EventEmitter<AffectedEntityKpi[]>();

  constants = Constants;
  tableInputs: TableInputs[] = [];

  isDirty = signal<boolean>(false);

  public ngOnInit(): void {
    const ddlColumns = ["inputTargets", "approvalTargets", "inputActuals", "approvalActuals"];
    this._propertiesService.getPropById(Constants.CONSTANT_DATA_INPUT_TABLE).subscribe({
      next: (response) => {
        if (response.inError) return;

        const options = AdaaHelper.setDropdownArray(response.responseData, "id", AdaaHelper.getFieldLanguage("name"));
        ddlColumns.forEach((e) => this.tableInputs.push({ fieldName: e, options: options }));
      },
    });
  }

  public inputListChanged(values: TableValueListOutput[]): void {
    values.forEach((value) => {
      const affectedEntity = this.affectedEntities().find((e) => e.entityId === value.data.entityId);
      if (!affectedEntity) return;

      switch (value.fieldName) {
        case "approvalActuals":
          affectedEntity.approvalActuals = value.value;
          break;
        case "approvalTargets":
          affectedEntity.approvalTargets = value.value;
          break;
        case "inputActuals":
          affectedEntity.inputActuals = value.value;
          break;
        case "inputTargets":
          affectedEntity.inputTargets = value.value;
          break;
        default:
          break;
      }
    });

    this.isDirty.set(true);
    this.affectedEntitiesChanged.emit(this.affectedEntities());
  }
}
