import { Component, inject, OnInit, viewChild } from "@angular/core";
import { Router } from "@angular/router";

import { AdaaHelper } from "../../../core/utils";
import { DataTableComponent } from "../../../shared/components";
import { Constants } from "../../../shared/constants/constants";
import { TableButtonClicked } from "../../../shared/models";
import { KpisService, SystemLayoutService } from "../../../shared/services";

@Component({
  selector: "adaa-audit-issues",
  imports: [DataTableComponent],
  templateUrl: "./audit-issues.component.html",
  styleUrl: "./audit-issues.component.scss",
})
export class AuditIssuesComponent implements OnInit {
  private _router = inject(Router);
  private _systemLayoutService = inject(SystemLayoutService);
  private _kpisService = inject(KpisService);

  private _auditsTable = viewChild<DataTableComponent>("auditsTable");

  private readonly _untilDestroyed = AdaaHelper.untilDestroyed();
  Constants = Constants;

  public ngOnInit(): void {
    this._systemLayoutService.hasActiveEntityChanged$.pipe(this._untilDestroyed()).subscribe(() => {
      this._auditsTable()?.resetFilters();
    });

    this._systemLayoutService.hasCycleChanged$.pipe(this._untilDestroyed()).subscribe(() => {
      this._auditsTable()?.resetFilters();
    });
  }

  public actionClicked(action: TableButtonClicked): void {
    switch (action.event) {
      case "view":
        this._router.navigateByUrl(
          `/console/kpi/${this._kpisService.getKpiTypeString(action.data.kpiTypeId)}/${action.data.kpiId}`
        );
        break;
      case "edit":
        this._router.navigateByUrl(
          `/console/data-entry/kpi/edit/${action.data.kpiId}?redirectUrl=/console/kpi/audit-issues`
        );
        break;
      default:
        break;
    }
  }
}
