import { Component, inject, input, OnInit, signal } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { ConfirmationModalComponent, FormActionButtonsComponent, FormInputComponent } from "../../../shared/components";
import { FormInputFormulaComponent } from "../../../shared/components/form/form-input-formula/form-input-formula.component";
import { Constants } from "../../../shared/constants/constants";
import { AdaaBoolean, Language, PageMode } from "../../../shared/constants/enums";
import { Formula } from "../../../shared/models/formulas.model";
import { AppService, FormulasApiService } from "../../../shared/services";

@Component({
  selector: "adaa-formulas-template-editor",
  standalone: true,
  templateUrl: "./formulas-template-editor.component.html",
  styleUrl: "./formulas-template-editor.component.scss",
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    FormInputComponent,
    FormActionButtonsComponent,
    FormInputFormulaComponent,
  ],
})
export class FormulasTemplateEditorComponent implements OnInit {
  private _formBuilder = inject(FormBuilder);
  private _activatedRoute = inject(ActivatedRoute);
  private _modalService = inject(NgbModal);
  private _appService = inject(AppService);
  private _toastrService = inject(ToastrService);
  private _translateService = inject(TranslateService);
  private _formulasTemplateService = inject(FormulasApiService);
  private _router = inject(Router);

  formulasForm: FormGroup = new FormGroup({});
  formulaId: string;
  pageMode = input.required<string>();
  PageMode = PageMode;
  isFormulaValid = signal<boolean>(false);
  formula = signal<Formula>({
    entityId: null,
    formula: "",
    kpiType: null,
    nameAE: "",
    nameEN: "",
    dscAE: "",
    dscEN: "",
    enable: "",
    displayOrder: 0,
    id: 0,
    status: 0,
    updateTS: 0,
    updateUser: null,
  });

  public ngOnInit(): void {
    this.formulaId = this._activatedRoute.snapshot?.paramMap?.get("id") as string;
    this._prepareForm();
    if (this.formulaId) this._getFormula();
  }

  private _prepareForm() {
    const isViewMode = this.pageMode() === PageMode.view;

    this.formulasForm = this._formBuilder.group({
      nameAE: this._formBuilder.control<string | undefined>(
        { value: "", disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      nameEN: this._formBuilder.control<string | undefined>(
        { value: "", disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      dscAE: this._formBuilder.control<string | undefined>(
        { value: "", disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      dscEN: this._formBuilder.control<string | undefined>(
        { value: "", disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      formula: this._formBuilder.control<string | undefined>(
        { value: "", disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
    });
  }

  private _getFormula() {
    this._formulasTemplateService.getFormula(this.formulaId).subscribe({
      next: (response) => {
        this.formula.set(response.responseData);
        this.formulasForm.patchValue(response.responseData);
        this.formulasForm.markAsPristine();
        this.formulasForm.markAsUntouched();
      },
    });
  }

  public saveFormula() {
    if (!this.isFormulaValid() || !this.formulasForm.controls["formula"].value) {
      this._toastrService.error(this._translateService.instant("notification.warning.formula_validation"));
      return;
    }
    if (this.pageMode() === PageMode.edit) {
      const result = {
        ...this.formula(),
        ...this.formulasForm.value,
      };
      this._formulasTemplateService.updateFormula(result).subscribe({
        next: () => {
          this._toastrService.success(this._translateService.instant("notification.success.save"));
          this._router.navigateByUrl("/console/formulas");
        },
      });
    } else {
      const result = {
        ...this.formulasForm.value,
        status: Constants.OBJECT_STATUS.ACTIVE,
        enable: AdaaBoolean.Y,
      };
      this._formulasTemplateService.createFormula(result).subscribe({
        next: (response) => {
          if (response.inError) return;
          this._toastrService.success(this._translateService.instant("notification.success.save"));
          this._router.navigateByUrl("/console/formulas");
        },
      });
    }
  }

  public cancelForm() {
    if (this.formulasForm.dirty) {
      const modal = this._modalService.open(ConfirmationModalComponent, {
        centered: true,
        size: "md",
        modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
      });

      modal.componentInstance.header = "common.form.modals_leave.cancel_yes_no_title";
      modal.componentInstance.title = "common.form.modals_leave.cancel_yes_no_information";

      modal.result.then((e) => {
        if (e) this._router.navigateByUrl(`/console/formulas`);
      });
    } else this._router.navigateByUrl(`/console/formulas`);
  }
}
