import { Component, computed, effect, inject, input, OnInit, output } from "@angular/core";
import { ControlContainer, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { distinctUntilChanged, filter } from "rxjs";

import { AdaaHelper } from "../../../../../../../core/utils";
import { FormDropdownComponent } from "../../../../../../../shared/components";
import { ValueText } from "../../../../../../../shared/models";

@Component({
  selector: "adaa-gov-directions-field",
  imports: [FormDropdownComponent, ReactiveFormsModule, TranslateModule],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true }),
    },
  ],
  template: `
    <adaa-form-dropdown
      [controlName]="field()"
      [formControlName]="field()"
      [clearable]="true"
      [searchable]="true"
      [label]="label() | translate"
      [options]="listOptions()"
      [invalid]="model.get(field())?.touched! && model.get(field())?.invalid!"
      [required]="isRequired()"
      (onClear)="selectedValue.emit($event)"
    />
  `,
})
export class GovDirectionsFieldComponent implements OnInit {
  readonly form = inject(ControlContainer);

  field = input.required<string>();
  label = input.required<string>();
  isRequired = input.required<boolean>();
  isDisabled = input<boolean>(false);
  list = input<unknown[]>([]);

  listOptions = computed<ValueText[]>(() =>
    AdaaHelper.setDropdownArray(this.list(), "id", AdaaHelper.getFieldLanguage("name"))
  );

  selectedValue = output<number>();

  readonly #untilDestroy = AdaaHelper.untilDestroyed();

  public get model() {
    return this.form.control as FormGroup;
  }

  constructor() {
    effect((onCleanup) => {
      const disable = this.isDisabled();
      if (disable) {
        this.model.get(this.field())?.disable();
      } else {
        this.model.get(this.field())?.enable();
      }

      onCleanup(() => {
        this.model.get(this.field())?.enable();
      });
    });
  }

  public ngOnInit() {
    this.model
      .get(this.field())
      ?.valueChanges.pipe(
        this.#untilDestroy(),
        distinctUntilChanged(),
        filter((value) => AdaaHelper.isDefined(value))
      )
      .subscribe({
        next: (val) => this.selectedValue.emit(val),
      });
  }
}
