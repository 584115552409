import { Component, computed, inject, OnInit, signal } from "@angular/core";
import { Router } from "@angular/router";
import { NgbPopoverModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";

import { AdaaHelper } from "../../../../core/utils";
import {
  DoughnutComponent,
  FormCheckboxComponent,
  FormDropdownComponent,
  ProgressIndicatorComponent,
  RibbonComponent,
  TpBarsComponent,
} from "../../../../shared/components";
import { Constants } from "../../../../shared/constants/constants";
import { Language, TpAgmStatus } from "../../../../shared/constants/enums";
import { AgmDashboardService, SystemLayoutService } from "../../../../shared/services";

@Component({
  selector: "adaa-agm-dashboard",
  imports: [
    RibbonComponent,
    FormDropdownComponent,
    FormCheckboxComponent,
    DoughnutComponent,
    ProgressIndicatorComponent,
    TpBarsComponent,
    TranslateModule,
    NgbPopoverModule,
  ],
  templateUrl: "./agm-dashboard.component.html",
  styleUrl: "./agm-dashboard.component.scss",
})
export class AgmDashboardComponent implements OnInit {
  private _translateService = inject(TranslateService);
  private _router = inject(Router);
  private _systemLayoutService = inject(SystemLayoutService);
  agmService = inject(AgmDashboardService);

  private _untilDestroyed = AdaaHelper.untilDestroyed();

  AdaaHelper = AdaaHelper;
  Constants = Constants;
  TpAgmStatus = TpAgmStatus;
  lang = signal<Language>(AdaaHelper.getCurrentLang());
  Language = Language;

  projectStatusHelp = {
    getText: () =>
      AdaaHelper.replaceTextWithToken.iterator(
        this._translateService.instant(`national_projects.messages.tp_dashboard`),
        [{ unit: this.agmService.dashboardData()?.delayedThreshold ?? "", str: "delayed_threshold" }]
      ),
    activated: false,
  };

  dashboardWidgets = computed(() => {
    const items = [
      {
        label: "annual_meetings.page_title",
        data: this.agmService.dashboardData()?.annualMeetingCount,
        icon: "adaa-icon-national_projects",
      },
      {
        label: "national_projects.milestones",
        data: this.agmService.dashboardData()?.milestoneCount,
        icon: "adaa-icon-milestones",
      },
      {
        label: "national_projects.allocated_budget",
        data: AdaaHelper.amountToString(this.agmService.dashboardData()?.budget),
        symbol: AdaaHelper.getCurrencySymbol(this.agmService.dashboardData()?.budget, true),
        icon: "adaa-icon-allocated_budget",
      },
    ];

    if (AdaaHelper.isPMOEntity())
      items.push({
        label: "national_projects.number_of_sectors",
        data: this.agmService.dashboardData()?.usedNationalSectorCount,
        icon: "adaa-icon-console_sectors",
      });
    else
      items.push({
        label: "national_projects.actual_expenditure",
        data: AdaaHelper.amountToString(this.agmService.dashboardData()?.expense),
        symbol: AdaaHelper.getCurrencySymbol(this.agmService.dashboardData()?.expense, true),
        icon: "adaa-icon-actual",
      });

    return items;
  });

  public ngOnInit(): void {
    this.agmService.dashboardFilters.filterBy = undefined;

    this._initPage();

    this._systemLayoutService.hasActiveEntityChanged$.pipe(this._untilDestroyed()).subscribe(() => {
      this._initPage();
    });
  }

  public cycleChanged(cycle: number) {
    this.agmService.dashboardFilters.cycleId = cycle;
    this.agmService.dashboardData.set(undefined);
    this.agmService.resetFilters(true, false);
    this.agmService.getData();
  }

  public onlyEntityChanged(value: boolean) {
    this.agmService.dashboardFilters.onlyProjectsOwnedByEntity = value;
    this.agmService.getData();
  }

  public navigateToProject(id: number): void {
    AdaaHelper.setLocalStorage(Constants.localStorageKeys.agmCycle, this.agmService.dashboardFilters.cycleId, "string");

    this._router.navigateByUrl(`/annual-meetings-projects-dashboard/projects/${id}`);
  }

  public filterDashboard(status: TpAgmStatus) {
    this.agmService.dashboardFilters.status = status;
    this.agmService.getDashboardData();
  }

  public navigateToCardsDashbaord(): void {
    AdaaHelper.setLocalStorage(Constants.localStorageKeys.agmCycle, this.agmService.dashboardFilters.cycleId, "string");
    this._router.navigateByUrl("/annual-meetings-projects-dashboard/projects");
  }

  private _initPage(): void {
    this.agmService.resetFilters(true, false);
    this.agmService.initDashboard(true);
    this.agmService.getData();
  }
}
