@if (hasHelpdeskGroup()) {
    <div class="container my-2">
        <div class="row d-flex justify-content-center">
            <div class="col bg-white mx-3 p-3">
                <adaa-chart-action-buttons
                    [elementId]="'supportChart1'"
                    [csvChartInfo]="'helpdesk.chart.issues_per_status' | translate"
                ></adaa-chart-action-buttons>
                <div class="d-flex justify-content-center">
                    <div>
                        <p class="my-2 fw-bold fs-4">{{ "helpdesk.chart.issues_per_status" | translate }}</p>
                        <canvas id="supportChart1" baseChart [data]="dataSet1()" type="pie" [options]="pieChartOptions" [legend]="true"> </canvas>
                    </div>
                </div>
            </div>
            <div class="col bg-white mx-3 p-3">
                <adaa-chart-action-buttons
                    [elementId]="'supportChart2'"
                    [csvChartInfo]="'helpdesk.chart.unresolved_issues_per_priority' | translate"
                ></adaa-chart-action-buttons>
                <div class="d-flex justify-content-center">
                    <div>
                        <p class="my-2 fw-bold fs-4">{{ "helpdesk.chart.unresolved_issues_per_priority" | translate }}</p>
                        <canvas id="supportChart2" baseChart [data]="dataSet2()" type="pie" [options]="pieChartOptions" [legend]="true"> </canvas>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

<adaa-data-table
    #adaaDataTable
    apiName="getAllCamelCase"
    apiPrefix="helpdesk"
    [titleHeader]="breadcrumb()"
    [configKey]="Constants.MAIN_TABLE_LIST_CONF_KEY.HELPDESK_TICKETS"
    [enableDeleteAction]="false"
    [enableAdd]="false"
    (actionClicked)="getAction($event)"
/>

<adaa-float-action />
