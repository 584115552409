import { Component, inject, input, viewChild } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { AdaaHelper } from "../../../../core/utils";
import { ConfirmationModalComponent, DataTableComponent, FloatActionComponent } from "../../../../shared/components";
import { genericFloatButtons } from "../../../../shared/components/general/float-action";
import { Constants } from "../../../../shared/constants/constants";
import { Language } from "../../../../shared/constants/enums";
import { TableButtonClicked } from "../../../../shared/models";
import { AppService, SystemLayoutService, TpSectorApiService } from "../../../../shared/services";

@Component({
  selector: "adaa-tp-sectors-list",
  standalone: true,
  imports: [DataTableComponent, FloatActionComponent],
  templateUrl: "./tp-sectors-list.component.html",
  styleUrl: "./tp-sectors-list.component.scss",
})
export class TpSectorsListComponent {
  private _modalService = inject(NgbModal);
  private _appService = inject(AppService);
  private _toastrService = inject(ToastrService);
  private _translateService = inject(TranslateService);
  private _tpSectorApiService = inject(TpSectorApiService);
  private _router = inject(Router);
  private readonly _systemLayoutService = inject(SystemLayoutService);

  readonly #floatActions = genericFloatButtons();
  readonly #untilDestroy = AdaaHelper.untilDestroyed();

  tableConfList = input.required<string>();
  adaaDataTable = viewChild.required<DataTableComponent>("adaaDataTable");
  constants = Constants;

  public ngOnInit() {
    this.#floatActions([
      {
        key: "print",
        data: undefined,
      },
      {
        key: "helpdesk",
        data: {
          url: "/helpdesk/create",
        },
      },
    ]);
    this._systemLayoutService.hasActiveEntityChanged$.pipe(this.#untilDestroy()).subscribe({
      next: () => {
        this._router.navigateByUrl(`/console`);
      },
    });
  }

  public addNewClicked() {
    this._router.navigateByUrl(`/console/national-project/sectors/create`);
  }

  public getAction(event: TableButtonClicked) {
    const sectorId = event.data.id;
    switch (event.event) {
      case "view":
        this._router.navigateByUrl(`/console/national-project/sectors/${sectorId}`);
        break;
      case "edit":
        this._router.navigateByUrl(`/console/national-project/sectors/edit/${sectorId}`);
        break;
      case "delete":
        this._deleteSector(sectorId);
        break;

      default:
        break;
    }
  }

  private _deleteSector(tpSectorId: number) {
    const modal = this._modalService.open(ConfirmationModalComponent, {
      centered: true,
      size: "md",
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });

    modal.componentInstance.header = "national_projects.national_sector.delete_confirmation_title";
    modal.componentInstance.title = "national_projects.national_sector.delete_confirmation_msg";

    modal.result.then((e) => {
      if (e) {
        this._tpSectorApiService.deleteTPSector(tpSectorId).subscribe({
          next: (response) => {
            if (response.inError) return;
            this._toastrService.success(this._translateService.instant("notification.success.remove"));
            this.adaaDataTable().loadTableData();
          },
        });
      }
    });
  }
}
