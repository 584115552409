<div class="row">
    <div class="col-12">
        <h4 class="fw-bold">
            {{ "metrics.title" | translate }}
            <span class="fw-light mx-1">
                @if (displayLabel) {
                    - {{ displayLabel }}
                }
            </span>
        </h4>
    </div>
</div>

<form [formGroup]="metricsForm">
    <div class="bg-white shadow-md rounded p-2 mb-3">
        <div class="row">
            <div
                [class.col-md-6]="metricsForm.get('type')?.value === Constants.METRICS_TYPE.DIMENSION"
                [class.col-md-12]="metricsForm.get('type')?.value !== Constants.METRICS_TYPE.DIMENSION"
                class="col-sm-12"
            >
                <adaa-form-dropdown
                    controlName="type"
                    formControlName="type"
                    [label]="'metrics.label_type' | translate"
                    [setValidator]="formValidation()!"
                    [clearable]="true"
                    [options]="mainTypes()"
                    [invalid]="submitted && metricsForm.get('type')?.invalid!"
                    (inputChanges)="metricTypeChange($event)"
                />
            </div>
            @if (metricsForm.get("type")?.value === Constants.METRICS_TYPE.DIMENSION) {
                <div class="col-md-6 col-sm-12">
                    <adaa-form-dropdown
                        controlName="dashboardType"
                        formControlName="dashboardType"
                        [label]="'metrics.dashboard_type' | translate"
                        [setValidator]="formValidation()!"
                        [clearable]="true"
                        [options]="dashboardTypes()"
                        [invalid]="submitted && metricsForm.get('dashboardType')?.invalid!"
                    />
                </div>
            }
        </div>

        @if (metricsForm.get("type")?.value === Constants.METRICS_TYPE.DIMENSION) {
            <adaa-metrics-dimensions-editor
                [pageMode]="pageMode()"
                [submitted]="submitted"
                [formValidation]="formValidation()"
                [metric]="metric()"
                (formChanged)="this.metricsForm.patchValue($event)"
                (parentMetricChanged)="parentMetricChanged($event)"
            />
        } @else {
            <div class="row">
                <div class="col-12">
                    <adaa-form-dropdown-multi
                        controlName="entityIds"
                        formControlName="entityIds"
                        [clearable]="true"
                        [searchable]="true"
                        [label]="'common.form.label.entities' | translate"
                        [setValidator]="formValidation()!"
                        [options]="entities()"
                        [invalid]="submitted && metricsForm.get('entityIds')?.invalid!"
                    />
                </div>
            </div>
        }
    </div>

    <div class="bg-white shadow-md rounded p-2 mb-3">
        <div class="row">
            <div class="col-md-6 col-sm-12">
                <adaa-form-input
                    controlName="nameAE"
                    formControlName="nameAE"
                    [label]="'common.form.label.nameAE' | translate"
                    [setValidator]="formValidation()!"
                    [invalid]="submitted && metricsForm.get('nameAE')?.invalid!"
                />
            </div>
            <div class="col-md-6 col-sm-12">
                <adaa-form-input
                    controlName="nameEN"
                    formControlName="nameEN"
                    [label]="'common.form.label.nameEN' | translate"
                    [setValidator]="formValidation()!"
                    [invalid]="submitted && metricsForm.get('nameEN')?.invalid!"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-sm-12">
                <adaa-form-input
                    controlName="dscAE"
                    formControlName="dscAE"
                    [isTextArea]="true"
                    [label]="'common.form.label.descriptionAE' | translate"
                    [setValidator]="formValidation()!"
                    [invalid]="submitted && metricsForm.get('dscAE')?.invalid!"
                />
            </div>
            <div class="col-md-6 col-sm-12">
                <adaa-form-input
                    controlName="dscEN"
                    formControlName="dscEN"
                    [label]="'common.form.label.descriptionEN' | translate"
                    [isTextArea]="true"
                    [setValidator]="formValidation()!"
                    [invalid]="submitted && metricsForm.get('dscEN')?.invalid!"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-sm-12">
                <adaa-form-dropdown
                    controlName="dataType"
                    formControlName="dataType"
                    [label]="'metrics.label_data_type' | translate"
                    [setValidator]="formValidation()!"
                    [clearable]="true"
                    [options]="dataTypes()"
                    [invalid]="submitted && metricsForm.get('dataType')?.invalid!"
                />
            </div>
            <div class="col-md-3 col-sm-12">
                <adaa-form-input
                    controlName="dataSourceAE"
                    formControlName="dataSourceAE"
                    [label]="'metrics.data_source_ae' | translate"
                    [setValidator]="formValidation()!"
                    [invalid]="submitted && metricsForm.get('dataSourceAE')?.invalid!"
                />
            </div>
            <div class="col-md-3 col-sm-12">
                <adaa-form-input
                    controlName="dataSourceEN"
                    formControlName="dataSourceEN"
                    [label]="'metrics.data_source_en' | translate"
                    [setValidator]="formValidation()!"
                    [invalid]="submitted && metricsForm.get('dataSourceEN')?.invalid!"
                />
            </div>
        </div>

        <div class="row my-3">
            <div class="col-12 d-flex justify-content-center">
                <adaa-form-checkbox
                    controlName="available"
                    formControlName="available"
                    [label]="'metrics.available' | translate"
                    [checkboxMode]="'yesNo'"
                    [boldLabel]="true"
                />
            </div>
        </div>
    </div>
</form>

@if (pageMode() === PageMode.view) {
    <div class="p-2">
        <adaa-data-table
            [isLocalData]="true"
            [localData]="dataEntries()"
            [configKey]="Constants.MAIN_TABLE_LIST_CONF_KEY.DATA_ENTRY_METRICS_LIST"
            [enableDeleteAction]="false"
            [enableEditAction]="false"
            [enableAdd]="false"
            [enableExport]="false"
            (actionClicked)="actionClicked()"
        />
    </div>
}

<div class="my-2">
    <adaa-form-action-buttons
        saveButtonTitle="common.form.button.submit"
        [cancelButtonTitle]="pageMode() === PageMode.view ? 'common.form.button.close' : 'common.form.button.cancel'"
        [showSaveButton]="pageMode() !== PageMode.view"
        (saveClicked)="submit()"
        (cancelClicked)="goBack()"
    />
</div>
