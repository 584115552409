import { Component, inject, signal, viewChild } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { AdaaHelper } from "../../../core/utils";
import { DataTableComponent, FormActionButtonsComponent } from "../../../shared/components";
import { Constants } from "../../../shared/constants/constants";
import { PermissionAction } from "../../../shared/constants/enums";
import { CustomButton, TableButtonClicked, TableInputChangedOutput, TableInputs } from "../../../shared/models";
import { NotificationConfig, NotificationConfigPlaceholder } from "../../../shared/models/notifications-config.model";
import { PermissionsService, SystemLayoutService, UserGroupsApiService } from "../../../shared/services";
import { NotificationsApiService } from "../../../shared/services/notifications-api.service";
import { EditorTemplateModalComponent } from "../editor-template-modal/editor-template-modal.component";
import { SmsTemplateModalComponent } from "../sms-template-modal/sms-template-modal.component";

@Component({
  selector: "adaa-notifications-management-list",
  standalone: true,
  imports: [DataTableComponent, FormActionButtonsComponent, SmsTemplateModalComponent, EditorTemplateModalComponent],
  templateUrl: "./notifications-management-list.component.html",
  styleUrl: "./notifications-management-list.component.scss",
})
export class NotificationsManagementListComponent {
  private readonly _systemLayoutService = inject(SystemLayoutService);
  private readonly _userGroupsApiService = inject(UserGroupsApiService);
  private readonly _toastrService = inject(ToastrService);
  private readonly _router = inject(Router);
  private _permissionsService = inject(PermissionsService);
  private _translateService = inject(TranslateService);
  private _notificationsApiService = inject(NotificationsApiService);

  adaaDataTable = viewChild.required<DataTableComponent>("adaaDataTable");
  smsTemplateEditor = viewChild.required<SmsTemplateModalComponent>("sms");
  appTemplateEditor = viewChild.required<EditorTemplateModalComponent>("editor");

  readonly #untilDestroy = AdaaHelper.untilDestroyed();
  adaaHelper = AdaaHelper;
  constants = Constants;
  customButtons: CustomButton[] = [];
  placeholders = signal<NotificationConfigPlaceholder[]>([]);

  data: NotificationConfig[] = [];

  configKey: string = AdaaHelper.isPMOEntity()
    ? Constants.MAIN_TABLE_LIST_CONF_KEY.NOTIFICATION_CONFIGURATION_PMO
    : Constants.MAIN_TABLE_LIST_CONF_KEY.NOTIFICATION_CONFIGURATION_ENTITY;

  tableInputs: TableInputs[] = [
    {
      fieldName: "notificationGroup",
      options: [],
      disableRules: [
        {
          propertyName: "enableGroup",
          compareWith: "N",
          operator: "equal",
        },
      ],
    },
    {
      fieldName: "isActive",
      checkboxMode: "yesNo",
    },
    {
      fieldName: "smsNotification",
      checkboxMode: "yesNo",
      disableRules: [
        {
          propertyName: "flgHasSMS",
          compareWith: "N",
          operator: "equal",
        },
      ],
    },
    {
      fieldName: "emailNotification",
      checkboxMode: "yesNo",
      disableRules: [
        {
          propertyName: "flgHasEmail",
          compareWith: "N",
          operator: "equal",
        },
      ],
    },
  ];
  constructor() {
    this._getPlaceholder();
  }

  public ngOnInit(): void {
    this._systemLayoutService.hasActiveEntityChanged$.pipe(this.#untilDestroy()).subscribe({
      next: () => {
        this.configKey = AdaaHelper.isPMOEntity()
          ? Constants.MAIN_TABLE_LIST_CONF_KEY.NOTIFICATION_CONFIGURATION_PMO
          : Constants.MAIN_TABLE_LIST_CONF_KEY.NOTIFICATION_CONFIGURATION_ENTITY;
        this.adaaDataTable().loadTableData();
      },
    });
    this._getUserGroup();
    this._checkPermissions();
  }

  private _getPlaceholder() {
    this._notificationsApiService.getNotificationsPlaceholders().subscribe({
      next: (res) => {
        this.placeholders.set(res.responseData);
      },
    });
  }

  private _getUserGroup() {
    this._userGroupsApiService.getUserGroupList().subscribe({
      next: (res) => {
        const result = AdaaHelper.setDropdownArray(res.responseData, "id", AdaaHelper.getFieldLanguage("name"));
        result.unshift({ value: null, text: this._translateService.instant("common.form.label.none") });
        this.tableInputs[0].options = result;
      },
    });
  }
  public inputListChanged(values: TableInputChangedOutput[]): void {
    if (!values.length) return;

    for (const value of values) {
      switch (value.fieldName) {
        case "notificationGroup":
          value.data.notificationGroup = value.value;
          break;
        case "isActive":
          value.data.isActive = value.value;
          break;
        case "smsNotification":
          value.data.smsNotification = value.value;
          break;
        case "emailNotification":
          value.data.emailNotification = value.value;
          break;
        default:
          break;
      }

      const index = this.data.findIndex((e) => e.id === value.data.id);

      //If in data array remove it
      if (index > -1) this.data.splice(index, 1);

      this.data.push(value.data);
    }
  }

  public submit() {
    this._notificationsApiService.updateNotifications(this.data).subscribe({
      next: () => {
        this._toastrService.success(this._translateService.instant("notification.success.save"));
      },
    });
  }
  public cancel() {
    this._router.navigateByUrl(`/console`);
  }

  private _checkPermissions(): void {
    this._permissionsService.hasPermission([
      {
        permissionAction: PermissionAction.manage,
        objectTypeId: Constants.CONSTANT_PERMISSIONS.NOTIFICATIONS,
      },
    ]);

    this.customButtons = [
      {
        iconName: "adaa-icon-application-template",
        eventName: "editAppTemp",
        iconTitle: "notification_config.templates.edit_buttons.app",
      },
      {
        iconName: "adaa-icon-sms-template",
        eventName: "editSmsTemp",
        iconTitle: "notification_config.templates.edit_buttons.sms",
      },
      {
        iconName: "adaa-icon-email-template",
        eventName: "editEmailTemp",
        iconTitle: "notification_config.templates.edit_buttons.email",
      },
    ];
  }

  public actionClicked(action: TableButtonClicked): void {
    switch (action.event) {
      case "editSmsTemp":
        this._editSmsTemp(action.data);
        break;
      case "editAppTemp":
        this._editorTemp(action.data, "app");
        break;
      case "editEmailTemp":
        this._editorTemp(action.data, "email");
        break;
      default:
        break;
    }
  }

  private _editSmsTemp(notification: NotificationConfig): void {
    const templateId = notification.baseTemplates.find((item) => item.type == Constants.BASE_TEMPLATE_TYPES.SMS)?.id;
    if (templateId)
      this._notificationsApiService.getNotificationsTemplate(templateId).subscribe({
        next: (res) => {
          this.smsTemplateEditor().smsForm.patchValue(res.responseData);
          this.smsTemplateEditor().open();
        },
      });
  }

  private _editorTemp(notification: NotificationConfig, type: "email" | "app"): void {
    const templateId = notification.baseTemplates.find(
      (item) => item.type === (type === "app" ? Constants.BASE_TEMPLATE_TYPES.APP : Constants.BASE_TEMPLATE_TYPES.EMAIL)
    )?.id;
    if (templateId)
      this._notificationsApiService.getNotificationsTemplate(templateId).subscribe({
        next: (res) => {
          this.appTemplateEditor().editorForm.patchValue(res.responseData);
          this.appTemplateEditor().open();
        },
      });
  }
}
