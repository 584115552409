import { Component, inject, OnInit } from "@angular/core";

import { PermissionActionDescriptorType } from "../../../../adaa-types";
import { ConsoleIconComponent } from "../../../shared/components";
import { PermissionAction } from "../../../shared/constants/enums";
import { HasPermissionDirective } from "../../../shared/directives";
import { PermissionActionModelType } from "../../../shared/models";
import { ConsoleService, LanguageService } from "../../../shared/services";

@Component({
  selector: "adaa-agm-submenu",
  imports: [ConsoleIconComponent, HasPermissionDirective],
  templateUrl: "./agm-submenu.component.html",
  styleUrl: "./agm-submenu.component.scss",
})
export class AgmSubmenuComponent implements OnInit {
  readonly languageService = inject(LanguageService);
  readonly consoleService = inject(ConsoleService);

  public ngOnInit(): void {
    this.consoleService.init();
  }

  public getPermission(permissionId: string | undefined): PermissionActionDescriptorType | undefined {
    if (permissionId) {
      const permissions = permissionId.split(",");
      const permissionsArray: PermissionActionModelType[] = [];

      permissions.forEach((e) => {
        permissionsArray.push(
          {
            permissionAction: PermissionAction.view,
            objectTypeId: +e,
          },
          {
            permissionAction: PermissionAction.manage,
            objectTypeId: +e,
          },
          {
            permissionAction: PermissionAction.execute,
            objectTypeId: +e,
          }
        );
      });

      return {
        actor: {
          modifier: "or",
          permissions: permissionsArray,
        },
      };
    } else return undefined;
  }
}
