import { Component, computed, inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { AdaaHelper } from "../../../../core/utils";
import { DataTableComponent } from "../../../../shared/components";
import { genericFloatButtons } from "../../../../shared/components/general/float-action";
import { FloatActionComponent } from "../../../../shared/components/general/float-action/float-action.component";
import { Constants } from "../../../../shared/constants/constants";
import { ItemAction, TableButtonClicked } from "../../../../shared/models";
import { SystemLayoutService } from "../../../../shared/services";

@Component({
  selector: "adaa-milestone-list",
  standalone: true,
  imports: [DataTableComponent, FloatActionComponent],
  templateUrl: "./milestone-list.component.html",
  styleUrl: "./milestone-list.component.scss",
})
export class MilestoneListComponent implements OnInit {
  private _router = inject(Router);
  private _systemLayoutService = inject(SystemLayoutService);

  constants = Constants;
  private _untilDestroyed = AdaaHelper.untilDestroyed();
  private readonly _floatActions = genericFloatButtons();
  paramsString = computed(() => `itemType=${Constants.CONSTANT_NATIONAL_PROJECTS_MILESTONE}`);

  disableEditRules: ItemAction[] = [
    {
      propertyName: "status",
      compareWith: Constants.SERVICES.STATE.IN_REVIEW,
      operator: "equal",
    },
  ];

  public ngOnInit(): void {
    this._systemLayoutService.hasCycleChanged$.pipe(this._untilDestroyed()).subscribe({
      next: () => window.location.reload(),
    });
    this._systemLayoutService.hasActiveEntityChanged$.pipe(this._untilDestroyed()).subscribe({
      next: () => window.location.reload(),
    });

    this._floatActions([
      {
        key: "helpdesk",
        data: {
          url: "/helpdesk/create",
        },
      },
      {
        key: "print",
        data: undefined,
      },
    ]);
  }

  public actionClicked(event: TableButtonClicked): void {
    switch (event.event) {
      case "view":
        this._router.navigateByUrl(`/national-projects-dashboard/milestones/${event.data.id}`);
        break;
      case "edit":
        this._router.navigateByUrl(`/console/national-project/project-milestones/edit/${event.data.id}`);
        break;
      case "connections":
        this._router.navigateByUrl(
          `/console/connections/${event.data.id}/${Constants.CONSTANT_NATIONAL_PROJECTS_MILESTONE}?isSoftConnection=false`
        );
        break;
      default:
        break;
    }
  }
}
