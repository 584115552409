<ng-template #content let-modal>
    <div class="modal-header">
        <h5 class="text-center fw-bold w-100">{{ "attributes.lov" | translate }}</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body overflow-x-hidden p-3">
        <form class="col-12" [formGroup]="attributesValuesForm">
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.nameAE' | translate"
                        formControlName="lovTextAE"
                        controlName="lovTextAE"
                        [required]="true"
                    ></adaa-form-input>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.nameEN' | translate"
                        formControlName="lovTextEN"
                        controlName="lovTextEN"
                        [required]="true"
                    ></adaa-form-input>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <adaa-form-input
                        [label]="'attributes.lov' | translate"
                        formControlName="lovNumber"
                        controlName="lovNumber"
                        [inputType]="'number'"
                        [required]="true"
                    ></adaa-form-input>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer d-flex justify-content-center mt-3">
        <adaa-form-action-buttons (cancelClicked)="modal.close()" (saveClicked)="submit()"></adaa-form-action-buttons>
    </div>
</ng-template>
