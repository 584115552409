import type { Route } from "@angular/router";

import { checkIfLoggedInFn } from "../../core/guards";
import { PageMode } from "../../shared/constants/enums";
import { createUsersPageGuards, manageUsersPageGuards, viewUsersPageGuards } from "./_guards";
import { ProfileComponent } from "./profile/profile.component";
import { RelatedObjectsComponent } from "./related-objects/related-objects.component";
import { UserEditorComponent } from "./user-editor/user-editor.component";
import { UsersListComponent } from "./users-list/users-list.component";

export const PROFILE_ROUTES: Route[] = [
  {
    path: "user-profile",
    component: ProfileComponent,
    canActivate: [checkIfLoggedInFn],
    data: {
      title: "breadcrumbs.user_profile",
      breadcrumb: "breadcrumbs.user_profile",
    },
  },
];

export const USER_ROUTES: Route[] = [
  {
    path: "users",
    canActivate: [checkIfLoggedInFn, ...viewUsersPageGuards],
    children: [
      {
        path: "",
        component: UsersListComponent,
        canActivate: [checkIfLoggedInFn, ...viewUsersPageGuards],
        data: {
          title: "breadcrumbs.users",
          breadcrumb: "breadcrumbs.users",
        },
      },
      {
        path: "related-objects/:id",
        component: RelatedObjectsComponent,
        canActivate: [checkIfLoggedInFn, ...viewUsersPageGuards],
        data: {
          title: "breadcrumbs.user_related_objects",
          breadcrumb: "breadcrumbs.user_related_objects",
        },
      },
      {
        path: "create",
        component: UserEditorComponent,
        canActivate: [checkIfLoggedInFn, ...createUsersPageGuards],
        data: {
          title: "breadcrumbs.new_user",
          breadcrumb: "breadcrumbs.new_user",
          pageMode: PageMode.create,
        },
      },
      {
        path: "edit/:id",
        component: UserEditorComponent,
        canActivate: [checkIfLoggedInFn, ...manageUsersPageGuards],
        data: {
          title: "breadcrumbs.edit_user",
          breadcrumb: "breadcrumbs.edit_user",
          pageMode: PageMode.edit,
        },
      },
      {
        path: ":id",
        component: UserEditorComponent,
        canActivate: [checkIfLoggedInFn, ...viewUsersPageGuards],
        data: {
          title: "breadcrumbs.view_user",
          breadcrumb: "breadcrumbs.view_user",
          pageMode: PageMode.view,
        },
      },
    ],
  },
];
