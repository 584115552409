<adaa-data-table
    #adaaDataTable
    [apiName]="'getAll'"
    [apiPrefix]="'annual-meeting-project-cycle'"
    [titleHeader]="breadcrumb() | translate"
    [configKey]="Constants.MAIN_TABLE_LIST_CONF_KEY.TRANSFORMATIONAL_PROJECTS_CYCLE"
    [itemTypeId]="Constants.CONSTANT_PERMISSIONS.ANNUAL_MEETINGS_PROJECTS_CYCLES"
    (addNewClicked)="addNew()"
    (actionClicked)="getAction($event)"
/>

<adaa-float-action />
