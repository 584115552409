import { Route } from "@angular/router";

import { checkIfLoggedInFn, pmoEntity } from "../../core/guards";
import { viewTranslationsPageGuards } from "./_guards";

export const TRANSLATION_ROUTES: Route[] = [
  {
    path: "translation",
    canActivate: [checkIfLoggedInFn, pmoEntity, ...viewTranslationsPageGuards],
    data: {
      breadcrumb: "breadcrumbs.translation_module.view",
    },
    loadComponent: () => import("./translations.component").then((m) => m.TranslationsComponent),
  },
];
