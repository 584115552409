<div class="row">
    <div class="col-12">
        <h4 class="fw-bold">
            {{ "national_strategies.title" | translate }}
            <span class="fw-light mx-1">
                @if (displayLabel) {
                    - {{ displayLabel }}
                }
            </span>
        </h4>
    </div>

    <div class="col-12" [formGroup]="nsForm">
        <adaa-form-steps
            [showSaveButton]="showSave()"
            [showFinishButton]="showFinish()"
            [tabs]="tabs"
            (currentTab)="activeTab.set($event)"
            (submitClicked)="save($event)"
            (cancelClicked)="goBack()"
        >
            <div class="tab-pane fade" id="tab-0" role="tabpanel" [ngClass]="{ 'show active': activeTab() === 0 }">
                <div class="bg-white shadow-md rounded p-2 mb-2">
                    <div class="row">
                        @if (pageMode() !== PageMode.create) {
                            <div class="col-12">
                                <adaa-form-input
                                    controlName="refCode"
                                    formControlName="refCode"
                                    [invalid]="submitted && nsForm.get('refCode')?.invalid!"
                                    [label]="'common.form.label.refcode' | translate"
                                    [required]="isFieldRequired(validations(), 'refCode')"
                                />
                            </div>
                        }
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-xs-12">
                            <adaa-form-input
                                controlName="nameAE"
                                formControlName="nameAE"
                                [invalid]="submitted && nsForm.get('nameAE')?.invalid!"
                                [label]="'common.form.label.nameAE' | translate"
                                [required]="isFieldRequired(validations(), 'nameAE')"
                            />
                        </div>
                        <div class="col-sm-6 col-xs-12">
                            <adaa-form-input
                                controlName="nameEN"
                                formControlName="nameEN"
                                [invalid]="submitted && nsForm.get('nameEN')?.invalid!"
                                [label]="'common.form.label.nameEN' | translate"
                                [required]="isFieldRequired(validations(), 'nameEN')"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-xs-12">
                            <adaa-form-input
                                controlName="dscAE"
                                formControlName="dscAE"
                                [invalid]="submitted && nsForm.get('dscAE')?.invalid!"
                                [label]="'common.form.label.descriptionAE' | translate"
                                [required]="isFieldRequired(validations(), 'dscAE')"
                                [isTextArea]="true"
                            />
                        </div>
                        <div class="col-sm-6 col-xs-12">
                            <adaa-form-input
                                controlName="dscEN"
                                formControlName="dscEN"
                                [invalid]="submitted && nsForm.get('dscEN')?.invalid!"
                                [label]="'common.form.label.descriptionEN' | translate"
                                [required]="isFieldRequired(validations(), 'dscEN')"
                                [isTextArea]="true"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-xs-12">
                            <adaa-form-input-date
                                controlName="startDate"
                                formControlName="startDate"
                                [invalid]="submitted && nsForm.get('startDate')?.invalid!"
                                [label]="'common.form.label.start_date' | translate"
                                [required]="isFieldRequired(validations(), 'startDate')"
                            />
                        </div>
                        <div class="col-sm-6 col-xs-12">
                            <adaa-form-input-date
                                controlName="endDate"
                                formControlName="endDate"
                                [invalid]="submitted && nsForm.get('endDate')?.invalid!"
                                [label]="'common.form.label.end_date' | translate"
                                [required]="isFieldRequired(validations(), 'endDate')"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-xs-12">
                            <adaa-form-dropdown
                                controlName="entityId"
                                formControlName="entityId"
                                [searchable]="true"
                                [invalid]="submitted && nsForm.get('entityId')?.invalid!"
                                [label]="'national_projects.accountable_entity' | translate"
                                [required]="isFieldRequired(validations(), 'entityId')"
                                [options]="entities()"
                                (inputChanges)="entityIdChanged($event)"
                            />
                        </div>
                        <div class="col-sm-6 col-xs-12">
                            <adaa-form-dropdown
                                controlName="owner"
                                formControlName="owner"
                                [searchable]="true"
                                [invalid]="submitted && nsForm.get('owner')?.invalid!"
                                [label]="'common.form.label.owner' | translate"
                                [required]="isFieldRequired(validations(), 'owner')"
                                [options]="owners()"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-xs-12">
                            <adaa-form-dropdown
                                controlName="strategyOwnerGroup"
                                formControlName="strategyOwnerGroup"
                                [searchable]="true"
                                [invalid]="submitted && nsForm.get('strategyOwnerGroup')?.invalid!"
                                [label]="'national_strategies.strategic_owner_group' | translate"
                                [required]="isFieldRequired(validations(), 'strategyOwnerGroup')"
                                [options]="strategyOwnerGroups()"
                            />
                        </div>
                        <div class="col-sm-6 col-xs-12 d-flex align-items-center mt-3">
                            <adaa-form-checkbox
                                controlName="available"
                                formControlName="available"
                                [label]="'common.form.label.available' | translate"
                                [checkboxMode]="'yesNo'"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="tab-1" role="tabpanel" [ngClass]="{ 'show active': activeTab() === 1 }">
                <div class="bg-white shadow-md rounded p-2 mb-2">
                    <div class="row">
                        <div class="col-12">
                            <adaa-data-table
                                #subDirectionsTable
                                titleHeader="national_strategies.obj_enabler"
                                configKey="sub_gov_dir_conf_list"
                                [isLocalData]="true"
                                [localData]="filteredSubDirections"
                                [enableViewAction]="false"
                                [enableActionsCell]="pageMode() !== PageMode.view"
                                [enableAdd]="pageMode() !== PageMode.view"
                                [enableEditAction]="pageMode() !== PageMode.view"
                                [enableDeleteAction]="pageMode() !== PageMode.view"
                                [enableExport]="false"
                                (addNewClicked)="addObjectiveEnabler()"
                                (actionClicked)="manageObjectiveEnabler($event)"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="tab-1" role="tabpanel" [ngClass]="{ 'show active': activeTab() === 2 }">
                <div class="bg-white shadow-md rounded p-2 mb-2">
                    <div class="row">
                        <div class="col-12">
                            <adaa-form-dropdown
                                controlName="ntKpiId"
                                formControlName="ntKpiId"
                                [searchable]="true"
                                [clearable]="true"
                                [invalid]="submitted && nsForm.get('ntKpiId')?.invalid!"
                                [label]="'breadcrumbs.ntkpi_module.list' | translate"
                                [required]="isFieldRequired(validations(), 'ntKpi')"
                                [options]="ntKpis()"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </adaa-form-steps>
    </div>
</div>
