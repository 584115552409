<p class="fw-bold fs-4">{{ "communication.title" | translate }}</p>

<div class="row">
    <div class="col-md-4 col-12">
        <div class="bg-white mx-2 p-2">
            <div class="row my-2">
                <div class="col-6">
                    <adaa-form-dropdown
                        [options]="actionOptions()"
                        (inputChanges)="filter('action', $event)"
                        [setDefaultValue]="'A'"
                    ></adaa-form-dropdown>
                </div>
                <div class="col-6">
                    <adaa-form-dropdown
                        [options]="readOptions()"
                        (inputChanges)="filter('read', $event)"
                        [setDefaultValue]="AdaaBoolean.N"
                    ></adaa-form-dropdown>
                </div>
            </div>
            <div class="d-flex gap-2">
                <div class="mx-1 my-2 w-100">
                    <input
                        #searchInputEl
                        type="search"
                        class="form-control"
                        autocomplete="on"
                        inputmode="search"
                        aria-label="'breadcrumbs.search' | translate"
                        [placeholder]="'common.form.label.search_message' | translate"
                        (input)="performSearch($event)"
                    />
                </div>
                <div class="orders gap-3">
                    <span class="adaa-icon-increasing icon" (click)="order('asc')"></span>
                    <span class="adaa-icon-decreasing icon" (click)="order('desc')"></span>
                </div>
            </div>
            <div class="m-2 notifications">
                @for (notification of notifications(); track $index) {
                    <div
                        class="notification p-2 my-1 border border-1 rounded"
                        [class.selected]="selectedNotification()?.id === notification.id"
                        (click)="getNotification(notification.id)"
                    >
                        <div class="d-flex gap-2">
                            @if (notification.commType === "A") {
                                <span class="badge bg-info my-2">{{ "communication.action_needed" | translate }}</span>
                            }
                            @if (notification.commType === "I") {
                                <span class="badge bg-warning my-2">{{ "common.form.label.information" | translate }}</span>
                            }
                            <p class="mb-0 mt-2 opacity-75">{{ moment(notification.createTS).format("DD/MM/YYYY HH:mm") }}</p>
                        </div>
                        <p [class.fw-bold]="notification.isRead === 'N'">{{ AdaaHelper.getItemValueByToken(notification, "title") }}</p>
                        <p class="mb-0 opacity-75 text-break">{{ AdaaHelper.getItemValueByToken(notification, "itemName") }}</p>
                    </div>
                } @empty {
                    <p class="fw-bold text-center fs-6">{{ "communication.no_notifications" | translate }}</p>
                }
            </div>
            <div class="d-flex justify-content-center my-4">
                <button class="btn btn-primary" (click)="showMoreMessages()">
                    <span class="fw-bolder">{{ "communication.show_more_messages" | translate }}</span>
                </button>
            </div>
        </div>
    </div>
    <div class="col-md-8 col-12">
        @if (selectedNotification()) {
            <div class="bg-white text-center p-3">
                <p class="fw-bold mb-0">{{ "communication.message" | translate }}</p>
                <div class="bg-grey p-2 my-2">
                    <p class="mb-0 text-primary fw-bold">
                        {{ AdaaHelper.getItemValueByToken(selectedNotification(), "title") }}
                    </p>
                </div>
                <div class="bg-grey p-3">
                    <div class="msg text-break custom-message" [innerHTML]="AdaaHelper.getItemValueByToken(selectedNotification(), 'commMsg')"></div>
                    <div
                        class="info border rounded p-4"
                        (click)="getItemForReview()"
                        [class.view-differences]="selectedNotification()?.commType === 'A'"
                    >
                        <p class="mb-0">
                            {{
                                ("common.form.label.adaa" | translate) +
                                    "." +
                                    ("communications_status." + selectedNotification()?.commType | translate) +
                                    "." +
                                    moment(selectedNotification()?.createTS).format("DD/MM/YYYY HH:mm")
                            }}
                        </p>
                        @if (selectedNotification()?.commType === "A") {
                            <p class="mb-0">{{ ("workflow.workflow_process_id" | translate) + " - " + selectedNotification()?.wfProcessCtlId }}</p>
                            <p class="mb-0">
                                {{
                                    AdaaHelper.getItemValueByToken(selectedNotification(), "itemTypeName") +
                                        " " +
                                        ("communication.view_differences" | translate)
                                }}
                            </p>
                        }
                        @if (
                            selectedNotification()?.commType === "I" &&
                            +selectedNotification()?.wfActionType! === Constants.NOTIFICATION_ACTION_TYPES.UPDATE
                        ) {
                            <div class="my-2">
                                <button class="btn btn-primary" (click)="$event.stopPropagation(); getItemForReview()">
                                    <span class="adaa-icon-view mx-3"></span>
                                    <span class="fw-bolder">{{ "communication.view_differences" | translate }}</span>
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>
            @if (selectedNotification()?.commType === "A") {
                <div class="bg-white my-2 p-2">
                    <adaa-data-table
                        #adaaDataTable
                        [isLocalData]="true"
                        [localData]="tableData()"
                        [configKey]="Constants.MAIN_TABLE_LIST_CONF_KEY.WORKFLOW_COMMENT_HISTORY_CONF_LIST"
                        [enableSettings]="false"
                        [enableFilter]="false"
                        [enableAdd]="false"
                        [enableExport]="false"
                        [enableActionsCell]="false"
                    ></adaa-data-table>
                </div>
                <div class="bg-white my-2 px-1 py-2 text-center">
                    <p>{{ "communication.reply_and_attachments" | translate }}</p>
                    <div class="my-2 p-2">
                        <adaa-wf-override-decision [label]="''" (emitDecision)="decision.set($event)" />
                    </div>
                    <adaa-form-input-file
                        [onDeleteRemoveFile]="false"
                        [allowedFileSize]="10000000"
                        [allowedFilesMessage]="''"
                        [allowedFiles]="allowedFiles"
                        (inputChanges)="attachment.set($event)"
                    ></adaa-form-input-file>
                </div>
                <div class="d-flex justify-content-center gap-4 my-2">
                    <button class="btn btn-primary">
                        <span class="fw-bolder">{{ "common.form.button.cancel" | translate }}</span>
                    </button>
                    <button
                        class="btn btn-primary"
                        [disabled]="!decision() || decision()?.categorizedComments?.length === 0"
                        (click)="submitDecision(false)"
                    >
                        <span class="fw-bolder">{{ "common.form.button.reject" | translate }}</span>
                    </button>
                    <button class="btn btn-primary" (click)="submitDecision(true)">
                        <span class="fw-bolder">{{ "common.form.button.approve" | translate }}</span>
                    </button>
                </div>
            }
        }
    </div>
</div>

<adaa-wf-difference [isAuditTrail]="selectedNotification()?.commType === 'I'" #workflowDifference />
<adaa-float-action />
