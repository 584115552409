import { FormControl, FormGroup, Validators } from "@angular/forms";

import { dateRangeValidator } from "../../../../../../core/validators";
import { Constants } from "../../../../../../shared/constants/constants";
import { ParameterCatalog } from "../../../../../../shared/models";
import { getRequiredValidator } from "../../../utils/form-groups/lib";

export const improvementPlanForm = (fields: ParameterCatalog[]) =>
  new FormGroup(
    {
      nameAE: new FormControl<string | null>(null, [
        ...getRequiredValidator(fields, "nameAE"),
        Validators.minLength(3),
        Validators.maxLength(Constants.FORM_FIELD_CONSTRAINTS.CHAR_MAXLENGTH),
      ]),
      nameEN: new FormControl<string | null>(null, [
        ...getRequiredValidator(fields, "nameEN"),
        Validators.minLength(3),
        Validators.maxLength(Constants.FORM_FIELD_CONSTRAINTS.CHAR_MAXLENGTH),
      ]),
      dscAE: new FormControl<string | null>(null, [
        ...getRequiredValidator(fields, "dscAE"),
        Validators.minLength(3),
        Validators.maxLength(Constants.FORM_FIELD_CONSTRAINTS.DESC_MAXLENGTH),
      ]),
      dscEN: new FormControl<string | null>(null, [
        ...getRequiredValidator(fields, "dscEN"),
        Validators.minLength(3),
        Validators.maxLength(Constants.FORM_FIELD_CONSTRAINTS.DESC_MAXLENGTH),
      ]),
      startDate: new FormControl<number | null>(null, [...getRequiredValidator(fields, "startDate")]),
      endDate: new FormControl<number | null>(null, [...getRequiredValidator(fields, "endDate")]),
      actualStartDate: new FormControl<number | null>(null, [...getRequiredValidator(fields, "actualStartDate")]),
      actualEndDate: new FormControl<number | null>(null, [...getRequiredValidator(fields, "actualEndDate")]),
      orgUnitId: new FormControl<number | null>(null, [...getRequiredValidator(fields, "orgUnitId")]),
      owner: new FormControl<number | null>(null, [...getRequiredValidator(fields, "owner")]),
      weight: new FormControl<number | null>(null, [...getRequiredValidator(fields, "weight")]),
      progress: new FormControl<number | null>(null, [...getRequiredValidator(fields, "progress")]),
    },
    {
      validators: Validators.compose([
        dateRangeValidator("startDate", "endDate"),
        dateRangeValidator("actualStartDate", "actualEndDate"),
      ]),
    }
  );

export type ImprovementPlanFormType = ReturnType<typeof improvementPlanForm>;
