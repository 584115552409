@if (systemLayoutService.isReady()) {
    <adaa-period-slider [activePeriod]="selectedPeriod()!" [frequency]="frequency" type="quarter" (periodSelect)="selectPeriod($event)" />
}

@if (selectedPeriod(); as period) {
    <adaa-aggregated-performance [selectedPeriod]="period" [isPmo]="true">
        @if (hasKPIsWithIssue()) {
            <i
                class="fa fa-warning text-warning pointer"
                routerLink="/console/kpi/audit-issues"
                [title]="'landingPage.entity_page.kpi_issues' | translate"
            ></i>
        }
        {{ "landingPage.entity_page.government_overall_performance" | translate }}
        <b>{{ selectedPeriod()?.type === "year" ? "" : "Q" + period.period.quarter }} {{ period.period.year }}</b>
    </adaa-aggregated-performance>

    @if (useNewDS()) {
        <adaa-uae-vision-performance [selectedPeriod]="period" />
    } @else {
        @let periodId = period.type === "year" ? period.period.year : period.period.id;

        <adaa-gov-vision-performance [id]="periodId" [period]="period.period.id" [isAnnual]="period.type === 'year'" [selectedPeriod]="period" />
    }

    <adaa-gov-enabler-performance [selectedPeriod]="period" />

    <adaa-national-strategies-performance [selectedPeriod]="period" />
}
