import { Component, inject, input, type OnInit, viewChild } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { ConfirmationModalComponent, DataTableComponent, FloatActionComponent } from "../../../shared/components";
import { genericFloatButtons } from "../../../shared/components/general/float-action";
import { Constants } from "../../../shared/constants/constants";
import { Language } from "../../../shared/constants/enums";
import { TableButtonClicked } from "../../../shared/models";
import { AppService, PillarsApiService } from "../../../shared/services";

@Component({
  selector: "adaa-pillars-list",
  standalone: true,
  imports: [DataTableComponent, TranslateModule, FloatActionComponent],
  templateUrl: "./pillars-list.component.html",
  styleUrl: "./pillars-list.component.scss",
})
export class PillarsListComponent implements OnInit {
  private _router = inject(Router);
  private _modalService = inject(NgbModal);
  private _appService = inject(AppService);
  private _pillarsService = inject(PillarsApiService);
  private readonly _translateService = inject(TranslateService);
  private _toastrService = inject(ToastrService);

  tableConfList = input.required<string>();
  adaaDataTable = viewChild.required<DataTableComponent>("adaaDataTable");

  private readonly _genericFloatButtons = genericFloatButtons();
  Constants = Constants;

  public ngOnInit() {
    this._floatActions();
  }

  public addNewClicked() {
    this._router.navigateByUrl(`/console/pillars/create`);
  }

  public getAction(event: TableButtonClicked) {
    const pillarId = event.data.id;
    switch (event.event) {
      case "view":
        this._router.navigateByUrl(`/console/pillars/${pillarId}`);
        break;
      case "edit":
        this._router.navigateByUrl(`/console/pillars/edit/${pillarId}`);
        break;
      case "delete":
        this._deletePillar(pillarId);
        break;

      default:
        break;
    }
  }

  private _deletePillar(pillarId: number) {
    const modal = this._modalService.open(ConfirmationModalComponent, {
      centered: true,
      size: "md",
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });

    modal.componentInstance.header = "pillar.delete_yes_no_title";
    modal.componentInstance.title = "pillar.delete_yes_no_information";

    modal.result.then((e) => {
      if (e) {
        this._pillarsService.deletePillar(pillarId).subscribe({
          next: (response) => {
            if (response.inError) return;
            this._toastrService.success(this._translateService.instant("notification.success.remove"));
            this.adaaDataTable().loadTableData();
          },
        });
      }
    });
  }

  private _floatActions(): void {
    this._genericFloatButtons([
      {
        key: "print",
        data: undefined,
      },
      {
        key: "helpdesk",
        data: {
          url: "/helpdesk/create",
        },
      },
    ]);
  }
}
