import { DatePipe, NgClass } from "@angular/common";
import { Component, computed, effect, inject, input, OnDestroy, OnInit, signal } from "@angular/core";
import { Router } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faSearch, faStar } from "@fortawesome/free-solid-svg-icons";
import { NgbPaginationModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { map } from "rxjs";

import { DubaiTimePipe, SafeHtmlPipe, TranslateTokenPipe } from "../../core/pipes";
import { AdaaHelper, useNewDS } from "../../core/utils";
import { Constants } from "../../shared/constants/constants";
import type {
  SearchCountAggregateType,
  SearchModelType,
  SearchResultItemType,
  SearchResultType,
  SearchTermTypes,
} from "../../shared/models";
import { FilesApiService, LanguageService, SearchApiService, SystemLayoutService } from "../../shared/services";
import { SearchProvider } from "./search.provider";
import {
  getActivityUrl,
  getKpiName,
  getKpiUrl,
  getProjectsUrl,
  getSearchCategories,
  getServiceName,
  hasBadWord,
  isSubKPIType,
} from "./search.utils";

@Component({
  selector: "adaa-search",
  standalone: true,
  imports: [
    TranslateModule,
    FontAwesomeModule,
    NgClass,
    NgbPaginationModule,
    SafeHtmlPipe,
    TranslateTokenPipe,
    DatePipe,
    DubaiTimePipe,
  ],
  templateUrl: "./search.component.html",
  styleUrl: "./search.component.scss",
})
export class SearchComponent implements OnInit, OnDestroy {
  readonly languageService = inject(LanguageService);
  readonly systemLayoutService = inject(SystemLayoutService);
  private readonly _router = inject(Router);
  private readonly _searchProvider = inject(SearchProvider);
  private readonly _toastrService = inject(ToastrService);
  private readonly _filesApiService = inject(FilesApiService);
  private readonly _translateService = inject(TranslateService);
  private readonly _searchApiService = inject(SearchApiService);

  s = input<string | null>(null); //* reads from the query params ?s=
  t = input<"" | SearchTermTypes | null>(null); //* reads from the query params ?t=

  data = signal<SearchModelType | null>(null);
  isLoading = signal<boolean>(false);
  isParentActive = signal<boolean>(false);

  totalNumberOfItems = computed(() => {
    const list = this.data()?.countAggregate ?? [];
    return list.reduce<number>((a, c: SearchCountAggregateType) => {
      if (!c.type || isSubKPIType(c.type)) return 0;
      return a + c.count;
    }, 0);
  });

  currentPage = 1;
  offset = 0;

  readonly limit = 10;
  readonly faStar = faStar;
  readonly faSearch = faSearch;
  readonly datetimeFormat = Constants.normalDate;
  readonly getKpiName = getKpiName;
  readonly isSubKpiType = isSubKPIType;
  readonly isDefined = AdaaHelper.isDefined.bind(AdaaHelper);
  readonly searchCategories = getSearchCategories();
  readonly isActive = (t: string | null) => {
    const type = this.t();
    if (!type && t === "") return true;
    return t === type;
  };
  readonly getCount = (t: string) => {
    const category = this.data()?.countAggregate.find(({ type }) => type === t?.toLowerCase());
    if (category) return category.count;
    return 0;
  };

  readonly #untilDestroy = AdaaHelper.untilDestroyed();
  readonly #doSearch = effect(() => {
    const searchTerm = this.s();
    const type = this.t();

    if (!searchTerm || searchTerm.length < 3) return;
    this._doSearch();

    if (type && (isSubKPIType(type) || type.toLowerCase() === "kpi")) {
      this.isParentActive.set(true);
    }
  });

  public get resultsFound() {
    const total = this.offset + this.limit;
    let items: number;
    if (!this.t() || this.t() === "") items = this.totalNumberOfItems();
    else items = this.data()?.searchCount as number;
    if (total > items) return items;
    return total;
  }

  public ngOnInit() {
    this._refreshPage();
  }

  public ngOnDestroy() {
    this.systemLayoutService.searchTerm.set(null);
  }

  public onPageChange() {
    this.offset = this.limit * (this.currentPage - 1);
    this._doSearch();
  }

  public onClick(item: SearchResultType) {
    if (item.type === "formula") {
      return this._router.navigate(["/console", "formulas", item.id]);
    }
    if (item.type === "user") {
      return this._router.navigate(["/console", "users", item.id]);
    }
    if (item.type === "task") {
      return this._router.navigate(["/console", "delivery-module", item.id]);
    }
    if (item.type === "document" || item.type === "attachment") {
      return this._filesApiService.save(item.id, AdaaHelper.getItemValueByToken(item, "name"), true);
    }
    if (item.type === "service") {
      return this._router.navigate(getServiceName(item.itemType, item.id).url);
    }

    if (item.type === "government_direction") {
      return this._router.navigate(["/console", "directions", item.id]);
    }
    if (item.type === "sub_government_direction") {
      return this._router.navigate(["/console", "directions", item.parentId]);
    }

    switch (item.itemType) {
      case Constants.CONSTANT_NKPITYPE:
      case Constants.CONSTANT_SKPITYPE:
      case Constants.CONSTANT_EKPI:
      case Constants.CONSTANT_OPM:
      case Constants.CONSTANT_UAEKPI:
      case Constants.CONSTANT_SRVKPI:
      case Constants.CONSTANT_MOKPITYPE:
      case Constants.CONSTANT_MTKPITYPE:
      case Constants.CONSTANT_NTKPITYPE:
      case Constants.CONSTANT_DTKPITYPE: {
        return this._router.navigate(getKpiUrl(item.itemType, item.id));
      }
      case Constants.CONSTANT_ACTIVITYTYPE: {
        return this._router.navigate(getActivityUrl(item.id, item.subType));
      }
      case Constants.CONSTANT_OBJECTIVETYPE: {
        return this._searchProvider.objectivesRedirection(item.id, item.entityId);
      }
      case Constants.CONSTANT_INITIATIVE: {
        return this._router.navigate(getProjectsUrl(item.id, item.subType));
      }
      case Constants.CONSTANT_PILLARS_TYPE: {
        return this._router.navigate(["/console", "pillars", item.id]);
      }
      default:
        break;
    }

    return;
  }

  public searchButtonClicked(el: HTMLInputElement) {
    const searchTerm = this.systemLayoutService.searchTerm();
    if (!searchTerm || searchTerm.length < 3) {
      el.focus();
      this._toastrService.warning(this._translateService.instant("notification.warning.text_search_length"));
      return;
    }
    return this._router.navigate(["/console", "search"], { queryParams: { s: searchTerm } });
  }

  public autoUpdateSearchTerm($e: Event) {
    const target = $e.target as unknown;
    const value = (target as Record<string, string | null>).value;
    if (hasBadWord(value ?? "")) {
      this._toastrService.warning(this._translateService.instant("search.stop_words_message"));
      return;
    }
    this.systemLayoutService.searchTerm.set(value);
  }

  public performSearch($e: Event) {
    $e.preventDefault();
    this.autoUpdateSearchTerm($e);
    const searchTerm = this.systemLayoutService.searchTerm();
    if (!searchTerm || searchTerm.length < 3) {
      this._toastrService.warning(this._translateService.instant("notification.warning.text_search_length"));
      return;
    }
    return this._router.navigate(["/console", "search"], { queryParams: { s: searchTerm } });
  }

  public getName(data: SearchResultType) {
    let name = "";
    if (this.languageService.current() === "ae" && !this.isDefined(data.nameAE)) {
      name = data.nameEN;
    }
    if (this.languageService.current() === "ae" && this.isDefined(data.nameAE)) {
      name = data.nameAE;
    }
    if (this.languageService.current() === "en" && !this.isDefined(data.nameEN)) {
      name = data.nameAE;
    }
    if (this.languageService.current() === "en" && this.isDefined(data.nameEN)) {
      name = data.nameEN;
    }
    return name;
  }

  public getDescription(data: SearchResultType) {
    let name = "";
    if (this.languageService.current() === "ae" && !this.isDefined(data.descriptionAE)) {
      name = data.descriptionEN;
    }
    if (this.languageService.current() === "ae" && this.isDefined(data.descriptionAE)) {
      name = data.descriptionAE;
    }
    if (this.languageService.current() === "en" && !this.isDefined(data.descriptionEN)) {
      name = data.descriptionAE;
    }
    if (this.languageService.current() === "en" && this.isDefined(data.descriptionEN)) {
      name = data.descriptionEN;
    }
    return name;
  }

  public selectType($e: Event, type: string) {
    $e.stopPropagation();
    if (type === "") {
      this.isParentActive.set(false);
      return this._router.navigate(["/console", "search"], {
        queryParams: { s: this.s() ?? "" },
        onSameUrlNavigation: "reload",
        preserveFragment: false,
      });
    }

    if (!isSubKPIType(type) && type.toLowerCase() !== "kpi") {
      this.isParentActive.set(false);
    } else if (isSubKPIType(type)) {
      this.isParentActive.set(true);
    } else {
      this.isParentActive.set(!this.isParentActive());
    }

    return this._router.navigate(["/console", "search"], {
      queryParams: { s: this.s() ?? "", t: type },
      onSameUrlNavigation: "reload",
      preserveFragment: false,
    });
  }

  public getLabel(
    type: Lowercase<SearchResultItemType>,
    data: { itemType?: number; subType?: number; id?: number; planId?: number }
  ) {
    if (type === "attachment" || type === "document") return "services.attachments";
    if (type === "government_direction") return "directions.government_directions";
    if (type === "sub_government_direction") return "directions.sub_government";
    if (type === "task") return "tasks.tasks";
    if (type === "tag") return "plusbutton.tags";
    if (type === "formula") return "formulas.formula_console_icon";
    if (type === "user") return "user.console";
    if (type === "pillars") return "entity.create.pillars";
    if (type === "service") return getServiceName(data.itemType as number, data.id as number).text;
    if (
      type === "kpi" ||
      type === "dtkpi" ||
      type === "ekpi" ||
      type === "mokpi" ||
      type === "mtkpi" ||
      type === "nkpi" ||
      type === "ntkpi" ||
      type === "opm" ||
      type === "skpi" ||
      type === "srvkpi" ||
      type === "uae"
    ) {
      const coreLabel = this._translateService.instant("kpi.kpis");
      const label = this._translateService.instant(this.getKpiName(data.itemType as number));
      return `${coreLabel} - ${label}`;
    }

    if (type === "initiative") {
      const projectTitle = this._translateService.instant("initiatives.project");
      const categoryTitle = this._translateService.instant(
        data.subType === Constants.CONSTANT_REGULAR_TASK_INITIATIVETYPE
          ? "initiatives.regular_task"
          : "initiatives.strategic_project"
      );
      return !useNewDS(data.planId) ? "breadcrumbs.initiative" : `${projectTitle} - ${categoryTitle}`;
    }

    if (type === "activity") {
      const projectTitle = this._translateService.instant("activities.activity");
      const categoryTitle = this._translateService.instant(
        data.subType === Constants.CONSTANT_OPERATIONAL_ACTIVITYTYPE ? "activities.operational" : "activities.strategic"
      );
      return `${projectTitle} ${useNewDS(data.planId) ? `- ${categoryTitle}` : ""}`;
    }

    if (type === "objective") {
      const projectTitle = this._translateService.instant("national_projects.projects");
      let categoryTitle = "";
      switch (data.subType) {
        case Constants.CONSTANT_ENABLER_OBJECTIVE: {
          categoryTitle = "breadcrumbs.enablerobjective";
          break;
        }
        case Constants.CONSTANT_MAIN_OBJECTIVE: {
          categoryTitle = "breadcrumbs.objectives.main";
          break;
        }
        case Constants.CONSTANT_STRATEGIC_OBJECTIVE: {
          categoryTitle = "breadcrumbs.objectives.strategic";
          break;
        }
        case Constants.CONSTANT_TRANSFORMATIONAL_OBJECTIVE: {
          categoryTitle = "breadcrumbs.objectives.transformational";
          break;
        }
        default:
          break;
      }
      return !useNewDS(data.planId)
        ? "objectives.objective"
        : `${projectTitle} - ${this._translateService.instant(categoryTitle)}`;
    }
    return "";
  }

  private _doSearch() {
    const searchTerm = this.s();
    const type = this.t();

    if (!searchTerm || searchTerm.length < 3) return;

    this.isLoading.set(true);
    this._searchApiService
      .doSearch({
        offset: this.offset,
        limit: this.limit,
        query: decodeURIComponent((searchTerm ?? "").toLocaleLowerCase()),
        type: (type ?? "") as SearchResultItemType,
      })
      .pipe(map(({ responseData }) => responseData))
      .subscribe({
        next: (data) => {
          this.systemLayoutService.searchTerm.set(searchTerm || null);
          this.data.set(data);
        },
        error: () => {
          this.isLoading.set(false);
        },
        complete: () => {
          this.isLoading.set(false);
        },
      });
  }

  private _refreshPage() {
    this.systemLayoutService.hasActiveEntityChanged$.pipe(this.#untilDestroy()).subscribe({
      next: () => window.location.reload(),
    });

    this.systemLayoutService.hasCycleChanged$.pipe(this.#untilDestroy()).subscribe({
      next: () => window.location.reload(),
    });
  }
}
