import { Component, inject, OnInit } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { AdaaHelper } from "../../../core/utils";
import {
  DataTableComponent,
  FloatActionComponent,
  FormActionButtonsComponent,
  FormDropdownComponent,
  FormDropdownTreeComponent,
  FormInputComponent,
  FormInputFileComponent,
  UserGrantsModalComponent,
  UserGroupsModalComponent,
  UserResponsiblitiesModalComponent,
} from "../../../shared/components";
import { genericFloatButtons } from "../../../shared/components/general/float-action";
import { Constants } from "../../../shared/constants/constants";
import { AdministrativeTitle, FileInputType, PageMode } from "../../../shared/constants/enums";
import {
  AttachFile,
  TableButtonClicked,
  UserEntitiesResponsibleFor,
  UserGrantModelType,
  UserGroupModelType,
} from "../../../shared/models";
import { LanguageService, SystemLayoutService, UserEditorService } from "../../../shared/services";

@Component({
  selector: "adaa-user-editor",
  standalone: true,
  imports: [
    TranslateModule,
    ReactiveFormsModule,
    DataTableComponent,
    FormActionButtonsComponent,
    FormInputComponent,
    FormDropdownComponent,
    FormDropdownTreeComponent,
    FormInputFileComponent,
    FloatActionComponent,
  ],
  templateUrl: "./user-editor.component.html",
  styleUrl: "./user-editor.component.scss",
})
export class UserEditorComponent implements OnInit {
  private _activatedRoute = inject(ActivatedRoute);
  private _modalService = inject(NgbModal);
  private _languageService = inject(LanguageService);
  private _router = inject(Router);
  private _toastrService = inject(ToastrService);
  private _translateService = inject(TranslateService);
  private _systemLayoutService = inject(SystemLayoutService);
  userEditorService = inject(UserEditorService);

  private readonly _genericFloatButtons = genericFloatButtons();
  private readonly _untilDestroyed = AdaaHelper.untilDestroyed();
  submitted = false;
  adaaHelper = AdaaHelper;
  userId: number | undefined = undefined;
  pageMode: PageMode;
  isViewMode: boolean = true;
  userForm: FormGroup;
  groups: UserGroupModelType[] = [];
  grants: UserGrantModelType[] = [];
  responsiblities: UserEntitiesResponsibleFor[] = [];
  allowedFiles: FileInputType[] = [
    FileInputType.bmp,
    FileInputType.gif,
    FileInputType.jpeg,
    FileInputType.jpg,
    FileInputType.png,
    FileInputType.svg,
  ];

  public get profileFile(): AttachFile {
    return {
      id: this.userForm.get("profilePictureId")?.value,
      filename: this.userForm.get("profilePicture")?.value
        ? this.userForm.get("profilePicture")?.value["filename"]
        : "",
    };
  }

  public get profileFileMessage(): string {
    if (this.pageMode === PageMode.view) return "";
    else
      return `${this._translateService.instant("entity.files_allowed")} <br> ${this._translateService.instant("user.max_file_size")} <br> ${this._translateService.instant("entity.recommended_image_dimensions")}`;
  }

  public ngOnInit(): void {
    this._systemLayoutService.hasActiveEntityChanged$.pipe(this._untilDestroyed()).subscribe(() => {
      this._router.navigateByUrl("/console/users");
    });

    this.pageMode = this._activatedRoute.snapshot.data.pageMode;
    this.isViewMode = this.pageMode === PageMode.view;
    this.userId = +this._activatedRoute.snapshot.params.id;

    this.userEditorService.initPage();
    this.userForm = this.userEditorService.prepareForm(this.pageMode);
    this._initPage();
    this._floatActions();
  }

  public onFilechange(event: AttachFile | null) {
    if (event) {
      this.userForm.get("profilePictureId")?.setValue(event.id);
      this.userForm.get("profilePicture")?.setValue({ filename: event.filename });
    } else {
      this.userForm.get("profilePictureId")?.setValue(null);
      this.userForm.get("profilePicture")?.setValue({ filename: "" });
    }
  }

  public onEntityChange(id: number): void {
    this.userEditorService.getOrgUnits(id);

    //Grants Modal
    this.userEditorService.getGrants(id);
  }

  public addUserGroups(): void {
    const modal = this._modalService.open(UserGroupsModalComponent, {
      centered: true,
      size: "xl",
      modalDialogClass: `modal-${this._languageService.direction()}`,
    });

    modal.componentInstance.selectedGroups = this.adaaHelper.clone(this.groups);

    modal.result.then((e) => {
      if (!this.adaaHelper.isDefined(e)) return;
      this.groups = e;
      this.userForm.get("userGroups")?.setValue(this.groups);
    });
  }

  public removeUserGroup(event: TableButtonClicked): void {
    this.groups = this.groups.filter((e) => e.id !== event.data.id);
    this.userForm.get("userGroups")?.setValue(this.groups);
  }

  public addUserGrant(): void {
    if (!this.adaaHelper.isDefined(this.grants)) this.grants = [];

    const modal = this._modalService.open(UserGrantsModalComponent, {
      centered: true,
      size: "xl",
      modalDialogClass: `modal-${this._languageService.direction()}`,
    });

    modal.componentInstance.entities = this.adaaHelper.clone(this.userEditorService.entities());
    modal.componentInstance.userGrants = this.adaaHelper.clone(this.grants);

    modal.result.then((e) => {
      if (!this.adaaHelper.isDefined(e)) return;

      this.grants = [...e];

      this.userForm.get("grants")?.setValue(this.grants);
    });
  }

  public removeUserGrant(event: TableButtonClicked): void {
    this.grants = this.grants.filter((e) => (event.data.id ? e.id !== event.data.id : e.fakeId !== event.data.fakeId));

    this.userForm.get("grants")?.setValue(this.grants);
  }

  public addResponsibility(): void {
    const modal = this._modalService.open(UserResponsiblitiesModalComponent, {
      centered: true,
      size: "xl",
      modalDialogClass: `modal-${this._languageService.direction()}`,
    });

    modal.componentInstance.selectedResponsiblities = this.adaaHelper.clone(this.responsiblities);
    modal.componentInstance.entities = this.userEditorService.entities().map((e) => {
      return {
        userId: this.userId,
        entityId: e.value,
        entityNameEN: e.text,
        entityNameAE: e.text,
      };
    });

    modal.result.then((e) => {
      if (!this.adaaHelper.isDefined(e)) return;
      this.responsiblities = e;
    });
  }

  public removeResponsibility(event: TableButtonClicked): void {
    this.responsiblities = this.responsiblities.filter((e) => e.entityId !== event.data.entityId);
  }

  public cancel(): void {
    this._router.navigate(["/console/users"]);
  }

  public submit(): void {
    this.submitted = true;
    if (!this.userForm.valid) {
      this._toastrService.warning(this._translateService.instant("notification.warning.missing_info"));
      return;
    }

    const grants = this.userForm.get("grants")?.value as UserGrantModelType[];
    if (grants == null || grants.length === 0) {
      this._toastrService.warning(this._translateService.instant("notification.warning.missing_info"));
      return;
    }

    const administrativeTitle = this.userForm.get("administrativeTitle")?.value;
    if (administrativeTitle) {
      this.userForm.get("directorGeneral")?.setValue(administrativeTitle === AdministrativeTitle.directorGeneral);
      this.userForm.get("minister")?.setValue(administrativeTitle === AdministrativeTitle.minister);
    }
    this.userEditorService.saveUser(this.userId, this.userForm.getRawValue(), this.responsiblities);
  }

  private _initPage(): void {
    if (this.userId) {
      this.userEditorService.getUser(this.userId).subscribe((user) => {
        if (!user) return;
        this.userForm.patchValue(user);
        this.groups = user.userGroups;
        this.grants = user.grants;

        this.userForm
          .get("administrativeTitle")
          ?.setValue(
            user.minister
              ? AdministrativeTitle.minister
              : user.directorGeneral
                ? AdministrativeTitle.directorGeneral
                : null
          );
      });

      this.userEditorService.getResponsiblities(this.userId).subscribe((responsiblities) => {
        this.responsiblities = responsiblities;
      });
    } else {
      const entityId = this.adaaHelper.getLocalStorage(Constants.localStorageKeys.currentEntity, {
        type: "prop",
        property: "id",
      }) as number | undefined;
      if (entityId) {
        if (!this.adaaHelper.isPMOEntity()) {
          this.userForm.get("entityId")?.setValue(entityId);
          this.userEditorService.getOrgUnits(entityId);
          this.userEditorService.getOrgUnits(entityId, true);
        }
        //Be Default don't get the PMO Roles
        this.userEditorService.getGrants(-1);
      }
    }
  }

  private _floatActions(): void {
    this._genericFloatButtons([
      {
        key: "helpdesk",
        data: {
          url: "/helpdesk/create",
        },
      },
      {
        key: "print",
        data: undefined,
      },
    ]);
  }
}
