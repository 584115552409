import {
  Component,
  computed,
  effect,
  ElementRef,
  inject,
  Injector,
  input,
  OnInit,
  signal,
  untracked,
  viewChild,
} from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import Handsontable from "handsontable/base";
import { GridSettings } from "handsontable/settings";
import { filter, map, pairwise, Subject, switchMap } from "rxjs";

import { AdaaBooleanType } from "../../../../../../adaa-types";
import { environment } from "../../../../../core/environments/environment";
import { AdaaHelper } from "../../../../../core/utils";
import { Constants } from "../../../../../shared/constants/constants";
import { AdaaBoolean, Language, PageMode } from "../../../../../shared/constants/enums";
import {
  KpiMetricModelType,
  KpiTargetModelType,
  ObjectStatus,
  PeriodModelType,
  TextMapModelType,
} from "../../../../../shared/models";
import { LanguageService, PeriodApiService } from "../../../../../shared/services";
import { isStartGreaterThanEnd } from "../../utils/form-groups/lib";

@Component({
  selector: "adaa-kpi-targets",
  standalone: true,
  imports: [TranslateModule],
  styleUrl: "./styles.scss",
  template: `
    <div class="w-100 d-flex justify-content-center align-content-center card mb-2 p-3 bg-white border-0 form-section">
      @if (hasErrors()) {
        @if (hasMissingDetails().fieldsMissing) {
          <div class="alert alert-danger my-1 border-0 rounded-1 fw-bold" role="alert">
            <i class="fa-solid fa-triangle-exclamation me-2" role="img" aria-label="Warning:"></i>
            {{ "nkpi.no_formula_info" | translate }}
          </div>
        }
        @if (hasMissingDetails().frequency) {
          <div class="alert alert-danger my-1 border-0 rounded-1 fw-bold d-flex align-items-center" role="alert">
            <i class="fa-solid fa-triangle-exclamation mx-2" role="img" aria-label="Warning:"></i>
            {{ "nkpi.frequency_missing" | translate }}
          </div>
        }
        @if (hasMissingDetails().formulaStatus) {
          <div class="alert alert-danger my-1 border-0 rounded-1 fw-bold d-flex align-items-center" role="alert">
            <i class="fa-solid fa-triangle-exclamation mx-2" role="img" aria-label="Warning:"></i>
            {{ "nkpi.invalid_formula" | translate }}
          </div>
        }
        @if (hasMissingDetails().dates) {
          <div class="alert alert-danger my-1 border-0 rounded-1 fw-bold d-flex align-items-center" role="alert">
            <i class="fa-solid fa-triangle-exclamation mx-2" role="img" aria-label="Warning:"></i>
            {{ "notification.error.dates_difference" | translate }}
          </div>
        }
        @if (hasMissingDetails().periods) {
          <div class="alert alert-danger my-1 border-0 rounded-1 fw-bold d-flex align-items-center" role="alert">
            <i class="fa-solid fa-triangle-exclamation mx-2" role="img" aria-label="Warning:"></i>
            {{ "notification.error.no_periods" | translate }}
          </div>
        }
      }

      @if (targetsInReview()) {
        <div class="alert alert-warning my-1 border-0 rounded-1 fw-bold d-flex align-items-center" role="alert">
          <i class="fa-solid fa-triangle-exclamation mx-2" role="img" aria-label="Warning:"></i>
          {{ "kpi.targets_in_review" | translate }}
        </div>
      }

      @if (isTabActive()) {
        <section class="hot-table" #hotTable></section>
      }
    </div>
  `,
})
export class KpiTargetsComponent implements OnInit {
  private readonly _injector = inject(Injector);
  private readonly _languageService = inject(LanguageService);
  private readonly _periodApiService = inject(PeriodApiService);

  textMaps = input<TextMapModelType[]>([]);
  stagedKpi = input<Record<string, unknown>>();
  kpi = input<Record<string, unknown>>();
  kpiType = input.required<number>();
  pageMode = input.required<PageMode>();
  isTabActive = input.required<boolean>();

  hotTable = viewChild<ElementRef>("hotTable");

  hasChanges = signal<boolean>(false);
  periods = signal<PeriodModelType[]>([]);
  kpiTargets = signal<KpiTargetModelType[]>([]);
  targetErrors = signal<KpiTargetModelType[]>([]);

  dt = computed(() => ({
    start: this.stagedKpi()?.startDate as number | null,
    end: this.stagedKpi()?.endDate as number | null,
  }));
  baselineYear = computed(() => this.stagedKpi()?.baselineYear as number);
  baselineKpi = computed(() => this.stagedKpi()?.baselineKpi as AdaaBooleanType);
  frequency = computed(() => this.stagedKpi()?.frequency as number | null);
  isSkpi = computed(() => this.kpiType() === Constants.KPI_TYPE.SKPI);
  targetsInReview = computed(() => this.stagedKpi()?.targetsInReview as boolean);
  kpiIsBounded = computed(() => this.stagedKpi()?.trend === Constants.CONSTANT_TREND_BOUNDED);
  targets = computed(() => this.kpi()?.targets as KpiTargetModelType[]);
  stagedTargets = computed(() => this.stagedKpi()?.targets as KpiTargetModelType[]);
  metrics = computed(() => this.stagedKpi()?.metrics as KpiMetricModelType[]);
  formulaStatus = computed(() => this.stagedKpi()?.formulaStatus as AdaaBooleanType | null);
  visionTarget = computed(() => this.stagedKpi()?.visionTarget as number);
  hasErrors = computed(() => Object.keys(this.hasMissingDetails()).length > 0);
  isDTKPI = computed(() => this.kpiType() === Constants.KPI_TYPE.DTKPI);
  isNTKPI = computed(() => this.kpiType() === Constants.KPI_TYPE.NTKPI);
  isMTKPI = computed(() => this.kpiType() === Constants.KPI_TYPE.MTKPI);
  isEKPI = computed(() => this.kpiType() === Constants.KPI_TYPE.EKPI);
  isMOKPI = computed(() => this.kpiType() === Constants.KPI_TYPE.MOKPI);
  isEKPIDisabled = computed(
    () =>
      this.pageMode() === PageMode.edit &&
      this.isEKPI() &&
      this.stagedKpi()?.targetsInputEntityId !== AdaaHelper.entity.id
  );
  hasMissingDetails = computed(() => {
    const errors: Record<string, true> = {};
    if (!AdaaHelper.isDefined(this.frequency())) {
      errors.frequency = true;
    }
    if (!AdaaHelper.isDefined(this.formulaStatus()) || this.formulaStatus() !== AdaaBoolean.Y) {
      errors.formulaStatus = true;
    }

    const { start, end } = this.dt();
    if (!start || !end) {
      errors.fieldsMissing = true;
    }
    if (isStartGreaterThanEnd(start, end)) {
      errors.dates = true;
    }
    if (!this.periods()?.length) {
      errors.periods = true;
    }

    return errors;
  });
  hasPermissionToUpdate = computed(() => {
    const kpi = this.stagedKpi();
    const entityId = kpi?.targetsInputEntityId;
    const linkedKpiId = kpi?.linkedKpiId;

    if (this.isSkpi() && AdaaHelper.isDefined(linkedKpiId)) return true;
    if (!AdaaHelper.isDefined(entityId)) return true;

    return entityId === AdaaHelper.entity?.id;
  });

  readonly #untilDestroy = AdaaHelper.untilDestroyed();
  readonly #onChangeEvent = new Subject<{ eType: string; eData: unknown }>();
  readonly #onChange$ = this.#onChangeEvent.asObservable().pipe(pairwise());

  readonly #getQuarterlyLabel = (quarter: number) => {
    if (quarter === 1) return this._languageService.translate("targets.periods.quarters.1");
    if (quarter === 2) return this._languageService.translate("targets.periods.quarters.2");
    if (quarter === 3) return this._languageService.translate("targets.periods.quarters.3");
    return this._languageService.translate("targets.periods.quarters.4");
  };
  readonly #getMonthlyLabel = (epoch: number) => {
    const dt = new Intl.DateTimeFormat(this._languageService.current() === Language.Arabic ? "ar-AR" : "en-GB", {
      month: "long",
      localeMatcher: "best fit",
      timeZone: Constants.uaeTimezoneName,
    });

    return dt.format(epoch);
  };
  readonly #refreshPeriodList = () => {
    return switchMap(([_, { eData }]) => {
      const data = eData as { startTS: number; endTS: number; frequency: number };

      return this._fetchPeriodRange(data);
    });
  };
  readonly #hasDataChanged = () => {
    return filter(([old$, new$]) => {
      const oldData = old$.eData as { startTS: number; endTS: number; frequency: number };
      const newData = new$.eData as { startTS: number; endTS: number; frequency: number };

      if (oldData.frequency !== newData.frequency) return true;
      if (oldData.startTS !== newData.startTS) return true;
      return oldData.endTS !== newData.endTS;
    });
  };
  readonly #hansontableEffect = () => {
    effect(
      () => {
        if (this.isTabActive() && !this.hotInstance) {
          this._initHandsontable();
        } else {
          this.hotInstance = undefined;
        }
      },
      { injector: this._injector }
    );
  };
  readonly #periodChangeEffect = () => {
    effect(
      () => {
        const { start, end } = this.dt();
        const frequency = this.frequency();
        const errors = this.hasMissingDetails();

        untracked(() => {
          if (errors.dates || errors.fieldsMissing || errors.frequency || errors.formulaStatus) return;

          if (!this.periods().length) {
            this._fetchPeriodRange({
              frequency: frequency as number,
              startTS: start as number,
              endTS: end as number,
            }).subscribe({
              next: (data) => this.periods.set(data ?? []),
              complete: () => {
                this._initTargetsList();
              },
            });
          }

          this.#onChangeEvent.next({
            eType: "period-change",
            eData: {
              frequency: frequency as number,
              startTS: start as number,
              endTS: end as number,
            },
          });
        });
      },
      { injector: this._injector }
    );
  };

  hotInstance: Handsontable | undefined;

  constructor() {
    this.#hansontableEffect();
    this.#periodChangeEffect();
    effect(() => this.showCellsWithError());
    effect(() => this._appendVisionTarget(this.visionTarget()));
  }

  public ngOnInit() {
    this._onPeriodChange();
  }

  public showCellsWithError() {
    const errors = this.targetErrors();
    const targets = this.kpiTargets();

    this.hotInstance?.batch(() => {
      errors.forEach(({ id: periodId }) => {
        const i = targets.findIndex(({ id }) => periodId === id);
        const { lowerLimit } = targets[i];

        let col: number;
        if (!this.kpiIsBounded()) col = 0;
        else {
          if (!AdaaHelper.isDefined(lowerLimit)) col = 0;
          else col = 1;
        }

        const place: [row: number, col: number] = [i, col];
        this.hotInstance?.setCellMeta(...place, "valid", false);
        this.hotInstance?.setCellMeta(...place, "className", "has-error");
      });
    });

    this.hotInstance?.render();
  }

  public submitTargets(reportError = true) {
    const kpi = this.stagedKpi();

    // NOTE: on create error detection
    if (this.pageMode() === PageMode.create) {
      if (this.baselineKpi() === AdaaBoolean.Y) {
        return {
          targetWasChanged: true,
          targets: this.kpiTargets().map((t) => {
            if (this.baselineKpi() === AdaaBoolean.Y) {
              return { ...t, value: null, lowerLimit: null, highLimit: null };
            } else {
              return t;
            }
          }),
        };
      }

      const errors = this.kpiTargets().filter(({ value, lowerLimit, highLimit }) => {
        if (this.kpiIsBounded()) return !AdaaHelper.isDefined(lowerLimit) || !AdaaHelper.isDefined(highLimit);
        return !AdaaHelper.isDefined(value);
      });

      //Ignore The Targets Validation for EKPIs
      if (errors.length > 0 && !this.isEKPI()) {
        this.targetErrors.set(errors);
        throw new Error("Missing Targets");
      } else this.targetErrors.set([]);

      return {
        targets: this.kpiTargets(),
        targetWasChanged: true,
      };
    }

    // NOTE: error detection for non-baseline KPI
    if (this.baselineKpi() === AdaaBoolean.N || !AdaaHelper.isDefined(this.baselineKpi())) {
      const errors = this.kpiTargets().filter(({ value, lowerLimit, highLimit }) => {
        if (this.kpiIsBounded()) {
          return !AdaaHelper.isDefined(lowerLimit) || !AdaaHelper.isDefined(highLimit);
        }
        return !AdaaHelper.isDefined(value);
      });

      //Ignore The Targets Validation for EKPIs
      if (errors.length > 0 && reportError && !this.isEKPI()) {
        this.targetErrors.set(errors);
        throw new Error("Missing Targets");
      } else this.targetErrors.set([]);
    }

    // NOTE: error detection for baseline KPI
    if (
      this.baselineKpi() === AdaaBoolean.Y &&
      kpi?.status !== ObjectStatus.DRAFT &&
      this.baselineYear() < new Date(AdaaHelper.getDubaiTime(Date.now())).getFullYear()
    ) {
      const errors = this.kpiTargets().filter(({ value, lowerLimit, highLimit, year }) => {
        if (this.baselineYear() === year) {
          return false;
        }

        if (this.kpiIsBounded()) {
          return !AdaaHelper.isDefined(lowerLimit) || !AdaaHelper.isDefined(highLimit);
        }
        return !AdaaHelper.isDefined(value);
      });

      //Ignore The Targets Validation for EKPIs
      if (errors.length > 0 && reportError && !this.isEKPI()) {
        this.targetErrors.set(errors);
        throw new Error("Missing Targets");
      } else this.targetErrors.set([]);
    }

    let hasAnyChanges = false;

    this.kpiTargets().forEach((p) => {
      const t = ((kpi?.targets ?? []) as Record<string, unknown>[]).find(({ id }) => id === p.id);
      // NOTE: if the target entry is not found in the original KPI
      if (!t) {
        hasAnyChanges = true;
        return;
      }

      if (this.kpiIsBounded()) hasAnyChanges = t.lowerLimit !== p.lowerLimit || t.highLimit !== p.highLimit;
      else hasAnyChanges = t.value !== p.value;
    });

    return {
      targetWasChanged: hasAnyChanges,
      targets: this.kpiTargets().map((t) => {
        // NOTE: if is baselineKPI and year is baselineYear. show `null`
        if (this.baselineKpi() === AdaaBoolean.Y && this.baselineYear() === t.year) {
          return { ...t, value: null, lowerLimit: null, highLimit: null };
        } else {
          return t;
        }
      }),
    };
  }

  private _onPeriodChange() {
    this.#onChange$
      .pipe(
        filter(([_, $event]) => $event.eType === "period-change"),
        this.#hasDataChanged(),
        this.#refreshPeriodList(),
        this.#untilDestroy()
      )
      .subscribe({
        next: (data) => {
          this.periods.set(data ?? []);
          this._initTargetsList();
        },
      });
  }

  private _fetchPeriodRange(data: { startTS: number; endTS: number; frequency: number }) {
    return this._periodApiService.getDateRange(data).pipe(map((res) => res.responseData));
  }

  private _appendVisionTarget(visionTarget: number) {
    if (!AdaaHelper.isDefined(visionTarget)) return;
    if (this.isDTKPI() || this.isNTKPI() || this.isMTKPI() || this.isMOKPI()) {
      this.hotInstance?.batch(() => {
        const row = this.kpiTargets()?.length - 1;
        const frequency = this.frequency();

        const fn = () => {
          if (!this.kpiIsBounded()) {
            this.hotInstance?.setDataAtCell(row, 0, `${visionTarget}`);
          } else {
            this.hotInstance?.setDataAtCell(row, 0, `${visionTarget}`);
            this.hotInstance?.setDataAtCell(row, 1, `${visionTarget}`);
          }
        };

        if (frequency && frequency >= Constants.FREQUENCY_ANNUAL) {
          const lastTarget = this.kpiTargets()[row];
          const { end } = this.dt();
          if (!end) return;
          const year = new Date(AdaaHelper.getDubaiTime(end)).getFullYear();
          if (lastTarget.year === year) fn();
        }
      });

      this.hotInstance?.render();
    }
  }

  private _initTargetsList() {
    const targets = this.stagedTargets() ?? [];

    this.kpiTargets.set(
      this.periods().map<KpiTargetModelType>((period) => {
        const data = targets.find(({ id }) => period.id === id);

        let value: Pick<KpiTargetModelType, "lowerLimit" | "highLimit" | "value">;
        if (this.kpiIsBounded()) {
          value = {
            lowerLimit: data?.lowerLimit as number | null,
            highLimit: data?.highLimit as number | null,
            value: null,
          };
        } else {
          value = {
            lowerLimit: null,
            highLimit: null,
            value: data?.value as number | null,
          };
        }

        return {
          ...value,
          frequency: this.frequency()!,
          date: period.date,
          day: period.day,
          month: period.month,
          quarter: period.quarter,
          semester: period.semester,
          year: period.year,
          targetOverride: data?.targetOverride ?? false,
          wasChanged: data?.wasChanged ?? false,
          id: period.id,
          periodId: period.id,
          readingId: data?.readingId,
          status: data?.status ?? ObjectStatus.DRAFT,
          average: data?.average ?? null,
          entities: [],
          highLimitAverage: data?.highLimitAverage ?? null,
          lowerLimitAverage: data?.lowerLimitAverage ?? null,
          revId: data?.revId,
          readingType: data?.readingType ?? "T",
          wfProcessCtlId: data?.wfProcessCtlId ?? undefined,
        } satisfies KpiTargetModelType;
      })
    );
  }

  private _getPeriodLabel(item: KpiTargetModelType) {
    const frequency = this.frequency();

    const annualBasedLabel =
      frequency === Constants.FREQUENCY_ANNUAL ||
      frequency === Constants.FREQUENCY_EVERY_TWO_YEARS ||
      frequency === Constants.FREQUENCY_EVERY_THREE_YEARS ||
      frequency === Constants.FREQUENCY_EVERY_FOUR_YEARS ||
      frequency === Constants.FREQUENCY_EVERY_FIVE_YEARS;
    if (annualBasedLabel) return `${item.year}`;

    if (frequency === Constants.FREQUENCY_SEMIANNUAL && this._languageService.current() === Language.Arabic) {
      const label = this._languageService.translate(`targets.periods.semestral.${item.semester}`);
      return `${label} ${item.year}`;
    }

    if (frequency === Constants.FREQUENCY_SEMIANNUAL && this._languageService.current() === Language.English) {
      const label = this._languageService.translate("targets.semiannual");
      return `${label} ${item.semester} ${item.year}`;
    }

    if (frequency === Constants.FREQUENCY_QUARTERLY) {
      return `${item.year} ${this.#getQuarterlyLabel(item.quarter)}`;
    }

    return this.#getMonthlyLabel(item.date) + " " + item.year;
  }

  private _initHandsontable() {
    if (this.hasErrors()) return;

    const container = this.hotTable()?.nativeElement as Element;
    const options = this._getOptions();

    if (container) {
      this.hotInstance = new Handsontable(container, options);
      this.hotInstance.updateSettings({
        cells: (row, _col, _prop) => {
          const index = this.periods().length - 1;
          const freq = this.frequency();
          const applyVisionTargetRule = freq && freq >= Constants.FREQUENCY_ANNUAL;
          let readOnly = false;

          if (this.targetsInReview()) {
            return {
              readOnly: true,
              className: "htDimmed readonly-cell",
            };
          }

          if (this.baselineKpi() === AdaaBoolean.Y && this.kpiTargets()[row].year === this.baselineYear()) {
            readOnly = true;
          } else if (
            (this.isDTKPI() || this.isNTKPI() || this.isMTKPI() || this.isMOKPI()) &&
            index === row &&
            AdaaHelper.isDefinedAndNotEmpty(this.visionTarget()) &&
            applyVisionTargetRule
          ) {
            const lastPeriod = this.periods()[index];
            const { end } = this.dt();
            if (end) {
              const year = new Date(AdaaHelper.getDubaiTime(end)).getFullYear();
              readOnly = lastPeriod.year === year;
            }
          }

          if (this.isNTKPI() && !AdaaHelper.isPMOEntity()) {
            readOnly = true;
          }

          if (this.isEKPIDisabled()) {
            readOnly = true;
          }

          return {
            readOnly,
            className: readOnly ? "htDimmed readonly-cell" : undefined,
          };
        },
      });

      this.showCellsWithError();
      this._appendVisionTarget(this.visionTarget());
    }
  }

  private _generateColumns() {
    if (!this.kpiIsBounded()) {
      return {
        columns: [
          { readonly: this.targetsInReview() || this.hasPermissionToUpdate() }, //value
        ],
        columnWidths: [400],
        columnLabels: [this._languageService.translate("nkpi.targets")],
      };
    }

    return {
      columns: [
        { readonly: this.targetsInReview() || this.hasPermissionToUpdate() }, // lower-limit
        { readonly: this.targetsInReview() || this.hasPermissionToUpdate() }, // high-limit
      ],
      columnWidths: [400, 400],
      columnLabels: [
        this._languageService.translate("kpi.lower_limit"),
        this._languageService.translate("kpi.upper_limit"),
      ],
    };
  }

  private _generateTableData() {
    return this.kpiTargets().map((i) => {
      if (!this.kpiIsBounded()) {
        return [i.value];
      }
      return [i.lowerLimit, i.highLimit];
    });
  }

  private _getOptions(): GridSettings {
    const currentLang = this._languageService.current();
    const data = this._generateTableData();
    const { columnWidths, columns, columnLabels } = this._generateColumns();

    return {
      data,
      rowHeaders: this.kpiTargets().map((i) => this._getPeriodLabel(i)),
      rowHeaderWidth: 300,
      colHeaders: columnLabels,
      columns: columns,
      colWidths: columnWidths,
      stretchH: "all",
      height: window.innerHeight * 0.6,
      viewportColumnRenderingOffset: 999999,
      viewportRowRenderingOffset: 999999,
      hiddenColumns: true,
      layoutDirection: this._languageService.direction(),
      language: currentLang === Language.Arabic ? "ar-AR" : "en-US",
      licenseKey: environment.handsontable_key,
      afterChange: (changes, _source) => this._onChange(changes),
    };
  }

  private _onChange(c: Handsontable.CellChange[] | null) {
    if (!AdaaHelper.isDefined(c)) return;

    this.hasChanges.set(true);

    for (const _c of c) {
      const [period, target, prev, curr] = _c;

      this.kpiTargets.update((t) => {
        t[period].wasChanged = AdaaHelper.tableValuesEvaluator(prev) !== AdaaHelper.tableValuesEvaluator(curr);
        if (!this.kpiIsBounded()) {
          t[period].value = AdaaHelper.tableValuesEvaluator(curr);

          return t;
        }

        if (target === 0) {
          t[period].lowerLimit = AdaaHelper.tableValuesEvaluator(curr);

          return t;
        }

        t[period].highLimit = AdaaHelper.tableValuesEvaluator(curr);

        return t;
      });
    }
  }
}
