import { Route } from "@angular/router";

import { manageAuditActualsPageGuards, viewAuditActualsPageGuards } from "./_guards";

export const AUDIT_ACTUALS_ROUTES: Route[] = [
  {
    path: "data-entry/kpi",
    canActivate: [...viewAuditActualsPageGuards],
    children: [
      {
        path: "",
        data: {
          title: "breadcrumbs.audit_data_module.kpi_actuals",
          breadcrumb: "breadcrumbs.audit_data_module.kpi_actuals",
        },
        loadComponent: () =>
          import("./audit-actuals-list/audit-actuals-list.component").then((e) => e.AuditActualsListComponent),
      },
      {
        path: "edit/:id",
        canActivate: [...manageAuditActualsPageGuards],
        data: {
          title: "data_entry.audited_data_entry_title",
          breadcrumb: "data_entry.audited_data_entry_title",
        },
        loadComponent: () =>
          import("./audit-actuals-editor/audit-actuals-editor.component").then((e) => e.AuditActualsEditorComponent),
      },
    ],
  },
];
