import type { Route } from "@angular/router";

import { checkIfLoggedInFn } from "../../core/guards";
import { viewLookupManagementPageGuards } from "./_guards";
import { LookupContentListComponent } from "./lookup-content-list/lookup-content-list.component";
import { LookupManagementListComponent } from "./lookup-management-list/lookup-management-list.component";

export const LOOKUP_ROUTES: Route[] = [
  {
    path: "lookup",
    canActivate: [checkIfLoggedInFn, ...viewLookupManagementPageGuards],
    children: [
      {
        path: "",
        component: LookupManagementListComponent,
        canActivate: [checkIfLoggedInFn, ...viewLookupManagementPageGuards],
        data: {
          title: "lookup.management.title",
          breadcrumb: "lookup.management.title",
        },
      },
      {
        path: ":id",
        component: LookupContentListComponent,
        canActivate: [checkIfLoggedInFn, ...viewLookupManagementPageGuards],
        data: {
          title: "nkpi.attachmentType",
          breadcrumb: { alias: "content" },
        },
      },
    ],
  },
];
