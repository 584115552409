import {
  Component,
  computed,
  effect,
  EventEmitter,
  inject,
  Injector,
  input,
  OnInit,
  Output,
  signal,
} from "@angular/core";
import { ControlContainer, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { TranslatePipe, TranslateService } from "@ngx-translate/core";
import { filter, forkJoin, ignoreElements, map, switchMap } from "rxjs";
import { tap } from "rxjs/operators";

import { AdaaHelper, useNewDS } from "../../../../../../../core/utils";
import { FormDropdownComponent } from "../../../../../../../shared/components";
import { Constants } from "../../../../../../../shared/constants/constants";
import { FormControlDisabledDirective } from "../../../../../../../shared/directives";
import { ObjectiveModelType, ValueText } from "../../../../../../../shared/models";
import { ObjectivesApiService } from "../../../../../../../shared/services";

@Component({
  selector: "adaa-objectives-field",
  standalone: true,
  imports: [FormControlDisabledDirective, FormDropdownComponent, ReactiveFormsModule, TranslatePipe],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true }),
    },
  ],
  template: `
    <section class="d-flex row">
      <div class="d-block col-12">
        <adaa-form-dropdown
          [controlName]="FIELD_NAME"
          [formControlName]="FIELD_NAME"
          [clearable]="true"
          [searchable]="true"
          [label]="label() | translate"
          [options]="objectiveOptions()"
          [invalid]="model.get(FIELD_NAME)?.touched! && model.get(FIELD_NAME)?.invalid!"
          [required]="isRequired()"
          [adaaInputDisabled]="isDisabled()"
        />
      </div>
    </section>
  `,
})
export class ObjectivesFieldComponent implements OnInit {
  readonly form = inject(ControlContainer);

  private readonly _injector = inject(Injector);
  private readonly _translateService = inject(TranslateService);
  private readonly _objectivesApiService = inject(ObjectivesApiService);

  isRequired = input.required<boolean>();
  isDisabled = input.required<boolean>();
  kpiType = input.required<number>();
  label = input.required<string>();

  objectives = signal<ObjectiveModelType[]>([]);
  objectiveOptions = signal<ValueText[]>([]);

  isSKPI = computed(() => this.kpiType() === Constants.KPI_TYPE.SKPI);
  isSRVKPI = computed(() => this.kpiType() === Constants.KPI_TYPE.SRVKPI);

  @Output() onChange = new EventEmitter<{ value: number; list: ObjectiveModelType[] }>();

  readonly FIELD_NAME = "objectiveId";

  readonly #untilDestroy = AdaaHelper.untilDestroyed();
  readonly #disableInputEffect = () => {
    effect(
      (onCleanup) => {
        const disable = this.isDisabled();
        if (disable) {
          this.model.controls[this.FIELD_NAME]?.disable({ onlySelf: true, emitEvent: true });
        } else {
          this.model.controls[this.FIELD_NAME]?.enable({ onlySelf: true, emitEvent: true });
        }

        onCleanup(() => {
          this.model.controls[this.FIELD_NAME]?.enable({ onlySelf: true, emitEvent: true });
        });
      },
      { injector: this._injector }
    );
  };

  public get model() {
    return this.form.control as FormGroup;
  }

  constructor() {
    this.#disableInputEffect();
  }

  public ngOnInit() {
    this._outputChanges();

    if (this.isSKPI()) {
      this._queryTranslations().subscribe();
    } else {
      this._queryObjectives().subscribe({
        next: () => {
          this.objectiveOptions.set(
            AdaaHelper.setDropdownArray(this.objectives(), "id", AdaaHelper.getFieldLanguage("name"))
          );
        },
      });
    }
  }

  private _outputChanges() {
    this.model.controls[this.FIELD_NAME]?.valueChanges
      ?.pipe(
        this.#untilDestroy(),
        filter((value) => AdaaHelper.isDefined(value))
      )
      .subscribe({
        next: () => {
          const value = this.model.controls[this.FIELD_NAME]?.getRawValue();
          this.onChange.emit({
            value,
            list: this.objectives(),
          });
        },
      });
  }

  private _queryObjectives() {
    let obs$ = this._objectivesApiService.getAllEnabler();
    if (this.isSKPI()) {
      obs$ = this._objectivesApiService.getObjectivesByEntityAndPlan({
        entityId: AdaaHelper.entity?.id,
        planId: AdaaHelper.plan?.id,
      });
    } else if (this.isSRVKPI()) {
      obs$ = this._objectivesApiService.getObjectivesLinkedToMainServices();
    }

    return obs$.pipe(
      filter((res) => !res.inError),
      map((res) => res.responseData),
      tap({
        next: (data) => this.objectives.set(data ?? []),
      })
    );
  }

  private _queryTranslations() {
    const obs$ = forkJoin([
      this._translateService.get("breadcrumbs.objectives.main"),
      this._translateService.get("breadcrumbs.objectives.strategic"),
    ]);

    return obs$.pipe(
      switchMap(([mainTxt, strategicTxt]) => {
        return this._queryObjectives().pipe(map(() => [mainTxt, strategicTxt]));
      }),
      tap({
        next: ([mainTxt, strategicTxt]) => {
          if (!useNewDS()) {
            this.objectiveOptions.set(
              AdaaHelper.setDropdownArray(this.objectives(), "id", AdaaHelper.getFieldLanguage("name"))
            );
            return;
          }

          const options = this.objectives().map((item) => {
            const value = item.id;

            let objectiveType: string;
            if (item.objectiveType === Constants.CONSTANT_MAIN_OBJECTIVE) objectiveType = mainTxt;
            else objectiveType = strategicTxt;

            return {
              value,
              text: `${AdaaHelper.getItemValueByToken(item, "name")} - (${objectiveType})`,
            } as ValueText;
          });

          this.objectiveOptions.set(options);
        },
      }),
      ignoreElements()
    );
  }
}
