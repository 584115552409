<div class="d-flex gap-2">
    <p class="fw-bold fs-3">{{ "landingPage.pillars.title" | translate }} -</p>
    <p class="fw-bold fs-3">{{ displayLabel() }}</p>
</div>

<div class="bg-white shadow-md rounded p-2 mb-3">
    <form class="col-12" [formGroup]="pillarForm">
        <div class="bg-white shadow-md rounded p-2 mb-3">
            <div class="row">
                <div class="col-12">
                    <adaa-form-input
                        [label]="'common.form.label.refcode' | translate"
                        [setValidator]="pillarEditorService.formValidation()!"
                        formControlName="refCode"
                        controlName="refCode"
                        toUppercase
                        alphabetOnly
                    ></adaa-form-input>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.nameAE' | translate"
                        [setValidator]="pillarEditorService.formValidation()!"
                        [invalid]="checkInvalid('nameAE')"
                        formControlName="nameAE"
                        controlName="nameAE"
                    ></adaa-form-input>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.nameEN' | translate"
                        [setValidator]="pillarEditorService.formValidation()!"
                        [invalid]="checkInvalid('nameEN')"
                        formControlName="nameEN"
                        controlName="nameEN"
                    ></adaa-form-input>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.descriptionAE' | translate"
                        [setValidator]="pillarEditorService.formValidation()!"
                        [invalid]="checkInvalid('dscAE')"
                        formControlName="dscAE"
                        controlName="dscAE"
                        [isTextArea]="true"
                    ></adaa-form-input>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.descriptionEN' | translate"
                        [setValidator]="pillarEditorService.formValidation()!"
                        [invalid]="checkInvalid('dscEN')"
                        formControlName="dscEN"
                        controlName="dscEN"
                        [isTextArea]="true"
                    ></adaa-form-input>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12 d-flex justify-content-center">
                    <adaa-form-checkbox
                        [label]="'common.form.label.available' | translate"
                        [checkboxMode]="'yesNo'"
                        controlName="available"
                        formControlName="available"
                    ></adaa-form-checkbox>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <adaa-form-two-way-select
                        [notSelectedLabel]="'rules_management.entities' | translate"
                        [selectedLabel]="'rules_management.selected_entities' | translate"
                        [setValidator]="pillarEditorService.formValidation()!"
                        [options]="enablerEntityOptions()"
                        [isDisabled]="pillarForm.get('pillarEntities')?.disabled ?? false"
                        formControlName="pillarEntities"
                        controlName="pillarEntities"
                    ></adaa-form-two-way-select>
                </div>
            </div>
        </div>
    </form>
</div>

@if (setDisabled()) {
    <div class="col-12 mb-3">
        <div class="d-flex justify-content-center">
            <button type="submit" class="btn btn-primary" [routerLink]="['/console/pillars/list']">
                <span>{{ "common.form.button.close" | translate }}</span>
            </button>
        </div>
    </div>
} @else {
    <div class="col-12 mb-3">
        <adaa-form-action-buttons
            [saveButtonTitle]="'executive_teams.submit' | translate"
            (saveClicked)="updatePillar()"
            (cancelClicked)="cancelForm()"
        ></adaa-form-action-buttons>
    </div>
}
