<p class="fw-bold fs-4">{{ "khadamati.schedule.title" | translate }}</p>

@if (isDimensions()) {
    <div class="row">
        <div class="col-4 sm-col-12">
            <adaa-form-dropdown
                [label]="'dimension.choose_type' | translate"
                [options]="dimensionTypes()"
                [setDefaultValue]="1"
                (inputChanges)="getSelectedSchedule($event)"
            ></adaa-form-dropdown>
        </div>
        @if (dimensionType() === dimensionSyncTypes.STRUCTURE) {
            <p class="fw-bold fs-5 my-3 ms-2">{{ "dimension.dms_sync" | translate }}</p>
        }
        @if (dimensionType() === dimensionSyncTypes.VALUE) {
            <p class="fw-bold fs-5 my-3 ms-2">{{ "dimension.metric_values_sync" | translate }}</p>
        }
    </div>
}

<div class="page-panel bg-white shadow p-4 mb-5 mt-4">
    <div class="title fs-5 fw-bold">
        <span>{{ "khadamati.schedule.configuration" | translate }}</span>
    </div>
    <div class="row">
        <div class="col-12 col-md-4 mt-3">
            <div class="mx-2">
                <adaa-form-radio
                    [options]="monthlyradioOption()"
                    [setDefaultValue]="currentSchedule().frequency"
                    [styles]="'d-flex justify-content-between'"
                    (inputChanges)="setActive($event)"
                ></adaa-form-radio>
            </div>
            <form class="col-12" [formGroup]="monthlyForm">
                <div class="mx-2">
                    <div class="my-4">
                        <adaa-form-dropdown
                            [label]="'khadamati.schedule.dayOfMonth' | translate"
                            [options]="months()"
                            [controlName]="'dayOfMonth'"
                            [formControlName]="'dayOfMonth'"
                            [required]="true"
                            [invalid]="checkInvalid('dayOfMonth', 'monthlyForm')"
                        ></adaa-form-dropdown>
                    </div>
                    <div class="ms-2" [style.pointer-events]="monthlyForm.controls['time'].enabled ? 'all' : 'none'">
                        <adaa-form-input-time
                            [label]="'time.time' | translate"
                            [controlName]="'time'"
                            [formControlName]="'time'"
                            [disabled]="monthlyForm.controls['time'].disabled"
                            (inputChanges)="setTime($event)"
                            [required]="true"
                            [isDisabled]="monthlyForm.get('time')?.disabled ?? false"
                            [invalid]="checkInvalid('time', 'monthlyForm')"
                        ></adaa-form-input-time>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-12 col-md-4 mt-3">
            <div class="mx-2">
                <adaa-form-radio
                    [options]="weeklyRadioOption()"
                    [setDefaultValue]="currentSchedule().frequency"
                    [styles]="'d-flex justify-content-between'"
                    (inputChanges)="setActive($event)"
                ></adaa-form-radio>
            </div>
            <form class="col-12" [formGroup]="weeklyForm">
                <div class="mx-2">
                    <div class="my-4">
                        <adaa-form-dropdown
                            [label]="'khadamati.schedule.week.choose' | translate"
                            [options]="weeks()"
                            [controlName]="'dayOfWeek'"
                            [formControlName]="'dayOfWeek'"
                            [required]="true"
                            [invalid]="checkInvalid('dayOfWeek', 'weeklyForm')"
                        ></adaa-form-dropdown>
                    </div>
                    <div class="ms-2">
                        <adaa-form-input-time
                            [label]="'time.time' | translate"
                            [controlName]="'time'"
                            [formControlName]="'time'"
                            (inputChanges)="setTime($event)"
                            [required]="true"
                            [isDisabled]="weeklyForm.get('time')?.disabled ?? false"
                            [invalid]="checkInvalid('time', 'weeklyForm')"
                        ></adaa-form-input-time>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-12 col-md-4 mt-3">
            <div class="mx-2">
                <adaa-form-radio
                    [options]="dailyradioOption()"
                    [setDefaultValue]="currentSchedule().frequency"
                    [styles]="'d-flex justify-content-between'"
                    (inputChanges)="setActive($event)"
                ></adaa-form-radio>
            </div>
            <form class="col-12 mb-5" [formGroup]="dailyForm">
                <div class="mx-2 my-4">
                    <div [style.pointer-events]="dailyForm.controls['time'].enabled ? 'all' : 'none'">
                        <adaa-form-input-time
                            [label]="'time.time' | translate"
                            [controlName]="'time'"
                            [formControlName]="'time'"
                            (inputChanges)="setTime($event)"
                            [required]="true"
                            [isDisabled]="dailyForm.get('time')?.disabled ?? false"
                            [invalid]="checkInvalid('time', 'dailyForm')"
                        ></adaa-form-input-time>
                    </div>
                </div>
            </form>
            <div class="mx-2 mt-4">
                <adaa-form-radio
                    [options]="inactiveRadioOption()"
                    [setDefaultValue]="currentSchedule().frequency"
                    (inputChanges)="setActive($event)"
                ></adaa-form-radio>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-center mt-5">
        <button type="submit" class="btn btn-primary action-buttons" (click)="saveSyncSchedule()">
            <span class="fw-bold">{{ "common.form.button.save" | translate }}</span>
        </button>
    </div>
</div>

<div class="page-panel bg-white shadow my-2 p-4">
    <div class="title fs-5 fw-bold">
        <span>{{ "khadamati.manual_sync.title" | translate }}</span>
    </div>
    <div class="d-flex justify-content-center my-4">
        <button type="submit" class="btn btn-secondary action-buttons" [class.btn-danger]="!runSync()" (click)="startStopSync()">
            @if (runSync()) {
                @if (isDimensions()) {
                    @if (dimensionType() === dimensionSyncTypes.STRUCTURE) {
                        <span class="fw-bold">{{ "dimension.start_dms_sync" | translate }}</span>
                    }
                    @if (dimensionType() === dimensionSyncTypes.VALUE) {
                        <span class="fw-bold">{{ "dimension.start_metric_values_sync" | translate }}</span>
                    }
                } @else {
                    <span class="fw-bold">{{ "khadamati.schedule.start_sync" | translate }}</span>
                }
            } @else {
                <span class="fw-bold">{{ "khadamati.schedule.stop_sync" | translate }}</span>
            }
        </button>
    </div>
    <p class="text-center fw-bold">
        {{ "khadamati.last_sync_date" | translate }} <span dir="ltr">{{ lastDate() }}</span>
    </p>
</div>
