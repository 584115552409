import { NgClass } from "@angular/common";
import { Component, computed, inject, input, OnInit, signal } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { AdaaHelper } from "../../../core/utils";
import {
  ConfirmationModalComponent,
  FormActionButtonsComponent,
  FormCheckboxComponent,
  FormInputComponent,
} from "../../../shared/components";
import { UserRolesManagerComponent } from "../../../shared/components/user-roles-manager/user-roles-manager.component";
import { Constants } from "../../../shared/constants/constants";
import { AdaaBoolean, Language, PageMode } from "../../../shared/constants/enums";
import { UserRole, UserRolesPermission } from "../../../shared/models";
import { AppService, SystemLayoutService } from "../../../shared/services";
import { UserRolesApiService } from "../../../shared/services/user-roles-api.service";
import { UserRolesEditorService } from "../../../shared/services/user-roles-editor.service";

@Component({
  selector: "adaa-user-roles-editor",
  standalone: true,
  imports: [
    FormInputComponent,
    ReactiveFormsModule,
    TranslateModule,
    UserRolesManagerComponent,
    FormCheckboxComponent,
    FormActionButtonsComponent,
    NgClass,
  ],
  templateUrl: "./user-roles-editor.component.html",
  styleUrl: "./user-roles-editor.component.scss",
})
export class UserRolesEditorComponent implements OnInit {
  private _formBuilder = inject(FormBuilder);
  private _userRolesApiService = inject(UserRolesApiService);
  private _activatedRoute = inject(ActivatedRoute);
  private _modalService = inject(NgbModal);
  private _appService = inject(AppService);
  private _router = inject(Router);
  private _toastrService = inject(ToastrService);
  private _translateService = inject(TranslateService);
  private _systemLayoutService = inject(SystemLayoutService);
  appService = inject(AppService);
  userRolesEditorService = inject(UserRolesEditorService);

  private _untilDestroyed = AdaaHelper.untilDestroyed();
  roleId: string | null;
  userRolesForm: FormGroup = new FormGroup({});
  pageMode = input.required<string>();
  submitted = signal<boolean>(false);
  pmoOnly = signal<boolean>(false);
  role = signal<UserRole | undefined>(undefined);
  PageMode = PageMode;
  Language = Language;

  permissions = signal<UserRolesPermission[]>([]);
  filteredPermissions = computed(() => {
    return this.permissions().filter((permission) =>
      this.pmoOnly() ? permission.pmoOnly === AdaaBoolean.Y : permission.pmoOnly === AdaaBoolean.N
    );
  });

  valueChecked: boolean;
  pmoRoleChecked = computed(() => {
    const result = this.permissions().find(
      (item) =>
        item.pmoOnly === AdaaBoolean.Y &&
        (item.create === AdaaBoolean.Y ||
          item.view === AdaaBoolean.Y ||
          item.manage === AdaaBoolean.Y ||
          item.execute === AdaaBoolean.Y)
    )
      ? true
      : false;

    if (this.pageMode() !== PageMode.view) {
      if (result) this.userRolesForm.get("isPmoRole")?.patchValue(AdaaBoolean.Y);
      if (result) this.userRolesForm.get("isPmoRole")?.disable();
      if (!result) this.userRolesForm.get("isPmoRole")?.enable();
    }
    return result;
  });

  public ngOnInit() {
    this.userRolesEditorService.formValidator();
    this._prepareForm();
    this.roleId = this._activatedRoute.snapshot?.paramMap?.get("id");
    this._userRolesApiService.getPermissions().subscribe({
      next: (response) => this.permissions.set(response.responseData),
      complete: () => {
        if (this.pageMode() === PageMode.view || this.pageMode() === PageMode.edit) {
          this._getRole();
        }
      },
    });

    this._systemLayoutService.hasActiveEntityChanged$.pipe(this._untilDestroyed()).subscribe(() => {
      this._router.navigateByUrl("console");
    });
  }

  private _getRole() {
    if (this.roleId) {
      this._userRolesApiService.getRole(+this.roleId).subscribe({
        next: (response) => {
          this.role.set(response.responseData);
          this.userRolesForm.patchValue(response.responseData);
          this.userRolesForm.markAsPristine();
          this.userRolesForm.markAsUntouched();
          const map = new Map(response.responseData.permissions?.map((item) => [item.itemTypeId, item]));
          const res = this.permissions().map((item) => map.get(item.itemTypeId) || item);
          this.permissions.set(res);
        },
      });
    }
  }

  private _prepareForm() {
    const isViewMode = this.pageMode() === PageMode.view;

    this.userRolesForm = this._formBuilder.group({
      nameAE: new FormControl<string | undefined>(
        { value: "", disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      nameEN: new FormControl<string | undefined>(
        { value: "", disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      dscAE: new FormControl<string | undefined>({ value: "", disabled: isViewMode }),
      dscEN: new FormControl<string | undefined>({ value: "", disabled: isViewMode }),
      enabled: new FormControl<string | undefined>({ value: AdaaBoolean.Y, disabled: isViewMode }),
      isPmoRole: new FormControl<string | undefined>({ value: "", disabled: isViewMode }),
    });
  }

  public checkInvalid(control: string) {
    return (
      this.userRolesForm.controls[control].errors?.["required"] &&
      (this.userRolesForm.get(control)?.dirty || this.submitted())
    );
  }

  public setPermissions(permission: UserRolesPermission) {
    const permissions: UserRolesPermission[] = AdaaHelper.clone(this.permissions());
    const index = permissions.findIndex((item) => item.id === permission.id);
    permissions[index] = permission;

    this.permissions.set(permissions);
  }

  public selectAll(event: Event, value: string) {
    const inputElement = event.target as HTMLInputElement;
    const isChecked = inputElement.checked;
    const permissions: UserRolesPermission[] = AdaaHelper.clone(this.permissions());

    permissions.forEach((permission) => {
      if (this.pmoOnly() ? permission.pmoOnly === AdaaBoolean.Y : permission.pmoOnly === AdaaBoolean.N) {
        switch (value) {
          case "create":
            if (permission.createEnabled === AdaaBoolean.Y) {
              permission.create = isChecked ? AdaaBoolean.Y : AdaaBoolean.N;
            }
            break;
          case "view":
            if (permission.viewEnabled === AdaaBoolean.Y) {
              permission.view = isChecked ? AdaaBoolean.Y : AdaaBoolean.N;
            }
            break;
          case "manage":
            if (permission.manageEnabled === AdaaBoolean.Y) {
              permission.manage = isChecked ? AdaaBoolean.Y : AdaaBoolean.N;
            }
            break;
          case "execute":
            if (permission.executeEnabled === AdaaBoolean.Y) {
              permission.execute = isChecked ? AdaaBoolean.Y : AdaaBoolean.N;
            }
            break;
          default:
            break;
        }
      }
    });

    this.permissions.set(permissions);
  }

  public selectPmoRole(event: Event) {
    const target = event.target as HTMLInputElement;
    this.valueChecked = target.checked;
  }

  public submit() {
    this.submitted.set(true);
    if (this.userRolesForm.invalid) {
      this._toastrService.error(this._translateService.instant("common.form.label.missing_information"));
      return;
    }
    let valueChecked: string;

    if (!AdaaHelper.isDefined(this.valueChecked)) {
      valueChecked = this.pmoRoleChecked() ? AdaaBoolean.Y : AdaaBoolean.N;
    } else if (!this.pmoRoleChecked()) {
      valueChecked = this.valueChecked === true ? AdaaBoolean.Y : AdaaBoolean.N;
    } else {
      valueChecked = AdaaBoolean.Y;
    }

    const result = {
      ...this.role(),
      ...this.userRolesForm.value,
      permissions: this.permissions(),
      isPmoOnly: AdaaBoolean.N,
      isPmoRole: this.userRolesForm.value.isPmoRole ?? valueChecked,
      status: Constants.OBJECT_STATUS.ACTIVE,
    };

    const url =
      this.pageMode() === PageMode.create
        ? this._userRolesApiService.createRole(result)
        : this._userRolesApiService.updateRole(result);
    url.subscribe({
      next: (response) => {
        if (response.inError) return;
        this._toastrService.success(this._translateService.instant("notification.success.save"));
        this._router.navigateByUrl("/console/users-roles");
      },
    });
  }

  public close() {
    if (this.userRolesForm.dirty) {
      const modal = this._modalService.open(ConfirmationModalComponent, {
        centered: true,
        size: "md",
        modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
      });

      modal.componentInstance.header = "common.form.modals_leave.cancel_yes_no_title";
      modal.componentInstance.title = "common.form.modals_leave.cancel_yes_no_information";

      modal.result.then((e) => {
        if (e) this._router.navigateByUrl("/console/users-roles");
      });
    } else this._router.navigateByUrl("/console/users-roles");
  }
}
