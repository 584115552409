import type { Route } from "@angular/router";

import { checkIfLoggedInFn } from "../../core/guards";
import { Constants } from "../../shared/constants/constants";
import { SystemLogComponent } from "./system-log.component";

export const SYSTEMLOG_ROUTES: Route[] = [
  {
    path: "audit-trail",
    component: SystemLogComponent,
    canActivate: [checkIfLoggedInFn],
    data: {
      title: "breadcrumbs.audit_trail_module.list",
      breadcrumb: "breadcrumbs.audit_trail_module.list",
      tableConfList: Constants.MAIN_TABLE_LIST_CONF_KEY.AUDIT_TRAIL,
    },
  },
];
