import { Component, computed, inject, type OnInit, signal } from "@angular/core";
import { DomSanitizer, type SafeResourceUrl } from "@angular/platform-browser";
import { filter, map } from "rxjs";

import { AdaaHelper } from "../../core/utils";
import { Constants } from "../../shared/constants/constants";
import { EntitiesApiService, PropertiesService, UsersApiService } from "../../shared/services";

@Component({
  selector: "adaa-employee-pulse",
  standalone: true,
  imports: [],
  templateUrl: "./employee-pulse.component.html",
  styleUrl: "./employee-pulse.component.scss",
})
export class EmployeePulseComponent implements OnInit {
  private readonly _domSanitizer = inject(DomSanitizer);
  private readonly _propertiesService = inject(PropertiesService);
  private readonly _usersApiService = inject(UsersApiService);
  private readonly _entitiesApiService = inject(EntitiesApiService);

  iframeHeight = computed(() => `${window.outerHeight - 100}px`);
  sessionId = computed(() => {
    const sessionId = AdaaHelper.getLocalStorage(Constants.localStorageKeys.sessionId, { type: "string" });

    return `${sessionId}`;
  });
  entityId = computed(() => {
    const entityId = AdaaHelper.getLocalStorage(Constants.localStorageKeys.currentEntity, {
      type: "prop",
      property: "id",
    });

    return Number(entityId);
  });

  iframeSource = signal<SafeResourceUrl | undefined>(undefined);

  public ngOnInit() {
    this._propertiesService
      .getPropByIdList([Constants.CONSTANT_EMPLOYEE_PULSE_DASHBOARD])
      .pipe(
        filter((res) => !res.inError),
        map((res) =>
          res.responseData.find(({ id }) => Constants.DYNAMIC_LINKS_LOOKUP_ID.NEW_EMPLOYEE_PULSE_DASHBOARD === id)
        ),
        filter((item) => AdaaHelper.isDefined(item)),
        map((item) => ({ ...item, value: Number(item?.value) }))
      )
      .subscribe({
        next: ({ value }) => {
          if (value === 1) {
            this._getNewPulseUrl();
          } else {
            this._getOldPulseUrl();
          }
        },
      });
  }

  private _getNewPulseUrl() {
    this._usersApiService
      .getHashedSessionId(this.sessionId())
      .pipe(filter((res) => !res.inError))
      .subscribe({
        next: (res) => {
          const url = `${Constants.EMPLOYEE_PULSE_IFRAME_URL}?entityId=${this.entityId()}&sessionId=${res.responseData}`;
          this.iframeSource.set(this._domSanitizer.bypassSecurityTrustResourceUrl(url));
        },
      });
  }

  private _getOldPulseUrl() {
    this._entitiesApiService
      .getById(this.entityId())
      .pipe(
        filter((res) => !res.inError),
        filter((res) => !!res.responseData.pulseUrl),
        map((res) => this._domSanitizer.bypassSecurityTrustResourceUrl(res.responseData.pulseUrl))
      )
      .subscribe({
        next: (sanitizedUrl) => {
          this.iframeSource.set(sanitizedUrl);
        },
      });
  }
}
