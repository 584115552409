<div class="page-datatable pt-0 pb-2">
    <adaa-data-table
        apiName="queryAuditTrail"
        [titleHeader]="title()"
        [configKey]="tableConfList()"
        [staticSearchFields]="staticSearchFields()!"
        [enablePrint]="true"
        [enableAdd]="false"
        [enableEditAction]="false"
        [enableDeleteAction]="false"
        (actionClicked)="handleTableActions($event, workflowDifference)"
    ></adaa-data-table>
</div>

<adaa-wf-difference [isAuditTrail]="true" #workflowDifference />

<adaa-float-action />
