import { inject, Injectable } from "@angular/core";

import { MainResponseModel } from "../models";
import { ExecTeamModelType } from "../models/exec-team.model";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class ExecTeamApiService {
  private _mainService = inject(MainService);

  public getByKpiId({ kpiId, periodId }: { kpiId: number; periodId: number }) {
    const url = `execteams/getByKpiId/${kpiId}`;
    const qs = new URLSearchParams({
      periodId: `${periodId}`,
    });

    return this._mainService.sendRequest<MainResponseModel<ExecTeamModelType>>({
      method: "GET",
      url: `${url}?${qs.toString()}`,
    });
  }
}
