<ng-template #content let-modal>
    <div class="modal-header">
        <h5 class="text-center fw-bold w-100">{{ "metrics.delete_yes_no_title" | translate }}</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body overflow-x-hidden p-3">
        <p class="text-center fs-5 fw-bolder">
            {{ "metrics.metrics_delete_modal" | translate }}
        </p>
        <div class="p-2">
            <div class="d-flex gap-5 border-bottom border-3 pb-1 mb-1">
                <p class="mb-0 fw-bold">{{ "opm.opm" | translate }}</p>
                <p class="mb-0 fw-bold">{{ "landingPage.entityupdate.objectname" | translate }}</p>
            </div>
            @for (kpi of data(); track $index) {
                <div class="d-flex gap-5">
                    <p class="info">{{ "opm.opm" | translate }}</p>
                    <p class="info">{{ kpi.nameAE }}</p>
                </div>
            }
        </div>
    </div>
    <div class="modal=footer d-flex justify-content-center mt-3">
        <div class="my-2">
            <adaa-form-action-buttons
                [showSaveButton]="false"
                [saveButtonTitle]="'common.form.button.save' | translate"
                [cancelButtonTitle]="'common.form.button.cancel' | translate"
                (cancelClicked)="modal.close()"
            ></adaa-form-action-buttons>
        </div>
    </div>
</ng-template>
