import { inject, Injectable } from "@angular/core";
import { filter } from "rxjs";

import type { MainResponseModel } from "../models";
import { MandatoryFields } from "../models/mandatory-fields.model";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class MandatoryApiService {
  private _mainService = inject(MainService);

  public getMandatoryFieldsBySearchKey(searchKey: string) {
    const url = `validator/getbysearchkey/${searchKey}`;

    return this._mainService.sendRequest<MainResponseModel<MandatoryFields>>({
      method: "GET",
      url,
    });
  }

  public updateMandatoryFields(isFinished: boolean, data: MandatoryFields) {
    const url = `validator/update?isFinish=${isFinished}`;

    return this._mainService
      .sendRequest<MainResponseModel<number>>({
        method: "POST",
        url: url,
        data,
      })
      .pipe(filter((res) => !res.inError));
  }
}
