import { Component, computed, effect, inject, signal, untracked } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { map } from "rxjs";

import { PercentageValuePipe } from "../../../../../core/pipes";
import { AdaaHelper } from "../../../../../core/utils";
import { Constants } from "../../../../../shared/constants/constants";
import { EkpiItemTableValueModel, ItemTableValueModel } from "../../../../../shared/models";
import { KpisApiService, LanguageService } from "../../../../../shared/services";

@Component({
  selector: "adaa-master-ekpi-table-values",
  imports: [TranslateModule, PercentageValuePipe],
  templateUrl: "./master-ekpi-table-values.component.html",
  styleUrl: "./master-ekpi-table-values.component.scss",
})
export class MasterEkpiTableValuesComponent {
  readonly modal = inject(NgbActiveModal);
  readonly languageService = inject(LanguageService);
  private readonly _kpiApiService = inject(KpisApiService);

  kpiId = signal<number | undefined>(undefined);
  periodId = signal<number | undefined>(undefined);
  frequency = signal<number | undefined>(undefined);
  periodCycle = signal<string | undefined>(undefined);
  measurementUnit = signal<number | undefined>(undefined);
  isNoFormula = signal<boolean>(false);
  isBounded = signal<boolean>(false);
  data = signal<EkpiItemTableValueModel[]>([]);

  isPercentage = computed(() => this.measurementUnit() === Constants.MEASUREMENT.CONSTANT_MEASUREMENT_PERCENTAGE);
  tableData = computed(() => {
    const data = this.data();
    const results: Record<string, Array<ItemTableValueModel & { entityId: number; periodLabel: string }>> = {};

    if (data.length === 0) return results;

    for (const item of data) {
      const name = AdaaHelper.getItemValueByToken(item, "entityName");
      results[name] = item.itemData.map(($i) => ({
        ...$i,
        entityId: item.entityId,
        periodLabel: this._getPeriodLabel($i),
      }));
    }
    return results;
  });
  tableDataCategories = computed(() => {
    const data = this.tableData();
    return Object.keys(data as unknown as Record<string, unknown>);
  });

  readonly kpiType = Constants.KPI_TYPE.EKPI;

  constructor() {
    effect(() => {
      const args = {
        id: this.kpiId()!,
        periodId: this.periodId()!,
        kpiType: this.kpiType,
        frequency: this.periodCycle()!,
      };

      untracked(() => {
        this._kpiApiService
          .getItemDataByTypeAndId(args)
          .pipe(map((res) => res.responseData))
          .subscribe({
            next: (data) => {
              this.data.set(data);
            },
          });
      });
    });
  }

  private _getPeriodLabel({ year, month }: ItemTableValueModel) {
    const SEMESTERS = [
      [1, 2, 3, 4, 5, 6],
      [7, 8, 9, 10, 11, 12],
    ];
    const QUARTERS = [
      [1, 2, 3],
      [4, 5, 6],
      [7, 8, 9],
      [10, 11, 12],
    ];
    const pad = (num: number, size: number) => {
      const result = `${num}`;
      if (result.length < size) {
        return "0" + result;
      }
      return result;
    };

    switch (this.frequency()) {
      case Constants.FREQUENCY.EVERY_TWO_YEARS:
      case Constants.FREQUENCY.EVERY_THREE_YEAR:
      case Constants.FREQUENCY.EVERY_FOUR_YEARS:
      case Constants.FREQUENCY.EVERY_FIVE_YEARS:
      case Constants.FREQUENCY.ANNUAL: {
        return `${year}`;
      }

      case Constants.FREQUENCY.SEMIANNUAL: {
        const index = SEMESTERS.findIndex((list) => !!list.find((c) => +c === month));
        return `S${index + 1} ${year}`;
      }

      case Constants.FREQUENCY.QUARTERLY: {
        const index = QUARTERS.findIndex((list) => !!list.find((c) => +c === month));
        return `Q${index + 1} ${year}`;
      }

      case Constants.FREQUENCY.MONTHLY: {
        return `${pad(month, 2)} / ${year}`;
      }

      default: {
        return `${year}`;
      }
    }
  }
}
