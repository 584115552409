import { Directive, EventEmitter, Input, Output } from "@angular/core";

export type SortColumn = number | "";
export type SortDirection = "asc" | "desc" | "";

const rotate: { [key: string]: SortDirection } = {
  asc: "desc",
  desc: "",
  "": "asc",
};

export const compare = (v1: string | number | null | undefined, v2: string | number | null | undefined) =>
  v1 === undefined || v1 === null || v2 === undefined || v2 === null ? 0 : v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
export interface SortEvent {
  column: SortColumn;
  direction: SortDirection;
}

@Directive({
  selector: "div[sortable]",
  host: {
    "[class.asc]": 'direction === "asc"',
    "[class.desc]": 'direction === "desc"',
    "(click)": "rotate()",
  },
  standalone: true,
})
export class SortableHeaderDirective {
  @Input() sortable: SortColumn = "";
  @Input() direction: SortDirection = "";
  @Output() sort = new EventEmitter<SortEvent>();

  public rotate() {
    this.direction = rotate[this.direction];
    this.sort.emit({ column: this.sortable, direction: this.direction });
  }
}
