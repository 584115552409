import { Component, inject, signal } from "@angular/core";
import { FormArray, FormBuilder, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";

import { AdaaHelper } from "../../../core/utils";
import { FormActionButtonsComponent, FormCheckboxComponent } from "../../../shared/components";
import { AdaaBoolean, Language } from "../../../shared/constants/enums";
import { MandatoryFields } from "../../../shared/models/mandatory-fields.model";
import { LanguageService, SystemLayoutService } from "../../../shared/services";
import { MandatoryApiService } from "../../../shared/services/mandatory-api.service";

@Component({
  selector: "adaa-mandatory-fields-editor",
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, TranslateModule, FormActionButtonsComponent, FormCheckboxComponent],
  templateUrl: "./mandatory-fields-editor.component.html",
  styleUrl: "./mandatory-fields-editor.component.scss",
})
export class MandatoryFieldsEditorComponent {
  private _route = inject(ActivatedRoute);
  private _mandatoryApiService = inject(MandatoryApiService);
  private _formBuilder = inject(FormBuilder);
  private _router = inject(Router);
  private _toastrService = inject(ToastrService);
  private _translateService = inject(TranslateService);
  private readonly _systemLayoutService = inject(SystemLayoutService);
  languageService = inject(LanguageService);

  private _subscription = new Subscription();
  readonly #untilDestroy = AdaaHelper.untilDestroyed();

  language = Language;

  searchKey: string;
  mandatoryTemp = signal<MandatoryFields>({
    name: "",
    nameAE: "",
    nameEN: "",
    searchKey: "",
    mandatory: "",
    type: "",
    parameterCatalogs: [],
  });

  mandatoryForm: FormGroup = new FormGroup({});
  config: FormGroup = new FormGroup({});

  public ngOnInit() {
    this._systemLayoutService.hasActiveEntityChanged$.pipe(this.#untilDestroy()).subscribe({
      next: () => {
        this._router.navigateByUrl(`/console`);
      },
    });
    this._prepareForm();
    this._getMandatoryFields();
  }
  private _getMandatoryFields() {
    if (!this._route.snapshot.data) return;
    this.searchKey = this._route.snapshot?.params["searchKey"];
    this._mandatoryApiService.getMandatoryFieldsBySearchKey(this.searchKey).subscribe({
      next: (response) => {
        this.mandatoryTemp.set(response.responseData);
        this.mandatoryTemp().parameterCatalogs = this.mandatoryTemp().parameterCatalogs.filter(
          (c) => c.visibleParameter === AdaaBoolean.Y
        );
        this._setFormValues();
      },
    });
  }
  public get fields() {
    return <FormArray<FormGroup>>this.mandatoryForm.get("parameterCatalogs");
  }
  private _prepareForm() {
    this.mandatoryForm = this._formBuilder.group({
      id: this._formBuilder.control(""),
      searchKey: this._formBuilder.control(""),
      type: this._formBuilder.control(""),
      updateUser: this._formBuilder.control(""),
      updateTS: this._formBuilder.control(""),
      nameAE: this._formBuilder.control(""),
      nameEN: this._formBuilder.control(""),
      status: this._formBuilder.control(""),
      parameterCatalogs: this._formBuilder.array([]),
    });
  }

  private _setFormValues(item: MandatoryFields = this.mandatoryTemp()) {
    this.mandatoryForm.patchValue(item);
    item.parameterCatalogs.forEach((field) => {
      this.config = this._formBuilder.group({
        id: field.id,
        nameAE: field.nameAE,
        nameEN: field.nameEN,
        mandatory: field.mandatory,
        alignment: field.alignment,
        dbFieldName: field.dbFieldName,
        displayOrder: field.displayOrder,
        fieldName: field.fieldName,
        fieldType: field.fieldType,
        filterType: field.filterType,
        formId: field.formId,
        mobileDisplay: field.mobileDisplay,
        searchKey: field.searchKey,
        status: field.status,
        step: field.step,
        updateTS: field.updateTS,
        visibleParameter: AdaaBoolean.Y,
      });
      this.fields.push(this.config);
    });
  }

  public cancel() {
    this._router.navigateByUrl(`/console/mandatory`);
  }
  public submit(isFinish = false) {
    if (this.mandatoryForm.valid && this.mandatoryForm.dirty) {
      this._subscription = this._mandatoryApiService
        .updateMandatoryFields(isFinish, this.mandatoryForm.value)
        .subscribe({
          next: (response) => {
            if (response.inError) {
              this._toastrService.error(this._translateService.instant("notification.error.unknown_object"));
              return;
            }
            this._router.navigateByUrl(`/console/mandatory`);
          },
        });
    }
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
