import { Component, inject } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

import { LanguageService } from "../../shared/services";

@Component({
  selector: "adaa-permission-denied",
  standalone: true,
  imports: [TranslateModule],
  template: `
    <div class="container auth-layout" [dir]="languageService.direction()">
      <div class="row d-flex justify-content-center flex-nowrap" style="margin-top:40vh">
        <div class="col-xl-4 col-lg-6 col-sm-8 col-12 px-2">
          <div class="row">
            <div class="col-md-2 col-sm-12">
              <i class="fa-solid fa-circle-exclamation fa-5x text-danger"></i>
            </div>
            <div class="col-md-9 col-sm-12 mx-2">
              <h2 class="text-danger fw-bold">{{ "access_denied.title" | translate }}</h2>
              <p class="fw-bold">{{ "access_denied.body" | translate }}</p>
              <a class="btn btn-outline-danger" href="javascript:history.back()">{{ "login.go_back" | translate }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrl: "./permission-denied.component.scss",
})
export class PermissionDeniedComponent {
  languageService = inject(LanguageService);
}
