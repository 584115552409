import { Component, inject, input, signal } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ChartConfiguration, ChartOptions } from "chart.js";
import { BaseChartDirective } from "ng2-charts";
import { forkJoin } from "rxjs";

import { AdaaHelper } from "../../../core/utils";
import { DataTableComponent, FloatActionComponent } from "../../../shared/components";
import { ChartActionButtonsComponent } from "../../../shared/components/chart-action-buttons/chart-action-buttons.component";
import { genericFloatButtons } from "../../../shared/components/general/float-action";
import { Constants } from "../../../shared/constants/constants";
import { TableButtonClicked } from "../../../shared/models";
import { SystemLayoutService } from "../../../shared/services";
import { SupportCenterApiService } from "../../../shared/services/support-center.api.service";

@Component({
  selector: "adaa-support-center-list",
  standalone: true,
  imports: [DataTableComponent, TranslateModule, FloatActionComponent, BaseChartDirective, ChartActionButtonsComponent],
  templateUrl: "./support-center-list.component.html",
  styleUrl: "./support-center-list.component.scss",
})
export class SupportCenterListComponent {
  private _translateService = inject(TranslateService);
  private _supportCenterApiService = inject(SupportCenterApiService);
  private _systemLayoutService = inject(SystemLayoutService);
  private _router = inject(Router);

  Constants = Constants;
  private readonly _untilDestroy = AdaaHelper.untilDestroyed();
  private readonly _genericFloatButtons = genericFloatButtons();

  breadcrumb = input.required<string>();

  hasHelpdeskGroup = signal<boolean>(false);
  dataSet1 = signal<ChartConfiguration<"pie">["data"] | undefined>(undefined);
  dataSet2 = signal<ChartConfiguration<"pie">["data"] | undefined>(undefined);

  pieChartOptions: ChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
    },
  };

  public ngOnInit() {
    this._floatActions();
    this._initPage();

    this._systemLayoutService.hasActiveEntityChanged$.pipe(this._untilDestroy()).subscribe({
      next: () => window.location.reload(),
    });
  }

  public getAction(value: TableButtonClicked) {
    switch (value.event) {
      case "view":
        this._router.navigateByUrl(`console/helpdesk/${value.data.id}`);
        break;
      case "edit":
        this._router.navigateByUrl(`console/helpdesk/edit/${value.data.id}`);
        break;

      default:
        break;
    }
  }

  private _initPage(): void {
    this._supportCenterApiService.getUserHelpdeskGroup().subscribe({
      next: (response) => {
        this.hasHelpdeskGroup.set(
          AdaaHelper.isDefined(response.responseData) &&
            response.responseData.typeId === Constants.CONSTANT_USER_GROUP_TYPE_HELPDESK
        );

        if (this.hasHelpdeskGroup()) this._getChartData();
      },
    });
  }

  private _floatActions(): void {
    this._genericFloatButtons([
      {
        key: "print",
        data: undefined,
      },
      {
        key: "helpdesk",
        data: {
          url: "/helpdesk/create",
        },
      },
    ]);
  }

  private _getChartData(): void {
    forkJoin({
      issuesPerStatus: this._supportCenterApiService.getIssuesPerStatus(),
      unresolvedIssues: this._supportCenterApiService.getUnresolvedIssuesPerPriority(),
    }).subscribe({
      next: (results) => {
        const statusIssueCounter = results.issuesPerStatus.responseData.map((issue) => issue.issuesCounter);
        const unresolvedIssueCounter = results.unresolvedIssues.responseData.map((issue) => issue.issuesCounter);
        this.dataSet1.set({
          labels: [
            this._translateService.instant("helpdesk.ticket.status.closed"),
            this._translateService.instant("helpdesk.ticket.status.new"),
          ],
          datasets: [
            {
              backgroundColor: ["#534741", "#736357"],
              data: statusIssueCounter,
            },
          ],
        });

        this.dataSet2.set({
          labels: [
            this._translateService.instant("helpdesk.ticket.priority.critical"),
            this._translateService.instant("helpdesk.ticket.priority.high"),
            this._translateService.instant("helpdesk.ticket.priority.medium"),
            this._translateService.instant("helpdesk.ticket.priority.low"),
          ],
          datasets: [
            {
              backgroundColor: ["#c7b299", "#c69c6d", "#534741", "#736357"],
              data: unresolvedIssueCounter,
            },
          ],
        });
      },
    });
  }
}
