<div class="d-flex gap-2">
    @if (pageMode() === PageMode.create) {
        <p class="fw-bold fs-3">{{ "government_scores.pillars.create" | translate }}</p>
    }
    @if (pageMode() === PageMode.view) {
        <p class="fw-bold fs-3">{{ "government_scores.pillars.view" | translate }}</p>
    }
    @if (pageMode() === PageMode.edit) {
        <p class="fw-bold fs-3">{{ "government_scores.pillars.edit" | translate }}</p>
    }
</div>

<div class="bg-white my-2 p-2">
    <adaa-form-dropdown
        [label]="'common.form.label.entity' | translate"
        [required]="true"
        [options]="availableEntitiesOptions()"
        [setDefaultValue]="entityId"
        [isDisabled]="pageMode() !== PageMode.create"
        [searchable]="true"
        [invalid]="submitted() && !entityId"
        (inputChanges)="entityId = $event"
    />
</div>

<div class="bg-white my-2 p-3">
    <adaa-data-table
        #adaaDataTable
        [apiPrefix]="'govScoresSector'"
        [configKey]="Constants.MAIN_TABLE_LIST_CONF_KEY.GOV_ENTITY_PILLARS_STEP_CONF"
        [titleHeader]="'government_scores.pillars.title_plural' | translate"
        [isLocalData]="true"
        [localData]="pillars()"
        [enableAdd]="pageMode() !== PageMode.view"
        [enableViewAction]="pageMode() === PageMode.view"
        [enableEditAction]="pageMode() !== PageMode.view"
        [enableDeleteAction]="pageMode() !== PageMode.view"
        (addNewClicked)="pillarsModal.open()"
        (actionClicked)="getAction($event)"
    ></adaa-data-table>
</div>

<div class="my-2">
    <adaa-form-action-buttons
        [showSaveButton]="pageMode() !== PageMode.view"
        [saveButtonTitle]="'common.form.button.submit' | translate"
        [cancelButtonTitle]="pageMode() === PageMode.view ? ('common.form.button.close' | translate) : ('common.form.button.cancel' | translate)"
        (cancelClicked)="close()"
        (saveClicked)="submit()"
    ></adaa-form-action-buttons>
</div>

<adaa-entity-pillars [pageMode]="pageMode()" [pillars]="pillars()" (pillarOutput)="addPillar($event)" #pillarsModal />

<adaa-float-action />
