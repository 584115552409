<div class="my-4">
    <h4 class="fw-bold w-100">
        @if (languageService.current() === language.English) {
            {{ mandatoryTemp().nameEN }}
        } @else {
            {{ mandatoryTemp().nameAE }}
        }
    </h4>
</div>
<div class="w-100 d-block card p-3 bg-white border-0">
    <div class="">
        <div class="row" [formGroup]="mandatoryForm">
            @for (item of fields.controls; track $index) {
                <form [formGroup]="item" class="col-6">
                    <div class="row mb-3">
                        <div class="col-md-6">
                            @if (languageService.current() === language.English) {
                                {{ item.get("nameEN")?.value }}
                            } @else {
                                {{ item.get("nameAE")?.value }}
                            }
                        </div>
                        <div class="col-md-6">
                            <adaa-form-checkbox
                                controlName="mandatory"
                                formControlName="mandatory"
                                checkboxMode="trueFalse"
                                [checkboxMode]="'yesNo'"
                                [useSwitch]="false"
                            />
                        </div>
                    </div>
                </form>
            }
        </div>
    </div>
</div>
<div class="my-4">
    <adaa-form-action-buttons
        [cancelButtonTitle]="'common.form.button.cancel'"
        [saveButtonTitle]="'common.form.button.submit'"
        [showSaveButton]="true"
        (cancelClicked)="cancel()"
        (saveClicked)="submit()"
    ></adaa-form-action-buttons>
</div>
