import { NgClass } from "@angular/common";
import { Component, input, OnChanges, output, SimpleChanges } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { QuillModule, QuillModules } from "ngx-quill";
import Quill from "quill";

import { AdaaHelper } from "../../../core/utils";
import { Language } from "../../constants/enums";
import { NotificationConfigPlaceholder } from "../../models/notifications-config.model";

@Component({
  selector: "adaa-quill-editor",
  standalone: true,
  imports: [QuillModule, FormsModule, NgClass],
  templateUrl: "./quill-editor.component.html",
  styleUrl: "./quill-editor.component.scss",
})
export class QuillEditorComponent implements OnChanges {
  content = "";
  AdaaHelper = AdaaHelper;
  Language = Language;
  language = input.required<string>();
  placeholder = input<NotificationConfigPlaceholder>();
  value = output<string>();
  quillEditorInstance: Quill;

  editorModules: QuillModules = {
    history: {
      delay: 1000,
      maxStack: 100,
      userOnly: false,
    },
    toolbar: {
      container: [
        ["bold", "italic", "strike"],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ color: [] }, { background: [] }],
        [{ font: [] }],
        [{ align: [] }],
        ["clean"],
        ["link"],
        ["undo", "redo"],
      ],
      handlers: {
        undo: function () {
          this.quill.history.undo();
        },
        redo: function () {
          this.quill.history.redo();
        },
      },
    },
  };

  public ngOnChanges(changes: SimpleChanges): void {
    document.documentElement.style.setProperty("--tooltip-strike", "dumn");
    if (AdaaHelper.isDefined(changes.placeholder?.currentValue)) this._insertPlaceholderText();
  }

  public onEditorCreated(quillInstance: Quill) {
    this.quillEditorInstance = quillInstance;
  }

  private _insertPlaceholderText() {
    if (this.quillEditorInstance) {
      const range = this.quillEditorInstance.getSelection();

      if (range) {
        const placeholderText = this.placeholder()?.placeholder;
        this.quillEditorInstance.insertText(range.index, placeholderText!);
        this.quillEditorInstance.setSelection(range.index + placeholderText!.length);
        const editorValue = this.quillEditorInstance.root.innerHTML;
        this.content = editorValue;
      }
    }
  }

  public change() {
    this.value.emit(this.content);
  }
}
