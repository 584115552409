import {
  Component,
  computed,
  effect,
  EventEmitter,
  inject,
  input,
  Output,
  signal,
  untracked,
  ViewChild,
} from "@angular/core";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import type { ChartConfiguration, ChartDataset, PluginChartOptions, TooltipItem } from "chart.js";
import { BaseChartDirective } from "ng2-charts";
import { filter } from "rxjs";

import { AdaaHelper } from "../../../../../core/utils";
import { ChartActionButtonsComponent } from "../../../../../shared/components";
import { Constants } from "../../../../../shared/constants/constants";
import { Language } from "../../../../../shared/constants/enums";
import { ItemViewCardPerformanceType } from "../../../../../shared/models";
import { ChartsApiService, ChartsService, LanguageService } from "../../../../../shared/services";

@Component({
  selector: "adaa-kpi-performance",
  standalone: true,
  imports: [BaseChartDirective, TranslateModule, ChartActionButtonsComponent],
  template: `
    <div class="card-title px-2 pt-3 pb-2 mb-0 view-card">
      <adaa-chart-action-buttons
        [elementId]="'kpiPerformanceChart'"
        [csvChartInfo]="csvChartInfo()"
      ></adaa-chart-action-buttons>
      <h5 class="m-0 mb-1 fw-bold">
        {{ title() | translate }}
      </h5>
    </div>

    @if (options()) {
      <div class="w-100 position-relative pt-2 pb-3 px-1 view-card">
        <canvas
          id="kpiPerformanceChart"
          baseChart
          class="chart"
          type="line"
          [options]="options()"
          [data]="data()"
          [style.height]="chartHeight()"
        ></canvas>
      </div>
    }
  `,
})
export class KpiPerformanceComponent {
  @Output() getChartData = new EventEmitter<ItemViewCardPerformanceType[]>();
  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  private readonly _languageService = inject(LanguageService);
  private readonly _translateService = inject(TranslateService);
  private readonly _chartsApiService = inject(ChartsApiService);
  private readonly _chartsService = inject(ChartsService);

  itemId = input.required<number>();
  kpiType = input.required<number>();
  periodId = input.required<number>();
  entityId = input.required<number>();
  frequency = input.required<number>();
  measureUnit = input.required<number>();
  periodCycle = input.required<string>();
  showLegend = input<boolean>(false);
  isBounded = input<boolean>(false);
  includeLegacy = input<boolean>(false);
  title = input<string>("graphic.kpi.actuals");
  chartHeight = input<string>("245px");
  entityMapId = input<number | undefined>(undefined);
  csvChartInfo = input<string>();

  calculationReadings = signal<ItemViewCardPerformanceType[]>([]);
  calculationReadingsFiltered = computed(() =>
    this.calculationReadings().filter(
      (data) =>
        AdaaHelper.isDefined(data.score) ||
        (this.isBounded()
          ? AdaaHelper.isDefined(data.lowerLimit) && AdaaHelper.isDefined(data.highLimit)
          : AdaaHelper.isDefined(data.target))
    )
  );

  data = signal<ChartConfiguration["data"]>({
    labels: [],
    datasets: [],
  });
  options = signal<ChartConfiguration["options"] | undefined>(undefined);

  years = computed(() => {
    const set = new Set<number>();
    for (const { period } of this.calculationReadingsFiltered()) {
      set.add(period.year);
    }
    return Array.from(set);
  });
  quarters = computed(() =>
    this.calculationReadingsFiltered().map(({ period }) => {
      if (period.month === 3) return `Q1 - ${period.year}`;
      if (period.month === 6) return `Q2 - ${period.year}`;
      if (period.month === 9) return `Q3 - ${period.year}`;
      return `Q4 - ${period.year}`;
    })
  );
  semesters = computed(() =>
    this.calculationReadingsFiltered().map(({ period }) => {
      if (period.semester === 1) return `S1 - ${period.year}`;
      return `S2 - ${period.year}`;
    })
  );
  months = computed(() => {
    const set = new Set<number>();
    for (const { period } of this.calculationReadingsFiltered()) {
      set.add(period.month);
    }
    return Array.from(set);
  });

  readonly #xAxisLabels = () => ({
    actual: this._translateService.instant("data_entry.actual"),
    target: this._translateService.instant("data_entry.target"),
    lowerLimit: this._translateService.instant("kpi.lower_limit"),
    upperLimit: this._translateService.instant("kpi.upper_limit"),
  });

  constructor() {
    effect(() => {
      const map = AdaaHelper.getMap(this.entityId());
      const options = {
        itemId: this.itemId(),
        itemTypeId: this.kpiType(),
        graph: "flat" as const,
        entityId: this.entityId(),
        includeLegacy: this.includeLegacy(),
        periodId: this.periodId(),
        option: this.periodCycle(),
        mapId: this.entityMapId() ?? (map?.id as number),
      };

      untracked(() => {
        this._chartsApiService
          .getCalculationReadingsGraphCardByItem(options)
          .pipe(filter((res) => !res.inError))
          .subscribe({
            next: (res) => this.calculationReadings.set(res.responseData ?? []),
            complete: () => {
              this._generateChartData();
              this._generateChartOptions();
              this.getChartData.emit(this.calculationReadings());
              this.chart?.update();
            },
          });
      });
    });
  }

  private _generateChartData() {
    const datasets: ChartDataset<"line", number[]>[] = [
      {
        yAxisID: "actual",
        pointStyle: "triangle",
        label: this.#xAxisLabels().actual,
        data: this.calculationReadingsFiltered().map((data) => data.score as number),
        showLine: true,
        spanGaps: true,
        borderColor: "#29a9e0",
      },
    ];
    if (!this.isBounded()) {
      datasets.push({
        yAxisID: "target",
        label: this.#xAxisLabels().target,
        data: this.calculationReadingsFiltered().map((data) => data.target as number),
        showLine: true,
        spanGaps: true,
        borderColor: "#000",
      });
    } else {
      datasets.push(
        {
          yAxisID: "lowerLimit",
          label: this.#xAxisLabels().lowerLimit,
          data: this.calculationReadingsFiltered().map((data) => data.lowerLimit as number),
          showLine: true,
          spanGaps: true,
        },
        {
          yAxisID: "upperLimit",
          label: this.#xAxisLabels().upperLimit,
          data: this.calculationReadingsFiltered().map((data) => data.highLimit as number),
          showLine: true,
          spanGaps: true,
        }
      );
    }

    this.data.set({
      labels: ((f: number) => {
        switch (f) {
          case Constants.FREQUENCY.SEMIANNUAL: {
            return this.semesters();
          }

          case Constants.FREQUENCY.QUARTERLY: {
            return this.quarters();
          }

          case Constants.FREQUENCY.MONTHLY: {
            return this.months();
          }

          default: {
            return this.years();
          }
        }
      })(this.frequency()),
      datasets,
    });
  }

  private _generateChartOptions() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;

    const data = this.data()
      .datasets.flatMap(({ data }) => data)
      .filter((a) => AdaaHelper.isDefined(a))
      .sort((a, b) => {
        if (!AdaaHelper.isDefined(a)) return 0;
        if (!AdaaHelper.isDefined(b)) return 0;
        return a > b ? -1 : 1;
      }) as number[];

    let max: number;
    if (data.length === 0) {
      max = 50;
    } else {
      if (Number(data[0].toFixed(0)) <= Number(data[0].toFixed(0)) % 10) {
        max = Number(data[0].toFixed(0));
      } else {
        max = Number(data[0].toFixed(0)) - (Number(data[0].toFixed(0)) % 10);
      }
    }

    const isRTL = this._languageService.current() === Language.Arabic;
    const options = this._chartsService.getPerformanceChartOptions({
      showLegend: this.showLegend(),
      padding: 2,
    });

    const { stepSize, max: _max } = this._getMaxAndStepSize(max);
    max = _max;

    let scale: { [k: string]: Record<string, unknown> } = {
      actual: {
        ...options?.scales?.y,
        max: max,
        ticks: {
          ...options?.scales?.y?.ticks,
          stepSize,
          callback: function (value: string | number) {
            const isPercent = Constants.CONSTANT_MEASUREMENT_PERCENTAGE_01 === that.measureUnit();
            value = +value;
            if (value >= 1e12)
              value = (value / 1e12).toFixed(1) + "T"; // Trillion
            else if (value >= 1e9)
              value = (value / 1e9).toFixed(1) + "B"; // Billion
            else if (value >= 1e6)
              value = (value / 1e6).toFixed(1) + "M"; // Million
            else if (value >= 1e3) value = (value / 1e3).toFixed(1) + "K"; // Thousand

            return `${value}${isPercent ? "%" : ""}`;
          },
        },
      },
    };

    if (!this.isBounded()) {
      scale = {
        ...scale,
        target: {
          ...options?.scales?.y,
          display: false,
          position: isRTL ? "left" : "right",
          max: max,
          ticks: {
            ...options?.scales?.y?.ticks,
            stepSize,
            callback: function (value: unknown) {
              const isPercent = Constants.CONSTANT_MEASUREMENT_PERCENTAGE_01 === that.measureUnit();
              return `${value}${isPercent ? "%" : ""}`;
            },
          },
        },
      };
    } else {
      scale = {
        ...scale,
        lowerLimit: {
          ...options?.scales?.y,
          display: false,
          position: isRTL ? "left" : "right",
          suggestedMax: max,
          ticks: {
            ...options?.scales?.y?.ticks,
            stepSize,
            callback: function (value: unknown) {
              const isPercent = Constants.CONSTANT_MEASUREMENT_PERCENTAGE_01 === that.measureUnit();
              return `${value}${isPercent ? "%" : ""}`;
            },
          },
        },
        upperLimit: {
          ...options?.scales?.y,
          display: false,
          position: isRTL ? "left" : "right",
          suggestedMax: max,
          ticks: {
            ...options?.scales?.y?.ticks,
            stepSize,
            callback: function (value: unknown) {
              const isPercent = Constants.CONSTANT_MEASUREMENT_PERCENTAGE_01 === that.measureUnit();
              return `${value}${isPercent ? "%" : ""}`;
            },
          },
        },
      };
    }

    const plugins: Record<string, unknown> = this._getPluginOptions(
      options?.plugins as PluginChartOptions<"line">["plugins"]
    );

    this.options.set({
      ...options,
      interaction: {
        mode: "point",
        intersect: false,
      },
      scales: {
        ...options?.scales,
        ...scale,
        x: {
          ...options?.scales?.x,
          offset: true,
        },
        y: {
          ...options?.scales?.y,
          display: false,
        },
      },
      plugins: {
        ...options?.plugins,
        ...plugins,
      },
    });
  }

  private _getPluginOptions(plugins: PluginChartOptions<"line">["plugins"]) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;

    return {
      tooltip: {
        ...plugins?.tooltip,
        callbacks: {
          label: function (context: TooltipItem<"line">) {
            const isPercent = Constants.CONSTANT_MEASUREMENT_PERCENTAGE_01 === that.measureUnit();
            let label = context.dataset.label || "";
            if (context.parsed.y !== null) {
              label += `: ${AdaaHelper.roundValue(context.parsed.y)}${isPercent ? "%" : ""}`;
            }
            return label;
          },
        },
      },
      legend: {
        display: true,
        position: "bottom",
        textDirection: this._languageService.direction(),
        align: "center",
        labels: {
          usePointStyle: true,
          pointStyle: "triangle",
          padding: 20,
          boxWidth: 65,
        },
      },
    };
  }

  private _getMaxAndStepSize(val: number) {
    if (val <= 5) return { max: 10, stepSize: 1 };
    if (val > 5 && val <= 15) return { max: 20, stepSize: 2 };
    if (val > 15 && val <= 30) return { max: 45, stepSize: 5 };
    if (val > 30 && val <= 60) return { max: 80, stepSize: 10 };
    if (val > 60 && val <= 100) return { max: 120, stepSize: 20 };
    return { max: val * 2, stepSize: 20 };
  }
}
