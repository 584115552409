<div class="card bg-white w-100 px-2 h-100 ntkpi-card" [style.border-bottom-color]="borderBottomColor()">
    <div class="card-title px-2 pt-3 pb-1">
        <h6 class="fw-bold">{{ data() | translateToken: "name" }}</h6>
    </div>

    <div class="card-body d-flex flex-column justify-content-center px-2 py-1">
        <h6 class="fw-bold text-primary d-block w-100 text-truncate m-0 py-1" [attr.title]="data() | translateToken: 'entityName'">
            {{ data() | translateToken: "entityName" }}
        </h6>

        <p class="text-info fw-bold d-block w-100 text-truncate m-0 pt-3 pb-1" [attr.title]="data() | translateToken: 'subGovernmentDirectionName'">
            {{ data() | translateToken: "subGovernmentDirectionName" }}
        </p>
    </div>

    <div class="card-footer d-flex justify-content-center pt-0 px-2 pb-3">
        <div class="w-50" [class.pe-2]="!isRTL()" [class.ps-2]="isRTL()">
            <adaa-progress-indicator [height]="18" [color]="data().annualScoreColor!" [value]="data().annualScore!" />

            <small class="text-center text-muted">{{ "landingPage.entity_page.annual_performance" | translate }}</small>
        </div>

        <div class="w-50" [class.ps-2]="!isRTL()" [class.pe-2]="isRTL()">
            <adaa-progress-indicator [height]="18" [color]="data().annualVisionScoreColor!" [value]="data().annualVisionScore!" />

            <small class="text-center text-muted">{{ "landingPage.entity_page.overall_performance" | translate }}</small>
        </div>
    </div>
</div>
