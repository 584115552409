import { Component, inject, input, TemplateRef, viewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";

import { Language } from "../../../constants/enums";
import { MonitoredKpiModelType } from "../../../models";
import { AppService } from "../../../services";
import { FormActionButtonsComponent } from "../../form";

@Component({
  selector: "adaa-metric-unlink-information",
  standalone: true,
  imports: [FormActionButtonsComponent, TranslateModule],
  templateUrl: "./metric-unlink-information.component.html",
  styleUrl: "./metric-unlink-information.component.scss",
})
export class MetricUnlinkInformationComponent {
  private _modalService = inject(NgbModal);
  private _appService = inject(AppService);

  data = input.required<MonitoredKpiModelType[]>();
  modal = viewChild.required<TemplateRef<unknown>>("content");

  public open() {
    this._modalService.open(this.modal(), {
      animation: true,
      scrollable: false,
      keyboard: false,
      size: "lg",
      centered: true,
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });
  }
}
