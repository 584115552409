import { Component, inject, input, output, signal, TemplateRef, viewChild } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { AdaaHelper } from "../../../core/utils";
import {
  FormActionButtonsComponent,
  FormDropdownComponent,
  FormInputComponent,
  FormInputFileComponent,
} from "../../../shared/components";
import { Constants } from "../../../shared/constants/constants";
import { AdaaBoolean, Language, PageMode } from "../../../shared/constants/enums";
import { AttachFile, CycleModelType, EntityReportModelType, ValueText } from "../../../shared/models";
import { AppService, EntityReportsEditorService, SystemLayoutService } from "../../../shared/services";

@Component({
  selector: "adaa-entity-reports-editor",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    FormActionButtonsComponent,
    FormInputComponent,
    FormInputFileComponent,
    FormDropdownComponent,
  ],
  templateUrl: "./entity-reports-editor.component.html",
  styleUrl: "./entity-reports-editor.component.scss",
})
export class EntityReportsEditorComponent {
  entityReportsEditorService = inject(EntityReportsEditorService);
  private _formBuilder = inject(FormBuilder);
  private _modalService = inject(NgbModal);
  private _appService = inject(AppService);
  private _translateService = inject(TranslateService);
  private _toastrService = inject(ToastrService);
  private readonly _systemLayoutService = inject(SystemLayoutService);

  pageMode = input.required<string>();
  currentEntityReport = input.required<EntityReportModelType>();
  editorClosed = output<EntityReportModelType>();
  PageMode = PageMode;

  modal = viewChild.required<TemplateRef<unknown>>("content");
  reportForm: FormGroup = new FormGroup({});

  years = signal<ValueText[]>([]);
  reportfile = signal<AttachFile>({ id: -1, filename: "" });
  submitted = signal<boolean>(false);
  readonly #untilDestroy = AdaaHelper.untilDestroyed();

  currentPlan = signal<CycleModelType>({
    id: 0,
    nameAE: "",
    nameEN: "",
    dscAE: "",
    dscEN: "",
    startYear: 0,
    endYear: 0,
    dataRevisionId: 0,
    entityMaps: [],
    status: 0,
    wfStatus: "",
    updateTS: 0,
  });

  public ngOnInit() {
    this._systemLayoutService.hasCycleChanged$.pipe(this.#untilDestroy()).subscribe({
      next: () => {
        this._preparePlanYears();
      },
    });
    this._prepareForm();
    this.entityReportsEditorService.formValidator();
    this._preparePlanYears();
  }

  private _prepareForm() {
    this.reportForm = this._formBuilder.group({
      nameAE: this._formBuilder.control<string | undefined>("", {
        validators: [Validators.required],
      }),
      nameEN: this._formBuilder.control<string | undefined>("", {
        validators: [Validators.required],
      }),
      attachDate: this._formBuilder.control<number | undefined>(undefined),
      id: this._formBuilder.control<number | undefined>(undefined, {
        validators: [Validators.required],
      }),
      filename: this._formBuilder.control<string | undefined>("", {
        validators: [Validators.required],
      }),
      status: this._formBuilder.control<string | undefined>(AdaaBoolean.N),
    });
  }

  private _preparePlanYears() {
    const plan = AdaaHelper.getLocalStorage(Constants.localStorageKeys.currentPlan, { type: "json" });
    this.currentPlan.set(plan as CycleModelType);
    if (!this.currentPlan()) return;
    const startYear = new Date(this.currentPlan().startYear).getFullYear();
    const endYear = new Date(this.currentPlan().endYear).getFullYear();
    const dropdown: ValueText[] = [];
    for (let i = startYear; i <= endYear; i++) {
      dropdown.push({
        value: new Date(i.toString()).getTime(),
        text: i.toString(),
      });
    }
    this.years.set(dropdown);
  }

  public checkInvalid(control: string) {
    return (
      this.reportForm.controls[control].errors?.["required"] &&
      (this.reportForm.get(control)?.dirty || this.submitted())
    );
  }

  public getFile() {
    const file: AttachFile = {
      id: this.reportForm.value.id,
      filename: this.reportForm.value.filename,
    };
    return file;
  }

  public open() {
    this._modalService.open(this.modal(), {
      animation: true,
      scrollable: false,
      keyboard: false,
      size: "xl",
      centered: true,
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });

    this.submitted.set(false);
  }

  public onFilechange(event: AttachFile | null) {
    if (event) {
      this.reportForm.controls.id.patchValue(event.id);
      this.reportForm.controls.filename.patchValue(event.filename);
    }
  }

  public submit() {
    this.submitted.set(true);
    if (this.reportForm.invalid) {
      this._toastrService.error(this._translateService.instant("common.form.label.missing_information"));
      return;
    }
    const result = {
      ...(this.pageMode() === PageMode.edit && { ...this.currentEntityReport() }),
      ...this.reportForm.value,
      id: this.reportForm.value.id,
      fileName: this.reportForm.value.filename,
      itemType: Constants.ITEM_TYPES_DOCS.ADAA2,
      auxAttachFile: { id: this.reportForm.value.id, filename: this.reportForm.value.filename },
      status: Constants.OBJECT_STATUS.ACTIVE,
    };
    this.editorClosed.emit(result);
  }
}
