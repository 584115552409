import { Component, inject, OnDestroy, OnInit } from "@angular/core";
import { NavigationEnd, Router, RouterOutlet } from "@angular/router";
import { filter } from "rxjs";

import { AdaaHelper } from "./core/utils";
import { Constants } from "./shared/constants/constants";
import { Language } from "./shared/constants/enums";
import { AppTitleService, LanguageService, SYS_LAYOUT_CHANNEL } from "./shared/services";

@Component({
  selector: "adaa-root",
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: "./app.component.html",
  styleUrl: "./app.component.scss",
})
export class AdaaComponent implements OnInit, OnDestroy {
  private _appTitleService = inject(AppTitleService);
  private _router = inject(Router);
  private _languageService = inject(LanguageService);

  private get _routeChanges$() {
    return this._router.events.pipe(filter((event) => event instanceof NavigationEnd));
  }

  public ngOnInit() {
    this._setAdaaLanguage();
    this._appTitleService.changeTitle(this._routeChanges$);
    this._appTitleService.changeTitle(this._languageService.hasChanged$);
  }

  public ngOnDestroy() {
    SYS_LAYOUT_CHANNEL.close();
  }

  private _setAdaaLanguage(): void {
    let localStorageLang = AdaaHelper.getLocalStorage(Constants.localStorageKeys.language) as Language | null;

    if (!localStorageLang) {
      localStorageLang = Language.Arabic;

      AdaaHelper.setLocalStorage(Constants.localStorageKeys.language, Language.Arabic, "string");
    }

    if (localStorageLang !== Language.English && localStorageLang !== Language.Arabic) {
      localStorageLang = Language.Arabic;
    }

    this._languageService.initLang(localStorageLang);
  }
}
