import { NgClass } from "@angular/common";
import { Component, inject, input, OnInit, signal } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { ToastrService } from "ngx-toastr";
import { forkJoin } from "rxjs";

import { AdaaHelper } from "../../../../core/utils";
import { dateRangeValidator } from "../../../../core/validators";
import {
  AttachmentsListComponent,
  CommentsComponent,
  ContributingEntitiesComponent,
  EntityPlanChangeDetectionComponent,
  FloatActionComponent,
  FormDropdownComponent,
  FormDropdownMultiComponent,
  FormInputComponent,
  FormInputDateComponent,
  FormInputFileComponent,
  FormStepsComponent,
  WfAlertDecisionComponent,
} from "../../../../shared/components";
import { genericFloatButtons } from "../../../../shared/components/general/float-action";
import { Constants } from "../../../../shared/constants/constants";
import { FileInputType } from "../../../../shared/constants/enums";
import {
  AgmComment,
  AgmMilestone,
  AgmNationalSector,
  AttachFile,
  FormTab,
  LoginResponse,
  MainResponseModel,
  ValidatorModelType,
  ValueText,
} from "../../../../shared/models";
import {
  AgmMilestoneApiService,
  AgmProjectApiService,
  EntitiesApiService,
  OtherEntitiesApiService,
  SystemLayoutService,
  UsersApiService,
  ValidatorApiService,
} from "../../../../shared/services";

@Component({
  selector: "adaa-agm-milestone-editor",
  imports: [
    NgClass,
    ReactiveFormsModule,
    TranslateModule,
    FormStepsComponent,
    FormDropdownComponent,
    FormDropdownMultiComponent,
    FormInputComponent,
    FormInputDateComponent,
    FormInputFileComponent,
    CommentsComponent,
    ContributingEntitiesComponent,
    AttachmentsListComponent,
    WfAlertDecisionComponent,
    EntityPlanChangeDetectionComponent,
    FloatActionComponent,
  ],
  templateUrl: "./agm-milestone-editor.component.html",
  styleUrl: "./agm-milestone-editor.component.scss",
})
export class AgmMilestoneEditorComponent implements OnInit {
  private _formBuilder = inject(FormBuilder);
  private _router = inject(Router);
  private _toastrService = inject(ToastrService);
  private _translateService = inject(TranslateService);
  private _validatorApiService = inject(ValidatorApiService);
  private _agmMilestoneApiService = inject(AgmMilestoneApiService);
  private _usersApiService = inject(UsersApiService);
  private _entitiesApiService = inject(EntitiesApiService);
  private _otherEntitiesApiService = inject(OtherEntitiesApiService);
  private _agmProjectApiService = inject(AgmProjectApiService);
  private _systemLayoutService = inject(SystemLayoutService);

  id = input.required<number>();

  private readonly _untilDestroyed = AdaaHelper.untilDestroyed();
  private readonly _floatActions = genericFloatButtons();

  AdaaHelper = AdaaHelper;
  constants = Constants;
  submitted: boolean = false;
  milestoneForm: FormGroup;
  formValidation = signal<ValidatorModelType | undefined>(undefined);
  activeTab = signal<number>(0);
  projects = signal<ValueText[]>([]);
  sectors = signal<ValueText[]>([]);
  entities = signal<ValueText[]>([]);
  otherEntities = signal<ValueText[]>([]);
  owners = signal<ValueText[]>([]);
  allowedFiles: FileInputType[] = [FileInputType.pdf, FileInputType.txt, FileInputType.doc, FileInputType.docx];
  readonly fetchMilestone = () => {
    return this._agmMilestoneApiService.getMilestone(this.id());
  };
  tabs: FormTab[] = [
    {
      title: "common.form.label.information",
    },
    {
      title: "kpi.contributing_entities",
    },
    {
      title: "kpi.attachments",
    },
  ];

  public get displayLabel(): string {
    const nameEN = this.milestoneForm.get("nameEN")?.value;
    const nameAE = this.milestoneForm.get("nameAE")?.value;

    return AdaaHelper.getPageLabel(nameAE, nameEN);
  }

  public get signOffFile(): AttachFile {
    const attachment = this.milestoneForm.get("signOffAttachment")?.value;
    return {
      id: attachment ? attachment.id : 0,
      filename: attachment ? attachment.filename : "",
    };
  }

  public get comments(): AgmComment[] {
    const comments: AgmComment[] = this.milestoneForm.get("comments")?.value ?? [];

    return comments.filter((e) => e.status !== Constants.OBJECT_STATUS.REMOVE);
  }

  public ngOnInit(): void {
    this.milestoneForm = this._prepareForm();

    this._floatActions([
      {
        key: "workflowHistory",
        data: {
          itemId: +this.id(),
          itemTypeId: Constants.CONSTANT_WORKFLOW.ANNUAL_PROJECT_MILESTONES,
        },
      },
      {
        key: "systemLog",
        data: {
          itemId: +this.id()!,
        },
      },
      {
        key: "print",
        data: undefined,
      },
    ]);

    this._systemLayoutService.hasCycleChanged$.pipe(this._untilDestroyed()).subscribe({
      next: () => this.goBack(),
    });
    this._systemLayoutService.hasActiveEntityChanged$.pipe(this._untilDestroyed()).subscribe({
      next: () => this.goBack(),
    });
  }

  public save(): void {
    this.submitted = true;
    this.tabs.forEach((e) => (e.hasError = false));

    if (!this.milestoneForm.valid) {
      this.tabs[0].hasError = true;
      this._toastrService.warning(this._translateService.instant("notification.warning.missing_info"));
      return;
    }

    const milestone = this.milestoneForm.getRawValue() as AgmMilestone;

    this._agmMilestoneApiService.updateMilestone(true, this._prepareData(milestone)).subscribe({
      next: (response) => {
        if (response.inError) return;

        this._toastrService.success(this._translateService.instant("notification.success.save"));
        this.goBack();
      },
    });
  }

  public goBack(): void {
    this._router.navigateByUrl("/console/annual-meetings-projects/milestones");
  }

  public onProgressChange(event: string | number | undefined) {
    if (event && +event === 100) {
      this.milestoneForm.get("signOff")?.setValidators(Validators.required);
      this.milestoneForm.get("actualEndDate")?.setValidators(Validators.required);
      this.milestoneForm.get("actualEndDate")?.enable();
    } else {
      this.milestoneForm.get("signOff")?.clearValidators();
      this.milestoneForm.get("actualEndDate")?.clearValidators();
      this.milestoneForm.get("actualEndDate")?.disable();
    }

    this.milestoneForm.updateValueAndValidity();
  }

  public entityChanged(value: number | undefined): void {
    if (!value) return;

    this._usersApiService.getOwnersPerEntity(value).subscribe({
      next: (response) => {
        if (response.inError) return;

        this.owners.set(AdaaHelper.setDropdownArray(response.responseData, "id", AdaaHelper.getFieldLanguage("name")));
      },
    });
  }

  public signOffFilechange(event: AttachFile | null): void {
    this.milestoneForm.get("signOffAttachment")?.setValue(event ? event : null);
  }

  public sectorsChanged(event: number[]): void {
    const nationalSectors = this.milestoneForm.get("nationalSectors")?.value ?? [];

    event.forEach((id) => {
      const item = nationalSectors.find((e: { nationalSectorId: number }) => e.nationalSectorId === id);
      if (!item) {
        const ns = {
          nationalSectorId: id,
          nameEN: "",
          nameAE: "",
          status: Constants.OBJECT_STATUS.ACTIVE,
        };
        nationalSectors.push(ns);
      } else {
        item.status = Constants.OBJECT_STATUS.ACTIVE;
      }
    });

    for (let i = nationalSectors.length - 1; i >= 0; i--) {
      if (!event.includes(nationalSectors[i].nationalSectorId)) {
        if ("id" in nationalSectors[i]) {
          nationalSectors[i].status = Constants.OBJECT_STATUS.REMOVE;
        } else {
          nationalSectors.splice(i, 1);
        }
      }
    }

    this.milestoneForm.get("nationalSectors")?.setValue(nationalSectors);
  }

  public commentAdded(event: string): void {
    const currentUser = AdaaHelper.getLocalStorage(Constants.localStorageKeys.user, {
      type: "json",
    }) as LoginResponse;

    const comments: AgmComment[] = this.milestoneForm.get("comments")?.value ?? [];

    comments.push({
      id: 0,
      status: Constants.OBJECT_STATUS.ACTIVE,
      itemId: +this.id(),
      itemType: Constants.CONSTANT_NATIONAL_PROJECTS_MILESTONE,
      commentsAE: event,
      commentsEN: event,
      creationDate: moment().utcOffset(Constants.uaeTimezone).valueOf(),
      updatedBy: currentUser.id,
      createdBy: currentUser.id,
      updateUserNameEN: currentUser.nameEN,
      updateUserNameAE: currentUser.nameAE,
      createdByUserNameEN: currentUser.nameEN,
      createdByUserNameAE: currentUser.nameAE,
      fakeId: Math.floor(Math.random() * 217 * 1000),
    });

    this.milestoneForm.get("comments")?.setValue(comments);
  }

  public commentRemoved(event: AgmComment): void {
    let comments: AgmComment[] = this.milestoneForm.get("comments")?.value ?? [];

    if (event.fakeId) {
      comments = comments.filter((e) => e.fakeId !== event.fakeId);
    } else {
      const comment = comments.find((e) => e.id === event.id);
      if (comment) comment.status = Constants.OBJECT_STATUS.REMOVE;
    }

    this.milestoneForm.get("comments")?.setValue(comments);
  }

  public initMilestone(milestone: MainResponseModel<AgmMilestone>): void {
    if (milestone.inError) {
      this.goBack();
    }

    if (milestone.responseData.status === Constants.OBJECT_STATUS.IN_REVIEW) {
      this._toastrService.warning(this._translateService.instant("notification.error.object_in_approval"));
      this.goBack();
    }

    this._initPage();

    this.milestoneForm.patchValue(milestone.responseData);
    this.milestoneForm.get("sectors")?.setValue(milestone.responseData.nationalSectors?.map((e) => e.nationalSectorId));

    this.projects.set([
      {
        value: milestone.responseData.annualMeetingProjectId,
        text: AdaaHelper.getItemValueByToken(milestone.responseData, "annualMeetingProjectName"),
      },
    ]);

    if (milestone.responseData.annualMeetingProjectId)
      this._getParentProject(milestone.responseData.annualMeetingProjectId);

    this.entityChanged(milestone.responseData.entityId);
    this.onProgressChange(milestone.responseData.progress);
  }

  private _initPage(): void {
    forkJoin({
      validators: this._validatorApiService.searchByKey(
        Constants.VALIDATORS_CONF_KEY.VALIDATION_NATIONAL_MILESTONE_FORM
      ),
      entities: this._entitiesApiService.getAllV2(),
      otherEntities: this._otherEntitiesApiService.getAll(),
    }).subscribe({
      next: (results) => {
        if (!results.validators.inError) this.formValidation.set(results.validators.responseData);

        this.entities.set(
          AdaaHelper.setDropdownArray(
            results.entities.inError ? [] : results.entities.responseData,
            "id",
            AdaaHelper.getFieldLanguage("name")
          )
        );

        this.otherEntities.set(
          AdaaHelper.setDropdownArray(
            results.otherEntities.inError ? [] : results.otherEntities.responseData,
            "id",
            AdaaHelper.getFieldLanguage("name")
          )
        );
      },
    });
  }

  private _getParentProject(projectId: number): void {
    this._agmProjectApiService.getProject(projectId).subscribe({
      next: (response) => {
        if (response.inError) return;

        this._setSectors(response.responseData.nationalSectors);
      },
    });
  }

  private _setSectors(secotrs: AgmNationalSector[]): void {
    const sectorsIds = this.sectors().map((e) => e.value);
    secotrs = secotrs.filter((e) => !sectorsIds.includes(e.id));
    this.sectors.set(AdaaHelper.setDropdownArray(secotrs, "nationalSectorId", AdaaHelper.getFieldLanguage("name")));
  }

  private _prepareForm() {
    return this._formBuilder.group(
      {
        id: { value: null },
        annualMeetingProjectId: [{ value: null, disabled: true }, Validators.required],
        nameAE: [{ value: null, disabled: true }, Validators.required],
        nameEN: [{ value: null, disabled: true }, Validators.required],
        dscAE: [{ value: null, disabled: true }, Validators.required],
        dscEN: [{ value: null, disabled: true }, Validators.required],
        startDate: [{ value: null, disabled: false }, Validators.required],
        endDate: [{ value: null, disabled: true }, Validators.required],
        actualStartDate: { value: null, disabled: false },
        actualEndDate: { value: null, disabled: false },
        entityId: [{ value: null, disabled: true }, Validators.required],
        owner: { value: null, disabled: true },
        budget: { value: null, disabled: false },
        expense: { value: null, disabled: false },
        progress: [{ value: null, disabled: false }, [Validators.max(100), Validators.min(0)]],
        signOff: { value: null, disabled: false },
        signOffAttachment: { value: null, disabled: false },
        nationalSectors: { value: null, disabled: false },
        comments: { value: null, disabled: false },
        contributingEntities: { value: null, disabled: false },
        attachments: { value: null, disabled: false },
        milestoneOrder: { value: null, disabled: false },
        status: { value: null, disabled: false },

        sectors: [{ value: null, disabled: false }], //ONLY FE
      },
      {
        validators: Validators.compose([
          dateRangeValidator("startDate", "endDate"),
          dateRangeValidator("actualStartDate", "actualEndDate"),
        ]),
      }
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _prepareData(milestone: any): AgmMilestone {
    delete milestone.sectors;

    milestone.budget = milestone.budget ? +milestone.budget : null;
    milestone.expense = milestone.expense ? +milestone.expense : null;
    milestone.progress = milestone.progress ? +milestone.progress : null;

    if (milestone.progress !== 100) {
      milestone.signOff = null;
      milestone.signOffAttachment = null;
      milestone.actualEndDate = null;
    }

    if (milestone.comments)
      milestone.comments.forEach((e: { fakeId: number | undefined; id: number | undefined }) => {
        if (e.fakeId) {
          delete e.fakeId;
          delete e.id;
        }
      });

    return milestone;
  }
}
