<div class="card strategy-card">
    <div class="card-title pb-0">
        <h6 class="text-center fw-bold m-0">{{ strategy() | translateToken: "name" }}</h6>
        <div class="w-100 text-center mt-2 pb-2 border-bottom border-muted">
            <small class="text-primary fw-bold"> {{ strategy().startDate | date: "yyyy" }} - {{ strategy().endDate | date: "yyyy" }} </small>
        </div>
    </div>

    <div class="card-body pb-3 pt-1">
        <div class="strategy-card--progress">
            <adaa-progress-indicator
                [height]="21"
                [label]="'national_strategies.project_progress' | translate"
                [value]="strategy().progress!"
                [color]="strategy().progressColor!"
            />

            @if (!showAllStrategies()) {
                <adaa-progress-indicator
                    [height]="21"
                    [label]="'landingPage.entity_page.annual_performance' | translate"
                    [value]="strategy().score!"
                    [color]="strategy().scoreColor!"
                />
            }

            <adaa-progress-indicator
                [height]="21"
                [label]="'landingPage.entity_page.overall_performance' | translate"
                [value]="strategy().visionScore!"
                [color]="strategy().visionScoreColor!"
            />
        </div>

        <div class="d-flex justify-content-between align-items-center flex-nowrap position-relative pt-2">
            <div class="count-widget d-flex justify-content-between align-items-center">
                <span class="d-flex align-items-center justify-content-center">
                    <i class="adaa-icon-console_kpis"></i>
                </span>
                <span class="d-flex align-items-center">
                    <b class="mx-2">{{ "breadcrumbs.kpi" | translate }}</b>
                    <b>{{ strategy().kpiCount > 0 ? strategy().kpiCount : "-" }}</b>
                </span>
            </div>

            <div class="count-widget d-flex align-items-center">
                <span class="d-flex align-items-center justify-content-center">
                    <i class="adaa-icon-console_initiatives"></i>
                </span>
                <span class="d-flex align-items-center">
                    <b class="mx-2">{{ "national_projects.projects" | translate }}</b>
                    <b>{{ strategy().initiativesCount > 0 ? strategy().initiativesCount : "-" }}</b>
                </span>
            </div>
        </div>
    </div>

    <div class="d-flex justify-content-center align-items-start card-footer py-2">
        @if (strategy().logo) {
            <adaa-picture [pictureId]="strategy().logo" height="auto" width="300px"></adaa-picture>
        }
    </div>

    <div [class]="'rounded-bottom text-white col-12 bg-' + getColor(strategy().progressStatus)"></div>
</div>
