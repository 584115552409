import { Component, inject, OnInit } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { AdaaHelper } from "../../../core/utils";
import {
  ConfirmationModalComponent,
  DataTableComponent,
  FormActionButtonsComponent,
  FormCheckboxComponent,
  FormDropdownComponent,
  FormInputComponent,
  FormInputDateComponent,
  FormInputFileComponent,
  SubDirectionModalComponent,
} from "../../../shared/components";
import { Constants } from "../../../shared/constants/constants";
import { ActionsEvents, AdaaBoolean, FileInputType, Language, PageMode } from "../../../shared/constants/enums";
import { AttachFile, SubGovDirection, TableButtonClicked } from "../../../shared/models";
import {
  AppService,
  GovernmentDirectionsEditorService,
  LanguageService,
  SystemLayoutService,
} from "../../../shared/services";

@Component({
  selector: "adaa-government-directions-editor",
  standalone: true,
  imports: [
    TranslateModule,
    ReactiveFormsModule,
    FormInputComponent,
    FormInputDateComponent,
    FormDropdownComponent,
    FormCheckboxComponent,
    FormInputFileComponent,
    DataTableComponent,
    FormActionButtonsComponent,
  ],
  templateUrl: "./government-directions-editor.component.html",
  styleUrl: "./government-directions-editor.component.scss",
})
export class GovernmentDirectionsEditorComponent implements OnInit {
  private _activatedRoute = inject(ActivatedRoute);
  private _languageService = inject(LanguageService);
  private _modalService = inject(NgbModal);
  private _router = inject(Router);
  private _toastrService = inject(ToastrService);
  private _translateService = inject(TranslateService);
  private _systemLayoutService = inject(SystemLayoutService);
  private _appService = inject(AppService);
  directionsService = inject(GovernmentDirectionsEditorService);

  private _untilDestroyed = AdaaHelper.untilDestroyed();
  language = Language;
  directionForm: FormGroup;
  pageMode: PageMode;
  isViewMode: boolean = true;
  allowedFiles: FileInputType[] = [FileInputType.svg];
  submitted: boolean = false;
  directionId: number | undefined = undefined;
  goToSubmit: boolean = false;
  filteredSubDirections: SubGovDirection[] = [];
  subDirections: SubGovDirection[] = [];

  public get directionIcon(): AttachFile {
    return {
      id: this.directionForm.get("logo")?.value,
      filename: this.directionForm.get("logo")?.value ? "logo.svg" : "",
    };
  }

  public get displayLabel(): string {
    const nameEN = this.directionForm.get("nameEN")?.value;
    const nameAE = this.directionForm.get("nameAE")?.value;

    return AdaaHelper.getPageLabel(nameAE, nameEN);
  }

  public ngOnInit(): void {
    this.pageMode = this._activatedRoute.snapshot.data.pageMode;
    this.isViewMode = this.pageMode === PageMode.view;

    this.directionsService.initPage();
    this.directionForm = this.directionsService.prepareForm(this.pageMode);
    this.directionId = +this._activatedRoute.snapshot.params.id;

    if (this.directionId) {
      this.directionsService.getDirection(this.directionId).subscribe((direction) => {
        if (!direction) return;
        this.directionForm.patchValue(direction);
        this.subDirections = direction.subdirections ?? [];

        this._setSubDirections();
      });
    }

    this._systemLayoutService.hasCycleChanged$.pipe(this._untilDestroyed()).subscribe({
      next: () => window.location.reload(),
    });

    this._systemLayoutService.hasActiveEntityChanged$.pipe(this._untilDestroyed()).subscribe({
      next: () => this._router.navigateByUrl(`/console`),
    });
  }

  public visionChanged(value: AdaaBoolean) {
    if (value === AdaaBoolean.Y) {
      this.directionForm.get("isNationalStrategy")?.setValue(false);
      this.directionForm.get("isNationalStrategy")?.disable();
    } else this.directionForm.get("isNationalStrategy")?.enable();
  }

  public onFilechange(event: AttachFile | null) {
    this.directionForm.get("logo")?.setValue(event ? event.id : null);
  }

  public addSubDirectionClicked(): void {
    const subDirection: SubGovDirection = {
      govDirectionId: undefined,
      isMain: false,
      refCode: undefined,
      nameAE: "",
      nameEN: "",
      id: 0,
      owner: undefined,
      status: Constants.OBJECT_STATUS.ACTIVE,
    };

    this._openSubDirectionModal(subDirection, PageMode.create);
  }

  public manageSubDirectionClicked({ event, data }: TableButtonClicked): void {
    switch (event) {
      case ActionsEvents.edit:
      case ActionsEvents.view: {
        const subDirection = this.subDirections.find((e) =>
          data.id ? e.id === data.id : data.fakeId ? e.fakeId === data.fakeId : false
        );
        if (subDirection)
          this._openSubDirectionModal(subDirection, event === ActionsEvents.view ? PageMode.view : PageMode.edit);
        break;
      }
      case ActionsEvents.delete: {
        this._deleteSubDirectionConfirmation(data);
        break;
      }
      default:
        break;
    }
  }

  public submit(): void {
    this.submitted = true;
    this.goToSubmit = false;

    if (!this.directionForm.valid) {
      this._toastrService.warning(this._translateService.instant("notification.warning.missing_info"));
      return;
    }

    if (!AdaaHelper.isDefined(this.subDirections) || this.subDirections.length === 0) {
      this._openDuplicateDirectionModal();
      return;
    }

    this.directionsService.saveDirection(this.directionId, this.directionForm.getRawValue());
  }

  public cancel(): void {
    this._router.navigate(["/console/directions"]);
  }

  private _openSubDirectionModal(subDirection: SubGovDirection, pageMode: PageMode) {
    const modal = this._modalService.open(SubDirectionModalComponent, {
      centered: true,
      size: "xl",
      modalDialogClass: `modal-${this._languageService.direction()}`,
    });

    modal.componentInstance.subDirection = subDirection;
    modal.componentInstance.pageMode = pageMode;

    modal.result.then((event) => {
      if (!AdaaHelper.isDefined(event)) {
        this.goToSubmit = false;
        return;
      }
      if (this._checkForDuplicateName(event)) {
        this._toastrService.error(this._translateService.instant("directions.sub_name_exists"));
        return;
      }

      const subDirectionIndex = this.subDirections.findIndex((e) =>
        event.id ? event.id === e.id : event.fakeId ? event.fakeId === e.fakeId : false
      );

      if (subDirectionIndex > -1) {
        this.subDirections.splice(subDirectionIndex, 1, event);
      } else {
        delete event.id;
        event.fakeId = Math.floor(Math.random() * 217 * 1000);
        this.subDirections.push(event);
      }

      this._setSubDirections();

      if (this.goToSubmit) this.submit();
    });
  }

  private _openDuplicateDirectionModal(): void {
    const modal = this._modalService.open(ConfirmationModalComponent, {
      centered: true,
      size: "md",
      modalDialogClass: `modal-${this._languageService.direction()}`,
    });

    modal.componentInstance.header = "directions.identical_title";
    modal.componentInstance.title = "directions.identical_text";

    modal.result.then((e) => {
      if (e) this._createDuplicateSubDirection();
    });
  }

  private _createDuplicateSubDirection(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const subDirection: any = {
      nameAE: this.directionForm.get("nameAE")?.value,
      nameEN: this.directionForm.get("nameEN")?.value,
      dscAE: this.directionForm.get("dscAE")?.value,
      dscEN: this.directionForm.get("dscEN")?.value,
      status: Constants.OBJECT_STATUS.ACTIVE,
      isMain: false,
      fakeId: Math.floor(Math.random() * 217 * 1000),
      owner: null,
    };

    this.goToSubmit = true;
    this._openSubDirectionModal(subDirection, PageMode.edit);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _deleteSubDirectionConfirmation(data: any): void {
    const modal = this._modalService.open(ConfirmationModalComponent, {
      centered: true,
      size: "md",
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });

    modal.componentInstance.header = "directions.sub_government";
    modal.componentInstance.title = "directions.subDirectionRemove";

    modal.result.then((e) => {
      if (e) this._deleteSubDirectionConfirmed(data);
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _deleteSubDirectionConfirmed(data: any): void {
    if (data.id) {
      const subDirection = this.subDirections.find((e) => e.id === data.id);
      if (subDirection) subDirection.status = Constants.OBJECT_STATUS.REMOVE;
    } else if (data.fakeId) {
      const subDirectionsIndex = this.subDirections.findIndex((e) => e.fakeId === data.fakeId);
      if (subDirectionsIndex > -1) this.subDirections.splice(subDirectionsIndex, 1);
    }

    this._setSubDirections();
  }

  private _setSubDirections(): void {
    this.filteredSubDirections.forEach(
      (e) =>
        (e.auxiliaryDirectionRequests = e.auxiliaryDirectionRequests?.filter(
          (e) => e.status != Constants.OBJECT_STATUS.REMOVE
        ))
    );

    this.filteredSubDirections = AdaaHelper.clone(
      this.subDirections.filter((e) => e.status !== Constants.OBJECT_STATUS.REMOVE)
    );

    this.directionForm.get("subdirections")?.setValue(this.subDirections);
  }

  private _checkForDuplicateName({ nameAE, nameEN, fakeId, id }: SubGovDirection): boolean {
    if (id) {
      return this.subDirections.findIndex((e) => (e.nameAE === nameAE || e.nameEN === nameEN) && e.id !== id) > -1;
    } else if (fakeId) {
      return (
        this.subDirections.findIndex((e) => (e.nameAE === nameAE || e.nameEN === nameEN) && e.fakeId !== fakeId) > -1
      );
    }
    return false;
  }
}
