<ng-template #content let-modal>
    <div class="modal-header">
        <h5 class="text-center fw-bold w-100">{{ "data_entry.edit_value" | translate }}</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body overflow-x-hidden p-3">
        <div>
            <adaa-form-input
                [setDefaultValue]="value()"
                (inputChanges)="value.set($event)"
                [inputType]="'number'"
                [label]="'common.form.label.value' | translate"
            ></adaa-form-input>
        </div>
    </div>
    <div class="modal=footer d-flex justify-content-center mt-3">
        <adaa-form-action-buttons (cancelClicked)="modal.dismiss()" (saveClicked)="updateValue()"></adaa-form-action-buttons>
    </div>
</ng-template>
