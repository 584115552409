import { Component, computed, inject, OnInit, signal, viewChildren } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";

import { AdaaHelper } from "../../../../../core/utils";
import { DataTableComponent, FormDropdownComponent, FormInputComponent } from "../../../../../shared/components";
import { Constants } from "../../../../../shared/constants/constants";
import { AdaaBoolean } from "../../../../../shared/constants/enums";
import { DataEntryMetricModel, KpiMetricModelType, ValueText } from "../../../../../shared/models";
import { LanguageService } from "../../../../../shared/services";

@Component({
  selector: "adaa-kpi-formula-metrics",
  standalone: true,
  styleUrl: "../styles.scss",
  imports: [TranslateModule, FormsModule, DataTableComponent, FormDropdownComponent, FormInputComponent],
  templateUrl: "./kpi-formula-metrics.component.html",
})
export class KpiFormulaMetricsComponent implements OnInit {
  readonly modal: NgbActiveModal = inject(NgbActiveModal);
  readonly languageService = inject(LanguageService);

  metricTypeOptions = signal<ValueText[]>([]);
  parsedFormula = signal<{ label: string; flgIsLocal: boolean; flgIsGlobal: boolean }[]>([]);
  hideYtpCalc = signal<boolean>(false);
  ytpCalcOptions = signal<ValueText[]>([]);
  stagedKpi = signal<Record<string, unknown> | undefined>(undefined);
  kpi = signal<Record<string, unknown> | undefined>(undefined);
  showMetricsList = signal<boolean>(false);
  kpiType = signal<number | undefined>(undefined);
  metrics = signal<KpiMetricModelType[]>([]);
  selectedMetric = signal<number | undefined>(undefined);

  isDTKPI = computed(() => this.kpiType() === Constants.KPI_TYPE.DTKPI);
  isGSKPI = computed(() => this.kpiType() === Constants.KPI_TYPE.GSKPI);
  formulaString = computed(() => this.stagedKpi()?.formula as string);
  stagedMetrics = computed(() => {
    const kpi = this.stagedKpi();
    if (!kpi?.metrics) return [];
    return kpi.metrics as KpiMetricModelType[];
  });
  metricTableConf = computed(() => {
    const metrics = this.metrics();
    if (metrics[this.selectedMetric()!].metricType === "M") {
      return {
        key: Constants.MAIN_TABLE_LIST_CONF_KEY.METRICS_MONITORING,
        apiName: "getAllMonitoring",
      };
    }

    return {
      key: Constants.MAIN_TABLE_LIST_CONF_KEY.METRICS,
      apiName: "getAllSharedAndLocal",
    };
  });

  metricDescription = viewChildren<FormInputComponent>("metricDescription");

  readonly #CONSTANT_AVERAGE = 25;

  readonly #metricTypeOptions = () => {
    const options: ValueText[] = [
      {
        value: "C",
        text: this.languageService.translate("metrics.custom_var"),
      },
      {
        value: "G/L",
        text: this.languageService.translate("metrics.global_local_var"),
      },
    ];

    if (this.isGSKPI()) {
      options.push({
        value: "M",
        text: this.languageService.translate("main_types.M"),
      });
    }

    this.metricTypeOptions.set(options);
  };

  public ngOnInit() {
    this.#metricTypeOptions();
  }

  public saveMetricDetails(data: {
    metrics: KpiMetricModelType[];
    formulaStatusHasChanged: boolean;
    revalidate: boolean;
    clearMetrics: boolean;
  }) {
    const { valid } = this._getMetricListErrors(data.metrics);
    if (!valid) return;
    this.modal.close(data);
  }

  public handleMetricSelection($e: DataEntryMetricModel, $i: number) {
    const metrics = this.metrics();
    metrics.forEach((metric, index) => {
      if ($i === index) {
        metrics[index] = {
          ...metric,
          status: $e.status,
          metricId: $e.id as number,
          metricType: $e.type,
          dscAE: $e.dscAE as string,
          dscEN: $e.dscEN as string,
          dataTypeAE: $e.dataTypeAE as string,
          dataTypeEN: $e.dataTypeEN as string,
        };
      }
    });

    this.metrics.set(metrics);
    this.showMetricsList.set(false);
    this.selectedMetric.set(undefined);
  }

  public handleChange(key: string, $e: unknown, $i: number) {
    if (key === "metricType" && $e !== "C") {
      this.selectedMetric.set($i);
      this.showMetricsList.set(true);
      return;
    }

    const dscEN: Record<string, unknown> = {};
    if (key === "dscAE") {
      dscEN["dscEN"] = $e;
    }

    const metrics = this.metrics();
    metrics.forEach((metric, index) => {
      if ($i === index) {
        metrics[index] = {
          ...metric,
          ...dscEN,
          metricId: $e !== "C" ? metric.metricId : undefined,
          [key]: $e,
        };
      }
    });

    this.metrics.set(metrics);
    this.selectedMetric.set(undefined);
    this.showMetricsList.set(false);
  }

  public updateMetrics() {
    if (!this.parsedFormula().length) {
      this.modal.close({
        metrics: this.stagedKpi()?.metrics ?? [],
        revalidate: true,
        formulaStatusHasChanged: false,
        clearMetrics: false,
      });

      return;
    }

    const kpiId = (this.stagedKpi()?.id as number) ?? null;

    for (const { label, flgIsLocal, flgIsGlobal } of this._getUniqueMetrics()) {
      const stagedMetric = this.stagedMetrics().find(({ nameAE }) => nameAE === label);
      if (!stagedMetric) {
        const m = this.metrics();
        const i = m.findIndex(({ nameAE }) => nameAE === label);
        if (i === -1) {
          m.push({
            kpiId,
            nameAE: label,
            nameEN: label,
            dscAE: null,
            dscEN: null,
            dataType: undefined,
            type: undefined,
            ytpCalc: this.#CONSTANT_AVERAGE,
            metricReadingsEnabler: [],
            metricReadings: [],
            metricType: flgIsLocal || flgIsGlobal ? "G/L" : "C",
            metricId: undefined,
            flgIsLocal: flgIsLocal ? AdaaBoolean.Y : AdaaBoolean.N,
            flgIsGlobal: flgIsGlobal ? AdaaBoolean.Y : AdaaBoolean.N,
          });
        } else {
          m[i] = {
            ...m[i],
            ytpCalc: m[i].ytpCalc ?? this.#CONSTANT_AVERAGE,
            type: m[i].type ?? undefined,
            dataType: m[i].dataType ?? undefined,
            nameAE: label,
            nameEN: label,
            flgIsLocal: flgIsLocal ? AdaaBoolean.Y : AdaaBoolean.N,
            flgIsGlobal: flgIsGlobal ? AdaaBoolean.Y : AdaaBoolean.N,
            metricType: flgIsLocal || flgIsGlobal ? "G/L" : "C",
          };
        }

        this.metrics.set(m);
        continue;
      }

      const m = [...this.metrics()];
      const i = m.findIndex(({ nameAE }) => nameAE === label);

      if (i === -1) {
        m.push({
          ...stagedMetric,
          nameAE: label,
          nameEN: label,
          type: stagedMetric.type ?? undefined,
          dataType: stagedMetric.dataType ?? undefined,
          ytpCalc: stagedMetric.ytpCalc ?? this.#CONSTANT_AVERAGE,
          metricType: !stagedMetric.metricType ? "C" : stagedMetric.metricType,
          flgIsLocal: flgIsLocal || stagedMetric.metricType === "L" ? AdaaBoolean.Y : AdaaBoolean.N,
          flgIsGlobal: flgIsGlobal || stagedMetric.metricType === "G" ? AdaaBoolean.Y : AdaaBoolean.N,
        });
      } else {
        m[i] = {
          ...stagedMetric,
          ...m[i],
          type: m[i].type ?? stagedMetric.type ?? undefined,
          dataType: m[i].dataType ?? stagedMetric.dataType ?? undefined,
          ytpCalc: m[i].ytpCalc ?? stagedMetric.ytpCalc ?? this.#CONSTANT_AVERAGE,
          nameAE: label,
          nameEN: label,
          metricType: !stagedMetric.metricType ? "C" : stagedMetric.metricType,
          flgIsLocal: flgIsLocal || stagedMetric.metricType === "L" ? AdaaBoolean.Y : AdaaBoolean.N,
          flgIsGlobal: flgIsGlobal || stagedMetric.metricType === "G" ? AdaaBoolean.Y : AdaaBoolean.N,
        };
      }

      this.metrics.set(m);
    }
  }

  public clearMetrics() {
    const auxMetrics = this.kpi()?.metrics ?? [];
    const metrics = this.metrics() ?? [];

    return JSON.stringify(auxMetrics) !== JSON.stringify(metrics);
  }

  private _getUniqueMetrics() {
    const p = new Set();
    for (const f of this.parsedFormula()) {
      p.add(JSON.stringify(f));
    }

    return Array.from(p).map((s) => JSON.parse(s as string)) as {
      label: string;
      flgIsLocal: boolean;
      flgIsGlobal: boolean;
    }[];
  }

  private _getMetricListErrors(metrics: KpiMetricModelType[]) {
    const list = this.metricDescription() ?? [];
    list.forEach((item) => {
      item?.inputControl.markAsTouched();
      item?.inputControl?.setErrors(null);
      item.invalid = false;
    });

    const invalidDescriptions = metrics.filter((metric) => !AdaaHelper.isDefined(metric.dscAE));
    if (invalidDescriptions.length > 0) {
      for (let $i = 0; $i < invalidDescriptions.length; $i++) {
        const formInput = list[$i];
        formInput?.inputControl?.markAsTouched();
        formInput?.inputControl?.setErrors({
          required: true,
        });
        formInput.invalid = true;
      }
    }

    return { valid: invalidDescriptions.length === 0 };
  }
}
