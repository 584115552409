<adaa-data-table
    #auditsTable
    apiName="getAuditList"
    paramsString="onlyAuditIssues=true"
    titleHeader="audit_data.audit_issues"
    [configKey]="Constants.MAIN_TABLE_LIST_CONF_KEY.AUDIT_ISSUES_LIST"
    [enableAdd]="false"
    [enableDeleteAction]="false"
    (actionClicked)="actionClicked($event)"
/>
