<adaa-auth-layout>
    @if (step === 1) {
        <h4 class="text-primary fw-bolder">{{ "login.link_account_title" | translate }}</h4>
        <h5 class="fw-bolder">{{ "login.have_account_question" | translate }}</h5>
        <div class="row my-3">
            <div class="col-3"></div>
            <div class="col-3 d-flex justify-content-center">
                <button class="btn btn-primary fw-bolder" (click)="linkAccount(true)">
                    {{ "login.yes" | translate }}
                </button>
            </div>
            <div class="col-3 d-flex justify-content-center">
                <button class="btn btn-danger fw-bolder" (click)="linkAccount(false)">
                    {{ "login.no" | translate }}
                </button>
            </div>
            <div class="col-3"></div>
        </div>
        <h5>{{ "login.uae_pass_link_note1" | translate }}</h5>
        <h5 class="mb-4">{{ "login.uae_pass_link_note3" | translate }}</h5>
    } @else {
        <h4 class="text-primary fw-bolder">{{ "login.link_account_title" | translate }}</h4>
        <h5 class="fw-bolder">{{ "login.link_account_dsc" | translate }}</h5>
        <form [formGroup]="linkAccountForm" (ngSubmit)="submit()" class="row mt-2">
            <div class="col-12 mb-3">
                <adaa-form-input
                    controlName="email"
                    formControlName="email"
                    inputType="email"
                    [required]="true"
                    [placeholder]="'common.form.label.email' | translate"
                    [invalid]="
                        linkAccountForm.get('password')?.dirty &&
                        (linkAccountForm.get('email')?.errors?.['required'] || linkAccountForm.get('email')?.errors?.['email'])
                    "
                />

                <adaa-form-input
                    controlName="password"
                    formControlName="password"
                    [required]="true"
                    [placeholder]="'common.form.label.password' | translate"
                    inputType="password"
                    [invalid]="linkAccountForm.get('password')?.dirty && linkAccountForm.get('password')?.errors?.['required']"
                />
            </div>
            <div class="col-12 mb-3">
                <div class="gap-1 col-7 mx-auto d-flex flex-column flex-nowrap align-items-center">
                    <button type="submit" class="btn btn-info">
                        <span>{{ "login.link_to_uae_pass" | translate }}</span>
                    </button>
                </div>
            </div>
            <div class="col-12 mb-4">
                <span role="button" routerLink="/forget-password" class="text-info">{{ "login.forgot" | translate }} </span>
            </div>
        </form>
        <h6 class="my-2 mb-5">{{ "login.uae_pass_link_note3" | translate }}</h6>
    }
</adaa-auth-layout>
