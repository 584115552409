import { Route } from "@angular/router";

import { checkIfLoggedInFn } from "../../core/guards";
import { AUDIT_ACTUALS_ROUTES } from "./audit-actuals/audit-actuals-list.routes";

export const AUDIT_ROUTES: Route[] = [
  {
    path: "audit",
    children: [
      {
        path: "",
        canActivate: [checkIfLoggedInFn],
        data: {
          title: "breadcrumbs.audit_data_module.sub_menu",
          breadcrumb: "breadcrumbs.audit_data_module.sub_menu",
        },
        loadComponent: () => import("./audit-submenu/audit-submenu.component").then((m) => m.AuditSubmenuComponent),
      },
      ...AUDIT_ACTUALS_ROUTES,
    ],
  },
];
