<div class="d-flex gap-2">
    <p class="fw-bold fs-3">{{ displayLabel() }}</p>
</div>

@if (pageMode() === PageMode.view) {
    <p>
        {{ "nkpi.last_update" | translate }}
        <span class="fw-bold">{{ transformationalTarget().updateTS | date: "dd/MM/yyyy" }}</span>
        {{ "common.form.label.by" | translate }}
        <span class="fw-bold">{{ transformationalTarget().updateUser }}</span>
    </p>

    <div class="bg-white p-2 text-start my-3">
        <p>
            <span class="fw-bold">{{ ("national_projects.transformational_targets.pillar" | translate) + ":" }}</span>
            {{ transformationalTarget() | translateToken: "subGovernmentDirectionName" }}
        </p>
    </div>
    <div class="bg-white p-2 text-start my-3">
        <p>
            <span class="fw-bold">{{ ("national_projects.transformational_targets.sub_pillar" | translate) + ":" }}</span>
            {{ transformationalTarget() | translateToken: "auxDirectionName" }}
        </p>
    </div>
    <div class="bg-white p-2 text-start my-3">
        <p class="fw-bold">{{ "common.form.label.description" | translate }}</p>
        <p>{{ transformationalTarget() | translateToken: "dsc" }}</p>
    </div>

    <div class="p-2">
        <adaa-data-table
            #adaaDataTable
            [isLocalData]="true"
            [localData]="transformationalTargets()"
            [titleHeader]="'national_projects.project_names' | translate"
            [configKey]="Constants.MAIN_TABLE_LIST_CONF_KEY.NATIONAL_PROJECTS_CONF_LIST"
            [itemTypeId]="Constants.CONSTANT_PERMISSIONS.NATIONAL_PROJECTS"
            [enableEditAction]="false"
            [enableDeleteAction]="false"
            [enableAdd]="false"
            (actionClicked)="navNationalProjects($event)"
        ></adaa-data-table>
    </div>

    <div class="p-2">
        <adaa-data-table
            #adaaDataTable
            [isLocalData]="true"
            [localData]="transformationalTargetKpis()"
            [titleHeader]="'national_projects.main_outcomes' | translate"
            [configKey]="Constants.MAIN_TABLE_LIST_CONF_KEY.NATIONAL_PROJECTS_TARGETS_LIST_CONF_LIST"
            [itemTypeId]="Constants.CONSTANT_PERMISSIONS.NATIONAL_PROJECTS"
            [enableEditAction]="false"
            [enableDeleteAction]="false"
            [enableAdd]="false"
            (actionClicked)="navMOKPI($event)"
        ></adaa-data-table>
    </div>
}

@if (pageMode() === PageMode.edit) {
    <div class="bg-white shadow-md rounded p-2 mb-3">
        <form class="col-12" [formGroup]="tTargetForm">
            <div class="bg-white shadow-md rounded p-2 mb-3">
                <div class="row">
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <adaa-form-dropdown
                            [label]="'national_projects.transformational_targets.pillar' | translate"
                            [options]="pillarOptions()"
                            [setValidator]="tpTargetsEditorService.formValidation()!"
                            [invalid]="submitted && tTargetForm.get('subGovernmentDirectionId')?.errors?.['required']"
                            [clearable]="true"
                            controlName="subGovernmentDirectionId"
                            formControlName="subGovernmentDirectionId"
                            (inputChanges)="onPillarChange($event)"
                        ></adaa-form-dropdown>
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <adaa-form-dropdown
                            [label]="'national_projects.transformational_targets.sub_pillar' | translate"
                            [options]="subPillarOptions()"
                            [setValidator]="tpTargetsEditorService.formValidation()!"
                            [invalid]="submitted && tTargetForm.get('auxDirectionId')?.errors?.['required']"
                            [clearable]="true"
                            controlName="auxDirectionId"
                            formControlName="auxDirectionId"
                            (inputChanges)="onSubPillarChange($event)"
                        ></adaa-form-dropdown>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <adaa-form-input
                            [label]="'common.form.label.nameAE' | translate"
                            [setValidator]="tpTargetsEditorService.formValidation()!"
                            [invalid]="submitted && tTargetForm.get('nameAE')?.errors?.['required']"
                            formControlName="nameAE"
                            controlName="nameAE"
                        ></adaa-form-input>
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <adaa-form-input
                            [label]="'common.form.label.nameEN' | translate"
                            [setValidator]="tpTargetsEditorService.formValidation()!"
                            [invalid]="submitted && tTargetForm.get('nameEN')?.errors?.['required']"
                            formControlName="nameEN"
                            controlName="nameEN"
                        ></adaa-form-input>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <adaa-form-input
                            [label]="'common.form.label.descriptionAE' | translate"
                            [setValidator]="tpTargetsEditorService.formValidation()!"
                            [invalid]="submitted && tTargetForm.get('dscAE')?.errors?.['required']"
                            formControlName="dscAE"
                            controlName="dscAE"
                            [isTextArea]="true"
                        ></adaa-form-input>
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <adaa-form-input
                            [label]="'common.form.label.descriptionEN' | translate"
                            [setValidator]="tpTargetsEditorService.formValidation()!"
                            [invalid]="submitted && tTargetForm.get('dscEN')?.errors?.['required']"
                            formControlName="dscEN"
                            controlName="dscEN"
                            [isTextArea]="true"
                        ></adaa-form-input>
                    </div>
                </div>
            </div>
        </form>
    </div>
}

<div class="col-12 mb-3">
    <adaa-form-action-buttons
        [cancelButtonTitle]="pageMode() === PageMode.view ? ('common.form.button.close' | translate) : ('common.form.button.cancel' | translate)"
        [showSaveButton]="pageMode() !== PageMode.view"
        [saveButtonTitle]="'executive_teams.submit' | translate"
        (saveClicked)="updateTarget()"
        (cancelClicked)="cancelForm()"
    ></adaa-form-action-buttons>
</div>
