import { Route } from "@angular/router";

import { checkIfLoggedInFn, NotArchivedPlan, pmoEntity } from "../../core/guards";
import { Constants } from "../../shared/constants/constants";
import { PageMode } from "../../shared/constants/enums";
import { createPillarsPageGuards, managePillarsPageGuards, viewPillarsPageGuards } from "./_guards";
import { PillarEditorComponent } from "./pillar-editor/pillar-editor.component";
import { PillarsListComponent } from "./pillars-list/pillars-list.component";

export const PILLAR_ROUTES: Route[] = [
  {
    path: "pillars",
    canActivate: [checkIfLoggedInFn, pmoEntity, ...viewPillarsPageGuards],
    children: [
      {
        path: "list",
        redirectTo: "",
      },
      {
        path: "",
        component: PillarsListComponent,
        data: {
          breadcrumb: "breadcrumbs.pillar_module.list",
          title: "breadcrumbs.pillar_module.list",
        },
      },
      {
        path: "create",
        component: PillarEditorComponent,
        canActivate: [...createPillarsPageGuards, NotArchivedPlan()],
        data: {
          breadcrumb: "breadcrumbs.pillar_module.new",
          title: "breadcrumbs.pillar_module.new",
          pageMode: PageMode.create,
        },
      },
      {
        path: "edit/:id",
        component: PillarEditorComponent,
        canActivate: [...managePillarsPageGuards, NotArchivedPlan()],
        data: {
          breadcrumb: "breadcrumbs.pillar_module.edit",
          title: "breadcrumbs.pillar_module.edit",
          pageMode: PageMode.edit,
        },
      },
      {
        path: ":id",
        component: PillarEditorComponent,
        data: {
          breadcrumb: "breadcrumbs.pillar_module.view",
          title: "breadcrumbs.pillar_module.view",
          pageMode: PageMode.view,
        },
      },
    ],
    data: {
      breadcrumb: "breadcrumbs.pillar_module.list",
      title: "breadcrumbs.pillar_module.list",
      tableConfList: Constants.MAIN_TABLE_LIST_CONF_KEY.PILLARS,
    },
  },
];
