<ng-template #content let-modal>
    <div class="modal-header">
        <h5 class="text-center fw-bold w-100">{{ "government_scores.pillars.title_single" | translate }}</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body overflow-x-hidden p-3">
        <form class="col-12" [formGroup]="entityPillarForm">
            <div class="bg-white shadow-md rounded p-2 mb-3">
                <div class="row">
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <adaa-form-input
                            [label]="'common.form.label.nameAE' | translate"
                            formControlName="nameAE"
                            controlName="nameAE"
                            [invalid]="checkInvalid('nameAE')"
                            [required]="true"
                        ></adaa-form-input>
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <adaa-form-input
                            [label]="'common.form.label.nameEN' | translate"
                            formControlName="nameEN"
                            controlName="nameEN"
                            [invalid]="checkInvalid('nameEN')"
                            [required]="true"
                        ></adaa-form-input>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <adaa-form-input
                            [label]="'common.form.label.descriptionAE' | translate"
                            formControlName="dscAE"
                            controlName="dscAE"
                            [invalid]="checkInvalid('dscAE')"
                            [isTextArea]="true"
                            [required]="true"
                        ></adaa-form-input>
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <adaa-form-input
                            [label]="'common.form.label.descriptionEN' | translate"
                            formControlName="dscEN"
                            controlName="dscEN"
                            [invalid]="checkInvalid('dscEN')"
                            [isTextArea]="true"
                            [required]="true"
                        ></adaa-form-input>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <adaa-form-dropdown
                            controlName="pillarType"
                            formControlName="pillarType"
                            appendTo=".modal-content"
                            [label]="'government_scores.pillars.pillar_type' | translate"
                            [invalid]="checkInvalid('pillarType')"
                            [options]="pillarTypeOptions()"
                            [clearable]="true"
                            [required]="true"
                        />
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <adaa-form-input
                            [label]="'common.tables.weight' | translate"
                            formControlName="weight"
                            controlName="weight"
                            [invalid]="checkInvalid('weight')"
                            [inputType]="'number'"
                            [required]="true"
                        ></adaa-form-input>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <adaa-form-input
                            [label]="'executive_dashboard.title' | translate"
                            formControlName="summary"
                            controlName="summary"
                            [isTextArea]="true"
                        ></adaa-form-input>
                    </div>
                </div>
            </div>
        </form>

        <div class="bg-white shadow-md rounded p-2 mb-3">
            <adaa-data-table
                #adaaDataTable
                [configKey]="Constants.MAIN_TABLE_LIST_CONF_KEY.GOV_ENTITY_PILLARS_AREA_OF_FOCUS_CONF"
                [titleHeader]="'government_scores.pillars.area_of_focus.title' | translate"
                [isLocalData]="true"
                [localData]="focusList()"
                [enableAdd]="pageMode() !== PageMode.view"
                [enableViewAction]="pageMode() === PageMode.view"
                [enableEditAction]="pageMode() !== PageMode.view"
                [enableDeleteAction]="pageMode() !== PageMode.view"
                (addNewClicked)="focus.focusIndex = null; focus.open()"
                (actionClicked)="getAction($event)"
            ></adaa-data-table>
        </div>
    </div>
    <div class="modal=footer d-flex justify-content-center mt-3">
        <div class="my-2">
            <adaa-form-action-buttons
                [showSaveButton]="pageMode() !== PageMode.view"
                [saveButtonTitle]="'common.form.button.save' | translate"
                [cancelButtonTitle]="
                    pageMode() === PageMode.view ? ('common.form.button.close' | translate) : ('common.form.button.cancel' | translate)
                "
                (cancelClicked)="modal.close()"
                (saveClicked)="submit()"
            ></adaa-form-action-buttons>
        </div>
    </div>
</ng-template>

<adaa-area-of-focus [focusList]="focusList()" [pageMode]="pageMode()" #focus (focus)="addFocusList($event)"></adaa-area-of-focus>
