import { Component, inject, type OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { AdaaHelper } from "../../../core/utils";
import { Constants } from "../../../shared/constants/constants";
import { SystemLayoutService } from "../../../shared/services";

@Component({
  selector: "adaa-gov-service-team",
  standalone: true,
  imports: [],
  templateUrl: "./gov-service-team.component.html",
  styleUrl: "./gov-service-team.component.scss",
})
export class GovServiceTeamComponent implements OnInit {
  private readonly _router = inject(Router);
  private readonly _systemLayoutService = inject(SystemLayoutService);

  public ngOnInit() {
    this._updateActiveEntity();
    this._refresh();
  }

  private _updateActiveEntity() {
    const entityId = AdaaHelper.getLocalStorage(Constants.localStorageKeys.currentEntity, {
      type: "prop",
      property: "id",
    }) as number | undefined;

    if (!entityId) return;

    if (entityId !== Constants.CONSTANT_PMO_ID) {
      this._router.navigateByUrl("/dashboard/entity");
    }
  }

  private _refresh() {
    this._systemLayoutService.hasActiveEntityChanged$.subscribe(() => {
      this._updateActiveEntity();
    });
  }
}
