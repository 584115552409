import { Component, computed, inject, input, OnInit, signal, viewChild } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { forkJoin } from "rxjs";

import { AdaaHelper } from "../../../core/utils";
import {
  DataTableComponent,
  FormActionButtonsComponent,
  FormCheckboxComponent,
  FormDropdownComponent,
  FormDropdownMultiComponent,
  FormInputComponent,
} from "../../../shared/components";
import { AttributeValuesComponent } from "../../../shared/components/modals/attribute-values/attribute-values.component";
import { AttributesValueInputComponent } from "../../../shared/components/modals/attributes-value-input/attributes-value-input.component";
import { Constants } from "../../../shared/constants/constants";
import { AdaaBoolean, PageMode } from "../../../shared/constants/enums";
import {
  AttributeItemType,
  AttributeModelType,
  AttributeTextMapType,
  PropTypeModelType,
  TableButtonClicked,
  ValueText,
} from "../../../shared/models";
import { AttributesApiService, PropertiesService } from "../../../shared/services";
import { AttributesEditorService } from "../../../shared/services/attributes-editor.service";

@Component({
  selector: "adaa-attributes-editor",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormInputComponent,
    TranslateModule,
    FormDropdownComponent,
    FormDropdownMultiComponent,
    FormCheckboxComponent,
    FormActionButtonsComponent,
    AttributesValueInputComponent,
    DataTableComponent,
    AttributeValuesComponent,
  ],
  templateUrl: "./attributes-editor.component.html",
  styleUrl: "./attributes-editor.component.scss",
})
export class AttributesEditorComponent implements OnInit {
  attributesEditorService = inject(AttributesEditorService);
  private _propsService = inject(PropertiesService);
  private _translateService = inject(TranslateService);
  private _toastrService = inject(ToastrService);
  private _activatedRoute = inject(ActivatedRoute);
  private _formBuilder = inject(FormBuilder);
  private _attributesApiService = inject(AttributesApiService);
  private _router = inject(Router);

  attributesForm: FormGroup = new FormGroup({});
  pageMode = input.required<string>();
  displayLabel = signal<string>("");
  submitted = signal<boolean>(false);
  showTable = signal<boolean>(false);
  attribute = signal<AttributeModelType | undefined>(undefined);
  records = signal<number | string>("");
  fieldType = signal<"text" | "dropdown" | "date">("text");
  dropdownData = signal<ValueText[]>([]);
  defaultValue = signal<string | undefined>(undefined);
  values = signal<AttributeTextMapType[]>([]);
  selectedValue = signal<Partial<AttributeTextMapType> | undefined>(undefined);
  input = viewChild.required<AttributesValueInputComponent>("input");
  attributeValues = viewChild.required<AttributeValuesComponent>("valuesInput");
  selectedItemTypes = signal<AttributeItemType[]>([]);

  dataTypes = signal<PropTypeModelType[]>([]);
  filteredDataTypes = signal<PropTypeModelType[]>([]);
  dataTypeOptions = computed(() => {
    const field = AdaaHelper.getFieldLanguage("name");
    const result: ValueText[] = AdaaHelper.setDropdownArray(this.filteredDataTypes(), "id", field);
    result.unshift({ value: "", text: this._translateService.instant("common.form.label.none") });
    return result;
  });

  itemTypes = signal<PropTypeModelType[]>([]);
  itemTypeOptions = computed(() => {
    const field = AdaaHelper.getFieldLanguage("name");
    const result: ValueText[] = AdaaHelper.setDropdownArray(this.itemTypes(), "id", field);
    result.unshift({ value: "", text: this._translateService.instant("common.form.label.none") });
    return result;
  });

  AdaaHelper = AdaaHelper;
  PageMode = PageMode;
  Constants = Constants;
  untilDestroy = AdaaHelper.untilDestroyed();
  attributeId: string | null;

  public ngOnInit(): void {
    this.attributeId = this._activatedRoute.snapshot?.paramMap?.get("id");
    this.attributesEditorService.formValidator();
    this._prepareForm();
    forkJoin({
      attributes: this._propsService.getPropById(Constants.CONSTANT_MEASUREMENT_UNIT_ATTRIBUTES),
      itemTypes: this._propsService.getAllItemTypes(),
    }).subscribe({
      next: (results) => {
        this.dataTypes.set(results.attributes.responseData);
        this.filteredDataTypes.set(results.attributes.responseData);
        this.itemTypes.set(results.itemTypes.responseData);
      },
      complete: () => {
        this._getAttribute();
      },
    });
  }

  private _getAttribute() {
    if (this.attributeId) {
      this._attributesApiService.getAttribute(+this.attributeId).subscribe({
        next: (response) => {
          this.attribute.set(response.responseData);
          this.attributesForm.patchValue(response.responseData);
          this.attributesForm
            .get("attrItemTypes")
            ?.patchValue(response.responseData.attrItemTypes?.map((item) => item.itemType));
          if (response.responseData.attrItemTypes) this.selectedItemTypes.set(response.responseData.attrItemTypes);
        },
      });
    }
  }

  private _prepareForm() {
    const isViewMode = this.pageMode() === PageMode.view;

    this.attributesForm = this._formBuilder.group({
      nameAE: this._formBuilder.control<string | undefined>(
        { value: "", disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      nameEN: this._formBuilder.control<string | undefined>(
        { value: "", disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      dscAE: this._formBuilder.control<string | undefined>({ value: "", disabled: isViewMode }),
      dscEN: this._formBuilder.control<string | undefined>({ value: "", disabled: isViewMode }),
      dataType: this._formBuilder.control<string | undefined>(
        { value: "", disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      available: this._formBuilder.control<string | undefined>({ value: "", disabled: isViewMode }),
      mandatory: this._formBuilder.control<string | undefined>({ value: "", disabled: isViewMode }),
      attrItemTypes: this._formBuilder.control<number[] | undefined>(
        { value: undefined, disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
    });

    this.attributesForm.valueChanges.pipe(this.untilDestroy()).subscribe((value) => {
      const displayLanguage = AdaaHelper.getFieldLanguage("name");
      if (!value[displayLanguage].length) {
        this.displayLabel.set(value["nameAE"] || value["nameEN"]);
      } else {
        this.displayLabel.set(value[displayLanguage]);
      }
    });

    this.attributesForm.get("dataType")?.valueChanges.subscribe((value) => {
      switch (value) {
        case Constants.CONSTANT_ATTRIBUTES_DATATYPE_TEXT_MAPPING:
          this.showTable.set(true);
          this.fieldType.set("dropdown");
          break;

        case Constants.CONSTANT_ATTRIBUTES_DATATYPE_FREE_TEXT:
        case Constants.CONSTANT_MEASUREMENT_CURRENCY:
        case Constants.CONSTANT_MEASUREMENT_PERCENTAGE:
          this.showTable.set(false);
          this.fieldType.set("text");
          break;

        case Constants.CONSTANT_ATTRIBUTES_DATATYPE_DATE:
          this.showTable.set(false);
          this.fieldType.set("date");
          break;

        default:
          break;
      }
    });
  }

  public checkInvalid(control: string) {
    return (
      this.attributesForm.controls[control].errors?.["required"] &&
      (this.attributesForm.get(control)?.dirty || this.submitted())
    );
  }

  public setMandatory() {
    const value = this.attributesForm.get("mandatory")?.value;
    if (value === AdaaBoolean.Y) {
      this.attributesForm.get("available")?.patchValue(AdaaBoolean.Y);
      this.attributesForm.get("available")?.disable();
    }
    if (value === AdaaBoolean.N) {
      this.attributesForm.get("available")?.patchValue(AdaaBoolean.N);
      this.attributesForm.get("available")?.enable();
    }
  }

  public save() {
    this.submitted.set(true);
    if (this.attributesForm.invalid) {
      this._toastrService.error(this._translateService.instant("common.form.label.missing_information"));
      return;
    }
    if (this.attributesForm.get("mandatory")?.value === AdaaBoolean.Y) {
      const typeIds = this.attributesForm.get("attrItemTypes")?.value;
      this._attributesApiService.getCountByType(typeIds).subscribe({
        next: (response) => {
          this.records.set(response.responseData);
          const options = AdaaHelper.setDropdownArray(
            this.values(),
            "lovNumber",
            AdaaHelper.getFieldLanguage("lovText")
          );
          this.dropdownData.set(options);
          this.input().open();
        },
      });
    } else this.submit();
  }

  public submit() {
    const attrItemTypes = this.attributesForm.value.attrItemTypes.map((attrItem: number) => {
      const value = this.selectedItemTypes().find((selectedItem) => selectedItem.itemType === attrItem);
      const newItem = {
        itemType: attrItem,
        status: Constants.OBJECT_STATUS.DRAFT,
      };
      return value ?? newItem;
    });
    const result = {
      ...this.attribute(),
      ...this.attributesForm.value,
      attrItemTypes,
      available:
        this.attributesForm.value.mandatory === AdaaBoolean.Y ? AdaaBoolean.Y : this.attributesForm.value.available,
      libExtAttrLovs: this.values(),
      retroactive: AdaaBoolean.Y,
      status: Constants.OBJECT_STATUS.ACTIVE,
      defaultValue: this.defaultValue(),
    };

    const serviceURL =
      this.pageMode() === PageMode.create
        ? this._attributesApiService.createAttribute(result)
        : this._attributesApiService.updateAttribute(result);

    serviceURL.subscribe({
      next: (response) => {
        if (response.inError) return;
        this._toastrService.success(this._translateService.instant("notification.success.save"));
        this._router.navigateByUrl("/console/attributes");
      },
    });
  }

  public cancel() {
    this._router.navigateByUrl("/console/attributes");
  }

  public addValue(data: AttributeTextMapType) {
    const values: AttributeTextMapType[] = AdaaHelper.clone(this.values());
    if (this.attributeValues().pageMode === PageMode.edit) {
      const index = data.displayOrder - 1;
      values.splice(index, 1, data);
    } else {
      data.displayOrder = this.values().length + 1;
      data.status = Constants.OBJECT_STATUS.DRAFT;
      values.push(data);
    }
    this.values.set(values);
  }

  public getAction(value: TableButtonClicked) {
    switch (value.event) {
      case "edit":
        this.selectedValue.set(value.data);
        this.attributeValues().pageMode = PageMode.edit;
        this.attributeValues().open();
        break;
      case "delete": {
        const values = AdaaHelper.clone(this.values());
        values.splice(value.index, 1);
        this.values.set(values);
        break;
      }

      default:
        break;
    }
  }
}
