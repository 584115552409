<div class="d-flex gap-2">
    <p class="fw-bold fs-3">{{ "breadcrumbs.attributes" | translate }} -</p>
    <p class="fw-bold fs-3">{{ displayLabel() }}</p>
</div>

<div class="bg-white shadow-md rounded p-2 mb-3">
    <form class="col-12" [formGroup]="attributesForm">
        <div class="bg-white shadow-md rounded p-2 mb-3">
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.nameAE' | translate"
                        formControlName="nameAE"
                        controlName="nameAE"
                        [setValidator]="attributesEditorService.formValidation()!"
                        [invalid]="checkInvalid('nameAE')"
                    ></adaa-form-input>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.nameEN' | translate"
                        formControlName="nameEN"
                        controlName="nameEN"
                        [setValidator]="attributesEditorService.formValidation()!"
                        [invalid]="checkInvalid('nameEN')"
                    ></adaa-form-input>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.descriptionAE' | translate"
                        formControlName="dscAE"
                        controlName="dscAE"
                        [setValidator]="attributesEditorService.formValidation()!"
                        [invalid]="checkInvalid('dscAE')"
                    ></adaa-form-input>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.descriptionEN' | translate"
                        formControlName="dscEN"
                        controlName="dscEN"
                        [setValidator]="attributesEditorService.formValidation()!"
                        [invalid]="checkInvalid('dscEN')"
                    ></adaa-form-input>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-dropdown
                        formControlName="dataType"
                        controlName="dataType"
                        [options]="dataTypeOptions()"
                        [label]="'attributes.create.data_type' | translate"
                        [setValidator]="attributesEditorService.formValidation()!"
                        [invalid]="checkInvalid('dataType')"
                    ></adaa-form-dropdown>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-dropdown-multi
                        formControlName="attrItemTypes"
                        controlName="attrItemTypes"
                        [options]="itemTypeOptions()"
                        [label]="'attributes.create.item_type' | translate"
                        [setValidator]="attributesEditorService.formValidation()!"
                        [invalid]="checkInvalid('attrItemTypes')"
                        [searchable]="true"
                        [clearable]="true"
                    ></adaa-form-dropdown-multi>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12 d-flex justify-content-center">
                    <adaa-form-checkbox
                        [label]="'add' | translate"
                        [checkboxMode]="'yesNo'"
                        controlName="available"
                        formControlName="available"
                    ></adaa-form-checkbox>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12 d-flex justify-content-center">
                    <adaa-form-checkbox
                        [label]="'common.form.label.mandatory' | translate"
                        [checkboxMode]="'yesNo'"
                        controlName="mandatory"
                        formControlName="mandatory"
                        (inputChanges)="setMandatory()"
                    ></adaa-form-checkbox>
                </div>
            </div>
        </div>
    </form>
</div>

@if (showTable()) {
    <div class="p-2">
        <adaa-data-table
            #adaaDataTable
            [isLocalData]="true"
            [localData]="values()"
            [titleHeader]="'common.form.modals.title_list_of_values' | translate"
            [configKey]="Constants.MAIN_TABLE_LIST_CONF_KEY.ATTR_TEXT_MAP_LIST"
            [itemTypeId]="Constants.CONSTANT_PERMISSIONS.EXTENDEND_ATTRIBUTES"
            [enableViewAction]="false"
            (addNewClicked)="valuesInput.pageMode = PageMode.create; valuesInput.open()"
            (actionClicked)="getAction($event)"
        ></adaa-data-table>
    </div>
}

<div>
    <adaa-form-action-buttons
        [showSaveButton]="pageMode() !== PageMode.view"
        [cancelButtonTitle]="pageMode() === PageMode.view ? ('common.form.button.close' | translate) : ('common.form.button.cancel' | translate)"
        (saveClicked)="save()"
        (cancelClicked)="cancel()"
    ></adaa-form-action-buttons>
</div>

<adaa-attribute-values #valuesInput (submitValue)="addValue($event)" [selectedValue]="selectedValue()"></adaa-attribute-values>
<adaa-attributes-value-input
    #input
    [fieldType]="fieldType()"
    [dropdownData]="dropdownData()"
    [records]="records()"
    (defaultValue)="defaultValue.set($event); submit()"
></adaa-attributes-value-input>
