import { DatePipe, DecimalPipe, NgClass } from "@angular/common";
import {
  Component,
  computed,
  effect,
  ElementRef,
  inject,
  Injector,
  input,
  OnInit,
  signal,
  ViewChild,
} from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { filter, forkJoin, map } from "rxjs";
import { BreadcrumbService } from "xng-breadcrumb";

import { DubaiTimePipe, PercentageValuePipe, TranslateTokenPipe } from "../../../core/pipes";
import { AdaaHelper, useNewDS } from "../../../core/utils";
import {
  DisplayNotesComponent,
  EntityPlanChangeDetectionComponent,
  FavoriteButtonComponent,
  FloatActionComponent,
  FormDropdownComponent,
  NotificationAlertComponent,
  ObjectDataStatusComponent,
  ObjectTagsComponent,
  PeriodSliderVariantComponent,
  ReadMoreComponent,
  ViewCardPerformanceComponent,
} from "../../../shared/components";
import { genericFloatButtons } from "../../../shared/components/general/float-action";
import type { CommonFloatActionButtonType } from "../../../shared/components/general/float-action/types";
import { Constants } from "../../../shared/constants/constants";
import { AdaaBoolean, Language } from "../../../shared/constants/enums";
import {
  AffectedEntity,
  CardEntryInfoType,
  CommonKpiModelType,
  EkpiModelType,
  ItemModel,
  ItemTableValueModel,
  KpiAuditInfoType,
  MainResponseModel,
  MetricReadingModelType,
  NationalTargetImprovementPlanType,
  NtkpiModelType,
  ObjectStatus,
  type ObjectTypeAnnualPerformanceType,
  OpmModelType,
  PropTypeModelType,
  SelectedPeriodModelType,
  SkpiModelType,
  SrvkpiModelType,
  ValueText,
} from "../../../shared/models";
import {
  AppTitleService,
  CalculationEngineApiService,
  ContributingEntitiesApiService,
  DataentryApiService,
  ItemApiService,
  KpisApiService,
  KpisService,
  LanguageService,
  PropertiesService,
  SystemLayoutService,
  UserService,
} from "../../../shared/services";
import {
  AttachmentsComponent,
  ImprovementPlanComponent,
  KpiBenchmarkViewCardComponent,
  KpiPerformanceComponent,
  KpiProgressComponent,
  LeftSideListComponent,
  NormalTableValuesComponent,
} from "./utils";
import { ExecTeamComponent } from "./utils/exec-team/exec-team.component";

type KpiDataPerformanceType = {
  evaluationPerformance: number;
  evaluationYtp: number;
  performance: number;
  ytp: number;
};

@Component({
  selector: "adaa-kpi-view",
  standalone: true,
  imports: [
    TranslateModule,
    TranslateTokenPipe,
    PeriodSliderVariantComponent,
    DubaiTimePipe,
    DatePipe,
    DisplayNotesComponent,
    ObjectTagsComponent,
    EntityPlanChangeDetectionComponent,
    ReadMoreComponent,
    RouterLink,
    ViewCardPerformanceComponent,
    KpiPerformanceComponent,
    FavoriteButtonComponent,
    ObjectDataStatusComponent,
    PercentageValuePipe,
    NormalTableValuesComponent,
    FloatActionComponent,
    NgClass,
    DecimalPipe,
    LeftSideListComponent,
    AttachmentsComponent,
    ImprovementPlanComponent,
    KpiProgressComponent,
    FormDropdownComponent,
    NotificationAlertComponent,
    KpiBenchmarkViewCardComponent,
    ExecTeamComponent,
  ],
  templateUrl: "./kpi-view.component.html",
  styleUrl: "./kpi-view.component.scss",
})
export class KpiViewComponent implements OnInit {
  readonly router = inject(Router);
  readonly kpiService = inject(KpisService);
  readonly languageService = inject(LanguageService);
  readonly userService = inject(UserService);
  private readonly _injector = inject(Injector);
  private readonly _kpiApiService = inject(KpisApiService);
  private readonly _itemApiService = inject(ItemApiService);
  private readonly _appTitleService = inject(AppTitleService);
  private readonly _translateService = inject(TranslateService);
  private readonly _breadcrumbService = inject(BreadcrumbService);
  private readonly _propertiesService = inject(PropertiesService);
  private readonly _systemLayoutService = inject(SystemLayoutService);
  private readonly _dataentryApiService = inject(DataentryApiService);
  private readonly _calculationEngineApiService = inject(CalculationEngineApiService);
  private readonly _contributingEntitiesApiService = inject(ContributingEntitiesApiService);

  @ViewChild("normalTableValues") contentToPrint!: ElementRef;

  type = input<string>("", { alias: "kpiType" });
  kpiId = input.required<number, string>({
    transform: ($v: string) => Number($v),
  });
  periodId = input<string>();

  kpiType = signal<number | null>(null);
  includeLegacy = signal<boolean>(false);
  props = signal<PropTypeModelType[]>([]);
  periodCycle = signal<string | null>(null);
  tableValues = signal<ItemTableValueModel[]>([]);
  kpiData = signal<CommonKpiModelType | null>(null);
  subServicePerformance = signal<ItemModel | null>(null);
  mainServicePerformance = signal<ItemModel | null>(null);
  dataentryInfo = signal<CardEntryInfoType | null>(null);
  auditInfo = signal<KpiAuditInfoType[]>([]);
  dataStatus = signal<KpiDataPerformanceType | null>(null);
  kpiAnnualPerformance = signal<undefined | ObjectTypeAnnualPerformanceType>(undefined);
  selectedPeriod = signal<Pick<SelectedPeriodModelType, "period"> | undefined>(undefined);
  contributingEntities = signal<{ id: number; nameAE: string; nameEN: string; kpiId: number }[]>([]);
  affectedEntities = signal<ValueText[]>([]);
  affectedEntity = signal<number | null>(null);
  affectedEntitiesList = signal<AffectedEntity[]>([]);

  skpiData = computed(() => this.kpiData() as SkpiModelType);
  ekpiData = computed(() => this.kpiData() as EkpiModelType);
  opmData = computed(() => this.kpiData() as OpmModelType);
  srvkpiData = computed(() => this.kpiData() as SrvkpiModelType);
  ntkpiData = computed(() => this.kpiData() as NtkpiModelType);
  isOPM = computed(() => this.kpiData()?.kpiType === Constants.CONSTANT_OPM);
  isEKPI = computed(() => this.kpiData()?.kpiType === Constants.CONSTANT_EKPI);
  isUAE = computed(() => this.kpiData()?.kpiType === Constants.CONSTANT_UAEKPI);
  isSRVKPI = computed(() => this.kpiData()?.kpiType === Constants.CONSTANT_SRVKPI);
  isSKPI = computed(() => this.kpiData()?.kpiType === Constants.CONSTANT_SKPITYPE);
  isNKPI = computed(() => this.kpiData()?.kpiType === Constants.CONSTANT_NKPITYPE);
  isNTKPI = computed(() => this.kpiData()?.kpiType === Constants.CONSTANT_NTKPITYPE);
  isDTKPI = computed(() => this.kpiData()?.kpiType === Constants.CONSTANT_DTKPITYPE);
  isMOKPI = computed(() => this.kpiData()?.kpiType === Constants.CONSTANT_MOKPITYPE);
  isMTKPI = computed(() => this.kpiData()?.kpiType === Constants.CONSTANT_MTKPITYPE);
  kpiFrequency = computed(() => {
    const frequency = this.kpiData()?.frequency;
    if (!AdaaHelper.isDefined(frequency)) return Constants.FREQUENCY_MONTHLY;
    return <number>frequency;
  });
  isFrequencyAnnual = computed(() => this.kpiData()!.frequency! < Constants.FREQUENCY_ANNUAL);
  isBounded = computed(() => this.kpiData()?.trend === Constants.CONSTANT_TREND_BOUNDED);
  hasMaxRank = computed(() => this.kpiData()?.measurementUnit === Constants.CONSTANT_MEASUREMENT_RANK);
  isPmoUser = computed(() => this.userService.currentUser()?.entityId === Constants.CONSTANT_PMO_ID);
  kpiHasAuditIssues = computed(() => this.auditInfo().filter(({ hasAuditIssue }) => hasAuditIssue).length > 0);
  yearAuditInfo = computed(() => {
    const auditInfo = this.auditInfo() ?? [];
    const period = this.selectedPeriod();

    if (!period) return;

    const item = auditInfo.find(({ year }) => year === period.period.year);
    if (!item) return;
    return item;
  });
  hasMissingNonBaselineTargets = computed(() => {
    const data = this.kpiData();
    if (!data) return false;
    if (this.isEKPI() && data.hasMissingNonBaselineTargets) return false;
    return data.hasMissingNonBaselineTargets;
  });
  kpiPerfColor = computed(
    () => Constants.COLOR_CODES[this.dataStatus()?.evaluationPerformance ?? Constants.CONSTANT_COLORS.EVALUATION_GRAY]
  );
  kpiAnnualPerfColor = computed(
    () => Constants.COLOR_CODES[this.kpiAnnualPerformance()?.scoreColor ?? Constants.CONSTANT_COLORS.EVALUATION_GRAY]
  );
  isLinkedMOKPI = computed(() => this.isMOKPI() || this.isMTKPI());
  isLinkedSKPI = computed(() => {
    const isLinkedKPI = AdaaHelper.isDefined(this.skpiData()?.linkedKpiId);
    const isSKPI = this.kpiType() === Constants.CONSTANT_SKPITYPE;
    return isSKPI && isLinkedKPI;
  });
  isNationalOrUAEKPI = computed(() => this.isNKPI() || this.isUAE() || this.isNTKPI() || this.isDTKPI());
  govDirTranslation = computed(() => {
    if (this.isNTKPI()) {
      return "directions.national_vision";
    } else if (this.isDTKPI()) {
      return "dtkpi.nationalStrategy";
    } else return "directions.government_direction";
  });
  subGovDirTranslation = computed(() => {
    if (this.isNTKPI()) {
      return "directions.main_pillar";
    } else if (this.isDTKPI()) {
      return "dtkpi.enablerObjective";
    } else return "directions.sub_government";
  });
  metricList = computed<MetricReadingModelType[]>(() => {
    if (this.tableValues().length > 0) {
      return this.tableValues()[0].metricReadingsItemData;
    }
    return [];
  });
  getMainServicePerformanceColor = computed(() => {
    switch (this.mainServicePerformance()?.evaluationPerformance) {
      case Constants.CONSTANT_COLORS.EVALUATION_RED: {
        return "bg-danger";
      }
      case Constants.CONSTANT_COLORS.EVALUATION_YELLOW: {
        return "bg-warning";
      }
      case Constants.CONSTANT_COLORS.EVALUATION_GREEN: {
        return "bg-success";
      }

      default: {
        return "bg-secondary";
      }
    }
  });
  getSubServicePerformanceColor = computed(() => {
    switch (this.subServicePerformance()?.evaluationPerformance) {
      case Constants.CONSTANT_COLORS.EVALUATION_RED: {
        return "bg-danger";
      }
      case Constants.CONSTANT_COLORS.EVALUATION_YELLOW: {
        return "bg-warning";
      }
      case Constants.CONSTANT_COLORS.EVALUATION_GREEN: {
        return "bg-success";
      }

      default: {
        return "bg-secondary";
      }
    }
  });
  improvementPlan = computed(() => {
    const data = this.ntkpiData();
    const list: NationalTargetImprovementPlanType[] = [];

    if (!AdaaHelper.isDefined(data.nationalTargetImprovementPlans)) return list;
    else list.push(...data.nationalTargetImprovementPlans);

    list.sort((a, b) => {
      if (a.improvementPlanOrder < b.improvementPlanOrder) return -1;
      if (a.improvementPlanOrder > b.improvementPlanOrder) return 1;
      return 0;
    });

    return list;
  });
  showEntitySelection = computed(
    () =>
      this.isEKPI() &&
      !AdaaHelper.isDefined(this.kpiData()?.targetsInputEntityId) &&
      this.affectedEntities().length > 0 &&
      (AdaaHelper.isPMOEntity() || this.kpiData()?.enablerEntityId === AdaaHelper.entity.id)
  );
  currentKpiId = computed(() => {
    const entityList = this.affectedEntitiesList();
    const entityId = this.affectedEntity();
    if (this.showEntitySelection() && AdaaHelper.isDefined(entityId)) {
      const found = entityList.find((a) => a.entityId === entityId);
      if (found) return found.entityEkpiId;
    }
    return this.kpiId();
  });
  auxKpiId = computed(() => {
    const entityList = this.affectedEntitiesList();
    const entityId = this.affectedEntity();
    if (this.showEntitySelection() && AdaaHelper.isDefined(entityId)) {
      const found = entityList.find((a) => a.entityId === entityId);
      if (found) return found.entityEkpiId;
    }
    return this.kpiId();
  });
  currentEntityId = computed(() => {
    if (this.affectedEntity()) return this.affectedEntity();
    if (this.showEntitySelection() && !this.affectedEntity()) return this.ekpiData()?.enablerEntityId;
    return this.kpiData()?.entityId;
  });

  readonly mapId = () => {
    const entityId = this.affectedEntity() ?? this.kpiData()?.entityId;
    const map = AdaaHelper.getMap(entityId);
    return map?.id as number | undefined;
  };
  readonly lang = Language;
  readonly normalDateFormat = Constants.normalDate;
  readonly datetimeFormat = Constants.dateTime;
  readonly useNewDS = useNewDS;
  readonly isDefined = AdaaHelper.isDefined.bind(AdaaHelper);
  readonly objectiveTypes = {
    main: Constants.CONSTANT_MAIN_OBJECTIVE,
    strategic: Constants.CONSTANT_STRATEGIC_OBJECTIVE,
    enabler: Constants.CONSTANT_ENABLER_OBJECTIVE,
  };
  readonly initiativeTypeText = (type: number) => {
    if (this.useNewDS()) {
      return type === Constants.CONSTANT_REGULAR_TASK_INITIATIVETYPE
        ? "initiatives.regular_task"
        : "initiatives.strategic_project";
    }

    return "initiatives.title";
  };
  readonly initiativeTypeLink = (type: number, id: number) => {
    if (type === Constants.CONSTANT_REGULAR_TASK_INITIATIVETYPE) return `/console/initiatives/regular-tasks/${id}`;
    return `/console/initiatives/strategic-projects/${id}`;
  };
  readonly fetchKpiCard = () => {
    const kpiId = this.kpiId();
    const periodId = this.periodId();

    const data = {
      id: Number(kpiId),
      periodId,
    };

    return forkJoin([this._kpiApiService.getCardById(data), this._kpiApiService.getById(data)]);
  };
  readonly getObjectiveUrl = () => {
    const objectiveId = this.skpiData()?.objectiveId;
    const objectiveType = this.skpiData()?.objectiveType;

    if (AdaaHelper.isPMOEntity()) {
      return `/console/pmo-objectives/enabler-objective/${objectiveId}`;
    }

    if (this.useNewDS()) {
      switch (objectiveType) {
        case this.objectiveTypes.main: {
          return `/console/objectives/main-objective/${objectiveId}`;
        }
        case this.objectiveTypes.strategic: {
          return `/console/objectives/strategic-objective/${objectiveId}`;
        }
        default:
          break;
      }
    }

    return `/console/objectives/${objectiveId}`;
  };
  readonly getConstants = {
    general: (value: number | undefined) => {
      const item = this.props().find(({ id }) => id === value);
      return item ? item : "";
    },
    trend: (value: number | undefined) => {
      const item = this.props().find(({ id }) => id === value);
      return item ? item : "";
    },
    ytp: (value: number | undefined) => {
      const item = this.props().find(({ id }) => id === value);
      return item ? item : "";
    },
    formula: (f: string) => {
      let value: number;
      if (this.kpiData()?.hasTarget === AdaaBoolean.Y) {
        value = 73;
      } else if (f === "NOFORMULAV") {
        value = 72;
      } else {
        value = 71;
      }
      const item = this.props().find(({ id }) => id === value);
      return item ? item : "";
    },
    metricAgg: (value: number | undefined) => {
      const item = this.props().find(({ id }) => id === value);
      return item ? item : "";
    },
  };
  readonly gotoSubService = () => {
    if (this.srvkpiData().subServiceTypeId === Constants.CONSTANT_AUXSERVICE) {
      return ["/console/services/aux-service", this.srvkpiData().subServiceId];
    }
    if (this.srvkpiData().subServiceTypeId === Constants.CONSTANT_VARSERVICE) {
      return ["/console/services/var-service", this.srvkpiData().subServiceId];
    }
    return ["/console/services"];
  };

  readonly #floatButtons = genericFloatButtons();
  readonly #untilDestroy = AdaaHelper.untilDestroyed();

  public get isPmoAndE_NT_DTKPI() {
    const isNTKPI = this.type() === "ntkpi";
    const isDTKPI = this.type() === "dtkpi";
    const isEKPI = this.type() === "ekpi";
    const isMOKPI = this.type() === "mokpi";

    return AdaaHelper.isPMOEntity() && (isNTKPI || isDTKPI || isEKPI || isMOKPI);
  }
  public get auditIssuesTooltip() {
    return (
      this.languageService.translate("audit_data.kpi_audit_issues_statement") +
      " " +
      this.periodsWithKPIAuditIssues.join(",")
    );
  }

  public get defineTargetTooltip() {
    return this.languageService.translate("audit_data.define_target_issues_statement");
  }

  public get periodsWithKPIAuditIssues() {
    const periodsInYears = this.auditInfo()?.filter((val) => val.hasAuditIssue);

    return periodsInYears.map((val) => val.year);
  }

  public ngOnInit() {
    this._getProps();
    this._getAuditInfo();
    this._refreshPage();
  }

  public initPage([res, data]: [MainResponseModel<CommonKpiModelType>, MainResponseModel<CommonKpiModelType>]) {
    this.kpiData.set({
      ...data.responseData,
      ...res.responseData,
    });
    this.kpiType.set(res.responseData.kpiType);
    this._setKpiTitleInfo(AdaaHelper.getItemValueByToken(res.responseData, "name"));
    this._floatActions(this.kpiData()!);

    if (this.isNationalOrUAEKPI()) {
      this._getContributingEntities();
    } else if (this.isEKPI()) {
      if (AdaaHelper.isPMOEntity() || this.kpiData()?.enablerEntityId === AdaaHelper.entity.id)
        this._getAffectedEntities();
    }
  }

  public getDataStatus(data: KpiDataPerformanceType) {
    this.dataStatus.set(data);
  }

  public refreshKpiTableValues() {
    this._getKpiTableValues();
  }

  public selectPeriod(period: Pick<SelectedPeriodModelType, "period">) {
    if (!period) return;
    this.selectedPeriod.update(() => period);
    this._getAnnualPerformance(period.period.year);
    this._getKpiTableValues();
    this._getMainService(period.period.id);
    this._getDataentryInfo();

    const urlTree = this.router.parseUrl(this.router.url);
    if (!urlTree.queryParams?.periodId || Number(urlTree.queryParams?.periodId) !== period.period.id) {
      urlTree.queryParams.periodId = period.period.id;

      const url = this.router.serializeUrl(urlTree);
      this.router.navigateByUrl(url, { onSameUrlNavigation: "ignore", replaceUrl: true });
    }
  }

  public selectPeriodCycle($e: string) {
    this.periodCycle.set($e);
    const period = this.selectedPeriod();
    if (period) {
      this._getKpiTableValues();
      this._getMainService(period.period.id);
    }
  }

  public affectedEntityChanged(entityId: number | null) {
    this.affectedEntity.set(entityId);
    const periodId = this.selectedPeriod()?.period.id;
    const year = this.selectedPeriod()?.period.year;
    if (periodId) {
      this._getKpiTableValues();
    }
    if (year) {
      this._getAnnualPerformance(year);
    }
  }

  private _getContributingEntities() {
    this._contributingEntitiesApiService
      .getCardByKpiId(Number(this.kpiId()))
      .pipe(
        filter((res) => !res.inError),
        map((res) => res.responseData)
      )
      .subscribe({
        next: (data) => this.contributingEntities.set(data ?? []),
      });
  }

  private _getKpiTableValues() {
    const data = this.skpiData();
    if (!data) return;

    let obs$ = this._itemApiService.getItemDataByTypeAndId({
      id: data.linkedKpiId || data.id,
      itemType: data.linkedKpiType || data.kpiType,
      frequency: `${this.periodCycle()}`,
      includeLegacyKpi: this.includeLegacy(),
      kpiType: data.kpiType,
      periodId: this.selectedPeriod()?.period.id,
    });

    if (this.showEntitySelection() && AdaaHelper.isDefined(this.affectedEntity())) {
      const entityId = this.affectedEntity();
      obs$ = this._kpiApiService.getItemDataByTypeAndIdByEntity({
        id: data.id,
        kpiType: data.kpiType,
        entityId: entityId ?? this.ekpiData()?.enablerEntityId,
        frequency: `${this.periodCycle()}`,
        includeLegacyKpi: this.includeLegacy(),
        periodId: this.selectedPeriod()?.period.id,
      });
    }

    obs$
      .pipe(
        filter((res) => !res.inError),
        map((res) => res.responseData)
      )
      .subscribe({
        next: (data) => {
          const list = <ItemTableValueModel[]>data;
          this.tableValues.set(list ?? []);
        },
      });
  }

  private _getMainService(periodId: number) {
    if (!this.isSRVKPI()) return;

    const data = this.srvkpiData();
    if (!data) return;

    this._itemApiService
      .getByIdAndTypeAndPeriod({
        periodId,
        kpiType: this.kpiType()!,
        id: data.mainServiceId,
        itemType: Constants.CONSTANT_MAINSERVICE,
        frequency: `${this.periodCycle()}`,
        includeLegacyKpi: this.includeLegacy(),
      })
      .pipe(
        filter((res) => !res.inError),
        map((res) => res.responseData)
      )
      .subscribe({
        next: (data) => this.mainServicePerformance.set(data as ItemModel),
        complete: () => this._getSubService(periodId),
      });
  }

  private _getSubService(periodId: number) {
    if (!this.isSRVKPI()) return;

    const data = this.srvkpiData();
    if (!data) return;

    let itemType: number | null = null;
    if (data.subServiceTypeId === Constants.CONSTANT_AUXSERVICE) itemType = Constants.CONSTANT_AUXSERVICE;
    if (data.subServiceTypeId === Constants.CONSTANT_VARSERVICE) itemType = Constants.CONSTANT_VARSERVICE;

    if (!itemType) return;

    this._itemApiService
      .getByIdAndTypeAndPeriod({
        itemType,
        periodId,
        kpiType: this.kpiType()!,
        id: data.subServiceId,
        frequency: `${this.periodCycle()}`,
        includeLegacyKpi: this.includeLegacy(),
      })
      .pipe(
        filter((res) => !res.inError),
        map((res) => res.responseData)
      )
      .subscribe({
        next: (data) => this.subServicePerformance.set(data as ItemModel),
      });
  }

  private _getAnnualPerformance(year: number) {
    this._calculationEngineApiService
      .getCalculationReadingAnnual({
        itemId: this.currentKpiId(),
        objectType: +this.kpiType()!,
        year,
      })
      .pipe(map((res) => res.responseData))
      .subscribe({
        next: (data) => this.kpiAnnualPerformance.set(data),
      });
  }

  private _setKpiTitleInfo(name: string) {
    let title = this.kpiService.getTableTitle(this.kpiType()!, "view");
    title = this._translateService.instant(title);

    this._appTitleService.setTitle(`${title} - ${name}`, true);
    this._breadcrumbService.set("@kpisView", title);
    this._breadcrumbService.set("@kpisList", this.kpiService.getTableTitle(this.kpiType()!, "list"));
  }

  private _refreshPage() {
    this._systemLayoutService.hasCycleChanged$.pipe(this.#untilDestroy()).subscribe({
      next: () => {
        this.router.navigate(this.kpiService.getListPageRoute(this.kpiData()?.kpiType as number));
      },
    });

    this._systemLayoutService.hasActiveEntityChanged$.pipe(this.#untilDestroy()).subscribe({
      next: () => {
        this.router.navigate(this.kpiService.getListPageRoute(this.kpiData()?.kpiType as number));
      },
    });
  }

  private _getDataentryInfo() {
    this._dataentryApiService
      .getInfov2({
        itemId: this.kpiData()!.id!,
        itemType: this.kpiType()!,
        periodId: this.selectedPeriod()!.period.id!,
      })
      .pipe(map((res) => res.responseData))
      .subscribe({
        next: (data) => this.dataentryInfo.set({ ...data }),
      });
  }

  private _getProps() {
    this._propertiesService
      .getPropByIdList([
        Constants.CONSTANT_TREND,
        Constants.CONSTANT_FORMULA_LLK,
        Constants.CONSTANT_YTP,
        Constants.CONSTANT_KPI_TARGET_TYPE,
        Constants.CONSTANT_TARGET_CATEGORY,
        Constants.CONSTANT_MONTHS,
        Constants.CONSTANT_GOV_METRIC_AGG_METHOD,
        Constants.CONSTANT_PUBLISHED_STATE,
      ])
      .pipe(map((res) => res.responseData))
      .subscribe({
        next: (data) => this.props.set(data || []),
      });
  }

  private _getAuditInfo() {
    effect(
      () => {
        const includeLegacy = this.includeLegacy();
        const kpiId =
          this.isLinkedSKPI() && this.skpiData().linkedKpiId ? this.skpiData().linkedKpiId : this.currentKpiId();

        this._kpiApiService
          .getAuditInfo({
            kpiId: Number(kpiId),
            includeLegacy,
          })
          .pipe(
            filter((res) => !res.inError),
            map((res) => res.responseData)
          )
          .subscribe({
            next: (data) => this.auditInfo.set(data ?? []),
          });
      },
      {
        injector: this._injector,
      }
    );
  }

  private _floatActions(data: CommonKpiModelType) {
    const isArchived = AdaaHelper.isPlanArchived();

    let list: CommonFloatActionButtonType[] = [
      {
        key: "print",
        data: undefined,
      },
      {
        key: "helpdesk",
        data: {
          url: "/helpdesk/create",
        },
      },
      {
        key: "addNote",
        data: {
          itemId: data.id,
          itemTypeId: this.kpiType()!,
        },
      },
      {
        key: "workflowHistory",
        data: {
          itemId: data.id,
          itemTypeId: this.kpiService.getWorkflowItemId(this.kpiType()!),
        },
      },
      {
        key: "systemLog",
        data: {
          itemId: data.id,
          itemTypeId: [this.kpiType()!, this.kpiService.getWorkflowItemId(this.kpiType()!)],
        },
      },
      {
        key: "email",
        data: {
          objectName: AdaaHelper.getItemValueByToken(data, "name"),
        },
      },
      {
        key: "connection",
        data: undefined,
      },
    ];

    if (!AdaaHelper.isDefined(data.wfProcessCtlId) && !isArchived) {
      list = [
        {
          key: "edit",
          data: {
            url: this.kpiService.getEditPageRoute(this.kpiType()!, data.id),
          },
        },
        ...list,
      ];
    }

    if (data.status !== ObjectStatus.DRAFT && !AdaaHelper.isDefined(data.wfProcessCtlId) && !isArchived) {
      list = [
        {
          key: "data-entry",
          data: {
            url: `/console/data-entry/kpi/edit/${data.id}`,
          },
        },
        ...list,
      ];
    }

    this.#floatButtons([...list]);
  }

  private _getAffectedEntities() {
    if (!this.kpiData()?.id) return;
    this._kpiApiService.getEkpiAffectedEntities(this.kpiData()!.id).subscribe({
      next: (response) => {
        if (response.inError) return;

        this.affectedEntitiesList.set(response.responseData ?? []);

        const options: ValueText[] = [];

        response.responseData.forEach((e) =>
          options.push({
            value: e.entityId,
            text: ` (${AdaaHelper.getItemValueByToken(e, "affectedEntityShortName")}) ${AdaaHelper.getItemValueByToken(e, "affectedEntityName")}`,
          })
        );
        this.affectedEntities.set(options);
      },
    });
  }

  public printContent() {
    const parentDiv = document.querySelector(".sys-layout--topbar");
    const printContents = this.contentToPrint.nativeElement.innerHTML;
    const pageTitle = document.title;

    const popupWin = window.open("", "_blank", "top=0,left=0,height=100%,width=auto");
    if (!popupWin) return;

    popupWin.document.write(`
      <html lang="${this.languageService.current()}">
        <head>
          <title>${pageTitle}</title>
          <div class="govbar" style="margin: 30px 0;">${parentDiv?.outerHTML}</div>
          <base href="${document.baseURI}"> <!-- Ensure relative paths work -->
          ${this._getOriginalStyles()}
        </head>
        <body onload="setTimeout(() => { window.print(); window.close(); }, 100);">
          <div>${printContents}</div> <!-- Wrap content in a container -->
        </body>
      </html>
    `);
    popupWin.document.close();
  }

  private _getOriginalStyles(): string {
    const styles = Array.from(document.querySelectorAll('style, link[rel="stylesheet"]'));
    return styles
      .map((style) => {
        if (style.tagName === "STYLE") {
          return `<style>${style.innerHTML}</style>`;
        } else if (style.tagName === "LINK") {
          const href = (style as HTMLLinkElement).href;
          return `<link rel="stylesheet" href="${href}" />`;
        }
        return "";
      })
      .join("");
  }
}
