<adaa-data-table
    #milestonesTable
    titleHeader="national_projects.national_projects_milestone.title"
    [configKey]="constants.MAIN_TABLE_LIST_CONF_KEY.PROJECT_MILESTONES_CONF_LIST"
    [paramsString]="paramsString()"
    [itemTypeId]="constants.CONSTANT_PERMISSIONS.NATIONAL_PROJECT_MILESTONES"
    [enableConnectionsAction]="true"
    [enableDeleteAction]="false"
    [enableAdd]="false"
    [disableEditRules]="disableEditRules"
    (actionClicked)="actionClicked($event)"
></adaa-data-table>

<adaa-float-action />
