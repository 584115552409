import { Component } from "@angular/core";
import { RouterOutlet } from "@angular/router";

import { SystemLayoutComponent } from "../../shared/components";

/**
 * @docs - Authenticated Page
 *
 * This is a utility component class whose purpose is to
 * represent a page that requires the user to be authenticated.
 * It also able to redirect users to either the "/console" or "/dashboard"
 *
 * !note No extraneous logic is required here nor expected hence the lack of a unit test
 */
@Component({
  standalone: true,
  template: `<adaa-system-layout> <router-outlet></router-outlet> </adaa-system-layout>`,
  imports: [RouterOutlet, SystemLayoutComponent],
})
export class AuthenticatedPage {}
