import { Component, computed, inject, input, OnInit, output, signal, TemplateRef, viewChild } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { FormActionButtonsComponent, FormDropdownTreeComponent, FormInputComponent } from "../../../shared/components";
import { Language, PageMode } from "../../../shared/constants/enums";
import { EntityModelType, StructuredOrgUnit, ValueText } from "../../../shared/models";
import { AppService, OrgUnitApiService } from "../../../shared/services";
import { OrgUnitEditorService } from "../../../shared/services/org-unit-editor.service";

@Component({
  selector: "adaa-organization-unit-editor-modal",
  standalone: true,
  imports: [
    TranslateModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    FormInputComponent,
    FormDropdownTreeComponent,
    FormActionButtonsComponent,
  ],
  templateUrl: "./organization-unit-editor-modal.html",
  styleUrl: "./organization-unit.component.scss",
})
export class OrganizationUnitEditorModalComponent implements OnInit {
  private _modalService = inject(NgbModal);
  private _appService = inject(AppService);
  private _formBuilder = inject(FormBuilder);
  private _orgUnitApiService = inject(OrgUnitApiService);
  private _toastrService = inject(ToastrService);
  private _translateService = inject(TranslateService);
  orgUnitEditorService = inject(OrgUnitEditorService);

  selectedUnit = input.required<StructuredOrgUnit | undefined>();
  pageMode = input.required<string>();
  parentOrgUnit = input.required<StructuredOrgUnit | undefined>();
  entity = input.required<EntityModelType>();
  orgUnitOptions = input.required<ValueText[]>();
  submitted = signal<boolean>(false);
  submitForm = output<{ nameAE: string; nameEN: string; parentOrgUnit: number }>();
  PageMode = PageMode;
  orgUnitForm: FormGroup = new FormGroup({});

  modal = viewChild.required<TemplateRef<unknown>>("content");
  selectedUnitOption = computed(() => {
    return [this.selectedUnit()?.id];
  });

  public ngOnInit(): void {
    this._prepareForm();
  }

  private _prepareForm() {
    this.orgUnitForm = this._formBuilder.group({
      nameAE: this._formBuilder.control<string | undefined>("", {
        validators: [Validators.required],
      }),
      nameEN: this._formBuilder.control<string | undefined>("", {
        validators: [Validators.required],
      }),
      parentOrgUnit: this._formBuilder.control<string | number | undefined>(undefined, {
        validators: [Validators.required],
      }),
    });
  }

  public open() {
    const modalRef: NgbModalRef = this._modalService.open(this.modal(), {
      animation: true,
      scrollable: false,
      keyboard: false,
      size: "lg",
      centered: true,
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });

    modalRef.dismissed.subscribe(() => this._resetForm());
    modalRef.closed.subscribe(() => this._resetForm());
  }

  public checkInvalid(control: string) {
    return (
      this.orgUnitForm.controls[control].errors?.["required"] &&
      (this.orgUnitForm.get(control)?.dirty || this.submitted())
    );
  }

  public submit() {
    this.submitted.set(true);
    if (this.orgUnitForm.invalid) {
      this._toastrService.error(this._translateService.instant("common.form.label.missing_information"));
      return;
    }
    this.submitForm.emit(this.orgUnitForm.value);
  }

  private _getOrgUnitsForProfile(entityId: number) {
    return this._orgUnitApiService.getByEntityIdForProfile(+entityId);
  }

  private _resetForm() {
    this.orgUnitForm.reset();
    this.orgUnitForm.markAsPristine();
    this.orgUnitForm.markAsUntouched();
    this.submitted.set(false);
  }
}
