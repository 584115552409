import { Component, inject, input, OnInit, viewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import * as fileSaver from "file-saver";
import { ToastrService } from "ngx-toastr";

import { AdaaHelper } from "../../../core/utils";
import { ConfirmationModalComponent, DataTableComponent, FloatActionComponent } from "../../../shared/components";
import { genericFloatButtons } from "../../../shared/components/general/float-action";
import { GenerateAgreementComponent } from "../../../shared/components/modals/generate-agreement/generate-agreement.component";
import { Constants } from "../../../shared/constants/constants";
import { Language } from "../../../shared/constants/enums";
import { CustomButton, TableButtonClicked } from "../../../shared/models";
import { AgreementDetails } from "../../../shared/models/tp-agreements.model";
import { AppService, FilesApiService, SystemLayoutService, TpApiService } from "../../../shared/services";

@Component({
  selector: "adaa-agreements",
  standalone: true,
  imports: [DataTableComponent, TranslateModule, GenerateAgreementComponent, FloatActionComponent],
  templateUrl: "./agreements.component.html",
  styleUrl: "./agreements.component.scss",
})
export class AgreementsComponent implements OnInit {
  private _filesApiService = inject(FilesApiService);
  private _tpApiService = inject(TpApiService);
  private _translateService = inject(TranslateService);
  private _toastrService = inject(ToastrService);
  private _modalService = inject(NgbModal);
  private _appService = inject(AppService);
  private _systemLayoutService = inject(SystemLayoutService);

  breadcrumb = input.required<string>();

  private readonly _untilDestroyed = AdaaHelper.untilDestroyed();
  private readonly _floatActions = genericFloatButtons();
  Constants = Constants;
  AdaaHelper = AdaaHelper;
  adaaDataTable = viewChild.required<DataTableComponent>("adaaDataTable");

  customButtons: CustomButton[] = [
    {
      iconName: "adaa-icon-send_to_entities",
      eventName: "publish",
      iconTitle: "help.faqs.published",
      onlyIf: (data: { published: boolean }) => !data.published && AdaaHelper.isPMOEntity(),
    },
  ];

  public ngOnInit(): void {
    this._systemLayoutService.hasActiveEntityChanged$.pipe(this._untilDestroyed()).subscribe({
      next: () => this.adaaDataTable().loadTableData(),
    });

    this._floatActions([
      {
        key: "helpdesk",
        data: {
          url: "/helpdesk/create",
        },
      },
      {
        key: "print",
        data: undefined,
      },
    ]);
  }

  public getAction(value: TableButtonClicked) {
    switch (value.event) {
      case "view":
        this._downloadFile(value.data);
        break;
      case "publish":
        this._publishAgreement(value.data.id);
        break;
      case "delete":
        this._deleteAgreement(value.data.id);
        break;

      default:
        break;
    }
  }

  private _publishAgreement(id: number) {
    const modal = this._modalService.open(ConfirmationModalComponent, {
      centered: true,
      size: "md",
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });

    modal.componentInstance.header = "national_projects.agreements.publish_project_agreement";
    modal.componentInstance.title = "national_projects.agreements.publish_project_agreement_msg";

    modal.result.then((e) => {
      if (e) {
        this._tpApiService.publishAgreement(id).subscribe({
          next: () => {
            this._toastrService.success(
              this._translateService.instant("national_projects.agreements.agreement_published_successfully")
            );
            this.adaaDataTable().loadTableData();
          },
        });
      }
    });
  }

  private _deleteAgreement(id: number) {
    const modal = this._modalService.open(ConfirmationModalComponent, {
      centered: true,
      size: "md",
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });

    modal.componentInstance.header = "national_projects.agreements.delete_yes_no_title";
    modal.componentInstance.title = "national_projects.agreements.delete_yes_no_information";

    modal.result.then((e) => {
      if (e) {
        this._tpApiService.deleteAgreement(id).subscribe({
          next: () => {
            this._toastrService.success(this._translateService.instant("notification.success.remove"));
            this.adaaDataTable().loadTableData();
          },
        });
      }
    });
  }

  private _downloadFile(data: AgreementDetails) {
    this._filesApiService.download(data.itemAttachmentId).subscribe({
      next: (blob) => {
        fileSaver.saveAs(blob, data.fileName as string);
      },
    });
  }

  public loadData(data: AgreementDetails) {
    this._downloadFile(data);
    this.adaaDataTable().loadTableData();
  }
}
