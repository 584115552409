import { Route } from "@angular/router";

import { checkIfLoggedInFn } from "../../core/guards";
import { Constants } from "../../shared/constants/constants";
import { PageMode } from "../../shared/constants/enums";
import { createTextMappingPageGuards, manageTextMappingPageGuards, viewTextMappingPageGuards } from "./_guard";
import { TextMappingEditorComponent } from "./text-mapping-editor/text-mapping-editor.component";
import { TextMappingsListComponent } from "./text-mappings-list/text-mappings-list.component";

export const TEXT_MAPPING_ROUTES: Route[] = [
  {
    path: "text-mapping",
    canActivate: [checkIfLoggedInFn, ...viewTextMappingPageGuards],
    children: [
      {
        path: "",
        component: TextMappingsListComponent,
      },
      {
        path: "create",
        component: TextMappingEditorComponent,
        canActivate: [checkIfLoggedInFn, ...createTextMappingPageGuards],
        data: {
          breadcrumb: "breadcrumbs.text_mappings_create",
          title: "breadcrumbs.text_mappings_create",
          pageMode: PageMode.create,
        },
      },
      {
        path: "edit/:id",
        component: TextMappingEditorComponent,
        canActivate: [checkIfLoggedInFn, ...manageTextMappingPageGuards],
        data: {
          breadcrumb: "breadcrumbs.text_mappings_edit",
          title: "breadcrumbs.text_mappings_edit",
          pageMode: PageMode.edit,
        },
      },
      {
        path: ":id",
        component: TextMappingEditorComponent,
        canActivate: [checkIfLoggedInFn, ...viewTextMappingPageGuards],
        data: {
          breadcrumb: "breadcrumbs.text_mappings_view",
          title: "breadcrumbs.text_mappings_view",
          pageMode: PageMode.view,
        },
      },
    ],
    data: {
      title: "breadcrumbs.text-mapping",
      breadcrumb: "breadcrumbs.text-mapping",
      tableConfList: Constants.MAIN_TABLE_LIST_CONF_KEY.TEXTMAPPING,
    },
  },
];
