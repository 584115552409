import { inject, Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";

import { AdaaHelper } from "../../core/utils";
import { MainResponseModel, PerformanceConfigType, PerformanceWeightType } from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class CalculationManagementService {
  private _mainService = inject(MainService);

  validTotal = 100;

  public validateTable(formGroup: FormGroup, listOfInvalidRows: number[]) {
    listOfInvalidRows = [];
    formGroup.setErrors(null);
    formGroup.value.data.forEach((item: PerformanceConfigType) => {
      let total = 0;
      item.weights.forEach((weight: PerformanceWeightType) => {
        if (AdaaHelper.isDefined(weight.value) && weight.value !== null) {
          total += weight.value;
        }
      });
      if (total !== this.validTotal) {
        listOfInvalidRows = this.invalidRows(item, listOfInvalidRows);
        formGroup.setErrors({ invalidTarget: true });
      }
    });
    return { formGroup: formGroup, listOfInvalidRows: listOfInvalidRows };
  }

  public invalidRows(item: PerformanceConfigType | null = null, listOfInvalidRows: number[]) {
    if (item) {
      if (!listOfInvalidRows.includes(item.id)) {
        listOfInvalidRows.push(item.id);
      }
    }
    return listOfInvalidRows;
  }

  public getColouredInvalidRows(element: number, listOfInvalidRows: number[]) {
    if (element) {
      return listOfInvalidRows.includes(element);
    }
    return false;
  }

  public preparingRequest(formGroup: FormGroup, original: PerformanceConfigType[]) {
    const originalWeights: PerformanceWeightType[] = [];
    const filteredWeights: PerformanceWeightType[] = [];
    const finalRequest = [];

    // Preparing original data
    original.forEach((item) => {
      item.weights.forEach((w) => {
        if (!AdaaHelper.isDefined(w.value)) {
          w.value = null;
        }
        originalWeights.push(w);
      });
    });

    // Preparing the edited form
    formGroup.value.data.forEach((item: PerformanceConfigType) => {
      item.weights.forEach((changedW) => {
        changedW["aggregationType"] = item.id;
        filteredWeights.push(changedW);
      });
    });

    // Preparing the final request
    for (let i = 0; i < originalWeights.length; i++) {
      if (originalWeights[i].value !== filteredWeights[i].value) {
        finalRequest.push(filteredWeights[i]);
      }
    }

    return finalRequest;
  }
  public updateGovernmentCalculation(data: { weights: PerformanceWeightType[] }) {
    const url = `performanceconfigdetail/government`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "PUT",
      url,
      data,
    });
  }

  public updateEntityCalculation(data: { weights: PerformanceWeightType[]; id: number }) {
    const url = `performanceconfigdetail/entity/${data.id}`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "PUT",
      url,
      data,
    });
  }
}
