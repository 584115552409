import { CommonModule } from "@angular/common";
import { Component, computed, inject, OnDestroy, OnInit, signal } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";

import { PermissionActionDescriptorType } from "../../../../adaa-types";
import { AdaaHelper } from "../../../core/utils";
import { FormActionButtonsComponent } from "../../../shared/components";
import { FormDropdownComponent } from "../../../shared/components";
import { Constants } from "../../../shared/constants/constants";
import { AdaaBoolean, Language, PermissionAction } from "../../../shared/constants/enums";
import { HasPermissionDirective } from "../../../shared/directives";
import { EntityModelType, PerformanceConfigType, ValueText } from "../../../shared/models";
import {
  CalculationEngineApiService,
  EntitiesApiService,
  LanguageService,
  SystemLayoutService,
} from "../../../shared/services";
import { CalculationManagementService } from "../../../shared/services/calculation-management-api.service";

@Component({
  selector: "adaa-calculation-entity",
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    HasPermissionDirective,
    FormActionButtonsComponent,
    FormDropdownComponent,
  ],
  templateUrl: "./calculation-entity.component.html",
  styleUrl: "./calculation-entity.component.scss",
})
export class CalculationEntityComponent implements OnInit, OnDestroy {
  private _calculationEngineService = inject(CalculationEngineApiService);
  private _calculationService = inject(CalculationManagementService);
  private _formBuilder = inject(FormBuilder);
  private _translateService = inject(TranslateService);
  private _toastrService = inject(ToastrService);
  private readonly _entitiesApiService = inject(EntitiesApiService);
  private readonly _systemLayoutService = inject(SystemLayoutService);
  private _router = inject(Router);
  languageService = inject(LanguageService);

  private _subscription = new Subscription();
  private readonly _untilDestroy = AdaaHelper.untilDestroyed();

  language = Language;

  subEntities: PerformanceConfigType[];
  listOfInvalidRows: number[] = [];

  entityCalculationForm: FormGroup = new FormGroup({});
  weights: FormGroup = new FormGroup({});
  config: FormGroup = new FormGroup({});
  AdaaHelper = AdaaHelper;

  entities = signal<EntityModelType[]>([]);
  entityId = signal<number>(-1);

  entitiesOptions = computed<ValueText[]>(() => {
    const field = AdaaHelper.getFieldLanguage("name");
    const result: ValueText[] = AdaaHelper.setDropdownArray(this.entities(), "id", field);
    return result;
  });

  manageCalculationManagementPermission: PermissionActionDescriptorType = {
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.manage,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.CALCULATION_MANAGEMENT,
        },
      ],
    },
  };

  public ngOnInit() {
    this._systemLayoutService.hasCycleChanged$.pipe(this._untilDestroy()).subscribe({
      next: () => {
        window.location.reload();
      },
    });

    this._systemLayoutService.hasActiveEntityChanged$.pipe(this._untilDestroy()).subscribe({
      next: () => {
        this._router.navigateByUrl("/console/calculation-management");
      },
    });

    this._prepareForm();
    this._getEntities();
  }

  private _prepareForm() {
    this.entityCalculationForm = this._formBuilder.group({
      entityId: this._formBuilder.control<number | null>(null),
      data: this._formBuilder.array([]),
    });
  }

  private _getEntities(): void {
    this._entitiesApiService.getAll(AdaaBoolean.N).subscribe({
      next: (response) => {
        this.entities.set(response.responseData.filter((entity) => entity.id !== 1));
      },
      complete: () => {
        this.entityCalculationForm.controls["entityId"]?.patchValue(this.entitiesOptions()[0].value);
        this.getPerformanceConfig();
      },
    });
  }
  public getPerformanceConfig() {
    const entityId = this.entityCalculationForm.controls.entityId?.value;
    this._calculationEngineService.getEntityPerformanceConfig(entityId).subscribe({
      next: (response) => {
        this.subEntities = response.responseData;
        this._setFormValues(response.responseData);
        this._setValidation();
        this.entityCalculationForm.valueChanges.subscribe(() => {
          this._setValidation();
        });
      },
    });
  }

  public get f() {
    return this.entityCalculationForm.controls;
  }

  public get gov() {
    return <FormArray<FormGroup>>this.entityCalculationForm.get("data");
  }

  public getConfig(gov: FormGroup) {
    return <FormArray<FormGroup>>gov.get("weights");
  }

  private _setFormValues(item: PerformanceConfigType[]) {
    this.gov.clear();
    item.forEach((gov) => {
      this.config = this._formBuilder.group({
        id: gov.id,
        nameAE: gov.nameAE,
        nameEN: gov.nameEN,
        weights: this._formBuilder.array([]),
      });
      this.gov.push(this.config);
      gov.weights.forEach((weight) => {
        this.weights = this._formBuilder.group({
          id: weight.id,
          typeId: weight.typeId,
          typeNameAE: weight.typeNameAE,
          typeNameEN: weight.typeNameEN,
          value: weight.value,
        });
        (<FormArray>this.config.controls.weights).push(this.weights);
      });
    });
  }

  private _setValidation() {
    const result = this._calculationService.validateTable(this.entityCalculationForm, this.listOfInvalidRows);
    this.entityCalculationForm = result.formGroup;
    this.listOfInvalidRows = result.listOfInvalidRows;
  }

  public getColouredInvalidRows(element: number) {
    if (element) {
      this._setValidation();
      return this._calculationService.getColouredInvalidRows(element, this.listOfInvalidRows);
    }
    return false;
  }
  public submit() {
    this._setValidation();
    if (!this.entityCalculationForm.invalid) {
      this._updateGovernmentCalculation();
    }
  }

  private _updateGovernmentCalculation() {
    const request = this._calculationService.preparingRequest(this.entityCalculationForm, this.subEntities);
    const entityId = this.entityCalculationForm.get("entityId")?.value;
    this._subscription.add(
      this._calculationService.updateEntityCalculation({ weights: request, id: entityId }).subscribe((response) => {
        if (response.inError) return;
        this.getPerformanceConfig();
        this._toastrService.success(this._translateService.instant("notification.success.save"));
      })
    );
  }
  public cancel() {
    this._router.navigate(["console/calculation-management"]);
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
