<div class="px-3 d-flex justify-content-center">
    <table class="items--table">
        <thead class="table--header mb-3">
            <tr>
                <th class="blank">{{ "common.tables.object_actions" | translate }}</th>
                <th class="blank">{{ "common.tables.create" | translate }}</th>
                <th class="blank">{{ "common.tables.view" | translate }}</th>
                <th class="blank">{{ "common.tables.manage" | translate }}</th>
                <th class="blank">{{ "common.tables.execute" | translate }}</th>
            </tr>
        </thead>
        <tbody>
            <tr [class.disabled]="pageMode() === PageMode.view">
                <td>
                    <span class="ms-1">
                        {{ "common.form.label.select_all" | translate }}
                    </span>
                </td>
                <td style="width: 10%">
                    <input
                        #create
                        class="form-check-input checkbox"
                        type="checkbox"
                        (change)="selectAll.emit({ event: $event, value: 'create' }); updateState($event, 'create')"
                        [checked]="isPmo() ? pmoTabState().create : generalTabState().create"
                        [disabled]="pageMode() === PageMode.view"
                    />
                </td>
                <td style="width: 10%">
                    <input
                        #view
                        class="form-check-input checkbox"
                        type="checkbox"
                        (change)="selectAll.emit({ event: $event, value: 'view' }); updateState($event, 'view')"
                        [checked]="isPmo() ? pmoTabState().view : generalTabState().view"
                        [disabled]="pageMode() === PageMode.view"
                    />
                </td>
                <td style="width: 10%">
                    <input
                        #manage
                        class="form-check-input checkbox"
                        type="checkbox"
                        (change)="selectAll.emit({ event: $event, value: 'manage' }); updateState($event, 'manage')"
                        [checked]="isPmo() ? pmoTabState().manage : generalTabState().manage"
                        [disabled]="pageMode() === PageMode.view"
                    />
                </td>
                <td style="width: 10%">
                    <input
                        #execute
                        class="form-check-input checkbox"
                        type="checkbox"
                        (change)="selectAll.emit({ event: $event, value: 'execute' }); updateState($event, 'execute')"
                        [checked]="isPmo() ? pmoTabState().execute : generalTabState().execute"
                        [disabled]="pageMode() === PageMode.view"
                    />
                </td>
            </tr>
            @for (item of data(); track $index) {
                <tr
                    [title]="
                        (appService.language() === Language.English ? highlightText().itemTypeDescEN! : highlightText().itemTypeDescAE!) +
                        '\n' +
                        highlightText().itemTypeDescURLs
                    "
                    (mouseover)="getHighlight(item.itemTypeId)"
                    (mouseleave)="showHighlight = false"
                    [class.disabled]="pageMode() === PageMode.view"
                >
                    <td>
                        <span class="ms-1">
                            {{ AdaaHelper.getItemValueByToken(item, "itemTypeName") }}
                        </span>
                    </td>
                    <td style="width: 10%">
                        <input
                            class="form-check-input checkbox"
                            type="checkbox"
                            [checked]="item.create === AdaaBoolean.Y"
                            [disabled]="item.createEnabled === AdaaBoolean.N || pageMode() === PageMode.view"
                            (change)="valueChanged($event, item.id, 'create')"
                        />
                    </td>
                    <td style="width: 10%">
                        <input
                            class="form-check-input checkbox"
                            type="checkbox"
                            [checked]="item.view === AdaaBoolean.Y"
                            [disabled]="item.viewEnabled === AdaaBoolean.N || pageMode() === PageMode.view"
                            (change)="valueChanged($event, item.id, 'view')"
                        />
                    </td>
                    <td style="width: 10%">
                        <input
                            class="form-check-input checkbox"
                            type="checkbox"
                            [checked]="item.manage === AdaaBoolean.Y"
                            [disabled]="item.manageEnabled === AdaaBoolean.N || pageMode() === PageMode.view"
                            (change)="valueChanged($event, item.id, 'manage')"
                        />
                    </td>
                    <td style="width: 10%">
                        <input
                            class="form-check-input checkbox"
                            type="checkbox"
                            [checked]="item.execute == AdaaBoolean.Y"
                            [disabled]="item.executeEnabled === AdaaBoolean.N || pageMode() === PageMode.view"
                            (change)="valueChanged($event, item.id, 'execute')"
                        />
                    </td>
                </tr>
            }
        </tbody>
    </table>
</div>
