<div class="ribbon" [class.ar]="lang() === Language.Arabic">
    <adaa-ribbon
        [widgetData]="dashboardWidgets()"
        [showFilterIcon]="true"
        [filterApplied]="agmService.isFilterApplied"
        (refreshClicked)="agmService.resetFilters()"
        (filterClicked)="agmService.openFilters()"
    />
</div>

<div class="row mt-3 me-1">
    <div class="col-md-3 col-sm-12 col-xs-12">
        <div class="row">
            <div class="col-12 mb-3">
                <div class="bg-white shadow-md rounded p-3 d-flex flex-column justify-content-center align-items-center">
                    <div class="col-12">
                        <adaa-form-dropdown
                            [setDefaultValue]="agmService.dashboardFilters.cycleId"
                            [options]="agmService.dashboardCycles"
                            [clearable]="true"
                            (inputChanges)="cycleChanged($event)"
                        />
                    </div>
                    <adaa-doughnut
                        [label]="'national_projects.overall_project_progress' | translate"
                        [colorId]="agmService.dashboardData()?.overallOnTimeProgressColor!"
                        [amount]="agmService.dashboardData()?.overallProgress!"
                    />
                </div>
            </div>

            <div class="col-12">
                <div class="bg-white shadow-md rounded p-3">
                    @if (!AdaaHelper.isPMOEntity()) {
                        <div class="row d-flex justify-content-end mb-2">
                            <span
                                class="col-1 fa fa-question text-primary text-center mb-1 mx-2"
                                [ngbPopover]="projectStatusHelp.getText()"
                                triggers="mouseenter:mouseleave"
                            ></span>
                        </div>
                    }
                    <div>
                        <adaa-progress-indicator
                            class="pointer"
                            [color]="Constants.CONSTANT_COLORS.EVALUATION_LIME"
                            [value]="agmService.dashboardData()?.completedWidth!"
                            [label]="('national_projects.completed_projects' | translate) + ': ' + agmService.dashboardData()?.completedCount"
                            [showValue]="false"
                            (click)="filterDashboard(TpAgmStatus.COMPLETED)"
                        />
                    </div>
                    <div class="mt-3">
                        <adaa-progress-indicator
                            class="pointer"
                            [color]="Constants.CONSTANT_COLORS.EVALUATION_BLUE"
                            [value]="agmService.dashboardData()?.ongoingWidth!"
                            [label]="('national_projects.ongoing_projects' | translate) + ': ' + agmService.dashboardData()?.ongoingCount"
                            [showValue]="false"
                            (click)="filterDashboard(TpAgmStatus.ON_TIME)"
                        />
                    </div>
                    <div class="mt-3">
                        <adaa-progress-indicator
                            class="pointer"
                            [color]="Constants.CONSTANT_COLORS.EVALUATION_GRAY"
                            [value]="agmService.dashboardData()?.delayedWidth!"
                            [label]="('national_projects.facing_delays' | translate) + ': ' + agmService.dashboardData()?.delayedCount"
                            [showValue]="false"
                            (click)="filterDashboard(TpAgmStatus.DELAYED)"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-9 col-sm-12 col-xs-12">
        <div class="bg-white shadow-md rounded p-3">
            <div class="row mb-2">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <h4 class="fw-bold">{{ "annual_meetings.page_title" | translate }}</h4>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12 d-flex justify-content-end">
                    @if (!AdaaHelper.isPMOEntity()) {
                        <adaa-form-checkbox
                            [label]="'national_projects.only_show_projects_owned_by_this_entity' | translate"
                            [controlName]="'allEntitiesCheck'"
                            [boldLabel]="true"
                            (inputChanges)="onlyEntityChanged($event)"
                        />
                    }
                </div>
            </div>
            <adaa-tp-bars
                [projects]="agmService.dashboardData()?.annualMeetings!"
                [showTargets]="false"
                (barClicked)="navigateToProject($event)"
            ></adaa-tp-bars>
            <button class="btn btn-outline-primary" (click)="navigateToCardsDashbaord()" type="button">
                {{ "national_projects.view_all_projects" | translate }}
            </button>
        </div>
    </div>
</div>
