import { Component, inject, input, OnInit, viewChild } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

import { AdaaHelper } from "../../core/utils";
import { DataTableComponent } from "../../shared/components";
import { Constants } from "../../shared/constants/constants";
import { CustomButton, SearchFields, TableButtonClicked } from "../../shared/models";
import { FilesApiService, SystemLayoutService } from "../../shared/services";

@Component({
  selector: "adaa-downloaded-reports",
  standalone: true,
  imports: [DataTableComponent, TranslateModule],
  templateUrl: "./downloaded-reports.component.html",
  styleUrl: "./downloaded-reports.component.scss",
})
export class DownloadedReportsComponent implements OnInit {
  private _filesApiService = inject(FilesApiService);
  private _systemLayoutService = inject(SystemLayoutService);

  Constants = Constants;
  tableConfList = input.required<string>();
  breadcrumb = input.required<string>();

  private readonly _datatable = viewChild.required<DataTableComponent>("adaaDataTable");

  customButtons: CustomButton[] = [
    {
      iconName: "adaa-icon-download",
      eventName: "download",
      iconTitle: "tooltips.download",
    },
  ];
  searchFields: SearchFields = {
    searchFields: {
      update_user: {
        value: AdaaHelper.getLocalStorage(Constants.localStorageKeys.user, {
          type: "prop",
          property: "email",
        }) as string,
        lov: [],
        range: undefined,
      },
      status: {
        value: undefined,
        lov: [Constants.OBJECT_STATUS.ACTIVE, Constants.OBJECT_STATUS.DRAFT, Constants.OBJECT_STATUS.ARCHIVED],
        range: undefined,
      },
    },
  };

  private readonly _untilDestroyed = AdaaHelper.untilDestroyed();

  public ngOnInit(): void {
    this._systemLayoutService.hasCycleChanged$.pipe(this._untilDestroyed()).subscribe({
      next: () => this._datatable().loadTableData(),
    });
    this._systemLayoutService.hasActiveEntityChanged$.pipe(this._untilDestroyed()).subscribe({
      next: () => this._datatable().loadTableData(),
    });
  }

  public actionClicked(event: TableButtonClicked & { index: number }) {
    const report = event.data;
    switch (event.event) {
      case "download":
        this._filesApiService.save(report.id, report.filename);
        break;
      case "delete":
        this._filesApiService.deleteFile(report.id).subscribe({
          complete: () => {
            this._datatable().loadTableData();
          },
        });
        break;

      default:
        break;
    }
  }
}
