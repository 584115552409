import { Component, inject, input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { Router, RouterLink } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { AdaaHelper } from "../../../core/utils";
import { AuthLayoutComponent, FormInputComponent } from "../../../shared/components";
import { Constants } from "../../../shared/constants/constants";
import { PermissionAction } from "../../../shared/constants/enums";
import { LoginResponse } from "../../../shared/models";
import {
  ConsoleService,
  LoaderService,
  LoginService,
  PermissionsService,
  UserProfileApiService,
} from "../../../shared/services";

@Component({
  selector: "adaa-link-account",
  imports: [TranslateModule, RouterLink, ReactiveFormsModule, AuthLayoutComponent, FormInputComponent],
  templateUrl: "./link-account.component.html",
  styleUrl: "./link-account.component.scss",
})
export class LinkAccountComponent implements OnInit {
  private _router = inject(Router);
  private _formBuilder = inject(FormBuilder);
  private _translateService = inject(TranslateService);
  private _toastrService = inject(ToastrService);
  private _loaderService = inject(LoaderService);
  private _loginService = inject(LoginService);
  private _permissionService = inject(PermissionsService);
  private _userProfileApiService = inject(UserProfileApiService);
  private _consoleService = inject(ConsoleService);

  uuid = input.required<string>({ alias: "uuid" });

  step: number = 1;
  linkAccountForm: FormGroup;

  public ngOnInit(): void {
    this._prepareForm();
  }

  public linkAccount(isConfirmed: boolean): void {
    if (isConfirmed) {
      this.step = 2;
    } else {
      this._loaderService.start();
      this._toastrService.error(this._translateService.instant("notification.error.code_67"), undefined, {
        timeOut: 10000,
      });
      this._loginService.uaePassLogout();
    }
  }

  public submit(): void {
    if (!this.linkAccountForm.valid) return;

    this._loginService.uaePassLinkAccount(this.linkAccountForm.getRawValue(), this.uuid()).subscribe({
      next: (response) => {
        if (response.inError) return;

        AdaaHelper.setLocalStorage(Constants.localStorageKeys.uaePassLogin, true, "string");
        this._onSuccessfulLogin(response.responseData);
      },
    });
  }

  private _prepareForm() {
    this.linkAccountForm = this._formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, Validators.required],
    });
  }

  private _onSuccessfulLogin(user: LoginResponse) {
    AdaaHelper.setLocalStorage(Constants.localStorageKeys.sessionId, user.sessionId, "string");
    AdaaHelper.setLocalStorage(Constants.localStorageKeys.currentEntity, { id: user.entityId }, "json");

    //Used every login
    AdaaHelper.setLocalStorage(Constants.localStorageKeys.firstVisit, "0", "string");
    AdaaHelper.setLocalStorage(Constants.localStorageKeys.lastUser, user.username, "string");

    //Used after login to check page
    AdaaHelper.setLocalStorage(Constants.localStorageKeys.checkLogin, "1", "string");

    //User to get the current user info in the system
    AdaaHelper.setLocalStorage(Constants.localStorageKeys.user, user, "json");

    const clearRelevantStorage = () => {
      AdaaHelper.deleteLocalStorage(Constants.localStorageKeys.firstVisit);
      AdaaHelper.deleteLocalStorage(Constants.localStorageKeys.lastUser);
      AdaaHelper.deleteLocalStorage(Constants.localStorageKeys.lastUrlVisited);
    };

    this._userProfileApiService.getSecurityQuestion().subscribe({
      next: (response) => {
        if (response.inError) {
          clearRelevantStorage();
          return;
        }

        return this._getRedirectUrl(response.responseData.length > 0, user);
      },
    });
  }

  private _getRedirectUrl(hasSecurityQuestions: boolean, user: LoginResponse): void {
    //THIS TO GET THE CONSOLE AFTER THE FIRST LOGIN
    //THE CACHE SHOULD BE CLEARED
    this._consoleService.init(false);

    if (!hasSecurityQuestions) {
      this._toastrService.warning(
        this._translateService.instant("notification.warning.required_field_user_profile"),
        undefined,
        { timeOut: 10000 }
      );
      this._router.navigate(["/user-profile"]);
    }

    this._permissionService.loadUserPermissions().subscribe({
      next: () => {
        const hasTpDashboardPermission = this._permissionService.hasPermission(
          [
            {
              objectTypeId: Constants.CONSTANT_PERMISSIONS.NATIONAL_PROJECTS_DASHBOARD,
              permissionAction: PermissionAction.view,
            },
          ],
          "and"
        );

        const hasHomepagePermission = this._permissionService.hasPermission(
          [
            {
              objectTypeId: Constants.CONSTANT_PERMISSIONS.HOMEPAGE,
              permissionAction: PermissionAction.view,
            },
          ],
          "and"
        );

        if (hasHomepagePermission) this._router.navigate(["/dashboard/target"]);
        else if (hasTpDashboardPermission) this._router.navigate(["/national-projects-dashboard"]);
        else this._router.navigate(["/verification"], { state: { user } });
      },
    });
  }
}
