@if (!allSent()) {
    <adaa-data-table
        #adaaDataTable
        [configKey]="sendInvite() ? inviteConfList : tableConfList()"
        [apiPrefix]="'plan'"
        [hideDeleteRules]="hideDeleteRules"
        [hideEditRules]="hideEditRules"
        [enableAdd]="pageMode() !== PageMode.view && !sendInvite()"
        [customActionButtons]="customButtons"
        [enableActionsCell]="!sendInvite()"
        [titleHeader]="'cycle.title' | translate"
        [isLocalData]="sendInvite()"
        [localData]="planEntities()"
        [itemTypeId]="Constants.CONSTANT_PERMISSIONS.CYCLES"
        [tableInputs]="tableInputs"
        (addNewClicked)="addNewClicked()"
        (actionClicked)="getAction($event)"
        (inputListChanged)="formatEntities($event)"
    ></adaa-data-table>
}

@if (sendInvite()) {
    @if (allSent()) {
        <div class="bg-white text-center p-4 my-4">
            <div class="background p-2">
                <p>{{ "cycle.no_data_row" | translate }}</p>
            </div>
        </div>
    }
    <div class="col-12 mb-3">
        <adaa-form-action-buttons
            [saveButtonTitle]="'common.form.button.send' | translate"
            [showSaveButton]="!allSent()"
            (saveClicked)="sendToEntities()"
            (cancelClicked)="close()"
        ></adaa-form-action-buttons>
    </div>
}
