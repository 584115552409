import { inject } from "@angular/core";

import { genericFloatButtons } from "../../../../shared/components/general/float-action";
import { PageMode } from "../../../../shared/constants/enums";
import { KpisService } from "../../../../shared/services";

export const editPageFloatActions = () => {
  const kpiService = inject(KpisService);
  const floatActions = genericFloatButtons();

  return (pageMode: PageMode, data?: { id: number; kpiType: number }) => {
    if (pageMode === PageMode.create) {
      return floatActions([
        {
          key: "print",
          data: undefined,
        },
        {
          key: "helpdesk",
          data: {
            url: "/helpdesk/create",
          },
        },
      ]);
    }

    if (data) {
      floatActions([
        {
          key: "workflowHistory",
          data: {
            itemId: data.id,
            itemTypeId: kpiService.getWorkflowItemId(data.kpiType),
          },
        },
        {
          key: "systemLog",
          data: {
            itemId: data.id,
            itemTypeId: [kpiService.getWorkflowItemId(data.kpiType), data.kpiType],
          },
        },
        {
          key: "print",
          data: undefined,
        },
        {
          key: "helpdesk",
          data: {
            url: "/helpdesk/create",
          },
        },
      ]);
    }
  };
};
