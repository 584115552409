import { Route } from "@angular/router";

import { checkIfLoggedInFn, pmoEntity } from "../../core/guards";
import { Constants } from "../../shared/constants/constants";
import { PageMode } from "../../shared/constants/enums";
import { manageMandatoryFieldsPageGuards } from "./_guards";
import { MandatoryFieldsEditorComponent } from "./mandatory-fields-editor/mandatory-fields-editor.component";
import { MandatoryFieldsListComponent } from "./mandatory-fields-list/mandatory-fields-list.component";

export const MANDATORY_FIELDS_ROUTES: Route[] = [
  {
    path: "mandatory",
    canActivate: [checkIfLoggedInFn, pmoEntity, ...manageMandatoryFieldsPageGuards],
    children: [
      {
        path: "list",
        redirectTo: "",
      },
      {
        path: "",
        component: MandatoryFieldsListComponent,
        data: {
          title: "mandatory.title",
          breadcrumb: "mandatory.title",
        },
      },
      {
        path: "edit/:searchKey",
        component: MandatoryFieldsEditorComponent,
        data: {
          breadcrumb: "breadcrumbs.edit_mandatory_fields",
          title: "breadcrumbs.edit_mandatory_fields",
          pageMode: PageMode.edit,
        },
      },
    ],
    data: {
      breadcrumb: "mandatory.title",
      tableConfList: Constants.MAIN_TABLE_LIST_CONF_KEY.MANDATORYS,
    },
  },
];
