import { Component, inject, input, signal } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";

import { AdaaHelper } from "../../../../core/utils";
import {
  ConfirmationModalComponent,
  FormActionButtonsComponent,
  FormInputComponent,
} from "../../../../shared/components";
import { Constants } from "../../../../shared/constants/constants";
import { Language, PageMode } from "../../../../shared/constants/enums";
import { AppService, SystemLayoutService, TpSectorApiService } from "../../../../shared/services";
import { TPSectorEditorService } from "../../../../shared/services/tp-sector-editor.service";

@Component({
  selector: "adaa-tp-sectors-editor",
  standalone: true,
  imports: [ReactiveFormsModule, RouterModule, TranslateModule, FormActionButtonsComponent, FormInputComponent],
  templateUrl: "./tp-sectors-editor.component.html",
  styleUrl: "./tp-sectors-editor.component.scss",
})
export class TpSectorsEditorComponent {
  private _formBuilder = inject(FormBuilder);
  private _tpSectorApiService = inject(TpSectorApiService);
  private _toastrService = inject(ToastrService);
  private _translateService = inject(TranslateService);
  private _router = inject(Router);
  private _modalService = inject(NgbModal);
  private _appService = inject(AppService);
  private _activatedRoute = inject(ActivatedRoute);
  private readonly _systemLayoutService = inject(SystemLayoutService);

  readonly #untilDestroy = AdaaHelper.untilDestroyed();
  tpSectorEditorService = inject(TPSectorEditorService);

  adaaHelper = AdaaHelper;
  _subscription = new Subscription();

  pageMode = input.required<string>();
  tpSectorForm: FormGroup = new FormGroup({});
  PageMode = PageMode;

  tpSectorId: string | null;

  displayLabel = signal<string>("");
  setDisabled = signal<boolean>(false);
  submitted = signal<boolean>(false);

  public ngOnInit(): void {
    this._systemLayoutService.hasActiveEntityChanged$.pipe(this.#untilDestroy()).subscribe({
      next: () => {
        this._router.navigateByUrl(`/console`);
      },
    });
    this.setDisabled.set(this.pageMode() === PageMode.view);
    this.tpSectorId = this._activatedRoute.snapshot?.paramMap?.get("id");

    this.tpSectorEditorService.formValidator();
    if (this.tpSectorId) this._getTPSector(+this.tpSectorId);
    this._prepareForm();
  }

  public checkInvalid(control: string) {
    return (
      this.tpSectorForm.controls[control].errors?.["required"] &&
      (this.tpSectorForm.get(`${control}`)?.dirty || this.submitted())
    );
  }

  private _prepareForm() {
    const isViewMode = this.pageMode() === PageMode.view;

    this.tpSectorForm = this._formBuilder.group({
      nameAE: this._formBuilder.control<string | undefined>(
        { value: "", disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      nameEN: this._formBuilder.control<string | undefined>(
        { value: "", disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
    });

    this._subscription.add(
      this.tpSectorForm.valueChanges.subscribe((value) => {
        const displayLanguage = AdaaHelper.getFieldLanguage("name");
        if (!value[displayLanguage].length) {
          this.displayLabel.set(value["nameAE"] || value["nameEN"]);
        } else this.displayLabel.set(value[displayLanguage]);
      })
    );
  }

  private _getTPSector(tpSectorId: number) {
    if (tpSectorId)
      this._tpSectorApiService.getTPSectorById(tpSectorId).subscribe({
        next: (response) => {
          this.tpSectorForm.patchValue({ ...response.responseData });
          this.tpSectorForm.markAsPristine();
          this.tpSectorForm.markAsUntouched();
        },
      });
  }

  public updateSector() {
    this.submitted.set(true);
    if (this.tpSectorForm.invalid) {
      this._toastrService.warning(this._translateService.instant("notification.warning.missing_info"));
      return;
    }
    const tpSector = this._formatTPSector();

    const serviceUrl =
      this.pageMode() === PageMode.edit
        ? this._tpSectorApiService.updateTPSector(tpSector)
        : this._tpSectorApiService.createTPSector(tpSector);

    serviceUrl.subscribe({
      next: (response) => {
        if (response.inError) return;
        this._toastrService.success(this._translateService.instant("notification.success.save"));
        this._router.navigateByUrl("/console/national-project/sectors");
      },
    });
  }
  private _formatTPSector() {
    const tpSector = this.tpSectorForm.value;
    tpSector.status = Constants.OBJECT_STATUS.ACTIVE;
    if (this.pageMode() === PageMode.edit) {
      tpSector.updateTS = Date.now();
      tpSector.id = this.tpSectorId;
    }
    return tpSector;
  }
  public cancelForm() {
    if (this.tpSectorForm.dirty) {
      const modal = this._modalService.open(ConfirmationModalComponent, {
        centered: true,
        size: "md",
        modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
      });

      modal.componentInstance.header = "common.form.modals_leave.cancel_yes_no_title";
      modal.componentInstance.title = "common.form.modals_leave.cancel_yes_no_information";

      modal.result.then((e) => {
        if (e) this._router.navigateByUrl(`/console/national-project/sectors`);
      });
    } else this._router.navigateByUrl(`/console/national-project/sectors`);
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
