import { Route } from "@angular/router";

import { checkIfLoggedInFn, pmoEntity } from "../../core/guards";
import { PageMode } from "../../shared/constants/enums";
import { createGovDirectionsPageGuards, manageGovDirectionsPageGuards, viewGovDirectionsPageGuards } from "./_guards";
import { GovernmentDirectionsEditorComponent } from "./government-directions-editor/government-directions-editor.component";
import { GovernmentDirectionsListComponent } from "./government-directions-list/government-directions-list.component";

export const GOVERNMENT_DIRECTIONS_ROUTES: Route[] = [
  {
    path: "directions",
    canActivate: [checkIfLoggedInFn, pmoEntity, ...viewGovDirectionsPageGuards],
    children: [
      {
        path: "list",
        redirectTo: "",
      },
      {
        path: "",
        component: GovernmentDirectionsListComponent,
        data: {
          title: "breadcrumbs.directions_module.list",
          breadcrumb: "breadcrumbs.directions_module.list",
        },
      },
      {
        path: "create",
        component: GovernmentDirectionsEditorComponent,
        canActivate: [...createGovDirectionsPageGuards],
        data: {
          title: "breadcrumbs.directions_module.new",
          breadcrumb: "breadcrumbs.directions_module.new",
          pageMode: PageMode.create,
        },
      },
      {
        path: "edit/:id",
        component: GovernmentDirectionsEditorComponent,
        canActivate: [...manageGovDirectionsPageGuards],
        data: {
          title: "breadcrumbs.directions_module.edit",
          breadcrumb: "breadcrumbs.directions_module.edit",
          pageMode: PageMode.edit,
        },
      },
      {
        path: ":id",
        component: GovernmentDirectionsEditorComponent,
        data: {
          title: "breadcrumbs.directions_module.view",
          breadcrumb: "breadcrumbs.directions_module.view",
          pageMode: PageMode.view,
        },
      },
    ],
  },
];
