<div class="modal-header bg-white">
    <h4 class="modal-title w-100 text-center">{{ "ekpi.card_view_all_data_button" | translate }}</h4>
    <button type="button" class="btn-close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.close()"></button>
</div>

<div class="modal-body bg-white">
    @for (key of tableDataCategories(); track $index) {
        <div class="d-block border border-1 border-white bg-color mb-1">
            <div class="row d-flex w-100 align-content-center">
                <div
                    class="col-xs-12 col-sm-4 align-self-center"
                    [class.text-start]="languageService.current() === 'en'"
                    [class.text-end]="languageService.current() === 'ae'"
                >
                    <span class="px-2">{{ key }}</span>
                </div>

                <div class="col-xs-12 col-sm-8 overflow-x-auto kpi-details--container">
                    <div class="d-block position-relative w-100 py-1">
                        <!-- row:Period -->
                        <div class="w-100 d-flex align-content-center kpi-details--row">
                            <div
                                class="flex-shrink-0 kpi-details--col kpi-details--main-col"
                                [class.is-formula]="!isNoFormula()"
                                [class.start-0]="languageService.current() === 'en'"
                                [class.end-0]="languageService.current() === 'ae'"
                            ></div>

                            @for (period of tableData()[key]; track period.periodId) {
                                <div class="d-flex text-center kpi-details--col kpi-details--data-col" [dir]="languageService.direction()">
                                    <span class="label fw-bold text-primary" [dir]="languageService.direction()">
                                        {{ period.periodLabel }}
                                    </span>
                                </div>
                            }
                        </div>

                        <!-- row:Targets -->
                        <div class="w-100 d-flex align-content-center kpi-details--row">
                            @let limits = isBounded() ? ["low", "high"] : [""];
                            @for (limit of limits; track limit) {
                                <div
                                    class="d-flex text-center kpi-details--col kpi-details--main-col"
                                    [class.is-formula]="!isNoFormula()"
                                    [class.start-0]="languageService.current() === 'en'"
                                    [class.end-0]="languageService.current() === 'ae'"
                                >
                                    <span class="label" [dir]="languageService.direction()">
                                        {{ (limit === "low" ? "kpi.lower_limit" : limit === "high" ? "kpi.upper_limit" : "nkpi.target") | translate }}
                                    </span>
                                </div>

                                @for (item of tableData()[key]; track item.periodId; let i = $index) {
                                    @let target = limit === "low" ? item.lowerLimit : limit === "high" ? item.highLimit : item.target;

                                    <div class="d-flex kpi-details--col" [dir]="languageService.direction()">
                                        <span class="label fw-bold" dir="ltr">
                                            @if (item.isBaselineYear) {
                                                {{ "ekpi.baselineYear" | translate }}
                                            } @else {
                                                {{ target | percentageValue: 2 : "-" : "%" : false }}
                                            }
                                        </span>
                                    </div>
                                }
                            }
                        </div>

                        <!-- row:Actuals -->
                        <div class="w-100 d-flex align-content-center kpi-details--row">
                            <div
                                class="d-flex text-center kpi-details--col kpi-details--main-col"
                                [class.is-formula]="!isNoFormula()"
                                [class.start-0]="languageService.current() === 'en'"
                                [class.end-0]="languageService.current() === 'ae'"
                            >
                                <span class="label" [dir]="languageService.direction()">
                                    {{ "nkpi.actual" | translate }}
                                </span>
                            </div>

                            @for (item of tableData()[key]; track item.periodId) {
                                @let actual = item.actual;

                                <div class="d-flex kpi-details--col" [dir]="languageService.direction()">
                                    @if (item.ignored) {
                                        <span class="label fw-bold" dir="ltr"> N/A </span>
                                    } @else {
                                        <span class="label fw-bold" dir="ltr">
                                            {{ actual | percentageValue: 2 : "-" : "%" : false }}
                                        </span>
                                    }
                                </div>
                            }
                        </div>

                        <!-- row:Annual Actuals -->
                        <div class="w-100 d-flex align-content-center kpi-details--row">
                            <div
                                class="d-flex text-center kpi-details--col kpi-details--main-col"
                                [class.is-formula]="!isNoFormula()"
                                [class.start-0]="languageService.current() === 'en'"
                                [class.end-0]="languageService.current() === 'ae'"
                            >
                                <span class="label" [dir]="languageService.direction()">
                                    {{ "data_entry.annual_actual" | translate }}
                                </span>
                            </div>

                            @for (item of tableData()[key]; track item.periodId) {
                                @let actual = item.annualActual;

                                <div class="d-flex kpi-details--col" [dir]="languageService.direction()">
                                    @if (item.ignored) {
                                        <span class="label fw-bold" dir="ltr"> N/A </span>
                                    } @else {
                                        <span class="label fw-bold" dir="ltr">
                                            {{ actual | percentageValue: 2 : "-" : "%" : false }}
                                        </span>
                                    }
                                </div>
                            }
                        </div>

                        <!-- row:Metric Readings -->
                        @if (
                            tableData()[key][0].metricReadingsItemData.length &&
                            tableData()[key][0].metricReadingsItemData[0].metricName.toLowerCase() !== "noformulav"
                        ) {
                            @for (reading of tableData()[key][0].metricReadingsItemData; track reading.id; let i = $index) {
                                <div class="w-100 d-flex align-content-center kpi-details--row">
                                    <div
                                        class="d-flex text-center kpi-details--col kpi-details--main-col"
                                        [class.is-formula]="!isNoFormula()"
                                        [class.start-0]="languageService.current() === 'en'"
                                        [class.end-0]="languageService.current() === 'ae'"
                                    >
                                        <span class="label" [dir]="languageService.direction()">
                                            {{ reading.metricName + " - " + reading.metricDescription }}
                                        </span>
                                    </div>

                                    @for (reading of reading.metricReadingsItemValues; track $index) {
                                        <div class="d-flex kpi-details--col" [dir]="languageService.direction()">
                                            <span class="label fw-bold" dir="ltr">
                                                {{ reading.value | percentageValue: 2 : "-" : "%" : false }}
                                            </span>
                                        </div>
                                    }
                                </div>
                            }
                        }

                        <!-- row:PeriodPerformance -->
                        <div class="w-100 d-flex align-content-center kpi-details--row">
                            <div
                                class="d-flex text-center kpi-details--col kpi-details--main-col"
                                [class.is-formula]="!isNoFormula()"
                                [class.start-0]="languageService.current() === 'en'"
                                [class.end-0]="languageService.current() === 'ae'"
                            >
                                <span class="label" [dir]="languageService.direction()">
                                    {{ "nkpi.performance" | translate }}
                                </span>
                            </div>

                            @for (item of tableData()[key]; track item.periodId) {
                                @let score = item.score;

                                <div class="d-flex kpi-details--col" [dir]="languageService.direction()">
                                    <span class="label fw-bold" dir="ltr">
                                        {{ score | percentageValue: 2 : "-" : "%" : false }}
                                    </span>
                                </div>
                            }
                        </div>

                        <!-- row:YearToPeriod -->
                        <div class="w-100 d-flex align-content-center kpi-details--row">
                            <div
                                class="d-flex text-center kpi-details--col kpi-details--main-col"
                                [class.is-formula]="!isNoFormula()"
                                [class.start-0]="languageService.current() === 'en'"
                                [class.end-0]="languageService.current() === 'ae'"
                            >
                                <span class="label" [dir]="languageService.direction()">
                                    {{ "ekpi.performance_ytp" | translate }}
                                </span>
                            </div>

                            @for (item of tableData()[key]; track item.periodId) {
                                @let scoreYtp = item.scoreYtp;

                                <div class="d-flex kpi-details--col" [dir]="languageService.direction()">
                                    <span class="label fw-bold" dir="ltr">
                                        {{ scoreYtp | percentageValue: 2 : "-" : "%" : false }}
                                    </span>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
</div>

<div class="modal-footer d-flex justify-content-center">
    <button type="button" class="btn btn-primary px-2" aria-label="Close button" aria-describedby="modal-title" (click)="modal.close()">
        {{ "common.form.button.close" | translate }}
    </button>
</div>
