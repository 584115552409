import { Route } from "@angular/router";

import { checkIfLoggedInFn } from "../../core/guards";
import { ImportExportObjectType } from "../../shared/constants/enums";
import { viewDataEntryPagesGuards, viewImportExportDataEntryPagesGuards } from "./_guards";
import { KPI_ACTUALS_ROUTES } from "./kpi-actuals/kpi-actuals.routes";
import { DATA_ENTRY_METRICS_ROUTES } from "./metrics/metrics.routes";

export const DATA_ENTRY_ROUTES: Route[] = [
  {
    path: "data-entry",
    children: [
      {
        path: "",
        canActivate: [checkIfLoggedInFn, ...viewDataEntryPagesGuards],
        data: {
          title: "breadcrumbs.data-entry",
          breadcrumb: "breadcrumbs.data-entry",
        },
        loadComponent: () =>
          import("./data-entry-submenu/data-entry-submenu.component").then((e) => e.DataEntrySubmenuComponent),
      },
      {
        path: "import-export-data",
        canActivate: [checkIfLoggedInFn, ...viewImportExportDataEntryPagesGuards],
        data: {
          breadcrumb: "importExport.title_kpidata",
          title: "importExport.title_kpidata",
          type: ImportExportObjectType.KPIDATA,
        },
        loadComponent: () =>
          import("../../shared/components/import-export/import-export.component").then((e) => e.ImportExportComponent),
      },
      ...KPI_ACTUALS_ROUTES,
      ...DATA_ENTRY_METRICS_ROUTES,
    ],
  },
];
