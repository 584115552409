import { Component, inject, OnInit, viewChild } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { AdaaHelper } from "../../../core/utils";
import { ConfirmationModalComponent, DataTableComponent, FloatActionComponent } from "../../../shared/components";
import { genericFloatButtons } from "../../../shared/components/general/float-action";
import { Constants } from "../../../shared/constants/constants";
import { AdaaBoolean, Language, PermissionAction } from "../../../shared/constants/enums";
import { CustomButton, TableButtonClicked } from "../../../shared/models";
import { AppService, PermissionsService, SystemLayoutService } from "../../../shared/services";
import { UserRolesApiService } from "../../../shared/services/user-roles-api.service";

@Component({
  selector: "adaa-user-roles-list",
  standalone: true,
  imports: [DataTableComponent, TranslateModule, FloatActionComponent],
  templateUrl: "./user-roles-list.component.html",
  styleUrl: "./user-roles-list.component.scss",
})
export class UserRolesListComponent implements OnInit {
  private _router = inject(Router);
  private _userRolesApiService = inject(UserRolesApiService);
  private _toastrService = inject(ToastrService);
  private _translateService = inject(TranslateService);
  private _appService = inject(AppService);
  private _modalService = inject(NgbModal);
  private _permissionsService = inject(PermissionsService);
  private _systemLayoutService = inject(SystemLayoutService);

  adaaDataTable = viewChild<DataTableComponent>("adaaDataTable");

  private _untilDestroyed = AdaaHelper.untilDestroyed();
  private _genericFloatButtons = genericFloatButtons();
  Constants = Constants;
  customButtons: CustomButton[] = [];

  public ngOnInit(): void {
    this._floatActions();

    this._permissionsService.isPermissionsLoaded.pipe(this._untilDestroyed()).subscribe((loaded: boolean) => {
      if (!loaded) return;

      this._checkPermissions();
    });

    this._systemLayoutService.hasActiveEntityChanged$.pipe(this._untilDestroyed()).subscribe(() => {
      this._router.navigateByUrl("console");
    });
  }

  private _checkPermissions() {
    const permission = this._permissionsService.hasPermission([
      {
        permissionAction: PermissionAction.manage,
        objectTypeId: Constants.CONSTANT_PERMISSIONS.USER_ROLES,
      },
    ]);

    this.customButtons.push(
      {
        iconName: "adaa-icon-adaa-ok-Y",
        eventName: "enable",
        iconTitle: "common.form.label.active",
        onlyIf: (data: { enabled: AdaaBoolean }) => {
          return AdaaBoolean.Y === data?.enabled && permission;
        },
      },
      {
        iconName: "adaa-icon-adaa-ok-N",
        eventName: "disable",
        iconTitle: "common.form.label.inactive",
        onlyIf: (data: { enabled: AdaaBoolean }) => {
          return AdaaBoolean.N === data?.enabled && permission;
        },
      }
    );
  }

  public addNew() {
    this._router.navigateByUrl("/console/users-roles/create");
  }

  public getAction(value: TableButtonClicked) {
    switch (value.event) {
      case "enable":
      case "disable":
        this._updateState(value);
        break;
      case "edit":
        this._router.navigateByUrl(`/console/users-roles/edit/${value.data.id}`);
        break;
      case "view":
        this._router.navigateByUrl(`/console/users-roles/${value.data.id}`);
        break;
      case "delete":
        this._deleteRole(value.data.id);
        break;
      default:
        break;
    }
  }

  private _updateState(value: TableButtonClicked) {
    const data = { id: value.data.id, enabled: value.event === "enable" ? "N" : "Y" };
    this._userRolesApiService.updateState(data).subscribe({
      next: () => {
        this._toastrService.success(
          this._translateService.instant(
            "notification.success." + (value.event === "enable" ? "deactivate" : "activate")
          )
        );
        this.adaaDataTable()?.loadTableData();
      },
    });
  }

  private _deleteRole(roleId: number) {
    const modal = this._modalService.open(ConfirmationModalComponent, {
      centered: true,
      size: "md",
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });

    modal.componentInstance.header = "user_roles.modals.delete_yes_no.title";
    modal.componentInstance.title = "user_roles.modals.delete_yes_no.info";

    modal.result.then((e) => {
      if (e) {
        this._userRolesApiService.deleteRole(roleId).subscribe({
          next: () => {
            this._toastrService.success(this._translateService.instant("notification.success.remove"));
            this.adaaDataTable()?.loadTableData();
          },
        });
      }
    });
  }

  private _floatActions(): void {
    this._genericFloatButtons([
      {
        key: "print",
        data: undefined,
      },
      {
        key: "helpdesk",
        data: {
          url: "/helpdesk/create",
        },
      },
    ]);
  }
}
