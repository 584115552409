import { Route } from "@angular/router";

import { checkIfLoggedInFn } from "../../core/guards";
import { ACTIVITIES_ROUTES } from "../activities/activities.routes";
import { AGM_ROUTES } from "../agm/agm.routes";
import { ATTRIBUTES_ROUTES } from "../attributes/attributes.routes";
import { AUDIT_ROUTES } from "../audit/audit.routes";
import { CALCULATION_MANAGEMENT_ROUTES } from "../calculation-management/calculation-management.routes";
import { DATA_ENTRY_ROUTES } from "../data-entry/data-entry.routes";
import { DATA_ENTRY_TIMELINE_ROUTES } from "../data-entry-timeline/data-entry-timeline.routes";
import { DIMENSIONS_ROUTES } from "../dimensions/dimensions.routes";
import { DOWNLOADED_REPORTS_ROUTES } from "../downloaded-reports/downloaded-reports.routes";
import { ENTITIES_ROUTES } from "../entities/entities.routes";
import { ENTITY_PROFILE_ROUTES } from "../entity-profile/entity-profile.routes";
import { ENTITY_REPORTS_ROUTES } from "../entity-reports/entity-reports.routes";
import { FORMULA_TEMPLATE_ROUTES } from "../formulas-template/formulas-template.routes";
import { GOVERNMENT_DIRECTIONS_ROUTES } from "../government-directions/government-directions.routes";
import { GOVERNMENT_SCORES_ROUTES } from "../government-scores/government-scores.routes";
import { IMPORT_EXPORT_PLAN_ROUTES } from "../import-export-plan/import-export-plan.routes";
import { INITIATIVES_ROUTES } from "../initiatives/initiatives.routes";
import { KHADAMATI_ROUTES } from "../khadamati/khadamati.routes";
import { KPI_ROUTES } from "../kpi/kpi.routes";
import { LOOKUP_ROUTES } from "../lookup-management/lookup-management.routes";
import { MANDATORY_FIELDS_ROUTES } from "../mandatory-fields/mandatory-fields.routes";
import { METRICS_ROUTES } from "../metrics/metrics.routes";
import { NATIONAL_STRATEGIES_PAGES_ROUTES } from "../national-strategies/national-strategies.routes";
import { NOTIFICATIONS_MANAGEMENT_ROUTES } from "../notifications-management/notifications-management.routes";
import { OBJECTIVES_ROUTES } from "../objectives/objective.routes";
import { OTHER_ENTITIES_ROUTES } from "../other-entities/other-entities.routes";
import { PILLAR_ROUTES } from "../pillars/pillars.routes";
import { SEARCH_ROUTES } from "../search/search.routes";
import { SERVICES_ROUTES } from "../services/services.routes";
import { STRATEGIC_CYCLE_ROUTES } from "../strategic-cycles/strategic-cycles.routes";
import { SYSTEMLOG_ROUTES } from "../system-log/system-log.routes";
import { TEXT_MAPPING_ROUTES } from "../text-mappings/text-mappings.routes";
import { TP_ROUTES } from "../transformational-projects/transformational-projects.routes";
import { TRANSLATION_ROUTES } from "../translations/translations.routes";
import { USER_ROUTES } from "../user/user.routes";
import { USER_GROUPS_ROUTES } from "../user-groups/user-groups.routes";
import { USER_ROLES_ROUTES } from "../user-roles/user.roles.routes";
import { WORKFLOWS_ROUTES } from "../workflows/workflows.routes";
import { ConsoleComponent } from "./console.component";

export const CONSOLE_ROUTES: Route[] = [
  {
    path: "console",
    canActivate: [checkIfLoggedInFn],
    children: [
      {
        path: "",
        component: ConsoleComponent,
        canActivate: [checkIfLoggedInFn],
      },
      ...USER_ROUTES,
      ...KPI_ROUTES,
      ...WORKFLOWS_ROUTES,
      ...SYSTEMLOG_ROUTES,
      ...PILLAR_ROUTES,
      ...TRANSLATION_ROUTES,
      ...OBJECTIVES_ROUTES,
      ...KHADAMATI_ROUTES,
      ...GOVERNMENT_DIRECTIONS_ROUTES,
      ...INITIATIVES_ROUTES,
      ...DOWNLOADED_REPORTS_ROUTES,
      ...FORMULA_TEMPLATE_ROUTES,
      ...USER_GROUPS_ROUTES,
      ...STRATEGIC_CYCLE_ROUTES,
      ...ENTITY_PROFILE_ROUTES,
      ...OTHER_ENTITIES_ROUTES,
      ...LOOKUP_ROUTES,
      ...ENTITY_REPORTS_ROUTES,
      ...CALCULATION_MANAGEMENT_ROUTES,
      ...USER_ROLES_ROUTES,
      ...ACTIVITIES_ROUTES,
      ...ATTRIBUTES_ROUTES,
      ...SEARCH_ROUTES,
      ...SERVICES_ROUTES,
      ...ENTITIES_ROUTES,
      ...NATIONAL_STRATEGIES_PAGES_ROUTES,
      ...MANDATORY_FIELDS_ROUTES,
      ...TP_ROUTES,
      ...NOTIFICATIONS_MANAGEMENT_ROUTES,
      ...TEXT_MAPPING_ROUTES,
      ...DATA_ENTRY_ROUTES,
      ...GOVERNMENT_SCORES_ROUTES,
      ...METRICS_ROUTES,
      ...AUDIT_ROUTES,
      ...AGM_ROUTES,
      ...DIMENSIONS_ROUTES,
      ...IMPORT_EXPORT_PLAN_ROUTES,
      ...DATA_ENTRY_TIMELINE_ROUTES,
    ],
    data: {
      title: "navigation.console",
      breadcrumb: "navigation.console",
    },
  },
];
