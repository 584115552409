<div class="w-100 d-flex justify-content-center align-items-center align-content-center searchbox">
    <div class="input-group input-group-lg flex-shrink-0 flex-nowrap">
        <input
            #searchInputEl
            type="search"
            autocomplete="on"
            inputmode="search"
            class="form-control"
            [dir]="languageService.direction()"
            [value]="systemLayoutService.searchTerm()"
            [placeholder]="'breadcrumbs.search' | translate"
            [attr.aria-label]="'breadcrumbs.search' | translate"
            (change)="autoUpdateSearchTerm($event)"
            (keydown.enter)="performSearch($event)"
        />
        <button class="btn btn-outline-primary text-primary bg-white" id="addon-wrapping" (click)="searchButtonClicked(searchInputEl)">
            <fa-icon [icon]="faSearch" [title]="'breadcrumbs.search' | translate" />
        </button>
    </div>
</div>

@if (s() && s()!.length > 2) {
    <div class="row mt-2 searchtitle">
        <div class="col-12">
            <h4>
                <i class="fw-bolder">{{ "search.searchingfor" | translate }}:</i>
            </h4>

            <h3>
                {{ s() }}
                @if (languageService.direction() === "rtl") {
                    <span class="fs-5 fw-bold">
                        <i> ( {{ "search.resultsfound" | translate }} {{ totalNumberOfItems() }} ) </i>
                    </span>
                } @else {
                    <span class="fs-5 fw-bold">
                        <i> ( {{ totalNumberOfItems() }} {{ "search.resultsfound" | translate }} ) </i>
                    </span>
                }
            </h3>
        </div>
    </div>

    <div class="row mt-4 mb-1 d-flex">
        <div class="col-sm-5 col-md-3">
            <ul class="list-group searchoptions" role="menu">
                <li
                    class="list-group-item d-flex justify-content-between align-items-center align-content-center flex-wrap option"
                    role="menuitem"
                    [attr.aria-current]="isActive('')"
                    [class.bg-primary]="isActive('')"
                    [class.text-white]="isActive('')"
                    [class.bg-transparent]="!isActive('')"
                    [class.text-dark]="!isActive('')"
                    [class.active]="isActive('')"
                    (click)="selectType($event, '')"
                >
                    <span class="fs-5 d-flex justify-content-start align-items-center align-content-center">
                        <span class="bg-white shadow icon flex-shrink-0">
                            <fa-icon [icon]="faStar" [title]="'common.form.label.all' | translate" [ngClass]="['text-secondary', 'icon']" />
                        </span>

                        <span class="fw-bold mx-3 text">{{ "common.form.label.all" | translate }}</span>
                    </span>

                    <span
                        class="badge fs-6 text-center flex-shrink-0"
                        [class.text-primary]="isActive('')"
                        [class.bg-white]="isActive('')"
                        [class.bg-primary]="!isActive('')"
                        [class.text-white]="!isActive('')"
                    >
                        {{ totalNumberOfItems() }}
                    </span>
                </li>

                @for (category of searchCategories; track $index) {
                    <li
                        class="list-group-item d-flex justify-content-between align-items-center align-content-center flex-wrap option"
                        role="menuitem"
                        [attr.aria-current]="isActive(category.type)"
                        [class.bg-primary]="isActive(category.type)"
                        [class.text-white]="isActive(category.type)"
                        [class.bg-transparent]="!isActive(category.type)"
                        [class.text-dark]="!isActive(category.type)"
                        [class.active]="isActive(category.type)"
                        [class.sub-active]="!!category.subCategories && isParentActive()"
                        (click)="selectType($event, category.type)"
                    >
                        <div class="d-flex justify-content-between align-items-center align-content-center w-100">
                            <span class="fs-6 d-flex justify-content-start align-items-center align-content-center">
                                <span class="bg-white shadow icon flex-shrink-0">
                                    <i [ngClass]="[category.icon]" [title]="category.text | translate"></i>
                                </span>

                                <span class="fw-bold mx-3 text">{{ category.text | translate }}</span>
                            </span>

                            <span
                                class="badge fs-6 text-center flex-shrink-0"
                                [class.text-primary]="isActive(category.type)"
                                [class.bg-white]="isActive(category.type)"
                                [class.bg-primary]="!isActive(category.type)"
                                [class.text-white]="!isActive(category.type)"
                            >
                                {{ getCount(category.type) }}
                            </span>
                        </div>

                        @if (category.subCategories && isParentActive()) {
                            <ul class="list-group subitemlist">
                                @for (item of category.subCategories; track $index) {
                                    <li
                                        class="list-group-item d-flex justify-content-between align-items-center align-content-center flex-wrap subitem"
                                        role="menuitem"
                                        [attr.aria-current]="isActive(item.type)"
                                        [class.bg-primary]="isActive(item.type)"
                                        [class.text-white]="isActive(item.type)"
                                        [class.bg-transparent]="!isActive(item.type)"
                                        [class.text-dark]="!isActive(item.type)"
                                        [class.active]="isActive(item.type)"
                                        (click)="selectType($event, item.type)"
                                    >
                                        <span class="fs-6 d-flex justify-content-start align-items-center align-content-center subitemtext">
                                            <span class="bg-white shadow subicon flex-shrink-0">
                                                <i [ngClass]="[item.icon]" [title]="item.text | translate"></i>
                                            </span>

                                            <span class="fw-bold mx-3 subtext">{{ item.text | translate }} ({{ getCount(item.type) }})</span>
                                        </span>
                                    </li>
                                }
                            </ul>
                        }
                    </li>
                }
            </ul>
        </div>

        <div class="col-sm-7 col-md-9">
            @if (isLoading()) {
                <div class="w-100 d-flex justify-content-center py-3 bg-white">
                    <div class="text-center d-block fs-6 fw-bold">
                        {{ "search.loading" | translate }}
                    </div>
                </div>
            }

            @if (!isLoading()) {
                @if (!data()?.searchResultList || (data()?.searchResultList ?? []).length === 0) {
                    <div class="d-flex justify-content-center align-items-center align-content-center bg-white py-4 shadow-sm">
                        <span class="fs-5 fw-bold">
                            {{ "search.noresults" | translate }}
                        </span>
                    </div>
                } @else {
                    <div class="d-flex justify-content-center align-items-center align-content-center" style="margin-top: -38px">
                        <span class="fs-4 fw-bold fst-italic">
                            {{ resultsFound }} {{ "search.found" | translate }} {{ "search.of" | translate }}
                            {{ !t() || t() === "" ? totalNumberOfItems() : data()?.searchCount }}
                        </span>
                    </div>

                    @for (data of data()?.searchResultList; track $index) {
                        <div class="card w-100 px-0 searchitem my-2 shadow-sm" role="link" [attr.tabindex]="$index" (click)="onClick(data)">
                            @if (
                                !t() ||
                                t() === "" ||
                                t()?.toLowerCase() === "service" ||
                                t()?.toLowerCase() === "activity" ||
                                t()?.toLowerCase() === "objective" ||
                                t()?.toLowerCase() === "initiative" ||
                                isSubKpiType(t()?.toLowerCase()!)
                            ) {
                                <div class="text-primary fw-bold py-2 px-3 searchtype">
                                    {{
                                        getLabel(data.type, {
                                            itemType: data.itemType,
                                            id: data.id,
                                            subType: data.subType,
                                            planId: data.planId,
                                        }) | translate
                                    }}
                                </div>
                            }

                            <div class="card-title mb-0 px-3 fw-bolder" [class.py-2]="!(!t() || t() === '')">
                                {{ getName(data) }}
                            </div>

                            @if (isDefined(data.descriptionAE) || isDefined(data.descriptionEN)) {
                                <div class="card-body px-3 pt-1 pb-2" [innerHTML]="getDescription(data) | safeHtml"></div>
                            }

                            <div class="card-footer px-3 pt-1">
                                @if (isDefined(data.startDate) || isDefined(data.endDate)) {
                                    <div class="d-block py-1 fs-6">
                                        @if (isDefined(data.startDate)) {
                                            <i class="fa-solid fa-clock"></i>
                                            <span class="mx-1 text-secondary">{{ data.startDate | dubaiTime | date: datetimeFormat }}</span>
                                        }

                                        <i class="fa-solid fa-minus mx-3"></i>

                                        @if (isDefined(data.endDate)) {
                                            <i class="fa-solid fa-clock"></i>
                                            <span class="mx-1 text-secondary">{{ data.endDate | dubaiTime | date: datetimeFormat }}</span>
                                        }
                                    </div>
                                }

                                @if (isDefined(data.entityNameAE) || isDefined(data.entityNameEN)) {
                                    <div class="d-block py-1 fs-6">
                                        <i class="adaa-icon-console_entities fs-6"></i>
                                        <span class="mx-2 text-secondary">{{ data | translateToken: "entityName" }}</span>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                }
            }

            <div class="d-flex align-items-center justify-content-end mt-3 mb-2 adaa--pagination _adaa_table_design_1 adaa--pagination">
                <ngb-pagination
                    [collectionSize]="data()?.searchCount ?? totalNumberOfItems()"
                    [(page)]="currentPage"
                    [pageSize]="limit"
                    [maxSize]="7"
                    (pageChange)="onPageChange()"
                />
            </div>
        </div>
    </div>
}
