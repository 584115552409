import { HttpHandlerFn, HttpRequest } from "@angular/common/http";
import { inject } from "@angular/core";
import { finalize } from "rxjs";

import { LoaderService } from "../../shared/services";

let requestCounter = 0;

export function loaderInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn) {
  const loaderService = inject(LoaderService);

  if (!req.context.get(LoaderService.useLoaderToken)) {
    return next(req);
  }

  loaderService.start();
  requestCounter++;

  return next(req).pipe(
    finalize(() => {
      requestCounter--;
      if (requestCounter === 0) {
        setTimeout(() => {
          loaderService.end();
        });
      }
    })
  );
}
