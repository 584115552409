import { Component } from "@angular/core";

@Component({
  selector: "adaa-dkpi",
  standalone: true,
  imports: [],
  templateUrl: "./dkpi.component.html",
  styleUrl: "./dkpi.component.scss",
})
export class DkpiComponent {}
