<p class="fw-bold fs-4">{{ "user_profile.wizard.title" | translate }}</p>

<div class="my-2 row">
    <div class="col-12">
        <adaa-form-steps
            [tabs]="tabs()"
            (currentTab)="activeTab.set($event)"
            (submitClicked)="saveInformation()"
            [saveTitle]="'common.form.button.finish'"
        >
            <div class="tab-pane fade" id="tab-0" role="tabpanel" [ngClass]="{ 'show active': activeTab() === 0 }">
                <adaa-profile-personal-information #personalInformation></adaa-profile-personal-information>
            </div>
            <div class="tab-pane fade" id="tab-1" role="tabpanel" [ngClass]="{ 'show active': activeTab() === 1 }">
                <adaa-profile-security #securityInformation></adaa-profile-security>
            </div>
            <div class="tab-pane fade" id="tab-2" role="tabpanel" [ngClass]="{ 'show active': activeTab() === 2 }">
                <adaa-profile-responsibilities></adaa-profile-responsibilities>
            </div>
        </adaa-form-steps>
    </div>
</div>

<adaa-float-action />
