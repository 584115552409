import { Route } from "@angular/router";

import { checkIfLoggedInFn, NotArchivedPlan } from "../../core/guards";
import { Constants } from "../../shared/constants/constants";
import { manageServicesPageGuards, viewServicesPageGuards } from "./_guards";

export const SERVICES_ROUTES: Route[] = [
  {
    path: "services",
    canActivate: [checkIfLoggedInFn, ...viewServicesPageGuards],
    children: [
      {
        path: "list",
        redirectTo: "",
      },
      {
        path: "",
        data: {
          title: "breadcrumbs.services.list",
          breadcrumb: "breadcrumbs.services.list",
        },
        loadComponent: () =>
          import("../services/services-list/services-list.component").then((e) => e.ServicesListComponent),
      },
      {
        path: "main-service",
        data: {
          breadcrumb: { skip: true },
        },
        children: [
          {
            path: "edit/:id",
            canActivate: [...manageServicesPageGuards, NotArchivedPlan()],
            data: {
              title: "breadcrumbs.services.edit_main_service",
              breadcrumb: "breadcrumbs.services.edit_main_service",
              type: Constants.CONSTANT_MAINSERVICE,
            },
            loadComponent: () =>
              import("../services/service-editor/service-editor.component").then((e) => e.ServiceEditorComponent),
          },
          {
            path: ":id",
            data: {
              title: "breadcrumbs.view_main_service",
              breadcrumb: "breadcrumbs.view_main_service",
              type: Constants.CONSTANT_MAINSERVICE,
            },
            loadComponent: () =>
              import("../services/service-view/service-view.component").then((e) => e.ServiceViewComponent),
          },
        ],
      },
      {
        path: "var-service",
        data: {
          breadcrumb: { skip: true },
        },
        children: [
          {
            path: "edit/:id",
            canActivate: [...manageServicesPageGuards, NotArchivedPlan()],
            data: {
              title: "breadcrumbs.services.edit_variation_service",
              breadcrumb: "breadcrumbs.services.edit_variation_service",
              type: Constants.CONSTANT_VARSERVICE,
            },
            loadComponent: () =>
              import("../services/service-editor/service-editor.component").then((e) => e.ServiceEditorComponent),
          },
          {
            path: ":id",
            data: {
              title: "breadcrumbs.view_var_service",
              breadcrumb: "breadcrumbs.view_var_service",
              type: Constants.CONSTANT_VARSERVICE,
            },
            loadComponent: () =>
              import("../services/service-view/service-view.component").then((e) => e.ServiceViewComponent),
          },
        ],
      },
      {
        path: "aux-service",
        data: {
          breadcrumb: { skip: true },
        },
        children: [
          {
            path: "edit/:id",
            canActivate: [...manageServicesPageGuards, NotArchivedPlan()],
            data: {
              title: "breadcrumbs.services.edit_auxiliary_service",
              breadcrumb: "breadcrumbs.services.edit_auxiliary_service",
              type: Constants.CONSTANT_AUXSERVICE,
            },
            loadComponent: () =>
              import("../services/service-editor/service-editor.component").then((e) => e.ServiceEditorComponent),
          },
          {
            path: ":id",
            data: {
              title: "breadcrumbs.view_aux_service",
              breadcrumb: "breadcrumbs.view_aux_service",
              type: Constants.CONSTANT_AUXSERVICE,
            },
            loadComponent: () =>
              import("../services/service-view/service-view.component").then((e) => e.ServiceViewComponent),
          },
        ],
      },
    ],
  },
];
