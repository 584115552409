import { Component, inject, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";

import { AdaaHelper } from "../../../core/utils";
import { DataTableComponent, RelatedObjectsModalComponent } from "../../../shared/components";
import { Constants } from "../../../shared/constants/constants";
import { Language } from "../../../shared/constants/enums";
import { ItemAction, TableButtonClicked } from "../../../shared/models";
import { AppService } from "../../../shared/services";

@Component({
  selector: "adaa-related-objects",
  standalone: true,
  imports: [DataTableComponent],
  templateUrl: "./related-objects.component.html",
  styleUrl: "./related-objects.component.scss",
})
export class RelatedObjectsComponent implements OnInit, OnDestroy {
  private _activatedRoute = inject(ActivatedRoute);
  private _appService = inject(AppService);
  private _modalService = inject(NgbModal);

  private _subscription = new Subscription();
  userId: string;
  hideViewRules: ItemAction[] = [
    {
      operator: "not-equal",
      propertyName: "itemTypeId",
      compareWith: Constants.CONSTANT_PERMISSIONS.CYCLES,
    },
    {
      operator: "not-equal",
      propertyName: "itemTypeId",
      compareWith: Constants.CONSTANT_PERMISSIONS.DELIVERY_MODULE_ACCESS,
    },
    {
      operator: "not-equal",
      propertyName: "itemTypeId",
      compareWith: Constants.CONSTANT_PERMISSIONS.EXPORT_DATA,
    },
    {
      operator: "not-equal",
      propertyName: "itemTypeId",
      compareWith: Constants.CONSTANT_PERMISSIONS.SRVKPI,
    },
    {
      operator: "not-equal",
      propertyName: "itemTypeId",
      compareWith: Constants.CONSTANT_PERMISSIONS.OPM,
    },
    {
      operator: "not-equal",
      propertyName: "itemTypeId",
      compareWith: Constants.CONSTANT_PERMISSIONS.EKPI,
    },
    {
      operator: "not-equal",
      propertyName: "itemTypeId",
      compareWith: Constants.CONSTANT_PERMISSIONS.SKPI,
    },
    {
      operator: "not-equal",
      propertyName: "itemTypeId",
      compareWith: Constants.CONSTANT_PERMISSIONS.DKPI,
    },
    {
      operator: "not-equal",
      propertyName: "itemTypeId",
      compareWith: Constants.CONSTANT_PERMISSIONS.NKPI,
    },
    {
      operator: "not-equal",
      propertyName: "itemTypeId",
      compareWith: Constants.CONSTANT_PERMISSIONS.ENTITY_ADDRESSES,
    },
    {
      operator: "not-equal",
      propertyName: "itemTypeId",
      compareWith: Constants.CONSTANT_PERMISSIONS.ENTITIES,
    },
    {
      operator: "not-equal",
      propertyName: "itemTypeId",
      compareWith: Constants.CONSTANT_PERMISSIONS.USER_GROUPS,
    },
    {
      operator: "not-equal",
      propertyName: "itemTypeId",
      compareWith: Constants.CONSTANT_PERMISSIONS.USER_ROLES,
    },
    {
      operator: "not-equal",
      propertyName: "itemTypeId",
      compareWith: Constants.CONSTANT_PERMISSIONS.USERS,
    },
    {
      operator: "not-equal",
      propertyName: "itemTypeId",
      compareWith: Constants.CONSTANT_PERMISSIONS.DIMENSIONS,
    },

    {
      operator: "not-equal",
      propertyName: "itemTypeId",
      compareWith: Constants.CONSTANT_PERMISSIONS.PILLARS,
    },
    {
      operator: "not-equal",
      propertyName: "itemTypeId",
      compareWith: Constants.CONSTANT_PERMISSIONS.SERVICES_KHADAMATI,
    },
    {
      operator: "not-equal",
      propertyName: "itemTypeId",
      compareWith: Constants.CONSTANT_PERMISSIONS.GOVERNMENT_DIRECTIONS,
    },
    {
      operator: "not-equal",
      propertyName: "itemTypeId",
      compareWith: Constants.CONSTANT_PERMISSIONS.ACTIVITIES,
    },
    {
      operator: "not-equal",
      propertyName: "itemTypeId",
      compareWith: Constants.CONSTANT_PERMISSIONS.INITIATIVES,
    },
    {
      operator: "not-equal",
      propertyName: "itemTypeId",
      compareWith: Constants.CONSTANT_PERMISSIONS.OBJECTIVES,
    },
    {
      operator: "not-equal",
      propertyName: "itemTypeId",
      compareWith: Constants.CONSTANT_PERMISSIONS.EXECUTIVE_TEAMS,
    },
    {
      operator: "not-equal",
      propertyName: "itemTypeId",
      compareWith: Constants.CONSTANT_PERMISSIONS.FORMULAS,
    },
    {
      operator: "not-equal",
      propertyName: "itemTypeId",
      compareWith: Constants.CONSTANT_PERMISSIONS.STRATEGIC_ACTIVITIES,
    },
    {
      operator: "not-equal",
      propertyName: "itemTypeId",
      compareWith: Constants.CONSTANT_PERMISSIONS.DTKPI,
    },
    {
      operator: "not-equal",
      propertyName: "itemTypeId",
      compareWith: Constants.CONSTANT_PERMISSIONS.GOV_SCORE_KPI,
    },
    {
      operator: "not-equal",
      propertyName: "itemTypeId",
      compareWith: Constants.CONSTANT_PERMISSIONS.STRATEGIC_PROJECT,
    },
    {
      operator: "not-equal",
      propertyName: "itemTypeId",
      compareWith: Constants.CONSTANT_PERMISSIONS.MTKPI,
    },
    {
      operator: "not-equal",
      propertyName: "itemTypeId",
      compareWith: Constants.CONSTANT_PERMISSIONS.TRANSFORMATIONAL_TARGETS,
    },
    {
      operator: "not-equal",
      propertyName: "itemTypeId",
      compareWith: Constants.CONSTANT_PERMISSIONS.TP_CYCLES,
    },
    {
      operator: "not-equal",
      propertyName: "itemTypeId",
      compareWith: Constants.CONSTANT_PERMISSIONS.NATIONAL_PROJECTS,
    },
    {
      operator: "not-equal",
      propertyName: "itemTypeId",
      compareWith: Constants.CONSTANT_PERMISSIONS.TEXTMAPPING,
    },
    {
      operator: "not-equal",
      propertyName: "itemTypeId",
      compareWith: Constants.CONSTANT_PERMISSIONS.NATIONAL_PROJECT_MILESTONES,
    },
    {
      operator: "not-equal",
      propertyName: "itemTypeId",
      compareWith: Constants.CONSTANT_PERMISSIONS.OTHER_ENTITIES,
    },
    {
      operator: "not-equal",
      propertyName: "itemTypeId",
      compareWith: Constants.CONSTANT_PERMISSIONS.ORG_UNIT,
    },
    {
      operator: "not-equal",
      propertyName: "itemTypeId",
      compareWith: Constants.CONSTANT_PERMISSIONS.METRICS,
    },
    {
      operator: "not-equal",
      propertyName: "itemTypeId",
      compareWith: Constants.CONSTANT_PERMISSIONS.ANNUAL_MEETINGS_PROJECTS,
    },
    {
      operator: "not-equal",
      propertyName: "itemTypeId",
      compareWith: Constants.CONSTANT_PERMISSIONS.ANNUAL_MEETINGS_PROJECTS_CYCLES,
    },
    {
      operator: "not-equal",
      propertyName: "itemTypeId",
      compareWith: Constants.CONSTANT_PERMISSIONS.ANNUAL_MEETINGS_PROJECTS_MILESTONES,
    },
    {
      operator: "not-equal",
      propertyName: "itemTypeId",
      compareWith: Constants.CONSTANT_PERMISSIONS.MOKPI,
    },
    {
      operator: "not-equal",
      propertyName: "itemTypeId",
      compareWith: Constants.CONSTANT_PERMISSIONS.TRANSFORMATIONAL_PROJECT_AGREEMENT,
    },
    {
      operator: "not-equal",
      propertyName: "itemTypeId",
      compareWith: Constants.CONSTANT_PERMISSIONS.NATIONAL_PROJECTS,
    },
    {
      operator: "not-equal",
      propertyName: "itemTypeId",
      compareWith: Constants.CONSTANT_PERMISSIONS.MAIN_OBJECTIVE,
    },
    {
      operator: "not-equal",
      propertyName: "itemTypeId",
      compareWith: Constants.CONSTANT_PERMISSIONS.NTKPI,
    },
  ];

  public ngOnInit(): void {
    this._subscription = this._activatedRoute.params.subscribe((params) => {
      if (AdaaHelper.isDefined(params["id"])) {
        this.userId = params["id"];
      }
    });
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  public actionClicked(action: TableButtonClicked): void {
    if (action.event === "view") {
      const modal = this._modalService.open(RelatedObjectsModalComponent, {
        centered: true,
        size: "xl",
        modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
      });
      modal.componentInstance.itemTypeId = action.data.itemTypeId;
      modal.componentInstance.entityId = action.data.entityId;
      modal.componentInstance.orgUnitId = action.data.orgUnitId;
    }
  }
}
