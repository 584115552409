import { Component, computed, effect, inject, input, signal, untracked } from "@angular/core";
import { TranslatePipe } from "@ngx-translate/core";
import { map } from "rxjs";

import { AdaaHelper } from "../../../../../core/utils";
import { ProgressIndicatorComponent } from "../../../../../shared/components";
import { Constants } from "../../../../../shared/constants/constants";
import {
  ItemTableValueModel,
  KpiAuditInfoType,
  KpiTypeConstantType,
  ObjectTypeAnnualPerformanceType,
  PeriodModelType,
} from "../../../../../shared/models";
import { CalculationEngineApiService } from "../../../../../shared/services";
import { isDtkpi, isMokpi, isMtkpi, isNkpi, isNtkpi } from "../../../kpi-utils";

@Component({
  selector: "adaa-kpi-progress",
  standalone: true,
  imports: [TranslatePipe, ProgressIndicatorComponent],
  host: {
    class: "w-100 d-block",
  },
  template: `
    <div class="w-100 d-flex align-content-center flex-wrap kpi-details--item">
      <span class="d-block w-100 fw-bold px-2 mb-3">
        {{ label() | translate }}: {{ visionTarget() }} &nbsp;&nbsp;

        @if (scoreInfo(); as info) {
          ({{ "nkpi.benchYear" | translate }}: {{ info.year }})
        }
      </span>

      @let data = kpiScore();
      @if (data) {
        <span class="d-block w-100 px-2 text-primary text-end">
          <adaa-progress-indicator
            class="w-100"
            [value]="data.score"
            [color]="Constants.CONSTANT_COLORS.EVALUATION_BLUE"
          />
        </span>
      }
    </div>
  `,
})
export class KpiProgressComponent {
  private readonly _calculationEngineApiService = inject(CalculationEngineApiService);

  period = input.required<PeriodModelType>();
  kpiId = input.required<number>();
  kpiType = input.required<number>();
  visionTarget = input<number>();
  kpiHasAuditIssues = input.required<boolean>();
  auditInfo = input<KpiAuditInfoType[]>([]);
  tableValues = input<ItemTableValueModel[]>([]);

  Constants = Constants;
  scoreInfo = signal<(ItemTableValueModel & { auditedActual?: number | "N/A" }) | undefined>(undefined);
  kpiScore = signal<ObjectTypeAnnualPerformanceType | undefined>(undefined);

  label = computed(() => {
    const type = this.kpiType();
    if (isDtkpi(type)) return "kpi.dkpi_target";
    if (isNtkpi(type) || isNkpi(type)) return "nkpi.vision_target";
    if (isMtkpi(type) || isMokpi(type)) return "national_projects.target_achievement";
    return "uae.direction_target";
  });
  visionPerfType = computed(() => {
    const type = this.kpiType();
    const item = Object.entries(Constants.KPI_TYPE).find(([_, v]) => v === type);

    if (!item) return;
    const k = item[0] as KpiTypeConstantType;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return Constants.VISION_PERFORMANCE[k];
  });

  constructor() {
    effect(() => {
      const itemId = this.kpiId();
      const period = this.period();
      const objectType = this.visionPerfType();
      const tableValues = this.tableValues() || [];
      if (!objectType) return;

      untracked(() => {
        const auditInfo = this.auditInfo() || [];
        const values = tableValues.map<ItemTableValueModel & { auditedActual?: number | "N/A" }>((value) => {
          const info = auditInfo.find(({ year }) => year === value.year);
          return {
            ...value,
            auditedActual: info ? info.auditedActual : undefined,
          };
        });

        const isMoOrMtKpi = isMtkpi(this.kpiType()) || isMokpi(this.kpiType());

        for (const value of values) {
          if (value.year !== period.year) continue;
          this.scoreInfo.set(value);
        }

        let periodId = isMoOrMtKpi ? period.year : period.id;
        let scoreInfo = this.scoreInfo();
        if (scoreInfo) {
          periodId = isMoOrMtKpi ? scoreInfo.year : scoreInfo.periodId;
        }
        if (!AdaaHelper.isDefined(scoreInfo?.actual)) {
          //Reverse the array to get the latest value
          const index = values
            .slice()
            .reverse()
            .find(({ actual }) => AdaaHelper.isDefined(actual));
          if (index) {
            this.scoreInfo.set(index);
            scoreInfo = { ...this.scoreInfo()! };
            periodId = isMoOrMtKpi ? scoreInfo.year : scoreInfo.periodId;
          }
        }

        this._getKpiScore({
          itemId,
          objectType,
          period: periodId,
        });
      });
    });
  }

  private _getKpiScore(input: { objectType: number; period: number; itemId: number }) {
    let obs$ = this._calculationEngineApiService.getCalculationReading({
      itemId: input.itemId,
      objectType: input.objectType,
      periodId: input.period,
    });

    if (isMtkpi(this.kpiType()) || isMokpi(this.kpiType())) {
      obs$ = this._calculationEngineApiService.getCalculationReadingAnnual({
        itemId: input.itemId,
        objectType: input.objectType,
        year: input.period,
      });
    }

    return obs$.pipe(map((res) => res.responseData)).subscribe({
      next: (data) => {
        this.kpiScore.set(data);
      },
    });
  }
}
