import { NgClass } from "@angular/common";
import { Component, inject, input, OnInit, signal } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import {
  ConfirmationModalComponent,
  DataTableComponent,
  FormActionButtonsComponent,
  FormCheckboxComponent,
  FormInputComponent,
} from "../../../shared/components";
import { UsersListSelectionComponent } from "../../../shared/components/modals/users-list-selection/users-list-selection.component";
import { Constants } from "../../../shared/constants/constants";
import { AdaaBoolean, Language, PageMode } from "../../../shared/constants/enums";
import { TableButtonClicked, UserGroup, UserProfile } from "../../../shared/models";
import { AppService, UserGroupsApiService } from "../../../shared/services";
import { UserGroupsEditorService } from "../../../shared/services/user-groups-editor.service";

@Component({
  selector: "adaa-user-groups-editor",
  standalone: true,
  imports: [
    TranslateModule,
    ReactiveFormsModule,
    FormInputComponent,
    FormCheckboxComponent,
    FormActionButtonsComponent,
    DataTableComponent,
    UsersListSelectionComponent,
    NgClass,
  ],
  templateUrl: "./user-groups-editor.component.html",
  styleUrl: "./user-groups-editor.component.scss",
})
export class UserGroupsEditorComponent implements OnInit {
  private _formBuilder = inject(FormBuilder);
  private _userGroupsApiService = inject(UserGroupsApiService);
  private _modalService = inject(NgbModal);
  private _router = inject(Router);
  private _toastrService = inject(ToastrService);
  private _translateService = inject(TranslateService);
  private _activatedRoute = inject(ActivatedRoute);
  userGroupsEditorService = inject(UserGroupsEditorService);
  appService = inject(AppService);

  userGroupsForm: FormGroup = new FormGroup({});
  pageMode = input.required<string>();
  PageMode = PageMode;
  users = signal<UserProfile[]>([]);
  groupId: string | null;
  group = signal<UserGroup>({
    usersList: [],
    userCount: 0,
    wfConfigCount: 0,
    typeNameAE: "",
    typeNameEN: "",
    id: 0,
    nameAE: "",
    nameEN: "",
    status: 0,
    enabled: AdaaBoolean.N,
    updateUser: "",
    typeId: 0,
    entityId: 0,
  });
  submitted = signal<boolean>(false);
  Constants = Constants;

  public ngOnInit(): void {
    this.userGroupsEditorService.formValidator();
    this._prepareForm();
    this.groupId = this._activatedRoute.snapshot?.paramMap?.get("id");
    this._getGroup();
  }

  private _prepareForm() {
    const isViewMode = this.pageMode() === PageMode.view;
    this.userGroupsForm = this._formBuilder.group({
      nameAE: this._formBuilder.control<string | undefined>(
        { value: "", disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      nameEN: this._formBuilder.control<string | undefined>(
        { value: "", disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      enabled: this._formBuilder.control<string | undefined>({
        value: this.pageMode() === PageMode.create ? AdaaBoolean.Y : "",
        disabled: isViewMode,
      }),
    });
  }

  private _getGroup() {
    if (this.groupId)
      this._userGroupsApiService.getGroupById(+this.groupId).subscribe({
        next: (response) => {
          this.userGroupsForm.patchValue(response.responseData);
          this.userGroupsForm.markAsPristine();
          this.userGroupsForm.markAsUntouched();
          this.group.set(response.responseData);
          this.users.set(response.responseData.usersList);
        },
      });
  }

  public addNewClicked() {
    const modal = this._modalService.open(UsersListSelectionComponent, {
      centered: true,
      size: "md",
      modalDialogClass: this.appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });

    modal.componentInstance.header = "common.form.modals_leave.cancel_yes_no_title";
    modal.componentInstance.title = "common.form.modals_leave.cancel_yes_no_information";
  }

  public getAction(value: TableButtonClicked) {
    switch (value.event) {
      case "view": {
        const newUrl = this._router.serializeUrl(this._router.createUrlTree([`/console/users/${value.data.id}`]));
        window.open(newUrl, "_blank");
        break;
      }
      case "delete": {
        const index = this.users().findIndex((user) => user.id === value.data.id);
        this.users().splice(index, 1);
        this.users.update((value) => {
          return [...value];
        });
        break;
      }

      default:
        break;
    }
  }

  public saveUsers(event: UserProfile[]) {
    this.users.update(() => {
      return [...event];
    });
  }

  public submit() {
    this.submitted.set(true);
    if (!this.users().length || this.userGroupsForm.invalid) {
      this._toastrService.error(this._translateService.instant("common.form.label.missing_information"));
      return;
    }
    if (this.pageMode() === PageMode.edit) {
      const result = {
        ...this.group(),
        ...this.userGroupsForm.value,
        usersList: [...this.users()],
      };
      this._userGroupsApiService.updateGroup(result).subscribe({
        next: () => {
          this._toastrService.success(this._translateService.instant("notification.success.save"));
          this._router.navigateByUrl("/console/users-groups");
        },
      });
    } else {
      const result = {
        ...this.userGroupsForm.value,
        status: Constants.OBJECT_STATUS.ACTIVE,
        usersList: this.users(),
      };
      this._userGroupsApiService.createUserGroup(result).subscribe({
        next: (response) => {
          if (response.inError) return;
          this._toastrService.success(this._translateService.instant("notification.success.save"));
          this._router.navigateByUrl("/console/users-groups");
        },
      });
    }
  }

  public close() {
    if (this.userGroupsForm.dirty) {
      const modal = this._modalService.open(ConfirmationModalComponent, {
        centered: true,
        size: "md",
        modalDialogClass: this.appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
      });

      modal.componentInstance.header = "common.form.modals_leave.cancel_yes_no_title";
      modal.componentInstance.title = "common.form.modals_leave.cancel_yes_no_information";

      modal.result.then((e) => {
        if (e) this._router.navigateByUrl("/console/users-groups");
      });
    } else this._router.navigateByUrl("/console/users-groups");
  }

  public checkInvalid(control: string) {
    return (
      this.userGroupsForm.controls[control].errors?.["required"] &&
      (this.userGroupsForm.get(control)?.dirty || this.submitted())
    );
  }
}
