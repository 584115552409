import { Component, inject, signal } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";

import { DataTableComponent } from "../../../../../shared/components";
import { Constants } from "../../../../../shared/constants/constants";
import { TableSelectedRow } from "../../../../../shared/models";

@Component({
  selector: "adaa-formula-selector-modal",
  standalone: true,
  imports: [TranslateModule, DataTableComponent],
  template: `
    <div class="modal-header">
      <h4 class="modal-title fw-bold">{{ "kpi.formula_modal_title" | translate }}</h4>
      <button
        type="button"
        class="btn-close"
        aria-label="Close button"
        aria-describedby="modal-title"
        (click)="modal.close()"
      ></button>
    </div>

    <div class="modal-body px-3 py-2">
      <adaa-data-table
        [configKey]="tableConfList"
        [disableTableActions]="true"
        [enableSelection]="true"
        [enableActionsCell]="false"
        [localData]="tableData()"
        [isLocalData]="true"
        [rowSelected]="selectedRow()?.id"
        (getRowData)="selectRow($event)"
      />
    </div>

    <div class="modal-footer d-flex justify-content-center align-items-center">
      <button type="button" class="btn btn-primary px-3" (click)="modal.close()">
        {{ "common.form.button.cancel" | translate }}
      </button>
    </div>
  `,
})
export class FormulaSelectorModalComponent {
  readonly modal: NgbActiveModal = inject(NgbActiveModal);

  selectedRow = signal<{ id: number } | undefined>(undefined);
  tableData = signal<Record<string, unknown>[]>([]);

  readonly tableConfList = Constants.MAIN_TABLE_LIST_CONF_KEY.FORMULA_HELPER_KPI_FORM;

  readonly selectRow = ($e: TableSelectedRow) => {
    this.modal.close($e.item);
  };
}
