import { Component, computed, inject, input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

import { TranslateTokenPipe } from "../../../../core/pipes";
import { PictureComponent, ProgressIndicatorComponent } from "../../../../shared/components";
import { Constants } from "../../../../shared/constants/constants";
import type { NationalAgendaEntityModelType } from "../../../../shared/models";
import { LanguageService } from "../../../../shared/services";

@Component({
  selector: "adaa-wtuae-entity-card",
  standalone: true,
  templateUrl: "./wtuae-entity-card.component.html",
  styleUrl: "./wtuae-entity-card.component.scss",
  imports: [PictureComponent, TranslateTokenPipe, ProgressIndicatorComponent, TranslateModule],
})
export class WtuaeEntityCardComponent {
  private readonly _languageService = inject(LanguageService);

  data = input.required<NationalAgendaEntityModelType>();

  isRTL = computed(() => this._languageService.direction() === "rtl");
  borderRightColor = computed(
    () => Constants.COLOR_CODES[this.data().annualVisionScoreColor ?? Constants.CONSTANT_COLORS.EVALUATION_GRAY]
  );
}
