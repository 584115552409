import type { Route } from "@angular/router";

import { checkIfLoggedInFn, pmoEntity } from "../../core/guards";
import { PageMode } from "../../shared/constants/enums";
import {
  createNationalStrategiesPageGuards,
  manageNationalStrategiesPageGuards,
  viewNationalStrategiesPageGuards,
} from "./_guards";
import { MainDashboardComponent, ObjectiveDashboardComponent, ViewDashboardComponent } from "./dashboards";
import { NationalStrategiesListComponent } from "./national-strategies-list/national-strategies-list.component";
import { NationalStrategyEditorComponent } from "./national-strategy-editor/national-strategy-editor.component";

export const NATIONAL_STRATEGIES_DASHBOARD_ROUTES: Route[] = [
  {
    path: "national-strategies-dashboard",
    component: MainDashboardComponent,
    canActivate: [checkIfLoggedInFn],
    data: {
      title: "national_strategies.strategies",
      breadcrumb: "national_strategies.strategies",
    },
  },
  {
    path: "national-strategies-dashboard/strategy/:id",
    component: ViewDashboardComponent,
    canActivate: [checkIfLoggedInFn],
    data: {
      title: "national_strategies.view_strategy",
      breadcrumb: "national_strategies.view_strategy",
    },
  },
  {
    path: "national-strategies-dashboard/strategy/:strategyId/objective/:objectiveId",
    component: ObjectiveDashboardComponent,
    canActivate: [checkIfLoggedInFn],
    data: {
      title: "national_strategies.enabler_objectives.view_eo",
      breadcrumb: "national_strategies.enabler_objectives.view_eo",
    },
  },
];

export const NATIONAL_STRATEGIES_PAGES_ROUTES: Route[] = [
  {
    path: "national-strategies",
    canActivate: [checkIfLoggedInFn, pmoEntity, ...viewNationalStrategiesPageGuards],
    children: [
      {
        path: "",
        component: NationalStrategiesListComponent,
        data: {
          title: "national_strategies.strategies",
          breadcrumb: "national_strategies.strategies",
        },
      },
      {
        path: "create",
        component: NationalStrategyEditorComponent,
        canActivate: [...createNationalStrategiesPageGuards],
        data: {
          title: "national_strategies.create_strategy",
          breadcrumb: "national_strategies.create_strategy",
          pageMode: PageMode.create,
        },
      },
      {
        path: "edit/:id",
        component: NationalStrategyEditorComponent,
        canActivate: [...manageNationalStrategiesPageGuards],
        data: {
          title: "national_strategies.edit_strategy",
          breadcrumb: "national_strategies.edit_strategy",
          pageMode: PageMode.edit,
        },
      },
      {
        path: ":id",
        component: NationalStrategyEditorComponent,
        data: {
          title: "national_strategies.view_strategy",
          breadcrumb: "national_strategies.view_strategy",
          pageMode: PageMode.view,
        },
      },
    ],
  },
];
