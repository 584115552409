<div class="row" [formGroup]="form">
    <div class="col-md-6 col-xs-12">
        <adaa-form-dropdown
            controlName="parentMetricEntityId"
            formControlName="parentMetricEntityId"
            [clearable]="true"
            [searchable]="true"
            [label]="'common.form.label.entity' | translate"
            [setValidator]="formValidation()!"
            [options]="entitiesMetrics()"
            [invalid]="submitted() && form.get('parentMetricEntityId')?.invalid!"
            (inputChanges)="parentMetricEntityChanged($event, true)"
        />
    </div>
    <div class="col-md-6 col-xs-12">
        <adaa-form-dropdown
            controlName="parentMetricId"
            formControlName="parentMetricId"
            [clearable]="true"
            [searchable]="true"
            [label]="'metrics.parent_metric' | translate"
            [setValidator]="formValidation()!"
            [options]="parentMetrics()"
            [invalid]="submitted() && form.get('parentMetricId')?.invalid!"
            (inputChanges)="parentMetricDdlChanged($event, true)"
        />
    </div>
    <div class="col-12">
        <adaa-form-dropdown-multi
            controlName="dimensionsTypes"
            formControlName="dimensionsTypes"
            [clearable]="true"
            [searchable]="true"
            [label]="'metrics.dimensions_types' | translate"
            [setValidator]="formValidation()!"
            [options]="dimensionsTypes()"
            [invalid]="submitted() && form.get('dimensionsTypes')?.invalid!"
            (inputChanges)="dimensionsTypesChanged($event)"
        />
    </div>
    @for (item of form.get("dimensions")?.value; track item) {
        <div class="col-12">
            <adaa-form-dropdown-multi
                [setDefaultValue]="item.dimensionValueIds"
                [clearable]="true"
                [searchable]="true"
                [label]="item | translateToken: 'name'"
                [setValidator]="formValidation()!"
                [options]="item.options"
                (inputChanges)="dimensionsValuesChanged($event, item)"
            />
        </div>
    }
</div>
