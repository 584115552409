import { DatePipe } from "@angular/common";
import { Component, effect, inject, input, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { filter, map } from "rxjs";

import { DubaiTimePipe } from "../../../../../core/pipes";
import { AttachmentModelType } from "../../../../../shared/models";
import { AttachmentsApiService, FilesApiService } from "../../../../../shared/services";

@Component({
  selector: "adaa-attachments",
  standalone: true,
  imports: [DatePipe, DubaiTimePipe, TranslateModule],
  styleUrl: "../styles.scss",
  template: `
    <div class="w-100 d-flex justify-content-between flex-wrap position-relative mt-2 py-2 px-1 shadow-sm attachments">
      <div class="w-100 d-flex align-content-center attachments--item">
        <span class="d-block w-50 fw-bold px-2">
          {{ "sidebar_tables.attachments" | translate }} ({{ attachments().length }})
        </span>
      </div>

      @for (item of attachments(); track item.id) {
        <div
          class="card w-100 bg-white border-1 rounded-1 mb-3"
          (click)="filesApiService.save(item.id, item.filename, true)"
        >
          <div class="card-body">
            <a class="link-info" href="javascript:;">
              {{ item.filename }}
            </a>
          </div>

          <div class="card-footer text-muted">
            {{ item.attachDate | dubaiTime | date: "dd/MM/yyyy hh:mm aa" }}
          </div>
        </div>
      }
    </div>
  `,
})
export class AttachmentsComponent {
  readonly filesApiService = inject(FilesApiService);
  private readonly _attachmentsApiService = inject(AttachmentsApiService);

  kpiId = input.required<number>();
  kpiType = input.required<number>();
  periodId = input<number>();

  attachments = signal<AttachmentModelType[]>([]);

  readonly #fetchAttachments = () =>
    this._attachmentsApiService
      .getByItemIdItemType({
        id: this.kpiId(),
        type: this.kpiType(),
        periodId: this.periodId(),
      })
      .pipe(
        filter((res) => !res.inError),
        map((res) => res.responseData)
      );

  constructor() {
    effect(() => {
      this.#fetchAttachments().subscribe({
        next: (data) => this.attachments.set(data ?? []),
      });
    });
  }
}
