<div class="p-2">
    <adaa-data-table
        #adaaDataTable
        [apiName]="'getAllV2'"
        [apiPrefix]="'libmetric'"
        [paramsString]="'itemType=83'"
        [titleHeader]="breadcrumb() | translate"
        [configKey]="Constants.MAIN_TABLE_LIST_CONF_KEY.METRICS"
        [itemTypeId]="Constants.CONSTANT_PERMISSIONS.METRICS"
        [disableEditRules]="getRules('edit')"
        [disableDeleteRules]="getRules('delete')"
        (actionClicked)="getAction($event)"
        (addNewClicked)="addNew()"
    ></adaa-data-table>
</div>

<adaa-metric-unlink-information #unlink [data]="relatedKpis()"></adaa-metric-unlink-information>
<adaa-float-action />
