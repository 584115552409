import { Component, inject, OnInit } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

import { AdaaHelper } from "../../../../core/utils";
import { InfoBarComponent, PeriodSliderComponent, SdgCircleComponent } from "../../../../shared/components";
import { Language } from "../../../../shared/constants/enums";
import { SelectedPeriodModelType } from "../../../../shared/models";
import { LanguageService, SdgDashboardService, SystemLayoutService } from "../../../../shared/services";

@Component({
  selector: "adaa-sdg-dashboard",
  standalone: true,
  imports: [PeriodSliderComponent, SdgCircleComponent, TranslateModule, InfoBarComponent],
  templateUrl: "./sdg-dashboard.component.html",
  styleUrl: "./sdg-dashboard.component.scss",
})
export class SdgDashboardComponent implements OnInit {
  private _systemLayoutService = inject(SystemLayoutService);

  sdgDashboardService = inject(SdgDashboardService);
  languageService = inject(LanguageService);

  private _untilDestroyed = AdaaHelper.untilDestroyed();

  Language = Language;

  public ngOnInit(): void {
    this._systemLayoutService.hasActiveEntityChanged$
      .pipe(this._untilDestroyed())
      .subscribe(() => this.sdgDashboardService.getSdg(this.sdgDashboardService.selectedPeriod()));

    this._systemLayoutService.hasCycleChanged$.pipe(this._untilDestroyed()).subscribe(() => window.location.reload());
  }

  public selectPeriod(data: SelectedPeriodModelType): void {
    if (!data) {
      return;
    }
    this.sdgDashboardService.selectedPeriod.set(data);
    this.sdgDashboardService.getSdg(data);
  }
}
