import { CommonModule } from "@angular/common";
import { Component, computed, inject, input, OnInit } from "@angular/core";
import { Router, RouterModule } from "@angular/router";
import { FaIconLibrary, FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { TranslateModule } from "@ngx-translate/core";

import { CurrencyValuePipe, TranslateTokenPipe } from "../../../../core/pipes";
import { AdaaHelper } from "../../../../core/utils";
import {
  CommentsComponent,
  DataTableComponent,
  DoughnutComponent,
  EntityPlanChangeDetectionComponent,
  PictureComponent,
  ProgressIndicatorComponent,
  ReadMoreComponent,
} from "../../../../shared/components";
import { FloatActionComponent } from "../../../../shared/components/general/float-action/float-action.component";
import { Constants } from "../../../../shared/constants/constants";
import { CustomButton, TableButtonClicked } from "../../../../shared/models";
import { MilestoneDetailsService, SystemLayoutService } from "../../../../shared/services";

@Component({
  selector: "adaa-milestone-view",
  standalone: true,
  imports: [
    RouterModule,
    TranslateModule,
    CommonModule,
    FontAwesomeModule,
    TranslateTokenPipe,
    CurrencyValuePipe,
    DoughnutComponent,
    ProgressIndicatorComponent,
    PictureComponent,
    ReadMoreComponent,
    DataTableComponent,
    CommentsComponent,
    FloatActionComponent,
    EntityPlanChangeDetectionComponent,
  ],
  templateUrl: "./milestone-view.component.html",
  styleUrl: "./milestone-view.component.scss",
})
export class MilestoneViewComponent implements OnInit {
  private _iconLibrary: FaIconLibrary = inject(FaIconLibrary);
  private _router = inject(Router);
  private _systemLayoutService = inject(SystemLayoutService);
  milestoneDetailsService = inject(MilestoneDetailsService);

  id = input.required<string>();

  private readonly _untilDestroyed = AdaaHelper.untilDestroyed();
  AdaaHelper = AdaaHelper;
  constants = Constants;
  paramsStringMokpi = computed(() => `projectMilestoneId=${this.milestoneDetailsService.milestone()?.id}`);
  customActionButtons: CustomButton[] = [
    {
      iconName: "adaa-icon-user_groups",
      eventName: "teamMembers",
      iconTitle: "national_projects.national_projects_milestone.sub_milestone_team_members",
    },
  ];

  public get contributingEntities() {
    if (!AdaaHelper.isDefined(this.milestoneDetailsService.milestone())) return [];
    const list = this.milestoneDetailsService.milestone()!.contributingEntities || [];
    return list.filter((e) => !AdaaHelper.isDefined(e.otherEntityId));
  }

  public get otherContributingEntities() {
    if (!AdaaHelper.isDefined(this.milestoneDetailsService.milestone())) return [];
    const list = this.milestoneDetailsService.milestone()!.contributingEntities || [];
    return list.filter((e) => AdaaHelper.isDefined(e.entityId) && AdaaHelper.isDefined(e.otherEntityId));
  }

  public ngOnInit(): void {
    this._iconLibrary.addIcons(faFileDownload);

    this._systemLayoutService.hasCycleChanged$.pipe(this._untilDestroyed()).subscribe({
      next: () => this._router.navigateByUrl("console/national-project/milestones"),
    });
    this._systemLayoutService.hasActiveEntityChanged$.pipe(this._untilDestroyed()).subscribe({
      next: () => this._router.navigateByUrl("console/national-project/milestones"),
    });
  }

  public actionClicked(event: TableButtonClicked): void {
    if (event.event === "view") {
      this._router.navigateByUrl(`/console/kpi/mtkpi/${event.data.id}`);
    }
  }
}
