@let onCloseResult = { metrics: stagedKpi()?.metrics ?? [], formulaStatusHasChanged: true, revalidate: false };

<div class="modal-header">
    <h4 class="modal-title w-100 text-center">
        <span class="fw-bold">
            @if (showMetricsList()) {
                {{ "metrics.metrics_selections" | translate }}
            } @else {
                {{ "kpi.validate_formula" | translate }}
            }
        </span>
    </h4>
    <button type="button" class="btn-close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.close(onCloseResult)"></button>
</div>

<div class="modal-body px-3 py-2">
    @if (!showMetricsList()) {
        <form #form="ngForm" class="metrics-form">
            <div class="row d-flex align-content-center justify-content-between align-items-center">
                <div class="fs-6 fw-bold text-center text-primary" [class.col-3]="!hideYtpCalc()" [class.col-4]="hideYtpCalc()">
                    {{ "metrics.label_name" | translate }}
                </div>

                <div class="fs-6 fw-bold text-center text-primary" [class.col-3]="!hideYtpCalc()" [class.col-4]="hideYtpCalc()">
                    {{ "helpdesk.type" | translate }}
                </div>

                <div class="fs-6 fw-bold text-center text-primary" [class.col-3]="!hideYtpCalc()" [class.col-4]="hideYtpCalc()">
                    {{ "metrics.label_description" | translate }}
                </div>

                @if (!hideYtpCalc()) {
                    <div class="col-3 fw-bold text-center text-primary">
                        {{ "kpi.year_to_period" | translate }}
                    </div>
                }
            </div>
            @for (metric of metrics(); track $index) {
                <div class="row d-flex align-content-center justify-content-between align-items-center">
                    <div class="text-center" [class.col-3]="!hideYtpCalc()" [class.col-4]="hideYtpCalc()">
                        {{ metric.nameAE }}
                        @switch (metric.metricType) {
                            @case ("G") {
                                <span class="text-primary fw-bold metric-type">[{{ "metrics.global_var" | translate }}]</span>
                            }
                            @case ("L") {
                                <span class="text-primary fw-bold metric-type">[{{ "metrics.local_var" | translate }}]</span>
                            }

                            @case ("M") {
                                <span class="text-warning fw-bold metric-type">[{{ "main_types.M" | translate }}]</span>
                            }

                            @default {
                                <span class="text-info fw-bold metric-type">[{{ "metrics.custom_var" | translate }}]</span>
                            }
                        }
                    </div>

                    <div [class.col-3]="!hideYtpCalc()" [class.col-4]="hideYtpCalc()">
                        <adaa-form-dropdown
                            [options]="metricTypeOptions()"
                            [clearable]="false"
                            [setDefaultValue]="metric.metricType === 'G' ? 'G/L' : metric.metricType === 'L' ? 'G/L' : metric.metricType"
                            (inputChanges)="handleChange('metricType', $event, $index)"
                        />
                    </div>

                    <div [class.col-3]="!hideYtpCalc()" [class.col-4]="hideYtpCalc()">
                        <adaa-form-input
                            #metricDescription
                            [setDefaultValue]="metric.dscAE"
                            [isDisabled]="metric.metricType !== 'C'"
                            (inputChanges)="handleChange('dscAE', $event, $index)"
                        />
                    </div>

                    @if (!hideYtpCalc()) {
                        <div class="col-3">
                            <adaa-form-dropdown
                                [options]="ytpCalcOptions()"
                                [clearable]="false"
                                [setDefaultValue]="metric.ytpCalc"
                                (inputChanges)="handleChange('ytpCalc', $event, $index)"
                            />
                        </div>
                    }
                </div>
            }
        </form>
    } @else {
        @let conf = metricTableConf();

        <adaa-data-table
            [configKey]="conf.key"
            [apiName]="conf.apiName"
            [enableAdd]="false"
            [enableExport]="false"
            [enableSelection]="true"
            [enableActionsCell]="false"
            (getRowData)="handleMetricSelection($event['item'], selectedMetric()!)"
        />
    }
</div>

<div class="modal-footer">
    <div class="d-flex w-100 align-items-center justify-content-center align-content-center">
        <button class="btn btn-primary px-4 mx-2 text-white" (click)="showMetricsList() ? showMetricsList.set(false) : modal.close(onCloseResult)">
            {{ "common.form.button.close" | translate }}
        </button>

        @if (!showMetricsList()) {
            @let result = { metrics: metrics(), formulaStatusHasChanged: true, revalidate: true, clearMetrics: clearMetrics() };

            <button class="btn btn-primary px-4 mx-2" (click)="saveMetricDetails(result)">
                {{ "common.form.button.save" | translate }}
            </button>
        }
    </div>
</div>
