import { Component, computed, inject, input, OnDestroy, OnInit, signal } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";

import { AdaaHelper } from "../../../core/utils";
import {
  ConfirmationModalComponent,
  FormActionButtonsComponent,
  FormCheckboxComponent,
  FormInputComponent,
  FormTwoWaySelectComponent,
} from "../../../shared/components";
import { Constants } from "../../../shared/constants/constants";
import { AdaaBoolean, Language, PageMode } from "../../../shared/constants/enums";
import { AlphabetOnlyDirective, UppercaseDirective } from "../../../shared/directives";
import { EntityModelType, ValueText } from "../../../shared/models";
import { PillarEntity } from "../../../shared/models/pillar.model";
import { AppService, EntitiesApiService, LanguageService, PillarsApiService } from "../../../shared/services";
import { PillarEditorService } from "../../../shared/services/pillar-editor.service";

@Component({
  selector: "adaa-pillar-editor",
  standalone: true,
  imports: [
    RouterModule,
    ReactiveFormsModule,
    TranslateModule,
    FormInputComponent,
    FormTwoWaySelectComponent,
    FormCheckboxComponent,
    FormActionButtonsComponent,
    UppercaseDirective,
    AlphabetOnlyDirective,
  ],
  templateUrl: "./pillar-editor.component.html",
  styleUrl: "./pillar-editor.component.scss",
})
export class PillarEditorComponent implements OnInit, OnDestroy {
  private _formBuilder = inject(FormBuilder);
  private _pillarsApiService = inject(PillarsApiService);
  private _modalService = inject(NgbModal);
  private _appService = inject(AppService);
  private _activatedRoute = inject(ActivatedRoute);
  private _entitiesApiService = inject(EntitiesApiService);
  private readonly _languageService = inject(LanguageService);
  private _translateService = inject(TranslateService);
  private _toastrService = inject(ToastrService);
  private _router = inject(Router);
  pillarEditorService = inject(PillarEditorService);

  pillarForm: FormGroup = new FormGroup({});
  pageMode = input.required<string>();
  adaaHelper = AdaaHelper;
  _subscription = new Subscription();

  PageMode = PageMode;
  pillarId: string | null;
  displayLabel = signal<string>("");
  setDisabled = signal<boolean>(false);
  enablerEntities = signal<EntityModelType[]>([]);
  selectedEntities = signal<PillarEntity[]>([]);
  submitted = signal<boolean>(false);

  enablerEntityOptions = computed(() =>
    this.enablerEntities().map((entity) => {
      return {
        text: AdaaHelper.getItemValueByToken(entity, "name"),
        value: entity.id,
      };
    })
  );

  public ngOnInit(): void {
    this.setDisabled.set(this.pageMode() === PageMode.view);
    this.pillarId = this._activatedRoute.snapshot?.paramMap?.get("id");

    this.pillarEditorService.formValidator();
    this._getEnablerEntities();
    this._prepareForm();
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  private _prepareForm() {
    const isViewMode = this.pageMode() === PageMode.view;

    this.pillarForm = this._formBuilder.group({
      refCode: this._formBuilder.control<string | undefined>(
        { value: "", disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      nameAE: this._formBuilder.control<string | undefined>(
        { value: "", disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      nameEN: this._formBuilder.control<string | undefined>(
        { value: "", disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      dscAE: this._formBuilder.control<string | undefined>(
        { value: "", disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      dscEN: this._formBuilder.control<string | undefined>(
        { value: "", disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      available: this._formBuilder.control<string | undefined>({ value: "", disabled: isViewMode }),
      pillarEntities: this._formBuilder.control<ValueText[] | undefined>({ value: [], disabled: isViewMode }),
    });

    this._subscription.add(
      this.pillarForm.valueChanges.subscribe((value) => {
        const displayLanguage = AdaaHelper.getFieldLanguage("name");
        if (!value[displayLanguage].length) {
          this.displayLabel.set(value["nameAE"] || value["nameEN"]);
        } else this.displayLabel.set(value[displayLanguage]);
      })
    );
  }

  private _getEnablerEntities() {
    this._entitiesApiService.getEntitiesByEnabler().subscribe({
      next: (response) => {
        this.enablerEntities.set(response.responseData);
      },
      complete: () => this.pillarId && this._getPillar(+this.pillarId),
    });
  }

  private _getPillar(pillarId: number) {
    this._pillarsApiService.getPillarById(pillarId).subscribe({
      next: (response) => {
        this.selectedEntities.set(response.responseData.pillarEntities);
        const mappedPillarEntities = response.responseData.pillarEntities.map((entity) => entity.entityId);
        this.pillarForm.patchValue({ ...response.responseData, pillarEntities: mappedPillarEntities });
        this.pillarForm.markAsPristine();
        this.pillarForm.markAsUntouched();
      },
    });
  }

  public cancelForm() {
    if (this.pillarForm.dirty) {
      const modal = this._modalService.open(ConfirmationModalComponent, {
        centered: true,
        size: "md",
        modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
      });

      modal.componentInstance.header = "common.form.modals_leave.cancel_yes_no_title";
      modal.componentInstance.title = "common.form.modals_leave.cancel_yes_no_information";

      modal.result.then((e) => {
        if (e) this._router.navigateByUrl(`/console/pillars/list`);
      });
    } else this._router.navigateByUrl(`/console/pillars/list`);
  }

  public updatePillar() {
    this.submitted.set(true);
    if (this.pillarForm.invalid) {
      this._toastrService.warning(this._translateService.instant("notification.warning.missing_info"));
      return;
    }
    const pillar = this._formatPillar();

    const serviceUrl =
      this.pageMode() === PageMode.edit
        ? this._pillarsApiService.updatePillar(pillar)
        : this._pillarsApiService.createPillar(pillar);

    serviceUrl.subscribe({
      next: (response) => {
        if (response.inError) return;
        this._toastrService.success(this._translateService.instant("notification.success.save"));
        this._router.navigateByUrl("/console/pillars/list");
      },
    });
  }

  public checkInvalid(control: string) {
    return (
      this.pillarForm.controls[control].errors?.["required"] &&
      (this.pillarForm.get("nameEN")?.dirty || this.submitted())
    );
  }

  private _formatPillar() {
    const pillar = this.pillarForm.value;
    const pillarEntities: number[] = this._extractValues(pillar.pillarEntities);
    const result: Partial<PillarEntity>[] = [];

    this.selectedEntities()?.forEach((selectedEntity) => {
      if (!pillarEntities.includes(selectedEntity.entityId)) {
        selectedEntity.status = Constants.OBJECT_STATUS.REMOVE;
      } else selectedEntity.status = Constants.OBJECT_STATUS.ACTIVE;
      result.push(selectedEntity);
    });

    pillarEntities.forEach((entity) => {
      if (!result.find((item) => item.entityId === entity)) {
        const match = this.enablerEntities().find((enablerEntity) => enablerEntity.id === entity);
        result.push({
          entityId: match?.id,
          entityNameAE: match?.nameAE,
          entityNameEN: match?.nameEN,
          entityShortNameAE: match?.shortNameAE,
          entityShortNameEN: match?.shortNameEN,
          status: Constants.OBJECT_STATUS.ACTIVE,
        });
      }
    });

    pillar.pillarEntities = result;
    pillar.status = Constants.OBJECT_STATUS.ACTIVE;
    pillar.refCode = pillar.refCode.toUpperCase();
    pillar.available = pillar.available ?? AdaaBoolean.N;
    if (this.pageMode() === PageMode.edit) {
      pillar.updateTS = Date.now();
      pillar.id = this.pillarId;
    }
    return pillar;
  }

  private _extractValues(array: (number | ValueText)[]): number[] {
    const res: number[] = [];
    array.forEach((item) => {
      if (typeof item === "number") {
        res.push(item);
      } else if (typeof item === "object" && "value" in item) {
        res.push(item.value);
      }
    });
    return res;
  }
}
