import { Component } from "@angular/core";

@Component({
  selector: "adaa-workflow-sync-scheduler",
  standalone: true,
  imports: [],
  templateUrl: "./workflow-sync-scheduler.component.html",
  styleUrl: "./workflow-sync-scheduler.component.scss",
})
export class WorkflowSyncSchedulerComponent {}
