import { Component, inject, input, OnInit, signal, viewChild } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { ToastrService } from "ngx-toastr";

import { AdaaHelper } from "../../core/utils";
import {
  DataTableComponent,
  FloatActionComponent,
  FormDropdownComponent,
  FormInputFileComponent,
  WfDifferenceComponent,
  WfOverrideDecisionComponent,
} from "../../shared/components";
import { genericFloatButtons } from "../../shared/components/general/float-action";
import { Constants } from "../../shared/constants/constants";
import { AdaaBoolean, FileInputType, Language } from "../../shared/constants/enums";
import {
  AttachFile,
  ValueText,
  WorkflowDecisionModelType,
  WorkflowDiffInputType,
  WorkflowItemDiffModelType,
  WorkflowProcessCtl,
} from "../../shared/models";
import { NotificationCommunication, SearchFields } from "../../shared/models/notification-center.model";
import { SystemLayoutService, WorkflowsApiService } from "../../shared/services";
import { NotificationCenterApiService } from "../../shared/services/notification-center.service";

@Component({
  selector: "adaa-notification-center",
  standalone: true,
  imports: [
    TranslateModule,
    FormDropdownComponent,
    DataTableComponent,
    FormInputFileComponent,
    WfDifferenceComponent,
    WfOverrideDecisionComponent,
    FloatActionComponent,
  ],
  templateUrl: "./notification-center.component.html",
  styleUrl: "./notification-center.component.scss",
})
export class NotificationCenterComponent implements OnInit {
  private _translateService = inject(TranslateService);
  private _toastrService = inject(ToastrService);
  private _notificationCenterApiService = inject(NotificationCenterApiService);
  private _workflowsApiService = inject(WorkflowsApiService);
  private _router = inject(Router);
  systemLayoutService = inject(SystemLayoutService);

  moment = moment;
  AdaaHelper = AdaaHelper;
  AdaaBoolean = AdaaBoolean;
  Language = Language;
  Constants = Constants;

  id = input<string | undefined>();

  private readonly _genericFloatButtons = genericFloatButtons();
  actionOptions = signal<ValueText[]>([]);
  readOptions = signal<ValueText[]>([]);
  attachment = signal<AttachFile | null>(null);
  decision = signal<Partial<WorkflowDecisionModelType | undefined>>(undefined);
  notifications = signal<NotificationCommunication[]>([]);
  selectedNotification = signal<NotificationCommunication | undefined>(undefined);
  tableData = signal<WorkflowProcessCtl[]>([]);
  review = signal<WorkflowItemDiffModelType | undefined>(undefined);
  workflowDifferenceRef = viewChild.required<WfDifferenceComponent>("workflowDifference");
  filterTypes = signal<{ action?: string; read?: string }>({
    action: "A",
    read: "N",
  });
  searchFields = signal<SearchFields | undefined>(undefined);
  allowedFiles: FileInputType[] = [
    FileInputType.txt,
    FileInputType.pdf,
    FileInputType.doc,
    FileInputType.docx,
    FileInputType.xls,
    FileInputType.xlsx,
    FileInputType.png,
    FileInputType.jpeg,
    FileInputType.jpg,
  ];

  public ngOnInit(): void {
    this._floatActions();

    this._notificationCenterApiService.getNotifications(true).subscribe({
      next: (response) => this.notifications.set(response.responseData),
      complete: () => {
        if (this.id()) this.getNotification(+this.id()!);
      },
    });
    this._translateService.get("communication.action").subscribe({
      complete: () => {
        this.actionOptions.set([
          { value: "A", text: this._translateService.instant("communication.action") },
          { value: "I", text: this._translateService.instant("communication.info") },
          { value: "", text: this._translateService.instant("communication.all") },
        ]);
        this.readOptions.set([
          { value: AdaaBoolean.Y, text: this._translateService.instant("communication.read") },
          { value: AdaaBoolean.N, text: this._translateService.instant("communication.unread") },
          { value: "", text: this._translateService.instant("communication.read_and_unread") },
        ]);
      },
    });
  }

  public performSearch(event: Event) {
    const input = (event.target as HTMLInputElement).value;
    const searchFields = {
      title_en: {
        value: input,
        order: null,
      },
      title_ae: {
        value: input,
        order: null,
      },
      comm_msg_ae: {
        value: input,
        order: null,
      },
      comm_msg_en: {
        value: input,
        order: null,
      },
    };
    this.searchFields.set(searchFields);
    this._notificationCenterApiService
      .getNotifications(false, false, { searchFields }, this.filterTypes().action, this.filterTypes().read)
      .subscribe({
        next: (response) => this.notifications.set(response.responseData),
      });
  }

  public getNotification(id: number) {
    const notification = this.notifications().find((notification) => notification.id === id);
    this.selectedNotification.set(notification);
    if (notification?.isRead === AdaaBoolean.N) {
      this._notificationCenterApiService.markAsRead(id).subscribe({
        complete: () => {
          notification.isRead = AdaaBoolean.Y;
          this.notifications.set(this.notifications().map((n) => (n.id === notification.id ? notification : n)));
        },
      });
    }
    if (notification && notification.isActionPending === AdaaBoolean.Y) {
      this._workflowsApiService.getPreviousWfProcessCtlSteps(notification.wfProcessCtlId).subscribe({
        next: (response) => this.tableData.set(response.responseData),
      });
    }
  }

  public getItemForReview() {
    const selectedNotification = this.selectedNotification();
    if (
      selectedNotification?.commType === "I" &&
      +selectedNotification.wfActionType !== Constants.NOTIFICATION_ACTION_TYPES.UPDATE
    )
      return;
    if (selectedNotification) {
      this.workflowDifferenceRef()?.open({
        itemId: this.selectedNotification()?.itemId,
        trailId: this.selectedNotification()?.auditTrailId,
        itemTypeId: this.selectedNotification()?.itemType,
        readingType: this.selectedNotification()?.readingType,
        wfActionType: this.selectedNotification()?.wfActionType,
        wfProcessCtlId: this.selectedNotification()?.wfProcessCtlId,
      } as WorkflowDiffInputType);
    }
  }

  public showMoreMessages() {
    this._notificationCenterApiService
      .getNotifications(
        false,
        false,
        this.searchFields() ? { searchFields: this.searchFields()! } : null,
        this.filterTypes().action,
        this.filterTypes().read,
        undefined,
        true
      )
      .subscribe({
        next: (response) => {
          if (response.responseData.length > 0) this.notifications.set(response.responseData);
        },
      });
  }

  public filter(type: string, event: string) {
    if (type === "action") {
      this.filterTypes.set({ ...this.filterTypes(), action: event });
    }
    if (type === "read") {
      this.filterTypes.set({ ...this.filterTypes(), read: event });
    }
    this._notificationCenterApiService
      .getNotifications(
        false,
        false,
        this.searchFields() ? { searchFields: this.searchFields()! } : null,
        this.filterTypes().action,
        this.filterTypes().read
      )
      .subscribe({
        next: (response) => this.notifications.set(response.responseData),
      });
  }

  public submitDecision(approval: boolean) {
    const notification = this.selectedNotification();
    if (!notification) return;

    const currentUserId = AdaaHelper.getLocalStorage(Constants.localStorageKeys.user, {
      type: "prop",
      property: "id",
    });

    const decision = {
      wfProcessCtlId: notification.wfProcessCtlId,
      wfProcessCtlStepId: notification.wfProcessCtlStepId,
      stepDecision: approval ? Constants.WF_ACTION_DECISION.APPROVED : Constants.WF_ACTION_DECISION.REJECTED,
      threadId: notification.threadId,
      itemId: notification.itemId,
      itemTypeId: notification.itemType,
      itemAttachmentId: this.attachment()?.id,
      categorizedComments: this.decision()?.categorizedComments ?? [],
      userId: +currentUserId,
    };
    this._workflowsApiService.submitWorkflowDecision(decision).subscribe({
      complete: () => {
        this._notificationCenterApiService.getNotifications(true).subscribe({
          next: (response) => {
            this.selectedNotification.set(undefined);
            this.attachment.set(null);
            this.decision.set(undefined);
            this._toastrService.success(this._translateService.instant("notification.success.save"));
            this.notifications.set(response.responseData);
          },
        });
      },
    });
  }

  private _floatActions(): void {
    this._genericFloatButtons([
      {
        key: "helpdesk",
        data: {
          url: "/helpdesk/create",
        },
      },
    ]);
  }

  public order(sort: "asc" | "desc") {
    this._notificationCenterApiService
      .getNotifications(
        false,
        true,
        this.searchFields() ? { searchFields: this.searchFields()! } : null,
        this.filterTypes().action,
        this.filterTypes().read,
        sort
      )
      .subscribe({
        next: (response) => this.notifications.set(response.responseData),
      });
  }
}
