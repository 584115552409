import { Component, inject, input, output, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

import { AdaaHelper } from "../../../core/utils";
import { AdaaBoolean, Language, PageMode } from "../../constants/enums";
import { UserRolesPermission } from "../../models";
import { AppService, RolesApiService } from "../../services";

type checkType = "create" | "view" | "manage" | "execute" | "null";

@Component({
  selector: "adaa-user-roles-manager",
  standalone: true,
  imports: [TranslateModule],
  templateUrl: "./user-roles-manager.component.html",
  styleUrl: "./user-roles-manager.component.scss",
})
export class UserRolesManagerComponent {
  appService = inject(AppService);
  private _rolesApiService = inject(RolesApiService);

  Language = Language;
  AdaaHelper = AdaaHelper;
  AdaaBoolean = AdaaBoolean;
  PageMode = PageMode;
  data = input<UserRolesPermission[]>();
  isPmo = input<boolean>();
  pageMode = input<string>();
  setPermissions = output<UserRolesPermission>();
  selectAll = output<{ event: Event; value: string }>();
  showHighlight: boolean = false;
  highlightText = signal<{
    itemTypeDescAE?: string;
    itemTypeDescEN?: string;
    itemTypeDescURLs?: string;
  }>({
    itemTypeDescAE: "",
    itemTypeDescEN: "",
    itemTypeDescURLs: "",
  });

  generalTabState = signal<{
    create: boolean;
    view: boolean;
    manage: boolean;
    execute: boolean;
  }>({
    create: false,
    view: false,
    manage: false,
    execute: false,
  });
  pmoTabState = signal<{
    create: boolean;
    view: boolean;
    manage: boolean;
    execute: boolean;
  }>({
    create: false,
    view: false,
    manage: false,
    execute: false,
  });

  public updateState(event: Event, value: checkType) {
    const element = event.target as HTMLInputElement;
    const checked = element.checked;
    const tab = this.isPmo() ? this.pmoTabState : this.generalTabState;

    switch (value) {
      case "create":
        tab.update((value) => ({ ...value, create: checked }));
        break;
      case "view":
        tab.update((value) => ({ ...value, view: checked }));
        break;
      case "manage":
        tab.update((value) => ({ ...value, manage: checked }));
        break;
      case "execute":
        tab.update((value) => ({ ...value, execute: checked }));
        break;

      default:
        break;
    }
  }

  public valueChanged(event: Event, id: number, permission: string): void {
    const target = event.target as HTMLInputElement;
    const isChecked = target.checked;
    const value = this.data()?.find((item) => item.id === id);
    if (value) {
      switch (permission) {
        case "create":
          value.create = isChecked ? AdaaBoolean.Y : AdaaBoolean.N;
          if (isChecked && value.viewEnabled === AdaaBoolean.Y && value.manageEnabled === AdaaBoolean.Y) {
            value.view = AdaaBoolean.Y;
            value.manage = AdaaBoolean.Y;
          }
          break;
        case "view":
          value.view = isChecked ? AdaaBoolean.Y : AdaaBoolean.N;
          if (!isChecked) {
            value.create = AdaaBoolean.N;
            value.manage = AdaaBoolean.N;
          }
          break;
        case "manage":
          value.manage = isChecked ? AdaaBoolean.Y : AdaaBoolean.N;
          if (isChecked) {
            if (value.viewEnabled === AdaaBoolean.Y) value.view = AdaaBoolean.Y;
          } else {
            value.create = AdaaBoolean.N;
          }
          break;
        case "execute":
          value.execute = isChecked ? AdaaBoolean.Y : AdaaBoolean.N;
          break;

        default:
          break;
      }

      this.setPermissions.emit(value);
    }
  }

  public getHighlight(itemId: number) {
    const itemType = this.data()?.find((permission) => permission.itemTypeId === itemId);
    if (itemType) {
      this.highlightText.set({
        itemTypeDescAE: itemType.itemTypeDescAE,
        itemTypeDescEN: itemType.itemTypeDescEN,
        itemTypeDescURLs: itemType.itemTypeDescURLs,
      });
    }
  }
}
