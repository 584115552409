<h1 class="my-4">
    <h4 class="fw-bold m-0">{{ "calculation.calculation_government" | translate }}</h4>
</h1>

<div class="calc-table">
    <div class="overflow-x-scroll">
        <div *ngIf="governments" class="row mb-2">
            <div class="col-md-2 fixed-col">
                <div class="color--golden font-bold"></div>
            </div>
            <div class="col-md-2"></div>
            <div class="col-md-10">
                <div class="d-flex text-center m-0">
                    <div *ngFor="let weight of governments[0].weights" class="w-100 m-auto" style="min-width: 12ch; font-size: 12px">
                        <span *ngIf="languageService.current() === language.English">{{ weight.typeNameEN }}</span>
                        <span *ngIf="languageService.current() === language.Arabic">{{ weight.typeNameAE }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="governments" [formGroup]="govCalculationForm" class="row">
            <div class="col-md-16">
                <div *ngFor="let government of gov.controls" class="row" [class.invalidInputs]="getColouredInvalidRows(government.value.id)">
                    <div class="col-md-16" [formGroup]="government">
                        <div class="row mb-2">
                            <div class="col-md-2 fixed-col" *ngIf="languageService.current() === language.English">
                                <span class="header">{{ government.value.nameEN }}</span>
                            </div>
                            <div class="col-md-2 fixed-col" *ngIf="languageService.current() === language.Arabic">
                                <span class="header">{{ government.value.nameAE }}</span>
                            </div>
                            <div class="col-md-2"></div>
                            <div class="col-md-10">
                                <div class="d-flex">
                                    <div
                                        *ngFor="let input of getConfig(government).controls"
                                        [formGroup]="input"
                                        class="w-100"
                                        style="min-width: 12ch; font-size: 12px"
                                    >
                                        <input
                                            type="number"
                                            min="0"
                                            title="{{
                                                languageService.current() === language.English ? input.value.typeNameEN : input.value.typeNameAE
                                            }}"
                                            formControlName="value"
                                            [ngClass]="AdaaHelper.isDefined(input.value.value) ? '' : 'no-value'"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *adaaHasPermission="manageCalculationManagementPermission" class="mt-4 text-center">
    <div class="my-2">
        <adaa-form-action-buttons
            [cancelButtonTitle]="'common.form.button.cancel' | translate"
            [saveButtonTitle]="'common.form.button.submit' | translate"
            [saveButtonDisabled]="!govCalculationForm.valid"
            (cancelClicked)="cancel()"
            (saveClicked)="submit()"
        ></adaa-form-action-buttons>
    </div>
</div>
