import { Component, computed, inject, type OnDestroy, type OnInit, signal } from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { Subscription } from "rxjs";

import { AdaaHelper, useNewDS } from "../../../core/utils";
import {
  AggregatedPerformanceComponent,
  DataTableComponent,
  EntityOverallPerformanceComponent,
  FloatActionComponent,
  GovernmentEnablerPillarsComponent,
  PeriodSliderComponent,
} from "../../../shared/components";
import { genericFloatButtons } from "../../../shared/components/general/float-action";
import { Constants } from "../../../shared/constants/constants";
import { AdaaBoolean } from "../../../shared/constants/enums";
import type { EntityModelType, SelectedPeriodModelType } from "../../../shared/models";
import {
  AggregationPerfService,
  AppService,
  ConsoleService,
  PillarEkpiPerformanceService,
  SystemLayoutService,
  UserService,
} from "../../../shared/services";

@Component({
  selector: "adaa-entity",
  standalone: true,
  templateUrl: "./entity.component.html",
  styleUrl: "./entity.component.scss",
  providers: [AggregationPerfService],
  imports: [
    RouterLink,
    TranslateModule,
    DataTableComponent,
    AggregatedPerformanceComponent,
    PeriodSliderComponent,
    EntityOverallPerformanceComponent,
    GovernmentEnablerPillarsComponent,
    FloatActionComponent,
  ],
})
export class EntityComponent implements OnInit, OnDestroy {
  readonly userService = inject(UserService);
  readonly consoleService = inject(ConsoleService);
  readonly systemLayoutService = inject(SystemLayoutService);
  private readonly _router = inject(Router);
  private readonly _appService = inject(AppService);
  private readonly _aggregationPerfService = inject(AggregationPerfService);
  private readonly _pillarEkpiPerformanceService = inject(PillarEkpiPerformanceService);

  entity = signal<EntityModelType | undefined>(undefined);
  selectedPeriod = signal<SelectedPeriodModelType | undefined>(undefined);
  private readonly _genericFloatButtons = genericFloatButtons();

  isExternalEntity = computed(
    () => this.entity() && this.entity()?.entityType === Constants.CONSTANT_EXTERNAL_ENTITY_TYPE
  );
  aggregationTypeCardTitle = computed(() => {
    const isAnnual = this.selectedPeriod()!.type === "year";
    if (isAnnual) {
      return this.selectedPeriod()?.period.year;
    }
    return `Q${this.selectedPeriod()?.period.quarter} ${this.selectedPeriod()?.period.year}`;
  });
  overallAggregationPerf = computed(() => this._aggregationPerfService.overallAggregationPerf());
  currentUser = computed(() => this.userService.currentUser());
  favoriteApiPath = computed(() => `/userv2/${this.currentUser()?.id}`);
  highPriorityParamString = computed(
    () => `periodId=${this.selectedPeriod()?.period?.id}&strategicEntityId=${this.entity()?.id}&priorityHigh=Y`
  );
  objectivesTableConf = computed(() => {
    const isAnnual = this.selectedPeriod()?.type === "year";
    return isAnnual
      ? Constants.MAIN_TABLE_LIST_CONF_KEY.HOMEPAGE_OBJECTIVES_ENTITIES_YEAR
      : Constants.MAIN_TABLE_LIST_CONF_KEY.HOMEPAGE_OBJECTIVES_ENTITIES;
  });
  hasKPIsWithIssue = computed(() => this._aggregationPerfService.hasKPIsWithIssue());
  highPriorityInitiativesConf = computed(() => {
    const period = this.selectedPeriod();
    if (period?.type === "year") {
      return Constants.MAIN_TABLE_LIST_CONF_KEY.HOMEPAGE_INITIATIVE_ANNUAL_HIGH_PRIORITY;
    }
    return Constants.MAIN_TABLE_LIST_CONF_KEY.HOMEPAGE_INITIATIVE_HIGH_PRIORITY;
  });

  readonly useNewDS = useNewDS;
  readonly favoritesTableConf = Constants.MAIN_TABLE_LIST_CONF_KEY.HOMEPAGE_FAVORITES;
  readonly objectivesParamString = () => {
    const qs = new URLSearchParams({
      enabled: AdaaBoolean.N,
      itemType: `${Constants.CONSTANT_OBJECTIVETYPE}`,
    });

    const entity = AdaaHelper.entity;
    qs.set("strategicEntityId", `${entity?.id}`);

    const periodId = this.selectedPeriod()?.period?.id;
    const plan = AdaaHelper.plan;
    if (periodId) {
      qs.set("periodId", `${periodId}`);
    } else {
      qs.set("periodId", `${plan?.id}`);
    }

    return qs.toString();
  };

  private readonly _subscriptions = new Subscription();

  public ngOnInit() {
    this.consoleService.init();
    this._refresh();
    this._updateActiveEntity();
    this._floatActions();
  }

  public ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  public goToViewObjective({
    id,
    objectiveType,
    enabler,
  }: {
    id: number;
    objectiveType: number;
    enabler: AdaaBoolean;
  }) {
    const url = enabler === AdaaBoolean.Y ? "pmo-objectives" : "objectives";

    let qs = "";
    if (this.selectedPeriod()) qs = `?periodId=${this.selectedPeriod()?.period.id}`;

    if (!useNewDS() && url === "objectives") return this._router.navigateByUrl(`/console/${url}/${id}${qs}`);
    else if (enabler === AdaaBoolean.Y && Constants.CONSTANT_ENABLER_OBJECTIVE === objectiveType)
      return this._router.navigateByUrl(`/console/${url}/enabler-objective/${id}${qs}`);
    else if (enabler === AdaaBoolean.Y && Constants.CONSTANT_TRANSFORMATIONAL_OBJECTIVE === objectiveType)
      return this._router.navigateByUrl(`/console/${url}/transformational-objective/${id}${qs}`);
    else if (enabler === AdaaBoolean.N && Constants.CONSTANT_MAIN_OBJECTIVE === objectiveType)
      return this._router.navigateByUrl(`/console/${url}/main-objective/${id}${qs}`);

    return this._router.navigateByUrl(`/console/${url}/strategic-objective/${id}${qs}`);
  }

  public goToInitiatives({ id }: { id: number }) {
    let qs = "";
    if (this.selectedPeriod()) qs = `?periodId=${this.selectedPeriod()?.period.id}`;
    return this._router.navigateByUrl(`/console/initiatives/${id}${qs}`);
  }

  public isDeleted({ status }: { status: number }) {
    return status === Constants.OBJECT_STATUS.REMOVE;
  }

  public canUserView(fieldName: string) {
    switch (fieldName) {
      case "enablerTeam":
        return this.currentUser()?.ekpifpClassification === AdaaBoolean.Y;
      case "serviceTeam":
        return this.currentUser()?.sfpClassification === AdaaBoolean.Y;
      case "directionalTeam":
        return this.currentUser()?.dkpifpClassification === AdaaBoolean.Y;
      case "nationalTeam":
        return this.currentUser()?.nkifpClassification === AdaaBoolean.Y;
      case "skpiopm":
        return this.currentUser()?.smClassification === AdaaBoolean.Y;
      default:
        return false;
    }
  }

  public selectPeriod(data: SelectedPeriodModelType) {
    if (!data) return;
    const { period, type } = data;
    if (type === "quarter") {
      this._aggregationPerfService.entityQuarterlyPerformance(period.id, this.entity()!.id).subscribe();
      this._pillarEkpiPerformanceService.getData(period.id, true, this.entity()!.id).subscribe();
    } else if (type === "year") {
      this._aggregationPerfService.entityAnnualPerformance(period.year, this.entity()!.id).subscribe();
      this._pillarEkpiPerformanceService.getData(period.year, false, this.entity()!.id).subscribe();
    }
    this._aggregationPerfService.getCountAuditIssues(period.id, this.entity()!.id).subscribe();
    this.selectedPeriod.set({ period, type });
  }

  private _updateActiveEntity() {
    const currentEntity = AdaaHelper.getLocalStorage(Constants.localStorageKeys.currentEntity, {
      type: "json",
    }) as EntityModelType;

    if (!currentEntity) return;

    if (currentEntity.id === Constants.CONSTANT_PMO_ID) {
      this._router.navigateByUrl("/dashboard/target");
      return;
    }

    this.entity.update(() => currentEntity);

    if (this.selectedPeriod()) {
      const isQuarter = this.selectedPeriod()?.type === "quarter";
      const period = isQuarter ? this.selectedPeriod()?.period.id : this.selectedPeriod()?.period.year;
      this._pillarEkpiPerformanceService.getData(period!, isQuarter, currentEntity.id).subscribe();
    }
  }

  private _refresh() {
    this._subscriptions.add(
      this.systemLayoutService.hasActiveEntityChanged$.subscribe({
        next: () => this._appService.reloadPage("/dashboard/entity"),
      })
    );

    this._subscriptions.add(
      this.systemLayoutService.hasCycleChanged$.subscribe({
        next: () => this._appService.reloadPage("/dashboard/entity"),
      })
    );
  }

  private _floatActions(): void {
    this._genericFloatButtons([
      {
        key: "print",
        data: undefined,
      },
    ]);
  }
}
