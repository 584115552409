import { Component, inject, OnInit, viewChild } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { AdaaHelper } from "../../../core/utils";
import { ConfirmationModalComponent, DataTableComponent, FloatActionComponent } from "../../../shared/components";
import { genericFloatButtons } from "../../../shared/components/general/float-action";
import { Constants } from "../../../shared/constants/constants";
import { AdaaBoolean, Language, PermissionAction } from "../../../shared/constants/enums";
import { CustomButton, ItemAction, TableButtonClicked, UserModelType } from "../../../shared/models";
import { AppService, PermissionsService, SystemLayoutService, UsersApiService } from "../../../shared/services";
@Component({
  selector: "adaa-users-list",
  standalone: true,
  imports: [DataTableComponent, FloatActionComponent],
  templateUrl: "./users-list.component.html",
  styleUrl: "./users-list.component.scss",
})
export class UsersListComponent implements OnInit {
  private _toastrService = inject(ToastrService);
  private _translateService = inject(TranslateService);
  private _modalService = inject(NgbModal);
  private _appService = inject(AppService);
  private _systemLayoutService = inject(SystemLayoutService);
  private _usersApiService = inject(UsersApiService);
  private _router = inject(Router);
  private _permissionsService = inject(PermissionsService);

  private _usersTable = viewChild<DataTableComponent>("usersTable");

  private readonly _genericFloatButtons = genericFloatButtons();
  private readonly _untilDestroyed = AdaaHelper.untilDestroyed();

  constants = Constants;
  customButtons: CustomButton[] = [];

  disableDeleteRules: ItemAction[] = [
    {
      operator: "equal",
      propertyName: "id",
      compareWith: AdaaHelper.getLocalStorage(Constants.localStorageKeys.user, {
        type: "prop",
        property: "id",
      }),
    },
  ];

  public ngOnInit(): void {
    this._floatActions();

    this._systemLayoutService.hasActiveEntityChanged$.pipe(this._untilDestroyed()).subscribe(() => {
      this._usersTable()?.resetFilters();
    });

    this._permissionsService.isPermissionsLoaded.pipe(this._untilDestroyed()).subscribe((loaded: boolean) => {
      if (!loaded) return;

      this._checkPermissions();
    });
  }

  public addNewClicked() {
    this._router.navigateByUrl(`/console/users/create`);
  }

  public actionClicked(action: TableButtonClicked): void {
    switch (action.event) {
      case "reset":
        this._resetPassword(action.data);
        break;
      case "enable":
        this._setUserStatus(action.data, AdaaBoolean.N);
        break;
      case "disable":
        this._setUserStatus(action.data, AdaaBoolean.Y);
        break;
      case "delete":
        this._deleteUser(action.data);
        break;
      case "related":
        this._router.navigateByUrl(`/console/users/related-objects/${action.data.id}`);
        break;
      case "view":
        this._router.navigateByUrl(`/console/users/${action.data.id}`);
        break;
      case "edit":
        this._router.navigateByUrl(`/console/users/edit/${action.data.id}`);
        break;
      default:
        break;
    }
  }

  private _setUserStatus(user: UserModelType, status: AdaaBoolean): void {
    this._usersApiService.updateState({ id: user.id, enabled: status }).subscribe({
      next: (response) => {
        if (response.inError) return;
        this._toastrService.success(
          this._translateService.instant(
            status === AdaaBoolean.Y ? "notification.success.activate" : "notification.success.deactivate"
          )
        );
        this._usersTable()?.loadTableData();
      },
    });
  }

  private _resetPassword(user: UserModelType): void {
    const modal = this._modalService.open(ConfirmationModalComponent, {
      centered: true,
      size: "md",
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });

    modal.componentInstance.header = "user.reset_login_attempts";
    modal.componentInstance.title = "user.reset_login_attempts";

    modal.result.then((e) => {
      if (e) this._resetPasswordConfirmed(user);
    });
  }

  private _resetPasswordConfirmed(user: UserModelType): void {
    this._usersApiService.resetLoginAttempts(user.id).subscribe({
      next: (response) => {
        if (response.inError) return;
        else if (response.responseCode === "1") {
          this._toastrService.success(this._translateService.instant("notification.success.save"));
          this._usersTable()?.loadTableData();
        }
      },
    });
  }

  private _deleteUser(user: UserModelType): void {
    const modal = this._modalService.open(ConfirmationModalComponent, {
      centered: true,
      size: "md",
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });

    modal.componentInstance.header = "user.create.delete_yes_no_title";
    modal.componentInstance.title = "user.create.delete_yes_no_information";

    modal.result.then((e) => {
      if (e) this._deleteUserConfirmed(user);
    });
  }

  private _deleteUserConfirmed(user: UserModelType): void {
    this._usersApiService.delete(user.id).subscribe({
      next: (response) => {
        if (response.inError) return;

        this._toastrService.success(this._translateService.instant("notification.success.remove"));
        this._usersTable()?.loadTableData();
      },
    });
  }

  private _checkPermissions(): void {
    const managePermission = this._permissionsService.hasPermission([
      {
        permissionAction: PermissionAction.manage,
        objectTypeId: Constants.CONSTANT_PERMISSIONS.USERS,
      },
    ]);

    const viewUserSecurity = this._permissionsService.hasPermission([
      {
        permissionAction: PermissionAction.view,
        objectTypeId: Constants.CONSTANT_PERMISSIONS.USERS_SECURITY,
      },
    ]);

    this.customButtons = [
      {
        iconName: "adaa-icon-adaa-reset-password",
        eventName: "reset",
        iconTitle: "user.reset_login_attempts",
        onlyIf: () => viewUserSecurity,
      },
      {
        iconName: "adaa-icon-adaa-related-objects",
        eventName: "related",
        iconTitle: "common.form.button.view_related_objects",
        onlyIf: () => managePermission,
      },
      {
        iconName: "adaa-icon-adaa-ok-Y",
        eventName: "enable",
        iconTitle: "common.form.label.active",
        onlyIf: (data: { enabled: AdaaBoolean }) => {
          return AdaaBoolean.Y === data?.enabled && managePermission;
        },
      },
      {
        iconName: "adaa-icon-adaa-ok-N",
        eventName: "disable",
        iconTitle: "common.form.label.inactive",
        onlyIf: (data: { enabled: AdaaBoolean }) => {
          return AdaaBoolean.N === data?.enabled && managePermission;
        },
      },
    ];
  }

  private _floatActions(): void {
    this._genericFloatButtons([
      {
        key: "helpdesk",
        data: {
          url: "/helpdesk/create",
        },
      },
      {
        key: "print",
        data: undefined,
      },
    ]);
  }
}
