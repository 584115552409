<div class="row mb-3">
    <div class="col-12">
        <adaa-period-slider
            [periodId]="sdgDashboardService.selectedPeriod()?.period?.id"
            [periodType]="sdgDashboardService.selectedPeriod()?.type"
            [activePeriod]="sdgDashboardService.selectedPeriod()!"
            type="quarter"
            (periodSelect)="selectPeriod($event)"
        />
    </div>
</div>

@if (sdgDashboardService.sdgData()) {
    <div class="row">
        <div class="col-12">
            <div class="bg-white shadow-md rounded p-3">
                <div class="row">
                    <div class="col-md-3 col-sm-12 col-xs-12">
                        <img
                            class="sdg-title"
                            [src]="languageService.current() === Language.English ? 'assets/images/SDG/SDG-en.png' : 'assets/images/SDG/SDG-ar.png'"
                            alt=""
                        />
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <adaa-sdg-circle [sdgModel]="sdgDashboardService.sdgData()" />
                    </div>
                    <div class="col-md-3 col-sm-12 col-xs-12 d-flex flex-column justify-content-center">
                        @for (item of sdgDashboardService.sdgData()?.bars; track $index) {
                            <div class="mb-3">
                                <adaa-info-bar [color]="item.color" [label]="item.label" [value]="item.value" [subLabel]="item.subLabel" />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
}
