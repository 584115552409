import { CommonModule } from "@angular/common";
import { Component, computed, inject, input, output, signal, TemplateRef, viewChild } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { FormActionButtonsComponent, FormInputComponent } from "../../../shared/components";
import { QuillEditorComponent } from "../../../shared/components/quill-editor/quill-editor.component";
import { Language } from "../../../shared/constants/enums";
import { NotificationConfigPlaceholder } from "../../../shared/models/notifications-config.model";
import { AppService } from "../../../shared/services";
import { NotificationsApiService } from "../../../shared/services/notifications-api.service";
import { PlaceholderListComponent } from "../placeholder-list/placeholder-list.component";

@Component({
  selector: "adaa-editor-template-modal",
  standalone: true,
  imports: [
    TranslateModule,
    CommonModule,
    FormInputComponent,
    FormActionButtonsComponent,
    ReactiveFormsModule,
    PlaceholderListComponent,
    QuillEditorComponent,
  ],
  templateUrl: "./editor-template-modal.component.html",
  styleUrl: "./editor-template-modal.component.scss",
})
export class EditorTemplateModalComponent {
  private _appService = inject(AppService);
  private _modalService = inject(NgbModal);
  private _formBuilder = inject(FormBuilder);
  private _toastrService = inject(ToastrService);
  private _translateService = inject(TranslateService);
  private _notificationsApiService = inject(NotificationsApiService);

  modal = viewChild.required<TemplateRef<unknown>>("content");
  contentAEEditor = viewChild.required<QuillEditorComponent>("contentAE");
  contentENEditor = viewChild.required<QuillEditorComponent>("contentEN");
  placehoders = input.required<NotificationConfigPlaceholder[]>();
  editorClosed = output<unknown>();

  editorForm: FormGroup = new FormGroup({});
  placehodersList = computed(() => this.placehoders());
  private _lastFocusedTextElement: HTMLInputElement;
  submitted = signal<boolean>(false);
  placeholder = signal<NotificationConfigPlaceholder | undefined>(undefined);
  Language = Language;

  public ngOnInit() {
    this._prepareForm();
  }

  private _prepareForm() {
    this.editorForm = this._formBuilder.group({
      contentAE: this._formBuilder.control<string | undefined>(""),
      contentEN: this._formBuilder.control<string | undefined>(""),
      status: this._formBuilder.control<string | undefined>(""),
      id: this._formBuilder.control<string | undefined>(""),
      subjectAE: this._formBuilder.control<string | undefined>(""),
      subjectEN: this._formBuilder.control<string | undefined>(""),
      type: this._formBuilder.control<string | undefined>(""),
    });
  }

  public open() {
    this._modalService.open(this.modal(), {
      animation: true,
      scrollable: false,
      keyboard: false,
      size: "xl",
      centered: true,
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });

    this.contentAEEditor().content = this.editorForm.value.contentAE;
    this.contentENEditor().content = this.editorForm.value.contentEN;
    this.submitted.set(false);
  }

  public submit() {
    this.submitted.set(true);
    if (this.editorForm.invalid) {
      this._toastrService.error(this._translateService.instant("common.form.label.missing_information"));
      return;
    }
    const result = {
      ...this.editorForm.value,
      updateTs: Date.now(),
    };

    this.editorClosed.emit(result);
    this._notificationsApiService.updateNotificationsTemplate(result).subscribe({
      next: () => {
        this._toastrService.success(this._translateService.instant("notification.success.save"));
        this._modalService.dismissAll();
      },
    });
  }

  public placeholderSelected(placeholder: NotificationConfigPlaceholder) {
    if (!this._lastFocusedTextElement) {
      return;
    }
    this.placeholder.set(placeholder);
  }

  public onTextareaFocus(event: MouseEvent) {
    this._lastFocusedTextElement = event.target as HTMLInputElement;
  }

  public setValue(event: string, language: Language) {
    const control = language === Language.Arabic ? "contentAE" : "contentEN";
    this.editorForm.get(control)?.patchValue(event);
  }
}
