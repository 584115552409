<div class="row">
    <div class="col-12">
        <h4 class="fw-bold">
            {{ "breadcrumbs.directions_module.list" | translate }}
            <span class="fw-light mx-1">
                @if (displayLabel) {
                    - {{ displayLabel }}
                }
            </span>
        </h4>
    </div>
    <form class="col-12" [formGroup]="directionForm">
        <div class="bg-white shadow-md rounded p-2 mb-3">
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.nameAE' | translate"
                        [setValidator]="directionsService.formValidation()!"
                        [invalid]="submitted && directionForm.get('nameAE')?.errors?.['required']"
                        formControlName="nameAE"
                        controlName="nameAE"
                    ></adaa-form-input>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.nameEN' | translate"
                        [setValidator]="directionsService.formValidation()!"
                        [invalid]="submitted && directionForm.get('nameEN')?.errors?.['required']"
                        formControlName="nameEN"
                        controlName="nameEN"
                    ></adaa-form-input>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.descriptionAE' | translate"
                        [setValidator]="directionsService.formValidation()!"
                        [invalid]="submitted && directionForm.get('dscAE')?.errors?.['required']"
                        formControlName="dscAE"
                        controlName="dscAE"
                        [isTextArea]="true"
                    ></adaa-form-input>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.descriptionEN' | translate"
                        [setValidator]="directionsService.formValidation()!"
                        [invalid]="submitted && directionForm.get('dscEN')?.errors?.['required']"
                        formControlName="dscEN"
                        controlName="dscEN"
                        [isTextArea]="true"
                    ></adaa-form-input>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input-date
                        [label]="'common.form.label.start_date' | translate"
                        [setValidator]="directionsService.formValidation()!"
                        [invalid]="submitted && directionForm.get('startDate')?.errors?.['required']"
                        formControlName="startDate"
                        controlName="startDate"
                    />
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input-date
                        [label]="'common.form.label.end_date' | translate"
                        [setValidator]="directionsService.formValidation()!"
                        [invalid]="submitted && directionForm.get('endDate')?.errors?.['required']"
                        formControlName="endDate"
                        controlName="endDate"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-dropdown
                        [label]="'national_projects.accountable_entity' | translate"
                        [setValidator]="directionsService.formValidation()!"
                        [invalid]="submitted && directionForm.get('entityId')?.errors?.['required']"
                        formControlName="entityId"
                        controlName="entityId"
                        [options]="directionsService.entities()"
                    />
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-dropdown
                        [label]="'common.form.label.owner' | translate"
                        [setValidator]="directionsService.formValidation()!"
                        [invalid]="submitted && directionForm.get('owner')?.errors?.['required']"
                        formControlName="owner"
                        controlName="owner"
                        [options]="directionsService.owners()"
                        [searchable]="true"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <adaa-form-dropdown
                        [label]="'national_strategies.strategic_owner_group' | translate"
                        [setValidator]="directionsService.formValidation()!"
                        [invalid]="submitted && directionForm.get('strategyOwnerGroup')?.errors?.['required']"
                        formControlName="strategyOwnerGroup"
                        controlName="strategyOwnerGroup"
                        [options]="directionsService.strategyOwnerGroups()"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <adaa-form-input-file
                        label="directions.gov_icon"
                        [allowedFilesMessage]="isViewMode ? ' ' : 'help.faqs.upload_tool_tip'"
                        [onDeleteRemoveFile]="false"
                        [isDisabled]="isViewMode"
                        [allowedFileSize]="100024"
                        [allowedFiles]="allowedFiles"
                        [file]="directionIcon"
                        (inputChanges)="onFilechange($event)"
                    ></adaa-form-input-file>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 col-sm-12 col-xs-12">
                    <adaa-form-checkbox
                        [label]="'directions.vision' | translate"
                        [checkboxMode]="'yesNo'"
                        controlName="vision"
                        formControlName="vision"
                        (inputChanges)="visionChanged($event)"
                    />
                </div>
                <div class="col-md-4 col-sm-12 col-xs-12">
                    <adaa-form-checkbox
                        [label]="'directions.national_strategy' | translate"
                        [checkboxMode]="'trueFalse'"
                        controlName="isNationalStrategy"
                        formControlName="isNationalStrategy"
                    />
                </div>
                <div class="col-md-4 col-sm-12 col-xs-12">
                    <adaa-form-checkbox
                        [label]="'common.form.label.available' | translate"
                        [checkboxMode]="'yesNo'"
                        controlName="available"
                        formControlName="available"
                    />
                </div>
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-md-12">
                <div class="bg-white shadow-md rounded p-2">
                    <adaa-data-table
                        #subGovernmentDirectionsTable
                        titleHeader="initiatives.sub_government_directions"
                        configKey="sub_gov_dir_conf_list"
                        [isLocalData]="true"
                        [localData]="filteredSubDirections"
                        [enableViewAction]="isViewMode"
                        [enableAdd]="!isViewMode"
                        [enableEditAction]="!isViewMode"
                        [enableDeleteAction]="!isViewMode"
                        [enableExport]="false"
                        [tableWithChildren]="true"
                        childKey="auxiliaryDirectionRequests"
                        (addNewClicked)="addSubDirectionClicked()"
                        (actionClicked)="manageSubDirectionClicked($event)"
                    ></adaa-data-table>
                </div>
            </div>
        </div>
    </form>
    <div class="co-12">
        <div class="col-12">
            <adaa-form-action-buttons
                [showSaveButton]="!isViewMode"
                saveButtonTitle="common.form.button.finish"
                (saveClicked)="submit()"
                (cancelClicked)="cancel()"
            ></adaa-form-action-buttons>
        </div>
    </div>
</div>
