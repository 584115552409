<div class="row">
    <div class="col-12">
        <h4 class="fw-bold">
            {{ "government_scores.scopes.single" | translate }}
            <span class="fw-light mx-1">
                @if (displayLabel) {
                    - {{ displayLabel }}
                }
            </span>
        </h4>
    </div>

    <div class="col-12" [formGroup]="scopeForm">
        <adaa-form-steps
            saveTitle="common.form.button.submit"
            [tabs]="tabs"
            [showSaveButton]="pageMode() !== PageMode.view"
            (submitClicked)="submit()"
            (cancelClicked)="goBack()"
            (currentTab)="activeTab.set($event)"
        >
            <div class="tab-pane fade" id="tab-0" role="tabpanel" [ngClass]="{ 'show active': activeTab() === 0 }">
                <div class="bg-white shadow-md rounded p-2 mb-2">
                    <div class="row">
                        <div class="col-sm-6 col-xs-12">
                            <adaa-form-dropdown
                                controlName="entityId"
                                formControlName="entityId"
                                [clearable]="true"
                                [searchable]="true"
                                [invalid]="submitted && scopeForm.get('entityId')?.invalid!"
                                [label]="'common.form.label.entity' | translate"
                                [setValidator]="formValidation()!"
                                [options]="entities()"
                            />
                        </div>
                        <div class="col-sm-6 col-xs-12">
                            <adaa-form-dropdown
                                controlName="pillarId"
                                formControlName="pillarId"
                                [clearable]="true"
                                [invalid]="submitted && scopeForm.get('pillarId')?.invalid!"
                                [label]="'data_entry.pillar_name' | translate"
                                [setValidator]="formValidation()!"
                                [options]="pillars()"
                                (inputChanges)="pillarChanged($event)"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-xs-12">
                            <adaa-form-input
                                controlName="nameAE"
                                formControlName="nameAE"
                                [invalid]="submitted && scopeForm.get('nameAE')?.invalid!"
                                [label]="'common.form.label.nameAE' | translate"
                                [setValidator]="formValidation()!"
                            />
                        </div>
                        <div class="col-sm-6 col-xs-12">
                            <adaa-form-input
                                controlName="nameEN"
                                formControlName="nameEN"
                                [invalid]="submitted && scopeForm.get('nameEN')?.invalid!"
                                [label]="'common.form.label.nameEN' | translate"
                                [setValidator]="formValidation()!"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-xs-12">
                            <adaa-form-input
                                controlName="dscAE"
                                formControlName="dscAE"
                                [isTextArea]="true"
                                [invalid]="submitted && scopeForm.get('dscAE')?.invalid!"
                                [label]="'common.form.label.descriptionAE' | translate"
                                [setValidator]="formValidation()!"
                            />
                        </div>
                        <div class="col-sm-6 col-xs-12">
                            <adaa-form-input
                                controlName="dscEN"
                                formControlName="dscEN"
                                [isTextArea]="true"
                                [invalid]="submitted && scopeForm.get('dscEN')?.invalid!"
                                [label]="'common.form.label.descriptionEN' | translate"
                                [setValidator]="formValidation()!"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <adaa-form-dropdown
                                controlName="layoutId"
                                formControlName="layoutId"
                                [invalid]="submitted && scopeForm.get('layoutId')?.invalid!"
                                [label]="'government_scores.scopes.layout_type' | translate"
                                [setValidator]="formValidation()!"
                                [options]="layouts()"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="tab-1" role="tabpanel" [ngClass]="{ 'show active': activeTab() === 1 }">
                <div class="bg-white shadow-md rounded p-2 mb-2">
                    <div class="row">
                        <div class="col-12">
                            <adaa-form-dropdown-multi
                                controlName="objectivesIds"
                                formControlName="objectivesIds"
                                [clearable]="true"
                                [invalid]="submitted && scopeForm.get('objectivesIds')?.invalid!"
                                [label]="'breadcrumbs.objectives.objectives' | translate"
                                [setValidator]="formValidation()!"
                                [options]="objectives()"
                                (inputChanges)="objectivesChanged($event)"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="tab-2" role="tabpanel" [ngClass]="{ 'show active': activeTab() === 2 }">
                <div class="bg-white shadow-md rounded p-2 mb-2">
                    <adaa-scope-kpi-list
                        [scopeId]="scopeForm.get('id')?.value"
                        [kpis]="scopeForm.get('govScoresScopeMainKpis')?.value ?? []"
                        [entityId]="scopeForm.get('entityId')?.value"
                        [enableEdit]="pageMode() !== PageMode.view"
                        (kpisChanged)="scopeForm.get('govScoresScopeMainKpis')?.setValue($event)"
                    />
                </div>
            </div>
        </adaa-form-steps>
    </div>
</div>

<adaa-float-action />
