import {
  Component,
  inject,
  input,
  OnChanges,
  OnInit,
  output,
  signal,
  SimpleChanges,
  TemplateRef,
  viewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { Language, PageMode } from "../../../constants/enums";
import { AttributeTextMapType } from "../../../models";
import { AppService } from "../../../services";
import { FormActionButtonsComponent, FormInputComponent } from "../../form";

@Component({
  selector: "adaa-attribute-values",
  standalone: true,
  imports: [FormInputComponent, ReactiveFormsModule, TranslateModule, FormActionButtonsComponent],
  templateUrl: "./attribute-values.component.html",
  styleUrl: "./attribute-values.component.scss",
})
export class AttributeValuesComponent implements OnInit, OnChanges {
  private _modalService = inject(NgbModal);
  private _appService = inject(AppService);
  private _toastrService = inject(ToastrService);
  private _translateService = inject(TranslateService);
  private _formBuilder = inject(FormBuilder);

  attributesValuesForm: FormGroup = new FormGroup({});
  modal = viewChild.required<TemplateRef<unknown>>("content");
  submitted = signal<boolean>(false);
  submitValue = output<AttributeTextMapType>();
  selectedValue = input<Partial<AttributeTextMapType>>();
  pageMode: string;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedValue?.currentValue) {
      this.attributesValuesForm.patchValue(changes.selectedValue?.currentValue);
    }
  }

  public ngOnInit(): void {
    this._prepareForm();
  }

  private _prepareForm() {
    this.attributesValuesForm = this._formBuilder.group({
      lovTextAE: this._formBuilder.control<string>("", {
        validators: [Validators.required],
      }),
      lovTextEN: this._formBuilder.control<string>("", {
        validators: [Validators.required],
      }),
      lovNumber: this._formBuilder.control<string | number>("", {
        validators: [Validators.required],
      }),
    });
  }

  public open() {
    if (this.selectedValue() && this.pageMode === PageMode.edit)
      this.attributesValuesForm.patchValue(this.selectedValue()!);
    const modalRef: NgbModalRef = this._modalService.open(this.modal(), {
      animation: true,
      scrollable: false,
      keyboard: false,
      size: "xl",
      centered: true,
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });

    modalRef.dismissed.subscribe(() => this._resetForm());
    modalRef.closed.subscribe(() => this._resetForm());
  }

  private _resetForm() {
    this.attributesValuesForm.reset();
    this.attributesValuesForm.markAsPristine();
    this.attributesValuesForm.markAsUntouched();
    this.submitted.set(false);
  }

  public submit() {
    this.submitted.set(true);
    if (this.attributesValuesForm.invalid) {
      this._toastrService.error(this._translateService.instant("common.form.label.missing_information"));
      return;
    }
    const result = {
      ...this.selectedValue(),
      ...this.attributesValuesForm.value,
    };
    this.submitValue.emit(this.pageMode === PageMode.edit ? result : this.attributesValuesForm.value);
    this._modalService.dismissAll();
  }
}
