import { Component, computed, inject, input, OnInit, output, signal, TemplateRef, viewChild } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { AdaaHelper } from "../../../../core/utils";
import { Constants } from "../../../constants/constants";
import { Language, PageMode } from "../../../constants/enums";
import { PropTypeModelType, TableButtonClicked, ValueText } from "../../../models";
import { GovScoresPillar, PillarFocus } from "../../../models/gov-scores.model";
import { AppService, GovScoresApiService } from "../../../services";
import { FormActionButtonsComponent, FormDropdownComponent, FormInputComponent } from "../../form";
import { DataTableComponent } from "../../table/data-table/data-table.component";
import { AreaOfFocusComponent } from "../area-of-focus/area-of-focus.component";

@Component({
  selector: "adaa-entity-pillars",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    FormActionButtonsComponent,
    FormInputComponent,
    FormDropdownComponent,
    DataTableComponent,
    AreaOfFocusComponent,
  ],
  templateUrl: "./entity-pillars.component.html",
  styleUrl: "./entity-pillars.component.scss",
})
export class EntityPillarsComponent implements OnInit {
  private _modalService = inject(NgbModal);
  private _appService = inject(AppService);
  private _toastrService = inject(ToastrService);
  private _translateService = inject(TranslateService);
  private _formBuilder = inject(FormBuilder);
  private _govScoresApiService = inject(GovScoresApiService);

  entityPillarForm: FormGroup = new FormGroup({});
  submitted = signal<boolean>(false);
  focusList = signal<PillarFocus[]>([]);
  modal = viewChild.required<TemplateRef<unknown>>("content");
  areaOfFocus = viewChild.required<AreaOfFocusComponent>("focus");
  pageMode = input<string>();
  pillars = input<GovScoresPillar[]>();
  focusIndex: number;
  pillarIndex: number;
  Constants = Constants;
  PageMode = PageMode;
  pillar = signal<Partial<GovScoresPillar>>({});
  pillarOutput = output<Partial<GovScoresPillar>>();
  modalRef: NgbModalRef;

  pillarTypes = signal<PropTypeModelType[]>([]);
  pillarTypeOptions = computed(() => {
    const field = AdaaHelper.getFieldLanguage("name");
    const result: ValueText[] = AdaaHelper.setDropdownArray(this.pillarTypes(), "id", field);
    return result;
  });

  public ngOnInit(): void {
    this._prepareForm();
    this._govScoresApiService.getAvailablePillarTypes().subscribe({
      next: (response) => this.pillarTypes.set(response.responseData),
    });
  }

  private _prepareForm() {
    const isViewMode = this.pageMode() === PageMode.view;

    this.entityPillarForm = this._formBuilder.group({
      nameAE: this._formBuilder.control<string>(
        { value: "", disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      nameEN: this._formBuilder.control<string>(
        { value: "", disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      dscAE: this._formBuilder.control<string>(
        { value: "", disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      dscEN: this._formBuilder.control<string>(
        { value: "", disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      pillarType: this._formBuilder.control<number | null>(
        { value: null, disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      weight: this._formBuilder.control<number | null>(
        { value: null, disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      summary: this._formBuilder.control<string>({ value: "", disabled: isViewMode }),
    });
  }

  public open() {
    const modalRef = this._modalService.open(this.modal(), {
      animation: true,
      scrollable: false,
      keyboard: false,
      size: "xl",
      centered: true,
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });

    this.modalRef = modalRef;
    modalRef.dismissed.subscribe(() => {
      this._resetForm();
      this.focusList.set([]);
    });
    modalRef.closed.subscribe(() => {
      this._resetForm();
      this.focusList.set([]);
    });
  }

  public submit() {
    this.submitted.set(true);
    if (!this._checkUnique("nameAE") || !this._checkUnique("nameEN")) {
      this._toastrService.error(this._translateService.instant("notification.error.pillar_not_unique"));
      return;
    }
    if (this.entityPillarForm.invalid) {
      this._toastrService.error(this._translateService.instant("notification.warning.missing_info"));
      return;
    }

    const result = {
      ...this.entityPillarForm.value,
      areasOfFocusList: this.focusList(),
    };
    const pillar = this.pillarTypes().find((item) => item.id === result.pillarType);
    result["pillarTypeNameAE"] = pillar?.nameAE;
    result["pillarTypeNameEN"] = pillar?.nameEN;

    this.pillarOutput.emit(result);
    this.modalRef.close();
  }

  private _resetForm() {
    this.entityPillarForm.reset();
    this.entityPillarForm.markAsPristine();
    this.entityPillarForm.markAsUntouched();
    this.submitted.set(false);
  }

  public addFocusList(focus: PillarFocus) {
    const focusList: PillarFocus[] = AdaaHelper.clone(this.focusList());
    if (AdaaHelper.isDefinedAndNotEmpty(this.focusIndex)) {
      focusList.splice(this.focusIndex, 1, focus);
    } else focusList.push(focus);
    this.focusList.set(focusList);
  }

  public getAction(value: TableButtonClicked) {
    if (AdaaHelper.isDefinedAndNotEmpty(value.index)) this.focusIndex = value.index;
    switch (value.event) {
      case "view":
      case "edit":
        this.areaOfFocus().areaOfFocusForm.patchValue(value.data);
        AdaaHelper.isDefinedAndNotEmpty(value.index) && (this.areaOfFocus().focusIndex = value.index);
        this.areaOfFocus().open();
        break;
      case "delete": {
        const list: PillarFocus[] = AdaaHelper.clone(this.focusList());
        list.splice(this.focusIndex, 1);
        this.focusList.set(list);
        break;
      }

      default:
        break;
    }
  }

  public checkInvalid(control: string) {
    const result =
      (this.entityPillarForm.controls[control]?.errors?.["required"] &&
        (this.entityPillarForm.get(control)?.dirty || this.submitted())) ||
      !this._checkUnique(control);
    return result;
  }

  private _checkUnique(control: string): boolean {
    const value = this.entityPillarForm.get(control)?.value;
    if (control === "nameAE" || control === "nameEN") {
      const index = this.pillars()?.findIndex((focus) => focus[control] === value);
      if (AdaaHelper.isDefinedAndNotEmpty(index) && index > -1 && index !== this.pillarIndex) return false;
      else return true;
    } else return true;
  }
}
