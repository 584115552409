import { Component, computed, inject, input, type OnInit, signal, viewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { filter, map } from "rxjs";
import { BreadcrumbService } from "xng-breadcrumb";

import { AdaaHelper, PeriodLabel, useNewDS } from "../../../core/utils";
import {
  ConfirmationModalComponent,
  DataTableComponent,
  EkpiEntityComparisonComponent,
  FloatActionComponent,
  NationalTargetCardModalComponent,
} from "../../../shared/components";
import { genericFloatButtons } from "../../../shared/components/general/float-action";
import { Constants } from "../../../shared/constants/constants";
import {
  CustomButton,
  ItemAction,
  KpiTypeConstantType,
  NtkpiModelType,
  PeriodModelType,
  SearchFields,
  TableActionEvent,
  TableButtonClicked,
  ValueText,
} from "../../../shared/models";
import {
  AppService,
  AppTitleService,
  EntityMapApiService,
  KpisApiService,
  KpisService,
  LanguageService,
  PeriodApiService,
  PermissionsService,
  SystemLayoutService,
} from "../../../shared/services";

@Component({
  selector: "adaa-kpi-list",
  standalone: true,
  imports: [DataTableComponent, FloatActionComponent],
  templateUrl: "./kpi-list.component.html",
  styleUrl: "./kpi-list.component.scss",
})
export class KpiListComponent implements OnInit {
  readonly router = inject(Router);
  readonly languageService = inject(LanguageService);
  private readonly _periodLabel = inject(PeriodLabel);
  private readonly _toastrService = inject(ToastrService);
  private readonly _periodApiService = inject(PeriodApiService);
  private readonly _permissionsService = inject(PermissionsService);
  private readonly _modalService = inject(NgbModal);
  private readonly _appService = inject(AppService);
  private readonly _kpisService = inject(KpisService);
  private readonly _kpisApiService = inject(KpisApiService);
  private readonly _activatedRoute = inject(ActivatedRoute);
  private readonly _appTitleService = inject(AppTitleService);
  private readonly _translateService = inject(TranslateService);
  private readonly _breadcrumbService = inject(BreadcrumbService);
  private readonly _systemLayoutService = inject(SystemLayoutService);
  private readonly _entityMapApiService = inject(EntityMapApiService);

  type = input<string>("", { alias: "kpiType" });
  subGovDir = input<number | undefined>(undefined, {
    alias: "sub_gov_direction_id",
  });
  pillarId = input<number | undefined>(undefined, {
    alias: "pillar_id",
  });

  entityYears = signal<ValueText[]>([]);
  customActionButtons = signal<CustomButton[]>([]);
  kpiType = signal<
    | {
        key: string;
        kpiTypeId: number;
        title: string;
        type: string;
        params: string;
        path: {
          toReload: () => string;
          toEdit: (id: number) => string;
          toView: (id: number) => string;
          toCreate: () => string;
        };
      }
    | undefined
  >(undefined);

  staticSearchFields = computed(() => {
    const query: SearchFields = { searchFields: {} };

    if (this.subGovDir()) {
      query.searchFields = {
        ...query.searchFields,
        sub_gov_direction_id: {
          value: undefined,
          lov: [`${this.subGovDir()}`],
          order: undefined,
        },
      };
    }
    if (this.pillarId()) {
      query.searchFields = {
        ...query.searchFields,
        pillar_id: {
          value: undefined,
          lov: [`${this.pillarId()}`],
          order: undefined,
        },
      };
    }

    return query;
  });

  kpiPermissionId = computed<number>(() =>
    this.kpiType() ? this._kpisService.getKpiPermission(+this.kpiType()!.kpiTypeId) : 0
  );
  showDeleteAction = computed<boolean>(() =>
    this.kpiType() ? this._kpisService.showDeleteAction(+this.kpiType()!.kpiTypeId) : false
  );

  dataTable = viewChild.required<DataTableComponent>("dataTable");

  readonly #floatActions = genericFloatButtons();
  readonly #untilDestroy = AdaaHelper.untilDestroyed();
  readonly disableEditDeleteRules: ItemAction[] = [
    {
      propertyName: "wfProcessCtlId",
      operator: "notNull",
    },
  ];

  public get isPmoAndE_NT_DTKPI() {
    const isNTKPI = this.type() === "ntkpi";
    const isDTKPI = this.type() === "dtkpi";
    const isEKPI = this.type() === "ekpi";

    if (AdaaHelper.isPMOEntity() && (isNTKPI || isDTKPI || isEKPI)) return true;
    else if (!AdaaHelper.isPMOEntity() && (isNTKPI || isDTKPI || isEKPI)) return false;
    return true;
  }

  private get _kpiTypes() {
    return Object.keys(Constants.KPI_TYPE).map((k) => {
      const kpiType = k.toLowerCase();
      const kpiTypeId = Constants.KPI_TYPE[k as KpiTypeConstantType];
      const title = this._kpisService.getTableTitle(kpiTypeId, "list");
      const configKey = this._kpisService.getConfigKey(kpiTypeId);

      return {
        title,
        kpiTypeId,
        params: `itemType=${kpiTypeId}`,
        key: configKey,
        type: kpiType,
        path: {
          toReload: () => `/console/kpi/${kpiType}`,
          toCreate: () => `/console/kpi/${kpiType}/create`,
          toEdit: (id: number) => `/console/kpi/${kpiType}/edit/${id}`,
          toView: (id: number) => `/console/kpi/${kpiType}/${id}`,
        },
      };
    });
  }

  public ngOnInit() {
    this.#floatActions([
      {
        key: "print",
        data: undefined,
      },
      {
        key: "helpdesk",
        data: {
          url: "/helpdesk/create",
        },
      },
    ]);

    this.customActionButtons.set(this._kpisService.getKpiActionButtons());

    this._getKpiType();
    this._refreshPage();
  }

  public handleTableAction({ event, data }: TableButtonClicked) {
    switch (event) {
      case "view": {
        const url = this.kpiType()?.path.toView(data.id);
        const qs = data.periodId ? { periodId: data.peridoId } : {};

        this.router.navigate([url], {
          queryParams: { ...qs },
        });
        break;
      }

      case "edit": {
        if (this.kpiType()?.type === "nkpi" || this.kpiType()?.type === "uae") return;
        if (this.kpiType()?.type === "opm" && !useNewDS(data.planId)) return;
        this.router.navigate([this.kpiType()?.path.toEdit(data.id)]);
        break;
      }

      case "delete": {
        this._deleteKpi(data);
        break;
      }

      case "successStory": {
        this._handleNtkpiCardFormPopup(data as NtkpiModelType);
        break;
      }

      case "entityComparison": {
        this._openEntityComparison(data.id, data.performanceYear ?? new Date().getFullYear());
        break;
      }

      default:
        break;
    }
  }

  private _deleteKpi($d: TableActionEvent["data"]): void {
    let title: string, info: string;

    if ($d.kpiType === Constants.KPI_TYPE.EKPI) {
      title = "kpi.delete_yes_no_information_ekpi";
      info = "kpi.are_you_sure_ekpi";
    } else {
      title = "kpi.delete_yes_no_information";
      info = "common.form.label.are_you_sure";
    }

    const modal = this._modalService.open(ConfirmationModalComponent, {
      centered: true,
      size: "md",
      modalDialogClass: this.languageService.modalDirection(),
    });

    modal.componentInstance.header = "kpi.delete_yes_no_title";
    modal.componentInstance.title = title;
    modal.componentInstance.subTitle = info;

    modal.result.then((e) => {
      if (e) {
        this._kpisApiService
          .deleteById($d.id)
          .pipe(filter((res) => !res.inError))
          .subscribe({
            next: (res) => {
              const additionalFields = res.additionalFields as { numberOfActivities?: number };
              if (
                AdaaHelper.isDefined(additionalFields) &&
                additionalFields.numberOfActivities === 1 &&
                $d.kpiType === Constants.KPI_TYPE.OPM
              ) {
                this._toastrService.warning(this._translateService.instant("notification.success.last"));
                return;
              }

              this._toastrService.success(this._translateService.instant("notification.success.remove"));
            },
            complete: () => {
              this.dataTable().loadTableData();
            },
          });
      }
    });
  }

  private _getKpiType() {
    this._activatedRoute.params
      .pipe(
        filter((p) => "kpiType" in p),
        this.#untilDestroy()
      )
      .subscribe({
        next: ({ kpiType }) => {
          const validKpiType = this._kpiTypes.find((k) => k.type === kpiType);
          if (!validKpiType) {
            this.router.navigate(["/console/kpi"]);
            return;
          }

          this.kpiType.set(validKpiType);
          this._appTitleService.setTitle(validKpiType.title, true);
          this._breadcrumbService.set("@kpisList", validKpiType.title);

          if (this.kpiType()?.kpiTypeId === Constants.KPI_TYPE.EKPI) this._getYearsListForEkpi();
        },
      });
  }

  private _refreshPage() {
    this._systemLayoutService.hasCycleChanged$.pipe(this.#untilDestroy()).subscribe({
      next: () => {
        if ((!AdaaHelper.isPlanArchived() && this.type() === "nkpi") || this.type() === "uae") {
          this._appService.reloadPage("/console/kpi");
        } else if ((AdaaHelper.isPlanArchived() && this.type() === "ntkpi") || this.type() === "dtkpi") {
          this._appService.reloadPage("/console/kpi");
        } else {
          this._appService.reloadPage(this.kpiType()?.path.toReload() ?? "/console/kpi");
        }
      },
    });

    this._systemLayoutService.hasActiveEntityChanged$.pipe(this.#untilDestroy()).subscribe({
      next: () => this._appService.reloadPage(this.kpiType()?.path.toReload() ?? "/console/kpi"),
    });
  }

  private _getYearsListForEkpi() {
    this._entityMapApiService.getEntityMap(AdaaHelper.plan.id, AdaaHelper.entity.id).subscribe({
      next: (response) => {
        if (response.inError) return;

        const entityYears: ValueText[] = [];
        for (let index = response.responseData.startYear; index <= response.responseData.endYear; index++)
          entityYears.push({ value: index, text: index.toString() });

        this.entityYears.set(entityYears);
      },
    });
  }

  private _openEntityComparison(id: number, currentYear: number) {
    const modal = this._modalService.open(EkpiEntityComparisonComponent, {
      centered: true,
      size: "xl",
      modalDialogClass: this.languageService.modalDirection(),
    });

    modal.componentInstance.id = id;
    modal.componentInstance.currentYear = currentYear;
    modal.componentInstance.entityYears = this.entityYears();
  }

  private _handleNtkpiCardFormPopup(data: NtkpiModelType) {
    this._kpisApiService
      .getById({ id: data.id })
      .pipe(
        filter((res) => !res.inError),
        map((res) => res.responseData)
      )
      .subscribe({
        next: (data) => {
          this._getDateInterval({
            frequency: data.frequency,
            startDate: data.startDate,
            endDate: data.endDate,
            kpiId: data.id,
          });
        },
      });
  }

  private _getDateInterval({
    frequency,
    startDate,
    endDate,
    kpiId,
  }: {
    frequency: number;
    startDate: number;
    endDate: number;
    kpiId: number;
  }) {
    this._periodApiService
      .getDateRange({
        endTS: endDate,
        startTS: startDate,
        frequency,
      })
      .pipe(
        filter((res) => !res.inError),
        map((res) => res.responseData)
      )
      .subscribe({
        next: (data) => {
          const periodList = this._generatePeriodList(data, frequency);

          this._openNtkpiCardModal({
            kpiId,
            periodList,
            periodId: periodList[0].value,
          });
        },
      });
  }

  private _generatePeriodList(data: PeriodModelType[], frequency: number): ValueText[] {
    return data.map((item) => ({ value: item.id, text: this._periodLabel.getLabel({ item, frequency }) }));
  }

  private _openNtkpiCardModal({
    kpiId,
    periodList,
    periodId,
  }: {
    kpiId: number;
    periodId?: number;
    periodList: ValueText[];
  }): void {
    const modal = this._modalService.open(NationalTargetCardModalComponent, {
      centered: true,
      size: "xl",
      modalDialogClass: this.languageService.modalDirection(),
    });

    modal.componentInstance.header = `${this.languageService.translate("ntkpi_card.national_target_card")}`;
    modal.componentInstance.ntkpiId = kpiId;
    modal.componentInstance.periodId = periodId;
    modal.componentInstance.periodList.set(periodList ?? []);
    modal.componentInstance.disableForm.set(false);
  }
}
