import {
  AfterViewInit,
  Component,
  computed,
  effect,
  EventEmitter,
  inject,
  input,
  Output,
  output,
  signal,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { NgbModal, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";

import { PercentageValuePipe } from "../../../../../core/pipes";
import { AdaaHelper } from "../../../../../core/utils";
import { FormCheckboxComponent, NationalTargetCardModalComponent } from "../../../../../shared/components";
import { Constants } from "../../../../../shared/constants/constants";
import {
  ItemTableValueModel,
  KpiAuditInfoType,
  MetricReadingItemValueType,
  MetricReadingModelType,
} from "../../../../../shared/models";
import { LanguageService } from "../../../../../shared/services";
import { MasterEkpiTableValuesComponent } from "../master-ekpi-table-values/master-ekpi-table-values.component";

@Component({
  selector: "adaa-normal-table-values",
  standalone: true,
  imports: [TranslateModule, FormCheckboxComponent, NgbTooltipModule, PercentageValuePipe],
  styleUrl: "../styles.scss",
  templateUrl: "./normal-table-values.component.html",
})
export class NormalTableValuesComponent implements AfterViewInit {
  @ViewChild("webTable", { read: TemplateRef }) webTable: TemplateRef<unknown>;
  @ViewChild("printTable", { read: TemplateRef }) printTable: TemplateRef<unknown>;
  @ViewChild("vc", { read: ViewContainerRef }) vc: ViewContainerRef;
  @Output() setIncludeLegacyKpi = new EventEmitter<boolean>();
  @Output() showNtkpiSuccessStory = new EventEmitter<void>();

  readonly languageService = inject(LanguageService);
  private readonly _modalService = inject(NgbModal);

  isBounded = input.required<boolean>();
  frequency = input.required<number>();
  measurementUnit = input.required<number>();
  kpiType = input.required<number>();
  kpiId = input.required<number>();
  periodId = input<number>();
  periodCycle = input<string>();
  data = input<ItemTableValueModel[]>([]);
  isMasterEkpi = input<boolean>(false);
  showLegacyCheckbox = input<boolean>(false);
  includeLegacyKpi = input<boolean>(false);
  formula = input<string>("");
  hasAuditIssues = input<boolean>(false);
  kpiAudit = input<KpiAuditInfoType[]>([]);
  metricsData = input<MetricReadingModelType[]>([]);
  auditInfo = input<KpiAuditInfoType[]>([]);
  printClicked = output();

  currentTableView = signal<"web" | "print" | undefined>(undefined);

  kpiHasAuditIssues = computed(() => this.auditInfo().filter(({ hasAuditIssue }) => hasAuditIssue).length > 0);
  isNoFormula = computed(() => this.formula() === Constants.FORMULA_STRING.NOFORMULA);
  isNtkpi = computed(() => this.kpiType() === Constants.KPI_TYPE.NTKPI);
  performance = computed(() => this.data().map(({ score, periodId }) => ({ periodId, value: score })));
  applyFlexGrow = computed(() => {
    const annualSpan = this.annualSpan();
    const annualSpanKeys = Object.keys(this.annualSpan());

    if (annualSpanKeys.length === 0) return false;

    const firstKey = annualSpanKeys[0];
    const spanning = annualSpan[Number(firstKey)];

    return spanning === 1;
  });
  sanitizedAuditInfo = computed(() => {
    const data = this.data();
    const auditInfo = this.auditInfo();

    return auditInfo.filter((item) => {
      const index = data.findIndex(($i) => $i.year === item.year);
      return index !== -1;
    });
  });
  annualSpan = computed(() => {
    const data = this.data();
    const groupings: Record<number, number> = {};
    for (const item of data) {
      if (!(item.year in groupings)) {
        groupings[item.year] = 1;
      } else {
        groupings[item.year] += 1;
      }
    }
    return groupings;
  });
  targets = computed(() =>
    this.data().map(({ target, periodId, targetOverride, isBaselineYear }) => {
      return {
        periodId,
        isBaselineYear,
        targetOverride,
        value: this.isPercentage(this.measurementUnit())
          ? AdaaHelper.percentageValue(target)
          : AdaaHelper.roundValue(target, 2, ""),
      };
    })
  );
  actuals = computed(() =>
    this.data().map(({ actual, periodId, ignored, actualOverride }) => {
      return {
        periodId,
        actualOverride,
        ignored,
        value: this.isPercentage(this.measurementUnit())
          ? AdaaHelper.percentageValue(actual)
          : AdaaHelper.roundValue(actual, 2, ""),
      };
    })
  );
  boundedTargets = computed(() =>
    this.data().map(({ lowerLimit, highLimit, periodId, isBaselineYear, targetOverride }) => {
      let low: string, high: string;

      if (!AdaaHelper.isDefined(lowerLimit)) {
        low = "";
      } else {
        low = this.isPercentage(this.measurementUnit())
          ? AdaaHelper.percentageValue(lowerLimit)
          : AdaaHelper.roundValue(lowerLimit, 2, "");
      }

      if (!AdaaHelper.isDefined(highLimit)) {
        high = "";
      } else {
        high = this.isPercentage(this.measurementUnit())
          ? AdaaHelper.percentageValue(highLimit)
          : AdaaHelper.roundValue(highLimit, 2, "");
      }

      return { low, high, periodId, isBaselineYear, targetOverride };
    })
  );
  periods = computed(() => {
    const periods = this.data().map(({ year, month, periodId }) => ({ year, month, periodId }));

    return periods.map(({ year, month, periodId }) => {
      switch (this.frequency()) {
        case Constants.FREQUENCY.EVERY_TWO_YEARS:
        case Constants.FREQUENCY.EVERY_THREE_YEAR:
        case Constants.FREQUENCY.EVERY_FOUR_YEARS:
        case Constants.FREQUENCY.EVERY_FIVE_YEARS:
        case Constants.FREQUENCY.ANNUAL: {
          return { label: year, periodId };
        }

        case Constants.FREQUENCY.SEMIANNUAL: {
          const index = this.#SEMESTERS.findIndex((list) => !!list.find((c) => +c === month));
          return { label: `S${index + 1} ${year}`, periodId };
        }

        case Constants.FREQUENCY.QUARTERLY: {
          const index = this.#QUARTERS.findIndex((list) => !!list.find((c) => +c === month));
          return { label: `Q${index + 1} ${year}`, periodId };
        }

        case Constants.FREQUENCY.MONTHLY: {
          return { label: `${this.#pad(month, 2)} / ${year}`, periodId };
        }

        default: {
          return { label: year, periodId };
        }
      }
    });
  });

  readonly #SEMESTERS = [
    [1, 2, 3, 4, 5, 6],
    [7, 8, 9, 10, 11, 12],
  ];
  readonly #QUARTERS = [
    [1, 2, 3],
    [4, 5, 6],
    [7, 8, 9],
    [10, 11, 12],
  ];

  readonly isDefined = AdaaHelper.isDefined.bind(AdaaHelper);
  readonly isPercentage = (unit?: number) => Constants.MEASUREMENT.CONSTANT_MEASUREMENT_PERCENTAGE === unit;

  readonly #pad = (num: number, size: number) => {
    const result = `${num}`;
    if (result.length < size) {
      return "0" + result;
    }
    return result;
  };

  readonly #viewToggler = () => {
    effect(() => {
      const view = this.currentTableView();
      if (!view) return;
      switch (view) {
        case "web": {
          this.vc.createEmbeddedView(this.webTable);
          break;
        }

        case "print": {
          this.vc.createEmbeddedView(this.printTable);
          break;
        }
      }
    });
  };

  constructor() {
    this.#viewToggler();
  }

  public ngAfterViewInit() {
    this.currentTableView.set("web");
  }

  public getSpanning(year: number) {
    return this.annualSpan()[year];
  }

  public getTarget(id: number) {
    const targets = this.targets();
    const item = targets.find((data) => data?.periodId === id);
    if (item) return item;
    return;
  }

  public getActual(id: number) {
    const actuals = this.actuals();
    const item = actuals.find((data) => data?.periodId === id);
    if (item) return item;
    return;
  }

  public getBounded(id: number) {
    const boundedTargets = this.boundedTargets();
    const item = boundedTargets.find((data) => data?.periodId === id);
    if (item) return item;
    return;
  }

  public getAuditPerformance(val?: number | "N/A", usePercentage = false, isNA: boolean = false) {
    if (!AdaaHelper.isDefined(val) && !isNA) return null;
    if (isNA) return "N/A";
    if (usePercentage) return AdaaHelper.percentageValue(val, 2, "");
    if (this.isPercentage(this.measurementUnit())) return AdaaHelper.percentageValue(val, 2, "");
    return AdaaHelper.roundValue(val!, 2, "");
  }

  public getMetricValue(periodId: number, mertricReading: MetricReadingItemValueType[] = []) {
    const metric = mertricReading.find((data) => data?.periodId === periodId);
    if (!AdaaHelper.isDefined(metric)) return;
    return metric;
  }

  public getScore(id: number) {
    const perf = this.performance();
    const item = perf.find((data) => data?.periodId === id);
    if (AdaaHelper.isDefined(item)) return item.value;
    return;
  }

  public openEkpiTableValuesModal() {
    const modal = this._modalService.open(MasterEkpiTableValuesComponent, {
      centered: true,
      size: "xl",
      modalDialogClass: this.languageService.modalDirection(),
    });

    modal.componentInstance.periodId.set(this.periodId());
    modal.componentInstance.kpiId.set(this.kpiId());
    modal.componentInstance.frequency.set(this.frequency());
    modal.componentInstance.periodCycle.set(this.periodCycle());
    modal.componentInstance.measurementUnit.set(this.measurementUnit());
    modal.componentInstance.isNoFormula.set(this.isNoFormula());
    modal.componentInstance.isBounded.set(this.isBounded());
  }

  public openEditNtkpiCardModal(periodId: number): void {
    const label = this._getLabelForPeriodColumn(periodId);
    const modal = this._modalService.open(NationalTargetCardModalComponent, {
      centered: true,
      size: "xl",
      modalDialogClass: this.languageService.modalDirection(),
    });

    modal.componentInstance.header = `${this.languageService.translate("ntkpi_card.national_target_card")} (${label})`;
    modal.componentInstance.periodId = periodId;
    modal.componentInstance.ntkpiId = this.kpiId();
    modal.componentInstance.disableForm.set(true);
  }

  private _getLabelForPeriodColumn(id: number): string {
    const period = this.data().find(({ periodId }) => periodId === id);

    switch (this.frequency()) {
      case Constants.FREQUENCY.SEMIANNUAL: {
        const index = this.#SEMESTERS.findIndex((list) => !!list.find((c) => +c === period?.month));
        return `${this.languageService.translate(`targets.periods.semestral.${index + 1}`)} ${period?.year}`;
      }

      case Constants.FREQUENCY.QUARTERLY: {
        const index = this.#QUARTERS.findIndex((list) => !!list.find((c) => +c === period?.month));
        return `${period?.year} ${this.languageService.translate(`data_entry.q${index + 1}`)}`;
      }

      case Constants.FREQUENCY.MONTHLY: {
        return `${AdaaHelper.getMonthName(period?.month, AdaaHelper.getCurrentLang(), false)} ${period?.year}`;
      }

      default: {
        return period?.year ? period?.year?.toString() : "";
      }
    }
  }
}
