import { Component, inject, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "adaa-send-confirmation-modal",
  standalone: true,
  imports: [TranslateModule],
  templateUrl: "./send-confirmation-modal.component.html",
  styleUrl: "./send-confirmation-modal.component.scss",
})
export class SendConfirmationModalComponent {
  @Input() header: string;
  @Input() title: string;
  @Input() subTitle: string = "common.form.label.are_you_sure";
  @Input() yesLabel: string = "common.form.button.confirm";
  @Input() noLabel: string = "common.form.button.cancel";
  @Input() items: string[];

  modal: NgbActiveModal = inject(NgbActiveModal);
}
