<div class="col-md-16 list__info--container info--wrapper--border">
    <div class="col-xs-16 list__info--container-search">
        <p class="form-label">{{ "notification_config.templates.modal.placeholders_title" | translate }}</p>
        <div class="input-group">
            <span class="input-group-text">
                <fa-icon [icon]="faSearch" [title]="'breadcrumbs.search' | translate"></fa-icon>
            </span>
            <input
                type="text"
                class="form-control"
                [placeholder]="'common.form.label.search' | translate"
                (input)="search($event)"
                [class.rtl]="AdaaHelper.getCurrentLang() === Language.Arabic"
            />
        </div>
    </div>
    <div class="col-xs-16 list__info--container-list">
        <ul>
            <li *ngFor="let item of searchValues() ?? items()" draggable="true" (dblclick)="onPlaceholderDoubleClick(item)">
                {{ getDescription(item) }}
            </li>
        </ul>
    </div>
</div>
