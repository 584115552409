<div class="w-100">
    <adaa-period-slider [activePeriod]="selectedPeriod()!" type="year" (periodSelect)="selectPeriod($event)"></adaa-period-slider>
</div>

<div class="ribbon" [class.ar]="lang() === Language.Arabic">
    <adaa-ribbon [showLogo]="true" [widgetData]="dashboardWidgets()" (refreshClicked)="queryData()">
        <ng-container ribbonLogo>
            <img
                class="ribbon-logo"
                src="assets/images/national-strategies/national-strategy.png"
                [alt]="'national_strategies.strategies' | translate"
            />
        </ng-container>
    </adaa-ribbon>
</div>

<div class="position-relative row bg-white mt-3 shadow tag-2" [class.ar]="lang() === Language.Arabic">
    <div class="position-relative col-md-5 col-sm-12">
        <h4 class="fw-bold mt-3 px-3 w-100">{{ dashboardData() | translateToken: "name" }}</h4>

        <img class="strategy-info--logo mt-3" [src]="dashboardImage()" [attr.alt]="dashboardData() | translateToken: 'entityName'" />
    </div>

    <div class="position-relative col-md-2 col-sm-12 d-flex justify-content-center flex-wrap">
        <adaa-doughnut
            size="xs"
            [amount]="dashboardData()?.visionScore!"
            [colorId]="dashboardData()?.visionScoreColor!"
            [description]="'national_projects.target' | translate"
        />
        <h6 class="fw-bold w-100 fs-6 text-center px-1">{{ "national_projects.target" | translate }}</h6>
    </div>

    <div class="position-relative col-md-5 col-sm-12 d-flex align-items-center justify-content-center flex-sm-wrap">
        <section class="w-50 d-block px-1 flex-shrink-0 flex-grow-0 mx-1">
            <span class="w-100 d-block position-relative fw-bold mx-2 text-dark" style="text-align: justify">
                {{ "national_strategies.project_progress" | translate }}
                <span>: &nbsp; {{ dashboardData()?.progress | percentageValue }}</span>
            </span>

            <div
                class="progress my-2 w-100 d-flex align-items-center position-relative mx-2"
                role="progressbar"
                aria-valuenow="50"
                aria-valuemin="0"
                aria-valuemax="100"
                style="height: 15px; border-radius: 3px"
                [attr.aria-label]="'national_strategies.project_progress' | translate"
            >
                <div
                    class="progress-bar"
                    style="height: 30px; border-radius: 3px"
                    [style.background-color]="getColor(dashboardData()?.progressColor!)"
                    [style.width]="dashboardData()?.progress | percentageValue"
                ></div>
            </div>
        </section>

        <section class="w-50 d-block px-1 flex-shrink-0 flex-grow-0 mx-1">
            <span class="w-100 d-block position-relative fw-bold mx-2 text-dark" style="text-align: justify">
                {{ "national_strategies.annual_preformance" | translate }}
                <span>: &nbsp; {{ dashboardData()?.score | percentageValue }}</span>
            </span>

            <div
                class="progress my-2 w-100 d-flex align-items-center position-relative mx-2"
                role="progressbar"
                aria-valuenow="50"
                aria-valuemin="0"
                aria-valuemax="100"
                style="height: 15px; border-radius: 3px"
                [attr.aria-label]="'national_strategies.annual_preformance' | translate"
            >
                <div
                    class="progress-bar"
                    style="height: 15px; border-radius: 3px"
                    [style.background-color]="getColor(dashboardData()?.scoreColor!)"
                    [style.width]="dashboardData()?.score | percentageValue"
                ></div>
            </div>
        </section>
    </div>
</div>

<div class="row mt-3 d-flex align-items-stretch">
    <div class="col-md-6 col-sm-12">
        <div class="card bg-white" style="min-height: 80px; max-height: 100px">
            <div class="card-title px-3 pt-2 m-0">
                <h4 class="fw-bold">{{ "directions.vision" | translate }}</h4>
            </div>

            <div class="card-body py-0">
                <p>{{ dashboardData() | translateToken: "dsc" }}</p>
            </div>
        </div>
    </div>

    <div class="col-md-6 col-sm-12">
        <div class="card bg-white" style="min-height: 80px; max-height: 100px">
            <div class="card-title px-3 pt-2 m-0">
                <h4 class="fw-bold">{{ "breadcrumbs.ntkpi_module.list" | translate }}</h4>
            </div>

            <div class="card-body py-0">
                <p>{{ dashboardData()?.ntKpi | translateToken: "name" }}</p>
            </div>
        </div>
    </div>
</div>

<div class="position-relative d-flex justify-content-center align-items-stretch flex-wrap mt-3">
    @for (child of dashboardData()?.nationalStrategyChildren; track child.id) {
        <div
            class="card m-2 d-flex flex-column justify-content-between bg-white strategy-child"
            [routerLink]="['/', 'national-strategies-dashboard', 'strategy', strategyId(), 'objective', child.id]"
        >
            <div class="child-title p-3 fw-bold d-flex">
                <span class="number">{{ $index + 1 }}</span>
                <span>
                    {{ child | translateToken: "name" }}
                    @if (child.isMain) {
                        <span class="badge text-bg-primary text-white rounded-pill mx-1">{{ "directions.is_main" | translate }}</span>
                    }
                </span>
            </div>

            <div class="child-progress d-flex justify-content-center flex-wrap px-4">
                <adaa-progress-indicator
                    class="w-100"
                    [value]="child.progressScore!"
                    [color]="child.progressScoreColor!"
                    [label]="'national_strategies.project_progress' | translate"
                    [height]="21"
                />

                <adaa-progress-indicator
                    class="w-100"
                    [value]="child.score!"
                    [color]="child.scoreColor!"
                    [label]="'national_strategies.annual_preformance' | translate"
                    [height]="21"
                />

                <adaa-progress-indicator
                    class="w-100"
                    [value]="child.visionScore!"
                    [color]="child.visionScoreColor!"
                    [label]="'national_projects.target_achieved' | translate"
                    [height]="21"
                />
            </div>

            <div class="child-footer d-flex justify-content-around p-3 flex-nowrap align-items-center">
                <span class="d-block p-2 w-25 border border-muted rounded-pill text-center">
                    {{ "breadcrumbs.kpi" | translate }} {{ child.kpiCount > 0 ? child.kpiCount : "-" }}
                </span>

                <span class="d-block p-2 w-25 border border-muted rounded-pill text-center">
                    {{ "national_projects.projects" | translate }} {{ child.initiativesCount > 0 ? child.initiativesCount : "-" }}
                </span>
            </div>
        </div>
    }
</div>
