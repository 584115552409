import { Component, input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

import { TranslateTokenPipe } from "../../../../../core/pipes";

@Component({
  selector: "adaa-left-side-list",
  standalone: true,
  imports: [TranslateModule, TranslateTokenPipe],
  styleUrl: "../styles.scss",
  template: `
    <section class="w-100 d-flex flex-column flex-wrap mt-2">
      @if (!list()?.length) {
        <div class="w-100 text-center text-dark bg-white fw-bold border-1 border-secondary py-2 mt-1">
          {{ "notification.warning.missing_data" | translate }}
        </div>
      } @else {
        @for (item of list(); track item.id) {
          <div class="w-100 text-center text-primary bg-white border-1 border-secondary-subtle py-2 mt-1 shadow-sm">
            {{ item | translateToken: token() }}
          </div>
        }
      }
    </section>
  `,
})
export class LeftSideListComponent {
  token = input<string>("name");
  list =
    input<
      { id: number; nameAE?: string; nameEN?: string; organizationNameAE?: string; organizationNameEN?: string }[]
    >();
}
