import { CommonModule } from "@angular/common";
import { Component, inject, input } from "@angular/core";
import { Router, RouterModule } from "@angular/router";
import { FaIconLibrary, FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { TranslateModule } from "@ngx-translate/core";

import { CurrencyValuePipe, TranslateTokenPipe } from "../../../../core/pipes";
import { AdaaHelper } from "../../../../core/utils";
import {
  CommentsComponent,
  DoughnutComponent,
  EntityPlanChangeDetectionComponent,
  FloatActionComponent,
  PictureComponent,
  ProgressIndicatorComponent,
  ReadMoreComponent,
} from "../../../../shared/components";
import { Constants } from "../../../../shared/constants/constants";
import { AgmMilestoneDetailsService, SystemLayoutService } from "../../../../shared/services";

@Component({
  selector: "adaa-agm-milestone-view",
  imports: [
    RouterModule,
    TranslateModule,
    CommonModule,
    FontAwesomeModule,
    TranslateTokenPipe,
    CurrencyValuePipe,
    DoughnutComponent,
    ProgressIndicatorComponent,
    PictureComponent,
    ReadMoreComponent,
    CommentsComponent,
    FloatActionComponent,
    EntityPlanChangeDetectionComponent,
  ],
  templateUrl: "./agm-milestone-view.component.html",
  styleUrl: "./agm-milestone-view.component.scss",
})
export class AgmMilestoneViewComponent {
  private _iconLibrary = inject(FaIconLibrary);
  private _router = inject(Router);
  private _systemLayoutService = inject(SystemLayoutService);
  agmMilestoneDetailsService = inject(AgmMilestoneDetailsService);

  id = input.required<string>();

  private readonly _untilDestroyed = AdaaHelper.untilDestroyed();
  AdaaHelper = AdaaHelper;
  Constants = Constants;

  public get contributingEntities() {
    if (!AdaaHelper.isDefined(this.agmMilestoneDetailsService.milestone())) return [];
    const list = this.agmMilestoneDetailsService.milestone()!.contributingEntities || [];
    return list.filter((e) => !AdaaHelper.isDefined(e.otherEntityId));
  }

  public get otherContributingEntities() {
    if (!AdaaHelper.isDefined(this.agmMilestoneDetailsService.milestone())) return [];
    const list = this.agmMilestoneDetailsService.milestone()!.contributingEntities || [];
    return list.filter((e) => AdaaHelper.isDefined(e.entityId) && AdaaHelper.isDefined(e.otherEntityId));
  }

  public ngOnInit(): void {
    this._iconLibrary.addIcons(faFileDownload);

    this._systemLayoutService.hasCycleChanged$.pipe(this._untilDestroyed()).subscribe({
      next: () => this._router.navigateByUrl("console/national-project/milestones"),
    });
    this._systemLayoutService.hasActiveEntityChanged$.pipe(this._untilDestroyed()).subscribe({
      next: () => this._router.navigateByUrl("console/national-project/milestones"),
    });
  }
}
