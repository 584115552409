import { Component, inject, OnInit, signal } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

import { AdaaHelper } from "../../../core/utils";
import { DataTableComponent } from "../../../shared/components";
import { Constants } from "../../../shared/constants/constants";
import { TableButtonClicked } from "../../../shared/models";
import { UserProfileApiService } from "../../../shared/services";

@Component({
  selector: "adaa-profile-responsibilities",
  standalone: true,
  imports: [TranslateModule, DataTableComponent],
  templateUrl: "./profile-responsibilities.component.html",
  styleUrl: "./profile-responsibilities.component.scss",
})
export class ProfileResponsibilitiesComponent implements OnInit {
  private _userProfileService = inject(UserProfileApiService);
  private _router = inject(Router);
  constants = Constants;

  tableConfListResponsibilities = Constants.MAIN_TABLE_LIST_CONF_KEY.USERS_PROFILE_OWNED_OBJECT;
  tableConfListEntities = Constants.MAIN_TABLE_LIST_CONF_KEY.USERS_RESPONSIBLE_FOR_OBJECT;

  userResponsibilities = signal<object[]>([]);
  userOwnedObjects = signal<object[]>([]);
  entityId = signal<number | undefined>(0);

  public ngOnInit(): void {
    const entityId = AdaaHelper.getLocalStorage(Constants.localStorageKeys.user, {
      type: "prop",
      property: "entityId",
    });
    this.entityId.set(+entityId);
    this._getUserResponsibilities();
    this._getOwnedObjects();
  }

  private _getUserResponsibilities() {
    const currentUserId = AdaaHelper.getLocalStorage(Constants.localStorageKeys.user, {
      type: "prop",
      property: "id",
    });
    this._userProfileService.getUserEntitiesResponsibleFor(+currentUserId).subscribe({
      next: (response) => this.userResponsibilities.set(response.responseData as object[]),
    });
  }

  private _getOwnedObjects() {
    this._userProfileService.getOwnedObjects().subscribe({
      next: (response) => this.userOwnedObjects.set(response.responseData),
    });
  }

  public navigate(data: TableButtonClicked["data"]) {
    switch (data.itemTypeId) {
      case Constants.CONSTANT_SUBGOVDIRECTION:
        this._router.navigateByUrl(`console/directions/${data.govDirectionId}`);
        break;
      case Constants.CONSTANT_SKPITYPE:
        this._router.navigateByUrl(`console/kpi/skpi/${data.id}`);
        break;

      default:
        break;
    }
  }
}
