<adaa-data-table
    #adaaDataTable
    [titleHeader]="'national_projects.transformational_targets.title'"
    apiName="getAll"
    apiPrefix="transformational-target"
    [configKey]="tableConfList()"
    [itemTypeId]="Constants.CONSTANT_PERMISSIONS.TP_CYCLES"
    [enableEditAction]="true"
    [enableDeleteAction]="true"
    [enableViewAction]="true"
    [enableAdd]="true"
    (addNewClicked)="addNewClicked()"
    (actionClicked)="getAction($event)"
/>

<adaa-float-action />
