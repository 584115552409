<adaa-data-table
    #adaaDataTable
    [titleHeader]="'national_projects.cycles.tp_cycles'"
    [configKey]="'tp_cycles_conf_list'"
    apiName="getAll"
    apiPrefix="national-project-cycle"
    [configKey]="tableConfList()"
    [itemTypeId]="constants.CONSTANT_PERMISSIONS.TP_CYCLES"
    [enableEditAction]="true"
    [enableDeleteAction]="true"
    [enableViewAction]="true"
    [enableAdd]="true"
    (addNewClicked)="addNewClicked()"
    (actionClicked)="getAction($event)"
/>

<adaa-float-action />
