import { inject, Injectable } from "@angular/core";
import { filter, switchMap, take, timer } from "rxjs";

import { DimensionsJobSchedule, MainResponseModel } from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class DimensionsService {
  private _mainService = inject(MainService);

  public configureStructureScheduler(data: { cron: string }) {
    return this._mainService.sendRequest<MainResponseModel<null>>({
      method: "POST",
      url: "libmetric/configureStructureScheduler",
      data: data,
    });
  }

  public manualTriggerStructure() {
    return this._mainService.sendRequest<MainResponseModel<null>>({
      method: "POST",
      url: "libmetric/manualTriggerStructure",
      data: {},
    });
  }

  public manualTriggerValue() {
    return this._mainService.sendRequest<MainResponseModel<null>>({
      method: "POST",
      url: "libmetric/manualTriggerValue",
      data: {},
    });
  }

  public configureValueScheduler(data: { cron: string }) {
    return this._mainService.sendRequest<MainResponseModel<null>>({
      method: "POST",
      url: "libmetric/configureValueScheduler",
      data: data,
    });
  }

  public getCurrentStructureScheduler() {
    return this._mainService.sendRequest<MainResponseModel<DimensionsJobSchedule>>({
      method: "GET",
      url: "libmetric/getCurrentStructureScheduler",
    });
  }

  public getCurrentValueScheduler() {
    return this._mainService.sendRequest<MainResponseModel<DimensionsJobSchedule>>({
      method: "GET",
      url: "libmetric/getCurrentValueScheduler",
    });
  }

  public disableStructureScheduler() {
    return this._mainService.sendRequest<MainResponseModel<null>>({
      method: "POST",
      url: "libmetric/disableStructureScheduler",
      data: {},
    });
  }

  public disableValueScheduler() {
    return this._mainService.sendRequest<MainResponseModel<null>>({
      method: "POST",
      url: "libmetric/disableValueScheduler",
      data: {},
    });
  }

  public getSyncStatus(multiplier: number, scheduleType: "structure" | "value") {
    return timer(1000, 2000 + multiplier * 1000).pipe(
      switchMap(() => this.checkRunningSchedule(scheduleType)),
      filter(
        (
          response: MainResponseModel<{
            lastDate: number;
            isRunning: boolean;
          }>
        ) => response.responseData.isRunning === false
      ),
      take(1)
    );
  }

  public checkRunningSchedule(scheduleType: "structure" | "value") {
    const url =
      scheduleType === "structure"
        ? "libmetric/getStructureSchedulerRunningResponse"
        : "libmetric/getValueSchedulerRunningResponse";

    return this._mainService.sendRequest<
      MainResponseModel<{
        lastDate: number;
        isRunning: boolean;
      }>
    >({
      method: "GET",
      url,
      loader: false,
    });
  }
}
