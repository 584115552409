import { Route } from "@angular/router";

import { checkIfLoggedInFn, pmoEntity } from "../../core/guards";
import { SynchronizationScheduleComponent } from "../../shared/components/synchronization-schedule/synchronization-schedule.component";
import { Constants } from "../../shared/constants/constants";
import { viewKhadamatiPagesGuards } from "./_guards";
import { KhadamatiSubmenuComponent } from "./khadamati-submenu/khadamati-submenu.component";
import { KhadamatiSynchronizationListComponent } from "./khadamati-synchronization-list/khadamati-synchronization-list.component";

export const KHADAMATI_ROUTES: Route[] = [
  {
    path: "khadamati",
    canActivate: [checkIfLoggedInFn, pmoEntity, ...viewKhadamatiPagesGuards],
    children: [
      {
        path: "",
        component: KhadamatiSubmenuComponent,
        data: {
          breadcrumb: "breadcrumbs.khadamati.title",
          title: "breadcrumbs.khadamati.title",
        },
      },
      {
        path: "list",
        component: KhadamatiSynchronizationListComponent,
        data: {
          breadcrumb: "breadcrumbs.khadamati.list",
          title: "breadcrumbs.khadamati.list",
          tableConfList: Constants.MAIN_TABLE_LIST_CONF_KEY.KHADAMATI,
          selectedConfList: Constants.MAIN_TABLE_LIST_CONF_KEY.KHADAMATI_DETAIL,
        },
      },
      {
        path: "schedule",
        component: SynchronizationScheduleComponent,
        data: {
          breadcrumb: "breadcrumbs.khadamati.schedule",
          title: "breadcrumbs.khadamati.schedule",
          isDimensions: false,
        },
      },
    ],
  },
];
