import { Component, inject, OnInit, signal } from "@angular/core";
import { FaIconLibrary, FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faList, faTable } from "@fortawesome/free-solid-svg-icons";

import { DubaiTimePipe } from "../../core/pipes";
import { AdaaHelper } from "../../core/utils";
import {
  FloatActionComponent,
  NestedTableComponent,
  ObjectsTreeComponent,
  PeriodSliderVariantComponent,
} from "../../shared/components";
import { genericFloatButtons } from "../../shared/components/general/float-action";
import { Constants } from "../../shared/constants/constants";
import { Language } from "../../shared/constants/enums";
import { ObjectiveModelType, SelectedPeriodModelType, StructureViewColumnConfig } from "../../shared/models";
import {
  ObjectivesApiService,
  PropertiesService,
  StructureViewService,
  SystemLayoutService,
} from "../../shared/services";

@Component({
  selector: "adaa-plan-navigation",
  standalone: true,
  imports: [
    FontAwesomeModule,
    PeriodSliderVariantComponent,
    NestedTableComponent,
    ObjectsTreeComponent,
    FloatActionComponent,
    DubaiTimePipe,
  ],
  templateUrl: "./plan-navigation.component.html",
  styleUrl: "./plan-navigation.component.scss",
})
export class PlanNavigationComponent implements OnInit {
  private _objectivesApiService = inject(ObjectivesApiService);
  private _propertiesService = inject(PropertiesService);
  private _systemLayoutService = inject(SystemLayoutService);
  private _iconLibrary = inject(FaIconLibrary);
  structureViewService = inject(StructureViewService);

  private readonly _genericFloatButtons = genericFloatButtons();
  private readonly _untilDestroyed = AdaaHelper.untilDestroyed();
  constants = Constants;
  planPeriod = signal<{ startDate: number; endDate: number }>({ startDate: 0, endDate: 0 });
  objectives = signal<ObjectiveModelType[]>([]);
  columns: StructureViewColumnConfig[] = [
    {
      title: "common.tables.type",
      width: "15%",
      type: "type",
      alignment: AdaaHelper.getCurrentLang() === Language.Arabic ? "R" : "L",
    },
    {
      title: "common.tables.name",
      width: "25%",
      type: "text",
      valueKey: AdaaHelper.getFieldLanguage("name"),
      alignment: AdaaHelper.getCurrentLang() === Language.Arabic ? "R" : "L",
      showTags: AdaaHelper.isPlanArchived(),
    },
    {
      title: "common.tables.performance",
      width: "15%",
      type: "percentage",
      colorKey: "evaluationPerformance",
      valueKey: "performance",
    },
    {
      title: "ekpi.ytp",
      width: "15%",
      type: "percentage",
      colorKey: "evaluationYtp",
      valueKey: "ytp",
    },
    {
      title: "common.tables.info",
      width: "10%",
      type: "status",
      valueKey: "info",
    },
    {
      title: "common.tables.trend",
      width: "10%",
      type: "trend",
      valueKey: "trend",
    },
    {
      title: "common.tables.actions",
      width: "8%",
      type: "actions",
      valueKey: "type",
    },
  ];
  columnsTree: StructureViewColumnConfig[] = AdaaHelper.isPlanArchived()
    ? [
        {
          title: "common.tables.name",
          width: "35%",
          type: "text",
          valueKey: AdaaHelper.getFieldLanguage("name"),
          alignment: AdaaHelper.getCurrentLang() === Language.Arabic ? "R" : "L",
          showTags: true,
        },
        {
          title: "common.tables.period_performance_short",
          width: "15%",
          type: "percentage",
          colorKey: "evaluationPerformance",
          valueKey: "performance",
        },
        {
          title: "common.tables.annual_performance_short",
          width: "15%",
          type: "percentage",
          colorKey: "evaluationYtp",
          valueKey: "ytp",
        },
        {
          title: "common.tables.info",
          width: "15%",
          type: "status",
          valueKey: "info",
        },
        {
          title: "common.tables.trend",
          width: "10%",
          type: "trend",
          valueKey: "trend",
        },
        {
          title: "common.tables.actions",
          width: "8%",
          type: "actions",
          valueKey: "type",
        },
      ]
    : [
        {
          title: "common.tables.type",
          width: "15%",
          type: "type",
          alignment: AdaaHelper.getCurrentLang() === Language.Arabic ? "R" : "L",
        },
        {
          title: "common.tables.name",
          width: "25%",
          type: "text",
          valueKey: AdaaHelper.getFieldLanguage("name"),
          alignment: AdaaHelper.getCurrentLang() === Language.Arabic ? "R" : "L",
        },
        {
          title: "common.tables.period_performance_short",
          width: "15%",
          type: "percentage",
          colorKey: "evaluationPerformance",
          valueKey: "performance",
        },
        {
          title: "common.tables.annual_performance_short",
          width: "15%",
          type: "percentage",
          colorKey: "evaluationYtp",
          valueKey: "ytp",
        },
        {
          title: "common.tables.info",
          width: "10%",
          type: "status",
          valueKey: "info",
        },
        {
          title: "common.tables.trend",
          width: "10%",
          type: "trend",
          valueKey: "trend",
        },
        {
          title: "common.tables.actions",
          width: "8%",
          type: "actions",
          valueKey: "type",
        },
      ];

  public ngOnInit(): void {
    this._setPeriod();
    this._getTypes();
    this._floatActions();

    this._iconLibrary.addIcons(faTable, faList);

    this._systemLayoutService.hasCycleChanged$.pipe(this._untilDestroyed()).subscribe({
      next: () => window.location.reload(),
    });
    this._systemLayoutService.hasActiveEntityChanged$.pipe(this._untilDestroyed()).subscribe({
      next: () => window.location.reload(),
    });
  }

  public selectPeriod(period: Pick<SelectedPeriodModelType, "period">) {
    if (!period) return;
    this.structureViewService.selectedPeriod.update(() => period);
    this._getObjectives();
  }

  private _getObjectives() {
    if (!AdaaHelper.isDefined(this.structureViewService.selectedPeriod())) return;

    this._objectivesApiService
      .getItemObjectivesByEntityAndPlanV2({
        entityId: AdaaHelper.entity?.id as number,
        planId: AdaaHelper.plan?.id as number,
        periodId: this.structureViewService.selectedPeriod()!.period.id,
      })
      .subscribe({
        next: (response) => {
          this.objectives.set(response.responseData);
        },
      });
  }

  private _setPeriod(): void {
    const plan = AdaaHelper.plan as { startYear: number; endYear: number };
    if (!plan) return;
    const dates = {
      startDate: plan.startYear,
      endDate: plan.endYear,
    };
    this.planPeriod.set(dates);
  }

  private _getTypes(): void {
    this._propertiesService.getAllItemTypes().subscribe({
      next: (response) => {
        if (response.inError) return;

        this.structureViewService.types.set(response.responseData);
      },
    });
  }

  private _floatActions(): void {
    this._genericFloatButtons([
      {
        key: "helpdesk",
        data: {
          url: "/helpdesk/create",
        },
      },
      {
        key: "print",
        data: undefined,
      },
    ]);
  }
}
