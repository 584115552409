import { Route } from "@angular/router";

import { checkIfLoggedInFn } from "../../core/guards";
import { isEntityDashboardGuard, isPMODashboardGuard } from "./_router.guards";
import { DkpiComponent } from "./dkpi/dkpi.component";
import { EnablerComponent } from "./enabler/enabler.component";
import { EntityComponent } from "./entity/entity.component";
import { ExecutiveDashboardComponent } from "./executive-dashboard/executive-dashboard.component";
import { GovServiceTeamComponent } from "./gov-service-team/gov-service-team.component";
import { NatComponent } from "./nat/nat.component";
import { NationalAgendaComponent } from "./national-agenda/national-agenda.component";
import { PmoComponent } from "./pmo/pmo.component";
import { SDG_ROUTES } from "./sdg/sdg.routes";

export const DASHBOARD_ROUTES: Route[] = [
  {
    path: "wtuae",
    component: NationalAgendaComponent,
    canActivate: [checkIfLoggedInFn],
    data: {
      title: "national_agenda.main_dashboard.page_title",
      breadcrumb: "national_agenda.main_dashboard.page_title",
    },
  },
  {
    path: "executive-dashboard",
    component: ExecutiveDashboardComponent,
    canActivate: [checkIfLoggedInFn],
    data: {
      title: "executive_dashboard.title",
      breadcrumb: "executive_dashboard.title",
    },
  },
  {
    path: "dashboard/target",
    component: PmoComponent,
    canActivate: [checkIfLoggedInFn, isPMODashboardGuard],
    data: {
      title: "navigation.home",
      breadcrumb: "navigation.home",
      isPmoDashboard: true,
    },
  },
  {
    path: "dashboard/entity",
    component: EntityComponent,
    canActivate: [checkIfLoggedInFn, isEntityDashboardGuard],
    data: {
      title: "landingPage.menu.entity",
      breadcrumb: "landingPage.menu.entity",
      isPmoDashboard: false,
    },
  },
  {
    path: "dashboard/dkpi",
    component: DkpiComponent,
    canActivate: [checkIfLoggedInFn, isPMODashboardGuard],
    data: {
      title: "landingPage.menu.dkpi",
      breadcrumb: "landingPage.menu.dkpi",
      isPmoDashboard: true,
    },
  },
  {
    path: "dashboard/get",
    component: EnablerComponent,
    canActivate: [checkIfLoggedInFn, isPMODashboardGuard],
    data: {
      title: "landingPage.menu.dkpi",
      breadcrumb: "landingPage.menu.dkpi",
      isPmoDashboard: true,
    },
  },
  {
    path: "dashboard/gspd",
    component: GovServiceTeamComponent,
    canActivate: [checkIfLoggedInFn, isPMODashboardGuard],
    data: {
      title: "landingPage.menu.gspd",
      breadcrumb: "landingPage.menu.gspd",
      isPmoDashboard: true,
    },
  },
  {
    path: "dashboard/nat",
    component: NatComponent,
    canActivate: [checkIfLoggedInFn, isPMODashboardGuard],
    data: {
      title: "landingPage.menu.nat",
      breadcrumb: "landingPage.menu.nat",
      isPmoDashboard: true,
    },
  },
  {
    path: "dashboard/skpi-opm",
    loadComponent: () => import("./skpi-opm/skpi-opm.component").then((e) => e.SkpiOpmComponent),
    canActivate: [checkIfLoggedInFn, isPMODashboardGuard],
    data: {
      title: "landingPage.menu.skpi_opm_team",
      breadcrumb: "landingPage.menu.skpi_opm_team",
      isPmoDashboard: true,
    },
  },
  ...SDG_ROUTES,
];
