<adaa-data-table
    #agmTable
    titleHeader="annual_meetings.page_title"
    [configKey]="constants.MAIN_TABLE_LIST_CONF_KEY.AGM_PROJECTS_CONF_LIST"
    apiName="getAllCamelCase"
    apiPrefix="annual-meeting-project"
    [itemTypeId]="constants.CONSTANT_PERMISSIONS.ANNUAL_MEETINGS_PROJECTS"
    [tableWithChildren]="true"
    childKey="annualMeetingProjectMilestones"
    [enableChildActionsCell]="true"
    [enableViewChildAction]="true"
    [enableEditChildAction]="true"
    [disableEditRules]="disableEditDeleteRules"
    [disableDeleteRules]="disableEditDeleteRules"
    [hideDeleteRules]="hideDeleteEditRules"
    [hideEditRules]="hideDeleteEditRules"
    (addNewClicked)="addNewClicked()"
    (actionClicked)="actionClicked($event)"
    (childClicked)="childClicked($event)"
></adaa-data-table>

<adaa-float-action />
