/* eslint-disable no-useless-escape */
import { NgClass, UpperCasePipe } from "@angular/common";
import { Component, computed, EventEmitter, inject, input, OnInit, Output, signal } from "@angular/core";
import { AbstractControl, FormControlStatus } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { combineLatest, filter, map, Observable } from "rxjs";

import { AdaaBooleanType } from "../../../../../../adaa-types";
import { SafeHtmlPipe } from "../../../../../core/pipes";
import { AdaaHelper } from "../../../../../core/utils";
import { Constants } from "../../../../../shared/constants/constants";
import { AdaaBoolean } from "../../../../../shared/constants/enums";
import {
  DataEntryMetricModel,
  KpiMetricModelType,
  MainResponseModel,
  ObjectStatus,
  ParameterCatalog,
  ValueText,
} from "../../../../../shared/models";
import {
  FormulaParserService,
  FormulasApiService,
  LanguageService,
  MetricApiService,
  ValidatorApiService,
} from "../../../../../shared/services";
import { KpiFormulaMetricsComponent } from "../kpi-formula-metrics/kpi-formula-metrics.component";

@Component({
  selector: "adaa-kpi-formula",
  standalone: true,
  imports: [TranslateModule, NgClass, UpperCasePipe, SafeHtmlPipe],
  providers: [FormulaParserService],
  styleUrl: "../styles.scss",
  templateUrl: "./kpi-formula.component.html",
})
export class KpiFormulaComponent implements OnInit {
  readonly languageService = inject(LanguageService);
  private readonly _modalService = inject(NgbModal);
  private readonly _toastrService = inject(ToastrService);
  private readonly _metricsApiService = inject(MetricApiService);
  private readonly _validatorApiService = inject(ValidatorApiService);
  private readonly _formulasApiService = inject(FormulasApiService);
  private readonly _formulaParserService = inject(FormulaParserService);

  kpiType = input.required<number>();
  required = input.required<boolean>();
  hideValidateButton = input.required<boolean>();
  ytpCalcOptions = input.required<ValueText[]>();
  hideYtpCalc = input.required<boolean>();
  stagedKpi = input<Record<string, unknown>>();
  kpi = input<Record<string, unknown>>();
  formulaInputControl = input.required<AbstractControl<string | null>>();

  isDisabled = signal<boolean>(false);
  hasError = signal<boolean>(false);
  sharedLocalMetrics = signal<DataEntryMetricModel[]>([]);
  monitoringMetrics = signal<DataEntryMetricModel[]>([]);
  validations = signal<ParameterCatalog[]>([]);

  metrics = computed<DataEntryMetricModel[]>(() => [...this.sharedLocalMetrics(), ...this.monitoringMetrics()]);
  formula = computed(() => this.stagedKpi()?.formula as string | null);
  formulaStatus = computed(() => this.stagedKpi()?.formulaStatus as AdaaBooleanType | null);
  stagedMetrics = computed(() => {
    const kpi = this.stagedKpi();
    if (!kpi?.metrics) return [];
    return kpi.metrics as KpiMetricModelType[];
  });
  kpiMetrics = computed(() => {
    const kpi = this.kpi();
    if (!kpi?.metrics) return [];
    return kpi.metrics as KpiMetricModelType[];
  });
  isDTKPI = computed(() => this.kpiType() === Constants.KPI_TYPE.DTKPI);
  isGSKPI = computed(() => this.kpiType() === Constants.KPI_TYPE.GSKPI);
  isNoFormula = computed(() => this.formula() === Constants.FORMULA_STRING.NOFORMULA);
  //If the KPI is linked to scope disabled the formula and formula selection
  isLinkedToScope = computed(() => this.kpi()?.isLinkedToScope === true);
  buttonLabel = computed(() => {
    if (this.hideValidateButton()) return "kpi.valid";

    if (this.hasError()) return "kpi.validate";

    if (this.isNoFormula()) {
      return "kpi.valid";
    }
    if (this.formulaStatus() === AdaaBoolean.N || !this.formulaStatus()) {
      return "kpi.validate";
    }
    if (this.formulaStatus() === AdaaBoolean.Y && !this.isNoFormula()) {
      return "kpi.valid";
    }

    return "kpi.validate";
  });
  formulaState = computed(() => {
    if (this.hasError()) return "invalid";

    if (this.isNoFormula()) {
      return "success";
    }
    if (this.formulaStatus() === AdaaBoolean.Y && !this.isNoFormula()) {
      return "success";
    }

    return "stale";
  });
  showComprehensiveFormula = computed(() => {
    const metrics = this.stagedMetrics();

    return (
      metrics.filter(({ metricType }) => metricType === "G" || metricType === "L" || metricType === "M").length > 0
    );
  });
  comprehensiveFormula = computed(() => {
    const metrics = this.stagedMetrics();

    return metrics.reduce((acc: string, curr) => {
      if (!AdaaHelper.isDefined(acc)) return acc;
      if (!curr.metricId) return acc;

      const metricType = this.#getMetricType(curr.metricId);
      if (!metricType) return acc;

      return acc.replace(
        new RegExp(`(?<!\\[)\\b${curr.nameAE}\\b(?![\\w\\s]*[\\]])`, "g"),
        `
          <kbd style="margin-inline: 1px; cursor: pointer" title="${AdaaHelper.getItemValueByToken(metricType, "dsc")}">
            ${AdaaHelper.getItemValueByToken(metricType, "name")}
          </kbd>
        `
      );
    }, this.formula() ?? "");
  });
  disableFormulaValidation = computed(() => this.hideValidateButton() || this.isNoFormula() || this.isLinkedToScope());

  readonly isDefined = AdaaHelper.isDefined.bind(AdaaHelper);
  readonly isFieldRequired = AdaaHelper.isFieldRequired.bind(AdaaHelper);

  readonly #untilDestroy = AdaaHelper.untilDestroyed();

  readonly #getMetricType = (metricId: number) => this.metrics().find(({ id }) => id === metricId);
  readonly #fetchMetricsByType = () => {
    let kpiId: number;
    const sponsorEntityId = this.stagedKpi()?.sponsorEntityId as number;

    if (this.isDTKPI() && AdaaHelper.isDefined(sponsorEntityId)) {
      kpiId = sponsorEntityId;
    } else {
      kpiId = AdaaHelper.entity?.id;
    }

    const globalMetrics$ = this._metricsApiService.getByEntityId(Constants.METRICS_TYPE.GLOBAL, kpiId);

    const localMetrics$ = this._metricsApiService.getByEntityId(Constants.METRICS_TYPE.LOCAL, AdaaHelper.entity?.id);

    const obs$: Observable<MainResponseModel<DataEntryMetricModel[]>>[] = [globalMetrics$, localMetrics$];

    if (this.isGSKPI()) obs$.push(this._metricsApiService.getAllMonitoring());

    combineLatest(obs$).subscribe({
      next: (res) => {
        const [globalRes, localRes] = res;

        if (!globalRes.inError) {
          this.sharedLocalMetrics.set(globalRes.responseData ?? []);
        }
        if (!localRes.inError) {
          this.sharedLocalMetrics.update((metrics) => [...metrics, ...(localRes.responseData ?? [])]);
        }
        //* monitoring metric list
        if (this.isGSKPI() && !res[2].inError) {
          this.monitoringMetrics.set(res[2].responseData);
        }
      },
    });
  };
  readonly #fetchValidations = () => {
    const key = Constants.VALIDATORS_CONF_KEY.VALIDATION_FORMULA_VALIDATE;

    this._validatorApiService
      .searchByKey(key)
      .pipe(
        filter((res) => !res.inError),
        map((res) => res.responseData)
      )
      .subscribe({
        next: (data) => this.validations.set(data.parameterCatalogs),
      });
  };

  @Output() formulaHasChanged = new EventEmitter<boolean>();
  @Output() clearMetrics = new EventEmitter<boolean>();
  @Output() metricsHasChanged = new EventEmitter<KpiMetricModelType[]>();
  @Output() formulaStatusHasChanged = new EventEmitter<AdaaBooleanType>();

  public ngOnInit() {
    this.#fetchMetricsByType();
    this.#fetchValidations();
    this._formulaInputStatus();
    this._checkFormula();
  }

  public validateFormula(formula: string, openModal = true) {
    if (!AdaaHelper.isDefinedAndNotEmpty(formula)) return;

    formula = this._sanitizeFormula(formula);

    this._formulasApiService
      .validateFormula({
        formula,
      })
      .pipe(map((res) => Number(res.responseCode) === 1))
      .subscribe({
        next: (isValid) => {
          if (!isValid) {
            this.formulaStatusHasChanged.emit(AdaaBoolean.N);
            this.formulaInputControl().markAsTouched();
            this.formulaInputControl().setErrors({ invalid: true });
            this._toastrService.warning(this.languageService.translate("nkpi.invalid_formula"));
            return;
          } else {
            this.formulaInputControl().setErrors(null);
          }

          openModal && this._openFormulaMetricsModal(formula);
        },
      });
  }

  private _openFormulaMetricsModal(f?: string) {
    if (this.isNoFormula()) return;

    const modal = this._modalService.open(KpiFormulaMetricsComponent, {
      keyboard: false,
      scrollable: false,
      animation: true,
      backdrop: "static",
      windowClass: "kpi--formula-validator",
      centered: true,
      size: "lg",
      modalDialogClass: `modal-${this.languageService.direction()}`,
    });

    const formula = f ? f : this.formula();

    modal.componentInstance.parsedFormula.set(this._formulaMetrics(formula ?? ""));
    modal.componentInstance.hideYtpCalc.set(this.hideYtpCalc());
    modal.componentInstance.ytpCalcOptions.set(this.ytpCalcOptions());
    modal.componentInstance.stagedKpi.set(this.stagedKpi());
    modal.componentInstance.kpi.set(this.kpi());
    modal.componentInstance.kpiType.set(this.kpiType());
    modal.componentInstance.updateMetrics();

    modal.result.then(
      ({
        metrics = [],
        revalidate = false,
        clearMetrics = false,
        formulaStatusHasChanged = false,
      }: {
        metrics?: KpiMetricModelType[];
        clearMetrics?: boolean;
        revalidate: boolean;
        formulaStatusHasChanged: boolean;
      }) => {
        const hasError = metrics.every((metric) => !AdaaHelper.isDefined(metric.dscAE));
        if (hasError) {
          this.hasError.set(hasError);
          return;
        }

        //If metrics changed we need to recalclute the formula
        this.kpiMetrics().every((metric, index) => {
          const newMetric = metrics[index];
          if (!newMetric) return false;

          if (
            newMetric.metricType !== metric.metricType ||
            newMetric.id !== metric.id ||
            newMetric.metricId !== metric.metricId
          ) {
            this.formulaHasChanged.emit(true);
            return false;
          }

          return true;
        });

        // this.formulaHasChanged.emit(true)
        this.metricsHasChanged.emit(metrics ?? []);
        this.clearMetrics.emit(clearMetrics);
        this.formulaStatusHasChanged.emit(formulaStatusHasChanged ? AdaaBoolean.Y : AdaaBoolean.N);
        if (revalidate) this.validateFormula(formula ?? "", false);
      }
    );

    this._hasFormulaChanged(formula ?? "");
  }

  private _sanitizeFormula(f: string) {
    f = f.trim();
    f = encodeURI(f);
    f = f.replace(/\%E2\%80\%8E/gm, "");
    return f;
  }

  private _formulaMetrics(f: string) {
    const fExp = new RegExp("\\s+(?=[^\\]}]*([\\[{]|$))", "g");
    const ff = f.replace(fExp, "");

    if (!ff.length) return [];

    // TODO: should show notification

    this._formulaParserService.init(ff);

    return this._formulaParserService.extractFormulaMetrics().map((f) => {
      return {
        label: f,
        flgIsLocal: this._formulaParserService.isLocalVar(f),
        flgIsGlobal: this._formulaParserService.isGlobalVar(f),
      };
    });
  }

  private _hasFormulaChanged(f: string) {
    const kpi = this.stagedKpi();

    if (AdaaHelper.isDefined(kpi?.id) && kpi?.status === ObjectStatus.ACTIVE) {
      this.formulaHasChanged.emit(this.kpi()?.formula !== f);
    } else {
      this.formulaHasChanged.emit(false);
    }
  }

  private _formulaInputStatus() {
    this.formulaInputControl()
      .statusChanges.pipe(this.#untilDestroy())
      .subscribe({
        next: (state: FormControlStatus) => {
          switch (state) {
            case "VALID":
              this.hasError.set(false);
              this.isDisabled.set(false);
              break;
            case "INVALID":
              this.hasError.set(true);
              this.isDisabled.set(false);
              break;
            case "DISABLED":
              this.hasError.set(false);
              this.isDisabled.set(true);
              break;
            default:
              break;
          }
        },
      });
  }

  private _checkFormula(): void {
    if (this.isNoFormula()) return;

    const hasError = this.stagedMetrics().every((metric) => !AdaaHelper.isDefined(metric.dscAE));
    if (hasError) {
      this.hasError.set(hasError);
      return;
    }
  }
}
