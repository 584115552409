<p class="ms-2 mb-0 fw-bold fs-4">{{ metric() | translateToken: "name" }}</p>

<div class="p-2">
    <div class="bg-white shadow-md rounded p-3 my-1">
        <div class="ms-1">
            @if (metric()?.nameAE || metric()?.nameEN) {
                <div class="row mb-1">
                    <div class="col-sm-4 col-xs-12 text-primary fw-bold">
                        {{ "metrics.label_name" | translate }}
                    </div>
                    <div class="col-sm-8 col-xs-12">{{ metric() | translateToken: "name" }}</div>
                </div>
            }

            @if (metric()?.dscAE || metric()?.dscEN) {
                <div class="row mb-1">
                    <div class="col-sm-4 col-xs-12 text-primary fw-bold">
                        {{ "metrics.label_description" | translate }}
                    </div>
                    <div class="col-sm-8 col-xs-12">{{ metric() | translateToken: "dsc" }}</div>
                </div>
            }
            @if (metric()?.dataType) {
                <div class="row mb-1">
                    <div class="col-sm-4 col-xs-12 text-primary fw-bold">
                        {{ "metrics.label_data_type" | translate }}
                    </div>
                    <div class="col-sm-8 col-xs-12">{{ getDataType(metric()?.dataType) }}</div>
                </div>
            }
            @if (metric()?.dscAE || metric()?.dscEN) {
                <div class="row mb-1">
                    <div class="col-sm-4 col-xs-12 text-primary fw-bold">
                        {{ "metrics.data_source" | translate }}
                    </div>
                    <div class="col-sm-8 col-xs-12">{{ metric() | translateToken: "dataSource" }}</div>
                </div>
            }
            @if (metric()?.type) {
                <div class="row mb-1">
                    <div class="col-sm-4 col-xs-12 text-primary fw-bold">
                        {{ "metrics.label_type" | translate }}
                    </div>
                    <div class="col-sm-8 col-xs-12">{{ getType(metric()?.type ?? "") }}</div>
                </div>
            }
        </div>

        @if (pageMode() === PageMode.edit) {
            <div class="row my-3">
                <div class="col-12 col-md-4 mt-1">
                    <p class="fw-bold fs-6 mb-0 ms-2 date">{{ "common.form.label.date" | translate }}</p>
                    <div class="d-flex gap-1 w-100">
                        <div class="w-100">
                            <adaa-form-dropdown [options]="months()" [setDefaultValue]="month()" (inputChanges)="month.set($event)" />
                        </div>
                        <div class="w-100">
                            <adaa-form-dropdown [options]="years()" [setDefaultValue]="year()" (inputChanges)="year.set($event)" />
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <adaa-form-input
                        [label]="'data_entry.value' | translate"
                        [setDefaultValue]="value()"
                        inputType="number"
                        (inputChanges)="value.set($event)"
                    ></adaa-form-input>
                </div>
                <div class="col-12 col-md-4">
                    <button class="btn btn-secondary w-100" (click)="confirmModal()">
                        <span class="fw-bolder">{{ "add" | translate }}</span>
                    </button>
                </div>
            </div>
        }
    </div>

    <div class="my-4">
        <adaa-data-table
            #adaaDataTable
            [isLocalData]="true"
            [localData]="dataEntries()"
            [configKey]="Constants.MAIN_TABLE_LIST_CONF_KEY.DATA_ENTRY_METRICS_LIST"
            [enableAdd]="false"
            [enableViewAction]="false"
            [enableExport]="false"
            [enableActionsCell]="pageMode() === PageMode.edit"
            [disableEditRules]="disableRules"
            [disableDeleteRules]="disableRules"
            (actionClicked)="getAction($event)"
        ></adaa-data-table>
    </div>
</div>

<adaa-metrics-value-input #valueInput (defaultValue)="setValue($event)"></adaa-metrics-value-input>
