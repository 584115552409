import { Component, inject, OnInit, signal } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";

import { PermissionActionDescriptorType } from "../../../../adaa-types";
import { AdaaHelper } from "../../../core/utils";
import { FormActionButtonsComponent, FormInputComponent } from "../../../shared/components";
import { Constants } from "../../../shared/constants/constants";
import { AdaaBoolean, PermissionAction } from "../../../shared/constants/enums";
import { HasPermissionDirective } from "../../../shared/directives";
import { CycleModelType, EntityMapModelType } from "../../../shared/models";
import { EntityMapApiService, SystemLayoutService } from "../../../shared/services";

@Component({
  selector: "adaa-strategic-document",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormInputComponent,
    TranslateModule,
    FormActionButtonsComponent,
    HasPermissionDirective,
  ],
  templateUrl: "./strategic-document.component.html",
  styleUrl: "./strategic-document.component.scss",
})
export class StrategicDocumentComponent implements OnInit {
  private _formBuilder = inject(FormBuilder);
  private _entityMapApiService = inject(EntityMapApiService);
  private _toastrService = inject(ToastrService);
  private _translateService = inject(TranslateService);
  private _router = inject(Router);
  private _systemLayoutService = inject(SystemLayoutService);

  inputMaxLength = 50000;
  currentEntityId = signal<number>(0);
  currentPlan = signal<CycleModelType>({
    id: 0,
    nameAE: "",
    nameEN: "",
    dscAE: "",
    dscEN: "",
    startYear: 0,
    endYear: 0,
    dataRevisionId: 0,
    entityMaps: [],
    status: 0,
    wfStatus: "",
    updateTS: 0,
  });
  entityMap = signal<EntityMapModelType>({
    entityId: 0,
    entityNameAE: "",
    entityNameEN: "",
    planId: 0,
    startYear: 0,
    endYear: 0,
    wasSent: AdaaBoolean.Y,
    id: 0,
    status: 0,
    updateTS: 0,
  });
  strategicDocumentForm: FormGroup = new FormGroup({});
  swotAnalysisDocumentForm: FormGroup = new FormGroup({});

  private _subscription = new Subscription();

  createPermission = signal<PermissionActionDescriptorType>({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.create,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.STRATEGIC_DOCUMENT,
        },
      ],
    },
  });

  public ngOnInit(): void {
    this._subscription.add(
      this._systemLayoutService.hasCycleChanged$.subscribe({
        next: () => this._getMap(),
      })
    );
    this._subscription.add(
      this._systemLayoutService.hasActiveEntityChanged$.subscribe({
        next: () => this._getMap(),
      })
    );
    this._prepareForms();
    this._getMap();
  }

  private _getMap() {
    const currentEntityId = AdaaHelper.getLocalStorage(Constants.localStorageKeys.currentEntity, {
      type: "prop",
      property: "id",
    });
    const plan = AdaaHelper.getLocalStorage(Constants.localStorageKeys.currentPlan, { type: "json" });
    this.currentEntityId.set(+currentEntityId);
    this.currentPlan.set(plan as CycleModelType);

    this._entityMapApiService.getEntityMap(this.currentPlan()?.id, this.currentEntityId()).subscribe({
      next: (response) => {
        this.entityMap.set(response.responseData);
        this.strategicDocumentForm.patchValue(response.responseData);
        this.swotAnalysisDocumentForm.patchValue(response.responseData);
      },
    });
  }

  private _prepareForms() {
    this.strategicDocumentForm = this._formBuilder.group({
      visionAE: this._formBuilder.control<string | undefined>("", {
        validators: [Validators.required],
      }),
      visionEN: this._formBuilder.control<string | undefined>("", {
        validators: [Validators.required],
      }),
      missionAE: this._formBuilder.control<string | undefined>("", {
        validators: [Validators.required],
      }),
      missionEN: this._formBuilder.control<string | undefined>("", {
        validators: [Validators.required],
      }),
      valuesAE: this._formBuilder.control<string | undefined>("", {
        validators: [Validators.required],
      }),
      valuesEN: this._formBuilder.control<string | undefined>("", {
        validators: [Validators.required],
      }),
    });

    this.swotAnalysisDocumentForm = this._formBuilder.group({
      strengthAE: this._formBuilder.control<string | undefined>("", {
        validators: [Validators.required],
      }),
      strengthEN: this._formBuilder.control<string | undefined>("", {
        validators: [Validators.required],
      }),
      weaknessAE: this._formBuilder.control<string | undefined>("", {
        validators: [Validators.required],
      }),
      weaknessEN: this._formBuilder.control<string | undefined>("", {
        validators: [Validators.required],
      }),
      opportunitiesAE: this._formBuilder.control<string | undefined>("", {
        validators: [Validators.required],
      }),
      opportunitiesEN: this._formBuilder.control<string | undefined>("", {
        validators: [Validators.required],
      }),
      threatsAE: this._formBuilder.control<string | undefined>("", {
        validators: [Validators.required],
      }),
      threatsEN: this._formBuilder.control<string | undefined>("", {
        validators: [Validators.required],
      }),
    });
  }

  public submit() {
    const result = {
      ...this.entityMap(),
      ...this.strategicDocumentForm.value,
      ...this.swotAnalysisDocumentForm.value,
      startYear: new Date(this.currentPlan().startYear).getFullYear(),
      endYear: new Date(this.currentPlan().endYear).getFullYear(),
      updateTS: this.currentPlan().updateTS,
      updateUser: this.currentPlan().updateUser,
      entityId: this.currentEntityId(),
      planId: this.currentPlan().id,
      wasSent: AdaaBoolean.Y,
      status: Constants.OBJECT_STATUS.ACTIVE,
    };

    delete result.entityNameAE;
    delete result.entityNameEN;
    delete result.flag;

    this._entityMapApiService.updateEntityMap(result).subscribe({
      next: () => {
        this._toastrService.success(this._translateService.instant("notification.success.save"));
        this._router.navigateByUrl("/console/provisioning");
      },
    });
  }

  public close() {
    this._router.navigateByUrl("/console/provisioning");
  }
}
