import { NgTemplateOutlet } from "@angular/common";
import {
  AfterViewInit,
  Component,
  computed,
  effect,
  EventEmitter,
  inject,
  Injector,
  input,
  OnInit,
  Output,
  Renderer2,
  signal,
  TemplateRef,
  untracked,
  viewChild,
} from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { NgbAccordionModule, NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { filter, forkJoin, map } from "rxjs";

import { AdaaHelper } from "../../../../../core/utils";
import {
  ConfirmationModalComponent,
  DataTableComponent,
  FormDropdownComponent,
  FormInputComponent,
  FormInputFileComponent,
} from "../../../../../shared/components";
import { Constants } from "../../../../../shared/constants/constants";
import { ActionsEvents, FileInputType, PageMode } from "../../../../../shared/constants/enums";
import { FormControlDisabledDirective } from "../../../../../shared/directives";
import {
  AttachFile,
  AttachmentModelType,
  BenchmarkModelType,
  BenchmarkValueType,
  ObjectStatus,
  ParameterCatalog,
  PropTypeModelType,
  TableActionEvent,
  ValueText,
} from "../../../../../shared/models";
import {
  FilesApiService,
  LanguageService,
  PropertiesService,
  ValidatorApiService,
} from "../../../../../shared/services";
import { kpiBenchmarkForm, kpiBenchmarkUploadForm, kpiBenchmarkYearsForm, StructuredBenchmarkYearsType } from "./util";

@Component({
  selector: "adaa-kpi-benchmark-form",
  standalone: true,
  imports: [
    TranslateModule,
    FormControlDisabledDirective,
    FormInputComponent,
    FormDropdownComponent,
    ReactiveFormsModule,
    DataTableComponent,
    NgbAccordionModule,
    FormInputFileComponent,
    NgTemplateOutlet,
  ],
  templateUrl: "./kpi-benchmark-form.component.html",
  styleUrl: "../styles.scss",
})
export class KpiBenchmarkFormComponent implements AfterViewInit, OnInit {
  @Output() onBenchmarkUpdate = new EventEmitter<BenchmarkModelType[]>();

  readonly languageService = inject(LanguageService);
  private readonly _filesApiService = inject(FilesApiService);
  private readonly _renderer2 = inject(Renderer2);
  private readonly _modalService = inject(NgbModal);
  private readonly _injector = inject(Injector);
  private readonly _toastrService = inject(ToastrService);
  private readonly _validatorApiService = inject(ValidatorApiService);
  private readonly _propertiesService = inject(PropertiesService);

  pageMode = input.required<PageMode>();
  kpi = input();
  stagedKpi = input();
  tableData = input([], {
    transform: (data: null | undefined | BenchmarkModelType[]): BenchmarkModelType[] => {
      if (!AdaaHelper.isDefined(data)) return [];
      return data as BenchmarkModelType[];
    },
  });

  benchmarkList = viewChild.required<DataTableComponent>("benchmarkList");
  benchmarkUploadList = viewChild<DataTableComponent>("benchmarkUploadList");
  modal = viewChild.required<TemplateRef<unknown>>("modal");
  uploadModal = viewChild.required<TemplateRef<unknown>>("uploadModal");

  isDirty = signal<boolean>(false);
  uploadModalState = signal<PageMode | undefined>(undefined);
  selectedActiveBenchmarkYear = signal<StructuredBenchmarkYearsType | undefined>(undefined);
  activeBenchmarkIndex = signal<number | null>(null);
  activeBenchmarkFileIndex = signal<number | null>(null);
  mode = signal<PageMode | null>(null);
  props = signal<PropTypeModelType[]>([]);
  countries = signal<PropTypeModelType[]>([]);
  validations = signal<ParameterCatalog[]>([]);
  countryOptions = signal<ValueText[]>([]);
  data = signal<BenchmarkModelType[]>([]);
  benchFileList = signal<object[]>([]);

  uploadModalIsReadonly = computed(() => this.uploadModalState() === PageMode.view);
  sanitizedData = computed(() => this.data()?.filter(({ status }) => status !== ObjectStatus.REMOVE));
  activeBenchmark = computed(() => {
    const index = this.activeBenchmarkIndex();
    const data = this.data();
    if (!AdaaHelper.isDefined(index) || !AdaaHelper.isDefined(data[index])) return;
    return data[index];
  });
  benchmarkTypes = computed(() => this.props().filter(({ propType }) => propType === Constants.CONSTANT_BENCHTYPE));
  benchmarkTypeOptions = computed(() => {
    const options = this.props().filter(({ propType }) => propType === Constants.CONSTANT_BENCHTYPE);

    return AdaaHelper.setDropdownArray(options, "id", AdaaHelper.getFieldLanguage("name"));
  });
  benchmarkYearsOptions = computed(() => {
    const options = this.props().filter(({ propType }) => propType === Constants.CONSTANT_YEARS);

    return options.map<ValueText>((data) => ({
      value: Number(data.nameAE),
      text: AdaaHelper.getItemValueByToken(data, "name"),
    }));
  });
  isReadonly = computed<boolean>(() => this.mode() === PageMode.view);

  readonly attachmentTableConfList = Constants.MAIN_TABLE_LIST_CONF_KEY.BENCHMARK_ATTACHMENT_LIST;
  readonly tableConfList = Constants.MAIN_TABLE_LIST_CONF_KEY.BENCHMARK_LIST;
  readonly allowedFiles: FileInputType[] = [
    FileInputType.txt,
    FileInputType.pdf,
    FileInputType.doc,
    FileInputType.docx,
    FileInputType.xlsx,
    FileInputType.xls,
    FileInputType.csv,
    FileInputType.ppt,
    FileInputType.pptx,
  ];
  readonly isDefined = AdaaHelper.isDefined.bind(AdaaHelper);
  readonly isFieldRequired = AdaaHelper.isFieldRequired.bind(AdaaHelper);
  readonly disableBenchType = () => {
    if (this.isReadonly()) return true;
    return Array.isArray(this.yearsList) && this.yearsList.length > 0;
  };

  readonly #untilDestroy = AdaaHelper.untilDestroyed();

  readonly #handleReadonlyEffects = () => {
    effect(
      () => {
        const disableAllForms = this.isReadonly();
        if (disableAllForms) {
          this.model?.disable({ onlySelf: false, emitEvent: true });
          this.subModel?.disable({ onlySelf: false, emitEvent: true });
        } else {
          this.model?.enable({ onlySelf: false, emitEvent: true });
          this.subModel?.enable({ onlySelf: false, emitEvent: true });
        }
      },
      { injector: this._injector }
    );
  };
  readonly #appendBenchTypeToTableData = () => {
    // NOTE: this is how to perform @Input() setter with signal
    effect(
      () => {
        const props = this.props();
        const data = this.tableData();

        if (!props.length) return;

        untracked(() => {
          const list: BenchmarkModelType[] = [];

          for (const item of data) {
            const prop = props.find(({ id }) => item.benchType === id);

            if (!prop) {
              list.push(item);
              continue;
            }

            list.push({
              ...item,
              typeNameAE: prop.nameAE,
              typeNameEN: prop.nameEN,
            });
          }

          this.data.set(list);
        });
      },
      { injector: this._injector }
    );
  };
  readonly #populateModelEffect = () => {
    effect(() => {
      const index = this.activeBenchmarkIndex();

      if (!AdaaHelper.isDefined(index)) return;

      untracked(() => {
        const data = this.activeBenchmark();
        if (!data) return;
        this._hydrateModel(data);
      });
    });
  };

  modalRef: NgbModalRef;
  uploadModalRef: NgbModalRef;
  model: ReturnType<typeof kpiBenchmarkForm>;
  subModel: ReturnType<typeof kpiBenchmarkYearsForm>;
  uploadModel: ReturnType<typeof kpiBenchmarkUploadForm>;

  public get benchmarkFile(): AttachFile {
    const attachment = this.uploadModel?.get("file")?.value;
    return {
      id: attachment ? attachment.id : 0,
      filename: attachment ? attachment.filename : "",
    };
  }

  public get yearsList() {
    return (this.model?.get("years")?.value ?? []) as Record<string, string | number | null>[];
  }

  public get uniqueYears() {
    const set = new Set(this.yearsList.map(({ year }) => year as number));
    return Array.from(set);
  }

  public get yearsAsAccordion() {
    const $accordion: {
      [k: number]: StructuredBenchmarkYearsType[];
    } = {};

    for (const $year of this.uniqueYears) {
      $accordion[$year] = this.yearsList
        .map((data, $index) => {
          const country = this.countries().find(({ id }) => data.countryId === id);
          if (!country) return;
          return {
            $index,
            id: data.id as number | null,
            year: data.year as number,
            value: data.value as number,
            countryId: data.countryId as number,
            countryName: AdaaHelper.getItemValueByToken(country, "name") as string,
          };
        })
        .filter((d) => AdaaHelper.isDefined(d))
        .filter((d) => d.year === $year);
    }

    return $accordion;
  }

  constructor() {
    this.#appendBenchTypeToTableData();
    this.#handleReadonlyEffects();
    this.#populateModelEffect();
  }

  public ngOnInit() {
    this._fetchBenchmarkTypes();
    this._fetchValidator();
    this._setupForm();
    this._setupSubForm();
    this._setupUploadForm();
    this._onBenchTypeSelection();
  }

  public ngAfterViewInit() {
    this.model
      ?.get("benchFileList")
      ?.valueChanges.pipe(this.#untilDestroy())
      .subscribe({
        next: (value) => {
          this.benchFileList.set(value ?? []);

          if (this.benchFileList().length > 0 && this.benchmarkUploadList()) {
            this.benchmarkUploadList()!.isLocalData = true;
            this.benchmarkUploadList()!.localData = [...(this.benchFileList() as object[])];
            this.benchmarkUploadList()!.resetFilters();
          }
        },
      });
  }

  public handleAddTableAction() {
    this.mode.set(PageMode.create);
    this.activeBenchmarkIndex.set(null);
    this._openModal();
  }

  public handleAddFileAction() {
    this.uploadModel?.reset();
    this.uploadModalState.set(PageMode.create);
    this._openUploadModal();
  }

  public handleBenchmarkTableActions($e: TableActionEvent) {
    if ($e.event === ActionsEvents.view || $e.event === ActionsEvents.edit) {
      this.activeBenchmarkIndex.set($e.index);
      this.mode.set($e.event === ActionsEvents.view ? PageMode.view : PageMode.edit);
      this._openModal();
      return;
    }

    if ($e.event === ActionsEvents.delete) {
      this._deleteBenchmark($e);
      return;
    }
  }

  public handleAttachmentTableAction($e: TableActionEvent) {
    if ($e.event === ActionsEvents.delete) {
      this._deleteBenchmarkFile($e);
    }

    if ($e.event === ActionsEvents.view || $e.event === ActionsEvents.edit) {
      this.activeBenchmarkFileIndex.set($e.index);
      this.uploadModel?.setValue({
        nameAE: $e.data.nameAE,
        nameEN: $e.data.nameEN,
        file: null,
      });

      this.uploadModel?.get("file")?.setValue({
        id: $e.data.id,
        filename: $e.data.filename,
      });

      this.uploadModalState.set($e.event === ActionsEvents.edit ? PageMode.edit : PageMode.view);
      this._openUploadModal();

      return;
    }
  }

  public submitBenchmarkYear() {
    this.subModel.controls["value"]?.setErrors(null);
    this.subModel?.markAllAsTouched();

    const value = this.subModel.controls["value"]?.getRawValue();
    if (!AdaaHelper.isDefinedAndNotEmpty(value)) {
      this._toastrService.warning(this.languageService.translate("notification.warning.missing_info"));
      return;
    }

    if (this.subModel?.invalid) {
      this._toastrService.warning(this.languageService.translate("notification.warning.missing_info"));
      return;
    }

    const selected = this.selectedActiveBenchmarkYear();
    const data = this.subModel?.getRawValue();

    // @on:Edit
    if (AdaaHelper.isDefined(selected)) {
      this.model?.get("years")?.setValue(
        this.yearsList.map((d, $i) => {
          if ($i !== selected?.$index) return d;
          return {
            ...d,
            value: isNaN(Number(data?.value)) ? null : Number(data?.value),
          };
        })
      );
      this.resetSubModel();
      return;
    }

    // @on:Create
    const list = this.yearsAsAccordion[data?.year as number];
    if (list && list.length > 0 && list.find(({ countryId }) => countryId === data?.countryId)) {
      this._toastrService.error(this.languageService.translate("kpi.benchmark_country_exists"));
      return;
    }

    this.model?.get("years")?.setValue([
      ...this.yearsList,
      {
        value: data?.value,
        year: data.year,
        countryId: data.countryId,
        id: data.id,
      },
    ]);
    this.resetSubModel();
  }

  public editBenchmarkYear($e: StructuredBenchmarkYearsType) {
    this.selectedActiveBenchmarkYear.set($e);
    this.subModel?.setValue({
      value: $e.value,
      year: $e.year,
      countryId: $e.countryId,
      id: $e.id ?? null,
    });
    this._renderer2.selectRootElement(".benchmark-year-value-input").focus();
  }

  public removeBenchmarkYear($e: StructuredBenchmarkYearsType) {
    const list = this.yearsList
      .map((data, $i) => {
        if ($i === $e.$index) {
          return;
        }
        return data;
      })
      .filter((data) => AdaaHelper.isDefined(data));

    this.model.get("years")?.setValue(list);
  }

  public resetSubModel() {
    this.selectedActiveBenchmarkYear.set(undefined);
    this.subModel?.reset({
      year: null,
      countryId: null,
      value: null,
      id: null,
    });
  }

  public handleFileUpload($e: AttachFile | null) {
    this.uploadModel.controls["file"]?.setValue($e);
  }

  public saveBenchmarkFile() {
    this.uploadModel.markAllAsTouched();

    if (this.uploadModel?.invalid) {
      this._toastrService.warning(this.languageService.translate("notification.warning.missing_info"));
      return;
    }

    const file = this.uploadModel.getRawValue();
    const files = this.model?.get("benchFileList")?.getRawValue() ?? [];
    const index = this.activeBenchmarkFileIndex();
    if (AdaaHelper.isDefined(index) && index >= 0) {
      files[index] = {
        nameAE: file.nameAE,
        nameEN: file.nameEN,
        ...file.file,
      };
      this.model.get("benchFileList")?.setValue(files);
    } else {
      this.model.get("benchFileList")?.setValue([
        ...files,
        {
          nameAE: file.nameAE,
          nameEN: file.nameEN,
          ...file.file,
        },
      ]);
    }

    this.closeUploadModal();
  }

  public closeUploadModal(skipConfirmation = true) {
    const cleanUp = () => {
      this.uploadModalRef.close();
      this.activeBenchmarkFileIndex.set(null);
      this.uploadModalState.set(undefined);
      this.uploadModel.reset();
    };

    if (skipConfirmation || this.isReadonly() || this.uploadModalIsReadonly()) {
      cleanUp();
      return;
    }

    const model = this.uploadModel.getRawValue();

    if (this.uploadModalState() === PageMode.create) {
      const isNull = Object.entries(model).every(([_, value]) => !AdaaHelper.isDefined(value));
      if (isNull) {
        cleanUp();
        return;
      }

      this._confirmCancellation().result.then((e) => {
        if (e) cleanUp();
      });
      return;
    }

    this._confirmCancellation().result.then((e) => {
      if (e) cleanUp();
    });
    return;
  }

  public closeModal(skipConfirmation = true) {
    const cleanUp = () => {
      this.modalRef.close();
      this.mode.set(null);
      this.activeBenchmarkIndex.set(null);
      this.model.reset();
      this.resetSubModel();
    };

    if (skipConfirmation || this.isReadonly()) {
      cleanUp();
      return;
    }

    if (this.mode() === PageMode.create) {
      const model = this.model.getRawValue();
      const isNull = Object.entries(model).every(([_, value]) => !AdaaHelper.isDefined(value));
      if (isNull) {
        cleanUp();
        return;
      }

      this._confirmCancellation().result.then((e) => {
        if (e) cleanUp();
      });
      return;
    }

    const activeBenchmark = this.activeBenchmark();
    if (!activeBenchmark) {
      cleanUp();
      return;
    }

    const isDirty =
      JSON.stringify(this._generateModelData(activeBenchmark)) !== JSON.stringify(this.model.getRawValue());

    if (isDirty) {
      this._confirmCancellation().result.then((e) => {
        if (e) cleanUp();
      });
      return;
    }

    cleanUp();
  }

  public saveBenchmark() {
    this.model.markAllAsTouched();

    if (this.model.invalid) {
      if (this.model.controls["years"]?.invalid) {
        this.subModel.controls["value"]?.setErrors({
          required: true,
        });
      }
      this._toastrService.warning(this.languageService.translate("notification.warning.missing_info"));
      return;
    }

    const years = this.model.controls["years"]?.getRawValue() as object[];
    if (!Array.isArray(years) || years.length === 0) {
      this._toastrService.warning(this.languageService.translate("notification.warning.missing_info"));
      return;
    }

    const props = this.props();
    const prop = props.find(({ id }) => this.model?.controls["benchType"]?.value === id);

    if (this.mode() === PageMode.create) {
      this._addBenchmark({
        typeNameAE: prop?.nameAE,
        typeNameEN: prop?.nameEN,
      });
    } else {
      this._editBenchmark({
        typeNameAE: prop?.nameAE,
        typeNameEN: prop?.nameEN,
      });
    }

    this.isDirty.set(true);
    this.onBenchmarkUpdate.emit(this.data());
    this.closeModal();
  }

  private _editBenchmark(data: { typeNameAE?: string; typeNameEN?: string }) {
    const index = this.activeBenchmarkIndex()!;
    const benchmark = this.activeBenchmark()!;
    const model = this.model.getRawValue();
    const kpi = this.kpi() as Record<string, unknown>;
    const object = { kpiId: kpi?.id as number };
    const list = this.data() ?? [];
    const item = list[index];

    if (!item) return;

    const stagedItem = {
      ...object,
      ...benchmark,
      ...data,
      nameAE: model?.nameAE as string,
      nameEN: model?.nameEN as string,
      benchType: model?.benchType as number,
      benchCountryType: model?.benchType as number,
      benchSource: model?.benchSource as string,
      benchFileList: model?.benchFileList as AttachmentModelType[],
      // NOTE: save benchmark lines
      kpiBenchMarkLovs: (model.years ?? []).map<BenchmarkValueType>(($t) => {
        const savedItem = item.kpiBenchMarkLovs.find(({ id }) => id === ($t as BenchmarkValueType).id);

        if (savedItem) {
          return {
            ...savedItem,
            countryId: ($t as BenchmarkValueType).countryId,
            year: ($t as BenchmarkValueType).year,
            value: ($t as BenchmarkValueType).value,
          };
        }

        return {
          kpiBenchmarkId: null,
          countryId: ($t as BenchmarkValueType).countryId,
          year: ($t as BenchmarkValueType).year,
          value: ($t as BenchmarkValueType).value,
          status: null,
        } as BenchmarkValueType;
      }),
    };

    list[index].kpiBenchMarkLovs = [
      ...stagedItem.kpiBenchMarkLovs,
      // NOTE: mark removed benchmark lines
      ...list[index].kpiBenchMarkLovs
        .filter((item) => {
          const $i = stagedItem.kpiBenchMarkLovs.find(({ id }) => id === item.id);
          return !$i;
        })
        .map(($d) => ({ ...$d, status: ObjectStatus.REMOVE })),
    ];

    list[index] = stagedItem;

    this.data.set([...list]);
  }

  private _addBenchmark(item: { typeNameAE: string | undefined; typeNameEN: string | undefined }) {
    const list = this.data();
    const data = this.model.getRawValue();

    this.data.set([
      ...list,
      {
        ...item,
        nameAE: data?.nameAE as string,
        nameEN: data?.nameEN as string,
        benchType: data?.benchType as number,
        benchCountryType: data?.benchType as number,
        benchSource: data?.benchSource as string,
        status: null,
        benchFileList: (data?.benchFileList as AttachmentModelType[]) ?? [],
        kpiBenchMarkLovs: (data.years ?? []).map<BenchmarkValueType>(($t) => {
          const item = $t as BenchmarkValueType;

          return {
            kpiBenchmarkId: null,
            countryId: item.countryId,
            year: item.year,
            value: item.value,
            status: null,
          } as BenchmarkValueType;
        }),
      } as BenchmarkModelType,
    ]);
  }

  private _openModal() {
    const tpl = this.modal();

    this.modalRef = this._modalService.open(tpl, {
      keyboard: false,
      centered: true,
      size: "lg",
      modalDialogClass: this.languageService.modalDirection(),
    });
  }

  private _openUploadModal() {
    const tpl = this.uploadModal();

    this.uploadModalRef = this._modalService.open(tpl, {
      keyboard: false,
      centered: true,
      modalDialogClass: this.languageService.modalDirection(),
    });
  }

  private _onBenchTypeSelection() {
    this.model
      ?.get("benchType")
      ?.valueChanges.pipe(
        filter((value) => AdaaHelper.isDefined(value)),
        map((value) => this.benchmarkTypes().find(({ id }) => value === id)),
        filter((data) => AdaaHelper.isDefined(data)),
        map((data) => Number(data.value)),
        map((value) => this.countries().filter(({ propType }) => value === propType)),
        map((list) => AdaaHelper.setDropdownArray(list, "id", AdaaHelper.getFieldLanguage("name"))),
        this.#untilDestroy()
      )
      .subscribe({
        next: (data) => this.countryOptions.set(data ?? []),
      });
  }

  private _fetchValidator() {
    this._validatorApiService
      .searchByKey(Constants.VALIDATORS_CONF_KEY.VALIDATION_BENCHMARKS)
      .pipe(
        filter((res) => !res.inError),
        map((res) => res.responseData)
      )
      .subscribe({
        next: (data) => this.validations.set(data?.parameterCatalogs),
      });
  }

  private _fetchBenchmarkTypes() {
    this._propertiesService
      .getPropByIdList([Constants.CONSTANT_YEARS, Constants.CONSTANT_BENCHTYPE])
      .pipe(
        filter((res) => !res.inError),
        map((res) => res.responseData)
      )
      .subscribe({
        next: (data) => {
          const countriesPropTypes = data
            .filter(({ propType }) => propType === Constants.CONSTANT_BENCHTYPE)
            .map(({ value }) => Number(value));

          this._fetchCountries(countriesPropTypes);
          this.props.set(data ?? []);
        },
      });
  }

  private _fetchCountries(id: number[]) {
    this._propertiesService
      .getPropByIdList(id)
      .pipe(
        filter((res) => !res.inError),
        map((res) => res.responseData)
      )
      .subscribe({
        next: (data) => {
          if (!data) return;

          const uniqueIds = new Set(data.map(({ id }) => id));
          const list: PropTypeModelType[] = [];

          for (const i of uniqueIds) {
            const item = data.find(({ id }) => id === i);
            if (item) list.push(item);
          }

          this.countries.set(list);
        },
      });
  }

  private _setupForm() {
    this.model = kpiBenchmarkForm(this.validations());

    this.model.controls["years"]?.valueChanges.subscribe({
      next: () => {
        if (this.disableBenchType()) {
          this.model.controls["benchType"]?.disable();
        } else {
          this.model.controls["benchType"]?.enable();
        }
      },
    });
  }

  private _setupSubForm() {
    this.subModel = kpiBenchmarkYearsForm();
  }

  private _setupUploadForm() {
    this.uploadModel = kpiBenchmarkUploadForm();
  }

  private _generateModelData(data: BenchmarkModelType) {
    return {
      nameAE: data.nameAE,
      nameEN: data.nameEN,
      benchType: data.benchType,
      benchSource: data.benchSource,
      benchFileList: data.benchFileList ?? [],
      years: (data.kpiBenchMarkLovs ?? []).map((data) => ({
        id: data.id ?? null,
        year: data.year,
        value: data.value,
        countryId: data.countryId,
      })),
    };
  }

  private _hydrateModel(data: BenchmarkModelType) {
    this.model.setValue({ ...this._generateModelData(data) }, { emitEvent: true, onlySelf: false });
  }

  private _deleteBenchmarkFile($e: TableActionEvent): void {
    const modal = this._modalService.open(ConfirmationModalComponent, {
      centered: true,
      size: "md",
      modalDialogClass: this.languageService.modalDirection(),
    });

    modal.componentInstance.header = "nkpi.benchmark_delete_attachment_yes_no_title";
    modal.componentInstance.title = "nkpi.benchmark_delete_attachment_yes_no_information";

    modal.result.then((e) => {
      if (e) {
        const files = this.model?.get("benchFileList")?.value ?? [];

        this._deleteFile($e.data.id);
        this.model.get("benchFileList")?.setValue(files.filter((_, $i) => $i !== $e.index));
        this.benchmarkUploadList()?.loadTableData();
        this.isDirty.set(true);
        return;
      }
    });
  }

  private _confirmCancellation() {
    const modal = this._modalService.open(ConfirmationModalComponent, {
      centered: true,
      size: "md",
      modalDialogClass: this.languageService.modalDirection(),
    });

    modal.componentInstance.header = "common.form.modals.cancel_yes_no_title";
    modal.componentInstance.title = "common.form.modals.cancel_yes_no_information";
    modal.componentInstance.subTitle = "common.form.label.are_you_sure";

    return modal;
  }

  private _deleteBenchmark($e: TableActionEvent): void {
    const modal = this._modalService.open(ConfirmationModalComponent, {
      centered: true,
      size: "md",
      modalDialogClass: this.languageService.modalDirection(),
    });

    modal.componentInstance.header = "nkpi.benchmark_delete_yes_no_title";
    modal.componentInstance.title = "nkpi.benchmark_delete_yes_no_information";

    modal.result.then((e) => {
      if (e) {
        const data = this.data();
        const $i = data.findIndex(({ id }) => id === $e.data.id);

        if ($i !== -1) {
          data[$i].status = ObjectStatus.REMOVE;
          this.data.set([...data]);
        } else {
          this._deleteFileList(data[$i].benchFileList);
          this.data.set([...data.filter((_, index) => index !== $e.index)]);
        }

        this.benchmarkList().loadTableData();
        this.onBenchmarkUpdate.emit(this.data());
      }
    });
  }

  private _deleteFileList(files?: AttachmentModelType[]) {
    forkJoin((files ?? []).map(({ id }) => this._filesApiService.deleteFile(id))).subscribe();
  }

  private _deleteFile(id: number) {
    this._filesApiService.deleteFile(id).subscribe();
  }
}
