import { Component, computed, inject, input, OnInit, signal, viewChild } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { forkJoin } from "rxjs";

import { AdaaHelper } from "../../../core/utils";
import {
  DataTableComponent,
  FormActionButtonsComponent,
  FormCheckboxComponent,
  FormDropdownComponent,
  FormDropdownMultiComponent,
  FormInputComponent,
  FormInputFileComponent,
} from "../../../shared/components";
import { Constants } from "../../../shared/constants/constants";
import { AdaaBoolean, FileInputType, PageMode } from "../../../shared/constants/enums";
import {
  AttachFile,
  EntityModelType,
  EntityOfficeModelType,
  PropTypeModelType,
  TableButtonClicked,
  UserModelType,
  ValueText,
} from "../../../shared/models";
import { EntitiesApiService, PropertiesService, SystemLayoutService, UsersApiService } from "../../../shared/services";
import { EntitiesEditorService } from "../../../shared/services/entities.editor.service";
import { AddressesEditorComponent } from "../../entity-profile/addresses/addresses-editor/addresses-editor.component";

@Component({
  selector: "adaa-entities-editor",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormInputComponent,
    FormCheckboxComponent,
    FormDropdownComponent,
    FormDropdownMultiComponent,
    FormActionButtonsComponent,
    TranslateModule,
    FormInputFileComponent,
    AddressesEditorComponent,
    DataTableComponent,
  ],
  templateUrl: "./entities-editor.component.html",
  styleUrl: "./entities-editor.component.scss",
})
export class EntitiesEditorComponent implements OnInit {
  private _entitiesApiService = inject(EntitiesApiService);
  private _usersApiService = inject(UsersApiService);
  private _propsService = inject(PropertiesService);
  private _activatedRoute = inject(ActivatedRoute);
  private _formBuilder = inject(FormBuilder);
  private _modalService = inject(NgbModal);
  private _translateService = inject(TranslateService);
  private _toastrService = inject(ToastrService);
  private _router = inject(Router);
  private _systemLayoutService = inject(SystemLayoutService);
  entitiesEditorService = inject(EntitiesEditorService);

  displayLabel = signal<string>("");
  submitted = signal<boolean>(false);
  logoVerticalExists = signal<boolean>(false);
  logoHorizontalExists = signal<boolean>(false);
  entity = signal<EntityModelType | undefined>(undefined);
  entityOffices = signal<EntityOfficeModelType[]>([]);
  filteredOffices = computed(() => {
    return this.entityOffices().filter((office) => office.status !== Constants.OBJECT_STATUS.REMOVE);
  });
  entityOffice = signal<EntityOfficeModelType | undefined>(undefined);
  untilDestroy = AdaaHelper.untilDestroyed();
  pageMode = input.required<string>();
  addressesPageMode = signal<string>("");
  addressesCurrentIndex = signal<number | undefined>(0);
  addressEditor = viewChild.required<AddressesEditorComponent>("editor");
  entitiesForm: FormGroup = new FormGroup({});
  entityId: string | null;
  allowedFiles: FileInputType[] = [
    FileInputType.bmp,
    FileInputType.gif,
    FileInputType.jpeg,
    FileInputType.jpg,
    FileInputType.png,
    FileInputType.svg,
  ];
  PageMode = PageMode;
  Constants = Constants;

  entityTypes = signal<PropTypeModelType[]>([]);
  entityTypeOptions = computed(() => {
    const field = AdaaHelper.getFieldLanguage("name");
    if (
      this.entity()?.entityType === Constants.AdaaEntityTypes.AUTHORITY ||
      this.entity()?.entityType === Constants.AdaaEntityTypes.MINISTRY
    ) {
      const index = this.entityTypes().findIndex((entity) => entity.id === Constants.AdaaEntityTypes.EXTERNAL);
      this.entityTypes().splice(index, 1);
    }
    const result: ValueText[] = AdaaHelper.setDropdownArray(this.entityTypes(), "id", field);
    result.unshift({ value: "", text: this._translateService.instant("common.form.label.none") });
    return result;
  });

  entitySizes = signal<PropTypeModelType[]>([]);
  entitySizeOptions = computed(() => {
    const field = AdaaHelper.getFieldLanguage("name");
    const result: ValueText[] = AdaaHelper.setDropdownArray(this.entitySizes(), "id", field);
    result.unshift({ value: "", text: this._translateService.instant("common.form.label.none") });
    return result;
  });

  users = signal<UserModelType[]>([]);
  usersOptions = computed(() => {
    const field = AdaaHelper.getFieldLanguage("name");
    const result: ValueText[] = AdaaHelper.setDropdownArray(this.users(), "id", field);
    result.unshift({ value: "", text: this._translateService.instant("common.form.label.none") });
    return result;
  });

  public ngOnInit(): void {
    this.entitiesEditorService.formValidator();
    this.entityId = this._activatedRoute.snapshot?.paramMap?.get("id");
    if (this.entityId) {
      this._entitiesApiService.getById(+this.entityId).subscribe({
        next: (response) => {
          this.entity.set(response.responseData);
          this.entitiesForm.patchValue(response.responseData);
          if (response.responseData.entityType === Constants.AdaaEntityTypes.EXTERNAL) {
            this.entitiesForm.get("entityType")?.disable();
            this.entitiesForm.get("enabler")?.disable();
          }
          if (response.responseData.logoHorizontalAttach !== null) this.logoHorizontalExists.set(true);
          if (response.responseData.logoVerticalAttach !== null) this.logoVerticalExists.set(true);
          response.responseData.entityOffice && this.entityOffices.set(response.responseData.entityOffice);
        },
      });
    }

    this._initProps();
    this._prepareForm();
  }

  public getImage(direction: "horizontal" | "vertical"): AttachFile {
    const isHorizontal = direction === "horizontal";

    const attach = this.entitiesForm.get(isHorizontal ? "logoHorizontalAttach" : "logoVerticalAttach")?.value;
    return {
      id: attach?.id ?? 0,
      filename: attach?.filename ?? "",
    };
  }

  public getFileMessage(direction: "horizontal" | "vertical"): string {
    if (direction === "horizontal" && this.logoHorizontalExists()) return "";
    if (direction === "vertical" && this.logoVerticalExists()) return "";
    return `${this._translateService.instant(direction === "horizontal" ? "entity.create.horizontal_logo_guidelines" : "entity.create.vertical_logo_guidelines")} 
    <br> ${this._translateService.instant("entity.files_allowed")} 
    <br> ${this._translateService.instant("entity.max_file_size")}`;
  }

  private _initProps() {
    const requests = {
      entitySizes: this._propsService.getPropById(Constants.CONSTANT_ENTITY_SIZE),
      entityTypes: this._propsService.getPropById(Constants.CONSTANT_ENTITY_TYPE),
      ...(this.entityId ? { users: this._usersApiService.getOwnersPerEntity(+this.entityId) } : {}),
    };
    forkJoin(requests).subscribe({
      next: (results) => {
        this.entityTypes.set(results.entityTypes.responseData);
        this.entitySizes.set(results.entitySizes.responseData);
        results.users && this.users.set(results.users.responseData);
        if (!AdaaHelper.isPMOEntity()) {
          this._entitiesApiService.getById(Constants.CONSTANT_PMO_ID).subscribe({
            next: (response) => {
              this._systemLayoutService.selectEntity(response.responseData);
            },
          });
        }
      },
    });
  }

  private _prepareForm() {
    const isViewMode = this.pageMode() === PageMode.view;

    this.entitiesForm = this._formBuilder.group({
      nameAE: this._formBuilder.control<string | undefined>(
        { value: "", disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      nameEN: this._formBuilder.control<string | undefined>(
        { value: "", disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      dscAE: this._formBuilder.control<string | undefined>({ value: "", disabled: isViewMode }),
      dscEN: this._formBuilder.control<string | undefined>({ value: "", disabled: isViewMode }),
      shortNameAE: this._formBuilder.control<string | undefined>(
        { value: "", disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      entityType: this._formBuilder.control<string | undefined>(
        { value: "", disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      entitySize: this._formBuilder.control<string | undefined>(
        { value: "", disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      enabler: this._formBuilder.control<string | undefined>({ value: "", disabled: isViewMode }),
      ministers: this._formBuilder.control<string | undefined>({ value: "", disabled: isViewMode }),
      directorGenerals: this._formBuilder.control<string | undefined>({ value: "", disabled: isViewMode }),
      skpiopmFocalPointId: this._formBuilder.control<string | null | undefined>({ value: null, disabled: isViewMode }),
      dkpiFocalPointId: this._formBuilder.control<string | null | undefined>({ value: null, disabled: isViewMode }),
      servicesFocalPointId: this._formBuilder.control<string | null | undefined>({ value: null, disabled: isViewMode }),
      ekpiFocalPointId: this._formBuilder.control<string | null | undefined>({ value: null, disabled: isViewMode }),
      strategyManagerId: this._formBuilder.control<string | null | undefined>({ value: null, disabled: isViewMode }),
      nkpiFocalPointId: this._formBuilder.control<string | null | undefined>({ value: null, disabled: isViewMode }),
      logoHorizontalAttach: null,
      logoVerticalAttach: null,
    });

    this.entitiesForm.valueChanges.pipe(this.untilDestroy()).subscribe((value) => {
      const displayLanguage = AdaaHelper.getFieldLanguage("name");
      if (!value[displayLanguage]?.length) {
        this.displayLabel.set(value["nameAE"] || value["nameEN"]);
      } else {
        this.displayLabel.set(value[displayLanguage]);
      }
    });
  }

  public checkInvalid(control: string) {
    return (
      this.entitiesForm.controls[control]?.errors?.["required"] &&
      (this.entitiesForm.get(control)?.dirty || this.submitted())
    );
  }

  public addNew() {
    this.addressEditor().addressForm.reset({});
    this.addressEditor().addressForm.enable();
    this.addressEditor().markerPositions = [];
    this.addressEditor().open();
    if (
      this.entityOffices().find(
        (office) => office.isHQ === AdaaBoolean.Y && office.status !== Constants.OBJECT_STATUS.REMOVE
      )
    )
      this.addressEditor().addressForm.get("isHQ")?.disable();
  }

  public getAction(value: TableButtonClicked) {
    this.addressesCurrentIndex.set(value.index);
    switch (value.event) {
      case "view":
        this.entityOffice.set(value.data);
        this.addressesPageMode.set(PageMode.view);
        this.addressEditor().addressForm.patchValue(value.data);
        this.addressEditor().addressForm.disable();
        this.addressEditor().open();
        break;
      case "edit":
        this.entityOffice.set(value.data);
        this.addressesPageMode.set(PageMode.edit);
        this.addressEditor().addressForm.patchValue(value.data);
        this.addressEditor().addressForm.enable();
        this.addressEditor().open();
        break;
      case "delete": {
        const offices: EntityOfficeModelType[] = AdaaHelper.clone(this.entityOffices());
        offices[value.index!].status = Constants.OBJECT_STATUS.REMOVE;
        this.entityOffices.set(offices);
        break;
      }

      default:
        break;
    }
  }

  public saveAddress(data: EntityOfficeModelType) {
    const offices: EntityOfficeModelType[] = AdaaHelper.clone(this.entityOffices());
    if (this.addressesPageMode() === PageMode.edit) {
      offices.splice(this.addressesCurrentIndex()!, 1, data);
    } else offices.push(data);
    this.entityOffices.set(offices);
    this._modalService.dismissAll();
  }

  public onFilechange(event: AttachFile | null, direction: "horizontal" | "vertical") {
    if (event) {
      if (direction === "horizontal") {
        this.entitiesForm.get("logoHorizontalAttach")?.setValue({
          id: event.id,
          attachFile: null,
          filename: event.filename,
          activityId: null,
          taskId: null,
          auxAttachFile: {
            filename: null,
          },
        });
      }
      if (direction === "vertical") {
        this.entitiesForm.get("logoVerticalAttach")?.setValue({
          id: event.id,
          attachFile: null,
          filename: event.filename,
          activityId: null,
          taskId: null,
          auxAttachFile: {
            filename: null,
          },
        });
      }
      if (direction === "horizontal") {
        this.logoHorizontalExists.set(true);
      } else this.logoVerticalExists.set(true);
    } else {
      this.entitiesForm.get(direction === "horizontal" ? "logoHorizontalAttach" : "logoVerticalAttach")?.setValue(null);
      if (direction === "horizontal") {
        this.logoHorizontalExists.set(false);
      } else this.logoVerticalExists.set(false);
    }
  }

  public submit() {
    this.submitted.set(true);
    if (this.entitiesForm.invalid) {
      this._toastrService.error(this._translateService.instant("notification.warning.missing_info"));
      return;
    }

    const entityOffices = this.entityOffices().map((office) => {
      return {
        geoX: office.geoX,
        geoY: office.geoY,
        entityId: office.entityId,
        addressAE: office.addressAE,
        addressEN: office.addressEN,
        zipCodeAE: office.zipCodeAE,
        cityAE: office.cityAE,
        cityEN: office.cityEN,
        isHQ: office.isHQ,
        id: office.id,
        status: office.status,
        updateTS: office.updateTS,
      };
    });
    const result = {
      ...this.entity(),
      ...this.entitiesForm.value,
      entityOffice: [...(entityOffices ?? [])],
      shortNameEN: this.entitiesForm.value.shortNameAE,
      status: Constants.OBJECT_STATUS.ACTIVE,
      ...(this.entitiesForm.value.logoHorizontalAttach === null && {
        logoHorizontalAttach: {
          id: null,
          attachFile: null,
          filename: null,
          activityId: null,
          taskId: null,
          auxAttachFile: {
            filename: null,
          },
        },
      }),
      ...(this.entitiesForm.value.logoVerticalAttach === null && {
        logoVerticalAttach: {
          id: null,
          attachFile: null,
          filename: null,
          activityId: null,
          taskId: null,
          auxAttachFile: {
            filename: null,
          },
        },
      }),
    };

    if (this.pageMode() === PageMode.create) {
      delete result.directorGenerals;
      delete result.ministers;
      this._entitiesApiService.createEntity(result).subscribe({
        next: (response) => {
          if (response.inError) return;
          this._toastrService.success(this._translateService.instant("notification.success.save"));
          this._router.navigateByUrl("/console/entities");
        },
      });
    }
    if (this.pageMode() === PageMode.edit) {
      this._entitiesApiService.updateEntity(result).subscribe({
        next: (response) => {
          if (response.inError) return;
          this._toastrService.success(this._translateService.instant("notification.success.save"));
          this._router.navigateByUrl("/console/entities");
        },
      });
    }
  }

  public close() {
    this._router.navigateByUrl("/console/entities");
  }
}
