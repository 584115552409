import { Component, inject, input, OnInit, signal, viewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { AdaaHelper } from "../../../../core/utils";
import { ConfirmationModalComponent, DataTableComponent } from "../../../../shared/components";
import { Constants } from "../../../../shared/constants/constants";
import { AdaaBoolean, Language, PageMode } from "../../../../shared/constants/enums";
import { EntityModelType, EntityOfficeModelType, TableButtonClicked } from "../../../../shared/models";
import { AppService, EntitiesApiService, SystemLayoutService } from "../../../../shared/services";
import { AddressesEditorComponent } from "../addresses-editor/addresses-editor.component";

@Component({
  selector: "adaa-addresses-list",
  standalone: true,
  imports: [DataTableComponent, TranslateModule, AddressesEditorComponent],
  templateUrl: "./addresses-list.component.html",
  styleUrl: "./addresses-list.component.scss",
})
export class AddressesListComponent implements OnInit {
  private _entitiesApiService = inject(EntitiesApiService);
  private _translateService = inject(TranslateService);
  private _toastrService = inject(ToastrService);
  private _modalService = inject(NgbModal);
  private _appService = inject(AppService);
  private _systemLayoutService = inject(SystemLayoutService);

  addressEditor = viewChild.required<AddressesEditorComponent>("editor");
  adaaDataTable = viewChild.required<DataTableComponent>("adaaDataTable");
  tableConfList = input.required<string>();
  entity = signal<EntityModelType>({
    id: 0,
    refCode: "",
    nameAE: "",
    nameEN: "",
    shortNameAE: "",
    shortNameEN: "",
    entityType: 0,
    entityTypeNameAE: "",
    entityTypeNameEN: "",
    entitySize: 0,
    entitySizeNameAE: "",
    entitySizeNameEN: "",
    dscAE: "",
    dscEN: "",
    logoHorizontalOffset: 0,
    status: 0,
    logoVerticalAttach: null,
    logoHorizontalAttach: null,
    enabler: AdaaBoolean.Y,
    enabled: AdaaBoolean.Y,
    pulseUrl: "",
    updateTS: 0,
  });
  entityOffice = signal<EntityOfficeModelType[]>([]);
  currentEntityOffice = signal<EntityOfficeModelType>({
    id: 0,
    status: 0,
    nameAE: "",
    nameEN: "",
    geoX: 0,
    geoY: 0,
    entityId: 0,
    addressAE: "",
    addressEN: "",
    zipCodeAE: "",
    cityAE: "",
    cityEN: "",
    isHQ: AdaaBoolean.Y,
    updateTS: 0,
  });
  pageMode = signal<string>("view");
  hqExists = signal<boolean>(false);
  private readonly _untilDestroyed = AdaaHelper.untilDestroyed();

  Constants = Constants;

  public ngOnInit(): void {
    this._getEntity();
    this._systemLayoutService.hasActiveEntityChanged$.pipe(this._untilDestroyed()).subscribe({
      next: () => this._getEntity(),
    });
  }

  private _getEntity() {
    const entityId = AdaaHelper.getLocalStorage(Constants.localStorageKeys.currentEntity, {
      type: "prop",
      property: "id",
    });
    this._entitiesApiService.getById(+entityId).subscribe({
      next: (response) => {
        if (response.responseData.entityOffice) {
          this.entity.set(response.responseData);
          this.entityOffice.set(response.responseData.entityOffice);
          if (
            this.entityOffice().find(
              (office) => office.isHQ === AdaaBoolean.Y && office.status !== Constants.OBJECT_STATUS.REMOVE
            )
          )
            this.hqExists.set(true);
          else this.hqExists.set(false);
        }
      },
      complete: () => this.adaaDataTable().loadTableData(),
    });
  }

  public addNew() {
    this.pageMode.set("create");
    this.addressEditor().addressForm.reset({});
    this.addressEditor().addressForm.enable();
    if (this.hqExists()) this.addressEditor().addressForm.get("isHQ")?.disable();
    this.addressEditor().markerPositions = [];
    this.addressEditor().open();
  }

  public getAction(value: TableButtonClicked) {
    switch (value.event) {
      case "view":
        this._handleAddressEditor(value, "view");
        break;
      case "edit":
        this._handleAddressEditor(value, "edit");
        break;
      case "delete":
        this._deleteAddress(value.data);
        break;
      default:
        break;
    }
  }

  private _handleAddressEditor(value: TableButtonClicked, mode: string) {
    this.currentEntityOffice.set(value.data);
    this.pageMode.set(mode);
    if (mode === "view") {
      Object.keys(this.addressEditor().addressForm.controls).forEach((key) => {
        this.addressEditor().addressForm.get(key)?.disable();
      });
    } else this.addressEditor().addressForm.enable();
    if (this.hqExists() && AdaaHelper.isDefinedAndNotEmpty(value.data.isHQ) && value.data.isHQ === AdaaBoolean.N)
      this.addressEditor().addressForm.get("isHQ")?.disable();
    this.addressEditor().addressForm.patchValue(value.data);
    this.addressEditor().markerPositions = [
      {
        lng: value.data.geoX,
        lat: value.data.geoY,
      },
    ];
    this.addressEditor().open();
  }

  private _deleteAddress(data: EntityOfficeModelType) {
    const modal = this._modalService.open(ConfirmationModalComponent, {
      animation: true,
      scrollable: false,
      keyboard: false,
      size: "lg",
      centered: true,
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });

    modal.componentInstance.header = "provisioning.address.delete_yes_no_title";
    modal.componentInstance.title = "provisioning.address.delete_yes_no_information";

    modal.result.then((e) => {
      if (e) {
        data.status = Constants.OBJECT_STATUS.REMOVE;
        const index = this.entity().entityOffice?.findIndex((office) => office.id === data.id);
        const entityOffice = this.entity().entityOffice;
        if (entityOffice && index !== undefined && index > -1) {
          entityOffice[index] = data;
          this.entity().entityOffice = entityOffice;
        }
        this._entitiesApiService.updateAddresses(this.entity()).subscribe({
          complete: () => {
            this._toastrService.success(this._translateService.instant("notification.success.remove"));
            this._getEntity();
          },
        });
      }
    });
  }

  public submit(data: EntityOfficeModelType) {
    if (this.pageMode() === PageMode.create) {
      this.entity().entityOffice?.push(data);
    }
    if (this.pageMode() === PageMode.edit) {
      const entityOffice = this.entity().entityOffice;
      const index = this.entity().entityOffice?.findIndex((office) => office.id === this.currentEntityOffice().id);
      if (entityOffice && index !== undefined && index > -1) {
        entityOffice[index] = data;
        this.entity().entityOffice = entityOffice;
      }
    }

    this._entitiesApiService.updateAddresses(this.entity()).subscribe({
      next: () => {
        this._modalService.dismissAll();
        this._toastrService.success(this._translateService.instant("notification.success.save"));
        this._getEntity();
      },
    });
  }
}
