import { Route } from "@angular/router";

import { checkIfLoggedInFn } from "../../core/guards";
import { Constants } from "../../shared/constants/constants";
import {
  viewAddressesPageGuards,
  viewEntityProfilePageGuards,
  viewOrganizationStructurePageGuards,
  viewStrategicDocumentPageGuards,
} from "./_guards";
import { AddressesListComponent } from "./addresses/addresses-list/addresses-list.component";
import { EntityProfileSubmenuComponent } from "./entity-profile-submenu/entity-profile-submenu.component";
import { OrganizationUnitComponent } from "./organization-unit/organization-unit.component";
import { StrategicDocumentComponent } from "./strategic-document/strategic-document.component";

export const ENTITY_PROFILE_ROUTES: Route[] = [
  {
    path: "provisioning",
    canActivate: [checkIfLoggedInFn, ...viewEntityProfilePageGuards],
    children: [
      {
        path: "",
        component: EntityProfileSubmenuComponent,
      },
      {
        path: "organization-unit",
        component: OrganizationUnitComponent,
        canActivate: [...viewOrganizationStructurePageGuards],
        data: {
          breadcrumb: "provisioning.organization_structure",
          title: "provisioning.organization_structure",
        },
      },
      {
        path: "addresses",
        component: AddressesListComponent,
        canActivate: [...viewAddressesPageGuards],
        data: {
          breadcrumb: "provisioning.addresses",
          title: "provisioning.addresses",
          tableConfList: Constants.MAIN_TABLE_LIST_CONF_KEY.ENTITY_OFFICES,
        },
      },
      {
        path: "directives",
        component: StrategicDocumentComponent,
        canActivate: [...viewStrategicDocumentPageGuards],
        data: {
          breadcrumb: "provisioning.directives",
          title: "provisioning.directives",
        },
      },
    ],
    data: {
      breadcrumb: "breadcrumbs.provisioning",
      title: "breadcrumbs.provisioning",
    },
  },
];
