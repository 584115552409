<div class="d-flex gap-2">
    <p class="fw-bold fs-3">{{ "annual_meetings.cycles.title" | translate }} -</p>
    <p class="fw-bold fs-3">{{ displayLabel() }}</p>
</div>

<div class="bg-white shadow-md rounded p-2 mb-3">
    <form class="col-12" [formGroup]="agmCycleForm">
        <div class="bg-white shadow-md rounded p-2 mb-3">
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.nameAE' | translate"
                        formControlName="nameAE"
                        controlName="nameAE"
                        [setValidator]="agmCyclesEditorService.formValidation()!"
                        [invalid]="checkInvalid('nameAE')"
                    ></adaa-form-input>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.nameEN' | translate"
                        formControlName="nameEN"
                        controlName="nameEN"
                        [setValidator]="agmCyclesEditorService.formValidation()!"
                        [invalid]="checkInvalid('nameEN')"
                    ></adaa-form-input>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input-date
                        [label]="'national_projects.planned_start_date' | translate"
                        [setValidator]="agmCyclesEditorService.formValidation()!"
                        [invalid]="checkInvalid('startDate') || agmCycleForm.errors?.startDate_endDate_Invalid"
                        formControlName="startDate"
                        controlName="startDate"
                    />
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input-date
                        [label]="'national_projects.planned_end_date' | translate"
                        [setValidator]="agmCyclesEditorService.formValidation()!"
                        [invalid]="checkInvalid('endDate') || agmCycleForm.errors?.startDate_endDate_Invalid"
                        formControlName="endDate"
                        controlName="endDate"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.descriptionAE' | translate"
                        formControlName="dscAE"
                        controlName="dscAE"
                        [isTextArea]="true"
                        [setValidator]="agmCyclesEditorService.formValidation()!"
                        [invalid]="checkInvalid('dscAE')"
                    ></adaa-form-input>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.descriptionEN' | translate"
                        formControlName="dscEN"
                        controlName="dscEN"
                        [isTextArea]="true"
                        [setValidator]="agmCyclesEditorService.formValidation()!"
                        [invalid]="checkInvalid('dscEN')"
                    ></adaa-form-input>
                </div>
            </div>
        </div>
    </form>
</div>

<div class="my-2">
    <adaa-form-action-buttons
        [showSaveButton]="pageMode() !== PageMode.view"
        [saveButtonTitle]="'common.form.button.submit' | translate"
        [cancelButtonTitle]="pageMode() === PageMode.view ? ('common.form.button.close' | translate) : ('common.form.button.cancel' | translate)"
        (saveClicked)="submit()"
        (cancelClicked)="close()"
    ></adaa-form-action-buttons>
</div>
