import { Route } from "@angular/router";

import { checkIfNotLoggedInFn } from "../../core/guards";
import { ForgetPasswordComponent } from "./forget-password/forget-password.component";
import { LinkAccountComponent } from "./link-account/link-account.component";
import { LoginComponent } from "./login/login.component";
import { LoginVerificationComponent } from "./login-verification/login-verification.component";
import { NewPasswordComponent } from "./new-password/new-password.component";

export const AUTH_ROUTES: Route[] = [
  {
    path: "login",
    component: LoginComponent,
    canActivate: [checkIfNotLoggedInFn],
    data: {
      title: "login.login",
      breadcrumb: "login.login",
    },
  },
  {
    path: "verification",
    canActivate: [checkIfNotLoggedInFn],
    component: LoginVerificationComponent,
    data: {
      title: "login.two_steps_title",
      breadcrumb: "login.two_steps_title",
    },
  },
  {
    path: "forget-password",
    canActivate: [checkIfNotLoggedInFn],
    component: ForgetPasswordComponent,
    data: {
      title: "login.forgot_password_title",
      breadcrumb: "login.forgot_password_title",
    },
  },
  {
    path: "new-password/:token",
    canActivate: [checkIfNotLoggedInFn],
    component: NewPasswordComponent,
    data: {
      title: "login.new_password",
      breadcrumb: "login.new_password",
    },
  },
  {
    path: "link-account/:uuid",
    canActivate: [checkIfNotLoggedInFn],
    component: LinkAccountComponent,
    data: {
      title: "login.link_account_title",
      breadcrumb: "login.link_account_title",
    },
  },
];
