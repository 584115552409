@if (adaaHelper.isDefinedAndNotEmpty(label)) {
    <label for="{{ controlId }}" class="form-label fw-bold" [dir]="languageService.direction()">
        {{ label }}
        <span *ngIf="required" class="form-required text-danger fw-bolder">*</span>
    </label>
}

<input
    id="time"
    type="time"
    min="00:00"
    max="23:59"
    class="form-control"
    aria-describedby="time"
    pattern="[0-9]{2}:[0-9]{2}"
    [class.is-invalid]="invalid"
    [disabled]="isDisabled"
    (ngModelChange)="valueChanged($event)"
    [(ngModel)]="time"
/>
