import { NgClass } from "@angular/common";
import { Component, EventEmitter, forwardRef, Input, input, OnInit, Output } from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";

import { TranslateTokenPipe } from "../../../../../core/pipes";
import { AdaaHelper } from "../../../../../core/utils";
import { Constants } from "../../../../../shared/constants/constants";
import type { ParameterCatalog, PropTypeModelType } from "../../../../../shared/models";

@Component({
  selector: "adaa-kpi-trend-input",
  standalone: true,
  styleUrl: "../styles.scss",
  imports: [TranslateModule, TranslateTokenPipe, NgClass],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => KpiTrendInputComponent),
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => KpiTrendInputComponent),
    },
  ],
  template: `
    <section class="d-flex row mt-2 mb-3 py-2">
      <div class="col-12 text-center py-2">
        <span class="fw-bold">
          {{ "nkpi.trend" | translate }} <span class="text-danger">{{ required() ? "*" : "" }}</span>
        </span>
      </div>

      <div class="col-12 pt-3 pb-2">
        <div class="d-flex justify-content-center align-content-center align-items-center gap-3 flex-sm-wrap">
          @for (option of options(); track option.id) {
            <button
              class="btn border-1 trend-option"
              [class.selected]="inputControl.value === option.id && !invalid()"
              [class.has-error]="invalid()"
              [class.disabled]="option.inputDisabled"
              (click)="valueChanged(option.id)"
            >
              <div class="trend-icon">
                <i [ngClass]="getIcon(option.id)"></i>
              </div>

              <div class="trend-label fw-bold">
                {{ option | translateToken: "name" }}
              </div>
            </button>
          }
        </div>
      </div>
    </section>
  `,
})
export class KpiTrendInputComponent implements OnInit, ControlValueAccessor {
  required = input<boolean>(false);
  invalid = input<boolean>(false);
  controlName = input<string>();
  options = input<(PropTypeModelType & { inputDisabled?: boolean })[]>([]);

  inputControl: FormControl = new FormControl();
  adaaHelper = AdaaHelper;

  private _onValidatorChange: () => void;
  private _onTouched: () => void;
  private _onChange: (value: string) => void;

  @Input()
  public set setDefaultValue(value: unknown) {
    if (value) {
      this.writeValue(value as string);
    }
  }

  @Input()
  public set setValidator(validatorList: { parameterCatalogs: ParameterCatalog[] }) {
    if (validatorList) {
      if (validatorList.parameterCatalogs) {
        const field = validatorList.parameterCatalogs.find((e) => e.fieldName === this.controlName());

        if (field) {
          this._updateValidation(field.mandatory === "Y");
        }
      }
    }
  }

  @Output() inputChanges = new EventEmitter();

  readonly getIcon = (id: number) => {
    if (id === Constants.TREND.INCREASING) return "fa-solid fa-arrows-up-to-line";
    if (id === Constants.TREND.DECREASING) return "fa-solid fa-arrows-down-to-line";
    if (id === Constants.TREND.ONTARGET) return "fa-solid fa-equals";
    if (id === Constants.TREND.BOUNDED) return "fa-solid fa-square";
    return "";
  };

  public ngOnInit(): void {
    this._updateValidation();
  }

  public writeValue(obj: string | number | null | undefined): void {
    if (!AdaaHelper.isDefined(obj)) this.inputControl.reset();
    this.inputControl.setValue(obj);
  }

  public registerOnChange(fn: (value: string) => void): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  public registerOnValidatorChange?(fn: () => void): void {
    this._onValidatorChange = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.inputControl.disable() : this.inputControl.enable();
  }

  public validate(_value: FormControl): false | { required: boolean } {
    const isRequired =
      (this.required() && this.inputControl.value === null) || (this.required() && this.inputControl.value === "");
    return (
      isRequired && {
        required: true,
      }
    );
  }

  private _updateValidation(isRequired = false): void {
    if (this.required() || isRequired) {
      this.inputControl.setValidators([Validators.required]);
    }

    this.inputControl.updateValueAndValidity();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public valueChanged(event: any): void {
    if (this._onChange) this._onChange(event);

    this.inputChanges.emit(event);
  }
}
