import { Component, inject, input, OnInit, output, signal } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";

import { TranslateTokenPipe } from "../../../../core/pipes";
import { AdaaHelper } from "../../../../core/utils";
import { FormDropdownComponent, FormDropdownMultiComponent } from "../../../../shared/components";
import { PageMode } from "../../../../shared/constants/enums";
import {
  DataEntryMetricModel,
  MetricDimensionValue,
  ParentMetric,
  ValidatorModelType,
  ValueText,
} from "../../../../shared/models";
import { MetricApiService } from "../../../../shared/services";

@Component({
  selector: "adaa-metrics-dimensions-editor",
  imports: [
    TranslateTokenPipe,
    ReactiveFormsModule,
    TranslateModule,
    FormDropdownComponent,
    FormDropdownMultiComponent,
  ],
  templateUrl: "./metrics-dimensions-editor.component.html",
  styleUrl: "./metrics-dimensions-editor.component.scss",
})
export class MetricsDimensionsEditorComponent implements OnInit {
  private _formBuilder = inject(FormBuilder);
  private _metricsApiService = inject(MetricApiService);

  pageMode = input.required<string>();
  submitted = input.required<boolean>();
  formValidation = input.required<ValidatorModelType | undefined>();
  metric = input.required<DataEntryMetricModel | undefined>();

  formChanged = output<{ [key: string]: unknown }>();
  parentMetricChanged = output<ParentMetric>();

  private _parentMetricsCopy = signal<ParentMetric[]>([]);

  entitiesMetrics = signal<ValueText[]>([]);
  parentMetrics = signal<ValueText[]>([]);
  dimensionsTypes = signal<ValueText[]>([]);

  form: FormGroup;

  public ngOnInit(): void {
    this._prepareForm();
    this._initPage();

    const metric = this.metric();
    if (!metric) return;

    this.form.patchValue(metric);

    const entityId = metric.entityIds?.at(0);
    if (entityId) {
      this.form.get("parentMetricEntityId")?.setValue(entityId);
      this.parentMetricEntityChanged(entityId);
    }

    this.parentMetricDdlChanged(metric.parentMetricId);

    const dimensionsTypes = metric.dimensions?.map((e) => e.dimensionId);
    if (dimensionsTypes) {
      this.form.get("dimensionsTypes")?.setValue(dimensionsTypes);
      this.dimensionsTypesChanged(dimensionsTypes);
    }
  }

  public parentMetricEntityChanged(value: number | undefined, resetControls: boolean = false): void {
    if (resetControls) {
      this.form.get("parentMetricId")?.setValue(null);
      this.parentMetricDdlChanged(undefined, true);
    }

    this.formChanged.emit(this.form.getRawValue());

    if (!value) {
      this.parentMetrics.set([]);
      this._parentMetricsCopy.set([]);

      return;
    }

    this._metricsApiService.getParentMetrics(value).subscribe({
      next: (response) => {
        if (response.inError) return;
        this.parentMetrics.set(
          AdaaHelper.setDropdownArray(response.responseData, "id", AdaaHelper.getFieldLanguage("name"))
        );

        this._parentMetricsCopy.set(response.responseData);
      },
    });
  }

  public parentMetricDdlChanged(value: number | undefined, resetControls: boolean = false): void {
    if (resetControls) {
      this.form.get("dimensionsTypes")?.setValue(null);
    }

    this.formChanged.emit(this.form.getRawValue());

    if (!value) {
      this.dimensionsTypes.set([]);
      return;
    }

    //NOTE: Emit the parent metric this is used to fill the name and data source of metric
    const parentMetric = this._parentMetricsCopy().find((e) => e.id === value);
    if (parentMetric) this.parentMetricChanged.emit(parentMetric);

    this._metricsApiService.getDimensionsByParentMetric(value).subscribe({
      next: (response) => {
        if (response.inError) return;
        this.dimensionsTypes.set(
          AdaaHelper.setDropdownArray(response.responseData, "id", AdaaHelper.getFieldLanguage("name"))
        );
      },
    });
  }

  public dimensionsTypesChanged(values: number[] | undefined) {
    if (!values || values.length === 0) {
      this.form.get("dimensions")?.setValue([]);
      this.formChanged.emit(this.form.getRawValue());
      return;
    }

    this._metricsApiService.getDimensionsValues(values).subscribe({
      next: (response) => {
        if (response.inError) return;

        const metricDimensionValue: MetricDimensionValue[] = [];
        const dimensions = (this.form.get("dimensions")?.value as MetricDimensionValue[]) ?? [];
        values.forEach((value) => {
          const dimension = this.dimensionsTypes().find((e) => e.value === value);

          metricDimensionValue.push({
            dimensionId: value,
            nameEN: dimension ? dimension.text : "",
            nameAE: dimension ? dimension.text : "",
            dimensionValueIds: dimensions.find((e) => e.dimensionId === value)?.dimensionValueIds ?? [],
            options: AdaaHelper.setDropdownArray(
              response.responseData.filter((e) => e.dimensionsId === value) ?? [],
              "id",
              AdaaHelper.getFieldLanguage("name")
            ),
          });
        });
        this.form.get("dimensions")?.setValue(metricDimensionValue);
        this.formChanged.emit(this.form.getRawValue());
      },
    });
  }

  public dimensionsValuesChanged(values: number[] | undefined, dimensionType: MetricDimensionValue) {
    const dimensions = (this.form.get("dimensions")?.value as MetricDimensionValue[]) ?? [];

    const dimension = dimensions.find((e) => e.dimensionId === dimensionType.dimensionId);
    if (dimension) dimension.dimensionValueIds = values ?? [];

    this.form.get("dimensions")?.setValue(dimensions);
    this.formChanged.emit(this.form.getRawValue());
  }

  private _initPage(): void {
    this._metricsApiService.getParentMetricEntities().subscribe({
      next: (response) => {
        if (!response.inError)
          this.entitiesMetrics.set(
            AdaaHelper.setDropdownArray(response.responseData, "id", AdaaHelper.getFieldLanguage("name"))
          );
      },
    });
  }

  private _prepareForm(): void {
    const isViewMode = this.pageMode() === PageMode.view;

    this.form = this._formBuilder.group({
      parentMetricEntityId: [{ value: null, disabled: isViewMode }, Validators.required],
      parentMetricId: [{ value: null, disabled: isViewMode }, Validators.required],
      dimensions: { value: null, disabled: isViewMode },

      //ONLY FE
      dimensionsTypes: { value: null, disabled: isViewMode },
    });
  }
}
