import { CommonModule } from "@angular/common";
import { Component, computed, inject, OnDestroy, OnInit, signal } from "@angular/core";
import { Router } from "@angular/router";
import { NgbPopoverModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";

import { AdaaHelper } from "../../../../core/utils";
import {
  DoughnutComponent,
  FormCheckboxComponent,
  FormDropdownComponent,
  ProgressIndicatorComponent,
  RibbonComponent,
  TpBarsComponent,
  TpMainSectorsComponent,
  TpOwnerComponent,
  TpSectorsComponent,
} from "../../../../shared/components";
import { Constants } from "../../../../shared/constants/constants";
import { Language, TpAgmStatus } from "../../../../shared/constants/enums";
import { SystemLayoutService, TpDashboardService } from "../../../../shared/services";

@Component({
  selector: "adaa-tp-dashboard",
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    DoughnutComponent,
    FormCheckboxComponent,
    FormDropdownComponent,
    ProgressIndicatorComponent,
    TpBarsComponent,
    TpOwnerComponent,
    TpMainSectorsComponent,
    TpSectorsComponent,
    RibbonComponent,
    NgbPopoverModule,
  ],
  templateUrl: "./tp-dashboard.component.html",
  styleUrl: "./tp-dashboard.component.scss",
})
export class TpDashboardComponent implements OnInit, OnDestroy {
  private _systemLayoutService = inject(SystemLayoutService);
  private _translateService = inject(TranslateService);

  tpService = inject(TpDashboardService);
  router = inject(Router);

  private _subscription = new Subscription();

  constants = Constants;
  adaaHelper = AdaaHelper;
  activeTab = 1;
  TpAgmStatus = TpAgmStatus;
  lang = signal<Language>(AdaaHelper.getCurrentLang());
  Language = Language;

  projectStatusHelp = {
    getText: () =>
      AdaaHelper.replaceTextWithToken.iterator(
        this._translateService.instant(`national_projects.messages.tp_dashboard`),
        [{ unit: this.tpService.dashboardData()?.delayedThreshold ?? "", str: "delayed_threshold" }]
      ),
    activated: false,
  };

  dashboardWidgets = computed(() => {
    const items = [
      {
        label: "national_projects.console_title",
        data: this.tpService.dashboardData()?.nationalProjectCount,
        icon: "adaa-icon-national_projects",
      },
      {
        label: "national_projects.milestones",
        data: this.tpService.dashboardData()?.milestoneCount,
        icon: "adaa-icon-milestones",
      },
      {
        label: "national_projects.national_targets",
        data: this.tpService.dashboardData()?.mainOutcomeCount,
        icon: "adaa-icon-targets",
      },
      {
        label: "national_projects.allocated_budget",
        data: this.adaaHelper.amountToString(this.tpService.dashboardData()?.budget),
        symbol: AdaaHelper.getCurrencySymbol(this.tpService.dashboardData()?.budget, true),
        icon: "adaa-icon-allocated_budget",
      },
    ];

    if (this.adaaHelper.isPMOEntity()) {
      items.push(
        {
          label: "national_projects.work_team_members",
          data: this.tpService.dashboardData()?.teamMemberCount,
          icon: "adaa-icon-console_userGroups",
        },
        {
          label: "national_projects.number_of_sectors",
          data: this.tpService.dashboardData()?.usedNationalSectorCount,
          icon: "adaa-icon-console_sectors",
        }
      );
    } else {
      items.push({
        label: "national_projects.actual_expenditure",
        data: this.adaaHelper.amountToString(this.tpService.dashboardData()?.expense),
        symbol: AdaaHelper.getCurrencySymbol(this.tpService.dashboardData()?.expense, true),
        icon: "adaa-icon-actual",
      });
    }

    return items;
  });

  public ngOnInit(): void {
    this.activeTab = +this.adaaHelper.getLocalStorage(this.constants.localStorageKeys.tpTab) || 1;
    this.tpService.dashboardFilters.filterBy = undefined;

    this.adaaHelper.deleteLocalStorage(this.constants.localStorageKeys.tpTab);

    this._initPage();

    this._subscription.add(
      this._systemLayoutService.hasActiveEntityChanged$.subscribe(() => {
        this._initPage();
      })
    );
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  public cycleChanged(cycle: number) {
    this.tpService.dashboardFilters.cycleId = cycle;
    this.tpService.dashboardData.set(undefined);
    this.tpService.resetFilters(true, false);
    this.tpService.getData();
  }

  public onlyEntityChanged(value: boolean) {
    this.tpService.dashboardFilters.onlyProjectsOwnedByEntity = value;
    this.tpService.getData();
  }

  public navigateToProject(id: number): void {
    this.adaaHelper.setLocalStorage(this.constants.localStorageKeys.tpTab, this.activeTab, "string");
    this.adaaHelper.setLocalStorage(
      this.constants.localStorageKeys.tpCycle,
      this.tpService.dashboardFilters.cycleId,
      "string"
    );
    this.router.navigateByUrl(`/national-projects-dashboard/projects/${id}`);
  }

  public filterDashboard(status: TpAgmStatus) {
    this.tpService.dashboardFilters.status = status;
    this.tpService.getDashboardData();
  }

  public navigateToCardsDashbaord(id: number | number[] | undefined): void {
    this.adaaHelper.setLocalStorage(this.constants.localStorageKeys.tpTab, this.activeTab, "string");
    this.adaaHelper.setLocalStorage(
      this.constants.localStorageKeys.tpCycle,
      this.tpService.dashboardFilters.cycleId,
      "string"
    );

    let route = "/national-projects-dashboard";

    switch (this.activeTab) {
      case 1:
        route += "/projects";
        break;
      case 2:
        route += `/owners?ownerIds=${id}`;
        break;
      case 3:
        route += `/main-sectors/${id}`;
        break;
      case 4:
      default:
        route += `/sectors/${id}`;
        break;
    }
    this.router.navigateByUrl(route);
  }

  public navigateToProgressDashboard(): void {
    this.adaaHelper.setLocalStorage(
      this.constants.localStorageKeys.tpCycle,
      this.tpService.dashboardFilters.cycleId,
      "string"
    );

    this.router.navigateByUrl("/national-projects-dashboard/progerss-comparison");
  }

  public navigateToAchievementDashboard(): void {
    this.adaaHelper.setLocalStorage(
      this.constants.localStorageKeys.tpCycle,
      this.tpService.dashboardFilters.cycleId,
      "string"
    );

    this.router.navigateByUrl("/national-projects-dashboard/achievement-comparison");
  }

  private _initPage(): void {
    this.tpService.resetFilters(true, false);
    this.tpService.initDashboard(true);
    this.tpService.getData();
  }
}
