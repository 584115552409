import { Component, inject, output, signal, TemplateRef, viewChild } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";

import { Language } from "../../../constants/enums";
import { AppService } from "../../../services";
import { FormActionButtonsComponent, FormInputComponent } from "../../form";
import { ConfirmationModalComponent } from "../../general";

@Component({
  selector: "adaa-metrics-value-input",
  standalone: true,
  imports: [FormActionButtonsComponent, FormInputComponent, TranslateModule],
  templateUrl: "./metrics-value-input.component.html",
  styleUrl: "./metrics-value-input.component.scss",
})
export class MetricsValueInputComponent {
  private _modalService = inject(NgbModal);
  private _appService = inject(AppService);

  modal = viewChild.required<TemplateRef<unknown>>("content");
  defaultValue = output<number | null>();
  value = signal<number | null>(null);

  public open() {
    const modalRef: NgbModalRef = this._modalService.open(this.modal(), {
      animation: true,
      scrollable: false,
      keyboard: false,
      size: "lg",
      centered: true,
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });

    modalRef.dismissed.subscribe(() => this.value.set(null));
    modalRef.closed.subscribe(() => this.value.set(null));
  }

  public updateValue() {
    const modal = this._modalService.open(ConfirmationModalComponent, {
      centered: true,
      size: "md",
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });

    modal.componentInstance.header = "data_entry.metric_add_edit_title";
    modal.componentInstance.title = "data_entry.metric_add_edit_information";

    modal.result.then((e) => {
      if (e) {
        this.defaultValue.emit(this.value());
      }
      this._modalService.dismissAll();
    });
  }
}
