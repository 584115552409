import { HttpEventType, HttpHandlerFn, HttpRequest, HttpResponse } from "@angular/common/http";
import { inject } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { tap } from "rxjs/operators";

import { IntegrityErrorComponent } from "../../shared/components";
import { Constants } from "../../shared/constants/constants";
import { Language } from "../../shared/constants/enums";
import { MainResponseModel, PropTypeModelType } from "../../shared/models";
import { IntegrityErrorType } from "../../shared/models/errors.model";
import { AppService, PropertiesService } from "../../shared/services";
import { environment } from "../environments/environment";
import { AdaaHelper } from "../utils";

const infoList: Array<number> = [
  Constants.CONSTANT_ITEMTYPE,
  Constants.CONSTANT_KPITYPE_PROP,
  Constants.CONSTANT_OBJECT_TYPE,
  Constants.CONSTANT_OBJECTIVETYPE,
  Constants.CONSTANT_EXEC_TEAM_MEMBER_TITLE_TYPE,
  Constants.CONSTANT_TICKET_TYPE,
  Constants.CONSTANT_TICKET_PRIORITY,
  Constants.CONSTANT_SERVICETYPES_TYPE,
];
const contantsMapInfo: { [key: number]: Array<PropTypeModelType> } = {};

export function notificationInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn) {
  const toastrService = inject(ToastrService);
  const translateService = inject(TranslateService);
  const appService = inject(AppService);
  const modalService = inject(NgbModal);
  const propsService = inject(PropertiesService);

  let ignoreNotifications = false;
  if (req.context.get(AdaaHelper.suppressNotificationToken)) {
    ignoreNotifications = true;
  }

  return next(req).pipe(
    tap({
      next: (event) => {
        if (ignoreNotifications) return;
        if (event.type !== HttpEventType.Response) return;

        const response = event as HttpResponse<MainResponseModel<unknown>>;

        /**
         * Don't show BE errors (Endpoints)
         * - Login Page API
         * - Change Password API
         */
        const excludeApiErrors = [
          environment.apiUrl + "authentication/login",
          environment.apiUrl + "users/changepassword",
        ];

        /**
         * Don't show BE errors (Error Codes)
         * - Error 5 No Data in DB Don't show warning
         * - Error 63 User not linked
         */
        const excludeErrorCodes = ["5", "63"];

        if (
          event == null ||
          excludeApiErrors.includes(event.url ?? "") ||
          excludeErrorCodes.includes(response.body?.responseCode ?? "") ||
          !response.body?.inError
        ) {
          return;
        }

        if (response?.body?.integrityErrors && response?.body?.integrityErrors?.length > 0) {
          let errorMessages: IntegrityErrorType[] = [];
          propsService.getPropByIdList(infoList).subscribe({
            next: (res) => {
              for (const prop of res.responseData) {
                if (!AdaaHelper.isDefined(contantsMapInfo[+prop.propType])) {
                  contantsMapInfo[+prop.propType] = [];
                }
                contantsMapInfo[+prop.propType].push(prop);
              }
              if (response.body) {
                errorMessages = groupErrorMessages(response.body.integrityErrors);
              }
            },
            complete: () => {
              const modal = modalService.open(IntegrityErrorComponent, {
                centered: true,
                size: "lg",
                modalDialogClass: appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
              });
              modal.componentInstance.errorMessages = errorMessages;
            },
          });
          return;
        }

        const responseCode = response.body.responseCode ?? "generic";
        const key = `notification.error.code_${responseCode}`;

        translateService.get(key).subscribe((res: string) => {
          if (res == key && response.body?.message) {
            toastrService.error(response.body.message);
          } else {
            toastrService.error(res);
          }
        });
      },
    })
  );
}

function groupErrorMessages(errorMessages: IntegrityErrorType[]) {
  for (let index = 0; index < errorMessages.length; index++) {
    const item = errorMessages[index];
    const itemDetails = getItemTypeName(item.itemTypeId);
    if (itemDetails !== null) {
      if (AdaaHelper.isDefined(itemDetails)) {
        item["objectType"] = AdaaHelper.getItemValueByToken(itemDetails, "name");
      }
    } else {
      item["objectType"] = AdaaHelper.getItemValueByToken(item, "itemTypeName");
    }
    errorMessages[index] = item;
  }
  return errorMessages;
}

function getItemTypeName(id: number) {
  const item = infoList
    .flatMap((prop) => contantsMapInfo[prop])
    .find((item) => AdaaHelper.isDefined(item) && +item.id === +id);
  return item || null;
}
