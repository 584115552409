import { Component, computed, inject, OnInit, signal } from "@angular/core";

import { AdaaHelper } from "../../../core/utils";
import {
  FloatActionComponent,
  NestedTableComponent,
  PeriodSliderComponent,
  RibbonComponent,
} from "../../../shared/components";
import { genericFloatButtons } from "../../../shared/components/general/float-action";
import { Constants } from "../../../shared/constants/constants";
import { Language } from "../../../shared/constants/enums";
import { GovernmentScore, SelectedPeriodModelType, StructureViewColumnConfig } from "../../../shared/models";
import {
  AppService,
  GovScoresPerfrormancesApiService,
  StructureViewService,
  SystemLayoutService,
} from "../../../shared/services";

@Component({
  selector: "adaa-structure-navigation",
  imports: [PeriodSliderComponent, RibbonComponent, NestedTableComponent, FloatActionComponent],
  templateUrl: "./structure-navigation.component.html",
  styleUrl: "./structure-navigation.component.scss",
})
export class StructureNavigationComponent implements OnInit {
  private readonly _appService = inject(AppService);
  private readonly _structureViewService = inject(StructureViewService);
  private readonly _govScoresPerfrormancesApiService = inject(GovScoresPerfrormancesApiService);
  readonly systemLayoutService = inject(SystemLayoutService);

  private readonly _genericFloatButtons = genericFloatButtons();
  Constants = Constants;
  selectedPeriod = signal<SelectedPeriodModelType | undefined>(undefined);
  governmentScore = signal<GovernmentScore | undefined>(undefined);
  scores: GovernmentScore[] = [];

  columns = computed<StructureViewColumnConfig[]>(() => {
    const columnsConfig: StructureViewColumnConfig[] = [
      {
        title: "common.tables.type",
        width: "17%",
        type: "text",
        valueKey: AdaaHelper.getFieldLanguage("itemType"),
        alignment: AdaaHelper.getCurrentLang() === Language.Arabic ? "R" : "L",
      },
      {
        title: "common.tables.name",
        width: "25%",
        type: "text",
        valueKey: AdaaHelper.getFieldLanguage("name"),
        alignment: AdaaHelper.getCurrentLang() === Language.Arabic ? "R" : "L",
      },
      {
        title: "common.tables.weight",
        width: "10%",
        type: "text",
        valueKey: "weight",
        alignment: "C",
      },
      {
        title: "forecasted_values.title_single",
        width: "20%",
        type: "html",
        valueKey: "metricTitle",
        titleKey: "metricDsc",
        alignment: AdaaHelper.getCurrentLang() === Language.Arabic ? "R" : "L",
      },
      {
        title: "common.tables.actions",
        width: "8%",
        type: "actions",
        valueKey: "itemTypeId",
        onlyIf: (data: { itemTypeId: number }) => data?.itemTypeId !== Constants.KPI_TYPE.GSKPI,
      },
    ];

    if (this.selectedPeriod()?.type === "quarter")
      columnsConfig.splice(
        3,
        0,
        {
          title: "common.tables.performance",
          width: "10%",
          type: "percentage",
          colorKey: "scoreColor",
          valueKey: "score",
        },
        {
          title: "ekpi.ytp",
          width: "10%",
          type: "percentage",
          colorKey: "annualScoreColor",
          valueKey: "annualScore",
        }
      );
    else
      columnsConfig.splice(3, 0, {
        title: "ekpi.ytp",
        width: "20%",
        type: "percentage",
        colorKey: "annualScoreColor",
        valueKey: "annualScore",
      });

    return columnsConfig;
  });
  dashboardWidgets = computed(() => {
    const widgets = [
      {
        label: "common.tables.ytp",
        data: AdaaHelper.percentageValue(this.governmentScore()?.annualScore),
        dataClasses: `fc-${this.governmentScore()?.annualScoreColor}`,
      },
    ];

    if (this.selectedPeriod()?.type === "quarter")
      widgets.splice(0, 0, {
        label: "common.tables.performance",
        data: AdaaHelper.percentageValue(this.governmentScore()?.score),
        dataClasses: `fc-${this.governmentScore()?.scoreColor}`,
      });

    return widgets;
  });

  public ngOnInit(): void {
    this._floatActions();
  }

  public selectPeriod(data: SelectedPeriodModelType): void {
    if (!data || !this._appService.hasPlanId()) return;

    const { period, type } = data;
    this.selectedPeriod.set({ period, type });

    this._getGovernmentScore(period.id);
    this._getScores(period.id);
  }

  private _getGovernmentScore(periodId: number): void {
    if (!this.selectedPeriod()) return;

    this._govScoresPerfrormancesApiService.getGovernmentScoreByPeriod(periodId).subscribe({
      next: (response) => {
        if (response.inError) return;

        this.governmentScore.set(response.responseData);
      },
    });
  }

  private _getScores(periodId: number): void {
    this._govScoresPerfrormancesApiService
      .getScoresByPeriodAndItemType(periodId, Constants.GOV_SCORES_SECTOR)
      .subscribe({
        next: (response) => {
          if (response.inError) return;

          this.scores = response.responseData;
        },
      });
  }

  private _floatActions(): void {
    this._genericFloatButtons([
      {
        key: "helpdesk",
        data: {
          url: "/helpdesk/create",
        },
      },
      {
        key: "print",
        data: undefined,
      },
    ]);
  }
}
