import { FormTab } from "../../../../shared/models";

export const infoTab = (disabled: boolean, hasError = false): FormTab => ({
  id: "info",
  title: "common.form.label.information",
  hasError,
  disabled,
});

export const measurementDetailsTab = (disabled: boolean, hasError = false): FormTab => ({
  id: "measurementDetails",
  title: "nkpi.metrics",
  hasError,
  disabled,
});

export const dataInputTab = (disabled: boolean, hasError = false): FormTab => ({
  id: "dataInput",
  title: "ekpi.data_input",
  hasError,
  disabled,
});

export const targetsTab = (disabled: boolean, hasError = false): FormTab => ({
  id: "targets",
  title: "nkpi.targets",
  hasError,
  disabled,
});

export const subkpiTab = (): FormTab => ({
  id: "subkpi",
  title: "kpi.subkpi",
  disabled: true,
  hasError: false,
});

export const benchmarkingTab = (hasError = false): FormTab => ({
  id: "benchmarking",
  title: "kpi.benchmarking",
  disabled: false,
  hasError,
});

export const attachmentsTab = (hasError = false): FormTab => ({
  id: "attachments",
  title: "kpi.attachments",
  disabled: false,
  hasError,
});

export const extendedFieldsTab = ({
  hasError = false,
  disabled = false,
}: {
  hasError?: boolean;
  disabled?: boolean;
}): FormTab => ({
  id: "extendedFields",
  title: "kpi.extended_fields",
  disabled,
  hasError,
});

export const dimensionTab = (hasError = false): FormTab => ({
  id: "dimensions",
  title: "nkpi.dimensions",
  disabled: false,
  hasError,
});

export const linksTab = (hasError = false): FormTab => ({
  id: "links",
  title: "common.links",
  disabled: false,
  hasError,
});

export const targetAgreementTab = (hasError = false): FormTab => ({
  id: "targetAgreement",
  title: "national_target_management",
  disabled: false,
  hasError,
});

export const improvementPlanTab = (hasError = false): FormTab => ({
  id: "improvementPlan",
  title: "ntkpi.improvement_plan",
  disabled: false,
  hasError,
});
