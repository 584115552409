import { FormControl, FormGroup, Validators } from "@angular/forms";

import { AdaaHelper } from "../../../../../core/utils";
import { dateRangeValidator } from "../../../../../core/validators";
import { Constants } from "../../../../../shared/constants/constants";
import { ParameterCatalog } from "../../../../../shared/models";
import { getRequiredValidator } from "./lib";

export const EkpiInfoTabForm = (fields: ParameterCatalog[]) =>
  new FormGroup(
    {
      objectiveId: new FormControl<number | null>(null, [...getRequiredValidator(fields, "objectiveId")]),
      objectiveNameAE: new FormControl({ value: null, disabled: true }, [
        ...getRequiredValidator(fields, "objectiveNameAE"),
      ]),
      objectiveNameEN: new FormControl({ value: null, disabled: true }, [
        ...getRequiredValidator(fields, "objectiveNameEN"),
      ]),
      nameAE: new FormControl<string | null>(null, [
        ...getRequiredValidator(fields, "nameAE"),
        Validators.minLength(3),
        Validators.maxLength(Constants.FORM_FIELD_CONSTRAINTS.CHAR_MAXLENGTH),
      ]),
      nameEN: new FormControl<string | null>(null, [
        ...getRequiredValidator(fields, "nameEN"),
        Validators.minLength(3),
        Validators.maxLength(Constants.FORM_FIELD_CONSTRAINTS.CHAR_MAXLENGTH),
      ]),
      dscAE: new FormControl<string | null>(null, [
        ...getRequiredValidator(fields, "dscAE"),
        Validators.minLength(3),
        Validators.maxLength(Constants.FORM_FIELD_CONSTRAINTS.DESC_MAXLENGTH),
      ]),
      dscEN: new FormControl<string | null>(null, [
        ...getRequiredValidator(fields, "dscEN"),
        Validators.minLength(3),
        Validators.maxLength(Constants.FORM_FIELD_CONSTRAINTS.DESC_MAXLENGTH),
      ]),
      startDate: new FormControl<number | null>(AdaaHelper.getDubaiTime(AdaaHelper.plan.startYear), [
        ...getRequiredValidator(fields, "startDate"),
      ]),
      endDate: new FormControl<number | null>(AdaaHelper.getDubaiTime(AdaaHelper.plan.endYear), [
        ...getRequiredValidator(fields, "endDate"),
      ]),
      ekpiPillarId: new FormControl(null, [...getRequiredValidator(fields, "ekpiPillarId")]),
      enablerEntityId: new FormControl<number | null>(null, [...getRequiredValidator(fields, "enablerEntityId")]),
      orgUnitId: new FormControl<number | null>(null, [...getRequiredValidator(fields, "orgUnitId")]),
      owner: new FormControl<number | null>(null, [...getRequiredValidator(fields, "owner")]),
      dataSource: new FormControl<string | null>(null, [...getRequiredValidator(fields, "dataSource")]),
      scopeMeasureAE: new FormControl<string | null>(null, [...getRequiredValidator(fields, "scopeMeasureAE")]),
      legacyKpi: new FormControl<number | null>(null, [...getRequiredValidator(fields, "legacyKpi")]),
      commentsAE: new FormControl<string>("", [...getRequiredValidator(fields, "commentsAE")]),
      affectedEntities: new FormControl<string | null>(null, [...getRequiredValidator(fields, "affectedEntities")]),
      auxAffectedEntities: new FormControl<string | null>(null, [
        ...getRequiredValidator(fields, "auxAffectedEntities"),
      ]),
      pmoOrgUnitId: new FormControl<number | null>(null, [...getRequiredValidator(fields, "pmoOrgUnitId")]),
      pmoOwner: new FormControl<number | null>(null, [...getRequiredValidator(fields, "pmoOwner")]),

      //ONLY FE
      affectedEntitiesIds: new FormControl<Array<number> | null>(null, [
        ...getRequiredValidator(fields, "affectedEntities"),
      ]),
    },
    {
      validators: Validators.compose([dateRangeValidator("startDate", "endDate")]),
    }
  );
