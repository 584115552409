import { Route } from "@angular/router";

import { checkIfLoggedInFn, pmoEntity } from "../../core/guards";
import { Constants } from "../../shared/constants/constants";
import { PageMode } from "../../shared/constants/enums";
import {
  createStrategicCyclesPageGuards,
  manageStrategicCyclesPageGuards,
  viewStrategicCyclesPageGuards,
} from "./_guards";
import { StrategicCyclesEditorComponent } from "./strategic-cycles-editor/strategic-cycles-editor.component";
import { StrategicCyclesListComponent } from "./strategic-cycles-list/strategic-cycles-list.component";

export const STRATEGIC_CYCLE_ROUTES: Route[] = [
  {
    path: "cycles",
    canActivate: [checkIfLoggedInFn, pmoEntity, ...viewStrategicCyclesPageGuards],
    children: [
      {
        path: "list",
        redirectTo: "",
      },
      {
        path: "",
        component: StrategicCyclesListComponent,
        data: {
          breadcrumb: "breadcrumbs.cycle_module.list",
        },
      },
      {
        path: "create",
        component: StrategicCyclesEditorComponent,
        canActivate: [...createStrategicCyclesPageGuards],
        data: {
          breadcrumb: "breadcrumbs.cycle_module.new",
          pageMode: PageMode.create,
        },
      },
      {
        path: "edit/:id",
        component: StrategicCyclesEditorComponent,
        canActivate: [...manageStrategicCyclesPageGuards],
        data: {
          breadcrumb: "breadcrumbs.cycle_module.edit",
          pageMode: PageMode.edit,
        },
      },
      {
        path: ":id",
        component: StrategicCyclesEditorComponent,
        data: {
          breadcrumb: "breadcrumbs.cycle_module.view",
          pageMode: PageMode.view,
        },
      },
    ],
    data: {
      breadcrumb: "breadcrumbs.cycle_module.list",
      tableConfList: Constants.MAIN_TABLE_LIST_CONF_KEY.CYCLES,
      title: "breadcrumbs.cycle_module.list",
    },
  },
];
