import { HttpEvent, HttpHandlerFn, HttpRequest } from "@angular/common/http";
import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { map } from "rxjs";

import { Constants } from "../../shared/constants/constants";
import { AdaaHelper } from "../utils";
import { MainInterceptorService } from "./main-interceptor.service";

export function mainInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn) {
  const router = inject(Router);
  const mainInterceptorService = inject(MainInterceptorService);

  const allowedPagesWithoutSession = [
    "login",
    "android",
    "verification",
    "new-password",
    "forget-password",
    "link-account",
  ];

  const session_id = localStorage.getItem(Constants.localStorageKeys.sessionId);

  if (!session_id) {
    const path = window.location.pathname.split("/")[1];
    const isPathAllowed = allowedPagesWithoutSession.includes(path);

    if (!isPathAllowed) {
      router.navigate(["/login"]);
    }
  }

  //Setting hardwareUuid
  const hardwareUuid = AdaaHelper.getLocalStorage(Constants.localStorageKeys.hardwareUuid);
  if (!AdaaHelper.isDefinedAndNotEmpty(hardwareUuid))
    AdaaHelper.setLocalStorage(Constants.localStorageKeys.hardwareUuid, generateHardwareUUID(), "string");

  return next(req).pipe(map((event: HttpEvent<unknown>) => mainInterceptorService.interceptorMapFn(event)));
}

function generateHardwareUUID(): string {
  const lut = [];
  for (let i = 0; i < 256; i++) {
    lut[i] = (i < 16 ? "0" : "") + i.toString(16);
  }
  const d0 = (Math.random() * 0xffffffff) | 0;
  const d1 = (Math.random() * 0xffffffff) | 0;
  const d2 = (Math.random() * 0xffffffff) | 0;
  const d3 = (Math.random() * 0xffffffff) | 0;
  return (
    lut[d0 & 0xff] +
    lut[(d0 >> 8) & 0xff] +
    lut[(d0 >> 16) & 0xff] +
    lut[(d0 >> 24) & 0xff] +
    "-" +
    lut[d1 & 0xff] +
    lut[(d1 >> 8) & 0xff] +
    "-" +
    lut[((d1 >> 16) & 0x0f) | 0x40] +
    lut[(d1 >> 24) & 0xff] +
    "-" +
    lut[(d2 & 0x3f) | 0x80] +
    lut[(d2 >> 8) & 0xff] +
    "-" +
    lut[(d2 >> 16) & 0xff] +
    lut[(d2 >> 24) & 0xff] +
    lut[d3 & 0xff] +
    lut[(d3 >> 8) & 0xff] +
    lut[(d3 >> 16) & 0xff] +
    lut[(d3 >> 24) & 0xff]
  );
}
