import { DatePipe } from "@angular/common";
import { Component, computed, inject, input, signal } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";

import { TranslateTokenPipe } from "../../../../core/pipes";
import { AdaaHelper } from "../../../../core/utils";
import {
  ConfirmationModalComponent,
  DataTableComponent,
  FormActionButtonsComponent,
  FormDropdownComponent,
  FormInputComponent,
} from "../../../../shared/components";
import { Constants } from "../../../../shared/constants/constants";
import { Language, PageMode } from "../../../../shared/constants/enums";
import {
  AuxGovDirection,
  CommonKpiModelType,
  ItemModel,
  SubGovDirection,
  TableButtonClicked,
  TpCycle,
  ValueText,
} from "../../../../shared/models";
import {
  AppService,
  GovDirectionApiService,
  KpisApiService,
  SubGovDirectionApiService,
  SystemLayoutService,
  TpApiService,
} from "../../../../shared/services";
import { TransformationalTargetsApiService } from "../../../../shared/services/transformational-targets-api.service";
import { TransformationalTargetsEditorService } from "../../../../shared/services/transformational-targets-editor.service";

@Component({
  selector: "adaa-transformational-target-editor",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    FormActionButtonsComponent,
    FormInputComponent,
    FormDropdownComponent,
    DataTableComponent,
    DatePipe,
    TranslateTokenPipe,
  ],
  templateUrl: "./transformational-target-editor.component.html",
  styleUrl: "./transformational-target-editor.component.scss",
})
export class TransformationalTargetEditorComponent {
  tpTargetsEditorService = inject(TransformationalTargetsEditorService);
  private _formBuilder = inject(FormBuilder);
  private _toastrService = inject(ToastrService);
  private _translateService = inject(TranslateService);
  private _tTargetsApiService = inject(TransformationalTargetsApiService);
  private _tpApiService = inject(TpApiService);
  private _kpiApiService = inject(KpisApiService);
  private _router = inject(Router);
  private _modalService = inject(NgbModal);
  private _appService = inject(AppService);
  private _activatedRoute = inject(ActivatedRoute);
  private readonly _systemLayoutService = inject(SystemLayoutService);
  private readonly _subGovDirectionApiService = inject(SubGovDirectionApiService);
  private readonly _govDirectionApiService = inject(GovDirectionApiService);

  readonly #untilDestroy = AdaaHelper.untilDestroyed();
  adaaHelper = AdaaHelper;
  _subscription = new Subscription();

  pageMode = input.required<string>();
  tTargetForm: FormGroup = new FormGroup({});
  PageMode = PageMode;
  Constants = Constants;

  tTargetId: string | null;

  pillars = signal<SubGovDirection[]>([]);
  pillarsList: SubGovDirection[] = [];
  subPillars = signal<AuxGovDirection[]>([]);
  subpillarsList: AuxGovDirection[] = [];
  pillarOptions = computed<ValueText[]>(() => {
    const field = AdaaHelper.getFieldLanguage("name");
    const result: ValueText[] = AdaaHelper.setDropdownArray(this.pillars(), "id", field);
    return result;
  });

  subPillarOptions = computed<ValueText[]>(() => {
    const field = AdaaHelper.getFieldLanguage("name");
    const result: ValueText[] = AdaaHelper.setDropdownArray(this.subPillars(), "id", field);
    return result;
  });

  displayLabel = signal<string>("");
  setDisabled = signal<boolean>(false);
  submitted: boolean = false;
  transformationalTarget = signal<TpCycle>({
    nameAE: "",
    nameEN: "",
    startDate: 0,
    endDate: 0,
    createTS: 0,
    createUser: "",
    id: 0,
    status: 0,
  });
  transformationalTargets = signal<Partial<ItemModel>[]>([]);
  transformationalTargetKpis = signal<Partial<CommonKpiModelType>[]>([]);

  public ngOnInit(): void {
    this._systemLayoutService.hasActiveEntityChanged$.pipe(this.#untilDestroy()).subscribe({
      next: () => {
        this._router.navigateByUrl(`/console`);
      },
    });
    this.setDisabled.set(this.pageMode() === PageMode.view);
    this.tTargetId = this._activatedRoute.snapshot?.paramMap?.get("id");

    this._tpApiService.getAllByTransformationalTarget(+this.tTargetId!).subscribe({
      next: (response) => {
        this.transformationalTargets.set(response.responseData);
      },
    });
    this._kpiApiService.getTransformationalTargetKpis(+this.tTargetId!).subscribe({
      next: (response) => {
        this.transformationalTargetKpis.set(response.responseData);
      },
    });
    this.tpTargetsEditorService.formValidator();
    this._prepareForm();
  }

  private _getPillars(): void {
    this._subGovDirectionApiService.getAllbyGovDirection(Constants.CONSTANT_WE_THE_UAE_2031_SUB_GOV_ID).subscribe({
      next: (response) => {
        this.pillarsList = response.responseData;
        this.pillars.set(AdaaHelper.clone(this.pillarsList));
      },
      complete: () => {},
    });
  }

  private _getSubPillars(): void {
    this._govDirectionApiService.getAuxiliaryDirection(Constants.CONSTANT_WE_THE_UAE_2031_SUB_GOV_ID).subscribe({
      next: (response) => {
        this.subpillarsList = response.responseData;
        this.subPillars.set(AdaaHelper.clone(this.subpillarsList));
      },
      complete: () => {
        if (this.tTargetId) this._getTTarget(+this.tTargetId);
      },
    });
  }

  private _getTTarget(tTargetId: number) {
    if (tTargetId)
      this._tTargetsApiService.getTTargetById(tTargetId).subscribe({
        next: (response) => {
          this.transformationalTarget.set(response.responseData);
          this.tTargetForm.patchValue({ ...response.responseData });
          this.tTargetForm.markAsPristine();
          this.tTargetForm.markAsUntouched();
        },
      });
  }

  public onPillarChange(value?: ValueText["value"] | undefined) {
    if (!value) {
      this.subPillars.set(this.subpillarsList);
      this.tTargetForm.controls["auxDirectionId"]?.setValue("");
      return;
    }

    const currentAuxDirection = this.subPillars().find(
      (item) => item.id === this.tTargetForm.get("auxDirectionId")?.value
    );
    if (value !== currentAuxDirection?.govSubDirectionId) this.tTargetForm.controls["auxDirectionId"]?.setValue("");

    const subPillarCopy: AuxGovDirection[] = AdaaHelper.clone(this.subpillarsList);
    this.subPillars.set(subPillarCopy.filter((item) => item.govSubDirectionId === value));
  }
  public onSubPillarChange(value?: ValueText["value"] | undefined) {
    if (!value) {
      return;
    }
    const subPillar: AuxGovDirection | undefined = this.subPillars().find((item) => item.id === value);
    if (!subPillar) return;
    this.tTargetForm.controls["subGovernmentDirectionId"]?.setValue(subPillar.govSubDirectionId);
  }
  private _prepareForm() {
    const isViewMode = this.pageMode() === PageMode.view;

    this.tTargetForm = this._formBuilder.group({
      auxDirectionId: this._formBuilder.control<string | null>(
        { value: null, disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      subGovernmentDirectionId: this._formBuilder.control<string | null>(
        { value: null, disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),

      nameAE: this._formBuilder.control<string | undefined>(
        { value: "", disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      nameEN: this._formBuilder.control<string | undefined>(
        { value: "", disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      dscAE: this._formBuilder.control<string | undefined>(
        { value: "", disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      dscEN: this._formBuilder.control<string | undefined>(
        { value: "", disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
    });

    this._subscription.add(
      this.tTargetForm.valueChanges.subscribe((value) => {
        const displayLanguage = AdaaHelper.getFieldLanguage("name");
        if (!value[displayLanguage].length) {
          this.displayLabel.set(value["nameAE"] || value["nameEN"]);
        } else this.displayLabel.set(value[displayLanguage]);
      })
    );
    this._getPillars();
    this._getSubPillars();
  }

  public updateTarget() {
    this.submitted = true;
    if (this.tTargetForm.invalid) {
      this._toastrService.warning(this._translateService.instant("notification.warning.missing_info"));
      return;
    }
    const tTarget = this._formatTTarget();

    const serviceUrl =
      this.pageMode() === PageMode.edit
        ? this._tTargetsApiService.updateTTarget(tTarget)
        : this._tTargetsApiService.createTTarget(tTarget);

    serviceUrl.subscribe({
      next: (response) => {
        if (response.inError) return;
        this._toastrService.success(this._translateService.instant("notification.success.save"));
        this._router.navigateByUrl("/console/national-project/transformational-targets");
      },
    });
  }

  private _formatTTarget() {
    const tTarget = this.tTargetForm.value;
    if (this.pageMode() === PageMode.edit) {
      tTarget.updateTS = Date.now();
      tTarget.id = this.tTargetId;
    }
    if (tTarget.subGovernmentDirectionId === "none") {
      tTarget.subGovernmentDirectionId == undefined;
    }
    if (tTarget.auxDirectionId === "none") {
      tTarget.auxDirectionId == undefined;
    }
    return tTarget;
  }

  public cancelForm() {
    if (this.tTargetForm.dirty) {
      const modal = this._modalService.open(ConfirmationModalComponent, {
        centered: true,
        size: "md",
        modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
      });

      modal.componentInstance.header = "common.form.modals_leave.cancel_yes_no_title";
      modal.componentInstance.title = "common.form.modals_leave.cancel_yes_no_information";

      modal.result.then((e) => {
        if (e) this._router.navigateByUrl(`/console/national-project/transformational-targets`);
      });
    } else this._router.navigateByUrl(`/console/national-project/transformational-targets`);
  }

  public navNationalProjects(value: TableButtonClicked) {
    if (value) {
      this._router.navigateByUrl(`/national-projects-dashboard/projects/${value.data.id}`);
    }
  }

  public navMOKPI(value: TableButtonClicked) {
    if (value) {
      this._router.navigateByUrl(`/console/kpi/mokpi/${value.data.id}`);
    }
  }
}
