import { Component, computed, inject, input, OnDestroy, OnInit, signal } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";

import { AdaaHelper } from "../../../core/utils/adaa-helper";
import { dateRangeValidator } from "../../../core/validators/date-range.validator";
import {
  ConfirmationModalComponent,
  FormActionButtonsComponent,
  FormInputComponent,
  FormInputDateComponent,
  FormTwoWaySelectComponent,
} from "../../../shared/components";
import { Constants } from "../../../shared/constants/constants";
import { Language, PageMode } from "../../../shared/constants/enums";
import { CycleModelType, EntityMapModelType, EntityModelType, ValueText } from "../../../shared/models";
import { AppService, CyclesApiService, EntitiesApiService } from "../../../shared/services";
import { CyclesEditorService } from "../../../shared/services/cycles-editor.service";

@Component({
  selector: "adaa-strategic-cycles-editor",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    FormInputComponent,
    FormTwoWaySelectComponent,
    FormActionButtonsComponent,
    FormInputDateComponent,
  ],
  templateUrl: "./strategic-cycles-editor.component.html",
  styleUrl: "./strategic-cycles-editor.component.scss",
})
export class StrategicCyclesEditorComponent implements OnInit, OnDestroy {
  private _formBuilder = inject(FormBuilder);
  private _entitiesApiService = inject(EntitiesApiService);
  private _cyclesApiService = inject(CyclesApiService);
  private _activatedRoute = inject(ActivatedRoute);
  private _translateService = inject(TranslateService);
  private _toastrService = inject(ToastrService);
  private _modalService = inject(NgbModal);
  private _appService = inject(AppService);
  private _router = inject(Router);
  cyclesEditorService = inject(CyclesEditorService);

  strategicCycleForm: FormGroup = new FormGroup({});
  entities = signal<EntityModelType[]>([]);
  entityOptions = computed(() => {
    return this.entities().map((entity) => {
      return {
        text: AdaaHelper.getItemValueByToken(entity, "name"),
        value: entity.id,
      };
    });
  });
  pageMode = input.required<string>();
  submitted = signal<boolean>(false);
  cycleButtonLabel = signal<string>("");
  plan = signal<CycleModelType>({
    id: 0,
    nameAE: "",
    nameEN: "",
    dscAE: "",
    dscEN: "",
    startYear: 0,
    endYear: 0,
    dataRevisionId: 0,
    entityMaps: [],
    status: 0,
    wfStatus: "",
    updateTS: 0,
  });
  displayLabel = signal<string>("");
  PageMode = PageMode;
  planId: string | null;
  Constants = Constants;
  private _subscription = new Subscription();

  public ngOnInit(): void {
    this.planId = this._activatedRoute.snapshot?.paramMap?.get("id");
    this.cyclesEditorService.formValidator();
    this._prepareForm();
    this._getEntities();
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  private _prepareForm() {
    const isViewMode = this.pageMode() === PageMode.view;

    this.strategicCycleForm = this._formBuilder.group(
      {
        nameAE: this._formBuilder.control<string | undefined>(
          { value: "", disabled: isViewMode },
          {
            validators: [Validators.required],
          }
        ),
        nameEN: this._formBuilder.control<string | undefined>(
          { value: "", disabled: isViewMode },
          {
            validators: [Validators.required],
          }
        ),
        dscAE: this._formBuilder.control<string | undefined>(
          { value: "", disabled: isViewMode },
          {
            validators: [Validators.required],
          }
        ),
        dscEN: this._formBuilder.control<string | undefined>(
          { value: "", disabled: isViewMode },
          {
            validators: [Validators.required],
          }
        ),
        startYear: this._formBuilder.control<string | undefined>(
          { value: "", disabled: isViewMode },
          {
            validators: [Validators.required],
          }
        ),
        endYear: this._formBuilder.control<string | undefined>(
          { value: "", disabled: isViewMode },
          {
            validators: [Validators.required],
          }
        ),
        entityMaps: this._formBuilder.control<ValueText[] | undefined>(
          { value: undefined, disabled: isViewMode },
          {
            validators: [Validators.required],
          }
        ),
      },
      {
        validators: Validators.compose([dateRangeValidator("startYear", "endYear")]),
      }
    );

    this._subscription.add(
      this.strategicCycleForm.valueChanges.subscribe((value) => {
        const displayLanguage = AdaaHelper.getFieldLanguage("name");
        if (!value[displayLanguage].length) {
          this.displayLabel.set(value["nameAE"] || value["nameEN"]);
        } else this.displayLabel.set(value[displayLanguage]);
      })
    );
  }

  private _getEntities() {
    this._entitiesApiService.getEntitiesForCycles().subscribe({
      next: (response) => this.entities.set(response.responseData),
      complete: () => this._getPlan(),
    });
  }

  private _getPlan() {
    if (this.planId) {
      this._cyclesApiService.getById(+this.planId).subscribe({
        next: (response) => {
          this.plan.set(response.responseData);
          this.strategicCycleForm.patchValue(response.responseData);
          this.cycleButtonLabel.set(this.getLabel(response.responseData.status));
          let result = [];
          for (const value of response.responseData.entityMaps) {
            const entity = this.entities().find((entity) => entity.id === value.entityId);
            if (entity) result.push(entity);
          }
          result = result.map((entity) => entity.id);
          if (this._checkDisabled(false)) {
            this.strategicCycleForm.get("nameAE")?.disable();
            this.strategicCycleForm.get("nameEN")?.disable();
            this.strategicCycleForm.get("startYear")?.disable();
            this.strategicCycleForm.get("endYear")?.disable();
          }
          if (this._checkDisabled(true)) {
            this.strategicCycleForm.get("entityMaps")?.disable();
            this.strategicCycleForm.get("dscAE")?.disable();
            this.strategicCycleForm.get("dscEN")?.disable();
          }
          this.strategicCycleForm.controls["entityMaps"].patchValue(result);
          this.strategicCycleForm.markAsPristine();
          this.strategicCycleForm.markAsUntouched();
        },
      });
    }
  }

  public submit(updateStatus: boolean = false) {
    this.submitted.set(true);
    if (this.strategicCycleForm.invalid) {
      this._toastrService.error(this._translateService.instant("common.form.label.missing_information"));
      return;
    }
    const formValue = this.strategicCycleForm.value;

    if (this.pageMode() === PageMode.edit) {
      formValue.entityMaps = formValue.entityMaps.map((item: ValueText | number) =>
        typeof item === "number" ? item : item.value
      );

      const entityMaps: Partial<EntityMapModelType>[] = formValue.entityMaps.map((entityId: number) => {
        const entity = this.plan().entityMaps.find((val) => val.entityId === entityId);
        if (entity) {
          return {
            entityId: entity?.entityId,
            planId: entity?.planId,
            startYear: entity?.startYear,
            endYear: entity?.endYear,
            wasSent: entity?.wasSent,
            id: entity?.id,
            status: entity?.status,
            updateTS: entity?.updateTS,
            updateUser: entity?.updateUser,
          };
        } else {
          return {
            entityId,
            startYear: formValue.startYear,
            endYear: formValue.endYear,
            status: Constants.OBJECT_STATUS.DRAFT,
          };
        }
      });
      for (const entity of this.plan().entityMaps) {
        if (!entityMaps.some((value) => value.id === entity.id)) {
          entityMaps.push({
            entityId: entity?.entityId,
            planId: entity?.planId,
            startYear: entity?.startYear,
            endYear: entity?.endYear,
            wasSent: entity?.wasSent,
            id: entity?.id,
            status: Constants.OBJECT_STATUS.REMOVE,
            updateTS: entity?.updateTS,
            updateUser: entity?.updateUser,
          });
        }
      }

      let newStatus: number = 0;
      if (this.plan().status === Constants.OBJECT_STATUS.ACTIVE) {
        newStatus = Constants.OBJECT_STATUS.CLOSED;
      }
      if (
        this.plan().status === Constants.OBJECT_STATUS.CLOSED ||
        this.plan().status === Constants.OBJECT_STATUS.PLANNING
      ) {
        newStatus = Constants.OBJECT_STATUS.ACTIVE;
      }

      const result = {
        ...this.plan(),
        ...formValue,
        entityMaps: entityMaps,
        status: updateStatus ? newStatus : this.plan().status,
      };
      this._cyclesApiService.updatePlan(result).subscribe({
        next: () => {
          this._toastrService.success(this._translateService.instant("notification.success.save"));
          this._router.navigateByUrl("console/cycles");
        },
      });
    } else {
      formValue.entityMaps = formValue.entityMaps.map((entityId: number) => {
        return {
          entityId,
          startYear: formValue.startYear,
          endYear: formValue.endYear,
          status: Constants.OBJECT_STATUS.DRAFT,
        };
      });
      this._cyclesApiService.createPlan(formValue).subscribe({
        next: () => {
          this._toastrService.success(this._translateService.instant("notification.success.save"));
          this._router.navigateByUrl("console/cycles");
        },
      });
    }
  }

  public cancel() {
    if (this.strategicCycleForm.dirty) {
      const modal = this._modalService.open(ConfirmationModalComponent, {
        centered: true,
        size: "md",
        modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
      });

      modal.componentInstance.header = "common.form.modals_leave.cancel_yes_no_title";
      modal.componentInstance.title = "common.form.modals_leave.cancel_yes_no_information";

      modal.result.then((e) => {
        if (e) this._router.navigateByUrl(`/console/cycles`);
      });
    } else this._router.navigateByUrl("/console/cycles");
  }

  public checkInvalid(control: string) {
    return (
      this.strategicCycleForm.controls[control].errors?.["required"] &&
      (this.strategicCycleForm.get(control)?.dirty || this.submitted())
    );
  }

  private _checkDisabled(isDescription: boolean) {
    let res: boolean;
    if (this.pageMode() === PageMode.view) return true;
    if (this.pageMode() === PageMode.edit) {
      if (isDescription) {
        res = this.plan().status === Constants.OBJECT_STATUS.CLOSED;
      } else res = this.plan().status !== Constants.OBJECT_STATUS.PLANNING;
      return res;
    }
    return null;
  }

  public getLabel(status: number) {
    switch (status) {
      case Constants.OBJECT_STATUS.ACTIVE:
        return "cycle.close_cycle";
        break;
      case Constants.OBJECT_STATUS.CLOSED:
        return "cycle.reopen_cycle";
        break;
      case Constants.OBJECT_STATUS.PLANNING:
        return "cycle.activate_cycle";
        break;

      default:
        return "";
        break;
    }
  }
}
