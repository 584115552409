<div class="card col-12 rounded-1 bg-white px-3 mb-2 pt-2 mt-3">
    <div class="card-title px-0 py-2 mb-0 d-flex justify-content-between align-items-center align-content-center">
        <h5 class="m-0 mb-1 fw-bold">
            {{ "kpi.table_data_title" | translate }}
        </h5>

        <div class="table-options d-flex gap-1">
            @if (showLegacyCheckbox()) {
                <adaa-form-checkbox
                    controlName="includeLegacyKPI"
                    checkboxMode="trueFalse"
                    [setDefaultValue]="includeLegacyKpi()"
                    [boldLabel]="includeLegacyKpi()"
                    [label]="'kpi.include_Legacy_Kpi' | translate"
                    (inputChanges)="setIncludeLegacyKpi.emit($event)"
                />
            }
            <button type="button" class="btn btn-secondary text-white card-action-btn-icon" (click)="printClicked.emit()">
                <i class="fa-solid fa-print"></i>
            </button>

            @if (isMasterEkpi()) {
                <button type="button" class="btn btn-secondary text-white btn-sm rounded-1 mx-1 px-2" (click)="openEkpiTableValuesModal()">
                    {{ "common.tables.view_all_data" | translate }}
                </button>
            }
        </div>
    </div>

    <div class="card-body px-0 py-2">
        <ng-container #vc />
    </div>
</div>

<ng-template #webTable>
    <div class="d-block position-relative w-100 overflow-x-auto py-1 kpi-details--table">
        <div id="normalTableValues__Header" class="d-flex align-content-center kpi-details--row">
            <div
                class="flex-shrink-0 position-sticky bg-white kpi-details--col kpi-details--main-col flex-grow-0"
                [class.is-formula]="!isNoFormula()"
                [class.start-0]="languageService.current() === 'en'"
                [class.end-0]="languageService.current() === 'ae'"
            ></div>

            @for (period of periods(); track period.periodId) {
                <div class="d-flex bg-white kpi-details--col kpi-details--data-col" [dir]="languageService.direction()">
                    <span class="label fw-bold text-primary" [dir]="languageService.direction()">
                        {{ period.label }}

                        @if (isNtkpi()) {
                            <button
                                class="btn btn-xs border-0 text-info"
                                (click)="openEditNtkpiCardModal(period.periodId); showNtkpiSuccessStory.emit()"
                            >
                                <i class="fa fa-link"></i>
                            </button>
                        }
                    </span>
                </div>
            }
        </div>

        @let showBoundedTargets = isBounded();

        <!-- Targets:Unbounded --ROW-- -->
        @if (!showBoundedTargets) {
            <div class="d-flex align-content-center kpi-details--row">
                <div
                    class="d-flex position-sticky text-center kpi-details--col kpi-details--main-col flex-grow-0"
                    [class.is-formula]="!isNoFormula()"
                    [class.start-0]="languageService.current() === 'en'"
                    [class.end-0]="languageService.current() === 'ae'"
                >
                    <span class="label" [dir]="languageService.direction()">
                        {{ "nkpi.target" | translate }}
                    </span>
                </div>

                @for (period of periods(); track period.periodId) {
                    @let target = getTarget(period.periodId);
                    @let targetOverride = target?.targetOverride;
                    @let isBaselineYear = target?.isBaselineYear;

                    <div
                        class="d-flex kpi-details--col kpi-details--data-col"
                        [class.target-override]="targetOverride"
                        [ngbTooltip]="targetOverride ? ('kpi.target_override' | translate) : false"
                    >
                        <span class="label fw-bold" [dir]="isBaselineYear ? languageService.direction() : 'ltr'">
                            {{ isBaselineYear ? ("kpi.baselineYear" | translate) : target?.value }}
                        </span>
                    </div>
                }
            </div>
        }
        <!-- END-ROW -->

        <!-- Actuals --ROW-- -->
        <div class="d-flex align-content-center kpi-details--row">
            <div
                class="d-flex position-sticky text-center kpi-details--col kpi-details--main-col flex-grow-0"
                [class.is-formula]="!isNoFormula()"
                [class.start-0]="languageService.current() === 'en'"
                [class.end-0]="languageService.current() === 'ae'"
            >
                <span class="label" [dir]="languageService.direction()">
                    {{ "nkpi.actual" | translate }}
                </span>
            </div>

            @for (period of periods(); track period.periodId) {
                @let actual = getActual(period.periodId);
                @let actualOverride = actual?.actualOverride;
                @let actualValue = actual?.ignored ? "N/A" : actual?.value;

                <div
                    class="d-flex kpi-details--col kpi-details--data-col"
                    [dir]="languageService.direction()"
                    [class.target-override]="actualOverride"
                    [ngbTooltip]="actualOverride ? ('kpi.actual_override' | translate) : false"
                >
                    <span class="label fw-bold" dir="ltr">
                        {{ actualValue }}
                    </span>
                </div>
            }
        </div>
        <!-- END-ROW -->

        <!-- Targets:Bounded --ROW-- -->
        @if (showBoundedTargets) {
            @let limits = ["low", "high"];
            @for (limit of limits; track limit) {
                <div class="d-flex align-content-center kpi-details--row">
                    <div
                        class="d-flex position-sticky text-center kpi-details--col kpi-details--main-col flex-grow-0"
                        [class.is-formula]="!isNoFormula()"
                        [class.start-0]="languageService.current() === 'en'"
                        [class.end-0]="languageService.current() === 'ae'"
                    >
                        <span class="label" [dir]="languageService.direction()">
                            {{ (limit === "low" ? "kpi.lower_limit" : "kpi.upper_limit") | translate }}
                        </span>
                    </div>

                    @for (period of periods(); track period.periodId) {
                        @let target = getBounded(period.periodId);
                        @let targetOverride = target?.targetOverride;
                        @let isBaselineYear = target?.isBaselineYear;

                        <div
                            class="d-flex kpi-details--col kpi-details--data-col"
                            [class.target-override]="targetOverride"
                            [ngbTooltip]="targetOverride ? ('kpi.target_override' | translate) : false"
                        >
                            <span class="label fw-bold">
                                @if (isBaselineYear) {
                                    <span [dir]="languageService.direction()">{{ "kpi.baselineYear" | translate }}</span>
                                } @else if (target && (limit === "low" || limit === "high")) {
                                    <span dir="ltr">{{ target[limit] }}</span>
                                }
                            </span>
                        </div>
                    }
                </div>
            }
        }
        <!-- END-ROW -->

        <!-- Targets:Audited --ROW-- -->
        @let limits = ["annualActual", "auditedActual"];
        @for (limit of limits; track limit) {
            <div [class.d-none]="!kpiHasAuditIssues() && limit === 'auditedActual'" class="d-flex align-content-center kpi-details--row">
                <div
                    class="d-flex position-sticky text-center kpi-details--col kpi-details--main-col flex-grow-0"
                    [class.is-formula]="!isNoFormula()"
                    [class.has-audit-issue]="limit === 'auditedActual'"
                    [style.flex-grow]="applyFlexGrow() ? 1 : 0 + ' !important'"
                    [class.start-0]="languageService.current() === 'en'"
                    [class.end-0]="languageService.current() === 'ae'"
                >
                    <span class="label" [dir]="languageService.direction()">
                        {{ (limit === "annualActual" ? "data_entry.annual_actual" : "data_entry.audited_annual_actual") | translate }}
                    </span>
                </div>

                @for (audit of sanitizedAuditInfo(); track $index) {
                    @let value =
                        getAuditPerformance(
                            limit === "annualActual" ? audit.annualActual : audit.auditedActual,
                            false,
                            limit === "annualActual" ? audit.annualActualIgnored : audit.auditedActualIgnored
                        );

                    <div
                        class="d-flex kpi-details--col kpi-details--data-col"
                        [class.has-audit-issue]="limit === 'auditedActual'"
                        [style.width]="getSpanning(audit.year) * 100 + 'px !important'"
                        [style.flex-basis]="'unset'"
                    >
                        <span class="label fw-bold" dir="ltr">
                            {{ value }}
                        </span>
                    </div>
                }
            </div>
        }
        <!-- END-ROW -->

        <!-- Actual:Metrics --ROW-- -->
        @if (!isNoFormula()) {
            @for (metric of metricsData(); track metric.id) {
                <div class="d-flex align-content-center kpi-details--row">
                    <div
                        class="d-flex position-sticky text-center kpi-details--col kpi-details--main-col"
                        [class.is-formula]="!isNoFormula()"
                        [class.start-0]="languageService.current() === 'en'"
                        [class.end-0]="languageService.current() === 'ae'"
                    >
                        <span class="label" [dir]="languageService.direction()">
                            <kbd>{{ metric.metricName }}</kbd>
                            <br />
                            <code
                                [dir]="languageService.direction()"
                                style="font-size: 11px; margin-top: 3px; font-weight: bold; color: rgba(83, 174, 230, 0.75)"
                            >
                                {{ metric.metricDescription }}
                            </code>
                        </span>
                    </div>

                    @let metricReading = metric.metricReadingsItemValues;

                    @for (period of periods(); track period.periodId) {
                        @let value = getMetricValue(period.periodId, metricReading);
                        @let actualOverride = value?.actualOverride;
                        @let actualValue = value?.ignored ? "N/A" : value?.value;

                        <div
                            class="d-flex kpi-details--col kpi-details--data-col"
                            [class.target-override]="actualOverride"
                            [ngbTooltip]="actualOverride ? ('kpi.actual_override' | translate) : false"
                        >
                            <span class="label fw-bold" dir="ltr">
                                {{ actualValue }}
                            </span>
                        </div>
                    }
                </div>
            }
        }
        <!-- END-ROW -->

        <!-- Performance:score --ROW-- -->
        <div class="d-flex align-content-center kpi-details--row">
            <div
                class="d-flex position-sticky text-center kpi-details--col kpi-details--main-col flex-grow-0"
                [class.start-0]="languageService.current() === 'en'"
                [class.end-0]="languageService.current() === 'ae'"
                [class.is-formula]="!isNoFormula()"
            >
                <span class="label" [dir]="languageService.direction()">
                    {{ "nkpi.performance" | translate }}
                </span>
            </div>

            @for (period of periods(); track period.periodId) {
                @let score = getScore(period.periodId);

                <div class="d-flex kpi-details--col kpi-details--data-col">
                    <span class="label fw-bold" dir="ltr">
                        @if (isDefined(score)) {
                            {{ score | percentageValue: 2 : "" : "%" }}
                        }
                    </span>
                </div>
            }
        </div>
        <!-- END-ROW -->

        <!-- AnnualPerformance --ROW-- -->
        <div class="d-flex align-content-center kpi-details--row">
            <div
                class="d-flex position-sticky text-center kpi-details--col kpi-details--main-col flex-grow-0"
                [class.start-0]="languageService.current() === 'en'"
                [class.end-0]="languageService.current() === 'ae'"
                [class.is-formula]="!isNoFormula()"
                [style.flex-grow]="applyFlexGrow() ? 1 : 0 + ' !important'"
            >
                <span class="label" [dir]="languageService.direction()">
                    {{ "landingPage.entity_page.annual_performance" | translate }}
                </span>
            </div>

            @for (audit of sanitizedAuditInfo(); track $index) {
                @let perf = getAuditPerformance(audit.annualPerformance, true);

                <div
                    class="d-flex kpi-details--col kpi-details--data-col"
                    [style.width]="getSpanning(audit.year) * 100 + 'px !important'"
                    [style.flex-basis]="'unset'"
                >
                    <span class="label fw-bold" dir="ltr">
                        @if (isDefined(perf)) {
                            {{ perf }}
                        }
                    </span>
                </div>
            }
        </div>
        <!-- END-ROW -->
    </div>
</ng-template>

<ng-template #printTable></ng-template>
