import { CommonModule } from "@angular/common";
import { Component, inject, input, output, signal, TemplateRef, viewChild } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";

import { AdaaHelper } from "../../../core/utils";
import { FormActionButtonsComponent, FormInputComponent } from "../../../shared/components";
import { AdaaBoolean, Language, PageMode } from "../../../shared/constants/enums";
import { LookupContent } from "../../../shared/models/lookup-content.models";
import { AppService } from "../../../shared/services";
import { LookupEditorService } from "../../../shared/services/lookup-editor.service";

@Component({
  selector: "adaa-lookup-content-editor",
  standalone: true,
  imports: [CommonModule, FormInputComponent, FormActionButtonsComponent, TranslateModule, ReactiveFormsModule],
  templateUrl: "./lookup-content-editor.component.html",
  styleUrl: "./lookup-content-editor.component.scss",
})
export class LookupContentEditorComponent {
  lookupEditorService = inject(LookupEditorService);
  private _formBuilder = inject(FormBuilder);
  private _modalService = inject(NgbModal);
  private _appService = inject(AppService);
  private _toastrService = inject(ToastrService);
  private _translateService = inject(TranslateService);

  modal = viewChild.required<TemplateRef<unknown>>("content");

  pageMode = input.required<string>();
  editorClosed = output<LookupContent>();
  PageMode = PageMode;

  lookupForm: FormGroup = new FormGroup({});
  displayValueLabel = signal<string>("");
  submitted = signal<boolean>(false);
  _subscription = new Subscription();

  public ngOnInit() {
    this._prepareForm();
    this.lookupEditorService.formValidator();
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  private _prepareForm() {
    this.lookupForm = this._formBuilder.group({
      nameAE: this._formBuilder.control<string | undefined>("", {
        validators: [Validators.required],
      }),
      nameEN: this._formBuilder.control<string | undefined>("", {
        validators: [Validators.required],
      }),
      propType: this._formBuilder.control<string | undefined>(""),
      id: this._formBuilder.control<string | undefined>(""),
      labelAE: this._formBuilder.control<string | undefined>(""),
      labelEN: this._formBuilder.control<string | undefined>(""),
      value: this._formBuilder.control<string | undefined>(""),
    });

    this._subscription.add(
      this.lookupForm.valueChanges.subscribe((value) => {
        const displayLanguage = AdaaHelper.getFieldLanguage("label");
        if (!value[displayLanguage]?.length) {
          this.displayValueLabel.set(value["labelAE"] || value["labelEN"]);
        } else this.displayValueLabel.set(value[displayLanguage]);
      })
    );
  }

  public open() {
    this._modalService.open(this.modal(), {
      animation: true,
      scrollable: false,
      keyboard: false,
      size: "xl",
      centered: true,
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });

    this.submitted.set(false);
  }

  public submit() {
    this.submitted.set(true);
    if (this.lookupForm.invalid) {
      this._toastrService.error(this._translateService.instant("common.form.label.missing_information"));
      return;
    }
    const result = {
      ...this.lookupForm.value,
      enabled: AdaaBoolean.Y,
    };

    this.editorClosed.emit(result);
  }

  public checkInvalid(control: string) {
    return (
      this.lookupForm.controls[control].errors?.["required"] &&
      (this.lookupForm.get("nameEN")?.dirty || this.submitted())
    );
  }
}
