<adaa-entity-plan-change-detection
    [template]="page"
    [request$]="fetchKpi()"
    [redirectionUrl]="'/console/kpi/' + kpiType()"
    [ignoreDetection]="pageMode() === 'create'"
    [ignoreEntityDetection]="entityIsPmo() && (isDTKPI() || isNTKPI() || isEKPI() || isMOKPI())"
    (continue)="initPage($event)"
/>

<ng-template #page>
    <adaa-float-action />

    <section class="d-block mb-1 page-header">
        <h4 class="m-0 fs-4 fw-bold">
            {{ pageTitle() | translate }}

            @if (kpiName()?.length) {
                <span class="fw-light mx-1"> - {{ kpiName() }} </span>
            }
        </h4>
    </section>

    @if (kpiId()) {
        <section class="d-block">
            <adaa-wf-alert-decision [itemId]="kpiId()!" [itemType]="kpiTypeId()" />
        </section>
    }

    <section class="d-block pb-3 pt-1">
        @let activeTab = kpiEditorTabs.activeTab();
        @if (isDefined(activeTab)) {
            <adaa-form-steps
                [tabs]="kpiEditorTabs.tabs()"
                [showSaveButton]="pageMode() === 'create' || (pageMode() === 'edit' && kpi()?.status === 3)"
                [showFinishButton]="true"
                [activeTab]="activeTab"
                (currentTab)="kpiEditorTabs.activeTab.set($event)"
                (submitClicked)="submit($event)"
                (cancelClicked)="goBack()"
            >
                <div class="tab-pane fade" role="tabpanel" [ngClass]="{ 'show active': kpiEditorTabs.isTabActiveFromId('measurementDetails') }">
                    <adaa-measurement-details
                        #measurementDetails
                        [validations]="validations()"
                        [kpiType]="kpiTypeId()"
                        [pageMode]="pageMode()"
                        [kpi]="kpi()"
                        [stagedKpi]="stagedKpi()"
                        [startDate]="timestamp()?.start"
                        [endDate]="timestamp()?.end"
                        (onMeasureDetailsTabFormValueChange)="handleMeasurementDetailsFormChanges($event)"
                    />
                </div>

                <div class="tab-pane fade" role="tabpanel" [ngClass]="{ 'show active': kpiEditorTabs.isTabActiveFromId('info') }">
                    <adaa-information-tab
                        #infoTabForm
                        [validations]="validations()"
                        [kpiType]="kpiTypeId()"
                        [pageMode]="pageMode()"
                        [kpi]="kpi()"
                        [stagedKpi]="stagedKpi()"
                        (onInfoTabFormValueChange)="measurementDetails.updateBaselineYear($event?.startDate); handleInfoTabFormChanges($event)"
                    />
                </div>

                <div class="tab-pane fade" role="tabpanel" [ngClass]="{ 'show active': kpiEditorTabs.isTabActiveFromId('targets') }">
                    @if (isMasterEKPI()) {
                        <adaa-master-kpi-targets
                            #kpiTargets
                            [isTabActive]="kpiEditorTabs.isTabActiveFromId('targets')"
                            [pageMode]="pageMode()"
                            [stagedKpi]="stagedKpi()"
                            [kpi]="kpi()"
                            [kpiType]="kpiTypeId()"
                            [textMaps]="textMaps()"
                        />
                    } @else {
                        <adaa-kpi-targets
                            #kpiTargets
                            [isTabActive]="kpiEditorTabs.isTabActiveFromId('targets')"
                            [pageMode]="pageMode()"
                            [stagedKpi]="stagedKpi()"
                            [kpi]="kpi()"
                            [kpiType]="kpiTypeId()"
                            [textMaps]="textMaps()"
                        />
                    }
                </div>

                <div class="tab-pane fade pt-1" role="tabpanel" [ngClass]="{ 'show active': kpiEditorTabs.isTabActiveFromId('extendedFields') }">
                    <adaa-ext-fields-tab [itemType]="kpiTypeId()" [itemId]="kpiId()" [attributes]="extAttributes()" #extFieldsTab />
                </div>

                <div class="tab-pane fade" role="tabpanel" [ngClass]="{ 'show active': kpiEditorTabs.isTabActiveFromId('benchmarking') }">
                    <adaa-kpi-benchmark-form
                        #kpiBenchmarkForm
                        [tableData]="benchmarks()"
                        [kpi]="kpi()"
                        [pageMode]="pageMode()"
                        [stagedKpi]="stagedKpi()"
                        (onBenchmarkUpdate)="handleBenchmarkFormChanges($event)"
                    />
                </div>

                <div class="tab-pane fade" role="tabpanel" [ngClass]="{ 'show active': kpiEditorTabs.isTabActiveFromId('attachments') }">
                    <div class="bg-white shadow-md rounded p-2 mb-2">
                        <adaa-attachments-list
                            #attachmentList
                            [attachments]="attachments()"
                            (attachmentsChanged)="handleAttachmentsFormChanges($event)"
                        />
                    </div>
                </div>

                <div class="tab-pane fade" role="tabpanel" [ngClass]="{ 'show active': kpiEditorTabs.isTabActiveFromId('links') }">
                    <adaa-national-target-form
                        [validations]="validations()"
                        [itemType]="kpiTypeId()"
                        [entityId]="entityId()"
                        [ntkpiId]="ntkpiId()"
                        #nationalTargetForm
                    />
                </div>

                <div class="tab-pane fade" role="tabpanel" [ngClass]="{ 'show active': kpiEditorTabs.isTabActiveFromId('targetAgreement') }">
                    <adaa-national-target-management [validations]="validations()" [options]="entityLeadOptionRequests()" #nationalTargetManagement />
                </div>

                <div class="tab-pane fade" role="tabpanel" [ngClass]="{ 'show active': kpiEditorTabs.isTabActiveFromId('dataInput') }">
                    <adaa-data-input-tab
                        #dataInput
                        [affectedEntities]="affectedEntities()"
                        (affectedEntitiesChanged)="handleDataInputChanges($event)"
                    />
                </div>

                <div class="tab-pane fade" role="tabpanel" [ngClass]="{ 'show active': kpiEditorTabs.isTabActiveFromId('improvementPlan') }">
                    <adaa-improvement-plan
                        #improvementPlan
                        [stagedKpi]="stagedKpi()"
                        [kpi]="kpi()"
                        [entities]="infoTabForm.entities()"
                        [kpiType]="kpiTypeId()"
                        (improvementPlanUpdated)="handleImprovementPlanChanges($event)"
                    />
                </div>
            </adaa-form-steps>
        }
    </section>
</ng-template>

<ng-template #recalculateModal>
    <div class="modal-header">
        <h4 class="modal-title w-100 text-center">{{ "kpi.formula_changed" | translate }}</h4>
        <button
            type="button"
            class="btn-close"
            aria-label="Close button"
            aria-describedby="modal-title"
            (click)="recalculateModalRef.dismiss()"
        ></button>
    </div>

    <div class="modal-body bg-white px-3 py-1">
        <p [innerHTML]="'kpi.formula_changed_decision' | translate"></p>
    </div>

    <div class="modal-footer d-flex justify-content-center align-items-center gap-3">
        <button type="button" class="btn btn-primary px-4" (click)="recalculateModalRef.close()">
            {{ "kpi.formula_recalculate" | translate }}
        </button>
    </div>
</ng-template>
