import { hasPermissionGuard } from "../../core/guards";
import { Constants } from "../../shared/constants/constants";
import { PermissionAction } from "../../shared/constants/enums";

export const viewSupportCenterPageGuards = [
  hasPermissionGuard({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.view,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.HELP,
        },
      ],
    },
    redirectTo: "/403",
  }),
];
