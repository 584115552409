import { Component, inject, signal, viewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { BreadcrumbService } from "xng-breadcrumb";

import { AdaaHelper } from "../../../core/utils";
import { ConfirmationModalComponent, DataTableComponent, FloatActionComponent } from "../../../shared/components";
import { genericFloatButtons } from "../../../shared/components/general/float-action";
import { Constants } from "../../../shared/constants/constants";
import { AdaaBoolean, Language, PageMode } from "../../../shared/constants/enums";
import { CustomButton, TableButtonClicked } from "../../../shared/models";
import { LookupContent } from "../../../shared/models/lookup-content.models";
import { AppService, AppTitleService } from "../../../shared/services";
import { LookupContentApiService } from "../../../shared/services/lookup-content-api.service";
import { LookupContentEditorComponent } from "../lookup-content-editor/lookup-content-editor.component";

@Component({
  selector: "adaa-lookup-content-list",
  standalone: true,
  imports: [DataTableComponent, FloatActionComponent, TranslateModule, LookupContentEditorComponent],
  templateUrl: "./lookup-content-list.component.html",
  styleUrl: "./lookup-content-list.component.scss",
})
export class LookupContentListComponent {
  private _router = inject(Router);
  private _activatedRoute = inject(ActivatedRoute);
  private _lookupContentApiService = inject(LookupContentApiService);
  private _toastrService = inject(ToastrService);
  private _translateService = inject(TranslateService);
  private _modalService = inject(NgbModal);
  private _appService = inject(AppService);
  private _breadcrumbService = inject(BreadcrumbService);
  private readonly _appTitleService = inject(AppTitleService);

  lookupEditor = viewChild.required<LookupContentEditorComponent>("editor");
  adaaDataTable = viewChild.required<DataTableComponent>("adaaDataTable");
  pageMode = signal<string>(PageMode.view);
  displayTitle = signal<string>("");
  lookupType: LookupContent;
  lookupId: string;

  PageMode = PageMode;

  constants = Constants;
  customButtons: CustomButton[] = [
    {
      iconName: "adaa-icon-adaa-ok-Y",
      eventName: "enable",
      iconTitle: "common.form.label.active",
      onlyIf: (data: { enabled: AdaaBoolean }) => AdaaBoolean.Y === data?.enabled,
    },
    {
      iconName: "adaa-icon-adaa-ok-N",
      eventName: "disable",
      iconTitle: "common.form.label.inactive",
      onlyIf: (data: { enabled: AdaaBoolean }) => AdaaBoolean.N === data?.enabled,
    },
  ];

  readonly #floatActions = genericFloatButtons();

  public ngOnInit(): void {
    this.lookupId = this._activatedRoute.snapshot?.params?.id;
    this._setTitle();

    this.#floatActions([
      {
        key: "print",
        data: undefined,
      },
    ]);
  }

  private _setTitle() {
    if (this.lookupId)
      this._lookupContentApiService.getLookupById(+this.lookupId).subscribe({
        next: (response) => {
          const displayLanguage = AdaaHelper.getFieldLanguage("name");

          this.lookupType = response.responseData;
          const name = this.lookupType[displayLanguage as keyof typeof this.lookupType].toString();
          this._appTitleService.setTitle(name, true);
          this._breadcrumbService.set("@content", name);
          this.displayTitle.set(name);
        },
      });
  }

  public addNew() {
    this.pageMode.set(PageMode.create);
    this.lookupEditor().lookupForm.reset({});
    this.lookupEditor().lookupForm.enable();
    this.lookupEditor().lookupForm.patchValue({ propType: this.lookupId });
    this.lookupEditor().lookupForm.patchValue({ labelAE: this.lookupType.labelAE, labelEN: this.lookupType.labelEN });
    this.lookupEditor().open();
  }

  public getAction(value: TableButtonClicked) {
    switch (value.event) {
      case "view":
        this._handleLookupEditor(value, PageMode.view);
        break;
      case "edit":
        this._handleLookupEditor(value, PageMode.edit);
        break;
      case "delete":
        this._deleteLookupContent(value);
        break;
      case "enable":
      case "disable":
        this._updateState(value);
        break;
      default:
        break;
    }
  }

  private _handleLookupEditor(value: TableButtonClicked, mode: string) {
    this.pageMode.set(mode);
    if (mode === PageMode.view) {
      Object.keys(this.lookupEditor().lookupForm.controls).forEach((key) => {
        this.lookupEditor().lookupForm.get(key)?.disable();
      });
    } else this.lookupEditor().lookupForm.enable();

    this.lookupEditor().lookupForm.patchValue(value.data);
    this.lookupEditor().lookupForm.patchValue({ labelAE: this.lookupType.labelAE, labelEN: this.lookupType.labelEN });

    if (mode === PageMode.edit) this.lookupEditor().lookupForm.patchValue({ id: value.data.id });
    if (mode === PageMode.create) this.lookupEditor().lookupForm.patchValue({ propType: this.lookupId });

    this.lookupEditor().open();
  }

  private _deleteLookupContent(value: TableButtonClicked) {
    const modal = this._modalService.open(ConfirmationModalComponent, {
      animation: true,
      scrollable: false,
      keyboard: false,
      size: "lg",
      centered: true,
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });

    modal.componentInstance.header = "lookup.content.delete_yes_no_title";
    modal.componentInstance.title = "lookup.content.delete_yes_no_information";

    modal.result.then((e) => {
      if (e) {
        this._lookupContentApiService.deleteLookupContent(value.data.id).subscribe({
          next: (response) => {
            if (response.inError) return;
            this._toastrService.success(this._translateService.instant("notification.success.remove"));
            this.adaaDataTable().loadTableData();
          },
        });
      }
    });
  }

  private _updateState(value: TableButtonClicked) {
    const data = { id: value.data.id, enabled: value.event === "enable" ? "N" : "Y" };
    this._lookupContentApiService.updateState(data).subscribe({
      next: () => {
        this._toastrService.success(
          this._translateService.instant(
            "notification.success." + (value.event === "enable" ? "deactivate" : "activate")
          )
        );
        this.adaaDataTable().loadTableData();
      },
    });
  }

  public submit(data: LookupContent) {
    const serviceUrl =
      this.pageMode() === PageMode.edit
        ? this._lookupContentApiService.updateLookupContent(data)
        : this._lookupContentApiService.createLookupContent(data);

    serviceUrl.subscribe({
      next: (response) => {
        if (response.inError) return;
        this._modalService.dismissAll();
        this._toastrService.success(this._translateService.instant("notification.success.save"));
        this.adaaDataTable().loadTableData();
      },
    });
  }
}
