import type { Route } from "@angular/router";

import { checkIfLoggedInFn } from "../../core/guards";
import { ImportExportObjectType, PageMode } from "../../shared/constants/enums";
import { AuditIssuesComponent } from "./audit-issues/audit-issues.component";
import { KpiEditorComponent } from "./kpi-editor/kpi-editor.component";
import { KpiListComponent } from "./kpi-list/kpi-list.component";
import { KpiSubmenuComponent } from "./kpi-submenu/kpi-submenu.component";
import {
  auditIssuesViewAccessGuard,
  kpiTypeCreateAccessGuard,
  kpiTypeEditAccessGuard,
  kpiTypeViewAccessGuard,
  viewImportExportKpiPagesGuards,
} from "./kpi-utils";
import { KpiViewComponent } from "./kpi-view/kpi-view.component";

export const KPI_ROUTES: Route[] = [
  {
    path: "kpi",
    children: [
      {
        path: "import-export-kpi",
        canActivate: [checkIfLoggedInFn, ...viewImportExportKpiPagesGuards],
        data: {
          breadcrumb: "importExport.title_kpi",
          title: "importExport.title_kpi",
          type: ImportExportObjectType.KPI,
        },
        loadComponent: () =>
          import("../../shared/components/import-export/import-export.component").then((e) => e.ImportExportComponent),
      },
      {
        path: "",
        component: KpiSubmenuComponent,
        canActivate: [checkIfLoggedInFn],
        data: {
          title: "kpi.kpis",
          breadcrumb: "kpi.kpis",
        },
      },
      {
        path: "audit-issues",
        component: AuditIssuesComponent,
        canActivate: [checkIfLoggedInFn, ...auditIssuesViewAccessGuard],
        data: {
          title: "breadcrumbs.audit_issue",
          breadcrumb: "breadcrumbs.audit_issue",
        },
      },
      {
        path: ":kpiType",
        children: [
          {
            path: "",
            component: KpiListComponent,
            canActivate: [checkIfLoggedInFn, kpiTypeViewAccessGuard],
            data: {
              title: "kpi.kpis",
              breadcrumb: {
                alias: "kpisList",
              },
            },
          },
          {
            path: "edit/:kpiId",
            component: KpiEditorComponent,
            canActivate: [checkIfLoggedInFn, kpiTypeEditAccessGuard],
            data: {
              title: "kpi.kpis",
              breadcrumb: {
                alias: "kpiEditor",
              },
              pageMode: PageMode.edit,
            },
          },
          {
            path: "create",
            component: KpiEditorComponent,
            canActivate: [checkIfLoggedInFn, kpiTypeCreateAccessGuard],
            data: {
              title: "kpi.kpis",
              breadcrumb: {
                alias: "kpiEditor",
              },
              pageMode: PageMode.create,
            },
          },
          {
            path: ":kpiId",
            component: KpiViewComponent,
            canActivate: [checkIfLoggedInFn, kpiTypeViewAccessGuard],
            data: {
              title: "kpi.kpis",
              breadcrumb: {
                alias: "kpisView",
              },
            },
          },
        ],
      },
    ],
  },
];
