import { Component, inject, input, viewChild } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { ConfirmationModalComponent, DataTableComponent, FloatActionComponent } from "../../../shared/components";
import { genericFloatButtons } from "../../../shared/components/general/float-action";
import { Constants } from "../../../shared/constants/constants";
import { Language } from "../../../shared/constants/enums";
import { TableButtonClicked } from "../../../shared/models";
import { AppService } from "../../../shared/services";
import { OtherEntitiesApiService } from "../../../shared/services/other-entities-api.service";

@Component({
  selector: "adaa-other-entities-list",
  standalone: true,
  imports: [DataTableComponent, FloatActionComponent],
  templateUrl: "./other-entities-list.component.html",
  styleUrl: "./other-entities-list.component.scss",
})
export class OtherEntitiesListComponent {
  private _router = inject(Router);
  private _modalService = inject(NgbModal);
  private _appService = inject(AppService);
  private _otherEntitesService = inject(OtherEntitiesApiService);
  private _toastrService = inject(ToastrService);
  private _translateService = inject(TranslateService);

  tableConfList = input.required<string>();
  adaaDataTable = viewChild.required<DataTableComponent>("adaaDataTable");
  constants = Constants;

  readonly #floatActions = genericFloatButtons();

  public ngOnInit() {
    this.#floatActions([
      {
        key: "print",
        data: undefined,
      },
      {
        key: "helpdesk",
        data: {
          url: "/helpdesk/create",
        },
      },
    ]);
  }
  public addNewClicked() {
    this._router.navigateByUrl(`/console/other-entities/create`);
  }

  public getAction(event: TableButtonClicked) {
    const otherEntityId = event.data.id;
    switch (event.event) {
      case "view":
        this._router.navigateByUrl(`/console/other-entities/${otherEntityId}`);
        break;
      case "edit":
        this._router.navigateByUrl(`/console/other-entities/edit/${otherEntityId}`);
        break;
      case "delete":
        this._deleteOtherEntityId(otherEntityId);
        break;

      default:
        break;
    }
  }

  private _deleteOtherEntityId(otherEntityId: number) {
    const modal = this._modalService.open(ConfirmationModalComponent, {
      centered: true,
      size: "md",
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });

    modal.componentInstance.header = "other_entities.delete_confirmation_title";
    modal.componentInstance.title = "other_entities.delete_confirmation_message";

    modal.result.then((e) => {
      if (e) {
        this._otherEntitesService.deleteOtherEntity(otherEntityId).subscribe({
          next: (response) => {
            if (response.inError) return;
            this._toastrService.success(this._translateService.instant("notification.success.remove"));
            this.adaaDataTable().loadTableData();
          },
        });
      }
    });
  }
}
