<adaa-data-table
    #adaaDataTable
    apiPrefix="govScoresSector"
    [configKey]="Constants.MAIN_TABLE_LIST_CONF_KEY.GOV_SECTORS_CONF_LIST"
    [titleHeader]="breadcrumb() | translate"
    [customTableButtons]="customTableButtons"
    (buttonClicked)="buttonClicked($event)"
    (addNewClicked)="addNew()"
    (actionClicked)="getAction($event)"
/>

<adaa-float-action />
