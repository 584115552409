import { Route } from "@angular/router";

import { checkIfLoggedInFn } from "../../core/guards";
import { Constants } from "../../shared/constants/constants";
import { DownloadedReportsComponent } from "./downloaded-reports.component";

export const DOWNLOADED_REPORTS_ROUTES: Route[] = [
  {
    path: "downloaded-reports",
    canActivate: [checkIfLoggedInFn],
    component: DownloadedReportsComponent,
    data: {
      breadcrumb: "report.generated_reports.console_menu_item",
      tableConfList: Constants.MAIN_TABLE_LIST_CONF_KEY.DOWNLOADED_REPORTS_CONF_LIST,
    },
  },
];
