@if (!isUAEPassLogin()) {
    <div class="bg-white shadow-md rounded p-2 mb-3">
        <div class="text-center">
            <p>{{ "new_password.error.error_message" | translate }}</p>
            <p class="text-danger">{{ "new_password.error.check_english_letters" | translate }}</p>
            <p class="text-danger">{{ "new_password.error.check_numbers" | translate }}</p>
            <p class="text-danger">{{ "new_password.error.check_password_length" | translate }}</p>
        </div>

        <form class="col-12" [formGroup]="passwordForm">
            <div class="row my-2">
                <div class="col-md-4 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.currentPassword' | translate"
                        [required]="true"
                        [controlName]="'oldPassword'"
                        [formControlName]="'oldPassword'"
                        [inputType]="'password'"
                    ></adaa-form-input>
                </div>
                <div class="col-md-4 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.newPassword' | translate"
                        [required]="true"
                        [controlName]="'newPassword'"
                        [formControlName]="'newPassword'"
                        [inputType]="'password'"
                    ></adaa-form-input>
                </div>
                <div class="col-md-4 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.newPasswordConfirmation' | translate"
                        [required]="true"
                        [controlName]="'newPasswordConfirmation'"
                        [formControlName]="'newPasswordConfirmation'"
                        [inputType]="'password'"
                    ></adaa-form-input>
                </div>
            </div>
        </form>

        <div class="d-flex justify-content-end my-4">
            <button type="submit" class="btn btn-primary" (click)="changePassword()">
                <span>{{ "common.form.button.change_password" | translate }}</span>
            </button>
        </div>
    </div>
}

<div class="bg-white shadow-md rounded p-2 mb-3">
    <form class="col-12" [formGroup]="securityQuestionsForm">
        <div class="row">
            <div class="col-12 col-md-6 mb-3">
                <adaa-form-dropdown
                    [label]="getLabel(1)"
                    [required]="true"
                    [options]="securityQuestionOptions()"
                    [controlName]="'question1'"
                    [formControlName]="'question1'"
                ></adaa-form-dropdown>
            </div>
            <div class="col-md-6 col-sm-12 col-xs-12">
                <adaa-form-input
                    [label]="'common.form.label.answer' | translate"
                    [required]="true"
                    [invalid]="checkInvalid('answer1')"
                    [controlName]="'answer1'"
                    [formControlName]="'answer1'"
                ></adaa-form-input>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6 mb-3">
                <adaa-form-dropdown
                    [label]="getLabel(2)"
                    [required]="true"
                    [options]="securityQuestionOptions()"
                    [controlName]="'question2'"
                    [formControlName]="'question2'"
                ></adaa-form-dropdown>
            </div>
            <div class="col-md-6 col-sm-12 col-xs-12">
                <adaa-form-input
                    [label]="'common.form.label.answer' | translate"
                    [required]="true"
                    [invalid]="checkInvalid('answer2')"
                    [controlName]="'answer2'"
                    [formControlName]="'answer2'"
                ></adaa-form-input>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6 mb-3">
                <adaa-form-dropdown
                    [label]="getLabel(3)"
                    [required]="true"
                    [options]="securityQuestionOptions()"
                    [controlName]="'question3'"
                    [formControlName]="'question3'"
                ></adaa-form-dropdown>
            </div>
            <div class="col-md-6 col-sm-12 col-xs-12">
                <adaa-form-input
                    [label]="'common.form.label.answer' | translate"
                    [required]="true"
                    [invalid]="checkInvalid('answer3')"
                    [controlName]="'answer3'"
                    [formControlName]="'answer3'"
                ></adaa-form-input>
            </div>
        </div>
    </form>
</div>
