import { NgClass } from "@angular/common";
import { Component, inject, input, OnInit, signal, viewChild } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { ToastrService } from "ngx-toastr";
import { forkJoin } from "rxjs";

import { AdaaHelper } from "../../../../core/utils";
import { dateRangeValidator } from "../../../../core/validators";
import {
  AttachmentsListComponent,
  CommentsComponent,
  ContributingEntitiesComponent,
  EntityPlanChangeDetectionComponent,
  FloatActionComponent,
  FormDropdownComponent,
  FormDropdownMultiComponent,
  FormDropdownTreeComponent,
  FormInputComponent,
  FormInputDateComponent,
  FormStepsComponent,
  MilestoneListComponent,
  NationalStrategiesSelectorComponent,
  TpPmoDesisionsComponent,
  WfAlertDecisionComponent,
} from "../../../../shared/components";
import { genericFloatButtons } from "../../../../shared/components/general/float-action";
import { Constants } from "../../../../shared/constants/constants";
import { AdaaBoolean, PageMode } from "../../../../shared/constants/enums";
import {
  AgmComment,
  AgmProject,
  FormTab,
  GovDirection,
  LoginResponse,
  MainResponseModel,
  SearchFields,
  SubGovDirection,
  ValidatorModelType,
  ValueText,
} from "../../../../shared/models";
import {
  AgmCycleApiService,
  AgmProjectApiService,
  EntitiesApiService,
  ItemApiService,
  OrgUnitApiService,
  OtherEntitiesApiService,
  PropertiesService,
  SystemLayoutService,
  TpSectorApiService,
  UsersApiService,
  ValidatorApiService,
} from "../../../../shared/services";

@Component({
  selector: "adaa-agm-project-editor",
  imports: [
    NgClass,
    TranslateModule,
    ReactiveFormsModule,
    AttachmentsListComponent,
    CommentsComponent,
    ContributingEntitiesComponent,
    FormDropdownComponent,
    FormDropdownMultiComponent,
    FormDropdownTreeComponent,
    FormInputComponent,
    FormInputDateComponent,
    FormStepsComponent,
    MilestoneListComponent,
    NationalStrategiesSelectorComponent,
    TpPmoDesisionsComponent,
    WfAlertDecisionComponent,
    FloatActionComponent,
    EntityPlanChangeDetectionComponent,
  ],
  templateUrl: "./agm-project-editor.component.html",
  styleUrl: "./agm-project-editor.component.scss",
})
export class AgmProjectEditorComponent implements OnInit {
  private _router = inject(Router);
  private _toastrService = inject(ToastrService);
  private _translateService = inject(TranslateService);
  private _formBuilder = inject(FormBuilder);
  private _validatorApiService = inject(ValidatorApiService);
  private _propertiesService = inject(PropertiesService);
  private _entitiesApiService = inject(EntitiesApiService);
  private _otherEntitiesApiService = inject(OtherEntitiesApiService);
  private _tpSectorApiService = inject(TpSectorApiService);
  private _usersApiService = inject(UsersApiService);
  private _orgUnitApiService = inject(OrgUnitApiService);
  private _itemApiService = inject(ItemApiService);
  private _agmCycleApiService = inject(AgmCycleApiService);
  private _agmProjectApiService = inject(AgmProjectApiService);
  private _systemLayoutService = inject(SystemLayoutService);

  nationalStrategies = viewChild.required<NationalStrategiesSelectorComponent>("nationalStrategies");

  id = input<number | undefined>();
  pageMode = input.required<PageMode>();

  private _noneOption = () => ({ value: undefined, text: this._translateService.instant("common.form.label.none") });
  private _untilDestroyed = AdaaHelper.untilDestroyed();
  private _floatActions = genericFloatButtons();

  constants = Constants;
  AdaaHelper = AdaaHelper;
  PageMode = PageMode;
  submitted: boolean = false;
  enableLinkToNt: boolean = false;
  projectForm: FormGroup;
  formValidation = signal<ValidatorModelType | undefined>(undefined);
  activeTab = signal<number>(0);
  entities = signal<ValueText[]>([]);
  otherEntities = signal<ValueText[]>([]);
  owners = signal<ValueText[]>([]);
  orgUnits = signal<ValueText[]>([]);
  pmoDesisions = signal<ValueText[]>([]);
  sectors = signal<ValueText[]>([]);
  ntKpis = signal<ValueText[]>([]);
  cycles = signal<ValueText[]>([]);
  govDirections = signal<GovDirection[]>([]);
  subGovDirections = signal<SubGovDirection[]>([]);
  readonly fetchProject = () => {
    return this._agmProjectApiService.getProject(Number(this.id()));
  };
  tabs: FormTab[] = [
    {
      title: "common.form.label.information",
    },
    {
      title: "national_projects.milestones",
    },
    {
      title: "kpi.attachments",
    },
    {
      title: "common.links",
    },
  ];

  public get displayLabel(): string {
    const nameEN = this.projectForm.get("nameEN")?.value;
    const nameAE = this.projectForm.get("nameAE")?.value;

    return AdaaHelper.getPageLabel(nameAE, nameEN);
  }

  public get showSaveButton(): boolean {
    return !this.id() || this.projectForm.get("status")?.value === Constants.OBJECT_STATUS.DRAFT;
  }

  public get targetAudience(): AgmComment[] {
    const targetAudience: AgmComment[] = this.projectForm.get("targetAudience")?.value ?? [];

    return targetAudience.filter((e) => e.status !== Constants.OBJECT_STATUS.REMOVE);
  }

  public get expectedImpact(): AgmComment[] {
    const expectedImpact: AgmComment[] = this.projectForm.get("expectedImpact")?.value ?? [];

    return expectedImpact.filter((e) => e.status !== Constants.OBJECT_STATUS.REMOVE);
  }

  public get comments(): AgmComment[] {
    const comments: AgmComment[] = this.projectForm.get("comments")?.value ?? [];

    return comments.filter((e) => e.status !== Constants.OBJECT_STATUS.REMOVE);
  }

  public get filteredEntites(): ValueText[] {
    const entities: number[] = [];

    const entityId = this.projectForm.get("entityId")?.value;
    if (entityId) entities.push(+entityId);

    let contributingEntities = this.projectForm.get("contributingEntities")?.value;
    if (contributingEntities) {
      contributingEntities = contributingEntities
        .filter((e: { otherEntityId: number | undefined }) => !e.otherEntityId)
        .map((e: { entityId: number }) => e.entityId);

      if (contributingEntities) entities.push(...contributingEntities);
    }

    return this.entities().filter((e) => entities.includes(e.value));
  }

  public get filteredOtherEntites(): ValueText[] {
    const entities: number[] = [];

    let contributingEntities = this.projectForm.get("contributingEntities")?.value;
    if (contributingEntities) {
      contributingEntities = contributingEntities
        .filter((e: { otherEntityId: number | undefined }) => e.otherEntityId)
        .map((e: { otherEntityId: number }) => e.otherEntityId);

      if (contributingEntities) entities.push(...contributingEntities);
    }

    return this.otherEntities().filter((e) => entities.includes(e.value));
  }

  public get filteredSectors(): ValueText[] {
    const sectors = this.projectForm.get("sectors")?.value;

    return sectors ? this.sectors().filter((e) => sectors.includes(e.value)) : [];
  }

  public ngOnInit(): void {
    this.projectForm = this._prepareForm();

    this._systemLayoutService.hasCycleChanged$.pipe(this._untilDestroyed()).subscribe({
      next: () => this.goBack(),
    });
    this._systemLayoutService.hasActiveEntityChanged$.pipe(this._untilDestroyed()).subscribe({
      next: () => this.goBack(),
    });
  }

  public initProject(response?: MainResponseModel<AgmProject>): void {
    if (response?.inError) {
      this._toastrService.error(this._translateService.instant("notification.error.unknown_object"));
      this.goBack();
    }

    if (response?.responseData.status === Constants.OBJECT_STATUS.IN_REVIEW) {
      this._toastrService.warning(this._translateService.instant("notification.error.object_in_approval"));
      this.goBack();
    }

    this._checkIfLinkToNationalTargetEnabled();
    this._getFloatActions();
    this._initPage();

    //If the page is on create mode and the entity is not pmo the default entity should be the current entity
    if (!this.id() && !AdaaHelper.isPMOEntity()) {
      this.projectForm.get("entityId")?.setValue(AdaaHelper.entity?.id);
      this.entityChanged(AdaaHelper.entity?.id);
    }

    if (response?.responseData) {
      this.projectForm.patchValue(response.responseData);

      //Set Sectors ddl
      this.projectForm.get("sectors")?.setValue(response.responseData.nationalSectors?.map((e) => e.nationalSectorId));

      //Set gov directions ddl
      this.govDirections.set(response.responseData.govDirections ?? []);
      this.subGovDirections.set(response.responseData.govSubDirections ?? []);

      //Get Entities ddl values
      this.entityChanged(response.responseData.entityId);
    }
  }

  public entityChanged(value: number | undefined): void {
    if (!value) {
      //Reset Inputs
      this.projectForm.get("owner")?.setValue(null);
      this.projectForm.get("projectCoordinator")?.setValue(null);
      this.projectForm.get("orgUnitId")?.setValue(null);

      //Reset ddl
      this.owners.set([]);
      this.orgUnits.set([]);
      if (this.enableLinkToNt) this.ntKpis.set([]);
    } else {
      this._getOwners(value);
      this._getOrgUnits(value);
      if (this.enableLinkToNt) this._getNtKpis(value);
    }
  }

  public goBack(): void {
    this._router.navigateByUrl("/console/annual-meetings-projects/projects");
  }

  public submit(isFinish: boolean): void {
    this.submitted = true;
    this.tabs.forEach((e) => (e.hasError = false));

    if (!this.projectForm.valid) {
      this.tabs[0].hasError = true;
      this._toastrService.warning(this._translateService.instant("notification.warning.missing_info"));
      return;
    }

    const { governmentDirections, govSubDirections } = this.nationalStrategies().submit();
    this.projectForm.get("govDirections")?.setValue(governmentDirections ?? []);
    this.projectForm.get("govSubDirections")?.setValue(govSubDirections ?? []);

    //On create set status as Draft
    const id = this.projectForm.get("id")?.value as number;
    if (isFinish && !id) this.projectForm.get("status")?.setValue(Constants.OBJECT_STATUS.DRAFT);

    const data = this.projectForm.getRawValue();
    if (!this._isDataValid(data, isFinish)) return;

    this._agmProjectApiService.saveProject(this._prepareDataFormSubmit(data, isFinish), isFinish).subscribe({
      next: (response) => {
        if (response.inError) return;

        this._toastrService.success(this._translateService.instant("notification.success.save"));

        isFinish
          ? this._router.navigateByUrl("/console/annual-meetings-projects/projects")
          : this.id()
            ? window.location.reload()
            : this._router.navigateByUrl(`/console/annual-meetings-projects/projects/edit/${response.responseData}`);
      },
    });
  }

  public sectorsChanged(event: number[]): void {
    const nationalSectors = this.projectForm.get("nationalSectors")?.value ?? [];

    event.forEach((id) => {
      const item = nationalSectors.find((e: { nationalSectorId: number }) => e.nationalSectorId === id);
      if (!item) {
        const ns = {
          nationalSectorId: id,
          nameEN: "",
          nameAE: "",
          status: Constants.OBJECT_STATUS.ACTIVE,
        };
        nationalSectors.push(ns);
      } else {
        item.status = Constants.OBJECT_STATUS.ACTIVE;
      }
    });

    for (let i = nationalSectors.length - 1; i >= 0; i--) {
      if (!event.includes(nationalSectors[i].nationalSectorId)) {
        if ("id" in nationalSectors[i]) {
          nationalSectors[i].status = Constants.OBJECT_STATUS.REMOVE;
        } else {
          nationalSectors.splice(i, 1);
        }
      }
    }

    this.projectForm.get("nationalSectors")?.setValue(nationalSectors);
  }

  public commentRemoved(event: AgmComment, formKey: "targetAudience" | "expectedImpact" | "comments"): void {
    let comments: AgmComment[] = this.projectForm.get(formKey)?.value ?? [];

    if (event.fakeId) {
      comments = comments.filter((e) => e.fakeId !== event.fakeId);
    } else {
      const comment = comments.find((e) => e.id === event.id);
      if (comment) comment.status = Constants.OBJECT_STATUS.REMOVE;
    }

    this.projectForm.get(formKey)?.setValue(comments);
  }

  public commentAdded(event: string, formKey: "targetAudience" | "expectedImpact" | "comments"): void {
    const currentUser = AdaaHelper.getLocalStorage(Constants.localStorageKeys.user, {
      type: "json",
    }) as LoginResponse;

    const comments: AgmComment[] = this.projectForm.get(formKey)?.value ?? [];

    const isTargetAudience = formKey === "targetAudience";
    const isExpectedImpact = formKey === "expectedImpact";
    const isComment = formKey === "comments";

    comments.push({
      id: 0,
      status: Constants.OBJECT_STATUS.ACTIVE,
      itemId: this.id() ? +this.id()! : 0,
      itemType: Constants.CONSTANT_NATIONAL_PROJECTS,
      audienceAE: isTargetAudience ? event : undefined,
      audienceEN: isTargetAudience ? event : undefined,
      impactAE: isExpectedImpact ? event : undefined,
      impactEN: isExpectedImpact ? event : undefined,
      commentsAE: isComment ? event : undefined,
      commentsEN: isComment ? event : undefined,
      creationDate: moment().utcOffset(Constants.uaeTimezone).valueOf(),
      updatedBy: currentUser.id,
      createdBy: currentUser.id,
      updateUserNameEN: currentUser.nameEN,
      updateUserNameAE: currentUser.nameAE,
      createdByUserNameEN: currentUser.nameEN,
      createdByUserNameAE: currentUser.nameAE,
      fakeId: Math.floor(Math.random() * 217 * 1000),
    });

    this.projectForm.get(formKey)?.setValue(comments);
  }

  private _getOwners(entityId: number): void {
    this._usersApiService.getOwnersPerEntity(entityId).subscribe({
      next: (response) => {
        if (response.inError) return;

        this.owners.set(
          AdaaHelper.setDropdownArray(response.responseData ?? [], "id", AdaaHelper.getFieldLanguage("name"))
        );

        const isOwnerAvailable = this.owners()?.find((e) => e.value === this.projectForm.get("owner")?.value);
        if (!isOwnerAvailable) this.projectForm.get("owner")?.setValue(null);

        const isPMAvailable = this.owners()?.find((e) => e.value === this.projectForm.get("projectCoordinator")?.value);
        if (!isPMAvailable) this.projectForm.get("projectCoordinator")?.setValue(null);
      },
    });
  }

  private _getOrgUnits(entityId: number): void {
    this._orgUnitApiService
      .getbyEntityidAndObjectype(entityId, Constants.CONSTANT_PERMISSIONS.ANNUAL_MEETINGS_PROJECTS)
      .subscribe({
        next: (response) => {
          if (response.inError) return;
          this.orgUnits.set([
            this._noneOption(),
            ...AdaaHelper.setDropdownArray(
              response.responseData ?? [],
              "id",
              AdaaHelper.getFieldLanguage("name"),
              "parentOrgUnit"
            ),
          ]);

          const isOrgUnitAvailable = response.responseData?.find(
            (e) => e.id === this.projectForm.get("orgUnitId")?.value
          );
          if (!isOrgUnitAvailable) this.projectForm.get("orgUnitId")?.setValue(null);
        },
      });
  }

  private _prepareForm() {
    return this._formBuilder.group(
      {
        id: { value: null, disabled: false },
        cycleId: { value: null, disabled: false },
        nameAE: [{ value: null, disabled: false }, Validators.required],
        nameEN: [{ value: null, disabled: false }, Validators.required],
        dscAE: [{ value: null, disabled: false }, Validators.required],
        dscEN: [{ value: null, disabled: false }, Validators.required],
        startDate: [{ value: null, disabled: false }, Validators.required],
        endDate: [{ value: null, disabled: false }, Validators.required],
        actualStartDate: { value: null, disabled: true },
        actualEndDate: { value: null, disabled: true },
        entityId: [{ value: null, disabled: !AdaaHelper.isPMOEntity() }, Validators.required],
        owner: [{ value: null, disabled: false }, Validators.required],
        projectCoordinator: [{ value: null, disabled: false }, Validators.required],
        orgUnitId: { value: null, disabled: false },
        decisions: { value: null, disabled: false },
        contributingEntities: { value: null, disabled: false },
        nationalSectors: { value: null, disabled: false },
        targetAudience: { value: null, disabled: false },
        expectedImpact: { value: null, disabled: false },
        comments: { value: null, disabled: false },
        attachments: { value: null, disabled: false },
        milestones: { value: [], disabled: false },
        govDirections: { value: null, disabled: false },
        govSubDirections: { value: null, disabled: false },
        ntKpiId: { value: null, disabled: true },
        status: { value: null, disabled: false },
        updateUser: { value: null, disabled: false },
        refCode: { value: null, disabled: false },

        //ONLY FE
        sectors: { value: null, disabled: false },
      },
      {
        validators: Validators.compose([
          dateRangeValidator("startDate", "endDate"),
          dateRangeValidator("actualStartDate", "actualEndDate"),
        ]),
      }
    );
  }

  /**
   * This method will get
   * - Validations
   * - Entities ddl data
   * - Other Entities ddl data
   * - Sectors ddl data
   * - PMO TP Decision
   * - Cycles
   */
  private _initPage(): void {
    forkJoin({
      validators: this._validatorApiService.searchByKey(Constants.VALIDATORS_CONF_KEY.VALIDATION_NATIONAL_PROJECT_FORM),
      entities: this._entitiesApiService.getAllV2(),
      otherEntities: this._otherEntitiesApiService.getAll(),
      pmoDesision: this._propertiesService.getPropById(Constants.CONSTANT_TP_PMO_DECISION),
      sectors: this._tpSectorApiService.getAll(),
      cycles: this._agmCycleApiService.getCycles(1000),
    }).subscribe({
      next: (results) => {
        if (!results.validators.inError) this.formValidation.set(results.validators.responseData);

        if (!results.entities.inError)
          this.entities.set(
            AdaaHelper.setDropdownArray(results.entities.responseData, "id", AdaaHelper.getFieldLanguage("name"))
          );

        if (!results.otherEntities.inError)
          this.otherEntities.set(
            AdaaHelper.setDropdownArray(results.otherEntities.responseData, "id", AdaaHelper.getFieldLanguage("name"))
          );

        if (!results.pmoDesision.inError)
          this.pmoDesisions.set(
            AdaaHelper.setDropdownArray(results.pmoDesision.responseData, "id", AdaaHelper.getFieldLanguage("name"))
          );

        if (!results.sectors.inError)
          this.sectors.set(
            AdaaHelper.setDropdownArray(results.sectors.responseData, "id", AdaaHelper.getFieldLanguage("name"))
          );

        if (!results.cycles.inError)
          this.cycles.set(
            AdaaHelper.setDropdownArray(results.cycles.responseData, "id", AdaaHelper.getFieldLanguage("name"))
          );
      },
    });
  }

  private _checkIfLinkToNationalTargetEnabled(): void {
    this._propertiesService.getPropById(Constants.FRONT_END_TOGGLES).subscribe({
      next: (response) => {
        if (response.inError) return;

        const linkToNt = response.responseData.find(
          (e) => e.id === Constants.DYNAMIC_LINKS_LOOKUP_ID.NATIONAL_STRATEGIES_LINK_TO_NT_ENABLED
        );

        if (linkToNt && +linkToNt.value === 1) {
          this.enableLinkToNt = true;
          this.projectForm.get("ntKpiId")?.enable();
        } else {
          this.enableLinkToNt = false;
          this.projectForm.get("ntKpiId")?.disable();
        }
      },
    });
  }

  private _getNtKpis(entityId: number): void {
    const filter: SearchFields = {
      searchFields: {
        status: {
          order: undefined,
          value: undefined,
          lov: [1],
        },
      },
    };

    this._itemApiService.getAllV2(Constants.KPI_TYPE.NTKPI, AdaaBoolean.N, filter, entityId).subscribe({
      next: (response) => {
        if (response.inError) return;
        this.ntKpis.set(AdaaHelper.setDropdownArray(response.responseData, "id", AdaaHelper.getFieldLanguage("name")));

        const ntkpi = this.projectForm.get("ntKpiId")?.value;
        if (ntkpi) {
          const index = this.ntKpis().findIndex((e) => e.value == ntkpi);
          if (index === -1) this.projectForm.get("ntKpiId")?.setValue(null);
        }
      },
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _isDataValid(data: any, isFinish: boolean): boolean {
    let isValid: boolean = true;

    if (isFinish) {
      //GOV DIRECTIONS ERRORS
      const governmentDirections = (data.governmentDirections || []).filter(
        (e: { status: number }) => e.status !== Constants.OBJECT_STATUS.REMOVE
      );
      governmentDirections.forEach((gd: { subdirections: SubGovDirection[] }) => {
        const subdirections = gd.subdirections.filter((e) => e.status !== Constants.OBJECT_STATUS.REMOVE);
        if (subdirections == null || subdirections.length == 0) {
          isValid = false;
          this._toastrService.error(
            this._translateService.instant("national_projects.messages.government_direction_error")
          );
          this.tabs[3].hasError = true;
        }
      });
    }

    //MILESTONES ERRORS
    const milestones = (data.milestones || []).filter(
      (e: { status: number }) => e.status !== Constants.OBJECT_STATUS.REMOVE
    );
    if (milestones.length > 0) {
      const totalPercentage = milestones.reduce(
        (prev: number, curr: { workload: { workload: string | number } }) => prev + (+curr.workload.workload || 0),
        0
      );

      if (totalPercentage > 0 && totalPercentage != 100) {
        isValid = false;
        this.tabs[1].hasError = true;
        this._toastrService.error(this._translateService.instant("national_projects.messages.weights_warning"));
      }

      const milestonesDateErrors = milestones.some((e: { startDate: number; endDate: number }) => {
        return e.startDate < data.startDate || e.endDate > data.endDate;
      });

      if (milestonesDateErrors) {
        this._toastrService.error(this._translateService.instant("national_projects.messages.milestone_dates_warning"));
        isValid = false;
        this.tabs[1].hasError = true;
      }
    }

    return isValid;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _prepareDataFormSubmit(data: any, isFinish: boolean): any {
    if (!data.id) delete data.id;

    if (isFinish && !data.id) delete data.status;

    if (!data.contributingEntities) data.contributingEntities = [];

    (data.milestones || []).forEach((milestone: { id: number | undefined; status: number | undefined }) => {
      if (!milestone.id) delete milestone.id;

      if (!data.id && !isFinish) delete milestone.status;
      if (
        isFinish &&
        milestone.status !== Constants.OBJECT_STATUS.REMOVE &&
        milestone.status !== Constants.OBJECT_STATUS.ACTIVE
      )
        milestone.status = Constants.OBJECT_STATUS.DRAFT;
    });

    (data.targetAudience || []).forEach(
      (e: { status: number | undefined; id: number | undefined; fakeId: number | undefined }) => {
        if (!e.id) {
          delete e.id;
          delete e.fakeId;
        }

        if (!data.id && !isFinish) delete e.status;
        if (isFinish && e.status !== Constants.OBJECT_STATUS.REMOVE && e.status !== Constants.OBJECT_STATUS.ACTIVE)
          e.status = Constants.OBJECT_STATUS.DRAFT;
      }
    );

    (data.expectedImpact || []).forEach(
      (e: { status: number | undefined; id: number | undefined; fakeId: number | undefined }) => {
        if (!e.id) {
          delete e.id;
          delete e.fakeId;
        }

        if (!data.id && !isFinish) delete e.status;
        if (isFinish && e.status !== Constants.OBJECT_STATUS.REMOVE && e.status !== Constants.OBJECT_STATUS.ACTIVE)
          e.status = Constants.OBJECT_STATUS.DRAFT;
      }
    );

    (data.comments || []).forEach(
      (e: { status: number | undefined; id: number | undefined; fakeId: number | undefined }) => {
        if (!e.id) {
          delete e.id;
          delete e.fakeId;
        }

        if (!data.id && !isFinish) delete e.status;
        if (isFinish && e.status !== Constants.OBJECT_STATUS.REMOVE && e.status !== Constants.OBJECT_STATUS.ACTIVE)
          e.status = Constants.OBJECT_STATUS.DRAFT;
      }
    );

    return data;
  }

  private _getFloatActions(): void {
    if (this.id())
      this._floatActions([
        {
          key: "workflowHistory",
          data: {
            itemId: +this.id()!,
            itemTypeId: Constants.CONSTANT_WORKFLOW.ANNUAL_PROJECT_PROJECTS,
          },
        },
        {
          key: "systemLog",
          data: {
            itemId: +this.id()!,
          },
        },
        {
          key: "helpdesk",
          data: {
            url: "/helpdesk/create",
          },
        },
      ]);
    else
      this._floatActions([
        {
          key: "print",
          data: undefined,
        },
        {
          key: "helpdesk",
          data: {
            url: "/helpdesk/create",
          },
        },
      ]);
  }
}
