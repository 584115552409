import { Component, inject, input, signal, viewChild } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";

import { AdaaHelper } from "../../../core/utils";
import {
  ConfirmationModalComponent,
  DataTableComponent,
  FormActionButtonsComponent,
  FormCheckboxComponent,
  FormInputComponent,
} from "../../../shared/components";
import { Constants } from "../../../shared/constants/constants";
import { Language, PageMode } from "../../../shared/constants/enums";
import { TableButtonClicked } from "../../../shared/models";
import { LibTexMapValue } from "../../../shared/models/text-mapping.model";
import { AppService, SystemLayoutService } from "../../../shared/services";
import { TextMappingsApiService } from "../../../shared/services/text-mapping-api.service";
import { TextMappingsEditorService } from "../../../shared/services/text-mapping-editoe.service";
import { TextMappingValueEditorComponent } from "../text-mapping-value-editor/text-mapping-value-editor.component";

@Component({
  selector: "adaa-text-mapping-editor",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    FormActionButtonsComponent,
    FormCheckboxComponent,
    FormInputComponent,
    DataTableComponent,
    TextMappingValueEditorComponent,
  ],
  templateUrl: "./text-mapping-editor.component.html",
  styleUrl: "./text-mapping-editor.component.scss",
})
export class TextMappingEditorComponent {
  valueEditor = viewChild.required<TextMappingValueEditorComponent>("editor");
  adaaDataTable = viewChild.required<DataTableComponent>("adaaDataTable");

  private _formBuilder = inject(FormBuilder);
  private _toastrService = inject(ToastrService);
  private _translateService = inject(TranslateService);
  private _router = inject(Router);
  private _modalService = inject(NgbModal);
  private _appService = inject(AppService);
  private _activatedRoute = inject(ActivatedRoute);
  private _textMappingsApiService = inject(TextMappingsApiService);
  private readonly _systemLayoutService = inject(SystemLayoutService);
  textMappingsEditorService = inject(TextMappingsEditorService);

  textMappingForm: FormGroup = new FormGroup({});
  pageMode = input.required<string>();
  valuePageMode = signal<string>(PageMode.view);

  adaaHelper = AdaaHelper;
  _subscription = new Subscription();
  textMappingId: string | null;

  constants = Constants;

  PageMode = PageMode;

  displayLabel = signal<string>("");
  setDisabled = signal<boolean>(false);
  submitted = signal<boolean>(false);
  texMapValues = signal<object[]>([]);
  texMapDeletedValues: LibTexMapValue[] = [];

  readonly #untilDestroy = AdaaHelper.untilDestroyed();

  public ngOnInit(): void {
    this.setDisabled.set(this.pageMode() === PageMode.view);
    this.textMappingId = this._activatedRoute.snapshot?.paramMap?.get("id");

    this.textMappingsEditorService.formValidator();
    if (this.textMappingId) this._getTexyMapping(+this.textMappingId);
    this._prepareForm();
    this._refreshPage();
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
  private _refreshPage() {
    this._systemLayoutService.hasActiveEntityChanged$.pipe(this.#untilDestroy()).subscribe({
      next: () => {
        this._router.navigateByUrl("/console");
      },
    });
  }
  private _prepareForm() {
    const isViewMode = this.pageMode() === PageMode.view;

    this.textMappingForm = this._formBuilder.group({
      nameAE: this._formBuilder.control<string | undefined>(
        { value: "", disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      nameEN: this._formBuilder.control<string | undefined>(
        { value: "", disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      available: this._formBuilder.control<string | undefined>({ value: "", disabled: isViewMode }),
    });

    this._subscription.add(
      this.textMappingForm.valueChanges.subscribe((value) => {
        const displayLanguage = AdaaHelper.getFieldLanguage("name");
        if (!value[displayLanguage].length) {
          this.displayLabel.set(value["nameAE"] || value["nameEN"]);
        } else this.displayLabel.set(value[displayLanguage]);
      })
    );
  }

  private _getTexyMapping(textMappingId: number) {
    if (textMappingId)
      this._textMappingsApiService.getTextMappingById(textMappingId).subscribe({
        next: (response) => {
          this.textMappingForm.patchValue({ ...response.responseData });
          this.texMapValues.set((response.responseData.libTexMapValues ?? []) as object[]);
          this.textMappingForm.markAsPristine();
          this.textMappingForm.markAsUntouched();
        },
      });
  }

  public checkInvalid(control: string) {
    return (
      this.textMappingForm.controls[control].errors?.["required"] &&
      (this.textMappingForm.get(`${control}`)?.dirty || this.submitted())
    );
  }

  public updateTextMapping() {
    this.submitted.set(true);
    if (this.textMappingForm.invalid) {
      this._toastrService.warning(this._translateService.instant("notification.warning.missing_info"));
      return;
    }
    const textMapping = this._formatOtherEntity();
    const serviceUrl =
      this.pageMode() === PageMode.edit
        ? this._textMappingsApiService.updateTextMapping(textMapping)
        : this._textMappingsApiService.createTextMapping(textMapping);

    serviceUrl.subscribe({
      next: (response) => {
        if (response.inError) return;
        this._toastrService.success(this._translateService.instant("notification.success.save"));
        this._router.navigateByUrl("/console/text-mapping");
      },
    });
  }

  private _formatOtherEntity() {
    const textMapping = this.textMappingForm.value;
    textMapping.libTexMapValues = [...this.texMapValues(), ...this.texMapDeletedValues];
    textMapping.status = Constants.OBJECT_STATUS.ACTIVE;
    if (this.pageMode() === PageMode.edit) {
      textMapping.updateTS = Date.now();
      textMapping.id = this.textMappingId;
    }
    return textMapping;
  }

  public cancelForm() {
    if (this.textMappingForm.dirty) {
      const modal = this._modalService.open(ConfirmationModalComponent, {
        centered: true,
        size: "md",
        modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
      });

      modal.componentInstance.header = "common.form.modals_leave.cancel_yes_no_title";
      modal.componentInstance.title = "common.form.modals_leave.cancel_yes_no_information";

      modal.result.then((e) => {
        if (e) this._router.navigateByUrl(`/console/text-mapping`);
      });
    } else this._router.navigateByUrl(`/console/text-mapping`);
  }

  public addNew() {
    this.valuePageMode.set(PageMode.create);
    this.valueEditor().textMappingValueForm.reset({});
    this.valueEditor().textMappingValueForm.enable();
    this.valueEditor().open();
  }

  public getAction(value: TableButtonClicked) {
    switch (value.event) {
      case "edit":
        this._handleTextMappingValueEditor(value, PageMode.edit);
        break;
      case "delete":
        this._deleteTextMappingValue(value);
        break;
      default:
        break;
    }
  }

  private _handleTextMappingValueEditor(value: TableButtonClicked, mode: string) {
    this.valuePageMode.set(mode);

    this.valueEditor().textMappingValueForm.patchValue(value.data);

    if (mode === PageMode.edit) {
      this.valueEditor().textMappingValueForm.patchValue({ id: value.data.id });
    }

    this.valueEditor().open();
  }

  private _deleteTextMappingValue(value: TableButtonClicked) {
    const modal = this._modalService.open(ConfirmationModalComponent, {
      animation: true,
      scrollable: false,
      keyboard: false,
      size: "lg",
      centered: true,
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });

    modal.componentInstance.header = "lov.value_delete_yes_no_title";
    modal.componentInstance.title = "lov.value_delete_yes_no_information";
    //change the status to be removed
    const dataToDelete = value.data;
    dataToDelete.status = Constants.OBJECT_STATUS.REMOVE;

    modal.result.then((e) => {
      if (e) {
        this.texMapValues.update((values) => {
          const indexToUpdate = values.findIndex((item) => (item as LibTexMapValue).id === value.data.id);
          values.splice(indexToUpdate, 1);
          //add it to deleted array if old value
          if (dataToDelete.id) this.texMapDeletedValues.push(dataToDelete);
          return [...values];
        });
        this.adaaDataTable().loadTableData();
      }
    });
  }

  public submit(data: LibTexMapValue) {
    if (this.valuePageMode() === PageMode.create) {
      this.texMapValues.update((values) => {
        return [...values, data];
      });
    } else {
      this.texMapValues.update((values) => {
        const indexToUpdate = values.findIndex((item) => (item as LibTexMapValue).id === data.id);
        values[indexToUpdate] = data;
        return [...values];
      });
    }

    this._modalService.dismissAll();
    this.adaaDataTable().loadTableData();
  }
}
