<adaa-data-table
    #nsTable
    titleHeader="national_strategies.strategies"
    configKey="national_strategies_conf_list"
    apiName="getAllNationalStrategies"
    [itemTypeId]="constants.CONSTANT_PERMISSIONS.GOVERNMENT_DIRECTIONS"
    [tableWithChildren]="true"
    childKey="subGovDirections"
    (actionClicked)="actionClicked($event)"
    (addNewClicked)="addNewClicked()"
></adaa-data-table>
