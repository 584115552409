import { NgClass } from "@angular/common";
import { Component, computed, inject, input, OnInit, signal } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { forkJoin } from "rxjs";

import { AdaaHelper } from "../../../core/utils";
import {
  ConfirmationModalComponent,
  DataTableComponent,
  FormCheckboxComponent,
  FormDropdownComponent,
  FormInputComponent,
  FormInputDateComponent,
  FormStepsComponent,
  ObjectiveEnablerModalComponent,
} from "../../../shared/components";
import { Constants } from "../../../shared/constants/constants";
import { ActionsEvents, AdaaBoolean, PageMode } from "../../../shared/constants/enums";
import {
  FormTab,
  ParameterCatalog,
  SearchFields,
  SubGovDirection,
  TableButtonClicked,
  ValueText,
} from "../../../shared/models";
import {
  EntitiesApiService,
  GovDirectionApiService,
  ItemApiService,
  LanguageService,
  PropertiesService,
  UserGroupsApiService,
  UsersApiService,
  ValidatorApiService,
} from "../../../shared/services";

@Component({
  selector: "adaa-national-strategy-editor",
  standalone: true,
  imports: [
    TranslateModule,
    ReactiveFormsModule,
    NgClass,
    DataTableComponent,
    FormStepsComponent,
    FormInputComponent,
    FormInputDateComponent,
    FormDropdownComponent,
    FormCheckboxComponent,
  ],
  templateUrl: "./national-strategy-editor.component.html",
  styleUrl: "./national-strategy-editor.component.scss",
})
export class NationalStrategyEditorComponent implements OnInit {
  private _translateService = inject(TranslateService);
  private _toastrService = inject(ToastrService);
  private _router = inject(Router);
  private _formBuilder = inject(FormBuilder);
  private _modalService = inject(NgbModal);
  private _languageService = inject(LanguageService);
  private _govDirectionApiService = inject(GovDirectionApiService);
  private _validatorApiService = inject(ValidatorApiService);
  private _entitiesApiService = inject(EntitiesApiService);
  private _usersApiService = inject(UsersApiService);
  private _userGroupsApiService = inject(UserGroupsApiService);
  private _propertiesService = inject(PropertiesService);
  private _itemApiService = inject(ItemApiService);

  id = input<number | undefined>();
  pageMode = input.required<PageMode>();

  nsForm: FormGroup;
  readonly isFieldRequired = AdaaHelper.isFieldRequired.bind(AdaaHelper);
  readonly PageMode = PageMode;
  validations = signal<ParameterCatalog[]>([]);
  validationsSubDirection = signal<ParameterCatalog[]>([]);
  activeTab = signal<number>(0);
  entities = signal<ValueText[]>([]);
  owners = signal<ValueText[]>([]);
  strategyOwnerGroups = signal<ValueText[]>([]);
  ntKpis = signal<ValueText[]>([]);
  subDirections: SubGovDirection[] = [];
  filteredSubDirections: SubGovDirection[] = [];
  enableLinkToNt: boolean = false;
  submitted: boolean = false;
  tabs: FormTab[] = [
    {
      title: "common.form.label.information",
    },
    {
      title: "national_strategies.obj_enabler",
    },
    {
      title: "common.links",
    },
  ];

  showSave = computed<boolean>(() => {
    return (
      this.pageMode() === PageMode.create ||
      (this.pageMode() === PageMode.edit && this.nsForm.get("status")?.value === Constants.OBJECT_STATUS.DRAFT)
    );
  });

  showFinish = computed<boolean>(() => {
    return this.pageMode() !== PageMode.view;
  });

  public get displayLabel(): string {
    const nameEN = this.nsForm.get("nameEN")?.value;
    const nameAE = this.nsForm.get("nameAE")?.value;

    return AdaaHelper.getPageLabel(nameAE, nameEN);
  }

  public ngOnInit(): void {
    this.nsForm = this._prepareForm();

    this._checkIfLinkToNationalTargetEnabled();
    this._getValidations();
    this._getEntities();
  }

  public entityIdChanged(value: number): void {
    if (value) {
      this._getOwners(value);
      this._getStrategyOwnerGroups(value);
      if (this.enableLinkToNt) this._getNtKpis(value);
    } else {
      this.owners.set([]);
      this.strategyOwnerGroups.set([]);
      if (this.enableLinkToNt) this.ntKpis.set([]);
    }
  }

  public addObjectiveEnabler(): void {
    const subDirection: SubGovDirection = {
      govDirectionId: undefined,
      isMain: false,
      refCode: undefined,
      nameAE: "",
      nameEN: "",
      id: 0,
      owner: undefined,
      status: Constants.OBJECT_STATUS.ACTIVE,
    };

    this._openObjectiveEnablerModal(subDirection, PageMode.create);
  }

  public manageObjectiveEnabler({ event, data }: TableButtonClicked): void {
    switch (event) {
      case ActionsEvents.edit: {
        const subDirection = this.subDirections.find((e) =>
          data.id ? e.id === data.id : data.fakeId ? e.fakeId === data.fakeId : false
        );
        if (subDirection) this._openObjectiveEnablerModal(subDirection, PageMode.edit);
        break;
      }
      case ActionsEvents.delete: {
        this._deleteObjectiveEnablerConfirmation(data);
        break;
      }
      default:
        break;
    }
  }

  public goBack(): void {
    this._router.navigateByUrl("/console/national-strategies");
  }

  public save(isFinish: boolean): void {
    this.submitted = true;
    if (!this.nsForm.valid) {
      this._toastrService.warning(this._translateService.instant("notification.warning.missing_info"));
      return;
    }

    const data = this.nsForm.getRawValue();

    if (this.enableLinkToNt && data["ntKpiId"]) {
      data["ntKpi"] = {
        id: data.ntKpiId,
      };
      delete data.ntKpiId;
    }
    if (this.pageMode() === PageMode.create) {
      delete data.id;
      delete data.refCode;
    }
    if (isFinish && data["subdirections"]) {
      (data["subdirections"] as SubGovDirection[]).forEach((e) => {
        if (e.status === Constants.OBJECT_STATUS.DRAFT) e.status = Constants.OBJECT_STATUS.ACTIVE;
      });
    }

    this._govDirectionApiService
      .saveDirection(this.pageMode() === PageMode.create ? "create" : "update", data, isFinish)
      .subscribe({
        next: (response) => {
          if (response.inError) return;

          this._toastrService.success(this._translateService.instant("notification.success.save"));
          isFinish
            ? this._router.navigateByUrl("/console/national-strategies")
            : this._router.navigateByUrl(`/console/national-strategies/edit/${response.responseData}`);
        },
      });
  }

  private _getEntities() {
    this._entitiesApiService.getAllV2().subscribe({
      next: (response) => {
        if (response.inError) return;

        const entities = AdaaHelper.setDropdownArray(response.responseData, "id", AdaaHelper.getFieldLanguage("name"));
        entities.unshift({ value: 0, text: this._translateService.instant("common.form.label.none") });
        this.entities.set(entities);
      },
    });
  }

  private _getStrategyOwnerGroups(entityId: number) {
    this._userGroupsApiService.getall(entityId).subscribe({
      next: (response) => {
        if (response.inError) return;

        const groups = AdaaHelper.setDropdownArray(response.responseData, "id", AdaaHelper.getFieldLanguage("name"));
        groups.unshift({ value: 0, text: this._translateService.instant("common.form.label.none") });
        this.strategyOwnerGroups.set(groups);

        const group = this.nsForm.get("strategyOwnerGroup")?.value;
        if (group) {
          const index = this.strategyOwnerGroups().findIndex((e) => e.value == group);
          if (index === -1) this.nsForm.get("strategyOwnerGroup")?.setValue(0);
        }
      },
    });
  }

  private _getOwners(entityId: number) {
    this._usersApiService.getOwnersPerEntity(entityId).subscribe({
      next: (response) => {
        if (response.inError) return;

        const owners = AdaaHelper.setDropdownArray(response.responseData, "id", AdaaHelper.getFieldLanguage("name"));
        owners.unshift({ value: 0, text: this._translateService.instant("common.form.label.none") });
        this.owners.set(owners);

        const owner = this.nsForm.get("owner")?.value;
        if (owner) {
          const index = this.owners().findIndex((e) => e.value == owner);
          if (index === -1) this.nsForm.get("owner")?.setValue(0);
        }
      },
    });
  }

  private _getNationalStrategy(): void {
    if (!AdaaHelper.isDefined(this.id())) return;

    this._govDirectionApiService.getDirection(this.id()!).subscribe({
      next: (response) => {
        if (response.inError) {
          this._toastrService.error(this._translateService.instant("notification.error.unknown_object"));
          this.goBack();
        }

        this.subDirections = response.responseData.subdirections ?? [];

        this.nsForm.patchValue(response.responseData);
        this.nsForm.get("ntKpiId")?.setValue(response.responseData.ntKpi?.id);

        this._setSubDirections();
      },
    });
  }

  private _getValidations(): void {
    forkJoin({
      nationalStrategyForm: this._validatorApiService.searchByKey(
        Constants.VALIDATORS_CONF_KEY.VALIDATION_NATIONAL_STRATEGY
      ),
      subDirectionForm: this._validatorApiService.searchByKey(
        Constants.VALIDATORS_CONF_KEY.VALIDATION_GOVERNMENT_SUB_DIRECTIONS
      ),
    }).subscribe({
      next: (results) => {
        this.validations.set(results.nationalStrategyForm.responseData.parameterCatalogs);
        this.validationsSubDirection.set(results.subDirectionForm.responseData.parameterCatalogs);
      },
    });
  }

  private _prepareForm() {
    const isViewMode = this.pageMode() === PageMode.view;

    return this._formBuilder.group({
      id: { value: null, disabled: isViewMode },
      refCode: { value: null, disabled: true },
      nameAE: { value: null, disabled: isViewMode },
      nameEN: { value: null, disabled: isViewMode },
      dscAE: { value: null, disabled: isViewMode },
      dscEN: { value: null, disabled: isViewMode },
      startDate: { value: null, disabled: isViewMode },
      endDate: { value: null, disabled: isViewMode },
      entityId: { value: 0, disabled: isViewMode },
      owner: { value: 0, disabled: isViewMode },
      strategyOwnerGroup: { value: 0, disabled: isViewMode },
      available: { value: AdaaBoolean.N, disabled: isViewMode },
      isNationalStrategy: true,
      subdirections: { value: null, disabled: isViewMode },
      ntKpiId: { value: null, disabled: true },
    });
  }

  private _openObjectiveEnablerModal(subDirection: SubGovDirection, pageMode: PageMode) {
    const modal = this._modalService.open(ObjectiveEnablerModalComponent, {
      centered: true,
      size: "xl",
      modalDialogClass: this._languageService.modalDirection(),
    });

    modal.componentInstance.subDirection = subDirection;
    modal.componentInstance.pageMode = pageMode;
    modal.componentInstance.validations = this.validationsSubDirection();
    modal.componentInstance.ownerGroups = this.strategyOwnerGroups();

    modal.result.then((event) => {
      if (!AdaaHelper.isDefined(event)) return;
      if (this._checkForDuplicateName(event)) {
        this._toastrService.error(this._translateService.instant("directions.sub_name_exists"));
        return;
      }

      const subDirectionIndex = this.subDirections.findIndex((e) =>
        event.id ? event.id === e.id : event.fakeId ? event.fakeId === e.fakeId : false
      );

      if (subDirectionIndex > -1) {
        this.subDirections.splice(subDirectionIndex, 1, event);
      } else {
        delete event.id;
        event.fakeId = Math.floor(Math.random() * 217 * 1000);
        this.subDirections.push(event);
      }

      this._setSubDirections();
    });
  }

  private _checkForDuplicateName({ nameAE, nameEN, fakeId, id }: SubGovDirection): boolean {
    if (id) {
      return this.subDirections.findIndex((e) => (e.nameAE === nameAE || e.nameEN === nameEN) && e.id !== id) > -1;
    } else if (fakeId) {
      return (
        this.subDirections.findIndex((e) => (e.nameAE === nameAE || e.nameEN === nameEN) && e.fakeId !== fakeId) > -1
      );
    }
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _deleteObjectiveEnablerConfirmation(data: any): void {
    const modal = this._modalService.open(ConfirmationModalComponent, {
      centered: true,
      size: "md",
      modalDialogClass: this._languageService.modalDirection(),
    });

    modal.componentInstance.header = "national_strategies.enabler_objectives.delete_eo";
    modal.componentInstance.title = "national_strategies.enabler_objectives.delete_eo_message";

    modal.result.then((e) => {
      if (e) this._deleteObjectiveEnablerConfirmed(data);
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _deleteObjectiveEnablerConfirmed(data: any): void {
    if (data.id) {
      const subDirection = this.subDirections.find((e) => e.id === data.id);
      if (subDirection) subDirection.status = Constants.OBJECT_STATUS.REMOVE;
    } else if (data.fakeId) {
      const subDirectionsIndex = this.subDirections.findIndex((e) => e.fakeId === data.fakeId);
      if (subDirectionsIndex > -1) this.subDirections.splice(subDirectionsIndex, 1);
    }

    this._setSubDirections();
  }

  private _setSubDirections(): void {
    this.filteredSubDirections = AdaaHelper.clone(
      this.subDirections.filter((e) => e.status !== Constants.OBJECT_STATUS.REMOVE)
    );

    this.nsForm.get("subdirections")?.setValue(this.subDirections);
  }

  private _checkIfLinkToNationalTargetEnabled(): void {
    this._propertiesService.getPropById(Constants.FRONT_END_TOGGLES).subscribe({
      next: (response) => {
        if (response.inError) return;

        const linkToNt = response.responseData.find(
          (e) => e.id === Constants.DYNAMIC_LINKS_LOOKUP_ID.NATIONAL_STRATEGIES_LINK_TO_NT_ENABLED
        );

        if (linkToNt && +linkToNt.value === 1) {
          this.enableLinkToNt = true;
          this.nsForm.get("ntKpiId")?.enable();
        } else {
          this.enableLinkToNt = false;
          this.nsForm.get("ntKpiId")?.disable();
        }
      },
      complete: () => {
        if (this.pageMode() !== PageMode.create) this._getNationalStrategy();
      },
    });
  }

  private _getNtKpis(entityId: number): void {
    const filter: SearchFields = {
      searchFields: {
        status: {
          order: undefined,
          value: undefined,
          lov: [1],
        },
      },
    };

    this._itemApiService.getAllV2(Constants.KPI_TYPE.NTKPI, AdaaBoolean.N, filter, entityId).subscribe({
      next: (response) => {
        if (response.inError) return;
        this.ntKpis.set(AdaaHelper.setDropdownArray(response.responseData, "id", AdaaHelper.getFieldLanguage("name")));

        const ntkpi = this.nsForm.get("ntKpiId")?.value;
        if (ntkpi) {
          const index = this.ntKpis().findIndex((e) => e.value == ntkpi);
          if (index === -1) this.nsForm.get("ntKpiId")?.setValue(null);
        }
      },
    });
  }
}
