import { Component, inject, viewChild } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

import { AdaaHelper } from "../../../core/utils";
import { DataTableComponent, FloatActionComponent } from "../../../shared/components";
import { genericFloatButtons } from "../../../shared/components/general/float-action";
import { Constants } from "../../../shared/constants/constants";
import { TableButtonClicked } from "../../../shared/models";
import { SystemLayoutService } from "../../../shared/services";

@Component({
  selector: "adaa-mandatory-fields-list",
  standalone: true,
  imports: [DataTableComponent, TranslateModule, FloatActionComponent],
  templateUrl: "./mandatory-fields-list.component.html",
  styleUrl: "./mandatory-fields-list.component.scss",
})
export class MandatoryFieldsListComponent {
  private _router = inject(Router);
  private readonly _systemLayoutService = inject(SystemLayoutService);

  readonly #untilDestroy = AdaaHelper.untilDestroyed();

  adaaDataTable = viewChild.required<DataTableComponent>("adaaDataTable");
  constants = Constants;
  readonly #floatActions = genericFloatButtons();

  public ngOnInit() {
    this.#floatActions([
      {
        key: "print",
        data: undefined,
      },
      {
        key: "helpdesk",
        data: {
          url: "/helpdesk/create",
        },
      },
    ]);
    this._systemLayoutService.hasActiveEntityChanged$.pipe(this.#untilDestroy()).subscribe({
      next: () => {
        this._router.navigateByUrl(`/console`);
      },
    });
  }
  public getAction(event: TableButtonClicked) {
    const searchKey = event.data.searchKey;
    switch (event.event) {
      case "edit":
        this._router.navigateByUrl(`/console/mandatory/edit/${searchKey}`);
        break;

      default:
        break;
    }
  }
}
