import { inject, Injectable } from "@angular/core";

import { AdaaBooleanType } from "../../../adaa-types";
import { AdaaBoolean } from "../constants/enums";
import { MainResponseModel } from "../models";
import { TextMapModelType } from "../models/text-mapping.model";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class TextMappingsApiService {
  private _mainService = inject(MainService);

  public getTextMappingById(tpSectorId: number) {
    const url = `textmap/getbyid/${tpSectorId}`;

    return this._mainService.sendRequest<MainResponseModel<TextMapModelType>>({
      method: "GET",
      url,
    });
  }

  public createTextMapping(tpCycle: Partial<TextMapModelType>) {
    const url = `textmap/create`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "POST",
      url,
      data: tpCycle,
    });
  }

  public updateTextMapping(data: Partial<TextMapModelType>) {
    const url = `textmap/update`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "POST",
      url,
      data,
    });
  }

  public deleteTextMapping(tpSectorId: number) {
    const url = `textmap/deleteById/${tpSectorId}`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "DELETE",
      url,
    });
  }

  public getAllByAvailability(isAvailable: AdaaBooleanType = AdaaBoolean.Y) {
    const url = `textmap/getAllByAvailability/${isAvailable}`;

    return this._mainService.sendRequest<MainResponseModel<TextMapModelType[]>>({
      method: "POST",
      url,
      data: {},
    });
  }
}
