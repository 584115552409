@let milestone = data();
@let sliderConf = sliderOption();
@let entityName = milestone | translateToken: "entityName";

<ng-template #plannedDatesContent>
    @let tooltipContent = plannedTimelineTooltip();

    <div class="text-white">
        {{ tooltipContent.start?.title }}: <span class="fw-bold text-primary mx-1">{{ tooltipContent.start?.date }}</span>
    </div>

    <div class="text-white">
        {{ tooltipContent.end?.title }}: <span class="fw-bold text-primary mx-1">{{ tooltipContent.end?.date }}</span>
    </div>
</ng-template>

<ng-template #actualDatesContent>
    @let tooltipContent = actualTimelineTooltip();

    <div class="text-white">
        {{ tooltipContent.start?.title }}: <span class="fw-bold text-primary mx-1">{{ tooltipContent.start?.date }}</span>
    </div>

    <div class="text-white">
        {{ tooltipContent.end?.title }}: <span class="fw-bold text-primary mx-1">{{ tooltipContent.end?.date }}</span>
    </div>
</ng-template>

<div class="row mb-3">
    <div
        class="d-flex flex-nowrap align-items-center align-content-center"
        [ngClass]="{
            'col-md-2 col-sm-4 col-xs-4': isDefinedAndNotEmpty(entityName),
            'col-md-3 col-sm-5 col-xs-5': !isDefinedAndNotEmpty(entityName),
        }"
    >
        <span class="badge rounded-pill bg-primary text-white shadow-sm mx-1">{{ index() }} </span>

        <span class="pointer fw-bold mx-1" [routerLink]="['/national-projects-dashboard/milestones', milestone.id]">
            {{ milestone | translateToken: "name" }}
        </span>
    </div>

    <div class="col-md-2 col-sm-4 col-xs-4 d-flex justify-content-center flex-wrap">
        <adaa-doughnut [amount]="milestone.progress" [colorId]="colorId" [size]="'xxs'" [title]="'common.tables.progress' | translate" />
        <span
            class="d-flex justify-content-center align-items-start align-content-start w-100 position-relative fw-bold"
            style="font-size: 12px; top: -20px"
        >
            {{ "common.tables.progress" | translate }}
        </span>
    </div>

    @if (isDefinedAndNotEmpty(entityName)) {
        <div class="col-md-2 col-sm-4 col-xs-4 d-flex flex-wrap align-items-center">
            <h6>{{ milestone | translateToken: "entityName" }}</h6>
        </div>
    }

    @if (sliderConf) {
        <div
            [ngClass]="{
                'col-md-6 col-xs-12': isDefinedAndNotEmpty(entityName),
                'col-md-7 col-xs-12': !isDefinedAndNotEmpty(entityName),
            }"
        >
            <div class="row">
                <div class="col-md-10 col-sm-12 col-xs-12">
                    <ngx-slider
                        class="my-5 planned"
                        [class.noStart]="!milestone.startDate"
                        [class.noEnd]="!milestone.endDate"
                        [options]="sliderConf"
                        [value]="milestone.startDate"
                        [highValue]="milestone.endDate"
                        [ngbTooltip]="plannedDatesContent"
                    />

                    <ngx-slider
                        class="my-5 actual"
                        [class.noStart]="!milestone.actualStartDate"
                        [class.noEnd]="!milestone.actualEndDate"
                        [options]="sliderConf"
                        [value]="milestone.actualStartDate ?? 0"
                        [highValue]="milestone.actualEndDate ?? 0"
                        [ngbTooltip]="actualDatesContent"
                    />
                </div>

                <!-- view team members -->
                <div class="col-2">
                    <button
                        class="btn btn-rounded btn-outline-primary rounded-pill"
                        [title]="'national_projects.project_team_members.popup_title' | translate"
                    >
                        <i class="fa-solid fa-users"></i>
                    </button>
                </div>
            </div>
        </div>
    }
</div>
