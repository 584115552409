@if (systemLayoutService.isReady()) {
    <adaa-period-slider [activePeriod]="selectedPeriod()!" type="quarter" (periodSelect)="selectPeriod($event)"></adaa-period-slider>
}

@if (selectedPeriod(); as period) {
    <adaa-aggregated-performance [selectedPeriod]="period" [isPmo]="false">
        @if (hasKPIsWithIssue()) {
            <i
                class="fa fa-warning text-warning pointer"
                routerLink="/console/kpi/audit-issues"
                [title]="'landingPage.entity_page.kpi_issues' | translate"
            ></i>
        }
        {{ "landingPage.entity_page.performance_on" | translate }}
        <b>{{ aggregationTypeCardTitle() }}</b>
    </adaa-aggregated-performance>

    @if (overallAggregationPerf(); as overallPerf) {
        @if (overallPerf.enabled) {
            <section class="position-relative d-block w-100 bg-white data-section">
                <div class="title">
                    <h4 class="fw-bold table-title" style="display: inline">
                        {{ "landingPage.entity_page.entity_overall_performance" | translate }}
                    </h4>
                </div>
                <adaa-entity-overall-performance
                    [entityId]="entity()!.id"
                    [periodId]="period.period.id"
                    [aggregationType]="overallPerf.aggregationType!"
                />
            </section>
        }
    }
}

<section class="d-block w-100 position-relative mt-3 mb-2 pt-4 pb-1 bg-transparent data-section shadow-none">
    <adaa-data-table
        apiPrefix="item"
        apiName="getAllV2"
        [titleHeader]="'breadcrumbs.objectives.objectives' | translate"
        [configKey]="objectivesTableConf()"
        [paramsString]="objectivesParamString()"
        [enablePrint]="true"
        [enableAdd]="false"
        [enableEditAction]="false"
        [enableDeleteAction]="false"
        (actionClicked)="goToViewObjective($event.data)"
    />
</section>

@if (!isExternalEntity()) {
    <section class="position-relative d-block w-100 shadow-none data-section mt-4 mb-3">
        <div class="title">
            <h4 class="fw-bold table-title" style="display: inline">{{ "landingPage.entity_page.government_enabler_pillars" | translate }}</h4>
        </div>

        @if (selectedPeriod()) {
            <adaa-government-enabler-pillars [period]="selectedPeriod()!" />
        } @else {
            <div class="container w-100 d-flex justify-content-center py-3">
                <div class="zsk__loader zsk__loader--sm"></div>
            </div>
        }
    </section>
}

@if (!useNewDS()) {
    <section class="d-block w-100 position-relative mt-3 mb-2 pt-4 pb-1 bg-transparent data-section shadow-none">
        <adaa-data-table
            apiName="getInitiativeByIdAndPriorityV2"
            [paramsString]="highPriorityParamString()"
            [titleHeader]="'landingPage.highpriority.title' | translate"
            [configKey]="highPriorityInitiativesConf()"
            [enablePrint]="true"
            [enableAdd]="false"
            [enableEditAction]="false"
            [enableDeleteAction]="false"
            (actionClicked)="goToInitiatives($event.data)"
        ></adaa-data-table>
    </section>
}

<section class="d-block w-100 position-relative mt-3 mb-2 pt-4 pb-1 bg-transparent data-section shadow-none">
    <adaa-data-table
        apiPrefix="favorite"
        [apiName]="favoriteApiPath()"
        [titleHeader]="'landingPage.favorites.title' | translate"
        [configKey]="favoritesTableConf"
        [enablePrint]="true"
        [enableAdd]="false"
        [enableEditAction]="false"
        [enableDeleteAction]="false"
    ></adaa-data-table>
</section>

<adaa-float-action />
