import { Component, inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

import { AuthLayoutComponent, FormInputComponent } from "../../../shared/components";
import { LoginRequest, MainResponseModel } from "../../../shared/models";
import { LoginService, RecaptchaService } from "../../../shared/services";

@Component({
  selector: "adaa-forget-password",
  standalone: true,
  templateUrl: "./forget-password.component.html",
  styleUrl: "./forget-password.component.scss",
  imports: [TranslateModule, ReactiveFormsModule, RouterModule, FormInputComponent, AuthLayoutComponent],
})
export class ForgetPasswordComponent implements OnInit {
  private _formBuilder = inject(FormBuilder);
  private _recaptchaService = inject(RecaptchaService);
  private _loginService = inject(LoginService);

  forgetPasswordForm: FormGroup;
  resetDone: boolean = false;

  public ngOnInit(): void {
    this._recaptchaService.appendReCAPTCHA();

    this._prepareForm();
  }

  public submit(): void {
    this._recaptchaService.getToken("login", (token) => {
      this.forgetPasswordForm.get("captchaToken")?.setValue(token);

      if (!this.forgetPasswordForm.valid) return;

      this._loginService
        .resetPassword(this.forgetPasswordForm.value as LoginRequest)
        .subscribe((response: MainResponseModel) => {
          if (response.responseCode === "1") this.resetDone = true;
        });
    });
  }

  /**
   * @description To prepare the login form, and to get the token from
   * google captcha.
   */
  private _prepareForm(): void {
    this.forgetPasswordForm = this._formBuilder.group({
      captchaToken: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      token: [""],
      uuid: [""],
    });
  }
}
