import { Component, inject, input, output, signal, TemplateRef, viewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";

import { Constants } from "../../../constants/constants";
import { Language } from "../../../constants/enums";
import { TableSelectedRow } from "../../../models";
import { UserProfile } from "../../../models/user-profile.model";
import { AppService, UserGroupsApiService } from "../../../services";
import { FormActionButtonsComponent } from "../../form";
import { DataTableComponent } from "../../table/data-table/data-table.component";

@Component({
  selector: "adaa-users-list-selection",
  standalone: true,
  imports: [DataTableComponent, TranslateModule, FormActionButtonsComponent],
  templateUrl: "./users-list-selection.component.html",
  styleUrl: "./users-list-selection.component.scss",
})
export class UsersListSelectionComponent {
  private _modalService = inject(NgbModal);
  private _appService = inject(AppService);
  private _userGroupsApiService = inject(UserGroupsApiService);

  tableConfList = signal<string>(Constants.MAIN_TABLE_LIST_CONF_KEY.USER_GROUP_USER_SELECTION);
  modal = viewChild.required<TemplateRef<unknown>>("content");
  users = signal<UserProfile[]>([]);
  selectedUsers = signal<UserProfile[]>([]);
  savedUsers = input<UserProfile[]>([]);
  submitSavedUsers = output<UserProfile[]>();

  public open() {
    this._getUsers();
    this._modalService.open(this.modal(), {
      animation: true,
      scrollable: false,
      keyboard: false,
      size: "lg",
      centered: true,
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });
  }

  private _getUsers() {
    this._userGroupsApiService.getallForGroupsByEntity().subscribe({
      next: (response) => this.users.set(response.responseData),
    });
  }

  public setTableSelection(event: TableSelectedRow) {
    this.selectedUsers.update(() => {
      return [...event.allSelectedRows];
    });
  }

  public submit() {
    this.submitSavedUsers.emit(this.selectedUsers());
    this._modalService.dismissAll();
  }
}
