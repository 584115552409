import { hasPermissionGuard } from "../../../core/guards";
import { Constants } from "../../../shared/constants/constants";
import { PermissionAction } from "../../../shared/constants/enums";

export const viewKpiActualsPageGuards = [
  hasPermissionGuard({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.view,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.DATA_ENTRY,
        },
      ],
    },
    redirectTo: "/403",
  }),
];

export const manageKpiActualsPageGuards = [
  hasPermissionGuard({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.manage,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.DATA_ENTRY,
        },
      ],
    },
    redirectTo: "/403",
  }),
];
