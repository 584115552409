<div class="p-2">
    <adaa-data-table
        #adaaDataTable
        apiName="getAll"
        [titleHeader]="'user_groups.title' | translate"
        [apiPrefix]="'usergroups'"
        [configKey]="Constants.MAIN_TABLE_LIST_CONF_KEY.USERS_GROUPS"
        [enableAdd]="false"
        [enableEditAction]="true"
        [enableDeleteAction]="true"
        [enableViewAction]="true"
        [customActionButtons]="customButtons"
        [enableAdd]="true"
        [itemTypeId]="Constants.CONSTANT_PERMISSIONS.USER_GROUPS"
        (addNewClicked)="addNewClicked()"
        (actionClicked)="getAction($event)"
    ></adaa-data-table>
</div>
