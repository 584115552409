<div class="p-2">
    <adaa-data-table
        #adaaDataTable
        apiName="getAll"
        titleHeader="user_roles.title"
        apiPrefix="userroles"
        [configKey]="Constants.MAIN_TABLE_LIST_CONF_KEY.USERS_ROLES"
        [enableEditAction]="true"
        [enableDeleteAction]="true"
        [enableViewAction]="true"
        [customActionButtons]="customButtons"
        [enableAdd]="true"
        [itemTypeId]="Constants.CONSTANT_PERMISSIONS.USER_ROLES"
        (addNewClicked)="addNew()"
        (actionClicked)="getAction($event)"
    ></adaa-data-table>
</div>

<adaa-float-action />
