import { Component, EventEmitter, inject, input, OnInit, Output, signal } from "@angular/core";
import { ControlContainer, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { filter, forkJoin } from "rxjs";
import { tap } from "rxjs/operators";

import { AdaaHelper } from "../../../../../../../core/utils";
import { FormDropdownComponent } from "../../../../../../../shared/components";
import { ServiceModel, ValueText } from "../../../../../../../shared/models";
import { ServicesApiService } from "../../../../../../../shared/services";

export type ServicesFieldOnChangeType = {
  value: number;
  field: "mainService" | "subService";
  services: ServiceModel[];
  subServices: ServiceModel[];
};

@Component({
  selector: "adaa-services-field",
  standalone: true,
  imports: [FormDropdownComponent, ReactiveFormsModule, TranslateModule],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true }),
    },
  ],
  template: `
    <section class="d-flex row">
      <div class="col-sm-6 col-xs-12">
        <adaa-form-dropdown
          [controlName]="FIELD_NAMES.mainService"
          [formControlName]="FIELD_NAMES.mainService"
          [clearable]="true"
          [searchable]="true"
          [label]="'srvkpi.service' | translate"
          [options]="servicesOptions()"
          [invalid]="model.get(FIELD_NAMES.mainService)?.touched! && model.get(FIELD_NAMES.mainService)?.invalid!"
          [required]="isRequired()"
        />
      </div>

      <div class="col-sm-6 col-xs-12">
        <adaa-form-dropdown
          [controlName]="FIELD_NAMES.subService"
          [formControlName]="FIELD_NAMES.subService"
          [clearable]="true"
          [searchable]="true"
          [label]="'srvkpi.auxVarService' | translate"
          [options]="subServicesOptions()"
          [invalid]="model.get(FIELD_NAMES.subService)?.touched! && model.get(FIELD_NAMES.subService)?.invalid!"
          [required]="isRequired()"
        />
      </div>
    </section>
  `,
})
export class ServicesFieldComponent implements OnInit {
  readonly form = inject(ControlContainer);

  private readonly _serviceApiService = inject(ServicesApiService);

  isRequired = input.required<boolean>();
  isDisabled = input<boolean>(false);

  servicesOptions = signal<ValueText[]>([]);
  subServicesOptions = signal<ValueText[]>([]);
  services = signal<ServiceModel[]>([]);
  subServices = signal<ServiceModel[]>([]);

  readonly FIELD_NAMES = {
    mainService: "mainServiceId",
    subService: "serviceId",
    objective: "objectiveId",
  };

  readonly #untilDestroy = AdaaHelper.untilDestroyed();

  @Output() onChange = new EventEmitter<ServicesFieldOnChangeType>();

  public get model() {
    return this.form.control as FormGroup;
  }

  public ngOnInit() {
    this._queryServices();
    this._handleObjectiveChanges();
    this._handleMainServiceChanges();
    this._handleSubServiceChanges();
  }

  private _handleObjectiveChanges() {
    this.model.controls[this.FIELD_NAMES.objective]?.valueChanges
      ?.pipe(
        this.#untilDestroy(),
        tap({
          next: (value: number | null) => {
            if (!value) {
              this.servicesOptions.set(
                AdaaHelper.setDropdownArray(this.services(), "id", AdaaHelper.getFieldLanguage("name"))
              );
              this.model?.controls[this.FIELD_NAMES.mainService]?.reset();

              this.subServicesOptions.set(
                AdaaHelper.setDropdownArray(this.subServices(), "id", AdaaHelper.getFieldLanguage("name"))
              );
              this.model?.controls[this.FIELD_NAMES.subService]?.reset();
            }
          },
        }),
        filter((value) => AdaaHelper.isDefined(value))
      )
      .subscribe({
        next: (value) => this._updateServiceFields(value),
      });
  }

  private _handleMainServiceChanges() {
    this.model?.controls[this.FIELD_NAMES.mainService]?.valueChanges
      .pipe(
        this.#untilDestroy(),
        tap({
          next: (value: number | null) => this._updateSubServiceField(value),
        }),
        filter((value) => AdaaHelper.isDefined(value))
      )
      .subscribe({
        next: (value: number) => {
          this.onChange.emit({
            field: "mainService",
            services: this.services(),
            subServices: this.subServices(),
            value,
          });
        },
      });
  }

  private _handleSubServiceChanges() {
    this.model?.controls[this.FIELD_NAMES.subService]?.valueChanges
      .pipe(
        this.#untilDestroy(),
        filter((value) => AdaaHelper.isDefined(value)),
        tap({
          next: (value) => this._updateMainServiceField(value),
        })
      )
      .subscribe({
        next: (value: number) => {
          this.onChange.emit({
            field: "subService",
            services: this.services(),
            subServices: this.subServices(),
            value,
          });
        },
      });
  }

  private _updateSubServiceField(value: number | null) {
    if (this.subServices().length < 1) return;

    if (!value) {
      this.subServicesOptions.set(
        AdaaHelper.setDropdownArray(this.subServices(), "id", AdaaHelper.getFieldLanguage("name"))
      );
      this.model?.controls[this.FIELD_NAMES.subService]?.reset();
      return;
    }

    const list = this.subServices().filter(({ parentId }) => parentId === value);
    this.subServicesOptions.set(AdaaHelper.setDropdownArray(list, "id", AdaaHelper.getFieldLanguage("name")));

    const service = this.model?.controls[this.FIELD_NAMES.subService]?.value;
    const subService = list.find(({ id }) => id === service);
    if (!subService) {
      this.model?.controls[this.FIELD_NAMES.subService]?.reset();
      this.model?.controls[this.FIELD_NAMES.subService]?.markAsTouched();
    }
  }

  private _updateMainServiceField(value: number) {
    if (this.subServices().length < 1) return;

    const mainService = this.model?.controls[this.FIELD_NAMES.mainService]?.value;
    const item = this.subServices().find(({ id }) => id === value);

    if (!mainService) {
      this.model?.controls[this.FIELD_NAMES.mainService]?.setValue(item?.parentId);
    }
  }

  private _updateServiceFields(value: number) {
    if (this.services().length < 1) return;

    const list = this.services().filter(({ objectiveId }) => objectiveId === value);
    this.servicesOptions.set(AdaaHelper.setDropdownArray(list, "id", AdaaHelper.getFieldLanguage("name")));

    const service = this.model?.controls[this.FIELD_NAMES.mainService]?.value;
    const mainService = list.find(({ id }) => id === service);
    if (!mainService) {
      this.model?.controls[this.FIELD_NAMES.mainService]?.reset();
      this.model?.controls[this.FIELD_NAMES.mainService]?.markAsTouched();

      this.model?.controls[this.FIELD_NAMES.subService]?.reset();
      this.model?.controls[this.FIELD_NAMES.subService]?.markAsTouched();

      return;
    }
  }

  private _queryServices() {
    const obs$ = forkJoin([this._serviceApiService.getServicesByMap(), this._serviceApiService.getSubServicesByMap()]);

    obs$.subscribe({
      next: ([res1, res2]) => {
        if (!res1.inError) {
          this.services.set(res1.responseData);
        }
        if (!res2.inError) {
          this.subServices.set(res2.responseData);
        }
      },
      complete: () => {
        this.servicesOptions.set(
          AdaaHelper.setDropdownArray(this.services() ?? [], "id", AdaaHelper.getFieldLanguage("name"))
        );

        this.subServicesOptions.set(
          AdaaHelper.setDropdownArray(this.subServices() ?? [], "id", AdaaHelper.getFieldLanguage("name"))
        );
      },
    });
  }
}
