import { Component, inject, input, OnInit, signal } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { AdaaHelper } from "../../../../core/utils";
import { dateRangeValidator } from "../../../../core/validators";
import { FormActionButtonsComponent, FormInputComponent, FormInputDateComponent } from "../../../../shared/components";
import { Constants } from "../../../../shared/constants/constants";
import { PageMode } from "../../../../shared/constants/enums";
import { AgmCycle } from "../../../../shared/models";
import { AgmCycleApiService } from "../../../../shared/services";
import { AgmCyclesEditorService } from "../../../../shared/services/agm-cycles-editor.service";

@Component({
  selector: "adaa-agm-cycle-editor",
  imports: [
    ReactiveFormsModule,
    FormInputComponent,
    FormInputDateComponent,
    FormActionButtonsComponent,
    TranslateModule,
  ],
  templateUrl: "./agm-cycle-editor.component.html",
  styleUrl: "./agm-cycle-editor.component.scss",
})
export class AgmCycleEditorComponent implements OnInit {
  private _formBuilder = inject(FormBuilder);
  private _translateService = inject(TranslateService);
  private _toastrService = inject(ToastrService);
  private _router = inject(Router);
  private _agmCycleApiService = inject(AgmCycleApiService);
  private _activatedRoute = inject(ActivatedRoute);
  agmCyclesEditorService = inject(AgmCyclesEditorService);

  displayLabel = signal<string>("");
  submitted = signal<boolean>(false);
  pageMode = input.required<string>();
  untilDestroy = AdaaHelper.untilDestroyed();
  agmCycleForm: FormGroup = new FormGroup({});
  PageMode = PageMode;
  agmCycleId: string | null;
  agmCycle = signal<AgmCycle | undefined>(undefined);

  public ngOnInit(): void {
    this.agmCycleId = this._activatedRoute.snapshot?.paramMap?.get("id");
    if (this.agmCycleId) {
      this._agmCycleApiService.getAgmCycle(+this.agmCycleId).subscribe({
        next: (response) => {
          this.agmCycle.set(response.responseData);
          this.agmCycleForm.patchValue(response.responseData);
        },
      });
    }
    this.agmCyclesEditorService.formValidator();
    this._prepareForm();
  }

  private _prepareForm() {
    const isViewMode = this.pageMode() === PageMode.view;

    this.agmCycleForm = this._formBuilder.group(
      {
        nameAE: this._formBuilder.control<string | undefined>(
          { value: "", disabled: isViewMode },
          {
            validators: [Validators.required],
          }
        ),
        nameEN: this._formBuilder.control<string | undefined>(
          { value: "", disabled: isViewMode },
          {
            validators: [Validators.required],
          }
        ),
        startDate: this._formBuilder.control<string | undefined>(
          { value: "", disabled: isViewMode },
          {
            validators: [Validators.required],
          }
        ),
        endDate: this._formBuilder.control<string | undefined>(
          { value: "", disabled: isViewMode },
          {
            validators: [Validators.required],
          }
        ),
        dscAE: this._formBuilder.control<string | undefined>({ value: "", disabled: isViewMode }),
        dscEN: this._formBuilder.control<string | undefined>({ value: "", disabled: isViewMode }),
      },
      {
        validators: Validators.compose([dateRangeValidator("startDate", "endDate")]),
      }
    );

    this.agmCycleForm.valueChanges.pipe(this.untilDestroy()).subscribe((value) => {
      const displayLanguage = AdaaHelper.getFieldLanguage("name");
      if (!value[displayLanguage]?.length) {
        this.displayLabel.set(value["nameAE"] || value["nameEN"]);
      } else {
        this.displayLabel.set(value[displayLanguage]);
      }
    });
  }

  public submit() {
    this.submitted.set(true);
    if (this.agmCycleForm.invalid) {
      this._toastrService.error(this._translateService.instant("notification.warning.missing_info"));
      return;
    }

    const result = {
      ...(this.pageMode() === PageMode.edit ? { ...this.agmCycle() } : {}),
      ...this.agmCycleForm.value,
      status: Constants.OBJECT_STATUS.ACTIVE,
    };

    const url =
      this.pageMode() === PageMode.create
        ? this._agmCycleApiService.createAgmCycle(result)
        : this._agmCycleApiService.updateAgmCycle(result);

    url.subscribe({
      next: (response) => {
        if (response.inError) return;
        this._toastrService.success(this._translateService.instant("notification.success.save"));
        this.close();
      },
    });
  }

  public close() {
    this._router.navigateByUrl("/console/annual-meetings-projects/cycles");
  }

  public checkInvalid(control: string) {
    return (
      this.agmCycleForm.controls[control]?.errors?.["required"] &&
      (this.agmCycleForm.get(control)?.dirty || this.submitted())
    );
  }
}
