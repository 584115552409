import { computed, inject, Injectable, signal } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { filter, forkJoin, map, of } from "rxjs";

import { AdaaHelper } from "../../../../core/utils";
import { Constants } from "../../../../shared/constants/constants";
import { AdaaBoolean, PageMode } from "../../../../shared/constants/enums";
import { FormTab, ObjectStatus } from "../../../../shared/models";
import { DataentryApiService, ExtendedFieldsService, UserService } from "../../../../shared/services";
import { ActualsData, EditorTabInputType } from "./@types";
import {
  attachmentsTab,
  benchmarkingTab,
  dataInputTab,
  dimensionTab,
  extendedFieldsTab,
  improvementPlanTab,
  infoTab,
  linksTab,
  measurementDetailsTab,
  targetAgreementTab,
  targetsTab,
} from "./tabs";

@Injectable({
  providedIn: "root",
})
export class KpiEditorTabs {
  private readonly _userService = inject(UserService);
  private readonly _translateService = inject(TranslateService);
  private readonly _dataentryApiService = inject(DataentryApiService);
  private readonly _extendedFieldsService = inject(ExtendedFieldsService);

  readonly extFieldsTabDisabledStatus = signal<boolean>(false);
  readonly activeTab = signal<number | undefined>(undefined);
  readonly tabs = computed(() =>
    this._tabs().map((tab) => ({ ...tab, title: this._translateService.instant(tab.title) }))
  );
  readonly hasError = computed(() => this.tabs().some(({ hasError }) => hasError));

  private _tabs = signal<FormTab[]>([]);
  private _hasExtFields = signal<boolean>(false);
  private _hasActualsInReview = signal<boolean>(false);
  private _data = signal<EditorTabInputType | undefined>(undefined);

  private _isNTKPI = computed(() => this._data()?.kpiType === Constants.CONSTANT_NTKPITYPE);
  private _isPMOUser = computed(() => AdaaHelper.isPMOEntity(this._userService.currentUser()?.entityId));
  private _isMasterEKPI = computed(
    () =>
      (this._data()?.pageMode === PageMode.edit &&
        this._data()?.kpiType === Constants.KPI_TYPE.EKPI &&
        !AdaaHelper.isDefined(this._data()?.targetsInputEntityId)) ||
      (this._data()?.pageMode === PageMode.create && this._data()?.kpiType === Constants.KPI_TYPE.EKPI)
  );

  readonly isTabActiveFromId = (id: string) => {
    const index = this.activeTab();
    if (!AdaaHelper.isDefined(index)) return false;
    if (!this.tabs()[index]) return false;

    return this.tabs()[index].id === id;
  };

  readonly isTabAvailable = (id: string) => {
    const tabs = this.tabs();
    return AdaaHelper.isDefined(tabs.find((tab) => tab.id === id));
  };

  public init(input: EditorTabInputType) {
    this._data.set({ ...input });

    const observations = [this._hasExtFields$(input.kpiType, input.kpiId), this._kpiHasActualsInReview$()] as const;

    forkJoin(observations).subscribe({
      next: ([hasExtFields, hasActualsInReview]) => {
        this._hasExtFields.set(hasExtFields);
        this._hasActualsInReview.set(hasActualsInReview);
      },
      complete: () => {
        this.generateTabs();
        this.activeTab.set(this._getFirstTab());
      },
    });
  }

  public disableTab(id: string) {
    this.extFieldsTabDisabledStatus.set(true);
    this._tabs.update((tabs) =>
      tabs.map((tab) => {
        if (tab.id === id) return { ...tab, disabled: true };
        return tab;
      })
    );
  }

  public enableTab(id: string) {
    this.extFieldsTabDisabledStatus.set(false);
    this._tabs.update((tabs) =>
      tabs.map((tab) => {
        if (tab.id === id) return { ...tab, disabled: false };
        return tab;
      })
    );
  }

  public tabHasError(id: string) {
    this._tabs.update((tabs) =>
      tabs.map((tab) => {
        if (tab.id === id) return { ...tab, hasError: true };
        return tab;
      })
    );
  }

  public resetTabErrors() {
    this._tabs.update((tabs) =>
      tabs.map((tab) => ({
        ...tab,
        hasError: false,
      }))
    );
  }

  public generateTabs(dataUpdate?: Partial<EditorTabInputType>) {
    if (dataUpdate) this._data.update((d) => ({ ...d, ...((dataUpdate ?? {}) as EditorTabInputType) }));

    const data = this._data();

    if (data?.kpiType === Constants.KPI_TYPE.EKPI) {
      this._tabs.set(this._getEkpiTabs());
      return;
    }

    this._tabs.set([
      infoTab(this._hasActualsInReview() || this._inEditAndTargetsInReview()),
      measurementDetailsTab(this._hasActualsInReview() || this._inEditAndTargetsInReview()),
      targetsTab(this._disableTargetsTab()),
      benchmarkingTab(),
      attachmentsTab(),
      extendedFieldsTab({
        hasError: false,
        disabled: this.extFieldsTabDisabledStatus(),
      }),
      dimensionTab(),
    ]);

    this._tabs.update((tabs) => {
      if (this._acceptsNationalAgenda()) return [...tabs, linksTab()];
      return tabs;
    });

    this._tabs.update((tabs) => {
      if (this._isNTKPI() && this._isPMOUser()) return [...tabs, targetAgreementTab()];
      return tabs;
    });

    this._tabs.update((tabs) => {
      if (this._isNTKPI()) return [...tabs, improvementPlanTab()];
      return tabs;
    });
  }

  private _getFirstTab() {
    const tabs = this.tabs();
    for (const index in tabs) {
      const $i = Number(index);
      const tab = tabs[$i];
      if (tab.disabled) continue;
      else return $i;
    }
    return 0;
  }

  private _inEditAndTargetsInReview() {
    const data = this._data();

    if (!data || !AdaaHelper.isDefined(data.kpiType)) return false;
    if (PageMode.edit !== data.pageMode) return false;
    return data.targetsInReview === true;
  }

  private _disableTargetsTab() {
    const data = this._data();

    if (!data) return false;

    if (data.baselineKpi === AdaaBoolean.N || !AdaaHelper.isDefined(data.baselineKpi)) return false; //@note: Don't disable if has baseline

    if (PageMode.create === data.pageMode) return true; // @note: Disable if it has baseline and is on create mode

    if (data.status === ObjectStatus.DRAFT) return true; // @note: Disable if it has baseline and is draft KPI

    if (data.status === ObjectStatus.ACTIVE) return false; // @note: Don't Disable if it has baseline and is active KPI

    const startYear = new Date(AdaaHelper.getDubaiTime(data.startDate as number)).getFullYear();
    if (data.baselineYear && data.baselineYear > startYear) return true; //@note: Disable if start year is less than baseline year

    return false;
  }

  private _acceptsNationalAgenda() {
    const data = this._data();
    if (!data) return false;
    if (data.kpiType === Constants.KPI_TYPE.SKPI) return true;
    if (data.kpiType !== Constants.KPI_TYPE.UAE && data.kpiType !== Constants.KPI_TYPE.DTKPI) return false;

    if (AdaaHelper.isDefined(data.directionId) && data.directionId === Constants.CONSTANT_SDG_GOV_DIR_ID) return true;

    return AdaaHelper.isDefined(data.govDirectionId) && data.govDirectionId === Constants.CONSTANT_SDG_GOV_DIR_ID;
  }

  private _hasExtFields$(kpiType: number, kpiId?: number) {
    return this._extendedFieldsService.hasExtendedFields(kpiType, kpiId);
  }

  private _kpiHasActualsInReview$() {
    const data = this._data();

    if (!data || !AdaaHelper.isDefined(data.kpiId)) return of(false);

    return this._dataentryApiService.getByKpiIdv2<ActualsData>(data.kpiId).pipe(
      filter((res) => !res.inError),
      map((res) => {
        if (res.responseData.hasMetrics) {
          return (
            res.responseData.dataEntryRequests.filter(
              (req) =>
                (req.metrics || []).map((m) => m.wfProcessCtlId).filter((m) => AdaaHelper.isDefined(m)).length > 0
            ).length > 0
          );
        }

        return res.responseData.metrics.map((m) => m.wfProcessCtlId).filter((m) => AdaaHelper.isDefined(m)).length > 0;
      })
    );
  }

  private _getEkpiTabs() {
    return AdaaHelper.isPMOEntity()
      ? [
          infoTab(this._inEditAndTargetsInReview()),
          measurementDetailsTab(this._inEditAndTargetsInReview()),
          dataInputTab(this._inEditAndTargetsInReview()),
          targetsTab(this._disableTargetsTab()),
          benchmarkingTab(),
          attachmentsTab(),
          extendedFieldsTab({
            hasError: false,
            disabled: this.extFieldsTabDisabledStatus(),
          }),
          dimensionTab(),
        ]
      : [
          infoTab(this._inEditAndTargetsInReview()),
          measurementDetailsTab(this._inEditAndTargetsInReview()),
          targetsTab(this._disableTargetsTab()),
          benchmarkingTab(),
          attachmentsTab(),
          extendedFieldsTab({
            hasError: false,
            disabled: this.extFieldsTabDisabledStatus(),
          }),
          dimensionTab(),
        ];
  }
}
