<div class="m-1" [class.invalid]="invalid">
    <label *ngIf="adaaHelper.isDefinedAndNotEmpty(label)" for="{{ controlId }}" class="d-flex justify-content-center form-label">
        {{ label }}
        <span *ngIf="required" class="form-required text-danger fw-bolder">*</span>
    </label>
    @if (info) {
        <p class="d-flex justify-content-center fst-italic text">{{ info }}</p>
    }

    <div class="textarea-container">
        <textarea
            #textarea
            [id]="controlId"
            cols="20"
            [attr.rows]="rows"
            class="form-control shadow-sm"
            [class.is-invalid]="invalid"
            [ngClass]="{
                'is-correct': correct === true,
                'is-incorrect': correct === false,
            }"
            style="resize: none"
            [placeholder]="placeholder"
            [formControl]="inputControl"
            (ngModelChange)="valueChanged($event)"
        >
        </textarea>
        @if (pageMode !== PageMode.view) {
            <button
                class="btn"
                [ngClass]="{
                    'btn-blue': correct !== true && correct !== false,
                    success: correct === true,
                    'btn-danger': correct === false,
                }"
                (click)="validateFormula()"
                [disabled]="!output || !output.length"
            >
                @if (correct) {
                    <span>{{ "kpi.valid" | translate }}</span>
                } @else {
                    <span>{{ ("kpi.validate_formula" | translate) + "/" + ("metrics.metrics" | translate) }}</span>
                }
            </button>
        }
    </div>
</div>
