import { Component, computed, inject, output, signal, TemplateRef, viewChild } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { pairwise, startWith } from "rxjs";

import { TranslateTokenPipe } from "../../../../core/pipes";
import { AdaaHelper } from "../../../../core/utils";
import { AdaaBoolean, Language } from "../../../constants/enums";
import { GovSectorEntity, ValueText } from "../../../models";
import { AgreementDetails, PublishedProjects } from "../../../models/tp-agreements.model";
import { AppService, TpApiService } from "../../../services";
import { ProjectAgreementEditorService } from "../../../services/project-agreements.editor.service";
import {
  FormActionButtonsComponent,
  FormCheckboxComponent,
  FormDropdownComponent,
  FormInputComponent,
} from "../../form";

@Component({
  selector: "adaa-generate-agreement",
  standalone: true,
  imports: [
    FormInputComponent,
    ReactiveFormsModule,
    TranslateModule,
    FormActionButtonsComponent,
    FormDropdownComponent,
    TranslateTokenPipe,
    FormCheckboxComponent,
  ],
  templateUrl: "./generate-agreement.component.html",
  styleUrl: "./generate-agreement.component.scss",
})
export class GenerateAgreementComponent {
  private _modalService = inject(NgbModal);
  private _appService = inject(AppService);
  private _formBuilder = inject(FormBuilder);
  private _tpApiService = inject(TpApiService);
  private _toastrService = inject(ToastrService);
  private _translateService = inject(TranslateService);
  projectAgreementEditorService = inject(ProjectAgreementEditorService);

  generateAgreementForm: FormGroup = new FormGroup({});
  modal = viewChild.required<TemplateRef<unknown>>("content");
  submitted = signal<boolean>(false);
  entities = signal<GovSectorEntity[]>([]);
  projects = signal<PublishedProjects[]>([]);
  entityOptions = computed(() => {
    const field = AdaaHelper.getFieldLanguage("name");
    const result: ValueText[] = AdaaHelper.setDropdownArray(this.entities(), "id", field);
    return result;
  });
  disableCheckBoxes = signal<boolean>(true);
  untilDestroy = AdaaHelper.untilDestroyed();
  AdaaBoolean = AdaaBoolean;
  checkedValues: { id: number; value: boolean }[] = [];
  agreement = output<AgreementDetails>();

  public ngOnInit(): void {
    this.projectAgreementEditorService.formValidator();
    this._tpApiService.getReadyPublishEntities().subscribe({
      next: (response) => this.entities.set(response.responseData),
    });
    this._prepareForm();
  }

  private _prepareForm() {
    this.generateAgreementForm = this._formBuilder.group({
      entityId: this._formBuilder.control<string | number>("", {
        validators: [Validators.required],
      }),
      firstLeaderName: this._formBuilder.control<string>("", {
        validators: [Validators.required],
      }),
      firstLeaderJobTitle: this._formBuilder.control<string | number>("", {
        validators: [Validators.required],
      }),
      secondLeaderName: this._formBuilder.control<string>(""),
      secondLeaderJobTitle: this._formBuilder.control<string | number>(""),
      projectIdsForSecondLeader: this._formBuilder.control<number[]>([]),
    });

    this.generateAgreementForm.valueChanges
      .pipe(this.untilDestroy(), startWith(this.generateAgreementForm.value), pairwise())
      .subscribe(([previousValues, currentValues]) => {
        if (previousValues.entityId !== currentValues.entityId) {
          this._tpApiService.getReadyPublishProjects(currentValues.entityId).subscribe({
            next: (response) => this.projects.set(response.responseData),
          });
        }
        if (currentValues.secondLeaderName.length || currentValues.secondLeaderJobTitle.length) {
          this.disableCheckBoxes.set(false);
        } else this.disableCheckBoxes.set(true);
      });
  }

  public open() {
    const modalRef: NgbModalRef = this._modalService.open(this.modal(), {
      animation: true,
      scrollable: false,
      keyboard: false,
      size: "xl",
      centered: true,
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });

    modalRef.dismissed.subscribe(() => this._resetForm());
    modalRef.closed.subscribe(() => this._resetForm());
  }

  private _resetForm() {
    this.projects.set([]);
    this.generateAgreementForm.reset();
    this.generateAgreementForm.markAsPristine();
    this.generateAgreementForm.markAsUntouched();
    this.submitted.set(false);
  }

  public submit() {
    this.submitted.set(true);
    if (this.generateAgreementForm.invalid) {
      this._toastrService.error(this._translateService.instant("common.form.label.missing_information"));
      return;
    }

    const projectIdsForSecondLeader = this.checkedValues
      .filter((check) => check.value === true)
      .map((check) => check.id);

    const result = {
      ...this.generateAgreementForm.value,
      projectIdsForSecondLeader: this.disableCheckBoxes() ? [] : projectIdsForSecondLeader,
    };

    this._tpApiService.generateAgreement(result).subscribe({
      next: (response) => this.agreement.emit(response.responseData),
      complete: () => {
        this._toastrService.success(this._translateService.instant("notification.success.save"));
        this._modalService.dismissAll();
      },
    });
  }

  public checkInvalid(control: string) {
    return (
      this.generateAgreementForm.controls[control].errors?.["required"] &&
      (this.generateAgreementForm.get(control)?.dirty || this.submitted())
    );
  }

  public valueChanged(event: boolean, id: number) {
    const valueIndex = this.checkedValues.findIndex((value) => value.id === id);
    if (valueIndex > -1) {
      this.checkedValues[valueIndex].value = event;
    } else this.checkedValues.push({ id, value: event });
  }
}
