import { Component, computed, inject, signal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { ActivatedRoute, RouterLink } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import { TranslateModule } from "@ngx-translate/core";
import { map } from "rxjs";

import { PercentageValuePipe, TranslateTokenPipe } from "../../../../core/pipes";
import { AdaaHelper } from "../../../../core/utils";
import {
  DoughnutComponent,
  PeriodSliderComponent,
  ProgressIndicatorComponent,
  RibbonComponent,
} from "../../../../shared/components";
import { Constants } from "../../../../shared/constants/constants";
import { Language } from "../../../../shared/constants/enums";
import type { NationalStrategyModelType, SelectedPeriodModelType } from "../../../../shared/models";
import { FilesApiService, NationalStrategiesApiService, SystemLayoutService } from "../../../../shared/services";

@Component({
  selector: "adaa-view-dashboard",
  standalone: true,
  templateUrl: "./view-dashboard.component.html",
  styleUrl: "./view-dashboard.component.scss",
  imports: [
    PeriodSliderComponent,
    TranslateModule,
    FontAwesomeModule,
    TranslateTokenPipe,
    DoughnutComponent,
    RouterLink,
    RibbonComponent,
    ProgressIndicatorComponent,
    PercentageValuePipe,
  ],
})
export class ViewDashboardComponent {
  private readonly _route = inject(ActivatedRoute);
  private readonly _fileService = inject(FilesApiService);
  private readonly _systemLayoutService = inject(SystemLayoutService);
  private readonly _nationalStrategiesApiService = inject(NationalStrategiesApiService);

  readonly #untilDestroy = AdaaHelper.untilDestroyed();

  strategyId = toSignal(this._route.params.pipe(map((params) => params["id"])), {
    manualCleanup: true,
  });
  selectedPeriod = signal<SelectedPeriodModelType | undefined>(undefined);
  dashboardData = signal<NationalStrategyModelType | undefined>(undefined);
  dashboardImage = signal<string | undefined>(undefined);
  lang = signal<Language>(AdaaHelper.getCurrentLang());
  Language = Language;

  dashboardWidgets = computed(() => [
    {
      label: "national_strategies.obj_enabler",
      data: this.dashboardData()?.nationalStrategyChildren?.length,
    },
    {
      label: "national_strategies.initiatives",
      data: this.dashboardData()?.initiativesCount,
    },
    {
      label: "national_strategies.kpis",
      data: this.dashboardData()?.kpiCount,
    },
    {
      label: "national_strategies.budget",
      data: AdaaHelper.amountToString(this.dashboardData()?.budget),
      symbol: AdaaHelper.getCurrencySymbol(this.dashboardData()?.budget, true, "-"),
    },
    {
      label: "national_strategies.actual_expenditure",
      data: AdaaHelper.amountToString(this.dashboardData()?.expense),
      symbol: AdaaHelper.getCurrencySymbol(this.dashboardData()?.expense, true, "-"),
    },
  ]);

  public get icons() {
    return {
      refresh: faArrowsRotate,
    };
  }

  public ngOnInit() {
    this._refresh();
  }

  public getColor(code: number) {
    return Constants.COLOR_CODES[code];
  }

  public selectPeriod(period: SelectedPeriodModelType) {
    if (!period) return;
    this.selectedPeriod.update(() => period);
    this.queryData();
  }

  public queryData() {
    const selectedPeriod = this.selectedPeriod() as SelectedPeriodModelType;
    const id = this.strategyId();

    this._nationalStrategiesApiService.getStrategyDashboardInfo(id, selectedPeriod.period.year).subscribe({
      next: (res) => {
        if (res.inError) return;
        this.dashboardData.update(() => res.responseData);
        this._getLogo();
      },
    });
  }

  private _getLogo() {
    this._fileService.download(this.dashboardData()!.logo!).subscribe({
      next: (blob) => {
        this.dashboardImage.set(window.URL.createObjectURL(blob));
      },
    });
  }

  private _refresh() {
    this._systemLayoutService.hasActiveEntityChanged$.pipe(this.#untilDestroy()).subscribe({
      next: () => window.location.reload(),
    });

    this._systemLayoutService.hasCycleChanged$.pipe(this.#untilDestroy()).subscribe({
      next: () => window.location.reload(),
    });
  }
}
