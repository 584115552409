<ng-template #content let-modal>
    <div class="modal-header">
        <h5 class="text-center fw-bold w-100">{{ "notification_config.templates.modal.title" | translate }}</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body overflow-x-hidden p-3">
        <form class="col-12" [formGroup]="smsForm">
            <div class="row">
                <div class="col-md-4 col-sm-12 col-xs-12">
                    <adaa-placeholder-list [items]="placehodersList()" (placeholderDoubleClick)="placeholderSelected($event)"></adaa-placeholder-list>
                </div>
                <div class="col-md-8 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.templateAE' | translate"
                        formControlName="contentAE"
                        controlName="contentAE"
                        [isTextArea]="true"
                        (click)="onTextareaFocus($event)"
                    ></adaa-form-input>
                    <adaa-form-input
                        [label]="'common.form.label.templateEN' | translate"
                        formControlName="contentEN"
                        controlName="contentEN"
                        [isTextArea]="true"
                        (click)="onTextareaFocus($event)"
                    ></adaa-form-input>
                </div>
            </div>
        </form>
        <div class="my-2">
            <adaa-form-action-buttons
                [cancelButtonTitle]="'common.form.button.cancel'"
                [saveButtonTitle]="'common.form.button.submit'"
                (cancelClicked)="modal.close()"
                (saveClicked)="submit()"
            ></adaa-form-action-buttons>
        </div>
    </div>
</ng-template>
