import { CommonModule } from "@angular/common";
import { Component, inject, input, QueryList, signal, ViewChild, ViewChildren } from "@angular/core";
import { FormsModule, NgModel } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbPaginationModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { FileSaverService } from "ngx-filesaver";
import { ToastrService } from "ngx-toastr";
import { debounceTime, distinctUntilChanged } from "rxjs";

import { AdaaHelper } from "../../../../core/utils";
import { FormActionButtonsComponent } from "../../../../shared/components";
import { Constants } from "../../../../shared/constants/constants";
import { Language } from "../../../../shared/constants/enums";
import { CycleModelType, KpiAnnualWeightsModelType, WeightInputModelType } from "../../../../shared/models";
import { KpisApiService, LanguageService, SystemLayoutService } from "../../../../shared/services";
import { compare, SortableHeaderDirective, SortEvent } from "../sortable-header.directive";

@Component({
  selector: "adaa-kpi-annual-weights",
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    NgbPaginationModule,
    FormActionButtonsComponent,
    SortableHeaderDirective,
  ],
  templateUrl: "./kpi-annual-weights.component.html",
  styleUrl: "./kpi-annual-weights.component.scss",
})
export class KPIAnnualWeightsComponent {
  private _translateService = inject(TranslateService);
  private _router = inject(Router);
  private _route = inject(ActivatedRoute);
  private _kpiApiService = inject(KpisApiService);
  private _toastrService = inject(ToastrService);
  private _fileSaverService = inject(FileSaverService);
  private readonly _systemLayoutService = inject(SystemLayoutService);
  languageService = inject(LanguageService);

  @ViewChildren(SortableHeaderDirective)
  headers: QueryList<SortableHeaderDirective>;

  language = Language;

  title = input.required<string>();

  readonly separator = "__$3p__";
  AdaaHelper = AdaaHelper;
  private readonly _untilDestroy = AdaaHelper.untilDestroyed();
  @ViewChild("searchInput") searchInput: NgModel;

  planYears = signal<number[]>([]);
  currentPlan = signal<CycleModelType>({
    id: 0,
    nameAE: "",
    nameEN: "",
    dscAE: "",
    dscEN: "",
    startYear: 0,
    endYear: 0,
    dataRevisionId: 0,
    entityMaps: [],
    status: 0,
    wfStatus: "",
    updateTS: 0,
  });
  changelog: { [k: string]: WeightInputModelType } = {};
  weightsConfigurations: { [k: number]: boolean } = {};
  totals: { [k: number]: number } = {};
  weights: KpiAnnualWeightsModelType[] = [];
  kpiItems: KpiAnnualWeightsModelType[] = [];
  tableRows: number[] = [];
  yearHovered: number | undefined = undefined;

  weightsInReview: KpiAnnualWeightsModelType[] = [];
  errors = {
    totalAbove100: [] as number[],
    totalBelow100: [] as number[],
    weightsWithError: [] as string[],
  };

  searchTerm: string;
  entityId: number;
  kpiType: number;

  offset: number = 0;
  currentPage: number = 1;
  limit: number = 20;

  public ngOnInit() {
    this._systemLayoutService.hasCycleChanged$.pipe(this._untilDestroy()).subscribe({
      next: () => {
        window.location.reload();
      },
    });

    this._systemLayoutService.hasActiveEntityChanged$.pipe(this._untilDestroy()).subscribe({
      next: () => {
        AdaaHelper.isPMOEntity()
          ? this._router.navigateByUrl("/console/calculation-management")
          : window.location.reload();
      },
    });

    this._getKpiType();
  }

  public ngAfterViewInit() {
    this.searchKpis();
  }

  public onPageChange(): void {
    this._getPageItems();
  }
  public searchKpis() {
    this.searchInput?.valueChanges?.pipe(debounceTime(800), distinctUntilChanged()).subscribe((term) => {
      this.searchTerm = term;
      this._applySearch(term);
    });
  }
  private _applySearch(value: string) {
    this._buildDataRows();
    if (!AdaaHelper.isDefined(value) || typeof value !== "string") {
      return;
    }
    if (value.length < 1) {
      return;
    }

    this.kpiItems = this.kpiItems.filter((val) => {
      if (this.languageService.current() === this.language.English) {
        return val.kpiNameEN?.toLocaleLowerCase().search(value.toLocaleLowerCase()) !== -1;
      }
      return val.kpiNameAE?.toLocaleLowerCase().search(value.toLocaleLowerCase()) !== -1;
    });
    this.tableRows = this.kpiItems.map((val) => val.itemId);
  }

  public onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });

    // sorting kpis
    if (direction === "" || column === "") {
      this._buildDataRows();
    } else {
      this.kpiItems = [...this.kpiItems].sort((a, b) => {
        const res = compare(
          this.getWeightProperty(a.itemId, "weight", column),
          this.getWeightProperty(b.itemId, "weight", column)
        );

        return direction === "asc" ? res : -res;
      });

      this._getPageItems();
    }
  }

  public getWeightProperty(itemId: number, key: keyof KpiAnnualWeightsModelType, year?: number) {
    const weights = this.weights.filter((val) => val.itemId === itemId);
    if (weights.length < 1) {
      return undefined;
    }
    switch (key) {
      case "itemId":
      case "itemType":
      case "kpiNameEN":
      case "kpiNameAE":
      case "kpiEndDate":
      case "kpiStartDate":
      case "refCode":
        return weights[0][key] || null;

      case "wfProcessCtlId": {
        const findingWF = weights.find((weight) => weight.year === year);
        if (AdaaHelper.isDefined(findingWF)) {
          return findingWF?.wfProcessCtlId;
        }
        return null;
      }

      case "id": {
        const findingId = weights.find((weight) => weight.year === year);
        if (AdaaHelper.isDefined(findingId)) {
          return findingId?.id || null;
        }
        return null;
      }

      case "year": {
        const findingYear = weights.find((weight) => weight.year === year);
        if (AdaaHelper.isDefined(findingYear)) {
          return findingYear?.year || null;
        }
        return year || null;
      }

      case "weight": {
        const weightsInReview = this.weightsInReview.filter((val) => val.itemId === itemId);
        if (weightsInReview.length > 0) {
          const findingWeightInReview = weightsInReview.find((weight) => weight.year === year);
          if (AdaaHelper.isDefined(findingWeightInReview)) {
            return findingWeightInReview?.snapshotWeight;
          }
        } // * So no snapshot
        const findingWeight = weights.find((weight) => weight.year === year);
        if (AdaaHelper.isDefined(findingWeight)) {
          const key = `${findingWeight?.itemId}${this.separator}${findingWeight?.year}`;
          if (AdaaHelper.isDefined(this.changelog[key])) {
            return this.changelog[key].weight;
          }
          return findingWeight?.weight;
        }
        return null;
      }

      default:
        return null;
    }
  }

  public getWeightYear(itemId: number, year?: number) {
    const weights = this.weights.filter((val) => val.itemId === itemId);
    const findingYear = weights.find((weight) => weight.year === year);
    if (AdaaHelper.isDefined(findingYear)) {
      return findingYear?.year.toString() || "null";
    }
    return year?.toString() || "null";
  }

  public canPerformNormalWeightDistribution(year: number) {
    return (
      this.weights.filter((weight) => {
        return weight.year === year && AdaaHelper.isDefined(weight.wfProcessCtlId);
      }).length < 1
    );
  }

  public performNormalWeightDistribution(year: number) {
    if (!this.weightsConfigurations[year]) return;
    const validWeights = this.weights.filter((weight) => {
      return (
        weight.year === year &&
        !AdaaHelper.isDefined(weight.wfProcessCtlId) &&
        AdaaHelper.isDefined(weight.id) &&
        this.isYearValid(weight.itemId, year)
      );
    });
    if (validWeights.length < 1) {
      this._toastrService.warning(this._translateService.instant("kpi_annual_weights.msg.no_weight_distribution"));
      return;
    }
    const eachWeight = 100 / validWeights.length;
    for (const data of validWeights) {
      this.captureWeightInput(
        {
          currentWeight: data.weight,
          id: data.id,
          itemId: data.itemId,
          itemType: data.itemType,
          kpiEndDate: data.kpiEndDate,
          kpiStartDate: data.kpiStartDate,
          refCode: data.refCode,
          snapshotWeight: data.snapshotWeight,
          wfProcessCtlId: data.wfProcessCtlId,
          year: data.year,
        },
        null,
        parseFloat(`${eachWeight}`).toFixed(2)
      );
    }
  }

  public periodHasError(year: number) {
    return (
      this.errors.totalAbove100.findIndex((val) => val === year) > -1 ||
      this.errors.totalBelow100.findIndex((val) => val === year) > -1 ||
      this.errors.weightsWithError.findIndex((val) => val.includes(`${year}`)) > -1
    );
  }

  public cellHasError(itemId: number, year: number) {
    return this.errors.weightsWithError.findIndex((val) => val === `${itemId}${this.separator}${year}`) > -1;
  }

  public isYearValid(itemId: string | number | null | undefined, year: number) {
    const weights = this.weights.filter((val) => val.itemId === itemId);
    if (weights.length < 1) {
      return undefined;
    }
    const startYear = new Date(weights[0].kpiStartDate).getFullYear();
    const endYear = new Date(weights[0].kpiEndDate).getFullYear();
    return year >= startYear && year <= endYear;
  }

  private _preparePlanYears() {
    const plan = AdaaHelper.getLocalStorage(Constants.localStorageKeys.currentPlan, { type: "json" });
    this.currentPlan.set(plan as CycleModelType);
    if (!this.currentPlan()) return;
    const startYear = new Date(this.currentPlan().startYear).getFullYear();
    const endYear = new Date(this.currentPlan().endYear).getFullYear();
    const years: number[] = [];
    for (let i = startYear; i <= endYear; i++) {
      years.push(i);
    }
    this.planYears.set(years);
  }

  private _getKpiType() {
    if (!this._route.snapshot.data) return;
    this.kpiType = +this._route.snapshot?.data["kpiType"];
    this._preparePlanYears();
    this._getConfStatus();
  }
  private _getConfStatus() {
    this._setEntityId();
    this._kpiApiService.getKPIAnnualStatusConfig(this.entityId, this.kpiType).subscribe({
      next: (response) => {
        for (const year of this.planYears()) {
          const index = response.responseData.findIndex((val) => val.year === year);
          if (index !== -1) {
            this.weightsConfigurations[year] = response.responseData[index].enabled;
          }
        }
        this._queryAnnualWeights();
      },
    });
  }
  private _setEntityId() {
    this.entityId = +AdaaHelper.getLocalStorage(Constants.localStorageKeys.currentEntity, {
      type: "prop",
      property: "id",
    });
  }
  private _queryAnnualWeights() {
    this._kpiApiService.getKPIAnnualWeights(this.entityId, this.kpiType).subscribe({
      next: (response) => {
        this._setWeights(response.responseData.weights);
        this._findAllWeightsInReview();
        this._setTotals(this._calculateTotals(this.weights));
      },
    });
  }
  private _setWeights(data: KpiAnnualWeightsModelType[]) {
    this.weights = [
      ...data.filter((val) => this.AdaaHelper.isDefined(val.itemId) && this.AdaaHelper.isDefined(val.itemType)),
    ];
    this._buildDataRows();
  }
  private _findAllWeightsInReview() {
    for (const data of this.weights) {
      if (AdaaHelper.isDefined(data.wfProcessCtlId)) {
        this.weightsInReview.push(data);
      }
    }
  }

  private _buildDataRows() {
    this.kpiItems = this.weights.reduce((accumulator: KpiAnnualWeightsModelType[], currentValue) => {
      if (!accumulator.find((value) => value.itemId === currentValue.itemId)) {
        accumulator.push(currentValue);
      }
      return accumulator;
    }, []);

    this._getPageItems();
  }

  private _getPageItems(data = this.kpiItems) {
    this.offset = this.limit * (this.currentPage - 1);
    const arrayCopy = AdaaHelper.clone(data.map((value) => value.itemId));
    this.tableRows = arrayCopy.splice(this.offset, this.limit);
  }
  public captureWeightInput(obj: WeightInputModelType, event: Event | null, val?: string) {
    const key = `${obj.itemId}${this.separator}${obj.year}`;
    const value = event ? (event?.target as HTMLInputElement).value : val;
    let weight: number;

    weight = parseFloat(value === "" || value === null || value === undefined ? `0` : value);

    // *  Error checking
    if (isNaN(weight) || weight > 100 || weight < 0) {
      this.errors.weightsWithError.push(key);
      return;
    }
    this.errors.weightsWithError = this.errors.weightsWithError.filter((val) => val !== key);
    if (+weight === obj.currentWeight) {
      return;
    }
    weight = parseFloat(weight.toFixed(2));
    this.changelog[key] = {
      ...obj,
      weight,
    };
    this._setTotals(this._calculateTotals(this._getWeightsIntegratedWithChangelog()));
    // * Verify the year average totals
    if (obj.year) {
      if (this.weightsConfigurations[+obj.year] && this.totals[+obj.year] > 100) {
        this.errors.totalAbove100.push(+obj.year);
      } else {
        this.errors.totalAbove100 = this.errors.totalAbove100.filter((val) => val !== obj.year);
      }
      if (this.weightsConfigurations[+obj.year] && this.totals[+obj.year] < 100) {
        this.errors.totalBelow100.push(+obj.year);
      } else {
        this.errors.totalBelow100 = this.errors.totalBelow100.filter((val) => val !== obj.year);
      }
    }
  }

  private _setTotals(data: { [k: number]: number }) {
    this.totals = Object.assign({}, data);
  }

  private _calculateTotals(weights: WeightInputModelType[] | KpiAnnualWeightsModelType[]): { [k: number]: number } {
    return weights.reduce(
      (
        acc,
        { weight, year, snapshotWeight, wfProcessCtlId, itemId, id }: WeightInputModelType | KpiAnnualWeightsModelType
      ) => {
        if (!AdaaHelper.isDefined(year)) {
          return acc;
        }
        if (year && !this.isYearValid(itemId, +year)) {
          return acc;
        }
        if (!AdaaHelper.isDefined(id)) {
          return acc;
        }

        let total: number = 0;
        if (
          year !== null &&
          year !== undefined &&
          Object.prototype.hasOwnProperty.call(acc, year) &&
          weight !== null &&
          weight !== undefined
        ) {
          if (wfProcessCtlId && snapshotWeight) total = acc[year] + +snapshotWeight;
          else total = acc[year] + +weight;
        } else {
          if (wfProcessCtlId && snapshotWeight && weight !== null && weight !== undefined) {
            total = +snapshotWeight;
          } else if (weight !== null && weight !== undefined) {
            total = +weight;
          }
        }
        if (year) acc[year] = parseFloat(total.toFixed(2));

        return acc;
      },
      {} as { [key: string]: number }
    );
  }

  private _getWeightsIntegratedWithChangelog(onlyChanged = false) {
    const newWeights: WeightInputModelType[] = [];
    for (const key of Object.keys(this.changelog)) {
      newWeights.push({
        id: this.changelog[key]?.id,
        year: this.changelog[key].year,
        itemId: this.changelog[key].itemId,
        itemType: this.changelog[key].itemType,
        weight: this.changelog[key].weight,
        snapshotWeight: this.changelog[key].snapshotWeight,
        wfProcessCtlId: this.changelog[key].wfProcessCtlId,
        kpiEndDate: this.changelog[key].kpiEndDate,
        kpiStartDate: this.changelog[key].kpiStartDate,
        refCode: this.changelog[key].refCode,
        currentWeight: this.changelog[key].currentWeight,
      });
    }
    if (!onlyChanged) {
      for (const data of this.weights) {
        // * For Weights with values already prevent deduping
        const changed = Object.keys(this.changelog).findIndex((key) => {
          const [itemId, year] = key.split(this.separator);
          return data.itemId === +itemId && data.year === +year;
        });
        if (changed > -1) continue;

        newWeights.push({
          id: data.id,
          year: data.year,
          itemId: data.itemId,
          itemType: data.itemType,
          weight: data.weight,
          snapshotWeight: data.snapshotWeight,
          wfProcessCtlId: data.wfProcessCtlId,
          kpiEndDate: data.kpiEndDate,
          kpiStartDate: data.kpiStartDate,
          refCode: data.refCode,
          currentWeight: null,
        });
      }
    }
    return newWeights;
  }
  public submitWeights() {
    this._verifyTotals();
    if (this._tableHasErrors()) {
      this._toastrService.error(this._translateService.instant("notification.warning.missing_info"));

      return;
    }
    if (Object.keys(this.changelog).length < 1) {
      this._toastrService.warning(this._translateService.instant("notification.warning.no_data_to_modify"));

      return;
    }
    const requestData = {
      weights: this._getWeightsIntegratedWithChangelog(true),
    };
    // * Clean up
    this.changelog = {};
    this._resetErrors(true);
    this._setTotals([]);
    this._kpiApiService.updadeAnnualWeights(+this.entityId, requestData).subscribe({
      next: (resp) => {
        if (resp.inError) {
          return;
        }
        this._toastrService.success(this._translateService.instant("notification.success.save"));
        this._router.navigateByUrl("console/calculation-management/kpi-annual-weights");
      },
    });
  }

  private _tableHasErrors() {
    let hasErrors = false;
    for (const period of this.planYears()) {
      hasErrors = this.periodHasError(period);
      if (hasErrors) {
        return hasErrors;
      }
    }
    return hasErrors;
  }

  private _verifyTotals() {
    this._resetErrors();
    for (const period of Object.keys(this.totals)) {
      if (!this.weightsConfigurations[+period]) {
        continue;
      }
      if (this.totals[+period] > 100) {
        this.errors.totalAbove100.push(+period);
      }
      if (this.totals[+period] < 100) {
        this.errors.totalBelow100.push(+period);
      }
    }
  }

  private _resetErrors(includeWeightErrors = false) {
    this.errors.totalAbove100 = [];
    this.errors.totalBelow100 = [];
    if (includeWeightErrors) {
      this.errors.weightsWithError = [];
    }
  }

  public cancel() {
    this._router.navigateByUrl("console/calculation-management/kpi-annual-weights");
  }

  public focusNext(row: number, col: number) {
    const nextElementSiblingId = "input-" + (row + 1) + "-" + col;
    if (row < this.tableRows.length) {
      (document.querySelector(`#${nextElementSiblingId}`) as HTMLElement)?.focus();
    }
  }

  public exportCSV() {
    const tmpDTO: { [k: number]: string } = {};
    for (const period of this.planYears()) {
      tmpDTO[`${period}`] = "";
    }
    const fileToExport = [];
    // * Header List
    const headerList = AdaaHelper.clone(tmpDTO);
    for (const key of Object.keys(headerList)) {
      if (key !== "kpiName") {
        headerList[key] = key;
      } else {
        headerList.kpiName = "KPI Name";
      }
    }
    fileToExport.push(headerList);
    const itemIds = Array.from(new Set(this.weights.map((val) => val.itemId)));
    for (const item of itemIds) {
      const tmpBlankRow = AdaaHelper.clone(tmpDTO);
      for (const key of Object.keys(headerList)) {
        if (key === "kpiName") {
          tmpBlankRow.kpiName = this.getWeightProperty(
            item,
            this.languageService.current() === this.language.English ? "kpiNameEN" : "kpiNameAE"
          );
        } else {
          const weight = this.getWeightProperty(item, "weight", +key);
          if (weight && +weight >= 0) {
            tmpBlankRow[key] = weight;
          } else {
            tmpBlankRow[key] = "";
          }
        }
      }
      fileToExport.push(tmpBlankRow);
    }

    const currentDate = moment();
    const blob = new Blob([JSON.stringify(fileToExport)], { type: "text/csv" });
    const fileName = `${
      AdaaHelper.getLocalStorage(Constants.localStorageKeys.currentEntity, {
        type: "prop",
        property: AdaaHelper.getFieldLanguage("shortName"),
      }) as string
    }_${currentDate.format("YYYYMMDD")}_${currentDate.format("HHmmss")}`;

    this._fileSaverService.save(blob, fileName);
  }
}
