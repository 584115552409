import { Component, inject, input, signal } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";

import { AdaaHelper } from "../../../core/utils";
import { ConfirmationModalComponent, FormActionButtonsComponent, FormInputComponent } from "../../../shared/components";
import { Constants } from "../../../shared/constants/constants";
import { Language, PageMode } from "../../../shared/constants/enums";
import { AppService, OtherEntitiesApiService, OtherEntityEditorService } from "../../../shared/services";

@Component({
  selector: "adaa-other-entity-editor",
  standalone: true,
  imports: [ReactiveFormsModule, RouterModule, TranslateModule, FormActionButtonsComponent, FormInputComponent],
  templateUrl: "./other-entity-editor.component.html",
  styleUrl: "./other-entity-editor.component.scss",
})
export class OtherEntityEditorComponent {
  private _formBuilder = inject(FormBuilder);
  private _toastrService = inject(ToastrService);
  private _translateService = inject(TranslateService);
  private _otherEntitiesApiService = inject(OtherEntitiesApiService);
  private _router = inject(Router);
  private _modalService = inject(NgbModal);
  private _appService = inject(AppService);
  otherEntityEditorService = inject(OtherEntityEditorService);
  private _activatedRoute = inject(ActivatedRoute);

  otherEntitiyForm: FormGroup = new FormGroup({});
  pageMode = input.required<string>();
  adaaHelper = AdaaHelper;
  _subscription = new Subscription();
  otherEntityId: string | null;

  PageMode = PageMode;

  displayLabel = signal<string>("");
  setDisabled = signal<boolean>(false);
  submitted = signal<boolean>(false);

  public ngOnInit(): void {
    this.setDisabled.set(this.pageMode() === PageMode.view);
    this.otherEntityId = this._activatedRoute.snapshot?.paramMap?.get("id");

    this.otherEntityEditorService.formValidator();
    if (this.otherEntityId) this._getOtherEntity(+this.otherEntityId);
    this._prepareForm();
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  private _prepareForm() {
    const isViewMode = this.pageMode() === PageMode.view;

    this.otherEntitiyForm = this._formBuilder.group({
      nameAE: this._formBuilder.control<string | undefined>(
        { value: "", disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      nameEN: this._formBuilder.control<string | undefined>(
        { value: "", disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      descriptionAE: this._formBuilder.control<string | undefined>({ value: "", disabled: isViewMode }),
      descriptionEN: this._formBuilder.control<string | undefined>({ value: "", disabled: isViewMode }),
    });

    this._subscription.add(
      this.otherEntitiyForm.valueChanges.subscribe((value) => {
        const displayLanguage = AdaaHelper.getFieldLanguage("name");
        if (!value[displayLanguage].length) {
          this.displayLabel.set(value["nameAE"] || value["nameEN"]);
        } else this.displayLabel.set(value[displayLanguage]);
      })
    );
  }

  private _getOtherEntity(otherEntityId: number) {
    if (otherEntityId)
      this._otherEntitiesApiService.getOtherEntityById(otherEntityId).subscribe({
        next: (response) => {
          this.otherEntitiyForm.patchValue({ ...response.responseData });
          this.otherEntitiyForm.markAsPristine();
          this.otherEntitiyForm.markAsUntouched();
        },
      });
  }

  public checkInvalid(control: string) {
    return (
      this.otherEntitiyForm.controls[control].errors?.["required"] &&
      (this.otherEntitiyForm.get(`${control}`)?.dirty || this.submitted())
    );
  }

  public updateOtherEntity() {
    this.submitted.set(true);
    if (this.otherEntitiyForm.invalid) {
      this._toastrService.warning(this._translateService.instant("notification.warning.missing_info"));
      return;
    }
    const otherEntity = this._formatOtherEntity();

    const serviceUrl =
      this.pageMode() === PageMode.edit
        ? this._otherEntitiesApiService.updateOtherEntity(otherEntity)
        : this._otherEntitiesApiService.createOtherEntity(otherEntity);

    serviceUrl.subscribe({
      next: (response) => {
        if (response.inError) return;
        this._toastrService.success(this._translateService.instant("notification.success.save"));
        this._router.navigateByUrl("/console/other-entities/list");
      },
    });
  }

  private _formatOtherEntity() {
    const otherEntity = this.otherEntitiyForm.value;
    otherEntity.status = Constants.OBJECT_STATUS.ACTIVE;
    if (this.pageMode() === PageMode.edit) {
      otherEntity.updateTS = Date.now();
      otherEntity.id = this.otherEntityId;
    }
    return otherEntity;
  }

  public cancelForm() {
    if (this.otherEntitiyForm.dirty) {
      const modal = this._modalService.open(ConfirmationModalComponent, {
        centered: true,
        size: "md",
        modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
      });

      modal.componentInstance.header = "common.form.modals_leave.cancel_yes_no_title";
      modal.componentInstance.title = "common.form.modals_leave.cancel_yes_no_information";

      modal.result.then((e) => {
        if (e) this._router.navigateByUrl(`/console/other-entities//list`);
      });
    } else this._router.navigateByUrl(`/console/other-entities//list`);
  }
}
