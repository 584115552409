<div class="d-flex gap-2">
    <p class="fw-bold fs-4">{{ "provisioning.directives" | translate }}</p>
</div>

<div class="bg-white shadow-md rounded p-2 mb-3">
    <form class="col-12" [formGroup]="strategicDocumentForm">
        <div class="bg-white shadow-md rounded p-2 mb-3">
            <div class="row mb-3">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'plan_wizard.labels.visionAE' | translate"
                        [isTextArea]="true"
                        [maxLength]="inputMaxLength"
                        formControlName="visionAE"
                        controlName="visionAE"
                    ></adaa-form-input>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'plan_wizard.labels.visionAE' | translate"
                        [isTextArea]="true"
                        [maxLength]="inputMaxLength"
                        formControlName="visionEN"
                        controlName="visionEN"
                    ></adaa-form-input>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'plan_wizard.labels.missionAE' | translate"
                        [isTextArea]="true"
                        [maxLength]="inputMaxLength"
                        formControlName="missionAE"
                        controlName="missionAE"
                    ></adaa-form-input>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'plan_wizard.labels.missionEN' | translate"
                        [isTextArea]="true"
                        [maxLength]="inputMaxLength"
                        formControlName="missionEN"
                        controlName="missionEN"
                    ></adaa-form-input>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.values_ae' | translate"
                        [isTextArea]="true"
                        [maxLength]="inputMaxLength"
                        formControlName="valuesAE"
                        controlName="valuesAE"
                    ></adaa-form-input>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.values_en' | translate"
                        [isTextArea]="true"
                        [maxLength]="inputMaxLength"
                        formControlName="valuesEN"
                        controlName="valuesEN"
                    ></adaa-form-input>
                </div>
            </div>
        </div>
    </form>
</div>

<div class="d-flex gap-2">
    <p class="fw-bold fs-4">{{ "provisioning.swot_title" | translate }}</p>
</div>

<div class="bg-white shadow-md rounded p-2 mb-3">
    <form class="col-12" [formGroup]="swotAnalysisDocumentForm">
        <div class="bg-white shadow-md rounded p-2 mb-3">
            <div class="row mb-3">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'plan_wizard.labels.strengthAE' | translate"
                        [isTextArea]="true"
                        [maxLength]="inputMaxLength"
                        formControlName="strengthAE"
                        controlName="strengthAE"
                    ></adaa-form-input>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'plan_wizard.labels.strengthEN' | translate"
                        [isTextArea]="true"
                        [maxLength]="inputMaxLength"
                        formControlName="strengthEN"
                        controlName="strengthEN"
                    ></adaa-form-input>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'plan_wizard.labels.weaknessAE' | translate"
                        [isTextArea]="true"
                        [maxLength]="inputMaxLength"
                        formControlName="weaknessAE"
                        controlName="weaknessAE"
                    ></adaa-form-input>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'plan_wizard.labels.weaknessEN' | translate"
                        [isTextArea]="true"
                        [maxLength]="inputMaxLength"
                        formControlName="weaknessEN"
                        controlName="weaknessEN"
                    ></adaa-form-input>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'plan_wizard.labels.opportunityAE' | translate"
                        [isTextArea]="true"
                        [maxLength]="inputMaxLength"
                        formControlName="opportunitiesAE"
                        controlName="opportunitiesAE"
                    ></adaa-form-input>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'plan_wizard.labels.opportunityEN' | translate"
                        [isTextArea]="true"
                        [maxLength]="inputMaxLength"
                        formControlName="opportunitiesEN"
                        controlName="opportunitiesEN"
                    ></adaa-form-input>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'plan_wizard.labels.threatAE' | translate"
                        [isTextArea]="true"
                        [maxLength]="inputMaxLength"
                        formControlName="threatsAE"
                        controlName="threatsAE"
                    ></adaa-form-input>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'plan_wizard.labels.threatEN' | translate"
                        [isTextArea]="true"
                        [maxLength]="inputMaxLength"
                        formControlName="threatsEN"
                        controlName="threatsEN"
                    ></adaa-form-input>
                </div>
            </div>
        </div>
    </form>
</div>

<adaa-form-action-buttons
    *adaaHasPermission="createPermission()"
    [saveButtonTitle]="'executive_teams.submit' | translate"
    (cancelClicked)="close()"
    (saveClicked)="submit()"
></adaa-form-action-buttons>
