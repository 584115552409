import { CommonModule } from "@angular/common";
import { Component, inject, OnInit, signal } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { BaseChartDirective } from "ng2-charts";

import { TranslateTokenPipe } from "../../../core/pipes";
import { AdaaHelper } from "../../../core/utils";
import { DoughnutComponent, FormDropdownComponent, PeriodSliderComponent } from "../../../shared/components";
import { Constants } from "../../../shared/constants/constants";
import { AggregationPerfModelType, SelectedPeriodModelType } from "../../../shared/models";
import { ChartsService, ExecutiveDashboardService, SystemLayoutService } from "../../../shared/services";

@Component({
  selector: "adaa-executive-dashboard",
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormDropdownComponent,
    PeriodSliderComponent,
    DoughnutComponent,
    TranslateTokenPipe,
    BaseChartDirective,
  ],
  templateUrl: "./executive-dashboard.component.html",
  styleUrl: "./executive-dashboard.component.scss",
})
export class ExecutiveDashboardComponent implements OnInit {
  private _systemLayoutService = inject(SystemLayoutService);
  private _formBuilder = inject(FormBuilder);
  executiveDashboardService = inject(ExecutiveDashboardService);
  chartsService = inject(ChartsService);

  private _untilDestroyed = AdaaHelper.untilDestroyed();
  selectedPeriod = signal<SelectedPeriodModelType | undefined>(undefined);
  executiveDashboardForm: FormGroup;
  adaaHelper = AdaaHelper;
  constants = Constants;

  public ngOnInit(): void {
    this._prepareForm();
    this.executiveDashboardService.getEntitesTypes();
    this._refresh();
  }

  public selectPeriod(event: SelectedPeriodModelType) {
    if (!event || event.type === "quarter") return;
    this.selectedPeriod.set(event);
    this.getPerformance();
  }

  public selectPerformance(event: AggregationPerfModelType) {
    this.executiveDashboardService.selectedPerformance.set(event);
    this.getPerformanceGraph();
  }

  public applyFilters() {
    this.getPerformance();
    this.getPerformanceGraph();
  }

  public getPerformance(selectedPeriod: SelectedPeriodModelType | undefined = undefined): void {
    this.executiveDashboardService.getPerformance(
      selectedPeriod ? selectedPeriod : this.selectedPeriod(),
      this.executiveDashboardForm.get("entitySize")?.value,
      this.executiveDashboardForm.get("entityType")?.value
    );
  }

  public getPerformanceGraph(selectedPeriod: SelectedPeriodModelType | undefined = undefined): void {
    this.executiveDashboardService.getPerformanceGraph(
      selectedPeriod ? selectedPeriod : this.selectedPeriod(),
      this.executiveDashboardForm.get("entitySize")?.value,
      this.executiveDashboardForm.get("entityType")?.value
    );
  }

  private _prepareForm(): void {
    this.executiveDashboardForm = this._formBuilder.group({
      entitySize: [0],
      entityType: [0],
    });
  }

  private _refresh() {
    this._systemLayoutService.hasActiveEntityChanged$
      .pipe(this._untilDestroyed())
      .subscribe(() => this.getPerformance());

    this._systemLayoutService.hasCycleChanged$.pipe(this._untilDestroyed()).subscribe(() => window.location.reload());
  }
}
