import { Component, inject, input, OnInit, signal } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { forkJoin } from "rxjs";

import { AdaaHelper } from "../../../core/utils";
import {
  DataTableComponent,
  FormActionButtonsComponent,
  FormCheckboxComponent,
  FormDropdownComponent,
  FormDropdownMultiComponent,
  FormInputComponent,
} from "../../../shared/components";
import { Constants } from "../../../shared/constants/constants";
import { AdaaBoolean, PageMode } from "../../../shared/constants/enums";
import {
  DataEntryMetricModel,
  DataEntryMetricResponse,
  MetricDimensionValue,
  MonitoredKpiModelType,
  ParentMetric,
  ValidatorModelType,
  ValueText,
} from "../../../shared/models";
import {
  EntitiesApiService,
  MetricApiService,
  PropertiesService,
  SystemLayoutService,
  ValidatorApiService,
} from "../../../shared/services";
import { MetricsDimensionsEditorComponent } from "./metrics-dimensions-editor/metrics-dimensions-editor.component";

@Component({
  selector: "adaa-metrics-editor",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormInputComponent,
    FormDropdownComponent,
    FormDropdownMultiComponent,
    FormCheckboxComponent,
    FormActionButtonsComponent,
    TranslateModule,
    DataTableComponent,
    MetricsDimensionsEditorComponent,
  ],
  templateUrl: "./metrics-editor.component.html",
  styleUrl: "./metrics-editor.component.scss",
})
export class MetricsEditorComponent implements OnInit {
  private _propsService = inject(PropertiesService);
  private _metricsApiService = inject(MetricApiService);
  private _entitiesApiService = inject(EntitiesApiService);
  private _formBuilder = inject(FormBuilder);
  private _validatorApiService = inject(ValidatorApiService);
  private _translateService = inject(TranslateService);
  private _toastrService = inject(ToastrService);
  private _router = inject(Router);
  private _systemLayoutService = inject(SystemLayoutService);

  pageMode = input.required<string>();
  id = input.required<string>();

  private _untilDestroy = AdaaHelper.untilDestroyed();

  submitted: boolean = false;
  metricsForm: FormGroup;
  formValidation = signal<ValidatorModelType | undefined>(undefined);
  metric = signal<DataEntryMetricModel | undefined>(undefined);
  entityId = signal<number | undefined>(undefined);
  PageMode = PageMode;
  Constants = Constants;
  dataTypes = signal<ValueText[]>([]);
  dashboardTypes = signal<ValueText[]>([]);
  mainTypes = signal<ValueText[]>([]);
  entities = signal<ValueText[]>([]);
  dataEntries = signal<DataEntryMetricResponse[]>([]);

  public get displayLabel(): string {
    const nameEN = this.metricsForm.get("nameEN")?.value;
    const nameAE = this.metricsForm.get("nameAE")?.value;

    return AdaaHelper.getPageLabel(nameAE, nameEN);
  }

  public ngOnInit(): void {
    this._systemLayoutService.hasActiveEntityChanged$.pipe(this._untilDestroy()).subscribe({
      next: () => this.goBack(),
    });

    this._prepareForm();
    this._initPage();

    if (this.id()) this._getMetric();
  }

  public submit() {
    this._updateValidationByMetricType();
    this.submitted = true;

    if (!this._checkMonitoringIsValid()) {
      this._toastrService.error(this._translateService.instant("metrics.showError.dimension_error"));
      return;
    }

    if (this.metricsForm.invalid) {
      this._toastrService.error(this._translateService.instant("notification.warning.missing_info"));
      return;
    }

    const result = {
      ...(!AdaaHelper.isPMOEntity() ? { entityIds: [this.entityId()] } : {}),
      ...(!AdaaHelper.isPMOEntity() ? { type: Constants.METRICS_TYPE.LOCAL } : {}),
      ...this.metric(),
      ...this.metricsForm.getRawValue(),
      status: Constants.OBJECT_STATUS.ACTIVE,
    };

    //If the metrics type is Dimenstion delete the entityIds.It's causing duplication in the metric itself.
    if (this.metricsForm.get("type")?.value === Constants.METRICS_TYPE.DIMENSION) {
      delete result.entityIds;
    }

    const url =
      this.pageMode() === PageMode.create
        ? this._metricsApiService.createMetric(result)
        : this._metricsApiService.updateMetric(result);

    url.subscribe({
      next: (response) => {
        if (response.inError) return;
        this._toastrService.success(this._translateService.instant("notification.success.save"));
        this._router.navigateByUrl("/console/metrics");
      },
    });
  }

  public actionClicked() {
    this._router.navigateByUrl(`/console/data-entry/metrics/${this.id()}`);
  }

  public goBack() {
    this._router.navigateByUrl("/console/metrics");
  }

  public metricTypeChange(value: string) {
    if (value === Constants.METRICS_TYPE.DIMENSION) {
      this.metricsForm.get("entityIds")?.setValue(null);
      this.metricsForm.get("dataSourceAE")?.disable();
      this.metricsForm.get("dataSourceEN")?.disable();
    } else {
      this.metricsForm.get("parentMetricEntityId")?.setValue(null);
      this.metricsForm.get("dataSourceAE")?.enable();
      this.metricsForm.get("dataSourceEN")?.enable();
    }
  }

  public parentMetricChanged(value: ParentMetric) {
    this.metricsForm.get("nameAE")?.setValue(value.nameAE);
    this.metricsForm.get("nameEN")?.setValue(value.nameEN);
    this.metricsForm.get("dscAE")?.setValue(value.nameAE);
    this.metricsForm.get("dscEN")?.setValue(value.nameEN);
    this.metricsForm.get("dataSourceAE")?.setValue(value.dataSourceAE);
    this.metricsForm.get("dataSourceEN")?.setValue(value.dataSourceEN);
  }

  private _initPage() {
    forkJoin({
      validators: this._validatorApiService.searchByKey(Constants.VALIDATORS_CONF_KEY.VALIDATION_METRICS),
      valueTypes: this._propsService.getPropById(Constants.CONSTANT_MEASUREMENT_UNIT_METRICS),
      dashboardTypes: this._metricsApiService.getAvailableDashboardTypes(),
      entities: this._entitiesApiService.getAll(AdaaBoolean.Y),
      translations: this._translateService.get([
        "main_types.G",
        "main_types.L",
        "main_types.D",
        "metrics.metrics_used_kpi",
      ]),
      ...(this.id() ? { relatedKPIs: this._metricsApiService.getRelatedKpis(+this.id()) } : {}),
    }).subscribe({
      next: (results) => {
        if (!results.validators.inError) this.formValidation.set(results.validators.responseData);

        if (!results.valueTypes.inError)
          this.dataTypes.set(
            AdaaHelper.setDropdownArray(results.valueTypes.responseData, "id", AdaaHelper.getFieldLanguage("name"))
          );

        if (!results.dashboardTypes.inError)
          this.dashboardTypes.set(
            AdaaHelper.setDropdownArray(results.dashboardTypes.responseData, "id", AdaaHelper.getFieldLanguage("name"))
          );

        if (!results.entities.inError)
          this.entities.set(
            AdaaHelper.setDropdownArray(
              AdaaHelper.isPMOEntity()
                ? results.entities.responseData.filter((e) => e.id !== Constants.CONSTANT_PMO_ID)
                : results.entities.responseData.filter((e) => e.id === AdaaHelper.entity.id),
              "id",
              AdaaHelper.getFieldLanguage("name")
            )
          );

        // REFERENCE: disabling a form control without removing the control's value i.e set/patch the value first then disable with the options below.
        if (!AdaaHelper.isPMOEntity() && this.pageMode() !== PageMode.view) {
          this.metricsForm.patchValue({ type: Constants.METRICS_TYPE.LOCAL, entityIds: [AdaaHelper.entity.id] });
          this.metricsForm.get("entityIds")?.disable({ onlySelf: true, emitEvent: false });
          this.metricsForm.get("type")?.disable({ onlySelf: true, emitEvent: false });
        }

        this.mainTypes.set([
          { value: Constants.METRICS_TYPE.GLOBAL, text: results.translations["main_types.G"] },
          { value: Constants.METRICS_TYPE.LOCAL, text: results.translations["main_types.L"] },
          { value: Constants.METRICS_TYPE.DIMENSION, text: results.translations["main_types.D"] },
        ]);

        if (results.relatedKPIs?.responseData.length) {
          const field = AdaaHelper.getFieldLanguage("name");
          const kpis = results.relatedKPIs?.responseData.map((kpi) => kpi[field as keyof MonitoredKpiModelType]);
          const htmlString = `
            ${results.translations["metrics.metrics_used_kpi"]}
            <ul> ${kpis?.map((item) => `<li>${item}</li>`).join("")}</ul>
          `;
          this._toastrService.warning(htmlString, "", { enableHtml: true });
        }
      },
    });
  }

  private _prepareForm() {
    const isViewMode = this.pageMode() === PageMode.view;

    this.metricsForm = this._formBuilder.group({
      nameAE: [{ value: "", disabled: isViewMode }, Validators.required],
      nameEN: [{ value: "", disabled: isViewMode }, Validators.required],
      dscAE: [{ value: "", disabled: isViewMode }, Validators.required],
      dscEN: [{ value: "", disabled: isViewMode }, Validators.required],
      dataSourceAE: { value: "", disabled: isViewMode },
      dataSourceEN: { value: "", disabled: isViewMode },
      dataType: [{ value: null, disabled: isViewMode }, Validators.required],
      type: [{ value: null, disabled: isViewMode }, Validators.required],
      dashboardType: { value: null, disabled: isViewMode },
      entityIds: [{ value: null, disabled: isViewMode }, Validators.required],
      available: { value: "", disabled: isViewMode },
      parentMetricEntityId: [{ value: null, disabled: isViewMode }, Validators.required],
      parentMetricId: [{ value: null, disabled: isViewMode }, Validators.required],
      dimensions: { value: null, disabled: isViewMode },
    });

    let isProgrammaticallyUpdating = false;
    this.metricsForm.valueChanges.pipe(this._untilDestroy()).subscribe((value) => {
      if (isProgrammaticallyUpdating) return;

      if (this.pageMode() !== PageMode.view && AdaaHelper.isPMOEntity()) {
        const entityIdsControl = this.metricsForm.get("entityIds")!;

        if (value.type === Constants.METRICS_TYPE.GLOBAL) {
          if (entityIdsControl?.value !== null || entityIdsControl.enabled) {
            isProgrammaticallyUpdating = true;
            entityIdsControl.setValue(null, { emitEvent: false });
            entityIdsControl.disable({ emitEvent: false });
            isProgrammaticallyUpdating = false;
          }
        } else {
          if (entityIdsControl?.disabled) {
            isProgrammaticallyUpdating = true;
            entityIdsControl.enable({ emitEvent: false });
            isProgrammaticallyUpdating = false;
          }
        }
      }
    });
  }

  private _handleControls(type: string | undefined, synced: AdaaBoolean | undefined) {
    if (type !== Constants.METRICS_TYPE.LOCAL && synced === AdaaBoolean.N) {
      this.metricsForm.get("dataType")?.disable({ onlySelf: true, emitEvent: false });
      this.metricsForm.get("type")?.disable({ onlySelf: true, emitEvent: false });
      this.metricsForm.get("entityIds")?.disable({ onlySelf: true, emitEvent: false });
    } else if (type === Constants.METRICS_TYPE.GLOBAL && synced === AdaaBoolean.Y) {
      Object.keys(this.metricsForm.controls).forEach((controlName) => {
        if (controlName === "available") return;
        const control = this.metricsForm.get(controlName);
        control?.disable({ onlySelf: true, emitEvent: false });
      });
    }
  }

  private _getMetric() {
    forkJoin({
      metric: this._metricsApiService.getById(+this.id()),
      dataEntries: this._metricsApiService.getDataEntryByMetricId(+this.id()),
    }).subscribe({
      next: (response) => {
        this.metricsForm.patchValue(response.metric.responseData);

        this.metric.set(response.metric.responseData);
        this.dataEntries.set(response.dataEntries.responseData);

        this._handleControls(response.metric.responseData.type, response.metric.responseData.synced);
      },
    });
  }

  private _updateValidationByMetricType() {
    const monitoringFields = ["dashboardType", "parentMetricEntityId", "parentMetricId"];
    if (this.metricsForm.get("type")?.value === Constants.METRICS_TYPE.DIMENSION) {
      monitoringFields.forEach((e) => {
        this.metricsForm.get(e)?.setValidators(Validators.required);
        this.metricsForm.get(e)?.updateValueAndValidity();
      });
      this.metricsForm.get("entityIds")?.clearValidators();
      this.metricsForm.get("entityIds")?.updateValueAndValidity();
    } else {
      monitoringFields.forEach((e) => {
        this.metricsForm.get(e)?.clearValidators();
        this.metricsForm.get(e)?.updateValueAndValidity();
      });
      this.metricsForm.get("entityIds")?.setValidators(Validators.required);
      this.metricsForm.get("entityIds")?.updateValueAndValidity();
    }

    this.metricsForm.updateValueAndValidity();
  }

  private _checkMonitoringIsValid(): boolean {
    if (this.metricsForm.get("type")?.value !== Constants.METRICS_TYPE.DIMENSION) return true;

    const dimensions = (this.metricsForm.get("dimensions")?.value as MetricDimensionValue[]) ?? [];

    return dimensions.filter((e) => e.dimensionValueIds == undefined || e.dimensionValueIds.length === 0).length <= 0;
  }
}
