import { Component, inject, isDevMode, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { forkJoin, map } from "rxjs";

import { AdaaHelper } from "../../../core/utils";
import {
  AuthLayoutComponent,
  FormCheckboxComponent,
  FormInputComponent,
  FormRadioComponent,
} from "../../../shared/components";
import { Constants } from "../../../shared/constants/constants";
import { Language, PermissionAction, VerificationMethod } from "../../../shared/constants/enums";
import {
  LoginResponse,
  MainResponseModel,
  RadioOption,
  type UserSecurityQuestionModelType,
  VerificationRequestEmail,
  VerificationRequestSms,
  VerificationRequestSubmit,
} from "../../../shared/models";
import {
  AppService,
  ConsoleService,
  LoginService,
  PermissionsService,
  UserProfileApiService,
} from "../../../shared/services";

@Component({
  selector: "adaa-login-verification",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    FormInputComponent,
    FormCheckboxComponent,
    FormRadioComponent,
    AuthLayoutComponent,
  ],
  templateUrl: "./login-verification.component.html",
  styleUrl: "./login-verification.component.scss",
})
export class LoginVerificationComponent implements OnInit {
  private _router = inject(Router);
  private _formBuilder = inject(FormBuilder);
  private _translateService = inject(TranslateService);
  private _toastrService = inject(ToastrService);
  private _appService = inject(AppService);
  private _loginService = inject(LoginService);
  private _permissionService = inject(PermissionsService);
  private _userProfileApiService = inject(UserProfileApiService);
  private _consoleService = inject(ConsoleService);

  user: LoginResponse;
  verificationForm: FormGroup;
  lockCounter = 0;
  verificationMethod = VerificationMethod;

  loginVerificationOptions: RadioOption[];

  constructor() {
    //You need to call this in the constructor not in onInit before the navigation is finished
    this.user = this._router.getCurrentNavigation()?.extras.state?.["user"];

    if (!AdaaHelper.isDefined(this.user)) {
      this._router.navigate(["/login"]);
    }
  }

  public ngOnInit(): void {
    this._prepareForm();

    this._translateService.get(["common.form.label.email", "login.text_message"]).subscribe((values) => {
      this.loginVerificationOptions = [
        {
          value: this.verificationMethod.EMAIL,
          text: values["common.form.label.email"],
        },
        {
          value: this.verificationMethod.SMS,
          text: `${values["login.text_message"]} ${this._getEncodeMobileNumber(this.user)}`,
        },
      ];
    });
  }

  public verificationMethodUpdated(value: VerificationMethod): void {
    this.verificationForm.get("method")?.setValue(value);
  }

  public sendVerificationCode(): void {
    const method = this.verificationForm.get("method")!.value as VerificationMethod;

    switch (method) {
      case VerificationMethod.EMAIL:
        this._sendVerificationCodeByEmail();
        break;
      case VerificationMethod.SMS:
        this._sendVerificationCodeBySms();
        break;
      default:
        break;
    }
  }

  public submit(): void {
    if (!this.verificationForm.valid) {
      return;
    }

    const model: VerificationRequestSubmit = {
      token: this.verificationForm.get("verificationCode")?.value,
      doNotAskAgain: this.verificationForm.get("rememberMe")?.value,
      userId: this.user.id,
    };

    this._loginService.validateVerificationCode(model).subscribe((response: MainResponseModel) => {
      if (response.responseCode === "1") {
        this._saveLoginInfo();
        this._onSuccessfulVerification();
      }
    });
  }

  private _sendVerificationCodeByEmail(): void {
    const model: VerificationRequestEmail = {
      email: this.user.email,
      text: "code",
      userId: this.user.id,
      subject: "",
    };

    this._loginService
      .fetchVerificationCode(model, VerificationMethod.EMAIL)
      .subscribe((response: MainResponseModel) => {
        if (response.responseCode === "1") {
          this._lockVerificationButton();
        }
      });
  }

  private _sendVerificationCodeBySms(): void {
    const model: VerificationRequestSms = {
      language: this._appService.language(),
      text: "code",
      userId: this.user.id,
    };

    this._loginService.fetchVerificationCode(model, VerificationMethod.SMS).subscribe((response: MainResponseModel) => {
      if (response.responseCode === "1") {
        this._lockVerificationButton();
      }
    });
  }

  /**
   * @description To lock the verification button from keep sending requests to
   * the API, the user will be able to resend the verification code in defined number.
   */
  private _lockVerificationButton(): void {
    this.lockCounter = 30;
    setInterval(() => {
      if (this.lockCounter !== 0) {
        this.lockCounter -= 1;
      }
    }, 1000);
  }

  /**
   * @description To prepare the verification form
   */
  private _prepareForm(): void {
    this.verificationForm = this._formBuilder.group({
      method: this.verificationMethod.EMAIL,
      verificationCode: [null, [Validators.required, Validators.maxLength(6)]],
      rememberMe: true,
    });
  }

  /**
   * @description To encode the mobile number so we can display just the last three digits.
   * @param userInformation
   */
  private _getEncodeMobileNumber(userInformation: LoginResponse): string {
    let encodedMobilePhone = "";
    if (userInformation) {
      if (userInformation.mobilePhone) {
        encodedMobilePhone = AdaaHelper.clone(userInformation.mobilePhone);
        const lastThreeDigits = encodedMobilePhone.slice(-3);
        const mobileNumberStars = encodedMobilePhone.replace(/\S/gi, "*");
        const substr = mobileNumberStars.length - 3;
        encodedMobilePhone =
          this._appService.language() === Language.English
            ? mobileNumberStars.slice(0, substr) + lastThreeDigits
            : lastThreeDigits + mobileNumberStars.slice(0, substr);
      }
    }
    return encodedMobilePhone;
  }

  /**
   * @description To set cookies and user information in the localStorage
   */
  private _saveLoginInfo(): void {
    AdaaHelper.setLocalStorage(Constants.localStorageKeys.checkLogin, "1", "string");
    AdaaHelper.setLocalStorage(Constants.localStorageKeys.sessionId, this.user.sessionId, "string");

    let rememberMe = this.verificationForm.get("rememberMe")?.value === true;

    if (isDevMode() && window.location.hostname === "localhost") {
      // To make the login easier for developers
      rememberMe = true;
    }

    if (rememberMe) {
      let visitValue = rememberMe ? "0" : "1";
      if (isDevMode() && window.location.hostname === "localhost") {
        // To make the login easier for developers
        visitValue = "0";
      }

      //Used every login
      AdaaHelper.setLocalStorage(Constants.localStorageKeys.firstVisit, visitValue, "string");
      AdaaHelper.setLocalStorage(Constants.localStorageKeys.lastUser, this.user.username, "string");
    }
    //User to get the current user info in the system
    AdaaHelper.setLocalStorage(Constants.localStorageKeys.user, this.user, "json");
  }

  private _onSuccessfulVerification() {
    const request$ = [this._permissionService.loadUserPermissions(), this._userProfileApiService.getSecurityQuestion()];

    const obs$ = forkJoin(request$).pipe(
      map(([, securityQuestionsResponse]): [root: string, path: string] => {
        const response = securityQuestionsResponse as MainResponseModel<UserSecurityQuestionModelType[]>;

        if (response.inError) {
          return ["", "user-profile"];
        }

        const url = this._getRedirectUrl(response.responseData.length > 0);
        if (url) {
          return url;
        }

        return ["", "user-profile"];
      })
    );

    obs$.subscribe((route) => {
      this._router.navigate(route);
    });
  }

  private _getRedirectUrl(hasSecurityQuestions: boolean): [root: string, path: string] | void {
    const hasTpDashboardPermission = this._permissionService.hasPermission(
      [
        {
          objectTypeId: Constants.CONSTANT_PERMISSIONS.NATIONAL_PROJECTS_DASHBOARD,
          permissionAction: PermissionAction.view,
        },
      ],
      "and"
    );

    const hasHomepagePermission = this._permissionService.hasPermission(
      [
        {
          objectTypeId: Constants.CONSTANT_PERMISSIONS.HOMEPAGE,
          permissionAction: PermissionAction.view,
        },
      ],
      "and"
    );

    //THIS TO GET THE CONSOLE AFTER THE FIRST LOGIN
    //THE CACHE SHOULD BE CLEARED
    this._consoleService.init(false);

    if (!hasSecurityQuestions) {
      this._toastrService.warning(this._translateService.instant("notification.warning.required_field_user_profile"));
      return ["", "user-profile"];
    }
    if (hasHomepagePermission) {
      return ["", "dashboard/target"];
    }
    if (hasTpDashboardPermission) {
      return ["", "national-projects-dashboard"];
    }
  }
}
