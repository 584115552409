<ng-template #content let-modal>
    <div class="modal-header">
        <h5 class="text-center fw-bold w-100">
            {{ pageMode() === PageMode.create ? ("entity.create.add_new_org_unit" | translate) : ("provisioning.organization_structure" | translate)
            }}
        </h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.close()"></button>
    </div>
    <div class="modal-body overflow-x-hidden p-3">
        <form class="col-12" [formGroup]="orgUnitForm">
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'entity.create.nameAE' | translate"
                        formControlName="nameAE"
                        controlName="nameAE"
                        [setValidator]="orgUnitEditorService.formValidation()!"
                        [invalid]="checkInvalid('nameAE')"
                    ></adaa-form-input>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'entity.create.nameEN' | translate"
                        formControlName="nameEN"
                        controlName="nameEN"
                        [setValidator]="orgUnitEditorService.formValidation()!"
                        [invalid]="checkInvalid('nameEN')"
                    ></adaa-form-input>
                </div>
            </div>
            @if (pageMode() === PageMode.create) {
            <div class="col-12 mb-3">
                <adaa-form-input
                    [label]="'entity.create.part_of' | translate"
                    formControlName="parentOrgUnit"
                    controlName="parentOrgUnit"
                    [setValidator]="orgUnitEditorService.formValidation()!"
                    [invalid]="checkInvalid('parentOrgUnit')"
                ></adaa-form-input>
            </div>
            } @if (pageMode() === PageMode.edit) {
            <div class="col-12 mb-3">
                <adaa-form-dropdown-tree
                    [label]="'entity.create.part_of' | translate"
                    [options]="orgUnitOptions()"
                    formControlName="parentOrgUnit"
                    controlName="parentOrgUnit"
                    [setDefaultValue]="selectedUnitOption()"
                    [setValidator]="orgUnitEditorService.formValidation()!"
                    [invalid]="checkInvalid('parentOrgUnit')"
                ></adaa-form-dropdown-tree>
            </div>
            }
        </form>
    </div>
    <div class="modal-footer d-flex justify-content-center">
        <div class="my-2">
            <adaa-form-action-buttons (cancelClicked)="modal.close()" (saveClicked)="submit()"></adaa-form-action-buttons>
        </div>
    </div>
</ng-template>
