import { Component, computed, effect, EventEmitter, inject, input, Output } from "@angular/core";
import { ControlContainer, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { TranslatePipe } from "@ngx-translate/core";

import { AdaaHelper } from "../../../../../../../core/utils";
import { FormDropdownMultiComponent } from "../../../../../../../shared/components";
import { Constants } from "../../../../../../../shared/constants/constants";
import { FormControlDisabledDirective } from "../../../../../../../shared/directives";
import { ObjectiveModelType, ValueText } from "../../../../../../../shared/models";

@Component({
  selector: "adaa-contributing-entities-dropdown-field",
  standalone: true,
  imports: [FormControlDisabledDirective, FormDropdownMultiComponent, ReactiveFormsModule, TranslatePipe],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true }),
    },
  ],
  template: `
    <section class="d-flex row">
      <div class="d-block col-12 pt-2">
        <adaa-form-dropdown-multi
          [controlName]="field()"
          [formControlName]="field()"
          [clearable]="true"
          [searchable]="true"
          [label]="label() | translate"
          [options]="listOptions()"
          [invalid]="model.get(field())?.touched! && model.get(field())?.invalid!"
          [showAsRequired]="isRequired()"
          [adaaInputDisabled]="isDisabled()"
        />
      </div>
    </section>
  `,
})
export class ContributingEntitiesDropdownFieldComponent {
  readonly form = inject(ControlContainer);

  isRequired = input.required<boolean>();
  isDisabled = input.required<boolean>();
  kpiType = input.required<number>();
  label = input.required<string>();
  list = input<unknown[]>([]);
  field = input.required<"contributingEntities" | "otherContributingEntities">();

  listOptions = computed<ValueText[]>(() =>
    AdaaHelper.setDropdownArray(this.list(), "id", AdaaHelper.getFieldLanguage("name"))
  );

  isDTKPI = computed(() => this.kpiType() === Constants.KPI_TYPE.DTKPI);
  isNTKPI = computed(() => this.kpiType() === Constants.KPI_TYPE.NTKPI);

  @Output() onChange = new EventEmitter<{ value: number; list: ObjectiveModelType[] }>();

  readonly #disableInputEffect = () => {
    effect((onCleanup) => {
      const disable = this.isDisabled();
      if (disable) {
        this.model.controls[this.field()]?.disable({ onlySelf: true, emitEvent: true });
      } else {
        this.model.controls[this.field()]?.enable({ onlySelf: true, emitEvent: true });
      }

      onCleanup(() => {
        this.model.controls[this.field()]?.enable({ onlySelf: true, emitEvent: true });
      });
    });
  };

  public get model() {
    return this.form.control as FormGroup;
  }

  constructor() {
    this.#disableInputEffect();
  }
}
