<div class="w-100 d-flex justify-content-center align-content-center card mb-2 py-1 px-2 bg-white border-0 form-section">
    <adaa-data-table
        titleHeader="ekpi.data_input"
        [configKey]="constants.MAIN_TABLE_LIST_CONF_KEY.EKPI_DATA_INPUT_CONF_LIST"
        [enablePrint]="false"
        [enableAdd]="false"
        [enableActionsCell]="false"
        [isLocalData]="true"
        [localData]="affectedEntities()"
        [tableInputs]="tableInputs"
        (inputListChanged)="inputListChanged($event)"
    />
</div>
