<adaa-data-table
    #adaaDataTable
    [apiName]="'getAgreements'"
    [titleHeader]="breadcrumb() | translate"
    [configKey]="Constants.MAIN_TABLE_LIST_CONF_KEY.PROJECT_AGREEMENT_CONF_LIST"
    (addNewClicked)="agreement.open()"
    (actionClicked)="getAction($event)"
    [customActionButtons]="customButtons"
    [enableEditAction]="false"
    [enableAdd]="AdaaHelper.isPMOEntity()"
    [enableDeleteAction]="AdaaHelper.isPMOEntity()"
/>

<adaa-generate-agreement #agreement (agreement)="loadData($event)" />

<adaa-float-action />
