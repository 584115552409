import { Component, inject, signal, viewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { merge } from "rxjs";

import { AdaaHelper } from "../../../core/utils";
import { ConfirmationModalComponent, DataTableComponent, FloatActionComponent } from "../../../shared/components";
import { genericFloatButtons } from "../../../shared/components/general/float-action";
import { Constants } from "../../../shared/constants/constants";
import { Language, PageMode } from "../../../shared/constants/enums";
import { CustomButton, EntityReportModelType, TableButtonClicked } from "../../../shared/models";
import { AppService, FilesApiService, SystemLayoutService } from "../../../shared/services";
import { EntityReportApiService } from "../../../shared/services/entity-report-api.service";
import { EntityReportsEditorComponent } from "../entity-reports-editor/entity-reports-editor.component";

@Component({
  selector: "adaa-entity-reports-list",
  standalone: true,
  imports: [DataTableComponent, FloatActionComponent, EntityReportsEditorComponent, TranslateModule],
  templateUrl: "./entity-reports-list.component.html",
  styleUrl: "./entity-reports-list.component.scss",
})
export class EntityReportsListComponent {
  private _filesApiService = inject(FilesApiService);
  private _entityReportApiService = inject(EntityReportApiService);
  private readonly _systemLayoutService = inject(SystemLayoutService);

  private _toastrService = inject(ToastrService);
  private _translateService = inject(TranslateService);
  private _modalService = inject(NgbModal);
  private _appService = inject(AppService);

  adaaDataTable = viewChild.required<DataTableComponent>("adaaDataTable");
  reportEditor = viewChild.required<EntityReportsEditorComponent>("editor");
  pageMode = signal<string>("view");
  readonly #untilDestroy = AdaaHelper.untilDestroyed();
  private readonly _genericFloatButtons = genericFloatButtons();

  constants = Constants;
  customButtons: CustomButton[] = [
    {
      iconName: "adaa-icon-download",
      eventName: "download",
      iconTitle: "tooltips.download",
    },
  ];

  currentEntityReport = signal<EntityReportModelType>({
    id: 0,
    status: 0,
    nameAE: "",
    nameEN: "",
    entityId: 0,
    updateTS: 0,
    auxAttachFile: { id: 0, filename: "" },
    filename: "",
  });

  public ngOnInit() {
    const hasActiveEntityChanged = this._systemLayoutService.hasActiveEntityChanged$;
    const hasCycleChanged = this._systemLayoutService.hasCycleChanged$;
    merge(hasActiveEntityChanged, hasCycleChanged)
      .pipe(this.#untilDestroy())
      .subscribe({
        next: () => {
          this.adaaDataTable().resetFilters();
        },
      });
    this._floatActions();
  }
  public addNew() {
    this.pageMode.set("create");
    this.reportEditor().reportForm.reset({});
    this.reportEditor().reportForm.enable();
    this.reportEditor().open();
  }

  public actionClicked(event: TableButtonClicked & { index: number }) {
    const report = event.data;
    switch (event.event) {
      case "edit":
        this._handleReportEditor(event, PageMode.edit);
        break;
      case "download":
        this._filesApiService.save(report.id, report.filename);
        break;
      case "delete":
        this._deleteReport(event);
        break;

      default:
        break;
    }
  }

  private _handleReportEditor(value: TableButtonClicked, mode: string) {
    this.currentEntityReport.set(value.data);
    this.pageMode.set(mode);
    this.reportEditor().reportForm.patchValue(value.data);

    this.reportEditor().open();
  }

  private _deleteReport(value: TableButtonClicked) {
    const modal = this._modalService.open(ConfirmationModalComponent, {
      animation: true,
      scrollable: false,
      keyboard: false,
      size: "lg",
      centered: true,
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });

    modal.componentInstance.header = "documents.remove_file_title";
    modal.componentInstance.title = "documents.remove_file";

    modal.result.then((e) => {
      if (e) {
        this._entityReportApiService.deleteEntityReport(value.data.id).subscribe({
          next: (response) => {
            if (response.inError) return;
            this._toastrService.success(this._translateService.instant("notification.success.remove"));
            this.adaaDataTable().loadTableData();
          },
        });
      }
    });
  }

  public submit(data: EntityReportModelType) {
    const serviceUrl = this._entityReportApiService.updateItemAttachment(data);

    serviceUrl.subscribe({
      next: (response) => {
        if (response.inError) return;
        this._modalService.dismissAll();
        this._toastrService.success(this._translateService.instant("notification.success.save"));
        this.adaaDataTable().loadTableData();
      },
    });
  }

  private _floatActions(): void {
    this._genericFloatButtons([
      {
        key: "helpdesk",
        data: {
          url: "/helpdesk/create",
        },
      },
    ]);
  }
}
