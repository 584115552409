import { NgClass } from "@angular/common";
import { Component, computed, inject, OnInit, signal } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { NgbActiveModal, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { TranslatePipe } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { distinctUntilChanged, filter, ignoreElements, map, switchMap, tap } from "rxjs";

import { AdaaHelper } from "../../../../../../core/utils";
import {
  FormDropdownComponent,
  FormDropdownTreeComponent,
  FormInputComponent,
  FormInputDateComponent,
  FormStepsComponent,
  TeamMembersListComponent,
} from "../../../../../../shared/components";
import { Constants } from "../../../../../../shared/constants/constants";
import { PageMode } from "../../../../../../shared/constants/enums";
import { FormControlDisabledDirective } from "../../../../../../shared/directives";
import {
  EntityModelType,
  FormTab,
  NationalTargetImprovementPlanType,
  ObjectStatus,
  OrgUnit,
  ParameterCatalog,
  TeamMemberType,
  UserModelType,
  ValueText,
} from "../../../../../../shared/models";
import {
  KpisService,
  LanguageService,
  OrgUnitApiService,
  UsersApiService,
  ValidatorApiService,
} from "../../../../../../shared/services";
import { improvementPlanForm, ImprovementPlanFormType } from "./form";

@Component({
  selector: "adaa-improvement-plan-form",
  imports: [
    TranslatePipe,
    ReactiveFormsModule,
    FormStepsComponent,
    NgClass,
    TeamMembersListComponent,
    FormInputComponent,
    FormInputDateComponent,
    FormControlDisabledDirective,
    FormDropdownComponent,
    FormDropdownTreeComponent,
  ],
  templateUrl: "./improvement-plan-form.component.html",
  styleUrl: "./improvement-plan-form.component.scss",
})
export class ImprovementPlanFormComponent implements OnInit {
  readonly modal = inject(NgbActiveModal);
  readonly languageService = inject(LanguageService);
  private readonly _toastrService = inject(ToastrService);
  private readonly _orgUnitApiService = inject(OrgUnitApiService);
  private readonly _validatorApiService = inject(ValidatorApiService);
  private readonly _usersApiService = inject(UsersApiService);
  private readonly _kpisService = inject(KpisService);

  readonly itemIndex = signal<number>(-1);
  readonly kpiType = signal<number>(0);
  readonly kpi = signal<Record<string, unknown> | undefined>(undefined);
  readonly mode = signal<PageMode>(PageMode.create);
  readonly entities = signal<EntityModelType[]>([]);
  readonly owners = signal<UserModelType[]>([]);
  readonly orgUnits = signal<OrgUnit[]>([]);
  readonly activeTab = signal<number>(0);
  readonly tabs = signal<FormTab[]>([]);
  readonly teamMembers = signal<TeamMemberType[]>([]);
  readonly plan = signal<NationalTargetImprovementPlanType | undefined>(undefined);
  readonly validations = signal<ParameterCatalog[]>([]);

  readonly orgUnitsOptions = computed(() => {
    return [
      { value: undefined, text: this.languageService.translate("common.form.label.none") },
      ...AdaaHelper.setDropdownArray(this.orgUnits() ?? [], "id", AdaaHelper.getFieldLanguage("name"), "parentOrgUnit"),
    ];
  });
  readonly entityOptions = computed<ValueText[]>(() =>
    AdaaHelper.setDropdownArray(this.entities(), "id", AdaaHelper.getFieldLanguage("name"))
  );
  readonly ownersOptions = computed<ValueText[]>(() =>
    AdaaHelper.setDropdownArray(this.owners(), "id", AdaaHelper.getFieldLanguage("name"))
  );
  readonly plannedDateLimits = computed(() => {
    const kpi = this.kpi()!;
    const { startDate, endDate } = kpi as { startDate: number; endDate: number };
    const maxDate = new Date(endDate);
    const minDate = new Date(startDate);

    return {
      min: {
        year: minDate.getFullYear(),
        month: minDate.getMonth() + 1,
        day: minDate.getDate(),
      } as NgbDateStruct,
      max: {
        year: maxDate.getFullYear(),
        month: maxDate.getMonth() + 1,
        day: maxDate.getDate(),
      } as NgbDateStruct,
    };
  });

  readonly tableConfList = Constants.MAIN_TABLE_LIST_CONF_KEY.MILESTONE_MEMBERS_CONF_LIST;
  readonly isDefined = AdaaHelper.isDefined.bind(AdaaHelper);
  readonly isFieldRequired = AdaaHelper.isFieldRequired.bind(AdaaHelper);

  readonly #untilDestroy = AdaaHelper.untilDestroyed();

  model: ImprovementPlanFormType;

  public ngOnInit() {
    this._setTabs();
    this._fetchValidator();
  }

  public appendOrgUnit($event: number) {
    this.model?.get("orgUnitId")?.setValue($event);
  }

  public submitTeamMembers(members: TeamMemberType[]) {
    this.teamMembers.set(members);
  }

  public save() {
    this.model.markAllAsTouched();
    if (this.model.invalid || this.itemIndex() === -1) {
      this._toastrService.warning(this.languageService.translate("notification.warning.missing_info"));
      return;
    }

    const data = this.model.getRawValue();
    const owner = this.owners().find(({ id }) => id === data.owner);
    const orgUnit = this.orgUnits().find(({ id }) => id === data.orgUnitId);
    if (this.mode() === PageMode.create) {
      const order = this.itemIndex() + 1;
      this.modal.close({
        ...data,
        ownerNameAE: owner?.nameAE,
        ownerNameEN: owner?.nameEN,
        orgUnitNameAE: orgUnit?.nameAE,
        orgUnitNameEN: orgUnit?.nameEN,
        status: ObjectStatus.DRAFT,
        improvementPlanOrder: order,
        teamMembers: this.teamMembers(),
      });
      return;
    }

    this.modal.close({
      ...this.plan(),
      ...data,
      teamMembers: this.teamMembers(),
    });
  }

  public cancel() {
    this.model?.reset();
    this.modal.dismiss();
  }

  private _fetchValidator() {
    this._validatorApiService
      .searchByKey(Constants.VALIDATORS_CONF_KEY.VALIDATION_IMPROVEMENT_PLAN_FORM)
      .pipe(
        filter((res) => !res.inError),
        map((res) => res.responseData?.parameterCatalogs ?? [])
      )
      .subscribe({
        next: (catalog) => {
          this.validations.set(catalog);
          this.model = improvementPlanForm(catalog);
        },
        complete: () => {
          this._captureOrgUnitChanges();
          this._fetchOrgUnits();
          this._setDates();
          this._setModel();
        },
      });
  }

  private _captureOrgUnitChanges() {
    this.model
      ?.get("orgUnitId")
      ?.valueChanges.pipe(
        this.#untilDestroy(),
        filter((value) => AdaaHelper.isDefined(value)),
        distinctUntilChanged(),
        switchMap((value) => this._fetchOwners(value))
      )
      .subscribe();
  }

  private _fetchOrgUnits() {
    const entityId = this.kpi()?.sponsorEntityId as number | undefined;

    if (!entityId) {
      this.model.get("orgUnitId")?.setValue(null);
      this.orgUnits.set([]);
      return;
    }

    this._orgUnitApiService
      .getbyEntityidAndObjectype(entityId, this._kpisService.getKpiPermission(this.kpiType()))
      .subscribe({
        next: (res) => {
          if (res.inError) {
            this.orgUnits.set([]);
            return;
          }

          this.orgUnits.set(res.responseData ?? []);

          const isOrgUnitAvailable = this.orgUnits()?.find((e) => e.id === this.model.get("orgUnitId")?.getRawValue());
          if (!isOrgUnitAvailable) this.model.get("orgUnitId")?.setValue(null);
        },
      });
  }

  private _fetchOwners(id: number) {
    return this._usersApiService.getOwnersPerOrgUnit(id).pipe(
      filter((res) => !res.inError),
      map((res) => res.responseData),
      tap({
        next: (data) => {
          this.owners.set(data ?? []);
          const isOwnerAvailable = this.owners()?.find(({ id }) => id === this.model?.get("owner")?.value);
          if (!isOwnerAvailable) this.model?.get("owner")?.setValue(null);
        },
      }),
      ignoreElements()
    );
  }

  private _setModel() {
    const plan = this.plan() as Record<string, unknown>;
    if (!plan) return;

    const controlKeys = Object.keys(plan ?? {});

    for (const k of controlKeys) {
      const control = this.model.get(k);
      if (!control) continue;
      if (!AdaaHelper.isDefined(plan[k])) continue;

      if (k === "startDate" || k === "endDate") {
        this.model.get(k)?.setValue(AdaaHelper.getDubaiTime(plan[k] as number));
      } else {
        this.model.get(k)?.setValue(plan[k]);
      }
    }
  }

  private _setDates() {
    if (this.mode() !== PageMode.create) return;

    const kpi = this.kpi();
    if (kpi?.startDate) {
      this.model.controls["startDate"]?.setValue(kpi.startDate as number);
    }
    if (kpi?.endDate) {
      this.model.controls["endDate"]?.setValue(kpi.endDate as number);
    }
  }

  private _setTabs() {
    this.tabs.set([
      {
        id: "info",
        disabled: false,
        hasError: false,
        title: "common.form.label.information",
      },
      {
        id: "teamMembers",
        disabled: false,
        hasError: false,
        title: "national_projects.project_team_members.common_text",
      },
    ]);
  }
}
