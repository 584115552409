<div class="p-2">
    <adaa-data-table
        #adaaDataTable
        apiName="getAllV2"
        [titleHeader]="'formulas.formula_console_icon' | translate"
        [apiPrefix]="'formulas'"
        [configKey]="tableConfList()"
        [enableAdd]="false"
        [enableEditAction]="true"
        [enableDeleteAction]="true"
        [enableViewAction]="true"
        [customActionButtons]="customButtons"
        [enableAdd]="true"
        [itemTypeId]="Constants.CONSTANT_PERMISSIONS.FORMULAS"
        (addNewClicked)="addNewClicked()"
        (actionClicked)="getAction($event)"
    ></adaa-data-table>
</div>
