@if (isDefined(model)) {
    <form
        autocomplete="on"
        spellcheck="true"
        class="d-block w-100 position-relative"
        [dir]="languageService.direction()"
        [attr.lang]="languageService.current()"
        [formGroup]="model"
    >
        <div class="w-100 d-block card mb-1 p-3 bg-white border-0 form-section">
            <section class="d-flex row mt-2 mb-3">
                <div class="col-sm-6 col-xs-12">
                    <adaa-form-dropdown
                        controlName="measurementUnit"
                        formControlName="measurementUnit"
                        [clearable]="true"
                        [label]="'kpi.measurement_unit' | translate"
                        [options]="measurementUnitOptions()"
                        [invalid]="model.get('measurementUnit')?.touched! && model.get('measurementUnit')?.invalid!"
                        [required]="isFieldRequired(validations(), 'measurementUnit')"
                        [adaaInputDisabled]="checkEkpiReadOnlyField() || isDerivedSKPI() || checkNTkpiReadOnlyField() || checkDTkpiReadOnlyField()"
                    />
                </div>

                <div class="col-sm-6 col-xs-12">
                    <adaa-form-input
                        controlName="measurementUnitDscAE"
                        formControlName="measurementUnitDscAE"
                        classes="form-control shadow-none"
                        [invalid]="model.get('measurementUnitDscAE')?.touched! && model.get('measurementUnitDscAE')?.invalid!"
                        [label]="'nkpi.measurement_unit_dsc_ae' | translate"
                        [required]="isFieldRequired(validations(), 'measurementUnitDscAE')"
                        [adaaInputDisabled]="checkEkpiReadOnlyField() || isDerivedSKPI()"
                    />
                </div>
            </section>

            <section class="d-flex row mt-2 mb-3">
                @if (isMaxRank) {
                    <div class="col-xs-12" [class.col-md-6]="isMaxRank">
                        <adaa-form-input
                            controlName="maxRank"
                            formControlName="maxRank"
                            classes="form-control shadow-none"
                            [invalid]="model.get('maxRank')?.touched! && model.get('maxRank')?.invalid!"
                            [label]="'nkpi.max_rank' | translate"
                            [showAsRequired]="isFieldRequired(validations(), 'maxRank') || isMaxRank"
                            [adaaInputDisabled]="
                                checkEkpiReadOnlyField() || isDerivedSKPI() || checkNTkpiReadOnlyField() || checkDTkpiReadOnlyField()
                            "
                        />
                    </div>
                }

                @if (isTextMapping) {
                    <div class="col-md-6 col-xs-12" [class.col-md-6]="isTextMapping">
                        <adaa-form-dropdown
                            controlName="textmapId"
                            formControlName="textmapId"
                            [clearable]="true"
                            [label]="'nkpi.select_textmap_id' | translate"
                            [options]="textmappingOptions()"
                            [invalid]="model.get('textmapId')?.touched! && model.get('textmapId')?.invalid!"
                            [showAsRequired]="isFieldRequired(validations(), 'textmapId')"
                            [adaaInputDisabled]="
                                checkEkpiReadOnlyField() || isDerivedSKPI() || checkNTkpiReadOnlyField() || checkDTkpiReadOnlyField()
                            "
                        />
                    </div>
                }

                <div class="col-xs-12" [class.col-sm-6]="isMaxRank || isTextMapping">
                    <adaa-form-dropdown
                        controlName="frequency"
                        formControlName="frequency"
                        [clearable]="true"
                        [label]="'kpi.frequency' | translate"
                        [options]="frequencyOptions()"
                        [invalid]="model.get('frequency')?.touched! && model.get('frequency')?.invalid!"
                        [required]="isFieldRequired(validations(), 'frequency')"
                        [adaaInputDisabled]="checkEkpiReadOnlyField() || isDerivedSKPI() || checkNTkpiReadOnlyField() || checkDTkpiReadOnlyField()"
                    />
                </div>
            </section>
        </div>

        <div class="w-100 d-block card mb-2 px-1 px-3 bg-white border-0 form-section">
            <adaa-kpi-trend-input
                controlName="trend"
                formControlName="trend"
                [options]="trend()"
                [required]="isFieldRequired(validations(), 'trend')"
                [adaaInputDisabled]="checkEkpiReadOnlyField() || isDerivedSKPI() || checkNTkpiReadOnlyField() || checkDTkpiReadOnlyField()"
                (inputChanges)="onTrendUpdate($event)"
            />
        </div>

        <div class="w-100 d-block card my-2 px-1 px-3 bg-white border-0 form-section">
            <section class="d-flex row mt-2 mb-3 py-2 align-items-stretch">
                <div class="col-sm-6 col-xs-12 d-flex align-items-center align-content-center justify-content-center">
                    <adaa-form-checkbox
                        controlName="baselineKpi"
                        formControlName="baselineKpi"
                        checkboxMode="yesNo"
                        [useSwitch]="true"
                        [boldLabel]="model.get('baselineKpi')?.value === 'Y'"
                        [label]="'nkpi.baselineKpi' | translate"
                        [adaaInputDisabled]="disableBaselineKPI()"
                    />
                </div>

                <div class="col-sm-6 col-xs-12">
                    <adaa-form-input
                        controlName="baselineYear"
                        formControlName="baselineYear"
                        [label]="'kpi.baselineYear' | translate"
                        [invalid]="model.get('baselineYear')?.touched! && model.get('baselineYear')?.invalid!"
                        [required]="isFieldRequired(validations(), 'baselineYear')"
                        [adaaInputDisabled]="true"
                    />
                </div>
            </section>
        </div>

        <div class="w-100 d-block card my-3 px-1 px-3 bg-white border-0 form-section">
            <section class="d-flex row mt-2 mb-0 pt-2 pb-3 align-items-stretch">
                <div class="col-12 d-flex align-content-center align-items-end gap-1 justify-content-center">
                    <div
                        class="w-100"
                        [class.w-75]="isFormulaKpi() || isKpiWithDetailedTargets()"
                        [class.flex-sm-fill]="isFormulaKpi() || isKpiWithDetailedTargets()"
                    >
                        <adaa-form-dropdown
                            controlName="_formula"
                            formControlName="_formula"
                            [clearable]="true"
                            [label]="'kpi.value_type' | translate"
                            [options]="formulaOptions()"
                            [invalid]="model.get('_formula')?.touched! && model.get('_formula')?.invalid!"
                            [required]="isFieldRequired(validations(), 'formula')"
                            [adaaInputDisabled]="
                                checkEkpiReadOnlyField() ||
                                isDerivedSKPI() ||
                                checkNTkpiReadOnlyField() ||
                                checkDTkpiReadOnlyField() ||
                                isLinkedToScope()
                            "
                            (inputChanges)="handleValueType($event)"
                        />
                    </div>

                    @if (isFormulaKpi() || isKpiWithDetailedTargets()) {
                        <div class="flex-shrink-0 mb-1" [ngStyle]="{ 'width.px': 60, 'padding-inline.px': 10 }">
                            <adaa-kpi-formula-selector
                                [isLinkedToScope]="isLinkedToScope() || checkEkpiReadOnlyField()"
                                (onFormulaSelection)="handleValueType(model.get('_formula')?.value)"
                            />
                        </div>
                    }
                </div>
            </section>

            @if (isDefined(model.get("_formula")?.value) && !hasAnnualFrequency()) {
                <section class="d-flex row mt-0 mb-2 pb-2 align-items-stretch">
                    <div class="col-12">
                        <adaa-form-dropdown
                            controlName="ytpCalc"
                            formControlName="ytpCalc"
                            [clearable]="true"
                            [label]="'kpi.year_to_period' | translate"
                            [options]="ytpCalcOptions()"
                            [invalid]="model.get('ytpCalc')?.touched! && model.get('ytpCalc')?.invalid!"
                            [required]="isFieldRequired(validations(), 'ytpCalc')"
                            [adaaInputDisabled]="
                                checkEkpiReadOnlyField() || isDerivedSKPI() || checkNTkpiReadOnlyField() || checkDTkpiReadOnlyField()
                            "
                        />
                    </div>
                </section>
            }

            <adaa-kpi-formula
                #kpiFormula
                [kpiType]="kpiType()"
                [kpi]="kpi()"
                [stagedKpi]="stagedKpi()"
                [hideYtpCalc]="hasAnnualFrequency()"
                [hideValidateButton]="
                    checkNTkpiReadOnlyField() || checkEkpiReadOnlyField() || checkDTkpiReadOnlyField() || !isDefined(model.get('_formula')?.value)
                "
                [ytpCalcOptions]="ytpCalcOptions()"
                [required]="isFieldRequired(validations(), 'formula')"
                [formulaInputControl]="model.controls['formula']!"
                (clearMetrics)="model.controls['clearMetrics']!.setValue($event)"
                (formulaHasChanged)="model.controls['formulaWasChanged']!.setValue($event)"
                (formulaStatusHasChanged)="model.controls['formulaStatus']!.setValue($event)"
                (metricsHasChanged)="model.controls['metrics']!.setValue($event)"
            />
        </div>
    </form>
}
