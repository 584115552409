import { CommonModule } from "@angular/common";
import { Component, inject, OnInit, signal, viewChild } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { filter, switchMap } from "rxjs";

import { FloatActionComponent, FormStepsComponent } from "../../../shared/components";
import { genericFloatButtons } from "../../../shared/components/general/float-action";
import { FormTab, QuestionAnswerData, UserSecurityQuestionModelType } from "../../../shared/models";
import { UserProfileApiService } from "../../../shared/services/user-profile-api.service";
import { ProfilePersonalInformationComponent } from "../profile-personal-information/profile-personal-information.component";
import { ProfileResponsibilitiesComponent } from "../profile-responsibilities/profile-responsibilities.component";
import { ProfileSecurityComponent } from "../profile-security/profile-security.component";

@Component({
  selector: "adaa-profile",
  standalone: true,
  imports: [
    TranslateModule,
    FormStepsComponent,
    ProfilePersonalInformationComponent,
    ProfileSecurityComponent,
    ProfileResponsibilitiesComponent,
    CommonModule,
    FloatActionComponent,
  ],
  templateUrl: "./profile.component.html",
  styleUrl: "./profile.component.scss",
})
export class ProfileComponent implements OnInit {
  private _translateService = inject(TranslateService);
  private _userProfileService = inject(UserProfileApiService);
  private _toastrService = inject(ToastrService);
  private _router = inject(Router);

  personalInformation = viewChild.required<ProfilePersonalInformationComponent>("personalInformation");
  securityInformation = viewChild.required<ProfileSecurityComponent>("securityInformation");

  private readonly _genericFloatButtons = genericFloatButtons();
  activeTab = signal<number>(0);
  tabs = signal<FormTab[]>([
    {
      title: this._translateService.instant("user_profile.wizard.personalInformation"),
      disabled: false,
    },
    {
      title: this._translateService.instant("user_profile.wizard.security"),
      disabled: false,
    },
    {
      title: this._translateService.instant("user_profile.wizard.owned_objects"),
      disabled: false,
    },
  ]);

  public ngOnInit(): void {
    this._floatActions();
  }

  public saveInformation() {
    const profileFormValue = this.personalInformation().profileForm.value;
    const profileInformation = this.personalInformation().profileInformation();
    const notificationPreferences = this.personalInformation().notificationPreferences();
    const securityFormValue = this.securityInformation().securityQuestionsForm.value;
    const userSecurityQuestions = this.securityInformation().securityQuestionsValues();

    if (!profileInformation) return;
    const securityQuestions = this._mapQuestionsToAnswers(
      userSecurityQuestions,
      securityFormValue,
      profileInformation?.id
    );
    if (!securityQuestions.length) {
      this._toastrService.error(this._translateService.instant("user_profile.wizard.allQuestions"));
      return;
    }

    const mappedQuestions = { securityQuestions };
    const preferedLanguage = this.personalInformation().notificationPreference();
    delete profileFormValue.username;
    delete profileFormValue.email;
    delete profileFormValue.nameAE;
    delete profileFormValue.nameEN;

    profileFormValue.id = profileInformation?.id;
    profileFormValue.preferedLanguage = preferedLanguage;
    profileFormValue.orgUnitIds = profileInformation?.orgUnitIds;

    this._userProfileService
      .updateUserInformation(profileFormValue)
      .pipe(
        switchMap(() => this._userProfileService.updateNotificationPreference(notificationPreferences)),
        switchMap(() => this._userProfileService.saveSecurityQuestions(mappedQuestions)),
        filter((response) => !response.inError)
      )
      .subscribe({
        next: () => this._toastrService.success(this._translateService.instant("notification.success.save")),
      });
  }

  private _mapQuestionsToAnswers(
    userSecurityQuestions: UserSecurityQuestionModelType[],
    data: QuestionAnswerData,
    userId: number
  ) {
    const result: { securityQuestionId: number; userId: number; answer: string; id?: number; status?: number }[] = [];

    for (const key of Object.keys(data)) {
      if (key.startsWith("question")) {
        const question = userSecurityQuestions.find((question) => question.securityQuestionId === data[key]);
        const answerKey = `answer${key.replace("question", "")}`;
        if (!data[answerKey]?.length) {
          return [];
        }
        result.push({
          securityQuestionId: data[key],
          userId,
          ...(question ? { id: question.id } : {}),
          answer: data[answerKey],
        });
      }
    }

    const questions = result.filter((q) => !q.id);

    for (let index = 0; index < questions.length; index++) {
      const element = questions[index];
      if (!element.id) {
        element.id = userSecurityQuestions.filter((question) => !result.some((q) => q?.id === question.id))[0]?.id;
      }
    }

    return [...result, ...questions];
  }

  private _floatActions(): void {
    this._genericFloatButtons([
      {
        key: "helpdesk",
        data: {
          url: "/helpdesk/create",
        },
      },
    ]);
  }
}
