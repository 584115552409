import { Component, computed, inject, signal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { map } from "rxjs";

import { PercentageValuePipe, TranslateTokenPipe } from "../../../../core/pipes";
import { AdaaHelper } from "../../../../core/utils";
import {
  DataTableComponent,
  DoughnutComponent,
  PeriodSliderComponent,
  RibbonComponent,
} from "../../../../shared/components";
import { Constants } from "../../../../shared/constants/constants";
import type { NationalStrategyModelType, SelectedPeriodModelType } from "../../../../shared/models";
import {
  FilesApiService,
  KpisService,
  NationalStrategiesApiService,
  SystemLayoutService,
} from "../../../../shared/services";

@Component({
  selector: "adaa-objective-dashboard",
  standalone: true,
  templateUrl: "./objective-dashboard.component.html",
  styleUrl: "./objective-dashboard.component.scss",
  imports: [
    PeriodSliderComponent,
    TranslateModule,
    FontAwesomeModule,
    TranslateTokenPipe,
    DoughnutComponent,
    PercentageValuePipe,
    DataTableComponent,
    RibbonComponent,
    RouterLink,
    NgbTooltip,
  ],
})
export class ObjectiveDashboardComponent {
  private readonly _router = inject(Router);
  private readonly _route = inject(ActivatedRoute);
  private readonly _kpisService = inject(KpisService);
  private readonly _fileService = inject(FilesApiService);
  private readonly _systemLayoutService = inject(SystemLayoutService);
  private readonly _nationalStrategiesApiService = inject(NationalStrategiesApiService);

  objectiveId = toSignal(this._route.params.pipe(map((params) => params["objectiveId"])), {
    manualCleanup: true,
  });
  strategyId = toSignal(this._route.params.pipe(map((params) => params["strategyId"])), {
    manualCleanup: true,
  });
  selectedPeriod = signal<SelectedPeriodModelType | undefined>(undefined);
  dashboardData = signal<NationalStrategyModelType | undefined>(undefined);
  dashboardImage = signal<string | undefined>(undefined);
  selectedTab = signal<1 | 2>(1);

  goalTargetParamString = computed(() => {
    const qs = new URLSearchParams();
    if (this.selectedPeriod()) {
      qs.append("periodId", `${this.selectedPeriod()?.period.id}`);
    }
    qs.append("subGovId", `${this.objectiveId()}`);
    return qs.toString();
  });
  goalProjectParamString = computed(() => `subGovId=${this.objectiveId()}`);
  objectivesList = computed(() => {
    const id = this.dashboardData()?.id;
    const list = this.dashboardData()?.nationalStrategyChildren ?? [];
    return list.map((data, index) => ({
      id: data.id,
      text: AdaaHelper.getItemValueByToken(data, "name"),
      number: `${+index + 1}`,
      url: `/national-strategies-dashboard/strategy/${id}/objective/${data.id}`,
    }));
  });
  objectiveDetails = computed(() =>
    this.dashboardData()?.nationalStrategyChildren.find((e) => e.id === +this.objectiveId())
  );
  objectiveNumber = computed(() => {
    for (const index in this.dashboardData()?.nationalStrategyChildren) {
      const data = this.dashboardData()?.nationalStrategyChildren[+index];
      if (data?.id === +this.objectiveId()) {
        return `${+index + 1}`;
      }
    }
    return "";
  });
  dashboardWidgets = computed(() => [
    {
      label: "national_strategies.initiatives",
      data: this.objectiveDetails()?.initiativesCount,
    },
    {
      label: "national_strategies.kpis",
      data: this.objectiveDetails()?.kpiCount,
    },
    {
      label: "national_strategies.budget",
      data: AdaaHelper.amountToString(this.objectiveDetails()?.budget),
      symbol: AdaaHelper.getCurrencySymbol(this.objectiveDetails()?.budget, true, "-"),
    },
    {
      label: "national_strategies.actual_expenditure",
      data: AdaaHelper.amountToString(this.dashboardData()?.expense),
      symbol: AdaaHelper.getCurrencySymbol(this.dashboardData()?.expense, true, "-"),
    },
    {
      label: "national_strategies.budget_expenditure",
      data: AdaaHelper.percentageValue(this.dashboardData()?.budgetExpenditure),
    },
  ]);

  readonly goalTargetConfKey = "ns_subgov_kpis_list";
  readonly goalProjectConfKey = "sdg_subgov_initiatives_list";

  readonly #untilDestroy = AdaaHelper.untilDestroyed();

  public get icons() {
    return {
      refresh: faArrowsRotate,
    };
  }

  public ngOnInit() {
    this._refresh();
  }

  public getColor(code: number) {
    return Constants.COLOR_CODES[code];
  }

  public selectPeriod(period: SelectedPeriodModelType) {
    if (!period) return;
    this.selectedPeriod.update(() => period);
    this.queryData();
  }

  public queryData() {
    const selectedPeriod = this.selectedPeriod() as SelectedPeriodModelType;
    if (!selectedPeriod) return;

    const id = this.strategyId();

    this._nationalStrategiesApiService.getStrategyDashboardInfo(id, selectedPeriod.period.year).subscribe({
      next: (res) => {
        if (res.inError) return;
        this.dashboardData.update(() => res.responseData);
        this._getLogo();
      },
    });
  }

  public handleTableAction(
    table: "initiatives" | "kpis",
    data: Partial<{
      id: number;
      viewKpiType: number;
      viewKpiId: number;
    }>
  ) {
    let qs = "";
    if (this.selectedPeriod()) qs = `?periodId=${this.selectedPeriod()?.period.id}`;

    switch (table) {
      case "initiatives": {
        this._router.navigateByUrl(`/console/initiatives/${data.id}${qs}`);
        break;
      }

      case "kpis": {
        const kpiType = this._kpisService.getKpiTypeString(data.viewKpiType!);
        this._router.navigateByUrl(`/console/kpi/${kpiType}/${data.viewKpiId}${qs}`);
      }
    }
  }

  private _getLogo() {
    this._fileService.download(this.dashboardData()!.logo!).subscribe({
      next: (blob) => {
        this.dashboardImage.set(window.URL.createObjectURL(blob));
      },
    });
  }

  private _refresh() {
    this._systemLayoutService.hasActiveEntityChanged$.pipe(this.#untilDestroy()).subscribe({
      next: () => window.location.reload(),
    });

    this._systemLayoutService.hasCycleChanged$.pipe(this.#untilDestroy()).subscribe({
      next: () => window.location.reload(),
    });
  }
}
