@if (data()) {
    <div class="d-block mb-2 ribbon w-100" [class.ar]="isArabic()">
        <adaa-ribbon
            class="have-golden-stats reduced-label-fs"
            [showLogo]="false"
            [showRefreshIcon]="false"
            [showFilterIcon]="false"
            [widgetData]="widgets()"
        />
    </div>

    <adaa-period-slider
        type="year"
        [activePeriod]="selectedPeriod()!"
        [frequency]="periodSliderFrequency"
        [startDate]="data()?.startDate"
        [endDate]="data()?.endDate"
        (periodSelect)="selectPeriod($event)"
    />

    <adaa-item-slider class="my-2" [showPerformance]="false" [hasTitle]="false">
        @for (item of subGovDirs(); track $index) {
            <adaa-uae-vision-perf-card
                [class.active]="selectedSubGovDir() === item.data.id"
                [class.inactive]="selectedSubGovDir() !== item.data.id"
                [data]="item.data"
                [useIndicator]="item.indicators"
                [logoType]="item.imageType"
                (cardClicked)="selectSubGovDir($event.id)"
            />
        }
    </adaa-item-slider>
}

<div class="d-block w-100 bg-white mt-3 rounded p-1 shadow">
    <div class="position-relative d-flex justify-content-center w-100 mt-2 mb-3">
        <ul
            class="nav nav-pills nav-fill gap-2 p-1 bg-primary small rounded-5 shadow w-auto"
            id="pillNav2"
            role="tablist"
            style="
                --bs-nav-link-color: var(--bs-white);
                --bs-nav-pills-link-active-color: var(--bs-primary);
                --bs-nav-pills-link-active-bg: var(--bs-white);
                --bs-link-hover-color: var(--bs-primary-bg-subtle);
                --bs-nav-link-font-weight: 700;
                --bs-nav-link-font-size: 14px;
            "
        >
            <li class="nav-item" role="presentation" (click)="selectedTab.set(1)">
                <a
                    class="nav-link px-4 active rounded-5"
                    id="home-tab2"
                    data-bs-toggle="tab"
                    type="button"
                    role="tab"
                    aria-selected="true"
                    href="#ntkpis"
                >
                    {{ "national_agenda.main_dashboard.ntkpis" | translate }}
                </a>
            </li>

            <li class="nav-item" role="presentation" (click)="selectedTab.set(2)">
                <a
                    class="nav-link px-4 rounded-5"
                    id="profile-tab2"
                    data-bs-toggle="tab"
                    type="button"
                    role="tab"
                    aria-selected="false"
                    href="#entities"
                >
                    {{ "national_agenda.main_dashboard.government_entities" | translate }}
                </a>
            </li>
        </ul>
    </div>

    <div class="tab-content clearfix mt-2">
        <div class="tab-pane" id="ntkpis" [class.active]="selectedTab() === 1">
            <div class="w-100 d-flex justify-content-center align-items-start align-content-start gap-1 flex-wrap">
                @for (kpi of ntkpis(); track kpi.id) {
                    <adaa-wtuae-ntkpi-card role="link" [data]="kpi" [routerLink]="['/console/kpi/ntkpi/', kpi.id]" />
                }
            </div>
        </div>

        <div class="tab-pane" id="entities" [class.active]="selectedTab() === 2">
            <div class="w-100 d-flex justify-content-evenly align-items-start align-content-start gap-1 flex-wrap">
                @for (entity of entities(); track entity.id) {
                    <adaa-wtuae-entity-card [data]="entity" (click)="selectedEntity.set(entity); openModal(content)" />
                }
            </div>
        </div>
    </div>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
        <div class="w-100 d-flex justify-content-center">
            @if (selectedEntity()?.logoHorizontal) {
                <adaa-picture width="330px" [pictureId]="selectedEntity()?.logoHorizontal" [attr.title]="selectedEntity() | translateToken: 'name'" />
            } @else {
                <h4 class="fw-bold w-100 text-center text-primary py-3">{{ selectedEntity() | translateToken: "name" }}</h4>
            }
        </div>
    </div>

    <div class="modal-body">
        <div class="w-100 d-flex justify-content-center align-items-start align-content-start gap-1 flex-wrap py-3">
            @for (kpi of filteredNtkpis(); track kpi.id) {
                <adaa-wtuae-ntkpi-card role="link" [data]="kpi" (click)="modal.dismiss(); router.navigate(['/console/kpi/ntkpi/', kpi.id])" />
            }
        </div>
    </div>

    <div class="modal-footer d-flex justify-content-center">
        <button class="btn btn-danger rounder-pill w-25 text-white" (click)="selectedEntity.set(undefined); modal.dismiss()">
            {{ "common.form.button.close" | translate }}
        </button>
    </div>
</ng-template>
