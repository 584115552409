import { Route } from "@angular/router";

import { PageMode } from "../../../shared/constants/enums";
import { DataEntryMetricsEditorComponent } from "./data-entry-metrics-editor/data-entry-metrics-editor.component";
import { DataEntryMetricsListComponent } from "./data-entry-metrics-list/data-entry-metrics-list.component";

export const DATA_ENTRY_METRICS_ROUTES: Route[] = [
  {
    path: "metrics",
    canActivate: [],
    children: [
      {
        path: "",
        component: DataEntryMetricsListComponent,
        data: {
          breadcrumb: "breadcrumbs.metrics",
          title: "breadcrumbs.metrics",
        },
      },
      {
        path: ":id",
        component: DataEntryMetricsEditorComponent,
        data: {
          breadcrumb: "breadcrumbs.view_metric",
          title: "breadcrumbs.view_metric",
          pageMode: PageMode.view,
        },
      },
      {
        path: "edit/:id",
        component: DataEntryMetricsEditorComponent,
        data: {
          breadcrumb: "breadcrumbs.metric_edit",
          title: "breadcrumbs.metric_edit",
          pageMode: PageMode.edit,
        },
      },
    ],
  },
];
