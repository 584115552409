<div class="d-flex gap-2 ms-3">
    <p class="fw-bold fs-3">{{ "user_roles.title" | translate }}</p>
</div>

<div class="px-3">
    <div class="bg-white shadow-md rounded p-2 mb-3">
        <form class="col-12" [formGroup]="userRolesForm">
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.nameAE' | translate"
                        [setValidator]="userRolesEditorService.formValidation()!"
                        [invalid]="checkInvalid('nameAE')"
                        formControlName="nameAE"
                        controlName="nameAE"
                    ></adaa-form-input>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.nameEN' | translate"
                        [setValidator]="userRolesEditorService.formValidation()!"
                        [invalid]="checkInvalid('nameEN')"
                        formControlName="nameEN"
                        controlName="nameEN"
                    ></adaa-form-input>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.descriptionAE' | translate"
                        [setValidator]="userRolesEditorService.formValidation()!"
                        [invalid]="checkInvalid('dscAE')"
                        formControlName="dscAE"
                        controlName="dscAE"
                    ></adaa-form-input>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.descriptionEN' | translate"
                        [setValidator]="userRolesEditorService.formValidation()!"
                        [invalid]="checkInvalid('dscEN')"
                        formControlName="dscEN"
                        controlName="dscEN"
                    ></adaa-form-input>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <div class="my-3 d-flex justify-content-center">
                        <adaa-form-checkbox
                            [label]="'common.form.label.active' | translate"
                            [checkboxMode]="'yesNo'"
                            controlName="enabled"
                            formControlName="enabled"
                        ></adaa-form-checkbox>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <div class="my-3 d-flex justify-content-center">
                        <adaa-form-checkbox
                            [label]="'common.form.label.pmo_role' | translate"
                            [checkboxMode]="'yesNo'"
                            controlName="isPmoRole"
                            formControlName="isPmoRole"
                            [disabled]="pmoRoleChecked()"
                        ></adaa-form-checkbox>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div class="bg-white shadow-md rounded p-2 mb-3">
        <div class="d-flex gap-5 my-2">
            <div class="position-relative d-flex justify-content-start mt-2" [ngClass]="appService.language() === Language.Arabic ? 'me-4' : 'ms-4'">
                <ul class="nav nav-pills nav-fill gap-2 p-1 bg-primary small rounded-5 shadow w-auto tablist" id="pillNav2" role="tablist">
                    <li class="nav-item" role="presentation" (click)="pmoOnly.set(false)">
                        <a class="nav-link px-4 active rounded-5" id="home-tab2" data-bs-toggle="tab" type="button" role="tab" aria-selected="true">
                            {{ "common.form.label.general_use" | translate }}
                        </a>
                    </li>

                    <li class="nav-item" role="presentation" (click)="pmoOnly.set(true)">
                        <a class="nav-link px-4 rounded-5" id="profile-tab2" data-bs-toggle="tab" type="button" role="tab" aria-selected="false">
                            {{ "common.form.label.pmo_only" | translate }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div [class.disabled]="pageMode() === PageMode.view">
            <adaa-user-roles-manager
                [data]="filteredPermissions()"
                [isPmo]="pmoOnly()"
                [pageMode]="pageMode()"
                (setPermissions)="setPermissions($event)"
                (selectAll)="selectAll($event.event, $event.value)"
            ></adaa-user-roles-manager>
        </div>
    </div>

    <div class="my-2">
        <adaa-form-action-buttons
            [showSaveButton]="pageMode() !== PageMode.view"
            [saveButtonTitle]="'common.form.button.submit' | translate"
            [cancelButtonTitle]="pageMode() === PageMode.view ? ('common.form.button.close' | translate) : ('common.form.button.cancel' | translate)"
            (cancelClicked)="close()"
            (saveClicked)="submit()"
        ></adaa-form-action-buttons>
    </div>
</div>
