import { Route } from "@angular/router";

import { checkIfLoggedInFn } from "../../core/guards";
import { Constants } from "../../shared/constants/constants";
import { viewReportsPageGuards } from "./_guards";
import { ReportsComponent } from "./reports.component";

export const REPORT_ROUTES: Route[] = [
  {
    path: "reporting",
    canActivate: [checkIfLoggedInFn, ...viewReportsPageGuards],
    children: [
      {
        path: "",
        component: ReportsComponent,
      },
    ],
    data: {
      title: "navigation.reporting",
      breadcrumb: "navigation.reporting",
      tableConfList: Constants.MAIN_TABLE_LIST_CONF_KEY.REPORTING_LIST,
    },
  },
];
