import { Route } from "@angular/router";

import { checkIfLoggedInFn } from "../../../core/guards";
import { SdgDashboardComponent } from "./sdg-dashboard/sdg-dashboard.component";
import { SdgDetailsComponent } from "./sdg-details/sdg-details.component";

export const SDG_ROUTES: Route[] = [
  {
    path: "sdg",
    canActivate: [checkIfLoggedInFn],
    children: [
      {
        path: "",
        component: SdgDashboardComponent,
        canActivate: [checkIfLoggedInFn],
        data: {
          title: "sdg.title",
          breadcrumb: "sdg.title",
        },
      },
      {
        path: ":id",
        component: SdgDetailsComponent,
        canActivate: [checkIfLoggedInFn],
        data: {
          title: "sdg.breadcrumb",
          breadcrumb: "sdg.breadcrumb",
        },
      },
    ],
  },
];
