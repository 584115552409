import { Component, inject, OnInit, viewChild } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { AdaaHelper } from "../../../../core/utils";
import { ConfirmationModalComponent, DataTableComponent, FloatActionComponent } from "../../../../shared/components";
import { genericFloatButtons } from "../../../../shared/components/general/float-action";
import { Constants } from "../../../../shared/constants/constants";
import { ItemAction, TableButtonClicked } from "../../../../shared/models";
import { AgmProjectApiService, LanguageService, SystemLayoutService } from "../../../../shared/services";

@Component({
  selector: "adaa-agm-project-list",
  imports: [DataTableComponent, FloatActionComponent],
  templateUrl: "./agm-project-list.component.html",
  styleUrl: "./agm-project-list.component.scss",
})
export class AgmProjectListComponent implements OnInit {
  private _router = inject(Router);
  private _modalService = inject(NgbModal);
  private _toastrService = inject(ToastrService);
  private _translateService = inject(TranslateService);
  private _languageService = inject(LanguageService);
  private _systemLayoutService = inject(SystemLayoutService);
  private _agmProjectApiService = inject(AgmProjectApiService);

  private _agmTable = viewChild<DataTableComponent>("agmTable");

  private _untilDestroyed = AdaaHelper.untilDestroyed();
  private _floatActions = genericFloatButtons();
  constants = Constants;
  disableEditDeleteRules: ItemAction[] = [
    {
      propertyName: "status",
      compareWith: Constants.SERVICES.STATE.IN_REVIEW,
      operator: "equal",
    },
  ];

  hideDeleteEditRules: ItemAction[] = AdaaHelper.isPMOEntity()
    ? []
    : [
        {
          propertyName: "entityId",
          compareWith: AdaaHelper.entity?.id,
          operator: "not-equal",
        },
      ];

  public ngOnInit(): void {
    this._getFloatActions();
    this._systemLayoutService.hasCycleChanged$.pipe(this._untilDestroyed()).subscribe({
      next: () => window.location.reload(),
    });
    this._systemLayoutService.hasActiveEntityChanged$.pipe(this._untilDestroyed()).subscribe({
      next: () => window.location.reload(),
    });
  }

  public addNewClicked(): void {
    this._router.navigateByUrl("/console/annual-meetings-projects/projects/create");
  }

  public actionClicked(event: TableButtonClicked): void {
    switch (event.event) {
      case "view":
        this._router.navigateByUrl(`/annual-meetings-projects-dashboard/projects/${event.data.id}`);
        break;
      case "edit":
        this._router.navigateByUrl(`/console/annual-meetings-projects/projects/edit/${event.data.id}`);
        break;
      case "delete":
        this._deleteProject(event.data.id);
        break;
      default:
        break;
    }
  }

  public childClicked(event: TableButtonClicked): void {
    switch (event.event) {
      case "view":
        this._router.navigate([`/annual-meetings-projects-dashboard/milestones/${event.data.id}`], {
          queryParams: {
            redirectTo: "/console/annual-meetings-projects/projects",
          },
        });
        break;
      case "edit":
        this._router.navigateByUrl(`/console/annual-meetings-projects/milestones/edit/${event.data.id}`);
        break;
      default:
        break;
    }
  }

  private _deleteProject(id: number): void {
    const modal = this._modalService.open(ConfirmationModalComponent, {
      centered: true,
      size: "md",
      modalDialogClass: this._languageService.modalDirection(),
    });

    modal.componentInstance.header = "annual_meetings.messages.agm_deleted_title";
    modal.componentInstance.title = "annual_meetings.messages.agm_deleted_message";

    modal.result.then((e) => {
      if (e) this._deleteProjectConfirmed(id);
    });
  }

  private _deleteProjectConfirmed(id: number) {
    this._agmProjectApiService.deleteProject(id).subscribe({
      next: (response) => {
        if (response.inError) return;
        this._toastrService.success(this._translateService.instant("notification.success.remove"));
        this._agmTable()?.loadTableData();
      },
    });
  }

  private _getFloatActions(): void {
    this._floatActions([
      {
        key: "print",
        data: undefined,
      },
      {
        key: "helpdesk",
        data: {
          url: "/helpdesk/create",
        },
      },
    ]);
  }
}
