<adaa-entity-plan-change-detection
    [template]="page"
    [request$]="tpDetailsService.fetchProject(+id())"
    [ignoreEntityDetection]="AdaaHelper.isPMOEntity()"
    (continue)="tpDetailsService.initDetails($event)"
/>

<ng-template #page>
    <div class="row">
        <!-- HEADER -->
        <div class="col-12">
            <h4 class="fw-bold">{{ tpDetailsService.project() | translateToken: "name" }}</h4>
        </div>

        <!-- UPDATE BY -->
        <div class="col-12 mb-1">
            {{ "nkpi.last_update" | translate }}, {{ tpDetailsService.project()?.updateTS | date: constants.normalDate }}
            {{ "common.form.label.by" | translate }}
            {{
                tpDetailsService.project()?.updateUserNameEN
                    ? (tpDetailsService.project() | translateToken: "updateUserName")
                    : tpDetailsService.project()?.updateUser
            }}
        </div>

        <!-- TAGS -->
        <div class="col-12 mb-3">
            @if (tpDetailsService.project()?.status !== constants.OBJECT_STATUS.DRAFT) {
                <span
                    class="badge rounded-pill text-white shadow-sm mx-1"
                    [style.background-color]="constants.COLOR_CODES[tpDetailsService.project()?.projectStatusId!]"
                    >{{ tpDetailsService.project() | translateToken: "projectStatus" }}</span
                >
            }

            @if (tpDetailsService.project()?.status === constants.OBJECT_STATUS.ACTIVE) {
                <span class="badge rounded-pill bg-light text-dark shadow-sm mx-1">{{ "national_projects.status.approved" | translate }}</span>
            } @else if (tpDetailsService.project()?.status === constants.OBJECT_STATUS.DRAFT) {
                <span class="badge rounded-pill bg-light text-dark shadow-sm mx-1">{{ "national_projects.status.draft" | translate }}</span>
            } @else if (tpDetailsService.project()?.status === constants.OBJECT_STATUS.IN_REVIEW) {
                <span class="badge rounded-pill bg-light text-dark shadow-sm mx-1">{{ "national_projects.status.inReview" | translate }}</span>
            } @else if (tpDetailsService.project()?.status === constants.OBJECT_STATUS.PLANNING) {
                <span class="badge rounded-pill bg-light text-dark shadow-sm mx-1">{{ "national_projects.status.archived" | translate }}</span>
            }

            @if (tpDetailsService.project()?.cycleId) {
                <span class="badge rounded-pill bg-light text-dark shadow-sm mx-1">{{
                    tpDetailsService.project() | translateToken: "cycleName"
                }}</span>
            }
            @if (tpDetailsService.project()?.orgUnitId) {
                <span class="badge rounded-pill bg-light text-dark shadow-sm mx-1">{{
                    tpDetailsService.project() | translateToken: "orgUnitName"
                }}</span>
            }
            @if (tpDetailsService.project()?.ntKpi) {
                <span
                    class="badge rounded-pill bg-light text-dark shadow-sm mx-1"
                    [routerLink]="['/console/kpi/ntkpi', tpDetailsService.project()?.ntKpi?.id]"
                    >{{ tpDetailsService.project()?.ntKpi | translateToken: "name" }}</span
                >
            }
        </div>

        <!-- OWNER AND PROGRESS -->
        <div class="col-12 mb-3">
            <div class="grid gap-3">
                <div class="g-col-md-6 g-col-xs-12 bg-white shadow-md rounded px-1 text-center d-flex align-items-center">
                    <div class="row">
                        @if (tpDetailsService.project()?.entityLogoId) {
                            <div class="col-md-5 col-sm-12 col-xs-12 overflow-hidden">
                                <h5 class="fw-bold">{{ "national_projects.accountable_entity" | translate }}</h5>
                                <adaa-picture [pictureId]="tpDetailsService.project()?.entityLogoId" [width]="'250px'"></adaa-picture>
                            </div>
                        }
                        <div class="col-md-4 col-sm-12 col-xs-12">
                            <div class="d-flex flex-column align-items-center">
                                <div class="col-12">
                                    <h5 class="fw-bold">{{ "national_projects.entity_lead" | translate }}</h5>
                                </div>
                                @if (tpDetailsService.project()?.ownerProfilePictureId) {
                                    <div class="col-lg-5 col-md-12 col-sm-12 col-xs-12">
                                        <adaa-picture class="mb-1" [pictureId]="tpDetailsService.project()?.ownerProfilePictureId"></adaa-picture>
                                    </div>
                                }
                                <div class="col-lg-7 col-md-12 col-sm-12 col-xs-12">
                                    {{ tpDetailsService.project() | translateToken: "ownerName" }}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-12 col-xs-12">
                            <div class="row">
                                <div class="col-12">
                                    <h5 class="fw-bold">{{ "national_projects.project_coordinator" | translate }}</h5>
                                </div>
                                <div class="col-12">
                                    {{ tpDetailsService.project() | translateToken: "projectCoordinatorName" }}
                                </div>
                                <div class="col-12 mb-2">
                                    {{ tpDetailsService.project()?.projectCoordinatorMobilePhone }}
                                </div>
                                <div class="col-12">
                                    <button class="btn btn-outline-primary" (click)="tpDetailsService.openTeamMembers()">
                                        {{ "national_projects.project_team_members.common_text" | translate }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="g-col-md-6 g-col-xs-12">
                    <div class="col-12 bg-white shadow-md rounded p-2 mb-2">
                        <adaa-progress-indicator
                            [color]="tpDetailsService.project()?.onTimeProgressColor!"
                            [value]="tpDetailsService.project()?.progress!"
                            [label]="('national_projects.project_progress' | translate) + ': '"
                        />
                        <adaa-progress-indicator
                            [color]="
                                tpDetailsService.project() === undefined || tpDetailsService.project()!.visionScore < 100
                                    ? constants.CONSTANT_COLORS.EVALUATION_GOLD
                                    : tpDetailsService.project()?.visionScoreColor!
                            "
                            [value]="tpDetailsService.project()?.visionScore!"
                            [label]="('national_projects.targets_achievement' | translate) + ': '"
                        />
                    </div>
                    <div class="col-12 bg-white shadow-md rounded">
                        <div class="row d-flex align-items-center">
                            <div class="col-md-6 col-sm-12 co-xs-12 d-flex flex-column align-items-center">
                                <h5>
                                    {{ "national_projects.allocated_budget" | translate }}:
                                    <strong>{{ tpDetailsService.project()?.budget | currencyValue: true }}</strong>
                                </h5>
                                <h5>
                                    {{ "activities.expenses" | translate }}:
                                    <strong>{{ tpDetailsService.project()?.expense | currencyValue: true }}</strong>
                                </h5>
                            </div>
                            <div class="col-md-6 col-sm-12 co-xs-12 d-flex flex-column align-items-center">
                                <adaa-doughnut
                                    [amount]="tpDetailsService.project()?.budgetExpenditure!"
                                    [colorId]="constants.CONSTANT_COLORS.EVALUATION_BLACK"
                                    [showProgressCircle]="false"
                                    [circleColor]="'#000'"
                                    [size]="'xxs'"
                                />
                                <h5>{{ "national_projects.percentage_of_expenses" | translate }}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- PROJECT BRIEF -->
        <div class="col-12 mb-3">
            <div class="bg-white shadow-md rounded p-2">
                <h6 class="fw-bold">{{ "national_projects.project_brief" | translate }}</h6>
                <adaa-read-more [maxLength]="300" [text]="tpDetailsService.project() | translateToken: 'dsc'" />
            </div>
        </div>

        <!-- T.TARGETS -->
        <div class="col-12 mb-3">
            <div class="bg-white shadow-md rounded p-2">
                <h6 class="fw-bold">{{ "national_projects.transformational_targets.title" | translate }}</h6>
                @for (item of tpDetailsService.project()?.transformationalTargets; track $index) {
                    <div class="card text-bg-light mb-3">
                        <div class="card-header">
                            <strong>{{ item | translateToken: "name" }}</strong>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6 col-sm-12 co-xs-12">
                                    <h6 class="text-primary">{{ "we_are_uae_main_pillar" | translate }}</h6>
                                    <p>{{ item | translateToken: "subGovernmentDirectionName" }}</p>
                                </div>
                                <div class="col-md-6 col-sm-12 co-xs-12">
                                    <h6 class="text-primary">{{ "we_are_uae_sub_pillar" | translate }}</h6>
                                    <p>{{ item | translateToken: "auxDirectionName" }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>

        <!-- KPI AND MILESTONES -->
        <div class="col-12 mb-3">
            <div class="bg-white shadow-md rounded p-2">
                <nav>
                    <div class="nav nav-tabs">
                        <button class="nav-link active" type="button" data-bs-toggle="tab" data-bs-target="#nav-plan">
                            {{ "national_projects.project_plan" | translate }}
                        </button>
                        <button class="nav-link" type="button" role="tab" data-bs-toggle="tab" data-bs-target="#nav-mokpi">
                            {{ "national_projects.main_outcomes" | translate }}
                        </button>
                    </div>
                </nav>
                <div class="tab-content pt-2">
                    @if (tpDetailsService.project()) {
                        <div class="tab-pane fade show active" id="nav-plan" role="tabpanel">
                            <adaa-tp-timeline
                                [project]="tpDetailsService.project()!"
                                [milestones]="tpDetailsService.milestones()"
                                (milestoneClicked)="tpDetailsService.goToMilestone($event)"
                                (mainAchievementsClicked)="tpDetailsService.showComments($event)"
                            />
                        </div>
                        <div class="tab-pane fade" id="nav-mokpi" role="tabpanel">
                            <adaa-data-table
                                [configKey]="'national_project_national_targets_list'"
                                [apiName]="'getNationalProjectKpis'"
                                [paramsString]="paramsStringMokpi()"
                                [enableAdd]="false"
                                [enableEditAction]="false"
                                [enableDeleteAction]="false"
                                (actionClicked)="tpDetailsService.actionClickedMokpi($event)"
                            ></adaa-data-table>
                        </div>
                    }
                </div>
            </div>
        </div>

        <!-- SECOTRS AND ATTACHMENTS -->
        <div class="col-12">
            <div class="grid gap-3">
                <div class="g-col-md-6 g-col-sm-12 g-col-xs-12 g-col-12 mb-3 bg-white shadow-md rounded p-2">
                    <h6 class="fw-bold">{{ "national_projects.national_sector.title" | translate }}</h6>
                    @for (item of tpDetailsService.project()?.nationalSectors; track $index) {
                        <span class="badge rounded-pill bg-light text-dark shadow-sm mx-1 fs-5">{{ item | translateToken: "name" }}</span>
                    }
                </div>
                <div class="g-col-md-6 g-col-sm-12 g-col-xs-12 g-col-12 mb-3 bg-white shadow-md rounded p-2">
                    <h6 class="fw-bold">{{ "helpdesk.attachments" | translate }}</h6>
                    @for (item of tpDetailsService.project()?.attachments; track $index) {
                        <button class="btn btn-outline-primary mx-1" (click)="tpDetailsService.downloadAttachment(item)">
                            <fa-icon class="mx-1" [icon]="['fas', 'file-download']"></fa-icon>
                            {{ item | translateToken: "name" }}
                        </button>
                    }
                </div>
            </div>
        </div>

        <!-- ENTITIES AND NOTES -->
        <div class="col-12">
            <div class="grid gap-3">
                <div class="g-col-md-6 g-col-sm-12 g-col-xs-12 g-col-12 mb-3 bg-white shadow-md rounded p-2">
                    <h6 class="fw-bold">{{ "national_projects.contributor_entities" | translate }}</h6>
                    @for (item of contributingEntities; track $index) {
                        <span class="badge rounded-pill bg-light text-dark shadow-sm mx-1 fs-5">{{ item | translateToken: "entityName" }}</span>
                    }
                    <h6 class="fw-bold mt-4">{{ "other_entities.plural" | translate }}</h6>
                    @for (item of otherContributingEntities; track $index) {
                        <span class="badge rounded-pill bg-light text-dark shadow-sm mx-1 fs-5">{{ item | translateToken: "otherEntityName" }}</span>
                    }
                </div>
                <div class="g-col-md-6 g-col-sm-12 g-col-xs-12 g-col-12 mb-3 bg-white shadow-md rounded p-2">
                    <adaa-comments
                        cardTitle="sidebar_tables.notes"
                        titleToken="name"
                        dateToken="createTS"
                        messageToken="note"
                        [comments]="tpDetailsService.notes()"
                    />
                </div>
            </div>
        </div>

        <!-- SOURCE OF BUDGET, STOPPED PROJECTS AND PMO DECISIONS -->
        <div class="col-12">
            <div class="grid gap-3">
                <div class="g-col-md-4 g-col-sm-12 g-col-xs-12 g-col-12 mb-3 bg-white shadow-md rounded p-2">
                    <h6 class="fw-bold">{{ "national_projects.stop_strategic_projects" | translate }}</h6>
                </div>
                <div class="g-col-md-4 g-col-sm-12 g-col-xs-12 g-col-12 mb-3 bg-white shadow-md rounded p-2">
                    <h6 class="fw-bold">{{ "national_projects.external_budget_source" | translate }}</h6>
                    {{ tpDetailsService.project()?.sourceOfBudget }}
                </div>
                <div class="g-col-md-4 g-col-sm-12 g-col-xs-12 g-col-12 mb-3 bg-white shadow-md rounded p-2">
                    <h6 class="fw-bold">{{ "national_projects.pmo_decisions" | translate }}</h6>
                    @for (item of tpDetailsService.project()?.decisions; track item) {
                        <span class="badge rounded-pill bg-light text-dark shadow-sm mx-1 fs-5">{{ item | translateToken: "text" }}</span>
                    }
                </div>
            </div>
        </div>

        <!-- GOV DIRECTIONS -->
        @if (tpDetailsService.project()?.govDirections) {
            <div class="col-12 mb-3">
                <div class="bg-white shadow-md rounded p-2 text-center">
                    <adaa-directions-card-grid
                        [govDirectionsList]="tpDetailsService.project()?.govDirections!"
                        [governmentDirectionsTitle]="'national_strategies.strategies'"
                        [governmentSubDirectionsTitle]="'national_strategies.obj_enabler'"
                    ></adaa-directions-card-grid>
                </div>
            </div>
        }

        <!-- TARGET AUDIENCE -->
        <div class="col-12 mb-3">
            <div class="bg-white shadow-md rounded p-2">
                <adaa-comments
                    cardTitle="national_projects.target_audience"
                    titleToken="createdByUserName"
                    dateToken="creationDate"
                    messageToken="audience"
                    [showNumber]="true"
                    [comments]="tpDetailsService.project()?.targetAudience"
                />
            </div>
        </div>

        <!-- EXPECTED IMPACT -->
        <div class="col-12 mb-3">
            <div class="bg-white shadow-md rounded p-2">
                <adaa-comments
                    cardTitle="national_projects.expected_impact"
                    titleToken="createdByUserName"
                    dateToken="creationDate"
                    messageToken="impact"
                    [showNumber]="true"
                    [comments]="tpDetailsService.project()?.expectedImpact"
                />
            </div>
        </div>

        <!-- COMMENTS -->
        <div class="col-12 mb-3">
            <div class="bg-white shadow-md rounded p-2">
                <adaa-comments
                    cardTitle="national_projects.comments"
                    titleToken="createdByUserName"
                    dateToken="creationDate"
                    messageToken="comments"
                    [showNumber]="true"
                    [comments]="tpDetailsService.project()?.comments"
                />
            </div>
        </div>
    </div>

    <adaa-float-action />
</ng-template>
