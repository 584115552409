import { CommonModule } from "@angular/common";
import { Component, computed, inject, input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { Router, RouterModule } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { environment } from "../../../core/environments/environment";
import { AdaaHelper } from "../../../core/utils";
import { AuthLayoutComponent, FormInputComponent } from "../../../shared/components";
import { Constants } from "../../../shared/constants/constants";
import { Language, PermissionAction, UaePassErrors } from "../../../shared/constants/enums";
import { LoginRequest, LoginResponse, MainResponseModel } from "../../../shared/models";
import {
  ConsoleService,
  LanguageService,
  LoaderService,
  LoginService,
  PermissionsService,
  RecaptchaService,
  UserProfileApiService,
} from "../../../shared/services";

@Component({
  selector: "adaa-login",
  standalone: true,
  templateUrl: "./login.component.html",
  styleUrl: "./login.component.scss",
  imports: [CommonModule, TranslateModule, FormInputComponent, ReactiveFormsModule, RouterModule, AuthLayoutComponent],
})
export class LoginComponent implements OnInit {
  languageService = inject(LanguageService);
  private _formBuilder = inject(FormBuilder);
  private _router = inject(Router);
  private _translateService = inject(TranslateService);
  private _toastrService = inject(ToastrService);
  private _loginService = inject(LoginService);
  private _permissionService = inject(PermissionsService);
  private _userProfileApiService = inject(UserProfileApiService);
  private _consoleService = inject(ConsoleService);
  private _loaderService = inject(LoaderService);
  private _recaptchaService = inject(RecaptchaService);

  loginForm: FormGroup;
  showUaePass = environment.uaePass.enable;

  uaePassCode = input<string>(undefined, { alias: "code" });
  uaePassError = input<string>(undefined, { alias: "error_description" });

  isArabic = computed<boolean>(() => this.languageService.current() === Language.Arabic);

  public ngOnInit(): void {
    this._recaptchaService.appendReCAPTCHA();

    this._prepareForm();

    if (this.uaePassCode()) this._handleUaePassLogin(this.uaePassCode()!);
    else if (this.uaePassError()) this._handleUaePassError(this.uaePassError()!);
  }

  public submit() {
    this._recaptchaService.getToken("login", (token) => {
      this.loginForm.get("captchaToken")?.setValue(token);

      if (!this.loginForm.valid) return;

      this._loginService.login(this.loginForm.value as LoginRequest).subscribe((response: MainResponseModel) => {
        const res = response.responseData as LoginResponse;
        if (response.inError) {
          this._toastrService.error(this._translateService.instant("notification.error.code_" + response.responseCode));
          return;
        }

        if (response.responseCode === "1") {
          // Set a default entity for the user (Helps for first routing)
          //   this.storeService.entityId = res.entityId.toString();
          // const defaultEntity: any = { entity: { id: res.entityId } };
          //   this.store.dispatch(selected_entity(defaultEntity));

          this._checkVerificationCode(res);
        }
      });
    });
  }

  public loginUaePass() {
    const language = this.isArabic() ? "ar" : "en";

    const url = `${environment.uaePass.url}?response_type=code&client_id=${environment.uaePass.clientId}&scope=${environment.uaePass.scope}&state=HnlHOJTkTb66Y5H&redirect_uri=${environment.uaePass.loginUrl}&acr_values=urn:safelayer:tws:policies:authentication:level:low&ui_locales=${language}`;

    window.location.href = url;
  }

  /**
   * @description This function to verify the user, if the user first time visit or
   * the local memory cleared then route to /verification page, also check if the last
   * user login is the same for current user, if not then route to /verification
   * @param res
   */
  private _checkVerificationCode(res: LoginResponse, byPassVerification: boolean = false) {
    const lastUserMatch: boolean = AdaaHelper.getLocalStorage(Constants.localStorageKeys.lastUser) === res.username;
    const firstVisit = Number(AdaaHelper.getLocalStorage(Constants.localStorageKeys.firstVisit));

    //If the user for auto test by pass the verifcation page
    const isTestUser = environment.autoTestEmails.includes(res.email);

    byPassVerification = (!firstVisit && lastUserMatch) || isTestUser || byPassVerification;

    AdaaHelper.setLocalStorage(Constants.localStorageKeys.sessionId, res.sessionId, "string");
    AdaaHelper.setLocalStorage(Constants.localStorageKeys.currentEntity, { id: res.entityId }, "json");

    if (byPassVerification) {
      //Used every login
      AdaaHelper.setLocalStorage(Constants.localStorageKeys.firstVisit, "0", "string");
      AdaaHelper.setLocalStorage(Constants.localStorageKeys.lastUser, res.username, "string");

      //Used after login to check page
      AdaaHelper.setLocalStorage(Constants.localStorageKeys.checkLogin, "1", "string");

      //User to get the current user info in the system
      AdaaHelper.setLocalStorage(Constants.localStorageKeys.user, res, "json");
    }
    // Navigate to appropriate page based on the user's permissions
    this._onSuccessfulLogin(res, byPassVerification);
  }

  private _prepareForm() {
    this.loginForm = this._formBuilder.group({
      captchaToken: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      password: [null, Validators.required],
      token: [""],
      uuid: [""],
    });
  }

  private _onSuccessfulLogin(user: LoginResponse, byPassVerification = false) {
    const clearRelevantStorage = () => {
      AdaaHelper.deleteLocalStorage(Constants.localStorageKeys.firstVisit);
      AdaaHelper.deleteLocalStorage(Constants.localStorageKeys.lastUser);
      AdaaHelper.deleteLocalStorage(Constants.localStorageKeys.lastUrlVisited);
    };

    this._userProfileApiService.getSecurityQuestion().subscribe({
      next: (response) => {
        if (response.inError || !byPassVerification) {
          clearRelevantStorage();
          return this._router.navigate(["/verification"], { state: { user } });
        }

        return this._getRedirectUrl(response.responseData.length > 0, user);
      },
    });
  }

  private _getRedirectUrl(hasSecurityQuestions: boolean, user: LoginResponse): void {
    //THIS TO GET THE CONSOLE AFTER THE FIRST LOGIN
    //THE CACHE SHOULD BE CLEARED
    this._consoleService.init(false);

    if (!hasSecurityQuestions) {
      this._toastrService.warning(this._translateService.instant("notification.warning.required_field_user_profile"));
      this._router.navigate(["/user-profile"]);
      return;
    }

    this._permissionService.loadUserPermissions().subscribe({
      next: () => {
        const hasTpDashboardPermission = this._permissionService.hasPermission(
          [
            {
              objectTypeId: Constants.CONSTANT_PERMISSIONS.NATIONAL_PROJECTS_DASHBOARD,
              permissionAction: PermissionAction.view,
            },
          ],
          "and"
        );

        const hasHomepagePermission = this._permissionService.hasPermission(
          [
            {
              objectTypeId: Constants.CONSTANT_PERMISSIONS.HOMEPAGE,
              permissionAction: PermissionAction.view,
            },
          ],
          "and"
        );

        if (hasHomepagePermission) this._router.navigate(["/dashboard/target"]);
        else if (hasTpDashboardPermission) this._router.navigate(["/national-projects-dashboard"]);
        else this._router.navigate(["/verification"], { state: { user } });
        return;
      },
    });
  }

  private _handleUaePassLogin(code: string) {
    this._loginService.uaePassLogin(code).subscribe({
      next: (response) => {
        if (response.responseCode === "63") {
          this._router.navigateByUrl(`/link-account/${response.responseData.uuid}`);
        } else {
          if (response.inError) {
            this._endUaePassSession(response.responseCode);
            return;
          }

          AdaaHelper.setLocalStorage(Constants.localStorageKeys.uaePassLogin, true, "string");
          this._checkVerificationCode(response.responseData, true);
        }
      },
    });
  }

  private _handleUaePassError(errorCode: string) {
    let errorMessage: string | undefined = undefined;

    if (errorCode === UaePassErrors.CANCELLED_ON_WEB) errorMessage = "notification.error.code_68";
    else if (errorCode === UaePassErrors.CANCELLED_ON_APP) errorMessage = "notification.error.code_72";
    else if (errorCode === UaePassErrors.USER_CLICKED_ON_EXIT) errorMessage = "notification.error.code_69";

    if (errorMessage)
      this._translateService.get(errorMessage).subscribe({
        next: (result) => {
          this._toastrService.error(result, undefined, { timeOut: 10000 });
        },
      });
  }

  private _endUaePassSession(responseCode: string) {
    const errorCodes = ["65", "66", "67", "68", "69", "70", "71", "72"];

    if (errorCodes.includes(responseCode)) {
      this._loaderService.start();
      this._loginService.uaePassLogout();
    }
  }
}
