<adaa-entity-plan-change-detection
    [template]="page"
    [request$]="fetchMilestone()"
    [ignoreEntityDetection]="AdaaHelper.isPMOEntity()"
    (continue)="initMilestone($event)"
/>

<ng-template #page>
    <div class="row">
        <div class="col-12">
            <h4 class="fw-bold">
                {{ "national_projects.national_projects_milestone.singular_title" | translate }}
                <span class="fw-light mx-1">
                    @if (displayLabel) {
                        - {{ displayLabel }}
                    }
                </span>
            </h4>
        </div>
        @if (id()) {
            <section class="d-block">
                <adaa-wf-alert-decision [itemId]="id()!" [itemType]="constants.CONSTANT_NATIONAL_PROJECTS_MILESTONE" />
            </section>
        }
        <div class="col-12" [formGroup]="milestoneForm">
            <adaa-form-steps
                [tabs]="tabs"
                [showSaveButton]="false"
                [showFinishButton]="true"
                (currentTab)="activeTab.set($event)"
                (submitClicked)="save()"
                (cancelClicked)="goBack()"
            >
                <div class="tab-pane fade" id="tab-0" role="tabpanel" [ngClass]="{ 'show active': activeTab() === 0 }">
                    <div class="bg-white shadow-md rounded p-2 mb-2">
                        <div class="row">
                            <div class="col-12">
                                <adaa-form-dropdown
                                    controlName="nationalProjectId"
                                    formControlName="nationalProjectId"
                                    [invalid]="submitted && milestoneForm.get('nationalProjectId')?.invalid!"
                                    [label]="'national_projects.singular_title' | translate"
                                    [setValidator]="formValidation()!"
                                    [options]="projects()"
                                />
                            </div>
                            <div class="col-sm-6 col-xs-12">
                                <adaa-form-input
                                    controlName="nameAE"
                                    formControlName="nameAE"
                                    [invalid]="submitted && milestoneForm.get('nameAE')?.invalid!"
                                    [label]="'common.form.label.nameAE' | translate"
                                    [setValidator]="formValidation()!"
                                />
                            </div>
                            <div class="col-sm-6 col-xs-12">
                                <adaa-form-input
                                    controlName="nameEN"
                                    formControlName="nameEN"
                                    [invalid]="submitted && milestoneForm.get('nameEN')?.invalid!"
                                    [label]="'common.form.label.nameEN' | translate"
                                    [setValidator]="formValidation()!"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6 col-xs-12">
                                <adaa-form-input
                                    controlName="dscAE"
                                    formControlName="dscAE"
                                    [isTextArea]="true"
                                    [invalid]="submitted && milestoneForm.get('dscAE')?.invalid!"
                                    [label]="'common.form.label.descriptionAE' | translate"
                                    [setValidator]="formValidation()!"
                                />
                            </div>
                            <div class="col-sm-6 col-xs-12">
                                <adaa-form-input
                                    controlName="dscEN"
                                    formControlName="dscEN"
                                    [isTextArea]="true"
                                    [invalid]="submitted && milestoneForm.get('dscEN')?.invalid!"
                                    [label]="'common.form.label.descriptionEN' | translate"
                                    [setValidator]="formValidation()!"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6 col-xs-12">
                                <adaa-form-input-date
                                    controlName="startDate"
                                    formControlName="startDate"
                                    [invalid]="
                                        submitted && (milestoneForm.get('startDate')?.invalid! || milestoneForm.errors?.startDate_endDate_Invalid)
                                    "
                                    [label]="'national_projects.planned_start_date' | translate"
                                    [setValidator]="formValidation()!"
                                />
                            </div>
                            <div class="col-sm-6 col-xs-12">
                                <adaa-form-input-date
                                    controlName="endDate"
                                    formControlName="endDate"
                                    [invalid]="
                                        submitted && (milestoneForm.get('endDate')?.invalid! || milestoneForm.errors?.startDate_endDate_Invalid)
                                    "
                                    [label]="'national_projects.planned_end_date' | translate"
                                    [setValidator]="formValidation()!"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6 col-xs-12">
                                <adaa-form-input-date
                                    controlName="actualStartDate"
                                    formControlName="actualStartDate"
                                    [invalid]="
                                        submitted &&
                                        (milestoneForm.get('actualStartDate')?.invalid! ||
                                            milestoneForm.errors?.actualStartDate_actualEndDate_Invalid)
                                    "
                                    [label]="'national_projects.actual_start_date' | translate"
                                    [setValidator]="formValidation()!"
                                />
                            </div>
                            <div class="col-sm-6 col-xs-12">
                                <adaa-form-input-date
                                    controlName="actualEndDate"
                                    formControlName="actualEndDate"
                                    [invalid]="
                                        submitted &&
                                        (milestoneForm.get('actualEndDate')?.invalid! || milestoneForm.errors?.actualStartDate_actualEndDate_Invalid)
                                    "
                                    [label]="'national_projects.actual_end_date' | translate"
                                    [setValidator]="formValidation()!"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="bg-white shadow-md rounded p-2 mb-2">
                        <div class="row">
                            <div class="col-sm-6 col-xs-12">
                                <adaa-form-dropdown
                                    controlName="entityId"
                                    formControlName="entityId"
                                    [invalid]="submitted && milestoneForm.get('entityId')?.invalid!"
                                    [label]="'national_projects.accountable_entity' | translate"
                                    [setValidator]="formValidation()!"
                                    [options]="entities()"
                                    (inputChanges)="entityChanged($event)"
                                />
                            </div>
                            <div class="col-sm-6 col-xs-12">
                                <adaa-form-dropdown
                                    controlName="owner"
                                    formControlName="owner"
                                    [invalid]="submitted && milestoneForm.get('owner')?.invalid!"
                                    [label]="'common.form.label.owner' | translate"
                                    [setValidator]="formValidation()!"
                                    [options]="owners()"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4 col-xs-12">
                                <adaa-form-input
                                    controlName="budget"
                                    formControlName="budget"
                                    inputType="positiveNumber"
                                    [invalid]="submitted && milestoneForm.get('budget')?.invalid!"
                                    [label]="'initiatives.budget' | translate"
                                    [setValidator]="formValidation()!"
                                />
                            </div>
                            <div class="col-sm-4 col-xs-12">
                                <adaa-form-input
                                    controlName="expense"
                                    formControlName="expense"
                                    inputType="positiveNumber"
                                    [invalid]="submitted && milestoneForm.get('expense')?.invalid!"
                                    [label]="'initiatives.expenses' | translate"
                                    [setValidator]="formValidation()!"
                                />
                            </div>
                            <div class="col-sm-4 col-xs-12">
                                <adaa-form-input
                                    controlName="progress"
                                    formControlName="progress"
                                    inputType="positiveNumber"
                                    [maxLength]="1"
                                    [invalid]="milestoneForm.get('progress')?.invalid!"
                                    [label]="'box_status.activity_progress' | translate"
                                    [setValidator]="formValidation()!"
                                    (inputChanges)="onProgressChange($event)"
                                />
                            </div>
                        </div>
                    </div>

                    @if (+milestoneForm.get("progress")?.value === 100) {
                        <div class="bg-white shadow-md rounded p-2 mb-2">
                            <div class="row">
                                <div class="col-12">
                                    <h6 class="fw-bold">{{ "national_projects.sign_off.justification" | translate }}</h6>
                                </div>
                                <div class="col-12">
                                    <adaa-form-input
                                        controlName="signOff"
                                        formControlName="signOff"
                                        [isTextArea]="true"
                                        [showAsRequired]="true"
                                        [invalid]="submitted && milestoneForm.get('signOff')?.invalid!"
                                        [label]="'national_projects.sign_off.justification_sub_text' | translate"
                                        [setValidator]="formValidation()!"
                                    />
                                </div>
                                <div class="col-12">
                                    <adaa-form-input-file
                                        allowedFilesMessage=""
                                        dropFileZoneMessage="national_projects.sign_off.evidence"
                                        [onDeleteRemoveFile]="false"
                                        [allowedFileSize]="26214400"
                                        [allowedFiles]="allowedFiles"
                                        [file]="signOffFile"
                                        (inputChanges)="signOffFilechange($event)"
                                    ></adaa-form-input-file>
                                </div>
                            </div>
                        </div>
                    }

                    <div class="bg-white shadow-md rounded p-2 mb-2">
                        <div class="row">
                            <div class="col-12">
                                <adaa-form-dropdown-multi
                                    controlName="sectors"
                                    formControlName="sectors"
                                    [invalid]="submitted && milestoneForm.get('sectors')?.invalid!"
                                    [label]="'national_projects.national_sector.title' | translate"
                                    [setValidator]="formValidation()!"
                                    [options]="sectors()"
                                    (inputChanges)="sectorsChanged($event)"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="bg-white shadow-md rounded p-2 mb-2">
                        <div class="row">
                            <div class="col-12">
                                <adaa-comments
                                    noCommentsMessage="common.tables.no_info"
                                    cardTitle="national_projects.comments"
                                    titleToken="createdByUserName"
                                    dateToken="creationDate"
                                    messageToken="comments"
                                    classes="fw-bold fs-6"
                                    [showNumber]="true"
                                    [comments]="comments"
                                    [isEditable]="true"
                                    editCardTitle="national_projects.add_comment"
                                    (commentAdded)="commentAdded($event)"
                                    (commentRemoved)="commentRemoved($event)"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tab-pane fade" id="tab-1" role="tabpanel" [ngClass]="{ 'show active': activeTab() === 1 }">
                    <div class="bg-white shadow-md rounded p-2 mb-2">
                        <adaa-contributing-entities
                            [id]="+id()"
                            [itemType]="constants.CONSTANT_NATIONAL_PROJECTS_MILESTONE"
                            entitiesControlName="cEntities"
                            otherEntitiesControlName="cOtherEntities"
                            [contributingEntities]="this.milestoneForm.get('contributingEntities')?.value"
                            [entities]="entities()"
                            [otherEntities]="otherEntities()"
                            (contributingEntitiesChanged)="this.milestoneForm.get('contributingEntities')?.setValue($event)"
                        />
                    </div>
                </div>

                <div class="tab-pane fade" id="tab-2" role="tabpanel" [ngClass]="{ 'show active': activeTab() === 2 }">
                    <div class="bg-white shadow-md rounded p-2 mb-2">
                        <adaa-sub-milestone-list
                            [subMilestones]="subMilestones()"
                            [entities]="filteredEntites"
                            [otherEntities]="filteredOtherEntites"
                            [parentEntityId]="this.milestoneForm.get('entityId')?.value"
                            [parentStartDate]="this.milestoneForm.get('startDate')?.value"
                            [parentEndDate]="this.milestoneForm.get('endDate')?.value"
                            (subMilestonesChanged)="milestoneForm.get('nationalProjectSubMilestones')?.setValue($event)"
                        />
                    </div>
                </div>

                <div class="tab-pane fade" id="tab-3" role="tabpanel" [ngClass]="{ 'show active': activeTab() === 3 }">
                    <div class="bg-white shadow-md rounded p-2 mb-2">
                        <adaa-team-members-list
                            tableTitle="national_projects.national_projects_milestone.team_members"
                            [tableConf]="constants.MAIN_TABLE_LIST_CONF_KEY.MILESTONE_MEMBERS_CONF_LIST"
                            [teamMembers]="milestoneForm.get('teamMembers')?.value ?? []"
                            [entities]="filteredEntites"
                            [otherEntities]="filteredOtherEntites"
                            [enableProjectMembers]="true"
                            [projectMembers]="projectTeamMembers()"
                            (teamMembersChanged)="milestoneForm.get('teamMembers')?.setValue($event)"
                        />
                    </div>
                </div>

                <div class="tab-pane fade" id="tab-4" role="tabpanel" [ngClass]="{ 'show active': activeTab() === 4 }">
                    <div class="bg-white shadow-md rounded p-2 mb-2">
                        <adaa-attachments-list
                            [attachments]="milestoneForm.get('attachments')?.value ?? []"
                            (attachmentsChanged)="milestoneForm.get('attachments')?.setValue($event)"
                        />
                    </div>
                </div>
            </adaa-form-steps>
        </div>
    </div>

    <adaa-float-action />
</ng-template>
