<div class="p-2">
    <adaa-data-table
        #adaaDataTable
        apiName="getAllV3"
        apiPrefix="libmetric"
        paramsString="itemType=83"
        [titleHeader]="breadcrumb() | translate"
        [configKey]="Constants.MAIN_TABLE_LIST_CONF_KEY.DATAENTRY_METRICS"
        [itemTypeId]="Constants.CONSTANT_PERMISSIONS.DATA_ENTRY"
        [enableDeleteAction]="false"
        [enableAdd]="false"
        [disableEditRules]="disableEditRules"
        (actionClicked)="getAction($event)"
    ></adaa-data-table>
</div>
