<div class="d-flex gap-2">
    <p class="fw-bold fs-3">{{ "breadcrumbs.text-mapping" | translate }} -</p>
    <p class="fw-bold fs-3">{{ displayLabel() }}</p>
</div>

<div class="bg-white shadow-md rounded p-2 mb-3">
    <form class="col-12" [formGroup]="textMappingForm">
        <div class="bg-white shadow-md rounded p-2 mb-3">
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.nameAE' | translate"
                        [setValidator]="textMappingsEditorService.formValidation()!"
                        [invalid]="checkInvalid('nameAE')"
                        formControlName="nameAE"
                        controlName="nameAE"
                    ></adaa-form-input>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.nameEN' | translate"
                        [setValidator]="textMappingsEditorService.formValidation()!"
                        [invalid]="checkInvalid('nameEN')"
                        formControlName="nameEN"
                        controlName="nameEN"
                    ></adaa-form-input>
                </div>
            </div>
            <div class="row">
                <div class="col-12 d-flex justify-content-center">
                    <adaa-form-checkbox
                        controlName="available"
                        formControlName="available"
                        checkboxMode="yesNo"
                        [label]="'common.form.label.available' | translate"
                        [boldLabel]="true"
                    />
                </div>
            </div>
        </div>
    </form>
</div>

<adaa-data-table
    #adaaDataTable
    [isLocalData]="true"
    [localData]="texMapValues()"
    [configKey]="'text_mapping_value_conf_list'"
    [itemTypeId]="constants.CONSTANT_PERMISSIONS.TEXTMAPPING"
    [enableEditAction]="pageMode() !== PageMode.view"
    [enableDeleteAction]="pageMode() !== PageMode.view"
    [enableActionsCell]="pageMode() !== PageMode.view"
    [enableAdd]="pageMode() !== PageMode.view"
    [enableViewAction]="false"
    (addNewClicked)="addNew()"
    (actionClicked)="getAction($event)"
></adaa-data-table>

<div class="col-12 mb-3">
    <adaa-form-action-buttons
        [cancelButtonTitle]="pageMode() === PageMode.view ? ('common.form.button.close' | translate) : ('common.form.button.cancel' | translate)"
        [showSaveButton]="pageMode() !== PageMode.view"
        [saveButtonTitle]="'executive_teams.submit' | translate"
        (saveClicked)="updateTextMapping()"
        (cancelClicked)="cancelForm()"
    ></adaa-form-action-buttons>
</div>

<adaa-text-mapping-value-editor #editor [pageMode]="valuePageMode()" (editorClosed)="submit($event)"> </adaa-text-mapping-value-editor>
