import { Component, inject, input, OnInit, signal, viewChild } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

import { AdaaHelper } from "../../../../core/utils";
import { DataTableComponent } from "../../../../shared/components";
import { Constants } from "../../../../shared/constants/constants";
import { AdaaBoolean } from "../../../../shared/constants/enums";
import { ItemAction, TableButtonClicked } from "../../../../shared/models";
import { SystemLayoutService } from "../../../../shared/services";

@Component({
  selector: "adaa-data-entry-metrics-list",
  standalone: true,
  imports: [DataTableComponent, TranslateModule],
  templateUrl: "./data-entry-metrics-list.component.html",
  styleUrl: "./data-entry-metrics-list.component.scss",
})
export class DataEntryMetricsListComponent implements OnInit {
  private _router = inject(Router);
  private _systemLayoutService = inject(SystemLayoutService);

  breadcrumb = input.required<string>();
  Constants = Constants;
  untilDestroy = AdaaHelper.untilDestroyed();
  adaaDataTable = viewChild.required<DataTableComponent>("adaaDataTable");
  isPMO = signal<boolean>(AdaaHelper.isPMOEntity());

  public get disableEditRules(): ItemAction[] {
    if (this.isPMO()) {
      return [
        {
          propertyName: "synced",
          compareWith: AdaaBoolean.Y,
          operator: "equal",
        },
        {
          propertyName: "type",
          compareWith: Constants.METRICS_TYPE.DIMENSION,
          operator: "equal",
        },
      ];
    } else {
      return [
        {
          propertyName: "synced",
          compareWith: AdaaBoolean.Y,
          operator: "equal",
        },
        {
          propertyName: "type",
          compareWith: Constants.METRICS_TYPE.GLOBAL,
          operator: "equal",
        },
        {
          propertyName: "type",
          compareWith: Constants.METRICS_TYPE.DIMENSION,
          operator: "equal",
        },
      ];
    }
  }

  public ngOnInit(): void {
    this._systemLayoutService.hasActiveEntityChanged$.pipe(this.untilDestroy()).subscribe({
      next: () => {
        this.adaaDataTable().loadTableData();
        this.isPMO.set(AdaaHelper.isPMOEntity());
      },
    });
  }

  public getAction(value: TableButtonClicked) {
    switch (value.event) {
      case "view":
        this._router.navigateByUrl(`/console/data-entry/metrics/${value.data.id}`);
        break;
      case "edit":
        this._router.navigateByUrl(`/console/data-entry/metrics/edit/${value.data.id}`);
        break;

      default:
        break;
    }
  }
}
