<ng-template #content let-modal>
    <div class="modal-header">
        <h5 class="text-center fw-bold w-100">{{ "notification_config.templates.modal.title" | translate }}</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body overflow-x-hidden p-3">
        <form class="col-12" [formGroup]="editorForm">
            <div class="row">
                <div class="col-md-4 col-sm-12 col-xs-12">
                    <adaa-placeholder-list [items]="placehodersList()" (placeholderDoubleClick)="placeholderSelected($event)"></adaa-placeholder-list>
                </div>
                <div class="col-md-8 col-sm-12 col-xs-12">
                    <div class="my-2">
                        <adaa-form-input
                            formControlName="subjectAE"
                            controlName="subjectAE"
                            textAlignment="right"
                            [label]="'common.form.label.titleAE' | translate"
                        ></adaa-form-input>
                        <div class="px-1 mt-2">
                            <p class="my-1 form-label">{{ "common.form.label.templateAE" | translate }}</p>
                            <adaa-quill-editor
                                #contentAE
                                [language]="'ae'"
                                [placeholder]="placeholder()"
                                (value)="setValue($event, Language.Arabic)"
                                (click)="onTextareaFocus($event)"
                            ></adaa-quill-editor>
                        </div>
                    </div>
                    <div class="my-2">
                        <adaa-form-input
                            formControlName="subjectEN"
                            controlName="subjectEN"
                            textAlignment="left"
                            [label]="'common.form.label.titleEN' | translate"
                        ></adaa-form-input>
                        <div class="px-1 mt-2">
                            <p class="my-1 form-label">{{ "common.form.label.templateEN" | translate }}</p>
                            <adaa-quill-editor
                                #contentEN
                                [language]="'en'"
                                (value)="setValue($event, Language.English)"
                                (click)="onTextareaFocus($event)"
                            ></adaa-quill-editor>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div class="my-2">
            <adaa-form-action-buttons
                [cancelButtonTitle]="'common.form.button.cancel'"
                [saveButtonTitle]="'common.form.button.submit'"
                (cancelClicked)="modal.close()"
                (saveClicked)="submit()"
            ></adaa-form-action-buttons>
        </div>
    </div>
</ng-template>
