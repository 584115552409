<div class="row text-center">
    <div class="col-md-16">
        <h2 class="fw-bold text">{{ "executive_dashboard.title" | translate }}</h2>
    </div>
</div>

@if (adaaHelper.isPMOEntity()) {
    <form [formGroup]="executiveDashboardForm">
        <div class="row mb-3">
            <div class="col-12">
                <div class="bg-white shadow-md rounded p-1">
                    <div class="row">
                        <div class="col-md-6 col-sm-12 col-xs-12">
                            <adaa-form-dropdown
                                [label]="'entity.create.entity_type' | translate"
                                [options]="executiveDashboardService.entityTypes"
                                [controlName]="'entityType'"
                                [formControlName]="'entityType'"
                                [classes]="'text-primary fw-bold'"
                                (inputChanges)="applyFilters()"
                            ></adaa-form-dropdown>
                        </div>
                        <div class="col-md-6 col-sm-12 col-xs-12">
                            <adaa-form-dropdown
                                [label]="'entity.create.entity_size' | translate"
                                [options]="executiveDashboardService.entitySizes"
                                [controlName]="'entitySize'"
                                [formControlName]="'entitySize'"
                                [classes]="'text-primary fw-bold'"
                                (inputChanges)="applyFilters()"
                            ></adaa-form-dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
}

<div class="row mb-3">
    <div class="col-12">
        <adaa-period-slider [activePeriod]="selectedPeriod()!" type="year" (periodSelect)="selectPeriod($event)"></adaa-period-slider>
    </div>
</div>

<section class="performances bg-white shadow-md rounded mb-3 overflow-hidden">
    <div class="performances-container">
        @for (item of executiveDashboardService.performances(); track item; let index = $index) {
            <div class="performance">
                <adaa-doughnut
                    [amount]="item.value"
                    [label]="item | translateToken: 'label'"
                    [colorId]="adaaHelper.isPMOEntity() ? item.color : constants.CONSTANT_COLORS.EVALUATION_GOLD"
                    [size]="item.id === executiveDashboardService.selectedPerformance()?.id ? 'md' : 'sm'"
                    [showProgressCircle]="adaaHelper.isPMOEntity()"
                    [circleColor]="
                        adaaHelper.isPMOEntity() ? '#d2d2d2' : item.id === executiveDashboardService.selectedPerformance()?.id ? '#d29f35' : '#d2d2d2'
                    "
                    (onclick)="selectPerformance(item)"
                />
            </div>
        }
    </div>
</section>

@if (executiveDashboardService.performanceChartsData()) {
    <div class="row mb-3">
        <div class="col-12">
            <div class="bg-white shadow-md rounded p-1">
                <div class="row">
                    <div class="col-12">
                        <canvas
                            class="entities-performance"
                            baseChart
                            [data]="executiveDashboardService.performanceChartsData()"
                            [options]="chartsService.barChartOptions"
                            [legend]="false"
                            [type]="'bar'"
                        >
                        </canvas>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

@if (executiveDashboardService.rankChartsData()) {
    <div class="row">
        <div class="col-12">
            <div class="bg-white shadow-md rounded p-1">
                <div class="row">
                    <div class="col-12">
                        <canvas
                            class="yearly-performance"
                            baseChart
                            [data]="executiveDashboardService.rankChartsData()"
                            [options]="chartsService.lineChartOptions"
                            [legend]="false"
                            [type]="'line'"
                        >
                        </canvas>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
