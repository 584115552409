import { inject, Injectable, signal } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { filter, forkJoin, ignoreElements, map, switchMap } from "rxjs";
import { tap } from "rxjs/operators";

import { AdaaHelper, useNewDS } from "../../../../../../core/utils";
import { Constants } from "../../../../../../shared/constants/constants";
import {
  InitiativeModelType,
  TpMilestone,
  TpProjectDetails,
  TransformationalTarget,
  ValueText,
} from "../../../../../../shared/models";
import {
  InitiativeApiService,
  TpApiService,
  TpMilestoneApiService,
  TransformationalTargetsApiService,
} from "../../../../../../shared/services";

export { ContributingEntitiesDropdownFieldComponent } from "./contributing-entities-dropdown-field/contributing-entities-dropdown-field.component";
export { GovDirectionsFieldComponent } from "./gov-directions-field/gov-directions-field.component";
export { InitiativesFieldComponent } from "./initiatives-field/initiatives-field.component";
export { MainOutcomeTypeFieldComponent } from "./main-outcome-type-field/main-outcome-type-field.component";
export { ObjectivesFieldComponent } from "./objectives-field/objectives-field.component";
export { ProjectMilestoneFieldComponent } from "./project-milestone-field/project-milestone-field.component";
export { ServicesFieldComponent, ServicesFieldOnChangeType } from "./services-field/services-field.component";
export { SponsorEntityFieldComponent } from "./sponsor-entity-field/sponsor-entity-field.component";
export { TpSelectionFieldComponent } from "./tp-selection-field/tp-selection-field.component";
export { TransformationalTargetFieldComponent } from "./transformational-target-field/transformational-target-field.component";

@Injectable({
  providedIn: null,
})
export class InfoTabService {
  private readonly _tpApiService = inject(TpApiService);
  private readonly _translateService = inject(TranslateService);
  private readonly _initiativeApiService = inject(InitiativeApiService);
  private readonly _tpMilestoneApiService = inject(TpMilestoneApiService);
  private readonly _transformationalTargetsApiService = inject(TransformationalTargetsApiService);

  transformationProjects = signal<TpProjectDetails[] | undefined>(undefined);
  transformationalTargets = signal<TransformationalTarget[] | undefined>(undefined);
  projectMilestones = signal<TpMilestone[] | undefined>(undefined);
  initiatives = signal<InitiativeModelType[] | undefined>(undefined);
  initiativesOptions = signal<ValueText[]>([]);

  public fetchTProjects(kpiId?: number) {
    const entityId = AdaaHelper.entity?.id;

    this._tpApiService
      .getAllForMainOutcomes({
        entityId,
        kpiId,
      })
      .pipe(
        filter((res) => !res.inError),
        map((res) => res.responseData),
        tap({
          next: (data) => {
            this.transformationProjects.set(data ?? []);
          },
        }),
        ignoreElements()
      )
      .subscribe();
  }

  public fetchProjectMilestiones() {
    this._tpMilestoneApiService
      .getMilestonesByEntityId(AdaaHelper.entity?.id)
      .pipe(
        filter((res) => !res.inError),
        map((res) => res.responseData),
        tap((data) => {
          this.projectMilestones.set(data ?? []);
        }),
        ignoreElements()
      )
      .subscribe();
  }

  public fetchTransformationalTargets(transformationalProjectId: number, kpiId?: number) {
    return this._transformationalTargetsApiService
      .getAllByNationalProjectId({
        transformationalProjectId,
        kpiId,
      })
      .pipe(
        filter((res) => !res.inError),
        map((res) => res.responseData),
        tap({
          next: (data) => {
            this.transformationalTargets.set(data ?? []);
          },
        })
      );
  }

  public fetchInitiatives() {
    // The translator
    const translator = ([regularTaskTxt, strategicProjectTxt]: readonly [string, string]) => {
      const list = this.initiatives() ?? [];
      const options: ValueText[] = [];

      options.push(
        ...list.map((item) => {
          const value = item.id;

          let type: string;
          if (item.initiativeType === Constants.CONSTANT_REGULAR_TASK_INITIATIVETYPE) {
            type = regularTaskTxt;
          } else {
            type = strategicProjectTxt;
          }

          return {
            value,
            text: `${AdaaHelper.getItemValueByToken(item, "name")} - (${type})`,
          } as ValueText;
        })
      );

      this.initiativesOptions.set(options);
    };

    // The API Call
    const query = () => {
      return this._initiativeApiService
        .getAllByEntityIdWithPagination({
          itemType: useNewDS() ? undefined : Constants.CONSTANT_REGULAR_TASK_INITIATIVETYPE,
          offset: 0,
          searchFields: {
            searchFields: { status: { value: 1, order: undefined } },
          },
        })
        .pipe(
          filter((res) => !res.inError),
          map((res) => res.responseData),
          tap({
            next: (data) => this.initiatives.set(data ?? []),
          })
        );
    };

    forkJoin<[string, string]>([
      this._translateService.get("initiatives.regular_task"),
      this._translateService.get("initiatives.strategic_project"),
    ])
      .pipe(
        switchMap(([mainTxt, strategicTxt]) => query().pipe(map(() => [mainTxt, strategicTxt] as const))),
        tap((data) => {
          translator(data);
        }),
        ignoreElements()
      )
      .subscribe();
  }
}
