<div class="w-100 d-flex justify-content-center align-content-center card mb-2 py-1 px-2 bg-white border-0 form-section">
    <section class="d-flex row mt-1 mb-3">
        <div class="col-12">
            <adaa-data-table
                #benchmarkList
                [titleHeader]="'kpi.benchmarking' | translate"
                [useSmHeader]="true"
                [configKey]="tableConfList"
                [isLocalData]="true"
                [enableAdd]="true"
                [enableExport]="false"
                [enableSelection]="false"
                [enableActionsCell]="true"
                [enableEditAction]="true"
                [enableDeleteAction]="true"
                [enableConnectionsAction]="false"
                [localData]="sanitizedData()"
                (addNewClicked)="handleAddTableAction()"
                (actionClicked)="handleBenchmarkTableActions($event)"
            />
        </div>
    </section>
</div>

<ng-template #modal>
    <div class="modal-header">
        <h4 class="modal-title w-100 text-center">{{ "nkpi.add_new_benchmark" | translate }}</h4>
        <button type="button" class="btn-close" aria-label="Close button" aria-describedby="modal-title" (click)="closeModal(false)"></button>
    </div>

    <div class="modal-body bg-white px-3 py-1">
        <form
            id="kpi-benchmarking-form"
            autocomplete="on"
            spellcheck="true"
            [formGroup]="model"
            [lang]="languageService.current()"
            [dir]="languageService.direction()"
        >
            <section class="d-flex row">
                <div class="col-6">
                    <adaa-form-input
                        formControlName="nameAE"
                        controlName="nameAE"
                        classes="form-control shadow-none"
                        [invalid]="model.get('nameAE')?.touched! && model.get('nameAE')?.invalid!"
                        [label]="'common.form.label.nameAE' | translate"
                        [required]="isFieldRequired(validations(), 'nameAE')"
                        [adaaInputDisabled]="isReadonly()"
                    />
                </div>

                <div class="col-6">
                    <adaa-form-input
                        formControlName="nameEN"
                        controlName="nameEN"
                        classes="form-control shadow-none"
                        [invalid]="model.get('nameEN')?.touched! && model.get('nameEN')?.invalid!"
                        [label]="'common.form.label.nameEN' | translate"
                        [required]="isFieldRequired(validations(), 'nameEN')"
                        [adaaInputDisabled]="isReadonly()"
                    />
                </div>
            </section>

            <section class="d-flex row">
                <div class="col-6">
                    <adaa-form-dropdown
                        controlName="benchType"
                        formControlName="benchType"
                        [clearable]="true"
                        [label]="'nkpi.benchType' | translate"
                        [options]="benchmarkTypeOptions()"
                        [invalid]="model.get('benchType')?.touched! && model.get('benchType')?.invalid!"
                        [required]="isFieldRequired(validations(), 'benchType')"
                    />
                </div>

                <div class="col-6">
                    <adaa-form-input
                        formControlName="benchSource"
                        controlName="benchSource"
                        classes="form-control shadow-none"
                        [invalid]="model.get('benchSource')?.touched! && model.get('benchSource')?.invalid!"
                        [label]="'nkpi.sourceAE' | translate"
                        [required]="isFieldRequired(validations(), 'benchSource')"
                        [adaaInputDisabled]="isReadonly()"
                    />
                </div>
            </section>

            <section class="row py-2">
                <div class="col-12">
                    <adaa-data-table
                        #benchmarkUploadList
                        [titleHeader]="'nkpi.upload_file' | translate"
                        [useSmHeader]="true"
                        [configKey]="attachmentTableConfList"
                        [isLocalData]="true"
                        [enableAdd]="!isReadonly()"
                        [enableExport]="false"
                        [enableFilter]="false"
                        [enableSelection]="false"
                        [enableActionsCell]="true"
                        [enableEditAction]="!isReadonly()"
                        [enableDeleteAction]="!isReadonly()"
                        [enableViewAction]="isReadonly()"
                        [enableConnectionsAction]="false"
                        [localData]="benchFileList()"
                        (actionClicked)="handleAttachmentTableAction($event)"
                        (addNewClicked)="handleAddFileAction()"
                    />
                </div>
            </section>
        </form>

        @if (!isReadonly()) {
            <form
                id="kpi-benchmarking-year-form"
                class="py-1"
                autocomplete="off"
                spellcheck="false"
                [formGroup]="subModel"
                [lang]="languageService.current()"
                [dir]="languageService.direction()"
            >
                <section class="d-flex row align-items-center align-content-center">
                    <div class="col-4">
                        <adaa-form-dropdown
                            controlName="year"
                            formControlName="year"
                            [clearable]="true"
                            [label]="'nkpi.benchYear' | translate"
                            [options]="benchmarkYearsOptions()"
                            [invalid]="subModel.get('year')?.touched! && subModel.get('year')?.invalid!"
                            [required]="isFieldRequired(validations(), 'years')"
                            [adaaInputDisabled]="isDefined(selectedActiveBenchmarkYear())"
                        />
                    </div>

                    <div class="col-4">
                        <adaa-form-dropdown
                            controlName="countryId"
                            formControlName="countryId"
                            [clearable]="true"
                            [label]="'nkpi.country' | translate"
                            [options]="countryOptions()"
                            [invalid]="subModel.get('countryId')?.touched! && subModel.get('countryId')?.invalid!"
                            [required]="isFieldRequired(validations(), 'country')"
                            [adaaInputDisabled]="isDefined(selectedActiveBenchmarkYear())"
                        />
                    </div>

                    <div class="col-3">
                        <adaa-form-input
                            formControlName="value"
                            controlName="value"
                            classes="form-control shadow-none benchmark-year-value-input"
                            inputType="number"
                            [invalid]="subModel.get('value')?.touched! && subModel.get('value')?.invalid!"
                            [label]="'nkpi.benchValue' | translate"
                            [required]="true"
                            [showAsRequired]="true"
                        />
                    </div>

                    <div class="col-1 flex-shrink-0 mt-4 d-flex justify-content-center align-items-center align-content-center">
                        <button
                            type="submit"
                            class="btn bg-secondary text-white mx-1 p-0 flex-shrink-0 btn-years-action"
                            [disabled]="subModel.disabled"
                            (click)="submitBenchmarkYear()"
                        >
                            @if (isDefined(selectedActiveBenchmarkYear())) {
                                <i class="fa fa-pen"></i>
                            } @else {
                                <i class="fa fa-plus"></i>
                            }
                        </button>

                        <button
                            type="reset"
                            class="btn bg-danger text-white mx-1 p-0 flex-shrink-0 btn-years-action"
                            [disabled]="subModel.disabled"
                            (click)="resetSubModel()"
                        >
                            <i class="fa fa fa-xmark"></i>
                        </button>
                    </div>
                </section>
            </form>
        }

        <ng-container *ngTemplateOutlet="benchmarkItems" />
    </div>

    <div class="modal-footer d-flex justify-content-center align-items-center gap-3">
        <button type="button" class="btn btn-secondary px-2" (click)="closeModal(false)">
            {{ "common.form.button.close" | translate }}
        </button>

        @if (!isReadonly()) {
            <button type="submit" class="btn btn-primary px-3" (click)="saveBenchmark()">
                {{ "common.form.button.submit" | translate }}
            </button>
        }
    </div>
</ng-template>

<!-- NOTE: displays list of benchmark years -->
<ng-template #benchmarkItems>
    <div class="accordion mt-3 mb-2" id="kpiBenchMarkLovs" ngbAccordion [closeOthers]="true" [destroyOnHide]="true">
        @for (year of uniqueYears; let i = $index; track i) {
            @let yearValues = yearsAsAccordion[year];

            <div class="accordion-item" ngbAccordionItem>
                <h2 class="accordion-header" [id]="'heading' + i" ngbAccordionHeader>
                    <button
                        ngbAccordionButton
                        type="button"
                        class="accordion-button"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                        [attr.data-bs-target]="'#collapse' + i"
                    >
                        <b>{{ year }}</b>
                    </button>
                </h2>

                <div ngbAccordionCollapse class="accordion-collapse bg-white" [id]="'collapse' + i" [attr.aria-labelledby]="'heading' + i">
                    <div class="accordion-body">
                        <div class="d-flex" data-row-header>
                            <div class="text-center text-white fw-bold" [class.col-4]="isReadonly()" [class.col-3]="!isReadonly()">
                                {{ "nkpi.benchYear" | translate }}
                            </div>

                            <div class="text-center text-white fw-bold" [class.col-4]="isReadonly()" [class.col-3]="!isReadonly()">
                                {{ "nkpi.country" | translate }}
                            </div>

                            <div class="text-center text-white fw-bold" [class.col-4]="isReadonly()" [class.col-3]="!isReadonly()">
                                {{ "common.form.label.value" | translate }}
                            </div>

                            @if (!isReadonly()) {
                                <div class="col-3 text-center text-white fw-bold">{{ "common.tables.actions" | translate }}</div>
                            }
                        </div>

                        @for (data of yearValues; track data.$index) {
                            @let activeBenchmarkYear = selectedActiveBenchmarkYear();

                            <div class="d-flex" data-row-body [class.is-selected]="activeBenchmarkYear && activeBenchmarkYear.$index === data.$index">
                                <div
                                    class="d-flex justify-content-center align-items-center text-center"
                                    [class.col-4]="isReadonly()"
                                    [class.col-3]="!isReadonly()"
                                >
                                    {{ data.year }}
                                </div>

                                <div
                                    class="d-flex justify-content-center align-items-center text-center"
                                    [class.col-4]="isReadonly()"
                                    [class.col-3]="!isReadonly()"
                                >
                                    {{ data.countryName }}
                                </div>

                                <div
                                    class="d-flex justify-content-center align-items-center text-center"
                                    [class.col-4]="isReadonly()"
                                    [class.col-3]="!isReadonly()"
                                >
                                    {{ data.value }}
                                </div>

                                @if (!isReadonly()) {
                                    <div class="col-3 d-flex justify-content-center align-items-center text-center">
                                        <button class="btn btn-xs p-1 text-secondary mx-1" (click)="editBenchmarkYear(data)">
                                            <i class="fa fa-pen"></i>
                                        </button>

                                        <button class="btn btn-xs p-1 text-secondary mx-1" (click)="removeBenchmarkYear(data)">
                                            <i class="fa fa-trash"></i>
                                        </button>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        }
    </div>
</ng-template>

<ng-template #uploadModal>
    <div class="modal-header">
        <h4 class="modal-title w-100 text-center">{{ "nkpi.attachment" | translate }}</h4>
        <button type="button" class="btn-close" aria-label="Close button" aria-describedby="modal-title" (click)="closeUploadModal(false)"></button>
    </div>

    <div class="modal-body bg-white px-3 py-1">
        <form
            id="kpi-benchmarking-upload-form"
            autocomplete="on"
            spellcheck="true"
            [formGroup]="uploadModel"
            [lang]="languageService.current()"
            [dir]="languageService.direction()"
            (ngSubmit)="saveBenchmarkFile()"
        >
            <section class="d-flex row">
                <div class="col-6">
                    <adaa-form-input
                        formControlName="nameAE"
                        controlName="nameAE"
                        classes="form-control shadow-none"
                        [invalid]="uploadModel.get('nameAE')?.touched! && uploadModel.get('nameAE')?.invalid!"
                        [label]="'common.form.label.nameAE' | translate"
                        [required]="true"
                        [adaaInputDisabled]="isReadonly() || uploadModalIsReadonly()"
                    />
                </div>

                <div class="col-6">
                    <adaa-form-input
                        formControlName="nameEN"
                        controlName="nameEN"
                        classes="form-control shadow-none"
                        [invalid]="uploadModel.get('nameEN')?.touched! && uploadModel.get('nameEN')?.invalid!"
                        [label]="'common.form.label.nameEN' | translate"
                        [required]="true"
                        [adaaInputDisabled]="isReadonly() || uploadModalIsReadonly()"
                    />
                </div>
            </section>

            <section class="row">
                <div class="col-12">
                    <adaa-form-input-file
                        [onDeleteRemoveFile]="false"
                        [allowedFileSize]="26214400"
                        [allowedFiles]="allowedFiles"
                        [file]="benchmarkFile"
                        [isDisabled]="uploadModalIsReadonly() || isReadonly()"
                        (inputChanges)="handleFileUpload($event)"
                    />
                </div>
            </section>
        </form>
    </div>

    <div class="modal-footer d-flex justify-content-center align-items-center gap-3">
        <button type="button" class="btn btn-secondary px-2" form="kpi-benchmarking-upload-form" (click)="closeUploadModal(false)">
            {{ "common.form.button.close" | translate }}
        </button>

        @if (!isReadonly()) {
            <button type="submit" class="btn btn-primary px-3" form="kpi-benchmarking-upload-form">
                {{ "common.form.button.submit" | translate }}
            </button>
        }
    </div>
</ng-template>
