import { Component, computed, effect, inject, input, OnInit, output } from "@angular/core";
import { ControlContainer, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { filter, tap } from "rxjs";

import { AdaaHelper } from "../../../../../../../core/utils";
import { FormDropdownComponent } from "../../../../../../../shared/components";
import { EntityModelType, TpProjectDetails, ValueText } from "../../../../../../../shared/models";

@Component({
  selector: "adaa-tp-selection-field",
  imports: [FormDropdownComponent, ReactiveFormsModule, TranslateModule],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true }),
    },
  ],
  template: `
    <adaa-form-dropdown
      [controlName]="fieldName"
      [formControlName]="fieldName"
      [clearable]="true"
      [searchable]="true"
      [label]="'mokpi.nationalProjectId' | translate"
      [options]="listOptions()"
      [invalid]="model.get(fieldName)?.touched! && model.get(fieldName)?.invalid!"
      [required]="isRequired()"
    />
  `,
})
export class TpSelectionFieldComponent implements OnInit {
  readonly form = inject(ControlContainer);

  list = input<TpProjectDetails[]>([]);
  entities = input<EntityModelType[]>([]);
  isDisabled = input<boolean>(false);
  isRequired = input.required<boolean>();

  listOptions = computed<ValueText[]>(() =>
    AdaaHelper.setDropdownArray(this.list(), "id", AdaaHelper.getFieldLanguage("name"))
  );

  onChange = output<number>();

  readonly fieldName = "nationalProjectId";

  readonly #untilDestroy = AdaaHelper.untilDestroyed();

  public get model() {
    return this.form.control as FormGroup;
  }

  constructor() {
    effect((onCleanup) => {
      const disable = this.isDisabled();
      if (disable) {
        this.model.get(this.fieldName)?.disable();
      } else {
        this.model.get(this.fieldName)?.enable();
      }

      onCleanup(() => {
        this.model.controls[this.fieldName]?.enable({ onlySelf: true, emitEvent: true });
      });
    });
  }

  public ngOnInit() {
    this._outputChanges();
  }

  private _outputChanges() {
    this.model.controls[this.fieldName]?.valueChanges
      ?.pipe(
        this.#untilDestroy(),
        filter((value) => AdaaHelper.isDefined(value)),
        tap({
          next: (value) => {
            const tp = this.list().find(({ id }) => id === value);
            this._updateAccountableEntity(tp?.entityId);
          },
        })
      )
      .subscribe({
        next: (value: number) => {
          this.onChange.emit(value);
        },
      });
  }

  private _updateAccountableEntity(entityId: number | undefined) {
    const entity = this.entities().find(({ id }) => id === entityId);
    const entityName = AdaaHelper.getItemValueByToken(entity, "name");
    this.model.controls["accountableEntityId"]?.setValue(entityName);
  }
}
