import { Component, computed, inject, OnInit, signal } from "@angular/core";
import { Router } from "@angular/router";

import { AdaaHelper } from "../../../core/utils";
import { DataTableComponent } from "../../../shared/components";
import { Constants } from "../../../shared/constants/constants";
import { TableSelectedRow } from "../../../shared/models";
import { SystemLayoutService } from "../../../shared/services";

@Component({
  selector: "adaa-dimensions-synchronization-list",
  imports: [DataTableComponent],
  templateUrl: "./dimensions-synchronization-list.component.html",
  styleUrl: "./dimensions-synchronization-list.component.scss",
})
export class DimensionsSynchronizationListComponent implements OnInit {
  private _systemLayoutService = inject(SystemLayoutService);
  private _router = inject(Router);
  Constants = Constants;

  syncId = signal<number>(0);
  logType = signal<"structure" | "value" | undefined>(undefined);
  private readonly _untilDestroy = AdaaHelper.untilDestroyed();

  data = computed(() => {
    return {
      syncDetailAPI:
        this.logType() === "structure" ? "getDimensionsStructureSyncLogsDetails" : "getDimensionsValueSyncLogsDetails",
      param: `${this.syncId()}`,
    };
  });

  selectedConfList = computed(() =>
    this.logType() === "structure"
      ? Constants.MAIN_TABLE_LIST_CONF_KEY.DIMENSIONS_SYNC_LOGS_DETAILS
      : Constants.MAIN_TABLE_LIST_CONF_KEY.DIMENSIONS_SYNC_LOGS_VALUE_DETAILS
  );

  public ngOnInit() {
    this._systemLayoutService.hasActiveEntityChanged$.pipe(this._untilDestroy()).subscribe({
      next: () => {
        if (!AdaaHelper.isPMOEntity()) this._router.navigateByUrl("/console");
      },
    });
  }

  public getRowData(event: TableSelectedRow["item"]) {
    this.logType.set(event.logTypeEN.toLowerCase());
    this.syncId.set(event.id);
  }
}
