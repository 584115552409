import { Route } from "@angular/router";

import { checkIfLoggedInFn } from "../../core/guards";
import { ImportExportObjectType } from "../../shared/constants/enums";
import { viewImportExportPlanPagesGuards } from "./_guards";

export const IMPORT_EXPORT_PLAN_ROUTES: Route[] = [
  {
    path: "import-export-plan",
    canActivate: [checkIfLoggedInFn, ...viewImportExportPlanPagesGuards],
    loadComponent: () =>
      import("../../shared/components/import-export/import-export.component").then((e) => e.ImportExportComponent),
    data: {
      breadcrumb: "breadcrumbs.plan_import_export",
      title: "breadcrumbs.plan_import_export",
      type: ImportExportObjectType.PLAN,
    },
  },
];
