import { Route } from "@angular/router";

import { NotArchivedPlan } from "../../../core/guards";
import { viewKpiActualsPageGuards } from "./_guards";

export const KPI_ACTUALS_ROUTES: Route[] = [
  {
    path: "kpi",
    canActivate: [...viewKpiActualsPageGuards],
    children: [
      {
        path: "",
        data: {
          title: "data_entry.kpi_actuals",
          breadcrumb: "data_entry.kpi_actuals",
        },
        loadComponent: () =>
          import("./kpi-actuals-list/kpi-actuals-list.component").then((e) => e.KpiActualsListComponent),
      },
      {
        path: "edit/:id",
        canActivate: [NotArchivedPlan()],
        data: {
          title: "data_entry.edit_title",
          breadcrumb: "data_entry.edit_title",
        },
        loadComponent: () =>
          import("./kpi-actuals-editor/kpi-actuals-editor.component").then((e) => e.KpiActualsEditorComponent),
      },
    ],
  },
];
