<adaa-data-table
    #scopesTable
    titleHeader="government_scores.scopes.title"
    apiPrefix="govScoresScopes"
    apiName="getAllV2Small"
    childKey="scopes"
    [configKey]="constants.MAIN_TABLE_LIST_CONF_KEY.GOVERNMENT_SCOPES_CONF_LIST"
    [itemTypeId]="constants.CONSTANT_PERMISSIONS.GOVERNMENT_SCORES"
    [enableEditAction]="false"
    [enableDeleteAction]="false"
    [customActionButtons]="customButtons"
    [tableWithChildren]="true"
    [enableChildActionsCell]="true"
    [enableEditChildAction]="true"
    [enableViewChildAction]="true"
    [enableDeleteChildAction]="true"
    (addNewClicked)="addNewClicked()"
    (actionClicked)="actionClicked($event)"
    (childClicked)="childClicked($event)"
/>

<adaa-float-action />
