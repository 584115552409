import { inject, Injectable } from "@angular/core";

import type { MainResponseModel } from "../models";
import { NotificationCommunication, SearchFields } from "../models/notification-center.model";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class NotificationCenterApiService {
  private _mainService = inject(MainService);

  public getNotifications(
    init: boolean = false,
    loader: boolean = false,
    data?: { searchFields: SearchFields } | null,
    action?: string,
    read?: string,
    sort?: string,
    offset?: boolean
  ) {
    const defaultUrl = `commcenter/getByFiltersByUser?limit=30&offset=0&type=A&isRead=N`;
    let url = `commcenter/getByFiltersByUser?limit=30`;

    url += `&offset=${offset ? 30 : 0}`;
    if (action && action.length > 0) url += `&type=${action}`;
    if (read && read.length > 0) url += `&isRead=${read}`;
    if (sort && sort.length > 0) url += `&sortBy=${sort}`;

    return this._mainService.sendRequest<MainResponseModel<NotificationCommunication[]>>({
      method: "POST",
      url: init ? defaultUrl : url,
      data: data,
      loader: loader,
    });
  }

  public markAsRead(id: number) {
    const url = `commcenter/read/${id}`;

    return this._mainService.sendRequest<MainResponseModel<NotificationCommunication>>({
      method: "GET",
      url,
    });
  }
}
