import { Component, inject, input, type OnDestroy, type OnInit, signal, ViewChild, viewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { Subscription } from "rxjs";

import { AdaaHelper } from "../../core/utils";
import { DataTableComponent } from "../../shared/components";
import { ReportGenerationComponent } from "../../shared/components/modals/report-generation/report-generation.component";
import { Constants } from "../../shared/constants/constants";
import { CustomButton } from "../../shared/models";
import { LanguageService, SystemLayoutService } from "../../shared/services";

@Component({
  selector: "adaa-reports",
  standalone: true,
  imports: [TranslateModule, DataTableComponent, ReportGenerationComponent],
  templateUrl: "./reports.component.html",
  styleUrl: "./reports.component.scss",
})
export class ReportsComponent implements OnInit, OnDestroy {
  private readonly _systemLayoutService = inject(SystemLayoutService);
  private readonly _languageService = inject(LanguageService);
  private readonly _modalService = inject(NgbModal);

  @ViewChild("reportGeneration") reportGeneration: ReportGenerationComponent;

  tableConfList = input.required<string>();
  adaaDataTable = viewChild.required<DataTableComponent>("adaaDataTable");
  customButtons: CustomButton[] = [
    {
      iconName: "adaa-icon-settings",
      eventName: "settings",
      iconTitle: "tooltips.settings",
    },
  ];
  Constants = Constants;

  private _subscription = new Subscription();
  showLoader = signal<boolean>(false);

  public ngOnInit() {
    this._subscription.add(
      this._systemLayoutService.hasCycleChanged$.subscribe({
        next: () => this.adaaDataTable().loadTableData(),
      })
    );
    this._subscription.add(
      this._systemLayoutService.hasActiveEntityChanged$.subscribe({
        next: () => {
          this.adaaDataTable().loadTableData();
          this.reportGeneration?._getOrgUnits(Number(AdaaHelper.entity?.id));
        },
      })
    );
  }

  public ngOnDestroy() {
    this._subscription.unsubscribe();
  }
}
