<adaa-data-table
    #milestonesTable
    titleHeader="annual_meetings.milestones.title"
    apiName="getAllCamelCase"
    apiPrefix="annual-meeting-project-milestone"
    [configKey]="constants.MAIN_TABLE_LIST_CONF_KEY.AGM_PROJECT_MILESTONES_CONF_LIST"
    [itemTypeId]="constants.CONSTANT_PERMISSIONS.ANNUAL_MEETINGS_PROJECTS_MILESTONES"
    [enableConnectionsAction]="true"
    [enableDeleteAction]="false"
    [enableAdd]="false"
    [disableEditRules]="disableEditRules"
    (actionClicked)="actionClicked($event)"
></adaa-data-table>

<adaa-float-action />
