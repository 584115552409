import { Route } from "@angular/router";

import { checkIfLoggedInFn, pmoEntity } from "../../core/guards";
import { SynchronizationScheduleComponent } from "../../shared/components/synchronization-schedule/synchronization-schedule.component";
import { viewDimensionsLogsPageGuards, viewDimensionsPagesGuards, viewDimensionsSyncPagesGuards } from "./_guards";
import { DimensionsSubmenuComponent } from "./dimensions-submenu/dimensions-submenu.component";
import { DimensionsSynchronizationListComponent } from "./dimensions-synchronization-list/dimensions-synchronization-list.component";

export const DIMENSIONS_ROUTES: Route[] = [
  {
    path: "dimensions",
    canActivate: [checkIfLoggedInFn, pmoEntity, ...viewDimensionsPagesGuards],
    children: [
      {
        path: "",
        component: DimensionsSubmenuComponent,
        data: {
          breadcrumb: "breadcrumbs.dimensions_module.list",
          title: "breadcrumbs.dimensions_module.list",
        },
      },
      {
        path: "logs",
        component: DimensionsSynchronizationListComponent,
        canActivate: [...viewDimensionsLogsPageGuards],
        data: {
          breadcrumb: "dimension.sync_logs",
          title: "dimension.sync_logs",
        },
      },
      {
        path: "schedule",
        component: SynchronizationScheduleComponent,
        canActivate: [...viewDimensionsSyncPagesGuards],
        data: {
          breadcrumb: "dimension.sync_schedule",
          title: "dimension.sync_schedule",
          isDimensions: true,
        },
      },
    ],
  },
];
