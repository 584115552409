import { Route } from "@angular/router";

import { checkIfLoggedInFn } from "../../core/guards";
import { PageMode } from "../../shared/constants/enums";
import { viewSupportCenterPageGuards } from "./_guards";
import { SupportCenterEditorComponent } from "./support-center-editor/support-center-editor.component";
import { SupportCenterListComponent } from "./support-center-list/support-center-list.component";

export const SUPPORT_CENTER_ROUTES: Route[] = [
  {
    path: "console/helpdesk",
    canActivate: [checkIfLoggedInFn, ...viewSupportCenterPageGuards],
    children: [
      {
        path: "",
        component: SupportCenterListComponent,
      },
      {
        path: "create",
        component: SupportCenterEditorComponent,
        data: {
          pageMode: PageMode.create,
          breadcrumb: "breadcrumbs.help_desk_module.new",
        },
      },
      {
        path: ":id",
        component: SupportCenterEditorComponent,
        data: {
          pageMode: PageMode.view,
          breadcrumb: "breadcrumbs.help_desk_module.view",
        },
      },
      {
        path: "edit/:id",
        component: SupportCenterEditorComponent,
        data: {
          pageMode: PageMode.edit,
          breadcrumb: "breadcrumbs.help_desk_module.edit",
        },
      },
    ],
    data: {
      title: "breadcrumbs.help_desk_module.list",
      breadcrumb: "breadcrumbs.help_desk_module.list",
    },
  },
];
