import type { Route } from "@angular/router";

import { checkIfLoggedInFn } from "../../core/guards";
import { Constants } from "../../shared/constants/constants";
import { wfMonitoringPageGuards, wfSubmenuPageGuards } from "./_guards";
import { WorkflowSyncSchedulerComponent } from "./workflow-sync-scheduler/workflow-sync-scheduler.component";
import { WorkflowsSubmenuComponent } from "./workflows-submenu/workflows-submenu.component";

export const WORKFLOWS_ROUTES: Route[] = [
  {
    path: "workflow",
    canActivate: [checkIfLoggedInFn],
    children: [
      {
        path: "",
        component: WorkflowsSubmenuComponent,
        canActivate: [checkIfLoggedInFn, ...wfSubmenuPageGuards],
        data: {
          title: "workflow.workflowconfiguration",
          breadcrumb: "workflow.workflowconfiguration",
        },
      },
      {
        path: "workflow-decision",
        component: WorkflowSyncSchedulerComponent,
        canActivate: [checkIfLoggedInFn],
        data: {
          title: "workflow_rejection_scheduler.title",
          breadcrumb: "workflow_rejection_scheduler.title",
        },
      },
      {
        path: "workflow-monitoring",
        canActivate: [checkIfLoggedInFn, ...wfMonitoringPageGuards],
        data: {
          title: "breadcrumbs.wf_monitoring_module.list",
          breadcrumb: "breadcrumbs.wf_monitoring_module.list",
          tableConfList: Constants.MAIN_TABLE_LIST_CONF_KEY.WORKFLOW_MONITORING,
        },
        loadComponent: () =>
          import("./workflow-monitoring/workflow-monitoring.component").then((m) => m.WorkflowMonitoringComponent),
      },
      {
        path: "workflow-configuration",
        canActivate: [checkIfLoggedInFn],
        loadChildren: () => import("./workflow-configuration/workflow-configuration.routes"),
      },
      {
        path: "workflow-triggers",
        canActivate: [checkIfLoggedInFn],
        loadChildren: () => import("./workflow-triggers/workflow-trigger.routes"),
      },
    ],
  },
];
