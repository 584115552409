<ng-template #content let-modal>
    <div class="modal-header">
        <h5 class="text-center fw-bold w-100">{{ "government_scores.pillars.area_of_focus.title" | translate }}</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body overflow-x-hidden p-3">
        <form class="col-12" [formGroup]="areaOfFocusForm">
            <div class="bg-white shadow-md rounded p-2 mb-3">
                <div class="row">
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <adaa-form-input
                            [label]="'common.form.label.nameAE' | translate"
                            formControlName="areaNameAE"
                            controlName="areaNameAE"
                            [invalid]="checkInvalid('areaNameAE')"
                            [required]="true"
                        ></adaa-form-input>
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <adaa-form-input
                            [label]="'common.form.label.nameEN' | translate"
                            formControlName="areaNameEN"
                            controlName="areaNameEN"
                            [invalid]="checkInvalid('areaNameEN')"
                            [required]="true"
                        ></adaa-form-input>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <adaa-form-input
                            [label]="'common.form.label.descriptionAE' | translate"
                            formControlName="areaDscAE"
                            controlName="areaDscAE"
                            [invalid]="checkInvalid('areaDscAE')"
                            [isTextArea]="true"
                        ></adaa-form-input>
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <adaa-form-input
                            [label]="'common.form.label.descriptionEN' | translate"
                            formControlName="areaDscEN"
                            controlName="areaDscEN"
                            [invalid]="checkInvalid('areaDscEN')"
                            [isTextArea]="true"
                        ></adaa-form-input>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal=footer d-flex justify-content-center mt-3">
        <div class="my-2">
            <adaa-form-action-buttons
                [showSaveButton]="pageMode() !== PageMode.view"
                [saveButtonTitle]="'common.form.button.save' | translate"
                [cancelButtonTitle]="
                    pageMode() === PageMode.view ? ('common.form.button.close' | translate) : ('common.form.button.cancel' | translate)
                "
                (cancelClicked)="modal.close()"
                (saveClicked)="submit()"
            ></adaa-form-action-buttons>
        </div>
    </div>
</ng-template>
