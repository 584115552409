import { inject, Injectable, signal } from "@angular/core";

import { Constants } from "../constants/constants";
import { ValidatorModelType } from "../models";
import { ValidatorApiService } from "./validator-api.service";

@Injectable({
  providedIn: "root",
})
export class TPSectorEditorService {
  private _validatorApiService = inject(ValidatorApiService);

  formValidation = signal<ValidatorModelType | undefined>(undefined);

  public formValidator() {
    this._validatorApiService.searchByKey(Constants.VALIDATORS_CONF_KEY.VALIDATION_ENTITY).subscribe({
      next: (response) => {
        if (response.inError) return;
        this.formValidation.set(response.responseData);
      },
    });
  }
}
