@if (customButtons.length > 0) {
    <adaa-data-table
        #usersTable
        [titleHeader]="'breadcrumbs.users'"
        [configKey]="'user_conf_list'"
        [apiName]="'getUsersList'"
        [customActionButtons]="customButtons"
        [disableDeleteRules]="disableDeleteRules"
        [itemTypeId]="constants.CONSTANT_PERMISSIONS.USERS"
        (actionClicked)="actionClicked($event)"
        (addNewClicked)="addNewClicked()"
    ></adaa-data-table>
}

<adaa-float-action />
