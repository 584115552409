import { Component, computed, inject, input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

import { TranslateTokenPipe } from "../../../../core/pipes";
import { ProgressIndicatorComponent } from "../../../../shared/components";
import { Constants } from "../../../../shared/constants/constants";
import type { NationalAgendaKpiModelType } from "../../../../shared/models";
import { LanguageService } from "../../../../shared/services";

@Component({
  selector: "adaa-wtuae-ntkpi-card",
  standalone: true,
  templateUrl: "./wtuae-ntkpi-card.component.html",
  styleUrl: "./wtuae-ntkpi-card.component.scss",
  imports: [TranslateTokenPipe, ProgressIndicatorComponent, TranslateModule],
})
export class WtuaeNtkpiCardComponent {
  private readonly _languageService = inject(LanguageService);

  data = input.required<NationalAgendaKpiModelType>();

  isRTL = computed(() => this._languageService.direction() === "rtl");
  borderBottomColor = computed(
    () => Constants.COLOR_CODES[this.data().annualVisionScoreColor ?? Constants.CONSTANT_COLORS.EVALUATION_GRAY]
  );
}
