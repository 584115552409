<ng-template #content let-modal>
    <div class="modal-header">
        <h5 class="text-center fw-bold w-100">{{ reportName() }}</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
    </div>

    <form [formGroup]="reportGenerationForm" class="modal-body overflow-x-hidden row px-3 pt-2 pb-5">
        @if (report().usePreGeneratedReport) {
            <div class="col-12 label d-flex my-3">
                <span class="tag"></span>
                <label [dir]="languageService.direction()" class="text-start fw-bold fs-6 p-2 ms-3">
                    {{ "report.pre_generated_report_alert" | translate }}
                </label>
            </div>
        }

        <div class="col-12 mb-3">
            <adaa-form-dropdown
                [label]="'report.output_format' | translate"
                [required]="true"
                [controlName]="'output'"
                [formControlName]="'output'"
                [options]="exportOptions()"
                appendTo=".modal-content"
            ></adaa-form-dropdown>
        </div>

        <div class="col-12 mb-3">
            <adaa-form-dropdown
                [label]="'report.language' | translate"
                [required]="true"
                [controlName]="'language'"
                [formControlName]="'language'"
                [options]="languageOptions()"
            ></adaa-form-dropdown>
        </div>

        @for (item of fieldList(); track item) {
            @if (item.visible) {
                @switch (item.inputType) {
                    @case ("date") {
                        <div class="col-6 mb-3">
                            <adaa-form-input-date
                                [label]="item.translateStringKey | translate"
                                [required]="item.mandatory"
                                [controlName]="item.outputKeyName"
                                [formControlName]="item.outputKeyName"
                            ></adaa-form-input-date>
                        </div>
                    }
                    @case ("select") {
                        <div class="col-6 mb-3">
                            <adaa-form-dropdown
                                appendTo=".modal-content"
                                [label]="item.translateStringKey | translate"
                                [options]="getDropdownListOptions(item.listScope)"
                                [required]="item.mandatory"
                                [controlName]="item.outputKeyName"
                                [formControlName]="item.outputKeyName"
                                (inputChanges)="
                                    item.filterDoubleDataTo || item.filterDataTo
                                        ? filterTableData($event, item.listScope)
                                        : applyChanges(item.listScope, $event)
                                "
                            ></adaa-form-dropdown>
                        </div>
                    }
                    @case ("treeview") {
                        <div class="col-6 mb-3">
                            <adaa-form-dropdown-tree
                                [label]="item.translateStringKey | translate"
                                [options]="orgUnits()"
                                formControlName="orgUnitId"
                                controlName="orgUnitId"
                                (inputChanges)="applyChanges(item.listScope, $event)"
                            ></adaa-form-dropdown-tree>
                        </div>
                    }
                    @case ("list") {
                        <div class="col-12">
                            <p class="text-center fw-bold fs-6">
                                {{ item.translateStringKey | translate }}
                                @if (item.mandatory) {
                                    <span class="form-required text-danger fw-bold">*</span>
                                }
                            </p>
                            @if (item.parameter === "Kpi") {
                                <p class="text-center fst-italic fw-light fs-6">
                                    {{ "report.info_message_pmo" | translate }}
                                </p>
                            }
                            @if (item.listScope === "entity" || item.hasRef || item.parameter === "NationalProjects") {
                                <div class="col-12 label d-flex my-2">
                                    <span class="tag"></span>
                                    <label [dir]="languageService.direction()" class="text-start fw-bold fs-6 p-2 ms-3">
                                        {{ "report.messages.more_than_10_user_msg" | translate }}
                                    </label>
                                </div>
                            }
                            <adaa-data-table
                                [isLocalData]="true"
                                [localData]="tableDataMap[item._index]()"
                                [configKey]="item.listConfList"
                                [enableAdd]="false"
                                [enableEditAction]="false"
                                [enableActionsCell]="false"
                                [enableSettings]="false"
                                (inputListChanged)="setTableSelection($event, item.parameter)"
                            ></adaa-data-table>
                        </div>
                    }
                }
            }
        }
    </form>

    <div class="modal-footer mb-3">
        <div class="d-flex w-100 justify-content-center gap-4">
            <button type="submit" class="btn btn-primary" (click)="modal.dismiss()">
                <span>{{ "common.form.button.cancel" | translate }}</span>
            </button>

            <button type="submit" class="btn btn-primary" (click)="prepareReport()">
                <span>{{ "common.form.label.generate" | translate }}</span>
            </button>
        </div>
    </div>
</ng-template>
