import { Component, effect, inject, Injector, input, OnInit, output } from "@angular/core";
import { ControlContainer, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { TranslatePipe } from "@ngx-translate/core";
import { filter } from "rxjs";

import { AdaaHelper } from "../../../../../../../core/utils";
import { FormDropdownComponent } from "../../../../../../../shared/components";
import { FormControlDisabledDirective } from "../../../../../../../shared/directives";
import { ValueText } from "../../../../../../../shared/models";

@Component({
  selector: "adaa-initiatives-field",
  standalone: true,
  imports: [FormControlDisabledDirective, FormDropdownComponent, ReactiveFormsModule, TranslatePipe],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true }),
    },
  ],
  template: `
    <section class="d-flex row mb-2">
      <div class="d-block col-12">
        <adaa-form-dropdown
          [controlName]="fieldName"
          [formControlName]="fieldName"
          [clearable]="true"
          [searchable]="true"
          [label]="label() | translate"
          [options]="options()"
          [invalid]="model.get(fieldName)?.touched! && model.get(fieldName)?.invalid!"
          [required]="isRequired()"
          [adaaInputDisabled]="isDisabled()"
        />
      </div>
    </section>
  `,
})
export class InitiativesFieldComponent implements OnInit {
  readonly form = inject(ControlContainer);

  private readonly _injector = inject(Injector);

  isRequired = input.required<boolean>();
  isDisabled = input.required<boolean>();
  kpiType = input.required<number>();
  label = input.required<string>();
  options = input.required<ValueText[]>();

  onChange = output<number>();

  readonly fieldName = "initiativeId";

  readonly #untilDestroy = AdaaHelper.untilDestroyed();
  readonly #disableInputEffect = () => {
    effect(
      (onCleanup) => {
        const disable = this.isDisabled();
        if (disable) {
          this.model.controls[this.fieldName]?.disable({ onlySelf: true, emitEvent: true });
        } else {
          this.model.controls[this.fieldName]?.enable({ onlySelf: true, emitEvent: true });
        }

        onCleanup(() => {
          this.model.controls[this.fieldName]?.enable({ onlySelf: true, emitEvent: true });
        });
      },
      { injector: this._injector }
    );
  };

  public get model() {
    return this.form.control as FormGroup;
  }

  constructor() {
    this.#disableInputEffect();
  }

  public ngOnInit() {
    this._outputChanges();
  }

  private _outputChanges() {
    this.model.controls[this.fieldName]?.valueChanges
      ?.pipe(
        this.#untilDestroy(),
        filter((value) => AdaaHelper.isDefined(value))
      )
      .subscribe({
        next: () => {
          const value = this.model.controls[this.fieldName]?.getRawValue();
          this.onChange.emit(value);
        },
      });
  }
}
