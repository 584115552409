<adaa-data-table
    [titleHeader]="'breadcrumbs.user_related_objects'"
    [configKey]="'user_related_objects_list'"
    [apiName]="'getAllPermissionsByUserId'"
    [paramsString]="userId"
    [enableAdd]="false"
    [enableDeleteAction]="false"
    [enableEditAction]="false"
    [hideViewRules]="hideViewRules"
    (actionClicked)="actionClicked($event)"
></adaa-data-table>
