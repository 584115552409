import { hasPermissionGuard } from "../../core/guards";
import { Constants } from "../../shared/constants/constants";
import { PermissionAction } from "../../shared/constants/enums";

export const viewImportExportPlanPagesGuards = [
  hasPermissionGuard({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.execute,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.EXPORT_DATA,
        },
      ],
    },
    redirectTo: "/403",
  }),
];
