<div class="row">
    <div class="col-12">
        <h4 class="fw-bold">{{ "user.create.title" | translate }}</h4>
    </div>
    <form class="col-12" [formGroup]="userForm">
        <div class="bg-white shadow-md rounded p-2 mb-3">
            <div class="row">
                <div class="col-12">
                    <adaa-form-input-file
                        label="breadcrumbs.user.profile_picture"
                        [isDisabled]="isViewMode"
                        [onDeleteRemoveFile]="false"
                        [allowedFileSize]="100024"
                        [allowedFilesMessage]="profileFileMessage"
                        [allowedFiles]="allowedFiles"
                        [file]="profileFile"
                        (inputChanges)="onFilechange($event)"
                    />
                </div>
            </div>
        </div>

        <div class="bg-white shadow-md rounded p-2 mb-3">
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.username' | translate"
                        [setValidator]="userEditorService.formValidation()!"
                        [invalid]="submitted && userForm.get('username')?.errors?.['required']"
                        formControlName="username"
                        controlName="username"
                    />
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.email' | translate"
                        [setValidator]="userEditorService.formValidation()!"
                        [inputType]="'email'"
                        [invalid]="(submitted && userForm.get('email')?.errors?.['required']) || userForm.get('email')?.errors?.['pattern']"
                        formControlName="email"
                        controlName="email"
                        (inputChanges)="userForm.get('username')!.setValue($event)"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.nameAE' | translate"
                        [setValidator]="userEditorService.formValidation()!"
                        [invalid]="submitted && userForm.get('nameAE')?.errors?.['required']"
                        formControlName="nameAE"
                        controlName="nameAE"
                    />
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.nameEN' | translate"
                        [setValidator]="userEditorService.formValidation()!"
                        [invalid]="submitted && userForm.get('nameEN')?.errors?.['required']"
                        formControlName="nameEN"
                        controlName="nameEN"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.jobTitleAE' | translate"
                        [setValidator]="userEditorService.formValidation()!"
                        [invalid]="submitted && userForm.get('jobTitleAE')?.errors?.['required']"
                        formControlName="jobTitleAE"
                        controlName="jobTitleAE"
                    />
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.jobTitleEN' | translate"
                        [setValidator]="userEditorService.formValidation()!"
                        [invalid]="submitted && userForm.get('jobTitleEN')?.errors?.['required']"
                        formControlName="jobTitleEN"
                        controlName="jobTitleEN"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.directPhone' | translate"
                        [setValidator]="userEditorService.formValidation()!"
                        [invalid]="submitted && userForm.get('directPhone')?.errors?.['required']"
                        [inputType]="'tel'"
                        formControlName="directPhone"
                        controlName="directPhone"
                    />
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.mobilePhone' | translate"
                        [setValidator]="userEditorService.formValidation()!"
                        [invalid]="submitted && userForm.get('mobilePhone')?.errors?.['required']"
                        [inputType]="'tel'"
                        formControlName="mobilePhone"
                        controlName="mobilePhone"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <adaa-form-dropdown
                        controlName="administrativeTitle"
                        formControlName="administrativeTitle"
                        [label]="'user.create.administrative_title' | translate"
                        [options]="userEditorService.administrativeTitles"
                        [setValidator]="userEditorService.formValidation()!"
                        [invalid]="submitted && userForm.get('administrativeTitle')?.errors?.['required']"
                        [clearable]="true"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <adaa-form-dropdown
                        controlName="entityId"
                        formControlName="entityId"
                        [searchable]="true"
                        [label]="'common.form.label.entity' | translate"
                        [options]="userEditorService.entities()"
                        [setValidator]="userEditorService.formValidation()!"
                        [invalid]="submitted && userForm.get('entityId')?.errors?.['required']"
                        (inputChanges)="onEntityChange($event)"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <adaa-form-dropdown-tree
                        controlName="orgUnitIds"
                        formControlName="orgUnitIds"
                        [label]="'common.form.label.position' | translate"
                        [options]="userEditorService.orgUnits()"
                        [setValidator]="userEditorService.formValidation()!"
                        [invalid]="submitted && userForm.get('orgUnitIds')?.errors?.['required']"
                        [isMultiple]="true"
                    />
                </div>
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-md-12">
                <div class="bg-white shadow-md rounded p-2">
                    <adaa-data-table
                        [titleHeader]="'user.create.roles'"
                        [useSmHeader]="true"
                        [required]="true"
                        [configKey]="'user_form_user_roles_conf_list'"
                        [isLocalData]="true"
                        [localData]="grants"
                        [enableViewAction]="false"
                        [enableEditAction]="false"
                        [enableAdd]="false"
                        [enableAdd]="!isViewMode"
                        [enableDeleteAction]="!isViewMode"
                        (addNewClicked)="addUserGrant()"
                        (actionClicked)="removeUserGrant($event)"
                    />
                </div>
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-md-12">
                <div class="bg-white shadow-md rounded p-2">
                    <adaa-data-table
                        [titleHeader]="'user.create.groups'"
                        [useSmHeader]="true"
                        [configKey]="'user_form_user_groups_conf_list'"
                        [isLocalData]="true"
                        [localData]="groups"
                        [enableViewAction]="false"
                        [enableEditAction]="false"
                        [enableAdd]="false"
                        [enableAdd]="!isViewMode"
                        [enableDeleteAction]="!isViewMode"
                        (addNewClicked)="addUserGroups()"
                        (actionClicked)="removeUserGroup($event)"
                    />
                </div>
            </div>
        </div>

        @if (adaaHelper.isPMOEntity()) {
            <div class="row mb-3">
                <div class="col-md-12">
                    <div class="bg-white shadow-md rounded p-2">
                        <adaa-data-table
                            [titleHeader]="'user.create.responsibleFor'"
                            [useSmHeader]="true"
                            [configKey]="'user_form_concerned_entities_conf_list'"
                            [isLocalData]="true"
                            [localData]="responsiblities"
                            [enableViewAction]="false"
                            [enableEditAction]="false"
                            [enableAdd]="false"
                            [enableAdd]="!isViewMode"
                            [enableDeleteAction]="!isViewMode"
                            (addNewClicked)="addResponsibility()"
                            (actionClicked)="removeResponsibility($event)"
                        />
                    </div>
                </div>
            </div>
        }
    </form>
    <div class="col-12">
        <adaa-form-action-buttons
            [showSaveButton]="!isViewMode"
            [saveButtonTitle]="'executive_teams.submit'"
            (saveClicked)="submit()"
            (cancelClicked)="cancel()"
        />
    </div>
</div>

<adaa-float-action />
