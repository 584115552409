import { HttpHandlerFn, HttpRequest } from "@angular/common/http";

import { Constants } from "../../shared/constants/constants";
import { AdaaHelper } from "../utils";

function getCoreHeaders() {
  const sessionId = AdaaHelper.getLocalStorage(Constants.localStorageKeys.sessionId) ?? "";
  const language = AdaaHelper.getLocalStorage(Constants.localStorageKeys.language) ?? "ae";
  const entityId =
    AdaaHelper.getLocalStorage(Constants.localStorageKeys.currentEntity, { type: "prop", property: "id" }) ?? "1";
  const planId =
    AdaaHelper.getLocalStorage(Constants.localStorageKeys.currentPlan, { type: "prop", property: "id" }) ?? "0";
  const mapId = AdaaHelper.getMap(Number(entityId))?.id ?? "0";
  const deviceToken = AdaaHelper.getLocalStorage(Constants.localStorageKeys.hardwareUuid) ?? "";

  // Ensure all values are strings
  return {
    sessionId: `${sessionId}`,
    language: `${language}`,
    entityId: `${entityId}`,
    planId: `${planId}`,
    mapId: `${mapId}`,
    deviceToken: `${deviceToken}`,
  };
}

export function headersInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn) {
  let headers = req.headers
    .set("Pragma", "no-cache")
    .set("X-Frame-Options", "sameorigin")
    .set("x-xss-protection", "1; mode=block")
    .set("Accept", "application/json, application/octet-stream")
    .set("actionPage", window.location.href)
    .set("Access-Control-Allow-Origin", "*")
    .set("Access-Control-Allow-Methods", "GET, POST, PATCH, PUT, DELETE, OPTIONS")
    .set(
      "Access-Control-Allow-Headers",
      "Origin, X-Requested-With, Content-Type, Accept, Authorization, Accept-Language"
    );

  const entityType = AdaaHelper.getLocalStorage(Constants.localStorageKeys.currentEntity, {
    type: "prop",
    property: "entityType",
  });

  if (!req.url.includes("file/translationsDownload")) {
    const { sessionId, language, entityId, planId, mapId, deviceToken } = getCoreHeaders();
    headers = headers
      .set("mapId", mapId)
      .set("planId", planId)
      .set("language", language)
      .set("sessionId", sessionId)
      .set("deviceToken", deviceToken)
      .set("Accept-Language", language)
      .set("actionPage", window.location.href)
      .set("applicationURL", window.location.origin);

    if (!headers.has("entityId")) {
      headers = headers.set("entityId", entityId);
    }
  }

  if (req.url.includes("report/getallv2") && +entityType === Constants.CONSTANT_EXTERNAL_ENTITY_TYPE) {
    headers = headers.set("planId", "");
  }

  const clonedRequest = req.clone({
    headers,
    reportProgress: true,
  });

  return next(clonedRequest);
}
