import { NgClass } from "@angular/common";
import { Component, inject, input, OnInit, signal, viewChild } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { ToastrService } from "ngx-toastr";
import { forkJoin } from "rxjs";

import { TranslateTokenPipe } from "../../../../core/pipes";
import { AdaaHelper } from "../../../../core/utils";
import { dateRangeValidator } from "../../../../core/validators";
import {
  AttachmentsListComponent,
  CommentsComponent,
  ContributingEntitiesComponent,
  EntityPlanChangeDetectionComponent,
  FormCheckboxComponent,
  FormDropdownComponent,
  FormDropdownMultiComponent,
  FormDropdownTreeComponent,
  FormInputComponent,
  FormInputDateComponent,
  FormStepsComponent,
  MilestoneListComponent,
  NationalStrategiesSelectorComponent,
  TeamMembersListComponent,
  TpPmoDesisionsComponent,
  WfAlertDecisionComponent,
} from "../../../../shared/components";
import { Constants } from "../../../../shared/constants/constants";
import { AdaaBoolean, PageMode } from "../../../../shared/constants/enums";
import {
  FormTab,
  GovDirection,
  LoginResponse,
  MainResponseModel,
  SearchFields,
  SubGovDirection,
  TpComment,
  TpProjectDetails,
  TransformationalTarget,
  ValidatorModelType,
  ValueText,
} from "../../../../shared/models";
import {
  EntitiesApiService,
  ItemApiService,
  ObjectivesApiService,
  OrgUnitApiService,
  OtherEntitiesApiService,
  PropertiesService,
  SystemLayoutService,
  TpApiService,
  TpSectorApiService,
  TransformationalTargetsApiService,
  UsersApiService,
  ValidatorApiService,
} from "../../../../shared/services";

@Component({
  selector: "adaa-tp-editor",
  standalone: true,
  imports: [
    NgClass,
    TranslateModule,
    ReactiveFormsModule,
    AttachmentsListComponent,
    CommentsComponent,
    ContributingEntitiesComponent,
    FormCheckboxComponent,
    FormDropdownComponent,
    FormDropdownMultiComponent,
    FormDropdownTreeComponent,
    FormInputComponent,
    FormInputDateComponent,
    FormStepsComponent,
    MilestoneListComponent,
    TeamMembersListComponent,
    TpPmoDesisionsComponent,
    NationalStrategiesSelectorComponent,
    TranslateTokenPipe,
    WfAlertDecisionComponent,
    EntityPlanChangeDetectionComponent,
  ],
  templateUrl: "./tp-editor.component.html",
  styleUrl: "./tp-editor.component.scss",
})
export class TpEditorComponent implements OnInit {
  private _tpApiService = inject(TpApiService);
  private _objectivesApiService = inject(ObjectivesApiService);
  private _entitiesApiService = inject(EntitiesApiService);
  private _otherEntitiesApiService = inject(OtherEntitiesApiService);
  private _usersApiService = inject(UsersApiService);
  private _orgUnitApiService = inject(OrgUnitApiService);
  private _propertiesService = inject(PropertiesService);
  private _validatorApiService = inject(ValidatorApiService);
  private _formBuilder = inject(FormBuilder);
  private _router = inject(Router);
  private _toastrService = inject(ToastrService);
  private _translateService = inject(TranslateService);
  private _tpSectorApiService = inject(TpSectorApiService);
  private _tTargetsApiService = inject(TransformationalTargetsApiService);
  private _itemApiService = inject(ItemApiService);
  private _systemLayoutService = inject(SystemLayoutService);

  id = input<number | undefined>();
  pageMode = input.required<PageMode>();

  private _noneOption = () => ({ value: undefined, text: this._translateService.instant("common.form.label.none") });
  private _tTargetsCopy = signal<TransformationalTarget[]>([]);
  private _untilDestroyed = AdaaHelper.untilDestroyed();

  PageMode = PageMode;
  AdaaHelper = AdaaHelper;
  constants = Constants;
  submitted: boolean = false;
  enableLinkToNt: boolean = false;
  tpForm: FormGroup;
  formValidation = signal<ValidatorModelType | undefined>(undefined);
  activeTab = signal<number>(0);
  transformationalObjectives = signal<ValueText[]>([]);
  entities = signal<ValueText[]>([]);
  otherEntities = signal<ValueText[]>([]);
  owners = signal<ValueText[]>([]);
  orgUnits = signal<ValueText[]>([]);
  projects = signal<ValueText[]>([]);
  pmoDesisions = signal<ValueText[]>([]);
  sectors = signal<ValueText[]>([]);
  tTargets = signal<ValueText[]>([]);
  ntKpis = signal<ValueText[]>([]);
  govDirections = signal<GovDirection[]>([]);
  subGovDirections = signal<SubGovDirection[]>([]);
  readonly fetchProject = () => {
    return this._tpApiService.getProject(Number(this.id()));
  };
  tabs: FormTab[] = [
    {
      title: "common.form.label.information",
    },
    {
      title: "national_projects.milestones",
    },
    {
      title: "kpi.attachments",
    },
    {
      title: "national_projects.project_team_members.common_text",
    },
    {
      title: "common.links",
    },
  ];

  nationalStrategies = viewChild.required<NationalStrategiesSelectorComponent>("nationalStrategies");

  public get displayLabel(): string {
    const nameEN = this.tpForm.get("nameEN")?.value;
    const nameAE = this.tpForm.get("nameAE")?.value;

    return AdaaHelper.getPageLabel(nameAE, nameEN);
  }

  public get targetAudience(): TpComment[] {
    const targetAudience: TpComment[] = this.tpForm.get("targetAudience")?.value ?? [];

    return targetAudience.filter((e) => e.status !== Constants.OBJECT_STATUS.REMOVE);
  }

  public get expectedImpact(): TpComment[] {
    const expectedImpact: TpComment[] = this.tpForm.get("expectedImpact")?.value ?? [];

    return expectedImpact.filter((e) => e.status !== Constants.OBJECT_STATUS.REMOVE);
  }

  public get comments(): TpComment[] {
    const comments: TpComment[] = this.tpForm.get("comments")?.value ?? [];

    return comments.filter((e) => e.status !== Constants.OBJECT_STATUS.REMOVE);
  }

  public get filteredEntites(): ValueText[] {
    const entities: number[] = [];

    const entityId = this.tpForm.get("entityId")?.value;
    if (entityId) entities.push(+entityId);

    let contributingEntities = this.tpForm.get("contributingEntities")?.value;
    if (contributingEntities) {
      contributingEntities = contributingEntities
        .filter((e: { otherEntityId: number | undefined }) => !e.otherEntityId)
        .map((e: { entityId: number }) => e.entityId);

      if (contributingEntities) entities.push(...contributingEntities);
    }

    return this.entities().filter((e) => entities.includes(e.value));
  }

  public get filteredOtherEntites(): ValueText[] {
    const entities: number[] = [];

    let contributingEntities = this.tpForm.get("contributingEntities")?.value;
    if (contributingEntities) {
      contributingEntities = contributingEntities
        .filter((e: { otherEntityId: number | undefined }) => e.otherEntityId)
        .map((e: { otherEntityId: number }) => e.otherEntityId);

      if (contributingEntities) entities.push(...contributingEntities);
    }

    return this.otherEntities().filter((e) => entities.includes(e.value));
  }

  public get filteredSectors(): ValueText[] {
    const tpSectors = this.tpForm.get("sectors")?.value;

    return tpSectors ? this.sectors().filter((e) => tpSectors.includes(e.value)) : [];
  }

  public get showSaveButton(): boolean {
    return !this.id() || this.tpForm.get("status")?.value === Constants.OBJECT_STATUS.DRAFT;
  }

  public get selectedTargets(): TransformationalTarget[] {
    const ids = this.tpForm.get("transformationalTargetsIds")?.value;
    return ids ? this._tTargetsCopy().filter((e) => ids.includes(e.id)) : [];
  }

  public ngOnInit(): void {
    this.tpForm = this._prepareForm();

    this._systemLayoutService.hasCycleChanged$.pipe(this._untilDestroyed()).subscribe({
      next: () => this._router.navigateByUrl("console/national-project/national-projects"),
    });
    this._systemLayoutService.hasActiveEntityChanged$.pipe(this._untilDestroyed()).subscribe({
      next: () => this._router.navigateByUrl("console/national-project/national-projects"),
    });
  }

  public goBack(): void {
    this._router.navigateByUrl("/console/national-project/national-projects");
  }

  public submit(isFinish: boolean): void {
    this.submitted = true;
    this.tabs.forEach((e) => (e.hasError = false));

    if (!this.tpForm.valid) {
      this.tabs[0].hasError = true;
      this._toastrService.warning(this._translateService.instant("notification.warning.missing_info"));
      return;
    }

    const { governmentDirections, govSubDirections } = this.nationalStrategies().submit();
    this.tpForm.get("govDirections")?.setValue(governmentDirections ?? []);
    this.tpForm.get("govSubDirections")?.setValue(govSubDirections ?? []);

    if (this.tpForm.get("transformationalTargetsIds")) {
      const value = this._getTTargetsValue(this.tpForm.get("transformationalTargetsIds")?.value);
      this.tpForm.get("transformationalTargets")?.setValue(value);
    }

    //On create set status as Draft
    const id = this.tpForm.get("id")?.value as number;
    if (isFinish && !id) this.tpForm.get("status")?.setValue(Constants.OBJECT_STATUS.DRAFT);

    const data = this.tpForm.getRawValue();
    if (!this._isDataValid(data, isFinish)) return;

    this._tpApiService.saveProject(this._prepareDataFormSubmit(data, isFinish), isFinish).subscribe({
      next: (response) => {
        if (response.inError) return;

        this._toastrService.success(this._translateService.instant("notification.success.save"));

        isFinish
          ? this._router.navigateByUrl("/console/national-project/national-projects")
          : this.id()
            ? window.location.reload()
            : this._router.navigateByUrl(`/console/national-project/national-projects/edit/${response.responseData}`);
      },
    });
  }

  public entityChanged(value: number | undefined): void {
    if (!value) {
      //Reset Inputs
      this.tpForm.get("owner")?.setValue(null);
      this.tpForm.get("projectCoordinator")?.setValue(null);
      this.tpForm.get("orgUnitId")?.setValue(null);
      this.tpForm.get("stoppedStrategicProjectsIds")?.setValue(null);

      //Reset ddl
      this.owners.set([]);
      this.orgUnits.set([]);
      this.projects.set([]);
      if (this.enableLinkToNt) this.ntKpis.set([]);
    } else {
      this._getOwners(value);
      this._getOrgUnits(value);
      this._getProjects(value);
      if (this.enableLinkToNt) this._getNtKpis(value);
    }
  }

  public externalBudgetChanged(value: string): void {
    if (value === AdaaBoolean.Y) this.tpForm.get("sourceOfBudget")?.enable();
    else {
      this.tpForm.get("sourceOfBudget")?.setValue(null);
      this.tpForm.get("sourceOfBudget")?.disable();
    }
  }

  public commentAdded(event: string, formKey: "targetAudience" | "expectedImpact" | "comments"): void {
    const currentUser = AdaaHelper.getLocalStorage(Constants.localStorageKeys.user, {
      type: "json",
    }) as LoginResponse;

    const comments: TpComment[] = this.tpForm.get(formKey)?.value ?? [];

    const isTargetAudience = formKey === "targetAudience";
    const isExpectedImpact = formKey === "expectedImpact";
    const isComment = formKey === "comments";

    comments.push({
      id: 0,
      status: Constants.OBJECT_STATUS.ACTIVE,
      itemId: this.id() ? +this.id()! : 0,
      itemType: Constants.CONSTANT_NATIONAL_PROJECTS,
      audienceAE: isTargetAudience ? event : undefined,
      audienceEN: isTargetAudience ? event : undefined,
      impactAE: isExpectedImpact ? event : undefined,
      impactEN: isExpectedImpact ? event : undefined,
      commentsAE: isComment ? event : undefined,
      commentsEN: isComment ? event : undefined,
      creationDate: moment().utcOffset(Constants.uaeTimezone).valueOf(),
      updatedBy: currentUser.id,
      createdBy: currentUser.id,
      updateUserNameEN: currentUser.nameEN,
      updateUserNameAE: currentUser.nameAE,
      createdByUserNameEN: currentUser.nameEN,
      createdByUserNameAE: currentUser.nameAE,
      fakeId: Math.floor(Math.random() * 217 * 1000),
    });

    this.tpForm.get(formKey)?.setValue(comments);
  }

  public commentRemoved(event: TpComment, formKey: "targetAudience" | "expectedImpact" | "comments"): void {
    let comments: TpComment[] = this.tpForm.get(formKey)?.value ?? [];

    if (event.fakeId) {
      comments = comments.filter((e) => e.fakeId !== event.fakeId);
    } else {
      const comment = comments.find((e) => e.id === event.id);
      if (comment) comment.status = Constants.OBJECT_STATUS.REMOVE;
    }

    this.tpForm.get(formKey)?.setValue(comments);
  }

  public sectorsChanged(event: number[]): void {
    const nationalSectors = this.tpForm.get("nationalSectors")?.value ?? [];

    event.forEach((id) => {
      const item = nationalSectors.find((e: { nationalSectorId: number }) => e.nationalSectorId === id);
      if (!item) {
        const ns = {
          nationalSectorId: id,
          nameEN: "",
          nameAE: "",
          status: Constants.OBJECT_STATUS.ACTIVE,
        };
        nationalSectors.push(ns);
      } else {
        item.status = Constants.OBJECT_STATUS.ACTIVE;
      }
    });

    for (let i = nationalSectors.length - 1; i >= 0; i--) {
      if (!event.includes(nationalSectors[i].nationalSectorId)) {
        if ("id" in nationalSectors[i]) {
          nationalSectors[i].status = Constants.OBJECT_STATUS.REMOVE;
        } else {
          nationalSectors.splice(i, 1);
        }
      }
    }

    this.tpForm.get("nationalSectors")?.setValue(nationalSectors);
  }

  public initProject(response?: MainResponseModel<TpProjectDetails>): void {
    if (response?.inError) {
      this._toastrService.error(this._translateService.instant("notification.error.unknown_object"));
      this.goBack();
    }

    if (response?.responseData.status === Constants.OBJECT_STATUS.IN_REVIEW) {
      this._toastrService.warning(this._translateService.instant("notification.error.object_in_approval"));
      this.goBack();
    }

    this._checkIfLinkToNationalTargetEnabled();
    this._initPage();

    //If the page is on create mode and the entity is not pmo the default entity should be the current entity
    if (!this.id() && !AdaaHelper.isPMOEntity()) {
      this.tpForm.get("entityId")?.setValue(AdaaHelper.entity?.id);
      this.entityChanged(AdaaHelper.entity?.id);
    }

    if (response?.responseData) {
      this.tpForm.patchValue(response.responseData);

      //Disable/Enable sourceOfBudget
      this.externalBudgetChanged(response.responseData.externalBudget);

      //Set stoppedStrategicProjectsIds only FE field
      this.tpForm
        .get("stoppedStrategicProjectsIds")
        ?.setValue(response.responseData.stoppedStrategicProjects?.map((e) => e.initiativeId));

      //Set Sectors ddl
      this.tpForm.get("sectors")?.setValue(response.responseData.nationalSectors?.map((e) => e.nationalSectorId));

      //Set T. Targets ddl
      this.tpForm
        .get("transformationalTargetsIds")
        ?.setValue(response.responseData.transformationalTargets?.map((e) => e.id));

      //Set gov directions ddl
      this.govDirections.set(response.responseData.govDirections ?? []);
      this.subGovDirections.set(response.responseData.govSubDirections ?? []);

      //Get Entities ddl values
      this.entityChanged(response.responseData.entityId);
    }
  }

  public stoppedStrategicProjectsChanged(event: number[] | undefined) {
    const projects: unknown[] = [];
    const currentUser = AdaaHelper.getLocalStorage(Constants.localStorageKeys.user, {
      type: "json",
    }) as LoginResponse;

    event = event ?? [];

    event.forEach((e) => {
      projects.push({
        initiativeId: e,
        createdBy: currentUser.id,
        updatedBy: currentUser.id,
      });
    });

    this.tpForm.get("stoppedStrategicProjects")?.setValue(projects);
  }

  /**
   * This method will get
   * - Validations
   * - T. Objectives ddl data
   * - Entities ddl data
   * - Other Entities ddl data
   * - Sectors ddl data
   * - PMO TP Decision
   * - T. Targets ddl data
   */
  private _initPage(): void {
    forkJoin({
      validators: this._validatorApiService.searchByKey(Constants.VALIDATORS_CONF_KEY.VALIDATION_NATIONAL_PROJECT_FORM),
      objectives: this._objectivesApiService.getObjectivesByType(Constants.CONSTANT_TRANSFORMATIONAL_OBJECTIVE),
      entities: this._entitiesApiService.getAllV2(),
      otherEntities: this._otherEntitiesApiService.getAll(),
      pmoDesision: this._propertiesService.getPropById(Constants.CONSTANT_TP_PMO_DECISION),
      sectors: this._tpSectorApiService.getAll(),
      tTargets: this._tTargetsApiService.getAll(),
    }).subscribe({
      next: (results) => {
        if (!results.validators.inError) this.formValidation.set(results.validators.responseData);

        if (!results.objectives.inError)
          this.transformationalObjectives.set(
            AdaaHelper.setDropdownArray(results.objectives.responseData, "id", AdaaHelper.getFieldLanguage("name"))
          );

        if (!results.entities.inError)
          this.entities.set(
            AdaaHelper.setDropdownArray(results.entities.responseData, "id", AdaaHelper.getFieldLanguage("name"))
          );

        if (!results.otherEntities.inError)
          this.otherEntities.set(
            AdaaHelper.setDropdownArray(results.otherEntities.responseData, "id", AdaaHelper.getFieldLanguage("name"))
          );

        if (!results.pmoDesision.inError)
          this.pmoDesisions.set(
            AdaaHelper.setDropdownArray(results.pmoDesision.responseData, "id", AdaaHelper.getFieldLanguage("name"))
          );

        if (!results.sectors.inError)
          this.sectors.set(
            AdaaHelper.setDropdownArray(results.sectors.responseData, "id", AdaaHelper.getFieldLanguage("name"))
          );

        if (!results.tTargets.inError) {
          this.tTargets.set(
            AdaaHelper.setDropdownArray(results.tTargets.responseData, "id", AdaaHelper.getFieldLanguage("name"))
          );
          this._tTargetsCopy.set(results.tTargets.responseData);
        }
      },
    });
  }

  private _prepareForm() {
    return this._formBuilder.group(
      {
        id: { value: null, disabled: false },
        objectiveId: { value: null, disabled: false },
        nameAE: [{ value: null, disabled: false }, Validators.required],
        nameEN: [{ value: null, disabled: false }, Validators.required],
        dscAE: [{ value: null, disabled: false }, Validators.required],
        dscEN: [{ value: null, disabled: false }, Validators.required],
        startDate: [{ value: null, disabled: false }, Validators.required],
        endDate: [{ value: null, disabled: false }, Validators.required],
        actualStartDate: { value: null, disabled: true },
        actualEndDate: { value: null, disabled: true },
        entityId: [{ value: null, disabled: !AdaaHelper.isPMOEntity() }, Validators.required],
        owner: [{ value: null, disabled: false }, Validators.required],
        projectCoordinator: [{ value: null, disabled: false }, Validators.required],
        orgUnitId: { value: null, disabled: false },
        externalBudget: { value: AdaaBoolean.N, disabled: false },
        sourceOfBudget: { value: null, disabled: false },
        stoppedStrategicProjects: { value: null, disabled: false },
        decisions: { value: null, disabled: false },
        contributingEntities: { value: null, disabled: false },
        nationalSectors: { value: null, disabled: false },
        targetAudience: { value: null, disabled: false },
        expectedImpact: { value: null, disabled: false },
        comments: { value: null, disabled: false },
        projectTeamMembers: { value: null, disabled: false },
        attachments: { value: null, disabled: false },
        milestones: { value: [], disabled: false },
        transformationalTargets: { value: null, disabled: false },
        govDirections: { value: null, disabled: false },
        govSubDirections: { value: null, disabled: false },
        ntKpiId: { value: null, disabled: true },
        status: { value: null, disabled: false },
        updateUser: { value: null, disabled: false },
        refCode: { value: null, disabled: false },

        //ONLY FE
        transformationalTargetsIds: { value: null, disabled: false },
        stoppedStrategicProjectsIds: { value: null, disabled: false },
        sectors: { value: null, disabled: false },
      },
      {
        validators: Validators.compose([
          dateRangeValidator("startDate", "endDate"),
          dateRangeValidator("actualStartDate", "actualEndDate"),
        ]),
      }
    );
  }

  private _getOwners(entityId: number): void {
    this._usersApiService.getOwnersPerEntity(entityId).subscribe({
      next: (response) => {
        if (response.inError) return;

        this.owners.set(
          AdaaHelper.setDropdownArray(response.responseData ?? [], "id", AdaaHelper.getFieldLanguage("name"))
        );

        const isOwnerAvailable = this.owners()?.find((e) => e.value === this.tpForm.get("owner")?.value);
        if (!isOwnerAvailable) this.tpForm.get("owner")?.setValue(null);

        const isPMAvailable = this.owners()?.find((e) => e.value === this.tpForm.get("projectCoordinator")?.value);
        if (!isPMAvailable) this.tpForm.get("projectCoordinator")?.setValue(null);
      },
    });
  }

  private _getOrgUnits(entityId: number): void {
    this._orgUnitApiService
      .getbyEntityidAndObjectype(entityId, Constants.CONSTANT_PERMISSIONS.NATIONAL_PROJECTS)
      .subscribe({
        next: (response) => {
          if (response.inError) return;
          this.orgUnits.set([
            this._noneOption(),
            ...AdaaHelper.setDropdownArray(
              response.responseData ?? [],
              "id",
              AdaaHelper.getFieldLanguage("name"),
              "parentOrgUnit"
            ),
          ]);

          const isOrgUnitAvailable = response.responseData?.find((e) => e.id === this.tpForm.get("orgUnitId")?.value);
          if (!isOrgUnitAvailable) this.tpForm.get("orgUnitId")?.setValue(null);
        },
      });
  }

  private _getProjects(entityId: number): void {
    this._tpApiService.getStrategicProjects(entityId).subscribe({
      next: (response) => {
        if (response.inError) return;
        this.projects.set(
          AdaaHelper.setDropdownArray(response.responseData ?? [], "id", AdaaHelper.getFieldLanguage("name"))
        );

        const ids = this.tpForm.get("stoppedStrategicProjectsIds")?.value;
        const isAvailable = this.projects().some((e) => ids?.includes(e.value));
        if (!isAvailable) this.tpForm.get("stoppedStrategicProjectsIds")?.setValue(null);
      },
    });
  }

  private _checkIfLinkToNationalTargetEnabled(): void {
    this._propertiesService.getPropById(Constants.FRONT_END_TOGGLES).subscribe({
      next: (response) => {
        if (response.inError) return;

        const linkToNt = response.responseData.find(
          (e) => e.id === Constants.DYNAMIC_LINKS_LOOKUP_ID.NATIONAL_STRATEGIES_LINK_TO_NT_ENABLED
        );

        if (linkToNt && +linkToNt.value === 1) {
          this.enableLinkToNt = true;
          this.tpForm.get("ntKpiId")?.enable();
        } else {
          this.enableLinkToNt = false;
          this.tpForm.get("ntKpiId")?.disable();
        }
      },
    });
  }

  private _getNtKpis(entityId: number): void {
    const filter: SearchFields = {
      searchFields: {
        status: {
          order: undefined,
          value: undefined,
          lov: [1],
        },
      },
    };

    this._itemApiService.getAllV2(Constants.KPI_TYPE.NTKPI, AdaaBoolean.N, filter, entityId).subscribe({
      next: (response) => {
        if (response.inError) return;
        this.ntKpis.set(AdaaHelper.setDropdownArray(response.responseData, "id", AdaaHelper.getFieldLanguage("name")));

        const ntkpi = this.tpForm.get("ntKpiId")?.value;
        if (ntkpi) {
          const index = this.ntKpis().findIndex((e) => e.value == ntkpi);
          if (index === -1) this.tpForm.get("ntKpiId")?.setValue(null);
        }
      },
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _isDataValid(data: any, isFinish: boolean): boolean {
    let isValid: boolean = true;

    if (isFinish) {
      //TEAM MEMBERS ERRORS
      const teamMembers = (data.projectTeamMembers || []).filter(
        (e: { status: number }) => e.status !== Constants.OBJECT_STATUS.REMOVE
      );
      if (teamMembers.length < 1) {
        this._toastrService.error(this._translateService.instant("national_projects.messages.no_members"));
        isValid = false;
        this.tabs[3].hasError = true;
      }

      //GOV DIRECTIONS ERRORS
      const governmentDirections = (data.governmentDirections || []).filter(
        (e: { status: number }) => e.status !== Constants.OBJECT_STATUS.REMOVE
      );
      governmentDirections.forEach((gd: { subdirections: SubGovDirection[] }) => {
        const subdirections = gd.subdirections.filter((e) => e.status !== Constants.OBJECT_STATUS.REMOVE);
        if (subdirections == null || subdirections.length == 0) {
          this._toastrService.error(
            this._translateService.instant("national_projects.messages.government_direction_error")
          );
          isValid = false;
          this.tabs[4].hasError = true;
        }
      });
    }

    //MILESTONES ERRORS
    const milestones = (data.milestones || []).filter(
      (e: { status: number }) => e.status !== Constants.OBJECT_STATUS.REMOVE
    );
    if (milestones.length > 0) {
      const totalPercentage = milestones.reduce(
        (prev: number, curr: { workload: { workload: string | number } }) => prev + (+curr.workload.workload || 0),
        0
      );

      if (totalPercentage > 0 && totalPercentage != 100) {
        this._toastrService.error(this._translateService.instant("national_projects.messages.weights_warning"));
        isValid = false;
        this.tabs[1].hasError = true;
      }

      const milestonesDateErrors = milestones.some((e: { startDate: number; endDate: number }) => {
        return e.startDate < data.startDate || e.endDate > data.endDate;
      });

      if (milestonesDateErrors) {
        this._toastrService.error(this._translateService.instant("national_projects.messages.milestone_dates_warning"));
        isValid = false;
        this.tabs[1].hasError = true;
      }
    }

    return isValid;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _prepareDataFormSubmit(data: any, isFinish: boolean): any {
    delete data.transformationalTargetsIds;
    delete data.stoppedStrategicProjectsIds;

    if (!data.id) delete data.id;

    if (isFinish && !data.id) delete data.status;

    if (!data.contributingEntities) data.contributingEntities = [];

    (data.milestones || []).forEach(
      (milestone: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        teamMembers: any[];
        id: number | undefined;
        status: number | undefined;
      }) => {
        if (!milestone.id) delete milestone.id;

        if (!data.id && !isFinish) delete milestone.status;
        if (
          isFinish &&
          milestone.status !== Constants.OBJECT_STATUS.REMOVE &&
          milestone.status !== Constants.OBJECT_STATUS.ACTIVE
        )
          milestone.status = Constants.OBJECT_STATUS.DRAFT;

        (milestone.teamMembers || []).forEach((teamMember) => {
          if (!teamMember.id) {
            delete teamMember.id;
            delete teamMember.fakeId;
          }

          if (!data.id && !isFinish) delete teamMember.status;

          if (
            isFinish &&
            teamMember.status !== Constants.OBJECT_STATUS.REMOVE &&
            teamMember.status !== Constants.OBJECT_STATUS.ACTIVE
          )
            milestone.status = Constants.OBJECT_STATUS.DRAFT;
        });
      }
    );

    (data.projectTeamMembers || []).forEach(
      (e: { status: number | undefined; id: number | undefined; fakeId: number | undefined }) => {
        if (!e.id) {
          delete e.id;
          delete e.fakeId;
        }

        if (!data.id && !isFinish) delete e.status;
        if (isFinish && e.status !== Constants.OBJECT_STATUS.REMOVE && e.status !== Constants.OBJECT_STATUS.ACTIVE)
          e.status = Constants.OBJECT_STATUS.DRAFT;
      }
    );

    (data.targetAudience || []).forEach(
      (e: { status: number | undefined; id: number | undefined; fakeId: number | undefined }) => {
        if (!e.id) {
          delete e.id;
          delete e.fakeId;
        }

        if (!data.id && !isFinish) delete e.status;
        if (isFinish && e.status !== Constants.OBJECT_STATUS.REMOVE && e.status !== Constants.OBJECT_STATUS.ACTIVE)
          e.status = Constants.OBJECT_STATUS.DRAFT;
      }
    );

    (data.expectedImpact || []).forEach(
      (e: { status: number | undefined; id: number | undefined; fakeId: number | undefined }) => {
        if (!e.id) {
          delete e.id;
          delete e.fakeId;
        }

        if (!data.id && !isFinish) delete e.status;
        if (isFinish && e.status !== Constants.OBJECT_STATUS.REMOVE && e.status !== Constants.OBJECT_STATUS.ACTIVE)
          e.status = Constants.OBJECT_STATUS.DRAFT;
      }
    );

    (data.comments || []).forEach(
      (e: { status: number | undefined; id: number | undefined; fakeId: number | undefined }) => {
        if (!e.id) {
          delete e.id;
          delete e.fakeId;
        }

        if (!data.id && !isFinish) delete e.status;
        if (isFinish && e.status !== Constants.OBJECT_STATUS.REMOVE && e.status !== Constants.OBJECT_STATUS.ACTIVE)
          e.status = Constants.OBJECT_STATUS.DRAFT;
      }
    );

    return data;
  }

  private _getTTargetsValue(event: number[]): Array<{ id: number; status?: number }> {
    const value: Array<{ id: number; status?: number }> = [];
    const transformationalTargets = this.tpForm.get("transformationalTargets")?.value ?? [];

    transformationalTargets.forEach((e: { id: number }) => {
      if (!event.includes(e.id)) value.push({ id: e.id, status: Constants.OBJECT_STATUS.REMOVE });
    });

    event?.forEach((e) => {
      value.push({ id: e });
    });

    return value;
  }
}
