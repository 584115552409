@for (data of childs(); track $index) {
    <ng-container>
        <table class="items--table" [dir]="appService.language() === Language.Arabic ? 'rtl' : 'ltr'">
            <tbody>
                <tr id="item{{ data?.id }}" [class.selected]="currentSelectedOrgUnit === data?.id">
                    <td
                        id="item-{{ data?.id }}"
                        [style.padding-left.px]="
                            parent().expanded && appService.language() === Language.English ? getPaddingForElement(parent().id) : BASE_PADDING
                        "
                        [style.padding-right.px]="
                            parent().expanded && appService.language() === Language.Arabic ? getPaddingForElement(parent().id) : BASE_PADDING
                        "
                    >
                        <a (click)="data.childs && data.childs.length > 0 ? expandChilds(data) : selectOrgUnit(data)">
                            @if (data?.childs?.length) {
                                <i
                                    [class.adaa-icon-expand_more]="data.expanded"
                                    [class.adaa-icon-arrow_forward_ios]="!data.expanded && appService.language() === Language.English"
                                    [class.adaa-icon-arrow_back_ios]="!data.expanded && appService.language() === Language.Arabic"
                                ></i>
                            }
                        </a>
                    </td>
                    <td (click)="selectOrgUnit(data)" style="width: 35%" class="address" [class.text-end]="appService.language() === Language.Arabic">
                        {{ "status.level" | translate }}{{ level() }}
                    </td>
                    <td (click)="selectOrgUnit(data)" style="width: 50%" [class.text-end]="appService.language() === Language.Arabic">
                        {{ AdaaHelper.getItemValueByToken(data, "name") }}
                    </td>
                    <td style="width: 10%" class="actions" *adaaHasPermission="managePermission()">
                        <ng-container>
                            @if ("L" + level() !== "L1") {
                                <div [class.text-end]="appService.language() === Language.Arabic" class="d-flex flex-row">
                                    <span (click)="selectOrgUnit(data); actionClicked.emit({ event: ActionEvents.edit, data })" class="mx-1 icons">
                                        <i class="adaa-icon-edit"></i>
                                    </span>
                                    <span (click)="selectOrgUnit(data); actionClicked.emit({ event: ActionEvents.delete, data })" class="mx-1 icons">
                                        <i class="adaa-icon-delete"></i>
                                    </span>
                                </div>
                            }
                        </ng-container>
                    </td>
                </tr>
            </tbody>
        </table>
        @if (data?.expanded && data?.childs) {
            <ng-container>
                <adaa-organization-structure
                    [childs]="data.childs ?? []"
                    [level]="level() + 1"
                    [parent]="data"
                    (actionClicked)="actionClicked.emit($event)"
                    (selectedUnit)="selectedUnit.emit($event)"
                    [currentSelectedOrgUnit]="currentSelectedOrgUnit"
                />
            </ng-container>
        }
    </ng-container>
}
