import { HttpEvent, HttpEventType } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as localForage from "localforage";

import { environment } from "../environments/environment";
import { LocalForageStorage } from "../utils";

@Injectable({
  providedIn: "root",
})
export class MainInterceptorService {
  public interceptorMapFn(event: HttpEvent<unknown>) {
    if (event.type !== HttpEventType.Response) {
      return event;
    }

    const urlStr = event.url?.replace(environment.apiUrl, "") ?? "";

    LocalForageStorage.STORED_APIS.forEach((api) => {
      if (new RegExp(api).test(urlStr)) {
        localForage
          .setItem(`${urlStr}`, event.body)
          .then(() => {})
          .catch(() => {});
      }
    });

    return event;
  }
}
