<div class="row">
    @if (planPeriod()) {
        <div class="col-12 mb-2">
            <adaa-period-slider-variant
                [showChartCycle]="false"
                [activePeriod]="structureViewService.selectedPeriod()!"
                [_frequency]="constants.FREQUENCY.MONTHLY"
                [_startDate]="planPeriod().startDate | dubaiTime"
                [_endDate]="planPeriod().endDate | dubaiTime"
                (selectPeriod)="selectPeriod($event)"
            />
        </div>
    }

    @if (structureViewService.selectedPeriod()) {
        <div class="col-12">
            <div class="d-block">
                <div class="position-relative d-flex mt-1 mb-1">
                    <ul
                        class="nav nav-pills nav-fill gap-2 p-1 bg-primary small rounded-5 shadow w-auto"
                        id="pillNav2"
                        role="tablist"
                        style="
                            --bs-nav-link-color: var(--bs-white);
                            --bs-nav-pills-link-active-color: var(--bs-primary);
                            --bs-nav-pills-link-active-bg: var(--bs-white);
                            --bs-link-hover-color: var(--bs-primary-bg-subtle);
                            --bs-nav-link-font-weight: 700;
                            --bs-nav-link-font-size: 14px;
                        "
                    >
                        <li class="nav-item" role="presentation" (click)="structureViewService.selectedLayout.set(1)">
                            <a
                                class="nav-link px-4 rounded-5"
                                [class.active]="structureViewService.selectedLayout() === 1"
                                data-bs-toggle="tab"
                                type="button"
                                role="tab"
                                aria-selected="true"
                            >
                                <fa-icon [icon]="['fas', 'table']"></fa-icon>
                            </a>
                        </li>

                        <li class="nav-item" role="presentation" (click)="structureViewService.selectedLayout.set(2)">
                            <a
                                class="nav-link px-4 rounded-5"
                                [class.active]="structureViewService.selectedLayout() === 2"
                                data-bs-toggle="tab"
                                type="button"
                                role="tab"
                                aria-selected="false"
                            >
                                <fa-icon [icon]="['fas', 'list']"></fa-icon>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="tab-content clearfix mt-2">
                <div class="tab-pane" [class.active]="structureViewService.selectedLayout() === 1">
                    <div class="bg-white rounded-1 shadow-sm border border-1">
                        <adaa-nested-table [columns]="columns" [data]="objectives()" [periodId]="structureViewService.selectedPeriod()!.period!.id" />
                    </div>
                </div>

                <div class="tab-pane" [class.active]="structureViewService.selectedLayout() === 2">
                    <adaa-objects-tree [columns]="columnsTree" [data]="objectives()" [periodId]="structureViewService.selectedPeriod()!.period!.id" />
                </div>
            </div>
        </div>
    }
</div>

<adaa-float-action />
