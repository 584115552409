import { Component, computed, inject, OnInit, viewChild } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { AdaaHelper } from "../../../../core/utils";
import { ConfirmationModalComponent, DataTableComponent, FloatActionComponent } from "../../../../shared/components";
import { genericFloatButtons } from "../../../../shared/components/general/float-action";
import { Constants } from "../../../../shared/constants/constants";
import { PermissionAction } from "../../../../shared/constants/enums";
import { CustomButton, ItemAction, PropTypeModelType, TableButtonClicked } from "../../../../shared/models";
import {
  LanguageService,
  PermissionsService,
  PropertiesService,
  SystemLayoutService,
  TpApiService,
} from "../../../../shared/services";

@Component({
  selector: "adaa-tp-list",
  standalone: true,
  imports: [DataTableComponent, FloatActionComponent],
  templateUrl: "./tp-list.component.html",
  styleUrl: "./tp-list.component.scss",
})
export class TpListComponent implements OnInit {
  private _router = inject(Router);
  private _languageService = inject(LanguageService);
  private _modalService = inject(NgbModal);
  private _tpApiService = inject(TpApiService);
  private _toastrService = inject(ToastrService);
  private _translateService = inject(TranslateService);
  private _propertiesService = inject(PropertiesService);
  private _permissionsService = inject(PermissionsService);
  private _systemLayoutService = inject(SystemLayoutService);

  private _tpTable = viewChild<DataTableComponent>("tpTable");

  private readonly _floatActions = genericFloatButtons();
  private _untilDestroyed = AdaaHelper.untilDestroyed();
  constants = Constants;
  customButtons: CustomButton[] = [];
  paramsString = computed(() => `itemType=${Constants.CONSTANT_NATIONAL_PROJECTS}`);

  disableEditDeleteRules: ItemAction[] = [
    {
      propertyName: "status",
      compareWith: Constants.SERVICES.STATE.IN_REVIEW,
      operator: "equal",
    },
  ];

  hideDeleteEditRules: ItemAction[] = AdaaHelper.isPMOEntity()
    ? []
    : [
        {
          propertyName: "entityId",
          compareWith: AdaaHelper.entity?.id,
          operator: "not-equal",
        },
      ];

  public ngOnInit(): void {
    this._checkReadyToPublishAndCancelStatusEnabled();

    this._systemLayoutService.hasCycleChanged$.pipe(this._untilDestroyed()).subscribe({
      next: () => window.location.reload(),
    });
    this._systemLayoutService.hasActiveEntityChanged$.pipe(this._untilDestroyed()).subscribe({
      next: () => window.location.reload(),
    });

    this._floatActions([
      {
        key: "helpdesk",
        data: {
          url: "/helpdesk/create",
        },
      },
      {
        key: "print",
        data: undefined,
      },
    ]);
  }

  public addNewClicked(): void {
    this._router.navigateByUrl("/console/national-project/national-projects/create");
  }

  public actionClicked(event: TableButtonClicked): void {
    switch (event.event) {
      case "view":
        this._router.navigateByUrl(`/national-projects-dashboard/projects/${event.data.id}`);
        break;
      case "edit":
        this._router.navigateByUrl(`/console/national-project/national-projects/edit/${event.data.id}`);
        break;
      case "connections":
        this._router.navigateByUrl(
          `/console/connections/${event.data.id}/${Constants.CONSTANT_NATIONAL_PROJECTS}?isSoftConnection=false`
        );
        break;
      case "delete":
        this._deleteProject(event.data.id);
        break;
      case "publish":
      case "unpublish":
        this._publishProject(event.data);
        break;
      case "cancel":
      case "uncancel":
        this._cancelProject(event.data);
        break;
      default:
        break;
    }
  }

  public childClicked(event: TableButtonClicked): void {
    switch (event.event) {
      case "view":
        this._router.navigateByUrl(`/national-projects-dashboard/milestones/${event.data.id}`);
        break;
      case "edit":
        this._router.navigateByUrl(`/console/national-project/project-milestones/edit/${event.data.id}`);
        break;
      case "connections":
        this._router.navigateByUrl(
          `/console/connections/${event.data.id}/${Constants.CONSTANT_NATIONAL_PROJECTS_MILESTONE}?isSoftConnection=false`
        );
        break;
      default:
        break;
    }
  }

  private _checkReadyToPublishAndCancelStatusEnabled(): void {
    this._propertiesService.getPropById(Constants.FRONT_END_TOGGLES).subscribe({
      next: (response) => {
        if (response.inError) return;

        const readyToPublish = response.responseData.find(
          (e) => e.id === Constants.DYNAMIC_LINKS_LOOKUP_ID.TRANSFORMATIONAL_PROJECT_READY_TO_PUBLISH
        );

        const cancelStatus = response.responseData.find(
          (e) => e.id === Constants.DYNAMIC_LINKS_LOOKUP_ID.TRANSFORMATIONAL_PROJECT_CANCEL_STATUS
        );

        this._permissionsService.isPermissionsLoaded.pipe(this._untilDestroyed()).subscribe((loaded: boolean) => {
          if (!loaded) return;

          this._checkPermission(readyToPublish, cancelStatus);
        });
      },
    });
  }

  private _checkPermission(readyToPublish: PropTypeModelType | undefined, cancelStatus: PropTypeModelType | undefined) {
    const permission = this._permissionsService.hasPermission([
      {
        permissionAction: PermissionAction.manage,
        objectTypeId: Constants.CONSTANT_PERMISSIONS.TRANSFORMATIONAL_PROJECTS_READY_TO_PUBLISH,
      },
    ]);

    const currentUserEntity = AdaaHelper.getLocalStorage(Constants.localStorageKeys.user, {
      type: "prop",
      property: "entityId",
    });

    if (readyToPublish && +readyToPublish.value === 1 && AdaaHelper.isPMOEntity()) {
      this.customButtons.push(
        {
          iconName: "fa-solid fa-file-circle-plus fa-custom-action",
          eventName: "publish",
          iconTitle: "national_projects.ready_to_publish",
          onlyIf: (data: { nationalProjectReadyPublish: boolean; status: number }) => {
            return (
              !data.nationalProjectReadyPublish &&
              data.status !== Constants.OBJECT_STATUS.DRAFT &&
              data.status !== Constants.OBJECT_STATUS.IN_REVIEW &&
              permission
            );
          },
        },
        {
          iconName: "fa-solid fa-file-circle-xmark fa-custom-action",
          eventName: "unpublish",
          iconTitle: "national_projects.project_published",
          onlyIf: (data: { nationalProjectReadyPublish: boolean; status: number }) => {
            return (
              data.nationalProjectReadyPublish &&
              data.status !== Constants.OBJECT_STATUS.DRAFT &&
              data.status !== Constants.OBJECT_STATUS.IN_REVIEW &&
              permission
            );
          },
        }
      );
    }

    if (cancelStatus && +cancelStatus.value === 1 && +currentUserEntity === Constants.CONSTANT_PMO_ID) {
      this.customButtons.push(
        {
          iconName: "fa-regular fa-circle-xmark fa-custom-action",
          eventName: "cancel",
          iconTitle: "national_projects.cancel",
          onlyIf: (data: { nationalProjectIgnored: boolean; status: number }) => {
            return (
              !data.nationalProjectIgnored &&
              data.status !== Constants.OBJECT_STATUS.DRAFT &&
              data.status !== Constants.OBJECT_STATUS.IN_REVIEW &&
              permission
            );
          },
        },
        {
          iconName: "fa-regular fa-circle-check fa-custom-action",
          eventName: "uncancel",
          iconTitle: "national_projects.enable",
          onlyIf: (data: { nationalProjectIgnored: boolean; status: number }) => {
            return (
              data.nationalProjectIgnored &&
              data.status !== Constants.OBJECT_STATUS.DRAFT &&
              data.status !== Constants.OBJECT_STATUS.IN_REVIEW &&
              permission
            );
          },
        }
      );
    }

    this.customButtons = [...this.customButtons];
  }

  private _deleteProject(id: number): void {
    const modal = this._modalService.open(ConfirmationModalComponent, {
      centered: true,
      size: "md",
      modalDialogClass: this._languageService.modalDirection(),
    });

    modal.componentInstance.header = "national_projects.confirmation_msg.delete_national_project_title";
    modal.componentInstance.title = "national_projects.confirmation_msg.delete_national_project_msg";

    modal.result.then((e) => {
      if (e) this._deleteProjectConfirmed(id);
    });
  }

  private _deleteProjectConfirmed(id: number) {
    this._tpApiService.deleteTp(id).subscribe({
      next: (response) => {
        if (response.inError) return;

        this._toastrService.success(this._translateService.instant("notification.success.remove"));
        this._tpTable()?.loadTableData();
      },
    });
  }

  private _publishProject(data: { id: number; nationalProjectReadyPublish: boolean | undefined }): void {
    const modal = this._modalService.open(ConfirmationModalComponent, {
      centered: true,
      size: "md",
      modalDialogClass: this._languageService.modalDirection(),
    });

    modal.componentInstance.header = "national_projects.confirmation_msg.publish_project_title";
    modal.componentInstance.title = "national_projects.confirmation_msg.publish_project_msg";

    modal.result.then((e) => {
      if (e) this._publishProjectConfirmed(data);
    });
  }

  private _publishProjectConfirmed(data: { id: number; nationalProjectReadyPublish: boolean | undefined }) {
    this._tpApiService
      .readyToPublish(data.id, data.nationalProjectReadyPublish ? !data.nationalProjectReadyPublish : true)
      .subscribe({
        next: (response) => {
          if (response.inError) return;

          this._toastrService.success(
            this._translateService.instant("national_projects.project_published_successfully"),
            this._translateService.instant("national_projects.publish")
          );
          this._tpTable()?.loadTableData();
        },
      });
  }

  private _cancelProject(data: { id: number; nationalProjectIgnored: boolean | undefined }): void {
    const modal = this._modalService.open(ConfirmationModalComponent, {
      centered: true,
      size: "md",
      modalDialogClass: this._languageService.modalDirection(),
    });

    modal.componentInstance.header = data.nationalProjectIgnored
      ? "national_projects.enable_yes_no_title"
      : "national_projects.cancel_yes_no_title";
    modal.componentInstance.title = data.nationalProjectIgnored
      ? "national_projects.enable_yes_no_information"
      : "national_projects.cancel_yes_no_information";
    modal.componentInstance.subTitle = "";

    modal.result.then((e) => {
      if (e) this._cancelProjectConfirmed(data);
    });
  }

  private _cancelProjectConfirmed(data: { id: number; nationalProjectIgnored: boolean | undefined }): void {
    this._tpApiService.cancelUncancelProject(data.id, data.nationalProjectIgnored ? "uncancel" : "cancel").subscribe({
      next: (response) => {
        if (response.inError) return;

        this._toastrService.success(
          this._translateService.instant(
            data.nationalProjectIgnored ? "national_projects.project_enabled" : "national_projects.project_canceled"
          ),
          this._translateService.instant(
            data.nationalProjectIgnored ? "national_projects.enable" : "national_projects.cancel"
          )
        );
        this._tpTable()?.loadTableData();
      },
    });
  }
}
