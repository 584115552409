<adaa-entity-plan-change-detection
    [template]="page"
    [request$]="agmMilestoneDetailsService.fetchMilestone(+id())"
    [ignoreEntityDetection]="AdaaHelper.isPMOEntity()"
    (continue)="agmMilestoneDetailsService.init($event)"
/>

<ng-template #page>
    <div class="row">
        <!-- HEADER -->
        <div class="col-12">
            <h4 class="fw-bold">{{ agmMilestoneDetailsService.milestone() | translateToken: "name" }}</h4>
        </div>

        <!-- UPDATE BY -->
        <div class="col-12 mb-1">
            {{ "nkpi.last_update" | translate }}, {{ agmMilestoneDetailsService.milestone()?.updateTS | date: Constants.normalDate }}
            {{ "common.form.label.by" | translate }}
            {{
                agmMilestoneDetailsService.milestone()?.updateUserNameEN
                    ? (agmMilestoneDetailsService.milestone() | translateToken: "updateUserName")
                    : agmMilestoneDetailsService.milestone()?.updateUser
            }}
        </div>

        <!-- OWNER AND PROGRESS -->
        <div class="col-12 mb-3">
            <div class="grid gap-3">
                <div class="g-col-md-6 g-col-xs-12 bg-white shadow-md rounded px-1 text-center d-flex align-items-center">
                    <div class="row">
                        @if (agmMilestoneDetailsService.milestone()?.entityLogoId) {
                            <div class="col-md-6 col-sm-12 overflow-hidden">
                                <h5 class="fw-bold mb-2">{{ "national_projects.accountable_entity" | translate }}</h5>
                                <adaa-picture [pictureId]="agmMilestoneDetailsService.milestone()?.entityLogoId" [width]="'300px'"></adaa-picture>
                            </div>
                        }
                        <div class="col-md-6 col-sm-12">
                            <div class="row">
                                <h5 class="fw-bold mb-2">{{ "annual_meetings.title" | translate }}</h5>

                                <a
                                    [routerLink]="[
                                        '/annual-meetings-projects-dashboard/projects/' +
                                            agmMilestoneDetailsService.milestone()?.annualMeetingProjectId,
                                    ]"
                                >
                                    {{ agmMilestoneDetailsService.milestone() | translateToken: "annualMeetingProjectName" }}
                                </a>

                                <h6 class="mt-2 mb-2 text-primary">
                                    {{ agmMilestoneDetailsService.milestone() | translateToken: "annualMeetingProjectEntityName" }}
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="g-col-md-6 g-col-xs-12">
                    <div class="col-12 bg-white shadow-md rounded p-2 mb-2">
                        <adaa-progress-indicator
                            [color]="agmMilestoneDetailsService.milestone()?.onTimeProgressColor!"
                            [value]="agmMilestoneDetailsService.milestone()?.progress!"
                            [label]="('common.tables.progress' | translate) + ': '"
                        />
                    </div>
                    <div class="col-12 bg-white shadow-md rounded">
                        <div class="row d-flex align-items-center">
                            <div class="col-md-6 col-sm-12 co-xs-12 d-flex flex-column align-items-center">
                                <h5>
                                    {{ "activities.budget" | translate }}:
                                    <strong>{{ agmMilestoneDetailsService.milestone()?.budget | currencyValue: true }}</strong>
                                </h5>
                                <h5>
                                    {{ "activities.expenses" | translate }}:
                                    <strong>{{ agmMilestoneDetailsService.milestone()?.expense | currencyValue: true }}</strong>
                                </h5>
                            </div>
                            <div class="col-md-6 col-sm-12 co-xs-12 d-flex flex-column align-items-center">
                                <adaa-doughnut
                                    [amount]="agmMilestoneDetailsService.milestone()?.budgetExpenditure!"
                                    [colorId]="Constants.CONSTANT_COLORS.EVALUATION_BLACK"
                                    [showProgressCircle]="false"
                                    [circleColor]="'#000'"
                                    [size]="'xxs'"
                                />
                                <h5>{{ "services.expenditures" | translate }}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- DESCRIPTION AND DATES -->
        <div class="col-12">
            <div class="grid gap-3">
                <div class="g-col-md-6 g-col-sm-12 g-col-xs-12 mb-3 bg-white shadow-md rounded p-2">
                    <h5 class="fw-bold">{{ "common.form.label.description" | translate }}</h5>
                    <adaa-read-more [maxLength]="300" [text]="agmMilestoneDetailsService.milestone() | translateToken: 'dsc'" />
                </div>
                <div class="g-col-md-6 g-col-sm-12 g-col-xs-12 mb-3 bg-white shadow-md rounded">
                    <div class="grid gap-3 p-3">
                        <div class="g-col-md-6 g-col-sm-12 g-col-xs-12 text-center date-container p-2">
                            <h5 class="text-primary fw-bold">{{ "national_projects.planned_start_date" | translate }}</h5>
                            <h6>{{ agmMilestoneDetailsService.milestone()?.startDate | date: Constants.normalDate }}</h6>
                        </div>
                        <div class="g-col-md-6 g-col-sm-12 g-col-xs-12 text-center date-container p-2">
                            <h5 class="text-primary fw-bold">{{ "national_projects.planned_end_date" | translate }}</h5>
                            <h6>{{ agmMilestoneDetailsService.milestone()?.endDate | date: Constants.normalDate }}</h6>
                        </div>
                        <div class="g-col-md-6 g-col-sm-12 g-col-xs-12 text-center date-container p-2">
                            <h5 class="text-primary fw-bold">{{ "national_projects.actual_start_date" | translate }}</h5>
                            <h6>
                                {{
                                    agmMilestoneDetailsService.milestone()?.actualStartDate
                                        ? (agmMilestoneDetailsService.milestone()?.actualStartDate | date: Constants.normalDate)
                                        : "-"
                                }}
                            </h6>
                        </div>
                        <div class="g-col-md-6 g-col-sm-12 g-col-xs-12 text-center date-container p-2">
                            <h5 class="text-primary fw-bold">{{ "national_projects.actual_end_date" | translate }}</h5>
                            <h6>
                                {{
                                    agmMilestoneDetailsService.milestone()?.actualEndDate
                                        ? (agmMilestoneDetailsService.milestone()?.actualEndDate | date: Constants.normalDate)
                                        : "-"
                                }}
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- SECTORS AND SIGN OFF -->
        <div class="col-12">
            <div class="grid gap-3">
                <div class="g-col-md-6 g-col-sm-12 g-col-xs-12 mb-3 bg-white shadow-md rounded p-2">
                    <h5 class="fw-bold">{{ "national_projects.national_sector.title" | translate }}</h5>
                    @for (item of agmMilestoneDetailsService.milestone()?.nationalSectors; track $index) {
                        <span class="badge rounded-pill bg-light text-dark shadow-sm mx-1 fs-5">{{ item | translateToken: "name" }}</span>
                    }
                </div>
                <div class="g-col-md-6 g-col-sm-12 g-col-xs-12 mb-3 bg-white shadow-md rounded p-2">
                    <div class="row">
                        <div class="col-12">
                            <h5 class="fw-bold">{{ "national_projects.sign_off.justification" | translate }}</h5>
                            <adaa-read-more [maxLength]="300" [text]="agmMilestoneDetailsService.milestone()?.signOff!" />
                        </div>

                        @if (agmMilestoneDetailsService.milestone()?.signOffAttachment?.id) {
                            <div class="col-12 mt-3">
                                <button
                                    class="btn btn-outline-primary"
                                    (click)="
                                        agmMilestoneDetailsService.downloadAttachment(agmMilestoneDetailsService.milestone()?.signOffAttachment!)
                                    "
                                >
                                    <fa-icon class="mx-1" [icon]="['fas', 'file-download']"></fa-icon>
                                    {{ agmMilestoneDetailsService.milestone()?.signOffAttachment | translateToken: "name" }}
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>

        <!-- ENTITIES AND NOTES -->
        <div class="col-12">
            <div class="grid gap-3">
                <div class="g-col-md-6 g-col-sm-12 g-col-xs-12 mb-3 bg-white shadow-md rounded p-2">
                    <h5 class="fw-bold">{{ "national_projects.contributor_entities" | translate }}</h5>
                    @for (item of contributingEntities; track $index) {
                        <span class="badge rounded-pill bg-light text-dark shadow-sm mx-1 fs-5">{{ item | translateToken: "entityName" }}</span>
                    }
                    <h5 class="fw-bold mt-4">{{ "other_entities.plural" | translate }}</h5>
                    @for (item of otherContributingEntities; track $index) {
                        <span class="badge rounded-pill bg-light text-dark shadow-sm mx-1 fs-5">{{ item | translateToken: "otherEntityName" }}</span>
                    }
                </div>
                <div class="g-col-md-6 g-col-sm-12 g-col-xs-12 mb-3 bg-white shadow-md rounded p-2">
                    <adaa-comments
                        classes="fs-5 fw-bold"
                        cardTitle="sidebar_tables.notes"
                        titleToken="name"
                        dateToken="createTS"
                        messageToken="note"
                        [comments]="agmMilestoneDetailsService.notes()"
                    />
                </div>
            </div>
        </div>

        <!-- ENTITIES AND NOTES -->
        <div class="col-12">
            <div class="grid gap-3">
                <div class="g-col-md-10 g-col-sm-12 g-col-xs-12 mb-3 bg-white shadow-md rounded p-2">
                    <adaa-comments
                        classes="fs-5 fw-bold"
                        cardTitle="national_projects.comments"
                        titleToken="createdByUserName"
                        dateToken="creationDate"
                        messageToken="comments"
                        [showNumber]="true"
                        [comments]="agmMilestoneDetailsService.milestone()?.comments"
                    />
                </div>
                <div class="g-col-md-2 g-col-sm-12 g-col-xs-12 mb-3 bg-white shadow-md rounded p-2">
                    <h5 class="fw-bold">{{ "helpdesk.attachment" | translate }}</h5>
                    @for (item of agmMilestoneDetailsService.milestone()?.attachments; track $index) {
                        <button class="btn btn-outline-primary mx-1" (click)="agmMilestoneDetailsService.downloadAttachment(item)">
                            <fa-icon class="mx-1" [icon]="['fas', 'file-download']"></fa-icon>
                            {{ item | translateToken: "name" }}
                        </button>
                    }
                </div>
            </div>
        </div>
    </div>

    <adaa-float-action (notesSaved)="agmMilestoneDetailsService.getNotes()" />
</ng-template>
