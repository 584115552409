<div class="d-flex gap-2">
    <p class="fw-bold fs-3">{{ "national_projects.national_sector.title" | translate }} -</p>
    <p class="fw-bold fs-3">{{ displayLabel() }}</p>
</div>

<div class="bg-white shadow-md rounded p-2 mb-3">
    <form class="col-12" [formGroup]="tpSectorForm">
        <div class="bg-white shadow-md rounded p-2 mb-3">
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.nameAE' | translate"
                        [setValidator]="tpSectorEditorService.formValidation()!"
                        [invalid]="checkInvalid('nameAE')"
                        formControlName="nameAE"
                        controlName="nameAE"
                    ></adaa-form-input>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.nameEN' | translate"
                        [setValidator]="tpSectorEditorService.formValidation()!"
                        [invalid]="checkInvalid('nameEN')"
                        formControlName="nameEN"
                        controlName="nameEN"
                    ></adaa-form-input>
                </div>
            </div>
        </div>
    </form>
</div>

<div class="col-12 mb-3">
    <adaa-form-action-buttons
        [cancelButtonTitle]="pageMode() === PageMode.view ? ('common.form.button.close' | translate) : ('common.form.button.cancel' | translate)"
        [showSaveButton]="pageMode() !== PageMode.view"
        [saveButtonTitle]="'executive_teams.submit' | translate"
        (saveClicked)="updateSector()"
        (cancelClicked)="cancelForm()"
    ></adaa-form-action-buttons>
</div>
