<h4 class="fw-bold my-3">{{ "provisioning.organization_structure" | translate }}</h4>

<div class="bg-white m-2 p-2">
    @if (selectedUnit()) {
        <div class="d-flex justify-content-end my-2" *adaaHasPermission="createPermission()">
            <button
                type="button"
                class="btn btn-sm bg-light text-primary rounded-circle shadow-sm"
                [title]="'tooltips.add' | translate"
                (click)="openEditor()"
            >
                <fa-icon [icon]="['fas', 'plus']"></fa-icon>
            </button>
        </div>
    }
    <table class="w-100">
        <thead class="table--header">
            <tr>
                <th class="blank">&nbsp;</th>
                <th class="level">{{ "entity.create.level" | translate }}</th>
                <th class="nane">{{ "common.tables.name" | translate }}</th>
                <th class="actions">
                    {{ "common.tables.actions" | translate }}
                </th>
            </tr>
        </thead>
    </table>
    <adaa-organization-structure
        [level]="1"
        [childs]="arrayOfOrgUnitsStructured()"
        (selectedUnit)="setSelectedUnit($event)"
        (actionClicked)="getAction($event)"
        [currentSelectedOrgUnit]="selectedUnit()?.id"
    />
</div>

<adaa-organization-unit-editor-modal
    #editor
    [pageMode]="pageMode()"
    [selectedUnit]="selectedUnit()"
    [parentOrgUnit]="parentOrgUnit()"
    [entity]="entity()"
    [orgUnitOptions]="orgUnitOptions()"
    (submitForm)="submit($event)"
/>
