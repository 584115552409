<div [class.me-4]="lang() === Language.Arabic" [class.ms-4]="lang() === Language.English">
    <adaa-ribbon
        [widgetData]="dashboardWidgets()"
        [showFilterIcon]="true"
        [filterApplied]="tpService.isFilterApplied"
        (refreshClicked)="tpService.resetFilters()"
        (filterClicked)="tpService.openFilters()"
    >
        <ng-container progressBars>
            <adaa-progress-indicator
                class="pointer"
                [color]="constants.CONSTANT_COLORS.EVALUATION_LIME"
                [value]="tpService.dashboardData()?.completedWidth!"
                [label]="('national_projects.completed_projects' | translate) + ': ' + tpService.dashboardData()?.completedCount"
                [showValue]="false"
                (click)="filterDashboard(TpAgmStatus.COMPLETED)"
            >
            </adaa-progress-indicator>
            <adaa-progress-indicator
                class="pointer"
                [color]="constants.CONSTANT_COLORS.EVALUATION_BLUE"
                [value]="tpService.dashboardData()?.ongoingWidth!"
                [label]="('national_projects.ongoing_projects' | translate) + ': ' + tpService.dashboardData()?.ongoingCount"
                [showValue]="false"
                (click)="filterDashboard(TpAgmStatus.ON_TIME)"
            >
            </adaa-progress-indicator>
            <adaa-progress-indicator
                class="pointer"
                [color]="constants.CONSTANT_COLORS.EVALUATION_GRAY"
                [value]="tpService.dashboardData()?.delayedWidth!"
                [label]="('national_projects.facing_delays' | translate) + ': ' + tpService.dashboardData()?.delayedCount"
                [showValue]="false"
                (click)="filterDashboard(TpAgmStatus.DELAYED)"
            >
            </adaa-progress-indicator>
        </ng-container>
    </adaa-ribbon>
</div>

<div class="row">
    <div class="col-12 d-flex flex-wrap justify-content-center">
        @for (item of tpService.dashboardData()?.nationalProjects; track $index) {
            <adaa-tp-project-card [project]="item" (cardClicked)="navigateToProject($event)"></adaa-tp-project-card>
        }
    </div>
</div>
