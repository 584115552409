@if (data()) {
    <div class="w-100 d-flex justify-content-between flex-wrap position-relative mt-2 py-2 kpi-details kpi-details--light">
        <div class="w-100 d-flex align-content-center flex-wrap kpi-details--item">
            <span class="d-block w-100 fw-bold px-2">
                {{ "sidebar_tables.executive_team" | translate }}
            </span>

            <span class="d-block w-100 px-2 py-2">
                <span class="text-info">{{ data() | translateToken: "name" }}</span>

                @for (item of data()?.members; track item.id) {
                    <span class="d-block w-100 mt-2">
                        {{ item | translateToken: "roleName" }}

                        <span class="d-block w-100 mt-1 text-primary">{{ item | translateToken: "name" }}</span>
                    </span>
                }
            </span>
        </div>
    </div>
}
