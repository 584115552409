import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";

import { hasPermissionGuard } from "../../core/guards";
import { handleRoutePermission } from "../../core/guards/guards-util";
import { AdaaHelper } from "../../core/utils";
import { Constants } from "../../shared/constants/constants";
import { PermissionAction } from "../../shared/constants/enums";
import { KpisService } from "../../shared/services";

export const isService = (itemType: number) => itemType === Constants.CONSTANT_SERVICE;
export const isObjective = (itemType: number) => itemType === Constants.CONSTANT_OBJECTIVETYPE;
export const isActivity = (itemType: number) => itemType === Constants.CONSTANT_ACTIVITYTYPE;
export const isInitiative = (itemType: number) => itemType === Constants.CONSTANT_INITIATIVE;
export const isOpm = (kpiType: number) => kpiType === Constants.CONSTANT_OPM;
export const isMtkpi = (kpiType: number) => kpiType === Constants.CONSTANT_MTKPITYPE;
export const isMokpi = (kpiType: number) => kpiType === Constants.CONSTANT_MOKPITYPE;
export const isDkpi = (kpiType: number) => kpiType === Constants.CONSTANT_UAEKPI;
export const isDtkpi = (kpiType: number) => kpiType === Constants.CONSTANT_DTKPITYPE;
export const isNtkpi = (kpiType: number) => kpiType === Constants.CONSTANT_NTKPITYPE;
export const isNkpi = (kpiType: number) => kpiType === Constants.CONSTANT_NKPITYPE;
export const isEkpi = (kpiType: number) => kpiType === Constants.CONSTANT_EKPI;
export const isSkpi = (kpiType: number) => kpiType === Constants.CONSTANT_SKPITYPE;
export const isSrvkpi = (kpiType: number) => kpiType === Constants.CONSTANT_SRVKPI;
export const isTpAggreementReadyToPublish = (objectType: number) =>
  objectType === Constants.CONSTANT_TP_AGREEMENT_READY_TO_PUBLISH;

export const kpiTypeViewAccessGuard = (state: ActivatedRouteSnapshot) => {
  const kpiType = state.params["kpiType"];
  return getKpiPermissionGuard(kpiType, PermissionAction.view);
};

export const kpiTypeEditAccessGuard = (state: ActivatedRouteSnapshot) => {
  const router = inject(Router);

  const kpiType = state.params["kpiType"];

  if (kpiType === "opm" || kpiType === "ekpi" || kpiType === "skpi") {
    const planStatus =
      AdaaHelper.getLocalStorage(Constants.localStorageKeys.currentPlan, { type: "prop", property: "status" }) ?? 0;

    return planStatus !== Constants.OBJECT_STATUS.ARCHIVED && planStatus !== Constants.OBJECT_STATUS.CLOSED
      ? getKpiPermissionGuard(kpiType, PermissionAction.manage)
      : router.createUrlTree(["/console/kpi"]);
  }

  return getKpiPermissionGuard(kpiType, PermissionAction.manage);
};

export const kpiTypeCreateAccessGuard = (state: ActivatedRouteSnapshot) => {
  const router = inject(Router);

  const kpiType = state.params["kpiType"];

  if (kpiType === "dtkpi" || kpiType === "ntkpi") {
    if (!AdaaHelper.isPMOEntity()) return router.createUrlTree(["/console/kpi"]);
    return getKpiPermissionGuard(kpiType, PermissionAction.create);
  } else if (kpiType === "srvkpi") {
    if (AdaaHelper.isPMOEntity()) return router.createUrlTree(["/console/kpi"]);
    return getKpiPermissionGuard(kpiType, PermissionAction.create);
  } else if (kpiType === "opm" || kpiType === "skpi") {
    const planStatus =
      AdaaHelper.getLocalStorage(Constants.localStorageKeys.currentPlan, { type: "prop", property: "status" }) ?? 0;

    return planStatus !== Constants.OBJECT_STATUS.ARCHIVED && planStatus !== Constants.OBJECT_STATUS.CLOSED
      ? getKpiPermissionGuard(kpiType, PermissionAction.create)
      : router.createUrlTree(["/console/kpi"]);
  } else if (kpiType === "ekpi") {
    const planStatus =
      AdaaHelper.getLocalStorage(Constants.localStorageKeys.currentPlan, { type: "prop", property: "status" }) ?? 0;

    return AdaaHelper.isPMOEntity() &&
      planStatus !== Constants.OBJECT_STATUS.ARCHIVED &&
      planStatus !== Constants.OBJECT_STATUS.CLOSED
      ? getKpiPermissionGuard(kpiType, PermissionAction.create)
      : router.createUrlTree(["/console/kpi"]);
  }

  return getKpiPermissionGuard(kpiType, PermissionAction.create);
};

const getKpiPermissionGuard = (kpiType: string, permissionAction: PermissionAction) => {
  const kpisService = inject(KpisService);
  const permissionId = kpisService.getKpiPermission(kpisService.getKpiTypeId(kpiType));

  return handleRoutePermission({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: permissionAction,
          objectTypeId: permissionId,
        },
      ],
    },
  });
};

export const auditIssuesViewAccessGuard = [
  hasPermissionGuard({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.view,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.AUDIT_RESULTS,
        },
      ],
    },
    redirectTo: "/403",
  }),
];

export const viewImportExportKpiPagesGuards = [
  hasPermissionGuard({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.execute,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.EXPORT_DATA,
        },
      ],
    },
    redirectTo: "/403",
  }),
];
