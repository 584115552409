import { Component, computed, inject, type OnDestroy, type OnInit, signal } from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { Subscription } from "rxjs";

import { AdaaHelper, useNewDS } from "../../../core/utils";
import {
  AggregatedPerformanceComponent,
  GovEnablerPerformanceComponent,
  GovVisionPerformanceComponent,
  NationalStrategiesPerformanceComponent,
  PeriodSliderComponent,
  UaeVisionPerformanceComponent,
} from "../../../shared/components";
import { Constants } from "../../../shared/constants/constants";
import type { SelectedPeriodModelType } from "../../../shared/models";
import {
  AggregationPerfService,
  AppService,
  GovEnablerPerfService,
  NationalStrategiesPerfService,
  SystemLayoutService,
  UaeVisionPerfService,
} from "../../../shared/services";

@Component({
  selector: "adaa-pmo",
  standalone: true,
  templateUrl: "./pmo.component.html",
  styleUrl: "./pmo.component.scss",
  providers: [AggregationPerfService],
  imports: [
    RouterLink,
    TranslateModule,
    PeriodSliderComponent,
    AggregatedPerformanceComponent,
    UaeVisionPerformanceComponent,
    GovEnablerPerformanceComponent,
    NationalStrategiesPerformanceComponent,
    GovVisionPerformanceComponent,
  ],
})
export class PmoComponent implements OnInit, OnDestroy {
  readonly systemLayoutService = inject(SystemLayoutService);
  private readonly _router = inject(Router);
  private readonly _appService = inject(AppService);
  private readonly _nationalStrategiesPerfService = inject(NationalStrategiesPerfService);
  private readonly _govEnablerPerfService = inject(GovEnablerPerfService);
  private readonly _uaeVisionPerfService = inject(UaeVisionPerfService);
  private readonly _aggregationPerfService = inject(AggregationPerfService);

  selectedPeriod = signal<SelectedPeriodModelType | undefined>(undefined);

  aggregationPerf = computed(() => this._aggregationPerfService.aggregationPerf());
  hasKPIsWithIssue = computed(() => this._aggregationPerfService.hasKPIsWithIssue());

  readonly frequency = Constants.FREQUENCY.QUARTERLY;
  readonly useNewDS = () => useNewDS();

  private readonly _subscriptions = new Subscription();

  public get numOfAggTypes() {
    return this.aggregationPerf().length;
  }

  public ngOnInit() {
    this._updateActiveEntity();
    this._refresh();
  }

  public ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  public selectPeriod(data: SelectedPeriodModelType) {
    if (!data || !this._appService.hasPlanId()) {
      return;
    }

    const { period, type } = data;
    if (type === "quarter") {
      this._aggregationPerfService.pmoQuarterlyPerformance(period.id).subscribe();
      this._uaeVisionPerfService.getQuarterlyVisionDirections(period.id);
      this._govEnablerPerfService.getQuarterlyGovEnablerPillars(period.id);
      this._nationalStrategiesPerfService.getQuarterlyGovEnablerDirections(period.id);
    } else if (type === "year") {
      this._aggregationPerfService.pmoAnnualPerformance(period.year).subscribe();
      this._uaeVisionPerfService.getAnnualVisionDirections(period.year);
      this._govEnablerPerfService.getAnnualGovEnablerPillars(period.year);
      this._nationalStrategiesPerfService.getAnnualGovEnablerDirections(period.year);
    }
    this._aggregationPerfService.getCountAuditIssues(period.id, Constants.CONSTANT_PMO_ID).subscribe();
    this.selectedPeriod.set({ period, type });
  }

  private _updateActiveEntity() {
    const entityId = AdaaHelper.getLocalStorage(Constants.localStorageKeys.currentEntity, {
      type: "prop",
      property: "id",
    }) as number | undefined;

    if (!entityId) return;

    if (entityId !== Constants.CONSTANT_PMO_ID) {
      this._router.navigateByUrl("/dashboard/entity");
    }
  }

  private _refresh() {
    this._subscriptions.add(
      this.systemLayoutService.hasActiveEntityChanged$.subscribe(() => {
        this._updateActiveEntity();
      })
    );

    this._subscriptions.add(
      this.systemLayoutService.hasCycleChanged$.subscribe({
        next: () => window.location.reload(),
      })
    );
  }
}
