<div class="card bg-white w-100 px-2 h-100 entity-card" [style.border-right-color]="borderRightColor()">
    <div class="card-title d-flex justify-content-center align-items-center align-content-center py-1">
        @if (data().logoHorizontal) {
            <adaa-picture width="250px" [pictureId]="data().logoHorizontal" [attr.title]="data() | translateToken: 'name'" />
        } @else {
            <h6 class="fw-bold w-100 text-center text-primary">{{ data() | translateToken: "name" }}</h6>
        }
    </div>

    <div class="card-body d-flex justify-content-center px-1 py-2">
        <div class="w-50" [class.pe-2]="!isRTL()" [class.ps-2]="isRTL()">
            <adaa-progress-indicator class="w-100" [height]="22" [color]="data().annualScoreColor!" [value]="data().annualScore!" />

            <small class="text-center text-muted">{{ "landingPage.entity_page.annual_performance" | translate }}</small>
        </div>

        <div class="w-50" [class.ps-2]="!isRTL()" [class.pe-2]="isRTL()">
            <adaa-progress-indicator class="w-100" [height]="22" [color]="data().annualVisionScoreColor!" [value]="data().annualVisionScore!" />

            <small class="text-center text-muted">{{ "landingPage.entity_page.overall_performance" | translate }}</small>
        </div>
    </div>

    <div class="card-footer d-flex justify-content-center align-items-center pb-3">
        <i class="adaa-icon-console_projectTargets fs-5 mx-2"></i>
        <span class="fs-6"> {{ data().ntKpiCount > 0 ? data().ntKpiCount : "-" }} {{ "national_agenda.main_dashboard.ntkpis" | translate }} </span>
    </div>
</div>
