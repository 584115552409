<div class="d-flex justify-content-end w-100">
    <div class="align" [class.ae]="lang() === Language.Arabic">
        <adaa-form-checkbox
            controlName="national-strategies-dashboard-show-all"
            [boldLabel]="true"
            [label]="'national_strategies.show_all_national_strategies' | translate"
            (inputChanges)="togglePeriodSelector($event)"
        />
    </div>
</div>

<div class="ms-1 me-3">
    @if (!showAllStrategies()) {
        @if (dashboardData(); as data) {
            <adaa-period-slider-variant
                #periodSliderVariant
                [_stepRange]="4"
                [_endDate]="endTS()"
                [_startDate]="startTS()"
                [_frequency]="periodSliderFrequency"
                [showChartCycle]="false"
                [boldYears]="true"
                [showMonths]="false"
                [emitOnInit]="false"
                [activePeriod]="selectedPeriod()!"
                (selectPeriod)="selectPeriod($event, periodSliderVariant)"
            />
        }
    }
</div>

<div class="ribbon" [class.ar]="lang() === Language.Arabic">
    <adaa-ribbon
        class="no-border-first-item"
        [showLogo]="true"
        [showFilterIcon]="!showAllStrategies()"
        [widgetData]="dashboardWidgets()"
        [filterApplied]="isFilterApplied()"
        (refreshClicked)="reset()"
        (filterClicked)="openFilters(content)"
    >
        <ng-container ribbonLogo>
            <img
                class="ribbon-logo"
                src="assets/images/national-strategies/national-strategy.png"
                [alt]="'national_strategies.strategies' | translate"
            />
        </ng-container>
    </adaa-ribbon>
</div>

<div class="position-relative d-flex align-content-start align-items-start dashboard-content py-2 mx-0" [class.mx-2]="lang() === Language.Arabic">
    <div class="dashboard-content--progress">
        @for (progress of dashboardProgress(); track $index) {
            <div class="card d-flex justify-content-center align-items-center bg-white my-3 py-1">
                <adaa-doughnut size="xs" [amount]="progress.amount!" [colorId]="progress.color!" [description]="progress.label | translate" />
                <h6 class="fw-bold fs-6 text-center px-1">{{ progress.label | translate }}</h6>
            </div>
        }

        <div class="card d-flex justify-content-center align-items-center bg-white mt-4 py-3 px-2">
            <!-- Completed national strategies count -->
            <div class="bar-item pointer-cursor w-100 mb-2" (click)="filterData('COMPLETED')">
                <span class="mb-2">
                    <span>
                        {{ "national_strategies.dashboard.finished_national_strategies" | translate }}
                    </span>

                    <strong class="margin-left-10 margin-right-10">
                        {{ dashboardData()?.countFinishedNationalStrategies }}
                    </strong>
                </span>

                <div [style.background-color]="'rgb(199, 234, 70, .3)'" class="progress skill-bar relative">
                    <div
                        [style.background-color]="'rgb(199, 234, 70)'"
                        [style.width]="
                            (+dashboardData()?.countFinishedNationalStrategies! / +(dashboardData()?.nationalStrategies)!.length) * 100 + '%'
                        "
                        style="height: 20px"
                        aria-valuemax="100"
                        aria-valuemin="0"
                        aria-valuenow="20"
                        class="progress-bar progress-bar-danger"
                        role="progressbar"
                    >
                        <div
                            [class.text-left]="languageService.current() === 'en'"
                            [class.text-right]="languageService.current() === 'ae'"
                            class="skill"
                        ></div>
                    </div>
                </div>
            </div>
            <!-- END -->

            <!-- On going national strategies count -->
            <div class="bar-item pointer-cursor w-100 mt-4 mb-2" (click)="filterData('ON_GOING')">
                <span class="mb-2">
                    <span>
                        {{ "national_strategies.dashboard.active_national_strategies" | translate }}
                    </span>

                    <strong class="margin-left-10 margin-right-10">
                        {{ dashboardData()?.countActiveNationalStrategies }}
                    </strong>
                </span>

                <div [style.background-color]="'rgb(0, 163, 232, .3)'" class="progress skill-bar relative">
                    <div
                        [style.background-color]="'rgb(0, 163, 232)'"
                        [style.width]="
                            (+dashboardData()?.countActiveNationalStrategies! / +(dashboardData()?.nationalStrategies)!.length) * 100 + '%'
                        "
                        style="height: 20px"
                        aria-valuemax="100"
                        aria-valuemin="0"
                        aria-valuenow="20"
                        class="progress-bar progress-bar-danger"
                        role="progressbar"
                    >
                        <div
                            [class.text-left]="languageService.current() === 'en'"
                            [class.text-right]="languageService.current() === 'ae'"
                            class="skill"
                        ></div>
                    </div>
                </div>
            </div>
            <!-- END -->
        </div>
    </div>

    <div class="d-flex justify-content-center align-items-start flex-wrap p-3 dashboard-content--main">
        @for (strategy of dashboardData()?.nationalStrategies; track strategy.id) {
            <adaa-strategy-card
                [strategy]="strategy"
                [routerLink]="'/national-strategies-dashboard/strategy/' + strategy.id"
                [showAllStrategies]="showAllStrategies()"
            />
        }
    </div>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title">{{ "common.tables.filter_modal" | translate }}</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.close()"></button>
    </div>

    <div class="modal-body" style="height: 130px">
        <adaa-form-dropdown
            appendTo=".modal-content"
            [label]="'helpdesk.status' | translate"
            [setDefaultValue]="selectedStatus()"
            [options]="statusOptions()"
            (inputChanges)="selectedStatus.set($event)"
        />
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="modal.close()">
            {{ "common.form.button.cancel" | translate }}
        </button>
        <button type="button" class="btn btn-primary" (click)="reset()">
            {{ "common.form.button.reset" | translate }}
        </button>
        <button type="button" class="btn btn-primary" (click)="apply()">
            {{ "common.form.button.apply" | translate }}
        </button>
    </div>
</ng-template>
