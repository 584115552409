<section class="d-flex flex-wrap row mt-0 mb-3 py-2" [dir]="languageService.direction()">
    <div class="col-12 text-center py-2">
        <span class="fw-bold">
            {{ "kpi.formula" | translate }} <span class="text-danger">{{ required() ? "*" : "" }}</span>
        </span>
    </div>

    <div class="position-relative d-block col-12">
        <div class="kpi-formula d-flex position-relative justify-content-center w-100 flex-wrap" [ngClass]="[formulaState()]">
            <span
                class="d-flex align-items-center justify-content-center align-content-center kpi-formula--text w-100"
                [ngClass]="[formulaState()]"
                [dir]="'ltr'"
            >
                @if (isNoFormula()) {
                    {{ "dimension.no_formula" | translate | uppercase }}
                } @else {
                    {{ formula() }}
                }
            </span>

            <button
                class="btn position-relative px-4 kpi-formula--validate my-1"
                [ngClass]="[formulaState()]"
                [disabled]="disableFormulaValidation()"
                (click)="validateFormula(formula()!)"
            >
                {{ buttonLabel() | translate }}
            </button>

            @if (showComprehensiveFormula()) {
                <div class="position-relative d-block col-12 py-2 px-4">
                    <span
                        class="px-3 py-2 my-1 w-auto d-block position-relative rounded shadow-sm show-formula text-center"
                        [ngClass]="[formulaState()]"
                        [innerHTML]="comprehensiveFormula() | safeHtml"
                    ></span>
                </div>
            }
        </div>
    </div>
</section>
