import { Routes } from "@angular/router";

import { PingGuard } from "./core/guards";
import { AuthenticatedPage } from "./core/utils/authenticated-page";
import {
  AUTH_ROUTES,
  CONSOLE_ROUTES,
  DASHBOARD_ROUTES,
  EMPLOYEE_PULSE_ROUTES,
  NATIONAL_STRATEGIES_DASHBOARD_ROUTES,
  PLAN_NAVIGATION_ROUTES,
  PROFILE_ROUTES,
  REPORT_ROUTES,
  TP_DASHBOARD_ROUTES,
  WORKFLOWS_ROUTES,
} from "./pages";
import { AGM_DASHBOARD_ROUTES } from "./pages/agm/agm.routes";
import { NOTIFICATION_CENTER_ROUTES } from "./pages/notification-center/notification-center.routes";
import { PermissionDeniedComponent } from "./pages/permission-denied/permission-denied.component";
import { SUPPORT_CENTER_ROUTES } from "./pages/support-center/support-center.routes";

export const routes: Routes = [
  ...AUTH_ROUTES,
  /**
   * @note Routes that need user to be authenticated
   */
  {
    path: "",
    component: AuthenticatedPage,
    canActivateChild: [PingGuard()],
    children: [
      {
        path: "",
        pathMatch: "full",
        redirectTo: "dashboard/target",
      },
      ...CONSOLE_ROUTES,
      ...DASHBOARD_ROUTES,
      ...TP_DASHBOARD_ROUTES,
      ...AGM_DASHBOARD_ROUTES,
      ...REPORT_ROUTES,
      ...PROFILE_ROUTES,
      ...NATIONAL_STRATEGIES_DASHBOARD_ROUTES,
      ...EMPLOYEE_PULSE_ROUTES,
      ...WORKFLOWS_ROUTES,
      ...PLAN_NAVIGATION_ROUTES,
      ...SUPPORT_CENTER_ROUTES,
      ...NOTIFICATION_CENTER_ROUTES,
    ],
  },
  // TODO: Add 500 page - title: "server_error.title"
  // TODO: Add 404 page - title: "page_not_found.title"
  {
    path: "403",
    component: PermissionDeniedComponent,
    data: {
      title: "access_denied.title",
    },
  },
  {
    path: "**",
    redirectTo: "/console",
    pathMatch: "full",
  },
];
