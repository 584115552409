<adaa-auth-layout>
    @if (!isUserDefined) {
        <div class="row">
            @if (errorMessage) {
                <h5 class="col-12 mt-2 text-danger">{{ errorMessage | translate }}</h5>
            } @else {
                <h5 class="col-12 mt-2 text-danger">{{ "login.user_not_found" | translate }}</h5>
                <h6 class="col-12 text-danger">{{ "login.user_not_found_instructions" | translate }}</h6>
            }
            <i class="fs-1 fa-solid fa-x text-danger"></i>
            <span role="button" class="text-info mb-4" [routerLink]="'/login'">{{ "login.go_back" | translate }}</span>
        </div>
    } @else if (isUserDefined) {
        <form [formGroup]="newPasswordForm" class="row mt-2">
            @if (currentStep === 1) {
                <section>
                    <h5 class="col-12 mt-2 text-info">{{ adaaHelper.getItemValueByToken(user, "name") }}</h5>
                    <h6 class="col-12 text-info">{{ user?.email }}</h6>
                    <span class="col-12 text-primary">{{ "login.text_select_identity" | translate }}</span>
                    <div class="col-12 mb-3">
                        <adaa-form-radio
                            [setDefaultValue]="verificationMethod.SMS"
                            [options]="resetPasswordOptions"
                            controlName="verificationMethod"
                            (inputChanges)="resetPasswordOptionsUpdated($event)"
                        >
                        </adaa-form-radio>
                    </div>
                    <div class="col-12 mb-3">
                        @if (verificationMethodValue === verificationMethod.SMS) {
                            <div class="mb-3">
                                @if (lockCounter === 0) {
                                    <button class="btn btn-info" (click)="sendVerificationCode()">
                                        {{ "common.form.button.send" | translate }}
                                    </button>
                                } @else if (lockCounter > 0) {
                                    <button [disabled]="true" class="btn btn-info">{{ "login.resendCode" | translate }} - {{ lockCounter }}</button>
                                }

                                <adaa-form-input
                                    [required]="true"
                                    [placeholder]="'login.confirmation_code' | translate"
                                    controlName="verificationCode"
                                    formControlName="verificationCode"
                                    inputType="positiveNumber"
                                    [maxLength]="999999"
                                    [invalid]="newPasswordForm.get('verificationCode')?.errors?.['required']"
                                ></adaa-form-input>
                            </div>
                        } @else if (verificationMethodValue === verificationMethod.QUESTIONS) {
                            <div class="mb-3">
                                <adaa-form-input
                                    class="col-12"
                                    [placeholder]="'login.answer' | translate"
                                    [label]="adaaHelper.getItemValueByToken(questions[0], 'dsc')"
                                    controlName="firstQuestion"
                                    formControlName="firstQuestion"
                                    inputType="text"
                                    [invalid]="newPasswordForm.get('firstQuestion')?.errors?.['required']"
                                ></adaa-form-input>
                                <adaa-form-input
                                    class="col-12"
                                    [placeholder]="'login.answer' | translate"
                                    [label]="adaaHelper.getItemValueByToken(questions[1], 'dsc')"
                                    controlName="firstQuestion"
                                    formControlName="secondQuestion"
                                    inputType="text"
                                    [invalid]="newPasswordForm.get('secondQuestion')?.errors?.['required']"
                                ></adaa-form-input>
                            </div>
                        }

                        <button type="button" (click)="validateAccount()" class="btn btn-info mb-4">
                            {{ "login.confirm" | translate }}
                        </button>
                    </div>
                </section>
            } @else if (currentStep === 2) {
                <section>
                    <h5 class="col-12 mb-1 text-info">{{ "login.create_password" | translate }}</h5>
                    <h5 class="col-12 mb-3 text-info">{{ "new_password.error.error_message" | translate }}</h5>
                    <h6 class="col-12 mb-0 text-danger">{{ "new_password.error.check_english_letters" | translate }}</h6>
                    <h6 class="col-12 mb-0 text-danger">{{ "new_password.error.check_numbers" | translate }}</h6>
                    <h6 class="col-12 mb-3 text-danger">{{ "new_password.error.check_password_length" | translate }}</h6>

                    <adaa-form-input
                        [required]="true"
                        [placeholder]="'login.password' | translate"
                        controlName="password"
                        formControlName="password"
                        inputType="password"
                        [invalid]="newPasswordForm.get('password')?.errors?.['required']"
                    ></adaa-form-input>

                    <adaa-form-input
                        [required]="true"
                        [placeholder]="'login.password_confirm' | translate"
                        controlName="confirmPassword"
                        formControlName="confirmPassword"
                        inputType="password"
                        [invalid]="newPasswordForm.get('confirmPassword')?.errors?.['required']"
                    ></adaa-form-input>

                    <button type="button" (click)="validatePassword()" class="btn btn-info mt-3 mb-4">
                        {{ "login.confirm" | translate }}
                    </button>
                </section>
            }
        </form>
    }
</adaa-auth-layout>
