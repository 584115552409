import { Component, inject, input, OnInit, viewChild } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { ConfirmationModalComponent, DataTableComponent, FloatActionComponent } from "../../../../shared/components";
import { genericFloatButtons } from "../../../../shared/components/general/float-action";
import { Constants } from "../../../../shared/constants/constants";
import { Language } from "../../../../shared/constants/enums";
import { TableButtonClicked } from "../../../../shared/models";
import { AppService } from "../../../../shared/services";
import { TransformationalTargetsApiService } from "../../../../shared/services/transformational-targets-api.service";

@Component({
  selector: "adaa-transformational-targets-list",
  standalone: true,
  imports: [DataTableComponent, FloatActionComponent],
  templateUrl: "./transformational-targets-list.component.html",
  styleUrl: "./transformational-targets-list.component.scss",
})
export class TransformationalTargetsListComponent implements OnInit {
  private _modalService = inject(NgbModal);
  private _appService = inject(AppService);
  private _toastrService = inject(ToastrService);
  private _translateService = inject(TranslateService);
  private _tTargetsApiService = inject(TransformationalTargetsApiService);
  private _router = inject(Router);

  tableConfList = input.required<string>();

  private readonly _floatActions = genericFloatButtons();
  adaaDataTable = viewChild.required<DataTableComponent>("adaaDataTable");
  Constants = Constants;

  public ngOnInit(): void {
    this._floatActions([
      {
        key: "helpdesk",
        data: {
          url: "/helpdesk/create",
        },
      },
      {
        key: "print",
        data: undefined,
      },
    ]);
  }

  public addNewClicked() {
    this._router.navigateByUrl(`/console/national-project/transformational-targets/create`);
  }

  public getAction(event: TableButtonClicked) {
    const targetId = event.data.id;
    switch (event.event) {
      case "view":
        this._router.navigateByUrl(`/console/national-project/transformational-targets/${targetId}`);
        break;
      case "edit":
        this._router.navigateByUrl(`/console/national-project/transformational-targets/edit/${targetId}`);
        break;
      case "delete":
        this._deleteTarget(targetId);
        break;

      default:
        break;
    }
  }
  private _deleteTarget(targetId: number) {
    const modal = this._modalService.open(ConfirmationModalComponent, {
      centered: true,
      size: "md",
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });

    modal.componentInstance.header = "national_projects.transformational_targets.delete_confirmation_title";
    modal.componentInstance.title = "national_projects.transformational_targets.delete_confirmation_msg";

    modal.result.then((e) => {
      if (e) {
        this._tTargetsApiService.deleteTTarget(targetId).subscribe({
          next: (response) => {
            if (response.inError) return;
            this._toastrService.success(this._translateService.instant("notification.success.remove"));
            this.adaaDataTable().loadTableData();
          },
        });
      }
    });
  }
}
