import { hasPermissionGuard } from "../../core/guards";
import { Constants } from "../../shared/constants/constants";
import { PermissionAction } from "../../shared/constants/enums";

export const viewAgmProjectPageGuards = [
  hasPermissionGuard({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.view,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.ANNUAL_MEETINGS_PROJECTS,
        },
      ],
    },
    redirectTo: "/403",
  }),
];

export const createAgmProjectPageGuards = [
  hasPermissionGuard({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.create,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.ANNUAL_MEETINGS_PROJECTS,
        },
      ],
    },
    redirectTo: "/403",
  }),
];

export const manageAgmProjectPageGuards = [
  hasPermissionGuard({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.manage,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.ANNUAL_MEETINGS_PROJECTS,
        },
      ],
    },
    redirectTo: "/403",
  }),
];

export const viewAgmMilestonePageGuards = [
  hasPermissionGuard({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.view,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.ANNUAL_MEETINGS_PROJECTS_MILESTONES,
        },
      ],
    },
    redirectTo: "/403",
  }),
];

export const viewAgmCyclePageGuards = [
  hasPermissionGuard({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.view,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.ANNUAL_MEETINGS_PROJECTS_CYCLES,
        },
      ],
    },
    redirectTo: "/403",
  }),
];

export const createAgmCyclePageGuards = [
  hasPermissionGuard({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.create,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.ANNUAL_MEETINGS_PROJECTS_CYCLES,
        },
      ],
    },
    redirectTo: "/403",
  }),
];

export const manageAgmCyclePageGuards = [
  hasPermissionGuard({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.manage,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.ANNUAL_MEETINGS_PROJECTS_CYCLES,
        },
      ],
    },
    redirectTo: "/403",
  }),
];

export const viewAgmSubMenu = [
  hasPermissionGuard({
    actor: {
      modifier: "or",
      permissions: [
        {
          permissionAction: PermissionAction.view,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.ANNUAL_MEETINGS_PROJECTS,
        },
        {
          permissionAction: PermissionAction.view,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.ANNUAL_MEETINGS_PROJECTS_CYCLES,
        },
        {
          permissionAction: PermissionAction.view,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.ANNUAL_MEETINGS_PROJECTS_CYCLES,
        },
      ],
    },
    redirectTo: "/403",
  }),
];
