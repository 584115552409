import { Component, inject, OnInit, viewChild } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { AdaaHelper } from "../../../../core/utils";
import {
  ConfirmationModalComponent,
  DataTableComponent,
  FloatActionComponent,
  ItemsOrderModalComponent,
} from "../../../../shared/components";
import { genericFloatButtons } from "../../../../shared/components/general/float-action";
import { Constants } from "../../../../shared/constants/constants";
import { CustomButton, GovScope, TableActionEvent, ValueText } from "../../../../shared/models";
import { GovScoresScopesApiService, LanguageService, SystemLayoutService } from "../../../../shared/services";

@Component({
  selector: "adaa-government-scopes-list",
  standalone: true,
  imports: [DataTableComponent, FloatActionComponent],
  templateUrl: "./government-scopes-list.component.html",
  styleUrl: "./government-scopes-list.component.scss",
})
export class GovernmentScopesListComponent implements OnInit {
  private _router = inject(Router);
  private _modalService = inject(NgbModal);
  private _toastrService = inject(ToastrService);
  private _translateService = inject(TranslateService);
  private _languageService = inject(LanguageService);
  private _govScoresScopesApiService = inject(GovScoresScopesApiService);
  private _systemLayoutService = inject(SystemLayoutService);

  private _scopesTable = viewChild<DataTableComponent>("scopesTable");

  private readonly _untilDestroy = AdaaHelper.untilDestroyed();
  private readonly _genericFloatButtons = genericFloatButtons();
  constants = Constants;
  customButtons: CustomButton[] = [
    {
      eventName: "sort",
      iconTitle: "navigation.tooltips.order_tooltip",
      iconName: "adaa-icon-sort",
    },
  ];

  public ngOnInit(): void {
    this._floatActions();
    this._systemLayoutService.hasActiveEntityChanged$.pipe(this._untilDestroy()).subscribe({
      next: () => {
        this._router.navigateByUrl("/console");
      },
    });
  }

  public addNewClicked(): void {
    this._router.navigateByUrl("/console/government-scores/scopes/create");
  }

  public actionClicked(event: TableActionEvent): void {
    switch (event.event) {
      case "view":
        this._router.navigateByUrl(
          `/console/government-scores/pillars/${event.data.entityId}?redirectUrl=${this._router.url}`
        );
        break;
      case "sort":
        this._getScopesByPillar(event.data.id);
        break;
      default:
        break;
    }
  }

  public childClicked(event: TableActionEvent): void {
    switch (event.event) {
      case "view":
        this._router.navigateByUrl(`/console/government-scores/scopes/${event.data.id}`);
        break;
      case "edit":
        this._router.navigateByUrl(`/console/government-scores/scopes/edit/${event.data.id}`);
        break;
      case "delete":
        this._deleteScope(event.data.id);
        break;
      default:
        break;
    }
  }

  private _deleteScope(id: number): void {
    const modal = this._modalService.open(ConfirmationModalComponent, {
      centered: true,
      size: "md",
      modalDialogClass: this._languageService.modalDirection(),
    });

    modal.componentInstance.header = "government_scores.scopes.delete_title";
    modal.componentInstance.title = "government_scores.scopes.delete_confirm";

    modal.result.then((e) => {
      if (e) this._deleteScopeConfirmed(id);
    });
  }

  private _deleteScopeConfirmed(id: number) {
    this._govScoresScopesApiService.deleteScope(id).subscribe({
      next: (response) => {
        if (response.inError) return;

        this._toastrService.success(this._translateService.instant("notification.success.remove"));
        this._scopesTable()?.loadTableData();
      },
    });
  }

  private _getScopesByPillar(pillarId: number): void {
    this._govScoresScopesApiService.getScopesByPillarId(pillarId).subscribe({
      next: (response) => {
        if (response.inError) return;
        this._sortScopes(response.responseData);
      },
    });
  }

  private _sortScopes(scopes: GovScope[]): void {
    const modal = this._modalService.open(ItemsOrderModalComponent, {
      centered: true,
      size: "xl",
      modalDialogClass: this._languageService.modalDirection(),
    });

    const data: ValueText[] = [];

    scopes.forEach((e) => data.push({ value: e.id, text: AdaaHelper.getItemValueByToken(e, "name") }));

    modal.componentInstance.data = data;
    modal.componentInstance.inputLabel = "government_scores.scopes.title";

    modal.result.then((result: number[] | undefined) => {
      if (!result) return;
      scopes.forEach((scope) => {
        const index = result.findIndex((e) => e === scope.id);
        scope.displayOrder = index + 1;
      });

      this._saveSortedScopes(scopes);
    });
  }

  private _saveSortedScopes(scopes: GovScope[]): void {
    this._govScoresScopesApiService.sortScopes(scopes).subscribe({
      next: (response) => {
        if (response.inError) return;

        this._toastrService.success(this._translateService.instant("notification.success.save"));
        this._scopesTable()?.resetFilters();
      },
    });
  }

  private _floatActions(): void {
    this._genericFloatButtons([
      {
        key: "helpdesk",
        data: {
          url: "/helpdesk/create",
        },
      },

      {
        key: "print",
        data: undefined,
      },
    ]);
  }
}
