import { CommonModule } from "@angular/common";
import { Component, EventEmitter, forwardRef, inject, Input, OnInit, Output } from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";

import { AdaaHelper } from "../../../../core/utils";
import { PageMode } from "../../../constants/enums";
import type { ParameterCatalog } from "../../../models";
import { FormulasApiService } from "../../../services";

@Component({
  selector: "adaa-form-input-formula",
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TranslateModule],
  templateUrl: "./form-input-formula.component.html",
  styleUrl: "./form-input-formula.component.scss",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FormInputFormulaComponent),
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => FormInputFormulaComponent),
    },
  ],
})
export class FormInputFormulaComponent implements OnInit, ControlValueAccessor {
  inputControl: FormControl = new FormControl();
  adaaHelper = AdaaHelper;
  controlType: string = "text";
  PageMode = PageMode;

  private _onValidatorChange: () => void;
  private _onTouched: () => void;
  private _onChange: (value: string) => void;

  @Input() required: boolean = false;
  @Input() pageMode: string = "";
  @Input() invalid: boolean = false;
  @Input() label: string = "";
  @Input() placeholder: string = "";
  @Input() isDisabled: boolean = false;
  @Input() inputType: "text" | "number" | "positiveNumber" | "email" | "password" = "text";
  @Input() maxLength: number | null = null;
  @Input() controlName: string | null = null;
  @Input() controlId: string | null = null;
  @Input() rows: number = 5;
  @Input() info: string;
  output: string;
  correct: boolean | null;

  @Input()
  public set setDefaultValue(value: string) {
    if (value) {
      this.writeValue(value);
    }
  }

  @Input()
  public set setValidator(validatorList: { parameterCatalogs: ParameterCatalog[] }) {
    if (validatorList) {
      if (validatorList.parameterCatalogs) {
        const field = validatorList.parameterCatalogs.find((e) => e.fieldName === this.controlName);

        if (field) {
          this.required = field.mandatory === "Y";
          this._updateValidation();
        }
      }
    }
  }

  @Output() inputChanges = new EventEmitter();
  @Output() isFormulaValid = new EventEmitter();

  private _formulasTemplateService = inject(FormulasApiService);

  public ngOnInit(): void {
    if (!this.adaaHelper.isDefined(this.controlId)) this.controlId = this.controlName;
    this.controlType = this.inputType === "positiveNumber" ? "number" : this.inputType;
    this._updateValidation();
  }

  public writeValue(obj: string | number): void {
    if (obj === null) {
      this.inputControl.reset();
    }

    if (this.inputType === "positiveNumber" && typeof obj === "number") {
      obj = isNaN(Number(obj)) ? 0 : Math.abs(+obj);
    }

    this.inputControl.setValue(obj);
  }

  public registerOnChange(fn: (value: string) => void): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  public registerOnValidatorChange?(fn: () => void): void {
    this._onValidatorChange = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.inputControl.disable() : this.inputControl.enable();
  }

  public validate(_value: FormControl): false | { required: boolean } {
    const isRequired =
      (this.required && this.inputControl.value === null) || (this.required && this.inputControl.value === "");
    return (
      isRequired && {
        required: true,
      }
    );
  }

  private _updateValidation(): void {
    const validations = [];

    switch (this.inputType) {
      case "number":
      case "positiveNumber":
        validations.push(Validators.pattern("^-?[0-9]\\d*(\\.\\d{1,2})?$"));
        break;
      case "email":
        validations.push(Validators.email);
        break;
      default:
        break;
    }

    this.inputControl.setValidators([...validations]);
    this.inputControl.updateValueAndValidity();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public valueChanged(event: any): void {
    if (this._onChange) this._onChange(event);

    this.correct = null;
    this.isFormulaValid.emit(this.correct);

    this.output = event;
    this.inputChanges.emit(event);
  }

  public validateFormula() {
    this._formulasTemplateService.validateFormula({ formula: this.output }).subscribe({
      next: (response) => {
        this.correct = response.responseCode === "1";
        this.isFormulaValid.emit(this.correct);
      },
    });
  }
}
