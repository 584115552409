<div class="d-flex gap-2">
    <p class="fw-bold fs-3">{{ "government_scores.government_sectors" | translate }} -</p>
    <p class="fw-bold fs-3">{{ displayLabel() }}</p>
</div>

<div class="bg-white shadow-md rounded p-2 mb-3">
    <form class="col-12" [formGroup]="sectorForm">
        <div class="row">
            <div class="col-md-6 col-sm-12 col-xs-12">
                <adaa-form-input
                    [label]="'common.form.label.nameAE' | translate"
                    [setValidator]="formValidation()!"
                    [invalid]="submitted() && sectorForm.get('nameAE')?.invalid!"
                    formControlName="nameAE"
                    controlName="nameAE"
                />
            </div>
            <div class="col-md-6 col-sm-12 col-xs-12">
                <adaa-form-input
                    [label]="'common.form.label.nameEN' | translate"
                    [setValidator]="formValidation()!"
                    [invalid]="submitted() && sectorForm.get('nameEN')?.invalid!"
                    formControlName="nameEN"
                    controlName="nameEN"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-sm-12 col-xs-12">
                <adaa-form-input
                    [label]="'common.form.label.descriptionAE' | translate"
                    [setValidator]="formValidation()!"
                    [invalid]="submitted() && sectorForm.get('dscAE')?.invalid!"
                    formControlName="dscAE"
                    controlName="dscAE"
                    [isTextArea]="true"
                />
            </div>
            <div class="col-md-6 col-sm-12 col-xs-12">
                <adaa-form-input
                    [label]="'common.form.label.descriptionEN' | translate"
                    [setValidator]="formValidation()!"
                    [invalid]="submitted() && sectorForm.get('dscEN')?.invalid!"
                    formControlName="dscEN"
                    controlName="dscEN"
                    [isTextArea]="true"
                />
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <adaa-form-two-way-select
                    [notSelectedLabel]="'rules_management.entities' | translate"
                    [selectedLabel]="'rules_management.selected_entities' | translate"
                    [setValidator]="formValidation()!"
                    [options]="availalbledEntityOptions()"
                    [allowSort]="pageMode() !== PageMode.view"
                    [isDisabled]="sectorForm.get('involvedEntities')?.disabled ?? false"
                    formControlName="involvedEntities"
                    controlName="involvedEntities"
                />
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-6 col-sm-12 col-xs-12 text-center">
                <label class="form-label">
                    {{ "government_scores.sectors.icon" | translate }}
                    <span class="form-required text-danger fw-bolder">*</span>
                </label>
                <adaa-form-input-file
                    [onDeleteRemoveFile]="false"
                    [isDisabled]="pageMode() === PageMode.view"
                    [onDeleteRemoveFile]="false"
                    [allowedFileSize]="100024"
                    [allowedFiles]="[FileInputType.svg]"
                    [allowedFilesMessage]="'government_scores.sectors.allowed_icon'"
                    [file]="getImage()"
                    (inputChanges)="onFilechange($event)"
                />
            </div>
            <div class="col-md-6 col-sm-12 col-xs-12 mt-2 text-center">
                <label class="form-label mb-2">
                    {{ "government_scores.sectors.color" | translate }}
                    <span class="form-required text-danger fw-bolder">*</span>
                </label>
                <div class="border rounded p-3">
                    <input
                        class="color"
                        readonly
                        [class.disabled]="pageMode() === PageMode.view"
                        cpPosition="right"
                        [cpWidth]="'600px'"
                        [(colorPicker)]="color"
                        [style.background]="color"
                        (colorPickerChange)="sectorForm.get('iconColor')?.setValue($event)"
                    />
                </div>
            </div>
        </div>
    </form>
</div>

<div class="my-2">
    <adaa-form-action-buttons
        [showSaveButton]="pageMode() !== PageMode.view"
        [saveButtonTitle]="'common.form.button.submit' | translate"
        [cancelButtonTitle]="pageMode() === PageMode.view ? ('common.form.button.close' | translate) : ('common.form.button.cancel' | translate)"
        (saveClicked)="submit()"
        (cancelClicked)="close()"
    />
</div>

<adaa-float-action />
