<h1 class="my-4">
    <h4 class="fw-bold m-0">{{ title() | translate }}</h4>
</h1>

<section class="weights-main-page">
    <div class="page-header">
        <div class="options">
            <button class="btn btn-primary" type="button" (click)="exportCSV()">
                <i class="fa fa-download"></i>
            </button>
        </div>
        <div class="period-row">
            @for (year of planYears(); track $index) {
                <div
                    class="period-col"
                    [sortable]="year"
                    (sort)="onSort($event)"
                    (mouseover)="weightsConfigurations[year] && (yearHovered = year)"
                    (mouseout)="weightsConfigurations[year] && (yearHovered = undefined)"
                >
                    <div class="hover-opts">
                        <button stop-event-propagation class="btn">
                            <i class="fa fa-server"></i>
                        </button>
                    </div>
                    <div class="hover-opts" [ngClass]="{ activate: yearHovered === year }">
                        <button
                            stop-event-propagation
                            class="btn"
                            [disabled]="!canPerformNormalWeightDistribution(+year)"
                            [attr.title]="'kpi_annual_weights.tooltips.equal_distribution' | translate"
                            (click)="performNormalWeightDistribution(+year)"
                        >
                            <i class="fa fa-server"></i>
                        </button>
                    </div>
                    <span>{{ year }}</span>
                </div>
            }
        </div>
    </div>

    <div class="page-toolbar">
        <div class="kpi-search">
            <div class="input-group">
                <input
                    type="search"
                    class="form-control"
                    #searchInput="ngModel"
                    [(ngModel)]="searchTerm"
                    [attr.placeholder]="'kpi_annual_weights.search' | translate"
                    [attr.aria-placeholder]="'kpi_annual_weights.search' | translate"
                    [attr.tabindex]="-1"
                />

                <div class="input-group-btn">
                    <button type="button" class="btn btn-default">
                        <i class="fa fa-search"></i>
                    </button>
                </div>
            </div>
        </div>

        <div class="kpi-weight-totals-section readonly">
            @for (year of planYears(); track $index) {
                <div class="period-col">
                    <span
                        [ngClass]="{
                            'not-100': weightsConfigurations[year] && (totals[year] < 100 || totals[year] > 100),
                            'is-100': weightsConfigurations[year] && totals[year] === 100,
                            'disabled-weight': !weightsConfigurations[year],
                        }"
                    >
                        <ng-container
                            *ngIf="
                                AdaaHelper.isDefined(weightsConfigurations) &&
                                AdaaHelper.isDefined(weightsConfigurations[year]) &&
                                weightsConfigurations[year]
                            "
                        >
                            {{ totals[year] | number: "1.0-2" }}%
                        </ng-container>
                        <ng-container
                            *ngIf="
                                AdaaHelper.isDefined(weightsConfigurations) &&
                                AdaaHelper.isDefined(weightsConfigurations[year]) &&
                                !weightsConfigurations[year]
                            "
                        >
                            {{ "kpi_annual_weights.disabled" | translate }}
                        </ng-container>

                        <span [ngStyle]="{ marginLeft: '5px' }">
                            <small [ngStyle]="{ color: '#44bd32' }" *ngIf="weightsConfigurations[year] && totals[year] === 100">
                                <i class="fa fa-check"></i>
                            </small>
                        </span>
                    </span>
                </div>
            }
        </div>
    </div>

    <div class="page-datatable">
        <div class="page-kpi-list">
            @for (itemId of tableRows; track $index) {
                <div
                    class="kpi-list-item"
                    [attr.title]="
                        languageService.current() === language.English
                            ? getWeightProperty(itemId, 'kpiNameEN')
                            : getWeightProperty(itemId, 'kpiNameAE')
                    "
                    [attr.tabindex]="-1"
                >
                    <small>
                        <a>{{ getWeightProperty(itemId, "refCode") }}</a>
                        &nbsp;&nbsp;
                        <code>
                            {{ "kpi_annual_weights.start" | translate }}:
                            {{ getWeightProperty(itemId, "kpiStartDate") | date: "yyyy" }}
                        </code>
                        <code> {{ "kpi_annual_weights.end" | translate }}: {{ getWeightProperty(itemId, "kpiEndDate") | date: "yyyy" }} </code>
                    </small>
                    <span>
                        {{
                            languageService.current() === language.English
                                ? getWeightProperty(itemId, "kpiNameEN")
                                : getWeightProperty(itemId, "kpiNameAE")
                        }}
                    </span>
                </div>
            }
        </div>

        <div class="annual-weights-table">
            @for (item of tableRows; track $index; let idx1 = $index) {
                <div class="table-row">
                    @for (year of planYears(); track $index; let idx2 = $index) {
                        <div class="table-col">
                            <input
                                type="number"
                                class="form-control"
                                [ngClass]="{
                                    'in-review': getWeightProperty(item, 'wfProcessCtlId', year),
                                    'cell-error': periodHasError(year) && !cellHasError(item, year),
                                    'weight-conf-disabled':
                                        AdaaHelper.isDefined(weightsConfigurations) &&
                                        AdaaHelper.isDefined(weightsConfigurations[year]) &&
                                        !weightsConfigurations[year],
                                }"
                                [readonly]="
                                    !!getWeightProperty(item, 'wfProcessCtlId', year) ||
                                    !!!getWeightProperty(item, 'id', year) ||
                                    (AdaaHelper.isDefined(weightsConfigurations) &&
                                        AdaaHelper.isDefined(weightsConfigurations[year]) &&
                                        !weightsConfigurations[year])
                                "
                                [attr.title]="
                                    getWeightProperty(item, 'year', year) +
                                    ' - ' +
                                    (languageService.current() === language.English
                                        ? getWeightProperty(item, 'kpiNameEN')
                                        : getWeightProperty(item, 'kpiNameAE'))
                                "
                                [attr.min]="0"
                                [attr.max]="100"
                                [attr.step]="0.01"
                                [attr.pattern]="'([0-9]{0,3})(\.\d{0,2})'"
                                [attr.dir]="languageService.current() === language.English ? 'ltr' : 'rtl'"
                                [value]="getWeightProperty(item, 'weight', year)"
                                [name]="'input-' + idx1 + '-' + idx2"
                                [id]="'input-' + idx1 + '-' + idx2"
                                (keyup.enter)="focusNext(idx1, idx2)"
                                (input)="
                                    captureWeightInput(
                                        {
                                            id: getWeightProperty(item, 'id', year),
                                            year: getWeightProperty(item, 'year', year),
                                            currentWeight: getWeightProperty(item, 'weight', year),
                                            itemId: getWeightProperty(item, 'itemId'),
                                            itemType: getWeightProperty(item, 'itemType'),
                                            refCode: getWeightProperty(item, 'refCode'),
                                            wfProcessCtlId: getWeightProperty(item, 'wfProcessCtlId'),
                                            snapshotWeight: getWeightProperty(item, 'snapshotWeight'),
                                            kpiEndDate: getWeightProperty(item, 'kpiEndDate'),
                                            kpiStartDate: getWeightProperty(item, 'kpiStartDate'),
                                        },
                                        $event
                                    )
                                "
                            />
                        </div>
                    }
                </div>
            }
        </div>
    </div>

    <div class="page-footer">
        <div class="kpi-pagination">
            <ngb-pagination [collectionSize]="kpiItems.length" [(page)]="currentPage" [pageSize]="limit" [maxSize]="5" (pageChange)="onPageChange()">
            </ngb-pagination>
        </div>
    </div>
</section>

<div class="my-2">
    <adaa-form-action-buttons
        [cancelButtonTitle]="'common.form.button.cancel' | translate"
        [saveButtonTitle]="'common.form.button.submit' | translate"
        (cancelClicked)="cancel()"
        (saveClicked)="submitWeights()"
    ></adaa-form-action-buttons>
</div>
