import type { Route } from "@angular/router";

import { checkIfLoggedInFn } from "../../core/guards";
import { SearchComponent } from "./search.component";

export const SEARCH_ROUTES: Route[] = [
  {
    path: "search",
    component: SearchComponent,
    canActivate: [checkIfLoggedInFn],
    data: {
      title: "breadcrumbs.search",
      breadcrumb: "breadcrumbs.search",
    },
  },
];
