import { Component, inject, Input, input, output, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

import { PermissionActionDescriptorType } from "../../../../adaa-types";
import { AdaaHelper } from "../../../core/utils";
import { Constants } from "../../constants/constants";
import { ActionsEvents, Language, PermissionAction } from "../../constants/enums";
import { HasPermissionDirective } from "../../directives";
import { OrgUnit, StructuredOrgUnit, TableButtonClicked } from "../../models";
import { AppService } from "../../services";

@Component({
  selector: "adaa-organization-structure",
  standalone: true,
  imports: [TranslateModule, HasPermissionDirective],
  templateUrl: "./organization-structure.component.html",
  styleUrl: "./organization-structure.component.scss",
})
export class OrganizationStructureComponent {
  appService = inject(AppService);
  @Input() currentSelectedOrgUnit: number | undefined;

  readonly BASE_PADDING: string = "15";
  level = input.required<number>();
  childs = input.required<StructuredOrgUnit[]>();
  parent = input<StructuredOrgUnit>({
    id: 0,
    nameAE: "",
    nameEN: "",
    entityId: 0,
    status: 0,
  });
  selectedUnit = output<OrgUnit>();
  actionClicked = output<TableButtonClicked>();

  AdaaHelper = AdaaHelper;
  Language = Language;
  ActionEvents = ActionsEvents;
  managePermission = signal<PermissionActionDescriptorType>({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.manage,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.ORG_STRUCTURE,
        },
      ],
    },
  });

  public getPaddingForElement(id: number) {
    const element = document.getElementById(`item-${id}`);
    if (!element) {
      return +this.BASE_PADDING;
    }

    const style = window.getComputedStyle(element, null);
    if (!style) {
      return null;
    }

    const paddingProperty = this.appService.language() === Language.Arabic ? "padding-right" : "padding-left";
    const paddingValue = style.getPropertyValue(paddingProperty);
    const intVal = parseInt(paddingValue, 10);

    if (isNaN(intVal)) {
      return null;
    }
    return intVal + 40;
  }

  public selectOrgUnit(orgUnit: OrgUnit) {
    this.currentSelectedOrgUnit = orgUnit.id;
    this.selectedUnit.emit(orgUnit);
  }

  public expandChilds(data: StructuredOrgUnit) {
    data.expanded = !data.expanded;
    this.currentSelectedOrgUnit = data.id;
  }
}
