<div class="row mb-3">
    <div class="col-12">
        <adaa-period-slider
            [periodId]="sdgDashboardService.selectedPeriod()?.period?.id"
            [periodType]="sdgDashboardService.selectedPeriod()?.type"
            [activePeriod]="sdgDashboardService.selectedPeriod()!"
            type="quarter"
            (periodSelect)="selectPeriod($event)"
        />
    </div>
</div>

@if (sdgDashboardService.sdgGovDirectionData()) {
    <div class="row mb-3">
        <div class="col-12">
            <div class="bg-white shadow-md rounded p-3">
                <div class="row align-items-center">
                    <div class="col-md-4 col-sm-12 col-xs-12">
                        <div class="row align-items-center">
                            <div class="col-md-6 col-sm-12 col-xs-12">
                                <img
                                    src="assets/images/SDG/logoTrans.png"
                                    [title]="'sdg.title' | translate"
                                    alt=""
                                    width="120px"
                                    class="pointer"
                                    [routerLink]="'/sdg'"
                                />
                            </div>
                            <div class="col-md-6 col-sm-12 col-xs-12">
                                <img
                                    [src]="imageUrl"
                                    [title]="sdgDashboardService.sdgGovDirectionData() | translateToken: 'dsc'"
                                    alt=""
                                    style="max-width: 150px"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-8 col-sm-12 col-xs-12">
                        <div class="row">
                            @for (item of sdgDashboardService.sdgGovDirectionData()?.bars; track $index) {
                                <div class="col-md-4 col-sm-12 col-xs-12 mb-2">
                                    <adaa-info-bar [color]="item.color" [label]="item.label" [value]="item.value" [subLabel]="item.subLabel">
                                    </adaa-info-bar>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

@if (subGovId() && sdgDashboardService.selectedPeriod()) {
    <div class="row mb-3">
        <div class="col-12">
            <div class="bg-white shadow-md rounded p-2">
                <nav>
                    <div class="nav nav-tabs">
                        <button class="nav-link active" type="button" data-bs-toggle="tab" data-bs-target="#nav-kpis">
                            {{ "sdg.goal_targets" | translate }}
                        </button>
                        <button class="nav-link" type="button" role="tab" data-bs-toggle="tab" data-bs-target="#nav-initiatives">
                            {{ "sdg.goal_initiatives" | translate }}
                        </button>
                    </div>
                </nav>
                <div class="tab-content pt-2">
                    <div class="tab-pane fade show active" id="nav-kpis" role="tabpanel">
                        <adaa-data-table
                            #kpisTable
                            [configKey]="'sdg_subgov_kpis_list'"
                            [apiName]="'getSubGovKpisByPeriod'"
                            [paramsString]="paramsStringKPIs()"
                            [enableAdd]="false"
                            [enableEditAction]="false"
                            [enableDeleteAction]="false"
                            (actionClicked)="actionClickedKpi($event)"
                        ></adaa-data-table>
                    </div>
                    <div class="tab-pane fade" id="nav-initiatives" role="tabpanel">
                        <adaa-data-table
                            #initiativesTable
                            [configKey]="'sdg_subgov_initiatives_list'"
                            [apiName]="'getInitiativesCrossCycle'"
                            [paramsString]="paramsStringInitiatives()"
                            [enableAdd]="false"
                            [enableEditAction]="false"
                            [enableDeleteAction]="false"
                            (actionClicked)="actionClickedProject($event)"
                        ></adaa-data-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

@if (sdgDashboardService.sdgData()) {
    <div class="row">
        <div class="col-12">
            <div class="navigation-blocks">
                @for (nav of sdgDashboardService.sdgData()?.subGovDirections; track $index) {
                    <div
                        class="nav-block"
                        [style.background]="nav.config.colorCode"
                        (mouseover)="tooltip = nav.id"
                        (mouseleave)="tooltip = undefined"
                        [routerLink]="'/sdg/' + nav.id"
                    >
                        {{ nav.displayOrder }}
                        @if (tooltip === nav.id && !AdaaHelper.isMobileDevice()) {
                            <div class="tooltip-sdg">
                                <span>{{ nav | translateToken: "name" }}</span>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    </div>
}
