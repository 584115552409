import { Route } from "@angular/router";

import { checkIfLoggedInFn } from "../../core/guards";
import { PageMode } from "../../shared/constants/enums";
import { viewAttributesPageGuards } from "./_guards";
import { AttributesEditorComponent } from "./attributes-editor/attributes-editor.component";
import { AttributesListComponent } from "./attributes-list/attributes-list.component";

export const ATTRIBUTES_ROUTES: Route[] = [
  {
    path: "attributes",
    canActivate: [checkIfLoggedInFn, ...viewAttributesPageGuards],
    children: [
      {
        path: "list",
        redirectTo: "",
      },
      {
        path: "",
        component: AttributesListComponent,
        data: {
          breadcrumb: "breadcrumbs.attributes",
          title: "breadcrumbs.attributes",
        },
      },
      {
        path: "create",
        component: AttributesEditorComponent,
        data: {
          breadcrumb: "attributes.create_new",
          title: "attributes.create_new",
          pageMode: PageMode.create,
        },
      },
      {
        path: ":id",
        component: AttributesEditorComponent,
        data: {
          breadcrumb: "breadcrumbs.attributes_module.view",
          title: "breadcrumbs.attributes_module.view",
          pageMode: PageMode.view,
        },
      },
      {
        path: "edit/:id",
        component: AttributesEditorComponent,
        data: {
          breadcrumb: "breadcrumbs.attributes_module.edit",
          title: "breadcrumbs.attributes_module.edit",
          pageMode: PageMode.edit,
        },
      },
    ],
  },
];
