<adaa-data-table
    #tpTable
    titleHeader="national_projects.console_title"
    [configKey]="constants.MAIN_TABLE_LIST_CONF_KEY.NATIONAL_PROJECTS_CONF_LIST"
    apiName="getAllCamelCase"
    apiPrefix="national-project"
    [paramsString]="paramsString()"
    [itemTypeId]="constants.CONSTANT_PERMISSIONS.NATIONAL_PROJECTS"
    [customActionButtons]="customButtons"
    [tableWithChildren]="true"
    childKey="nationalProjectMilestones"
    [enableConnectionsAction]="true"
    [enableChildActionsCell]="true"
    [enableViewChildAction]="true"
    [enableEditChildAction]="true"
    [enableConnectionsChildAction]="true"
    [disableEditRules]="disableEditDeleteRules"
    [disableDeleteRules]="disableEditDeleteRules"
    [hideDeleteRules]="hideDeleteEditRules"
    [hideEditRules]="hideDeleteEditRules"
    (addNewClicked)="addNewClicked()"
    (actionClicked)="actionClicked($event)"
    (childClicked)="childClicked($event)"
/>

<adaa-float-action />
