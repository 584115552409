import { Component, computed, inject, type OnInit, signal, type TemplateRef } from "@angular/core";
import { RouterLink } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { map, switchMap } from "rxjs";

import { AdaaHelper } from "../../../../core/utils";
import {
  DoughnutComponent,
  FormCheckboxComponent,
  FormDropdownComponent,
  type ItemListWidgetData,
  PeriodSliderVariantComponent,
  RibbonComponent,
  type SimpleWidgetData,
  type SymbolWidgetData,
} from "../../../../shared/components";
import { Constants } from "../../../../shared/constants/constants";
import { Language } from "../../../../shared/constants/enums";
import type {
  MainResponseModel,
  NationalStrategyStatsModelType,
  SelectedPeriodModelType,
  ValueText,
} from "../../../../shared/models";
import {
  LanguageService,
  NationalStrategiesApiService,
  PeriodApiService,
  SystemLayoutService,
} from "../../../../shared/services";
import { StrategyCardComponent } from "./strategy-card/strategy-card.component";

@Component({
  selector: "adaa-main-dashboard",
  standalone: true,
  templateUrl: "./main-dashboard.component.html",
  styleUrl: "./main-dashboard.component.scss",
  imports: [
    FormCheckboxComponent,
    TranslateModule,
    FontAwesomeModule,
    PeriodSliderVariantComponent,
    DoughnutComponent,
    StrategyCardComponent,
    RouterLink,
    RibbonComponent,
    FormDropdownComponent,
  ],
})
export class MainDashboardComponent implements OnInit {
  readonly languageService = inject(LanguageService);
  private readonly _periodApiService = inject(PeriodApiService);
  private readonly _translateService = inject(TranslateService);
  private readonly _modalService = inject(NgbModal);
  private readonly _nationalStrategiesApiService = inject(NationalStrategiesApiService);
  private readonly _systemLayoutService = inject(SystemLayoutService);

  selectedStatus = signal<"COMPLETED" | "ON_GOING" | "ALL">("ALL");
  selectedPeriod = signal<Pick<SelectedPeriodModelType, "period"> | undefined>(undefined);
  showAllStrategies = signal<boolean>(false);
  dashboardData = signal<NationalStrategyStatsModelType | undefined>(undefined);
  startTS = signal<number>(AdaaHelper.getDubaiTime(AdaaHelper.plan?.startYear));
  endTS = signal<number>(AdaaHelper.getDubaiTime(AdaaHelper.plan?.endYear));
  hasCalibrated = signal<boolean>(false);
  lang = signal<Language>(AdaaHelper.getCurrentLang());
  Language = Language;

  isFilterApplied = computed(() => this.selectedStatus() !== "ALL");
  dashboardProgress = computed(() =>
    [
      {
        amount: this.dashboardData()?.overallProgress,
        color: this.dashboardData()?.overallProgressColor,
        label: "national_strategies.initiative_progress",
      },
      {
        amount: this.dashboardData()?.overallScore,
        color: this.dashboardData()?.overallScoreColor,
        label: "national_strategies.annual_preformance",
      },
      {
        amount: this.dashboardData()?.overallVisionScore,
        color: this.dashboardData()?.overallVisionScoreColor,
        label: "national_strategies.vision_achievement",
      },
    ].filter((_, index) => {
      if (this.showAllStrategies()) {
        return index !== 1;
      }
      return true;
    })
  );
  dashboardWidgets = computed<(SimpleWidgetData | ItemListWidgetData | SymbolWidgetData)[]>(() => [
    {
      isDuo: false,
      label: "national_strategies.strategies",
      data: this.dashboardData()?.countNationalStrategy,
    },
    {
      isDuo: true,
      items: [
        {
          label: "national_strategies.dashboard.completed",
          data: this.dashboardData()?.countFinishedNationalStrategies,
        },
        {
          label: "national_strategies.dashboard.active",
          data: this.dashboardData()?.countActiveNationalStrategies,
        },
      ],
    },
    {
      isDuo: false,
      label: "national_strategies.obj_enabler",
      data: this.dashboardData()?.countNationalStrategyChildren,
    },
    {
      isDuo: false,
      label: "national_strategies.initiatives",
      data: this.dashboardData()?.initiativeCount,
    },
    {
      isDuo: false,
      label: "national_strategies.kpis",
      data: this.dashboardData()?.kpiCount,
    },
    {
      isDuo: false,
      label: "national_strategies.budget",
      data: AdaaHelper.amountToString(this.dashboardData()?.budget),
      symbol: AdaaHelper.getCurrencySymbol(this.dashboardData()?.budget, true, "-"),
    },
    {
      isDuo: false,
      label: "national_strategies.actual_expenditure",
      data: AdaaHelper.amountToString(this.dashboardData()?.expense),
      symbol: AdaaHelper.getCurrencySymbol(this.dashboardData()?.expense, true, "-"),
    },
  ]);

  private readonly _untilDestroyed = AdaaHelper.untilDestroyed();

  readonly periodSliderFrequency = Constants.FREQUENCY.ANNUAL;

  statusOptions = signal<ValueText[]>([]);

  public get icons() {
    return {
      refresh: faArrowsRotate,
    };
  }

  public ngOnInit() {
    this._initPeriod();
    this._refresh();

    this._translateService
      .get([
        "national_strategies.dashboard.all",
        "national_strategies.dashboard.active",
        "national_strategies.dashboard.completed",
      ])
      .subscribe((values) => {
        this.statusOptions.set([
          {
            value: "ALL",
            text: values["national_strategies.dashboard.all"],
          },
          {
            value: "ON_GOING",
            text: values["national_strategies.dashboard.active"],
          },
          {
            value: "COMPLETED",
            text: values["national_strategies.dashboard.completed"],
          },
        ]);
      });
  }

  public selectPeriod(period: Pick<SelectedPeriodModelType, "period">, cmp: PeriodSliderVariantComponent) {
    if (!period) return;
    this.selectedPeriod.update(() => period);
    this._queryData();

    const startYear = new Date(AdaaHelper.getDubaiTime(AdaaHelper.plan.startYear)).getFullYear();
    const endYear = new Date(AdaaHelper.getDubaiTime(AdaaHelper.plan.endYear)).getFullYear();

    if (!this.hasCalibrated() && (period.period.year === startYear || period.period.year === endYear)) {
      cmp.recalibrate({
        startDate: AdaaHelper.getDubaiTime(this.dashboardData()!.firstStartDate!),
        endDate: AdaaHelper.getDubaiTime(this.dashboardData()!.lastEndDate!),
      });
      this.hasCalibrated.set(true);
    }
  }

  public togglePeriodSelector(bool: boolean) {
    this.showAllStrategies.set(bool);
    if (bool) this._fetchAllData();
  }

  public openFilters(content: TemplateRef<unknown>) {
    this._modalService.open(content, {
      animation: true,
      scrollable: true,
      keyboard: false,
      centered: true,
      modalDialogClass: this.languageService.modalDirection(),
    });
  }

  public reset() {
    this._modalService.dismissAll();
    this.selectedStatus.set("ALL");
    this._queryData();
  }

  public apply() {
    this._modalService.dismissAll();
    this._queryData();
  }

  public filterData(status: "COMPLETED" | "ON_GOING") {
    this.selectedStatus.set(status);
    this._queryData();
  }

  private _fetchAllData() {
    this._nationalStrategiesApiService.getStrategiesDashboardInfo<true>().subscribe({
      next: (res) => this._updateDashboardData(res),
    });
  }

  private _initPeriod() {
    this._periodApiService
      .getActualPeriod({ useLoader: true })
      .pipe(
        map((res) => res.responseData),
        switchMap((period) =>
          this._periodApiService.getAll({ useLoader: true }).pipe(
            map((res) => res.responseData),
            map((periodList) => {
              const index = periodList.findIndex(({ year, month }) => month === 12 && year === period.year);
              if (index !== -1) return periodList[index];
              return period;
            })
          )
        )
      )
      .subscribe({
        next: (period) => {
          this.selectedPeriod.update(() => ({ period }));
          this._queryData();
        },
      });
  }

  private _queryData() {
    const selectedPeriod = this.selectedPeriod() as SelectedPeriodModelType;

    this._nationalStrategiesApiService
      .getStrategiesDashboardInfo<false>({
        year: selectedPeriod.period.year,
        status: this.selectedStatus(),
      })
      .subscribe({
        next: (res) => this._updateDashboardData(res),
      });
  }

  private _updateDashboardData(response: MainResponseModel<NationalStrategyStatsModelType>) {
    if (response.inError) return;
    this.dashboardData.set(response.responseData);
  }

  private _refresh() {
    this._systemLayoutService.hasActiveEntityChanged$.pipe(this._untilDestroyed()).subscribe({
      next: () => window.location.reload(),
    });

    this._systemLayoutService.hasCycleChanged$.pipe(this._untilDestroyed()).subscribe({
      next: () => window.location.reload(),
    });
  }
}
