import "./core/utils/sentry";

import { HttpClient, provideHttpClient, withInterceptors } from "@angular/common/http";
import { ApplicationConfig, ErrorHandler, importProvidersFrom } from "@angular/core";
import { provideAnimations } from "@angular/platform-browser/animations";
import {
  PreloadAllModules,
  provideRouter,
  withComponentInputBinding,
  withInMemoryScrolling,
  withPreloading,
  withRouterConfig,
} from "@angular/router";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import * as Sentry from "@sentry/angular";
import { provideCharts, withDefaultRegisterables } from "ng2-charts";
import { provideToastr } from "ngx-toastr";

import { routes } from "./app.routes";
import {
  errorsInterceptor,
  headersInterceptor,
  loaderInterceptor,
  mainInterceptor,
  notificationInterceptor,
} from "./core/interceptors";
import { AdaaHelper, CustomTranslateLoader } from "./core/utils";
import { Language } from "./shared/constants/enums";

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withInMemoryScrolling({
        scrollPositionRestoration: "top",
      }),
      withRouterConfig({
        paramsInheritanceStrategy: "always",
        onSameUrlNavigation: "reload",
        urlUpdateStrategy: "eager",
      }),
      withComponentInputBinding(),
      withPreloading(PreloadAllModules)
    ),
    provideHttpClient(
      withInterceptors([
        mainInterceptor,
        headersInterceptor,
        notificationInterceptor,
        errorsInterceptor,
        loaderInterceptor,
      ])
    ),
    { provide: ErrorHandler, useValue: Sentry.createErrorHandler() },
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useClass: CustomTranslateLoader,
          deps: [HttpClient],
        },
      })
    ),
    provideAnimations(),
    provideToastr({
      positionClass: AdaaHelper.getCurrentLang() === Language.Arabic ? "toast-top-left" : "toast-top-right",
    }),
    provideCharts(withDefaultRegisterables()),
  ],
};
