<adaa-auth-layout>
    <form [formGroup]="loginForm" (ngSubmit)="submit()" class="row mt-2">
        <div class="col-12 mb-3">
            <adaa-form-input
                controlName="email"
                formControlName="email"
                inputType="email"
                [required]="true"
                [placeholder]="'common.form.label.email' | translate"
                [invalid]="
                    loginForm.get('password')?.dirty && (loginForm.get('email')?.errors?.['required'] || loginForm.get('email')?.errors?.['email'])
                "
            />

            <adaa-form-input
                controlName="password"
                formControlName="password"
                [required]="true"
                [placeholder]="'common.form.label.password' | translate"
                inputType="password"
                [invalid]="loginForm.get('password')?.dirty && loginForm.get('password')?.errors?.['required']"
            />
        </div>
        <div class="col-12 mb-3">
            <div class="gap-1 col-4 mx-auto d-flex flex-column flex-nowrap align-items-center">
                <button type="submit" class="btn btn-info">
                    <span>{{ "login.login" | translate }}</span>
                </button>
                @if (showUaePass) {
                    <h6 [class.rtl-label]="isArabic()">{{ "login.or" | translate }}</h6>

                    @if (isArabic()) {
                        <img (click)="loginUaePass()" src="assets/images/uae_pass_buttons/login_arabic.png" />
                    } @else {
                        <img (click)="loginUaePass()" src="assets/images/uae_pass_buttons/login_english.png" />
                    }
                }
            </div>
        </div>
        <div class="col-12">
            <span role="button" routerLink="/forget-password" class="text-info">{{ "login.forgot" | translate }} </span>
        </div>
        <div class="col-12 mb-4">
            <span role="button" (click)="languageService.switchLanguage()" class="text-info">{{ "language.change_lang" | translate }} </span>
        </div>
    </form>
</adaa-auth-layout>
