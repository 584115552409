import { hasPermissionGuard } from "../../core/guards";
import { Constants } from "../../shared/constants/constants";
import { PermissionAction } from "../../shared/constants/enums";

export const viewEntityProfilePageGuards = [
  hasPermissionGuard({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.view,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.PROVISIONING,
        },
      ],
    },
    redirectTo: "/403",
  }),
];

export const viewAddressesPageGuards = [
  hasPermissionGuard({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.view,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.ENTITY_ADDRESSES,
        },
      ],
    },
    redirectTo: "/403",
  }),
];

export const viewOrganizationStructurePageGuards = [
  hasPermissionGuard({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.view,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.ORG_STRUCTURE,
        },
      ],
    },
    redirectTo: "/403",
  }),
];

export const viewStrategicDocumentPageGuards = [
  hasPermissionGuard({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.view,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.STRATEGIC_DOCUMENT,
        },
      ],
    },
    redirectTo: "/403",
  }),
];
