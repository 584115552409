import { Component, computed, effect, inject, input, linkedSignal, output, signal, viewChild } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { NgbAccordionModule, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { AdaaHelper } from "../../../../../core/utils";
import { ConfirmationModalComponent, DataTableComponent } from "../../../../../shared/components";
import { Constants } from "../../../../../shared/constants/constants";
import { PageMode } from "../../../../../shared/constants/enums";
import {
  CustomButton,
  EntityModelType,
  NationalTargetImprovementPlanType,
  ObjectStatus,
  TableActionEvent,
} from "../../../../../shared/models";
import { DataTableService, LanguageService } from "../../../../../shared/services";
import { ImprovementPlanFormComponent } from "./improvement-plan-form/improvement-plan-form.component";

const CustomTableAction = {
  moveUp: "move-up",
  moveDown: "move-down",
};

@Component({
  selector: "adaa-improvement-plan",
  imports: [TranslateModule, ReactiveFormsModule, DataTableComponent, NgbAccordionModule],
  templateUrl: "./improvement-plan.component.html",
  styleUrl: "./improvement-plan.component.scss",
})
export class ImprovementPlanComponent {
  readonly languageService = inject(LanguageService);
  private readonly _modalService = inject(NgbModal);
  private readonly _toastrService = inject(ToastrService);
  private readonly _dataTableService = inject(DataTableService);

  readonly tableConfList = Constants.MAIN_TABLE_LIST_CONF_KEY.IMPROVEMENT_PLAN;

  kpiType = input<number>(0);
  entities = input<EntityModelType[]>([]);
  kpi = input<Record<string, unknown> | undefined>(undefined);
  stagedKpi = input<Record<string, unknown> | undefined>(undefined);

  isDirty = signal<boolean>(false);

  readonly data = linkedSignal(
    () => (this.stagedKpi()?.nationalTargetImprovementPlans ?? []) as NationalTargetImprovementPlanType[]
  );

  readonly orderedData = computed(() => {
    const data = this.data();

    data.sort((a, b) => {
      if (a.improvementPlanOrder < b.improvementPlanOrder) return -1;
      else if (a.improvementPlanOrder > b.improvementPlanOrder) return 1;
      return 0;
    });

    return data.filter((e) => e.status !== ObjectStatus.REMOVE).map((e, i) => ({ ...e, improvementPlanOrder: i + 1 }));
  });

  readonly improvementPlanList = viewChild.required<DataTableComponent>("improvementPlanList");

  readonly improvementPlanUpdated = output<NationalTargetImprovementPlanType[]>();

  readonly customActionButtons: CustomButton[] = [
    {
      eventName: CustomTableAction.moveUp,
      iconName: "adaa-icon-increasing",
      iconTitle: "",
    },
    {
      eventName: CustomTableAction.moveDown,
      iconName: "adaa-icon-decreasing",
      iconTitle: "",
    },
  ];

  constructor() {
    effect((onCleanup) => {
      const stagedKpi = this.stagedKpi();
      if (stagedKpi?.sponsorEntityId) {
        this._dataTableService.currentSelectedEntityId.set(stagedKpi?.sponsorEntityId as number);
      }

      onCleanup(() => {
        this._dataTableService.currentSelectedEntityId.set(AdaaHelper.entity?.id as number);
      });
    });
  }

  public handleAddTableAction() {
    this._openModal(PageMode.create, this.orderedData().length);
  }

  public handleTableActions({ event, data, index }: TableActionEvent) {
    if (event === CustomTableAction.moveUp || event === CustomTableAction.moveDown) {
      this._moveItem(index, event);
      return;
    }

    if (event === "edit") {
      this._openModal(PageMode.edit, index, data);
      return;
    }

    if (event === "delete") {
      this._deleteImprovementPlan({ event, data, index });
      return;
    }
  }

  public submit() {
    const data = this.orderedData();

    if (data.length === 0) return [];
    const totalWeight = data.reduce((a, b) => {
      if (!AdaaHelper.isDefined(b.weight)) return a;
      const weight = Number(b.weight);
      if (isNaN(weight)) return a;
      return a + weight;
    }, 0);

    if (totalWeight !== 100) {
      this._toastrService.error(this.languageService.translate("notification.error.activities_workload_not_100"));
      throw new Error("Improvement plan weight is not 100");
    }

    return data;
  }

  private _moveItem(index: number, direction: string) {
    if (index < 0 || index >= this.orderedData().length) {
      return;
    }

    const newArray = [...this.orderedData()];

    if (direction === CustomTableAction.moveUp && index > 0) {
      [newArray[index - 1], newArray[index]] = [newArray[index], newArray[index - 1]];
      newArray[index - 1].improvementPlanOrder = index;
      newArray[index].improvementPlanOrder = index + 1;
    } else if (direction === CustomTableAction.moveDown && index < this.orderedData().length - 1) {
      [newArray[index], newArray[index + 1]] = [newArray[index + 1], newArray[index]];
      newArray[index + 1].improvementPlanOrder = index + 2;
      newArray[index].improvementPlanOrder = index + 1;
    }

    this.data.set(newArray);
  }

  private _openModal(mode: PageMode, index: number, plan?: NationalTargetImprovementPlanType): void {
    const modal = this._modalService.open(ImprovementPlanFormComponent, {
      centered: true,
      size: "xl",
      modalDialogClass: this.languageService.modalDirection(),
    });

    if (mode === PageMode.edit) {
      if (!plan) throw new Error("Plan is missing!");
      modal.componentInstance.plan.set(plan);
      modal.componentInstance.teamMembers.set(plan.teamMembers ?? []);
    }

    modal.componentInstance.mode.set(mode);
    modal.componentInstance.kpiType.set(this.kpiType());
    modal.componentInstance.kpi.set(this.stagedKpi());
    modal.componentInstance.itemIndex.set(index);
    modal.componentInstance.entities.set(
      this.entities().filter(({ id }) => id === Constants.CONSTANT_PMO_ID || id === this.stagedKpi()?.sponsorEntityId)
    );

    modal.result.then((e: NationalTargetImprovementPlanType) => {
      const data = this.data();

      if (mode === PageMode.create) {
        this.data.set([...data, e]);
      } else if (mode === PageMode.edit) {
        this.data.set(
          data.map((d, i) => {
            if (i === index) return e;
            return d;
          })
        );
      }

      this.isDirty.set(true);
      this.improvementPlanUpdated.emit(this.orderedData());
    });
  }

  private _deleteImprovementPlan({ data, index }: TableActionEvent): void {
    const modal = this._modalService.open(ConfirmationModalComponent, {
      centered: true,
      size: "md",
      modalDialogClass: this.languageService.modalDirection(),
    });

    modal.componentInstance.header = "nkpi.benchmark_delete_yes_no_title";
    modal.componentInstance.title = "nkpi.benchmark_delete_yes_no_information";

    modal.result.then((e) => {
      if (e) {
        if ("id" in data) {
          data.status = ObjectStatus.REMOVE;
          data.improvementPlanOrder = 0;

          this.data.set(
            this.data().map(($d) => {
              if (data.id === $d.id) return data;
              return $d;
            })
          );
        } else {
          this.data.set(this.data().filter((_, $i) => $i !== index));
        }

        this.improvementPlanList().loadTableData();
        this.isDirty.set(true);
      }
      this.improvementPlanUpdated.emit(this.orderedData());
    });
  }
}
