import { Component, effect, inject, input, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { filter, map } from "rxjs";

import { TranslateTokenPipe } from "../../../../../core/pipes";
import { ExecTeamModelType } from "../../../../../shared/models/exec-team.model";
import { ExecTeamApiService } from "../../../../../shared/services/exec-team-api.service";

@Component({
  selector: "adaa-exec-team",
  imports: [TranslateModule, TranslateTokenPipe],
  templateUrl: "./exec-team.component.html",
  styleUrl: "./exec-team.scss",
})
export class ExecTeamComponent {
  private readonly _execTeamApiService = inject(ExecTeamApiService);

  kpiId = input.required<number>();
  periodId = input.required<number>();

  data = signal<ExecTeamModelType | undefined>(undefined);

  constructor() {
    effect(() => {
      this._execTeamApiService
        .getByKpiId({
          kpiId: this.kpiId(),
          periodId: this.periodId(),
        })
        .pipe(
          filter((res) => !res.inError),
          map((res) => res.responseData)
        )
        .subscribe({
          next: (data) => {
            this.data.set(data);
          },
        });
    });
  }
}
