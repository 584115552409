import { inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { map } from "rxjs";

import { AdaaHelper, useNewDS } from "../../core/utils";
import { Constants } from "../../shared/constants/constants";
import { AdaaBoolean } from "../../shared/constants/enums";
import { ObjectivesApiService } from "../../shared/services";

@Injectable({
  providedIn: "root",
})
export class SearchProvider {
  private readonly _router = inject(Router);
  private readonly _objectivesApiService = inject(ObjectivesApiService);

  public objectivesRedirection(id: number, entityId: number) {
    if (!useNewDS() && AdaaHelper.isPMOEntity(entityId)) {
      return this._router.navigate(["/console", "objectives", id]);
    }
    return this._objectivesApiService
      .getEnablerCardById(id)
      .pipe(map(({ responseData }) => responseData))
      .subscribe({
        next: (res) => {
          if (res.enabler === AdaaBoolean.Y && res.objectiveType === Constants.CONSTANT_ENABLER_OBJECTIVE) {
            this._router.navigate(["/console", "pmo-objectives", "enabler-objective", res.id]);
          } else if (res.objectiveType === Constants.CONSTANT_MAIN_OBJECTIVE) {
            this._router.navigate(["/console", "objectives", "main-objective", res.id]);
          } else if (res.objectiveType === Constants.CONSTANT_STRATEGIC_OBJECTIVE) {
            this._router.navigate(["/console", "objectives", "strategic-objective", res.id]);
          } else if (res.objectiveType === Constants.CONSTANT_TRANSFORMATIONAL_OBJECTIVE) {
            this._router.navigate(["/console", "pmo-objectives", "transformational-objective", res.id]);
          }
        },
      });
  }
}
