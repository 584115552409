import { Component, inject, input, signal } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";

import { AdaaHelper } from "../../../../core/utils";
import {
  ConfirmationModalComponent,
  FormActionButtonsComponent,
  FormInputComponent,
  FormInputDateComponent,
} from "../../../../shared/components";
import { Constants } from "../../../../shared/constants/constants";
import { Language, PageMode } from "../../../../shared/constants/enums";
import { AppService, SystemLayoutService, TpCyclesApiService } from "../../../../shared/services";
import { TPCycleEditorService } from "../../../../shared/services/tp-cycle-editor.service";

@Component({
  selector: "adaa-tp-cycle-editor",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    FormActionButtonsComponent,
    FormInputComponent,
    FormInputDateComponent,
  ],
  templateUrl: "./tp-cycle-editor.component.html",
  styleUrl: "./tp-cycle-editor.component.scss",
})
export class TpCycleEditorComponent {
  tpCycleEditorService = inject(TPCycleEditorService);

  private _formBuilder = inject(FormBuilder);
  private _toastrService = inject(ToastrService);
  private _translateService = inject(TranslateService);
  private _tpCyclesApiService = inject(TpCyclesApiService);
  private _router = inject(Router);
  private _modalService = inject(NgbModal);
  private _appService = inject(AppService);
  private _activatedRoute = inject(ActivatedRoute);
  private readonly _systemLayoutService = inject(SystemLayoutService);

  readonly #untilDestroy = AdaaHelper.untilDestroyed();

  adaaHelper = AdaaHelper;
  _subscription = new Subscription();

  pageMode = input.required<string>();
  tpCycleForm: FormGroup = new FormGroup({});
  PageMode = PageMode;

  tpCycleId: string | null;

  displayLabel = signal<string>("");
  setDisabled = signal<boolean>(false);
  submitted = signal<boolean>(false);

  public ngOnInit(): void {
    this._systemLayoutService.hasActiveEntityChanged$.pipe(this.#untilDestroy()).subscribe({
      next: () => {
        this._router.navigateByUrl(`/console`);
      },
    });
    this.setDisabled.set(this.pageMode() === PageMode.view);
    this.tpCycleId = this._activatedRoute.snapshot?.paramMap?.get("id");

    this.tpCycleEditorService.formValidator();
    if (this.tpCycleId) this._getTPCycle(+this.tpCycleId);
    this._prepareForm();
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  private _prepareForm() {
    const isViewMode = this.pageMode() === PageMode.view;

    this.tpCycleForm = this._formBuilder.group({
      nameAE: this._formBuilder.control<string | undefined>(
        { value: "", disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      nameEN: this._formBuilder.control<string | undefined>(
        { value: "", disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      startDate: this._formBuilder.control<number | null>(
        { value: null, disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      endDate: this._formBuilder.control<number | null>(
        { value: null, disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      dscAE: this._formBuilder.control<string | undefined>({ value: "", disabled: isViewMode }),
      dscEN: this._formBuilder.control<string | undefined>({ value: "", disabled: isViewMode }),
    });

    this._subscription.add(
      this.tpCycleForm.valueChanges.subscribe((value) => {
        const displayLanguage = AdaaHelper.getFieldLanguage("name");
        if (!value[displayLanguage].length) {
          this.displayLabel.set(value["nameAE"] || value["nameEN"]);
        } else this.displayLabel.set(value[displayLanguage]);
      })
    );
  }

  private _getTPCycle(tpCycleId: number) {
    if (tpCycleId)
      this._tpCyclesApiService.getTPCycleById(tpCycleId).subscribe({
        next: (response) => {
          this.tpCycleForm.patchValue({ ...response.responseData });
          this.tpCycleForm.markAsPristine();
          this.tpCycleForm.markAsUntouched();
        },
      });
  }

  public checkInvalid(control: string) {
    return (
      this.tpCycleForm.controls[control].errors?.["required"] &&
      (this.tpCycleForm.get(`${control}`)?.dirty || this.submitted())
    );
  }
  public updateCycle() {
    this.submitted.set(true);
    if (this.tpCycleForm.invalid) {
      this._toastrService.warning(this._translateService.instant("notification.warning.missing_info"));
      return;
    }
    const tpCycle = this._formatTPCycle();

    const serviceUrl =
      this.pageMode() === PageMode.edit
        ? this._tpCyclesApiService.updateTPCycle(tpCycle)
        : this._tpCyclesApiService.createTPCycle(tpCycle);

    serviceUrl.subscribe({
      next: (response) => {
        if (response.inError) return;
        this._toastrService.success(this._translateService.instant("notification.success.save"));
        this._router.navigateByUrl("/console/national-project/cycles");
      },
    });
  }
  private _formatTPCycle() {
    const tpCycle = this.tpCycleForm.value;
    tpCycle.status = Constants.OBJECT_STATUS.ACTIVE;
    if (this.pageMode() === PageMode.edit) {
      tpCycle.updateTS = Date.now();
      tpCycle.id = this.tpCycleId;
    }
    return tpCycle;
  }
  public cancelForm() {
    if (this.tpCycleForm.dirty) {
      const modal = this._modalService.open(ConfirmationModalComponent, {
        centered: true,
        size: "md",
        modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
      });

      modal.componentInstance.header = "common.form.modals_leave.cancel_yes_no_title";
      modal.componentInstance.title = "common.form.modals_leave.cancel_yes_no_information";

      modal.result.then((e) => {
        if (e) this._router.navigateByUrl(`/console/national-project/cycles`);
      });
    } else this._router.navigateByUrl(`/console/national-project/cycles`);
  }
}
