import { Component, inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router, RouterModule } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { AdaaHelper } from "../../../core/utils";
import { AuthLayoutComponent, FormInputComponent, FormRadioComponent } from "../../../shared/components";
import { Constants } from "../../../shared/constants/constants";
import { Language, VerificationMethod } from "../../../shared/constants/enums";
import {
  LoginResponse,
  MainResponseModel,
  NewPassword,
  RadioOption,
  SecurityQuestion,
  SecurityQuestionAnswerModel,
  VerificationRequestSms,
  VerificationRequestSubmit,
} from "../../../shared/models";
import { AppService, LoginService } from "../../../shared/services";

@Component({
  selector: "adaa-new-password",
  standalone: true,
  imports: [
    TranslateModule,
    ReactiveFormsModule,
    RouterModule,
    AuthLayoutComponent,
    FormRadioComponent,
    FormInputComponent,
  ],
  templateUrl: "./new-password.component.html",
  styleUrl: "./new-password.component.scss",
})
export class NewPasswordComponent implements OnInit {
  private _formBuilder = inject(FormBuilder);
  private _activatedRoute = inject(ActivatedRoute);
  private _router = inject(Router);
  private _loginService = inject(LoginService);
  private _appService = inject(AppService);
  private _translateService = inject(TranslateService);
  private _toastrService = inject(ToastrService);

  private _token: string;

  newPasswordForm: FormGroup;
  user: LoginResponse | undefined;
  verificationMethod = VerificationMethod;
  resetPasswordOptions: RadioOption[] = [];
  questions: SecurityQuestion[] = [];
  lockCounter: number = 0;
  adaaHelper = AdaaHelper;
  currentStep: number = 1;
  isUserDefined: boolean = true;
  errorMessage: string | undefined = undefined;

  public get verificationMethodValue(): string {
    return this.newPasswordForm.get("verificationMethod")?.value;
  }

  public ngOnInit(): void {
    this._prepareForm();

    this._activatedRoute.params.subscribe({
      next: (params: Params) => {
        this._token = params["token"];
        this._getSecurityQuestions();
      },
    });
  }

  public sendVerificationCode(): void {
    const model: VerificationRequestSms = {
      language: this._appService.language(),
      text: "code",
      userId: this.user!.id,
    };

    this._loginService.fetchVerificationCode(model, VerificationMethod.SMS).subscribe((response: MainResponseModel) => {
      if (response.responseCode === "1") {
        this._lockVerificationButton();
      }
    });
  }

  public resetPasswordOptionsUpdated(value: VerificationMethod): void {
    this.newPasswordForm.get("verificationMethod")?.setValue(value);
  }

  /**
   * @description 1ed step validate the account new password
   */
  public validateAccount(): void {
    if (this.verificationMethodValue === VerificationMethod.QUESTIONS) {
      const firstQuestion = this.newPasswordForm.get("firstQuestion")?.value;
      const secondQuestion = this.newPasswordForm.get("secondQuestion")?.value;
      if (firstQuestion && secondQuestion) {
        this._loginService
          .validateQuestions(this._token, this._getDataValidateByQuestions(firstQuestion, secondQuestion))
          .subscribe((response: MainResponseModel) => {
            response.responseCode === "1"
              ? (this.currentStep = 2)
              : this._toastrService.error(this._translateService.instant("login.error_answers"));
          });
      } else this._toastrService.error(this._translateService.instant("login.error_answers"));
    } else {
      const code = this.newPasswordForm.get("verificationCode")?.value;
      if (code) {
        this._loginService
          .validateCode(this._token, this._getDataValidateByCode(code))
          .subscribe((response: MainResponseModel) => {
            response.responseCode === "1"
              ? (this.currentStep = 2)
              : this._toastrService.error(this._translateService.instant("login.error_code"));
          });
      } else this._toastrService.error(this._translateService.instant("login.error_code"));
    }
  }

  /**
   * @description 2ed step validate the account new password
   */
  public validatePassword(): void {
    const password = this.newPasswordForm.get("password")?.value;
    const confirmPassword = this.newPasswordForm.get("confirmPassword")?.value;

    if (password && password === confirmPassword) {
      this._loginService
        .updatePassword(this._token, this._getDataPassword(password))
        .subscribe((response: MainResponseModel) => {
          if (+response.responseCode === Constants.CONSTANT_ERROR_CODE.PASSWORD_WRONG_COMPLEXITY) {
            this._toastrService.error(
              this._translateService.instant("notification.error.password_requirements_failed")
            );
          } else if (+response.responseCode === Constants.CONSTANT_ERROR_CODE.AD_USER_NOT_EXISTS) {
            this._toastrService.error(this._translateService.instant("notification.error.ad_user_not_exists"));
          } else if (+response.responseCode === 1) {
            this._router.navigateByUrl("/");
          }
        });
    } else this._toastrService.error(this._translateService.instant("login.password_incorrect"));
  }

  /**
   * @description To prepare the new password form
   * @private
   */
  private _prepareForm(): void {
    this.newPasswordForm = this._formBuilder.group({
      verificationMethod: [VerificationMethod.SMS],
      verificationCode: [null, Validators.required],
      firstQuestion: [null, Validators.required],
      secondQuestion: [null, Validators.required],
      password: [null, Validators.required],
      confirmPassword: [null, Validators.required],
    });
  }

  /**
   * @description
   * @private
   */
  private _getUserByToken(isQuestionsAnswered: boolean = true): void {
    this._loginService.getUserbyToken(this._token).subscribe((response: MainResponseModel) => {
      if (response.inError)
        this.errorMessage =
          response.responseCode === "39" || response.responseCode === "38"
            ? `notification.error.code_${response.responseCode}`
            : undefined;

      this.user = <LoginResponse>response.responseData;
      this.isUserDefined = this.adaaHelper.isDefined(this.user);

      this.resetPasswordOptions = [
        {
          value: this.verificationMethod.SMS,
          text: `${this._translateService.instant("login.text_message")} ${this._getEncodeMobileNumber(this.user)}`,
        },
      ];

      if (isQuestionsAnswered)
        this.resetPasswordOptions = [
          ...this.resetPasswordOptions,
          {
            value: this.verificationMethod.QUESTIONS,
            text: this._translateService.instant("login.text_questions"),
          },
        ];
    });
  }

  /**
   * @description
   * @private
   */
  private _getSecurityQuestions(): void {
    this._loginService.getSecurityQuestions(this._token).subscribe((response: MainResponseModel) => {
      this.questions = <SecurityQuestion[]>response.responseData;
      this._getUserByToken(AdaaHelper.isDefined(this.questions) && this.questions.length > 0);
    });
  }

  /**
   * @description To encode the mobile number so we can display just the last three digits.
   * @param userInformation
   * @private
   */
  private _getEncodeMobileNumber(userInformation: LoginResponse | undefined): string {
    let encodedMobilePhone = "";
    if (userInformation) {
      if (userInformation.mobilePhone) {
        encodedMobilePhone = AdaaHelper.clone(userInformation.mobilePhone);
        const lastThreeDigits = encodedMobilePhone.slice(-3);
        const mobileNumberStars = encodedMobilePhone.replace(/\S/gi, "*");
        const substr = mobileNumberStars.length - 3;
        encodedMobilePhone =
          this._appService.language() === Language.English
            ? mobileNumberStars.slice(0, substr) + lastThreeDigits
            : lastThreeDigits + mobileNumberStars.slice(0, substr);
      }
    }
    return encodedMobilePhone;
  }

  /**
   * @description To lock the verification button from keep sending requests to
   * the API, the user will be able to resend the verification code in defined number.
   */
  private _lockVerificationButton(): void {
    this.lockCounter = 30;
    setInterval(() => {
      if (this.lockCounter !== 0) {
        this.lockCounter -= 1;
      }
    }, 1000);
  }

  /**
   * @description To get the form data for validate by questions
   * @param firstQuestion:string
   * @param secondQuestion:string
   * @private
   */
  private _getDataValidateByQuestions(firstQuestion: string, secondQuestion: string): SecurityQuestionAnswerModel {
    return {
      securityQuestions: [
        { userId: this.user?.id, securityQuestionId: this.questions[0].id, answer: firstQuestion },
        { userId: this.user?.id, securityQuestionId: this.questions[1].id, answer: secondQuestion },
      ],
    };
  }

  /**
   * @description To get the form data for validate by code
   * @param verificationCode:string
   * @private
   */
  private _getDataValidateByCode(verificationCode: string): VerificationRequestSubmit {
    return {
      userId: this.user?.id,
      token: verificationCode,
      doNotAskAgain: false,
    };
  }

  /**
   * @description To get the form data for new password form
   * @param password:string
   * @private
   */
  private _getDataPassword(password: string): NewPassword {
    return {
      email: this.user?.email,
      newPassword: password,
    };
  }
}
