import { CommonModule } from "@angular/common";
import { Component, computed, inject, OnInit, signal, viewChild } from "@angular/core";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

import { TranslateTokenPipe } from "../../../../core/pipes";
import { AdaaHelper } from "../../../../core/utils";
import { DataTableComponent, InfoBarComponent, PeriodSliderComponent } from "../../../../shared/components";
import { SelectedPeriodModelType, TableButtonClicked } from "../../../../shared/models";
import { LanguageService, SdgDashboardService, SystemLayoutService } from "../../../../shared/services";

@Component({
  selector: "adaa-sdg-details",
  standalone: true,
  imports: [
    CommonModule,
    PeriodSliderComponent,
    TranslateModule,
    RouterModule,
    TranslateTokenPipe,
    DataTableComponent,
    InfoBarComponent,
  ],
  templateUrl: "./sdg-details.component.html",
  styleUrl: "./sdg-details.component.scss",
})
export class SdgDetailsComponent implements OnInit {
  private _activatedRoute = inject(ActivatedRoute);
  private _languageService = inject(LanguageService);
  private _systemLayoutService = inject(SystemLayoutService);
  private _router = inject(Router);
  sdgDashboardService = inject(SdgDashboardService);

  private _untilDestroyed = AdaaHelper.untilDestroyed();
  subGovId = signal<number | undefined>(undefined);
  AdaaHelper = AdaaHelper;
  tooltip: number | undefined = undefined;
  paramsStringKPIs = computed(
    () => `periodId=${this.sdgDashboardService.selectedPeriod()?.period?.id}&subGovId=${this.subGovId()}`
  );
  paramsStringInitiatives = computed(() => `subGovId=${this.subGovId()}`);

  private readonly _kpisTable = viewChild<DataTableComponent>("kpisTable");
  private readonly _initiativesTable = viewChild<DataTableComponent>("initiativesTable");

  public get imageUrl(): string {
    return `assets/images/SDG/sdg-${this._languageService.current()}/${this.sdgDashboardService.sdgGovDirectionData()?.displayOrder}.jpg`;
  }

  public ngOnInit(): void {
    this._activatedRoute.params.pipe(this._untilDestroyed()).subscribe((e) => {
      this.subGovId.set(+e.id);
      this.sdgDashboardService.getSdgGovDirection(this.sdgDashboardService.selectedPeriod(), this.subGovId());
    });

    this._systemLayoutService.hasActiveEntityChanged$.pipe(this._untilDestroyed()).subscribe(() => {
      this.sdgDashboardService.getSdgGovDirection(this.sdgDashboardService.selectedPeriod(), this.subGovId());
      this._kpisTable()?.resetFilters();
      this._initiativesTable()?.resetFilters();
    });

    this._systemLayoutService.hasCycleChanged$.pipe(this._untilDestroyed()).subscribe(() => window.location.reload());
  }

  public selectPeriod(data: SelectedPeriodModelType) {
    if (!data) {
      return;
    }
    this.sdgDashboardService.selectedPeriod.set(data);
    this.sdgDashboardService.getSdgGovDirection(data, this.subGovId());
  }

  public actionClickedKpi(event: TableButtonClicked): void {
    if (event.event === "view") {
      this._router.navigateByUrl(
        `console/kpi/dtkpi/${event.data.viewKpiId}?periodId=${this.sdgDashboardService.selectedPeriod()?.period.id}`
      );
    }
  }

  public actionClickedProject(event: TableButtonClicked): void {
    if (event.event === "view") {
      this._router.navigateByUrl(
        `console/initiatives/${event.data.id}?periodId=${this.sdgDashboardService.selectedPeriod()?.period.id}`
      );
    }
  }
}
