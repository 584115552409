<adaa-data-table
    #dataTable
    [titleHeader]="kpiType()?.title!"
    [configKey]="kpiType()?.key!"
    [apiName]="'getAllV2'"
    [paramsString]="kpiType()?.params!"
    [staticSearchFields]="staticSearchFields()"
    [customActionButtons]="customActionButtons()"
    [enableEditAction]="true"
    [enableViewAction]="true"
    [enableDeleteAction]="showDeleteAction()"
    [enableConnectionsAction]="true"
    [enableAdd]="isPmoAndE_NT_DTKPI"
    [itemTypeId]="kpiPermissionId()"
    [disableDeleteRules]="disableEditDeleteRules"
    [disableEditRules]="disableEditDeleteRules"
    (addNewClicked)="router.navigate([kpiType()?.path?.toCreate()])"
    (actionClicked)="handleTableAction($event)"
/>

<adaa-float-action />
