import { Route } from "@angular/router";

import { checkIfLoggedInFn } from "../../core/guards";
import { Constants } from "../../shared/constants/constants";
import { viewEntityReportsGuards } from "./_guards";
import { EntityReportsListComponent } from "./entity-reports-list/entity-reports-list.component";

export const ENTITY_REPORTS_ROUTES: Route[] = [
  {
    path: "docs",
    canActivate: [checkIfLoggedInFn, ...viewEntityReportsGuards],
    children: [
      {
        path: "",
        component: EntityReportsListComponent,
        canActivate: [checkIfLoggedInFn, ...viewEntityReportsGuards],
        data: {
          breadcrumb: "documents.title",
          title: "documents.title",
        },
      },
    ],
    data: {
      breadcrumb: "documents.title",
      tableConfList: Constants.MAIN_TABLE_LIST_CONF_KEY.PILLARS,
    },
  },
];
