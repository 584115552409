import { Component, computed, inject, input, OnInit, signal } from "@angular/core";
import { FaIconLibrary, FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { TranslateModule } from "@ngx-translate/core";
import { BaseChartDirective } from "ng2-charts";

import { AdaaHelper } from "../../../../core/utils";
import { RibbonComponent } from "../../../../shared/components";
import { Constants } from "../../../../shared/constants/constants";
import { Language } from "../../../../shared/constants/enums";
import { ChartsService, LanguageService, SystemLayoutService, TpDashboardService } from "../../../../shared/services";

@Component({
  selector: "adaa-tp-progerss",
  imports: [BaseChartDirective, TranslateModule, FontAwesomeModule, RibbonComponent],
  templateUrl: "./tp-progerss.component.html",
  styleUrl: "./tp-progerss.component.scss",
})
export class TpProgerssComponent implements OnInit {
  private _iconLibrary: FaIconLibrary = inject(FaIconLibrary);
  private _languageService = inject(LanguageService);
  private _systemLayoutService = inject(SystemLayoutService);
  tpService = inject(TpDashboardService);
  chartService = inject(ChartsService);

  private readonly _itemsInPage = 20;
  private _untilDestroyed = AdaaHelper.untilDestroyed();

  filterBy = input.required<"progerss" | "achievement">();

  currentPage = signal<number>(0);

  public get isArabic() {
    return this._languageService.current() === Language.Arabic;
  }

  offset = computed(() => this.currentPage() * this._itemsInPage);
  numberOfPages = computed(() =>
    Math.ceil(
      this.tpService.dashboardData() ? this.tpService.dashboardData()!.nationalProjects.length / this._itemsInPage : 0
    )
  );

  chartLabels = computed(() => {
    const data = this.tpService
      .dashboardData()
      ?.nationalProjects.slice(this.offset(), this.offset() + this._itemsInPage)
      .map((data) => {
        const label = AdaaHelper.getItemValueByToken(data, "name");
        if (!label) return "";
        return label;
      });

    return data;
  });

  chartDataset = computed(() => {
    const barChartData = this.tpService
      .dashboardData()
      ?.nationalProjects.slice(this.offset(), this.offset() + this._itemsInPage);

    const barChartColors = this.tpService
      .dashboardData()
      ?.nationalProjects.slice(this.offset(), this.offset() + this._itemsInPage)
      .map((data) => {
        const label = AdaaHelper.getItemValueByToken(data, "name");

        if (!label) {
          return this._getColor(Constants.CONSTANT_COLORS.EVALUATION_GRAY);
        }
        return this._getColor(this.filterBy() === "progerss" ? data.progressColor : data.visionScoreColor);
      });

    const datasets = [
      {
        data: barChartData,
        backgroundColor: barChartColors,
        order: 1,
        tension: 0.5,
        barThickness: 15,
      },
    ];

    return datasets;
  });

  dashboardWidgets = computed(() => {
    const items = [
      {
        label: "national_projects.console_title",
        data: this.tpService.dashboardData()?.nationalProjectCount,
        icon: "adaa-icon-national_projects",
      },
      {
        label: "national_projects.national_targets",
        data: this.tpService.dashboardData()?.mainOutcomeCount,
        icon: "adaa-icon-targets",
      },
    ];

    return items;
  });

  public ngOnInit(): void {
    this._iconLibrary.addIcons(faAngleRight, faAngleLeft);

    this.tpService.dashboardFilters.cycleId = +AdaaHelper.getLocalStorage(Constants.localStorageKeys.tpCycle);
    this.tpService.dashboardFilters.filterBy = this.filterBy();

    this.tpService.initDashboard();
    this.tpService.getData(false);

    this.chartService.initTpProgressChartOptions(this.filterBy() === "progerss");

    this._systemLayoutService.hasActiveEntityChanged$.pipe(this._untilDestroyed()).subscribe(() => {
      this.tpService.resetFilters(false);
      this.currentPage.set(0);

      this.tpService.initDashboard();
      this.tpService.getData(false);
    });
  }

  public movePrev(): void {
    if (this.currentPage() === 0) return;
    this.currentPage.set(this.currentPage() - 1);
  }

  public moveNext(): void {
    if (this.currentPage() === this.numberOfPages() - 1) return;
    this.currentPage.set(this.currentPage() + 1);
  }

  private _getColor(code: number) {
    return Constants.COLOR_CODES[code];
  }
}
