import { Component, computed, inject, type OnInit, signal, type TemplateRef } from "@angular/core";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";

import { TranslateTokenPipe } from "../../../core/pipes";
import {
  type CommonMeasureType,
  ItemSliderComponent,
  PeriodSliderComponent,
  PictureComponent,
  RibbonComponent,
  type SimpleWidgetData,
  UaeVisionPerfCardComponent,
} from "../../../shared/components";
import { Constants } from "../../../shared/constants/constants";
import { Language } from "../../../shared/constants/enums";
import {
  NationalAgendaEntityModelType,
  NationalAgendaKpiModelType,
  NationalAgendaModelType,
  type SelectedPeriodModelType,
} from "../../../shared/models";
import { LanguageService, NationalAgendaApiService, PeriodSliderService } from "../../../shared/services";
import { WtuaeEntityCardComponent } from "./wtuae-entity-card/wtuae-entity-card.component";
import { WtuaeNtkpiCardComponent } from "./wtuae-ntkpi-card/wtuae-ntkpi-card.component";

@Component({
  selector: "adaa-national-agenda",
  standalone: true,
  templateUrl: "./national-agenda.component.html",
  styleUrl: "./national-agenda.component.scss",
  imports: [
    PeriodSliderComponent,
    RibbonComponent,
    TranslateModule,
    TranslateTokenPipe,
    WtuaeNtkpiCardComponent,
    WtuaeEntityCardComponent,
    RouterLink,
    PictureComponent,
    ItemSliderComponent,
    UaeVisionPerfCardComponent,
  ],
})
export class NationalAgendaComponent implements OnInit {
  readonly router = inject(Router);
  private readonly _route = inject(ActivatedRoute);
  private readonly _modalService = inject(NgbModal);
  private readonly _periodSliderService = inject(PeriodSliderService);
  private readonly _nationalAgendaApiService = inject(NationalAgendaApiService);
  private readonly _languageService = inject(LanguageService);

  selectedSubGovDir = signal<number | undefined>(undefined);
  selectedTab = signal<1 | 2>(1);
  selectedEntity = signal<NationalAgendaEntityModelType | undefined>(undefined);
  selectedPeriod = signal<SelectedPeriodModelType | undefined>(undefined);
  data = signal<NationalAgendaModelType | undefined>(undefined);
  ntkpis = signal<NationalAgendaKpiModelType[]>([]);
  entities = signal<NationalAgendaEntityModelType[]>([]);

  isArabic = computed(() => this._languageService.current() === Language.Arabic);

  filteredNtkpis = computed<NationalAgendaKpiModelType[]>(() =>
    this.ntkpis().filter((e) => e.entityId === this.selectedEntity()?.id)
  );
  widgets = computed<SimpleWidgetData[]>(() => [
    {
      isDuo: false,
      data: this.data()?.ntKpiCount,
      label: "national_agenda.main_dashboard.ntkpis",
    },
    {
      isDuo: false,
      data: this.data()?.entityHavingNtKpiCount,
      label: "national_agenda.main_dashboard.government_entities",
    },
    {
      isDuo: false,
      data: this.data()?.contributingEntityHavingNtKpiCount,
      label: "national_agenda.main_dashboard.contributing_entities",
    },
    {
      isDuo: false,
      data: this.data()?.challengeCount,
      label: "national_agenda.main_dashboard.government_challenges",
    },
    {
      isDuo: false,
      data: this.data()?.successStoryCount,
      label: "national_agenda.main_dashboard.government_achievements",
    },
    {
      isDuo: false,
      data: this.data()?.nationalProjectCount,
      label: "national_agenda.main_dashboard.transformational_projects",
    },
    {
      isDuo: false,
      data: this.data()?.linkedDTKpiCount,
      label: "national_agenda.main_dashboard.linked_dtkpi",
    },
    {
      isDuo: false,
      data: this.data()?.linkedSKpiCount,
      label: "national_agenda.main_dashboard.linked_skpi",
    },
  ]);
  subGovDirs = computed(() => [
    {
      data: {
        id: this.data()?.id,
        logo: this.data()?.logo as number,
        performance: this.data()?.performance,
        performanceColor: this.data()?.performanceColor,
        visionAchievement: this.data()?.visionAchievement,
        visionAchievementColor: this.data()?.visionAchievementColor,
        nameAE: this.data()?.nameAE,
        nameEN: this.data()?.nameEN,
      } as CommonMeasureType,
      imageType: "svg" as const,
      indicators: {
        performance: true,
        visionAchievement: true,
        ntkpiCount: false,
      },
    },
    ...(this.data()?.subGovDirections ?? []).map((subGovDir) => ({
      data: {
        id: subGovDir.id,
        logo: subGovDir.logo,
        performance: subGovDir.performance!,
        performanceColor: subGovDir.performanceColor!,
        visionAchievement: subGovDir.visionAchievement!,
        visionAchievementColor: subGovDir.visionAchievementColor!,
        ntKpiCount: subGovDir.ntKpiCount!,
        nameAE: subGovDir.nameAE,
        nameEN: subGovDir.nameEN,
      } as CommonMeasureType,
      imageType: "normal" as const,
      indicators: {
        performance: true,
        visionAchievement: true,
        ntkpiCount: true,
      },
    })),
  ]);

  readonly periodSliderFrequency = Constants.FREQUENCY.ANNUAL;

  public ngOnInit() {
    this._periodSliderService.getActualPeriod("year", true).subscribe({
      next: () => {
        const year = this._periodSliderService.actualPeriod()?.period.year;
        this.selectedPeriod.update(() => this._periodSliderService.actualPeriod());
        this._queryData(year!);
      },
    });
  }

  public selectPeriod(period: SelectedPeriodModelType) {
    if (!period) return;
    this._queryData(period.period.year);
    this.selectedPeriod.update(() => period);
  }

  public selectSubGovDir(id?: number) {
    this.selectedSubGovDir.set(id);
    if (id === this.data()?.id) {
      id = undefined;
    }
    this._getEntities(id);
    this._getNtKPIs(id);
  }

  public openModal(content: TemplateRef<unknown>) {
    this._modalService.open(content, {
      animation: true,
      scrollable: true,
      keyboard: true,
      size: "xl",
      centered: true,
      fullscreen: false,
      modalDialogClass: this._languageService.modalDirection(),
      beforeDismiss: () => {
        this.selectedEntity.set(undefined);
        return true;
      },
    });
  }

  private _queryData(year: number) {
    this._nationalAgendaApiService.getDashboardData(year).subscribe({
      next: (res) => {
        if (res.inError) return;
        this.data.update(() => res.responseData);
        this._usingQueryParams();
      },
    });
  }

  private _usingQueryParams() {
    this._route.queryParams.subscribe({
      next: (qs) => {
        let subGovDirId: number | undefined;
        if ("sub_gov_direction_id" in qs) {
          subGovDirId = qs["sub_gov_direction_id"];
        } else {
          subGovDirId = this.data()?.id;
        }
        this.selectSubGovDir(subGovDirId);
      },
    });
  }

  private _getEntities(subGovDirId?: number) {
    this._nationalAgendaApiService
      .getEntities({
        year: this.selectedPeriod()?.period?.year as number,
        govDirId: this.data()?.id as number,
        subGovDirId,
      })
      .subscribe({
        next: (res) => {
          if (res.inError) return;
          this.entities.update(() => res.responseData);
        },
      });
  }

  private _getNtKPIs(subGovDirId?: number) {
    this._nationalAgendaApiService
      .getKpis({
        year: this.selectedPeriod()?.period?.year as number,
        govDirId: this.data()?.id as number,
        subGovDirId,
      })
      .subscribe({
        next: (res) => {
          if (res.inError) return;
          this.ntkpis.update(() => res.responseData);
        },
      });
  }
}
