@if (showLoader()) {
    <p class="fw-bold fs-4">{{ "report.title" | translate }}</p>
    <div class="col-12 bg-white p-3 justify-content-center align-items-center mt-2">
        <div class="bg text-center p-3">
            <p>{{ "report.loading_report" | translate }}</p>
            <div class="w-100 d-flex justify-content-center py-2">
                <div class="zsk__loader zsk__loader--sm"></div>
            </div>
            <p class="mt-3">{{ "report.background_message" | translate }}</p>
        </div>
    </div>
} @else {
    <div class="col-12">
        <adaa-data-table
            #adaaDataTable
            apiName="getAllV2"
            [titleHeader]="'report.title' | translate"
            [apiPrefix]="'report'"
            [configKey]="tableConfList()"
            [enableAdd]="false"
            [enableEditAction]="false"
            [enableDeleteAction]="false"
            [enableViewAction]="false"
            [enablePrint]="true"
            [itemTypeId]="Constants.CONSTANT_PERMISSIONS.REPORTS"
            (actionClicked)="reportGeneration.open($event.data)"
            [customActionButtons]="customButtons"
        ></adaa-data-table>
    </div>
}

<adaa-report-generation #reportGeneration (showLoadingPage)="showLoader.set($event)" adaa-report-generation></adaa-report-generation>
