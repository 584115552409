import { Component, computed, effect, inject, input, OnInit, output } from "@angular/core";
import { ControlContainer, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";

import { AdaaHelper } from "../../../../../../../core/utils";
import { FormDropdownComponent } from "../../../../../../../shared/components";
import { TransformationalTarget, ValueText } from "../../../../../../../shared/models";

@Component({
  selector: "adaa-transformational-target-field",
  imports: [ReactiveFormsModule, TranslateModule, FormDropdownComponent],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true }),
    },
  ],
  template: `
    <adaa-form-dropdown
      [controlName]="fieldName"
      [formControlName]="fieldName"
      [clearable]="true"
      [searchable]="true"
      [label]="'transformational_target' | translate"
      [options]="listOptions()"
      [invalid]="model.get(fieldName)?.touched! && model.get(fieldName)?.invalid!"
      [showAsRequired]="isRequired()"
    />
  `,
})
export class TransformationalTargetFieldComponent implements OnInit {
  readonly form = inject(ControlContainer);

  isDisabled = input<boolean>(false);
  isRequired = input.required<boolean>();
  list = input<TransformationalTarget[]>([]);

  listOptions = computed<ValueText[]>(() =>
    AdaaHelper.setDropdownArray(this.list(), "id", AdaaHelper.getFieldLanguage("name"))
  );

  onChange = output<number>();

  readonly fieldName = "transformationalTarget";

  readonly #untilDestroy = AdaaHelper.untilDestroyed();

  public get model() {
    return this.form.control as FormGroup;
  }

  constructor() {
    effect((onCleanup) => {
      const disable = this.isDisabled();
      if (disable) {
        this.model.get(this.fieldName)?.disable();
      } else {
        this.model.get(this.fieldName)?.enable();
      }

      onCleanup(() => {
        this.model.controls[this.fieldName]?.enable({ onlySelf: true, emitEvent: true });
      });
    });
  }

  public ngOnInit() {
    this._outputChanges();
  }

  private _outputChanges() {
    this.model.controls[this.fieldName]?.valueChanges?.pipe(this.#untilDestroy()).subscribe({
      next: () => {
        const value = this.model.controls[this.fieldName]?.getRawValue();
        this.onChange.emit(value);
      },
    });
  }
}
