import { Component, EventEmitter, input, Output } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "adaa-kpi-formula-selector",
  standalone: true,
  styleUrl: "../styles.scss",
  imports: [TranslateModule],
  template: `
    <button
      class="btn rounded-circle formula-selector-btn d-flex justify-content-center align-items-center p-0 m-0"
      [disabled]="isLinkedToScope()"
      (click)="onFormulaSelection.emit()"
    >
      <svg id="adaa-list-view" viewBox="0 0 55 40" width="21px">
        <title>{{ "formulas.formula_list" | translate }}</title>
        <path
          d="M14.71,11.86a3.57,3.57,0,0,0,.47-2.51,1.13,1.13,0,0,0-1.11-.92H11.73l.06-.31a16.31,16.31,0,0,1,.72-2.56,4.83,4.83,0,0,1,.68-1.26,1.42,1.42,0,0,1,.5-.42l.44-.16,2.08,1.7a1.13,1.13,0,0,0,1.44,0,4.31,4.31,0,0,0,1.28-1.83,7.08,7.08,0,0,0,.41-1.81,1.13,1.13,0,0,0-.62-1.1A5.87,5.87,0,0,0,17.08.15,9.57,9.57,0,0,0,15.54,0a11.67,11.67,0,0,0-2.85.34,7,7,0,0,0-2.63,1.29A8.09,8.09,0,0,0,8,4.11,13.29,13.29,0,0,0,6.71,7.86l-.1.5-.86.31a12.28,12.28,0,0,0-1.56.75,1.12,1.12,0,0,0-.57,1v.9a1.13,1.13,0,0,0,1.13,1.13H5.87l-2,12.9A13.29,13.29,0,0,1,3.2,28a11,11,0,0,1-.94,1.92,5.76,5.76,0,0,1-.93,1.16,3.65,3.65,0,0,1-.71.54,1.12,1.12,0,0,0-.54,1.44l.3.74a1.13,1.13,0,0,0,1,.7,1,1,0,0,0,.3,0,9.73,9.73,0,0,0,1.64-.68h0a8.91,8.91,0,0,0,2.21-1.64,12.19,12.19,0,0,0,2.05-2.79A13,13,0,0,0,9,25l2-12.62h2.75A1.13,1.13,0,0,0,14.71,11.86Z"
        />
        <path
          d="M22.85,27.3A19,19,0,0,1,22.19,22a17.58,17.58,0,0,1,.8-5.56,22.77,22.77,0,0,1,2.18-4.86,27.28,27.28,0,0,1,3-4.06A35.3,35.3,0,0,1,31.4,4.26a1.13,1.13,0,0,0,.09-1.63L30.92,2a1.12,1.12,0,0,0-1.42-.18A35.87,35.87,0,0,0,25.25,5a25.29,25.29,0,0,0-4,4.43,23.9,23.9,0,0,0-3,5.71,20.63,20.63,0,0,0-1.19,7.18,15,15,0,0,0,1.32,6.23,16.44,16.44,0,0,0,4.39,5.56,1.12,1.12,0,0,0,.73.27,1.14,1.14,0,0,0,.64-.2l1-.67a1.13,1.13,0,0,0,.23-1.64A12.78,12.78,0,0,1,22.85,27.3Z"
        />
        <path
          d="M40.2,27c.33-.14.64-.29.92-.44s.54-.29.7-.4a1.13,1.13,0,0,0,.5-.94v-.84a1.13,1.13,0,0,0-1-1.12l-1.49-.18L37.94,18l.34-.31c.4-.36.83-.76,1.29-1.21S40.5,15.54,41,15,42,14,42.4,13.44a14.39,14.39,0,0,0,1.2-1.67,7.54,7.54,0,0,0,.78-1.7,1.13,1.13,0,0,0-.54-1.32,5.52,5.52,0,0,0-3.77-.43,10.27,10.27,0,0,0-1.26.36,1.13,1.13,0,0,0-.73,1.24c0,.11,0,.28.06.53l.07.89c0,.3,0,.57,0,.84s0,.23,0,.32a8.46,8.46,0,0,1-1.11,1.41l-.32.33L35.65,10.2A4.21,4.21,0,0,0,34.91,9,2.44,2.44,0,0,0,33,8.22a4.94,4.94,0,0,0-1.26.17,9,9,0,0,0-1.14.38,8.4,8.4,0,0,0-1,.49c-.29.17-.51.3-.66.4a1.12,1.12,0,0,0-.49.93v.84a1.13,1.13,0,0,0,1,1.12l1.46.18,1.77,4.79-1,.91q-1.1,1-2.27,2.18a27.9,27.9,0,0,0-2.16,2.51,8.53,8.53,0,0,0-1.42,2.6,1.13,1.13,0,0,0,.51,1.3,5.45,5.45,0,0,0,3.73.47,6.62,6.62,0,0,0,1.39-.4A1.13,1.13,0,0,0,32,25.85a2.87,2.87,0,0,1,0-.42c0-.27,0-.55,0-1.12l0-.66a2.66,2.66,0,0,0,0-.29,5.56,5.56,0,0,1,.47-.67c.24-.31.51-.62.81-.93l.45-.47,1.29,4.26a3.27,3.27,0,0,0,.82,1.36,2.56,2.56,0,0,0,1.88.69A4.87,4.87,0,0,0,39,27.44,7.15,7.15,0,0,0,40.2,27Z"
        />
        <path
          d="M52.31,7.62A18.21,18.21,0,0,0,47.91,2a1.13,1.13,0,0,0-1.4-.09l-.87.6a1.13,1.13,0,0,0-.27,1.6A21.64,21.64,0,0,1,47,6.57a13.5,13.5,0,0,1,1,2.25,12.42,12.42,0,0,1,.49,2.24,21,21,0,0,1,.15,2.54,19.24,19.24,0,0,1-.9,5.94,26.08,26.08,0,0,1-2.27,5.14,25.25,25.25,0,0,1-3,4.11,28.2,28.2,0,0,1-3,3,1.12,1.12,0,0,0-.41.81,1.13,1.13,0,0,0,.33.85l.67.67a1.13,1.13,0,0,0,.8.33,1.14,1.14,0,0,0,.56-.15A26.15,26.15,0,0,0,45.78,31a25.68,25.68,0,0,0,3.94-4.53,24,24,0,0,0,2.85-5.71,20.88,20.88,0,0,0,1.1-6.84A14.81,14.81,0,0,0,52.31,7.62Z"
        />
      </svg>
    </button>
  `,
})
export class KpiFormulaSelectorComponent {
  //If the KPI is linked to scope disabled the formula and formula selection
  isLinkedToScope = input<boolean>(false);

  @Output() onFormulaSelection = new EventEmitter();
}
