<div class="p-2">
    <adaa-data-table
        #adaaDataTable
        apiName="getLookupList"
        [titleHeader]="'lookup.management.title' | translate"
        [configKey]="'lookup_table_management_config'"
        [itemTypeId]="constants.CONSTANT_PERMISSIONS.LOOKUP_MANAGEMENT"
        [paramsString]="'itemType=67'"
        [enableAdd]="false"
        [enableEditAction]="true"
        [enableDeleteAction]="true"
        [enableViewAction]="true"
        [enableAdd]="false"
        [enableViewAction]="false"
        [enableDeleteAction]="false"
        (actionClicked)="getAction($event)"
    ></adaa-data-table>
</div>

<adaa-float-action />
