import { NgClass } from "@angular/common";
import { Component, computed, effect, inject, Injector, input, signal, untracked } from "@angular/core";
import { RouterLink } from "@angular/router";
import { NgxSliderModule, Options } from "@angular-slider/ngx-slider";
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";

import { TranslateTokenPipe } from "../../../../../core/pipes";
import { AdaaHelper } from "../../../../../core/utils";
import { DoughnutComponent } from "../../../../../shared/components";
import { Constants } from "../../../../../shared/constants/constants";
import { Language } from "../../../../../shared/constants/enums";
import { NationalTargetImprovementPlanType } from "../../../../../shared/models";
import { LanguageService } from "../../../../../shared/services";

@Component({
  selector: "adaa-improvement-plan",
  standalone: true,
  imports: [TranslateModule, NgxSliderModule, RouterLink, TranslateTokenPipe, DoughnutComponent, NgbTooltip, NgClass],
  templateUrl: "./improvement-plan.component.html",
  styleUrl: "./improvement-plan.component.scss",
})
export class ImprovementPlanComponent {
  private readonly _injector = inject(Injector);
  private readonly _languageService = inject(LanguageService);
  private readonly _translateService = inject(TranslateService);

  index = input.required<number>();
  data = input.required<NationalTargetImprovementPlanType>();

  sliderOption = signal<Options | undefined>(undefined);

  plannedTimelineTooltip = computed(() => {
    const data = this.data();

    let obj: Partial<{
      start: { title: string; date: string | number };
      end: { title: string; date: string | number };
    }> = {};

    if (data.startDate) {
      obj = {
        ...obj,
        start: {
          title: `${this._translateService.instant("national_projects.planned")} ${this._translateService.instant("common.form.label.start_date")}`,
          date: AdaaHelper.formatDate(data.startDate, false),
        },
      };
    }

    if (data.endDate) {
      obj = {
        ...obj,
        end: {
          title: `${this._translateService.instant("national_projects.planned")} ${this._translateService.instant("common.form.label.end_date")}`,
          date: AdaaHelper.formatDate(data.endDate, false),
        },
      };
    }

    return obj;
  });
  actualTimelineTooltip = computed(() => {
    const data = this.data();

    let obj: Partial<{
      start: { title: string; date: string | number };
      end: { title: string; date: string | number };
    }> = {};

    if (data.actualStartDate) {
      obj = {
        ...obj,
        start: {
          title: `${this._translateService.instant("national_projects.actual")} ${this._translateService.instant("common.form.label.start_date")}`,
          date: AdaaHelper.formatDate(data.actualStartDate, false),
        },
      };
    }

    if (data.actualEndDate) {
      obj = {
        ...obj,
        end: {
          title: `${this._translateService.instant("national_projects.actual")} ${this._translateService.instant("common.form.label.end_date")}`,
          date: AdaaHelper.formatDate(data.actualEndDate, false),
        },
      };
    }

    return obj;
  });

  readonly colorId = Constants.CONSTANT_COLORS.EVALUATION_GOLD;
  readonly isDefinedAndNotEmpty = AdaaHelper.isDefinedAndNotEmpty.bind(AdaaHelper);

  constructor() {
    this._sliderOptionEffect();
  }

  private _sliderOptionEffect() {
    effect(
      () => {
        const data = this.data();
        const dateRange: Date[] = this._getDateRange(data);

        untracked(() => {
          this.sliderOption.set({
            rightToLeft: this._languageService.current() === Language.Arabic,
            showTicks: false,
            disabled: true,
            stepsArray: dateRange.map((date: Date) => {
              return { value: date.getTime() };
            }),
            translate: (value: number): string => {
              const val = AdaaHelper.formatDate(value, false, {
                year: "numeric",
                month: "short",
              });

              return typeof val === "string" ? val : "";
            },
          });
        });
      },
      {
        injector: this._injector,
      }
    );
  }

  private _getDateRange(data: NationalTargetImprovementPlanType): Date[] {
    const dates = [];
    const startDate = new Date(data.startDate);
    const endDate = new Date(data.endDate);

    for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
      dates.push(new Date(d));
    }

    return dates;
  }
}
