<div class="ribbon" [class.ar]="lang() === Language.Arabic">
    <adaa-ribbon
        [widgetData]="dashboardWidgets()"
        [showFilterIcon]="true"
        [filterApplied]="tpService.isFilterApplied"
        (refreshClicked)="tpService.resetFilters()"
        (filterClicked)="tpService.openFilters()"
    />
</div>

<div class="row mt-3 me-1">
    <div class="col-md-3 col-sm-12 col-xs-12">
        <div class="row">
            <div class="col-12 mb-3">
                <div class="bg-white shadow-md rounded p-3 d-flex flex-column justify-content-center align-items-center">
                    <div class="col-12">
                        <adaa-form-dropdown
                            [setDefaultValue]="tpService.dashboardFilters.cycleId"
                            [options]="tpService.dashboardCycles"
                            (inputChanges)="cycleChanged($event)"
                        ></adaa-form-dropdown>
                    </div>
                    <adaa-doughnut
                        [label]="'national_projects.overall_project_progress' | translate"
                        [colorId]="tpService.dashboardData()?.overallOnTimeProgressColor!"
                        [amount]="tpService.dashboardData()?.overallProgress!"
                        (onclick)="navigateToProgressDashboard()"
                    />
                    <adaa-doughnut
                        [label]="'national_projects.accumulated_annual_score' | translate"
                        [colorId]="tpService.dashboardData()?.accumulatedAnnualScoreColor!"
                        [amount]="tpService.dashboardData()?.accumulatedAnnualScore!"
                    />
                    <adaa-doughnut
                        [label]="'national_projects.overall_target_achievement' | translate"
                        [colorId]="constants.CONSTANT_COLORS.EVALUATION_GOLD"
                        [amount]="tpService.dashboardData()?.overallVisionScore!"
                        (onclick)="navigateToAchievementDashboard()"
                    />
                </div>
            </div>

            <div class="col-12">
                <div class="bg-white shadow-md rounded p-3">
                    @if (!adaaHelper.isPMOEntity()) {
                        <div class="row d-flex justify-content-end mb-2">
                            <span
                                class="col-1 fa fa-question text-primary text-center mb-1 mx-2"
                                [ngbPopover]="projectStatusHelp.getText()"
                                triggers="mouseenter:mouseleave"
                            ></span>
                        </div>
                    }
                    <div>
                        <adaa-progress-indicator
                            class="pointer"
                            [color]="constants.CONSTANT_COLORS.EVALUATION_LIME"
                            [value]="tpService.dashboardData()?.completedWidth!"
                            [label]="('national_projects.completed_projects' | translate) + ': ' + tpService.dashboardData()?.completedCount"
                            [showValue]="false"
                            (click)="filterDashboard(TpAgmStatus.COMPLETED)"
                        />
                    </div>
                    <div class="mt-3">
                        <adaa-progress-indicator
                            class="pointer"
                            [color]="constants.CONSTANT_COLORS.EVALUATION_BLUE"
                            [value]="tpService.dashboardData()?.ongoingWidth!"
                            [label]="('national_projects.ongoing_projects' | translate) + ': ' + tpService.dashboardData()?.ongoingCount"
                            [showValue]="false"
                            (click)="filterDashboard(TpAgmStatus.ON_TIME)"
                        />
                    </div>
                    <div class="mt-3">
                        <adaa-progress-indicator
                            class="pointer"
                            [color]="constants.CONSTANT_COLORS.EVALUATION_GRAY"
                            [value]="tpService.dashboardData()?.delayedWidth!"
                            [label]="('national_projects.facing_delays' | translate) + ': ' + tpService.dashboardData()?.delayedCount"
                            [showValue]="false"
                            (click)="filterDashboard(TpAgmStatus.DELAYED)"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-9 col-sm-12 col-xs-12">
        <div class="bg-white shadow-md rounded p-3">
            @if (adaaHelper.isPMOEntity()) {
                <nav>
                    <div class="nav nav-tabs">
                        <button class="nav-link" [class.active]="activeTab === 1" type="button" (click)="activeTab = 1">
                            {{ "national_projects.project_names" | translate }}
                        </button>
                        <button class="nav-link" [class.active]="activeTab === 2" type="button" role="tab" (click)="activeTab = 2">
                            {{ "national_projects.transformational_projects_leaders" | translate }}
                        </button>
                        <button class="nav-link" [class.active]="activeTab === 3" type="button" role="tab" (click)="activeTab = 3">
                            {{ "national_projects.transformational_projects_main_sectors" | translate }}
                        </button>
                        <button class="nav-link" [class.active]="activeTab === 4" type="button" role="tab" (click)="activeTab = 4">
                            {{ "national_projects.transformational_projects_detailed_sectors" | translate }}
                        </button>
                    </div>
                </nav>
                <div class="tab-content pt-2">
                    <div class="tab-pane p-3 fade" [ngClass]="{ 'show active': activeTab === 1 }" id="nav-projects" role="tabpanel">
                        @if (tpService.dashboardData() && activeTab === 1) {
                            <adaa-tp-bars
                                [projects]="tpService.dashboardData()?.nationalProjects!"
                                (barClicked)="navigateToProject($event)"
                            ></adaa-tp-bars>
                            <button class="btn btn-outline-primary" (click)="navigateToCardsDashbaord(undefined)" type="button">
                                {{ "national_projects.view_all_projects" | translate }}
                            </button>
                        } @else {
                            <div class="w-100 d-flex justify-content-center align-items-center py-4">
                                <div class="zsk__loader zsk__loader--sm"></div>
                            </div>
                        }
                    </div>
                    <div class="tab-pane fade" [ngClass]="{ 'show active': activeTab === 2 }" id="nav-leaders" role="tabpanel">
                        @if (tpService.ownersData() && activeTab === 2) {
                            <div class="d-flex flex-wrap justify-content-center">
                                @for (item of tpService.ownersData(); track $index) {
                                    <adaa-tp-owner [owner]="item" (cardClicked)="navigateToCardsDashbaord($event)"></adaa-tp-owner>
                                }
                            </div>
                        }
                    </div>
                    <div class="tab-pane p-3 fade" [ngClass]="{ 'show active': activeTab === 3 }" id="nav-main" role="tabpanel">
                        @if (tpService.mainSectorsData() && activeTab === 3) {
                            <adaa-tp-main-sectors
                                [sectors]="tpService.mainSectorsData()!"
                                (cardClicked)="navigateToCardsDashbaord($event)"
                            ></adaa-tp-main-sectors>
                        }
                    </div>
                    <div class="tab-pane fade" [ngClass]="{ 'show active': activeTab === 4 }" id="nav-detailed" role="tabpanel">
                        @if (tpService.sectorsData() && activeTab === 4) {
                            <adaa-tp-sectors [sectors]="tpService.sectorsData()!" (cardClicked)="navigateToCardsDashbaord($event)"></adaa-tp-sectors>
                        }
                    </div>
                </div>
            } @else {
                <div class="row mb-2">
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <h4 class="fw-bold">{{ "national_projects.dashboard_title" | translate }}</h4>
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12 d-flex justify-content-end">
                        <adaa-form-checkbox
                            [label]="'national_projects.only_show_projects_owned_by_this_entity' | translate"
                            [controlName]="'allEntitiesCheck'"
                            [boldLabel]="true"
                            (inputChanges)="onlyEntityChanged($event)"
                        ></adaa-form-checkbox>
                    </div>
                </div>
                <adaa-tp-bars [projects]="tpService.dashboardData()?.nationalProjects!" (barClicked)="navigateToProject($event)"></adaa-tp-bars>
                <button class="btn btn-outline-primary" (click)="navigateToCardsDashbaord(undefined)" type="button">
                    {{ "national_projects.view_all_projects" | translate }}
                </button>
            }
        </div>
    </div>
</div>
