import { Route } from "@angular/router";

import { checkIfLoggedInFn } from "../../core/guards";
import { PageMode } from "../../shared/constants/enums";
import { viewEntitiesPageGuards } from "./_guards";
import { EntitiesEditorComponent } from "./entities-editor/entities-editor.component";
import { EntitiesListComponent } from "./entities-list/entities-list.component";

export const ENTITIES_ROUTES: Route[] = [
  {
    path: "entities",
    canActivate: [checkIfLoggedInFn, ...viewEntitiesPageGuards],
    children: [
      {
        path: "list",
        redirectTo: "",
      },
      {
        path: "",
        component: EntitiesListComponent,
        data: {
          breadcrumb: "breadcrumbs.entities",
          title: "breadcrumbs.entities",
        },
      },
      {
        path: "create",
        component: EntitiesEditorComponent,
        data: {
          breadcrumb: "breadcrumbs.entity_module.new",
          title: "breadcrumbs.entity_module.new",
          pageMode: PageMode.create,
        },
      },
      {
        path: ":id",
        component: EntitiesEditorComponent,
        data: {
          breadcrumb: "breadcrumbs.entity_module.view",
          title: "breadcrumbs.entity_module.view",
          pageMode: PageMode.view,
        },
      },
      {
        path: "edit/:id",
        component: EntitiesEditorComponent,
        data: {
          breadcrumb: "breadcrumbs.entity_module.edit",
          title: "breadcrumbs.entity_module.edit",
          pageMode: PageMode.edit,
        },
      },
    ],
  },
];
