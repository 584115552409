import {
  Component,
  computed,
  inject,
  OnDestroy,
  OnInit,
  output,
  signal,
  TemplateRef,
  viewChild,
  type WritableSignal,
} from "@angular/core";
import { FormBuilder, type FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { ToastrService } from "ngx-toastr";
import { filter, forkJoin, mergeMap, Subscription } from "rxjs";

import { AdaaHelper } from "../../../../core/utils";
import { Constants } from "../../../constants/constants";
import { AdaaBoolean, Language } from "../../../constants/enums";
import {
  BaseReportFieldType,
  CycleModelType,
  DateReportFieldType,
  type EntityModelType,
  type PropTypeModelType,
  ReportData,
  type ReportFieldListScope,
  ReportFieldParameterType,
  ReportFieldsConfig,
  ReportParameterTypeValue,
  ReportRequestModel,
  type SelectReportFieldType,
  type TableButtonClicked,
  TableInputChangedOutput,
  type TableReportFieldType,
  type TpProjectDetails,
  UserModelType,
  ValueText,
} from "../../../models";
import type { Pillar } from "../../../models/pillar.model";
import {
  AppService,
  CyclesApiService,
  EntitiesApiService,
  ItemApiService,
  KpisApiService,
  LanguageService,
  OrgUnitApiService,
  PillarsApiService,
  PropertiesService,
  ReportDataService,
  SystemLayoutService,
  TpApiService,
  UsersApiService,
} from "../../../services";
import { FormDropdownComponent, FormDropdownTreeComponent, FormInputDateComponent } from "../../form";
import { DataTableComponent } from "../../table/data-table/data-table.component";

@Component({
  selector: "adaa-report-generation",
  standalone: true,
  imports: [
    TranslateModule,
    FormDropdownComponent,
    ReactiveFormsModule,
    DataTableComponent,
    FormInputDateComponent,
    FormDropdownTreeComponent,
  ],
  providers: [ReportDataService],
  templateUrl: "./report-generation.component.html",
  styleUrl: "./report-generation.component.scss",
})
export class ReportGenerationComponent implements OnInit, OnDestroy {
  private readonly _translateService = inject(TranslateService);
  private readonly _cyclesApiService = inject(CyclesApiService);
  private readonly _reportDataService = inject(ReportDataService);
  private readonly _usersApiService = inject(UsersApiService);
  private readonly _propertiesService = inject(PropertiesService);
  private readonly _itemApiService = inject(ItemApiService);
  private readonly _pillarsApiService = inject(PillarsApiService);
  private readonly _entitiesApiService = inject(EntitiesApiService);
  private readonly _kpisApiService = inject(KpisApiService);
  private readonly _tpApiService = inject(TpApiService);
  private _systemLayoutService = inject(SystemLayoutService);
  readonly languageService = inject(LanguageService);
  private _formBuilder = inject(FormBuilder);
  private _toastrService = inject(ToastrService);
  private _orgUnitApiService = inject(OrgUnitApiService);
  private _modalService = inject(NgbModal);
  private _appService = inject(AppService);

  reportName = signal<string | undefined>(undefined);
  exportOptions = signal<ValueText[]>([]);
  languageOptions = signal<ValueText[]>([]);
  fieldList = signal<ReportFieldsConfig[]>([]);
  kpiTypes = signal<PropTypeModelType[]>([]);
  pillars = signal<Pillar[]>([]);
  cycles = signal<CycleModelType[]>([]);
  cycleYears = signal<number[]>([]);
  KPIs = signal<EntityModelType[]>([]);
  entities = signal<EntityModelType[]>([]);
  entityOwners = signal<UserModelType[]>([]);
  noneSelected = signal<boolean>(false);
  tableDataMap: {
    [index: string]: WritableSignal<object[]>;
  } = {};
  report = signal<ReportData>({
    nameAE: "",
    nameEN: "",
    descAE: "",
    descEN: "",
    type: "",
    path: null,
    fieldList: "",
    exportOption: "",
    pmo: "",
    entity: "",
    usePreGeneratedReport: null,
    preGeneratedReportValidityInDays: null,
    id: 0,
    status: 0,
    updateTS: 0,
    updateUser: null,
  });
  showLoadingPage = output<boolean>();
  selectedTableData = signal<{ parameter: ReportFieldParameterType; tableData: number[] }[]>([]);
  nationalProjects = signal<TpProjectDetails[]>([]);
  activeCycle = signal<CycleModelType>(this._systemLayoutService.activeCycle()!);
  orgUnits = signal<ValueText[]>([]);

  cycleYearOptions = computed<ValueText[]>(() => {
    const field = AdaaHelper.getFieldLanguage("name");
    const result: ValueText[] = AdaaHelper.setDropdownArray(this.cycleYears(), "id", field);
    result.unshift({ value: "none", text: this._translateService.instant("common.form.label.none") });
    return result;
  });

  kpiTypeOptions = computed<ValueText[]>(() => {
    const field = AdaaHelper.getFieldLanguage("name");
    const result: ValueText[] = AdaaHelper.setDropdownArray(this.kpiTypes(), "id", field);
    result.unshift({ value: "none", text: this._translateService.instant("common.form.label.none") });
    return result;
  });

  pillarOptions = computed<ValueText[]>(() => {
    const field = AdaaHelper.getFieldLanguage("name");
    const result: ValueText[] = AdaaHelper.setDropdownArray(this.pillars(), "id", field);
    result.unshift({ value: "none", text: this._translateService.instant("common.form.label.select_all") });
    return result;
  });

  entityOwnersOptions = computed<ValueText[]>(() => {
    const field = AdaaHelper.getFieldLanguage("name");
    const result: ValueText[] = AdaaHelper.setDropdownArray(this.entityOwners(), "id", field);
    result.unshift({ value: "none", text: this._translateService.instant("common.form.label.none") });
    return result;
  });

  cycleOptions = computed<ValueText[]>(() => {
    const field = AdaaHelper.getFieldLanguage("name");
    const result: ValueText[] = AdaaHelper.setDropdownArray(this.cycles(), "id", field);
    result.unshift({ value: "none", text: this._translateService.instant("common.form.label.none") });
    return result;
  });

  entitiesOptions = computed<ValueText[]>(() => {
    const field = AdaaHelper.getFieldLanguage("name");
    const result: ValueText[] = AdaaHelper.setDropdownArray(this.entities(), "id", field);
    result.unshift({ value: "none", text: this._translateService.instant("common.form.label.none") });
    return result;
  });

  reportGenerationForm: FormGroup;
  modal = viewChild.required<TemplateRef<unknown>>("content");
  modalRef: NgbModalRef;

  private _subscription = new Subscription();

  public ngOnInit(): void {
    this._getOrgUnits(Number(AdaaHelper.entity?.id));
    this._subscription.add(
      this._systemLayoutService.hasCycleChanged$.subscribe({
        next: () => {
          this.activeCycle.set(this._systemLayoutService.activeCycle()!);
          this._getYearsBetween(this.activeCycle().startYear, this.activeCycle().endYear);
        },
      })
    );
    const cycle = this._systemLayoutService.activeCycle();
    cycle && this._getYearsBetween(cycle?.startYear, cycle?.endYear);
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  public open(event: TableButtonClicked["data"]) {
    const modalRef: NgbModalRef = this._modalService.open(this.modal(), {
      animation: true,
      scrollable: false,
      keyboard: false,
      size: "xl",
      centered: true,
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });

    this.modalRef = modalRef;

    this.report.set(event);
    this.reportName.set(AdaaHelper.getItemValueByToken(event, "name"));

    const fieldList = JSON.parse(event.fieldList) as ReportFieldsConfig[];
    this.fieldList.set(fieldList.map((item, index) => ({ ...item, _index: `field_${index}` })));

    this._initReportingModal();
  }

  public getDropdownListOptions(listScope: ReportFieldListScope) {
    let options: ValueText[];
    switch (listScope) {
      case "cycle-in-years":
        options = this.cycleYearOptions();
        break;
      case "cycles":
        options = this.cycleOptions();
        break;
      case "entity":
        options = this.entitiesOptions();
        break;
      case "kpiType":
        options = this.kpiTypeOptions();
        break;
      case "pillar":
        options = this.pillarOptions();
        break;
      case "owners":
        options = this.entityOwnersOptions();
        break;
      default:
        options = [];
        break;
    }

    return options;
  }

  private _initReportingModal() {
    this._prepareForm();
    this._getProps();
    this._fetchData();
    this._populateDates();
    this._fetchTableData();
  }

  private _prepareForm(): void {
    this.reportGenerationForm = this._formBuilder.group({
      output: this._formBuilder.control<string | undefined>(undefined, {
        validators: [Validators.required],
      }),
      language: this._formBuilder.control<string | undefined>(undefined, {
        validators: [Validators.required],
      }),
    });

    if (this.fieldList) {
      for (const item of this.fieldList()) {
        if (!item.visible) continue;

        if (
          item.inputType === "checkbox" ||
          item.inputType === "date" ||
          item.inputType === "select" ||
          item.inputType === "treeview"
        ) {
          this.reportGenerationForm.addControl(
            item.outputKeyName,
            this._formBuilder.control<number | string | ValueText[] | undefined>(undefined, {
              validators: item.mandatory ? [Validators.required] : [],
            })
          );

          continue;
        }

        if (item.inputType === "list") {
          this.tableDataMap[item._index] = signal([]);
        }
      }
    }
  }

  private _fetchData() {
    const fields = this.fieldList().filter((field) => field.inputType === "select") as SelectReportFieldType[];

    for (const field of fields) {
      switch (field.listScope) {
        case "entity":
          this._getEntities();
          break;
        case "kpiType":
          this._getKPItypes();
          break;
        case "pillar":
          this._getPillars();
          break;
        case "cycles":
          this._getCycles();
          break;
        case "owners":
          this._getOwners();
          break;
        default:
          break;
      }
    }
  }

  private _fetchTableData() {
    const fields = this.fieldList().filter((field) => {
      const { inputType } = field as TableReportFieldType;
      return inputType === "list";
    }) as TableReportFieldType[];

    for (const field of fields) {
      switch (field.parameter) {
        case "Entity":
          this._getEntities();
          this.tableDataMap[field._index] = this.entities;
          break;
        case "StrategicCycle":
          this._getCycles(true);
          this.tableDataMap[field._index] = this.cycles;
          break;
        case "NationalProjects":
          this._getNationalProjects();
          this.tableDataMap[field._index] = this.nationalProjects;
          break;
        case "Kpi": {
          const kpiType = this.fieldList().find((field) => field.parameter === "KpiType")?.value;
          if (!AdaaHelper.isDefined(kpiType)) return;
          this._getKPIs(kpiType[0], Constants.CONSTANT_PMO_ID);
          this.tableDataMap[field._index] = this.KPIs;
          break;
        }

        default:
          break;
      }
    }
  }

  private _getProps() {
    this._propertiesService
      .getPropByIdList([Constants.CONSTANT_REPORT_LANGUAGE, Constants.CONSTANT_REPORT_OUTPUT_FORMAT])
      .pipe(filter((res) => !res.inError))
      .subscribe({
        next: (res) => {
          this.exportOptions.set(
            JSON.parse(this.report()?.exportOption).map((option: string) => this.getOutputValue(option))
          );

          this.languageOptions.set(
            res.responseData
              .filter(({ propType }) => propType === Constants.CONSTANT_REPORT_LANGUAGE)
              .map((prop) => ({
                value: prop.value === "ae" ? "ar" : prop.value,
                text: AdaaHelper.getItemValueByToken(prop, "name"),
              }))
          );
        },
        complete: () => {
          const languageValue = this.languageService.current() === "ae" ? "ar" : "en";
          this.reportGenerationForm.controls["output"].setValue(this.exportOptions()[0].value);
          this.reportGenerationForm.controls["language"].setValue(
            this.languageOptions().find((item) => item.value === languageValue)?.value
          );
        },
      });
  }

  private _getCycles(isTableData?: boolean): void {
    this._cyclesApiService
      .getAllIncludeArchived()
      .pipe(filter((res) => !res.inError))
      .subscribe({
        next: (response) => {
          let result = response.responseData.sort((cycleA, cycleB) => {
            if (new Date(cycleA.endYear).getFullYear() < new Date(cycleB.endYear).getFullYear()) return 1;
            if (new Date(cycleA.endYear).getFullYear() > new Date(cycleB.endYear).getFullYear()) return -1;
            return 0;
          });
          if (isTableData) {
            result = result.filter((cycle) => {
              const activeCycle = this.activeCycle();
              return activeCycle !== null && activeCycle !== undefined && cycle.endYear < activeCycle.startYear;
            });
          }

          this.cycles.set(result);
        },
        complete: () => {
          const option = this.cycleOptions().find((option) => option.value === this.activeCycle()?.id)?.value;
          this.reportGenerationForm.controls["planId"]?.setValue(option);
          this.reportGenerationForm.controls["year"]?.setValue(this.cycleYearOptions()[0].value);
        },
      });
  }

  private _getOwners() {
    const entityId = this._systemLayoutService.activeEntity()?.id;
    if (entityId) {
      this._usersApiService.getOwnersPerEntity(entityId).subscribe({
        next: (response) => this.entityOwners.set(response.responseData),
        complete: () => {
          this.reportGenerationForm.controls["owner"]?.setValue(this.entityOwnersOptions()[0].value);
        },
      });
    }
  }

  private _getNationalProjects() {
    if (AdaaHelper.isPMOEntity()) {
      this._tpApiService.getNationalProjectsByEntity().subscribe({
        next: (response) => {
          this.nationalProjects.set(response.responseData);
        },
      });
    } else {
      const entityId = AdaaHelper.getLocalStorage(Constants.localStorageKeys.currentEntity, {
        type: "prop",
        property: "id",
      });

      this._tpApiService.getNationalProjectsByEntity(+entityId).subscribe({
        next: (response) => {
          this.nationalProjects.set(response.responseData);
        },
      });
    }
  }

  private _getPillars(): void {
    this._pillarsApiService.getPillars().subscribe({
      next: (response) => {
        this.pillars.set(response.responseData);
      },
      complete: () => {
        this.reportGenerationForm.controls["pillarId"]?.setValue(this.pillarOptions()[0].value);
      },
    });
  }

  private _getEntities(): void {
    this._entitiesApiService.getAll(AdaaBoolean.N).subscribe({
      next: (response) => {
        this.entities.set(response.responseData);
      },
      complete: () => {
        this.reportGenerationForm.controls["entityId"]?.setValue(this.entitiesOptions()[0].value);
      },
    });
  }

  private _getKPItypes(): void {
    this._propertiesService.getAllPermittedKpiTypes().subscribe({
      next: (response) => {
        this.kpiTypes.set(response.responseData);
      },
      complete: () => {
        this.reportGenerationForm.controls["kpiType"]?.setValue(this.kpiTypeOptions()[0].value);
      },
    });
  }

  private _populateDates() {
    const dateFields = this.fieldList().filter(({ inputType }) => inputType === "date") as DateReportFieldType[];

    for (const { parameter, outputKeyName } of dateFields) {
      switch (parameter) {
        case "StartDate":
          this.reportGenerationForm.controls[outputKeyName]?.setValue(this.activeCycle()?.startYear);
          break;
        case "EndDate":
          this.reportGenerationForm.controls[outputKeyName]?.setValue(this.activeCycle()?.endYear);
          break;
      }
    }
  }

  public _getKPIs(kpiType: number, entityId: number, index?: string, year?: number) {
    this._kpisApiService
      .getAllByTypeAndEntityList(kpiType, [entityId], year)
      .pipe(filter((res) => !res.inError))
      .subscribe({
        next: (response) => {
          if (index) {
            this.tableDataMap[index].set(response.responseData as EntityModelType[]);
          } else this.KPIs.set(response.responseData as EntityModelType[]);
        },
      });
  }

  public _getOrgUnits(entityId: number) {
    this._orgUnitApiService.getAllByEntity(entityId).subscribe({
      next: (response) => {
        this.orgUnits.set([
          { value: undefined, text: this._translateService.instant("common.form.label.none") },
          ...AdaaHelper.setDropdownArray(
            response.responseData ?? [],
            "id",
            AdaaHelper.getFieldLanguage("name"),
            "parentOrgUnit"
          ),
        ]);
      },
    });
  }

  public applyChanges(listScope: ReportFieldListScope, value?: string | number) {
    if (listScope === "cycles" || listScope === "cycle-in-years") {
      this.noneSelected.set(value === "none");
    }
    if (listScope === "cycles") {
      const cycle = this.cycles().find((item) => item.id === value);
      cycle && this._getYearsBetween(cycle.startYear, cycle.endYear);
      this.reportGenerationForm.controls["year"]?.setValue("none");
    }
    if (listScope === "org-unit") {
      value &&
        this._usersApiService.getOwnersPerOrgUnit(+value).subscribe({
          next: (response) => this.entityOwners.set(response.responseData),
        });
    }
  }

  public filterTableData(value?: ValueText["value"] | undefined, listScope?: ReportFieldListScope) {
    if (!value) return;

    const reportType: ReportParameterTypeValue = this.fieldList().find(
      (field) => field.parameter === "ReportType"
    )?.value;
    if (value && listScope === "cycle-in-years" && reportType === "national-target-performance-docx") {
      const id = this.fieldList().find((field) => field.parameter === "KpiType")?.value[0];
      const index = this.fieldList().find((field) => field.parameter === "Kpi")?._index;
      this._getKPIs(id, Constants.CONSTANT_PMO_ID, index, value);
    }

    let inputRef: BaseReportFieldType | undefined;
    const reportForm = this.reportGenerationForm.value;

    let mandatoryChecks: string[] = [];
    for (const input of this.fieldList()) {
      if (input.hasRef) {
        inputRef = input;
        mandatoryChecks = this.fieldList()
          .filter((target) => target.filterDoubleDataTo || target.filterDataTo === input.parameter)
          .map((target) => target.outputKeyName);
      }
    }

    if (mandatoryChecks.length && inputRef) {
      const hasAllProperties = mandatoryChecks.every((prop) => prop in reportForm);
      if (hasAllProperties) {
        switch (inputRef.parameter) {
          case "Kpi": {
            const kpiId = !reportForm.kpiType || reportForm.kpiType === "none" ? 0 : reportForm.kpiType;
            const entityId = !reportForm.entityId || reportForm.entityId === "none" ? 0 : reportForm.entityId;

            if (!mandatoryChecks.includes("entityId") && kpiId !== 0) {
              this._itemApiService.getAllV2(kpiId).subscribe({
                next: (response) => this.tableDataMap[inputRef._index].set(response.responseData),
              });
            }
            if (
              mandatoryChecks.includes("entityId") &&
              mandatoryChecks.includes("kpiType") &&
              kpiId !== 0 &&
              entityId !== 0
            ) {
              this._getKPIs(kpiId, entityId, inputRef._index);
            }
            break;
          }

          default:
            break;
        }
      }
    }
  }

  private _getYearsBetween(startTimestamp: number, endTimestamp: number) {
    const startYear = new Date(startTimestamp).getUTCFullYear() + 1;
    const endYear = new Date(endTimestamp).getFullYear();

    const years = [];
    for (let year = startYear; year <= endYear; year++) {
      years.push(year);
    }

    this.cycleYears.set(years);
  }

  public getOutputValue(item: string) {
    switch (item) {
      case "docx":
        return {
          value: item,
          text: "WORD",
        };
      case "xls":
        return {
          value: "xlsx",
          text: "EXCEL",
        };
      case "pdf":
        return {
          value: item,
          text: "PDF",
        };
      default:
        return {};
    }
  }

  public setTableSelection(data: TableInputChangedOutput[], parameter: ReportFieldParameterType) {
    const mappedIds: number[] = [];
    for (const check of data) {
      mappedIds.push(check.data.id);
    }
    this.selectedTableData.set(this.selectedTableData().filter((data) => data.parameter !== parameter));
    this.selectedTableData().push({ parameter, tableData: mappedIds });
  }

  public prepareReport() {
    if (!this.reportGenerationForm.valid) {
      return;
    }
    const reportForm = this.reportGenerationForm.value;
    const type = this.fieldList().find((item) => item.parameter === "ReportType")?.value;
    const kpiType = this.fieldList().find((item) => item.parameter === "KpiType")?.value;
    const currentPlanId = this._systemLayoutService.activeCycle()?.id;
    const entityId = this._systemLayoutService.activeEntity()?.id;
    const entityName = this._systemLayoutService.activeEntity()?.nameEN;
    const language = this.languageService.current();
    const username = AdaaHelper.getLocalStorage(Constants.localStorageKeys.user, {
      type: "prop",
      property: "username",
    });
    const startDate = moment(reportForm.startDate ?? this.activeCycle()?.startYear).format("YYYY-MM-DD");
    const endDate = moment(reportForm.endDate ?? this.activeCycle()?.endYear).format("YYYY-MM-DD");
    const maxTableSelection = 10;

    const resultObject = {
      language: reportForm.language ?? language,
      nameAE: this.report()?.nameAE,
      nameEN: this.report()?.nameEN,
      currentPlanId,
      planId: reportForm.planId ? [reportForm.planId] : [currentPlanId],
      entityId: reportForm.entityId ?? entityId,
      entityName,
      outputExtension: reportForm.output,
      type,
      usePreGeneratedReport: this.report()?.usePreGeneratedReport ?? false,
      preGeneratedReportValidityInDays: this.report()?.preGeneratedReportValidityInDays,
      reportId: this.report()?.id,
      username,
      ...(!reportForm.year ? { startDate } : {}),
      ...(!reportForm.year ? { endDate } : {}),
    };

    let planIds: number[] = [];
    let entityIds: number[] = [];
    let kpiIds: number[] = [];
    let projectsIds: number[] = [];

    const result: ReportRequestModel[] = [];

    if (this.selectedTableData().length) {
      for (const selected of this.selectedTableData()) {
        if (selected.parameter === "StrategicCycle") {
          planIds = selected.tableData;
        }
        if (selected.parameter === "Entity") {
          entityIds = selected.tableData;
        }
        if (selected.parameter === "Kpi") {
          kpiIds = selected.tableData;
        }
        if (selected.parameter === "NationalProjects") {
          projectsIds = selected.tableData;
        }
      }

      if (
        planIds.length > maxTableSelection ||
        entityIds.length > maxTableSelection ||
        kpiIds.length > maxTableSelection ||
        projectsIds.length > maxTableSelection
      ) {
        this._toastrService.error(this._translateService.instant("report.messages.more_than_10_reports"));
        return;
      }
    }

    if (projectsIds.length) {
      for (const id of projectsIds) {
        result.push({
          ...resultObject,
          ...(id ? { id: +id } : {}),
        });
      }
    }

    if (kpiIds.length) {
      for (const id of kpiIds) {
        result.push({
          ...resultObject,
          ...(id ? { kpiId: +id } : {}),
          ...(reportForm.kpiType ? { kpiType: [reportForm.kpiType] } : {}),
        });
      }
    }

    if (planIds.length || entityIds.length) {
      for (const id of entityIds) {
        result.push({
          language: reportForm.language ?? language,
          nameAE: this.report()?.nameAE,
          nameEN: this.report()?.nameEN,
          currentPlanId,
          planId: planIds.length ? (planIds as number[]) : ([currentPlanId] as number[]),
          entityId: id,
          entityName,
          outputExtension: reportForm.output,
          type,
          usePreGeneratedReport: this.report()?.usePreGeneratedReport,
          preGeneratedReportValidityInDays: this.report()?.preGeneratedReportValidityInDays,
          reportId: this.report()?.id,
          username,
          startDate,
          endDate,
          ...(kpiType ? { kpiType: kpiType } : {}),
          ...(reportForm.pillarId ? { pillarId: +reportForm.pillarId } : {}),
          ...(reportForm.year ? { year: +reportForm.year } : {}),
        });
      }
    }

    const output: ReportRequestModel = {
      ...resultObject,
      ...(kpiType ? { kpiType } : {}),
      ...(reportForm.orgUnitId ? { orgUnitId: +reportForm.orgUnitId } : {}),
      ...(reportForm.owner ? { owner: +reportForm.owner } : {}),
      ...(reportForm.pillarId ? { pillarId: +reportForm.pillarId } : {}),
      ...(reportForm.year ? { year: +reportForm.year } : {}),
    };

    if (
      this.fieldList().find((field) => field.parameter === "ReportType")?.value === "national-target-performance-docx"
    ) {
      result.forEach((item) => {
        delete item.currentPlanId;
        item.kpiType = kpiType;
        item.year = +reportForm.year;
      });
    }

    this._reportDataService
      .startReportDownload(result.length ? result : [output])
      .pipe(
        mergeMap((response) => {
          this.showLoadingPage.emit(true);
          const observables = response.responseData.map((item) => {
            const requestId = item.requestId;
            this.modalRef.dismiss();
            this.selectedTableData.set([]);
            return this._reportDataService.getReportStatus(requestId, 1);
          });
          return forkJoin(observables);
        }),
        filter((data) => {
          if (data.find((item) => item.responseData.status.toLocaleLowerCase() === "failed")) {
            this._toastrService.error(this._translateService.instant("notification.error.generic"));
            this.showLoadingPage.emit(false);
            return false;
          }
          return data.every((item) => item.responseData.status.toLowerCase() === "completed");
        }),
        mergeMap((data) => {
          const downloadObservables = data.map((item) => {
            return this._reportDataService.downloadReportFile(item.responseData.requestId, item.responseData.fileName);
          });
          return forkJoin(downloadObservables);
        })
      )
      .subscribe({
        complete: () => this.showLoadingPage.emit(false),
      });
  }

  public checkFormValid(): boolean {
    let isValid: boolean = this.reportGenerationForm.valid && !this.noneSelected();

    const mandatoryFields = this.fieldList().filter(
      (field) => field.mandatory && field.inputType === "list" && field.visible
    );
    if (mandatoryFields.length) {
      for (const field of mandatoryFields) {
        isValid = !!this.selectedTableData().find((tableData) => tableData.parameter === field.parameter)?.tableData;
      }
    }
    return isValid;
  }
}
