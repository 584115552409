import { Route } from "@angular/router";

import { checkIfLoggedInFn } from "../../core/guards";
import { PageMode } from "../../shared/constants/enums";
import { MetricsEditorComponent } from "./metrics-editor/metrics-editor.component";
import { MetricsListComponent } from "./metrics-list/metrics-list.component";

export const METRICS_ROUTES: Route[] = [
  {
    path: "metrics",
    canActivate: [checkIfLoggedInFn],
    children: [
      {
        path: "list",
        redirectTo: "",
      },
      {
        path: "",
        component: MetricsListComponent,
        data: {
          breadcrumb: "breadcrumbs.metrics",
          title: "breadcrumbs.metrics",
        },
      },
      {
        path: "create",
        component: MetricsEditorComponent,
        data: {
          breadcrumb: "metrics.create.add_button_list",
          title: "metrics.create.add_button_list",
          pageMode: PageMode.create,
        },
      },
      {
        path: ":id",
        component: MetricsEditorComponent,
        data: {
          breadcrumb: "breadcrumbs.view_metric",
          title: "breadcrumbs.view_metric",
          pageMode: PageMode.view,
        },
      },
      {
        path: "edit/:id",
        component: MetricsEditorComponent,
        data: {
          breadcrumb: "breadcrumbs.metric_edit",
          title: "breadcrumbs.metric_edit",
          pageMode: PageMode.edit,
        },
      },
    ],
  },
];
