<div class="p-2">
    <adaa-data-table
        #adaaDataTable
        titleHeader="landingPage.pillars.title"
        apiName="getAllV2"
        apiPrefix="pillar"
        [configKey]="tableConfList()"
        [enableAdd]="false"
        [enableEditAction]="true"
        [enableDeleteAction]="true"
        [enableViewAction]="true"
        [enableAdd]="true"
        [itemTypeId]="Constants.CONSTANT_PERMISSIONS.PILLARS"
        (addNewClicked)="addNewClicked()"
        (actionClicked)="getAction($event)"
    />
</div>

<adaa-float-action />
