import { Component, computed, inject, input, OnDestroy, OnInit, signal } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { ToastrService } from "ngx-toastr";
import { interval, Observable, Subject, switchMap, takeUntil, tap } from "rxjs";

import { AdaaHelper } from "../../../core/utils";
import { DIMENSIONS_SYNC_TYPES } from "../../constants/enums";
import { MainResponseModel, ValueText } from "../../models";
import {
  ClassPropType,
  CronObject,
  CurrentSchedule,
  DimensionsJobSchedule,
} from "../../models/khadamati-synchronization.model";
import { SystemLayoutService } from "../../services";
import { DimensionsService } from "../../services/dimensions.service";
import { KhadamatiService } from "../../services/khadamati.service";
import { FormDropdownComponent, FormRadioComponent } from "..";
import { FormInputTimeComponent } from "../form/form-input-time/form-input-time.component";
import { createCronExpression, getCronFrequency } from "./synch-schedule.utils";

@Component({
  selector: "adaa-synchronization-schedule",
  standalone: true,
  templateUrl: "./synchronization-schedule.component.html",
  styleUrl: "./synchronization-schedule.component.scss",
  imports: [TranslateModule, ReactiveFormsModule, FormRadioComponent, FormDropdownComponent, FormInputTimeComponent],
})
export class SynchronizationScheduleComponent implements OnInit, OnDestroy {
  private _formBuilder = inject(FormBuilder);
  private _translateService = inject(TranslateService);
  private _khadamatiService = inject(KhadamatiService);
  private _dimensionsService = inject(DimensionsService);
  private _toastrService = inject(ToastrService);
  private _systemLayoutService = inject(SystemLayoutService);
  private _router = inject(Router);

  [key: string]: ClassPropType;
  monthlyForm: FormGroup = new FormGroup({});
  weeklyForm: FormGroup = new FormGroup({});
  dailyForm: FormGroup = new FormGroup({});
  daysOfWeek = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
  runSync = signal<boolean>(true);
  radioOptions = signal<ValueText[]>([]);
  activeForm = signal<string>("");
  currentSchedule = signal<CurrentSchedule>({
    frequency: "",
  });
  isDimensions = input.required<boolean>();
  private readonly _untilDestroy = AdaaHelper.untilDestroyed();

  monthlyradioOption = computed(() => {
    const option = this.radioOptions().find((option) => option.value === "monthly");
    return option ? [option] : [];
  });
  weeklyRadioOption = computed(() => {
    const option = this.radioOptions().find((option) => option.value === "weekly");
    return option ? [option] : [];
  });
  dailyradioOption = computed(() => {
    const option = this.radioOptions().find((option) => option.value === "daily");
    return option ? [option] : [];
  });
  inactiveRadioOption = computed(() => {
    const option = this.radioOptions().find((option) => option.value === "inactive");
    return option ? [option] : [];
  });

  lastDate = signal<Date | string | undefined>(undefined);
  months = signal<ValueText[]>([]);
  weeks = signal<ValueText[]>([]);
  dimensionTypes = signal<ValueText[]>([
    { text: this._translateService.instant("dimension.dms_sync"), value: 1 },
    { text: this._translateService.instant("dimension.metric_values_sync"), value: 2 },
  ]);
  dimensionType = signal<number | undefined>(1);
  dimensionSyncTypes = DIMENSIONS_SYNC_TYPES;
  time = signal<{ hour: number; minute: number; second: number } | undefined>(undefined);
  private _destroy$ = new Subject<void>();

  public ngOnInit(): void {
    this._systemLayoutService.hasActiveEntityChanged$.pipe(this._untilDestroy()).subscribe({
      next: () => {
        if (!AdaaHelper.isPMOEntity()) this._router.navigateByUrl("/console");
      },
    });

    this._prepareForms();
    if (!this.isDimensions()) this._initSyncCheck();
    this._getLastSyncDate();
    this._translateService.get(["khadamati.schedule.week.choose"]).subscribe({
      next: () => {
        this.getDropdownListOptions();
        this._getCurrentScheduleSync();
        this._translateService
          .get([
            "khadamati.schedule.monthly",
            "khadamati.schedule.weekly",
            "khadamati.schedule.daily",
            "khadamati.schedule.inactive",
          ])
          .subscribe((values) => {
            this.radioOptions.set([
              { text: values["khadamati.schedule.monthly"], value: "monthly" },
              { text: values["khadamati.schedule.weekly"], value: "weekly" },
              { text: values["khadamati.schedule.daily"], value: "daily" },
              { text: values["khadamati.schedule.inactive"], value: "inactive" },
            ]);
          });
      },
    });
  }

  private _prepareForms() {
    this.monthlyForm = this._formBuilder.group({
      dayOfMonth: this._formBuilder.control<string | undefined>("", {
        validators: [Validators.required],
      }),
      time: this._formBuilder.control<{ hour: number; minute: number; second: number } | undefined>(undefined, {
        validators: [Validators.required],
      }),
    });

    this.weeklyForm = this._formBuilder.group({
      dayOfWeek: this._formBuilder.control<string | undefined>("", {
        validators: [Validators.required],
      }),
      time: this._formBuilder.control<{ hour: number; minute: number; second: number } | undefined>(undefined, {
        validators: [Validators.required],
      }),
    });

    this.dailyForm = this._formBuilder.group({
      time: this._formBuilder.control<{ hour: number; minute: number; second: number } | undefined>(undefined, {
        validators: [Validators.required],
      }),
    });
  }

  public getDropdownListOptions() {
    const monthValues: ValueText[] = [
      { value: "none", text: this._translateService.instant("common.form.label.none") },
    ];
    const weekValues: ValueText[] = [{ value: "none", text: this._translateService.instant("common.form.label.none") }];
    for (let index = 0; index <= 31; index++) {
      monthValues.push({
        value: index,
        text: index.toString(),
      });
    }

    for (let index = 0; index < this.daysOfWeek.length; index++) {
      const day = this.daysOfWeek[index];
      weekValues.push({
        value: index + 1,
        text: this._translateService.instant(`khadamati.schedule.week.${day}`),
      });
    }

    this.monthlyForm.controls["dayOfMonth"].setValue(monthValues[0].value);
    this.weeklyForm.controls["dayOfWeek"].setValue(weekValues[0].value);
    this.months.set(monthValues);
    this.weeks.set(weekValues);
  }

  private _getCurrentScheduleSync() {
    let url: Observable<MainResponseModel<DimensionsJobSchedule>>;
    if (this.isDimensions()) {
      url =
        this.dimensionType() === DIMENSIONS_SYNC_TYPES.STRUCTURE
          ? this._dimensionsService.getCurrentStructureScheduler()
          : this._dimensionsService.getCurrentStructureScheduler();
      url.subscribe({
        next: (response) => this._setActiveScheduleForms(response.responseData?.cron ?? ""),
      });
    } else {
      this._khadamatiService.getCurrentScheduleSync().subscribe({
        next: (response) => {
          this._setActiveScheduleForms(response.responseData[0]?.cron ?? "");
        },
      });
    }
  }

  private _getLastSyncDate() {
    if (this.isDimensions()) {
      this._dimensionsService
        .checkRunningSchedule(this.dimensionType() === DIMENSIONS_SYNC_TYPES.STRUCTURE ? "structure" : "value")
        .subscribe({
          next: (response) =>
            this.lastDate.set(moment(new Date(response.responseData.lastDate)).format("DD/MM/YYYY hh:mm A")),
        });
    } else {
      this._khadamatiService.getLastSyncDate().subscribe({
        next: (response) => {
          const timestamp = response.responseData.lastDate;
          this.lastDate.set(moment(new Date(timestamp)).format("DD/MM/YYYY hh:mm A"));
        },
      });
    }
  }

  private _initSyncCheck() {
    this._khadamatiService.checkRunningSchedule().subscribe({
      next: (response) => this.runSync.set(!response.responseData),
    });
    interval(30000)
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this._khadamatiService.checkRunningSchedule().subscribe({
          next: (response) => this.runSync.set(!response.responseData),
        });
        this._getLastSyncDate();
      });
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private _setActiveScheduleForms(cron: string) {
    this.currentSchedule.set(getCronFrequency(cron));
    this.activeForm.set(this.currentSchedule().frequency + "Form");

    switch (this.currentSchedule().frequency) {
      case "monthly":
        {
          const monthOption = this.months().find((option) => option.value === this.currentSchedule().dayOfMonth);
          this.monthlyForm.controls["dayOfMonth"].patchValue(monthOption?.value);
          this.monthlyForm.controls["time"].patchValue(this.currentSchedule().time);
        }
        break;
      case "weekly":
        {
          const weekOption = this.weeks().find((option) => option.value === this.currentSchedule().dayOfWeek);
          this.weeklyForm.controls["dayOfWeek"].patchValue(weekOption?.value);
          this.weeklyForm.controls["time"].patchValue(this.currentSchedule().time);
        }
        break;
      case "daily":
        {
          this.dailyForm.controls["time"].patchValue(this.currentSchedule().time);
        }
        break;

      case "inactive":
        this.setActive(this.currentSchedule().frequency);
        break;

      default:
        break;
    }

    this.setActive(this.currentSchedule().frequency);
  }

  public setActive(event: string) {
    this.activeForm.set(event + "Form");
    switch (event) {
      case "monthly":
        this.monthlyForm.enable();
        this.weeklyForm.disable();
        this.dailyForm.disable();
        this.weeklyForm.reset();
        this.weeklyForm.controls["time"].setValue({});
        this.dailyForm.controls["time"].setValue({});
        break;
      case "weekly":
        this.weeklyForm.enable();
        this.monthlyForm.disable();
        this.dailyForm.disable();
        this.monthlyForm.reset();
        this.monthlyForm.controls["time"].setValue({});
        this.dailyForm.controls["time"].setValue({});
        break;
      case "daily":
        this.dailyForm.enable();
        this.monthlyForm.disable();
        this.weeklyForm.disable();
        this.monthlyForm.reset();
        this.weeklyForm.reset();
        this.monthlyForm.controls["time"].setValue({});
        this.weeklyForm.controls["time"].setValue({});
        break;
      case "inactive":
        this.monthlyForm.disable();
        this.weeklyForm.disable();
        this.dailyForm.disable();
        this.monthlyForm.controls["time"].setValue({});
        this.weeklyForm.controls["time"].setValue({});
        this.dailyForm.controls["time"].setValue({});
        this.monthlyForm.reset();
        this.weeklyForm.reset();
        break;

      default:
        break;
    }
  }

  public setTime(event: { hour: number; minute: number; second: number }) {
    this.time.set(event);
  }

  public saveSyncSchedule() {
    const formValue = this[this.activeForm()]?.value;
    const hours = formValue?.time.split(":")[0];
    const minutes = formValue?.time.split(":")[1];
    if (formValue?.dayOfMonth === "none" || formValue?.dayOfWeek === "none") {
      this._toastrService.error(this._translateService.instant("common.form.label.missing_information"));
      return;
    }
    if (this[this.activeForm()]?.invalid) {
      this._toastrService.error(this._translateService.instant("common.form.label.missing_information"));
      return;
    }
    if (!formValue && this.activeForm().toLowerCase() === "inactiveform") {
      if (this.isDimensions()) {
        const url =
          this.dimensionType() === DIMENSIONS_SYNC_TYPES.STRUCTURE
            ? this._dimensionsService.disableStructureScheduler()
            : this._dimensionsService.disableValueScheduler();

        url.subscribe({
          next: (response) => {
            if (response.inError) return;
            this._toastrService.success(this._translateService.instant("notification.success.save"));
          },
        });
      } else {
        this._khadamatiService.disableSyncJob().subscribe({
          next: (response) => {
            if (response.inError) return;
            this._toastrService.success(this._translateService.instant("notification.success.save"));
          },
        });
      }
    } else {
      const object: CronObject = {
        hours,
        minutes,
        ...(formValue.dayOfMonth ? { dayOfMonth: formValue.dayOfMonth } : {}),
        ...(formValue.dayOfWeek ? { dayOfWeek: formValue.dayOfWeek } : {}),
      };
      const cron = createCronExpression(object);
      const schedule = [
        {
          triggerId: "ServicesSyncJob",
          cron,
        },
      ];

      if (this.isDimensions()) {
        const url =
          this.dimensionType() === DIMENSIONS_SYNC_TYPES.STRUCTURE
            ? this._dimensionsService.configureStructureScheduler({ cron })
            : this._dimensionsService.configureValueScheduler({ cron });

        url.subscribe({
          next: (response) => {
            if (response.inError) return;
            this._toastrService.success(this._translateService.instant("notification.success.save"));
          },
        });
      } else {
        this._khadamatiService.updateCurrentScheduleSync(schedule).subscribe({
          next: (response) => {
            if (response.inError) return;
            this._toastrService.success(this._translateService.instant("notification.success.save"));
          },
        });
      }
    }
  }

  public startStopSync() {
    let url: Observable<MainResponseModel<string | null>>;
    if (this.isDimensions()) {
      url =
        this.dimensionType() === DIMENSIONS_SYNC_TYPES.STRUCTURE
          ? this._dimensionsService.manualTriggerStructure()
          : this._dimensionsService.manualTriggerValue();
    } else url = this._khadamatiService.startStopSync(this.runSync());
    url
      .pipe(
        tap(() => this.runSync.set(false)),
        switchMap(() => {
          if (this.isDimensions()) {
            return this._dimensionsService.getSyncStatus(
              8,
              this.dimensionType() === DIMENSIONS_SYNC_TYPES.STRUCTURE ? "structure" : "value"
            );
          }
          return this._khadamatiService.getSyncStatus(8);
        }),
        switchMap(async (syncStatus) => {
          const { lastDate } = syncStatus.responseData as { lastDate: number; isRunning: boolean };
          if (lastDate) {
            this.lastDate.set(moment(lastDate).format("DD/MM/YYYY hh:mm A"));
          } else return this._getLastSyncDate();
        })
      )
      .subscribe({
        next: () => this.runSync.set(true),
      });
  }

  public checkInvalid(control: string, currentForm: string) {
    if (currentForm !== this.activeForm()) return false;
    const form = this[this.activeForm()];
    const value = form?.controls[control]?.value;
    if (!value || value === "none") return true;
    if (control === "time" && !Object.keys(value).length) return true;
    return form?.controls[control]?.invalid ? true : false;
  }

  public getSelectedSchedule(event: number) {
    this.dimensionType.set(event);
    this.dailyForm.reset({});
    this.weeklyForm.reset({});
    this.monthlyForm.reset({});
    this._getLastSyncDate();

    const url =
      this.dimensionType() === DIMENSIONS_SYNC_TYPES.STRUCTURE
        ? this._dimensionsService.getCurrentStructureScheduler()
        : this._dimensionsService.getCurrentValueScheduler();

    url.subscribe({
      next: (response) => this._setActiveScheduleForms(response.responseData?.cron ?? ""),
    });
  }
}
