<div class="p-2">
    <adaa-data-table
        #adaaDataTable
        apiName="getMandatoryList"
        [titleHeader]="'mandatory.title' | translate"
        [configKey]="'mandatory_conf_list'"
        [itemTypeId]="constants.CONSTANT_PERMISSIONS.MANDATORY"
        [paramsString]="'66?itemType=66'"
        [enableAdd]="false"
        [enableEditAction]="true"
        [enableDeleteAction]="true"
        [enableViewAction]="true"
        [enableAdd]="false"
        [enableViewAction]="false"
        [enableDeleteAction]="false"
        (actionClicked)="getAction($event)"
    ></adaa-data-table>
</div>
<adaa-float-action />
