<ng-template #content let-modal>
    <div class="modal-header">
        <h5 class="text-center fw-bold mb-0 w-100">{{ "national_projects.agreements.generateAgreement" | translate }}</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body overflow-x-hidden p-3">
        <form class="col-12" [formGroup]="generateAgreementForm">
            <div class="row">
                <div class="col-12">
                    <adaa-form-dropdown
                        controlName="entityId"
                        formControlName="entityId"
                        [setValidator]="projectAgreementEditorService.formValidation()!"
                        [invalid]="checkInvalid('entityId')"
                        [label]="'common.form.label.entity' | translate"
                        [options]="entityOptions()"
                        [clearable]="true"
                        appendTo=".modal-content"
                    ></adaa-form-dropdown>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        formControlName="firstLeaderName"
                        controlName="firstLeaderName"
                        [label]="'national_projects.agreements.firstLeaderName' | translate"
                        [setValidator]="projectAgreementEditorService.formValidation()!"
                        [invalid]="checkInvalid('firstLeaderName')"
                    ></adaa-form-input>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        formControlName="firstLeaderJobTitle"
                        controlName="firstLeaderJobTitle"
                        [label]="'national_projects.agreements.firstLeaderJob' | translate"
                        [setValidator]="projectAgreementEditorService.formValidation()!"
                        [invalid]="checkInvalid('firstLeaderJobTitle')"
                    ></adaa-form-input>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        formControlName="secondLeaderName"
                        controlName="secondLeaderName"
                        [label]="'national_projects.agreements.secondLeaderName' | translate"
                        [setValidator]="projectAgreementEditorService.formValidation()!"
                        [invalid]="checkInvalid('secondLeaderName')"
                    ></adaa-form-input>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        formControlName="secondLeaderJobTitle"
                        controlName="secondLeaderJobTitle"
                        [label]="'national_projects.agreements.secondLeaderJob' | translate"
                        [setValidator]="projectAgreementEditorService.formValidation()!"
                        [invalid]="checkInvalid('secondLeaderJobTitle')"
                    ></adaa-form-input>
                </div>
            </div>
        </form>
        @if (projects().length) {
            <div class="row p-2">
                <div class="col-6">
                    <p class="fw-bold mb-0">{{ "national_projects.project_name" | translate }}</p>
                </div>
                <div class="col-3">
                    <p class="fw-bold mb-0">{{ "national_projects.agreements.firstLeader" | translate }}</p>
                </div>
                <div class="col-3">
                    <p class="fw-bold mb-0">{{ "national_projects.agreements.secondLeader" | translate }}</p>
                </div>
            </div>
            <div class="row p-2">
                @for (project of projects(); track $index) {
                    <div class="col-6">
                        <p>{{ project | translateToken: "name" }}</p>
                    </div>
                    <div class="col-3">
                        <adaa-form-checkbox [setDefaultValue]="AdaaBoolean.Y" [isDisabled]="true"></adaa-form-checkbox>
                    </div>
                    <div class="col-3">
                        <adaa-form-checkbox
                            [isDisabled]="disableCheckBoxes()"
                            [clear]="disableCheckBoxes() ? true : false"
                            (inputChanges)="valueChanged($event, project.id)"
                        ></adaa-form-checkbox>
                    </div>
                }
            </div>
        }
    </div>
    <div class="modal=footer d-flex justify-content-center mt-3">
        <adaa-form-action-buttons
            [saveButtonTitle]="'common.form.label.generate' | translate"
            (cancelClicked)="modal.close()"
            (saveClicked)="submit()"
        ></adaa-form-action-buttons>
    </div>
</ng-template>
