<adaa-data-table
    #adaaDataTable
    apiName="getEntityReportsList"
    [paramsString]="Constants.ITEM_TYPES_DOCS.GENERATED_REPORTS.toString()"
    [alwaysFilterBySearchFields]="searchFields"
    [titleHeader]="breadcrumb() | translate"
    [configKey]="tableConfList()"
    [enableAdd]="false"
    [enableViewAction]="false"
    [enableEditAction]="false"
    [customActionButtons]="customButtons"
    [enableDeleteAction]="true"
    (actionClicked)="actionClicked($event)"
/>
