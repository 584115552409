import { Component, inject, viewChild } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

import { DataTableComponent, FloatActionComponent } from "../../../shared/components";
import { genericFloatButtons } from "../../../shared/components/general/float-action";
import { Constants } from "../../../shared/constants/constants";
import { TableButtonClicked } from "../../../shared/models";

@Component({
  selector: "adaa-lookup-management-list",
  standalone: true,
  imports: [DataTableComponent, TranslateModule, FloatActionComponent],
  templateUrl: "./lookup-management-list.component.html",
  styleUrl: "./lookup-management-list.component.scss",
})
export class LookupManagementListComponent {
  private _router = inject(Router);

  adaaDataTable = viewChild.required<DataTableComponent>("adaaDataTable");
  constants = Constants;
  readonly #floatActions = genericFloatButtons();

  public ngOnInit(): void {
    this.#floatActions([
      {
        key: "print",
        data: undefined,
      },
    ]);
  }

  public getAction(event: TableButtonClicked) {
    const lookupId = event.data.id;
    switch (event.event) {
      case "edit":
        this._router.navigateByUrl(`/console/lookup/${lookupId}`);
        break;

      default:
        break;
    }
  }
}
