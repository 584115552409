import { Component, computed, inject, OnInit, signal } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

import { AdaaHelper } from "../../../../core/utils";
import { ProgressIndicatorComponent, RibbonComponent, TpProjectCardComponent } from "../../../../shared/components";
import { Constants } from "../../../../shared/constants/constants";
import { Language, TpAgmStatus } from "../../../../shared/constants/enums";
import { AgmDashboardService, SystemLayoutService } from "../../../../shared/services";

@Component({
  selector: "adaa-agm-cards-dashboard",
  imports: [TranslateModule, TpProjectCardComponent, RibbonComponent, ProgressIndicatorComponent],
  templateUrl: "./agm-cards-dashboard.component.html",
  styleUrl: "./agm-cards-dashboard.component.scss",
})
export class AgmCardsDashboardComponent implements OnInit {
  private _systemLayoutService = inject(SystemLayoutService);
  private _router = inject(Router);
  private _activatedRoute = inject(ActivatedRoute);
  agmService = inject(AgmDashboardService);

  private _untilDestroy = AdaaHelper.untilDestroyed();
  TpAgmStatus = TpAgmStatus;
  Constants = Constants;
  lang = signal<Language>(AdaaHelper.getCurrentLang());
  Language = Language;
  dashboardWidgets = computed(() => {
    const items = [
      {
        label: "annual_meetings.page_title",
        data: this.agmService.dashboardData()?.annualMeetingCount,
        icon: "adaa-icon-national_projects",
      },
    ];

    return items;
  });

  public ngOnInit(): void {
    this.agmService.dashboardFilters.cycleId = +AdaaHelper.getLocalStorage(Constants.localStorageKeys.agmCycle);
    this.agmService.dashboardFilters.filterBy = undefined;

    this.agmService.resetFilters(true, false);
    this.agmService.initDashboard();
    this.agmService.getData();

    this._systemLayoutService.hasActiveEntityChanged$.pipe(this._untilDestroy()).subscribe(() => {
      this.agmService.initDashboard();
      this.agmService.getData();
    });
  }

  public navigateToProject(id: number): void {
    this._router.navigateByUrl(`/annual-meetings-projects-dashboard/projects/${id}`);
  }

  public filterDashboard(status: TpAgmStatus) {
    this.agmService.dashboardFilters.status = status;
    this.agmService.getDashboardData();
  }
}
