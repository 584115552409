import { Component, inject, input, OnInit, signal, viewChild } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { AdaaHelper } from "../../../core/utils";
import { ConfirmationModalComponent, DataTableComponent, FloatActionComponent } from "../../../shared/components";
import { genericFloatButtons } from "../../../shared/components/general/float-action";
import { MetricUnlinkInformationComponent } from "../../../shared/components/modals/metric-unlink-information/metric-unlink-information.component";
import { Constants } from "../../../shared/constants/constants";
import { AdaaBoolean, Language } from "../../../shared/constants/enums";
import { ItemAction, MonitoredKpiModelType, TableButtonClicked } from "../../../shared/models";
import { AppService, MetricApiService, SystemLayoutService } from "../../../shared/services";

@Component({
  selector: "adaa-metrics-list",
  standalone: true,
  imports: [DataTableComponent, TranslateModule, MetricUnlinkInformationComponent, FloatActionComponent],
  templateUrl: "./metrics-list.component.html",
  styleUrl: "./metrics-list.component.scss",
})
export class MetricsListComponent implements OnInit {
  private _router = inject(Router);
  private _translateService = inject(TranslateService);
  private _systemLayoutService = inject(SystemLayoutService);
  private _metricsApiService = inject(MetricApiService);
  private _toastrService = inject(ToastrService);
  private _modalService = inject(NgbModal);
  private _appService = inject(AppService);

  private readonly _genericFloatButtons = genericFloatButtons();
  private readonly _untilDestroy = AdaaHelper.untilDestroyed();
  Constants = Constants;
  breadcrumb = input.required<string>();
  adaaDataTable = viewChild.required<DataTableComponent>("adaaDataTable");
  isPMO = signal<boolean>(AdaaHelper.isPMOEntity());
  relatedKpis = signal<MonitoredKpiModelType[]>([]);
  unlink = viewChild.required<MetricUnlinkInformationComponent>("unlink");

  public getRules(event: "edit" | "delete"): ItemAction[] {
    if (event === "edit" && this.isPMO()) return [];
    if (event === "edit" && !this.isPMO()) {
      return [
        {
          propertyName: "createdByPmo",
          compareWith: AdaaBoolean.Y,
          operator: "equal",
        },
        {
          propertyName: "synced",
          compareWith: AdaaBoolean.Y,
          operator: "equal",
        },
      ];
    }
    if (event === "delete" && this.isPMO()) {
      return [
        {
          propertyName: "synced",
          compareWith: AdaaBoolean.Y,
          operator: "equal",
        },
      ];
    }
    if (event === "delete" && !this.isPMO()) {
      return [
        {
          propertyName: "createdByPmo",
          compareWith: AdaaBoolean.Y,
          operator: "equal",
        },
        {
          propertyName: "synced",
          compareWith: AdaaBoolean.Y,
          operator: "equal",
        },
      ];
    }
    return [];
  }

  public ngOnInit(): void {
    this._floatActions();

    this._systemLayoutService.hasActiveEntityChanged$.pipe(this._untilDestroy()).subscribe({
      next: () => {
        this.adaaDataTable().loadTableData();
        this.isPMO.set(AdaaHelper.isPMOEntity());
      },
    });
  }

  public addNew() {
    this._router.navigateByUrl("/console/metrics/create");
  }

  public getAction(value: TableButtonClicked) {
    switch (value.event) {
      case "view":
        this._router.navigateByUrl(`/console/metrics/${value.data.id}`);
        break;
      case "edit":
        this._router.navigateByUrl(`/console/metrics/edit/${value.data.id}`);
        break;
      case "delete":
        this._getInfo(value.data.id);
        break;

      default:
        break;
    }
  }

  private _getInfo(id: number) {
    this._metricsApiService.getRelatedKpis(id).subscribe({
      next: (response) => {
        if (response.responseData.length) {
          this.relatedKpis.set(response.responseData);
          this.unlink().open();
        } else this._deleteMetric(id);
      },
    });
  }

  private _deleteMetric(id: number) {
    const modal = this._modalService.open(ConfirmationModalComponent, {
      centered: true,
      size: "md",
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });

    modal.componentInstance.header = "metrics.delete_yes_no_title";
    modal.componentInstance.title = "metrics.delete_yes_no_information";

    modal.result.then((e) => {
      if (e) {
        this._metricsApiService.deleteMetric(id).subscribe({
          next: (response) => {
            if (response.inError) return;
            this._toastrService.success(this._translateService.instant("notification.success.remove"));
            this.adaaDataTable().loadTableData();
          },
        });
      }
    });
  }

  private _floatActions(): void {
    this._genericFloatButtons([
      {
        key: "print",
        data: undefined,
      },
      {
        key: "helpdesk",
        data: {
          url: "/helpdesk/create",
        },
      },
    ]);
  }
}
