<div class="modal-header bg-white">
    <h4 class="modal-title">{{ header | translate }}</h4>
</div>
<div class="modal-body text-start">
    <div class="bg-white p-2">
        <p class="text-primary">{{ title | translate }}</p>
        <div class="list">
            @for (item of items; track $index) {
                <p class="list-item my-2 p-2">{{ item }}</p>
            }
        </div>
    </div>
</div>
<div class="modal-footer d-flex justify-content-center">
    <button type="button" class="btn btn-outline-primary px-4 mx-2" (click)="modal.close(true)">
        {{ yesLabel | translate }}
    </button>

    <button type="button" class="btn btn-danger px-4 mx-2" (click)="modal.close(false)">
        {{ noLabel | translate }}
    </button>
</div>
