<div class="d-flex gap-2">
    <h4 class="fw-bold">
        {{ pageTitle() | translate }}
        <span class="fw-light mx-1">
            @if (displayLabel) {
                - {{ displayLabel }}
            }
        </span>
    </h4>
</div>

<div class="bg-white shadow-md rounded p-2 mb-3">
    <form class="col-12" [formGroup]="ticketForm">
        <div class="bg-white shadow-md rounded p-2 mb-3">
            <div class="row">
                <div class="col-12">
                    <adaa-form-input
                        [label]="'common.form.label.title' | translate"
                        formControlName="title"
                        controlName="title"
                        [setValidator]="formValidation()!"
                        [invalid]="submitted() && ticketForm.get('title')?.invalid!"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <adaa-form-input
                        [label]="'common.form.label.description' | translate"
                        formControlName="description"
                        controlName="description"
                        [setValidator]="formValidation()!"
                        [invalid]="submitted() && ticketForm.get('description')?.invalid!"
                        [isTextArea]="true"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-dropdown
                        formControlName="typeId"
                        controlName="typeId"
                        [clearable]="true"
                        [label]="'helpdesk.type' | translate"
                        [setValidator]="formValidation()!"
                        [invalid]="submitted() && ticketForm.get('typeId')?.invalid!"
                        [options]="ticketTypes()"
                    />
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-dropdown
                        formControlName="priorityId"
                        controlName="priorityId"
                        [clearable]="true"
                        [label]="'helpdesk.priority' | translate"
                        [setValidator]="formValidation()!"
                        [invalid]="submitted() && ticketForm.get('priorityId')?.invalid!"
                        [options]="ticketPriorities()"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-dropdown
                        formControlName="sectionId"
                        controlName="sectionId"
                        [clearable]="true"
                        [label]="'helpdesk.section' | translate"
                        [setValidator]="formValidation()!"
                        [invalid]="submitted() && ticketForm.get('sectionId')?.invalid!"
                        [options]="ticketSections()"
                    />
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-dropdown
                        formControlName="objectId"
                        controlName="objectId"
                        [clearable]="true"
                        [label]="'helpdesk.object' | translate"
                        [setValidator]="formValidation()!"
                        [invalid]="submitted() && ticketForm.get('objectId')?.invalid!"
                        [options]="ticketItems()"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <adaa-form-input
                        [label]="'helpdesk.url' | translate"
                        formControlName="url"
                        controlName="url"
                        [setValidator]="formValidation()!"
                    />
                </div>
            </div>
        </div>
    </form>

    <div class="p-2">
        <adaa-data-table
            #adaaDataTable
            titleHeader="helpdesk.attachments"
            [configKey]="Constants.MAIN_TABLE_LIST_CONF_KEY.HELPDESK_ATTACHMENTS"
            [enableExport]="false"
            [isLocalData]="true"
            [localData]="attachmentsFiltered()"
            [enableAdd]="pageMode() !== PageMode.view"
            [enableEditAction]="pageMode() !== PageMode.view"
            [enableDeleteAction]="pageMode() !== PageMode.view"
            (addNewClicked)="attachment.open()"
            (actionClicked)="getAction($event)"
        />
    </div>
</div>

@if (canSeeManagementInfo()) {
    <div class="bg-white shadow-md rounded p-2 mb-3">
        <form class="col-12" [formGroup]="ticketForm">
            <div class="bg-white shadow-md rounded p-2 mb-3">
                <div class="row">
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <adaa-form-input
                            [label]="'helpdesk.created_by' | translate"
                            [formControlName]="AdaaHelper.getFieldLanguage('userName')"
                            [controlName]="AdaaHelper.getFieldLanguage('userName')"
                        />
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <adaa-form-input-date
                            [label]="'helpdesk.creation_date' | translate"
                            formControlName="creationDate"
                            controlName="creationDate"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <adaa-form-input-date
                            formControlName="expectedCompletionDate"
                            controlName="expectedCompletionDate"
                            [label]="'helpdesk.expected_resolution_date' | translate"
                            [setValidator]="formValidation()!"
                            [invalid]="submitted() && ticketForm.get('expectedCompletionDate')?.invalid!"
                        />
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <adaa-form-dropdown
                            formControlName="currentStatusId"
                            controlName="currentStatusId"
                            [clearable]="true"
                            [label]="'helpdesk.status' | translate"
                            [setValidator]="formValidation()!"
                            [invalid]="submitted() && ticketForm.get('currentStatusId')?.invalid!"
                            [options]="ticketStatus()"
                        />
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div class="bg-white shadow-md rounded p-2 mb-3">
        <div class="bg-white shadow-md rounded p-2 mb-3">
            <form class="col-12" [formGroup]="ticketForm">
                <div class="row">
                    <div class="col-12">
                        <adaa-form-dropdown
                            formControlName="assignedId"
                            controlName="assignedId"
                            [clearable]="true"
                            [label]="'helpdesk.assigned_to' | translate"
                            [setValidator]="formValidation()!"
                            [invalid]="submitted() && ticketForm.get('assigned_to')?.invalid!"
                            [options]="helpdeskGroups()"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <adaa-form-input
                            formControlName="supportRequestStatus"
                            controlName="supportRequestStatus"
                            [label]="'helpdesk.service_now_status' | translate"
                            [isDisabled]="true"
                        />
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <adaa-form-input
                            formControlName="helpdeskReference"
                            controlName="helpdeskReference"
                            [label]="'helpdesk.service_now_reference' | translate"
                            [isDisabled]="true"
                        />
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center p-1 mt-3">
                    <p class="mx-auto fw-bold">{{ "helpdesk.comments_log" | translate }}</p>
                    <div>
                        @if (pageMode() !== PageMode.view) {
                            <button
                                type="button"
                                class="btn btn-sm bg-white rounded-circle shadow-sm text-primary"
                                [title]="'tooltips.add' | translate"
                                (click)="comment.open()"
                            >
                                <fa-icon [icon]="['fas', 'plus']"></fa-icon>
                            </button>
                        }
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 col-sm-12 col-xs-12">
                        <div>
                            <p class="mb-1 fw-bold">{{ "helpdesk.comments" | translate }}</p>
                            <div class="border border-2 rounded comments">
                                <ul>
                                    @for (comment of comments(); track $index) {
                                        <li (click)="highlight($index)" class="comment rounded p-3 my-2 d-flex justify-content-center">
                                            @if (comment.userName) {
                                                {{ comment.userName + " - " + comment.time }}
                                            }
                                            @if (comment.creationDate) {
                                                {{
                                                    AdaaHelper.getItemValueByToken(comment, "userName") +
                                                        " - " +
                                                        (comment.creationDate | dubaiTime | date: Constants.dateTime)
                                                }}
                                            }
                                        </li>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-8 col-sm-12 col-xs-12">
                        <div class="mt-1 pe-none">
                            <adaa-form-input
                                [label]="'helpdesk.comment' | translate"
                                [rows]="11"
                                [isTextArea]="true"
                                [setDefaultValue]="selected()"
                                [isDisabled]="true"
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
}

<adaa-form-action-buttons
    [saveButtonTitle]="'executive_teams.submit' | translate"
    [cancelButtonTitle]="pageMode() === PageMode.view ? ('common.form.button.close' | translate) : ('common.form.button.cancel' | translate)"
    [showSaveButton]="pageMode() !== PageMode.view"
    (saveClicked)="submit()"
    (cancelClicked)="goBack()"
/>

<adaa-support-center-attachment #attachment [pageMode]="attachmentPageMode()" (attachment)="addAttachment($event)" />

<adaa-helpdesk-comments #comment (comment)="addComment($event)" />
