import { Component, inject, input, OnInit, output, signal, TemplateRef, viewChild } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { AdaaHelper } from "../../../../core/utils";
import { Language, PageMode } from "../../../constants/enums";
import { PillarFocus } from "../../../models/gov-scores.model";
import { AppService } from "../../../services";
import { FormActionButtonsComponent, FormInputComponent } from "../../form";

@Component({
  selector: "adaa-area-of-focus",
  standalone: true,
  imports: [FormInputComponent, FormActionButtonsComponent, TranslateModule, ReactiveFormsModule],
  templateUrl: "./area-of-focus.component.html",
  styleUrl: "./area-of-focus.component.scss",
})
export class AreaOfFocusComponent implements OnInit {
  private _formBuilder = inject(FormBuilder);
  private _modalService = inject(NgbModal);
  private _appService = inject(AppService);
  private _translateService = inject(TranslateService);
  private _toastrService = inject(ToastrService);

  areaOfFocusForm: FormGroup = new FormGroup({});
  submitted = signal<boolean>(false);
  modal = viewChild.required<TemplateRef<unknown>>("content");
  pageMode = input<string>();
  focusList = input<PillarFocus[]>();
  focus = output<PillarFocus>();
  modalRef: NgbModalRef;
  PageMode = PageMode;
  focusIndex: number | null;

  public ngOnInit(): void {
    this._prepareForm();
  }

  private _prepareForm() {
    const isViewMode = this.pageMode() === PageMode.view;

    this.areaOfFocusForm = this._formBuilder.group({
      areaNameAE: this._formBuilder.control<string>(
        { value: "", disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      areaNameEN: this._formBuilder.control<string>(
        { value: "", disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      areaDscAE: this._formBuilder.control<string>({ value: "", disabled: isViewMode }),
      areaDscEN: this._formBuilder.control<string>({ value: "", disabled: isViewMode }),
    });
  }

  public open() {
    const modalRef = this._modalService.open(this.modal(), {
      animation: true,
      scrollable: false,
      keyboard: false,
      size: "lg",
      centered: true,
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });

    this.modalRef = modalRef;
    modalRef.dismissed.subscribe(() => this._resetForm());
    modalRef.closed.subscribe(() => this._resetForm());
  }

  public submit() {
    this.submitted.set(true);
    if (!this._checkUnique("areaNameAE") || !this._checkUnique("areaNameEN")) {
      this._toastrService.error(this._translateService.instant("notification.error.focus_not_unique"));
      return;
    }
    if (this.areaOfFocusForm.invalid) {
      this._toastrService.error(this._translateService.instant("notification.warning.missing_info"));
      return;
    }

    this.focus.emit(this.areaOfFocusForm.value);
    this.modalRef.close();
  }

  public checkInvalid(control: string) {
    const result =
      (this.areaOfFocusForm.controls[control]?.errors?.["required"] &&
        (this.areaOfFocusForm.get(control)?.dirty || this.submitted())) ||
      !this._checkUnique(control);
    return result;
  }

  private _checkUnique(control: string): boolean {
    const value = this.areaOfFocusForm.get(control)?.value;
    if (control === "areaNameAE" || control === "areaNameEN") {
      const index = this.focusList()?.findIndex((focus) => focus[control] === value);
      if (AdaaHelper.isDefinedAndNotEmpty(index) && index > -1 && index !== this.focusIndex) return false;
      else return true;
    } else return true;
  }

  private _resetForm() {
    this.areaOfFocusForm.reset();
    this.areaOfFocusForm.markAsPristine();
    this.areaOfFocusForm.markAsUntouched();
    this.submitted.set(false);
  }
}
