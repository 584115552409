import { Route } from "@angular/router";

import { checkIfLoggedInFn, pmoEntity } from "../../core/guards";
import { PageMode } from "../../shared/constants/enums";
import {
  createGovernmentScoresPageGuards,
  manageGovernmentScoresPageGuards,
  viewGovernmentScoresPageGuards,
} from "./_guards";
import { EntityPillarsEditorComponent } from "./entity-pillars/entity-pillars-editor/entity-pillars-editor.component";
import { EntityPillarsListComponent } from "./entity-pillars/entity-pillars-list/entity-pillars-list.component";
import { GovernmentScopesEditorComponent } from "./government-scopes/government-scopes-editor/government-scopes-editor.component";
import { GovernmentScopesListComponent } from "./government-scopes/government-scopes-list/government-scopes-list.component";
import { GovernmentScoresSubmenuComponent } from "./government-scores-submenu/government-scores-submenu.component";
import { GovernmentSectorsEditorComponent } from "./government-sectors/government-sectors-editor/government-sectors-editor.component";
import { GovernmentSectorsListComponent } from "./government-sectors/government-sectors-list/government-sectors-list.component";
import { StructureNavigationComponent } from "./structure-navigation/structure-navigation.component";

const sectorsRoutes: Route = {
  path: "sectors",
  children: [
    {
      path: "",
      component: GovernmentSectorsListComponent,
      canActivate: [checkIfLoggedInFn],
      data: {
        breadcrumb: "government_scores.government_sectors",
        title: "government_scores.government_sectors",
      },
    },
    {
      path: "create",
      component: GovernmentSectorsEditorComponent,
      canActivate: [checkIfLoggedInFn, ...createGovernmentScoresPageGuards],
      data: {
        breadcrumb: "government_scores.sectors.create",
        title: "government_scores.sectors.create",
        pageMode: PageMode.create,
      },
    },
    {
      path: "edit/:id",
      component: GovernmentSectorsEditorComponent,
      canActivate: [checkIfLoggedInFn, ...manageGovernmentScoresPageGuards],
      data: {
        breadcrumb: "government_scores.sectors.edit",
        title: "government_scores.sectors.edit",
        pageMode: PageMode.edit,
      },
    },
    {
      path: ":id",
      component: GovernmentSectorsEditorComponent,
      canActivate: [checkIfLoggedInFn],
      data: {
        breadcrumb: "government_scores.sectors.view",
        title: "government_scores.sectors.view",
        pageMode: PageMode.view,
      },
    },
  ],
};

const entityPillarsRoutes: Route = {
  path: "pillars",
  children: [
    {
      path: "",
      component: EntityPillarsListComponent,
      canActivate: [checkIfLoggedInFn],
      data: {
        breadcrumb: "government_scores.entity_pillars",
        title: "government_scores.entity_pillars",
      },
    },
    {
      path: "create",
      component: EntityPillarsEditorComponent,
      canActivate: [checkIfLoggedInFn, ...createGovernmentScoresPageGuards],
      data: {
        breadcrumb: "government_scores.pillars.create",
        title: "government_scores.pillars.create",
        pageMode: PageMode.create,
      },
    },
    {
      path: "edit/:id",
      component: EntityPillarsEditorComponent,
      canActivate: [checkIfLoggedInFn, ...manageGovernmentScoresPageGuards],
      data: {
        breadcrumb: "government_scores.pillars.edit",
        title: "government_scores.pillars.edit",
        pageMode: PageMode.edit,
      },
    },
    {
      path: ":id",
      component: EntityPillarsEditorComponent,
      canActivate: [checkIfLoggedInFn],
      data: {
        breadcrumb: "government_scores.pillars.view",
        title: "government_scores.pillars.view",
        pageMode: PageMode.view,
      },
    },
  ],
};

const scopesRoutes: Route = {
  path: "scopes",
  children: [
    {
      path: "",
      component: GovernmentScopesListComponent,
      canActivate: [checkIfLoggedInFn],
      data: {
        breadcrumb: "government_scores.scopes.title",
        title: "government_scores.scopes.title",
      },
    },
    {
      path: "create",
      component: GovernmentScopesEditorComponent,
      canActivate: [checkIfLoggedInFn, ...createGovernmentScoresPageGuards],
      data: {
        breadcrumb: "government_scores.scopes.create",
        title: "government_scores.scopes.create",
        pageMode: PageMode.create,
      },
    },
    {
      path: "edit/:id",
      component: GovernmentScopesEditorComponent,
      canActivate: [checkIfLoggedInFn, ...manageGovernmentScoresPageGuards],
      data: {
        breadcrumb: "government_scores.scopes.edit",
        title: "government_scores.scopes.edit",
        pageMode: PageMode.edit,
      },
    },
    {
      path: ":id",
      component: GovernmentScopesEditorComponent,
      canActivate: [checkIfLoggedInFn],
      data: {
        breadcrumb: "government_scores.scopes.view",
        title: "government_scores.scopes.view",
        pageMode: PageMode.view,
      },
    },
  ],
};

const structureNavigationRoutes: Route = {
  path: "navigation",
  component: StructureNavigationComponent,
  canActivate: [checkIfLoggedInFn, ...viewGovernmentScoresPageGuards],
  data: {
    breadcrumb: "government_scores.structure_navigation",
    title: "government_scores.structure_navigation",
  },
};

export const GOVERNMENT_SCORES_ROUTES: Route[] = [
  {
    path: "government-scores",
    canActivate: [...viewGovernmentScoresPageGuards, pmoEntity],
    children: [
      {
        path: "",
        canActivate: [checkIfLoggedInFn],
        component: GovernmentScoresSubmenuComponent,
        data: {
          breadcrumb: "government_scores.page_title",
          title: "government_scores.page_title",
        },
      },
      sectorsRoutes,
      entityPillarsRoutes,
      scopesRoutes,
      structureNavigationRoutes,
    ],
  },
];
