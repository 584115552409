<adaa-data-table
    #adaaDataTable
    [titleHeader]="'breadcrumbs.other_entities'"
    [configKey]="'other_entities_conf_list'"
    [apiName]="'getOtherEntitiesList'"
    [configKey]="tableConfList()"
    [itemTypeId]="constants.CONSTANT_PERMISSIONS.OTHER_ENTITIES"
    [enableEditAction]="true"
    [enableDeleteAction]="true"
    [enableViewAction]="true"
    [enableAdd]="true"
    (addNewClicked)="addNewClicked()"
    (actionClicked)="getAction($event)"
></adaa-data-table>

<adaa-float-action />
