@if (isDefined(model)) {
    <form
        autocomplete="on"
        spellcheck="true"
        class="d-block w-100 position-relative"
        [dir]="languageService.direction()"
        [attr.lang]="languageService.current()"
        [formGroup]="model"
    >
        <div class="w-100 d-block card mb-2 p-2 bg-white border-0 form-section">
            @let initiativesList = infoTabService.initiatives();
            @if (isOPM() && isDefined(initiativesList)) {
                <adaa-initiatives-field
                    [kpiType]="kpiType()"
                    [isRequired]="isFieldRequired(validations(), 'initiativeId')"
                    [isDisabled]="false"
                    [label]="initiativeTranslation"
                    [options]="infoTabService.initiativesOptions()"
                    (onChange)="handleInitiativesChanges($event, initiativesList)"
                />
            }

            @if (isSKPI() || (isEKPI() && isPMOEntity()) || isSRVKPI()) {
                <adaa-objectives-field
                    [kpiType]="kpiType()"
                    [isRequired]="isFieldRequired(validations(), isSRVKPI() ? 'serviceId' : 'objectiveId')"
                    [isDisabled]="isEKPI() && !isPMOEntity()"
                    [label]="isSRVKPI() ? 'srvkpi.serviceObjective' : 'skpi.objective'"
                    (onChange)="handleObjectiveChanges($event)"
                />
            }

            @if (isEKPI() && !isPMOEntity()) {
                @if (isArabic) {
                    <adaa-form-input
                        controlName="objectiveNameAE"
                        formControlName="objectiveNameAE"
                        [label]="'skpi.objective' | translate"
                        [required]="isFieldRequired(validations(), 'objectiveId')"
                    />
                } @else {
                    <adaa-form-input
                        controlName="objectiveNameEN"
                        formControlName="objectiveNameEN"
                        [label]="'skpi.objective' | translate"
                        [required]="isFieldRequired(validations(), 'objectiveId')"
                    />
                }
            }

            @let projectMilestoneList = infoTabService.projectMilestones();
            @if (isMTKPI() && isDefined(projectMilestoneList)) {
                <adaa-project-milestone-field
                    label="mtkpi.projectMilestoneId"
                    [list]="projectMilestoneList"
                    [isRequired]="isFieldRequired(validations(), 'projectMilestoneId')"
                    [isDisabled]="disableProjectMilestoneField()"
                    (onChange)="handleTpMilestoneChanges($event, projectMilestoneList)"
                />
            }

            @if (isSRVKPI()) {
                <adaa-services-field [isRequired]="isFieldRequired(validations(), 'serviceId')" (onChange)="handleServicesChanges($event)" />
            }

            @if (isMOKPI()) {
                @let tpList = infoTabService.transformationProjects();
                @let entityList = entities();

                @if (tpList && entityList.length > 0) {
                    <section class="d-flex row pb-3">
                        <div class="col-sm-6 col-xs-12">
                            <adaa-tp-selection-field
                                [list]="tpList"
                                [entities]="entityList"
                                [isRequired]="isFieldRequired(validations(), 'nationalProjectId')"
                                [isDisabled]="disableTpSelectionField()"
                                (onChange)="handleTpSelectionFieldChanges($event, tpList)"
                            />
                        </div>

                        <div class="col-sm-6 col-xs-12">
                            <adaa-form-input
                                controlName="accountableEntityId"
                                formControlName="accountableEntityId"
                                classes="form-control shadow-none"
                                [label]="'national_projects.accountable_entity' | translate"
                                [adaaInputDisabled]="true"
                            />
                        </div>
                    </section>
                }
            }

            <section class="d-flex row mb-2">
                <div class="col-sm-6 col-xs-12">
                    <adaa-form-input
                        controlName="nameAE"
                        formControlName="nameAE"
                        classes="form-control shadow-none"
                        [invalid]="model.get('nameAE')?.touched! && model.get('nameAE')?.invalid!"
                        [label]="'common.form.label.nameAE' | translate"
                        [required]="isFieldRequired(validations(), 'nameAE')"
                        [adaaInputDisabled]="
                            checkEkpiReadOnlyField() ||
                            isDerivedSKPI() ||
                            checkNTkpiReadOnlyField() ||
                            checkDTkpiReadOnlyField() ||
                            isTransformationalTargetSelected()
                        "
                    />
                </div>

                <div class="col-sm-6 col-xs-12">
                    <adaa-form-input
                        controlName="nameEN"
                        formControlName="nameEN"
                        classes="form-control shadow-none"
                        [invalid]="model.get('nameEN')?.touched! && model.get('nameEN')?.invalid!"
                        [label]="'common.form.label.nameEN' | translate"
                        [required]="isFieldRequired(validations(), 'nameEN')"
                        [adaaInputDisabled]="
                            checkEkpiReadOnlyField() ||
                            isDerivedSKPI() ||
                            checkNTkpiReadOnlyField() ||
                            checkDTkpiReadOnlyField() ||
                            isTransformationalTargetSelected()
                        "
                    />
                </div>
            </section>

            <section class="d-flex row mb-2">
                <div class="col-sm-6 col-xs-12">
                    <adaa-form-input
                        controlName="dscAE"
                        formControlName="dscAE"
                        classes="form-control shadow-none"
                        [invalid]="model.get('dscAE')?.touched! && model.get('dscAE')?.invalid!"
                        [label]="'common.form.label.descriptionAE' | translate"
                        [isTextArea]="true"
                        [required]="isFieldRequired(validations(), 'dscAE')"
                        [adaaInputDisabled]="
                            checkEkpiReadOnlyField() ||
                            isDerivedSKPI() ||
                            checkNTkpiReadOnlyField() ||
                            checkDTkpiReadOnlyField() ||
                            isTransformationalTargetSelected()
                        "
                    />
                </div>

                <div class="col-sm-6 col-xs-12">
                    <adaa-form-input
                        controlName="dscEN"
                        formControlName="dscEN"
                        classes="form-control shadow-none"
                        [invalid]="model.get('dscEN')?.touched! && model.get('dscEN')?.invalid!"
                        [label]="'common.form.label.descriptionEN' | translate"
                        [isTextArea]="true"
                        [required]="isFieldRequired(validations(), 'dscEN')"
                        [adaaInputDisabled]="
                            checkEkpiReadOnlyField() ||
                            isDerivedSKPI() ||
                            checkNTkpiReadOnlyField() ||
                            checkDTkpiReadOnlyField() ||
                            isTransformationalTargetSelected()
                        "
                    />
                </div>
            </section>

            <section class="d-flex row mb-2">
                <div class="col-sm-6 col-xs-12">
                    <adaa-form-input-date
                        controlName="startDate"
                        formControlName="startDate"
                        [invalid]="model.get('startDate')?.touched! && (model.get('startDate')?.invalid! || model.errors?.startDate_endDate_Invalid)"
                        [label]="'common.form.label.start_date' | translate"
                        [required]="isFieldRequired(validations(), 'startDate')"
                        [adaaInputDisabled]="checkEkpiReadOnlyField() || isDerivedSKPI() || checkNTkpiReadOnlyField() || checkDTkpiReadOnlyField()"
                        (inputChanges)="checkDates($event, 'startDate')"
                    />
                </div>

                <div class="col-sm-6 col-xs-12">
                    <adaa-form-input-date
                        controlName="endDate"
                        formControlName="endDate"
                        [invalid]="model.get('endDate')?.touched! && (model.get('endDate')?.invalid! || model.errors?.startDate_endDate_Invalid)"
                        [label]="'common.form.label.end_date' | translate"
                        [required]="isFieldRequired(validations(), 'endDate')"
                        [adaaInputDisabled]="checkEkpiReadOnlyField() || isDerivedSKPI() || checkNTkpiReadOnlyField() || checkDTkpiReadOnlyField()"
                        (inputChanges)="checkDates($event, 'endDate')"
                    />
                </div>

                @if (isDTKPI()) {
                    <div class="col-12 d-flex justify-content-center mt-4">
                        <adaa-form-checkbox
                            controlName="published"
                            formControlName="published"
                            checkboxMode="yesNo"
                            [useSwitch]="true"
                            [boldLabel]="model.get('published')?.value === 'Y'"
                            [label]="'nkpi.published' | translate"
                        />
                    </div>
                }
            </section>
        </div>

        @if (isMOKPI()) {
            @let transformationalProject = stagedKpi()?.nationalProjectId;
            @if (isDefined(transformationalProject)) {
                <div class="w-100 d-block card mb-2 p-2 bg-white border-0 form-section">
                    <section class="d-flex row">
                        @let mainOutcomes = mainOutcomeList();
                        @if (mainOutcomes.length > 0) {
                            <div
                                [class.col-md-6]="mainOutcomeTypeField?.isTransformationalTargetSelected()"
                                [class.col-12]="!mainOutcomeTypeField?.isTransformationalTargetSelected()"
                            >
                                <adaa-main-outcome-type-field
                                    #mainOutcomeTypeField
                                    [isDisabled]="disableMainOutcomeType()"
                                    [isRequired]="true"
                                    [list]="mainOutcomeList()"
                                    (onChange)="handleMainOutcomeTypeChanges($event.isTransformationalTargetSelected)"
                                />
                            </div>

                            @if (mainOutcomeTypeField?.isTransformationalTargetSelected()) {
                                @let transformationalTargets = infoTabService.transformationalTargets();
                                @if (isDefined(transformationalTargets)) {
                                    <div class="col-xs-12 col-md-6">
                                        <adaa-transformational-target-field
                                            [isRequired]="true"
                                            [isDisabled]="false"
                                            [list]="transformationalTargets"
                                        />
                                    </div>
                                }
                            }
                        }
                    </section>
                </div>
            }
        }

        <div class="w-100 d-block card mb-2 bg-white border-0 form-section">
            @if (isEKPI()) {
                <section class="d-flex row p-2">
                    <div class="col-sm-6 col-xs-12">
                        <adaa-form-dropdown
                            controlName="ekpiPillarId"
                            formControlName="ekpiPillarId"
                            [clearable]="true"
                            [searchable]="true"
                            [label]="'ekpi.piller' | translate"
                            [options]="pillarOptions()"
                            [invalid]="model.get('ekpiPillarId')?.touched! && model.get('ekpiPillarId')?.invalid!"
                            [required]="isFieldRequired(validations(), 'ekpiPillarId')"
                        />
                    </div>
                    <div class="col-sm-6 col-xs-12">
                        <adaa-form-dropdown
                            controlName="enablerEntityId"
                            formControlName="enablerEntityId"
                            [clearable]="true"
                            [searchable]="true"
                            [label]="'ekpi.enablerEntity' | translate"
                            [options]="enablerEntityOptions()"
                            [invalid]="model.get('enablerEntityId')?.touched! && model.get('enablerEntityId')?.invalid!"
                            [required]="isFieldRequired(validations(), 'enablerEntityId')"
                        />
                    </div>
                </section>
            }

            @if (!isDTKPI() && !isNTKPI()) {
                <!-- NOTE: IF EKPI ONLY SHOW THIS SECTION IF THE ENABLER ENTITY IS SELECTED -->
                @if ((isEKPI() && model.get("enablerEntityId")?.value) || !isEKPI()) {
                    <section class="d-flex row p-2">
                        <div class="col-sm-6 col-xs-12">
                            <adaa-form-dropdown-tree
                                controlName="orgUnitId"
                                formControlName="orgUnitId"
                                [label]="'common.form.label.organization_unit' | translate"
                                [options]="orgUnitsOptions()"
                                [invalid]="model.get('orgUnitId')?.touched! && model.get('orgUnitId')?.invalid!"
                                [required]="isFieldRequired(validations(), 'orgUnitId')"
                                (inputChanges)="appendOrgUnit($event)"
                            />
                        </div>

                        <div class="col-sm-6 col-xs-12">
                            <adaa-form-dropdown
                                controlName="owner"
                                formControlName="owner"
                                [clearable]="true"
                                [searchable]="true"
                                [label]="'nkpi.owner' | translate"
                                [options]="ownersOptions()"
                                [invalid]="model.get('owner')?.touched! && model.get('owner')?.invalid!"
                                [required]="isFieldRequired(validations(), 'owner')"
                                [adaaInputDisabled]="!isDefined(model.get('orgUnitId')?.value)"
                            />
                        </div>
                    </section>
                }
            }

            @if (isMasterEKPI()) {
                <section class="d-flex row mt-3 p-2">
                    <div class="col-12 px-3">
                        <adaa-form-two-way-select
                            controlName="affectedEntitiesIds"
                            formControlName="affectedEntitiesIds"
                            label="entity.create.list"
                            notSelectedLabel="ekpi.affectedEntities"
                            selectedLabel="ekpi.selected_entities"
                            [options]="affectedEntitiesOptions()"
                            [setValidator]="{ parameterCatalogs: validations() }"
                            [isDisabled]="!isPMOEntity()"
                        />
                    </div>
                </section>
                @if (isPMOEntity()) {
                    <section class="d-flex row mt-3">
                        <div class="col-sm-6 col-xs-12">
                            <adaa-form-dropdown-tree
                                controlName="pmoOrgUnitId"
                                formControlName="pmoOrgUnitId"
                                [label]="'ekpi.pmo_org_unit' | translate"
                                [options]="pmoOrgUnitsOptions()"
                                [invalid]="model.get('pmoOrgUnitId')?.touched! && model.get('pmoOrgUnitId')?.invalid!"
                                [required]="isFieldRequired(validations(), 'pmoOrgUnitId')"
                            />
                        </div>
                        <div class="col-sm-6 col-xs-12">
                            <adaa-form-dropdown
                                controlName="pmoOwner"
                                formControlName="pmoOwner"
                                [searchable]="true"
                                [label]="'ekpi.pmo_owner' | translate"
                                [options]="pmoOwnersOptions()"
                                [invalid]="model.get('pmoOwner')?.touched! && model.get('pmoOwner')?.invalid!"
                                [required]="isFieldRequired(validations(), 'pmoOwner')"
                            />
                        </div>
                    </section>
                }
            }
        </div>

        @if (isSRVKPI()) {
            <div class="w-100 d-block card mb-2 p-2 bg-white border-0 form-section">
                <section class="d-flex row">
                    <div class="col-sm-6 col-xs-12">
                        <adaa-form-dropdown
                            controlName="classification"
                            formControlName="classification"
                            [clearable]="true"
                            [searchable]="true"
                            [label]="'srvkpi.classification' | translate"
                            [options]="classificationOptions()"
                            [invalid]="model.get('classification')?.touched! && model.get('classification')?.invalid!"
                            [required]="isFieldRequired(validations(), 'classification')"
                            [adaaInputDisabled]="!isPmoUser()"
                        />
                    </div>

                    <div class="col-sm-6 col-xs-12">
                        <adaa-form-dropdown
                            controlName="perspective"
                            formControlName="perspective"
                            [clearable]="true"
                            [searchable]="true"
                            [label]="'srvkpi.perspective' | translate"
                            [options]="perspectiveOptions()"
                            [invalid]="model.get('perspective')?.touched! && model.get('perspective')?.invalid!"
                            [required]="isFieldRequired(validations(), 'perspective')"
                        />
                    </div>
                </section>

                <section class="d-flex justify-content-center row">
                    <div class="col-4 d-flex justify-content-center">
                        <adaa-form-checkbox
                            controlName="importedValues"
                            formControlName="importedValues"
                            checkboxMode="yesNo"
                            [useSwitch]="true"
                            [boldLabel]="model.get('importedValues')?.value === 'Y'"
                            [label]="'srvkpi.importValues' | translate"
                            [adaaInputDisabled]="!isPmoUser()"
                        />
                    </div>

                    @if (isPmoUser()) {
                        <div class="col-4 d-flex justify-content-center">
                            <adaa-form-checkbox
                                controlName="verifiedValues"
                                formControlName="verifiedValues"
                                checkboxMode="yesNo"
                                [useSwitch]="true"
                                [boldLabel]="model.get('verifiedValues')?.value === 'Y'"
                                [label]="'srvkpi.actualValues' | translate"
                                [adaaInputDisabled]="!isPmoUser()"
                            />
                        </div>

                        <div class="col-4 d-flex justify-content-center">
                            <adaa-form-checkbox
                                controlName="veryImportant"
                                formControlName="veryImportant"
                                checkboxMode="yesNo"
                                [adaaInputDisabled]="!isPmoUser()"
                                [useSwitch]="isPmoUser()"
                                [boldLabel]="model.get('veryImportant')?.value === 'Y'"
                                [label]="'srvkpi.veryImportant' | translate"
                            />
                        </div>
                    }
                </section>

                <section class="d-flex row">
                    @if (isPmoUser() && showVeryImportantReason()) {
                        <div class="col-12 mt-2">
                            <adaa-form-input
                                controlName="veryImportantReasonAE"
                                formControlName="veryImportantReasonAE"
                                classes="form-control shadow-none"
                                [isTextArea]="true"
                                [invalid]="model.get('veryImportantReasonAE')?.touched! && model.get('veryImportantReasonAE')?.invalid!"
                                [label]="'common.form.label.veryImportantReason' | translate"
                                [showAsRequired]="showVeryImportantReason()"
                            />
                        </div>
                    }
                </section>
            </div>
        }

        @if (isNTKPI() || isDTKPI()) {
            <div class="w-100 d-block card mb-2 p-2 bg-white border-0 form-section">
                <section class="d-flex row py-1">
                    <div class="col-xs-12" [class.col-md-6]="!isNTKPI()" [class.col-md-4]="isNTKPI()">
                        <adaa-gov-directions-field
                            field="directionId"
                            [list]="govDirections()"
                            [label]="govDirectionLabel()"
                            [isRequired]="isFieldRequired(validations(), 'subDirectionId')"
                            [isDisabled]="isNTKPI() || checkNTkpiReadOnlyField() || checkDTkpiReadOnlyField()"
                            (selectedValue)="handleGovDirectionChanges($event)"
                        />
                    </div>

                    <div class="col-md-6 col-xs-12" [class.col-md-6]="!isNTKPI()" [class.col-md-4]="isNTKPI()">
                        <adaa-gov-directions-field
                            field="subDirectionId"
                            [list]="subGovDirections()"
                            [label]="subGDirectionLabel()"
                            [isRequired]="isFieldRequired(validations(), 'subDirectionId')"
                            [isDisabled]="!isDefined(model.get('directionId')?.value) || checkNTkpiReadOnlyField() || checkDTkpiReadOnlyField()"
                            (selectedValue)="handleSubGovDirectionChanges($event)"
                        />
                    </div>

                    @if (isNTKPI()) {
                        <div class="col-md-4 col-xs-12">
                            <adaa-gov-directions-field
                                field="auxiliaryDirectionId"
                                label="directions.sub_pillar"
                                [list]="auxGovDirections()"
                                [isRequired]="isFieldRequired(validations(), 'subDirectionId')"
                                [isDisabled]="!isDefined(stagedKpi()?.subDirectionId) || !isPmoUser()"
                            />
                        </div>
                    }
                </section>

                <section class="d-flex row py-2">
                    <div class="col-xs-12" [class.col-md-4]="isNTKPI()" [class.col-md-6]="!isNTKPI()">
                        <adaa-sponsor-entity-field
                            [entities]="entities()"
                            [isRequired]="isFieldRequired(validations(), 'sponsorEntityId')"
                            [isDisabled]="disableSponsorEntity()"
                        />
                    </div>

                    <div class="col-xs-12" [class.col-md-4]="isNTKPI()" [class.col-md-6]="!isNTKPI()">
                        <adaa-form-input
                            controlName="dataSource"
                            formControlName="dataSource"
                            classes="form-control shadow-none"
                            [invalid]="model.get('dataSource')?.touched! && model.get('dataSource')?.invalid!"
                            [label]="'nkpi.data_source_entity_source' | translate"
                            [required]="isFieldRequired(validations(), 'dataSource') || (isNTKPI() && !isPmoUser() && pageMode() === 'edit')"
                        />
                    </div>

                    @if (isNTKPI()) {
                        <div class="col-md-4 col-xs-12">
                            <adaa-form-dropdown
                                controlName="actualDataAvailabilityPeriod"
                                formControlName="actualDataAvailabilityPeriod"
                                [clearable]="true"
                                [searchable]="true"
                                [label]="'ntkpi.actual_data_availability_period' | translate"
                                [options]="monthsOptions()"
                                [invalid]="model.get('actualDataAvailabilityPeriod')?.touched! && model.get('actualDataAvailabilityPeriod')?.invalid!"
                                [required]="isFieldRequired(validations(), 'actualDataAvailabilityPeriod') || isFieldRequiredForNTKPIandDTKPI()"
                            />
                        </div>
                    }
                </section>

                @if (isNTKPI()) {
                    <section class="d-flex row py-2">
                        <div class="col-md-6 col-xs-12">
                            <adaa-form-dropdown
                                controlName="publishedState"
                                formControlName="publishedState"
                                [clearable]="true"
                                [searchable]="true"
                                [label]="'published_state' | translate"
                                [options]="publishedStateOptions()"
                                [invalid]="model.get('publishedState')?.touched! && model.get('publishedState')?.invalid!"
                                [required]="isFieldRequired(validations(), 'publishedState')"
                                [adaaInputDisabled]="!isPMOEntity()"
                            />
                        </div>

                        <div class="col-md-6 col-xs-12">
                            <adaa-form-dropdown
                                controlName="targetCategory"
                                formControlName="targetCategory"
                                [clearable]="true"
                                [searchable]="true"
                                [label]="'target_category' | translate"
                                [options]="targetCategoryOptions()"
                                [invalid]="model.get('targetCategory')?.touched! && model.get('targetCategory')?.invalid!"
                                [required]="isFieldRequired(validations(), 'targetCategory')"
                                [adaaInputDisabled]="!isPMOEntity()"
                            />
                        </div>
                    </section>
                }

                <section class="d-flex row">
                    <div class="col-12">
                        <adaa-contributing-entities-dropdown-field
                            label="nkpi.contributingEntities"
                            field="contributingEntities"
                            [list]="entities()"
                            [kpiType]="kpiType()"
                            [isRequired]="
                                isFieldRequired(validations(), 'contributingEntities') ||
                                ((isDTKPI() || isNTKPI()) && !isPMOEntity() && pageMode() === 'edit')
                            "
                            [isDisabled]="false"
                        />
                    </div>

                    <div class="col-12 pt-2">
                        <adaa-contributing-entities-dropdown-field
                            label="other_entities.contrib_entities"
                            field="otherContributingEntities"
                            [list]="otherEntities()"
                            [kpiType]="kpiType()"
                            [isRequired]="
                                isFieldRequired(validations(), 'otherContributingEntities') ||
                                ((isDTKPI() || isNTKPI()) && !isPMOEntity() && pageMode() === 'edit')
                            "
                            [isDisabled]="false"
                        />
                    </div>
                </section>

                <section class="d-flex row pt-2">
                    <div class="col-sm-6 col-xs-12">
                        <adaa-form-dropdown-tree
                            controlName="orgUnitId"
                            formControlName="orgUnitId"
                            [label]="'common.form.label.organization_unit' | translate"
                            [options]="orgUnitsOptions()"
                            [invalid]="model.get('orgUnitId')?.touched! && model.get('orgUnitId')?.invalid!"
                            [required]="isFieldRequired(validations(), 'orgUnitId') || (isNTKPI() && !isPmoUser() && pageMode() === 'edit')"
                            (inputChanges)="appendOrgUnit($event)"
                        />
                    </div>

                    <div class="col-sm-6 col-xs-12">
                        <adaa-form-dropdown
                            controlName="owner"
                            formControlName="owner"
                            [clearable]="true"
                            [searchable]="true"
                            [label]="'nkpi.owner' | translate"
                            [options]="ownersOptions()"
                            [invalid]="model.get('owner')?.touched! && model.get('owner')?.invalid!"
                            [required]="isFieldRequired(validations(), 'owner') || (isNTKPI() && !isPmoUser() && pageMode() === 'edit')"
                            [adaaInputDisabled]="!isDefined(model.get('orgUnitId')?.value)"
                        />
                    </div>
                </section>
            </div>
        }

        <div class="w-100 d-block card mb-2 p-2 bg-white border-0 form-section">
            @if (isNTKPI()) {
                <section class="d-flex row pt-2">
                    <div class="col-md-6 col-12">
                        <adaa-form-input
                            controlName="measurementMechanism"
                            formControlName="measurementMechanism"
                            classes="form-control shadow-none"
                            [invalid]="model.get('measurementMechanism')?.touched! && model.get('measurementMechanism')?.invalid!"
                            [label]="'ntkpi.explanation_of_the_measurement_mechanism' | translate"
                            [required]="
                                isFieldRequired(validations(), 'measurementMechanism') || (isNTKPI() && !isPmoUser() && pageMode() === 'edit')
                            "
                        />
                    </div>

                    <div class="col-sm-6 col-xs-12">
                        <adaa-form-dropdown
                            controlName="targetTypeId"
                            formControlName="targetTypeId"
                            [clearable]="true"
                            [searchable]="true"
                            [label]="'ntkpi.target_type' | translate"
                            [options]="targetTypeOptions()"
                            [invalid]="model.get('targetTypeId')?.touched! && model.get('targetTypeId')?.invalid!"
                            [required]="isFieldRequired(validations(), 'targetTypeId') || isPMOEntity()"
                            [adaaInputDisabled]="!isPMOEntity()"
                        />
                    </div>

                    <div class="col-12 pt-2">
                        <adaa-form-dropdown-multi
                            controlName="internationalOrganizations"
                            formControlName="internationalOrganizations"
                            [clearable]="true"
                            [searchable]="true"
                            [label]="'ntkpi.international_organizations' | translate"
                            [options]="internationalOrganizations()"
                            [invalid]="model.get('internationalOrganizations')?.touched! && model.get('internationalOrganizations')?.invalid!"
                            [required]="isFieldRequired(validations(), 'internationalOrganizations')"
                        />
                    </div>
                </section>
            }

            <section class="d-flex row pt-2">
                @if (!isDTKPI() && !isNTKPI()) {
                    <div class="col-12 pt-2">
                        <adaa-form-input
                            controlName="dataSource"
                            formControlName="dataSource"
                            classes="form-control shadow-none"
                            [invalid]="model.get('dataSource')?.touched! && model.get('dataSource')?.invalid!"
                            [label]="'nkpi.data_source_entity_source' | translate"
                            [required]="isFieldRequired(validations(), 'dataSource')"
                        />
                    </div>
                }

                <div class="col-12 pt-2">
                    <adaa-form-input
                        controlName="scopeMeasureAE"
                        formControlName="scopeMeasureAE"
                        classes="form-control shadow-none"
                        [invalid]="model.get('scopeMeasureAE')?.touched! && model.get('scopeMeasureAE')?.invalid!"
                        [label]="'nkpi.scopeMeasurement' | translate"
                        [required]="isFieldRequired(validations(), 'scopeMeasureAE') || (isNTKPI() && !isPmoUser() && pageMode() === 'edit')"
                        [adaaInputDisabled]="checkDTkpiReadOnlyField() || checkEkpiReadOnlyField()"
                    />
                </div>
            </section>

            <section class="d-flex row">
                @if (isMTKPI() || isNTKPI() || isDTKPI() || isMOKPI()) {
                    <div class="col-xs-12 pt-2" [class.col-md-6]="!isNTKPI()" [class.col-12]="isNTKPI()">
                        <adaa-form-input
                            controlName="visionTarget"
                            formControlName="visionTarget"
                            inputType="positiveNumber"
                            [label]="(isNTKPI() ? 'kpi.nkpi_target' : 'kpi.dkpi_target') | translate"
                            [invalid]="model.get('visionTarget')?.touched! && model.get('visionTarget')?.invalid!"
                            [required]="isFieldRequired(validations(), 'visionTarget')"
                            [adaaInputDisabled]="checkNTkpiReadOnlyField() || checkDTkpiReadOnlyField()"
                        />
                    </div>
                }

                @if (!isNTKPI()) {
                    <div
                        class="col-12 pt-2"
                        [class.col-md-6]="isMTKPI() || isDTKPI() || isMOKPI()"
                        [class.col-xs-12]="isMTKPI() || isDTKPI() || isMOKPI()"
                    >
                        <adaa-form-dropdown
                            controlName="legacyKpi"
                            formControlName="legacyKpi"
                            [searchable]="true"
                            [clearable]="true"
                            [label]="'kpi.legacy_kpi' | translate"
                            [options]="legacyKpisOptions()"
                            [invalid]="model.get('legacyKpi')?.touched! && model.get('legacyKpi')?.invalid!"
                            [required]="isFieldRequired(validations(), 'legacyKpi')"
                            [adaaInputDisabled]="checkEkpiReadOnlyField() || isDerivedSKPI()"
                            (onSearch)="onLegacyKpiSearch($event)"
                        />
                    </div>
                }
            </section>
        </div>

        <div class="w-100 d-block card mb-2 p-2 bg-white border-0 form-section">
            <section class="d-flex row">
                <div class="col-12">
                    <adaa-form-input
                        controlName="commentsAE"
                        formControlName="commentsAE"
                        classes="form-control shadow-none"
                        [invalid]="model.get('commentsAE')?.touched! && model.get('commentsAE')?.invalid!"
                        [label]="'common.form.label.commentsAE' | translate"
                        [isTextArea]="true"
                        [required]="isFieldRequired(validations(), 'commentsAE')"
                    />
                </div>
            </section>
        </div>
    </form>
}
