import { Component, inject, viewChild } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { ConfirmationModalComponent, DataTableComponent } from "../../../shared/components";
import { Constants } from "../../../shared/constants/constants";
import { NationalStrategyDetailed, TableButtonClicked } from "../../../shared/models";
import { GovDirectionApiService, LanguageService } from "../../../shared/services";

@Component({
  selector: "adaa-national-strategies-list",
  standalone: true,
  imports: [DataTableComponent],
  templateUrl: "./national-strategies-list.component.html",
  styleUrl: "./national-strategies-list.component.scss",
})
export class NationalStrategiesListComponent {
  private _router = inject(Router);
  private _modalService = inject(NgbModal);
  private _toastrService = inject(ToastrService);
  private _translateService = inject(TranslateService);
  private _languageService = inject(LanguageService);
  private _govDirectionApiService = inject(GovDirectionApiService);

  private _nsTable = viewChild<DataTableComponent>("nsTable");

  constants = Constants;

  public addNewClicked() {
    this._router.navigateByUrl(`/console/national-strategies/create`);
  }

  public actionClicked(action: TableButtonClicked): void {
    switch (action.event) {
      case "delete":
        this._deleteNationalStrategy(action.data);
        break;
      case "view":
        this._router.navigateByUrl(`/console/national-strategies/${action.data.id}`);
        break;
      case "edit":
        this._router.navigateByUrl(`/console/national-strategies/edit/${action.data.id}`);
        break;
      default:
        break;
    }
  }

  private _deleteNationalStrategy(nationalStrategy: NationalStrategyDetailed): void {
    const modal = this._modalService.open(ConfirmationModalComponent, {
      centered: true,
      size: "md",
      modalDialogClass: this._languageService.modalDirection(),
    });
    modal.componentInstance.header = "national_strategies.delete_strategy.title";
    modal.componentInstance.title = "national_strategies.delete_strategy.message";
    modal.result.then((e) => {
      if (e) this._deleteNationalStrategyConfirmed(nationalStrategy);
    });
  }

  private _deleteNationalStrategyConfirmed(nationalStrategy: NationalStrategyDetailed): void {
    this._govDirectionApiService.delete(nationalStrategy.id).subscribe({
      next: (response) => {
        if (response.inError) return;

        this._toastrService.success(this._translateService.instant("notification.success.remove"));
        this._nsTable()?.loadTableData();
      },
    });
  }
}
