import { CommonModule } from "@angular/common";
import { Component, EventEmitter, forwardRef, inject, Input, OnInit, Output } from "@angular/core";
import { ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbTimepickerConfig, NgbTimepickerModule, NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";

import { AdaaHelper } from "../../../../core/utils";
import { ParameterCatalog } from "../../../models";
import { LanguageService } from "../../../services";

@Component({
  selector: "adaa-form-input-time",
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NgbTimepickerModule, FontAwesomeModule],
  templateUrl: "./form-input-time.component.html",
  styleUrl: "./form-input-time.component.scss",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FormInputTimeComponent),
    },
  ],
})
export class FormInputTimeComponent implements OnInit, ControlValueAccessor {
  inputControl: FormControl = new FormControl();
  adaaHelper = AdaaHelper;
  languageService = inject(LanguageService);

  @Input() label: string = "";
  @Input() invalid: boolean = false;
  @Input() controlName: string | null = null;
  @Input() controlId: string | null = null;
  @Input() required: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input()
  public set setValidator(validatorList: { parameterCatalogs: ParameterCatalog[] }) {
    if (validatorList) {
      if (validatorList.parameterCatalogs) {
        const field = validatorList.parameterCatalogs.find((e) => e.fieldName === this.controlName);

        if (field) {
          this.required = field.mandatory === "Y";
          this.inputControl.updateValueAndValidity();
        }
      }
    }
  }
  @Output() inputChanges = new EventEmitter();

  time: NgbTimeStruct;
  disabled = false;

  private _onChange = (_time: NgbTimeStruct) => {};
  private _onTouched = () => {};

  constructor(private _config: NgbTimepickerConfig) {
    this._config.spinners = false;
    this._config.meridian = true;
  }

  public ngOnInit(): void {
    if (!this.adaaHelper.isDefined(this.controlId)) this.controlId = this.controlName;
    this.inputControl.updateValueAndValidity();
  }

  public writeValue(time: NgbTimeStruct): void {
    if (time) {
      this.time = time;
    }
  }

  public registerOnChange(fn: (time: NgbTimeStruct) => void): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public onTimeChange(time: NgbTimeStruct): void {
    this.time = time;
    this._onChange(time);
    this._onTouched();
  }

  public valueChanged(time: NgbTimeStruct): void {
    this.time = time;
    this._onChange(time);
    this._onTouched();
    this.inputChanges.emit(time);
  }
}
