import { NgClass } from "@angular/common";
import { Component, inject, input, OnInit, signal } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { forkJoin, pairwise, startWith } from "rxjs";

import { AdaaHelper } from "../../../../core/utils";
import {
  FloatActionComponent,
  FormDropdownComponent,
  FormDropdownMultiComponent,
  FormInputComponent,
  FormStepsComponent,
  ScopeKpiListComponent,
} from "../../../../shared/components";
import { genericFloatButtons } from "../../../../shared/components/general/float-action";
import { CommonFloatActionButtonType } from "../../../../shared/components/general/float-action/types";
import { Constants } from "../../../../shared/constants/constants";
import { PageMode } from "../../../../shared/constants/enums";
import {
  FormTab,
  GovScoresPillar,
  ObjectiveModelType,
  ScopeMainKpi,
  ValidatorModelType,
  ValueText,
} from "../../../../shared/models";
import { GovScoresScopesApiService, SystemLayoutService, ValidatorApiService } from "../../../../shared/services";

@Component({
  selector: "adaa-government-scopes-editor",
  standalone: true,
  imports: [
    NgClass,
    ReactiveFormsModule,
    TranslateModule,
    FormStepsComponent,
    FormInputComponent,
    FormDropdownComponent,
    FormDropdownMultiComponent,
    ScopeKpiListComponent,
    FloatActionComponent,
  ],
  templateUrl: "./government-scopes-editor.component.html",
  styleUrl: "./government-scopes-editor.component.scss",
})
export class GovernmentScopesEditorComponent implements OnInit {
  private _formBuilder = inject(FormBuilder);
  private _toastrService = inject(ToastrService);
  private _translateService = inject(TranslateService);
  private _router = inject(Router);
  private _validatorApiService = inject(ValidatorApiService);
  private _govScoresScopesApiService = inject(GovScoresScopesApiService);
  private _systemLayoutService = inject(SystemLayoutService);

  private readonly _untilDestroy = AdaaHelper.untilDestroyed();
  private readonly _genericFloatButtons = genericFloatButtons();

  id = input<string>();
  pageMode = input.required<string>();

  private _pillarsCopy = signal<GovScoresPillar[]>([]);
  private _objectivesCopy = signal<ObjectiveModelType[]>([]);

  submitted: boolean = false;
  activeTab = signal<number>(0);
  scopeForm: FormGroup;
  PageMode = PageMode;
  formValidation = signal<ValidatorModelType | undefined>(undefined);
  layouts = signal<ValueText[]>([]);
  entities = signal<ValueText[]>([]);
  pillars = signal<ValueText[]>([]);
  objectives = signal<ValueText[]>([]);
  tabs: FormTab[] = [
    {
      title: "common.form.label.information",
    },
    {
      title: "breadcrumbs.objectives.objectives",
    },
    {
      title: "government_scores.scopes.main_kpis",
    },
  ];

  private get _isMainKpisValid(): boolean {
    const kpis = (this.scopeForm.get("govScoresScopeMainKpis")?.value as ScopeMainKpi[]) ?? [];
    return kpis.filter((e) => e.status !== Constants.OBJECT_STATUS.REMOVE).length > 0;
  }

  public get displayLabel(): string {
    const nameEN = this.scopeForm.get("nameEN")?.value;
    const nameAE = this.scopeForm.get("nameAE")?.value;

    return AdaaHelper.getPageLabel(nameAE, nameEN);
  }

  public ngOnInit(): void {
    this._systemLayoutService.hasActiveEntityChanged$.pipe(this._untilDestroy()).subscribe({
      next: () => {
        this._router.navigateByUrl("/console");
      },
    });

    this._prepareForm();

    this._initPage();
    this._floatActions();
  }

  public pillarChanged(value: number | undefined): void {
    if (!value) return;
    const pillar = this._pillarsCopy().find((e) => e.id === value);
    this.scopeForm.get("entityId")?.setValue(pillar?.entityId);
  }

  public objectivesChanged(value: number[]): void {
    if (!this._objectivesCopy() || this._objectivesCopy().length === 0) return;

    const objectives = this._objectivesCopy().filter((e) => value.includes(e.id));
    this.scopeForm.get("objectives")?.setValue(objectives);
  }

  public goBack(): void {
    this._router.navigateByUrl("/console/government-scores/scopes");
  }

  public submit(): void {
    this.submitted = true;
    this.tabs.forEach((e) => (e.hasError = false));

    if (!this.scopeForm.valid) {
      this._toastrService.warning(this._translateService.instant("notification.warning.missing_info"));
      this.tabs[0].hasError = true;
      return;
    }

    if (!this._isMainKpisValid) {
      this.tabs[2].hasError = true;
      this._toastrService.warning(this._translateService.instant("government_scores.scopes.main_kpis_required"));
      return;
    }

    this._govScoresScopesApiService.saveScope(this._prepareDataFormSubmit(this.scopeForm.getRawValue())).subscribe({
      next: (response) => {
        if (response.inError) return;

        this._toastrService.success(this._translateService.instant("notification.success.save"));
        this._router.navigateByUrl("/console/government-scores/scopes");
      },
    });
  }

  /**
   * This method will get
   * - Validations
   * - Layout Types
   * - Entities
   * - Pillars
   */
  private _initPage(): void {
    forkJoin({
      validators: this._validatorApiService.searchByKey(
        Constants.VALIDATORS_CONF_KEY.VALIDATION_GOVERNMENT_SCOPES_FORM
      ),
      layouts: this._govScoresScopesApiService.getAvailableLayouts(),
      entities: this._govScoresScopesApiService.getEntitiesLinkedToGovScoresPillar(),
      pillars: this._govScoresScopesApiService.getPillarsByEntityId(),
    }).subscribe({
      next: (results) => {
        if (!results.validators.inError) this.formValidation.set(results.validators.responseData);

        if (!results.layouts.inError)
          this.layouts.set(
            AdaaHelper.setDropdownArray(results.layouts.responseData, "id", AdaaHelper.getFieldLanguage("name"))
          );

        if (!results.entities.inError)
          this.entities.set(
            AdaaHelper.setDropdownArray(results.entities.responseData, "id", AdaaHelper.getFieldLanguage("name"))
          );

        if (!results.pillars.inError) {
          this._pillarsCopy.set(results.pillars.responseData);
          this.pillars.set(
            AdaaHelper.setDropdownArray(results.pillars.responseData, "id", AdaaHelper.getFieldLanguage("name"))
          );
        }
      },
      complete: () => {
        if (this.id()) this._getScope(+this.id()!);
      },
    });
  }

  private _getScope(id: number): void {
    this._govScoresScopesApiService.getScope(id).subscribe({
      next: (response) => {
        if (response.inError) {
          this._toastrService.error(this._translateService.instant("notification.error.unknown_object"));
          this.goBack();
        }

        response.responseData.govScoresScopeMainKpis.forEach((e) => (e.metricsCopy = AdaaHelper.clone(e.metrics)));
        this.scopeForm.patchValue(response.responseData);
        this.scopeForm.get("objectivesIds")?.setValue(response.responseData.objectives?.map((e) => e.id));
      },
    });
  }

  private _prepareForm(): void {
    const isViewMode = this.pageMode() === PageMode.view;
    this.scopeForm = this._formBuilder.group({
      id: { value: null, disabled: isViewMode },
      nameAE: [{ value: null, disabled: isViewMode }, Validators.required],
      nameEN: [{ value: null, disabled: isViewMode }, Validators.required],
      dscAE: { value: null, disabled: isViewMode },
      dscEN: { value: null, disabled: isViewMode },
      layoutId: [{ value: null, disabled: isViewMode }, Validators.required],
      entityId: [{ value: null, disabled: isViewMode }, Validators.required],
      pillarId: [{ value: null, disabled: isViewMode }, Validators.required],
      objectives: { value: null, disabled: isViewMode },
      govScoresScopeMainKpis: { value: null, disabled: isViewMode },
      displayOrder: { value: null, disabled: isViewMode },

      //ONLY FE
      objectivesIds: { value: null, disabled: isViewMode },
    });

    this.scopeForm
      .get("entityId")
      ?.valueChanges.pipe(
        // Emit the initial value and subsequent value changes as pairs
        startWith(this.scopeForm.get("entityId")?.value),
        pairwise()
      )
      .subscribe(([previousValue, currentValue]) => {
        // If the previous value exists and is different from the current value
        if (previousValue && previousValue !== currentValue) {
          // Reset related form controls when "entityId" changes
          this.scopeForm.get("pillarId")?.setValue(null);
          this.scopeForm.get("objectives")?.setValue(null);
          this.scopeForm.get("objectivesIds")?.setValue(null);
          this.scopeForm.get("govScoresScopeMainKpis")?.setValue(null);
        }

        this._setPillars(currentValue);
        this._setObjectives(currentValue);
      });
  }

  private _setPillars(entityId: number | undefined) {
    if (entityId) {
      //Set pillars ddl
      const pillars = this._pillarsCopy().filter((e) => e.entityId === entityId);
      this.pillars.set(AdaaHelper.setDropdownArray(pillars, "id", AdaaHelper.getFieldLanguage("name")));

      //Set pillars ddl value
      const pillar = this.pillars().find((e) => e.value === this.scopeForm.get("pillarId")?.value);
      if (!pillar) this.scopeForm.get("pillarId")?.setValue(null);
    } else {
      //Set pillars ddl
      this.pillars.set(AdaaHelper.setDropdownArray(this._pillarsCopy(), "id", AdaaHelper.getFieldLanguage("name")));

      //Set pillars ddl value
      this.scopeForm.get("pillarId")?.setValue(null);
    }
  }

  private _setObjectives(entityId: number | undefined) {
    if (!entityId) {
      this.objectives.set([]);
      return;
    }

    this._govScoresScopesApiService.getObjectiveByEntityId(entityId).subscribe({
      next: (response) => {
        if (response.inError) {
          this._objectivesCopy.set([]);
          this.objectives.set([]);
          return;
        }

        this._objectivesCopy.set(response.responseData);

        response.responseData.forEach((e) => {
          e.nameEN = `[${e.objectiveTypeNameEN}] ${e.nameEN}`;
          e.nameAE = `[${e.objectiveTypeNameAE}] ${e.nameAE}`;
        });

        this.objectives.set(
          AdaaHelper.setDropdownArray(response.responseData, "id", AdaaHelper.getFieldLanguage("name"))
        );
      },
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _prepareDataFormSubmit(data: any): any {
    if (!data.id) delete data.id;
    delete data.objectivesIds;
    if (!data.govScoresScopeMainKpis) data.govScoresScopeMainKpis = [];
    if (!data.objectives) data.objectives = [];
    return data;
  }

  private _floatActions(): void {
    const actions: CommonFloatActionButtonType[] = [
      {
        key: "helpdesk",
        data: {
          url: "/helpdesk/create",
        },
      },
      {
        key: "print",
        data: undefined,
      },
    ];

    if (this.pageMode() !== PageMode.create)
      actions.push({
        key: "systemLog",
        data: {
          itemId: +this.id()!,
          itemTypeId: [Constants.GOV_SCORES_SCOPE],
        },
      });

    if (this.pageMode() === PageMode.view)
      actions.push({
        key: "edit",
        data: {
          url: `/console/government-scores/scopes/edit/${this.id()}`,
        },
      });

    this._genericFloatButtons(actions);
  }
}
