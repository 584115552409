<adaa-data-table
    #adaaDataTable
    titleHeader="communication.notification_templates"
    apiName="getAll"
    apiPrefix="notificationconfig"
    [configKey]="configKey"
    [itemTypeId]="constants.CONSTANT_PERMISSIONS.NOTIFICATIONS"
    [enableDeleteAction]="false"
    [enableViewAction]="false"
    [enableEditAction]="false"
    [enableActionsCell]="adaaHelper.isPMOEntity()"
    [customActionButtons]="customButtons"
    [tableInputs]="tableInputs"
    (actionClicked)="actionClicked($event)"
    (inputListChanged)="inputListChanged($event)"
></adaa-data-table>
<div class="my-2">
    <adaa-form-action-buttons
        [cancelButtonTitle]="'common.form.button.cancel'"
        [saveButtonTitle]="'common.form.button.submit'"
        (cancelClicked)="cancel()"
        (saveClicked)="submit()"
    ></adaa-form-action-buttons>
</div>

<adaa-sms-template-modal #sms [placehoders]="placeholders()"> </adaa-sms-template-modal>
<adaa-editor-template-modal #editor [placehoders]="placeholders()"></adaa-editor-template-modal>
