<adaa-period-slider [activePeriod]="selectedPeriod()!" type="year" (periodSelect)="selectPeriod($event)"></adaa-period-slider>

<adaa-ribbon [showLogo]="true" [widgetData]="dashboardWidgets()" (refreshClicked)="queryData()">
    <ng-container ribbonLogo>
        <img class="ribbon-logo" src="assets/images/national-strategies/national-strategy.png" [alt]="'national_strategies.strategies' | translate" />
    </ng-container>
</adaa-ribbon>

<div class="strategy-name my-4 text-primary fw-bold">
    <h3 class="fw-bold d-flex align-items-center">
        <span class="obj-numbering">{{ objectiveNumber() }}</span>
        {{ objectiveDetails() | translateToken: "name" }}
        @if (objectiveDetails()?.isMain) {
            <span class="badge bg-primary text-white mx-2">{{ "directions.is_main" | translate }}</span>
        }
    </h3>
</div>

<div class="position-relative row bg-white mt-3 shadow">
    <div class="position-relative col-md-5 col-sm-12 d-flex justify-content-center flex-wrap">
        <h4 class="fw-bold mt-3 w-100 text-center">{{ dashboardData() | translateToken: "name" }}</h4>

        <img class="strategy-info--logo mt-3" [src]="dashboardImage()" [attr.alt]="dashboardData() | translateToken: 'entityName'" />
    </div>

    <div class="position-relative col-md-2 col-sm-12 d-flex justify-content-center flex-wrap">
        <adaa-doughnut
            size="xs"
            [amount]="objectiveDetails()?.visionScore!"
            [colorId]="objectiveDetails()?.visionScoreColor!"
            [description]="'national_projects.target' | translate"
        />
        <h6 class="fw-bold w-100 fs-6 text-center px-1">{{ "national_projects.target" | translate }}</h6>
    </div>

    <div class="position-relative col-md-5 col-sm-12 d-flex align-items-center justify-content-center flex-sm-wrap">
        <section class="w-50 d-block px-1 flex-shrink-0 mx-1">
            <span class="w-100 d-block position-relative fw-bold mx-2 text-dark" style="text-align: justify">
                {{ "national_strategies.project_progress" | translate }}
                <span>: &nbsp; {{ objectiveDetails()?.progressScore | percentageValue }}</span>
            </span>

            <div
                class="progress my-2 w-100 d-flex align-items-center position-relative mx-2"
                role="progressbar"
                aria-valuenow="50"
                aria-valuemin="0"
                aria-valuemax="100"
                style="height: 15px; border-radius: 3px"
                [attr.aria-label]="'national_strategies.project_progress' | translate"
            >
                <div
                    class="progress-bar"
                    style="height: 30px; border-radius: 3px"
                    [style.background-color]="getColor(objectiveDetails()?.progressScoreColor!)"
                    [style.width]="objectiveDetails()?.progressScore | percentageValue"
                ></div>
            </div>
        </section>

        <section class="w-50 d-block px-1 flex-shrink-0 mx-1">
            <span class="w-100 d-block position-relative fw-bold mx-2 text-dark" style="text-align: justify">
                {{ "national_strategies.annual_preformance" | translate }}
                <span>: &nbsp; {{ objectiveDetails()?.score | percentageValue }}</span>
            </span>

            <div
                class="progress my-2 w-100 d-flex align-items-center position-relative mx-2"
                role="progressbar"
                aria-valuenow="50"
                aria-valuemin="0"
                aria-valuemax="100"
                style="height: 15px; border-radius: 3px"
                [attr.aria-label]="'national_strategies.annual_preformance' | translate"
            >
                <div
                    class="progress-bar"
                    style="height: 15px; border-radius: 3px"
                    [style.background-color]="getColor(objectiveDetails()?.scoreColor!)"
                    [style.width]="objectiveDetails()?.score | percentageValue"
                ></div>
            </div>
        </section>
    </div>
</div>

<div class="d-block w-100 bg-white mt-3 rounded p-2">
    <div class="position-relative d-flex justify-content-center w-100 mb-3 mt-2">
        <ul
            class="nav nav-pills nav-fill gap-2 p-1 small bg-primary shadow rounded-5 w-25"
            id="pillNav2"
            role="tablist"
            style="
                --bs-nav-link-color: var(--bs-white);
                --bs-nav-pills-link-active-color: var(--bs-primary);
                --bs-nav-pills-link-active-bg: var(--bs-white);
                --bs-link-hover-color: var(--bs-primary-bg-subtle);
                --bs-nav-link-font-weight: 700;
                --bs-nav-link-font-size: 14px;
            "
        >
            <li class="nav-item" role="presentation" (click)="selectedTab.set(1)">
                <a
                    class="nav-link active rounded-5"
                    id="home-tab2"
                    data-bs-toggle="tab"
                    type="button"
                    role="tab"
                    aria-selected="true"
                    href="#targets"
                >
                    {{ "sdg.goal_kpis" | translate }}
                </a>
            </li>
            <li class="nav-item" role="presentation" (click)="selectedTab.set(2)">
                <a
                    class="nav-link rounded-5"
                    id="profile-tab2"
                    data-bs-toggle="tab"
                    type="button"
                    role="tab"
                    aria-selected="false"
                    href="#initiatives"
                >
                    {{ "sdg.goal_initiatives" | translate }}
                </a>
            </li>
        </ul>
    </div>

    <div class="tab-content clearfix mt-2">
        <div class="tab-pane" id="targets" [class.active]="selectedTab() === 1">
            <adaa-data-table
                apiName="getSubGovKpisByPeriod"
                [paramsString]="goalTargetParamString()"
                [configKey]="goalTargetConfKey"
                [enablePrint]="true"
                [enableAdd]="false"
                [enableEditAction]="false"
                [enableDeleteAction]="false"
                (actionClicked)="handleTableAction('kpis', $event.data)"
            ></adaa-data-table>
        </div>

        <div class="tab-pane" id="initiatives" [class.active]="selectedTab() === 2">
            <adaa-data-table
                apiName="getInitiativesCrossCycle"
                [paramsString]="goalProjectParamString()"
                [configKey]="goalProjectConfKey"
                [enablePrint]="true"
                [enableAdd]="false"
                [enableEditAction]="false"
                [enableDeleteAction]="false"
                (actionClicked)="handleTableAction('initiatives', $event.data)"
            ></adaa-data-table>
        </div>
    </div>

    <div class="d-flex w-100">
        @for (item of objectivesList(); track item.id) {
            <a [routerLink]="item.url" [ngbTooltip]="item.text" class="mx-2 px-4 py-2 bg-primary text-white link-underline link-underline-opacity-0">
                {{ item.number }}
            </a>
        }
    </div>
</div>
