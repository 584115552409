import { CommonModule } from "@angular/common";
import { Component, computed, inject, input, output, signal, TemplateRef, viewChild } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";

import { FormActionButtonsComponent, FormInputComponent } from "../../../shared/components";
import { Language } from "../../../shared/constants/enums";
import { NotificationConfigPlaceholder } from "../../../shared/models/notifications-config.model";
import { AppService } from "../../../shared/services";
import { NotificationsApiService } from "../../../shared/services/notifications-api.service";
import { PlaceholderListComponent } from "../placeholder-list/placeholder-list.component";

@Component({
  selector: "adaa-sms-template-modal",
  standalone: true,
  imports: [
    TranslateModule,
    CommonModule,
    FormInputComponent,
    FormActionButtonsComponent,
    ReactiveFormsModule,
    PlaceholderListComponent,
  ],
  templateUrl: "./sms-template-modal.component.html",
  styleUrl: "./sms-template-modal.component.scss",
})
export class SmsTemplateModalComponent {
  private _appService = inject(AppService);
  private _modalService = inject(NgbModal);
  private _formBuilder = inject(FormBuilder);
  private _toastrService = inject(ToastrService);
  private _translateService = inject(TranslateService);
  private _notificationsApiService = inject(NotificationsApiService);

  modal = viewChild.required<TemplateRef<unknown>>("content");
  placehoders = input.required<NotificationConfigPlaceholder[]>();
  editorClosed = output<unknown>();

  smsForm: FormGroup = new FormGroup({});
  placehodersList = computed(() => this.placehoders());
  displayValueLabel = signal<string>("");
  private _lastFocusedTextElement: HTMLInputElement;
  submitted = signal<boolean>(false);
  _subscription = new Subscription();

  public ngOnInit() {
    this._prepareForm();
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  private _prepareForm() {
    this.smsForm = this._formBuilder.group({
      contentAE: this._formBuilder.control<string | undefined>(""),
      contentEN: this._formBuilder.control<string | undefined>(""),
      status: this._formBuilder.control<string | undefined>(""),
      id: this._formBuilder.control<string | undefined>(""),
      subjectAE: this._formBuilder.control<string | undefined>(""),
      subjectEN: this._formBuilder.control<string | undefined>(""),
      type: this._formBuilder.control<string | undefined>(""),
    });
  }
  public open() {
    this._modalService.open(this.modal(), {
      animation: true,
      scrollable: false,
      keyboard: false,
      size: "xl",
      centered: true,
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });

    this.submitted.set(false);
  }

  public submit() {
    this.submitted.set(true);
    if (this.smsForm.invalid) {
      this._toastrService.error(this._translateService.instant("common.form.label.missing_information"));
      return;
    }
    const result = {
      ...this.smsForm.value,
      updateTs: Date.now(),
    };

    this.editorClosed.emit(result);
    this._notificationsApiService.updateNotificationsTemplate(result).subscribe({
      next: () => {
        this._toastrService.success(this._translateService.instant("notification.success.save"));
        this._modalService.dismissAll();
      },
    });
  }

  public placeholderSelected(placeholder: NotificationConfigPlaceholder) {
    // ignore if no textarea was focused yet
    if (!this._lastFocusedTextElement) {
      return;
    }

    const controlName = this._lastFocusedTextElement.getAttribute("id");
    const value = this._lastFocusedTextElement.value;
    if (this._lastFocusedTextElement.selectionStart) {
      this._lastFocusedTextElement.value =
        value.substring(0, this._lastFocusedTextElement.selectionStart) +
        placeholder.placeholder +
        value.substring(this._lastFocusedTextElement.selectionStart, value.length);
      if (controlName) this.smsForm.controls[controlName]?.setValue(this._lastFocusedTextElement.value);
    }
  }

  public onTextareaFocus(event: MouseEvent) {
    this._lastFocusedTextElement = event.target as HTMLInputElement;
  }
}
