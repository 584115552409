<div class="d-flex gap-2">
    <p class="fw-bold fs-3">{{ "breadcrumbs.entities" | translate }} -</p>
    <p class="fw-bold fs-3">{{ displayLabel() }}</p>
</div>

<div class="bg-white shadow-md rounded p-2 mb-3">
    <form class="col-12" [formGroup]="entitiesForm">
        <div class="bg-white shadow-md rounded p-2 mb-3">
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.nameAE' | translate"
                        formControlName="nameAE"
                        controlName="nameAE"
                        [setValidator]="entitiesEditorService.formValidation()!"
                        [invalid]="checkInvalid('nameAE')"
                    ></adaa-form-input>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.nameEN' | translate"
                        formControlName="nameEN"
                        controlName="nameEN"
                        [setValidator]="entitiesEditorService.formValidation()!"
                        [invalid]="checkInvalid('nameEN')"
                    ></adaa-form-input>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.descriptionAE' | translate"
                        formControlName="dscAE"
                        controlName="dscAE"
                        [setValidator]="entitiesEditorService.formValidation()!"
                        [invalid]="checkInvalid('dscAE')"
                    ></adaa-form-input>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.descriptionEN' | translate"
                        formControlName="dscEN"
                        controlName="dscEN"
                        [setValidator]="entitiesEditorService.formValidation()!"
                        [invalid]="checkInvalid('dscEN')"
                    ></adaa-form-input>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        formControlName="shortNameAE"
                        controlName="shortNameAE"
                        [maxLength]="5"
                        [label]="'entity.create.shortName' | translate"
                        [setValidator]="entitiesEditorService.formValidation()!"
                        [invalid]="checkInvalid('shortNameAE')"
                    ></adaa-form-input>
                </div>
                <div class="col-md-3 col-sm-12 col-xs-12">
                    <adaa-form-dropdown
                        formControlName="entityType"
                        controlName="entityType"
                        [label]="'entity.create.entity_type' | translate"
                        [setValidator]="entitiesEditorService.formValidation()!"
                        [invalid]="checkInvalid('entityType')"
                        [options]="entityTypeOptions()"
                    ></adaa-form-dropdown>
                </div>
                <div class="col-md-3 col-sm-12 col-xs-12">
                    <adaa-form-dropdown
                        formControlName="entitySize"
                        controlName="entitySize"
                        [label]="'entity.create.entity_size' | translate"
                        [setValidator]="entitiesEditorService.formValidation()!"
                        [invalid]="checkInvalid('entitySize')"
                        [options]="entitySizeOptions()"
                    ></adaa-form-dropdown>
                </div>
            </div>
            @if (pageMode() !== PageMode.create) {
                <div class="row">
                    <div class="col-12">
                        <adaa-form-dropdown-multi
                            formControlName="ministers"
                            controlName="ministers"
                            [label]="'entity.minister' | translate"
                            [setValidator]="entitiesEditorService.formValidation()!"
                            [invalid]="checkInvalid('ministers')"
                            [options]="usersOptions()"
                            [clearable]="true"
                            [searchable]="true"
                        ></adaa-form-dropdown-multi>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <adaa-form-dropdown-multi
                            formControlName="directorGenerals"
                            controlName="directorGenerals"
                            [label]="'entity.director_generals' | translate"
                            [setValidator]="entitiesEditorService.formValidation()!"
                            [invalid]="checkInvalid('directorGenerals')"
                            [options]="usersOptions()"
                            [clearable]="true"
                            [searchable]="true"
                        ></adaa-form-dropdown-multi>
                    </div>
                </div>
            }
            <div class="row my-4">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <p class="guidelines text-center fw-bold">{{ "entity.create.logo_horizontal" | translate }}</p>
                    <adaa-form-input-file
                        [onDeleteRemoveFile]="false"
                        [isDisabled]="pageMode() === PageMode.view"
                        [onDeleteRemoveFile]="false"
                        [allowedFileSize]="100024"
                        [allowedFiles]="allowedFiles"
                        [allowedFilesMessage]="getFileMessage('horizontal')"
                        [file]="getImage('horizontal')"
                        (inputChanges)="onFilechange($event, 'horizontal')"
                    ></adaa-form-input-file>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <p class="guidelines text-center fw-bold">{{ "entity.create.logo_vertical" | translate }}</p>
                    <adaa-form-input-file
                        [onDeleteRemoveFile]="false"
                        [isDisabled]="pageMode() === PageMode.view"
                        [allowedFileSize]="100024"
                        [allowedFiles]="allowedFiles"
                        [allowedFilesMessage]="getFileMessage('vertical')"
                        [file]="getImage('vertical')"
                        (inputChanges)="onFilechange($event, 'vertical')"
                    ></adaa-form-input-file>
                </div>
            </div>
            <div class="row">
                <div class="col-12 d-flex justify-content-center">
                    <adaa-form-checkbox
                        [label]="'entity.create.enabler' | translate"
                        [checkboxMode]="'yesNo'"
                        controlName="enabler"
                        formControlName="enabler"
                    ></adaa-form-checkbox>
                </div>
            </div>
            @if (pageMode() === PageMode.edit) {
                <div class="row mt-3">
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <adaa-form-dropdown
                            formControlName="strategyManagerId"
                            controlName="strategyManagerId"
                            [label]="'entity.strategic_manager' | translate"
                            [setValidator]="entitiesEditorService.formValidation()!"
                            [options]="usersOptions()"
                        ></adaa-form-dropdown>
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <adaa-form-dropdown
                            formControlName="nkpiFocalPointId"
                            controlName="nkpiFocalPointId"
                            [label]="'entity.nkpi_focal_point' | translate"
                            [setValidator]="entitiesEditorService.formValidation()!"
                            [options]="usersOptions()"
                        ></adaa-form-dropdown>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <adaa-form-dropdown
                            formControlName="ekpiFocalPointId"
                            controlName="ekpiFocalPointId"
                            [label]="'entity.ekpi_focal_point' | translate"
                            [setValidator]="entitiesEditorService.formValidation()!"
                            [options]="usersOptions()"
                        ></adaa-form-dropdown>
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <adaa-form-dropdown
                            formControlName="servicesFocalPointId"
                            controlName="servicesFocalPointId"
                            [label]="'entity.services_focal_point' | translate"
                            [setValidator]="entitiesEditorService.formValidation()!"
                            [options]="usersOptions()"
                        ></adaa-form-dropdown>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <adaa-form-dropdown
                            formControlName="dkpiFocalPointId"
                            controlName="dkpiFocalPointId"
                            [label]="'entity.dkpi_focal_point' | translate"
                            [setValidator]="entitiesEditorService.formValidation()!"
                            [options]="usersOptions()"
                        ></adaa-form-dropdown>
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <adaa-form-dropdown
                            formControlName="skpiopmFocalPointId"
                            controlName="skpiopmFocalPointId"
                            [label]="'entity.skpiopm_focal_point' | translate"
                            [setValidator]="entitiesEditorService.formValidation()!"
                            [options]="usersOptions()"
                        ></adaa-form-dropdown>
                    </div>
                </div>
            }
        </div>
    </form>
</div>

<div class="bg-white shadow-md rounded p-2 mb-3">
    <div class="p-2">
        <adaa-data-table
            #adaaDataTable
            [isLocalData]="true"
            [localData]="filteredOffices()"
            [titleHeader]="'entity.create.entity_offices' | translate"
            [configKey]="Constants.MAIN_TABLE_LIST_CONF_KEY.ENTITY_OFFICES"
            [itemTypeId]="Constants.CONSTANT_PERMISSIONS.ENTITY_ADDRESSES"
            [enableDeleteAction]="pageMode() !== PageMode.view"
            [enableEditAction]="pageMode() !== PageMode.view"
            [enableAdd]="pageMode() !== PageMode.view"
            (addNewClicked)="addNew()"
            (actionClicked)="getAction($event)"
        ></adaa-data-table>
    </div>
</div>

<div class="my-2">
    <adaa-form-action-buttons
        [showSaveButton]="pageMode() !== PageMode.view"
        [saveButtonTitle]="'common.form.button.submit' | translate"
        [cancelButtonTitle]="pageMode() === PageMode.view ? ('common.form.button.close' | translate) : ('common.form.button.cancel' | translate)"
        (saveClicked)="submit()"
        (cancelClicked)="close()"
    ></adaa-form-action-buttons>
</div>

<adaa-addresses-editor
    #editor
    [pageMode]="addressesPageMode()"
    [currentEntityOffice]="entityOffice()"
    (editorClosed)="saveAddress($event)"
></adaa-addresses-editor>
