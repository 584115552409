import { NgStyle } from "@angular/common";
import {
  Component,
  computed,
  effect,
  EventEmitter,
  inject,
  Injector,
  Input,
  input,
  OnInit,
  Output,
  signal,
  viewChild,
} from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { filter, map } from "rxjs";

import { AdaaBooleanType } from "../../../../../../adaa-types";
import { AdaaHelper } from "../../../../../core/utils";
import { FormCheckboxComponent, FormDropdownComponent, FormInputComponent } from "../../../../../shared/components";
import { Constants } from "../../../../../shared/constants/constants";
import { AdaaBoolean, PageMode } from "../../../../../shared/constants/enums";
import { FormControlDisabledDirective } from "../../../../../shared/directives";
import {
  KpiTypeConstantType,
  ObjectStatus,
  ParameterCatalog,
  PropTypeModelType,
  TextMapModelType,
  ValueText,
} from "../../../../../shared/models";
import { Formula } from "../../../../../shared/models/formulas.model";
import { FormulasApiService, LanguageService, PropertiesService } from "../../../../../shared/services";
import { formByKpiType, FormGroupTab } from "../../utils";
import { getRequiredValidator } from "../../utils/form-groups/lib";
import { FormulaSelectorModalComponent } from "../formula-selector-modal/formula-selector-modal.component";
import { KpiFormulaComponent } from "../kpi-formula/kpi-formula.component";
import { KpiFormulaSelectorComponent } from "../kpi-formula-selector/kpi-formula-selector.component";
import { KpiTrendInputComponent } from "../kpi-trend-input/kpi-trend-input.component";

@Component({
  selector: "adaa-measurement-details",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormDropdownComponent,
    FormInputComponent,
    FormCheckboxComponent,
    TranslateModule,
    FormControlDisabledDirective,
    KpiTrendInputComponent,
    KpiFormulaSelectorComponent,
    NgStyle,
    KpiFormulaComponent,
  ],
  styleUrl: "../styles.scss",
  templateUrl: "./measurement-details.component.html",
})
export class MeasurementDetailsComponent implements OnInit {
  @Input({ alias: "validations" }) public set _validations(catalog: ParameterCatalog[]) {
    this.validations.set(catalog ?? []);
  }

  readonly languageService = inject(LanguageService);
  private readonly _injector = inject(Injector);
  private readonly _modalService = inject(NgbModal);
  private readonly _propertiesService = inject(PropertiesService);
  private readonly _formulasApiService = inject(FormulasApiService);

  kpiFormula = viewChild.required<KpiFormulaComponent>("kpiFormula");

  kpi = input<Record<string, unknown>>();
  stagedKpi = input<Record<string, unknown>>();
  pageMode = input.required<PageMode>();
  kpiType = input.required<number>();
  textMaps = input<TextMapModelType[]>([]);
  startDate = input<number>();
  endDate = input<number>();

  formula = signal<Formula | undefined>(undefined);
  props = signal<PropTypeModelType[]>([]);
  validations = signal<ParameterCatalog[]>([]);
  measurementUnitOptions = signal<ValueText[]>([]);
  trend = signal<(PropTypeModelType & { inputDisabled?: boolean })[]>([]);
  ytpCalcOptions = signal<ValueText[]>([]);

  isDTKPI = computed(() => this.kpiType() === Constants.KPI_TYPE.DTKPI);
  isNTKPI = computed(() => this.kpiType() === Constants.KPI_TYPE.NTKPI);
  isEKPI = computed(() => this.kpiType() === Constants.KPI_TYPE.EKPI);
  isSKPI = computed(() => this.kpiType() === Constants.KPI_TYPE.SKPI);
  isOPM = computed(() => this.kpiType() === Constants.KPI_TYPE.OPM);
  isMOKPI = computed(() => this.kpiType() === Constants.KPI_TYPE.MOKPI);
  isMTKPI = computed(() => this.kpiType() === Constants.KPI_TYPE.MTKPI);
  isGSKPI = computed(() => this.kpiType() === Constants.KPI_TYPE.GSKPI);
  //If the KPI is linked to scope disabled the formula and formula selection
  isLinkedToScope = computed(() => this.kpi()?.isLinkedToScope === true);
  checkEkpiReadOnlyField = computed(() => {
    if (!this.isEKPI()) return false;
    if (this.pageMode() === PageMode.create) return false;
    const kpi = this.stagedKpi() as unknown as { targetsInputEntityId: number };
    if (kpi && AdaaHelper.isDefined(kpi.targetsInputEntityId)) return true;
    return !AdaaHelper.isPMOEntity();
  });
  isDerivedSKPI = computed(() => {
    if (!this.isSKPI()) return false;
    const kpi = this.stagedKpi() as unknown as { linkedKpiId: number };
    if (!kpi) return false;
    return AdaaHelper.isDefined(kpi.linkedKpiId);
  });
  checkNTkpiReadOnlyField = computed(() => {
    if (!this.isNTKPI()) return false;
    return !AdaaHelper.isPMOEntity();
  });
  checkDTkpiReadOnlyField = computed(() => {
    if (!this.isDTKPI()) return false;
    return !AdaaHelper.isPMOEntity();
  });
  frequencyOptions = computed(() => {
    const list = this.props().filter(({ propType }) => propType === Constants.CONSTANT_FREQUENCY);

    return list.map(
      (prop) =>
        ({
          value: prop.id,
          text: AdaaHelper.getItemValueByToken(prop, "name"),
        }) as ValueText
    );
  });
  formulaOptions = computed(() => {
    const list = this.props().filter(({ propType }) => propType === Constants.CONSTANT_FORMULA_LLK);

    const options = list.map(
      (prop) =>
        ({
          value: prop.id,
          text: AdaaHelper.getItemValueByToken(prop, "name"),
        }) as ValueText
    );

    const kpi = this.stagedKpi() as { hasTarget: AdaaBooleanType };
    if (kpi && kpi.hasTarget === AdaaBoolean.Y) return options;

    return options.filter(({ value }) => value !== Constants.CONSTANT_FORMULAWTARGETS);
  });
  textmappingOptions = computed(() => AdaaHelper.setDropdownArray(this.textMaps(), "id", "name"));
  isBaselineKpi = computed(() => this.model?.get("baselineKpi")?.value === AdaaBoolean.Y);
  disableBaselineKPI = computed(() => {
    if (this.isDTKPI() || this.isMOKPI() || this.isMTKPI()) {
      return true;
    }

    if (this.pageMode() === PageMode.edit) {
      const kpi = this.stagedKpi() as {
        status: ObjectStatus;
        isSubKpi: boolean;
        linkedKpiId: number;
        aggChildren: AdaaBooleanType;
      };

      if (kpi.status === ObjectStatus.ACTIVE) return true;
      if (kpi.isSubKpi) return true;
      if (AdaaHelper.isDefined(kpi.linkedKpiId)) return true;

      return kpi.aggChildren === AdaaBoolean.Y && kpi.status === ObjectStatus.DRAFT;
    }

    return false;
  });
  disableTrendInput = computed(
    () =>
      this.checkEkpiReadOnlyField() ||
      this.isDerivedSKPI() ||
      this.checkNTkpiReadOnlyField() ||
      this.checkDTkpiReadOnlyField()
  );

  readonly isDefined = AdaaHelper.isDefined.bind(AdaaHelper);
  readonly yearRangeCalc = AdaaHelper.yearRangeCalc.call(AdaaHelper, 10, 11);
  readonly isFieldRequired = AdaaHelper.isFieldRequired.bind(AdaaHelper);
  readonly isNoFormulaKpi = () => this.model?.get("defaultFormulaOption")?.value === Constants.CONSTANT_NOFORMULA;
  readonly isFormulaKpi = () => this.model?.get("_formula")?.value === Constants.CONSTANT_FORMULA;
  readonly isKpiWithDetailedTargets = () => this.model?.get("hasTarget")?.value === AdaaBoolean.Y;
  readonly hasAnnualFrequency = () => this.model?.get("frequency")?.value > Constants.FREQUENCY_SEMIANNUAL;

  readonly #CONSTANT_AVERAGE = 25;
  readonly #CONSTANT_SUM = 28;
  readonly #untilDestroy = AdaaHelper.untilDestroyed();

  model: FormGroup;

  @Output() onMeasureDetailsTabFormValueChange = new EventEmitter();

  public get isMaxRank() {
    const unit = this.model?.get("measurementUnit")?.value;
    return unit === Constants.MEASUREMENT.CONSTANT_MEASUREMENT_RANK;
  }

  public get isTextMapping() {
    const unit = this.model?.get("measurementUnit")?.value;
    return unit === Constants.MEASUREMENT.CONSTANT_MEASUREMENT_TEXT_MAPPING;
  }

  public ngOnInit() {
    this._prepareForm(this.validations());
    this._captureModelValueChanges();
    this._captureTrendChanges();
    this._captureMeasurementUnitChanges();
    this._fetchProps();
    this._handleBaselineKpiState();
  }

  public updateBaselineYear(ts: number) {
    const { years: year } = AdaaHelper.getDubaiTimeAsObject(ts);

    if (this.pageMode() === PageMode.create) {
      this.model?.get("baselineYear")?.setValue(year);
    }
    if (this.pageMode() === PageMode.edit) {
      this.model?.get("baselineYear")?.setValue(this.stagedKpi()?.baselineYear);
    }

    this.model.get("baselineYear")?.markAsPristine();
    this.model.get("baselineYear")?.markAsTouched();
  }

  public onTrendUpdate(trend: number) {
    this.model?.get("trend")?.setValue(trend);
  }

  public handleValueType(id: number) {
    //@note: Helps in determine when the modal can be shown
    let showModal = this.model.get("_formula")?.touched;
    if (this.pageMode() === PageMode.create) showModal = true;
    if (!showModal && this.pageMode() === PageMode.edit && !AdaaHelper.isDefined(this.stagedKpi()?.formula))
      showModal = true;
    if (this.model.get("_formula")?.untouched && this.pageMode() === PageMode.edit) {
      this.model.get("_formula")?.markAsTouched({
        onlySelf: true,
        emitEvent: false,
      });
    }

    this.model?.get("defaultFormulaOption")?.setValue(id);

    if (!this.isMaxRank && !this.isTextMapping) {
      this.model?.get("formulaStatus")?.setValue(null);
    }

    if (!showModal) {
      this.model?.get("formulaStatus")?.setValue(this.kpi()?.formulaStatus);
    }

    if (id === Constants.CONSTANT_NOFORMULA) {
      this.model?.get("formula")?.setValue(Constants.FORMULA_STRING.NOFORMULA);
      this.model?.get("formulaStatus")?.setValue(AdaaBoolean.Y);

      // NOTE: switching from formula to no formula
      if (this.pageMode() === PageMode.edit && this.kpi()?.formula !== Constants.FORMULA_STRING.NOFORMULA) {
        this.model?.get("formulaWasChanged")?.setValue(true);
        this.model?.get("clearMetrics")?.setValue(true);
      }
    }

    if (id === Constants.CONSTANT_FORMULAWTARGETS) {
      this.model?.get("hasTarget")?.setValue(AdaaBoolean.Y);
    } else {
      this.model?.get("hasTarget")?.setValue(AdaaBoolean.N);
    }

    if (id === Constants.CONSTANT_FORMULA && showModal) {
      this._formulasApiService
        .getAll()
        .pipe(
          filter((res) => !res.inError),
          map((res) => res.responseData)
        )
        .subscribe({
          next: (data) => {
            this.model?.get("formulaStatus")?.setValue(AdaaBoolean.N);
            this._openFormulaSelectionTab(data);
          },
        });
    }
  }

  private _prepareForm(validations: ParameterCatalog[]) {
    const kpiType = Object.entries(Constants.KPI_TYPE).find(([_, val]) => val === this.kpiType());
    if (!kpiType) {
      throw new Error(`Unknown KPI Type ${this.kpiType()}`);
    }
    const [type] = kpiType as [Lowercase<KpiTypeConstantType>, number];

    const forms = formByKpiType[type.toLowerCase()];
    if (!forms) {
      throw new Error(`Form type ${type} not supported!`);
    }

    this.model = (forms as FormGroupTab).measurementDetails(validations);

    if (this.pageMode() === PageMode.edit) {
      const kpi = this.stagedKpi();
      if (!kpi) return;

      const controlKeys = Object.keys(kpi ?? {});

      for (const k of controlKeys) {
        if (!this.model.contains(k)) continue;
        if (!kpi[k]) continue;
        this.model.get(k)?.setValue(kpi[k]);
      }
    }

    if (!AdaaHelper.isDefined(this.model.get("baselineYear")?.getRawValue())) {
      const { years: year } = AdaaHelper.getDubaiTimeAsObject(this.startDate() ? this.startDate()! : Date.now());
      this.model.get("baselineYear")?.setValue(year);
    }

    if (this.isLinkedToScope()) this.model.get("_formula")?.disable();
  }

  private _fetchProps() {
    this._propertiesService
      .getPropByIdList([
        Constants.CONSTANT_MEASUREMENT_UNIT,
        Constants.CONSTANT_FREQUENCY,
        Constants.CONSTANT_FORMULA_LLK,
        Constants.CONSTANT_TREND,
        Constants.CONSTANT_YTP,
      ])
      .pipe(
        filter((res) => !res.inError),
        map((res) => (res.responseData || []).filter(({ enabled }) => enabled === AdaaBoolean.Y))
      )
      .subscribe({
        next: (data) => this.props.set(data ?? []),
        complete: () => {
          this._setMeasurementUnits();
          this._setTrend();
          this._setYtpCalc();

          if (this.pageMode() === PageMode.edit) {
            this._hydrateFormula();
          }
        },
      });
  }

  private _hydrateFormula() {
    const kpi = this.stagedKpi() as { hasTarget: AdaaBooleanType; formula: string };

    if (!AdaaHelper.isDefined(kpi.formula)) return;

    if (kpi && kpi.hasTarget === AdaaBoolean.Y) {
      this.model?.get("_formula")?.setValue(Constants.CONSTANT_FORMULAWTARGETS);
    } else if (kpi && kpi.formula !== Constants.FORMULA_STRING.NOFORMULA) {
      this.model?.get("_formula")?.setValue(Constants.CONSTANT_FORMULA);
    } else {
      this.model?.get("_formula")?.setValue(Constants.CONSTANT_NOFORMULA);
    }
  }

  private _captureTrendChanges() {
    this.model
      ?.get("trend")
      ?.valueChanges.pipe(this.#untilDestroy())
      .subscribe({
        next: () => {
          this._setTrend();
          this._setMeasurementUnits();
          this._setYtpCalc();
        },
      });
  }

  private _captureMeasurementUnitChanges() {
    this.model
      ?.get("measurementUnit")
      ?.valueChanges.pipe(this.#untilDestroy())
      .subscribe({
        next: () => {
          this._setTrend();
          this._setMeasurementUnits();
          this._setYtpCalc();
          this._setMaxRankField();
          this._setTextMap();
        },
      });
  }

  private _setMaxRankField() {
    if (this.isMaxRank) {
      this.model.addControl("maxRank", new FormControl<number | null>(null, [Validators.required]));
    } else {
      this.model.get("maxRank")?.setValue(null);
      this.model.removeControl("maxRank", { emitEvent: true });
    }
  }

  private _setTextMap() {
    if (this.isTextMapping) {
      this.model.addControl(
        "textmapId",
        new FormControl<number | null>(null, [...getRequiredValidator(this.validations(), "textmapId")])
      );
    } else {
      this.model.get("textmapId")?.setValue(null);
      this.model.removeControl("textmapId", { emitEvent: true });
    }
  }

  private _setTrend() {
    const list = this.props().filter(({ propType }) => propType === Constants.CONSTANT_TREND);
    const orderedList: PropTypeModelType[] = [...list.filter((_, index) => index !== 0), list[0]];

    this.trend.set(
      orderedList.map((item) => {
        const data = { ...item, inputDisabled: false };

        if (this.isMaxRank && item) {
          if (item?.id === Constants.TREND.ONTARGET || item.id === Constants.TREND.BOUNDED) data.inputDisabled = true;
        } else {
          data.inputDisabled = false;
        }

        //NOTE: if the EKPI is readonly should disable the trend input
        data.inputDisabled = this.disableTrendInput();

        return data;
      })
    );
  }

  private _setMeasurementUnits() {
    const list = this.props().filter(({ propType }) => propType === Constants.CONSTANT_MEASUREMENT_UNIT);
    const trend = this.model?.get("trend")?.value;

    const hasExceptions =
      trend && (trend === Constants.CONSTANT_TREND_BOUNDED || trend === Constants.CONSTANT_TREND_ONTARGET);

    const items = list
      .filter((prop) => !(hasExceptions && prop.id === Constants.MEASUREMENT.CONSTANT_MEASUREMENT_RANK))
      .map(
        (prop) =>
          ({
            value: prop.id,
            text: AdaaHelper.getItemValueByToken(prop, "name"),
          }) as ValueText
      );

    this.measurementUnitOptions.set(items);
  }

  private _setYtpCalc() {
    const list = this.props().filter(({ propType }) => propType === Constants.CONSTANT_YTP);

    if (this.isMaxRank || this.isTextMapping) {
      this.ytpCalcOptions.set(
        list
          .filter((prop) => prop.id !== this.#CONSTANT_SUM)
          .map(
            (prop) =>
              ({
                value: prop.id,
                text: AdaaHelper.getItemValueByToken(prop, "name"),
              }) as ValueText
          )
      );
    }

    this.ytpCalcOptions.set(
      list.map(
        (prop) =>
          ({
            value: prop.id,
            text: AdaaHelper.getItemValueByToken(prop, "name"),
          }) as ValueText
      )
    );

    if (this.pageMode() === PageMode.create) {
      this.model?.get("ytpCalc")?.setValue(this.#CONSTANT_AVERAGE);
    }
  }

  private _openFormulaSelectionTab(data: Formula[] = []) {
    const modal = this._modalService.open(FormulaSelectorModalComponent, {
      centered: true,
      size: "lg",
      modalDialogClass: `modal-${this.languageService.direction()}`,
    });

    modal.componentInstance.tableData.set(data);
    modal.componentInstance.selectedRow.set(this.formula() ? { id: this.formula()?.id } : undefined);

    //@note: Set the formula AS `${formula}`
    modal.result.then((f?: Formula) => {
      if (f) {
        this.formula.set(f);
        this.model?.get("formula")?.setValue(f.formula);
        this.kpiFormula().validateFormula(f.formula);

        if (
          this.pageMode() === PageMode.edit &&
          this.kpi()?.formula === f.formula &&
          this.kpi()?.status === ObjectStatus.ACTIVE
        ) {
          this.model?.get("formulaWasChanged")?.setValue(true);
        }
      } else {
        // @note Reset all formula related data from the model
        this.model?.get("defaultFormulaOption")?.reset(null);
        this.model?.get("formula")?.reset(null);
        this.model?.get("formulaStatus")?.setValue(AdaaBoolean.N);

        // @note Mark them as having errors
        this.model?.get("defaultFormulaOption")?.setErrors({
          required: true,
        });
        this.model?.get("formula")?.setErrors({
          required: true,
        });
      }
    });
  }

  private _captureModelValueChanges() {
    this.model?.valueChanges.pipe(this.#untilDestroy()).subscribe({
      next: () => {
        const data = this.model.getRawValue();
        delete data._formula;
        this.onMeasureDetailsTabFormValueChange.emit(data);
      },
    });
  }

  private _handleBaselineKpiState() {
    effect(
      () => {
        const disableBaselineKpi = this.disableBaselineKPI();

        if (disableBaselineKpi) {
          this.model.get("baselineKpi")?.disable();
        } else {
          this.model.get("baselineKpi")?.enable();
        }
      },
      { injector: this._injector }
    );
  }
}
