<div class="p-2">
    <adaa-data-table
        #adaaDataTable
        [apiName]="'getAllV2'"
        [apiPrefix]="'libextattr'"
        [titleHeader]="breadcrumb() | translate"
        [configKey]="Constants.MAIN_TABLE_LIST_CONF_KEY.ATTRIBUTES"
        [itemTypeId]="Constants.CONSTANT_PERMISSIONS.EXTENDEND_ATTRIBUTES"
        (addNewClicked)="addNew()"
        (actionClicked)="getAction($event)"
    ></adaa-data-table>
</div>
