<div class="p-2">
    <adaa-data-table
        #adaaDataTable
        apiName="getEntityReportsList"
        [titleHeader]="'documents.title' | translate"
        [paramsString]="constants.ITEM_TYPES_DOCS.ADAA2.toString()"
        [configKey]="constants.MAIN_TABLE_LIST_CONF_KEY.DOCSADDA_MANAGE_LIST_CONFIG"
        [itemTypeId]="constants.CONSTANT_PERMISSIONS.DOC_LIBRARY"
        [enableAdd]="false"
        [enableEditAction]="true"
        [enableDeleteAction]="true"
        [enableViewAction]="false"
        [enablePrint]="false"
        [enableAdd]="true"
        [customActionButtons]="customButtons"
        (addNewClicked)="addNew()"
        (actionClicked)="actionClicked($event)"
    ></adaa-data-table>

    <adaa-entity-reports-editor
        #editor
        [pageMode]="pageMode()"
        [currentEntityReport]="currentEntityReport()"
        (editorClosed)="submit($event)"
    ></adaa-entity-reports-editor>
</div>

<adaa-float-action />
