import { inject, Injectable } from "@angular/core";

import { MainResponseModel } from "../models";
import { LookupContent } from "../models/lookup-content.models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class LookupContentApiService {
  private _mainService = inject(MainService);

  public getLookupById(lookupId: number) {
    const url = `prop/getPropTypeById/${lookupId}`;

    return this._mainService.sendRequest<MainResponseModel<LookupContent>>({
      method: "GET",
      url,
    });
  }

  public createLookupContent(lookupContent: Partial<LookupContent>) {
    const url = `prop/createProp`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "POST",
      url,
      data: lookupContent,
    });
  }

  public updateLookupContent(data: Partial<LookupContent>) {
    const url = `prop/updateProp`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "POST",
      url,
      data,
    });
  }

  public updateState(data: Partial<LookupContent>) {
    const url = `prop/updateState`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "POST",
      url,
      data,
    });
  }

  public deleteLookupContent(lookupId: number) {
    const url = `prop/deleteById/${lookupId}`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "DELETE",
      url,
    });
  }
}
