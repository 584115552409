import { inject } from "@angular/core";
import { type Route, Router } from "@angular/router";

import { checkIfLoggedInFn, NotArchivedPlan } from "../../core/guards";
import { useNewDS } from "../../core/utils";
import { Constants } from "../../shared/constants/constants";
import { PageMode } from "../../shared/constants/enums";
import { activitiesGuards, strategicActivitiesGuards } from "./_guards";

const strategicActivityRoutes: Route = {
  path: "strategic",
  canActivate: [checkIfLoggedInFn],
  children: [
    {
      path: "",
      canActivate: [
        () => {
          const router = inject(Router);
          if (useNewDS()) return true;
          else return router.createUrlTree(["/console", "activities", "list"]);
        },
        ...strategicActivitiesGuards.view,
      ],
      data: {
        title: "breadcrumbs.activity.strategic_list",
        breadcrumb: "breadcrumbs.activity.strategic_list",
        activityType: Constants.CONSTANT_STRATEGIC_ACTIVITYTYPE,
        tableConfList: Constants.MAIN_TABLE_LIST_CONF_KEY.NEW_DS_ACTIVITIES,
        itemTypeId: Constants.CONSTANT_PERMISSIONS.STRATEGIC_ACTIVITIES,
      },
      loadComponent: () => import("./activities-list/activities-list.component").then((m) => m.ActivitiesListComponent),
    },
    {
      path: "create",
      canActivate: [...strategicActivitiesGuards.create, NotArchivedPlan()],
      data: {
        title: "breadcrumbs.activity.strategic_new",
        breadcrumb: "breadcrumbs.activity.strategic_new",
        type: Constants.CONSTANT_STRATEGIC_ACTIVITYTYPE,
        initiativeType: Constants.CONSTANT_STRATEGIC_PROJECT_INITIATIVETYPE,
        pageMode: PageMode.create,
      },
      loadComponent: () =>
        import("./activities-editor/activities-editor.component").then((m) => m.ActivitiesEditorComponent),
    },
    {
      path: "edit/:id",
      canActivate: [...strategicActivitiesGuards.edit, NotArchivedPlan()],
      data: {
        title: "breadcrumbs.activity.strategic_edit",
        breadcrumb: "breadcrumbs.activity.strategic_edit",
        type: Constants.CONSTANT_STRATEGIC_ACTIVITYTYPE,
        initiativeType: Constants.CONSTANT_STRATEGIC_PROJECT_INITIATIVETYPE,
        pageMode: PageMode.edit,
      },
      loadComponent: () =>
        import("./activities-editor/activities-editor.component").then((m) => m.ActivitiesEditorComponent),
    },
    {
      path: ":id",
      canActivate: [...strategicActivitiesGuards.view],
      data: {
        title: "activities.strategic",
        breadcrumb: "breadcrumbs.activity.strategic_view",
        type: Constants.CONSTANT_STRATEGIC_ACTIVITYTYPE,
      },
      loadComponent: () => import("./activities-view/activities-view.component").then((m) => m.ActivitiesViewComponent),
    },
  ],
};

const operationalActivityRoutes: Route = {
  path: "operational",
  canActivate: [checkIfLoggedInFn],
  children: [
    {
      path: "",
      canActivate: [
        () => {
          const router = inject(Router);
          if (useNewDS()) return true;
          else return router.createUrlTree(["/console", "activities", "list"]);
        },
        ...activitiesGuards.view,
      ],
      data: {
        title: "breadcrumbs.activity.operational_list",
        breadcrumb: "breadcrumbs.activity.operational_list",
        activityType: Constants.CONSTANT_OPERATIONAL_ACTIVITYTYPE,
        tableConfList: Constants.MAIN_TABLE_LIST_CONF_KEY.NEW_DS_ACTIVITIES,
        itemTypeId: Constants.CONSTANT_PERMISSIONS.ACTIVITIES,
      },
      loadComponent: () => import("./activities-list/activities-list.component").then((m) => m.ActivitiesListComponent),
    },
    {
      path: "create",
      canActivate: [...activitiesGuards.create, NotArchivedPlan()],
      data: {
        title: "breadcrumbs.activity.operational_new",
        breadcrumb: "breadcrumbs.activity.operational_new",
        type: Constants.CONSTANT_OPERATIONAL_ACTIVITYTYPE,
        initiativeType: Constants.CONSTANT_REGULAR_TASK_INITIATIVETYPE,
        pageMode: PageMode.create,
      },
      loadComponent: () =>
        import("./activities-editor/activities-editor.component").then((m) => m.ActivitiesEditorComponent),
    },
    {
      path: "edit/:id",
      canActivate: [...activitiesGuards.edit, NotArchivedPlan()],
      data: {
        title: "breadcrumbs.activity.operational_edit",
        breadcrumb: "breadcrumbs.activity.operational_edit",
        type: Constants.CONSTANT_OPERATIONAL_ACTIVITYTYPE,
        initiativeType: Constants.CONSTANT_REGULAR_TASK_INITIATIVETYPE,
        pageMode: PageMode.edit,
      },
      loadComponent: () =>
        import("./activities-editor/activities-editor.component").then((m) => m.ActivitiesEditorComponent),
    },
    {
      path: ":id",
      canActivate: [...activitiesGuards.view],
      data: {
        title: "activities.operational",
        breadcrumb: "breadcrumbs.activity.operational_view",
        type: Constants.CONSTANT_OPERATIONAL_ACTIVITYTYPE,
      },
      loadComponent: () => import("./activities-view/activities-view.component").then((m) => m.ActivitiesViewComponent),
    },
  ],
};

export const ACTIVITIES_ROUTES: Route[] = [
  {
    path: "activities",
    canActivate: [checkIfLoggedInFn],
    children: [
      {
        path: "",
        canActivate: [
          checkIfLoggedInFn,
          () => {
            const router = inject(Router);
            if (useNewDS()) return true;
            else return router.createUrlTree(["/console", "activities", "list"]);
          },
        ],
        data: {
          title: "breadcrumbs.activity.list",
          breadcrumb: "breadcrumbs.activity.list",
        },
        loadComponent: () =>
          import("./activities-submenu/activities-submenu.component").then((m) => m.ActivitiesSubmenuComponent),
      },
      strategicActivityRoutes,
      operationalActivityRoutes,
      {
        path: "list",
        canActivate: [
          checkIfLoggedInFn,
          () => {
            const router = inject(Router);
            if (!useNewDS()) return true;
            else return router.createUrlTree(["/console", "activities"]);
          },
          ...activitiesGuards.view,
        ],
        data: {
          title: "breadcrumbs.activity.list",
          breadcrumb: { skip: true },
          tableConfList: Constants.MAIN_TABLE_LIST_CONF_KEY.ACTIVITIES,
          itemTypeId: Constants.CONSTANT_PERMISSIONS.ACTIVITIES,
          activityType: Constants.CONSTANT_OPERATIONAL_ACTIVITYTYPE,
        },
        loadComponent: () =>
          import("./activities-list/activities-list.component").then((m) => m.ActivitiesListComponent),
      },
      {
        path: "create",
        canActivate: [checkIfLoggedInFn, ...activitiesGuards.create, NotArchivedPlan()],
        data: {
          title: "breadcrumbs.activity.new",
          breadcrumb: "breadcrumbs.activity.new",
          initiativeType: Constants.CONSTANT_REGULAR_TASK_INITIATIVETYPE,
          type: Constants.CONSTANT_OPERATIONAL_ACTIVITYTYPE,
          pageMode: PageMode.create,
        },
        loadComponent: () =>
          import("./activities-editor/activities-editor.component").then((m) => m.ActivitiesEditorComponent),
      },
      {
        path: "edit/:id",
        canActivate: [checkIfLoggedInFn, ...activitiesGuards.edit, NotArchivedPlan()],
        data: {
          title: "breadcrumbs.activity.edit",
          breadcrumb: "breadcrumbs.activity.edit",
          type: Constants.CONSTANT_OPERATIONAL_ACTIVITYTYPE,
          initiativeType: Constants.CONSTANT_REGULAR_TASK_INITIATIVETYPE,
          pageMode: PageMode.edit,
        },
        loadComponent: () =>
          import("./activities-editor/activities-editor.component").then((m) => m.ActivitiesEditorComponent),
      },
      {
        path: ":id",
        canActivate: [
          checkIfLoggedInFn,
          () => {
            const router = inject(Router);
            if (!useNewDS()) return true;
            else return router.createUrlTree(["/console", "activities"]);
          },
          ...activitiesGuards.view,
        ],
        data: {
          title: "tasks.activity",
          breadcrumb: "breadcrumbs.activity.view",
          type: Constants.CONSTANT_OPERATIONAL_ACTIVITYTYPE,
        },
        loadComponent: () =>
          import("./activities-view/activities-view.component").then((m) => m.ActivitiesViewComponent),
      },
    ],
  },
];
