import { Component, effect, inject, Injector, input, OnInit, signal, untracked } from "@angular/core";
import { FormArray, FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { TranslatePipe } from "@ngx-translate/core";
import { distinctUntilChanged, filter, map } from "rxjs";

import { TranslateTokenPipe } from "../../../../../core/pipes";
import { AdaaHelper } from "../../../../../core/utils";
import { FormCheckboxComponent } from "../../../../../shared/components";
import { Constants } from "../../../../../shared/constants/constants";
import {
  EntityLeaderDecisionStateType,
  ObjectStatus,
  ParameterCatalog,
  PropTypeModelType,
} from "../../../../../shared/models";
import { PropertiesService, UserService } from "../../../../../shared/services";

@Component({
  selector: "adaa-national-target-management",
  imports: [ReactiveFormsModule, FormCheckboxComponent, TranslateTokenPipe, TranslatePipe],
  templateUrl: "./national-target-management.component.html",
  styleUrl: "../styles.scss",
})
export class NationalTargetManagementComponent implements OnInit {
  private readonly _injector = inject(Injector);
  private readonly _userService = inject(UserService);
  private readonly _propertiesService = inject(PropertiesService);

  form = new FormGroup({
    entityLeaderDecisions: new FormArray([]),
  });

  options = input<EntityLeaderDecisionStateType[]>([]);
  validations = input<ParameterCatalog[]>([]);

  props = signal<PropTypeModelType[]>([]);

  #untilDestroyed = AdaaHelper.untilDestroyed();

  public get entityLeaderDecisions() {
    return this.form.controls["entityLeaderDecisions"] as unknown as FormArray;
  }

  public ngOnInit() {
    this._fetchProps();
    this._addFormControls();
  }

  public getOption(index: number) {
    return this.props()[index];
  }

  public submit() {
    const options = this.options();
    const formGroups = this.entityLeaderDecisions.controls as FormGroup[];
    const allTrue: number[] = [];

    for (const form of formGroups) {
      const value = form.value;
      const key = Object.keys(value)[0];
      if (value[key]) {
        allTrue.push(Number(key));
      }
    }

    const user = this._userService.currentUser();
    const result: EntityLeaderDecisionStateType[] = [];

    // add
    for (const id of allTrue) {
      const found = options.find(({ optionId }) => optionId === id);
      if (!found) {
        result.push({
          id: undefined,
          optionId: id,
          createdBy: user?.id as number,
          updatedBy: user?.id as number,
          status: ObjectStatus.DRAFT,
        });
      }
    }

    // remove
    for (const option of options) {
      const found = allTrue.find((id) => option.optionId === id);
      if (!found) {
        result.push({
          ...option,
          status: ObjectStatus.REMOVE,
        });
      }
    }

    // normalcy
    for (const id of allTrue) {
      const found = options.find(({ optionId }) => optionId === id);
      if (found) {
        result.push(found);
      }
    }

    return result;
  }

  private _firstChangeDetection() {
    const props = this.props();
    if (!AdaaHelper.isDefined(props) || !AdaaHelper.isDefined(props[0])) return;

    const optionId = props[0].id;
    const controls = this.entityLeaderDecisions.controls;

    (this.entityLeaderDecisions.controls[0] as FormGroup).controls[optionId].valueChanges
      .pipe(this.#untilDestroyed(), distinctUntilChanged())
      .subscribe({
        next: (value) => {
          for (const index in props) {
            if (+index === 0) continue;
            const option = props[+index];
            for (const control of controls) {
              if (!value) {
                (control as FormGroup).controls[option.id]?.enable();
              } else {
                if ((control as FormGroup).controls[option.id]) {
                  (control as FormGroup).controls[option.id]?.setValue(false);
                }
              }
            }
          }
        },
      });
  }

  private _otherChangeDetection() {
    for (const $i in this.entityLeaderDecisions.controls) {
      if (+$i === 0) continue;

      const props = this.props();
      if (!AdaaHelper.isDefined(props) || !AdaaHelper.isDefined(props[+$i])) return;

      const { id } = props[+$i];
      (this.entityLeaderDecisions.controls[+$i] as FormGroup).controls[id].valueChanges
        .pipe(this.#untilDestroyed(), distinctUntilChanged())
        .subscribe({
          next: (value) => {
            if (value) {
              const optionId = props[0]?.id;
              (this.entityLeaderDecisions.controls[0] as FormGroup).controls[optionId]?.setValue(false);
            }
          },
        });
    }
  }

  private _fetchProps() {
    this._propertiesService
      .getPropById(Constants.NTKPI_CARD_PROPS.entityLeaderDecision)
      .pipe(
        filter((res) => !res.inError),
        map((res) => res.responseData)
      )
      .subscribe({
        next: (data) => this.props.set(data),
      });
  }

  private _addFormControls() {
    effect(
      () => {
        const props = this.props();
        const options = this.options() ?? [];

        untracked(() => {
          for (const prop of props) {
            this.entityLeaderDecisions.push(
              new FormGroup({
                [prop.id]: new FormControl(false, []),
              })
            );
          }

          const controls = this.entityLeaderDecisions.controls;
          for (const option of options) {
            for (const control of controls) {
              if ((control as FormGroup).controls[option.optionId]) {
                (control as FormGroup).controls[option.optionId].setValue(true);
              }
            }
          }

          this._firstChangeDetection();
          this._otherChangeDetection();
        });
      },
      { injector: this._injector }
    );
  }
}
