<adaa-auth-layout>
    @if (!resetDone) {
        <form [formGroup]="forgetPasswordForm" (ngSubmit)="submit()" class="row mt-2">
            <div class="col-12 mb-3">
                <h5 class="text-info mb-1">{{ "login.forgot_password_title" | translate }}</h5>
                <h6 class="text-info">{{ "login.forgot_text" | translate }}</h6>
            </div>
            <div class="col-12 mb-3">
                <adaa-form-input
                    [required]="true"
                    [placeholder]="'common.form.label.email' | translate"
                    controlName="email"
                    formControlName="email"
                    inputType="email"
                    [invalid]="forgetPasswordForm.get('email')?.errors?.['required'] || forgetPasswordForm.get('email')?.errors?.['email']"
                ></adaa-form-input>
            </div>
            <div class="col-12 mb-3">
                <div class="d-grid gap-1 col-4 mx-auto">
                    <button type="submit" class="btn btn-info">
                        <span>{{ "login.reset_password" | translate }}</span>
                    </button>
                </div>
            </div>
            <div class="col-12 mb-5">
                <span role="button" class="text-info" [routerLink]="'/login'">{{ "login.go_back" | translate }}</span>
            </div>
        </form>
    } @else if (resetDone) {
        <div>
            <p class="mt-3 text-info">
                {{ "login.send_reset_email_text" | translate }}
                <span class="text-primary">{{ forgetPasswordForm.get("email")?.value }}</span>
            </p>
            <p>{{ "login.send_reset_email_instructions" | translate }}</p>
            <i class="fs-1 fa-solid fa-check text-success"></i> <br />
            <span role="button" class="text-info mb-5" [routerLink]="'/login'">{{ "login.go_back" | translate }}</span>
        </div>
    }
</adaa-auth-layout>
