import { Component, inject, OnInit, signal, viewChild } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { AdaaHelper } from "../../../../core/utils";
import {
  ConfirmationModalComponent,
  DataTableComponent,
  FloatActionComponent,
  ItemsOrderModalComponent,
} from "../../../../shared/components";
import { genericFloatButtons } from "../../../../shared/components/general/float-action";
import { Constants } from "../../../../shared/constants/constants";
import { AdaaBoolean, Language } from "../../../../shared/constants/enums";
import { CustomButton, TableButtonClicked, ValueText } from "../../../../shared/models";
import { GovPillarEntity, GovScoresPillar } from "../../../../shared/models/gov-scores.model";
import { AppService, GovScoresApiService, LanguageService, SystemLayoutService } from "../../../../shared/services";

@Component({
  selector: "adaa-entity-pillars-list",
  standalone: true,
  imports: [DataTableComponent, FloatActionComponent],
  templateUrl: "./entity-pillars-list.component.html",
  styleUrl: "./entity-pillars-list.component.scss",
})
export class EntityPillarsListComponent implements OnInit {
  private _govScoresApiService = inject(GovScoresApiService);
  private _modalService = inject(NgbModal);
  private _appService = inject(AppService);
  private _translateService = inject(TranslateService);
  private _toastrService = inject(ToastrService);
  private _systemLayoutService = inject(SystemLayoutService);
  private _router = inject(Router);
  private _languageService = inject(LanguageService);

  private readonly _untilDestroy = AdaaHelper.untilDestroyed();
  private readonly _genericFloatButtons = genericFloatButtons();
  pillarEntities = signal<GovPillarEntity[]>([]);
  Constants = Constants;
  adaaDataTable = viewChild.required<DataTableComponent>("adaaDataTable");

  CustomButtons: CustomButton[] = [
    {
      eventName: "sort",
      iconTitle: "navigation.tooltips.order_tooltip",
      iconName: "adaa-icon-sort",
    },
  ];

  public ngOnInit(): void {
    this._floatActions();
    this._systemLayoutService.hasActiveEntityChanged$.pipe(this._untilDestroy()).subscribe({
      next: () => {
        this._router.navigateByUrl("/console");
      },
    });
  }

  public addNew() {
    this._router.navigateByUrl("console/government-scores/pillars/create");
  }

  public getAction(value: TableButtonClicked) {
    switch (value.event) {
      case "view":
        this._router.navigateByUrl(`console/government-scores/pillars/${value.data.id}`);
        break;
      case "edit":
        this._router.navigateByUrl(`console/government-scores/pillars/edit/${value.data.id}`);
        break;
      case "delete":
        this._deletePillar(value.data.id);
        break;
      case "sort":
        this._getPillarsByEntity(value.data.id);
        break;
      default:
        break;
    }
  }

  private _getPillarsByEntity(entityId: number): void {
    this._govScoresApiService.getGovScoresPillarsByEntity(entityId).subscribe({
      next: (response) => {
        if (response.inError) return;

        this._sortPillars(response.responseData);
      },
    });
  }

  private _sortPillars(pillars: GovScoresPillar[]): void {
    const modal = this._modalService.open(ItemsOrderModalComponent, {
      centered: true,
      size: "xl",
      modalDialogClass: this._languageService.modalDirection(),
    });

    const data: ValueText[] = [];

    pillars.forEach((e) => data.push({ value: e.id, text: AdaaHelper.getItemValueByToken(e, "name") }));

    modal.componentInstance.data = data;
    modal.componentInstance.inputLabel = "government_scores.entity_pillars";

    modal.result.then((result: number[] | undefined) => {
      if (!result) return;
      pillars.forEach((pillar) => {
        const index = result.findIndex((e) => e === pillar.id);
        pillar.displayOrder = index + 1;
        pillar.newPillar = pillar.id ? AdaaBoolean.N : AdaaBoolean.Y;
      });

      this._saveSortedPillars(pillars);
    });
  }

  private _saveSortedPillars(pillars: GovScoresPillar[]): void {
    this._govScoresApiService.updateGovScoresPillars(pillars).subscribe({
      next: (response) => {
        if (response.inError) return;

        this._toastrService.success(this._translateService.instant("notification.success.save"));
        this.adaaDataTable().loadTableData();
      },
    });
  }

  private _deletePillar(id: number) {
    const modal = this._modalService.open(ConfirmationModalComponent, {
      centered: true,
      size: "md",
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });

    modal.componentInstance.header = "government_scores.pillars.confirm_delete_title";
    modal.componentInstance.title = "government_scores.pillars.confirm_delete_message";

    modal.result.then((e) => {
      if (e) {
        this._govScoresApiService.deletePillarByEntityId(id).subscribe({
          next: (response) => {
            if (response.inError) return;
            this._toastrService.success(this._translateService.instant("notification.success.remove"));
            this.adaaDataTable().loadTableData();
          },
        });
      }
    });
  }

  private _floatActions(): void {
    this._genericFloatButtons([
      {
        key: "helpdesk",
        data: {
          url: "/helpdesk/create",
        },
      },

      {
        key: "print",
        data: undefined,
      },
    ]);
  }
}
