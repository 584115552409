import {
  Component,
  computed,
  effect,
  ElementRef,
  inject,
  Injector,
  input,
  OnInit,
  signal,
  untracked,
  viewChild,
} from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import Handsontable from "handsontable/base";
import { DetailedSettings } from "handsontable/plugins/nestedHeaders/nestedHeaders";
import { ColumnSettings, GridSettings } from "handsontable/settings";
import { filter, map, pairwise, Subject, switchMap } from "rxjs";

import { AdaaBooleanType } from "../../../../../../adaa-types";
import { environment } from "../../../../../core/environments/environment";
import { AdaaHelper } from "../../../../../core/utils";
import { Constants } from "../../../../../shared/constants/constants";
import { AdaaBoolean, Language, PageMode } from "../../../../../shared/constants/enums";
import {
  AffectedEntityKpi,
  EntityTargetModelType,
  KpiMetricModelType,
  KpiTargetModelType,
  ObjectStatus,
  PeriodModelType,
} from "../../../../../shared/models";
import { TextMapModelType } from "../../../../../shared/models/text-mapping.model";
import { LanguageService, PeriodApiService } from "../../../../../shared/services";
import { isStartGreaterThanEnd } from "../../utils/form-groups/lib";

@Component({
  selector: "adaa-master-kpi-targets",
  standalone: true,
  imports: [TranslateModule],
  styleUrl: "./styles.scss",
  template: ` <div
    class="w-100 d-flex justify-content-center align-content-center card mb-2 p-3 bg-white border-0 form-section"
  >
    @if (hasErrors()) {
      @if (hasMissingDetails().fieldsMissing) {
        <div class="alert alert-danger my-1 border-0 rounded-1 fw-bold" role="alert">
          <i class="fa-solid fa-triangle-exclamation me-2" role="img" aria-label="Warning:"></i>
          {{ "nkpi.no_formula_info" | translate }}
        </div>
      }
      @if (hasMissingDetails().frequency) {
        <div class="alert alert-danger my-1 border-0 rounded-1 fw-bold d-flex align-items-center" role="alert">
          <i class="fa-solid fa-triangle-exclamation mx-2" role="img" aria-label="Warning:"></i>
          {{ "nkpi.frequency_missing" | translate }}
        </div>
      }
      @if (hasMissingDetails().formulaStatus) {
        <div class="alert alert-danger my-1 border-0 rounded-1 fw-bold d-flex align-items-center" role="alert">
          <i class="fa-solid fa-triangle-exclamation mx-2" role="img" aria-label="Warning:"></i>
          {{ "nkpi.invalid_formula" | translate }}
        </div>
      }
      @if (hasMissingDetails().dates) {
        <div class="alert alert-danger my-1 border-0 rounded-1 fw-bold d-flex align-items-center" role="alert">
          <i class="fa-solid fa-triangle-exclamation mx-2" role="img" aria-label="Warning:"></i>
          {{ "notification.error.dates_difference" | translate }}
        </div>
      }
      @if (hasMissingDetails().periods) {
        <div class="alert alert-danger my-1 border-0 rounded-1 fw-bold d-flex align-items-center" role="alert">
          <i class="fa-solid fa-triangle-exclamation mx-2" role="img" aria-label="Warning:"></i>
          {{ "notification.error.no_periods" | translate }}
        </div>
      }
      @if (stagedKpi()?.targetsInReview) {
        <div class="alert alert-warning my-1 border-0 rounded-1 fw-bold d-flex align-items-center" role="alert">
          <i class="fa-solid fa-triangle-exclamation mx-2" role="img" aria-label="Warning:"></i>
          {{ "kpi.targets_in_review" | translate }}
        </div>
      }
    }

    @if (entitiesInReview().length > 0) {
      <div class="alert alert-warning my-1 border-0 rounded-1 fw-bold d-flex align-items-center" role="alert">
        <i class="fa-solid fa-triangle-exclamation mx-2" role="img" aria-label="Warning:"></i>
        {{ "kpi.targets_in_review" | translate }}
      </div>
    }

    @if (isTabActive()) {
      <section class="hot-table" #hotTable></section>
    }
  </div>`,
})
export class MasterKpiTargetsComponent implements OnInit {
  private readonly _injector = inject(Injector);
  private readonly _languageService = inject(LanguageService);
  private readonly _periodApiService = inject(PeriodApiService);

  textMaps = input<TextMapModelType[]>([]);
  stagedKpi = input<Record<string, unknown>>();
  kpi = input<Record<string, unknown>>();
  kpiType = input.required<number>();
  pageMode = input.required<PageMode>();
  isTabActive = input.required<boolean>();

  hotTable = viewChild<ElementRef>("hotTable");

  hasChanges = signal<boolean>(false);
  periods = signal<PeriodModelType[]>([]);
  kpiTargets = signal<KpiTargetModelType[]>([]);
  targetErrors = signal<KpiTargetModelType[]>([]);

  dt = computed(() => ({
    start: this.stagedKpi()?.startDate as number | null,
    end: this.stagedKpi()?.endDate as number | null,
  }));
  baselineYear = computed(() => this.stagedKpi()?.baselineYear as number);
  baselineKpi = computed(() => this.stagedKpi()?.baselineKpi as AdaaBooleanType);
  frequency = computed(() => this.stagedKpi()?.frequency as number | null);
  isSkpi = computed(() => this.kpiType() === Constants.KPI_TYPE.SKPI);
  targetsInReview = computed(() => this.stagedKpi()?.targetsInReview as boolean);
  kpiIsBounded = computed(() => this.stagedKpi()?.trend === Constants.CONSTANT_TREND_BOUNDED);
  targets = computed(() => this.kpi()?.targets as KpiTargetModelType[]);
  stagedTargets = computed(() => this.stagedKpi()?.targets as KpiTargetModelType[]);
  metrics = computed(() => this.stagedKpi()?.metrics as KpiMetricModelType[]);
  formulaStatus = computed(() => this.stagedKpi()?.formulaStatus as AdaaBooleanType | null);
  affectedEntities = computed(() => this.stagedKpi()?.affectedEntities as AffectedEntityKpi[] | null);
  hasErrors = computed(() => Object.keys(this.hasMissingDetails()).length > 0);
  hasMissingDetails = computed(() => {
    const errors: Record<string, true> = {};
    if (!AdaaHelper.isDefined(this.frequency())) {
      errors.frequency = true;
    }
    if (!AdaaHelper.isDefined(this.formulaStatus()) || this.formulaStatus() !== AdaaBoolean.Y) {
      errors.formulaStatus = true;
    }

    const { start, end } = this.dt();
    if (!start || !end) {
      errors.fieldsMissing = true;
    }
    if (isStartGreaterThanEnd(start, end)) {
      errors.dates = true;
    }
    if (!this.periods()?.length) {
      errors.periods = true;
    }

    return errors;
  });
  hasPermissionToUpdate = computed(() => {
    const kpi = this.stagedKpi();
    const entityId = kpi?.targetsInputEntityId;
    const linkedKpiId = kpi?.linkedKpiId;

    if (this.isSkpi() && AdaaHelper.isDefined(linkedKpiId)) return true;
    if (!AdaaHelper.isDefined(entityId)) return true;

    return entityId === AdaaHelper.entity?.id;
  });
  entitiesInReview = computed(() => (this.kpi()?.entitiesInReview ?? []) as number[]);

  readonly #untilDestroy = AdaaHelper.untilDestroyed();
  readonly #onPeriodChangeEvent = new Subject<unknown>();
  readonly #onEntityChangeEvent = new Subject<unknown>();
  readonly #onPeriodChange$ = this.#onPeriodChangeEvent.asObservable().pipe(pairwise());
  readonly #onEntityChange$ = this.#onEntityChangeEvent.asObservable().pipe(pairwise());

  readonly #getQuarterlyLabel = (quarter: number) => {
    if (quarter === 1) return this._languageService.translate("targets.periods.quarters.1");
    if (quarter === 2) return this._languageService.translate("targets.periods.quarters.2");
    if (quarter === 3) return this._languageService.translate("targets.periods.quarters.3");
    return this._languageService.translate("targets.periods.quarters.4");
  };
  readonly #getMonthlyLabel = (epoch: number) => {
    const dt = new Intl.DateTimeFormat(this._languageService.current() === Language.Arabic ? "ar-AR" : "en-GB", {
      month: "long",
      localeMatcher: "best fit",
      timeZone: Constants.uaeTimezoneName,
    });

    return dt.format(epoch);
  };
  readonly #refreshPeriodList = () => {
    return switchMap(([_, data]) => {
      return this._fetchPeriodRange(data);
    });
  };
  readonly #hasPeriodDataChanged = () => {
    return filter(([old$, new$]) => {
      const oldData = old$ as { startTS: number; endTS: number; frequency: number };
      const newData = new$ as { startTS: number; endTS: number; frequency: number };

      if (oldData.frequency !== newData.frequency) return true;
      if (oldData.startTS !== newData.startTS) return true;
      return oldData.endTS !== newData.endTS;
    });
  };
  readonly #hasEntitiesChanged = () => {
    return filter(([old$, new$]) => {
      const oldData = old$ as AffectedEntityKpi[] | null;
      const newData = new$ as AffectedEntityKpi[] | null;
      return JSON.stringify(oldData) !== JSON.stringify(newData);
    });
  };

  readonly #hansontableEffect = () => {
    effect(
      () => {
        if (this.isTabActive() && !this.hotInstance) {
          this._initHandsontable();
        } else {
          this.hotInstance = undefined;
        }
      },
      { injector: this._injector }
    );
  };
  readonly #entityChangeEffect = () => {
    effect(
      () => {
        const errors = this.hasMissingDetails();
        const affectedEntities = this.affectedEntities();

        untracked(() => {
          if (errors.dates || errors.fieldsMissing || errors.frequency || errors.formulaStatus) return;

          this.#onEntityChangeEvent.next({
            eData: affectedEntities,
          });
        });
      },
      { injector: this._injector }
    );
  };
  readonly #periodChangeEffect = () => {
    effect(
      () => {
        const { start, end } = this.dt();
        const frequency = this.frequency();
        const errors = this.hasMissingDetails();

        untracked(() => {
          if (errors.dates || errors.fieldsMissing || errors.frequency || errors.formulaStatus) return;

          if (!this.periods().length) {
            this._fetchPeriodRange({
              frequency: frequency as number,
              startTS: start as number,
              endTS: end as number,
            }).subscribe({
              next: (data) => this.periods.set(data ?? []),
              complete: () => {
                this._initTargetsList();
              },
            });
          }

          this.#onPeriodChangeEvent.next({
            frequency: frequency as number,
            startTS: start as number,
            endTS: end as number,
          });
        });
      },
      { injector: this._injector }
    );
  };

  hotInstance: Handsontable | undefined;

  constructor() {
    this.#hansontableEffect();
    this.#periodChangeEffect();
    this.#entityChangeEffect();
    effect(() => this.showCellsWithError());
  }

  public ngOnInit() {
    this._onPeriodOrEntitiesChange();
  }

  public showCellsWithError() {
    const errors = this.targetErrors();
    const targets = this.kpiTargets();

    this.hotInstance?.batch(() => {
      errors.forEach(({ id: periodId }) => {
        const i = targets.findIndex(({ id }) => periodId === id);
        const { lowerLimit } = targets[i];

        let col: number;
        if (!this.kpiIsBounded()) col = 0;
        else {
          if (!AdaaHelper.isDefined(lowerLimit)) col = 0;
          else col = 1;
        }

        const place: [row: number, col: number] = [i, col];
        this.hotInstance?.setCellMeta(...place, "valid", false);
        this.hotInstance?.setCellMeta(...place, "className", "has-error");
      });
    });

    this.hotInstance?.render();
  }

  public submitTargets() {
    const kpi = this.stagedKpi();

    // NOTE: on create error detection
    if (this.pageMode() === PageMode.create) {
      if (this.baselineKpi() === AdaaBoolean.Y) {
        return {
          targetWasChanged: true,
          targets: this.kpiTargets().map((t) => {
            if (this.baselineKpi() === AdaaBoolean.Y) {
              t.entities?.forEach((e) => {
                e.lowerLimit = null;
                e.highLimit = null;
                e.value = null;
              });
              return { ...t, value: null, lowerLimit: null, highLimit: null };
            } else {
              return t;
            }
          }),
        };
      }

      return {
        targets: this.kpiTargets(),
        targetWasChanged: true,
      };
    }

    let hasAnyChanges = false;

    this.kpiTargets().forEach((updated) => {
      const original = ((kpi?.targets ?? []) as Record<string, unknown>[]).find(({ id }) => id === updated.id);
      // NOTE: if the target entry is not found in the original KPI
      if (!original) {
        hasAnyChanges = true;
        return;
      }

      if (this.kpiIsBounded())
        hasAnyChanges = original.lowerLimit !== updated.lowerLimit || original.highLimit !== updated.highLimit;
      else hasAnyChanges = original.value !== updated.value;
    });

    return {
      targetWasChanged: hasAnyChanges,
      targets: this.kpiTargets().map((t) => {
        // NOTE: if is baselineKPI and year is baselineYear. show `null`
        if (this.baselineKpi() === AdaaBoolean.Y && this.baselineYear() === t.year) {
          t.entities?.forEach((e) => {
            e.lowerLimit = null;
            e.highLimit = null;
            e.value = null;
          });
          return { ...t, value: null, lowerLimit: null, highLimit: null };
        } else {
          return t;
        }
      }),
    };
  }

  private _onPeriodOrEntitiesChange() {
    this.#onPeriodChange$
      .pipe(this.#hasPeriodDataChanged(), this.#refreshPeriodList(), this.#untilDestroy())
      .subscribe({
        next: (data) => {
          this.periods.set(data ?? []);
          this._initTargetsList();
        },
      });

    this.#onEntityChange$.pipe(this.#hasEntitiesChanged(), this.#untilDestroy()).subscribe({
      next: () => this._initTargetsList(),
    });
  }

  private _fetchPeriodRange(data: { startTS: number; endTS: number; frequency: number }) {
    return this._periodApiService.getDateRange(data).pipe(map((res) => res.responseData));
  }

  private _initTargetsList() {
    const targets = this.stagedTargets() ?? [];
    this.kpiTargets.set(
      this.periods().map<KpiTargetModelType>((period) => {
        const data = targets.find(({ id }) => period.id === id);

        let value: Pick<KpiTargetModelType, "lowerLimit" | "highLimit" | "value">;
        if (this.kpiIsBounded()) {
          value = {
            lowerLimit: data?.lowerLimit != null ? data.lowerLimit : null,
            highLimit: data?.highLimit != null ? data.highLimit : null,
            value: null,
          };
        } else {
          value = {
            lowerLimit: null,
            highLimit: null,
            value: data?.value != null ? data.value : null,
          };
        }

        const entities: EntityTargetModelType[] = [];
        this.affectedEntities()?.forEach((affectedEntity) => {
          const entityData = data?.entities?.find((e) => e.entityId === affectedEntity.entityId);

          if (entityData) {
            let entityValue: Pick<EntityTargetModelType, "lowerLimit" | "highLimit" | "value">;
            if (this.kpiIsBounded()) {
              entityValue = {
                lowerLimit: entityData?.lowerLimit != null ? entityData.lowerLimit : null,
                highLimit: entityData?.highLimit != null ? entityData.highLimit : null,
                value: null,
              };
            } else {
              entityValue = {
                lowerLimit: null,
                highLimit: null,
                value: entityData?.value != null ? entityData.value : null,
              };
            }
            entities.push({
              ...entityValue,
              entityId: affectedEntity.entityId,
              wasChanged: entityData?.wasChanged ?? false,
              readingId: entityData?.readingId,
              revId: entityData?.revId,
              wfProcessCtlId: entityData?.wfProcessCtlId,
            });
          } else {
            const oldEntity = this.targets()
              ?.find((e) => e.id === period.id)
              ?.entities?.find((e) => e.entityId === affectedEntity.entityId);

            if (oldEntity) entities.push(oldEntity);
            else
              entities.push({
                entityId: affectedEntity.entityId,
                value: null,
                lowerLimit: null,
                highLimit: null,
                wasChanged: true,
                readingId: undefined,
                revId: undefined,
                wfProcessCtlId: undefined,
              });
          }
        });

        return {
          ...value,
          frequency: this.frequency()!,
          date: period.date,
          day: period.day,
          month: period.month,
          quarter: period.quarter,
          semester: period.semester,
          year: period.year,
          targetOverride: data?.targetOverride ?? false,
          wasChanged: data?.wasChanged ?? false,
          id: period.id,
          periodId: period.id,
          readingId: data?.readingId,
          status: data?.status ?? ObjectStatus.ACTIVE,
          average: data?.average ?? null,
          entities: entities,
          highLimitAverage: data?.highLimitAverage ?? null,
          lowerLimitAverage: data?.lowerLimitAverage ?? null,
          revId: data?.revId,
          readingType: data?.readingType ?? "T",
          wfProcessCtlId: data?.wfProcessCtlId ?? undefined,
        } satisfies KpiTargetModelType;
      })
    );
  }

  private _getPeriodLabel(item: KpiTargetModelType) {
    const frequency = this.frequency();

    const annualBasedLabel =
      frequency === Constants.FREQUENCY_ANNUAL ||
      frequency === Constants.FREQUENCY_EVERY_TWO_YEARS ||
      frequency === Constants.FREQUENCY_EVERY_THREE_YEARS ||
      frequency === Constants.FREQUENCY_EVERY_FOUR_YEARS ||
      frequency === Constants.FREQUENCY_EVERY_FIVE_YEARS;
    if (annualBasedLabel) return `${item.year}`;

    if (frequency === Constants.FREQUENCY_SEMIANNUAL && this._languageService.current() === Language.Arabic) {
      const label = this._languageService.translate(`targets.periods.semestral.${item.semester}`);
      return `${label} ${item.year}`;
    }

    if (frequency === Constants.FREQUENCY_SEMIANNUAL && this._languageService.current() === Language.English) {
      const label = this._languageService.translate("targets.semiannual");
      return `${label} ${item.semester} ${item.year}`;
    }

    if (frequency === Constants.FREQUENCY_QUARTERLY) {
      return `${item.year} ${this.#getQuarterlyLabel(item.quarter)}`;
    }

    return this.#getMonthlyLabel(item.date) + " " + item.year;
  }

  private _initHandsontable() {
    if (this.hasErrors()) return;

    const container = this.hotTable()?.nativeElement as Element;
    const options = this._getOptions();

    if (container) {
      this.hotInstance = new Handsontable(container, options);
      this.hotInstance.updateSettings({
        cells: (row, col, _prop) => {
          const isBaseLine =
            this.baselineKpi() === AdaaBoolean.Y && this.kpiTargets()[col].year === this.baselineYear();
          const rowHasWF = this._rowHasWF(row);
          const readOnly = this._isReadOnlyRow(row) || isBaseLine || rowHasWF;
          return {
            readOnly,
            className: readOnly ? (rowHasWF ? "inReviewCell" : "htDimmed readonly-cell") : undefined,
          };
        },
      });

      this.showCellsWithError();
    }
  }

  private _generateColumns() {
    const columnWidths: number[] = [];
    const columns: Array<ColumnSettings> = [];
    const periods = this.kpiTargets().map((i) => this._getPeriodLabel(i));

    periods.forEach((_e) => {
      if (this.kpiIsBounded()) {
        columnWidths.push(120, 120);
        columns.push({ readOnly: true, className: "disabledCell" }, { readOnly: true, className: "disabledCell" });
      } else {
        columnWidths.push(120);
        columns.push({ readOnly: true, className: "disabledCell" });
      }
    });

    return { columnWidths, columns };
  }

  private _generateHeaders() {
    const firstHeader: Array<string | DetailedSettings> = [];
    const seconedHeader: Array<string | DetailedSettings> = [];
    const periods = this.kpiTargets().map((i) => this._getPeriodLabel(i));

    periods.forEach((e) => {
      firstHeader.push({ label: e, colspan: 2 });
      seconedHeader.push(
        this._languageService.translate("kpi.lower_limit"),
        this._languageService.translate("kpi.upper_limit")
      );
    });

    return [firstHeader, seconedHeader];
  }

  private _generateRowHeaders(): string[] {
    const headers: string[] = [];

    //GOV KPI
    headers.push(this._languageService.translate("kpi.govKpi"));

    //Entities
    this.affectedEntities()?.forEach((e) => {
      headers.push(AdaaHelper.getItemValueByToken(e, "entityName"));
    });

    return headers;
  }

  private _generateTableData() {
    const data: Array<Array<number | null>> = [];
    let innerData: Array<number | null> = [];

    this.kpiTargets().map((kt) => {
      this.kpiIsBounded() ? innerData.push(kt.lowerLimit, kt.highLimit) : innerData.push(kt.value);
    });
    data.push(innerData);

    this.affectedEntities()?.forEach((entity) => {
      innerData = [];
      this.kpiTargets().map((kt) => {
        const entityKT = kt.entities?.find((e) => e.entityId === entity.entityId);
        if (AdaaHelper.isDefined(entityKT))
          this.kpiIsBounded()
            ? innerData.push(entityKT.lowerLimit, entityKT.highLimit)
            : innerData.push(entityKT.value);
        else this.kpiIsBounded() ? innerData.push(null, null) : innerData.push(null);
      });
      data.push(innerData);
    });

    return data;
  }

  private _getOptions(): GridSettings {
    const currentLang = this._languageService.current();
    const { columnWidths, columns } = this._generateColumns();

    const data: GridSettings = {
      data: this._generateTableData(),
      rowHeaders: this._generateRowHeaders(),
      rowHeaderWidth: 300,
      columns: columns,
      colWidths: columnWidths,
      stretchH: "all",
      height: window.innerHeight * 0.7,
      viewportColumnRenderingOffset: 999999,
      viewportRowRenderingOffset: 999999,
      hiddenColumns: true,
      layoutDirection: this._languageService.direction(),
      language: currentLang === Language.Arabic ? "ar-AR" : "en-US",
      licenseKey: environment.handsontable_key,
      afterChange: (changes, _source) => this._onChange(changes),
      afterGetRowHeader: (row, th) => {
        if (th) {
          th.title = th.textContent ?? "";

          if (this._rowHasWF(row)) th.className = "inReviewCell";
        }
      },
    };

    this.kpiIsBounded()
      ? (data.nestedHeaders = this._generateHeaders())
      : (data.colHeaders = this.kpiTargets().map((i) => this._getPeriodLabel(i)));

    return data;
  }

  private _onChange(changes: Handsontable.CellChange[] | null) {
    if (!AdaaHelper.isDefined(changes)) return;

    this.hasChanges.set(true);

    for (const change of changes) {
      const [entity, period, prev, curr] = change;

      const isEven = +period % 2 === 0;
      const boundedPeriod = Math.floor(+period / 2);

      this.kpiTargets.update((t) => {
        //GOV KPI
        if (entity === 0) {
          if (!this.kpiIsBounded()) {
            t[+period].wasChanged = AdaaHelper.tableValuesEvaluator(prev) !== AdaaHelper.tableValuesEvaluator(curr);

            t[+period].value = AdaaHelper.tableValuesEvaluator(curr);
            t[+period].average = AdaaHelper.tableValuesEvaluator(curr);

            return t;
          } else if (isEven) {
            t[boundedPeriod].wasChanged =
              AdaaHelper.tableValuesEvaluator(prev) !== AdaaHelper.tableValuesEvaluator(curr);

            t[boundedPeriod].lowerLimit = AdaaHelper.tableValuesEvaluator(curr);
            t[boundedPeriod].lowerLimitAverage = AdaaHelper.tableValuesEvaluator(curr);

            return t;
          } else if (!isEven) {
            t[boundedPeriod].wasChanged =
              AdaaHelper.tableValuesEvaluator(prev) !== AdaaHelper.tableValuesEvaluator(curr);

            t[boundedPeriod].highLimit = AdaaHelper.tableValuesEvaluator(curr);
            t[boundedPeriod].highLimitAverage = AdaaHelper.tableValuesEvaluator(curr);

            return t;
          }

          return t;
        } else {
          if (!this.kpiIsBounded()) {
            t[+period].entities![entity - 1].wasChanged =
              AdaaHelper.tableValuesEvaluator(prev) !== AdaaHelper.tableValuesEvaluator(curr);

            t[+period].entities![entity - 1].value = AdaaHelper.tableValuesEvaluator(curr);

            return t;
          } else if (isEven) {
            t[boundedPeriod].entities![entity - 1].wasChanged =
              AdaaHelper.tableValuesEvaluator(prev) !== AdaaHelper.tableValuesEvaluator(curr);

            t[boundedPeriod].entities![entity - 1].lowerLimit = AdaaHelper.tableValuesEvaluator(curr);

            return t;
          } else if (!isEven) {
            t[boundedPeriod].entities![entity - 1].wasChanged =
              AdaaHelper.tableValuesEvaluator(prev) !== AdaaHelper.tableValuesEvaluator(curr);

            t[boundedPeriod].entities![entity - 1].highLimit = AdaaHelper.tableValuesEvaluator(curr);

            return t;
          }

          return t;
        }
      });
    }
  }

  private _isReadOnlyRow(row: number): boolean {
    //GOV ROW
    if (row === 0) return !AdaaHelper.isPMOEntity();

    const entities = this.affectedEntities();
    if (!entities) return false;

    const entity = entities[row - 1];
    if (!entity) return false;

    if (entity.inputTargets === Constants.CONSTANT_DATA_INPUT.PMO) return !AdaaHelper.isPMOEntity();
    else if (entity.inputTargets === Constants.CONSTANT_DATA_INPUT.ENTITY) return AdaaHelper.entity.id !== entity.id;
    else if (entity.inputTargets === Constants.CONSTANT_DATA_INPUT.ENABLER)
      return this.stagedKpi()?.enablerEntityId !== AdaaHelper.entity.id;

    return false;
  }

  private _rowHasWF(row: number): boolean {
    if (row === 0) return false;

    const entities = this.affectedEntities();
    if (!entities) return false;

    const entity = entities[row - 1];
    if (!entity?.entityId) return false;

    return this.entitiesInReview().includes(entity.entityId);
  }
}
