<div class="modal-header">
    <h4 class="modal-title">{{ "ntkpi.improvement_plan_milestone" | translate }}</h4>
    <button type="button" class="btn-close" aria-label="Close button" aria-describedby="modal-title" (click)="cancel()"></button>
</div>

<div class="modal-body">
    <adaa-form-steps
        [tabs]="tabs()"
        [showSaveButton]="true"
        [showFinishButton]="false"
        [activeTab]="activeTab()"
        (currentTab)="activeTab.set($event)"
        (submitClicked)="save()"
        (cancelClicked)="cancel()"
    >
        <div class="tab-pane fade" role="tabpanel" [ngClass]="{ 'show active': activeTab() === 0 }">
            @if (isDefined(model)) {
                <form
                    autocomplete="on"
                    spellcheck="true"
                    class="d-block w-100 position-relative modal-body"
                    [dir]="languageService.direction()"
                    [attr.lang]="languageService.current()"
                    [formGroup]="model"
                >
                    <section class="d-flex row mb-2">
                        <div class="col-sm-6 col-xs-12">
                            <adaa-form-input
                                controlName="nameAE"
                                formControlName="nameAE"
                                classes="form-control shadow-none"
                                [invalid]="model.get('nameAE')?.touched! && model.get('nameAE')?.invalid!"
                                [label]="'common.form.label.nameAE' | translate"
                                [required]="isFieldRequired(validations(), 'nameAE')"
                            />
                        </div>

                        <div class="col-sm-6 col-xs-12">
                            <adaa-form-input
                                controlName="nameEN"
                                formControlName="nameEN"
                                classes="form-control shadow-none"
                                [invalid]="model.get('nameEN')?.touched! && model.get('nameEN')?.invalid!"
                                [label]="'common.form.label.nameEN' | translate"
                                [required]="isFieldRequired(validations(), 'nameEN')"
                            />
                        </div>
                    </section>

                    <section class="d-flex row mb-2">
                        <div class="col-sm-6 col-xs-12">
                            <adaa-form-input
                                controlName="dscAE"
                                formControlName="dscAE"
                                classes="form-control shadow-none"
                                [invalid]="model.get('dscAE')?.touched! && model.get('dscAE')?.invalid!"
                                [label]="'common.form.label.descriptionAE' | translate"
                                [isTextArea]="true"
                                [required]="isFieldRequired(validations(), 'dscAE')"
                            />
                        </div>

                        <div class="col-sm-6 col-xs-12">
                            <adaa-form-input
                                controlName="dscEN"
                                formControlName="dscEN"
                                classes="form-control shadow-none"
                                [invalid]="model.get('dscEN')?.touched! && model.get('dscEN')?.invalid!"
                                [label]="'common.form.label.descriptionEN' | translate"
                                [isTextArea]="true"
                                [required]="isFieldRequired(validations(), 'dscEN')"
                            />
                        </div>
                    </section>

                    <section class="d-flex row mb-2">
                        <div class="col-sm-6 col-xs-12">
                            <adaa-form-input-date
                                controlName="startDate"
                                formControlName="startDate"
                                [invalid]="
                                    model.get('startDate')?.touched! && (model.get('startDate')?.invalid! || model.errors?.startDate_endDate_Invalid)
                                "
                                [label]="'common.form.label.start_date' | translate"
                                [required]="isFieldRequired(validations(), 'startDate')"
                            />
                        </div>

                        <div class="col-sm-6 col-xs-12">
                            <adaa-form-input-date
                                controlName="endDate"
                                formControlName="endDate"
                                [invalid]="
                                    model.get('endDate')?.touched! && (model.get('endDate')?.invalid! || model.errors?.startDate_endDate_Invalid)
                                "
                                [label]="'common.form.label.end_date' | translate"
                                [required]="isFieldRequired(validations(), 'endDate')"
                            />
                        </div>
                    </section>

                    <section class="d-flex row mb-2">
                        <div class="col-sm-6 col-xs-12">
                            <adaa-form-input-date
                                controlName="actualStartDate"
                                formControlName="actualStartDate"
                                [invalid]="
                                    model.get('actualStartDate')?.touched! &&
                                    (model.get('actualStartDate')?.invalid! || model.errors?.actualStartDate_actualEndDate_Invalid)
                                "
                                [label]="'national_projects.actual_start_date' | translate"
                                [required]="isFieldRequired(validations(), 'actualStartDate')"
                            />
                        </div>

                        <div class="col-sm-6 col-xs-12">
                            <adaa-form-input-date
                                controlName="actualEndDate"
                                formControlName="actualEndDate"
                                [invalid]="
                                    model.get('actualEndDate')?.touched! &&
                                    (model.get('actualEndDate')?.invalid! || model.errors?.actualStartDate_actualEndDate_Invalid)
                                "
                                [label]="'national_projects.actual_end_date' | translate"
                                [required]="isFieldRequired(validations(), 'actualEndDate')"
                            />
                        </div>
                    </section>

                    <section class="d-flex row">
                        <div class="col-sm-6 col-xs-12">
                            <adaa-form-dropdown-tree
                                controlName="orgUnitId"
                                formControlName="orgUnitId"
                                [label]="'common.form.label.organization_unit' | translate"
                                [options]="orgUnitsOptions()"
                                [invalid]="model.get('orgUnitId')?.touched! && model.get('orgUnitId')?.invalid!"
                                [required]="isFieldRequired(validations(), 'orgUnitId')"
                                (inputChanges)="appendOrgUnit($event)"
                            />
                        </div>

                        <div class="col-sm-6 col-xs-12">
                            <adaa-form-dropdown
                                controlName="owner"
                                formControlName="owner"
                                [clearable]="true"
                                [searchable]="true"
                                [label]="'nkpi.owner' | translate"
                                [options]="ownersOptions()"
                                [invalid]="model.get('owner')?.touched! && model.get('owner')?.invalid!"
                                [required]="isFieldRequired(validations(), 'owner')"
                                [adaaInputDisabled]="!isDefined(model.get('orgUnitId')?.value)"
                            />
                        </div>
                    </section>

                    <section class="d-flex row mb-2">
                        <div class="col-sm-6 col-xs-12">
                            <adaa-form-input
                                controlName="weight"
                                formControlName="weight"
                                classes="form-control shadow-none"
                                inputType="positiveNumber"
                                [invalid]="model.get('weight')?.touched! && model.get('weight')?.invalid!"
                                [label]="'national_projects.workload' | translate"
                                [required]="isFieldRequired(validations(), 'weight')"
                            />
                        </div>

                        <div class="col-sm-6 col-xs-12">
                            <adaa-form-input
                                controlName="progress"
                                formControlName="progress"
                                classes="form-control shadow-none"
                                inputType="positiveNumber"
                                [invalid]="model.get('progress')?.touched! && model.get('progress')?.invalid!"
                                [label]="'common.tables.progress' | translate"
                                [required]="isFieldRequired(validations(), 'progress')"
                            />
                        </div>
                    </section>
                </form>
            }
        </div>

        <div class="tab-pane fade" role="tabpanel" [ngClass]="{ 'show active': activeTab() === 1 }">
            <div class="bg-white shadow-md rounded p-2 mb-2">
                <adaa-team-members-list
                    [tableConf]="tableConfList"
                    [teamMembers]="teamMembers()"
                    [entities]="entityOptions()"
                    [otherEntities]="[]"
                    [enableProjectMembers]="false"
                    [onlySystemUsers]="true"
                    (teamMembersChanged)="submitTeamMembers($event)"
                />
            </div>
        </div>
    </adaa-form-steps>
</div>
