import { AbstractControl, FormControl, FormGroup, Validators } from "@angular/forms";

import { AttachFile, ParameterCatalog } from "../../../../../shared/models";
import { getRequiredValidator } from "../../utils/form-groups/lib";

export type StructuredBenchmarkYearsType = {
  id: number | null;
  $index: number;
  year: number;
  countryId: number;
  countryName: string;
  value: number;
};

const minLengthArray = (min: number) => {
  return (c: AbstractControl): { [key: string]: boolean } | null => {
    if (!c.value) return null;
    if (c.value.length >= min) return null;
    return { minLengthArray: true };
  };
};

export const kpiBenchmarkForm = (catalog: ParameterCatalog[]) =>
  new FormGroup({
    nameAE: new FormControl<string>("", [...getRequiredValidator(catalog, "nameAE"), Validators.minLength(3)]),
    nameEN: new FormControl<string>("", [...getRequiredValidator(catalog, "nameEN"), Validators.minLength(3)]),
    benchType: new FormControl<number | null>(null, [...getRequiredValidator(catalog, "benchType")]),
    benchSource: new FormControl<string>("", [
      ...getRequiredValidator(catalog, "benchSource"),
      Validators.minLength(3),
    ]),
    benchFileList: new FormControl<object[]>([], [...getRequiredValidator(catalog, "benchFileList")]),
    years: new FormControl<object[]>([], [Validators.required, minLengthArray(1)]),
  });

export const kpiBenchmarkYearsForm = () =>
  new FormGroup({
    year: new FormControl<number | null>(null, [Validators.required]),
    countryId: new FormControl<number | null>(null, [Validators.required]),
    value: new FormControl<number | null>(null, [Validators.required]),
    id: new FormControl<number | null>(null),
  });

export const kpiBenchmarkUploadForm = () =>
  new FormGroup({
    nameAE: new FormControl<string>("", [Validators.required, Validators.minLength(3)]),
    nameEN: new FormControl<string>("", [Validators.required, Validators.minLength(3)]),
    file: new FormControl<AttachFile | null>(null, [Validators.required]),
  });
