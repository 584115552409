<adaa-entity-plan-change-detection
    [template]="page"
    [request$]="fetchProject()"
    [ignoreDetection]="pageMode() === PageMode.create"
    [ignoreEntityDetection]="AdaaHelper.isPMOEntity()"
    (continue)="initProject($event)"
/>

<ng-template #page>
    <div class="row">
        <div class="col-12">
            <h4 class="fw-bold">
                {{ "annual_meetings.title" | translate }}
                <span class="fw-light mx-1">
                    @if (displayLabel) {
                        - {{ displayLabel }}
                    }
                </span>
            </h4>
        </div>
        @if (id()) {
            <section class="d-block">
                <adaa-wf-alert-decision [itemId]="id()!" [itemType]="constants.CONSTANT_ANNUAL_MEETINGS_PROJECT" />
            </section>
        }
        <div class="col-12" [formGroup]="projectForm">
            <adaa-form-steps
                [tabs]="tabs"
                [showFinishButton]="true"
                [showSaveButton]="showSaveButton"
                (submitClicked)="submit($event)"
                (cancelClicked)="goBack()"
                (currentTab)="activeTab.set($event)"
            >
                <div class="tab-pane fade" id="tab-0" role="tabpanel" [ngClass]="{ 'show active': activeTab() === 0 }">
                    <div class="bg-white shadow-md rounded p-2 mb-2">
                        <div class="row">
                            <div class="col-12">
                                <adaa-form-dropdown
                                    controlName="cycleId"
                                    formControlName="cycleId"
                                    [clearable]="true"
                                    [invalid]="submitted && projectForm.get('cycleId')?.invalid!"
                                    [label]="'annual_meetings.cycles.single' | translate"
                                    [setValidator]="formValidation()!"
                                    [required]="true"
                                    [options]="cycles()"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6 col-xs-12">
                                <adaa-form-input
                                    controlName="nameAE"
                                    formControlName="nameAE"
                                    [invalid]="submitted && projectForm.get('nameAE')?.invalid!"
                                    [label]="'common.form.label.nameAE' | translate"
                                    [setValidator]="formValidation()!"
                                />
                            </div>
                            <div class="col-sm-6 col-xs-12">
                                <adaa-form-input
                                    controlName="nameEN"
                                    formControlName="nameEN"
                                    [invalid]="submitted && projectForm.get('nameEN')?.invalid!"
                                    [label]="'common.form.label.nameEN' | translate"
                                    [setValidator]="formValidation()!"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6 col-xs-12">
                                <adaa-form-input
                                    controlName="dscAE"
                                    formControlName="dscAE"
                                    [isTextArea]="true"
                                    [invalid]="submitted && projectForm.get('dscAE')?.invalid!"
                                    [label]="'common.form.label.descriptionAE' | translate"
                                    [setValidator]="formValidation()!"
                                />
                            </div>
                            <div class="col-sm-6 col-xs-12">
                                <adaa-form-input
                                    controlName="dscEN"
                                    formControlName="dscEN"
                                    [isTextArea]="true"
                                    [invalid]="submitted && projectForm.get('dscEN')?.invalid!"
                                    [label]="'common.form.label.descriptionEN' | translate"
                                    [setValidator]="formValidation()!"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6 col-xs-12">
                                <adaa-form-input-date
                                    controlName="startDate"
                                    formControlName="startDate"
                                    [invalid]="submitted && (projectForm.get('startDate')?.invalid! || projectForm.errors?.startDate_endDate_Invalid)"
                                    [label]="'national_projects.planned_start_date' | translate"
                                    [setValidator]="formValidation()!"
                                />
                            </div>
                            <div class="col-sm-6 col-xs-12">
                                <adaa-form-input-date
                                    controlName="endDate"
                                    formControlName="endDate"
                                    [invalid]="submitted && (projectForm.get('endDate')?.invalid! || projectForm.errors?.startDate_endDate_Invalid)"
                                    [label]="'national_projects.planned_end_date' | translate"
                                    [setValidator]="formValidation()!"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6 col-xs-12">
                                <adaa-form-input-date
                                    controlName="actualStartDate"
                                    formControlName="actualStartDate"
                                    [invalid]="
                                        submitted &&
                                        (projectForm.get('actualStartDate')?.invalid! || projectForm.errors?.actualStartDate_actualEndDate_Invalid)
                                    "
                                    [label]="'national_projects.actual_start_date' | translate"
                                    [setValidator]="formValidation()!"
                                />
                            </div>
                            <div class="col-sm-6 col-xs-12">
                                <adaa-form-input-date
                                    controlName="actualEndDate"
                                    formControlName="actualEndDate"
                                    [invalid]="
                                        submitted &&
                                        (projectForm.get('actualEndDate')?.invalid! || projectForm.errors?.actualStartDate_actualEndDate_Invalid)
                                    "
                                    [label]="'national_projects.actual_end_date' | translate"
                                    [setValidator]="formValidation()!"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="bg-white shadow-md rounded p-2 mb-2">
                        <div class="row">
                            <div class="col-sm-6 col-xs-12">
                                <adaa-form-dropdown
                                    controlName="entityId"
                                    formControlName="entityId"
                                    [clearable]="true"
                                    [invalid]="submitted && projectForm.get('entityId')?.invalid!"
                                    [label]="'national_projects.accountable_entity' | translate"
                                    [setValidator]="formValidation()!"
                                    [options]="entities()"
                                    (inputChanges)="entityChanged($event)"
                                />
                            </div>
                            <div class="col-sm-6 col-xs-12">
                                <adaa-form-dropdown
                                    controlName="owner"
                                    formControlName="owner"
                                    [clearable]="true"
                                    [invalid]="submitted && projectForm.get('owner')?.invalid!"
                                    [label]="'national_projects.entity_lead' | translate"
                                    [setValidator]="formValidation()!"
                                    [options]="owners()"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6 col-xs-12">
                                <adaa-form-dropdown-tree
                                    controlName="orgUnitId"
                                    formControlName="orgUnitId"
                                    [invalid]="submitted && projectForm.get('orgUnitId')?.invalid!"
                                    [label]="'common.form.label.organization_unit' | translate"
                                    [setValidator]="formValidation()!"
                                    [options]="orgUnits()"
                                />
                            </div>
                            <div class="col-sm-6 col-xs-12">
                                <adaa-form-dropdown
                                    controlName="projectCoordinator"
                                    formControlName="projectCoordinator"
                                    [clearable]="true"
                                    [invalid]="submitted && projectForm.get('projectCoordinator')?.invalid!"
                                    [label]="'national_projects.project_coordinator' | translate"
                                    [setValidator]="formValidation()!"
                                    [options]="owners()"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="bg-white shadow-md rounded p-2 mb-2">
                        <adaa-tp-pmo-desisions
                            [pmoDesisions]="pmoDesisions()"
                            [decisions]="projectForm.get('decisions')?.value"
                            (decisionsChanged)="projectForm.get('decisions')?.setValue($event)"
                        />
                    </div>

                    <div class="bg-white shadow-md rounded p-2 mb-2">
                        <adaa-contributing-entities
                            [id]="id() ?? 0"
                            [itemType]="constants.CONSTANT_NATIONAL_PROJECTS"
                            entitiesControlName="cEntities"
                            otherEntitiesControlName="cOtherEntities"
                            [contributingEntities]="projectForm.get('contributingEntities')?.value"
                            [entities]="entities()"
                            [otherEntities]="otherEntities()"
                            (contributingEntitiesChanged)="projectForm.get('contributingEntities')?.setValue($event)"
                        />
                    </div>

                    <div class="bg-white shadow-md rounded p-2 mb-2">
                        <div class="row">
                            <div class="col-12">
                                <adaa-form-dropdown-multi
                                    controlName="sectors"
                                    formControlName="sectors"
                                    [clearable]="true"
                                    [invalid]="submitted && projectForm.get('sectors')?.invalid!"
                                    [label]="'national_projects.national_sector.title' | translate"
                                    [setValidator]="formValidation()!"
                                    [options]="sectors()"
                                    (inputChanges)="sectorsChanged($event)"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="bg-white shadow-md rounded p-2 mb-2">
                        <div class="row">
                            <div class="col-12">
                                <adaa-comments
                                    noCommentsMessage="common.tables.no_info"
                                    cardTitle="national_projects.target_audience"
                                    titleToken="createdByUserName"
                                    dateToken="creationDate"
                                    messageToken="audience"
                                    classes="fw-bold fs-6"
                                    [showNumber]="true"
                                    [comments]="targetAudience"
                                    [isEditable]="true"
                                    editCardTitle="national_projects.add_target_audience"
                                    (commentAdded)="commentAdded($event, 'targetAudience')"
                                    (commentRemoved)="commentRemoved($event, 'targetAudience')"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="bg-white shadow-md rounded p-2 mb-2">
                        <div class="row">
                            <div class="col-12">
                                <adaa-comments
                                    noCommentsMessage="common.tables.no_info"
                                    cardTitle="national_projects.expected_impact"
                                    titleToken="createdByUserName"
                                    dateToken="creationDate"
                                    messageToken="impact"
                                    classes="fw-bold fs-6"
                                    [showNumber]="true"
                                    [comments]="expectedImpact"
                                    [isEditable]="true"
                                    editCardTitle="national_projects.add_expected_impact"
                                    (commentAdded)="commentAdded($event, 'expectedImpact')"
                                    (commentRemoved)="commentRemoved($event, 'expectedImpact')"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="bg-white shadow-md rounded p-2 mb-2">
                        <div class="row">
                            <div class="col-12">
                                <adaa-comments
                                    noCommentsMessage="common.tables.no_info"
                                    cardTitle="national_projects.comments"
                                    titleToken="createdByUserName"
                                    dateToken="creationDate"
                                    messageToken="comments"
                                    classes="fw-bold fs-6"
                                    [showNumber]="true"
                                    [comments]="comments"
                                    [isEditable]="true"
                                    editCardTitle="national_projects.add_comment"
                                    (commentAdded)="commentAdded($event, 'comments')"
                                    (commentRemoved)="commentRemoved($event, 'comments')"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tab-pane fade" id="tab-1" role="tabpanel" [ngClass]="{ 'show active': activeTab() === 1 }">
                    <div class="bg-white shadow-md rounded p-2 mb-2">
                        <adaa-milestone-list
                            [projectId]="id() ? +id()! : undefined"
                            [milestones]="projectForm.get('milestones')?.value ? projectForm.get('milestones')!.value : []"
                            [projectStartDate]="projectForm.get('startDate')?.value ? projectForm.get('startDate')!.value : 0"
                            [projectEndDate]="projectForm.get('endDate')?.value ? projectForm.get('endDate')!.value : 0"
                            [entities]="entities()"
                            [otherEntities]="otherEntities()"
                            [parentEntities]="filteredEntites"
                            [parentOtherEntities]="filteredOtherEntites"
                            [parentEntityId]="projectForm.get('entityId')?.value"
                            [parentSectors]="filteredSectors"
                            [enableTeamMembers]="false"
                            (milestonesChanged)="projectForm.get('milestones')?.setValue($event)"
                        />
                    </div>
                </div>

                <div class="tab-pane fade" id="tab-2" role="tabpanel" [ngClass]="{ 'show active': activeTab() === 2 }">
                    <div class="bg-white shadow-md rounded p-2 mb-2">
                        <adaa-attachments-list
                            [attachments]="projectForm.get('attachments')?.value ?? []"
                            (attachmentsChanged)="projectForm.get('attachments')?.setValue($event)"
                        />
                    </div>
                </div>

                <div class="tab-pane fade" id="tab-3" role="tabpanel" [ngClass]="{ 'show active': activeTab() === 3 }">
                    <div class="bg-white shadow-md rounded p-2 mb-2">
                        <div class="row">
                            <div class="col-12">
                                <adaa-form-dropdown
                                    controlName="ntKpiId"
                                    formControlName="ntKpiId"
                                    [searchable]="true"
                                    [clearable]="true"
                                    [invalid]="submitted && projectForm.get('ntKpiId')?.invalid!"
                                    [label]="'breadcrumbs.ntkpi_module.list' | translate"
                                    [setValidator]="formValidation()!"
                                    [options]="ntKpis()"
                                />
                            </div>
                        </div>
                    </div>

                    <adaa-national-strategies-selector
                        #nationalStrategies
                        [enableMainSelection]="false"
                        [isTpPage]="true"
                        [govDirectionData]="govDirections()"
                        [subGovDirectionData]="subGovDirections()"
                    />
                </div>
            </adaa-form-steps>
        </div>
    </div>

    <adaa-float-action />
</ng-template>
