<adaa-entity-plan-change-detection
    [template]="page"
    [request$]="fetchKpiCard()"
    [ignoreEntityDetection]="isPmoAndE_NT_DTKPI"
    [entityUserCheck]="true"
    [redirectionUrl]="'/console/kpi/' + type()"
    (continue)="initPage($event)"
/>

<ng-template #page>
    <adaa-float-action />

    @let kpi = kpiData();

    <div id="kpi-view-page" class="d-block position-relative w-100 pb-4 pt-2">
        @if (hasMissingNonBaselineTargets()) {
            <span
                class="badge bg-warning w-auto text-white rounded-pill border-danger border-1 shadow-sm mb-1 kpi-warns"
                [dir]="languageService.direction()"
            >
                <i class="fa fa-warning text-warning"></i> {{ defineTargetTooltip }}
            </span>
        }

        @if (kpiHasAuditIssues()) {
            <span
                class="badge bg-warning text-white rounded-pill border-danger border-1 shadow-sm mb-1 kpi-warns"
                [dir]="languageService.direction()"
            >
                <i class="fa fa-warning text-warning"></i> {{ auditIssuesTooltip }}
            </span>
        }

        <section id="kpi-view-page-title" class="d-block position-relative w-100 pb-2">
            <span class="d-block w-100 fw-bold fs-4">
                {{ kpiData() | translateToken: "name" }}
                @let data = skpiData();
                @if (data) {
                    @if (data.linkedKpiId) {
                        <h6 class="d-inline w-auto px-2 text-center m-0">
                            <span class="badge bg-primary text-white rounded-1 mx-1">
                                {{ data | translateToken: "linkedKpiTypeName" }}
                            </span>
                        </h6>
                    }
                }

                @let opm = opmData();
                @if (opm?.priorityHigh! === "Y" && !useNewDS()) {
                    <h6 class="d-inline w-auto px-2 text-center m-0">
                        <span class="badge bg-primary text-white rounded-1 mx-1">
                            {{ "initiatives.high_priority" | translate }}
                        </span>
                    </h6>
                }
            </span>
        </section>

        <section id="kpi-view-page-content" class="d-block position-relative w-100 py-2">
            <div class="row d-flex flex-wrap">
                <!-- right-side -->
                <div class="col-sm-8 col-12 page--right-side">
                    <!-- section:labels -->
                    <div class="w-100 d-flex flex-wrap align-content-center align-items-center gap-1">
                        @if (kpiType(); as kpiType) {
                            @if (!isSRVKPI()) {
                                <h3>
                                    <span class="badge text-dark bg-white rounded-1 fs-6 shadow-sm border border-1 border-secondary-subtle p-2">
                                        {{ kpiService.getTableTitle(kpiType, "list", true) | translate }}
                                    </span>
                                </h3>
                            }
                        }
                        @if (isNTKPI() || isDTKPI() || isUAE() || isNKPI()) {
                            @if (isDefined(kpiData()?.sponsorEntityId)) {
                                <h3>
                                    <span class="badge text-dark bg-white rounded-1 fs-6 shadow-sm border border-1 border-secondary-subtle p-2">
                                        {{ kpiData() | translateToken: "sponsorEntityName" }}
                                    </span>
                                </h3>
                            }
                        }
                        @if (kpiData()?.orgUnitId) {
                            <h3>
                                <span class="badge text-dark bg-white rounded-1 fs-6 shadow-sm border border-1 border-secondary-subtle p-2">
                                    {{ kpiData() | translateToken: "orgUnitName" }}
                                </span>
                            </h3>
                        }
                        @if (kpiData()?.owner) {
                            <h3>
                                <span class="badge text-dark bg-white rounded-1 fs-6 shadow-sm border border-1 border-secondary-subtle p-2">
                                    {{ kpiData() | translateToken: "ownerName" }}
                                </span>
                            </h3>
                        }
                        @if (isEKPI()) {
                            <h3>
                                <span class="badge text-dark bg-white rounded-1 fs-6 shadow-sm border border-1 border-secondary-subtle p-2">
                                    {{ ekpiData() | translateToken: "pillarName" }}
                                </span>
                            </h3>

                            <h3>
                                <span class="badge text-dark bg-white rounded-1 fs-6 shadow-sm border border-1 border-secondary-subtle p-2">
                                    {{ ekpiData() | translateToken: "enablerEntityName" }}
                                </span>
                            </h3>
                        }
                    </div>

                    <!-- section:last-updated-on -->
                    <section class="w-100 mb-4 d-flex mb-2">
                        <small>
                            {{ "nkpi.last_update" | translate }},
                            {{ kpiData()?.updateTS! | dubaiTime | date: datetimeFormat }}
                            {{ "common.form.label.by" | translate }}
                            @if (kpiData()?.updateUserNameAE || kpiData()?.updateUserNameEN) {
                                {{ kpiData() | translateToken: "updateUserName" : true }}
                            } @else {
                                {{ kpiData()?.updateUser }}
                            }
                        </small>
                    </section>

                    @if (kpiData() && kpiType()) {
                        <adaa-notification-alert
                            [itemId]="kpiData()!.id"
                            [itemType]="kpiType()!"
                            (actionClicked)="router.navigateByUrl('/communications/' + $event.id)"
                        />
                    }

                    <!-- section:description -->
                    <div class="card col-12 rounded-1 bg-white px-3 mb-3 mt-4">
                        <div class="card-title px-0 py-2 mb-0">
                            <h5 class="m-0 mb-1 fw-bold">
                                {{ "common.form.label.description" | translate }}
                            </h5>
                        </div>

                        <div class="card-body px-0 pt-0 pb-2">
                            <adaa-read-more [maxLength]="250" [text]="kpiData() | translateToken: 'dsc' : true" />
                        </div>
                    </div>

                    <!-- section:ntkpi-dtkpi-vision-info -->
                    @if (isNationalOrUAEKPI() || isLinkedSKPI()) {
                        <div class="card col-12 rounded-1 bg-white px-3 mb-3 mt-4">
                            <div class="card-body px-0 pt-3 pb-2">
                                <p>
                                    <b class="fw-bolder">{{ govDirTranslation() | translate }}: </b>
                                    {{ kpiData() | translateToken: "govDirectionName" }}
                                </p>

                                <p>
                                    <b class="fw-bolder">{{ subGovDirTranslation() | translate }}: </b>
                                    {{ kpiData() | translateToken: "subDirectionName" }}
                                </p>

                                @if (isNTKPI()) {
                                    <p>
                                        <b class="fw-bolder">{{ "directions.sub_pillar" | translate }}: </b>
                                        {{ kpiData() | translateToken: "auxiliaryDirectionName" }}
                                    </p>
                                }
                            </div>
                        </div>
                    }

                    @if (isLinkedMOKPI()) {
                        <!-- section:description -->
                        <div class="card col-12 rounded-1 bg-white px-3 mb-3 mt-4">
                            <div class="card-body px-0 pt-3 pb-2 mb-0">
                                <p>
                                    <b class="fw-bolder">{{ "national_projects.singular_title" | translate }}: </b>
                                    <a class="link-info" [routerLink]="['/national-projects-dashboard/projects/' + kpiData()?.nationalProjectId]">
                                        {{ kpiData() | translateToken: "nationalProjectName" }}
                                    </a>
                                </p>

                                @if (isDefined(kpiData()?.transformationalTarget)) {
                                    <p>
                                        <b class="fw-bolder">{{ "transformational_target" | translate }}: </b>
                                        <a
                                            class="link-info"
                                            [routerLink]="[
                                                '/console',
                                                'national-project',
                                                'transformational-targets',
                                                kpiData()?.transformationalTarget?.id,
                                            ]"
                                        >
                                            {{ kpiData()?.transformationalTarget | translateToken: "name" }}
                                        </a>
                                    </p>
                                }

                                @if (isMTKPI()) {
                                    <p>
                                        <b class="fw-bolder">{{ "mtkpi.projectMilestoneId" | translate }}: </b>
                                        <a
                                            class="link-info"
                                            [routerLink]="['/national-projects-dashboard', 'milestones', kpiData()?.projectMilestoneId]"
                                        >
                                            {{ kpiData() | translateToken: "projectMilestoneName" }}
                                        </a>
                                    </p>
                                }
                            </div>
                        </div>
                    }

                    @if ((isSKPI() || isOPM() || isSRVKPI() || isEKPI() || isMOKPI()) && isDefined(skpiData().objectiveType)) {
                        <div class="card col-12 rounded-1 bg-white px-3 mb-3 mt-4">
                            <div class="card-title px-0 py-2 mb-0">
                                <h5 class="m-0 mb-1 fw-bold">
                                    @if (useNewDS()) {
                                        @switch (skpiData().objectiveType) {
                                            @case (objectiveTypes.main) {
                                                {{ "breadcrumbs.objectives.main" | translate }}
                                            }
                                            @case (objectiveTypes.strategic) {
                                                {{ "breadcrumbs.objectives.strategic" | translate }}
                                            }
                                            @case (objectiveTypes.enabler) {
                                                {{ "objectives.enabler_objective" | translate }}
                                            }
                                        }
                                    } @else {
                                        @if (skpiData().objectiveType === objectiveTypes.enabler) {
                                            {{ "objectives.enabler_objective" | translate }}
                                        } @else {
                                            {{ "skpi.objective" | translate }}
                                        }
                                    }
                                </h5>
                            </div>

                            <div class="card-body px-0 pt-0 pb-2">
                                <a
                                    class="link-info fw-bold"
                                    [routerLink]="getObjectiveUrl()"
                                    [queryParams]="{ periodId: selectedPeriod()?.period?.id }"
                                >
                                    {{ skpiData() | translateToken: "objectiveName" }}
                                </a>
                            </div>
                        </div>
                    }

                    @if (isSRVKPI()) {
                        <div class="card col-12 rounded-1 bg-white px-3 mb-3 mt-4">
                            <div class="card-title px-0 py-2 mb-0">
                                <h5 class="m-0 mb-1 fw-bold">
                                    <span>{{ "srvkpi.mainService" | translate }}</span>

                                    @if (mainServicePerformance(); as data) {
                                        @if (data.performance) {
                                            <span class="badge mx-2 rounded-1" [ngClass]="getMainServicePerformanceColor()">
                                                {{ data.performance | number: "1.0-2" }}%
                                            </span>
                                        }
                                    }
                                </h5>
                            </div>

                            <div class="card-body px-0 pt-0 pb-2">
                                <a
                                    class="link-info fw-bold"
                                    [routerLink]="['/console/services/main-service', srvkpiData().mainServiceId]"
                                    [queryParams]="{ periodId: periodId() }"
                                >
                                    {{ srvkpiData() | translateToken: "mainServiceName" }}
                                </a>
                            </div>
                        </div>

                        <div class="card col-12 rounded-1 bg-white px-3 mb-3 mt-4">
                            <div class="card-title px-0 py-2 mb-0">
                                <h5 class="m-0 mb-1 fw-bold d-flex align-items-center">
                                    <span>{{ "srvkpi.auxVarService" | translate }}</span>

                                    @if (subServicePerformance(); as data) {
                                        @if (data.performance) {
                                            <span class="badge mx-2 rounded-1" [ngClass]="getSubServicePerformanceColor()">
                                                {{ data.performance | number: "1.0-2" }}%
                                            </span>
                                        }
                                    }
                                </h5>
                            </div>

                            <div class="card-body px-0 pt-0 pb-2">
                                <a class="link-info fw-bold" [routerLink]="gotoSubService()" [queryParams]="{ periodId: periodId() }">
                                    {{ srvkpiData() | translateToken: "subServiceName" }}
                                </a>
                            </div>
                        </div>
                    }

                    @if (isOPM()) {
                        <div class="card col-12 rounded-1 bg-white px-3 mb-3 mt-4">
                            <div class="card-title px-0 py-2 mb-0">
                                <h5 class="m-0 mb-1 fw-bold">
                                    @if (useNewDS()) {
                                        {{ initiativeTypeText(opmData().initiativeType)! | translate }}
                                    } @else {
                                        {{ "initiatives.title" | translate }}
                                    }
                                </h5>
                            </div>

                            <div class="card-body px-0 pt-0 pb-2">
                                @if (useNewDS()) {
                                    <a class="link-info fw-bold" [routerLink]="initiativeTypeLink(opmData().initiativeType, opmData().initiativeId)">
                                        {{ opmData() | translateToken: "initiativeName" }}
                                    </a>
                                } @else {
                                    <a
                                        class="link-info fw-bold"
                                        [routerLink]="'/console/initiatives/' + opmData().initiativeId"
                                        [queryParams]="{ periodId: periodId() }"
                                    >
                                        {{ opmData() | translateToken: "initiativeName" }}
                                    </a>
                                }
                            </div>
                        </div>

                        @if (!useNewDS()) {
                            <div class="card col-12 rounded-1 bg-white px-3 mb-3 mt-4">
                                <div class="card-title px-0 py-2 mb-0">
                                    <h5 class="m-0 mb-1 fw-bold">
                                        {{ "activities.activity" | translate }}
                                    </h5>
                                </div>

                                <div class="card-body px-0 pt-0 pb-2">
                                    <a
                                        class="link-info fw-bold"
                                        [routerLink]="'/console/activities/' + opmData().activityId"
                                        [queryParams]="{ periodId: periodId() }"
                                    >
                                        {{ opmData() | translateToken: "activityName" }}
                                    </a>
                                </div>
                            </div>
                        }
                    }

                    @if (showEntitySelection()) {
                        <div class="card col-12 rounded-1 bg-white px-3 mb-3 mt-4">
                            <div class="card-title px-0 py-2 mb-0">
                                <h5 class="m-0 mb-1 fw-bold">
                                    {{ "kpi.entity" | translate }}
                                </h5>
                            </div>

                            <div class="card-body px-0 pt-0 pb-2">
                                <adaa-form-dropdown
                                    inputPlaceholder="kpi.gov_data"
                                    [clearable]="true"
                                    [searchable]="true"
                                    [options]="affectedEntities()"
                                    (inputChanges)="affectedEntityChanged($event)"
                                    (onClear)="affectedEntityChanged(null)"
                                />
                            </div>
                        </div>
                    }

                    <!-- section:period-slider -->
                    @if (skpiData(); as data) {
                        <adaa-period-slider-variant
                            [showMonthsAsNumbers]="true"
                            [periodId]="periodId() ? +periodId()! : undefined"
                            [activePeriod]="selectedPeriod()"
                            [kpiType]="data.linkedKpiType || data.kpiType"
                            [isEKPI]="isEKPI()"
                            [_frequency]="kpiFrequency()"
                            [_startDate]="data.startDate | dubaiTime"
                            [_endDate]="data.endDate | dubaiTime"
                            (selectPeriod)="selectPeriod($event)"
                            (selectedChartFrequencyCycle)="selectPeriodCycle($event)"
                        />
                    }

                    <!-- section:kpi performance -->
                    @if (skpiData(); as data) {
                        <div class="card col-12 rounded-1 bg-white px-3 mb-2 pt-1 mt-3">
                            <adaa-view-card-performance
                                [endDate]="data.endDate"
                                [startDate]="data.startDate"
                                [entityId]="currentEntityId()!"
                                [entityMapId]="mapId()"
                                [itemId]="auxKpiId()"
                                [objectType]="data.kpiType"
                                [includeLegacy]="includeLegacy()"
                                [periodId]="selectedPeriod()?.period?.id!"
                                [csvChartInfo]="kpiData() | translateToken: 'name'"
                            />
                        </div>
                    }

                    <!-- section:kpi performance -->
                    @if (skpiData(); as data) {
                        <div class="card col-12 rounded-1 bg-white px-3 mb-2 pt-1 mt-3 actual">
                            <adaa-kpi-performance
                                [measureUnit]="data.measurementUnit"
                                [frequency]="kpiFrequency()"
                                [isBounded]="isBounded()"
                                [style.height]="'340px'"
                                [periodCycle]="periodCycle()!"
                                [entityId]="currentEntityId()!"
                                [itemId]="auxKpiId()"
                                [kpiType]="data.kpiType"
                                [includeLegacy]="includeLegacy()"
                                [periodId]="selectedPeriod()?.period?.id!"
                                [csvChartInfo]="kpiData() | translateToken: 'name'"
                            />
                        </div>
                    }

                    <!-- section: table-values -->
                    @if (skpiData(); as data) {
                        @let values = tableValues();
                        @if (isDefined(data) && values.length > 0) {
                            <div #normalTableValues>
                                <adaa-normal-table-values
                                    [isMasterEkpi]="showEntitySelection()"
                                    [formula]="data.formula"
                                    [isBounded]="isBounded()"
                                    [auditInfo]="auditInfo()"
                                    [kpiType]="data.linkedKpiType || data.kpiType"
                                    [periodCycle]="periodCycle()!"
                                    [periodId]="selectedPeriod()?.period?.id!"
                                    [kpiId]="currentKpiId()"
                                    [measurementUnit]="data.measurementUnit"
                                    [frequency]="kpiFrequency()"
                                    [data]="values"
                                    [showLegacyCheckbox]="!!data.legacyKpi && useNewDS()"
                                    [includeLegacyKpi]="includeLegacy()"
                                    [metricsData]="metricList()"
                                    (setIncludeLegacyKpi)="includeLegacy.set($event); refreshKpiTableValues()"
                                    (printClicked)="printContent()"
                                />
                            </div>
                        }
                    }

                    @if (kpiData(); as data) {
                        @let benchmarks = data?.benchmarks ?? [];
                        @if (benchmarks.length > 0) {
                            <adaa-kpi-benchmark-view-card [data]="benchmarks" />
                        }
                    }

                    <!-- section:other sections -->
                    @if (kpiData(); as data) {
                        <div class="card col-12 rounded-1 bg-white px-3 mb-2 pt-1 mt-3">
                            <div class="card-title px-0 py-2 mb-0">
                                <h5 class="m-0 mb-1 fw-bold">
                                    {{ "kpi.status_analysis" | translate }}
                                </h5>
                            </div>

                            <div class="card-body px-0 pt-1 pb-2">
                                <adaa-read-more [maxLength]="250" [text]="dataentryInfo()?.analysisAE ?? dataentryInfo()?.analysisEN" />
                            </div>
                        </div>

                        <div class="card col-12 rounded-1 bg-white px-3 mb-2 pt-1 mt-3">
                            <div class="card-title px-0 py-2 mb-0">
                                <h5 class="m-0 mb-1 fw-bold">
                                    {{ "kpi.areas_of_improvement" | translate }}
                                </h5>
                            </div>

                            <div class="card-body px-0 pt-1 pb-2">
                                <adaa-read-more
                                    [maxLength]="250"
                                    [text]="dataentryInfo()?.areasOfImprovementsAE ?? dataentryInfo()?.areasOfImprovementsEN"
                                />
                            </div>
                        </div>

                        <div class="card col-12 rounded-1 bg-white px-3 mb-2 pt-1 mt-3">
                            <div class="card-title px-0 py-2 mb-0">
                                <h5 class="m-0 mb-1 fw-bold">
                                    {{ "kpi.recommendations" | translate }}
                                </h5>
                            </div>

                            <div class="card-body px-0 pt-1 pb-2">
                                <adaa-read-more [maxLength]="250" [text]="dataentryInfo()?.recommendationsAE ?? dataentryInfo()?.recommendationsEN" />
                            </div>
                        </div>

                        <div class="card col-12 rounded-1 bg-white px-3 mb-2 pt-1 mt-3">
                            <div class="card-title px-0 py-2 mb-0">
                                <h5 class="m-0 mb-1 fw-bold">
                                    {{ "kpi.pmo_notes" | translate }}
                                </h5>
                            </div>

                            <div class="card-body px-0 pt-1 pb-2">
                                <adaa-read-more [maxLength]="250" [text]="dataentryInfo()?.pmoNotesAE ?? dataentryInfo()?.pmoNotesEN" />
                            </div>
                        </div>

                        @if (isNTKPI()) {
                            <div class="card col-12 rounded-1 bg-white px-3 mb-2 pt-1 mt-3">
                                <div class="card-title px-0 py-2 mb-0">
                                    <h5 class="m-0 mb-1 fw-bold">
                                        {{ "ntkpi_card.entity_leader_decision_title" | translate }}
                                    </h5>
                                </div>

                                @if (isDefined(ntkpiData().entityLeadOptionRequests) && ntkpiData().entityLeadOptionRequests!.length > 0) {
                                    <div class="card-body px-0 pt-1 pb-2">
                                        @for (item of ntkpiData().entityLeadOptionRequests; track item.id) {
                                            <span class="mx-1">{{ item | translateToken: "optionName" }}</span>
                                        }
                                    </div>
                                }
                            </div>

                            <div class="card col-12 rounded-1 bg-white px-3 mb-2 pt-1 mt-3">
                                <div class="card-title px-0 py-2 mb-0">
                                    <h5 class="m-0 mb-1 fw-bold">
                                        {{ "ntkpi.explanation_of_the_measurement_mechanism" | translate }}
                                    </h5>
                                </div>

                                <div class="card-body px-0 pt-1 pb-2">
                                    <adaa-read-more [maxLength]="210" [text]="ntkpiData().measurementMechanism" />
                                </div>
                            </div>
                        }

                        <div class="card col-12 rounded-1 bg-white px-3 pt-1 mt-3">
                            <div class="card-title px-0 py-2 mb-0">
                                <h5 class="m-0 mb-1 fw-bold">
                                    {{ "common.form.label.comments" | translate }}
                                </h5>
                            </div>

                            <div class="card-body px-0 pt-1 pb-2">
                                <adaa-read-more [maxLength]="250" [text]="data?.commentsAE ?? data?.commentsEN" />
                            </div>
                        </div>

                        @if (isNTKPI()) {
                            <div class="card col-12 rounded-1 bg-white px-3 mb-2 pt-1 mt-3">
                                <div class="card-title px-0 py-2 mb-0">
                                    <h5 class="m-0 mb-1 fw-bold">
                                        {{ "ntkpi.improvement_plan" | translate }}
                                    </h5>
                                </div>

                                @let improvementPlanList = improvementPlan();

                                <div class="card-body px-0 pt-1 pb-2">
                                    @for (item of improvementPlanList; let $i = $index; track $i) {
                                        <adaa-improvement-plan [index]="$i + 1" [data]="item" />
                                    }
                                </div>
                            </div>
                        }
                    }
                </div>

                <!-- left-side -->
                <div class="col-sm-4 col-12 page--left-side">
                    <div class="card col-12 rounded-1 bg-white p-2">
                        <!-- section: objective status -->
                        <div class="w-100 d-flex position-relative align-content-center align-items-stretch">
                            @if (kpiData(); as data) {
                                <adaa-favorite-button [itemId]="+data.id" [objectType]="data.kpiType" />
                                <adaa-object-data-status
                                    [entityId]="currentEntityId()!"
                                    [itemId]="auxKpiId()"
                                    [objectType]="data.kpiType"
                                    [periodId]="selectedPeriod()?.period?.id"
                                    (getObjectDataStatus)="getDataStatus($event)"
                                />
                            }
                        </div>

                        <!-- section:performance indicators -->
                        <div class="w-100 d-flex justify-content-between position-relative mt-1 pt-3 pb-2 kpi-performance">
                            <div class="px-1 py-3 rounded shadow" [style.background-color]="kpiPerfColor()">
                                <span class="d-block w-100 text-center fw-bold fs-3 mb-4 text-white">
                                    {{ dataStatus()?.performance | percentageValue }}
                                </span>

                                <span class="d-block w-100 text-center fw-bold text-white">
                                    {{ "box_status.current" | translate }}
                                </span>
                            </div>

                            <div class="px-1 py-3 rounded shadow" [style.background-color]="kpiAnnualPerfColor()">
                                <span class="d-block w-100 text-center fw-bold fs-3 mb-4 text-white">
                                    {{ kpiAnnualPerformance()?.score | percentageValue }}
                                </span>

                                <span class="d-block w-100 text-center fw-bold text-white">
                                    {{ "box_status.annual_performance" | translate }}
                                </span>
                            </div>
                        </div>

                        <!-- section:audit issues -->

                        <!-- section:kpi ref-code -->
                        @if (kpiData(); as data) {
                            <div class="w-100 d-flex justify-content-between flex-wrap position-relative mt-2 py-2 kpi-details kpi-details--light">
                                <!-- detail:refCode -->
                                <div class="w-100 d-flex align-content-center kpi-details--item">
                                    <span class="d-block w-50 fw-bold px-2">
                                        {{ "common.form.label.refcode" | translate }}
                                    </span>

                                    <span class="d-block w-50 px-2 text-primary text-end">
                                        @if (data.refCode) {
                                            {{ data.refCode }}
                                        } @else {
                                            {{ "notification.warning.missing_data" | translate }}
                                        }
                                    </span>
                                </div>
                            </div>
                        }

                        <!-- section:kpi audit -->
                        @if (kpi) {
                            @let auditInfo = yearAuditInfo();
                            @if (auditInfo && auditInfo.hasAuditIssue) {
                                <div
                                    class="w-100 d-flex justify-content-between flex-wrap position-relative mt-2 py-2 kpi-details kpi-details--light"
                                >
                                    <!-- detail:auditIssues -->
                                    <div class="w-100 d-flex align-items-center align-content-center kpi-details--item">
                                        <span class="d-block w-auto flex-shrink-0 fw-bold px-2">
                                            <i class="fa fa-warning fa-2x text-warning"></i>
                                        </span>

                                        <span class="d-block w-100 px-2">
                                            <ol class="list-group bg-white p-0">
                                                <li
                                                    class="list-group-item list-group-item-light d-flex align-items-start gap-3"
                                                    [dir]="languageService.direction()"
                                                >
                                                    <span class="w-75 fw-bold" [dir]="languageService.direction()">
                                                        {{ "data_entry.annual_target" | translate }}:
                                                    </span>

                                                    @if (isDefined(auditInfo?.auditedTarget)) {
                                                        <b class="text-primary fw-bolder w-25 audit-text-value" [dir]="'ltr'">
                                                            {{ auditInfo?.auditedTarget | percentageValue }}
                                                        </b>
                                                    } @else {
                                                        <b class="text-primary fw-bolder w-25 audit-text-value" [dir]="'ltr'">
                                                            {{ auditInfo?.annualTarget | percentageValue }}
                                                        </b>
                                                    }
                                                </li>

                                                <li
                                                    class="list-group-item list-group-item-light d-flex align-items-start gap-3"
                                                    [dir]="languageService.direction()"
                                                >
                                                    <span class="w-75 fw-bold" [dir]="languageService.direction()">
                                                        {{ "data_entry.annual_actual" | translate }}:
                                                    </span>

                                                    <b class="text-primary fw-bolder w-25 audit-text-value" [dir]="'ltr'">
                                                        {{ auditInfo?.annualActual | percentageValue }}
                                                    </b>
                                                </li>

                                                <li
                                                    class="list-group-item list-group-item-light d-flex align-items-start gap-3"
                                                    [dir]="languageService.direction()"
                                                >
                                                    <span class="w-75 fw-bold" [dir]="languageService.direction()">
                                                        {{ "data_entry.audited_annual_actual" | translate }}:
                                                    </span>

                                                    <b class="text-primary fw-bolder w-25 audit-text-value" [dir]="'ltr'">
                                                        {{ auditInfo?.auditedActual | percentageValue }}
                                                    </b>
                                                </li>
                                            </ol>
                                        </span>
                                    </div>
                                </div>
                            }
                        }

                        <!-- section:very important / (Classification / Perspective) -->
                        @if (isSRVKPI()) {
                            @if (srvkpiData().veryImportant === "Y" && isPmoUser()) {
                                <div
                                    class="w-100 d-flex justify-content-between flex-wrap position-relative mt-2 py-2 kpi-details kpi-details--light"
                                >
                                    <!-- detail:classification -->
                                    @if (srvkpiData().classificationAE || srvkpiData().classificationEN) {
                                        <div class="w-100 d-flex align-content-center kpi-details--item">
                                            <span class="d-block w-50 fw-bold px-2">
                                                {{ "srvkpi.veryImportant" | translate }}
                                            </span>

                                            <span class="d-block w-50 px-2 text-primary text-end">
                                                {{ "common.form.label.yes" | translate }}
                                            </span>
                                        </div>
                                    }

                                    <!-- detail:perspective -->
                                    @if (srvkpiData().perspectiveAE || srvkpiData().perspectiveEN) {
                                        <div class="w-100 d-flex align-content-center kpi-details--item">
                                            <span class="d-block w-50 fw-bold px-2">
                                                {{ "srvkpi.reason_veryImportant" | translate }}
                                            </span>

                                            <span class="d-block w-50 px-2 text-primary text-end">
                                                {{ srvkpiData().veryImportantReasonAE }}
                                            </span>
                                        </div>
                                    }
                                </div>
                            }

                            <div class="w-100 d-flex justify-content-between flex-wrap position-relative mt-2 py-2 kpi-details kpi-details--light">
                                <!-- detail:classification -->
                                @if (srvkpiData().classificationAE || srvkpiData().classificationEN) {
                                    <div class="w-100 d-flex align-content-center kpi-details--item">
                                        <span class="d-block w-50 fw-bold px-2">
                                            {{ "srvkpi.classification" | translate }}
                                        </span>

                                        <span class="d-block w-50 px-2 text-primary text-end">
                                            {{ srvkpiData() | translateToken: "classification" }}
                                        </span>
                                    </div>
                                }

                                <!-- detail:perspective -->
                                @if (srvkpiData().perspectiveAE || srvkpiData().perspectiveEN) {
                                    <div class="w-100 d-flex align-content-center kpi-details--item">
                                        <span class="d-block w-50 fw-bold px-2">
                                            {{ "srvkpi.perspective" | translate }}
                                        </span>

                                        <span class="d-block w-50 px-2 text-primary text-end">
                                            {{ srvkpiData() | translateToken: "perspective" }}
                                        </span>
                                    </div>
                                }
                            </div>
                        }

                        <!-- section:kpi scope measurement -->
                        <div class="w-100 d-flex justify-content-between flex-wrap position-relative mt-2 py-2 kpi-details kpi-details--light">
                            @let linkedKpiId = skpiData().linkedKpiId;
                            @if (linkedKpiId) {
                                <div class="w-100 d-flex align-content-center kpi-details--item">
                                    <span class="d-block w-50 fw-bold px-2">
                                        {{ "kpi.linked_kpi_type" | translate }}
                                    </span>

                                    <span class="d-block w-50 px-2 text-primary text-end">
                                        {{ skpiData() | translateToken: "linkedKpiTypeName" }}
                                    </span>
                                </div>
                            }

                            <div class="w-100 d-flex align-content-center kpi-details--item">
                                <span class="d-block w-50 fw-bold px-2">
                                    {{ "nkpi.frequency" | translate }}
                                </span>

                                <span class="d-block w-50 px-2 text-primary text-end">
                                    {{ kpiData() | translateToken: "frequency" }}
                                </span>
                            </div>

                            <div class="w-100 d-flex align-content-center kpi-details--item">
                                <span class="d-block w-50 fw-bold px-2">
                                    {{ "nkpi.measurement_unit" | translate }}
                                </span>

                                <span class="d-block w-50 px-2 text-primary text-end">
                                    {{ kpiData() | translateToken: "measurementUnit" }}
                                </span>
                            </div>

                            <div class="w-100 d-flex align-content-center kpi-details--item">
                                <span class="d-block w-50 fw-bold px-2">
                                    {{ "nkpi.measurement_unit_dsc" | translate }}
                                </span>

                                <span class="d-block w-50 px-2 text-primary text-end">
                                    {{ kpiData()?.measurementUnitDscAE ?? kpiData()?.measurementUnitDscEN }}
                                </span>
                            </div>

                            @if (hasMaxRank()) {
                                <div class="w-100 d-flex align-content-center kpi-details--item">
                                    <span class="d-block w-50 fw-bold px-2">
                                        {{ "nkpi.max_rank" | translate }}
                                    </span>

                                    <span class="d-block w-50 px-2 text-primary text-end">
                                        {{ kpiData()?.maxRank }}
                                    </span>
                                </div>
                            }

                            <div class="w-100 d-flex align-content-center kpi-details--item">
                                <span class="d-block w-50 fw-bold px-2">
                                    {{ "kpi.calculation_type" | translate }}
                                </span>

                                <span class="d-block w-50 px-2 text-primary text-end">
                                    {{ getConstants.formula(kpiData()?.formula!) | translateToken: "name" }}
                                </span>
                            </div>

                            <div class="w-100 d-flex align-content-center kpi-details--item">
                                <span class="d-block w-50 fw-bold px-2">
                                    {{ "kpi.trend" | translate }}
                                </span>

                                <span class="d-block w-50 px-2 text-primary text-end">
                                    {{ getConstants.trend(kpiData()?.trend!) | translateToken: "name" }}
                                </span>
                            </div>

                            @if (isMOKPI()) {
                                <div class="w-100 d-flex align-content-center kpi-details--item">
                                    <span class="d-block w-50 fw-bold px-2">
                                        {{ "mokpi_type" | translate }}
                                    </span>

                                    <span class="d-block w-50 px-2 text-primary text-end">
                                        {{ kpiData() | translateToken: "mainOutcomeTypeName" }}
                                    </span>
                                </div>
                            }

                            <div class="w-100 d-flex align-content-center kpi-details--item">
                                <span class="d-block w-50 fw-bold px-2">
                                    {{ "kpi.formula" | translate }}
                                </span>

                                <span class="d-block w-50 px-2 text-primary text-end">
                                    <span class="pb-2" [dir]="'ltr'">
                                        {{ kpiData()?.formula !== "NOFORMULAV" ? kpiData()?.formula : ("kpi.NOFORMULAV" | translate) }}
                                    </span>

                                    @if (kpiData()?.formula !== "NOFORMULAV") {
                                        @for (metric of metricList(); track metric.id) {
                                            <span class="d-block py-1 text-primary text-end">
                                                @if (languageService.current() === lang.English) {
                                                    {{ metric.metricName }} - {{ metric.metricDescription }}
                                                } @else if (languageService.current() === lang.Arabic) {
                                                    {{ metric.metricDescription }} - {{ metric.metricName }}
                                                }
                                            </span>
                                        }
                                    }
                                </span>
                            </div>

                            @if (isEKPI()) {
                                <div class="w-100 d-flex align-content-center kpi-details--item">
                                    <span class="d-block w-50 fw-bold px-2">
                                        {{ "ekpi.gov_metric_agg_method" | translate }}
                                    </span>

                                    <span class="d-block w-50 px-2 text-primary text-end">
                                        {{ getConstants.metricAgg(kpiData()?.ekpiMetricRollupMethod!) | translateToken: "name" }}
                                    </span>
                                </div>
                            }

                            <!-- YTP calc-->
                            @if (isFrequencyAnnual()) {
                                <div class="w-100 d-flex align-content-center kpi-details--item">
                                    <span class="d-block w-50 fw-bold px-2">
                                        {{ "kpi.year_to_period" | translate }}
                                    </span>

                                    @if (kpiData()?.formula !== "NOFORMULAV" && kpiData()?.hasTarget === "N") {
                                        <span class="d-block w-50 px-2 text-primary text-end">
                                            {{ "nkpi.target" | translate }} - {{ getConstants.ytp(kpiData()?.ytpCalc!) | translateToken: "name" }}

                                            @for (metric of metricList(); track metric.id) {
                                                <span class="d-block py-1 text-primary text-end">
                                                    @if (languageService.current() === lang.English) {
                                                        {{ metric.metricName }} - {{ getConstants.ytp(metric.ytpCalc!) | translateToken: "name" }}
                                                    } @else if (languageService.current() === lang.Arabic) {
                                                        {{ getConstants.ytp(metric.ytpCalc!) | translateToken: "name" }} - {{ metric.metricName }}
                                                    }
                                                </span>
                                            }
                                        </span>
                                    } @else if (kpiData()?.formula === "NOFORMULAV") {
                                        <span class="d-block w-50 px-2 text-primary text-end">
                                            {{ getConstants.ytp(kpiData()?.ytpCalc!) | translateToken: "name" }}
                                        </span>
                                    } @else if (kpiData()?.formula !== "NOFORMULAV" && kpiData()?.hasTarget === "Y") {
                                        <span class="d-block w-50 px-2 text-primary text-end">
                                            @for (metric of metricList(); track metric.id) {
                                                @if (languageService.current() === lang.English) {
                                                    <p class="text-end w-100 d-block text-primary">
                                                        {{ metric.metricName }} - {{ getConstants.ytp(metric.ytpCalc!) | translateToken: "name" }}
                                                    </p>
                                                } @else if (languageService.current() === lang.Arabic) {
                                                    <p class="text-end w-100 d-block text-primary">
                                                        {{ getConstants.ytp(metric.ytpCalc!) | translateToken: "name" }} - {{ metric.metricName }}
                                                    </p>
                                                }
                                            }
                                        </span>
                                    }
                                </div>
                            }
                        </div>

                        @if (selectedPeriod(); as period) {
                            <!-- section:kpi progress -->
                            @if (kpi && (isNTKPI() || isDTKPI() || isNKPI() || isUAE() || isMTKPI() || isMOKPI())) {
                                <div
                                    class="w-100 d-flex justify-content-between flex-wrap position-relative mt-2 py-2 kpi-details kpi-details--light"
                                >
                                    <adaa-kpi-progress
                                        class="w-100 d-block"
                                        [kpiId]="kpiId()"
                                        [kpiType]="kpi.kpiType"
                                        [period]="period.period"
                                        [tableValues]="tableValues()"
                                        [kpiHasAuditIssues]="kpiHasAuditIssues()"
                                        [auditInfo]="auditInfo()"
                                        [visionTarget]="ntkpiData().visionTarget || 0"
                                    />
                                </div>
                            }
                        }

                        <!-- section:kpi dates -->
                        @if (kpiData(); as data) {
                            <div class="w-100 d-flex justify-content-between flex-wrap position-relative mt-2 py-2 kpi-details kpi-details--light">
                                <!-- detail:startDate -->
                                <div class="w-100 d-flex align-content-center kpi-details--item">
                                    <span class="d-block w-50 fw-bold px-2">
                                        {{ "common.form.label.start_date" | translate }}
                                    </span>

                                    <span class="d-block w-50 px-2 text-primary text-end">
                                        {{ data.startDate! | dubaiTime | date: normalDateFormat }}
                                    </span>
                                </div>

                                <!-- detail:endDate -->
                                <div class="w-100 d-flex align-content-center kpi-details--item">
                                    <span class="d-block w-50 fw-bold px-2">
                                        {{ "common.form.label.end_date" | translate }}
                                    </span>

                                    <span class="d-block w-50 px-2 text-primary text-end">
                                        {{ data.endDate! | dubaiTime | date: normalDateFormat }}
                                    </span>
                                </div>
                            </div>
                        }

                        @if (isNationalOrUAEKPI()) {
                            <div class="w-100 d-flex justify-content-between flex-wrap position-relative mt-2 py-2 kpi-details kpi-details--light">
                                <div class="w-100 d-flex align-content-center flex-wrap kpi-details--item">
                                    <span class="d-block w-100 fw-bold px-2">
                                        {{ "sidebar_tables.contributing_entities" | translate }}
                                    </span>

                                    <span class="d-block w-100 px-2 pt-1">
                                        <adaa-left-side-list [list]="contributingEntities()" />
                                    </span>
                                </div>
                            </div>
                        }

                        @if (isDefined(kpiData()?.execTeam)) {
                            <adaa-exec-team [kpiId]="skpiData()!.linkedKpiId || currentKpiId()" [periodId]="selectedPeriod()?.period?.id!" />
                        }

                        @if (isNTKPI()) {
                            <div class="w-100 d-flex justify-content-between flex-wrap position-relative mt-2 py-2 kpi-details kpi-details--light">
                                <div class="w-100 d-flex align-content-center flex-wrap kpi-details--item">
                                    <span class="d-block w-100 fw-bold px-2">
                                        {{ "ntkpi.international_organizations" | translate }}
                                    </span>

                                    <span class="d-block w-100 px-2 pt-1">
                                        <adaa-left-side-list [list]="ntkpiData().internationalOrganizations ?? []" [token]="'organizationName'" />
                                    </span>
                                </div>
                            </div>

                            <div class="w-100 d-flex justify-content-between flex-wrap position-relative mt-2 py-2 kpi-details kpi-details--light">
                                <div class="w-100 d-flex align-content-center kpi-details--item">
                                    <span class="d-block w-50 fw-bold px-2">
                                        {{ "published_state" | translate }}
                                    </span>

                                    <span class="d-block w-50 px-2 text-primary text-end">
                                        {{ getConstants.general(ntkpiData().publishedState!) | translateToken: "name" }}
                                    </span>
                                </div>
                            </div>

                            <div class="w-100 d-flex justify-content-between flex-wrap position-relative mt-2 py-2 kpi-details kpi-details--light">
                                <div class="w-100 d-flex align-content-center kpi-details--item" [attr.dir]="languageService.direction()">
                                    <span class="d-block w-75 fw-bold px-2">
                                        {{ "ntkpi.actual_data_availability_period" | translate }}
                                    </span>

                                    <span class="d-block w-25 px-2 text-primary pt-1 text-end">
                                        {{ getConstants.general(ntkpiData().actualDataAvailabilityPeriod!) | translateToken: "name" }}
                                    </span>
                                </div>
                            </div>

                            <div class="w-100 d-flex justify-content-between flex-wrap position-relative mt-2 py-2 kpi-details kpi-details--light">
                                <div class="w-100 d-flex align-content-center kpi-details--item">
                                    <span class="d-block w-50 fw-bold px-2">
                                        {{ "target_category" | translate }}
                                    </span>

                                    <span class="d-block w-50 px-2 text-primary text-end">
                                        {{ getConstants.general(ntkpiData().targetCategory!) | translateToken: "name" }}
                                    </span>
                                </div>
                            </div>

                            <div class="w-100 d-flex justify-content-between flex-wrap position-relative mt-2 py-2 kpi-details kpi-details--light">
                                <div class="w-100 d-flex align-content-center kpi-details--item">
                                    <span class="d-block w-50 fw-bold px-2">
                                        {{ "ntkpi.target_type" | translate }}
                                    </span>

                                    <span class="d-block w-50 px-2 text-primary text-end">
                                        {{ getConstants.general(ntkpiData().targetTypeId) | translateToken: "name" }}
                                    </span>
                                </div>
                            </div>
                        }

                        @if (isDefined(kpiData()?.ntKpi?.id)) {
                            <div class="w-100 d-flex justify-content-between flex-wrap position-relative mt-2 py-2 kpi-details kpi-details--light">
                                <div class="w-100 d-flex align-content-center kpi-details--item">
                                    <span class="d-block w-50 fw-bold px-2">
                                        {{ "breadcrumbs.ntkpi_module.list" | translate }}
                                    </span>

                                    <span class="d-block w-50 px-2 text-primary text-end">
                                        {{ kpiData()?.ntKpi | translateToken: "name" }}
                                    </span>
                                </div>
                            </div>
                        }

                        @if (isDefined(kpiData()?.parentKpiId)) {
                            <div class="w-100 d-flex justify-content-between flex-wrap position-relative mt-2 py-2 kpi-details kpi-details--light">
                                <div class="w-100 d-flex align-content-center kpi-details--item">
                                    <span class="d-block w-50 fw-bold px-2">
                                        {{ "kpi.parent_kpi" | translate }}
                                    </span>

                                    <span class="d-block w-50 px-2 text-primary text-end">
                                        {{ kpiData() | translateToken: "parentKpiName" }}
                                    </span>
                                </div>
                            </div>
                        }

                        <!-- section:kpi scope measurement -->
                        @if (kpiData(); as data) {
                            <div class="w-100 d-flex justify-content-between flex-wrap position-relative mt-2 py-2 kpi-details kpi-details--light">
                                <div class="w-100 d-flex align-content-center flex-wrap kpi-details--item">
                                    <span class="d-block w-100 fw-bold px-2">
                                        {{ "nkpi.scopeMeasurement" | translate }}
                                    </span>

                                    <span class="d-block w-100 px-2 text-primary pt-1">
                                        <adaa-read-more [maxLength]="100" [text]="data?.scopeMeasureAE" />
                                    </span>
                                </div>
                            </div>
                        }

                        <!-- section:data source-->
                        @if (kpiData(); as data) {
                            <div class="w-100 d-flex justify-content-between flex-wrap position-relative mt-2 py-2 kpi-details kpi-details--light">
                                <div class="w-100 d-flex align-content-center flex-wrap kpi-details--item">
                                    <span class="d-block w-100 fw-bold px-2">
                                        {{ "nkpi.data_source_entity_source" | translate }}
                                    </span>

                                    <span class="d-block w-100 px-2 text-primary pt-1">
                                        <adaa-read-more [maxLength]="100" [text]="data.dataSource ?? ''" />
                                    </span>
                                </div>
                            </div>
                        }

                        @if (!isNTKPI() && !isDTKPI()) {
                            <div class="w-100 d-flex justify-content-between flex-wrap position-relative mt-2 py-2 kpi-details kpi-details--light">
                                <div class="w-100 d-flex align-content-center flex-wrap kpi-details--item">
                                    <span class="d-block w-100 fw-bold px-2">
                                        {{ "nkpi.legacy_kpi" | translate }}
                                    </span>

                                    <span class="d-block w-100 px-2 mt-2 text-primary">
                                        <a
                                            class="link-primary fw-bold"
                                            [routerLink]="['/console/kpi', type(), kpiData()?.legacyKpi]"
                                            [queryParams]="{ parentLegacyId: kpiId() }"
                                        >
                                            {{ kpiData() | translateToken: "legacyKpi" }}
                                        </a>
                                    </span>
                                </div>
                            </div>
                        }

                        <!-- section: notes -->
                        @if (kpiData(); as data) {
                            <adaa-attachments [kpiId]="data.id" [kpiType]="data.kpiType" [periodId]="selectedPeriod()?.period?.id" />
                        }

                        <!-- section: notes -->
                        @if (kpiData(); as data) {
                            <adaa-display-notes class="my-1" [id]="data.id" [type]="data.kpiType" />
                        }

                        <!-- section:objective details -->
                        @if (kpiData(); as data) {
                            <adaa-object-tags class="my-1" [id]="data.id" [type]="data.kpiType" [itemType]="data.kpiType" />
                        }
                    </div>
                </div>
            </div>
        </section>
    </div>
</ng-template>
