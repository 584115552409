<div class="d-flex gap-2">
    <p class="fw-bold fs-3">{{ "user_groups.title" | translate }}</p>
</div>

<div class="bg-white shadow-md rounded p-2 mb-3">
    <form class="col-12" [formGroup]="userGroupsForm">
        <div class="bg-white shadow-md rounded p-2 mb-3">
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.nameAE' | translate"
                        [setValidator]="userGroupsEditorService.formValidation()!"
                        [invalid]="checkInvalid('nameAE')"
                        formControlName="nameAE"
                        controlName="nameAE"
                    ></adaa-form-input>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.nameEN' | translate"
                        [setValidator]="userGroupsEditorService.formValidation()!"
                        [invalid]="checkInvalid('nameEN')"
                        formControlName="nameEN"
                        controlName="nameEN"
                    ></adaa-form-input>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12 d-flex justify-content-center" [ngClass]="{ 'me-4': appService.language() === 'ae' }">
                    <adaa-form-checkbox
                        [label]="'common.form.label.active' | translate"
                        [checkboxMode]="'yesNo'"
                        controlName="enabled"
                        formControlName="enabled"
                    ></adaa-form-checkbox>
                </div>
            </div>
        </div>
    </form>
</div>

<div class="p-2 my-4" [class.table-invalid]="!users().length && submitted()">
    <adaa-data-table
        #adaaDataTable
        [configKey]="Constants.MAIN_TABLE_LIST_CONF_KEY.USER_GROUP_USER_GRID"
        [isLocalData]="true"
        [localData]="users()"
        [enableAdd]="false"
        [enableEditAction]="false"
        [enableDeleteAction]="pageMode() !== PageMode.view"
        [enableAdd]="pageMode() !== PageMode.view"
        (addNewClicked)="selection.open()"
        (actionClicked)="getAction($event)"
    ></adaa-data-table>
</div>

<div class="col-12 mb-3">
    <adaa-form-action-buttons
        [showSaveButton]="pageMode() !== PageMode.view"
        [saveButtonTitle]="'executive_teams.submit' | translate"
        [cancelButtonTitle]="pageMode() === PageMode.view ? ('common.form.button.close' | translate) : ('common.form.button.cancel' | translate)"
        (saveClicked)="submit()"
        (cancelClicked)="close()"
    ></adaa-form-action-buttons>
</div>

<adaa-users-list-selection #selection (submitSavedUsers)="saveUsers($event)" [savedUsers]="users()"></adaa-users-list-selection>
