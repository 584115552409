import { inject, Injectable } from "@angular/core";

import { MainResponseModel, UserRole, UserRolesPermission } from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class UserRolesApiService {
  private _mainService = inject(MainService);

  public getPermissions() {
    const url = `userroles/getAllPermissions`;

    return this._mainService.sendRequest<MainResponseModel<UserRolesPermission[]>>({
      method: "GET",
      url,
    });
  }

  public updateState(data: { id: number; enabled: string }) {
    const url = `userroles/updateState`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "POST",
      url,
      data,
    });
  }

  public createRole(data: UserRole) {
    const url = `userroles/create?isFinish=true`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "POST",
      url,
      data,
    });
  }

  public updateRole(data: UserRole) {
    const url = `userroles/update?isFinish=true`;

    return this._mainService.sendRequest<MainResponseModel<number>>({
      method: "POST",
      url,
      data,
    });
  }

  public getRole(roleId: number) {
    const url = `userroles/getbyid/${roleId}`;

    return this._mainService.sendRequest<MainResponseModel<UserRole>>({
      method: "GET",
      url,
    });
  }

  public deleteRole(roleId: number) {
    const url = `userroles/deleteById/${roleId}`;

    return this._mainService.sendRequest<MainResponseModel<null>>({
      method: "DELETE",
      url,
    });
  }
}
