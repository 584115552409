<adaa-data-table
    titleHeader="khadamati.list"
    apiName="getSynchronizationHistory"
    [configKey]="tableConfList()"
    [enableActionsCell]="false"
    [enableAdd]="false"
    [enablePrint]="true"
    [enableSelection]="true"
    [rowSelected]="syncId()"
    (getRowData)="getSyncHistoryDetail($event.item)"
/>

@if (syncId()) {
    <adaa-data-table
        class="mt-2"
        titleHeader="khadamati.sync_details"
        apiName="getSynchronizationHistoryDetail"
        [configKey]="selectedConfList()"
        [paramsString]="paramsString()"
        [enableAdd]="false"
        [enablePrint]="true"
        [enableActionsCell]="false"
    />
}
