<adaa-data-table
    #adaaDataTable
    [configKey]="tableConfList()"
    [isLocalData]="true"
    [localData]="entityOffice()"
    [titleHeader]="'provisioning.addresses' | translate"
    [itemTypeId]="Constants.CONSTANT_PERMISSIONS.PROVISIONING"
    (addNewClicked)="addNew()"
    (actionClicked)="getAction($event)"
></adaa-data-table>

<adaa-addresses-editor
    #editor
    [pageMode]="pageMode()"
    [currentEntityOffice]="currentEntityOffice()"
    (editorClosed)="submit($event)"
></adaa-addresses-editor>
