<div [class.me-4]="lang() === Language.Arabic" [class.ms-4]="lang() === Language.English">
    <adaa-ribbon
        [widgetData]="dashboardWidgets()"
        [showFilterIcon]="true"
        [filterApplied]="agmService.isFilterApplied"
        (refreshClicked)="agmService.resetFilters()"
        (filterClicked)="agmService.openFilters()"
    >
        <ng-container progressBars>
            <adaa-progress-indicator
                class="pointer"
                [color]="Constants.CONSTANT_COLORS.EVALUATION_LIME"
                [value]="agmService.dashboardData()?.completedWidth!"
                [label]="('national_projects.completed_projects' | translate) + ': ' + agmService.dashboardData()?.completedCount"
                [showValue]="false"
                (click)="filterDashboard(TpAgmStatus.COMPLETED)"
            >
            </adaa-progress-indicator>
            <adaa-progress-indicator
                class="pointer"
                [color]="Constants.CONSTANT_COLORS.EVALUATION_BLUE"
                [value]="agmService.dashboardData()?.ongoingWidth!"
                [label]="('national_projects.ongoing_projects' | translate) + ': ' + agmService.dashboardData()?.ongoingCount"
                [showValue]="false"
                (click)="filterDashboard(TpAgmStatus.ON_TIME)"
            >
            </adaa-progress-indicator>
            <adaa-progress-indicator
                class="pointer"
                [color]="Constants.CONSTANT_COLORS.EVALUATION_GRAY"
                [value]="agmService.dashboardData()?.delayedWidth!"
                [label]="('national_projects.facing_delays' | translate) + ': ' + agmService.dashboardData()?.delayedCount"
                [showValue]="false"
                (click)="filterDashboard(TpAgmStatus.DELAYED)"
            >
            </adaa-progress-indicator>
        </ng-container>
    </adaa-ribbon>
</div>

<div class="row">
    <div class="col-12 d-flex flex-wrap justify-content-center">
        @for (item of agmService.dashboardData()?.annualMeetings; track $index) {
            <adaa-tp-project-card [project]="item" [showTargetsProgressBar]="false" (cardClicked)="navigateToProject($event)" />
        }
    </div>
</div>
