<adaa-ribbon
    [widgetData]="dashboardWidgets()"
    [showFilterIcon]="true"
    [filterApplied]="tpService.isFilterApplied"
    (refreshClicked)="tpService.resetFilters(false)"
    (filterClicked)="tpService.openFilters(false)"
/>

@if (tpService.dashboardData()) {
    <div class="bg-white shadow-md rounded p-2 mt-2">
        <div class="row">
            <div class="col-12">
                <div class="row mb-3">
                    <div class="col-sm-10 col-xs-12">
                        <h5 class="fw-bold mb-2">
                            {{
                                filterBy() === "progerss"
                                    ? ("national_projects.overall_projects" | translate)
                                    : ("national_projects.overall_achievement" | translate)
                            }}
                        </h5>
                    </div>
                    <div class="col-sm-2 col-xs-12 d-flex justify-content-end">
                        <button type="button" class="btn btn-sm bg-white text-primary rounded-circle shadow-sm mx-1" (click)="movePrev()">
                            <fa-icon [icon]="isArabic ? ['fas', 'angle-right'] : ['fas', 'angle-left']"></fa-icon>
                        </button>
                        <button type="button" class="btn btn-sm bg-white text-primary rounded-circle shadow-sm mx-1" (click)="moveNext()">
                            <fa-icon [icon]="isArabic ? ['fas', 'angle-left'] : ['fas', 'angle-right']"></fa-icon>
                        </button>
                    </div>
                </div>
                <canvas baseChart type="bar" [datasets]="chartDataset()" [options]="chartService.barChartOptions"></canvas>
            </div>
        </div>
    </div>
}
