import { Component, computed, input, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

import { DataTableComponent } from "../../../shared/components";
import { TableSelectedRow } from "../../../shared/models";

@Component({
  selector: "adaa-synchronization-list",
  standalone: true,
  imports: [DataTableComponent, TranslateModule],
  templateUrl: "./khadamati-synchronization-list.component.html",
  styleUrl: "./khadamati-synchronization-list.component.scss",
})
export class KhadamatiSynchronizationListComponent {
  tableConfList = input.required<string>();
  selectedConfList = input.required<string>();

  syncId = signal<number>(0);

  paramsString = computed(() => `${this.syncId()}`);

  public getSyncHistoryDetail(event: TableSelectedRow["item"]) {
    this.syncId.set(event.id);
  }
}
