<div class="d-flex gap-2">
    <p class="fw-bold fs-3">{{ "formulas.title_manage" | translate }}</p>
</div>

<div class="bg-white shadow-md rounded p-2 mb-3">
    <form class="col-12" [formGroup]="formulasForm">
        <div class="bg-white shadow-md rounded p-2 mb-3">
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input [label]="'common.form.label.nameAE' | translate" formControlName="nameAE" controlName="nameAE"></adaa-form-input>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input [label]="'common.form.label.nameEN' | translate" formControlName="nameEN" controlName="nameEN"></adaa-form-input>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.descriptionAE' | translate"
                        formControlName="dscAE"
                        controlName="dscAE"
                        [isTextArea]="true"
                    ></adaa-form-input>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.descriptionEN' | translate"
                        formControlName="dscEN"
                        controlName="dscEN"
                        [isTextArea]="true"
                    ></adaa-form-input>
                </div>
                <div class="col-12 my-2">
                    <adaa-form-input-formula
                        [label]="'formulas.formula_pick_structure' | translate"
                        formControlName="formula"
                        controlName="formula"
                        [info]="'formulas.formula_tooltip' | translate"
                        (isFormulaValid)="isFormulaValid.set($event)"
                        [pageMode]="pageMode()"
                    ></adaa-form-input-formula>
                </div>
            </div>
        </div>
    </form>
</div>

<div class="col-12 mb-3">
    <adaa-form-action-buttons
        [cancelButtonTitle]="pageMode() === PageMode.view ? ('common.form.button.close' | translate) : ('common.form.button.cancel' | translate)"
        [saveButtonTitle]="'common.form.button.save' | translate"
        [showSaveButton]="pageMode() !== PageMode.view"
        (saveClicked)="saveFormula()"
        (cancelClicked)="cancelForm()"
    ></adaa-form-action-buttons>
</div>
