<adaa-data-table
    #adaaDataTable
    titleHeader="government_scores.entity_pillars"
    apiPrefix="govScoresPillars"
    childKey="govScoresPillars"
    [configKey]="Constants.MAIN_TABLE_LIST_CONF_KEY.GOV_ENTITY_PILLARS_CONF"
    [tableWithChildren]="true"
    [enableChildActionsCell]="false"
    [customActionButtons]="CustomButtons"
    (addNewClicked)="addNew()"
    (actionClicked)="getAction($event)"
/>

<adaa-float-action />
