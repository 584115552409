<adaa-data-table
    #adaaDataTable
    titleHeader="dimension.sync_logs"
    apiName="getDimensionSyncLogs"
    [configKey]="Constants.MAIN_TABLE_LIST_CONF_KEY.DIMENSIONS_SYNC_LOGS"
    [enableActionsCell]="false"
    [enableAdd]="false"
    [enablePrint]="true"
    [enableExport]="false"
    [enableSelection]="true"
    [rowSelected]="syncId()"
    (getRowData)="getRowData($event.item)"
/>

@if (syncId() && logType() !== undefined) {
    <adaa-data-table
        #adaaDataTable
        class="mt-2"
        titleHeader="khadamati.sync_details"
        [configKey]="selectedConfList()"
        [apiName]="data().syncDetailAPI"
        [paramsString]="data().param"
        [enableAdd]="false"
        [enablePrint]="true"
        [enableActionsCell]="false"
        [enableExport]="false"
    />
}
