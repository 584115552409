import { Route } from "@angular/router";

import { checkIfLoggedInFn } from "../../core/guards";
import { PageMode } from "../../shared/constants/enums";
import {
  createAgmCyclePageGuards,
  createAgmProjectPageGuards,
  manageAgmCyclePageGuards,
  manageAgmProjectPageGuards,
  viewAgmCyclePageGuards,
  viewAgmMilestonePageGuards,
  viewAgmProjectPageGuards,
  viewAgmSubMenu,
} from "./_guards";
import { AgmCycleEditorComponent, AgmCycleListComponent } from "./agm-cycle";
import { AgmMilestoneEditorComponent, AgmMilestoneListComponent, AgmMilestoneViewComponent } from "./agm-milestone";
import { AgmProjectEditorComponent, AgmProjectListComponent, AgmProjectViewComponent } from "./agm-project";
import { AgmSubmenuComponent } from "./agm-submenu/agm-submenu.component";
import { AgmCardsDashboardComponent, AgmDashboardComponent } from "./dashboard";

export const AGM_DASHBOARD_ROUTES: Route[] = [
  {
    path: "annual-meetings-projects-dashboard",
    canActivate: [checkIfLoggedInFn],
    children: [
      {
        path: "",
        component: AgmDashboardComponent,
        canActivate: [checkIfLoggedInFn, ...viewAgmProjectPageGuards],
        data: {
          title: "annual_meetings.dashboard",
          breadcrumb: "annual_meetings.dashboard",
        },
      },
      {
        path: "projects",
        children: [
          {
            path: "",
            component: AgmCardsDashboardComponent,
            canActivate: [checkIfLoggedInFn, ...viewAgmProjectPageGuards],
            data: {
              title: "annual_meetings.page_title",
              breadcrumb: "annual_meetings.page_title",
            },
          },
          {
            path: ":id",
            component: AgmProjectViewComponent,
            canActivate: [checkIfLoggedInFn, ...viewAgmProjectPageGuards],
            data: {
              title: "annual_meetings.view",
              breadcrumb: "annual_meetings.view",
            },
          },
        ],
      },
      {
        path: "milestones/:id",
        component: AgmMilestoneViewComponent,
        canActivate: [checkIfLoggedInFn, ...viewAgmMilestonePageGuards],
        data: {
          title: "annual_meetings.milestones.view",
          breadcrumb: "annual_meetings.milestones.view",
        },
      },
    ],
  },
];

const PROJECT_ROUTES: Route[] = [
  {
    path: "annual-meetings-projects",
    redirectTo: "projects",
  },
  {
    path: "projects",
    canActivate: [checkIfLoggedInFn, ...viewAgmProjectPageGuards],
    children: [
      {
        path: "",
        component: AgmProjectListComponent,
        canActivate: [checkIfLoggedInFn, ...viewAgmProjectPageGuards],
        data: {
          breadcrumb: "annual_meetings.page_title",
          title: "annual_meetings.page_title",
        },
      },
      {
        path: "create",
        component: AgmProjectEditorComponent,
        canActivate: [checkIfLoggedInFn, ...createAgmProjectPageGuards],
        data: {
          breadcrumb: "annual_meetings.create",
          title: "annual_meetings.create",
          pageMode: PageMode.create,
        },
      },
      {
        path: "edit/:id",
        component: AgmProjectEditorComponent,
        canActivate: [checkIfLoggedInFn, ...manageAgmProjectPageGuards],
        data: {
          breadcrumb: "annual_meetings.edit",
          title: "annual_meetings.edit",
          pageMode: PageMode.edit,
        },
      },
    ],
  },
];

const MILESTONE_ROUTES: Route[] = [
  {
    path: "milestones",
    canActivate: [checkIfLoggedInFn, ...viewAgmMilestonePageGuards],
    children: [
      {
        path: "",
        component: AgmMilestoneListComponent,
        data: {
          breadcrumb: "annual_meetings.milestones.title",
          title: "annual_meetings.milestones.title",
        },
      },
      {
        path: "edit/:id",
        component: AgmMilestoneEditorComponent,
        data: {
          breadcrumb: "annual_meetings.milestones.edit",
          title: "annual_meetings.milestones.edit",
        },
      },
    ],
  },
];

const CYCLE_ROUTES: Route[] = [
  {
    path: "cycles",
    canActivate: [checkIfLoggedInFn, ...viewAgmCyclePageGuards],
    children: [
      {
        path: "",
        component: AgmCycleListComponent,
        data: {
          breadcrumb: "annual_meetings.cycles.title",
          title: "annual_meetings.cycles.title",
        },
      },
      {
        path: "create",
        canActivate: [...createAgmCyclePageGuards],
        component: AgmCycleEditorComponent,
        data: {
          breadcrumb: "annual_meetings.cycles.create",
          title: "annual_meetings.cycles.create",
          pageMode: PageMode.create,
        },
      },
      {
        path: "edit/:id",
        component: AgmCycleEditorComponent,
        canActivate: [...manageAgmCyclePageGuards],
        data: {
          breadcrumb: "annual_meetings.cycles.edit",
          title: "annual_meetings.cycles.edit",
          pageMode: PageMode.edit,
        },
      },
      {
        path: ":id",
        component: AgmCycleEditorComponent,
        data: {
          breadcrumb: "annual_meetings.cycles.view",
          title: "annual_meetings.cycles.view",
          pageMode: PageMode.view,
        },
      },
    ],
  },
];

export const AGM_ROUTES: Route[] = [
  {
    path: "annual-meetings-projects",
    canActivate: [checkIfLoggedInFn, ...viewAgmSubMenu],
    data: {
      breadcrumb: "annual_meetings.title",
    },
    children: [
      {
        path: "",
        component: AgmSubmenuComponent,
      },
      ...PROJECT_ROUTES,
      ...MILESTONE_ROUTES,
      ...CYCLE_ROUTES,
    ],
  },
];
