<div class="w-100 d-flex justify-content-center align-content-center card mb-2 py-1 px-2 bg-white border-0 form-section">
    <section class="d-flex row mt-1 mb-3">
        <div class="col-12">
            <adaa-data-table
                #improvementPlanList
                [titleHeader]="'ntkpi.improvement_plan' | translate"
                [configKey]="tableConfList"
                [isLocalData]="true"
                [enableAdd]="true"
                [enableExport]="false"
                [enableSelection]="false"
                [enableViewAction]="false"
                [enableActionsCell]="true"
                [enableEditAction]="true"
                [enableDeleteAction]="true"
                [enableConnectionsAction]="false"
                [customActionButtons]="orderedData().length > 1 ? customActionButtons : []"
                [localData]="orderedData()"
                (addNewClicked)="handleAddTableAction()"
                (actionClicked)="handleTableActions($event)"
            />
        </div>
    </section>
</div>
