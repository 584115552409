import { Component, inject, input, OnInit, signal } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

import { AdaaHelper } from "../../core/utils";
import { DataTableComponent, FloatActionComponent, WfDifferenceComponent } from "../../shared/components";
import { genericFloatButtons } from "../../shared/components/general/float-action";
import { SearchFields, TableButtonClicked } from "../../shared/models";
import { SystemLayoutService } from "../../shared/services";

@Component({
  selector: "adaa-system-log",
  standalone: true,
  templateUrl: "./system-log.component.html",
  styleUrl: "./system-log.component.scss",
  imports: [DataTableComponent, TranslateModule, WfDifferenceComponent, FloatActionComponent],
})
export class SystemLogComponent implements OnInit {
  private readonly _route = inject(ActivatedRoute);
  private readonly _systemLayoutService = inject(SystemLayoutService);

  title = input.required<string>();
  tableConfList = input.required<string>();

  staticSearchFields = signal<SearchFields | undefined>(undefined);
  private readonly _genericFloatButtons = genericFloatButtons();

  readonly #untilDestroy = AdaaHelper.untilDestroyed();

  public ngOnInit() {
    this._route.queryParams.subscribe({
      next: (qs) => {
        let search: SearchFields = {
          searchFields: {},
        };
        if (qs["object_id"]) {
          search = {
            searchFields: {
              ...search.searchFields,
              object_id: {
                value: Number(qs.object_id),
                order: "desc",
              },
            },
          };
        }

        if (qs["item_type_id"]) {
          search = {
            searchFields: {
              ...search.searchFields,
              item_type_id: {
                value: undefined,
                lov: JSON.parse(qs["item_type_id"]),
                order: "desc",
              },
            },
          };
        }

        this.staticSearchFields.set(search);
      },
    });
    this._refreshPage();
    this._floatActions();
  }

  public handleTableActions({ event, data }: TableButtonClicked, modal: WfDifferenceComponent) {
    if (event === "view") {
      modal.open({ trailId: data.id });
    }
  }

  private _refreshPage() {
    this._systemLayoutService.hasActiveEntityChanged$
      .pipe(this.#untilDestroy())
      .subscribe(() => window.location.reload());

    this._systemLayoutService.hasCycleChanged$.pipe(this.#untilDestroy()).subscribe(() => window.location.reload());
  }

  private _floatActions(): void {
    this._genericFloatButtons([
      {
        key: "print",
        data: undefined,
      },
    ]);
  }
}
