import { FormControl, FormGroup, Validators } from "@angular/forms";

import { AdaaHelper } from "../../../../../core/utils";
import { dateRangeValidator } from "../../../../../core/validators";
import { Constants } from "../../../../../shared/constants/constants";
import { ParameterCatalog } from "../../../../../shared/models";
import { getRequiredValidator } from "./lib";

export const OpmInfoTabForm = (fields: ParameterCatalog[]) =>
  new FormGroup(
    {
      initiativeId: new FormControl<number | null>(null, [...getRequiredValidator(fields, "initiativeId")]),
      nameAE: new FormControl<string | null>(null, [
        ...getRequiredValidator(fields, "nameAE"),
        Validators.minLength(3),
        Validators.maxLength(Constants.FORM_FIELD_CONSTRAINTS.CHAR_MAXLENGTH),
      ]),
      nameEN: new FormControl<string | null>(null, [
        ...getRequiredValidator(fields, "nameEN"),
        Validators.minLength(3),
        Validators.maxLength(Constants.FORM_FIELD_CONSTRAINTS.CHAR_MAXLENGTH),
      ]),
      dscAE: new FormControl<string | null>(null, [
        ...getRequiredValidator(fields, "dscAE"),
        Validators.minLength(3),
        Validators.maxLength(Constants.FORM_FIELD_CONSTRAINTS.DESC_MAXLENGTH),
      ]),
      dscEN: new FormControl<string | null>(null, [
        ...getRequiredValidator(fields, "dscEN"),
        Validators.minLength(3),
        Validators.maxLength(Constants.FORM_FIELD_CONSTRAINTS.DESC_MAXLENGTH),
      ]),
      startDate: new FormControl<number | null>(AdaaHelper.getDubaiTime(AdaaHelper.plan.startYear), [
        ...getRequiredValidator(fields, "startDate"),
      ]),
      endDate: new FormControl<number | null>(AdaaHelper.getDubaiTime(AdaaHelper.plan.endYear), [
        ...getRequiredValidator(fields, "endDate"),
      ]),
      orgUnitId: new FormControl<number | null>(null, [...getRequiredValidator(fields, "orgUnitId")]),
      owner: new FormControl<number | null>(null, [...getRequiredValidator(fields, "owner")]),
      dataSource: new FormControl<string | null>(null, [...getRequiredValidator(fields, "dataSource")]),
      scopeMeasureAE: new FormControl<string | null>(null, [...getRequiredValidator(fields, "scopeMeasureAE")]),
      legacyKpi: new FormControl<number | null>(null, [...getRequiredValidator(fields, "legacyKpi")]),
      commentsAE: new FormControl<string>("", [...getRequiredValidator(fields, "commentsAE")]),
      sponsorEntityId: new FormControl<number | null>(null, [...getRequiredValidator(fields, "sponsorEntityId")]),
    },
    {
      validators: Validators.compose([dateRangeValidator("startDate", "endDate")]),
    }
  );
