<ng-template #content let-modal>
    <div class="modal-header">
        <h5 class="text-center fw-bold w-100">{{ "lookup.content.title" | translate }}</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body overflow-x-hidden p-3">
        <form class="col-12" [formGroup]="lookupForm">
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.nameAE' | translate"
                        formControlName="nameAE"
                        controlName="nameAE"
                        [setValidator]="lookupEditorService.formValidation()!"
                        [invalid]="checkInvalid('nameAE')"
                    ></adaa-form-input>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.nameEN' | translate"
                        formControlName="nameEN"
                        controlName="nameEN"
                        [setValidator]="lookupEditorService.formValidation()!"
                        [invalid]="checkInvalid('nameEN')"
                    ></adaa-form-input>
                </div>
            </div>
            <div *ngIf="displayValueLabel()" class="row">
                <div class="col-12">
                    <adaa-form-input
                        [label]="displayValueLabel()"
                        formControlName="value"
                        controlName="value"
                        [setValidator]="lookupEditorService.formValidation()!"
                        [invalid]="checkInvalid('value')"
                    ></adaa-form-input>
                </div>
            </div>
        </form>
        <div class="my-2">
            <adaa-form-action-buttons
                [cancelButtonTitle]="
                    pageMode() === PageMode.view ? ('common.form.button.close' | translate) : ('common.form.button.cancel' | translate)
                "
                [showSaveButton]="pageMode() !== PageMode.view"
                (cancelClicked)="modal.close()"
                (saveClicked)="submit()"
            ></adaa-form-action-buttons>
        </div>
    </div>
</ng-template>
