import { Component, computed, inject, OnInit, signal } from "@angular/core";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { filter, switchMap } from "rxjs";

import { AdaaHelper } from "../../../core/utils";
import { FormDropdownComponent, FormInputComponent } from "../../../shared/components";
import { Constants } from "../../../shared/constants/constants";
import { PropTypeModelType, UserSecurityQuestionModelType, ValueText } from "../../../shared/models";
import { PropertiesService, UserProfileApiService } from "../../../shared/services";

@Component({
  selector: "adaa-profile-security",
  standalone: true,
  imports: [TranslateModule, FormInputComponent, FormDropdownComponent, FormsModule, ReactiveFormsModule],
  templateUrl: "./profile-security.component.html",
  styleUrl: "./profile-security.component.scss",
})
export class ProfileSecurityComponent implements OnInit {
  private _propertyService = inject(PropertiesService);
  private _translateService = inject(TranslateService);
  private _toastrService = inject(ToastrService);
  private _userProfileService = inject(UserProfileApiService);
  private _formBuilder = inject(FormBuilder);

  securityQuestions = signal<PropTypeModelType[]>([]);
  securityQuestionsForm: FormGroup = new FormGroup({});
  passwordForm: FormGroup = new FormGroup({});
  isUAEPassLogin = signal<boolean>(false);

  securityQuestionOptions = computed(() => {
    const result: ValueText[] = this.securityQuestions().map((prop) => {
      return {
        text: AdaaHelper.getItemValueByToken(prop, "name"),
        value: prop.id,
      };
    });

    this._translateService.get(["common.form.label.please_select"]).subscribe((values) => {
      result.unshift({ text: values["common.form.label.please_select"], value: "none" });
    });
    return result;
  });

  securityQuestionsValues = signal<UserSecurityQuestionModelType[]>([]);

  public ngOnInit(): void {
    this._prepareForms();
    this._getSecurityQuestions();
    this._checkUserInfo();
  }

  private _checkUserInfo() {
    this._userProfileService.getUserInformation().subscribe({
      next: (response) => this.isUAEPassLogin.set(response.responseData.uaePassLogin),
    });
  }

  private _prepareForms() {
    this.securityQuestionsForm = this._formBuilder.group({
      question1: this._formBuilder.control<string | undefined>(undefined, {
        validators: [Validators.required],
      }),
      answer1: this._formBuilder.control<string | undefined>(undefined, {
        validators: [Validators.required],
      }),
      question2: this._formBuilder.control<string | undefined>(undefined, {
        validators: [Validators.required],
      }),
      answer2: this._formBuilder.control<string | undefined>(undefined, {
        validators: [Validators.required],
      }),
      question3: this._formBuilder.control<string | undefined>(undefined, {
        validators: [Validators.required],
      }),
      answer3: this._formBuilder.control<string | undefined>(undefined, {
        validators: [Validators.required],
      }),
    });

    this.passwordForm = this._formBuilder.group({
      oldPassword: this._formBuilder.control<string | undefined>(undefined, {
        validators: [Validators.required],
      }),
      newPassword: this._formBuilder.control<string | undefined>(undefined, {
        validators: [Validators.required],
      }),
      newPasswordConfirmation: this._formBuilder.control<string | undefined>(undefined, {
        validators: [Validators.required],
      }),
    });
  }

  private _getSecurityQuestions() {
    this._propertyService
      .getPropById(Constants.CONSTANT_SECURITYQUESTIONS)
      .pipe(
        switchMap((response) => {
          this.securityQuestions.set(response.responseData);
          return this._userProfileService.getUserSecurityQuestions();
        })
      )
      .subscribe({
        next: (response) => {
          const securityQuestions = response.responseData.securityQuestions;
          this.securityQuestionsValues.set(securityQuestions);
        },
        complete: () => {
          this.securityQuestionsValues().forEach((question, i) => {
            const index = this.securityQuestionOptions().findIndex((q) => q.value === question.securityQuestionId);
            const questionControlName = `question${i + 1}`;
            const answerControlName = `answer${i + 1}`;
            if (this.securityQuestionsForm.controls[questionControlName]) {
              this.securityQuestionsForm.controls[questionControlName].setValue(
                this.securityQuestionOptions()[index].value
              );
              this.securityQuestionsForm.controls[answerControlName].setValue(question.answer);
            }
          });
        },
      });
  }

  public changePassword() {
    if (this.passwordForm.invalid) {
      this._toastrService.error(this._translateService.instant("notification.warning.password_must_field"));
      return;
    }

    const passwordValue = this.passwordForm.value;

    if (!this._validatePassword(passwordValue.newPassword)) {
      this._toastrService.error(this._translateService.instant("notification.error.password_requirements_failed"));
      return;
    }

    if (passwordValue.newPassword !== passwordValue.newPasswordConfirmation) {
      this._toastrService.error(this._translateService.instant("notification.warning.passwords_must_match"));
      return;
    }

    const currentUserEmail = AdaaHelper.getLocalStorage(Constants.localStorageKeys.user, {
      type: "prop",
      property: "email",
    }) as string;

    const result = {
      email: currentUserEmail,
      oldPassword: passwordValue.oldPassword,
      newPassword: passwordValue.newPassword,
    };

    this._userProfileService
      .changePassword(result)
      .pipe(filter((response) => !response.inError))
      .subscribe({
        next: () => {
          this._toastrService.success(this._translateService.instant("notification.success.save"));
          this.passwordForm.reset();
        },
      });
  }

  private _validatePassword(password: string) {
    const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+[\]{};':"|,.<>?-]{8,}$/;
    return regex.test(password);
  }

  public getLabel(count: number) {
    return this._translateService.instant("common.form.label.security_question") + " " + count;
  }

  public checkInvalid(control: string) {
    return this.securityQuestionsForm.controls[control].errors?.["required"];
  }
}
