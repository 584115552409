<adaa-data-table
    #adaaDataTable
    [titleHeader]="'breadcrumbs.text-mapping'"
    apiName="getAll"
    apiPrefix="textmap"
    [configKey]="tableConfList()"
    [itemTypeId]="constants.CONSTANT_PERMISSIONS.TEXTMAPPING"
    [enableEditAction]="true"
    [enableDeleteAction]="true"
    [enableViewAction]="true"
    [enableAdd]="true"
    (addNewClicked)="addNewClicked()"
    (actionClicked)="getAction($event)"
></adaa-data-table>

<adaa-float-action />
