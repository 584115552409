import type { Route } from "@angular/router";

import { checkIfLoggedInFn } from "../../core/guards";
import { EmployeePulseComponent } from "./employee-pulse.component";

export const EMPLOYEE_PULSE_ROUTES: Route[] = [
  {
    path: "employees-pulse",
    canActivate: [checkIfLoggedInFn],
    component: EmployeePulseComponent,
    data: {
      title: "happiness_survey.title",
      breadcrumb: "happiness_survey.title",
    },
  },
];
