import { Component, inject, input, OnInit, signal, viewChild } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { ConfirmationModalComponent, DataTableComponent } from "../../../shared/components";
import { Constants } from "../../../shared/constants/constants";
import { AdaaBoolean, Language, PermissionAction } from "../../../shared/constants/enums";
import { CustomButton, TableButtonClicked } from "../../../shared/models";
import { AppService, EntitiesApiService, PermissionsService } from "../../../shared/services";

@Component({
  selector: "adaa-entities-list",
  standalone: true,
  imports: [DataTableComponent, TranslateModule],
  templateUrl: "./entities-list.component.html",
  styleUrl: "./entities-list.component.scss",
})
export class EntitiesListComponent implements OnInit {
  private _permissionsService = inject(PermissionsService);
  private _entitiesApiService = inject(EntitiesApiService);
  private _translateService = inject(TranslateService);
  private _toastrService = inject(ToastrService);
  private _modalService = inject(NgbModal);
  private _appService = inject(AppService);
  private _router = inject(Router);

  breadcrumb = input.required<string>();
  adaaDataTable = viewChild.required<DataTableComponent>("adaaDataTable");
  Constants = Constants;
  hasPermission = signal<boolean>(false);

  customButtons: CustomButton[] = [
    {
      iconName: "adaa-icon-adaa-ok-Y",
      eventName: "enable",
      iconTitle: "common.form.label.active",
      onlyIf: (data: { enabled: AdaaBoolean }) => {
        return AdaaBoolean.Y === data?.enabled;
      },
    },
    {
      iconName: "adaa-icon-adaa-ok-N",
      eventName: "disable",
      iconTitle: "common.form.label.inactive",
      onlyIf: (data: { enabled: AdaaBoolean }) => {
        return AdaaBoolean.N === data?.enabled;
      },
    },
  ];

  public ngOnInit(): void {
    const permission = this._permissionsService.hasPermission([
      {
        permissionAction: PermissionAction.manage,
        objectTypeId: Constants.CONSTANT_PERMISSIONS.ENTITIES,
      },
    ]);
    this.hasPermission.set(permission);
  }

  public addNew() {
    this._router.navigateByUrl("/console/entities/create");
  }

  public getAction(value: TableButtonClicked) {
    switch (value.event) {
      case "enable":
      case "disable":
        this._updateState(value);
        break;
      case "view":
        this._router.navigateByUrl(`/console/entities/${value.data.id}`);
        break;
      case "edit":
        this._router.navigateByUrl(`/console/entities/edit/${value.data.id}`);
        break;
      case "delete":
        this._deleteEntity(value.data.id);
        break;

      default:
        break;
    }
  }

  private _updateState(value: TableButtonClicked) {
    const data = value.data;
    data.enabled = value.event === "enable" ? "N" : "Y";
    this._entitiesApiService.updateState(data).subscribe({
      next: () => {
        this._toastrService.success(
          this._translateService.instant(
            "notification.success." + (value.event === "enable" ? "deactivate" : "activate")
          )
        );
        this.adaaDataTable().loadTableData();
      },
    });
  }

  private _deleteEntity(id: number) {
    const modal = this._modalService.open(ConfirmationModalComponent, {
      centered: true,
      size: "md",
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });

    modal.componentInstance.header = "entity.create.delete_yes_no_title";
    modal.componentInstance.title = "entity.create.delete_yes_no_information";

    modal.result.then((e) => {
      if (e) {
        this._entitiesApiService.deleteEntity(id).subscribe({
          next: () => {
            this._toastrService.success(this._translateService.instant("notification.success.remove"));
            this.adaaDataTable().loadTableData();
          },
        });
      }
    });
  }
}
