<adaa-entity-plan-change-detection
    [template]="page"
    [request$]="milestoneDetailsService.fetchMilestone(+id())"
    [ignoreEntityDetection]="AdaaHelper.isPMOEntity()"
    (continue)="milestoneDetailsService.init($event)"
/>

<ng-template #page>
    <div class="row">
        <!-- HEADER -->
        <div class="col-12">
            <h4 class="fw-bold">{{ milestoneDetailsService.milestone() | translateToken: "name" }}</h4>
        </div>

        <!-- UPDATE BY -->
        <div class="col-12 mb-1">
            {{ "nkpi.last_update" | translate }}, {{ milestoneDetailsService.milestone()?.updateTS | date: constants.normalDate }}
            {{ "common.form.label.by" | translate }}
            {{
                milestoneDetailsService.milestone()?.updateUserNameEN
                    ? (milestoneDetailsService.milestone() | translateToken: "updateUserName")
                    : milestoneDetailsService.milestone()?.updateUser
            }}
        </div>

        <!-- OWNER AND PROGRESS -->
        <div class="col-12 mb-3">
            <div class="grid gap-3">
                <div class="g-col-md-6 g-col-xs-12 bg-white shadow-md rounded px-1 text-center d-flex align-items-center">
                    <div class="row">
                        @if (milestoneDetailsService.milestone()?.entityLogoId) {
                            <div class="col-md-6 col-sm-12 overflow-hidden">
                                <h5 class="fw-bold mb-2">{{ "national_projects.accountable_entity" | translate }}</h5>
                                <adaa-picture [pictureId]="milestoneDetailsService.milestone()?.entityLogoId" [width]="'300px'"></adaa-picture>
                            </div>
                        }
                        <div class="col-md-6 col-sm-12">
                            <div class="row">
                                <h5 class="fw-bold mb-2">{{ "national_projects.project_name" | translate }}</h5>

                                <a [routerLink]="['/national-projects-dashboard/projects/' + milestoneDetailsService.milestone()?.nationalProjectId]">
                                    {{ milestoneDetailsService.milestone() | translateToken: "nationalProjectName" }}
                                </a>

                                <h6 class="mt-2 mb-2 text-primary">
                                    {{ milestoneDetailsService.milestone() | translateToken: "nationalProjectEntityName" }}
                                </h6>

                                <div class="col-12">
                                    <button class="btn btn-primary" (click)="milestoneDetailsService.openTeamMembers()">
                                        {{ "national_projects.national_projects_milestone.team_members" | translate }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="g-col-md-6 g-col-xs-12">
                    <div class="col-12 bg-white shadow-md rounded p-2 mb-2">
                        <adaa-progress-indicator
                            [color]="milestoneDetailsService.milestone()?.onTimeProgressColor!"
                            [value]="milestoneDetailsService.milestone()?.progress!"
                            [label]="('common.tables.progress' | translate) + ': '"
                        />
                        <adaa-progress-indicator
                            [color]="
                                milestoneDetailsService.milestone() === undefined || milestoneDetailsService.milestone()!.visionScore! < 100
                                    ? constants.CONSTANT_COLORS.EVALUATION_GOLD
                                    : milestoneDetailsService.milestone()?.visionScoreColor!
                            "
                            [value]="milestoneDetailsService.milestone()?.visionScore!"
                            [label]="('national_projects.targets_achievement' | translate) + ': '"
                        />
                    </div>
                    <div class="col-12 bg-white shadow-md rounded">
                        <div class="row d-flex align-items-center">
                            <div class="col-md-6 col-sm-12 co-xs-12 d-flex flex-column align-items-center">
                                <h5>
                                    {{ "activities.budget" | translate }}:
                                    <strong>{{ milestoneDetailsService.milestone()?.budget | currencyValue: true }}</strong>
                                </h5>
                                <h5>
                                    {{ "activities.expenses" | translate }}:
                                    <strong>{{ milestoneDetailsService.milestone()?.expense | currencyValue: true }}</strong>
                                </h5>
                            </div>
                            <div class="col-md-6 col-sm-12 co-xs-12 d-flex flex-column align-items-center">
                                <adaa-doughnut
                                    [amount]="milestoneDetailsService.milestone()?.budgetExpenditure!"
                                    [colorId]="constants.CONSTANT_COLORS.EVALUATION_BLACK"
                                    [showProgressCircle]="false"
                                    [circleColor]="'#000'"
                                    [size]="'xxs'"
                                />
                                <h5>{{ "services.expenditures" | translate }}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- DESCRIPTION AND DATES -->
        <div class="col-12">
            <div class="grid gap-3">
                <div class="g-col-md-6 g-col-sm-12 g-col-xs-12 mb-3 bg-white shadow-md rounded p-2">
                    <h5 class="fw-bold">{{ "common.form.label.description" | translate }}</h5>
                    <adaa-read-more [maxLength]="300" [text]="milestoneDetailsService.milestone() | translateToken: 'dsc'" />
                </div>
                <div class="g-col-md-6 g-col-sm-12 g-col-xs-12 mb-3 bg-white shadow-md rounded">
                    <div class="grid gap-3 p-3">
                        <div class="g-col-md-6 g-col-sm-12 g-col-xs-12 text-center date-container p-2">
                            <h5 class="text-primary fw-bold">{{ "national_projects.planned_start_date" | translate }}</h5>
                            <h6>{{ milestoneDetailsService.milestone()?.startDate | date: constants.normalDate }}</h6>
                        </div>
                        <div class="g-col-md-6 g-col-sm-12 g-col-xs-12 text-center date-container p-2">
                            <h5 class="text-primary fw-bold">{{ "national_projects.planned_end_date" | translate }}</h5>
                            <h6>{{ milestoneDetailsService.milestone()?.endDate | date: constants.normalDate }}</h6>
                        </div>
                        <div class="g-col-md-6 g-col-sm-12 g-col-xs-12 text-center date-container p-2">
                            <h5 class="text-primary fw-bold">{{ "national_projects.actual_start_date" | translate }}</h5>
                            <h6>
                                {{
                                    milestoneDetailsService.milestone()?.actualStartDate
                                        ? (milestoneDetailsService.milestone()?.actualStartDate | date: constants.normalDate)
                                        : "-"
                                }}
                            </h6>
                        </div>
                        <div class="g-col-md-6 g-col-sm-12 g-col-xs-12 text-center date-container p-2">
                            <h5 class="text-primary fw-bold">{{ "national_projects.actual_end_date" | translate }}</h5>
                            <h6>
                                {{
                                    milestoneDetailsService.milestone()?.actualEndDate
                                        ? (milestoneDetailsService.milestone()?.actualEndDate | date: constants.normalDate)
                                        : "-"
                                }}
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- SECTORS AND SIGN OFF -->
        <div class="col-12">
            <div class="grid gap-3">
                <div class="g-col-md-6 g-col-sm-12 g-col-xs-12 mb-3 bg-white shadow-md rounded p-2">
                    <h5 class="fw-bold">{{ "national_projects.national_sector.title" | translate }}</h5>
                    @for (item of milestoneDetailsService.milestone()?.nationalSectors; track $index) {
                        <span class="badge rounded-pill bg-light text-dark shadow-sm mx-1 fs-5">{{ item | translateToken: "name" }}</span>
                    }
                </div>
                <div class="g-col-md-6 g-col-sm-12 g-col-xs-12 mb-3 bg-white shadow-md rounded p-2">
                    <div class="row">
                        <div class="col-12">
                            <h5 class="fw-bold">{{ "national_projects.sign_off.justification" | translate }}</h5>
                            <adaa-read-more [maxLength]="300" [text]="milestoneDetailsService.milestone()?.signOff!" />
                        </div>

                        @if (milestoneDetailsService.milestone()?.signOffAttachment?.id) {
                            <div class="col-12 mt-3">
                                <button
                                    class="btn btn-outline-primary mx-1"
                                    (click)="milestoneDetailsService.downloadAttachment(milestoneDetailsService.milestone()?.signOffAttachment!)"
                                >
                                    <fa-icon class="mx-1" [icon]="['fas', 'file-download']"></fa-icon>
                                    {{ milestoneDetailsService.milestone()?.signOffAttachment | translateToken: "name" }}
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>

        <!-- ENTITIES AND NOTES -->
        <div class="col-12">
            <div class="grid gap-3">
                <div class="g-col-md-6 g-col-sm-12 g-col-xs-12 mb-3 bg-white shadow-md rounded p-2">
                    <h5 class="fw-bold">{{ "national_projects.contributor_entities" | translate }}</h5>
                    @for (item of contributingEntities; track $index) {
                        <span class="badge rounded-pill bg-light text-dark shadow-sm mx-1 fs-5">{{ item | translateToken: "entityName" }}</span>
                    }
                    <h5 class="fw-bold mt-4">{{ "other_entities.plural" | translate }}</h5>
                    @for (item of otherContributingEntities; track $index) {
                        <span class="badge rounded-pill bg-light text-dark shadow-sm mx-1 fs-5">{{ item | translateToken: "otherEntityName" }}</span>
                    }
                </div>
                <div class="g-col-md-6 g-col-sm-12 g-col-xs-12 mb-3 bg-white shadow-md rounded p-2">
                    <adaa-comments
                        classes="fs-5 fw-bold"
                        cardTitle="sidebar_tables.notes"
                        titleToken="name"
                        dateToken="createTS"
                        messageToken="note"
                        [comments]="milestoneDetailsService.notes()"
                    />
                </div>
            </div>
        </div>

        <!-- ENTITIES AND NOTES -->
        <div class="col-12">
            <div class="grid gap-3">
                <div class="g-col-md-10 g-col-sm-12 g-col-xs-12 mb-3 bg-white shadow-md rounded p-2">
                    <adaa-comments
                        classes="fs-5 fw-bold"
                        cardTitle="national_projects.comments"
                        titleToken="createdByUserName"
                        dateToken="creationDate"
                        messageToken="comments"
                        [showNumber]="true"
                        [comments]="milestoneDetailsService.milestone()?.comments"
                    />
                </div>
                <div class="g-col-md-2 g-col-sm-12 g-col-xs-12 mb-3 bg-white shadow-md rounded p-2">
                    <h5 class="fw-bold">{{ "helpdesk.attachment" | translate }}</h5>
                    @for (item of milestoneDetailsService.milestone()?.attachments; track $index) {
                        <button class="btn btn-outline-primary" (click)="milestoneDetailsService.downloadAttachment(item)">
                            <fa-icon class="mx-1" [icon]="['fas', 'file-download']"></fa-icon>
                            {{ item | translateToken: "name" }}
                        </button>
                    }
                </div>
            </div>
        </div>

        <!-- KPI AND MILESTONES -->
        <div class="col-12 mb-3">
            <div class="bg-white shadow-md rounded p-2">
                <nav>
                    <div class="nav nav-tabs">
                        <button class="nav-link active" type="button" data-bs-toggle="tab" data-bs-target="#nav-targets">
                            {{ "national_projects.milestone_targets" | translate }}
                        </button>
                        <button class="nav-link" type="button" role="tab" data-bs-toggle="tab" data-bs-target="#nav-sub-millestones">
                            {{ "national_projects.national_projects_milestone.sub_milestones" | translate }}
                        </button>
                    </div>
                </nav>
                <div class="tab-content pt-2">
                    @if (milestoneDetailsService.milestone()) {
                        <div class="tab-pane fade show active" id="nav-targets" role="tabpanel">
                            <adaa-data-table
                                [configKey]="'national_project_milestone_targets_list'"
                                [apiName]="'getNationalProjectMilestonesKpis'"
                                [paramsString]="paramsStringMokpi()"
                                [enableAdd]="false"
                                [enableEditAction]="false"
                                [enableDeleteAction]="false"
                                (actionClicked)="actionClicked($event)"
                            ></adaa-data-table>
                        </div>
                        <div class="tab-pane fade" id="nav-sub-millestones" role="tabpanel">
                            <adaa-data-table
                                [configKey]="constants.MAIN_TABLE_LIST_CONF_KEY.SUB_PROJECT_MILESTONE_CONF_LIST"
                                [isLocalData]="true"
                                [localData]="milestoneDetailsService.subMilestones()"
                                [enableAdd]="false"
                                [enableEditAction]="false"
                                [enableDeleteAction]="false"
                                [enableViewAction]="false"
                                [customActionButtons]="customActionButtons"
                                (actionClicked)="milestoneDetailsService.openSubMilestoneTeamMembers($event)"
                            ></adaa-data-table>
                        </div>
                    }
                </div>
            </div>
        </div>
    </div>

    <adaa-float-action (notesSaved)="milestoneDetailsService.getNotes()" />
</ng-template>
