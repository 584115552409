import { CronObject, CurrentSchedule } from "../../models";

export const createCronExpression = (cronObject: CronObject): string => {
  const { seconds = "00", minutes = "00", hours = "00", dayOfMonth = "?", month = "*", dayOfWeek = "*" } = cronObject;

  const validSeconds = validate(String(seconds), /^[0-5]?[0-9]$/, "00").padStart(2, "0");
  const validMinutes = validate(String(minutes), /^[0-5]?[0-9]$/, "00").padStart(2, "0");
  const validHours = validate(String(hours), /^([01]?[0-9]|2[0-3])$/, "00").padStart(2, "0");
  const validDayOfMonth = validate(String(dayOfMonth), /^([1-9]|[12][0-9]|3[01]|\*)$/, "?");
  const validMonth = validate(String(month), /^(1[0-2]|[1-9]|\*)$/, "*");
  let validDayOfWeek = validate(String(dayOfWeek), /^([0-6]|\?|L|#)$/, "?");

  if (validDayOfMonth !== "?") validDayOfMonth.padStart(2, "0");
  if (validMonth !== "?") validMonth.padStart(2, "0");
  if (validDayOfMonth === "?" && validDayOfWeek === "?") validDayOfWeek = "*";
  if (validDayOfWeek !== "?" && validDayOfWeek !== "*") validDayOfWeek.padStart(2, "0");

  return `${validSeconds} ${validMinutes} ${validHours} ${validDayOfMonth} ${validMonth} ${validDayOfWeek}`;
};

export const getCronFrequency = (cronExpression: string): CurrentSchedule => {
  if (cronExpression === "") return { frequency: "inactive" };
  const [__, minute, hour, dayOfMonth, _, dayOfWeek] = cronExpression.split(" ");

  if (dayOfMonth !== "?" && dayOfMonth !== "*") {
    return { frequency: "monthly", dayOfMonth: +dayOfMonth, time: `${hour}:${minute}` };
  }
  if (dayOfWeek !== "?" && dayOfWeek !== "*" && dayOfWeek !== "00" && dayOfWeek !== "07") {
    return { frequency: "weekly", dayOfWeek: +dayOfWeek, time: `${hour}:${minute}` };
  }
  if (dayOfMonth === "?" && dayOfWeek === "*") {
    return { frequency: "daily", time: `${hour}:${minute}` };
  }
  return { frequency: "inactive" };
};

const validate = (value: string, pattern: RegExp, defaultValue: string): string => {
  return pattern.test(value) ? value : defaultValue;
};
