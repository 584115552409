import { hasPermissionGuard } from "../../core/guards";
import { Constants } from "../../shared/constants/constants";
import { PermissionAction } from "../../shared/constants/enums";

export const viewCalculationManagementPageGuards = [
  hasPermissionGuard({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.view,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.CALCULATION_MANAGEMENT,
        },
      ],
    },
    redirectTo: "/403",
  }),
];

export const viewKpiAnnualWeightsPageGuards = [
  hasPermissionGuard({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.view,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.KPI_ANNUAL_WEIGHTS,
        },
      ],
    },
    redirectTo: "/403",
  }),
];

export const viewKpiAnnualWeightsSKPIPageGuards = [
  hasPermissionGuard({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.view,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.SKPI,
        },
      ],
    },
    redirectTo: "/403",
  }),
];

export const viewKpiAnnualWeightsSRVKPIPageGuards = [
  hasPermissionGuard({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.view,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.SRVKPI,
        },
      ],
    },
    redirectTo: "/403",
  }),
];

export const viewKpiAnnualWeightsOPMPageGuards = [
  hasPermissionGuard({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.view,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.OPM,
        },
      ],
    },
    redirectTo: "/403",
  }),
];

export const viewKpiAnnualWeightsMOKPIPageGuards = [
  hasPermissionGuard({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.view,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.MOKPI,
        },
      ],
    },
    redirectTo: "/403",
  }),
];

export const viewKpiAnnualWeightsEKPIPageGuards = [
  hasPermissionGuard({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.view,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.EKPI,
        },
      ],
    },
    redirectTo: "/403",
  }),
];
