import { CommonModule } from "@angular/common";
import { Component, inject, OnDestroy, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";

import { PermissionActionDescriptorType } from "../../../../adaa-types";
import { AdaaHelper } from "../../../core/utils";
import { FormActionButtonsComponent } from "../../../shared/components";
import { Constants } from "../../../shared/constants/constants";
import { Language, PermissionAction } from "../../../shared/constants/enums";
import { HasPermissionDirective } from "../../../shared/directives";
import { PerformanceConfigType } from "../../../shared/models";
import { CalculationEngineApiService, LanguageService, SystemLayoutService } from "../../../shared/services";
import { CalculationManagementService } from "../../../shared/services/calculation-management-api.service";

@Component({
  selector: "adaa-calculation-government",
  standalone: true,
  imports: [CommonModule, TranslateModule, ReactiveFormsModule, HasPermissionDirective, FormActionButtonsComponent],
  templateUrl: "./calculation-government.component.html",
  styleUrl: "./calculation-government.component.scss",
})
export class CalculationGovernmentComponent implements OnInit, OnDestroy {
  private _calculationEngineService = inject(CalculationEngineApiService);
  private _calculationService = inject(CalculationManagementService);
  private _formBuilder = inject(FormBuilder);
  private _translateService = inject(TranslateService);
  private _toastrService = inject(ToastrService);
  private _router = inject(Router);
  private readonly _systemLayoutService = inject(SystemLayoutService);
  languageService = inject(LanguageService);

  private readonly _untilDestroy = AdaaHelper.untilDestroyed();
  private _subscription = new Subscription();

  language = Language;

  governments: PerformanceConfigType[];
  listOfInvalidRows: number[] = [];

  govCalculationForm: FormGroup = new FormGroup({});
  weights: FormGroup = new FormGroup({});
  config: FormGroup = new FormGroup({});
  AdaaHelper = AdaaHelper;

  manageCalculationManagementPermission: PermissionActionDescriptorType = {
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.manage,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.CALCULATION_MANAGEMENT,
        },
      ],
    },
  };

  public ngOnInit() {
    this._systemLayoutService.hasCycleChanged$.pipe(this._untilDestroy()).subscribe({
      next: () => {
        window.location.reload();
      },
    });

    this._systemLayoutService.hasActiveEntityChanged$.pipe(this._untilDestroy()).subscribe({
      next: () => {
        this._router.navigateByUrl("/console/calculation-management");
      },
    });

    this._getPerformanceConfig();
  }
  private _getPerformanceConfig() {
    this._calculationEngineService.getGovPerformanceConfig().subscribe({
      next: (response) => {
        this.governments = response.responseData;
        this._prepareForm(response.responseData);
        this._setValidation();
        this.govCalculationForm.valueChanges.subscribe(() => {
          this._setValidation();
        });
      },
    });
  }

  public get f() {
    return this.govCalculationForm.controls;
  }

  public get gov() {
    return <FormArray<FormGroup>>this.govCalculationForm.get("data");
  }

  public getConfig(gov: FormGroup) {
    return <FormArray<FormGroup>>gov.get("weights");
  }

  private _prepareForm(item: PerformanceConfigType[]) {
    this.govCalculationForm = this._formBuilder.group({
      data: this._formBuilder.array([]),
    });

    item.forEach((gov) => {
      this.config = this._formBuilder.group({
        id: gov.id,
        nameAE: gov.nameAE,
        nameEN: gov.nameEN,
        weights: this._formBuilder.array([]),
      });
      this.gov.push(this.config);
      gov.weights.forEach((weight) => {
        this.weights = this._formBuilder.group({
          id: weight.id,
          typeId: weight.typeId,
          typeNameAE: weight.typeNameAE,
          typeNameEN: weight.typeNameEN,
          value: weight.value,
        });
        (<FormArray>this.config.controls.weights).push(this.weights);
      });
    });
  }

  private _setValidation() {
    const result = this._calculationService.validateTable(this.govCalculationForm, this.listOfInvalidRows);
    this.govCalculationForm = result.formGroup;
    this.listOfInvalidRows = result.listOfInvalidRows;
  }

  public getColouredInvalidRows(element: number) {
    if (element) {
      this._setValidation();
      return this._calculationService.getColouredInvalidRows(element, this.listOfInvalidRows);
    }
    return false;
  }
  public submit() {
    this._setValidation();
    if (!this.govCalculationForm.invalid) {
      this._updateGovernmentCalculation();
    }
  }

  private _updateGovernmentCalculation() {
    const request = this._calculationService.preparingRequest(this.govCalculationForm, this.governments);
    this._subscription.add(
      this._calculationService.updateGovernmentCalculation({ weights: request }).subscribe((response) => {
        if (response.inError) return;
        this._toastrService.success(this._translateService.instant("notification.success.save"));
      })
    );
  }
  public cancel() {
    this._router.navigate(["console/calculation-management"]);
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
