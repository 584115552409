import { hasPermissionGuard } from "../../core/guards";
import { Constants } from "../../shared/constants/constants";
import { PermissionAction } from "../../shared/constants/enums";

export const submenuGuards = [
  hasPermissionGuard({
    actor: {
      modifier: "or",
      permissions: [
        {
          permissionAction: PermissionAction.view,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.STRATEGIC_PROJECT,
        },
        {
          permissionAction: PermissionAction.view,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.INITIATIVES,
        },
      ],
    },
    redirectTo: "/403",
  }),
];

export const projectsGuards = {
  create: [
    hasPermissionGuard({
      actor: {
        modifier: "and",
        permissions: [
          {
            permissionAction: PermissionAction.create,
            objectTypeId: Constants.CONSTANT_PERMISSIONS.INITIATIVES,
          },
        ],
      },
      redirectTo: "/403",
    }),
  ],
  edit: [
    hasPermissionGuard({
      actor: {
        modifier: "and",
        permissions: [
          {
            permissionAction: PermissionAction.manage,
            objectTypeId: Constants.CONSTANT_PERMISSIONS.INITIATIVES,
          },
        ],
      },
      redirectTo: "/403",
    }),
  ],
  view: [
    hasPermissionGuard({
      actor: {
        modifier: "and",
        permissions: [
          {
            permissionAction: PermissionAction.view,
            objectTypeId: Constants.CONSTANT_PERMISSIONS.INITIATIVES,
          },
        ],
      },
      redirectTo: "/403",
    }),
  ],
};

export const strategicProjectGuards = {
  create: [
    hasPermissionGuard({
      actor: {
        modifier: "and",
        permissions: [
          {
            permissionAction: PermissionAction.create,
            objectTypeId: Constants.CONSTANT_PERMISSIONS.STRATEGIC_PROJECT,
          },
        ],
      },
      redirectTo: "/403",
    }),
  ],
  edit: [
    hasPermissionGuard({
      actor: {
        modifier: "and",
        permissions: [
          {
            permissionAction: PermissionAction.manage,
            objectTypeId: Constants.CONSTANT_PERMISSIONS.STRATEGIC_PROJECT,
          },
        ],
      },
      redirectTo: "/403",
    }),
  ],
  view: [
    hasPermissionGuard({
      actor: {
        modifier: "and",
        permissions: [
          {
            permissionAction: PermissionAction.view,
            objectTypeId: Constants.CONSTANT_PERMISSIONS.STRATEGIC_PROJECT,
          },
        ],
      },
      redirectTo: "/403",
    }),
  ],
};
