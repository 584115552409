import { DtkpiInfoTabForm } from "./dtkpi";
import { EkpiInfoTabForm } from "./ekpi";
import { measurementUnitFormGroup } from "./lib";
import { MokpiInfoTabForm } from "./mokpi";
import { MtkpiInfoTabForm } from "./mtkpi";
import { NtkpiInfoTabForm } from "./ntkpi";
import { OpmInfoTabForm } from "./opm";
import { SkpiInfoTabForm } from "./skpi";
import { SrvkpiInfoTabForm } from "./srvkpi";

export const formByKpiType = {
  skpi: { infoTab: SkpiInfoTabForm, measurementDetails: measurementUnitFormGroup },
  opm: { infoTab: OpmInfoTabForm, measurementDetails: measurementUnitFormGroup },
  srvkpi: { infoTab: SrvkpiInfoTabForm, measurementDetails: measurementUnitFormGroup },
  mtkpi: { infoTab: MtkpiInfoTabForm, measurementDetails: measurementUnitFormGroup },
  ekpi: { infoTab: EkpiInfoTabForm, measurementDetails: measurementUnitFormGroup },
  dtkpi: { infoTab: DtkpiInfoTabForm, measurementDetails: measurementUnitFormGroup },
  ntkpi: { infoTab: NtkpiInfoTabForm, measurementDetails: measurementUnitFormGroup },
  mokpi: { infoTab: MokpiInfoTabForm, measurementDetails: measurementUnitFormGroup },
} as Record<string, unknown>;
