import type { Route } from "@angular/router";

import { checkIfLoggedInFn, pmoEntity } from "../../core/guards";
import { Constants } from "../../shared/constants/constants";
import { PageMode } from "../../shared/constants/enums";
import { createOtherEntitiesPageGuards, manageOtherEntitiesPageGuards, viewOtherEntitiesPageGuards } from "./_guards";
import { OtherEntitiesListComponent } from "./other-entities-list/other-entities-list.component";
import { OtherEntityEditorComponent } from "./other-entity-editor/other-entity-editor.component";

export const OTHER_ENTITIES_ROUTES: Route[] = [
  {
    path: "other-entities",
    canActivate: [checkIfLoggedInFn, pmoEntity, ...viewOtherEntitiesPageGuards],
    data: {
      breadcrumb: "breadcrumbs.other_entities",
      tableConfList: Constants.MAIN_TABLE_LIST_CONF_KEY.OTHER_ENTITIES_CONF_LIST,
    },
    children: [
      {
        path: "list",
        redirectTo: "",
      },
      {
        path: "",
        component: OtherEntitiesListComponent,
        canActivate: [checkIfLoggedInFn, pmoEntity, ...viewOtherEntitiesPageGuards],
        data: {
          breadcrumb: "breadcrumbs.other_entities",
          title: "breadcrumbs.other_entities",
        },
      },
      {
        path: "create",
        component: OtherEntityEditorComponent,
        canActivate: [checkIfLoggedInFn, pmoEntity, ...createOtherEntitiesPageGuards],
        data: {
          breadcrumb: "other_entities.create",
          title: "other_entities.create",
          pageMode: PageMode.create,
        },
      },
      {
        path: "edit/:id",
        component: OtherEntityEditorComponent,
        canActivate: [checkIfLoggedInFn, pmoEntity, ...manageOtherEntitiesPageGuards],
        data: {
          breadcrumb: "other_entities.edit",
          title: "other_entities.edit",
          pageMode: PageMode.edit,
        },
      },
      {
        path: ":id",
        component: OtherEntityEditorComponent,
        canActivate: [checkIfLoggedInFn, pmoEntity, ...viewOtherEntitiesPageGuards],
        data: {
          breadcrumb: "other_entities.view",
          title: "other_entities.view",
          pageMode: PageMode.view,
        },
      },
    ],
  },
];
