<adaa-data-table
    #governmentDirectionsTable
    [titleHeader]="'breadcrumbs.directions_module.list'"
    [configKey]="'gov_dir_conf_list'"
    [apiPrefix]="'govdirection'"
    [paramsString]="paramsString()"
    [itemTypeId]="constants.CONSTANT_PERMISSIONS.GOVERNMENT_DIRECTIONS"
    (actionClicked)="actionClicked($event)"
    (addNewClicked)="addNewClicked()"
></adaa-data-table>
