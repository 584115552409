import { inject } from "@angular/core";
import { type Route, Router } from "@angular/router";

import { checkIfLoggedInFn, NotArchivedPlan } from "../../core/guards";
import { useNewDS } from "../../core/utils";
import { Constants } from "../../shared/constants/constants";
import { PageMode } from "../../shared/constants/enums";
import { projectsGuards, strategicProjectGuards, submenuGuards } from "./_guards";

const strategicProjectRoutes: Route = {
  path: "strategic-projects",
  canActivate: [
    checkIfLoggedInFn,
    () => {
      const router = inject(Router);

      if (useNewDS()) return true;
      router.navigate(["/console", "initiatives", "list"]);
      return false;
    },
  ],
  children: [
    {
      path: "",
      canActivate: [...strategicProjectGuards.view],
      data: {
        title: "breadcrumbs.initiative_module.strategic_project_list",
        breadcrumb: "breadcrumbs.initiative_module.strategic_project_list",
        initiativeType: Constants.CONSTANT_STRATEGIC_PROJECT_INITIATIVETYPE,
        itemTypeId: Constants.CONSTANT_PERMISSIONS.STRATEGIC_PROJECT,
      },
      loadComponent: () => import("./projects-list/projects-list.component").then((m) => m.ProjectsListComponent),
    },
    {
      path: "create",
      canActivate: [...strategicProjectGuards.create, NotArchivedPlan()],
      data: {
        title: "breadcrumbs.initiative_module.strategic_project_new",
        breadcrumb: "breadcrumbs.initiative_module.strategic_project_new",
        initiativeType: Constants.CONSTANT_STRATEGIC_PROJECT_INITIATIVETYPE,
        pageMode: PageMode.create,
      },
      loadComponent: () => import("./project-editor/project-editor.component").then((m) => m.ProjectEditorComponent),
    },
    {
      path: "edit/:id",
      canActivate: [...strategicProjectGuards.edit, NotArchivedPlan()],
      data: {
        title: "breadcrumbs.initiative_module.strategic_project_edit",
        breadcrumb: "breadcrumbs.initiative_module.strategic_project_edit",
        initiativeType: Constants.CONSTANT_STRATEGIC_PROJECT_INITIATIVETYPE,
        pageMode: PageMode.edit,
      },
      loadComponent: () => import("./project-editor/project-editor.component").then((m) => m.ProjectEditorComponent),
    },
    {
      path: ":id",
      canActivate: [...strategicProjectGuards.view],
      data: {
        title: "initiatives.strategic_project",
        breadcrumb: "breadcrumbs.initiative_module.strategic_project_view",
        type: Constants.CONSTANT_STRATEGIC_PROJECT_INITIATIVETYPE,
      },
      loadComponent: () => import("./project-view/project-view.component").then((m) => m.ProjectViewComponent),
    },
  ],
};

const regularTaskRoutes: Route = {
  path: "regular-tasks",
  canActivate: [
    checkIfLoggedInFn,
    () => {
      const router = inject(Router);

      if (useNewDS()) return true;
      router.navigate(["/console", "initiatives", "list"]);
      return false;
    },
  ],
  children: [
    {
      path: "",
      canActivate: [...projectsGuards.view],
      data: {
        title: "breadcrumbs.initiative_module.regular_task_list",
        breadcrumb: "breadcrumbs.initiative_module.regular_task_list",
        initiativeType: Constants.CONSTANT_REGULAR_TASK_INITIATIVETYPE,
        itemTypeId: Constants.CONSTANT_PERMISSIONS.INITIATIVES,
      },
      loadComponent: () => import("./projects-list/projects-list.component").then((m) => m.ProjectsListComponent),
    },
    {
      path: "create",
      canActivate: [...projectsGuards.create, NotArchivedPlan()],
      data: {
        title: "breadcrumbs.initiative_module.regular_task_new",
        breadcrumb: "breadcrumbs.initiative_module.regular_task_new",
        initiativeType: Constants.CONSTANT_REGULAR_TASK_INITIATIVETYPE,
        pageMode: PageMode.create,
      },
      loadComponent: () => import("./project-editor/project-editor.component").then((m) => m.ProjectEditorComponent),
    },
    {
      path: "edit/:id",
      canActivate: [...projectsGuards.edit, NotArchivedPlan()],
      data: {
        title: "breadcrumbs.initiative_module.regular_task_edit",
        breadcrumb: "breadcrumbs.initiative_module.regular_task_edit",
        initiativeType: Constants.CONSTANT_REGULAR_TASK_INITIATIVETYPE,
        pageMode: PageMode.edit,
      },
      loadComponent: () => import("./project-editor/project-editor.component").then((m) => m.ProjectEditorComponent),
    },
    {
      path: ":id",
      canActivate: [...projectsGuards.view],
      data: {
        title: "initiatives.regular_task",
        breadcrumb: "breadcrumbs.initiative_module.regular_task_view",
        type: Constants.CONSTANT_REGULAR_TASK_INITIATIVETYPE,
      },
      loadComponent: () => import("./project-view/project-view.component").then((m) => m.ProjectViewComponent),
    },
  ],
};

export const INITIATIVES_ROUTES: Route[] = [
  {
    path: "initiatives",
    canActivate: [checkIfLoggedInFn],
    children: [
      {
        path: "",
        canActivate: [
          checkIfLoggedInFn,
          () => {
            const router = inject(Router);

            if (useNewDS()) return true;
            router.navigate(["/console", "initiatives", "list"]);
            return false;
          },
          ...submenuGuards,
        ],
        data: {
          title: "national_projects.projects",
          breadcrumb: {
            alias: "initiatives",
          },
        },
        loadComponent: () =>
          import("./projects-submenu/projects-submenu.component").then((m) => m.ProjectsSubmenuComponent),
      },
      strategicProjectRoutes,
      regularTaskRoutes,
      {
        path: "list",
        canActivate: [
          checkIfLoggedInFn,
          ...projectsGuards.view,
          () => {
            const router = inject(Router);

            if (!useNewDS()) return true;
            router.navigate(["/console", "initiatives"]);
            return false;
          },
        ],
        data: {
          title: "breadcrumbs.initiative_module.list",
          breadcrumb: { skip: true },
          initiativeType: Constants.CONSTANT_REGULAR_TASK_INITIATIVETYPE,
          itemTypeId: Constants.CONSTANT_PERMISSIONS.INITIATIVES,
        },
        loadComponent: () => import("./projects-list/projects-list.component").then((m) => m.ProjectsListComponent),
      },
      {
        path: "create",
        canActivate: [checkIfLoggedInFn, ...projectsGuards.create, NotArchivedPlan()],
        data: {
          title: "breadcrumbs.initiative_module.new",
          breadcrumb: "breadcrumbs.initiative_module.new",
          initiativeType: Constants.CONSTANT_STRATEGIC_PROJECT_INITIATIVETYPE,
          pageMode: PageMode.create,
        },
        loadComponent: () => import("./project-editor/project-editor.component").then((m) => m.ProjectEditorComponent),
      },
      {
        path: "edit/:id",
        canActivate: [checkIfLoggedInFn, ...projectsGuards.edit, NotArchivedPlan()],
        data: {
          title: "breadcrumbs.initiative_module.edit",
          breadcrumb: "breadcrumbs.initiative_module.edit",
          initiativeType: Constants.CONSTANT_STRATEGIC_PROJECT_INITIATIVETYPE,
          pageMode: PageMode.edit,
        },
        loadComponent: () => import("./project-editor/project-editor.component").then((m) => m.ProjectEditorComponent),
      },
      {
        path: ":id",
        canActivate: [checkIfLoggedInFn, ...projectsGuards.view],
        data: {
          title: "initiatives.initiative",
          breadcrumb: "breadcrumbs.initiative_module.view",
          type: Constants.CONSTANT_STRATEGIC_PROJECT_INITIATIVETYPE,
        },
        loadComponent: () => import("./project-view/project-view.component").then((m) => m.ProjectViewComponent),
      },
    ],
  },
];
