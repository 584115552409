import { Component, computed, inject, OnInit, signal } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

import { AdaaHelper } from "../../../../core/utils";
import { ProgressIndicatorComponent, RibbonComponent, TpProjectCardComponent } from "../../../../shared/components";
import { Constants } from "../../../../shared/constants/constants";
import { Language, TpAgmStatus } from "../../../../shared/constants/enums";
import { SystemLayoutService, TpDashboardService } from "../../../../shared/services";

@Component({
  selector: "adaa-tp-cards-dashboard",
  standalone: true,
  imports: [TranslateModule, TpProjectCardComponent, RibbonComponent, ProgressIndicatorComponent],
  templateUrl: "./tp-cards-dashboard.component.html",
  styleUrl: "./tp-cards-dashboard.component.scss",
})
export class TpCardsDashboardComponent implements OnInit {
  private _systemLayoutService = inject(SystemLayoutService);
  private _router = inject(Router);
  private _activatedRoute = inject(ActivatedRoute);
  tpService = inject(TpDashboardService);

  private _untilDestroy = AdaaHelper.untilDestroyed();
  TpAgmStatus = TpAgmStatus;
  constants = Constants;
  lang = signal<Language>(AdaaHelper.getCurrentLang());
  Language = Language;
  dashboardWidgets = computed(() => {
    const items = [
      {
        label: "national_projects.console_title",
        data: this.tpService.dashboardData()?.nationalProjectCount,
        icon: "adaa-icon-national_projects",
      },
      {
        label: "national_projects.national_targets",
        data: this.tpService.dashboardData()?.mainOutcomeCount,
        icon: "adaa-icon-targets",
      },
    ];

    return items;
  });

  public ngOnInit(): void {
    this.tpService.dashboardFilters.cycleId = +AdaaHelper.getLocalStorage(Constants.localStorageKeys.tpCycle);
    this.tpService.dashboardFilters.filterBy = undefined;

    this.tpService.resetFilters(true, false);
    this._getDataByPage();

    this._systemLayoutService.hasActiveEntityChanged$.pipe(this._untilDestroy()).subscribe(() => {
      this.tpService.initDashboard();

      this.tpService.getData(false);
    });
  }

  public navigateToProject(id: number): void {
    this._router.navigateByUrl(`/national-projects-dashboard/projects/${id}`);
  }

  public filterDashboard(status: TpAgmStatus) {
    this.tpService.dashboardFilters.status = status;
    this.tpService.getDashboardData();
  }

  private _getDataByPage() {
    this.tpService.initDashboard();

    if (this._router.url.indexOf("/owners") > -1) {
      this._activatedRoute.params.pipe(this._untilDestroy()).subscribe((params) => {
        if (AdaaHelper.isDefined(params["ownerIds"])) {
          this.tpService.dashboardFilters.ownerId = params["ownerIds"].split(",");
          this.tpService.getData(false);
        }
      });
    } else if (this._router.url.indexOf("/sectors") > -1) {
      this._activatedRoute.params.pipe(this._untilDestroy()).subscribe((params) => {
        if (AdaaHelper.isDefined(params["id"])) {
          this.tpService.dashboardFilters.sectorId = +params["id"];
          this.tpService.getData(false);
        }
      });
    } else if (this._router.url.indexOf("/main-sectors") > -1) {
      this._activatedRoute.params.pipe(this._untilDestroy()).subscribe((params) => {
        if (AdaaHelper.isDefined(params["id"])) {
          this.tpService.dashboardFilters.mainSectorId = +params["id"];
          this.tpService.getData(false);
        }
      });
    } else {
      this.tpService.getData(false);
    }
  }
}
