import { Component, inject } from "@angular/core";
import { Router } from "@angular/router";

import { PermissionActionDescriptorType } from "../../../../adaa-types";
import { AdaaHelper } from "../../../core/utils";
import { ConsoleIconComponent } from "../../../shared/components";
import { PermissionAction } from "../../../shared/constants/enums";
import { HasPermissionDirective } from "../../../shared/directives";
import { PermissionActionModelType } from "../../../shared/models";
import { ConsoleService, LanguageService, SystemLayoutService } from "../../../shared/services";

@Component({
  selector: "adaa-dimensions-submenu",
  imports: [ConsoleIconComponent, HasPermissionDirective],
  templateUrl: "./dimensions-submenu.component.html",
})
export class DimensionsSubmenuComponent {
  readonly languageService = inject(LanguageService);
  readonly consoleService = inject(ConsoleService);
  private _systemLayoutService = inject(SystemLayoutService);
  private _router = inject(Router);

  private readonly _untilDestroy = AdaaHelper.untilDestroyed();

  public ngOnInit(): void {
    this.consoleService.init();
    this._systemLayoutService.hasActiveEntityChanged$.pipe(this._untilDestroy()).subscribe({
      next: () => {
        if (!AdaaHelper.isPMOEntity()) this._router.navigateByUrl("/console");
      },
    });
  }

  public getPermission(permissionId: string | undefined): PermissionActionDescriptorType | undefined {
    if (permissionId) {
      const permissions = permissionId.split(",");
      const permissionsArray: PermissionActionModelType[] = [];

      permissions.forEach((e) => {
        permissionsArray.push(
          {
            permissionAction: PermissionAction.view,
            objectTypeId: +e,
          },
          {
            permissionAction: PermissionAction.manage,
            objectTypeId: +e,
          },
          {
            permissionAction: PermissionAction.execute,
            objectTypeId: +e,
          }
        );
      });

      return {
        actor: {
          modifier: "or",
          permissions: permissionsArray,
        },
      };
    } else return undefined;
  }
}
