import { Component, inject, input, OnInit, signal, viewChild } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { AdaaHelper } from "../../../core/utils";
import { ConfirmationModalComponent, DataTableComponent, FormActionButtonsComponent } from "../../../shared/components";
import { SendConfirmationModalComponent } from "../../../shared/components/general/send-confirmation-modal/send-confirmation-modal.component";
import { Constants } from "../../../shared/constants/constants";
import { AdaaBoolean, Language, PageMode } from "../../../shared/constants/enums";
import {
  CustomButton,
  EntityMapModelType,
  ItemAction,
  TableButtonClicked,
  TableInputChangedOutput,
  TableInputs,
} from "../../../shared/models";
import { AppService, CyclesApiService, SystemLayoutService } from "../../../shared/services";

@Component({
  selector: "adaa-strategic-cycles",
  standalone: true,
  imports: [DataTableComponent, FormActionButtonsComponent, TranslateModule],
  templateUrl: "./strategic-cycles-list.component.html",
  styleUrl: "./strategic-cycles-list.component.scss",
})
export class StrategicCyclesListComponent implements OnInit {
  private _router = inject(Router);
  private _cyclesApiService = inject(CyclesApiService);
  private _modalService = inject(NgbModal);
  private _appService = inject(AppService);
  private _toastrService = inject(ToastrService);
  private _translateService = inject(TranslateService);
  private _systemLayoutService = inject(SystemLayoutService);

  tableConfList = input.required<string>();
  inviteConfList = Constants.MAIN_TABLE_LIST_CONF_KEY.STRATEGIC_CYCLE_ENTITIES;
  pageMode = input.required<string>();
  sendInvite = signal<boolean>(false);
  planEntities = signal<EntityMapModelType[]>([]);
  allSent = signal<boolean>(false);
  formattedEntities = signal<{
    result: Partial<EntityMapModelType[]>;
    toSend: Partial<EntityMapModelType[]>;
  }>({
    result: [],
    toSend: [],
  });
  adaaDataTable = viewChild.required<DataTableComponent>("adaaDataTable");

  PageMode = PageMode;
  Constants = Constants;
  hideDeleteRules: ItemAction[] = [
    {
      operator: "equal",
      propertyName: "status",
      compareWith: Constants.OBJECT_STATUS.ARCHIVED,
    },
    {
      operator: "equal",
      propertyName: "status",
      compareWith: Constants.OBJECT_STATUS.ACTIVE,
    },
    {
      operator: "equal",
      propertyName: "status",
      compareWith: Constants.OBJECT_STATUS.CLOSED,
    },
  ];

  hideEditRules: ItemAction[] = [
    {
      operator: "equal",
      propertyName: "status",
      compareWith: Constants.OBJECT_STATUS.ARCHIVED,
    },
  ];

  customButtons: CustomButton[] = [
    {
      iconName: "adaa-icon-float-email",
      eventName: "send",
      iconTitle: "common.form.button.send_to_entities",
      onlyIf: (data: { status: number }) => data?.status !== Constants.OBJECT_STATUS.ARCHIVED,
    },
    {
      iconName: "adaa-icon-edit-disabled",
      eventName: "editDisabled",
      iconTitle: "tooltips.edit_disabled",
      onlyIf: (data: { status: number }) => data?.status === Constants.OBJECT_STATUS.ARCHIVED,
    },
  ];
  tableInputs: TableInputs[] = [
    {
      fieldName: "chooseEntity",
      defaultValue: true,
      checkboxMode: "trueFalse",
      disableRules: [
        {
          propertyName: "wasSent",
          compareWith: "Y",
          operator: "equal",
        },
      ],
    },
  ];

  untilDestroyed = AdaaHelper.untilDestroyed();

  public ngOnInit(): void {
    this._systemLayoutService.hasCycleChanged$.pipe(this.untilDestroyed()).subscribe({
      next: () => this.adaaDataTable().loadTableData(),
    });
    this._systemLayoutService.hasActiveEntityChanged$.pipe(this.untilDestroyed()).subscribe({
      next: () => this.adaaDataTable().loadTableData(),
    });
  }

  public addNewClicked() {
    this._router.navigateByUrl("/console/cycles/create");
  }

  private _getPlan(planId: number) {
    this._cyclesApiService.getByPlanId(planId).subscribe({
      next: (response) => {
        this.planEntities.set(response.responseData);
        if (!response.responseData.find((entity) => entity.wasSent === AdaaBoolean.N)) {
          this.allSent.set(true);
        }
        this.adaaDataTable().loadTableData();
      },
    });
  }

  private _deletePlan(planId: number) {
    const modal = this._modalService.open(ConfirmationModalComponent, {
      centered: true,
      size: "md",
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });

    modal.componentInstance.header = "cycle.delete_yes_no_title";
    modal.componentInstance.title = "cycle.delete_yes_no_information";

    modal.result.then((e) => {
      if (e) {
        this._cyclesApiService.deletePlan(planId).subscribe({
          next: () => {
            this._toastrService.success(this._translateService.instant("notification.success.remove"));
            this.adaaDataTable().loadTableData();
          },
        });
      }
    });
  }

  public getAction(value: TableButtonClicked) {
    switch (value.event) {
      case "view":
        this._router.navigateByUrl(`/console/cycles/${value.data.id}`);
        break;
      case "edit":
        this._router.navigateByUrl(`/console/cycles/edit/${value.data.id}`);
        break;
      case "send":
        this.sendInvite.set(true);
        this._getPlan(value.data.id);
        break;
      case "delete":
        this._deletePlan(value.data.id);
        break;

      default:
        break;
    }
  }

  public sendToEntities() {
    const formattedEntities = this.formattedEntities().result.length ? this.formattedEntities() : this.formatEntities();
    const modal = this._modalService.open(SendConfirmationModalComponent, {
      centered: true,
      size: "lg",
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });

    modal.componentInstance.header = "cycle.notify_entities_confirm";
    modal.componentInstance.title = "common.tables.entity_name";
    modal.componentInstance.items = formattedEntities.toSend.map((entity) =>
      AdaaHelper.getItemValueByToken(entity, "entityName")
    );

    modal.result.then((e) => {
      if (e) {
        this._cyclesApiService.sendToEntities(formattedEntities.result as EntityMapModelType[]).subscribe({
          next: () => {
            this._toastrService.success(this._translateService.instant("notification.success.save"));
            this.close();
          },
        });
      }
    });
  }

  public formatEntities(values?: TableInputChangedOutput[]) {
    let sent: Partial<EntityMapModelType>[] = this.planEntities().filter((entity) => entity.wasSent === AdaaBoolean.Y);
    let notSent: Partial<EntityMapModelType>[] = this.planEntities().filter(
      (entity) => entity.wasSent === AdaaBoolean.N
    );
    notSent = notSent.map((entity) => {
      const res = {
        id: entity.id,
        entityId: entity.entityId,
        entityNameAE: entity.entityNameAE,
        entityNameEN: entity.entityNameEN,
        planId: entity.planId,
        status: Constants.OBJECT_STATUS.ACTIVE,
        updateUser: entity.updateUser,
        updateTS: entity.updateTS,
        wasSent: entity.wasSent,
        flag: AdaaBoolean.Y,
        disabled: false,
      };
      return res;
    });
    sent = sent.map((entity) => {
      const res = {
        id: entity.id,
        entityId: entity.entityId,
        entityNameAE: entity.entityNameAE,
        entityNameEN: entity.entityNameEN,
        planId: entity.planId,
        status: entity.status,
        updateUser: entity.updateUser,
        updateTS: entity.updateTS,
        wasSent: entity.wasSent,
        flag: AdaaBoolean.Y,
        disabled: entity.wasSent === AdaaBoolean.Y,
      };
      return res;
    });

    if (values?.length) {
      for (const check of values) {
        const index = notSent.findIndex((entity) => entity.id === check.data.id);
        if (check.value === 1) {
          notSent[index].flag = AdaaBoolean.Y;
          delete notSent[index].chooseEntity;
        }
        if (check.value === 0) {
          notSent[index].flag = AdaaBoolean.N;
          notSent[index].chooseEntity = AdaaBoolean.N;
          notSent[index].status = Constants.OBJECT_STATUS.PLANNING;
        }
      }
    }

    const toSend = notSent.filter((entity) => entity.flag === AdaaBoolean.Y);
    const res = {
      result: [...notSent, ...sent] as EntityMapModelType[],
      toSend: toSend as EntityMapModelType[],
    };
    this.formattedEntities.set(res);
    return res;
  }

  public close() {
    this.sendInvite.set(false);
    this.allSent.set(false);
    setTimeout(() => {
      this.adaaDataTable()?.loadTableData();
    });
  }
}
