<div class="p-2">
    <adaa-data-table
        #adaaDataTable
        apiName="getLookupContentList"
        [titleHeader]="displayTitle()"
        [configKey]="'lookup_table_content_config'"
        [paramsString]="lookupId + '?itemType=67'"
        [itemTypeId]="constants.CONSTANT_PERMISSIONS.LOOKUP_MANAGEMENT"
        [enableAdd]="true"
        [enableEditAction]="true"
        [enableDeleteAction]="true"
        [enableViewAction]="true"
        [enablePrint]="true"
        [customActionButtons]="customButtons"
        (addNewClicked)="addNew()"
        (actionClicked)="getAction($event)"
    ></adaa-data-table>
</div>

<adaa-lookup-content-editor #editor [pageMode]="pageMode()" (editorClosed)="submit($event)"> </adaa-lookup-content-editor>

<adaa-float-action />
