<div>
    <div class="bg-white shadow-md rounded p-3 mb-3">
        <p class="fw-bold fs-4">{{ "user_profile.wizard.owned_objects" | translate }}</p>
        <adaa-data-table
            #adaaDataTable
            [configKey]="tableConfListResponsibilities"
            [isLocalData]="true"
            [localData]="userOwnedObjects()"
            [enableAdd]="false"
            [enableEditAction]="false"
            [enableDeleteAction]="false"
            (actionClicked)="navigate($event.data)"
        ></adaa-data-table>
    </div>
</div>

@if (entityId() === constants.CONSTANT_PMO_ID) {
    <div class="mt-3">
        <div class="bg-white shadow-md rounded p-3 mb-3">
            <p class="fw-bold fs-4">{{ "user_profile.wizard.responsible_for" | translate }}</p>
            <adaa-data-table
                #adaaDataTable
                [configKey]="tableConfListEntities"
                [isLocalData]="true"
                [localData]="userResponsibilities()"
                [enableAdd]="false"
                [enableActionsCell]="false"
            ></adaa-data-table>
        </div>
    </div>
}
