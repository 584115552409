import { hasPermissionGuard } from "../../core/guards";
import { Constants } from "../../shared/constants/constants";
import { PermissionAction } from "../../shared/constants/enums";

export const viewTransformationalProjectsPageGuards = [
  hasPermissionGuard({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.view,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.NATIONAL_PROJECTS,
        },
      ],
    },
    redirectTo: "/403",
  }),
];

export const manageTransformationalProjectsPageGuards = [
  hasPermissionGuard({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.manage,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.NATIONAL_PROJECTS,
        },
      ],
    },
    redirectTo: "/403",
  }),
];

export const createTransformationalProjectsPageGuards = [
  hasPermissionGuard({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.create,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.NATIONAL_PROJECTS,
        },
      ],
    },
    redirectTo: "/403",
  }),
];

export const viewTPMilestonePageGuards = [
  hasPermissionGuard({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.view,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.NATIONAL_PROJECT_MILESTONES,
        },
      ],
    },
    redirectTo: "/403",
  }),
];

export const manageTPMilestonePageGuards = [
  hasPermissionGuard({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.manage,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.NATIONAL_PROJECT_MILESTONES,
        },
      ],
    },
    redirectTo: "/403",
  }),
];

export const viewTPCyclesPageGuards = [
  hasPermissionGuard({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.view,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.TP_CYCLES,
        },
      ],
    },
    redirectTo: "/403",
  }),
];

export const createTPCyclesPageGuards = [
  hasPermissionGuard({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.create,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.TP_CYCLES,
        },
      ],
    },
    redirectTo: "/403",
  }),
];

export const manageTPCyclesPageGuards = [
  hasPermissionGuard({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.manage,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.TP_CYCLES,
        },
      ],
    },
    redirectTo: "/403",
  }),
];

export const viewTTargetsPageGuards = [
  hasPermissionGuard({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.view,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.TRANSFORMATIONAL_TARGETS,
        },
      ],
    },
    redirectTo: "/403",
  }),
];

export const createTTargetsPageGuards = [
  hasPermissionGuard({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.create,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.TRANSFORMATIONAL_TARGETS,
        },
      ],
    },
    redirectTo: "/403",
  }),
];

export const manageTTargetsPageGuards = [
  hasPermissionGuard({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.manage,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.TRANSFORMATIONAL_TARGETS,
        },
      ],
    },
    redirectTo: "/403",
  }),
];

export const viewAgreementPageGuards = [
  hasPermissionGuard({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.view,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.TRANSFORMATIONAL_PROJECT_AGREEMENT,
        },
      ],
    },
    redirectTo: "/403",
  }),
];

export const viewTPSubMenu = [
  hasPermissionGuard({
    actor: {
      modifier: "or",
      permissions: [
        {
          permissionAction: PermissionAction.view,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.NATIONAL_PROJECTS,
        },
        {
          permissionAction: PermissionAction.view,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.NATIONAL_PROJECT_MILESTONES,
        },
        {
          permissionAction: PermissionAction.view,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.TP_CYCLES,
        },
        {
          permissionAction: PermissionAction.view,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.TRANSFORMATIONAL_TARGETS,
        },
        {
          permissionAction: PermissionAction.view,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.TRANSFORMATIONAL_PROJECT_AGREEMENT,
        },
        {
          permissionAction: PermissionAction.view,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.MOKPI,
        },
        {
          permissionAction: PermissionAction.view,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.MTKPI,
        },
      ],
    },
    redirectTo: "/403",
  }),
];
