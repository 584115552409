<div class="card col-12 rounded-1 bg-white p-0 mb-2 mt-3 kpi-benchmark">
    <div class="card-title p-2 mb-0 w-100">
        <div class="row">
            <div class="col-sm-4 col-xs-12">
                <adaa-form-dropdown
                    [enableTooltips]="true"
                    [setDefaultValue]="selectedBenchmarkId()"
                    [options]="benchmarksOptions()"
                    (inputChanges)="selectBenchmark($event)"
                />
            </div>
            <div class="col-sm-4 col-xs-12 d-flex justify-content-center align-items-center">
                <h5 class="fw-bold mb-0">
                    {{ "kpi.benchmarking" | translate }}
                </h5>
            </div>
            <div class="col-sm-4 col-xs-12">
                @if (attachmentsOptions().length > 0) {
                    <adaa-form-dropdown
                        [enableTooltips]="true"
                        [setDefaultValue]="selectedAttachmentId()"
                        [options]="attachmentsOptions()"
                        (inputChanges)="selectAttachment($event)"
                    />
                }
            </div>
        </div>
    </div>

    <div class="card-body px-1 py-2">
        <div class="d-flex w-100 justify-content-end mb-1">
            <adaa-chart-action-buttons
                [elementId]="'kpiBenchmarkChart'"
                [csvChartInfo]="selectedBenchmark() | translateToken: 'name'"
            ></adaa-chart-action-buttons>
        </div>

        @if (chartOptions() && chartData()) {
            <canvas
                #chart
                id="kpiBenchmarkChart"
                class="kpi-benchmark--chart"
                baseChart
                [data]="chartData()"
                [options]="chartOptions()"
                [plugins]="lineChartPlugins"
                type="line"
            ></canvas>
            <div id="chart-legend" class="custom-legend"></div>
        }
    </div>
</div>
