import { CommonModule } from "@angular/common";
import { Component, inject, OnInit } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

import { PermissionActionDescriptorType } from "../../../adaa-types";
import { AdaaHelper } from "../../core/utils";
import { ConsoleIconComponent } from "../../shared/components";
import { Constants } from "../../shared/constants/constants";
import { PermissionAction } from "../../shared/constants/enums";
import { HasPermissionDirective } from "../../shared/directives";
import type { PermissionActionModelType } from "../../shared/models";
import { ConsoleService, LanguageService } from "../../shared/services";

@Component({
  standalone: true,
  imports: [HasPermissionDirective, TranslateModule, CommonModule, ConsoleIconComponent],
  templateUrl: "./console.component.html",
  styleUrl: "./console.component.scss",
})
export class ConsoleComponent implements OnInit {
  languageService = inject(LanguageService);
  consoleService = inject(ConsoleService);

  public get isPmo() {
    const entityId = AdaaHelper.getLocalStorage(Constants.localStorageKeys.currentEntity, {
      type: "prop",
      property: "id",
    });

    return Number(entityId) === Constants.CONSTANT_PMO_ID;
  }

  public ngOnInit(): void {
    this.consoleService.init();
  }

  public getPermission(permissionId: string | undefined): PermissionActionDescriptorType | undefined {
    if (permissionId) {
      const permissions = permissionId.split(",");
      const permissionsArray: PermissionActionModelType[] = [];

      permissions.forEach((e) => {
        permissionsArray.push(
          {
            permissionAction: PermissionAction.view,
            objectTypeId: +e,
          },
          {
            permissionAction: PermissionAction.manage,
            objectTypeId: +e,
          },
          {
            permissionAction: PermissionAction.execute,
            objectTypeId: +e,
          }
        );
      });

      return {
        actor: {
          modifier: "or",
          permissions: permissionsArray,
        },
      };
    } else return undefined;
  }
}
