import { Route } from "@angular/router";

import { checkIfLoggedInFn, notPmoEntity } from "../../core/guards";
import { viewPlanNavigationPageGuards } from "./_guards";
import { PlanNavigationComponent } from "./plan-navigation.component";

export const PLAN_NAVIGATION_ROUTES: Route[] = [
  {
    path: "navigation",
    canActivate: [checkIfLoggedInFn, notPmoEntity, ...viewPlanNavigationPageGuards],
    component: PlanNavigationComponent,
    data: {
      title: "navigation.plan_navigation",
      breadcrumb: "navigation.plan_navigation",
    },
  },
];
