import { Component, inject, input, OnInit, signal, viewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { TranslateTokenPipe } from "../../../../core/pipes";
import { AdaaHelper } from "../../../../core/utils";
import {
  ConfirmationModalComponent,
  DataTableComponent,
  FormDropdownComponent,
  FormInputComponent,
} from "../../../../shared/components";
import { MetricsValueInputComponent } from "../../../../shared/components/modals/metrics-value-input/metrics-value-input.component";
import { Constants } from "../../../../shared/constants/constants";
import { Language, PageMode } from "../../../../shared/constants/enums";
import {
  DataEntryMetricModel,
  DataEntryMetricResponse,
  ItemAction,
  PropTypeModelType,
  TableButtonClicked,
  ValueText,
} from "../../../../shared/models";
import { AppService, MetricApiService, PropertiesService, SystemLayoutService } from "../../../../shared/services";

@Component({
  selector: "adaa-data-entry-metrics-editor",
  standalone: true,
  imports: [
    TranslateModule,
    TranslateTokenPipe,
    DataTableComponent,
    FormInputComponent,
    MetricsValueInputComponent,
    FormDropdownComponent,
  ],
  templateUrl: "./data-entry-metrics-editor.component.html",
  styleUrl: "./data-entry-metrics-editor.component.scss",
})
export class DataEntryMetricsEditorComponent implements OnInit {
  private _metricsApiService = inject(MetricApiService);
  private _activatedRoute = inject(ActivatedRoute);
  private _propsService = inject(PropertiesService);
  private _translateService = inject(TranslateService);
  private _toastrService = inject(ToastrService);
  private _modalService = inject(NgbModal);
  private _appService = inject(AppService);
  private _systemLayoutService = inject(SystemLayoutService);
  private _router = inject(Router);

  metricId: number;
  dataEntryIndex: number;
  metric = signal<DataEntryMetricModel | undefined>(undefined);
  dataTypes = signal<PropTypeModelType[]>([]);
  dataEntries = signal<DataEntryMetricResponse[]>([]);
  value = signal<number | null | undefined>(undefined);
  pageMode = input.required<string>();
  valueInput = viewChild.required<MetricsValueInputComponent>("valueInput");
  adaaDataTable = viewChild.required<DataTableComponent>("adaaDataTable");
  PageMode = PageMode;
  Constants = Constants;
  untilDestroy = AdaaHelper.untilDestroyed();

  month = signal<number>(1);
  year = signal<number>(new Date().getFullYear());

  months = signal<ValueText[]>([]);
  years = signal<ValueText[]>([]);

  public get disableRules(): ItemAction[] {
    if (AdaaHelper.isPMOEntity()) {
      return [];
    } else
      return [
        {
          propertyName: "dataStatus",
          compareWith: "N",
          operator: "equal",
        },
      ];
  }

  getType = (value: string): string => {
    return this._translateService.instant(`main_types.${value}`);
  };

  public getDataType(value: number | undefined) {
    const field = AdaaHelper.getFieldLanguage("name");
    const type = this.dataTypes().find((type) => type.id === value);
    if (type) {
      return type[field as keyof PropTypeModelType];
    }
    return "";
  }

  public ngOnInit(): void {
    this._setMonthsYearsDdl();

    this._systemLayoutService.hasActiveEntityChanged$.pipe(this.untilDestroy()).subscribe({
      next: () => this._router.navigateByUrl("/console/data-entry/metrics"),
    });

    this._propsService.getPropById(Constants.CONSTANT_MEASUREMENT_UNIT_METRICS).subscribe({
      next: (response) => this.dataTypes.set(response.responseData),
    });
    const metricId = this._activatedRoute.snapshot?.paramMap?.get("id");
    if (metricId) {
      this.metricId = +metricId;
      this._metricsApiService.getById(this.metricId).subscribe({
        next: (response) => this.metric.set(response.responseData),
      });

      this._getDataEntries();
    }
  }

  private _getDataEntries() {
    this._metricsApiService.getDataEntryByMetricId(this.metricId).subscribe({
      next: (response) => this.dataEntries.set(response.responseData),
    });
  }

  public getAction(value: TableButtonClicked) {
    if (AdaaHelper.isDefinedAndNotEmpty(value.index)) this.dataEntryIndex = value.index;
    switch (value.event) {
      case "edit":
        this.valueInput().value.set(value.data.actual);
        this.valueInput().open();
        break;
      case "delete":
        this._deleteEntry(value.data.id);
        break;

      default:
        break;
    }
  }

  private _deleteEntry(id: number) {
    const modal = this._modalService.open(ConfirmationModalComponent, {
      centered: true,
      size: "md",
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });

    modal.componentInstance.header = "data_entry.metric_value_delete_yes_no_title";
    modal.componentInstance.title = "data_entry.metric_value_delete_yes_no_information";

    modal.result.then((e) => {
      if (e) {
        this._metricsApiService.deleteDataEntry(id).subscribe({
          next: (response) => {
            if (response.inError) return;
            this._toastrService.success(this._translateService.instant("notification.success.remove"));
            this._getDataEntries();
          },
        });
      }
    });
  }

  public confirmModal() {
    if (!this.value()) {
      this._toastrService.error(this._translateService.instant("data_entry.value_empty"));
      return;
    }

    const modal = this._modalService.open(ConfirmationModalComponent, {
      centered: true,
      size: "md",
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });

    modal.componentInstance.header = "data_entry.metric_add_edit_title";
    modal.componentInstance.title = "data_entry.metric_add_edit_information";

    modal.result.then((e) => {
      if (e) this._addDataEntry();
    });
  }

  private _addDataEntry() {
    const month = this.month();
    const year = this.year();
    const entityId = AdaaHelper.getLocalStorage(Constants.localStorageKeys.currentEntity, {
      type: "prop",
      property: "id",
    });

    if (
      this.dataEntries().find((entry) => entry.month === month) &&
      this.dataEntries().find((entry) => entry.year === year)
    ) {
      this._toastrService.error(this._translateService.instant("data_entry.cannotAddMessage"));
      return;
    }

    const result = {
      updated: false,
      periodDate: new Date(year, month - 1).getTime(),
      month,
      year,
      actual: this.value()?.toString(),
      metricId: this.metric()?.id,
      metricType: this.metric()?.type,
      dataStatus: "N",
      entityId: entityId as string,
    };

    this._metricsApiService.updateDataEntryMetrics([result]).subscribe({
      next: (response) => {
        if (response.inError) return;
        const additionalFields = response.additionalFields as { [key: string]: string };
        if (additionalFields[Constants.CONSTANT_MEASUREMENT_UNIT_METRICS.toString()]) {
          this._toastrService.warning(this._translateService.instant("data_entry.notifications.CODE_27"));
        }
        this._getDataEntries();
        this.value.set(null);
      },
    });
  }

  public setValue(value: number | null) {
    if (value) {
      let entries: DataEntryMetricResponse[] = AdaaHelper.clone(this.dataEntries());
      const entry = this.dataEntries()[this.dataEntryIndex];

      entry.actual = value?.toString();
      entries.splice(this.dataEntryIndex, 1, entry);
      entries = entries.map((entry) => {
        return {
          metricNameAE: entry.metricNameAE,
          metricNameEN: entry.metricNameEN,
          metricDscAE: entry.metricDscAE,
          metricDscEN: entry.metricDscEN,
          year: entry.year,
          month: entry.month,
          synced: entry.synced,
          id: entry.id,
          metricId: entry.metricId,
          metricType: entry.metricType,
          periodId: entry.periodId,
          periodDate: entry.periodDate,
          entityId: entry.entityId.toString(),
          dataStatus: entry.dataStatus,
          actual: entry.actual,
          updateUser: entry.updateUser,
          updateTS: entry.updateTS,
          revId: entry.revId,
          ignored: entry.ignored,
          actualOverride: entry.actualOverride,
          smDate: entry.smDate,
          updated: true,
        };
      });

      this._metricsApiService.updateDataEntryMetrics(entries).subscribe({
        next: (response) => {
          if (response.inError) return;
          const additionalFields = response.additionalFields as { [key: string]: string };
          if (additionalFields[Constants.CONSTANT_MEASUREMENT_UNIT_METRICS.toString()]) {
            this._toastrService.success(this._translateService.instant("data_entry.notifications.CODE_27"));
          }
        },
        complete: () => {
          this._getDataEntries();
          this.value.set(null);
        },
      });
    }
  }

  private _setMonthsYearsDdl(): void {
    const months: ValueText[] = [];
    for (let index = 1; index <= 12; index++) months.push({ value: index, text: index.toString() });
    this.months.set(months);

    const years: ValueText[] = [];
    const currentYear = new Date().getFullYear();
    for (let index = currentYear; index >= 2000; index--) years.push({ value: index, text: index.toString() });
    this.years.set(years);
  }
}
