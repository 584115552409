import { DatePipe } from "@angular/common";
import { Component, computed, inject, input, OnInit, signal, viewChild } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { FaIconLibrary, FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import moment from "moment";
import { ToastrService } from "ngx-toastr";
import { forkJoin } from "rxjs";

import { DubaiTimePipe } from "../../../core/pipes";
import { AdaaHelper } from "../../../core/utils";
import {
  ChangesJustificationComponent,
  DataTableComponent,
  FormActionButtonsComponent,
  FormDropdownComponent,
  FormInputComponent,
  FormInputDateComponent,
  HelpdeskCommentsComponent,
  SupportCenterAttachmentComponent,
} from "../../../shared/components";
import { Constants } from "../../../shared/constants/constants";
import { AdaaBoolean, PageMode } from "../../../shared/constants/enums";
import {
  CreateHelpdeskAttachment,
  HelpdeskComment,
  HelpdeskItem,
  JustificationHistory,
  LoginResponse,
  TableButtonClicked,
  UserGroup,
  ValidatorModelType,
  ValueText,
} from "../../../shared/models";
import {
  LanguageService,
  PropertiesService,
  SupportCenterApiService,
  UserGroupsApiService,
  UsersApiService,
  ValidatorApiService,
} from "../../../shared/services";

@Component({
  selector: "adaa-support-center-editor",
  standalone: true,
  imports: [
    FormInputComponent,
    ReactiveFormsModule,
    TranslateModule,
    FormDropdownComponent,
    DataTableComponent,
    SupportCenterAttachmentComponent,
    FormActionButtonsComponent,
    FormInputDateComponent,
    HelpdeskCommentsComponent,
    FontAwesomeModule,
    DubaiTimePipe,
    DatePipe,
  ],
  templateUrl: "./support-center-editor.component.html",
  styleUrl: "./support-center-editor.component.scss",
})
export class SupportCenterEditorComponent implements OnInit {
  private _formBuilder = inject(FormBuilder);
  private _languageService = inject(LanguageService);
  private _propsService = inject(PropertiesService);
  private _supportCenterApiService = inject(SupportCenterApiService);
  private _userGroupsApiService = inject(UserGroupsApiService);
  private _toastrService = inject(ToastrService);
  private _router = inject(Router);
  private _iconLibrary = inject(FaIconLibrary);
  private _validatorApiService = inject(ValidatorApiService);
  private _usersApiService = inject(UsersApiService);
  private _modalService = inject(NgbModal);

  pageMode = input.required<string>();
  id = input<string | undefined>();
  url = input<string | undefined>();

  attachment = viewChild<SupportCenterAttachmentComponent>("attachment");

  hasHelpdeskGroup = signal<boolean>(false);
  userGroups = signal<UserGroup[]>([]);
  attachmentPageMode = signal<string>("");
  selected = signal<string>("");
  submitted = signal<boolean>(false);
  attachments = signal<CreateHelpdeskAttachment[]>([]);
  comments = signal<HelpdeskComment[]>([]);
  formValidation = signal<ValidatorModelType | undefined>(undefined);
  justificationHistory = signal<JustificationHistory | undefined>(undefined);
  helpdesk = signal<HelpdeskItem | undefined>(undefined);

  AdaaHelper = AdaaHelper;
  Constants = Constants;
  ticketForm: FormGroup = new FormGroup({});
  PageMode = PageMode;
  currentAttachmentId: number;

  ticketTypes = signal<ValueText[]>([]);
  ticketPriorities = signal<ValueText[]>([]);
  ticketItems = signal<ValueText[]>([]);
  ticketSections = signal<ValueText[]>([]);
  ticketStatus = signal<ValueText[]>([]);
  helpdeskGroups = signal<ValueText[]>([]);

  pageTitle = computed(() => {
    if (this.pageMode() === PageMode.create) return "helpdesk.create";
    else if (this.pageMode() === PageMode.edit) return "helpdesk.edit";
    else return "breadcrumbs.help_desk_module.view";
  });
  attachmentsFiltered = computed(() => {
    return this.attachments().filter((attachment) => attachment.status !== Constants.OBJECT_STATUS.REMOVE);
  });
  canSeeManagementInfo = computed(() => {
    return (
      this.userGroups().some((e) => e.id === this.helpdesk()?.assignedId && e.enabled === AdaaBoolean.Y) &&
      this.pageMode() !== PageMode.create
    );
  });

  public get displayLabel(): string {
    return this.ticketForm.get("title")?.value;
  }

  public ngOnInit(): void {
    this._iconLibrary.addIcons(faPlus);

    this._prepareForm();
    this._loadUserGroups();
  }

  public submit() {
    const priorityChanged = this._checkIsFiledChanged("priorityId");
    const assignedChanged = this._checkIsFiledChanged("assignedId");
    const expectedCompletionDateChanged = this._checkIsFiledChanged("expectedCompletionDate");
    const currentStatusChanged =
      this._checkIsFiledChanged("currentStatusId") &&
      this.ticketForm.get("currentStatusId")?.value === Constants.SUPPORT_TICKET_STATUS.RESOLVED;

    if (priorityChanged || assignedChanged || expectedCompletionDateChanged || currentStatusChanged)
      this._openChangesJustification(
        priorityChanged,
        assignedChanged,
        expectedCompletionDateChanged,
        currentStatusChanged
      );
    else this._doSubmit();
  }

  public goBack() {
    this._router.navigateByUrl("console/helpdesk");
  }

  public getAction(value: TableButtonClicked) {
    switch (value.event) {
      case "view":
        this.attachmentPageMode.set(PageMode.view);
        this.attachment()?.attachmentForm.patchValue(value.data);
        this.attachment()?.attachmentForm.get("file")?.setValue({
          id: value.data.id,
          filename: value.data.filename,
        });
        this.attachment()?.attachmentForm.disable();
        this.attachment()?.open();
        break;
      case "edit":
        this.attachmentPageMode.set(PageMode.edit);
        this.attachment()?.attachmentForm.patchValue(value.data);
        this.attachment()?.attachmentForm.get("file")?.setValue({
          id: value.data.id,
          filename: value.data.filename,
        });
        this.currentAttachmentId = value.data.id;
        this.attachment()?.attachmentForm.enable();
        this.attachment()?.open();
        break;
      case "delete": {
        const attachments: CreateHelpdeskAttachment[] = AdaaHelper.clone(this.attachments());
        const index = attachments.findIndex((attachment) => attachment.id === value.data.id);
        attachments[index].status = Constants.OBJECT_STATUS.REMOVE;
        this.attachments.set(attachments);
        break;
      }

      default:
        break;
    }
  }

  public addAttachment(attachment: CreateHelpdeskAttachment) {
    const attachments: CreateHelpdeskAttachment[] = AdaaHelper.clone(this.attachments());
    const index = attachments.findIndex((item) => item.id === attachment.id);
    if (index > -1) {
      attachments.splice(index, 1, attachment);
      this.attachments.set(attachments);
    } else {
      const index = attachments.findIndex((item) => item.id === this.currentAttachmentId);
      if (index > -1) {
        attachments[index].status = Constants.OBJECT_STATUS.REMOVE;
      }
      attachments.push(attachment);
      this.attachments.set(attachments);
    }
  }

  public addComment(comment: string) {
    const time = moment().format(Constants.dateTime);
    const comments = AdaaHelper.clone(this.comments());
    const currentUser = AdaaHelper.getLocalStorage(Constants.localStorageKeys.user, {
      type: "json",
    }) as LoginResponse;

    comments.push({
      time,
      comment,
      userName: AdaaHelper.getItemValueByToken(currentUser, "name"),
    });
    this.comments.set(comments);
  }

  public highlight(index: number) {
    const value = this.comments()[index];
    this.selected.set(value.comment);
  }

  private _loadUserGroups() {
    const currentUserId = AdaaHelper.getLocalStorage(Constants.localStorageKeys.user, {
      type: "prop",
      property: "id",
    });

    const requests = {
      userGroups: this._usersApiService.getAllUserGroupsByUserId(+currentUserId),
      helpDeskGroup: this._supportCenterApiService.getUserHelpdeskGroup(),
    };

    forkJoin(requests).subscribe({
      next: (results) => {
        this.hasHelpdeskGroup.set(
          AdaaHelper.isDefined(results.helpDeskGroup.responseData) &&
            results.helpDeskGroup.responseData.typeId === Constants.CONSTANT_USER_GROUP_TYPE_HELPDESK
        );

        this.userGroups.set(results.userGroups.responseData ?? []);
      },
      complete: () => {
        !this.hasHelpdeskGroup() || this.pageMode() === PageMode.view
          ? this.ticketForm.get("assignedId")?.disable()
          : this.ticketForm.get("assignedId")?.enable();

        this._initPage();
        if (this.id()) this._initTicket(+this.id()!);
      },
    });
  }

  private _initTicket(id: number) {
    const requests = {
      ticket: this._supportCenterApiService.getById(id),
      attachments: this._supportCenterApiService.getHelpdeskAttachments(id),
      comments: this._supportCenterApiService.getHelpdeskComments(id),
    };
    forkJoin(requests).subscribe({
      next: (results) => {
        if (results.ticket.inError) {
          this._toastrService.error(this._languageService.translate("notification.error.unknown_object"));
          this.goBack();
        }

        this.ticketForm.patchValue(results.ticket.responseData);
        this.helpdesk.set(results.ticket.responseData);
        this.attachments.set(results.attachments.responseData);
        this.comments.set(results.comments.responseData);
      },
    });
  }

  private _initPage() {
    const requests = {
      validation: this._validatorApiService.searchByKey(Constants.VALIDATORS_CONF_KEY.VALIDATION_HELPDESK),
      props: this._propsService.getPropByIdList([
        Constants.CONSTANT_HELP_DESK_TICKET_TYPE,
        Constants.CONSTANT_TICKET_PRIORITY,
        Constants.CONSTANT_TICKET_ITEM,
        Constants.CONSTANT_TICKET_SECTION,
        Constants.CONSTANT_STATUS,
      ]),
      helpdeskGroups: this._userGroupsApiService.getallHelpdesk(),
    };

    forkJoin(requests).subscribe({
      next: (results) => {
        this.formValidation.set(results.validation.responseData);

        const ticketTypes = results.props.responseData.filter(
          (e) => e.propType === Constants.CONSTANT_HELP_DESK_TICKET_TYPE
        );
        const ticketPriorities = results.props.responseData.filter(
          (e) => e.propType === Constants.CONSTANT_TICKET_PRIORITY
        );
        const ticketItems = results.props.responseData.filter((e) => e.propType === Constants.CONSTANT_TICKET_ITEM);
        const ticketSections = results.props.responseData.filter(
          (e) => e.propType === Constants.CONSTANT_TICKET_SECTION
        );
        const ticketStatus = results.props.responseData.filter((e) => e.propType === Constants.CONSTANT_STATUS);

        this.ticketTypes.set(AdaaHelper.setDropdownArray(ticketTypes, "id", AdaaHelper.getFieldLanguage("name")));
        this.ticketPriorities.set(
          AdaaHelper.setDropdownArray(ticketPriorities, "id", AdaaHelper.getFieldLanguage("name"))
        );
        this.ticketItems.set(AdaaHelper.setDropdownArray(ticketItems, "id", AdaaHelper.getFieldLanguage("name")));
        this.ticketSections.set(AdaaHelper.setDropdownArray(ticketSections, "id", AdaaHelper.getFieldLanguage("name")));
        this.ticketStatus.set(AdaaHelper.setDropdownArray(ticketStatus, "id", AdaaHelper.getFieldLanguage("name")));
        this.helpdeskGroups.set(
          AdaaHelper.setDropdownArray(results.helpdeskGroups.responseData, "id", AdaaHelper.getFieldLanguage("name"))
        );
      },
      complete: () => {
        if (this.pageMode() === PageMode.create && this.url())
          this.ticketForm.get("title")?.setValue(this._getTicketTitle(this.url()!));
      },
    });
  }

  private _prepareForm() {
    const isViewMode = this.pageMode() === PageMode.view;

    this.ticketForm = this._formBuilder.group({
      title: [{ value: null, disabled: isViewMode }, Validators.required],
      description: [{ value: null, disabled: isViewMode }, Validators.required],
      typeId: [{ value: null, disabled: isViewMode }, Validators.required],
      priorityId: { value: null, disabled: isViewMode },
      sectionId: { value: this.url() ? this._getTicketSection(this.url()!) : null, disabled: true },
      objectId: { value: this.url() ? this._getTicketItem(this.url()!) : null, disabled: isViewMode },
      url: { value: this.url() ?? null, disabled: true },
      creationDate: { value: null, disabled: true },
      expectedCompletionDate: { value: null, disabled: true },
      supportRequestStatus: { value: null, disabled: true },
      helpdeskReference: { value: null, disabled: true },
      currentStatusId: { value: null, disabled: isViewMode },
      assignedId: { value: null, disabled: true },
      userNameEN: { value: null, disabled: true },
      userNameAE: { value: null, disabled: true },
    });
  }

  private _doSubmit() {
    this.submitted.set(true);
    if (this.ticketForm.invalid) {
      this._toastrService.error(this._languageService.translate("common.form.label.missing_information"));
      return;
    }
    let comments = this.comments()
      .map((value) => {
        if (!value.id) {
          return {
            comment: value.comment,
          };
        }
        return null;
      })
      .filter((x) => x !== null);
    comments = [
      ...comments,
      ...(this.justificationHistory()?.assignChangeReason
        ? [{ comment: this.justificationHistory()?.assignChangeReason ?? "" }]
        : []),
      ...(this.justificationHistory()?.resolvedChangeReason
        ? [{ comment: this.justificationHistory()?.resolvedChangeReason ?? "" }]
        : []),
    ];

    const history = { ...this.justificationHistory() };

    let result = {
      ...this.helpdesk(),
      ...this.ticketForm.getRawValue(),
      attachments: this.attachments(),
      comments: comments.length ? comments : [],
    };

    if (this.pageMode() === PageMode.edit) {
      result = {
        ...result,
        id: this.helpdesk()?.id,
        auxObjId: null,
        history: this.justificationHistory() ? history : {},
      };
    }

    this._supportCenterApiService.saveItem(result, this.pageMode() === PageMode.edit).subscribe({
      next: (response) => {
        if (response.inError) return;
        this._toastrService.success(this._languageService.translate("notification.success.save"));
        this.goBack();
      },
    });
  }

  private _checkIsFiledChanged(
    filedName: "priorityId" | "assignedId" | "expectedCompletionDate" | "currentStatusId"
  ): boolean {
    return (
      this.helpdesk() != null &&
      this.ticketForm.get(filedName) != null &&
      this.helpdesk()![filedName] !== this.ticketForm.get(filedName)!.value
    );
  }

  private _openChangesJustification(
    priorityChanged: boolean,
    assignedChanged: boolean,
    expectedCompletionDateChanged: boolean,
    currentStatusChanged: boolean
  ) {
    const modal = this._modalService.open(ChangesJustificationComponent, {
      centered: true,
      backdrop: "static",
      size: "xl",
      modalDialogClass: this._languageService.modalDirection(),
    });

    modal.componentInstance.priorityChanged = priorityChanged;
    modal.componentInstance.assignedChanged = assignedChanged;
    modal.componentInstance.expectedCompletionDateChanged = expectedCompletionDateChanged;
    modal.componentInstance.currentStatusChanged = currentStatusChanged;

    modal.result.then((result) => {
      if (!result) return;
      this.justificationHistory.set(result);
      this._doSubmit();
    });
  }

  private _getTicketItem(url: string): number {
    if (/\/create$/.test(url) || /\/edit\/\d+$/.test(url)) return Constants.TICKET_ITEMS.FORM;
    else if (/\/\d+$/.test(url)) return Constants.TICKET_ITEMS.CARD;
    else return Constants.TICKET_ITEMS.LIST;
  }

  /**
   * NOTE: if you have a matching url always check for the longer one first
   * Example: /users-roles should be before /users
   * @param url
   * @returns
   */
  private _getTicketSection(url: string, returnNull: boolean = false): number | null {
    if (url.includes("/users-roles")) return Constants.TICKET_SECTIONS.USER_ROLES;
    else if (url.includes("/users-groups")) return Constants.TICKET_SECTIONS.USER_GROUPS;
    else if (url.includes("/users")) return Constants.TICKET_SECTIONS.USERS;
    else if (url.includes("/entities")) return Constants.TICKET_SECTIONS.ENTITIES;
    else if (url.includes("/metrics")) return Constants.TICKET_SECTIONS.METRICS;
    else if (url.includes("/dimensions")) return Constants.TICKET_SECTIONS.DIMENSIONS;
    else if (url.includes("/attributes")) return Constants.TICKET_SECTIONS.ATTRIBUTES;
    else if (url.includes("/pmo-objectives") || url.includes("/objectives"))
      return Constants.TICKET_SECTIONS.OBJECTIVES;
    else if (url.includes("/initiatives")) return Constants.TICKET_SECTIONS.INITIATIVES;
    else if (url.includes("/activities")) return Constants.TICKET_SECTIONS.ACTIVITIES;
    else if (url.includes("/directions")) return Constants.TICKET_SECTIONS.GOVERNMENT_DIRECTIONS;
    else if (url.includes("/services")) return Constants.TICKET_SECTIONS.SERVICES;
    else if (url.includes("/cycles")) return Constants.TICKET_SECTIONS.CYCLES;
    else if (url.includes("/pillars")) return Constants.TICKET_SECTIONS.PILLARS;
    else if (url.includes("/mandatory")) return Constants.TICKET_SECTIONS.MANDATORY_FIELDS;
    else if (url.includes("/data-entry/metrics")) return Constants.TICKET_SECTIONS.DATA_ENTRY_METTRICS;
    else if (url.includes("/data-entry")) return Constants.TICKET_SECTIONS.DATA_ENTRY;
    else if (url.includes("/provisioning")) return Constants.TICKET_SECTIONS.PROVISIONING;
    else if (url.includes("/formulas")) return Constants.TICKET_SECTIONS.FORMULAS;
    else if (url.includes("/notifications")) return Constants.TICKET_SECTIONS.NOTIFICATIONS;
    else if (url.includes("/user-profile")) return Constants.TICKET_SECTIONS.USER_PROFILE;
    else if (url.includes("/nkpi")) return Constants.TICKET_SECTIONS.NKPI;
    else if (url.includes("/uae")) return Constants.TICKET_SECTIONS.DKPI;
    else if (url.includes("/skpi")) return Constants.TICKET_SECTIONS.SKPI;
    else if (url.includes("/mokpi")) return Constants.TICKET_SECTIONS.MOKPI;
    else if (url.includes("/mtkpi")) return Constants.TICKET_SECTIONS.MTKPI;
    else if (url.includes("/opm")) return Constants.TICKET_SECTIONS.OPM;
    else if (url.includes("/ekpi")) return Constants.TICKET_SECTIONS.EKPI;
    else if (url.includes("/ntkpi")) return Constants.TICKET_SECTIONS.NTKPI;
    else if (url.includes("/dtkpi")) return Constants.TICKET_SECTIONS.DTKPI;
    else if (url.includes("/srvkpi")) return Constants.TICKET_SECTIONS.SRVKPI;
    else if (url.includes("/import-export-plan")) return Constants.TICKET_SECTIONS.PLAN_EDITOR;
    else if (url.includes("/communications")) return Constants.TICKET_SECTIONS.COMMUNICATIONS_CENTER;
    else if (url.includes("/navigation")) return Constants.TICKET_SECTIONS.NAVIGATION;
    else if (url.includes("/workflow/workflow-configuration")) return Constants.TICKET_SECTIONS.WORKFLOW_CONFIGURATION;
    else if (url.includes("/workflow/workflow-monitoring")) return Constants.TICKET_SECTIONS.WORKFLOW_MONITORING;
    else if (url.includes("/workflow/workflow-triggers")) return Constants.TICKET_SECTIONS.WORKFLOW_TRIGGER;
    else if (url.includes("/national-project/national-projects") || url.includes("/national-projects-dashboard"))
      return Constants.TICKET_SECTIONS.NATIONAL_PROJECT;
    else if (url.includes("/national-project/project-milestones"))
      return Constants.TICKET_SECTIONS.NATIONAL_PROJECT_MILESTONE;
    else if (url.includes("/national-project/agreements")) return Constants.TICKET_SECTIONS.NATIONAL_PROJECT_AGREEMENT;
    else if (url.includes("/national-project/sectors")) return Constants.TICKET_SECTIONS.NATIONAL_PROJECT_SECTOR;
    else if (url.includes("/national-project/transformational-targets"))
      return Constants.TICKET_SECTIONS.TRANSFORMATIONAL_TARGETS;
    else if (url.includes("/other-entities")) return Constants.TICKET_SECTIONS.OTHER_ENTITIES;
    else if (url.includes("/import-export-kpi")) return Constants.TICKET_SECTIONS.IMPORT_EXPORT_KPI;
    else if (url.includes("/text-mapping")) return Constants.TICKET_SECTIONS.TEXT_MAPPING;
    else if (url.includes("/government-scores/scopes")) return Constants.TICKET_SECTIONS.GOV_SCOPES;
    else if (url.includes("/government-scores/sectors")) return Constants.TICKET_SECTIONS.GOV_SECTORS;
    else if (url.includes("/annual-meetings-projects-dashboard") || url.includes("/annual-meetings-projects/projects"))
      return Constants.TICKET_SECTIONS.AGM_PROJECT;
    else if (url.includes("/helpdesk")) return Constants.TICKET_SECTIONS.SUPPORT_CENTER;
    else if (url.includes("/console/docs")) return Constants.TICKET_SECTIONS.ENTITY_REPORTS;
    else return returnNull ? null : Constants.TICKET_SECTIONS.USERS;
  }

  private _getTicketTitle(url: string): string | null {
    const sectionId = this._getTicketSection(url, true);
    if (!sectionId) return null;
    const section = this.ticketSections().find((e) => e.value === sectionId);
    if (!section) return null;

    return section.text;
  }
}
