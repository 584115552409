import { Route } from "@angular/router";

import { checkIfLoggedInFn } from "../../core/guards";
import { Constants } from "../../shared/constants/constants";
import { PageMode } from "../../shared/constants/enums";
import { createFormulasPageGuards, manageFormulasPageGuards, viewFormulasPageGuards } from "./_guards";
import { FormulasTemplateEditorComponent } from "./formulas-template-editor/formulas-template-editor.component";
import { FormulasTemplateListComponent } from "./formulas-template-list/formulas-template-list.component";

export const FORMULA_TEMPLATE_ROUTES: Route[] = [
  {
    path: "formulas",
    canActivate: [checkIfLoggedInFn, ...viewFormulasPageGuards],
    children: [
      {
        path: "",
        pathMatch: "full",
        component: FormulasTemplateListComponent,
      },
      {
        path: "create",
        component: FormulasTemplateEditorComponent,
        canActivate: [...createFormulasPageGuards],
        data: {
          breadcrumb: "breadcrumbs.formulas.new",
          pageMode: PageMode.create,
        },
      },
      {
        path: ":id",
        component: FormulasTemplateEditorComponent,
        data: {
          breadcrumb: "breadcrumbs.formulas.view",
          pageMode: PageMode.view,
        },
      },
      {
        path: "edit/:id",
        canActivate: [...manageFormulasPageGuards],
        component: FormulasTemplateEditorComponent,
        data: {
          breadcrumb: "breadcrumbs.formulas.edit",
          pageMode: PageMode.edit,
        },
      },
    ],
    data: {
      breadcrumb: "formulas.formula_console_icon",
      tableConfList: Constants.MAIN_TABLE_LIST_CONF_KEY.FORMULAS,
    },
  },
];
