import { AfterViewInit, Component, computed, inject, input, OnInit, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { Chart, ChartConfiguration } from "chart.js";
import autocolorPlugin from "chartjs-plugin-autocolors";
import { BaseChartDirective } from "ng2-charts";
import { filter, map } from "rxjs";

import { TranslateTokenPipe } from "../../../../../core/pipes";
import { AdaaHelper } from "../../../../../core/utils";
import { FormDropdownComponent } from "../../../../../shared/components";
import { ChartActionButtonsComponent } from "../../../../../shared/components/chart-action-buttons/chart-action-buttons.component";
import { Constants } from "../../../../../shared/constants/constants";
import { PropTypeModelType, SavedBenchmarkModelType } from "../../../../../shared/models";
import { ChartsService, FilesApiService, PropertiesService } from "../../../../../shared/services";

@Component({
  selector: "adaa-kpi-benchmark-view-card",
  standalone: true,
  templateUrl: "./kpi-benchmark-view-card.component.html",
  styleUrl: "../styles.scss",
  imports: [
    TranslateModule,
    TranslateTokenPipe,
    FormDropdownComponent,
    BaseChartDirective,
    ChartActionButtonsComponent,
  ],
})
export class KpiBenchmarkViewCardComponent implements OnInit, AfterViewInit {
  private readonly _propertiesService = inject(PropertiesService);
  private readonly _filesApiService = inject(FilesApiService);
  readonly chartService = inject(ChartsService);

  data = input<SavedBenchmarkModelType[]>([]);

  selectedAttachmentId = signal<number | null>(null);
  selectedBenchmarkId = signal<number | null>(null);
  selectedBenchmark = computed(() => this.data().find((e) => e.id === this.selectedBenchmarkId()));
  benchmarksOptions = computed(() =>
    AdaaHelper.setDropdownArray(this.data(), "id", AdaaHelper.getFieldLanguage("name"))
  );
  attachmentsOptions = computed(() =>
    this.selectedBenchmark()?.benchFileList
      ? AdaaHelper.setDropdownArray(this.selectedBenchmark()!.benchFileList!, "id", AdaaHelper.getFieldLanguage("name"))
      : []
  );

  props = signal<PropTypeModelType[]>([]);
  chartData = signal<ChartConfiguration["data"]>({
    labels: [],
    datasets: [],
  });
  chartOptions = signal<ChartConfiguration["options"] | undefined>(undefined);

  benchData = computed(() => {
    const data = this.data();
    const selectedBenchmark = this.selectedBenchmark();
    if (!selectedBenchmark) return [];
    const item = data.find((item) => item.id === selectedBenchmark.id);
    return item?.kpiBenchMarkLovs ?? [];
  });
  benchCountryTypes = computed(() => {
    const data = this.data();
    return data.map((item) => item.benchCountryType);
  });
  benchCountryType = computed(() => {
    const selectedBenchmark = this.selectedBenchmark();
    if (!selectedBenchmark) return [];
    return this.props().filter((item) => item.propType === selectedBenchmark.benchCountryType);
  });

  readonly lineChartPlugins = [autocolorPlugin];

  public ngOnInit(): void {
    this._fetchProps();
    this._selectFirstBenchmark();
    this.chartService.initBenchmarkChartOptions({});
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this._createCustomLegend();
    }, 1000);
  }

  public selectBenchmark(id: string) {
    this.selectedBenchmarkId.set(+id);
    this.selectedAttachmentId.set(null);
    this._generateChartOptions();
    this._generateChartData();
  }

  public selectAttachment(id: string) {
    const file = this.selectedBenchmark()?.benchFileList?.find((e) => e.id === +id);
    if (!file) return;
    this._filesApiService.save(file.id, file.filename, true);
  }

  private _selectFirstBenchmark() {
    this.selectedBenchmarkId.set(this.data()[0]?.id);
  }

  private _fetchProps() {
    this._propertiesService
      .getPropByIdList([Constants.CONSTANT_COUNTRIES, Constants.CONSTANT_BENCHTYPE, ...this.benchCountryTypes()])
      .pipe(
        filter((res) => !res.inError),
        map((res) => res.responseData)
      )
      .subscribe({
        next: (data) => {
          this.props.set(data);

          this._generateChartOptions();
          this._generateChartData();
        },
      });
  }

  private _generateChartData() {
    const benchmarkName = AdaaHelper.getItemValueByToken(this.selectedBenchmark(), "name");
    const years = Array.from(
      new Set(
        this.benchData()
          .map(({ year }) => year)
          .sort((a, b) => a - b)
      )
    );
    const countries = Array.from(new Set(this.benchData().map(({ countryId }) => countryId)));
    const datasets: unknown[] = [];

    countries.forEach((c) => {
      const country = this.benchCountryType().find(({ id }) => id === c);
      const data = this.benchData()
        .filter((e) => e.countryId === c)
        .sort((a, b) => a.year - b.year)
        .map((e) => e.value);

      const countryName = AdaaHelper.getItemValueByToken(country, "name");

      datasets.push({
        data: data,
        label: AdaaHelper.isDefinedAndNotEmpty(countryName) ? countryName : benchmarkName,
      });
    });

    this.chartData.set({
      labels: years,
      datasets: datasets as ChartConfiguration["data"]["datasets"],
    });
  }

  private _generateChartOptions() {
    const data = this.benchData().map(({ value }) => value);
    data.sort((a, b) => (a > b ? 1 : b > a ? -1 : 0)).reverse();

    const YMax = data[0];

    this.chartOptions.set({
      ...this.chartService.lineChartOptions,
      scales: {
        ...this.chartService.lineChartOptions?.scales,
        y: {
          ...this.chartService.lineChartOptions!.scales!.y,
          beginAtZero: true,
          ticks: {
            ...this.chartService.lineChartOptions!.scales!.y?.ticks,
            stepSize:
              YMax > 10000
                ? 5000
                : YMax > 1000
                  ? 100
                  : YMax > 500
                    ? 50
                    : YMax >= 100
                      ? 20
                      : YMax <= 80 && YMax > 10
                        ? 10
                        : 1,
          },
        } as Record<string, unknown>,
      },
    });
  }

  private _createCustomLegend() {
    const ctx = document.getElementById("kpiBenchmarkChart") as HTMLCanvasElement;
    if (!ctx) {
      return;
    }
    const existingChart = Chart.getChart(ctx);
    if (existingChart) {
      existingChart.destroy();
    }
    const chart = new Chart<"line">(ctx, {
      type: "line",
      data: this.chartData() as ChartConfiguration<"line">["data"],
      options: this.chartService.lineChartOptions,
    });
    const legendContainer = document.getElementById("chart-legend")!;
    legendContainer.innerHTML = chart.data.datasets
      .map((dataset) => {
        const countryCode = this.chartService.getCountryFlagCode(dataset.label ?? "");
        return `
        <div class="legend-item mx-3" style="cursor: pointer;">
          <span class="legend-color" style="background-color: ${dataset.borderColor};"></span>
          <span class="fi fi-${countryCode}"></span>
          <span>${dataset.label}</span>
        </div>`;
      })
      .join("");

    document.querySelectorAll(".legend-item").forEach((legend, index) => {
      legend.addEventListener("click", () => {
        const dataset = chart.data.datasets[index];
        dataset.hidden = !dataset.hidden;
        legend.classList.toggle("hidden", dataset.hidden);
        chart.update();
      });
    });
  }
}
