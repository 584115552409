import { Component, computed, inject, OnInit, viewChild } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { AdaaHelper } from "../../../core/utils";
import { ConfirmationModalComponent, DataTableComponent } from "../../../shared/components";
import { Constants } from "../../../shared/constants/constants";
import { Language } from "../../../shared/constants/enums";
import { GovDirection, TableButtonClicked } from "../../../shared/models";
import { AppService, GovDirectionApiService, SystemLayoutService } from "../../../shared/services";

@Component({
  selector: "adaa-government-directions-list",
  standalone: true,
  imports: [DataTableComponent],
  templateUrl: "./government-directions-list.component.html",
  styleUrl: "./government-directions-list.component.scss",
})
export class GovernmentDirectionsListComponent implements OnInit {
  private _router = inject(Router);
  private _modalService = inject(NgbModal);
  private _appService = inject(AppService);
  private _govDirectionApiService = inject(GovDirectionApiService);
  private _toastrService = inject(ToastrService);
  private _translateService = inject(TranslateService);
  private readonly _systemLayoutService = inject(SystemLayoutService);

  private _governmentDirectionsTable = viewChild<DataTableComponent>("governmentDirectionsTable");

  private _untilDestroyed = AdaaHelper.untilDestroyed();
  constants = Constants;
  paramsString = computed(() => `itemType=${Constants.CONSTANT_GOVDIRECTION}`);

  public ngOnInit(): void {
    this._systemLayoutService.hasCycleChanged$.pipe(this._untilDestroyed()).subscribe({
      next: () => window.location.reload(),
    });
    this._systemLayoutService.hasActiveEntityChanged$.pipe(this._untilDestroyed()).subscribe({
      next: () => this._router.navigateByUrl(`/console`),
    });
  }

  public addNewClicked() {
    this._router.navigateByUrl(`/console/directions/create`);
  }

  public actionClicked(action: TableButtonClicked): void {
    switch (action.event) {
      case "delete":
        this._deleteDirection(action.data);
        break;
      case "view":
        this._router.navigateByUrl(`/console/directions/${action.data.id}`);
        break;
      case "edit":
        this._router.navigateByUrl(`/console/directions/edit/${action.data.id}`);
        break;
      default:
        break;
    }
  }

  private _deleteDirection(direction: GovDirection): void {
    const modal = this._modalService.open(ConfirmationModalComponent, {
      centered: true,
      size: "md",
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });

    modal.componentInstance.header = "directions.government_direction";
    modal.componentInstance.title = "directions.directionRemove";

    modal.result.then((e) => {
      if (e) this._deleteDirectionConfirmed(direction);
    });
  }

  private _deleteDirectionConfirmed(direction: GovDirection): void {
    this._govDirectionApiService.delete(direction.id).subscribe({
      next: (response) => {
        if (response.inError) return;

        this._toastrService.success(this._translateService.instant("notification.success.remove"));
        this._governmentDirectionsTable()?.loadTableData();
      },
    });
  }
}
