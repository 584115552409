<div class="d-flex gap-2">
    <p class="fw-bold fs-4">{{ "cycle.title" | translate }} -</p>
    <p class="fw-bold fs-4">{{ displayLabel() }}</p>
</div>

<div class="bg-white shadow-md rounded p-2 mb-3">
    <form class="col-12" [formGroup]="strategicCycleForm">
        <div class="bg-white shadow-md rounded p-2 mb-3">
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.nameAE' | translate"
                        [setValidator]="cyclesEditorService.formValidation()!"
                        [invalid]="checkInvalid('nameAE')"
                        formControlName="nameAE"
                        controlName="nameAE"
                    ></adaa-form-input>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.nameEN' | translate"
                        [setValidator]="cyclesEditorService.formValidation()!"
                        [invalid]="checkInvalid('nameEN')"
                        formControlName="nameEN"
                        controlName="nameEN"
                    ></adaa-form-input>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.descriptionAE' | translate"
                        [setValidator]="cyclesEditorService.formValidation()!"
                        [invalid]="checkInvalid('dscAE')"
                        formControlName="dscAE"
                        controlName="dscAE"
                        [isTextArea]="true"
                    ></adaa-form-input>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.descriptionEN' | translate"
                        [setValidator]="cyclesEditorService.formValidation()!"
                        [invalid]="checkInvalid('dscEN')"
                        formControlName="dscEN"
                        controlName="dscEN"
                        [isTextArea]="true"
                    ></adaa-form-input>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input-date
                        [label]="'common.form.label.start_date' | translate"
                        [setValidator]="cyclesEditorService.formValidation()!"
                        [invalid]="checkInvalid('startYear') || strategicCycleForm.errors?.startYear_endYear_Invalid"
                        formControlName="startYear"
                        controlName="startYear"
                    />
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input-date
                        [label]="'common.form.label.end_date' | translate"
                        [setValidator]="cyclesEditorService.formValidation()!"
                        [invalid]="checkInvalid('endYear') || strategicCycleForm.errors?.startYear_endYear_Invalid"
                        formControlName="endYear"
                        controlName="endYear"
                    />
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <adaa-form-two-way-select
                        [notSelectedLabel]="'cycle.available_entities' | translate"
                        [selectedLabel]="'cycle.connected_entities' | translate"
                        [options]="entityOptions()"
                        [setValidator]="cyclesEditorService.formValidation()!"
                        [isDisabled]="strategicCycleForm.get('entityMaps')?.disabled ?? false"
                        formControlName="entityMaps"
                        controlName="entityMaps"
                    ></adaa-form-two-way-select>
                </div>
            </div>
        </div>
    </form>
</div>

<div class="col-12 mb-3 d-flex justify-content-center gap-4">
    <adaa-form-action-buttons
        [saveButtonTitle]="pageMode() === PageMode.create ? ('executive_teams.submit' | translate) : ('common.form.button.save' | translate)"
        [cancelButtonTitle]="pageMode() === PageMode.view ? ('common.form.button.close' | translate) : ('common.form.button.cancel' | translate)"
        [showSaveButton]="
            (pageMode() === PageMode.create ||
                plan().status === Constants.OBJECT_STATUS.PLANNING ||
                plan().status === Constants.OBJECT_STATUS.ACTIVE) &&
            pageMode() !== PageMode.view
        "
        (cancelClicked)="cancel()"
        (saveClicked)="submit()"
    ></adaa-form-action-buttons>
    @if (pageMode() === PageMode.edit) {
        <button class="btn btn-primary" (click)="submit(true)">
            <span>{{ cycleButtonLabel() | translate }}</span>
        </button>
    }
</div>
