import { Component, inject, input, OnInit, signal, viewChild } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { AdaaHelper } from "../../../../core/utils";
import {
  ConfirmationModalComponent,
  DataTableComponent,
  FloatActionComponent,
  ItemsOrderModalComponent,
} from "../../../../shared/components";
import { genericFloatButtons } from "../../../../shared/components/general/float-action";
import { Constants } from "../../../../shared/constants/constants";
import { Language } from "../../../../shared/constants/enums";
import { CustomTableButton, TableButtonClicked, ValueText } from "../../../../shared/models";
import { AppService, GovScoresApiService, LanguageService, SystemLayoutService } from "../../../../shared/services";

@Component({
  selector: "adaa-government-sectors-list",
  standalone: true,
  imports: [DataTableComponent, TranslateModule, FloatActionComponent],
  templateUrl: "./government-sectors-list.component.html",
  styleUrl: "./government-sectors-list.component.scss",
})
export class GovernmentSectorsListComponent implements OnInit {
  private _router = inject(Router);
  private _govScoresApiService = inject(GovScoresApiService);
  private _translateService = inject(TranslateService);
  private _toastrService = inject(ToastrService);
  private _modalService = inject(NgbModal);
  private _appService = inject(AppService);
  private _systemLayoutService = inject(SystemLayoutService);
  private _languageService = inject(LanguageService);

  breadcrumb = input.required<string>();

  private readonly _untilDestroy = AdaaHelper.untilDestroyed();
  private readonly _genericFloatButtons = genericFloatButtons();
  Constants = Constants;
  list = signal<ValueText[]>([]);
  adaaDataTable = viewChild.required<DataTableComponent>("adaaDataTable");
  customTableButtons: CustomTableButton[] = [
    {
      eventName: "sort",
      iconTitle: "navigation.tooltips.order_tooltip",
      iconName: "adaa-icon-sort",
    },
  ];

  public ngOnInit(): void {
    this._floatActions();
    this._systemLayoutService.hasActiveEntityChanged$.pipe(this._untilDestroy()).subscribe({
      next: () => this._router.navigateByUrl("/console"),
    });
  }

  public addNew() {
    this._router.navigateByUrl("/console/government-scores/sectors/create");
  }

  public getAction(value: TableButtonClicked) {
    switch (value.event) {
      case "view":
        this._router.navigateByUrl(`/console/government-scores/sectors/${value.data.id}`);
        break;
      case "edit":
        this._router.navigateByUrl(`/console/government-scores/sectors/edit/${value.data.id}`);
        break;
      case "delete":
        this._deleteSector(value.data.id);
        break;

      default:
        break;
    }
  }

  private _deleteSector(id: number) {
    const modal = this._modalService.open(ConfirmationModalComponent, {
      centered: true,
      size: "md",
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });

    modal.componentInstance.header = "government_scores.sectors.confirm_delete_title";
    modal.componentInstance.title = "government_scores.sectors.confirm_delete_message";

    modal.result.then((e) => {
      if (e) {
        this._govScoresApiService.deleteGovScoresSector(id).subscribe({
          next: (response) => {
            if (response.inError) return;
            this._toastrService.success(this._translateService.instant("notification.success.remove"));
            this.adaaDataTable().loadTableData();
          },
        });
      }
    });
  }

  public buttonClicked(event: string) {
    if (!event) return;

    const modal = this._modalService.open(ItemsOrderModalComponent, {
      centered: true,
      size: "xl",
      modalDialogClass: this._languageService.modalDirection(),
    });

    const tableData = this.adaaDataTable().tableData ?? [];
    const data: ValueText[] = [];
    tableData.forEach((e) => data.push({ value: e.id, text: AdaaHelper.getItemValueByToken(e, "name") }));

    modal.componentInstance.data = data;
    modal.componentInstance.inputLabel = "government_scores.government_sectors";

    modal.result.then((result: number[] | undefined) => {
      if (!result) return;
      tableData.forEach((sector) => {
        const index = result.findIndex((e) => e === sector.id);
        sector.displayOrder = index + 1;
      });

      this._govScoresApiService.updateSectorsOrders(tableData).subscribe({
        complete: () => this.adaaDataTable().loadTableData(),
      });
    });
  }

  private _floatActions(): void {
    this._genericFloatButtons([
      {
        key: "helpdesk",
        data: {
          url: "/helpdesk/create",
        },
      },

      {
        key: "print",
        data: undefined,
      },
    ]);
  }
}
