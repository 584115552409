import { Component, computed, inject, input, OnInit, signal } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ColorPickerDirective } from "ngx-color-picker";
import { ToastrService } from "ngx-toastr";
import { forkJoin } from "rxjs";

import { AdaaHelper } from "../../../../core/utils";
import {
  FloatActionComponent,
  FormActionButtonsComponent,
  FormInputComponent,
  FormInputFileComponent,
  FormTwoWaySelectComponent,
} from "../../../../shared/components";
import { genericFloatButtons } from "../../../../shared/components/general/float-action";
import { CommonFloatActionButtonType } from "../../../../shared/components/general/float-action/types";
import { Constants } from "../../../../shared/constants/constants";
import { FileInputType, PageMode } from "../../../../shared/constants/enums";
import { AttachFile, GovSector, GovSectorEntity, ValidatorModelType, ValueText } from "../../../../shared/models";
import { GovScoresApiService, SystemLayoutService, ValidatorApiService } from "../../../../shared/services";

@Component({
  selector: "adaa-government-sectors-editor",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormInputComponent,
    FormTwoWaySelectComponent,
    TranslateModule,
    FormInputFileComponent,
    FormActionButtonsComponent,
    FloatActionComponent,
    ColorPickerDirective,
  ],
  templateUrl: "./government-sectors-editor.component.html",
  styleUrl: "./government-sectors-editor.component.scss",
})
export class GovernmentSectorsEditorComponent implements OnInit {
  private _formBuilder = inject(FormBuilder);
  private _toastrService = inject(ToastrService);
  private _router = inject(Router);
  private _translateService = inject(TranslateService);
  private _govScoresApiService = inject(GovScoresApiService);
  private _validatorApiService = inject(ValidatorApiService);
  private _systemLayoutService = inject(SystemLayoutService);

  pageMode = input.required<string>();
  id = input<string>();

  private readonly _untilDestroy = AdaaHelper.untilDestroyed();
  private readonly _genericFloatButtons = genericFloatButtons();

  sectorForm: FormGroup = new FormGroup({});
  formValidation = signal<ValidatorModelType | undefined>(undefined);
  displayLabel = signal<string>("");
  submitted = signal<boolean>(false);
  FileInputType = FileInputType;
  PageMode = PageMode;
  color: string = "#000";

  availableEntities = signal<GovSectorEntity[]>([]);
  availalbledEntityOptions = computed(() => {
    const field = AdaaHelper.getFieldLanguage("name");
    const result: ValueText[] = AdaaHelper.setDropdownArray(this.availableEntities(), "id", field);
    return result;
  });
  sector = signal<GovSector | undefined>(undefined);

  public ngOnInit(): void {
    this._systemLayoutService.hasActiveEntityChanged$.pipe(this._untilDestroy()).subscribe({
      next: () => {
        this._router.navigateByUrl("/console");
      },
    });

    this._prepareForm();
    this._initPage();
    this._floatActions();
  }

  private _initPage(): void {
    forkJoin({
      validators: this._validatorApiService.searchByKey(Constants.VALIDATORS_CONF_KEY.VALIDATION_GOV_SECTORS),
      entities: this._govScoresApiService.getAvailableSectorEntities(),
    }).subscribe({
      next: (results) => {
        if (!results.validators.inError) this.formValidation.set(results.validators.responseData);

        if (!results.entities.inError) this.availableEntities.set(results.entities.responseData);
      },
      complete: () => {
        if (!this.id()) return;
        this._getSector();
      },
    });
  }

  private _getSector(): void {
    this._govScoresApiService.getGovScoresSector(+this.id()!).subscribe({
      next: (response) => {
        this.color = response.responseData.iconColor;
        this.sector.set(response.responseData);
        const mappedEntities = response.responseData.involvedEntities.map((entity) => entity.id);
        let entities: GovSectorEntity[] = AdaaHelper.clone(this.availableEntities());
        entities = [...entities, ...response.responseData.involvedEntities];
        this.availableEntities.set(entities);
        this.sectorForm.patchValue({ ...response.responseData, involvedEntities: mappedEntities });
        this.sectorForm.markAsPristine();
        this.sectorForm.markAsUntouched();
      },
    });
  }

  private _prepareForm() {
    const isViewMode = this.pageMode() === PageMode.view;

    this.sectorForm = this._formBuilder.group({
      nameAE: this._formBuilder.control<string | undefined>(
        { value: "", disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      nameEN: this._formBuilder.control<string | undefined>(
        { value: "", disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      dscAE: this._formBuilder.control<string | undefined>({ value: "", disabled: isViewMode }),
      dscEN: this._formBuilder.control<string | undefined>({ value: "", disabled: isViewMode }),
      involvedEntities: this._formBuilder.control<ValueText[] | undefined>(
        { value: [], disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      iconAttach: this._formBuilder.control<
        | {
            id: string;
            attachFile: null;
            filename: string;
            activityId: null;
            taskId: null;
            auxAttachFile: {
              filename: null;
            };
          }
        | undefined
      >(
        { value: undefined, disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
      iconColor: this._formBuilder.control<string | undefined>(
        { value: undefined, disabled: isViewMode },
        {
          validators: [Validators.required],
        }
      ),
    });

    this.sectorForm.valueChanges.pipe(this._untilDestroy()).subscribe((value) => {
      const displayLanguage = AdaaHelper.getFieldLanguage("name");
      if (!value[displayLanguage]?.length) {
        this.displayLabel.set(value["nameAE"] || value["nameEN"]);
      } else this.displayLabel.set(value[displayLanguage]);
    });
  }

  public submit() {
    this.submitted.set(true);
    if (this.sectorForm.invalid) {
      this._toastrService.error(this._translateService.instant("notification.warning.missing_info"));
      return;
    }

    const involvedEntities: Partial<GovSectorEntity>[] = this.sectorForm.value.involvedEntities.map(
      (entityId: number, index: number) => {
        const entity = this.availableEntities().find((value) => value.id === entityId);
        return this._mapEntityValues(entity!, index + 1);
      }
    );

    const previousEntities: Partial<GovSectorEntity>[] = [];
    this.sector()?.involvedEntities.forEach((entity, index) => {
      if (!involvedEntities.find((selected) => selected.id === entity.id)) {
        const value = this._mapEntityValues(entity, index + 1, Constants.OBJECT_STATUS.REMOVE);
        previousEntities.push(value);
      }
    });

    const result = {
      ...this.sector(),
      ...this.sectorForm.value,
      involvedEntities: [...involvedEntities, ...previousEntities],
      iconColor: this.color,
    };

    const url =
      this.pageMode() === PageMode.create
        ? this._govScoresApiService.createGovScoresSector(result)
        : this._govScoresApiService.updateGovScoresSector(result);

    url.subscribe({
      next: (response) => {
        if (response.inError) return;
        this._toastrService.success(this._translateService.instant("notification.success.save"));
        this.close();
      },
    });
  }

  private _mapEntityValues(entity: GovSectorEntity, displayOrder: number, status = entity.status) {
    return {
      refCode: entity?.refCode,
      nameAE: entity?.nameAE,
      nameEN: entity?.nameEN,
      shortNameAE: entity?.shortNameAE,
      shortNameEN: entity?.shortNameEN,
      entityTypeId: entity?.entityTypeId,
      entitySize: entity?.entitySize,
      dscAE: entity?.dscAE,
      dscEN: entity?.dscEN,
      logoHorizontalId: entity?.logoHorizontalId,
      logoHorizontalOffset: entity?.logoHorizontalOffset,
      enabler: entity?.enabler,
      creationDate: entity?.creationDate,
      isOtherEntity: entity?.isOtherEntity,
      enabled: entity?.enabled,
      id: entity?.id,
      status: status,
      updateTS: entity?.updateTS,
      displayOrder,
    };
  }

  private _floatActions(): void {
    const actions: CommonFloatActionButtonType[] = [
      {
        key: "helpdesk",
        data: {
          url: "/helpdesk/create",
        },
      },
      {
        key: "print",
        data: undefined,
      },
    ];

    if (this.pageMode() !== PageMode.create)
      actions.push({
        key: "systemLog",
        data: {
          itemId: +this.id()!,
          itemTypeId: [Constants.GOV_SCORES_SECTOR],
        },
      });

    if (this.pageMode() === PageMode.view)
      actions.push({
        key: "edit",
        data: {
          url: `/console/government-scores/sectors/edit/${this.id()}`,
        },
      });

    this._genericFloatButtons(actions);
  }

  public getImage(): AttachFile {
    const attach = this.sectorForm.get("iconAttach")?.value;
    return {
      id: attach?.id ?? 0,
      filename: attach?.filename ?? "",
    };
  }

  public onFilechange(event: AttachFile | null) {
    if (event) {
      this.sectorForm.get("iconAttach")?.setValue({
        id: event.id,
        attachFile: null,
        filename: event.filename,
        activityId: null,
        taskId: null,
        auxAttachFile: {
          filename: null,
        },
      });
    } else this.sectorForm.get("iconAttach")?.setValue(null);
  }

  public close() {
    this._router.navigateByUrl("/console/government-scores/sectors");
  }
}
