import { Component } from "@angular/core";

@Component({
  selector: "adaa-enabler",
  standalone: true,
  imports: [],
  templateUrl: "./enabler.component.html",
  styleUrl: "./enabler.component.scss",
})
export class EnablerComponent {}
