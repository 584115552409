import { Injectable } from "@angular/core";
import isEqual from "lodash.isequal";

import { AdaaHelper } from "../../core/utils";
import { ObjectStatus } from "../models";

export interface FormComparisonConfig {
  fieldsToIgnore: string[]; // Only considering parent keys - keys from nested objects are ignored
  fieldsToCheckStatus: string[];
  fieldsWithCustomChecks?: { key: string; fn: ($new: FormModelObjectType[], $old: FormModelObjectType[]) => boolean }[];
}

export type FormModelObjectType = Record<string, unknown>;

@Injectable({
  providedIn: null,
})
export class FormDirtyCheckerService {
  public isDirty(
    model: FormModelObjectType,
    $old: FormModelObjectType,
    { fieldsToCheckStatus, fieldsToIgnore, fieldsWithCustomChecks }: FormComparisonConfig
  ) {
    const dirtyKeys = new Set<string>();
    const $new = this._sanityCheckNewData({ ...model }, $old);

    for (const key of Object.keys($new)) {
      const ignoreKey = fieldsToIgnore.find((k) => k === key);
      if (ignoreKey) continue;

      const checkStatus = fieldsToCheckStatus.find((k) => k === key);
      const customCheck = (fieldsWithCustomChecks ?? []).find((f) => f.key === key);

      // Checks arrays with unequal status value
      if (Array.isArray($new[key]) && checkStatus) {
        const $oldArray = $old[key] as FormModelObjectType[];
        const $newArray = ($new[key] as FormModelObjectType[]).filter(({ status }) => {
          return (status as number) !== ObjectStatus.REMOVE;
        });

        if (!isEqual($newArray, $oldArray)) dirtyKeys.add(key);

        // Checks arrays for inequality using a custom function
      } else if (Array.isArray($new[key]) && customCheck) {
        const $oldArray = $old[key] as FormModelObjectType[];
        const $newArray = $new[key] as FormModelObjectType[];

        if (!customCheck?.fn($newArray, $oldArray)) dirtyKeys.add(key);

        // Checks for equality in non-Array items
      } else if (!isEqual($new[key], $old[key])) {
        dirtyKeys.add(key);
      }
    }

    return Array.from(dirtyKeys).length !== 0;
  }

  private _sanityCheckNewData($new: FormModelObjectType, $old: FormModelObjectType) {
    for (const key of Object.keys($new)) {
      const finalValue = $new[key];
      const originalValue = $old[key];
      const isNil = (v: unknown) => v === null || v === undefined;
      const isEmptyString = (v: unknown) => typeof v === "string" && v.length === 0;

      if (isEmptyString(finalValue) && isNil(originalValue)) {
        $new[key] = null;
      } else if (
        Array.isArray(finalValue) &&
        (finalValue as unknown[]).length === 0 &&
        !AdaaHelper.isDefined(originalValue)
      ) {
        $new[key] = null;
      }
    }

    return $new;
  }
}
