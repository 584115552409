import { Component, inject } from "@angular/core";

import { PermissionActionDescriptorType } from "../../../../../adaa-types";
import { ConsoleIconComponent } from "../../../../shared/components";
import { PermissionAction } from "../../../../shared/constants/enums";
import { HasPermissionDirective } from "../../../../shared/directives";
import { PermissionActionModelType } from "../../../../shared/models";
import { ConsoleService, LanguageService } from "../../../../shared/services";

@Component({
  selector: "adaa-sub-menu",
  standalone: true,
  imports: [ConsoleIconComponent, HasPermissionDirective],
  templateUrl: "./sub-menu.component.html",
})
export class SubMenuComponent {
  readonly languageService = inject(LanguageService);
  readonly consoleService = inject(ConsoleService);

  public ngOnInit(): void {
    this.consoleService.init();
  }

  public getPermission(permissionId: string | undefined): PermissionActionDescriptorType | undefined {
    if (permissionId) {
      const permissions = permissionId.split(",");
      const permissionsArray: PermissionActionModelType[] = [];

      permissions.forEach((e) => {
        permissionsArray.push({
          permissionAction: PermissionAction.view,
          objectTypeId: +e,
        });
      });

      return {
        actor: {
          modifier: "and",
          permissions: permissionsArray,
        },
      };
    } else return undefined;
  }
}
