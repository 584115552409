import * as Sentry from "@sentry/angular";

import { environment } from "../environments/environment";

if (isSentryAvailable()) {
  Sentry.init({
    dsn: environment.glitchDns,
    environment: environment.production
      ? "production"
      : environment.staging
        ? "staging"
        : environment.newbe
          ? "newbe"
          : environment.sandbox
            ? "sandbox"
            : undefined,
  });
}

function isSentryAvailable(): boolean {
  return Boolean(environment.glitchDns) && window.location.hostname !== "localhost";
}
