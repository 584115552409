import { CommonModule } from "@angular/common";
import { Component, inject, input, output, signal, TemplateRef, viewChild } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";

import { FormActionButtonsComponent, FormInputComponent } from "../../../shared/components";
import { Constants } from "../../../shared/constants/constants";
import { Language, PageMode } from "../../../shared/constants/enums";
import { LibTexMapValue } from "../../../shared/models/text-mapping.model";
import { AppService } from "../../../shared/services";
import { TextMappingValueEditorService } from "../../../shared/services/text-mapping-value-editor.service";

@Component({
  selector: "adaa-text-mapping-value-editor",
  standalone: true,
  imports: [CommonModule, FormInputComponent, FormActionButtonsComponent, TranslateModule, ReactiveFormsModule],
  templateUrl: "./text-mapping-value-editor.component.html",
  styleUrl: "./text-mapping-value-editor.component.scss",
})
export class TextMappingValueEditorComponent {
  textMappingValueEditorService = inject(TextMappingValueEditorService);
  private _formBuilder = inject(FormBuilder);
  private _modalService = inject(NgbModal);
  private _appService = inject(AppService);
  private _toastrService = inject(ToastrService);
  private _translateService = inject(TranslateService);

  modal = viewChild.required<TemplateRef<unknown>>("content");

  pageMode = input.required<string>();
  editorClosed = output<LibTexMapValue>();
  PageMode = PageMode;

  textMappingValueForm: FormGroup = new FormGroup({});
  submitted = signal<boolean>(false);
  _subscription = new Subscription();

  public ngOnInit() {
    this._prepareForm();
    this.textMappingValueEditorService.formValidator();
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  private _prepareForm() {
    this.textMappingValueForm = this._formBuilder.group({
      lovTextAE: this._formBuilder.control<string | undefined>("", {
        validators: [Validators.required],
      }),
      lovTextEN: this._formBuilder.control<string | undefined>("", {
        validators: [Validators.required],
      }),
      id: this._formBuilder.control<string | undefined>(""),
      textMapId: this._formBuilder.control<string | undefined>(""),
      lovValue: this._formBuilder.control<string | undefined>(""),
    });
  }

  public open() {
    this._modalService.open(this.modal(), {
      animation: true,
      scrollable: false,
      keyboard: false,
      size: "xl",
      centered: true,
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });

    this.submitted.set(false);
  }

  public submit() {
    this.submitted.set(true);
    if (this.textMappingValueForm.invalid) {
      this._toastrService.error(this._translateService.instant("common.form.label.missing_information"));
      return;
    }
    const result = {
      ...this.textMappingValueForm.value,
      updateTS: Date.now(),
      status: Constants.OBJECT_STATUS.ACTIVE,
    };

    this.editorClosed.emit(result);
  }

  public checkInvalid(control: string) {
    return (
      this.textMappingValueForm.controls[control].errors?.["required"] &&
      (this.textMappingValueForm.get("nameEN")?.dirty || this.submitted())
    );
  }
}
