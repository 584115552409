import { inject, Injectable } from "@angular/core";
import { filter, switchMap, take, timer } from "rxjs";

import { environment } from "../../core/environments/environment";
import { KhadamatiJobSchedule, MainResponseModel } from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class KhadamatiService {
  private _mainService = inject(MainService);

  public startStopSync(value: boolean) {
    const apiUrl = `${environment.khadamatiApiUrl}jobs/startStopSync/${value}`;

    return this._mainService.sendRequest<MainResponseModel<string>>({
      method: "POST",
      apiUrl,
      url: "",
      data: null,
    });
  }

  public getLastSyncDate() {
    const url = `khadamati/getLastDate`;

    return this._mainService.sendRequest<MainResponseModel<{ lastDate: number }>>({
      method: "GET",
      url,
      loader: false,
    });
  }

  public getSyncStatus(multiplier: number) {
    return timer(1000, 2000 + multiplier * 1000).pipe(
      switchMap(() => this.checkRunningSchedule()),
      filter((response: MainResponseModel<boolean>) => response.responseData === false),
      take(1)
    );
  }

  public checkRunningSchedule() {
    const apiUrl = `${environment.khadamatiApiUrl}jobs/isScheduleRunning`;

    return this._mainService.sendRequest<MainResponseModel<boolean>>({
      method: "GET",
      apiUrl,
      url: "",
      loader: false,
    });
  }

  public getCurrentScheduleSync() {
    const apiUrl = `${environment.khadamatiApiUrl}jobs/getCurrentScheduleSync`;

    return this._mainService.sendRequest<MainResponseModel<KhadamatiJobSchedule[]>>({
      method: "GET",
      apiUrl,
      url: "",
    });
  }

  public updateCurrentScheduleSync(data: { cron: string; triggerId: string }[]) {
    const apiUrl = `${environment.khadamatiApiUrl}jobs/updateCurrentScheduleSync`;

    return this._mainService.sendRequest<MainResponseModel<null>>({
      method: "POST",
      apiUrl,
      url: "",
      data,
    });
  }

  public disableSyncJob() {
    const apiUrl = `${environment.khadamatiApiUrl}jobs/disableSync`;

    return this._mainService.sendRequest<MainResponseModel<string>>({
      method: "POST",
      apiUrl,
      url: "",
      data: true,
    });
  }
}
