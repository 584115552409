import { Route } from "@angular/router";

import { checkIfLoggedInFn, pmoEntity } from "../../core/guards";
import { viewDataEntryPagesGuards } from "../data-entry/_guards";

export const DATA_ENTRY_TIMELINE_ROUTES: Route[] = [
  {
    path: "data-cycle-automation",
    canActivate: [checkIfLoggedInFn, pmoEntity, ...viewDataEntryPagesGuards],
    children: [
      {
        path: "",
        data: {
          title: "data_cycle_automation.title",
          breadcrumb: "data_cycle_automation.title",
        },
        loadComponent: () =>
          import("./data-entry-timeline-list/data-entry-timeline.component").then((e) => e.DataEntryTimelineComponent),
      },
    ],
  },
];
