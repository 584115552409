/// <reference types="@angular/localize" />

import { enableProdMode } from "@angular/core";
import { bootstrapApplication } from "@angular/platform-browser";

import { AdaaComponent } from "./app/app.component";
import { appConfig } from "./app/app.config";
import { environment } from "./app/core/environments/environment";

if (environment.production) {
  enableProdMode();
}

// eslint-disable-next-line no-console
bootstrapApplication(AdaaComponent, appConfig).catch((err) => console.error(err));
