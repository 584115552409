<div class="w-100 d-block card my-3 py-2 px-3 bg-white border-0 form-section">
    <div class="card-title">
        <h6>{{ "ntkpi_card.entity_leader_decision_title" | translate }}</h6>
    </div>

    <div class="card-body">
        <form class="row w-100 d-block" id="kpi-benchmarking-form" autocomplete="on" spellcheck="true" [formGroup]="form">
            <ng-container formArrayName="entityLeaderDecisions">
                @for (optionFormControl of entityLeaderDecisions.controls; track $index) {
                    @if (getOption($index)) {
                        <div
                            class="col-12 py-1 w-100 d-flex justify-content-start align-items-center align-content-center"
                            [formGroup]="$any(optionFormControl)"
                        >
                            <adaa-form-checkbox
                                [formControlName]="$any(getOption($index).id)"
                                [label]="getOption($index) | translateToken: 'name'"
                                [useSwitch]="true"
                                [boldLabel]="optionFormControl.value[getOption($index).id]"
                            />
                        </div>
                    }
                }
            </ng-container>
        </form>
    </div>
</div>
