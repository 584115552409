<ng-template #content let-modal>
    <div class="modal-header">
        <h5 class="text-center fw-bold w-100">{{ "user_groups.modals.add_user.title" | translate }}</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body overflow-x-hidden p-3">
        <div class="p-2">
            <adaa-data-table
                #adaaDataTable
                apiName="getAll"
                [configKey]="tableConfList()"
                [isLocalData]="true"
                [localData]="users()"
                [enableActionsCell]="false"
                [enableSelection]="true"
                [enableAdd]="false"
                (getRowData)="setTableSelection($event)"
                [multiSelection]="savedUsers()"
            ></adaa-data-table>
        </div>

        <adaa-form-action-buttons (cancelClicked)="modal.close()" (saveClicked)="submit()"></adaa-form-action-buttons>
    </div>
</ng-template>
