<div class="p-2">
    <adaa-data-table
        #adaaDataTable
        [apiName]="'getAllV2'"
        [apiPrefix]="'entity'"
        [titleHeader]="breadcrumb() | translate"
        [configKey]="Constants.MAIN_TABLE_LIST_CONF_KEY.ENTITIES"
        [itemTypeId]="Constants.CONSTANT_PERMISSIONS.ENTITIES"
        [customActionButtons]="customButtons"
        (addNewClicked)="addNew()"
        (actionClicked)="getAction($event)"
    ></adaa-data-table>
</div>
