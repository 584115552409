import { Route } from "@angular/router";

import { checkIfLoggedInFn } from "../../core/guards";
import { Constants } from "../../shared/constants/constants";
import { PageMode } from "../../shared/constants/enums";
import {
  createTPCyclesPageGuards,
  createTransformationalProjectsPageGuards,
  createTTargetsPageGuards,
  manageTPCyclesPageGuards,
  manageTPMilestonePageGuards,
  manageTransformationalProjectsPageGuards,
  manageTTargetsPageGuards,
  viewAgreementPageGuards,
  viewTPCyclesPageGuards,
  viewTPMilestonePageGuards,
  viewTPSubMenu,
  viewTransformationalProjectsPageGuards,
  viewTTargetsPageGuards,
} from "./_guards";
import { AgreementsComponent } from "./agreements/agreements.component";
import { CyclesListComponent, TpCycleEditorComponent } from "./cycles";
import { TpCardsDashboardComponent, TpDashboardComponent, TpProgerssComponent } from "./dashboard";
import { MilestoneEditorComponent, MilestoneListComponent, MilestoneViewComponent } from "./milestones";
import { TpSectorsEditorComponent } from "./sectors/tp-sectors-editor/tp-sectors-editor.component";
import { TpSectorsListComponent } from "./sectors/tp-sectors-list/tp-sectors-list.component";
import { TpSubmenuComponent } from "./tp-submenu/tp-submenu.component";
import { TpEditorComponent, TpListComponent, TpViewComponent } from "./transformational-project";
import { TransformationalTargetEditorComponent } from "./transformational-targets/transformational-target-editor/transformational-target-editor.component";
import { TransformationalTargetsListComponent } from "./transformational-targets/transformational-targets-list/transformational-targets-list.component";

export const TP_DASHBOARD_ROUTES: Route[] = [
  {
    path: "national-projects-dashboard",
    canActivate: [checkIfLoggedInFn],
    children: [
      {
        path: "",
        component: TpDashboardComponent,
        data: {
          title: "national_projects.dashboard",
          breadcrumb: "national_projects.dashboard",
        },
      },
      {
        path: "projects",
        children: [
          {
            path: "",
            component: TpCardsDashboardComponent,
            canActivate: [checkIfLoggedInFn],
            data: {
              title: "national_projects.project_names",
              breadcrumb: "national_projects.project_names",
            },
          },
          {
            path: ":id",
            component: TpViewComponent,
            canActivate: [checkIfLoggedInFn],
            data: {
              title: "national_projects.view_national_project",
              breadcrumb: "national_projects.view_national_project",
            },
          },
        ],
      },
      {
        path: "owners",
        component: TpCardsDashboardComponent,
        data: {
          title: "national_projects.project_names",
          breadcrumb: "national_projects.project_names",
        },
      },
      {
        path: "main-sectors/:id",
        component: TpCardsDashboardComponent,
        data: {
          title: "national_projects.project_names",
          breadcrumb: "national_projects.project_names",
        },
      },
      {
        path: "sectors/:id",
        component: TpCardsDashboardComponent,
        data: {
          title: "national_projects.project_names",
          breadcrumb: "national_projects.project_names",
        },
      },
      {
        path: "milestones/:id",
        component: MilestoneViewComponent,
        data: {
          title: "national_projects.national_projects_milestone.singular_title",
          breadcrumb: "national_projects.national_projects_milestone.singular_title",
        },
      },
      {
        path: "progerss-comparison",
        component: TpProgerssComponent,
        data: {
          title: "national_projects.overall_projects",
          breadcrumb: "national_projects.overall_projects",
          filterBy: "progerss",
        },
      },
      {
        path: "achievement-comparison",
        component: TpProgerssComponent,
        data: {
          title: "national_projects.overall_achievement",
          breadcrumb: "national_projects.overall_achievement",
          filterBy: "achievement",
        },
      },
    ],
  },
];

const TP_PAGES_ROUTES: Route[] = [
  {
    path: "national-projects",
    canActivate: [checkIfLoggedInFn, ...viewTransformationalProjectsPageGuards],
    children: [
      {
        path: "",
        component: TpListComponent,
        data: {
          breadcrumb: "national_projects.console_title",
          title: "national_projects.console_title",
        },
      },
      {
        path: "create",
        component: TpEditorComponent,
        canActivate: [checkIfLoggedInFn, ...createTransformationalProjectsPageGuards],
        data: {
          breadcrumb: "national_projects.create_project",
          title: "national_projects.create_project",
          pageMode: PageMode.create,
        },
      },
      {
        path: "edit/:id",
        component: TpEditorComponent,
        canActivate: [checkIfLoggedInFn, ...manageTransformationalProjectsPageGuards],
        data: {
          breadcrumb: "national_projects.edit_project",
          title: "national_projects.edit_project",
          pageMode: PageMode.edit,
        },
      },
    ],
  },
];

const TP_MILESTONE_PAGES_ROUTES: Route[] = [
  {
    path: "project-milestones",
    canActivate: [checkIfLoggedInFn, ...viewTPMilestonePageGuards],
    children: [
      {
        path: "",
        component: MilestoneListComponent,
        data: {
          breadcrumb: "national_projects.national_projects_milestone.title",
          title: "national_projects.national_projects_milestone.title",
        },
      },
      {
        path: "edit/:id",
        component: MilestoneEditorComponent,
        canActivate: [checkIfLoggedInFn, ...manageTPMilestonePageGuards],
        data: {
          breadcrumb: "national_projects.national_projects_milestone.singular_title",
          title: "national_projects.national_projects_milestone.singular_title",
        },
      },
    ],
  },
];

const CYCLES_ROUTES: Route[] = [
  {
    path: "cycles",
    canActivate: [checkIfLoggedInFn, ...viewTPCyclesPageGuards],
    children: [
      {
        path: "",
        component: CyclesListComponent,
        data: {
          tableConfList: Constants.MAIN_TABLE_LIST_CONF_KEY.TRANSFORMATIONAL_PROJECTS_CYCLE,
        },
      },
      {
        path: "create",
        component: TpCycleEditorComponent,
        canActivate: [checkIfLoggedInFn, ...createTPCyclesPageGuards],
        data: {
          breadcrumb: "national_projects.cycles.create_tp_cycle",
          title: "national_projects.cycles.create_tp_cycle",
          pageMode: PageMode.create,
        },
      },
      {
        path: "edit/:id",
        component: TpCycleEditorComponent,
        canActivate: [checkIfLoggedInFn, ...manageTPCyclesPageGuards],
        data: {
          breadcrumb: "national_projects.cycles.edit_tp_cycle",
          title: "national_projects.cycles.edit_tp_cycle",
          pageMode: PageMode.edit,
        },
      },
      {
        path: ":id",
        component: TpCycleEditorComponent,
        canActivate: [checkIfLoggedInFn, ...viewTPCyclesPageGuards],
        data: {
          breadcrumb: "national_projects.cycles.view_tp_cycle",
          title: "national_projects.cycles.view_tp_cycle",
          pageMode: PageMode.view,
        },
      },
    ],
    data: {
      breadcrumb: "national_projects.cycles.tp_cycles",
      title: "national_projects.cycles.tp_cycles",
    },
  },
];

const SECTORS_ROUTES: Route[] = [
  {
    path: "sectors",
    children: [
      {
        path: "",
        component: TpSectorsListComponent,
        canActivate: [checkIfLoggedInFn, ...viewTransformationalProjectsPageGuards],
        data: {
          tableConfList: Constants.MAIN_TABLE_LIST_CONF_KEY.NATIONAL_SECTORS,
        },
      },
      {
        path: "create",
        component: TpSectorsEditorComponent,
        canActivate: [checkIfLoggedInFn, ...createTransformationalProjectsPageGuards],
        data: {
          breadcrumb: "national_projects.national_sector.editor.create",
          title: "national_projects.national_sector.editor.create",
          pageMode: PageMode.create,
        },
      },
      {
        path: "edit/:id",
        component: TpSectorsEditorComponent,
        canActivate: [checkIfLoggedInFn, ...manageTransformationalProjectsPageGuards],
        data: {
          breadcrumb: "national_projects.national_sector.editor.edit",
          title: "national_projects.national_sector.editor.edit",
          pageMode: PageMode.edit,
        },
      },
      {
        path: ":id",
        component: TpSectorsEditorComponent,
        canActivate: [checkIfLoggedInFn, ...viewTransformationalProjectsPageGuards],
        data: {
          breadcrumb: "national_projects.national_sector.editor.view",
          title: "national_projects.national_sector.editor.view",
          pageMode: PageMode.view,
        },
      },
    ],
    data: {
      breadcrumb: "national_projects.national_sector.title",
      title: "national_projects.national_sector.title",
    },
  },
];

const TARGETS_ROUTES: Route[] = [
  {
    path: "transformational-targets",
    children: [
      {
        path: "",
        component: TransformationalTargetsListComponent,
        canActivate: [checkIfLoggedInFn, ...viewTTargetsPageGuards],
        data: {
          tableConfList: Constants.MAIN_TABLE_LIST_CONF_KEY.TRANSFORMATIONAL_TARGETS,
        },
      },
      {
        path: "create",
        component: TransformationalTargetEditorComponent,
        canActivate: [checkIfLoggedInFn, ...createTTargetsPageGuards],
        data: {
          breadcrumb: "national_projects.transformational_targets.create_transformational_target",
          title: "national_projects.transformational_targets.create_transformational_target",
          pageMode: PageMode.create,
        },
      },
      {
        path: "edit/:id",
        component: TransformationalTargetEditorComponent,
        canActivate: [checkIfLoggedInFn, ...manageTTargetsPageGuards],
        data: {
          breadcrumb: "national_projects.transformational_targets.edit_transformational_target",
          title: "national_projects.transformational_targets.edit_transformational_target",
          pageMode: PageMode.edit,
        },
      },
      {
        path: ":id",
        component: TransformationalTargetEditorComponent,
        canActivate: [checkIfLoggedInFn, ...viewTTargetsPageGuards],
        data: {
          breadcrumb: "national_projects.transformational_targets.view_transformational_target",
          title: "national_projects.transformational_targets.view_transformational_target",
          pageMode: PageMode.view,
        },
      },
    ],
    data: {
      breadcrumb: "national_projects.transformational_targets.title",
      title: "national_projects.transformational_targets.title",
    },
  },
];

const AGREEMENTS_ROUTES: Route[] = [
  {
    path: "agreements",
    canActivate: [checkIfLoggedInFn, ...viewAgreementPageGuards],
    children: [
      {
        path: "",
        component: AgreementsComponent,
        data: {
          breadcrumb: "national_projects.agreements.title",
          title: "national_projects.agreements.title",
        },
      },
    ],
  },
];

export const TP_ROUTES: Route[] = [
  {
    path: "national-project",
    canActivate: [checkIfLoggedInFn, ...viewTPSubMenu],
    data: {
      breadcrumb: "breadcrumbs.transformational_project",
    },
    children: [
      {
        path: "",
        component: TpSubmenuComponent,
      },
      ...TP_PAGES_ROUTES,
      ...TP_MILESTONE_PAGES_ROUTES,
      ...CYCLES_ROUTES,
      ...SECTORS_ROUTES,
      ...TARGETS_ROUTES,
      ...AGREEMENTS_ROUTES,
    ],
  },
];
