@if (systemLayoutService.isReady()) {
    <adaa-period-slider
        [activePeriod]="selectedPeriod()!"
        [frequency]="Constants.FREQUENCY.QUARTERLY"
        type="quarter"
        (periodSelect)="selectPeriod($event)"
    />
}
<adaa-ribbon [widgetData]="dashboardWidgets()" [showLogo]="true" [showRefreshIcon]="false">
    <ng-container ribbonLogo>
        <div class="pb-1">
            <i class="adaa-icon-console_gs"></i>
        </div>
    </ng-container>
</adaa-ribbon>

@if (selectedPeriod()?.period) {
    <div class="bg-white rounded-1 shadow-sm border border-1 mt-2">
        <adaa-nested-table [columns]="columns()" [data]="scores" [periodId]="selectedPeriod()!.period!.id" [useGovScoresConfig]="true" />
    </div>
}

<adaa-float-action />
