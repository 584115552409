import { Component, computed, inject, input, OnInit, signal, viewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { AdaaHelper } from "../../../../core/utils";
import {
  DataTableComponent,
  FloatActionComponent,
  FormActionButtonsComponent,
  FormDropdownComponent,
} from "../../../../shared/components";
import { genericFloatButtons } from "../../../../shared/components/general/float-action";
import { CommonFloatActionButtonType } from "../../../../shared/components/general/float-action/types";
import { EntityPillarsComponent } from "../../../../shared/components/modals/entity-pillars/entity-pillars.component";
import { Constants } from "../../../../shared/constants/constants";
import { AdaaBoolean, PageMode } from "../../../../shared/constants/enums";
import { EntityModelType, TableButtonClicked, ValueText } from "../../../../shared/models";
import { GovScoresPillar, GovSectorEntity } from "../../../../shared/models/gov-scores.model";
import { EntitiesApiService, GovScoresApiService, SystemLayoutService } from "../../../../shared/services";

@Component({
  selector: "adaa-entity-pillars-editor",
  standalone: true,
  imports: [
    TranslateModule,
    FormDropdownComponent,
    DataTableComponent,
    EntityPillarsComponent,
    FormActionButtonsComponent,
    FloatActionComponent,
  ],
  templateUrl: "./entity-pillars-editor.component.html",
  styleUrl: "./entity-pillars-editor.component.scss",
})
export class EntityPillarsEditorComponent implements OnInit {
  private _govScoresApiService = inject(GovScoresApiService);
  private _activatedRoute = inject(ActivatedRoute);
  private _router = inject(Router);
  private _toastrService = inject(ToastrService);
  private _translateService = inject(TranslateService);
  private _entityApiService = inject(EntitiesApiService);
  private _systemLayoutService = inject(SystemLayoutService);

  private readonly _genericFloatButtons = genericFloatButtons();
  private readonly _untilDestroy = AdaaHelper.untilDestroyed();

  pillars = signal<GovScoresPillar[]>([]);
  entityId: number;
  pageMode = input.required<string>();
  Constants = Constants;
  PageMode = PageMode;
  pillarIndex: number | null;
  entityPillarsModal = viewChild.required<EntityPillarsComponent>("pillarsModal");
  submitted = signal<boolean>(false);

  availableEntities = signal<(GovSectorEntity | EntityModelType)[]>([]);
  availableEntitiesOptions = computed(() => {
    const field = AdaaHelper.getFieldLanguage("name");
    const result: ValueText[] = AdaaHelper.setDropdownArray(this.availableEntities(), "id", field);
    return result;
  });

  public ngOnInit(): void {
    this._systemLayoutService.hasActiveEntityChanged$.pipe(this._untilDestroy()).subscribe({
      next: () => {
        this._router.navigateByUrl("/console");
      },
    });

    this._initPage();
    this._floatActions();
  }

  public getAction(value: TableButtonClicked) {
    if (AdaaHelper.isDefinedAndNotEmpty(value.index)) this.pillarIndex = value.index;
    switch (value.event) {
      case "view":
      case "edit":
        AdaaHelper.isDefinedAndNotEmpty(value.index) && (this.entityPillarsModal().pillarIndex = value.index);
        this.entityPillarsModal().entityPillarForm.patchValue(value.data);
        this.entityPillarsModal().focusList.set(value.data.areasOfFocusList);
        this.entityPillarsModal().open();
        break;
      case "delete": {
        const list: GovScoresPillar[] = AdaaHelper.clone(this.pillars());
        list.splice(this.pillarIndex!, 1);
        this.pillars.set(list);
        break;
      }
      default:
        break;
    }
  }

  public addPillar(pillar: Partial<GovScoresPillar>) {
    const pillars = AdaaHelper.clone(this.pillars());
    if (AdaaHelper.isDefinedAndNotEmpty(this.pillarIndex)) {
      const value = this.pillars()[this.pillarIndex];
      const update = {
        ...value,
        ...pillar,
      };
      pillars.splice(this.pillarIndex, 1, update);
    } else pillars.push(pillar);
    this.pillars.set(pillars);
    this.pillarIndex = null;
  }

  public submit() {
    this.submitted.set(true);
    const weightTotal = this.pillars().reduce(function (acc, obj) {
      return acc + +obj.weight;
    }, 0);
    const unique = this.pillars().length === new Set(this.pillars().map((item) => item.pillarType)).size;

    if (!this.entityId) {
      this._toastrService.error(this._translateService.instant("notification.warning.missing_info"));
      return;
    }
    if (weightTotal !== 100) {
      this._toastrService.error(this._translateService.instant("government_scores.pillars.weights_warning"));
      return;
    }
    if (!unique) {
      this._toastrService.error(this._translateService.instant("government_scores.pillars.type_warning"));
      return;
    }

    const result = this.pillars().map((pillar, index) => {
      const areasOfFocusList = pillar.areasOfFocusList.map((focus) => {
        return {
          ...focus,
          newArea: focus.id ? AdaaBoolean.N : AdaaBoolean.Y,
        };
      });
      return {
        ...(pillar.id ? { id: pillar.id } : {}),
        ...(pillar.sectorId ? { sectorId: pillar.sectorId } : {}),
        ...(pillar.status ? { status: pillar.status } : {}),
        nameAE: pillar.nameAE,
        nameEN: pillar.nameEN,
        dscAE: pillar.dscAE,
        dscEN: pillar.dscEN,
        weight: pillar.weight,
        areasOfFocusList,
        pillarType: pillar.pillarType,
        pillarTypeNameAE: pillar.pillarTypeNameAE,
        pillarTypeNameEN: pillar.pillarTypeNameEN,
        newPillar: pillar.id ? AdaaBoolean.N : AdaaBoolean.Y,
        entityId: this.entityId,
        summary: pillar.summary,
        displayOrder: index + 1,
      };
    });

    const url =
      this.pageMode() === PageMode.create
        ? this._govScoresApiService.createGovScoresPillars(result)
        : this._govScoresApiService.updateGovScoresPillars(result);
    url.subscribe({
      next: (response) => {
        if (response.inError) return;
        this._toastrService.success(this._translateService.instant("notification.success.save"));
        this._router.navigateByUrl("/console/government-scores/pillars");
      },
    });
  }

  public close() {
    const redirectUrl = this._activatedRoute.snapshot?.queryParams["redirectUrl"];
    this._router.navigateByUrl(redirectUrl ?? "/console/government-scores/pillars");
  }

  private _initPage(): void {
    const entityId = this._activatedRoute.snapshot?.paramMap?.get("id");
    if (entityId) this.entityId = +entityId;
    if (this.entityId) {
      this._entityApiService.getById(+this.entityId!).subscribe({
        next: (response) => {
          if (response.inError) return;
          this.availableEntities.set([response.responseData]);
        },
      });
      this._govScoresApiService.getGovScoresPillarsByEntity(+this.entityId).subscribe({
        next: (response) => {
          if (response.inError) return;
          this.pillars.set(response.responseData);
        },
      });
    } else {
      this._govScoresApiService.getAvailablePillarSectorEntities().subscribe({
        next: (response) => {
          if (response.inError) return;
          this.availableEntities.set(response.responseData);
        },
      });
    }
  }

  private _floatActions(): void {
    const actions: CommonFloatActionButtonType[] = [
      {
        key: "helpdesk",
        data: {
          url: "/helpdesk/create",
        },
      },
      {
        key: "print",
        data: undefined,
      },
    ];

    if (this.pageMode() === PageMode.view)
      actions.push({
        key: "edit",
        data: {
          url: `/console/government-scores/pillars/edit/${this.entityId}`,
        },
      });

    this._genericFloatButtons(actions);
  }
}
