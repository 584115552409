import { Route } from "@angular/router";

import { checkIfLoggedInFn } from "../../core/guards";
import { NotificationCenterComponent } from "./notification-center.component";

export const NOTIFICATION_CENTER_ROUTES: Route[] = [
  {
    path: "communications",
    canActivate: [checkIfLoggedInFn],
    children: [
      {
        path: "",
        component: NotificationCenterComponent,
        data: {
          title: "breadcrumbs.communications",
          breadcrumb: "breadcrumbs.communications",
        },
      },
      {
        path: ":id",
        component: NotificationCenterComponent,
        data: {
          title: "breadcrumbs.communications",
          breadcrumb: { skip: true },
        },
      },
    ],
  },
];
