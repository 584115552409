<div class="my-4">
    <h4 class="fw-bold m-0">{{ "kpi_annual_weights.conf" | translate }}</h4>
</div>

<div class="d-block my-4">
    <div class="row">
        <div class="col-3">
            <div [formGroup]="entityform">
                <adaa-form-dropdown
                    [options]="this.entitiesOptions()"
                    [required]="true"
                    [enableTooltips]="true"
                    [controlName]="'entityId'"
                    [formControlName]="'entityId'"
                    (inputChanges)="getKPIAnnualWeightsConfig()"
                ></adaa-form-dropdown>
            </div>
        </div>
    </div>
    <div class="row conf-pane">
        <div class="col-2"></div>
        <div class="col-9 main-col periods">
            @for (year of planYears(); track $index) {
                <span>{{ year }}</span>
            }
        </div>
    </div>

    <div class="row conf-pane">
        <div class="col-2 left-col agg-row">
            @for (data of kpiTypes; track $index) {
                <div class="agg-col">
                    <span>
                        {{ getCurrentLanguageKPIType(data) }}
                    </span>
                </div>
            }
        </div>

        <div class="col-9 main-col main-tbl">
            @for (data of kpiTypes; track $index) {
                <div class="agg-row">
                    @for (year of planYears(); track $index) {
                        <div class="w-100" [class.disabled]="!hasManagePermission">
                            @if (getProperty(data.kpiType, year, "year")) {
                                <button
                                    class="btn btn-primary"
                                    [class.agg-status-btn]="!getProperty(data.kpiType, year, 'useWeight')"
                                    [class.disabled]="!hasManagePermission"
                                    (click)="toggleWeightsConf(data.kpiType, year)"
                                >
                                    <span class="fw-bolder">{{
                                        !!getProperty(data.kpiType, year, "useWeight")
                                            ? ("kpi_annual_weights.enabled" | translate)
                                            : ("kpi_annual_weights.disabled" | translate)
                                    }}</span>
                                </button>
                            }
                        </div>
                    }
                </div>
            }
        </div>
    </div>
</div>
