import { Component, computed, inject, signal } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { AdaaHelper } from "../../../core/utils";
import { FormDropdownComponent } from "../../../shared/components";
import { Constants } from "../../../shared/constants/constants";
import { AdaaBoolean, Language, PermissionAction } from "../../../shared/constants/enums";
import {
  CycleModelType,
  EntityModelType,
  KpiAnnualWeightsConfigModelType,
  KpiTypeWeightConf,
  ValueText,
} from "../../../shared/models";
import { EntitiesApiService, KpisApiService, PermissionsService, SystemLayoutService } from "../../../shared/services";

@Component({
  selector: "adaa-kpi-annual-weights-conf",
  standalone: true,
  imports: [TranslateModule, ReactiveFormsModule, FormDropdownComponent],
  templateUrl: "./kpi-annual-weights-conf.component.html",
  styleUrl: "./kpi-annual-weights-conf.component.scss",
})
export class KpiAnnualWeightsConfComponent {
  private _kpiApiService = inject(KpisApiService);
  private _translateService = inject(TranslateService);
  private _toastrService = inject(ToastrService);
  private _formBuilder = inject(FormBuilder);
  private _router = inject(Router);
  private _systemLayoutService = inject(SystemLayoutService);
  private _entitiesApiService = inject(EntitiesApiService);
  private _permissionService = inject(PermissionsService);

  AdaaHelper = AdaaHelper;
  private readonly _untilDestroy = AdaaHelper.untilDestroyed();
  entityform: FormGroup = new FormGroup({});

  annualEntities: KpiAnnualWeightsConfigModelType[];
  kpiTypes: KpiTypeWeightConf[] = [];
  entities = signal<EntityModelType[]>([]);
  entityId = signal<number>(-1);
  hasManagePermission: boolean = false;

  entitiesOptions = computed<ValueText[]>(() => {
    const field = AdaaHelper.getFieldLanguage("name");
    const result: ValueText[] = AdaaHelper.setDropdownArray(this.entities(), "id", field);
    return result;
  });

  planYears = signal<number[]>([]);
  currentPlan = signal<CycleModelType>({
    id: 0,
    nameAE: "",
    nameEN: "",
    dscAE: "",
    dscEN: "",
    startYear: 0,
    endYear: 0,
    dataRevisionId: 0,
    entityMaps: [],
    status: 0,
    wfStatus: "",
    updateTS: 0,
  });

  public ngOnInit() {
    this._systemLayoutService.hasCycleChanged$.pipe(this._untilDestroy()).subscribe({
      next: () => {
        window.location.reload();
      },
    });

    this._systemLayoutService.hasActiveEntityChanged$.pipe(this._untilDestroy()).subscribe({
      next: () => {
        this._router.navigateByUrl("/console/calculation-management");
      },
    });

    this._permissionService.isPermissionsLoaded.pipe(this._untilDestroy()).subscribe((loaded: boolean) => {
      if (!loaded) return;

      this._checkManagePermissions();
    });
    this._prepareForm();
    this._getEntities();
    this._preparePlanYears();
  }

  private _prepareForm() {
    this.entityform = this._formBuilder.group({
      entityId: this._formBuilder.control<number | null>(null),
    });
  }

  private _getEntities(): void {
    this._entitiesApiService.getAll(AdaaBoolean.N).subscribe({
      next: (response) => {
        this.entities.set(response.responseData.filter((entity) => entity.id !== 1));
      },
      complete: () => {
        this.entityform.controls["entityId"]?.patchValue(this.entitiesOptions()[0].value);
        this.getKPIAnnualWeightsConfig();
      },
    });
  }

  public getKPIAnnualWeightsConfig() {
    const entityId = this.entityform.get("entityId")?.value;
    this._kpiApiService.getKPIAnnualWeightsConfig(entityId).subscribe({
      next: (response) => {
        this.annualEntities = response.responseData;
        this._setEntityConfigurations();
      },
    });
  }

  private _setEntityConfigurations() {
    if (this.annualEntities.length < 1) {
      return;
    }
    const set = new Set();
    for (const { kpiType } of this.annualEntities) {
      set.add(kpiType);
    }
    this.kpiTypes = [];
    for (const id of Array.from(set)) {
      const index = this.annualEntities.findIndex((val) => val.kpiType === id);
      if (index !== -1) {
        const { kpiType, kpiTypeNameAE, kpiTypeNameEN } = this.annualEntities[index];
        this.kpiTypes.push({ kpiType, kpiTypeNameAE, kpiTypeNameEN });
      }
    }
  }

  private _preparePlanYears() {
    const plan = AdaaHelper.getLocalStorage(Constants.localStorageKeys.currentPlan, { type: "json" });
    this.currentPlan.set(plan as CycleModelType);
    if (!this.currentPlan()) return;
    const startYear = new Date(this.currentPlan().startYear).getFullYear();
    const endYear = new Date(this.currentPlan().endYear).getFullYear();
    const years: number[] = [];
    for (let i = startYear; i <= endYear; i++) {
      years.push(i);
    }
    this.planYears.set(years);
  }

  public getCurrentLanguageKPIType(kpi: KpiTypeWeightConf) {
    const localStorageLang = AdaaHelper.getLocalStorage(Constants.localStorageKeys.language) as Language | null;

    let displayLabel = "";
    if (localStorageLang === Language.English) {
      displayLabel = kpi["kpiTypeNameEN"];
    } else displayLabel = kpi["kpiTypeNameAE"];
    return displayLabel;
  }

  public getProperty(kpiType: number, year: number, key: keyof KpiAnnualWeightsConfigModelType) {
    const weights = this.annualEntities.filter((val) => val.kpiType === kpiType);
    if (weights.length < 1) {
      return;
    }
    const index = weights.findIndex((val) => val.year === year);
    if (index === -1) {
      return;
    }
    return weights[index][key];
  }

  public toggleWeightsConf(kpiType: number, year: number) {
    if (!this.hasManagePermission) return;

    const newWeightConfig = !this.getProperty(kpiType, year, "useWeight");
    const currentWeightConfig = this.getProperty(kpiType, year, "useWeight");
    if (currentWeightConfig !== newWeightConfig) {
      this._updateConfiguration(kpiType, year, newWeightConfig);
    }
  }

  private _updateConfiguration(kpiType: number, year: number, newWeightConfig: boolean) {
    const id = this.getProperty(kpiType, year, "id");
    if (id)
      this._kpiApiService.updadeAnnualConfig(+id, { useWeight: newWeightConfig }).subscribe((response) => {
        if (response.inError) return;
        this.getKPIAnnualWeightsConfig();
        this._toastrService.success(this._translateService.instant("notification.success.save"));
      });
  }

  private _checkManagePermissions(): void {
    this.hasManagePermission = this._permissionService.hasPermission(
      [
        {
          objectTypeId: Constants.CONSTANT_PERMISSIONS.KPI_ANNUAL_WEIGHTS,
          permissionAction: PermissionAction.manage,
        },
      ],
      "and"
    );
  }
}
