<ng-template #content let-modal>
    <div class="modal-header">
        <h5 class="text-center fw-bold w-100">{{ "common.form.modals.input_default_data" | translate }}</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body overflow-x-hidden p-3">
        <p class="text-center my-2">
            {{ ("common.form.modals.input_default_data_information" | translate) + " " + records() }}
        </p>
        <div>
            @switch (fieldType()) {
                @case ("text") {
                    <adaa-form-input (inputChanges)="value.set($event)"></adaa-form-input>
                }
                @case ("dropdown") {
                    <adaa-form-dropdown
                        [options]="dropdownData() ?? []"
                        (inputChanges)="value.set($event)"
                        appendTo=".modal-content"
                    ></adaa-form-dropdown>
                }
                @case ("date") {
                    <adaa-form-input-date (inputChanges)="value.set($event)" />
                }
            }
        </div>
    </div>
    <div class="modal=footer d-flex justify-content-center mt-3">
        <adaa-form-action-buttons
            (cancelClicked)="modal.dismiss()"
            (saveClicked)="defaultValue.emit(value()); modal.close()"
        ></adaa-form-action-buttons>
    </div>
</ng-template>
