import { CommonModule } from "@angular/common";
import { Component, inject, input, output, signal } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { TranslateModule } from "@ngx-translate/core";

import { AdaaHelper } from "../../../core/utils";
import { Language } from "../../../shared/constants/enums";
import { NotificationConfigPlaceholder } from "../../../shared/models/notifications-config.model";
import { AppService } from "../../../shared/services";

@Component({
  selector: "adaa-placeholder-list",
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, TranslateModule],
  templateUrl: "./placeholder-list.component.html",
  styleUrl: "./placeholder-list.component.scss",
})
export class PlaceholderListComponent {
  private _appService = inject(AppService);

  items = input.required<NotificationConfigPlaceholder[]>();
  searchValues = signal<NotificationConfigPlaceholder[] | undefined>(undefined);
  placeholderDoubleClick = output<NotificationConfigPlaceholder>();

  public getDescription(item: NotificationConfigPlaceholder) {
    if (this._appService.language() === Language.Arabic) return item.dscAE;
    else return item.dscEN;
  }

  faSearch = faSearch;
  AdaaHelper = AdaaHelper;
  Language = Language;

  public onPlaceholderDoubleClick(placeholder: NotificationConfigPlaceholder) {
    this.placeholderDoubleClick.emit(placeholder);
  }

  public search(event: Event) {
    const searchTerm = (event.target as HTMLInputElement).value;
    const language = AdaaHelper.getCurrentLang() === Language.Arabic ? "dscAE" : "dscEN";
    const searchValues = this.items().filter(
      (item) =>
        item.placeholder.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item[language].toLowerCase().includes(searchTerm.toLowerCase())
    );
    this.searchValues.set(searchValues);
  }
}
