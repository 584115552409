import { hasPermissionGuard } from "../../core/guards";
import { Constants } from "../../shared/constants/constants";
import { PermissionAction } from "../../shared/constants/enums";

export const viewDimensionsPagesGuards = [
  hasPermissionGuard({
    actor: {
      modifier: "or",
      permissions: [
        {
          permissionAction: PermissionAction.view,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.DIMENSIONS,
        },
        {
          permissionAction: PermissionAction.view,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.DIMENSIONS_SYNC,
        },
        {
          permissionAction: PermissionAction.view,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.DIMENSIONS_LOGS,
        },
      ],
    },
    redirectTo: "/403",
  }),
];

export const viewDimensionsSyncPagesGuards = [
  hasPermissionGuard({
    actor: {
      modifier: "or",
      permissions: [
        {
          permissionAction: PermissionAction.view,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.DIMENSIONS_SYNC,
        },
      ],
    },
    redirectTo: "/403",
  }),
];

export const viewDimensionsLogsPageGuards = [
  hasPermissionGuard({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.view,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.DIMENSIONS_LOGS,
        },
      ],
    },
    redirectTo: "/403",
  }),
];
