import { Component, inject, input, OnInit, viewChild } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { AdaaHelper } from "../../../core/utils";
import { ConfirmationModalComponent, DataTableComponent } from "../../../shared/components";
import { Constants } from "../../../shared/constants/constants";
import { AdaaBoolean, Language, PermissionAction } from "../../../shared/constants/enums";
import { CustomButton, TableButtonClicked } from "../../../shared/models";
import { Formula } from "../../../shared/models/formulas.model";
import { AppService, FormulasApiService, PermissionsService } from "../../../shared/services";

@Component({
  selector: "adaa-formulastemplate",
  standalone: true,
  imports: [DataTableComponent, TranslateModule],
  templateUrl: "./formulas-template-list.component.html",
  styleUrl: "./formulas-template-list.component.scss",
})
export class FormulasTemplateListComponent implements OnInit {
  tableConfList = input.required<string>();
  private _router = inject(Router);
  private _formulasTemplateService = inject(FormulasApiService);
  private _modalService = inject(NgbModal);
  private _appService = inject(AppService);
  private _toastrService = inject(ToastrService);
  private _translateService = inject(TranslateService);
  private _permissionsService = inject(PermissionsService);

  adaaDataTable = viewChild.required<DataTableComponent>("adaaDataTable");
  Constants = Constants;
  private _untilDestroyed = AdaaHelper.untilDestroyed();

  customButtons: CustomButton[] = [];

  public ngOnInit(): void {
    this._permissionsService.isPermissionsLoaded.pipe(this._untilDestroyed()).subscribe((loaded: boolean) => {
      if (!loaded) return;

      this._checkPermissions();
    });
  }

  private _checkPermissions() {
    const permission = this._permissionsService.hasPermission([
      {
        permissionAction: PermissionAction.manage,
        objectTypeId: Constants.CONSTANT_PERMISSIONS.FORMULAS,
      },
    ]);

    this.customButtons.push(
      {
        iconName: "adaa-icon-adaa-ok-Y",
        eventName: "enable",
        iconTitle: "common.form.label.active",
        onlyIf: (data: { enable: AdaaBoolean }) => {
          return AdaaBoolean.Y === data?.enable && permission;
        },
      },
      {
        iconName: "adaa-icon-adaa-ok-N",
        eventName: "disable",
        iconTitle: "common.form.label.inactive",
        onlyIf: (data: { enable: AdaaBoolean }) => {
          return AdaaBoolean.N === data?.enable && permission;
        },
      }
    );
  }

  public addNewClicked() {
    this._router.navigateByUrl("/console/formulas/create");
  }

  private _deleteFormula(data: Formula) {
    data.status = Constants.OBJECT_STATUS.REMOVE;
    const modal = this._modalService.open(ConfirmationModalComponent, {
      centered: true,
      size: "md",
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });

    modal.componentInstance.header = "formulas.delete_yes_no_title";
    modal.componentInstance.title = "formulas.delete_yes_no_message";

    modal.result.then((e) => {
      if (e) {
        this._formulasTemplateService.updateFormula(data).subscribe({
          next: () => {
            this._toastrService.success(this._translateService.instant("notification.success.remove"));
            this.adaaDataTable().loadTableData();
          },
        });
      }
    });
  }

  private _activateFormula(value: TableButtonClicked) {
    this._formulasTemplateService.activateFormula(value.data.id).subscribe({
      next: () => {
        this._toastrService.success(
          this._translateService.instant(
            "notification.success." + (value.event === "enable" ? "deactivate" : "activate")
          )
        );
        this.adaaDataTable().loadTableData();
      },
    });
  }

  public getAction(value: TableButtonClicked) {
    switch (value.event) {
      case "edit":
        this._router.navigateByUrl(`/console/formulas/edit/${value.data.id}`);
        break;
      case "view":
        this._router.navigateByUrl(`/console/formulas/${value.data.id}`);
        break;
      case "delete":
        this._deleteFormula(value.data);
        break;
      case "enable":
      case "disable":
        this._activateFormula(value);
        break;

      default:
        break;
    }
  }
}
