import { Component, computed, inject, OnInit, signal, viewChild } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { FaIconLibrary, FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { EMPTY, switchMap } from "rxjs";

import { PermissionActionDescriptorType } from "../../../../adaa-types";
import { AdaaHelper } from "../../../core/utils";
import { ConfirmationModalComponent } from "../../../shared/components";
import { OrganizationStructureComponent } from "../../../shared/components/organization-structure/organization-structure.component";
import { Constants } from "../../../shared/constants/constants";
import { AdaaBoolean, Language, PageMode, PermissionAction } from "../../../shared/constants/enums";
import { HasPermissionDirective } from "../../../shared/directives";
import { EntityModelType, OrgUnit, StructuredOrgUnit, TableButtonClicked } from "../../../shared/models";
import { AppService, EntitiesApiService, OrgUnitApiService, SystemLayoutService } from "../../../shared/services";
import { OrgUnitEditorService } from "../../../shared/services/org-unit-editor.service";
import { OrganizationUnitEditorModalComponent } from "./organization-unit-editor-modal";

@Component({
  selector: "adaa-organization-unit",
  standalone: true,
  imports: [
    OrganizationStructureComponent,
    TranslateModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    OrganizationUnitEditorModalComponent,
    HasPermissionDirective,
  ],
  templateUrl: "./organization-unit.component.html",
  styleUrl: "./organization-unit.component.scss",
})
export class OrganizationUnitComponent implements OnInit {
  private _entitiesApiService = inject(EntitiesApiService);
  private _orgUnitApiService = inject(OrgUnitApiService);
  private _iconLibrary: FaIconLibrary = inject(FaIconLibrary);
  private _modalService = inject(NgbModal);
  private _appService = inject(AppService);
  private _translateService = inject(TranslateService);
  private _toastrService = inject(ToastrService);
  private _systemLayoutService = inject(SystemLayoutService);
  orgUnitEditorService = inject(OrgUnitEditorService);

  private readonly _untilDestroyed = AdaaHelper.untilDestroyed();
  arrayOfOrgUnitsStructured = signal<StructuredOrgUnit[]>([]);
  entity = signal<EntityModelType>({
    id: 0,
    refCode: "",
    nameAE: "",
    nameEN: "",
    shortNameAE: "",
    shortNameEN: "",
    entityType: 0,
    entityTypeNameAE: "",
    entityTypeNameEN: "",
    entitySize: 0,
    entitySizeNameAE: "",
    entitySizeNameEN: "",
    dscAE: "",
    dscEN: "",
    logoHorizontalOffset: 0,
    status: 0,
    logoVerticalAttach: null,
    logoHorizontalAttach: null,
    enabler: AdaaBoolean.Y,
    enabled: AdaaBoolean.Y,
    pulseUrl: "",
    updateTS: 0,
  });
  orgUnits = signal<OrgUnit[]>([]);
  selectedUnit = signal<StructuredOrgUnit | undefined>(undefined);
  parentOrgUnit = signal<StructuredOrgUnit | undefined>(undefined);
  pageMode = signal<string>("");
  editor = viewChild.required<OrganizationUnitEditorModalComponent>("editor");
  PageMode = PageMode;

  createPermission = signal<PermissionActionDescriptorType>({
    actor: {
      modifier: "and",
      permissions: [
        {
          permissionAction: PermissionAction.create,
          objectTypeId: Constants.CONSTANT_PERMISSIONS.ORG_STRUCTURE,
        },
      ],
    },
  });

  selectedUnitOption = computed(() => {
    return [this.selectedUnit()?.id];
  });
  orgUnitOptions = computed(() => {
    const mappedEntity = {
      id: this.entity().id,
      refCode: this.entity().refCode,
      dscAE: this.entity().dscAE,
      dscEN: this.entity().dscEN,
      parentOrgUnit: null,
      updateUser: this.entity().updateUser,
      updateTS: this.entity().updateTS,
      nameAE: this.entity().nameAE,
      nameEN: this.entity().nameEN,
      planId: this.entity().planId,
      entityId: this.entity().entityId,
      status: this.entity().status,
    };
    return [
      ...AdaaHelper.setDropdownArray(
        [mappedEntity, ...this.orgUnits()],
        "id",
        AdaaHelper.getFieldLanguage("name"),
        "parentOrgUnit"
      ),
    ];
  });

  public ngOnInit(): void {
    this._iconLibrary.addIcons(faPlus);

    this.orgUnitEditorService.formValidator();
    this._getEntity();

    this._systemLayoutService.hasActiveEntityChanged$.pipe(this._untilDestroyed()).subscribe({
      next: () => {
        this._getEntity();
        this.selectedUnit.set(undefined);
        this.parentOrgUnit.set(undefined);
      },
    });
  }

  private _getEntity() {
    const entityId = AdaaHelper.getLocalStorage(Constants.localStorageKeys.currentEntity, {
      type: "prop",
      property: "id",
    }) as string;

    if (entityId) {
      this._entitiesApiService
        .getById(+entityId)
        .pipe(
          switchMap((response) => {
            if (response.inError) return EMPTY;
            this.entity.set(response.responseData);
            return this._getOrgUnitsForProfile(+entityId);
          })
        )
        .subscribe({
          next: (response) => {
            this.orgUnits.set(response.responseData);
          },
          complete: () => this._structureOrgUnits(),
        });
    }
  }

  private _getOrgUnitsForProfile(entityId: number) {
    return this._orgUnitApiService.getByEntityIdForProfile(+entityId);
  }

  private _structureOrgUnits() {
    this.arrayOfOrgUnitsStructured.set([]);
    const entityStructured = AdaaHelper.clone(this.entity());
    this.arrayOfOrgUnitsStructured().push(entityStructured);
    entityStructured.expanded = true;
    entityStructured.childs = this.orgUnits().filter((c) => c.parentOrgUnit === null || c.parentOrgUnit === undefined);
    entityStructured.childs = entityStructured.childs.map((c: StructuredOrgUnit) => {
      c.expanded = true;
      return c;
    });
    this._mapOrgUnits(entityStructured.childs);
  }

  private _mapOrgUnits(orgUnits: StructuredOrgUnit[]) {
    orgUnits.forEach((unit) => {
      const childOrgUnits = this.orgUnits().filter((c) => c.parentOrgUnit === unit.id);
      unit.childs = childOrgUnits;
      unit.childs = unit.childs.map((c: StructuredOrgUnit) => {
        c.expanded = true;
        return c;
      });
      if (unit.childs && unit.childs.length > 0) {
        this._mapOrgUnits(unit.childs);
      }
    });
  }

  public getAction(value: TableButtonClicked) {
    switch (value.event) {
      case "edit": {
        const parentOrgUnit = this._findParentOrgUnit(this.arrayOfOrgUnitsStructured(), value.data.id);
        parentOrgUnit && this.parentOrgUnit.set(parentOrgUnit);
        this.editor().orgUnitForm.get("parentOrgUnit")?.enable();
        this.editor().orgUnitForm.patchValue(value.data);
        this.editor().orgUnitForm.controls["parentOrgUnit"].patchValue([this.parentOrgUnit()?.id]);
        this.pageMode.set(PageMode.edit);
        this.editor().open();
        break;
      }
      case "delete":
        this._deleteOrgUnit(value.data);
        break;

      default:
        break;
    }
  }

  public openEditor() {
    this.pageMode.set(PageMode.create);
    this.editor().orgUnitForm.get("parentOrgUnit")?.disable();
    this.editor().orgUnitForm.controls["parentOrgUnit"].patchValue(
      AdaaHelper.getItemValueByToken(this.selectedUnit(), "name")
    );
    this.editor().open();
  }

  private _deleteOrgUnit(data: OrgUnit) {
    const modal = this._modalService.open(ConfirmationModalComponent, {
      animation: true,
      scrollable: false,
      keyboard: false,
      size: "lg",
      centered: true,
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });

    modal.componentInstance.header = "provisioning.orgunit.delete_yes_no_title";
    modal.componentInstance.title = "provisioning.orgunit.delete_yes_no_information";

    modal.result.then((e) => {
      if (e) {
        this._orgUnitApiService.deleteOrgUnit(data.id).subscribe({
          next: () => {
            this._toastrService.success(this._translateService.instant("notification.success.remove"));
            this._getOrgUnitsForProfile(this.entity().id).subscribe({
              next: (response) => {
                this.orgUnits.set(response.responseData);
                this._structureOrgUnits();
              },
            });
          },
        });
      }
    });
  }

  public setSelectedUnit(data: OrgUnit) {
    this.selectedUnit.set(data);
    const parentOrgUnit = this._findParentOrgUnit(this.arrayOfOrgUnitsStructured(), data.id);
    parentOrgUnit && this.parentOrgUnit.set(parentOrgUnit);
  }

  private _findParentOrgUnit(array: StructuredOrgUnit[], id: number, parent?: OrgUnit): OrgUnit | null {
    for (const value of array) {
      if (value.id === id) {
        return parent || null;
      }
      if (value.childs) {
        const foundParent = this._findParentOrgUnit(value.childs, id, value);
        if (foundParent) {
          return foundParent;
        }
      }
    }
    return null;
  }

  public submit(formValue: { nameAE: string; nameEN: string; parentOrgUnit: number }) {
    let result: Partial<StructuredOrgUnit> = {};
    if (this.pageMode() === PageMode.create) {
      result = {
        ...formValue,
        parentOrgUnit:
          this.pageMode() === PageMode.create
            ? this.selectedUnit()?.id === this.entity().id
              ? undefined
              : this.selectedUnit()?.id
            : formValue.parentOrgUnit,
        entityId: this.entity().id,
        manager: undefined,
        planId: undefined,
        status: Constants.OBJECT_STATUS.ACTIVE,
      };
    }
    if (this.pageMode() === PageMode.edit) {
      const parentOrgUnit = Array.isArray(formValue.parentOrgUnit)
        ? formValue.parentOrgUnit[0]
        : formValue.parentOrgUnit;
      result = {
        ...formValue,
        parentOrgUnit: parentOrgUnit === this.entity().id ? null : parentOrgUnit,
        childs: this.selectedUnit()?.childs,
        entityId: this.entity().id,
        expanded: this.selectedUnit()?.expanded,
        id: this.selectedUnit()?.id,
        manager: undefined,
        status: this.selectedUnit()?.status,
        updateTS: this.selectedUnit()?.updateTS,
        updateUser: this.selectedUnit()?.updateUser,
      };
    }
    this._orgUnitApiService.updateOrgUnit(result).subscribe({
      next: (response) => {
        if (response.inError) return;
        this._getOrgUnitsForProfile(this.entity().id).subscribe({
          next: (response) => {
            this._modalService.dismissAll();
            this._toastrService.success(this._translateService.instant("notification.success.save"));
            this.orgUnits.set(response.responseData);
            this._structureOrgUnits();
          },
        });
      },
    });
  }
}
