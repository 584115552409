import { Route } from "@angular/router";

import { checkIfLoggedInFn, notPmoEntity, pmoEntity } from "../../core/guards";
import { AdaaHelper } from "../../core/utils";
import {
  viewCalculationManagementPageGuards,
  viewKpiAnnualWeightsEKPIPageGuards,
  viewKpiAnnualWeightsMOKPIPageGuards,
  viewKpiAnnualWeightsOPMPageGuards,
  viewKpiAnnualWeightsPageGuards,
  viewKpiAnnualWeightsSKPIPageGuards,
  viewKpiAnnualWeightsSRVKPIPageGuards,
} from "./_guards";
import { CalculationEntityComponent } from "./calculation-entity/calculation-entity.component";
import { CalculationGovernmentComponent } from "./calculation-government/calculation-government.component";
import { CalculationManagementSubmenuComponent } from "./calculation-management-submenu/calculation-management-submenu.component";
import { KPIAnnualWeightsComponent } from "./KPI-annual-weights/kpi-annual-weights/kpi-annual-weights.component";
import { SubMenuComponent } from "./KPI-annual-weights/sub-menu/sub-menu.component";
import { KpiAnnualWeightsConfComponent } from "./kpi-annual-weights-conf/kpi-annual-weights-conf.component";

export const CALCULATION_MANAGEMENT_ROUTES: Route[] = [
  {
    path: "calculation-management",
    canActivate: [checkIfLoggedInFn],
    children: [
      {
        path: "",
        component: CalculationManagementSubmenuComponent,
      },
      {
        path: "calculation-government",
        component: CalculationGovernmentComponent,
        canActivate: [pmoEntity, ...viewCalculationManagementPageGuards],
        data: {
          breadcrumb: "calculation.calculation_government",
          title: "calculation.calculation_government",
        },
      },
      {
        path: "calculation-entity-performance",
        component: CalculationEntityComponent,
        canActivate: [pmoEntity, ...viewCalculationManagementPageGuards],
        data: {
          breadcrumb: "calculation.calculation_entity",
          title: "calculation.calculation_entity",
        },
      },
      {
        path: "kpi-annual-weights-conf",
        component: KpiAnnualWeightsConfComponent,
        canActivate: [...viewKpiAnnualWeightsPageGuards],
        data: {
          breadcrumb: "kpi_annual_weights.conf",
          title: "kpi_annual_weights.conf",
        },
      },
      {
        path: "kpi-annual-weights",
        canActivate: [notPmoEntity, ...viewKpiAnnualWeightsPageGuards, checkIfLoggedInFn],
        data: {
          breadcrumb: "breadcrumbs.kpi_annual_weights",
          title: "breadcrumbs.kpi_annual_weights",
        },
        children: [
          {
            path: "",
            component: SubMenuComponent,
          },
          {
            path: "skpi",
            component: KPIAnnualWeightsComponent,
            canActivate: [...viewKpiAnnualWeightsSKPIPageGuards],
            data: {
              breadcrumb: "kpi_annual_weights.strategic_kpi",
              title: "kpi_annual_weights.strategic_kpi",
              kpiType: 38,
            },
          },
          {
            path: "opm",
            component: KPIAnnualWeightsComponent,
            canActivate: [...viewKpiAnnualWeightsOPMPageGuards],
            data: {
              breadcrumb: AdaaHelper.isPlanArchived() ? "kpi_annual_weights.operational_kpi" : "kpi_annual_weights.opm",
              title: AdaaHelper.isPlanArchived() ? "kpi_annual_weights.operational_kpi" : "kpi_annual_weights.opm",
              kpiType: 39,
            },
          },
          {
            path: "mokpi",
            component: KPIAnnualWeightsComponent,
            canActivate: [...viewKpiAnnualWeightsMOKPIPageGuards],
            data: {
              breadcrumb: "kpi_annual_weights.mokpi",
              title: "kpi_annual_weights.mokpi",
              kpiType: 53,
            },
          },
          {
            path: "srvkpi",
            component: KPIAnnualWeightsComponent,
            canActivate: [...viewKpiAnnualWeightsSRVKPIPageGuards],
            data: {
              breadcrumb: "kpi_annual_weights.service_kpi",
              title: "kpi_annual_weights.service_kpi",
              kpiType: 82,
            },
          },
          {
            path: "ekpi",
            component: KPIAnnualWeightsComponent,
            canActivate: [...viewKpiAnnualWeightsEKPIPageGuards],
            data: {
              breadcrumb: "kpi_annual_weights.enabler_kpi",
              title: "kpi_annual_weights.enabler_kpi",
              kpiType: 41,
            },
          },
        ],
      },
    ],
    data: {
      breadcrumb: "calculation.calculation_management",
      title: "calculation.calculation_management",
    },
  },
];
