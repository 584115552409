import { inject } from "@angular/core";
import { type ActivatedRouteSnapshot, type CanActivateFn, Router } from "@angular/router";

import { AdaaHelper } from "../../core/utils";
import { Constants } from "../../shared/constants/constants";

export const isPMODashboardGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const router = inject(Router);

  const path = route.routeConfig?.path;
  const entityId = AdaaHelper.getLocalStorage(Constants.localStorageKeys.currentEntity, {
    type: "prop",
    property: "id",
  }) as number | undefined;

  if (!entityId) return true;
  if (entityId === Constants.CONSTANT_PMO_ID && path === "dashboard/entity")
    return router.createUrlTree(["/dashboard/target"]);

  return true;
};

export const isEntityDashboardGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const router = inject(Router);

  const path = route.routeConfig?.path;
  const entityId = AdaaHelper.getLocalStorage(Constants.localStorageKeys.currentEntity, {
    type: "prop",
    property: "id",
  }) as number | undefined;

  if (!entityId) return true;
  if (entityId !== Constants.CONSTANT_PMO_ID && path !== "dashboard/entity")
    return router.createUrlTree(["/dashboard/entity"]);

  return true;
};
