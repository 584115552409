import { Component, inject, OnInit, signal } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { switchMap } from "rxjs";

import { AdaaHelper } from "../../../core/utils";
import { DataTableComponent, FormInputComponent, FormRadioComponent } from "../../../shared/components";
import { Constants } from "../../../shared/constants/constants";
import { AdaaBoolean } from "../../../shared/constants/enums";
import { TableInputChangedOutput, TableInputs, ValueText } from "../../../shared/models";
import { NotificationChannel } from "../../../shared/models/notification-channel,model";
import { UserProfile } from "../../../shared/models/user-profile.model";
import { EntitiesApiService, LanguageService, UserProfileApiService } from "../../../shared/services";

@Component({
  selector: "adaa-profile-personal-information",
  standalone: true,
  imports: [FormInputComponent, ReactiveFormsModule, TranslateModule, DataTableComponent, FormRadioComponent],
  templateUrl: "./profile-personal-information.component.html",
  styleUrl: "./profile-personal-information.component.scss",
})
export class ProfilePersonalInformationComponent implements OnInit {
  private _userProfileService = inject(UserProfileApiService);
  private _entitiesApiService = inject(EntitiesApiService);
  private _formBuilder = inject(FormBuilder);
  private _translateService = inject(TranslateService);
  languageService = inject(LanguageService);

  profileForm: FormGroup = new FormGroup({});
  checkForm: FormGroup = new FormGroup({});
  notificationChannels = signal<NotificationChannel[]>([]);
  notificationPreference = signal<string | undefined>(undefined);
  notificationPreferences = signal<Partial<NotificationChannel>[]>([]);
  profileInformation = signal<UserProfile | undefined>(undefined);
  userEntity = signal<string | undefined>(undefined);
  initialFormValue = signal<FormGroup | undefined>(undefined);
  radioOptions = signal<ValueText[]>([]);

  tableConfList = Constants.MAIN_TABLE_LIST_CONF_KEY.NOTIFICATION_CONFIGURATION;
  tableInputs: TableInputs[] = [
    {
      fieldName: "sms",
      checkboxMode: "trueFalse",
      disableRules: [
        {
          propertyName: "smsActive",
          compareWith: AdaaBoolean.N,
          operator: "equal",
        },
      ],
    },
    {
      fieldName: "email",
      checkboxMode: "trueFalse",
      disableRules: [
        {
          propertyName: "emailActive",
          compareWith: AdaaBoolean.N,
          operator: "equal",
        },
      ],
    },
  ];

  public ngOnInit(): void {
    this._prepareForm();
    this._getUserInformation();
    this._getNotificationChannels();
    this._getNotificationPreference();
  }

  private _getUserInformation() {
    this._userProfileService
      .getUserInformation()
      .pipe(
        switchMap((response) => {
          const entityId = response.responseData.entityId;
          this.profileInformation.set(response.responseData);
          this.profileForm.patchValue(response.responseData);
          this.profileForm.controls["directPhone"].patchValue(response.responseData["directPhone"]);
          this.profileForm.controls["mobilePhone"].patchValue(response.responseData["mobilePhone"]);
          this.initialFormValue.set(this.profileForm.value);
          return this._entitiesApiService.getById(entityId);
        })
      )
      .subscribe({
        next: (response) => {
          this.userEntity.set(AdaaHelper.getItemValueByToken(response.responseData, "name"));
          this._translateService.get("language.forms.arabic").subscribe({
            complete: () => {
              this.radioOptions.set([
                { text: this._translateService.instant("common.form.label.arabic"), value: "ARABIC" },
                { text: this._translateService.instant("common.form.label.english"), value: "ENGLISH" },
              ]);
            },
          });
        },
      });
  }

  private _getNotificationChannels() {
    const currentUserId = AdaaHelper.getLocalStorage(Constants.localStorageKeys.user, {
      type: "prop",
      property: "id",
    });
    this._userProfileService.getNotificationChannels(+currentUserId).subscribe({
      next: (response) => {
        this.notificationChannels.set(response.responseData);
      },
    });
  }

  private _getNotificationPreference() {
    const currentUserId = AdaaHelper.getLocalStorage(Constants.localStorageKeys.user, {
      type: "prop",
      property: "id",
    });
    this._userProfileService.getNotificationPreference(+currentUserId).subscribe({
      next: (response) => {
        this.notificationPreference.set(response.responseData.preferedLanguage);
      },
    });
  }

  private _prepareForm() {
    this.profileForm = this._formBuilder.group({
      username: this._formBuilder.control<string | undefined>(
        { value: this.profileInformation()?.username, disabled: true },
        {
          validators: [Validators.required],
        }
      ),
      email: this._formBuilder.control<string | undefined>(
        { value: "", disabled: true },
        {
          validators: [Validators.required, Validators.email],
        }
      ),
      nameAE: this._formBuilder.control<string | undefined>(
        { value: "", disabled: true },
        {
          validators: [Validators.required],
        }
      ),
      nameEN: this._formBuilder.control<string | undefined>(
        { value: "", disabled: true },
        {
          validators: [Validators.required],
        }
      ),
      jobTitleAE: this._formBuilder.control<string | undefined>(""),
      jobTitleEN: this._formBuilder.control<string | undefined>(""),
      directPhone: this._formBuilder.control<string | number | undefined>(undefined),
      mobilePhone: this._formBuilder.control<string | number | undefined>(undefined),
    });

    this.checkForm = this._formBuilder.group({
      arabic: this._formBuilder.control<string | undefined>(""),
      english: this._formBuilder.control<string | undefined>(""),
    });
  }

  public setPreferredLanguage(value: string) {
    this.notificationPreference.set(value);
  }

  public setNotificationPreferences(event: TableInputChangedOutput[]) {
    const notifications: Partial<NotificationChannel>[] = [];
    for (const check of event) {
      const value: NotificationChannel | number = notifications.findIndex(
        (notification) => notification.id === check.data.id
      );
      if (value === -1) {
        const result = {
          notificationId: check.data.notificationId,
          preferedChannel: check.data.preferedChannel,
          preferedLanguage: check.data.preferedLanguage,
          sms: check.fieldName === "sms" ? check.value : check.data.sms,
          email: check.fieldName === "email" ? check.value : check.data.email,
          active: check.data.active,
          id: check.data.id,
        };
        notifications.push(result);
      } else {
        const result = {
          ...notifications[value],
          [check.fieldName]: check.value,
        };
        notifications[value] = result;
      }
    }
    this.notificationPreferences.set(notifications);
  }
}
