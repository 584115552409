<div class="bg-white shadow-md rounded-1 p-2 mb-3">
    <form class="col-12" [formGroup]="profileForm">
        <div class="bg-white shadow-md rounded-1 p-2 mb-3">
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.username' | translate"
                        formControlName="username"
                        controlName="username"
                        [required]="true"
                    ></adaa-form-input>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.email' | translate"
                        formControlName="email"
                        controlName="email"
                        [required]="true"
                    ></adaa-form-input>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.nameAE' | translate"
                        formControlName="nameAE"
                        controlName="nameAE"
                        [required]="true"
                    ></adaa-form-input>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.nameEN' | translate"
                        formControlName="nameEN"
                        controlName="nameEN"
                        [required]="true"
                    ></adaa-form-input>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.jobTitleAE' | translate"
                        formControlName="jobTitleAE"
                        controlName="jobTitleAE"
                    ></adaa-form-input>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.jobTitleEN' | translate"
                        formControlName="jobTitleEN"
                        controlName="jobTitleEN"
                    ></adaa-form-input>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.directPhone' | translate"
                        formControlName="directPhone"
                        controlName="directPhone"
                        [inputType]="'tel'"
                    ></adaa-form-input>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <adaa-form-input
                        [label]="'common.form.label.mobilePhone' | translate"
                        formControlName="mobilePhone"
                        controlName="mobilePhone"
                        [inputType]="'tel'"
                    ></adaa-form-input>
                </div>
            </div>
        </div>
    </form>
</div>

<div class="my-3">
    <p class="fw-bold fs-4">{{ "rules_management.entities" | translate }}</p>
    <div class="bg-white rounded-1 p-2">
        <div class="bg-secondary rounded-1 text-center text-white fw-bold shadow-md p-2 m-3">
            {{ userEntity() }}
        </div>
    </div>
</div>

<div class="my-3">
    <p class="fw-bold fs-4">{{ "user_profile.wizard.notification_settings" | translate }}</p>
    <div class="bg-white shadow-md rounded-1 p-2 mb-3" [dir]="languageService.direction()">
        <div class="bg-light d-flex gap-5 align-items-center">
            <p class="mt-3" [class]="languageService.current() === 'ae' ? 'me-4' : 'ms-4'">
                {{ "user_profile.wizard.preferred_language" | translate }}
            </p>
            <adaa-form-radio
                [options]="radioOptions()"
                [setDefaultValue]="notificationPreference()"
                (inputChanges)="setPreferredLanguage($event)"
                [styles]="'d-flex justify-content-between'"
            ></adaa-form-radio>
        </div>
    </div>
</div>

<div class="my-3">
    <p class="fw-bold fs-4">{{ "user_profile.channels" | translate }}</p>
    <div class="bg-white shadow-md rounded-1 p-3 mb-3">
        <adaa-data-table
            #adaaDataTable
            [configKey]="tableConfList"
            [isLocalData]="true"
            [localData]="notificationChannels()"
            [enableAdd]="false"
            [enableActionsCell]="false"
            (inputListChanged)="setNotificationPreferences($event)"
            [tableInputs]="tableInputs"
        ></adaa-data-table>
    </div>
</div>
