import { Component, inject, input, OnInit, output, signal, TemplateRef, viewChild } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { GoogleMap, MapMarker } from "@angular/google-maps";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { FormActionButtonsComponent, FormCheckboxComponent, FormInputComponent } from "../../../../shared/components";
import { Constants } from "../../../../shared/constants/constants";
import { AdaaBoolean, Language, PageMode } from "../../../../shared/constants/enums";
import { EntityOfficeModelType } from "../../../../shared/models";
import { AppService } from "../../../../shared/services";
import { AddressesEditorService } from "../../../../shared/services/addresses-editor.service";

@Component({
  selector: "adaa-addresses-editor",
  standalone: true,
  imports: [
    FormInputComponent,
    ReactiveFormsModule,
    TranslateModule,
    FormActionButtonsComponent,
    GoogleMap,
    MapMarker,
    FormCheckboxComponent,
  ],
  templateUrl: "./addresses-editor.component.html",
  styleUrl: "./addresses-editor.component.scss",
})
export class AddressesEditorComponent implements OnInit {
  private _formBuilder = inject(FormBuilder);
  private _modalService = inject(NgbModal);
  private _appService = inject(AppService);
  private _translateService = inject(TranslateService);
  private _toastrService = inject(ToastrService);
  addressesEditorService = inject(AddressesEditorService);

  modal = viewChild.required<TemplateRef<unknown>>("content");

  pageMode = input.required<string>();
  currentEntityOffice = input<EntityOfficeModelType>();
  editorClosed = output<EntityOfficeModelType>();
  PageMode = PageMode;

  addressForm: FormGroup = new FormGroup({});
  options = signal<google.maps.MapOptions>({
    center: { lat: 25.276987, lng: 55.296249 },
    zoom: 5,
  });
  coordinates = signal<{ latitude: string | number; longitude: string | number }>({
    latitude: 0,
    longitude: 0,
  });
  submitted = signal<boolean>(false);
  markerOptions: google.maps.marker.AdvancedMarkerElementOptions = { gmpDraggable: false };
  markerPositions: google.maps.LatLngLiteral[] = [];

  public ngOnInit() {
    this._prepareForm();
    this.addressesEditorService.formValidator();
  }

  public open() {
    const modal = this._modalService.open(this.modal(), {
      animation: true,
      scrollable: false,
      keyboard: false,
      size: "xl",
      centered: true,
      modalDialogClass: this._appService.language() === Language.Arabic ? "modal-rtl" : "modal-ltr",
    });

    modal.closed.subscribe(() => this.addressForm.reset({ isHQ: AdaaBoolean.N }));
    this.submitted.set(false);
  }

  private _prepareForm() {
    this.addressForm = this._formBuilder.group({
      addressAE: this._formBuilder.control<string | undefined>("", {
        validators: [Validators.required],
      }),
      addressEN: this._formBuilder.control<string | undefined>("", {
        validators: [Validators.required],
      }),
      zipCodeAE: this._formBuilder.control<string | undefined>("", {
        validators: [Validators.required],
      }),
      cityAE: this._formBuilder.control<string | undefined>("", {
        validators: [Validators.required],
      }),
      cityEN: this._formBuilder.control<string | undefined>("", {
        validators: [Validators.required],
      }),
      geoX: this._formBuilder.control<number | undefined>(undefined, {
        validators: [Validators.required],
      }),
      geoY: this._formBuilder.control<number | undefined>(undefined, {
        validators: [Validators.required],
      }),
      isHQ: this._formBuilder.control<string | undefined>(AdaaBoolean.N),
    });
  }

  public mapClicked(event: google.maps.MapMouseEvent) {
    if (event.latLng) {
      this.markerPositions = [];
      this.markerPositions.push(event.latLng.toJSON());
      this.coordinates.set({
        latitude: event.latLng.lat().toFixed(6),
        longitude: event.latLng.lng().toFixed(6),
      });
      this.addressForm.controls["geoX"].patchValue(this.coordinates().longitude);
      this.addressForm.controls["geoY"].patchValue(this.coordinates().latitude);
    }
  }

  public submit() {
    this.submitted.set(true);
    if (this.addressForm.invalid) {
      this._toastrService.error(this._translateService.instant("common.form.label.missing_information"));
      return;
    }
    const result = {
      ...(this.pageMode() === PageMode.edit && { ...this.currentEntityOffice() }),
      ...this.addressForm.value,
      isHQ: this.addressForm.value.isHQ ?? AdaaBoolean.N,
      status: Constants.OBJECT_STATUS.ACTIVE,
    };

    this.editorClosed.emit(result);
  }

  public checkInvalid(control: string) {
    return (
      this.addressForm.controls[control].errors?.["required"] &&
      (this.addressForm.get("nameEN")?.dirty || this.submitted())
    );
  }
}
