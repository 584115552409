import { Route } from "@angular/router";

import { checkIfLoggedInFn, pmoEntity } from "../../core/guards";
import { Constants } from "../../shared/constants/constants";
import { PageMode } from "../../shared/constants/enums";
import { createUsersRolesPageGuards, manageUsersRolesPageGuards, viewUsersRolesPageGuards } from "./_guards";
import { UserRolesEditorComponent } from "./user-roles-editor/user-roles-editor.component";
import { UserRolesListComponent } from "./user-roles-list/user-roles-list.component";

export const USER_ROLES_ROUTES: Route[] = [
  {
    path: "users-roles",
    canActivate: [checkIfLoggedInFn, pmoEntity, ...viewUsersRolesPageGuards],
    children: [
      {
        path: "list",
        redirectTo: "",
      },
      {
        path: "",
        component: UserRolesListComponent,
      },
      {
        path: "create",
        canActivate: [...createUsersRolesPageGuards],
        component: UserRolesEditorComponent,
        data: {
          title: "breadcrumbs.new_user_role",
          breadcrumb: "breadcrumbs.new_user_role",
          pageMode: PageMode.create,
        },
      },
      {
        path: ":id",
        component: UserRolesEditorComponent,
        data: {
          title: "breadcrumbs.view_user_role",
          breadcrumb: "breadcrumbs.view_user_role",
          pageMode: PageMode.view,
        },
      },
      {
        path: "edit/:id",
        canActivate: [...manageUsersRolesPageGuards],
        component: UserRolesEditorComponent,
        data: {
          title: "breadcrumbs.edit_user_roles",
          breadcrumb: "breadcrumbs.edit_user_roles",
          pageMode: PageMode.edit,
        },
      },
    ],
    data: {
      title: "user_roles.title",
      breadcrumb: "user_roles.title",
      tableConfList: Constants.MAIN_TABLE_LIST_CONF_KEY.USERS_GROUPS,
    },
  },
];
