import { Route } from "@angular/router";

import { checkIfLoggedInFn } from "../../core/guards";
import { viewNotificationManagementPageGuards } from "./_guards";
import { NotificationsManagementListComponent } from "./notifications-management-list/notifications-management-list.component";

export const NOTIFICATIONS_MANAGEMENT_ROUTES: Route[] = [
  {
    path: "notifications",
    canActivate: [checkIfLoggedInFn, ...viewNotificationManagementPageGuards],
    children: [
      {
        path: "",
        component: NotificationsManagementListComponent,
        data: {
          breadcrumb: "communication.notification_templates",
          title: "communication.notification_templates",
        },
      },
    ],
  },
];
