<adaa-auth-layout>
    <form [formGroup]="verificationForm" (ngSubmit)="submit()" class="row mt-2">
        <div class="col-12 mb-3">
            @if (user) {
                <p>{{ user.username }}</p>
            }

            <h5 class="text-info mb-1">{{ "login.two_steps_title" | translate }}</h5>
            <h6 class="text-info">{{ "login.select_channel" | translate }}</h6>
        </div>
        <div class="col-12 mb-3">
            <adaa-form-radio
                [setDefaultValue]="verificationMethod.EMAIL"
                [options]="loginVerificationOptions"
                controlName="method"
                (inputChanges)="verificationMethodUpdated($event)"
            >
            </adaa-form-radio>
        </div>
        <div class="col-12 mb-3">
            @if (lockCounter === 0) {
                <button type="button" (click)="sendVerificationCode()" class="btn btn-info">
                    {{ "common.form.button.send" | translate }}
                </button>
            } @else if (lockCounter > 0) {
                <button [disabled]="true" class="btn btn-info">{{ "login.resendCode" | translate }} - {{ lockCounter }}</button>
            }
        </div>
        <div class="col-12">
            <adaa-form-input
                [required]="true"
                [placeholder]="'login.confirmation_code' | translate"
                controlName="verificationCode"
                formControlName="verificationCode"
                inputType="positiveNumber"
                [maxLength]="999999"
                [invalid]="verificationForm.get('verificationCode')?.dirty && verificationForm.get('verificationCode')?.errors?.['required']"
            ></adaa-form-input>

            <adaa-form-checkbox [label]="'login.dont_ask' | translate" controlName="rememberMe" formControlName="rememberMe"></adaa-form-checkbox>
        </div>
        <div class="col-12 mb-4">
            <button type="submit" class="btn btn-info">
                {{ "login.confirm" | translate }}
            </button>
        </div>
    </form>
</adaa-auth-layout>
