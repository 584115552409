/* eslint-disable no-console */
import { HttpErrorResponse, HttpHandlerFn, HttpRequest } from "@angular/common/http";
import { inject, isDevMode } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import { Constants } from "../../shared/constants/constants";
import { LogoutService } from "../../shared/services";

/**
 * Handles all errors from the server
 *
 * @param {HttpRequest} req
 * @param {HttpHandlerFn} next
 * @returns
 */
export function errorsInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn) {
  const toastrService = inject(ToastrService);
  const router = inject(Router);
  const translateService = inject(TranslateService);
  const logoutService = inject(LogoutService);

  const isSessionError = (error: HttpErrorResponse) => {
    if (error.error) {
      return +error.error.responseCode === Constants.CONSTANT_ERROR_CODE.SESSION_EXPIRED;
    }
    return false;
  };

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (isSessionError(error)) {
        logoutService.setLastUrlVisited(router.url);
        const key = "notification.error.code_" + (error.error.responseCode ? error.error.responseCode : "generic");
        translateService.get(key).subscribe((res: string) => {
          toastrService.error(res);
        });
        logoutService.logout(true);
      }

      if (isDevMode()) {
        console.groupCollapsed(`Response Error from ${req.url} - [method: ${req.method}]`);
        console.log(error);
        console.groupEnd();
      }

      return throwError(() => error);
    })
  );
}
